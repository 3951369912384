import React from 'react';
import vars from 'src/export.scss';
import { Loading } from '@bitmodern/bit-ui';
import styles from './GlobalLoading.module.scss';
import { useGlobalLoading } from './useGlobalLoading';

export function GlobalLoading() {
  const globalLoading = useGlobalLoading();

  if (!globalLoading.isLoading) return null;

  return (
    <div className={styles.root}>
      <Loading
        className={styles.loading}
        color={vars.textPrimary}
        delay={0}
        size={16}
      />
      Syncing
    </div>
  );
}
