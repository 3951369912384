import React from 'react';
import { StarIcon } from 'src/packages/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import tqPreview from './testQuality.png';
import styles from './ReviewPanel.module.scss';

export function ReviewPanel() {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div className={styles.comment}>{t('reviewPanel.comment')}</div>
      <div className={styles.rating}>
        {[...Array(5)].map((_, i) => (
          <StarIcon key={`star-${i}`} size={26} />
        ))}
      </div>
      <div className={styles.name}>- {t('reviewPanel.name')}</div>
      <div className={styles.jobPosition}>{t('reviewPanel.jobPosition')}</div>
      <div className={styles.tqPreview}>
        <img src={tqPreview} />
      </div>
    </div>
  );
}
