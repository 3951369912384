import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import { useFormik } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import { showNotificationError, HttpError } from '@testquality/sdk';
import { Button, InputPassword, useReCaptcha } from '@bitmodern/bit-ui';
import { GithubIcon, GoogleIcon } from '@bitmodern/bit-ui/icons';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { userCompleteInviteThunk } from '@bitmodern/redux/state/users/thunks';
import { useAppDispatch } from '@bitmodern/redux/store';
import { getClient } from 'src/Client';
import { TermsOfServiceDialog } from 'src/components/organisms';
import routes from 'src/components/Router/routes';
import { useQuery } from 'src/hooks/useQuery';
import { useTranslation } from 'src/i18n/hooks';
import { useSocialLogin } from 'src/modules/authentication/elements';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import vars from 'src/export.scss';
import styles from './CompleteInviteUserStep1.module.scss';

import Debug from 'debug';
const debug = Debug('CompleteInviteUserStep1');

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('completeInviteUser.form.email'),
  password: Yup.string()
    .required()
    .min(6)
    .label('completeInviteUser.form.password'),
});

type InitialValues = {
  email: string;
  password: string;
};

export type CompleteInviteUserStep1Props = {
  email?: string;
  onSubmit: ({ email }: InitialValues) => void;
};

export function CompleteInviteUserStep1({
  email = '',
}: CompleteInviteUserStep1Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const terms = useOverlayTriggerState({});
  const { token: verificationToken } = useQuery(['token']);
  const { executeRecaptcha } = useReCaptcha();
  const { github, google } = useSocialLogin({
    onError: showNotificationError,
    verificationToken,
  });
  const { t } = useTranslation();

  const initialValues = useMemo<InitialValues>(
    () => ({
      email,
      password: '',
    }),
    [email],
  );
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  async function onSubmit({ email, password }: InitialValues) {
    debug('onSubmit', { email, password });

    if (!executeRecaptcha) {
      showNotificationError(
        new HttpError(t('completeInviteUser.step1.recaptchaError')),
      );
      return;
    }

    const recaptcha = await executeRecaptcha('completeInviteUserStep1');
    debug('recaptcha', recaptcha);

    const userDetails = {
      email,
      password,
      verification_token: verificationToken,
    };
    const user = JSON.stringify(userDetails);

    return (
      dispatch(
        userCompleteInviteThunk({
          params: { user, g_recaptcha_response: recaptcha },
        }),
      )
        .then(unwrapResult)
        .then(() => {
          return getClient().getAuth().login(email, password, true);
        })
        .then((token) => {
          if (token) {
            dispatch(authenticateAction(token));
            history.replace(routes.HOME({ site: token.client_name }));
          } else {
            throw new HttpError(t('completeInviteUser.step1.loginError'));
          }
        })
        // TODO
        // should we redirect to sign in page ?
        .catch((error) => showNotificationError(error))
    );
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>{t('completeInviteUser.step1.title')}</div>
      <div className={styles.subtitle}>
        {t('completeInviteUser.step1.subtitle', { email })}
      </div>

      <div className={styles.buttons}>
        <Button
          color="primaryLight"
          fullWidth
          loading={github.loading}
          onClick={github.handleClick}
          icon={<GithubIcon color={vars.textPrimary} size={24} />}>
          {t('completeInviteUser.step1.buttons.github')}
        </Button>
        <Button
          color="primaryLight"
          fullWidth
          loading={google.loading}
          onClick={google.handleClick}
          icon={<GoogleIcon color={vars.textPrimary} size={22} />}>
          {t('completeInviteUser.step1.buttons.google')}
        </Button>
      </div>

      <div className={styles.separator}>
        <span className={styles.separatorText}>
          {t('completeInviteUser.step1.email')}
        </span>
      </div>

      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <InputPassword
          aria-label={t('completeInviteUser.form.password')}
          error={formikError(formik, 'password')}
          fullWidth
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('completeInviteUser.form.password')}
          value={formik.values.password}
        />
        <Button
          className={styles.formSubmit}
          color="primaryLight"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('completeInviteUser.form.submit')}
        </Button>
      </form>

      <div className={styles.terms}>
        <Trans i18nKey="completeInviteUser.step1.terms">
          I agree to TestQuality&apos;s{' '}
          <span className={styles.termsLink} onClick={terms.open}>
            Privacy Policy
          </span>{' '}
          and{' '}
          <span className={styles.termsLink} onClick={terms.open}>
            Terms of Service
          </span>
          .
        </Trans>
      </div>

      <div className={styles.termsGoogle}>
        <Trans i18nKey="completeInviteUser.step1.termsGoogle">
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            className={styles.termsLink}
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className={styles.termsLink}
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer">
            Terms of Service
          </a>{' '}
          apply.
        </Trans>
      </div>

      <TermsOfServiceDialog isOpen={terms.isOpen} onClose={terms.close} />
    </div>
  );
}
