import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'src/i18n/hooks';
import { LearnMorePanel } from '../LearnMorePanel';

export function InvitesStepInfo() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('getStarted.invitesStep.info.hero')}</title>
      </Helmet>
      <LearnMorePanel
        title={t('getStarted.invitesStep.info.title')}
        text={t('getStarted.invitesStep.info.text')}
        url={t('getStarted.invitesStep.info.url')}
      />
    </>
  );
}
