/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { DataSet } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { dataSetAdapter } from './dataSetAdapter';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
  dataSetDeleteOneThunk,
  dataSetUpdateOneThunk,
  dataSetCreateOneThunk,
} from './dataSetThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const dataSetSlice = createSlice({
  name: 'dataSet',
  initialState: dataSetAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    dataSetCleared(state) {
      state.loading = 'idle';
      dataSetAdapter.removeAll(state);
    },
    dataSetUpsertMany(
      state,
      action: PayloadAction<DataSet[] | Record<number, DataSet>>,
    ) {
      dataSetAdapter.upsertMany(state, action.payload);
    },
    dataSetUpsertOne(state, action: PayloadAction<DataSet>) {
      dataSetAdapter.upsertOne(state, action.payload);
    },
    dataSetUpdateOne(state, action: PayloadAction<Update<DataSet>>) {
      dataSetAdapter.updateOne(state, action.payload);
    },
    dataSetRemoveOne(state, action: PayloadAction<number>) {
      dataSetAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dataSetFetchManyThunk.pending, handlePending);
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      dataSetAdapter.upsertMany(state, action.payload.dataSet);
    });
    builder.addCase(dataSetFetchManyThunk.rejected, handleRejected);
    builder.addCase(dataSetFetchOneThunk.pending, handlePending);
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      dataSetAdapter.upsertOne(state, action.payload.dataSet[0]);
    });
    builder.addCase(dataSetFetchOneThunk.rejected, handleRejected);
    builder.addCase(dataSetDeleteOneThunk.pending, handlePending);
    builder.addCase(dataSetDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        dataSetAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(dataSetDeleteOneThunk.rejected, handleRejected);
    builder.addCase(dataSetUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, dataSetAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(dataSetUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      dataSetAdapter.updateOne(state, action.payload);
    });
    builder.addCase(dataSetUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          dataSetAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(dataSetCreateOneThunk.pending, handlePending);
    builder.addCase(dataSetCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      dataSetAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(dataSetCreateOneThunk.rejected, handleRejected);
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.dataSet) {
          dataSetAdapter.upsertMany(state, action.payload.dataSet);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.dataSet) {
          dataSetAdapter.upsertMany(state, action.payload.dataSet);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.dataSet) {
          dataSetAdapter.upsertMany(state, action.payload.dataSet);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.dataSet) {
        dataSetAdapter.upsertMany(state, action.payload.dataSet);
      }
    });
  },
});

export const {
  dataSetCleared,
  dataSetUpsertMany,
  dataSetUpsertOne,
  dataSetUpdateOne,
  dataSetRemoveOne,
} = dataSetSlice.actions;

export const dataSetReducer = dataSetSlice.reducer;
