import React from 'react';
import { Test, TestQuality } from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import { allTestQualitySelector } from '@bitmodern/redux/state/testQuality/selectors';
import {
  excellentTQTypeSelector,
  goodTQTypeSelector,
} from '@bitmodern/redux/state/testQualityType/selectors';
import TestQualityBreakdownListItem from './TestQualityBreakdownListItem';
import styles from './TestQualityBreakdownList.module.scss';

type Props = {
  tests: Test[];
  className?: string;
};

export default function TestQualityBreakdownList({
  tests,
  className = '',
}: Props) {
  const allTestQuality = useAppSelector(allTestQualitySelector);
  const excellentTQType = useAppSelector(excellentTQTypeSelector);
  const goodTQType = useAppSelector(goodTQTypeSelector);

  const renderBreakdownItems = (tq: TestQuality) => {
    const amount = tests.filter(
      (test) => test.test_quality_id === tq.id,
    ).length;

    if (!amount || !excellentTQType || !goodTQType) return null;

    const isGoodQuality =
      tq.test_quality_type_id === excellentTQType.id ||
      tq.test_quality_type_id === goodTQType.id;

    return (
      <TestQualityBreakdownListItem
        key={tq.id}
        testQuality={tq}
        isGoodQuality={isGoodQuality}
        testQualityAmount={amount}
      />
    );
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {allTestQuality.map(renderBreakdownItems)}
    </div>
  );
}
