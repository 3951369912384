/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectResAdapter } from './defectResAdapter';

export const defectResSelectors = defectResAdapter.getSelectors(
  (state: State) => state.gen.defectRes,
);
