/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectStatusAdapter } from './defectStatusAdapter';

export const defectStatusSelectors = defectStatusAdapter.getSelectors(
  (state: State) => state.gen.defectStatus,
);
