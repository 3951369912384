/* eslint-disable no-shadow */
import {
  BugIcon,
  DangerIcon,
  DocumentIcon,
  FlagIcon,
  FolderStarIcon,
  TestIcon,
} from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';

export enum ProjectColors {
  Blue = '0',
  Green = '1',
  Orange = '2',
  Coral = '3',
  Purple = '4',
  Red = '5',
}

export const ProjectColorsDisplay: ReadonlyMap<ProjectColors, string> = new Map(
  [
    [ProjectColors.Blue, vars.projectBlue],
    [ProjectColors.Green, vars.projectGreen],
    [ProjectColors.Orange, vars.projectOrange],
    [ProjectColors.Coral, vars.projectCoral],
    [ProjectColors.Purple, vars.projectPurple],
    [ProjectColors.Red, vars.projectRed],
  ],
);

export enum ProjectIcons {
  Test = '0',
  Flag = '1',
  Danger = '2',
  FolderStar = '3',
  Document = '4',
  Bug = '5',
}

export const ProjectIconsDisplay: ReadonlyMap<ProjectIcons, any> = new Map([
  [ProjectIcons.Test, TestIcon],
  [ProjectIcons.Flag, FlagIcon],
  [ProjectIcons.Danger, DangerIcon],
  [ProjectIcons.FolderStar, FolderStarIcon],
  [ProjectIcons.Document, DocumentIcon],
  [ProjectIcons.Bug, BugIcon],
]);
