import { State } from '@bitmodern/redux/store';
import { appVersionPlatVersionPlanSelectors } from 'src/gen/domain/app_version_plat_version_plan/appVersionPlatVersionPlanSelector';
import { createSelector } from 'reselect';
import { AppVersionPlatVersionPlan } from '@testquality/sdk';

export function appVersionPlatVersionPlansSelector(state: State) {
  return appVersionPlatVersionPlanSelectors.selectAll(state);
}

export const makeAppVersionPlatVersionPlansByPlan = () =>
  createSelector(
    [
      appVersionPlatVersionPlanSelectors.selectEntities,
      (_, { planId }: { planId?: number }) => planId,
    ],
    (appVersionPlatVersionPlans, planId) => {
      const avpvps: AppVersionPlatVersionPlan[] = [];
      if (!planId) return avpvps;

      for (const id in appVersionPlatVersionPlans) {
        const avpv = appVersionPlatVersionPlans[id];
        if (avpv && avpv.plan_id === planId) {
          avpvps.push(avpv);
        }
      }

      return avpvps;
    },
  );

// TODO: remove this
export const appVersionPlatVersionPlansSelectorByPlan =
  makeAppVersionPlatVersionPlansByPlan();
