import { AppThunk } from '@bitmodern/redux/store';
import { runResultStepUpdateOneThunk } from 'src/gen/domain/run_result_step/runResultStepThunk';
import { BatchService } from '@testquality/sdk';
import { runResultUpdateOneThunk } from 'src/gen/domain/run_result/runResultThunk';
import { runResultStepsByRunResultsSelector } from '../RunResultSteps/selectors';

export function changeRunResultsStatusThunk(
  runResultIds: number[],
  statusId: number,
): AppThunk<Promise<any>> {
  return (dispatch, getState) => {
    const runResultSteps = runResultStepsByRunResultsSelector(
      getState(),
      runResultIds,
    );

    const batch = new BatchService();

    runResultIds.forEach((runResultId) => {
      dispatch(
        runResultUpdateOneThunk({
          batch,
          optimistic: true,
          id: runResultId,
          data: { status_id: statusId },
        }),
      );
    });

    runResultSteps.forEach((runResultStep) => {
      dispatch(
        runResultStepUpdateOneThunk({
          batch,
          optimistic: true,
          id: runResultStep.id,
          data: { ...runResultStep, status_id: statusId },
        }),
      );
    });

    return batch.executeBatch();
  };
}
