import React, { ReactNode } from 'react';
import styles from './DescriptiveAction.module.scss';

type Props = {
  action: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  className?: string;
};

export default function DescriptiveAction({
  action,
  description,
  title,
  className = '',
}: Props) {
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.meta}>
        <div className={styles.title}>{title}</div>
        {description || <div>{description}</div>}
      </div>
      {action}
    </div>
  );
}
