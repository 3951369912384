/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Status } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { statusAdapter } from './statusAdapter';
import {
  statusFetchManyThunk,
  statusFetchOneThunk,
  statusDeleteOneThunk,
  statusUpdateOneThunk,
  statusCreateOneThunk,
} from './statusThunk';
import {
  statusTypeFetchManyThunk,
  statusTypeFetchOneThunk,
} from '../status_type/statusTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const statusSlice = createSlice({
  name: 'status',
  initialState: statusAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    statusCleared(state) {
      state.loading = 'idle';
      statusAdapter.removeAll(state);
    },
    statusUpsertMany(
      state,
      action: PayloadAction<Status[] | Record<number, Status>>,
    ) {
      statusAdapter.upsertMany(state, action.payload);
    },
    statusUpsertOne(state, action: PayloadAction<Status>) {
      statusAdapter.upsertOne(state, action.payload);
    },
    statusUpdateOne(state, action: PayloadAction<Update<Status>>) {
      statusAdapter.updateOne(state, action.payload);
    },
    statusRemoveOne(state, action: PayloadAction<number>) {
      statusAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(statusFetchManyThunk.pending, handlePending);
    builder.addCase(statusFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusAdapter.upsertMany(state, action.payload.status);
    });
    builder.addCase(statusFetchManyThunk.rejected, handleRejected);
    builder.addCase(statusFetchOneThunk.pending, handlePending);
    builder.addCase(statusFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusAdapter.upsertOne(state, action.payload.status[0]);
    });
    builder.addCase(statusFetchOneThunk.rejected, handleRejected);
    builder.addCase(statusDeleteOneThunk.pending, handlePending);
    builder.addCase(statusDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        statusAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(statusDeleteOneThunk.rejected, handleRejected);
    builder.addCase(statusUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, statusAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(statusUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusAdapter.updateOne(state, action.payload);
    });
    builder.addCase(statusUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          statusAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(statusCreateOneThunk.pending, handlePending);
    builder.addCase(statusCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(statusCreateOneThunk.rejected, handleRejected);
    builder.addCase(statusTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.status) {
        statusAdapter.upsertMany(state, action.payload.status);
      }
    });
    builder.addCase(statusTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.status) {
        statusAdapter.upsertMany(state, action.payload.status);
      }
    });
  },
});

export const {
  statusCleared,
  statusUpsertMany,
  statusUpsertOne,
  statusUpdateOne,
  statusRemoveOne,
} = statusSlice.actions;

export const statusReducer = statusSlice.reducer;
