import React from 'react';
import { useTranslation } from 'react-i18next';
import { JiraIcon } from '@bitmodern/bit-ui/icons';
import { useIntegration } from '../../hooks/useIntegration';
import SuccessIcons from '../SuccessIcons';
import { StepContent } from '../StepContent';
import { StepActions } from '../StepActions';
import { StepProps } from '../Step';
import StaggerWrapper from '../StaggerWrapper';
import styles from './JiraStep.module.scss';

export function JiraStep({ onNextStep, currentStep, stepTotal }: StepProps) {
  const { t } = useTranslation();
  const { authorize, loading, success, user } = useIntegration('jira');

  let action;
  let content;
  let title;
  let onClickAction = onNextStep;

  if (success) {
    title = t('getStarted.jiraStep.success.title');
    content = (
      <>
        <SuccessIcons IntegrationIcon={JiraIcon} />
        <p>{t('getStarted.jiraStep.success.text')}</p>
      </>
    );
  } else if (user) {
    title = t('getStarted.jiraStep.integration.title');
    content = (
      <ul className={styles.list}>
        <li>{t('getStarted.jiraStep.integration.one')}</li>
        <li>{t('getStarted.jiraStep.integration.two')}</li>
        <li>{t('getStarted.jiraStep.integration.three')}</li>
      </ul>
    );
  } else {
    action = t('getStarted.jiraStep.authorizeButton');
    title = t('getStarted.jiraStep.title');
    content = (
      <ul className={styles.list}>
        <li>{t('getStarted.jiraStep.features.one')}</li>
        <li>{t('getStarted.jiraStep.features.two')}</li>
        <li>{t('getStarted.jiraStep.features.three')}</li>
      </ul>
    );
    onClickAction = authorize;
  }

  return (
    <>
      <StepContent
        currentStep={currentStep}
        stepTotal={stepTotal}
        title={title}>
        <StaggerWrapper>{content}</StaggerWrapper>
      </StepContent>
      <StepActions
        action={action}
        loading={loading}
        onClickAction={onClickAction}
        onSkip={onNextStep}
      />
    </>
  );
}
