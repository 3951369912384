import { AppThunk } from '@bitmodern/redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  accessRoleCreateOneThunk,
  accessRoleUpdateOneThunk,
} from 'src/gen/domain/access_role/accessRoleThunk';
import { upsertAccessRolePoliciesThunk } from '@bitmodern/redux/state/policy/thunks';
import { accessRoleSelector, accessRolesSelector } from './selectors';
import { RoleFormDetails } from 'src/components/organisms/RoleForm/RoleForm';
import { Permission } from 'src/enums/PermissionsEnum';
import _pick from 'lodash/pick';
import { AccessRole } from '@testquality/sdk';

export function upsertAccessRoleThunk(
  values: RoleFormDetails,
  initialValues: RoleFormDetails,
  accessRoleId?: number,
): AppThunk<Promise<any>> {
  return (dispatch, getState) => {
    const currentAccessRole = accessRoleSelector(getState(), { accessRoleId });
    const accessRoles = accessRolesSelector(getState());

    /* NOTE: Update existing role */
    if (currentAccessRole) {
      const promises: Array<Promise<any>> = [];
      if (policiesHaveChanged(values, initialValues)) {
        promises.push(
          dispatch(upsertAccessRolePoliciesThunk(values, currentAccessRole)),
        );
      }

      const updates: Partial<AccessRole> = {};

      if (currentAccessRole.name !== values.name) {
        updates.name = values.name;
      }

      if (currentAccessRole.is_default !== values.isDefault) {
        updates.is_default = values.isDefault;

        // Set or clear is_default from other role as needed.
        const additionalRoleToUpdate = values.isDefault
          ? accessRoles.find(({ is_default }) => Boolean(is_default))
          : accessRoles.find(({ id }) => id !== currentAccessRole.id);

        if (additionalRoleToUpdate) {
          promises.push(
            dispatch(
              accessRoleUpdateOneThunk({
                id: additionalRoleToUpdate.id,
                data: { is_default: !values.isDefault },
              }),
            ),
          );
        }
      }

      if (Object.keys(updates).length) {
        promises.push(
          dispatch(
            accessRoleUpdateOneThunk({
              id: currentAccessRole.id,
              data: updates,
            }),
          ),
        );
      }

      return Promise.all(promises);
    }

    /* NOTE: Create new role */
    const reqs: Array<Promise<any>> = [];

    reqs.push(
      dispatch(
        accessRoleCreateOneThunk({
          data: { is_default: values.isDefault, name: values.name },
        }),
      )
        .then(unwrapResult)
        .then((res: any) => {
          const stringifiedValues = JSON.stringify({ ...values, name: '' });
          /* NOTE: Update policies only if default permissions were changed */
          if (stringifiedValues !== JSON.stringify(initialValues)) {
            return dispatch(
              upsertAccessRolePoliciesThunk(values, res, res.policy),
            );
          }
          return undefined;
        }),
    );

    if (values.isDefault) {
      const role = accessRoles.find((r) => r.is_default);
      if (role) {
        reqs.push(
          dispatch(
            accessRoleUpdateOneThunk({
              id: role.id,
              data: { is_default: false },
            }),
          ),
        );
      }
    }

    return Promise.all(reqs);
  };
}

function policiesHaveChanged(
  values: RoleFormDetails,
  initialValues: RoleFormDetails,
): boolean {
  const permissions = Object.values(Permission);
  permissions.push('projects' as any);

  return (
    JSON.stringify(_pick(values, permissions)) !==
    JSON.stringify(_pick(initialValues, permissions))
  );
}
