import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Loading, notification, Surface, Button } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import useMutation from 'src/hooks/useMutation';
import { useQuery } from 'src/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  emailVerificationCheckThunk,
  resendEmailVerificationThunk,
} from '@bitmodern/redux/state/authentication/thunks';
import {
  isAuthenticatedSelector,
  tokenSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useTranslation } from 'src/i18n/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { getClient } from 'src/Client';
import styles from './EmailVerificationView.module.scss';
import { notificationErrorTimeout } from 'src/constants';

export default function EmailVerificationView() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const appToken = useAppSelector(tokenSelector);
  const { token, email } = useQuery(['token', 'email']);

  const site = appToken?.client_name;

  const resendVerification = useCallback(() => {
    return dispatch(resendEmailVerificationThunk({}))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notification.actions.resend.success.message'),
        }),
      )
      .catch(() =>
        notification.open({
          type: 'error',
          message: t('notification.actions.resend.failed.message'),
          duration: notificationErrorTimeout,
        }),
      );
  }, [dispatch, t]);
  const resendVerificationMutation = useMutation(resendVerification);

  useEffect(() => {
    dispatch(
      emailVerificationCheckThunk({
        params: { verificationToken: token },
        data: { email },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        notification.open({
          type: 'success',
          message: t('emailVerification.notification.success'),
          duration: 0, // do not auto-close success
        });
        if (isAuthenticated) {
          return getClient().getAuth().refresh();
        }
        return undefined;
      })
      .then((freshToken) => {
        if (!freshToken) {
          history.push(routes.LOGIN({}));
        } else {
          dispatch(authenticateAction(freshToken));
          if (freshToken) {
            history.push(routes.HOME({ site: freshToken.client_name }));
          }
        }
      })
      .catch((error: { message: string; name: string }) => {
        if (error.message.includes('already verified')) {
          notification.open({
            type: 'success',
            message: t('emailVerification.notification.alreadyVerified'),
            duration: 0, // do not auto-close success
          });
          if (site) {
            history.push(routes.HOME({ site }));
          } else {
            history.push(routes.LOGIN({}));
          }
        } else {
          setIsLoading(false);
        }
      });
  }, [dispatch, t, email, history, site, token, isAuthenticated]);

  const onClickLogin = () => {
    history.push(routes.LOGIN({}));
  };

  const onClickNavigate = () => {
    if (site) {
      history.push(routes.HOME({ site }));
    } else {
      history.push(routes.LOGIN({}));
    }
  };

  return (
    <div className={styles.verification}>
      <Helmet>
        <title>{t('emailVerification.head.title')}</title>
      </Helmet>

      {isLoading ? (
        <Loading size={48} />
      ) : (
        <Surface className={styles.panel}>
          <h1 className={styles.title}>{t('emailVerification.fail.title')}</h1>
          <h3>{t('emailVerification.fail.subtitle')}</h3>
          <p className={styles.description}>
            {t('emailVerification.fail.description')}
          </p>
          <div className={styles.actions}>
            {!isAuthenticated ? (
              <>
                <Button
                  onClick={resendVerificationMutation.mutate}
                  loading={resendVerificationMutation.isLoading}>
                  {t('emailVerification.fail.resend')}
                </Button>
                <Button onClick={onClickNavigate} color="primaryLight">
                  {t('emailVerification.fail.navigate')}
                </Button>
              </>
            ) : (
              <Button onClick={onClickLogin}>
                {t('emailVerification.fail.login')}
              </Button>
            )}
          </div>
        </Surface>
      )}
    </div>
  );
}
