import { createSelector } from 'reselect';
import { State } from '@bitmodern/redux/store';

export const tokenSelector = createSelector(
  [(state: State) => state.authentication.token],
  (token) => token,
);

export const isAuthenticatedSelector = createSelector(
  [(state: State) => state.authentication.token],
  (token) => !!token,
);

export const currentUserSelector = createSelector(
  [
    (state: State) => state.authentication.token,
    (state: State) => state.gen.user.entities,
  ],
  (token, entities) => {
    if (token) {
      return entities[token.user_id];
    }
    return undefined;
  },
);

export const siteSelector = createSelector(
  [(state: State) => state.authentication.token],
  (token) => token?.client_name,
);
