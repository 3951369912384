import axios from 'axios';
import { getResponse } from '@testquality/sdk';
import { getClient } from 'src/Client';

export function getGithubPurchases(token: string) {
  return axios
    .get('https://api.github.com/user/marketplace_purchases', {
      headers: {
        Accept: 'application/vnd.github.valkyrie-preview+json',
        Authorization: `token ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const purchases = response.data;
      return Promise.all(
        purchases.map((purchase) => githubAllocated(purchase.account.id)),
      ).then((responses) => {
        return responses.map((item: any, i) => {
          const purchase = purchases[i];
          if (!item?.name) return purchase;
          return {
            ...purchase,
            allocated: {
              ...item,
              fullname: `${item.given_name} ${item.family_name}`,
            },
          };
        });
      });
    });
}

function githubAllocated(accountId) {
  return getResponse<any>(getClient().api, {
    method: 'get',
    url: `/subscription/order/github_marketplace/plan_already_allocated/${accountId}`,
  });
}

export function authenticateGithub(redirectUrl) {
  return getResponse<any>(getClient().api, {
    method: 'get',
    url: `/system/github/authorize?redirect_url=${redirectUrl}`,
  });
}

export function githubAttachTokenService(accessToken: string): Promise<any> {
  return getResponse<any>(getClient().api, {
    method: 'post',
    url: '/base_integration/2/attach_access_token',
    params: { access_token: accessToken },
  });
}
