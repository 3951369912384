/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { RunSuiteHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runSuiteHistoryAdapter } from './runSuiteHistoryAdapter';
import { runSuiteHistoryFetchThunk } from './runSuiteHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runSuiteHistorySlice = createSlice({
  name: 'runSuiteHistory',
  initialState: runSuiteHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runSuiteHistoryCleared(state) {
      state.loading = 'idle';
      runSuiteHistoryAdapter.removeAll(state);
    },
    runSuiteHistoryUpsertMany(
      state,
      action: PayloadAction<
        RunSuiteHistory[] | Record<number, RunSuiteHistory>
      >,
    ) {
      runSuiteHistoryAdapter.upsertMany(state, action.payload);
    },
    runSuiteHistoryUpsertOne(state, action: PayloadAction<RunSuiteHistory>) {
      runSuiteHistoryAdapter.upsertOne(state, action.payload);
    },
    runSuiteHistoryUpdateOne(
      state,
      action: PayloadAction<Update<RunSuiteHistory>>,
    ) {
      runSuiteHistoryAdapter.updateOne(state, action.payload);
    },
    runSuiteHistoryRemoveOne(state, action: PayloadAction<number>) {
      runSuiteHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runSuiteHistoryFetchThunk.pending, handlePending);
    builder.addCase(runSuiteHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runSuiteHistoryAdapter.upsertMany(state, action.payload.runSuiteHistory);
    });
    builder.addCase(runSuiteHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  runSuiteHistoryCleared,
  runSuiteHistoryUpsertMany,
  runSuiteHistoryUpsertOne,
  runSuiteHistoryUpdateOne,
  runSuiteHistoryRemoveOne,
} = runSuiteHistorySlice.actions;

export const runSuiteHistoryReducer = runSuiteHistorySlice.reducer;
