/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SignupOptionHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { signupOptionHistoryAdapter } from './signupOptionHistoryAdapter';
import { signupOptionHistoryFetchThunk } from './signupOptionHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const signupOptionHistorySlice = createSlice({
  name: 'signupOptionHistory',
  initialState: signupOptionHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    signupOptionHistoryCleared(state) {
      state.loading = 'idle';
      signupOptionHistoryAdapter.removeAll(state);
    },
    signupOptionHistoryUpsertMany(
      state,
      action: PayloadAction<
        SignupOptionHistory[] | Record<number, SignupOptionHistory>
      >,
    ) {
      signupOptionHistoryAdapter.upsertMany(state, action.payload);
    },
    signupOptionHistoryUpsertOne(
      state,
      action: PayloadAction<SignupOptionHistory>,
    ) {
      signupOptionHistoryAdapter.upsertOne(state, action.payload);
    },
    signupOptionHistoryUpdateOne(
      state,
      action: PayloadAction<Update<SignupOptionHistory>>,
    ) {
      signupOptionHistoryAdapter.updateOne(state, action.payload);
    },
    signupOptionHistoryRemoveOne(state, action: PayloadAction<number>) {
      signupOptionHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupOptionHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      signupOptionHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        signupOptionHistoryAdapter.upsertMany(
          state,
          action.payload.signupOptionHistory,
        );
      },
    );
    builder.addCase(signupOptionHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  signupOptionHistoryCleared,
  signupOptionHistoryUpsertMany,
  signupOptionHistoryUpsertOne,
  signupOptionHistoryUpdateOne,
  signupOptionHistoryRemoveOne,
} = signupOptionHistorySlice.actions;

export const signupOptionHistoryReducer = signupOptionHistorySlice.reducer;
