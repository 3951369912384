import React, { ComponentProps, ReactNode, useEffect } from 'react';
import { Route } from 'react-router';
import { trackEvent } from '../organisms/Tracker/Tracker';

type Props = ComponentProps<typeof Route>;

export default function AppRoute({
  component: Component,
  render,
  ...rest
}: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        let children: ReactNode = null;
        if (Component) children = <Component {...props} />;
        if (render) children = render(props);

        return (
          <>
            <RouteTracker match={props.match} />
            {children}
          </>
        );
      }}
    />
  );
}

function RouteTracker({ match }: any) {
  useEffect(() => {
    const url = match.url.endsWith('/') ? match.url.slice(0, -1) : match.url;
    trackEvent('pageView', {
      params: match.params,
      path: match.path,
      url,
    });
    // only when url change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.url, match.path]);

  return null;
}
