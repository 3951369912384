import React, { useCallback } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@bitmodern/bit-ui';
import { DeleteIcon } from '@bitmodern/bit-ui/icons';
import {
  ConfirmDialog,
  DescriptiveAction,
  IntegrationMappings,
  SettingsPanel,
} from 'src/components/organisms';
import vars from 'src/export.scss';
import { Integration, IntegrationProject } from '@testquality/sdk';
import { integrationProjectRemoveOne } from 'src/gen/domain/integration_project/integrationProjectSlice';
import { integrationProjectDetachThunk } from 'src/gen/domain/integration_project/integrationProjectThunk';
import { projectSelectors } from 'src/gen/domain/project/projectSelector';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { routes } from 'src/components/Router';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import useLocation from 'src/hooks/useLocation';
import { redirectForAuth } from '@bitmodern/services/integrationService';
import styles from './ProjectIntegrationEditView.module.scss';

type Props = {
  integrationProject?: IntegrationProject;
  integration?: Integration;
};

export default function ProjectIntegrationEditView({
  integrationProject,
  integration,
}: Props) {
  const { site, projectId } =
    useParams<typeof routes.PROJECT_INTEGRATION.params>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
    integration?.id,
  );
  const deleteDialog = useOverlayTriggerState({});
  const projects = useAppSelector(projectSelectors.selectAll);
  const project = projects.find((p) => p.id === integrationProject?.project_id);

  const onConfirmDelete = useCallback(() => {
    if (!integrationProject) return Promise.resolve();

    // todo: why are detach then removing?
    return dispatch(
      integrationProjectDetachThunk({ data: integrationProject }),
    ).then(() => {
      dispatch(integrationProjectRemoveOne(integrationProject.id));
      history.goBack();
    });
  }, [dispatch, history, integrationProject]);

  const deleteMutation = useMutation(onConfirmDelete);

  const onAuthorize = useCallback(() => {
    const integrationId = integration?.id;
    if (integrationId) {
      return redirectForAuth(integrationId, location.pathname);
    }
    return Promise.reject(new Error('Unknown integration'));
  }, [integration, location]);

  const authorizeMutation = useMutation(onAuthorize);

  const title = t('integrationProjectEdit.headEdit.title');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SettingsPanel back={routes.INTEGRATIONS({ site })} title={title}>
        {integrationProject && (
          <div className={styles.projectMappingInfo}>
            <h1 className={styles.projectTitle}>{project?.name}</h1> -
            <div className={styles.mapping}>
              {t('integrationSetup.mappedTo', {
                name: integrationProject?.project_reference_id,
              })}
            </div>
          </div>
        )}
        {integrationProject && (
          <>
            <IntegrationMappings integrationProject={integrationProject} />
            <DescriptiveAction
              action={
                <Button
                  className={styles.delete}
                  color="error"
                  icon={<DeleteIcon color={vars.onError} size={20} />}
                  onClick={deleteDialog.open}
                  size="small">
                  {t('integrationProjectEdit.delete.action')}
                </Button>
              }
              description={t('integrationProjectEdit.delete.description')}
              title={t('integrationProjectEdit.delete.title')}
            />
          </>
        )}
        {!isAuthenticated && (
          <DescriptiveAction
            action={
              <Button
                loading={authorizeMutation.isLoading}
                onClick={authorizeMutation.mutate}
                size="small">
                {t('integrationSetup.actions.authorize')}
              </Button>
            }
            description={t('integrationSetup.notAuthorized')}
            title={t('integrationSetup.authorization')}
          />
        )}
      </SettingsPanel>
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('integrationProjectEdit.deleteDialog.title')}>
        {t('integrationProjectEdit.deleteDialog.content')}
      </ConfirmDialog>
    </>
  );
}
