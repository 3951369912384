/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { caseTypeAdapter } from './caseTypeAdapter';

export const caseTypeSelectors = caseTypeAdapter.getSelectors(
  (state: State) => state.gen.caseType,
);
