import { useCallback, useEffect, useState } from 'react';

export default function useStateProp<P, C extends (newProp: P) => void>(
  prop: P,
  onChange?: C,
): [P, (newProp: P) => void] {
  const [propState, setPropState] = useState(prop);

  const onChangeHandler = useCallback(
    (newProp: P) => {
      setPropState(newProp);
      if (onChange) onChange(newProp);
    },
    [onChange],
  );

  useEffect(() => {
    setPropState(prop);
  }, [prop]);

  return [propState, onChangeHandler];
}
