import React, { ReactNode, useEffect } from 'react';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import './ReCaptcha.scss';

type ReCaptchaProviderProps = {
  children: ReactNode;
  siteKey: string;
};

function ReCaptchaProvider({ children, siteKey }: ReCaptchaProviderProps) {
  useEffect(() => {
    if (!siteKey) {
      throw new Error('<ReCaptchaProvider /> site key not provided');
    }
  }, [siteKey]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      {children}
    </GoogleReCaptchaProvider>
  );
}

export { ReCaptchaProvider, useGoogleReCaptcha as useReCaptcha };
