import { State } from '@bitmodern/redux/store';
import { projectSelectors } from 'src/gen/domain/project/projectSelector';

export function projectsSelector(state: State) {
  return projectSelectors.selectAll(state);
}

export function projectSelector(state: State, id: number) {
  return projectSelectors.selectById(state, id);
}

export function projectsIsFetching(state: State) {
  return state.gen.project.loading === 'pending';
}
