/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { BaseIntegration } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { baseIntegrationAdapter } from './baseIntegrationAdapter';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
  baseIntegrationDeleteOneThunk,
  baseIntegrationUpdateOneThunk,
  baseIntegrationCreateOneThunk,
} from './baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const baseIntegrationSlice = createSlice({
  name: 'baseIntegration',
  initialState: baseIntegrationAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    baseIntegrationCleared(state) {
      state.loading = 'idle';
      baseIntegrationAdapter.removeAll(state);
    },
    baseIntegrationUpsertMany(
      state,
      action: PayloadAction<
        BaseIntegration[] | Record<number, BaseIntegration>
      >,
    ) {
      baseIntegrationAdapter.upsertMany(state, action.payload);
    },
    baseIntegrationUpsertOne(state, action: PayloadAction<BaseIntegration>) {
      baseIntegrationAdapter.upsertOne(state, action.payload);
    },
    baseIntegrationUpdateOne(
      state,
      action: PayloadAction<Update<BaseIntegration>>,
    ) {
      baseIntegrationAdapter.updateOne(state, action.payload);
    },
    baseIntegrationRemoveOne(state, action: PayloadAction<number>) {
      baseIntegrationAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(baseIntegrationFetchManyThunk.pending, handlePending);
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseIntegration,
        );
      },
    );
    builder.addCase(baseIntegrationFetchManyThunk.rejected, handleRejected);
    builder.addCase(baseIntegrationFetchOneThunk.pending, handlePending);
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      baseIntegrationAdapter.upsertOne(
        state,
        action.payload.baseIntegration[0],
      );
    });
    builder.addCase(baseIntegrationFetchOneThunk.rejected, handleRejected);
    builder.addCase(baseIntegrationDeleteOneThunk.pending, handlePending);
    builder.addCase(
      baseIntegrationDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          baseIntegrationAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(baseIntegrationDeleteOneThunk.rejected, handleRejected);
    builder.addCase(baseIntegrationUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, baseIntegrationAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      baseIntegrationUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseIntegrationAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(baseIntegrationUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          baseIntegrationAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(baseIntegrationCreateOneThunk.pending, handlePending);
    builder.addCase(
      baseIntegrationCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseIntegrationAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(baseIntegrationCreateOneThunk.rejected, handleRejected);
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.baseIntegration) {
        baseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseIntegration,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.baseIntegration) {
        baseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseIntegration,
        );
      }
    });
  },
});

export const {
  baseIntegrationCleared,
  baseIntegrationUpsertMany,
  baseIntegrationUpsertOne,
  baseIntegrationUpdateOne,
  baseIntegrationRemoveOne,
} = baseIntegrationSlice.actions;

export const baseIntegrationReducer = baseIntegrationSlice.reducer;
