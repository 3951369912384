import filter from 'lodash/filter';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { State } from '@bitmodern/redux/store';
import { runSelectors } from 'src/gen/domain/run/runSelector';
import { Run, RunResult } from '@testquality/sdk';
import { RunFilter } from 'src/enums/RunFilterEnum';
import { StatusEnum } from 'src/enums/StatusEnum';

export function runsSelector(state: State) {
  return runSelectors.selectAll(state);
}

export function runSelector(state: State, runId: number) {
  return runSelectors.selectById(state, runId);
}

export const selectRunsByProjectId = createSelector(
  [
    runSelectors.selectEntities,
    (state: State, args: { projectId?: number }) => args,
  ],
  (runs, { projectId }) => {
    if (!projectId) return [];

    const toReturn: Run[] = [];
    for (const key in runs) {
      const run = runs[key];
      if (run && run?.project_id === projectId) {
        toReturn.push(run);
      }
    }

    return toReturn;
  },
);

export const selectRunsByPlanId = createSelector(
  [
    (state: State) => state.gen.run.entities,
    (state: State, planId: number) => planId,
  ],
  (entities, planId) => {
    const list = (filter(entities, (run) => run?.plan_id === planId) ||
      []) as Run[];
    return list;
  },
);

type RunsByFilterParams = {
  projectId?: number;
  runFilter?: RunFilter;
  search?: string;
};
export const selectRunsByFilter = createSelector(
  [runSelectors.selectEntities, (_, params: RunsByFilterParams) => params],
  (runEntities, { projectId, runFilter, search }) => {
    let runs: Run[] = [];
    if (!projectId) return runs;

    for (const id in runEntities) {
      const run = runEntities[id];
      if (run && run.project_id === projectId) runs.push(run);
    }

    runs = [...runs].sort(
      (a, b) =>
        new Date(b.start_time || '').getTime() -
        new Date(a.start_time || '').getTime(),
    );

    if (!runFilter && !search) return runs;

    if (runFilter !== RunFilter.All) {
      runs = runs.filter((run) => {
        if (runFilter === RunFilter.Running && run.is_running) {
          return true;
        }
        if (runFilter === RunFilter.Completed && run.is_complete) {
          return true;
        }
        if (runFilter === RunFilter.Paused && !run.is_running) {
          return true;
        }
        return false;
      });
    }

    if (search) {
      const lowerCaseSearch = search?.toLowerCase().trim();
      runs = runs.filter((run) =>
        run.name.toLowerCase().includes(lowerCaseSearch),
      );
    }

    return runs;
  },
);

export const selectLastRun = createSelector(
  [
    (state: State) => state.gen.run.entities,
    (state: State, projectId: number) => projectId,
  ],
  (entities, projectId) => {
    const list = (filter(entities, (run) => run?.project_id === projectId) ||
      []) as Run[];
    list.sort(
      (a, b) =>
        new Date(b.start_time || '').getTime() -
        new Date(a.start_time || '').getTime(),
    );
    return list[0];
  },
);

export interface Range {
  start?: Date | null;
  end?: Date | null;
  projectId: number;
  milestoneId?: number;
}

export const selectRunsByRange = createSelector(
  [
    (state: State) => state.gen.run.entities,
    (state: State, range: Range) => range,
  ],
  (entities, { start, projectId, end, milestoneId }) => {
    const list = (filter(entities, (run) => {
      if (!run) {
        return false;
      }
      if (milestoneId) {
        return run.project_id === projectId && run.milestone_id === milestoneId;
      }
      return run.project_id === projectId;
    }) || []) as Run[];
    return list
      .filter((run) => {
        if (!start && !end) return true;
        const date = run.start_time ? new Date(run.start_time).getTime() : 0;

        if (start && end) {
          return date >= start.getTime() && date <= end.getTime();
        }
        if (start) {
          return date >= start.getTime();
        }
        if (end) {
          return date <= end.getTime();
        }
        return true;
      })
      .sort(
        (a, b) =>
          new Date(b.end_time || '').getTime() -
          new Date(a.end_time || '').getTime(),
      );
  },
);

export const selectCanCompleteRun = createSelector(
  [
    (state: State) => state.gen.run.entities,
    (state: State, runId: number) => runId,
    (state: State) => state.gen.runResult.entities,
    (state: State) => state.gen.status.entities,
  ],
  (run, runId, runResult, status) => {
    const aRun = run[runId];
    if (!aRun) {
      return false;
    }
    const runResults = (filter(runResult, (rs) => rs?.run_id === runId) ||
      []) as RunResult[];
    const pending = find(status, (s) => s?.key === StatusEnum.Pending);
    if (!pending) {
      return false;
    }

    const rsPending = runResults.filter((rs) => rs?.status_id === pending.id);
    return !run.is_complete && !rsPending.length;
  },
);
