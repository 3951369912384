/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React, { ReactNode, useRef } from 'react';
import { useTextField, AriaTextFieldOptions, mergeProps } from 'react-aria';
import classnames from 'classnames';
import mergeRefs from 'react-merge-refs';
import { useFocusAccent } from '@bitmodern/bit-ui';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';
import styles from './TextArea.module.scss';

type InputProps = AriaTextFieldOptions<'textarea'> & {
  className?: string;
  error?: ReactNode;
  focused?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  showLabel?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  (
    {
      className = '',
      error,
      focused,
      fullHeight = false,
      fullWidth = false,
      onChange,
      required,
      showLabel = true,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const { label } = rest;

    const { borderClassName, borderProps } = useFocusAccent({
      isFocused: focused,
    });

    const innerRef = useRef<HTMLTextAreaElement>(null);
    const { labelProps, inputProps } = useTextField(
      {
        ...rest,
        inputElementType: 'textarea',
      },
      innerRef,
    );

    const inputCN = classnames(styles.input, borderClassName, className, {
      [styles.fullwidth]: fullWidth,
      [styles.fullHeight]: fullHeight,
    });

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
    ) => {
      if (inputProps.onChange) {
        inputProps.onChange(event);
      }
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <>
        {showLabel && (
          <FieldLabel required={required} {...labelProps}>
            {label}
          </FieldLabel>
        )}
        <textarea
          {...mergeProps(borderProps, inputProps)}
          onChange={handleChange}
          className={inputCN}
          ref={mergeRefs([innerRef, ref])}
        />
        {error && <FieldError className={styles.error}>{error}</FieldError>}
      </>
    );
  },
);

export default TextArea;
