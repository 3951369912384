/**
 * Copyright (C) 2020 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { PersistentStorage } from '@testquality/sdk';

export class LocalStorageHelper implements PersistentStorage {
  private readonly isSession;

  constructor(isSession = false) {
    this.isSession = isSession;
  }

  public set<T>(property: string, value: T, that: any = this) {
    const storage = this.isSession ? sessionStorage : localStorage;
    if (storage) {
      if (value !== undefined) {
        storage.setItem(property, JSON.stringify(value));
      } else {
        storage.removeItem(property);
      }
    }
    if (that[property] !== value) {
      that[property] = value; // eslint-disable-line
    }
  }

  public get<T>(property: string, defaultVal?: T, that: any = this): T {
    if (that[property] === undefined) {
      const storage = this.isSession ? sessionStorage : localStorage;
      const value = storage ? storage.getItem(property) : undefined;
      // console.log('storage: ' + property, value);
      if (value !== undefined && value !== null) {
        that[property] = JSON.parse(value); // eslint-disable-line
      } else if (defaultVal !== undefined) {
        that[property] = defaultVal; // eslint-disable-line
      }
    }
    return that[property];
  }
}
