/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { labelAssignedAdapter } from './labelAssignedAdapter';

export const labelAssignedSelectors = labelAssignedAdapter.getSelectors(
  (state: State) => state.gen.labelAssigned,
);
