import React, {
  ComponentProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import Trigger from 'rc-trigger';
import 'rc-trigger/assets/index.less';
import style from './Dropdown.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  classNameOverlay?: string;
  disabled?: boolean;
  closeOnClick?: boolean;
  onChangeVisible?: (visible: boolean) => void;
  overlay: any;
  mask?: boolean;
  stretch?: 'width' | 'minWidth' | 'height' | 'minHeight';
  visible?: boolean;
} & Pick<ComponentProps<typeof Trigger>, 'popupAlign'>;

export default function Dropdown({
  children,
  className = '',
  classNameOverlay = '',
  disabled = false,
  closeOnClick = true,
  onChangeVisible,
  overlay,
  mask = true,
  popupAlign = {},
  stretch,
  visible = false,
}: Props) {
  const [isVisible, setVisible] = useState(visible);
  const child = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const onPopupVisibleChange = (value) => {
    setVisible(value);
    onChangeVisible?.(value);
  };

  const onClickOverlay = () => {
    if (closeOnClick) {
      setVisible(false);
      onChangeVisible?.(false);
    }
  };

  const onClickCapture = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setVisible((s) => {
      onChangeVisible?.(!s);
      return !s;
    });
  };

  const defaultPopupAlign = {
    points: ['tl', 'bl'],
    overflow: { adjustX: true, adjustY: true },
    ...popupAlign,
  };

  return (
    <Trigger
      action={['click']}
      popup={
        <div
          className={`${style.popup} ${classNameOverlay}`}
          onClick={onClickOverlay}>
          {overlay}
        </div>
      }
      mask={mask}
      maskClosable
      popupAlign={defaultPopupAlign}
      stretch={stretch}
      popupVisible={disabled ? false : isVisible}
      onPopupVisibleChange={onPopupVisibleChange}
      maskTransitionName="bui-dropdown"
      zIndex={100}>
      <div className={className} onClickCapture={onClickCapture} ref={child}>
        {children}
      </div>
    </Trigger>
  );
}
