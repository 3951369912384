import {
  getResponse,
  QueryParams,
  PlanRoute,
  ProjectPlanRoute,
  planGetMany,
} from '@testquality/sdk';
import { MappingType } from 'src/components/organisms/ImportDataForm/MappingTypes';
import { getClient } from 'src/Client';

export function getPlansByProjectParams(projectId: number, batch?) {
  return {
    batch,
    url: ProjectPlanRoute(projectId),
    params: { per_page: -1, _with: 'purpose,suite,suite.test,suite.test.step' },
  };
}

export function getPlansByProject(projectId: number) {
  return planGetMany(getPlansByProjectParams(projectId)).then(
    (resourceList) => resourceList.data,
  );
}

export function postJunitXML(
  files: File[],
  planId: number,
  milestoneId?: number,
) {
  const data = new FormData();
  Object.keys(files).forEach((key) => {
    data.append(`files[${key}]`, files[key]);
  });
  if (milestoneId) {
    data.append('milestone_id', milestoneId.toString());
  }
  const config: QueryParams<any> = {
    method: 'post',
    url: `${PlanRoute()}/${planId}/junit_xml`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return getResponse<any>(getClient().api, config);
}

export function postGherkinFiles(file: File, projectId: string) {
  const data = new FormData();
  data.append('project_id', projectId);
  data.append('file', file);
  const config: QueryParams<any> = {
    method: 'post',
    url: `/import_feature`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return getResponse<any>(getClient().api, config);
}

export function postCSVFiles(
  file: File,
  mappings: MappingType[],
  extraValues: {
    delimiter: string;
    useUtf8Encode: boolean;
    project_id?: number;
  },
  encoding: string,
  extraFields: { project: number; plan: number; suite: number },
) {
  const data = new FormData();
  data.append('file', file);
  Object.entries(extraValues).forEach(([key, value]) => {
    if (value !== undefined) {
      data.append(
        `config[${key}]`,
        typeof value !== 'string' ? value.toString() : value,
      );
    }
  });

  mappings.forEach((mapping, index) => {
    if (typeof mapping === 'object') {
      Object.entries(mapping).forEach(([key, value]) => {
        if (typeof value === 'object') {
          Object.entries(value).forEach(([keyM, valueM]) => {
            if (typeof valueM === 'object') {
              Object.entries(valueM).forEach(
                async ([keyCustom, valueCustom]) => {
                  if (typeof valueCustom === 'object') {
                    await addDataOptions(
                      valueCustom,
                      data,
                      index,
                      key,
                      keyM,
                      keyCustom,
                    );
                  } else {
                    data.append(
                      `config[mappings][${index}][${key}][${keyM}][${keyCustom}]`,
                      `${valueCustom}`,
                    );
                  }
                },
              );
            } else {
              data.append(
                `config[mappings][${index}][${key}][${keyM}]`,
                typeof valueM === 'number' ? valueM.toString() : valueM,
              );
            }
          });
        } else {
          data.append(
            `config[mappings][${index}][${key}]`,
            (value && value.toString()) || '',
          );
        }
      });
    }
  });
  const hasRun = mappings.find((mapping) => {
    return (
      mapping.include &&
      mapping.to?.entity === 'run' &&
      mapping.to?.name === 'name'
    );
  });

  data.append(
    'config[importDataType][id]',
    hasRun ? 'Run Results' : 'Tests Cases',
  );
  if (encoding !== undefined) {
    data.append(`config[encoding]`, encoding);
  }
  Object.entries(extraFields).forEach(([key, value]) =>
    data.append(`config[${key}]`, value.toString()),
  );

  return getResponse<any>(getClient().api, {
    method: 'post',
    url: `/import_data`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

const addDataOptions = async (
  valueCustom,
  data,
  index,
  key,
  keyM,
  keyCustom,
) => {
  data.append(`config[mappings][${index}][showData]`, true);
  await Object.entries(valueCustom).forEach(([keyCustomTo, valueCustomTo]) => {
    data.append(
      `config[mappings][${index}][${key}][${keyM}][${keyCustom}][${keyCustomTo}]`,
      typeof valueCustomTo === 'number'
        ? valueCustomTo.toString()
        : valueCustomTo,
    );
  });
};

export function postGherkinRunResultFiles({
  files,
  planId,
  projectId,
  milestoneId,
}: {
  files: File[];
  planId: number;
  projectId?: string;
  milestoneId?: number;
}) {
  const data = new FormData();
  Object.keys(files).forEach((key) => {
    data.append(`files[${key}]`, files[key]);
  });
  if (projectId) {
    data.append('project_id', projectId);
  }
  if (milestoneId) {
    data.append('milestone_id', milestoneId.toString());
  }
  const config: QueryParams<any> = {
    method: 'post',
    url: `${PlanRoute()}/${planId}/import_feature_results`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return getResponse<any>(getClient().api, config);
}
