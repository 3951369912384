/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AppConfigHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appConfigHistoryAdapter } from './appConfigHistoryAdapter';
import { appConfigHistoryFetchThunk } from './appConfigHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appConfigHistorySlice = createSlice({
  name: 'appConfigHistory',
  initialState: appConfigHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appConfigHistoryCleared(state) {
      state.loading = 'idle';
      appConfigHistoryAdapter.removeAll(state);
    },
    appConfigHistoryUpsertMany(
      state,
      action: PayloadAction<
        AppConfigHistory[] | Record<number, AppConfigHistory>
      >,
    ) {
      appConfigHistoryAdapter.upsertMany(state, action.payload);
    },
    appConfigHistoryUpsertOne(state, action: PayloadAction<AppConfigHistory>) {
      appConfigHistoryAdapter.upsertOne(state, action.payload);
    },
    appConfigHistoryUpdateOne(
      state,
      action: PayloadAction<Update<AppConfigHistory>>,
    ) {
      appConfigHistoryAdapter.updateOne(state, action.payload);
    },
    appConfigHistoryRemoveOne(state, action: PayloadAction<number>) {
      appConfigHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appConfigHistoryFetchThunk.pending, handlePending);
    builder.addCase(appConfigHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appConfigHistoryAdapter.upsertMany(
        state,
        action.payload.appConfigHistory,
      );
    });
    builder.addCase(appConfigHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  appConfigHistoryCleared,
  appConfigHistoryUpsertMany,
  appConfigHistoryUpsertOne,
  appConfigHistoryUpdateOne,
  appConfigHistoryRemoveOne,
} = appConfigHistorySlice.actions;

export const appConfigHistoryReducer = appConfigHistorySlice.reducer;
