/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PlatVersionHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { platVersionHistoryAdapter } from './platVersionHistoryAdapter';
import { platVersionHistoryFetchThunk } from './platVersionHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const platVersionHistorySlice = createSlice({
  name: 'platVersionHistory',
  initialState: platVersionHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    platVersionHistoryCleared(state) {
      state.loading = 'idle';
      platVersionHistoryAdapter.removeAll(state);
    },
    platVersionHistoryUpsertMany(
      state,
      action: PayloadAction<
        PlatVersionHistory[] | Record<number, PlatVersionHistory>
      >,
    ) {
      platVersionHistoryAdapter.upsertMany(state, action.payload);
    },
    platVersionHistoryUpsertOne(
      state,
      action: PayloadAction<PlatVersionHistory>,
    ) {
      platVersionHistoryAdapter.upsertOne(state, action.payload);
    },
    platVersionHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PlatVersionHistory>>,
    ) {
      platVersionHistoryAdapter.updateOne(state, action.payload);
    },
    platVersionHistoryRemoveOne(state, action: PayloadAction<number>) {
      platVersionHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(platVersionHistoryFetchThunk.pending, handlePending);
    builder.addCase(platVersionHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platVersionHistoryAdapter.upsertMany(
        state,
        action.payload.platVersionHistory,
      );
    });
    builder.addCase(platVersionHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  platVersionHistoryCleared,
  platVersionHistoryUpsertMany,
  platVersionHistoryUpsertOne,
  platVersionHistoryUpdateOne,
  platVersionHistoryRemoveOne,
} = platVersionHistorySlice.actions;

export const platVersionHistoryReducer = platVersionHistorySlice.reducer;
