import React from 'react';
import { FormikProps } from 'formik';
import {
  Avatar,
  Button,
  DialogContent,
  EasyMDEEditor,
  Grid,
  Input,
  PanelActions,
  Select,
  SelectMultiple,
} from '@bitmodern/bit-ui';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'src/components/Router';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import {
  Attachment,
  DefectComponent,
  DefectLabel,
  DefectPriority,
  DefectUser,
  DefectType,
  Integration,
} from '@testquality/sdk';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { UserSelect } from 'src/components/organisms';
import styles from './Step2.module.scss';
import { processAttachmentPath } from '../../../utils/fileHelper';

type Props = {
  defectComponents: DefectComponent[];
  defectLabels: DefectLabel[];
  defectPriorities: DefectPriority[];
  defectUsers: DefectUser[];
  defectTypes: DefectType[];
  formik: FormikProps<{
    components: Array<{ [key: string]: any }>;
    assignee: { [key: string]: any };
    attachments: Attachment[];
    description: string;
    labels: Array<{ [key: string]: any }>;
    priority: { [key: string]: any };
    type: { [key: string]: any };
    summary: string;
    integrationProjectId: number;
  }>;
  integration?: Integration;
  isJira: boolean;
  isLoading: boolean;
  projectId: number;
  isLinking: boolean;
};

export default function Step2({
  defectComponents,
  defectLabels,
  defectPriorities,
  defectUsers,
  defectTypes,
  formik,
  integration,
  isJira,
  isLoading,
  projectId,
  isLinking,
}: Props) {
  const { t } = useTranslation();

  const { site } = useParams<typeof routes.REQUIREMENT_CREATE.params>();

  const currentUser = useAppSelector(currentUserSelector);
  const onChangePriority = (value) => {
    const priority = {
      id: value,
      name: defectPriorities?.find(({ id }) => id === value)?.name,
    };
    formik.setFieldValue('priority', priority);
  };

  const onChangeAssignedTo = (value) => {
    formik.setFieldValue(
      'assignee',
      defectUsers?.find(({ id }) => id === value),
    );
  };

  const onChangeType = (value) => {
    formik.setFieldValue(
      'type',
      defectTypes?.find(({ id }) => id === value),
    );
  };

  const onChangeLabels = (values) => {
    formik.setFieldValue(
      'labels',
      values.map((value) => {
        return {
          value,
          label: value,
          id: defectLabels.find(({ name }) => name === value)?.id,
          isTag: true,
        };
      }),
    );
  };

  const onChangeComponent = (values) => {
    formik.setFieldValue(
      'components',
      values.map((value) => ({
        value,
        label: defectComponents.find(({ id }) => id === value)?.name,
      })),
    );
  };

  const onChageDescription = (value) => {
    formik.setFieldValue('description', value);
  };

  const onFileUpload = (attachment: Attachment) => {
    const newAttachments: Attachment[] = formik.values.attachments.slice();
    newAttachments.push(attachment);
    formik.setFieldValue('attachments', newAttachments);
  };

  const priorityOptions = defectPriorities.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const componentsOptions = defectComponents.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const userOptions = defectUsers.map(({ id, name, email, avatar_url }) => ({
    label: name || email,
    value: id,
    picture: avatar_url,
  }));

  const typeOptions = defectTypes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const reporterName = currentUser?.given_name
    ? currentUser.given_name
    : currentUser?.email;

  const labelsOptions = defectLabels.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const labelValues = formik.values.labels.map(({ value }) => value);
  const componentsValues = formik.values.components.map(({ value }) => value);
  const priorityValue = formik.values.priority?.id;
  const typeValue = formik.values.type?.id;

  return (
    <form
      className={styles.form}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}>
      <DialogContent className={styles.dialogContent}>
        <Input
          error={formikError(formik, 'summary')}
          fullWidth
          label={t('defectCreate.form.summary')}
          name="summary"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          value={formik.values.summary}
        />
        <div className={styles.label}>{t('defectCreate.form.description')}</div>
        <EasyMDEEditor
          onChange={onChageDescription}
          onFileUpload={onFileUpload}
          parentId={projectId}
          parentType="Project"
          value={formik.values.description}
          withFormatting={!isJira}
        />
        <Grid.Row>
          <Grid.Col span={8}>
            <div className={`${styles.reporter} ${styles.reporter}`}>
              <div className={styles.reporterName}>
                {t('defectCreate.form.reporter')}:
              </div>
              <Avatar
                className={styles.reporterIcon}
                name={currentUser?.given_name}
                size={20}
                src={processAttachmentPath(currentUser?.picture)}
              />
              {reporterName}
            </div>
          </Grid.Col>
          <Grid.Col span={4}>
            <Link
              className={styles.templates}
              to={{
                pathname: routes.INTEGRATION_SETUP({
                  site,
                  baseIntegrationId: String(integration?.base_integration_id),
                }),
                state: {
                  openTemplatesDialog: true,
                },
              }}>
              {t('defectCreate.form.manageTemplates')}
            </Link>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={7}>
            {isJira && (
              <>
                <Select
                  label={t('defectCreate.form.type')}
                  onChange={onChangeType}
                  options={typeOptions}
                  loading={isLoading}
                  placeholder="Select Type..."
                  value={typeValue}
                />
                <SelectMultiple
                  label={t('defectCreate.form.component')}
                  onChange={onChangeComponent}
                  loading={isLoading}
                  options={componentsOptions}
                  placeholder={t('defectCreate.componentPlaceholder')}
                  values={componentsValues}
                />
              </>
            )}
            <SelectMultiple
              label={t('defectCreate.form.label')}
              onChange={onChangeLabels}
              options={labelsOptions}
              placeholder={t('defectCreate.labelsPlaceholder')}
              loading={isLoading}
              tags
              values={labelValues}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <Select
              label={t('defectCreate.form.priority')}
              onChange={onChangePriority}
              options={priorityOptions}
              loading={isLoading}
              placeholder="Select Priority..."
              value={priorityValue}
            />
            <UserSelect
              label={t('defectCreate.form.assignee')}
              loading={isLoading}
              onChange={onChangeAssignedTo}
              options={userOptions}
              placeholder="Select User..."
              value={formik.values.assignee?.id}
            />
          </Grid.Col>
        </Grid.Row>
      </DialogContent>
      <PanelActions>
        <Button
          disabled={isLoading || !formik.isValid || isLinking}
          loading={isLinking}
          type="submit">
          {t('defectCreate.form.submit')}
        </Button>
      </PanelActions>
    </form>
  );
}
