/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import {
  AsyncThunk,
  AsyncThunkPayloadCreator,
  configureStore,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useDispatch, useSelector } from 'react-redux';
import { ReturnToken } from '@testquality/sdk';
import { getEnv } from 'src/env';
import rootReducer from './RootReducer';
import { ExtraArguments } from './ExtraArguments';
import { LocalStorageHelper } from 'src/common/localstorage-helper';
import { getClient } from 'src/Client';

export type State = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  State,
  ExtraArguments,
  Action<string>
>;

type AsyncThunkConfig = {
  dispatch: AppDispatch;
  state: State;
};

export function createAppThunk<Returned, ThunkArg = void>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    AsyncThunkConfig
  >,
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> {
  return createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    type,
    payloadCreator,
  );
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export function useAppSelector<TSelected = unknown>(
  selector: (state: State) => TSelected,
): TSelected {
  return useSelector<State, TSelected>(selector);
}

function resetableReducer(state: State | undefined, action): State {
  if (action.type === 'RESET_STATE') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
}

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['theme'],
};
const persistedReducer = persistReducer(rootPersistConfig, resetableReducer);

const defaultState = resetableReducer(undefined, {});
const localStorageHelper = new LocalStorageHelper();
const sessionStorageHelper = new LocalStorageHelper(true);
const token =
  (localStorageHelper.get('token') as ReturnToken) ||
  (sessionStorageHelper.get('token') as ReturnToken);
const color: 'light' | 'dark' =
  document.documentElement.getAttribute('data-theme') === 'light'
    ? 'light'
    : 'dark';

const preloadedState = {
  authentication: {
    ...defaultState.authentication,
    token,
  },
  theme: { color },
};

const store = configureStore({
  reducer: persistedReducer,
  devTools: getEnv().isDevelopment,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: {
        extraArgument: {
          client: getClient(),
        },
      },
    }),
});

export { store };
