/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Client } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { clientAdapter } from './clientAdapter';
import {
  clientFetchManyThunk,
  clientFetchOneThunk,
  clientDeleteOneThunk,
  clientUpdateOneThunk,
  clientCreateOneThunk,
} from './clientThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const clientSlice = createSlice({
  name: 'client',
  initialState: clientAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    clientCleared(state) {
      state.loading = 'idle';
      clientAdapter.removeAll(state);
    },
    clientUpsertMany(
      state,
      action: PayloadAction<Client[] | Record<number, Client>>,
    ) {
      clientAdapter.upsertMany(state, action.payload);
    },
    clientUpsertOne(state, action: PayloadAction<Client>) {
      clientAdapter.upsertOne(state, action.payload);
    },
    clientUpdateOne(state, action: PayloadAction<Update<Client>>) {
      clientAdapter.updateOne(state, action.payload);
    },
    clientRemoveOne(state, action: PayloadAction<number>) {
      clientAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clientFetchManyThunk.pending, handlePending);
    builder.addCase(clientFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      clientAdapter.upsertMany(state, action.payload.client);
    });
    builder.addCase(clientFetchManyThunk.rejected, handleRejected);
    builder.addCase(clientFetchOneThunk.pending, handlePending);
    builder.addCase(clientFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      clientAdapter.upsertOne(state, action.payload.client[0]);
    });
    builder.addCase(clientFetchOneThunk.rejected, handleRejected);
    builder.addCase(clientDeleteOneThunk.pending, handlePending);
    builder.addCase(clientDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        clientAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(clientDeleteOneThunk.rejected, handleRejected);
    builder.addCase(clientUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, clientAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(clientUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      clientAdapter.updateOne(state, action.payload);
    });
    builder.addCase(clientUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          clientAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(clientCreateOneThunk.pending, handlePending);
    builder.addCase(clientCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      clientAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(clientCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  clientCleared,
  clientUpsertMany,
  clientUpsertOne,
  clientUpdateOne,
  clientRemoveOne,
} = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
