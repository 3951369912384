import { Cache } from './Cache';

export default class InMemoryCache implements Cache {
  fetchedKeys = {};

  async get(key) {
    return this.fetchedKeys[key];
  }

  async set(key, value) {
    this.fetchedKeys[key] = value;
  }

  async delete(key) {
    delete this.fetchedKeys[key];
  }

  async clear() {
    this.fetchedKeys = {};
  }
}
