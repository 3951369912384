import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/i18n/hooks';
import { Dialog, DialogContent } from '@bitmodern/bit-ui';
import useModalManager from 'src/hooks/useModalManager';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { routes } from 'src/components/Router';
import { Project } from '@testquality/sdk';
import styles from './ImportDataDialog.module.scss';
import Automations from '../Automations';
import Imports from '../ImportDataForm/Imports';

export default function ImportDataDialog() {
  const { t } = useTranslation();
  const { hideModal } = useModalManager();
  const [warnings] = useState<string[]>([]);
  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const [projects] = useState<Project[]>([]);

  let projectList = <></>;
  if (
    projects.length > 0 &&
    (projects.length !== 1 ||
      projectId === undefined ||
      projects[0].id !== parseInt(projectId, 10))
  ) {
    projectList = (
      <>
        <div className={styles.container}>
          <div className={styles.title}>
            {t('importDataForm.projects.title')}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.description}>
            {projects.map((project) => (
              <p className={styles.alert} key={project.id}>
                <Link
                  to={routes.PROJECT_TESTS({
                    site,
                    projectId: project.id.toString(),
                  })}>
                  {project.name}
                </Link>
              </p>
            ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="large"
      title={t('importDataForm.title')}>
      <DialogContent>
        <div className={styles.container}>
          <div className={styles.content}>
            <div>
              <div className={styles.title}>
                {t('importDataForm.automations')}
              </div>
              <Automations />
              <div className={styles.title}>{t('importDataForm.imports')}</div>
              <Imports />
            </div>
          </div>
        </div>
        {warnings.length > 0 && (
          <>
            <div className={styles.container}>
              <div className={styles.title}>
                <span> {t('importDataForm.warning.title')}</span>
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.description}>
                {warnings.map((warning) => {
                  return (
                    <p className={styles.alert} key={warning}>
                      {warning}
                    </p>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {projectList}
      </DialogContent>
    </Dialog>
  );
}
