import React, { useCallback } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Grid, PanelHeader, SelectMultiple } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import Requirements from 'src/components/organisms/Requirements';
import { useMaker } from 'src/packages/redux/hooks';
import { makeRequirementsByPlan } from '@bitmodern/redux/state/requirements/selectors';
import { labelCreateOneThunk } from 'src/gen/domain/label/labelThunk';
import {
  labelAssignedCreateOneThunk,
  labelAssignedDeleteOneThunk,
} from 'src/gen/domain/label_assigned/labelAssignedThunk';
import {
  Label,
  Plan as PlanModel,
  PlanApi,
  BatchService,
} from '@testquality/sdk';
import { labelAssignedByPlanSelector } from '@bitmodern/redux/state/label_assigned/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { purposeSelectors } from 'src/gen/domain/purpose/purposeSelector';
import {
  planPurposeCreateOneThunk,
  planPurposeDetachThunk,
} from 'src/gen/domain/plan_purpose/planPurposeThunk';
import InputDirectInlineEdit from '@bitmodern/bit-ui/InlineEdit/InputDirectInlineEdit';
import { planUpdateOneThunk } from 'src/gen/domain/plan/planThunk';
import { planPurposesByPlanSelector } from '@bitmodern/redux/state/plan_purpose/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import CustomProperties from 'src/components/organisms/CustomProperties/CustomProperties';
import { UserSelect } from 'src/components/organisms';
import useMutation from 'src/hooks/useMutation';
import styles from './PlanOverviewView.module.scss';
import { getUsersMap } from '../../../utils/fileHelper';

type Props = {
  plan: PlanModel;
};

export default function PlanOverviewView({ plan }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { planId } = useParams<typeof routes.PLAN_OVERVIEW.params>();
  const users = useAppSelector(usersSelector);
  const purposes = useAppSelector(purposeSelectors.selectAll);
  const planPurposes = useAppSelector((state) =>
    planPurposesByPlanSelector(state, { planId: parseInt(planId, 10) }),
  );
  const labels = useAppSelector(labelsSelector);
  const planLabels = useAppSelector((state) =>
    labelAssignedByPlanSelector(state, { planId: parseInt(planId, 10) }),
  );

  const requirements = useMaker(makeRequirementsByPlan, {
    planId: parseInt(planId, 10),
  });

  const assignedToOptions = getUsersMap(users);

  const purposeOptions = purposes.map((purpose) => ({
    value: purpose.id,
    label: purpose.name,
  }));

  const labelOptions = labels.map((label) => ({
    value: label.id,
    label: label.label,
  }));

  const purposeIds = planPurposes.map((pp) => pp.purpose_id!);
  const labelIds = planLabels.map((label) => label.label_id);

  const onChangeAssignedTo = (value) => {
    updatePlan({ assigned_to_tester: value === '' ? null : value });
  };

  const onChangeRetainRuns = (value) => {
    return updatePlan({ retain_runs: value });
  };

  const onChangePurpose = useCallback(
    (values: number[]) => {
      const batch = new BatchService();

      purposeIds.forEach((id) => {
        if (!values.includes(id)) {
          const item = planPurposes.find(
            (planPurpose) => planPurpose.purpose_id === id,
          );
          if (item) {
            dispatch(planPurposeDetachThunk({ data: item, batch }));
          }
        }
      });

      // add items
      values.forEach((value) => {
        if (!purposeIds.includes(value)) {
          dispatch(
            planPurposeCreateOneThunk({
              data: {
                plan_id: parseInt(planId, 10),
                purpose_id: value,
              },
              batch,
            }),
          );
        }
      });
      return batch.executeBatch();
    },
    [dispatch, planPurposes, planId, purposeIds],
  );
  const changePurposeMutation = useMutation(onChangePurpose);

  const onChangeLabels = useCallback(
    (values) => {
      const batch = new BatchService();

      // remove items
      const newValues = labelIds.slice();
      newValues.forEach((id) => {
        if (!values.includes(id)) {
          const item = planLabels.find(
            (labelAssigned) => labelAssigned.label_id === id,
          );
          if (item) {
            dispatch(
              labelAssignedDeleteOneThunk({
                id: item.id,
                batch,
              }),
            );
          }
        }
      });
      // add items
      values.forEach((value) => {
        if (!newValues.includes(value)) {
          if (typeof value === 'string') {
            // Add new label first
            dispatch(
              labelCreateOneThunk({
                data: { label: value },
                batch,
              }),
            ).then((resp: any) => {
              dispatch(
                labelAssignedCreateOneThunk({
                  data: {
                    related_id: parseInt(planId, 10),
                    related_type: RelatedType.PLAN,
                    label_id: (resp.payload as Label).id,
                  },
                  batch,
                }),
              );
            });
          } else {
            dispatch(
              labelAssignedCreateOneThunk({
                data: {
                  related_id: parseInt(planId, 10),
                  related_type: RelatedType.PLAN,
                  label_id: value,
                },
                batch,
              }),
            );
          }
        }
      });

      return batch.executeBatch();
    },
    [dispatch, labelIds, planLabels, planId],
  );
  const changeLabelMutation = useMutation(onChangeLabels);

  const onChangeVirtual = (virtual) => {
    updatePlan({ virtual });
  };

  const updatePlan = (value: Partial<PlanModel> | Partial<PlanApi>) => {
    return dispatch(
      planUpdateOneThunk({ data: value, id: plan.id, optimistic: true }),
    );
  };

  return (
    <div className={styles.content}>
      <Grid.Row className={styles.grid} gutter={0}>
        <Grid.Col span={5}>
          <div className={styles.fieldsContent}>
            <SelectMultiple
              label={t('plans.overview.header.purpose')}
              loading={changePurposeMutation.isLoading}
              onChange={changePurposeMutation.mutate}
              options={purposeOptions}
              placeholder={t('plans.overview.purposesPlaceholder')}
              values={purposeIds}
            />

            <SelectMultiple
              label={t('plans.overview.header.labels')}
              loading={changeLabelMutation.isLoading}
              onChange={changeLabelMutation.mutate}
              options={labelOptions}
              placeholder={t('plans.overview.labelsPlaceholder')}
              tags
              values={labelIds}
            />
            <UserSelect
              label={t('plans.overview.header.assignedTo')}
              placeholder="Select"
              allowClear
              onChange={onChangeAssignedTo}
              options={assignedToOptions}
              value={plan.assigned_to_tester}
            />
            <InputDirectInlineEdit
              label={t('plans.overview.header.retainRuns')}
              className={styles.retainRuns}
              onCommit={onChangeRetainRuns}
              value={plan.retain_runs.toString()}
            />
            <h3 className={styles.title}>
              {t('plans.overview.header.customProperties')}
            </h3>
            <CustomProperties onChange={onChangeVirtual} model={plan} />
          </div>
        </Grid.Col>
        <Grid.Col className={styles.requirements} span={7}>
          <PanelHeader title={t('requirements.title')} />
          <div className={styles.requirementList}>
            <Requirements requirements={requirements} />
          </div>
        </Grid.Col>
      </Grid.Row>
    </div>
  );
}
