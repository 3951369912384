/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { LabelApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { labelAssignedNormalizer } from '../label_assigned/labelAssignedNormalize';

export const labelNormalizer = (model: LabelApi, cache: NormalizerCache) => {
  if (!cache.label) {
    cache.label = [];
  }
  cache.label.push(model);

  if (model.label_assigned && model.label_assigned.length) {
    model.label_assigned.forEach((child) => {
      labelAssignedNormalizer(child, cache);
    });
    model.label_assigned = undefined;
  }
};
