import React, { useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format, parse } from 'date-fns';
import { Test } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { DateFormat } from 'src/enums/DateFormatEnum';
import { DateRange } from 'src/common/DataRange';
import vars from 'src/export.scss';

type Props = {
  range: DateRange;
  tests: Test[];
};

function GrowthChart({ tests, range }: Props) {
  const { t } = useTranslation();
  const testsBreakdwn = useMemo(() => breakdownTestsData(tests), [tests]);
  if (!testsBreakdwn) return null;

  const testsData = chartData(
    t('analyze.testGrowth.chart.tests'),
    testsBreakdwn.tests,
    range,
  );
  const manualData = chartData(
    t('analyze.testGrowth.chart.manualTests'),
    testsBreakdwn.manualTests,
    range,
  );

  const dateMin = format(range[0], DateFormat.Day);
  const dateMax = format(range[1], DateFormat.Day);

  return (
    <div style={{ height: 300 }}>
      <ResponsiveLine
        enableGridX={false}
        enableGridY={false}
        enableArea
        enablePointLabel
        colors={[vars.successMain, vars.accentMain]}
        data={[testsData, manualData]}
        lineWidth={3}
        margin={{ top: 24, right: 40, bottom: 24, left: 40 }}
        xScale={{
          format: '%Y-%m-%d',
          max: dateMax,
          min: dateMin,
          precision: 'day',
          type: 'time',
          useUTC: false,
        }}
        xFormat="time:%Y-%m-%d"
        axisLeft={null}
        axisBottom={{
          tickValues: 8,
          format: '%b %d',
        }}
        pointSize={6}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointColor="#ffffff"
        enableSlices="x"
        useMesh
        theme={{
          background: 'transparent',
          textColor: vars.textSecondary,
          fontSize: 12,
          tooltip: {
            container: { background: vars.primaryDark },
          },
        }}
      />
    </div>
  );
}

type Entry = { x: string; y: number };
type Data = { id: string; data: Entry[] };
type TestsBreakdwn = {
  tests: Entry[];
  manualTests: Entry[];
};

function chartData(
  label: string,
  breakdown: Entry[],
  dateRange: DateRange,
): Data {
  const { startingValue, filteredBreakdown } = breakdown.reduce<{
    startingValue: number;
    accumulatedValue: number;
    filteredBreakdown: Entry[];
  }>(
    (acc, entry) => {
      const dataDate = parse(entry.x, DateFormat.Day, new Date()).getTime();
      if (dataDate < dateRange[0].getTime()) {
        return {
          ...acc,
          startingValue: acc.startingValue + entry.y,
          accumulatedValue: acc.accumulatedValue + entry.y,
        };
      }
      if (dataDate <= dateRange[1].getTime()) {
        acc.accumulatedValue += entry.y;
        acc.filteredBreakdown.push({ ...entry, y: acc.accumulatedValue });
      }
      return acc;
    },
    { startingValue: 0, accumulatedValue: 0, filteredBreakdown: [] },
  );

  return {
    id: label,
    data: [
      {
        x: format(dateRange[0], DateFormat.Day),
        y: startingValue,
      },
      ...filteredBreakdown,
    ],
  };
}

function breakdownTestsData(tests: Test[]): TestsBreakdwn | null {
  if (!tests.length) return null;

  return [...tests]
    .sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )
    .reduce<TestsBreakdwn>(
      (acc, test) => {
        const createdAt = format(new Date(test.created_at), DateFormat.Day);
        const sameDate = acc.tests.find((entry) => entry.x === createdAt);
        if (sameDate) {
          sameDate.y += 1;
        } else {
          acc.tests.push({ x: createdAt, y: 1 });
        }
        if (!test.is_automated) {
          const sameDateManual = acc.manualTests.find(
            (entry) => entry.x === createdAt,
          );
          if (sameDateManual) {
            sameDateManual.y += 1;
          } else {
            acc.manualTests.push({ x: createdAt, y: 1 });
          }
        }

        return acc;
      },
      { tests: [], manualTests: [] },
    );
}

export default React.memo(GrowthChart);
