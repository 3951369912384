import React, { useCallback, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {
  Avatar,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { DeleteIcon, EditIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { billingContactsSelector } from '@bitmodern/redux/state/billingContacts/selectors';
import {
  BillingContactFormDialog,
  ConfirmDialog,
} from 'src/components/organisms';
import { BillingContact } from '@testquality/sdk';
import { subscriptionsSelector } from '@bitmodern/redux/state/subscriptions/selectors';
import { billingContactDeleteOneThunk } from 'src/gen/domain/billing_contact/billingContactThunk';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import styles from './BillingContacts.module.scss';

export default function BillingContacts() {
  const { t } = useTranslation();
  const [currentContact, setCurrentContact] = useState<BillingContact>();
  const dispatch = useAppDispatch();
  const formDialog = useOverlayTriggerState({});
  const deleteDialog = useOverlayTriggerState({});
  const billingContacts = useAppSelector(billingContactsSelector);
  const users = useAppSelector(usersSelector);
  const subscriptions = useAppSelector(subscriptionsSelector);

  const handleOnDelete = useCallback(() => {
    if (!currentContact) return Promise.resolve();
    return dispatch(
      billingContactDeleteOneThunk({ id: currentContact.id }),
    ).finally(deleteDialog.close);
  }, [currentContact, dispatch, deleteDialog.close]);

  const deleteMutation = useMutation(handleOnDelete);

  const onClickAdd = () => {
    setCurrentContact(undefined);
    formDialog.open();
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>{t('billingContacts.title')}</div>
        <Button onClick={onClickAdd}>{t('billingContacts.add')}</Button>
      </div>
      <Table outlined rounded>
        <TableHead>
          <TableCell>{t('billingContacts.table.subscription')}</TableCell>
          <TableCell>{t('billingContacts.table.owner')}</TableCell>
          <TableCell colSpan={2}>{t('billingContacts.table.email')}</TableCell>
        </TableHead>
        <TableBody>
          {billingContacts.map((billingContact) => {
            const user = users.find((u) => billingContact.user_id === u.id);
            const subscription = subscriptions.find(
              (s) => billingContact.subscriptions_id === s.id,
            );
            return (
              <TableRow key={billingContact.id}>
                <TableCell className={styles.cell}>
                  {subscription?.name}
                </TableCell>
                <TableCell className={styles.cell}>
                  <Avatar
                    name={user?.given_name}
                    size={20}
                    src={user?.picture}
                  />
                  <span className={styles.username}>{user?.given_name}</span>
                </TableCell>
                <TableCell className={styles.cell}>
                  {billingContact.email}
                </TableCell>
                <TableCell className={`${styles.cell} ${styles.actions}`}>
                  <IconButton
                    onClick={() => {
                      setCurrentContact(billingContact);
                      formDialog.open();
                    }}
                    size="small"
                    title={t('billingContacts.edit')}>
                    <EditIcon color={vars.textPrimary} size={16} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setCurrentContact(billingContact);
                      deleteDialog.open();
                    }}
                    size="small"
                    title={t('billingContacts.delete')}>
                    <DeleteIcon color={vars.textPrimary} size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <BillingContactFormDialog
        billingContact={currentContact}
        isOpen={formDialog.isOpen}
        onClose={formDialog.close}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('billingContacts.deleteDialog.title')}>
        {t('billingContacts.deleteDialog.content')}
      </ConfirmDialog>
    </div>
  );
}
