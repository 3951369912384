import { createSelector } from '@reduxjs/toolkit';
import { State } from '@bitmodern/redux/store';
import { watchSelectors } from 'src/gen/domain/watch/watchSelector';
import { WatchType } from 'src/enums/WatchEnum';

export function watchSelector(state: State) {
  return watchSelectors.selectAll(state);
}

export const watchBySuiteIdSelector = createSelector(
  [watchSelector, (state: State, suiteId: number) => suiteId],
  (watches, suiteId) =>
    watches.find((watch) => {
      return (
        watch.related_id === suiteId &&
        watch.related_type.includes(WatchType.SUITE)
      );
    }),
);

export const watchByTestIdSelector = createSelector(
  [watchSelector, (state: State, testId: number) => testId],
  (watches, testId) =>
    watches.find(
      (watch) =>
        watch.related_id === testId &&
        watch.related_type.includes(WatchType.TEST),
    ),
);

export const watchByCycleIdSelector = createSelector(
  [watchSelector, (state: State, cycleId: number) => cycleId],
  (watches, cycleId) =>
    watches.find(
      (watch) =>
        watch.related_id === cycleId &&
        watch.related_type.includes(WatchType.PLAN),
    ),
);

export const watchByProjectIdSelector = createSelector(
  [watchSelector, (state: State, projectId: number) => projectId],
  (watches, projectId) =>
    watches.find(
      (watch) =>
        watch.related_id === projectId &&
        watch.related_type.includes(WatchType.PROJECT),
    ),
);
