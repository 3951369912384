import { Plat } from '@testquality/sdk';
import { useFormik } from 'formik';
import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Dialog, Input } from '@bitmodern/bit-ui';
import {
  platCreateOneThunk,
  platUpdateOneThunk,
} from 'src/gen/domain/plat/platThunk';
import { useAppDispatch } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './PlatFormDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('platForm.form.name'),
});

type Props = {
  plat?: Plat;
} & Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>;

export default function PlatFormDialog({ plat, onClose, isOpen }: Props) {
  const isEdit = Boolean(plat);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(
    () => ({
      name: plat?.name || '',
    }),
    [plat],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  function onSubmit({ name }) {
    if (isEdit) {
      return dispatch(
        platUpdateOneThunk({ id: plat?.id, data: { name, is_default: false } }),
      ).then(onClose);
    }

    return dispatch(
      platCreateOneThunk({ data: { name, is_default: false } }),
    ).then(onClose);
  }

  const title = isEdit ? t('platForm.edit') : t('platForm.create');
  const submit = isEdit ? t('platForm.form.update') : t('platForm.form.create');

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="small" title={title}>
      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          autoFocus
          error={formikError(formik, 'name')}
          fullWidth
          label={t('platForm.form.name')}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('platForm.form.namePlaceholder')}
          value={formik.values.name}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit">
          {submit}
        </Button>
      </form>
    </Dialog>
  );
}
