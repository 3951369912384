/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SharedStepHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { sharedStepHistoryAdapter } from './sharedStepHistoryAdapter';
import { sharedStepHistoryFetchThunk } from './sharedStepHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const sharedStepHistorySlice = createSlice({
  name: 'sharedStepHistory',
  initialState: sharedStepHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    sharedStepHistoryCleared(state) {
      state.loading = 'idle';
      sharedStepHistoryAdapter.removeAll(state);
    },
    sharedStepHistoryUpsertMany(
      state,
      action: PayloadAction<
        SharedStepHistory[] | Record<number, SharedStepHistory>
      >,
    ) {
      sharedStepHistoryAdapter.upsertMany(state, action.payload);
    },
    sharedStepHistoryUpsertOne(
      state,
      action: PayloadAction<SharedStepHistory>,
    ) {
      sharedStepHistoryAdapter.upsertOne(state, action.payload);
    },
    sharedStepHistoryUpdateOne(
      state,
      action: PayloadAction<Update<SharedStepHistory>>,
    ) {
      sharedStepHistoryAdapter.updateOne(state, action.payload);
    },
    sharedStepHistoryRemoveOne(state, action: PayloadAction<number>) {
      sharedStepHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedStepHistoryFetchThunk.pending, handlePending);
    builder.addCase(sharedStepHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      sharedStepHistoryAdapter.upsertMany(
        state,
        action.payload.sharedStepHistory,
      );
    });
    builder.addCase(sharedStepHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  sharedStepHistoryCleared,
  sharedStepHistoryUpsertMany,
  sharedStepHistoryUpsertOne,
  sharedStepHistoryUpdateOne,
  sharedStepHistoryRemoveOne,
} = sharedStepHistorySlice.actions;

export const sharedStepHistoryReducer = sharedStepHistorySlice.reducer;
