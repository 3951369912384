import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  PanelActions,
  Spacer,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import useModalManager from 'src/hooks/useModalManager';
import { useAppDispatch } from '@bitmodern/redux/store';
import { Run } from '@testquality/sdk';
import { completeRunThunk } from '@bitmodern/redux/state/runs/thunks';
import styles from './CompleteRunDialog.module.scss';

type Props = {
  run: Run;
};

export default function CompleteRunDialog({ run }: Props) {
  const { hideModal } = useModalManager();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onConfirm = () => {
    dispatch(completeRunThunk(run));
    hideModal();
  };

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="small"
      title={t('completeRun.title')}>
      <DialogContent className={styles.content}>
        {t('completeRun.content')}
      </DialogContent>
      <PanelActions>
        <Spacer>
          <Button color="primaryDark" onClick={hideModal}>
            {t('completeRun.cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('completeRun.complete')}</Button>
        </Spacer>
      </PanelActions>
    </Dialog>
  );
}
