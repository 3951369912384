/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CasePriorityHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { casePriorityHistoryAdapter } from './casePriorityHistoryAdapter';
import { casePriorityHistoryFetchThunk } from './casePriorityHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const casePriorityHistorySlice = createSlice({
  name: 'casePriorityHistory',
  initialState: casePriorityHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    casePriorityHistoryCleared(state) {
      state.loading = 'idle';
      casePriorityHistoryAdapter.removeAll(state);
    },
    casePriorityHistoryUpsertMany(
      state,
      action: PayloadAction<
        CasePriorityHistory[] | Record<number, CasePriorityHistory>
      >,
    ) {
      casePriorityHistoryAdapter.upsertMany(state, action.payload);
    },
    casePriorityHistoryUpsertOne(
      state,
      action: PayloadAction<CasePriorityHistory>,
    ) {
      casePriorityHistoryAdapter.upsertOne(state, action.payload);
    },
    casePriorityHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CasePriorityHistory>>,
    ) {
      casePriorityHistoryAdapter.updateOne(state, action.payload);
    },
    casePriorityHistoryRemoveOne(state, action: PayloadAction<number>) {
      casePriorityHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(casePriorityHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      casePriorityHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        casePriorityHistoryAdapter.upsertMany(
          state,
          action.payload.casePriorityHistory,
        );
      },
    );
    builder.addCase(casePriorityHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  casePriorityHistoryCleared,
  casePriorityHistoryUpsertMany,
  casePriorityHistoryUpsertOne,
  casePriorityHistoryUpdateOne,
  casePriorityHistoryRemoveOne,
} = casePriorityHistorySlice.actions;

export const casePriorityHistoryReducer = casePriorityHistorySlice.reducer;
