import useStateProp from 'src/hooks/useStateProp';
import {
  DASET_ID_KEY,
  DataSet,
} from 'src/packages/redux/state/dataSets/selectors';

export type DataSetReq = Partial<DataSet> & Pick<DataSet, 'data' | 'schema'>;

export type UseDatasetArgs = {
  dataSet: DataSetReq;
};

export function useDataset({ dataSet: initialDataset }: UseDatasetArgs) {
  const [dataSet, setDataSet] = useStateProp(initialDataset);
  const tqIdCount = dataSet.schema.tq_id.count;

  const updateDataSet = (updatedDataSet: Partial<DataSetReq>) => {
    const next = { ...dataSet, ...updatedDataSet };
    setDataSet(next);
    return next;
  };

  const handleAddRow = () => {
    const newTqIdCount = dataSet.schema.tq_id.count + 1;
    const schema = {
      ...dataSet.schema,
      tq_id: { ...dataSet.schema.tq_id, count: newTqIdCount },
    };
    const row = Object.entries(dataSet.schema).reduce(
      (acu, [key, val]) => {
        if (val.type === 'string') acu[key] = '';
        return acu;
      },
      { tq_id: newTqIdCount },
    );
    updateDataSet({ schema, data: [...dataSet.data, row] });
  };

  const handleDeleteRow = (rowIndex: number) => {
    const data = dataSet?.data.filter((_, idx) => idx !== rowIndex);
    updateDataSet({ data });
  };

  const handleDeleteColumn = (columnKey) => {
    if (columnKey === DASET_ID_KEY) return;
    const data = dataSet.data.map((item) => {
      const { [columnKey]: excluded, tq_id, ...rest } = item;
      return { ...rest, tq_id };
    });
    const { [columnKey]: excluded, ...schema } = dataSet.schema;
    updateDataSet({ data, schema: schema as DataSet['schema'] });
  };

  const handleAddColumn = async (columnName: string) => {
    if (Object.keys(dataSet.schema).includes(columnName)) {
      const error = new Error('Duplicated column name');
      error.name = 'duplicatedColumn';
      throw error;
    }
    const datsetId: DataSet['schema'][typeof DASET_ID_KEY] = {
      type: 'pk',
      count: tqIdCount + 1,
    };

    const schema: DataSet['schema'] = {
      ...dataSet.schema,
      [columnName]: { type: 'string' },
      [DASET_ID_KEY]: datsetId,
    };

    const data = dataSet.data.length
      ? dataSet.data.map((prev) => ({ ...prev, [columnName]: '' }))
      : [{ [columnName]: '', [DASET_ID_KEY]: tqIdCount + 1 }];

    return updateDataSet({ schema, data });
  };

  const handleEditColumName = (newName: string, oldName: string) => {
    if (oldName === DASET_ID_KEY) return;
    const { [oldName]: oldValue, tq_id, ...restSchema } = dataSet.schema;
    const schema = { ...restSchema, tq_id, [newName]: oldValue };

    const data = dataSet.data.map((prev) => {
      const { [oldName]: oldItem, tq_id, ...rest } = prev;
      return { ...rest, tq_id, [newName]: oldItem };
    });

    updateDataSet({ data, schema });
  };

  const handleTableCellChange = (key, idx, val) => {
    const data = dataSet.data.map((prevData, i) =>
      i === idx ? { ...prevData, [key]: val } : prevData,
    );
    updateDataSet({ data });
  };

  return {
    dataSet,
    handleAddColumn,
    handleAddRow,
    handleDeleteColumn,
    handleDeleteRow,
    handleEditColumName,
    handleTableCellChange,
    setDataSet,
  };
}
