import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import vars from 'src/export.scss';
import { useCommandBar } from 'src/hooks/useCommandBar';
import {
  BookmarkIcon,
  // BugIcon,
  CustomizeIcon,
  IntegrationsIcon,
  PlayIcon,
  SubscriptionsIcon,
  TestIcon,
  UserIcon,
  UsersIcon,
} from 'src/packages/bit-ui/icons';
import { routes } from 'src/components/Router';
import CommandBar, {
  CommandBarType,
} from 'src/packages/bit-ui/CommandBar/CommandBar';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import useModalManager from 'src/hooks/useModalManager';
import { rootPlanSuiteSelector } from 'src/packages/redux/state/planSuites/selectors';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import { useParamSelector } from 'src/packages/redux/hooks';
import { selectRunsByProjectId } from 'src/packages/redux/state/runs/selectors';
import { useTranslation } from 'react-i18next';

export default function GlobalCommandBar() {
  const { isOpen, hide, commands, handleSetCommands } = useCommandBar();
  const history = useHistory();
  const { site } = useParamsInRoute<typeof routes.HOME.params>(
    routes.HOME.path,
  );
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  const { t } = useTranslation();
  const projectNum = parseInt(projectId, 10);
  const { showModal } = useModalManager();

  const { openCreateRequirement } = useIntegrationCheck(site, projectNum);

  const handleOnClose = hide;

  const rootPlanSuite = useParamSelector(rootPlanSuiteSelector, {
    projectId: parseInt(projectId, 10),
  });

  const runs = useParamSelector(selectRunsByProjectId, {
    projectId: projectNum,
  });

  const globalCommands: CommandBarType = [
    {
      command: 'navigation',
      label: 'Navigation',
      id: 'navigation',
      withSeparator: true,
      commands: [
        {
          command: 'openMyProfile',
          id: 'nav-profile',
          handler: () => {
            history.push(routes.PROFILE({ site }));
            handleOnClose();
          },
          icon: <UserIcon color={vars.textSecondary} size={22} />,
          label: t('commandBar.labels.openProfile'),
        },
        {
          command: 'openMyIntegrations',
          id: 'nav-integration',
          handler: () => {
            history.push(routes.INTEGRATIONS({ site }));
            handleOnClose();
          },
          icon: <IntegrationsIcon color={vars.textSecondary} size={22} />,
          label: t('commandBar.labels.openIntegrations'),
        },
        {
          command: 'openMySubscriptions',
          id: 'nav-subs',
          handler: () => {
            history.push(routes.SUBSCRIPTIONS({ site }));
            handleOnClose();
          },
          icon: <SubscriptionsIcon color={vars.textSecondary} size={22} />,
          label: t('commandBar.labels.openSubscriptions'),
        },
        {
          command: 'openUserManagement',
          id: 'nav-users',
          handler: () => {
            history.push(routes.USERS({ site }));
            handleOnClose();
          },
          icon: <UsersIcon color={vars.textPrimary} size={18} />,
          label: t('commandBar.labels.openUserManagement'),
        },
        {
          command: 'openCustomization',
          id: 'nav-custom',
          handler: () => {
            history.push(routes.CUSTOMIZATION({ site }));
            handleOnClose();
          },
          icon: <CustomizeIcon color={vars.textPrimary} size={18} />,
          label: t('commandBar.labels.openCustomization'),
        },
      ],
    },
  ];

  // const logDefectCommands: CommandBarType = runs.map((run) => ({
  //   command: 'logDefect' + run.id,
  //   id: 'logDefect' + run.id,
  //   handler: () => {
  //     if (run.id) {
  //       handleOnClose();
  //       openCreateDefect(run.id, [0]);
  //     }
  //   },
  //   icon: <PlayIcon color={vars.textPrimary} size={18} />,
  //   label: run.name,
  // }));

  const projectCommands: CommandBarType = [
    {
      command: 'createTest',
      id: 'create-test',
      handler: () => {
        if (rootPlanSuite) {
          handleOnClose();
          showModal({
            modalName: 'testCreate',
            modalProps: { planSuite: rootPlanSuite },
            type: 'modal',
          });
        }
      },
      icon: <TestIcon color={vars.textPrimary} size={18} />,
      label: t('commandBar.labels.createTest'),
    },
    {
      command: 'createRun',
      id: 'create-run',
      handler: () => {
        if (rootPlanSuite) {
          handleOnClose();
          showModal({
            modalName: 'startRun',
            type: 'modal',
          });
        }
      },
      icon: <PlayIcon color={vars.textPrimary} size={18} />,
      label: t('commandBar.labels.createRun'),
    },
    {
      command: 'createStory',
      id: 'create-story',
      handler: () => {
        handleOnClose();
        openCreateRequirement(0);
      },
      icon: <BookmarkIcon color={vars.textPrimary} size={18} />,
      label: t('commandBar.labels.createStory'),
    },
    // {
    //   command: 'createDefect',
    //   id: 'create-defect',
    //   handler: () => {
    //     console.log(logDefectCommands);
    //     handleSetCommands(logDefectCommands);
    //   },
    //   icon: <BugIcon color={vars.textPrimary} size={18} />,
    //   label: 'Log Defect',
    // },
  ];

  useEffect(() => {
    handleSetCommands(
      projectId ? [...globalCommands, ...projectCommands] : globalCommands,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runs]);

  return (
    <CommandBar
      elements={commands}
      id="global-command-bar"
      onClose={handleOnClose}
      open={isOpen}
      placeholder={t('commandBar.placeholder.default')}
    />
  );
}
