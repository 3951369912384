import React from 'react';
import {
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableHead,
} from '@bitmodern/bit-ui';
import { AddIcon, MoreIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import classNames from 'classnames';
import styles from './DataSetHeader.module.scss';

type Props = {
  columns: string[];
  onAddRow: () => void;
  onAddColumn: () => void;
  onDeleteColumn: (key: string) => void;
  onEditColumnName: (key: string) => void;
  isEditing: boolean;
};

export default function DataSetHeader({
  columns,
  onAddRow,
  onAddColumn,
  onDeleteColumn,
  onEditColumnName,
  isEditing,
}: Props) {
  const { t } = useTranslation();

  const headerFirstCN = classNames(styles.header, styles.headerFirst);
  const headerLastCN = classNames(styles.header, styles.headerLast);
  const headerCellCN = classNames(styles.headerCell, {
    [styles.headerCellEditing]: isEditing,
  });

  const renderHeaders = (key) => (
    <TableCell key={key} className={styles.header}>
      <div className={headerCellCN}>
        {key}
        {isEditing && (
          <Dropdown
            popupAlign={{ points: ['tr', 'br'] }}
            className={styles.dropdown}
            overlay={
              <Menu>
                <MenuItem onClick={() => onEditColumnName(key)}>
                  {t('dataSet.editColumn')}
                </MenuItem>
                <MenuItem onClick={() => onDeleteColumn(key)}>
                  {t('dataSet.deleteColumn')}
                </MenuItem>
              </Menu>
            }>
            <IconButton
              size="medium"
              title={t('dataSet.manageColumn')}
              className={styles.manageColumn}>
              <MoreIcon color={vars.textSecondary} size={20} />
            </IconButton>
          </Dropdown>
        )}
      </div>
    </TableCell>
  );

  return (
    <TableHead className={styles.head}>
      <TableCell className={headerFirstCN}>#</TableCell>
      {columns.map(renderHeaders)}
      {isEditing && (
        <TableCell className={headerLastCN}>
          <Dropdown
            className={styles.dropdown}
            popupAlign={{ points: ['tr', 'br'] }}
            overlay={
              <Menu>
                <MenuItem onClick={onAddColumn}>
                  {t('dataSet.addColumn')}
                </MenuItem>
                <MenuItem onClick={onAddRow}>{t('dataSet.addRow')}</MenuItem>
              </Menu>
            }>
            <IconButton size="small" color="accent">
              <AddIcon size={20} color={vars.textPrimary} />
            </IconButton>
          </Dropdown>
        </TableCell>
      )}
    </TableHead>
  );
}
