import { ReactNode } from 'react';
import antNotification from 'antd/es/notification';
import 'antd/es/notification/style/index.less';
import './notification.module.scss';

type Config = {
  btn?: ReactNode;
  description?: ReactNode;
  duration?: number | null;
  icon?: ReactNode;
  key?: string;
  message: ReactNode;
  onClick?: () => void;
  onClose?: () => void;
  type?: 'success' | 'info' | 'error' | 'warning';
};

const notification = {
  open: (config: Config) => {
    antNotification.open(config);
  },
  close: (key: string) => {
    antNotification.close(key);
  },
};

export default notification;
