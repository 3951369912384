import React, { useCallback } from 'react';
import classnames from 'classnames';
import { SuiteTest, Test } from '@testquality/sdk';
import { Grid, Loading, PanelHeader, Surface } from '@bitmodern/bit-ui';
import { DoneIcon, PendingIcon } from '@bitmodern/bit-ui/icons';
import {
  AnalyzeFilters,
  AnalyzeTree,
  TestQualityChart,
  TestQualityBreakdownList,
  TestQualityMetrics,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import useFetch from 'src/hooks/useFetch';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppSelector } from '@bitmodern/redux/store';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { testsFilteredSelector } from '@bitmodern/redux/state/tests/selectors';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import { allTestQualitySelector } from '@bitmodern/redux/state/testQuality/selectors';
import {
  excellentTQTypeSelector,
  goodTQTypeSelector,
} from '@bitmodern/redux/state/testQualityType/selectors';
import { getTestQualityAnalysis } from '@bitmodern/services/TestQualityService';
import styles from './AnalyzeTestQualityView.module.scss';
import { useParamSelector } from 'src/packages/redux/hooks';
import { getDateRangeByTimeframe } from 'src/packages/redux/state/date/selectors';

const BASE_FILTER_TYPE = 'analyze';
const QUALITY_FILTER_TYPE = 'analyzeTestQuality';

export default function AnalyzeTestQualityView() {
  const { projectId: projeIdUrl } = useParams<typeof routes.ANALYZE.params>();
  const projectId = parseInt(projeIdUrl, 10);
  const { openDrawer } = useDrawerManager();
  const baseFilters = useAppSelector((state) =>
    filtersByTypeSelector(state, BASE_FILTER_TYPE),
  );
  const timeframe = useParamSelector(getDateRangeByTimeframe, {
    type: BASE_FILTER_TYPE,
  });
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const filteredTests = useAppSelector((state) =>
    testsFilteredSelector(state, projectId, QUALITY_FILTER_TYPE),
  );
  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId,
      filterType: QUALITY_FILTER_TYPE,
    }),
  );
  const allTestQuality = useAppSelector(allTestQualitySelector);
  const excellentTQType = useAppSelector(excellentTQTypeSelector);
  const goodTQType = useAppSelector(goodTQTypeSelector);

  const qualityAnalysisFetch = useFetch(
    () => {
      if (!timeframe) return Promise.reject();
      const parsedStartDate = timeframe[0];

      return getTestQualityAnalysis(projectId, parsedStartDate);
    },
    [`quality-${projectId}`, timeframe ? timeframe[0] : baseFilters.startDate],
    { ignoreCallback: true },
  );

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const infoTagsToRender = (test: Test) => {
    const testQuality = allTestQuality.find(
      (tq) => tq.id === test.test_quality_id,
    );

    if (!testQuality || !excellentTQType || !goodTQType) return null;

    const isGoodTest =
      testQuality.test_quality_type_id === excellentTQType.id ||
      testQuality.test_quality_type_id === goodTQType.id;

    const iconCN = classnames(styles.badgeIcon, {
      [styles.goodIcon]: isGoodTest,
      [styles.warningIcon]: !isGoodTest,
    });

    return (
      <div className={styles.badge}>
        <span className={iconCN}>
          {isGoodTest ? <DoneIcon size={12} /> : <PendingIcon size={12} />}
        </span>
        <span>{testQuality.name}</span>
      </div>
    );
  };

  if (!project || !timeframe) return null;

  return (
    <>
      <Surface>
        <PanelHeader
          title={project.name}
          actions={<AnalyzeFilters type={QUALITY_FILTER_TYPE} extraFilters />}
        />
        <Grid.Row gutter={0} className={styles.rowContainer}>
          <Grid.Col span={12} md={5} className={styles.column}>
            <TestQualityMetrics tests={filteredTests} />
          </Grid.Col>
          <Grid.Col span={12} md={7}>
            {qualityAnalysisFetch.isLoading && (
              <Loading className={styles.loading} size={48} />
            )}
            {qualityAnalysisFetch.isFetched && (
              <TestQualityChart
                range={timeframe}
                timeSeries={qualityAnalysisFetch.data}
              />
            )}
          </Grid.Col>
        </Grid.Row>
      </Surface>
      <TestQualityBreakdownList tests={filteredTests} />
      <div style={{ height: 400 }}>
        <AnalyzeTree
          id={project.id.toString()}
          onClickTest={onClickTest}
          suitesTree={tree}
          infoTagsToRender={infoTagsToRender}
        />
      </div>
    </>
  );
}
