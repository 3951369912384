/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  BaseCapabilityBaseIntegration,
  baseCapabilityBaseIntegrationDetach,
  baseCapabilityBaseIntegrationUpdateOne,
  baseCapabilityBaseIntegrationCreateOne,
  baseCapabilityBaseIntegrationGetMany,
  baseCapabilityBaseIntegrationGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const baseCapabilityBaseIntegrationDetachThunk = createAsyncThunk(
  'baseCapabilityBaseIntegration/DetachThunk',
  async (arg: QueryParams<BaseCapabilityBaseIntegration>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'baseCapabilityBaseIntegration/DetachThunk',
      );
    }
    return baseCapabilityBaseIntegrationDetach(arg.data, arg).then(
      () => arg.data,
    );
  },
);

export const baseCapabilityBaseIntegrationUpdateOneThunk = createAsyncThunk(
  'baseCapabilityBaseIntegration/UpdateOneThunk',
  async ({
    handleError = true,
    ...arg
  }: ThunkArgs<BaseCapabilityBaseIntegration>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'baseCapabilityBaseIntegration/UpdateOneThunk',
        );
      }
      const data = await baseCapabilityBaseIntegrationUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<BaseCapabilityBaseIntegration>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const baseCapabilityBaseIntegrationCreateOneThunk = createAsyncThunk(
  'baseCapabilityBaseIntegration/CreateOneThunk',
  async ({
    handleError = true,
    ...arg
  }: ThunkArgs<BaseCapabilityBaseIntegration>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'baseCapabilityBaseIntegration/CreateOneThunk',
        );
      }
      return await baseCapabilityBaseIntegrationCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const baseCapabilityBaseIntegrationFetchManyThunk = createAsyncThunk(
  'baseCapabilityBaseIntegration/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<BaseCapabilityBaseIntegration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await baseCapabilityBaseIntegrationGetMany(
        inlineArgs,
      );
      return {
        baseCapabilityBaseIntegration: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const baseCapabilityBaseIntegrationFetchOneThunk = createAsyncThunk(
  'baseCapabilityBaseIntegration/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<BaseCapabilityBaseIntegration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'baseCapabilityBaseIntegration/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await baseCapabilityBaseIntegrationGetOne(
        arg.id as number,
        arg,
      );
      return {
        baseCapabilityBaseIntegration: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
