/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlanSuiteTestIncludeApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const planSuiteTestIncludeNormalizer = (
  model: PlanSuiteTestIncludeApi,
  cache: NormalizerCache,
) => {
  if (!cache.planSuiteTestInclude) {
    cache.planSuiteTestInclude = [];
  }
  cache.planSuiteTestInclude.push(model);
};
