/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-console */

import { AppThunk } from '@bitmodern/redux/store';
import { Notifications } from '@testquality/sdk';
import { isAuthenticatedSelector } from '@bitmodern/redux/state/authentication/selectors';
import {
  notifiableNotificationThunk,
  watchNotificationThunk,
} from '@bitmodern/redux/state/notifications/thunks';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { accessRoleSelectors } from '../domain/access_role/accessRoleSelector';
import { accessRoleRemoveOne } from '../domain/access_role/accessRoleSlice';
import { accessRoleFetchOneThunk } from '../domain/access_role/accessRoleThunk';
import { accessRoleUserSelectors } from '../domain/access_role_user/accessRoleUserSelector';
import {
  accessRoleUserRemoveOne,
  accessRoleUserUpsertOne,
} from '../domain/access_role_user/accessRoleUserSlice';
import { accessRoleUserFetchOneThunk } from '../domain/access_role_user/accessRoleUserThunk';
import { appSelectors } from '../domain/app/appSelector';
import { appRemoveOne, appUpsertOne } from '../domain/app/appSlice';
import { appFetchOneThunk } from '../domain/app/appThunk';
import { appConfigSelectors } from '../domain/app_config/appConfigSelector';
import {
  appConfigRemoveOne,
  appConfigUpsertOne,
} from '../domain/app_config/appConfigSlice';
import { appConfigFetchOneThunk } from '../domain/app_config/appConfigThunk';
import { appInstallSelectors } from '../domain/app_install/appInstallSelector';
import {
  appInstallRemoveOne,
  appInstallUpsertOne,
} from '../domain/app_install/appInstallSlice';
import { appInstallFetchOneThunk } from '../domain/app_install/appInstallThunk';
import { appInstallProjectSelectors } from '../domain/app_install_project/appInstallProjectSelector';
import {
  appInstallProjectRemoveOne,
  appInstallProjectUpsertOne,
} from '../domain/app_install_project/appInstallProjectSlice';
import { appInstallProjectFetchOneThunk } from '../domain/app_install_project/appInstallProjectThunk';
import { appVersionSelectors } from '../domain/app_version/appVersionSelector';
import {
  appVersionRemoveOne,
  appVersionUpsertOne,
} from '../domain/app_version/appVersionSlice';
import { appVersionFetchOneThunk } from '../domain/app_version/appVersionThunk';
import { appVersionPlatVersionSelectors } from '../domain/app_version_plat_version/appVersionPlatVersionSelector';
import { appVersionPlatVersionRemoveOne } from '../domain/app_version_plat_version/appVersionPlatVersionSlice';
import { appVersionPlatVersionFetchOneThunk } from '../domain/app_version_plat_version/appVersionPlatVersionThunk';
import { appVersionPlatVersionPlanSelectors } from '../domain/app_version_plat_version_plan/appVersionPlatVersionPlanSelector';
import {
  appVersionPlatVersionPlanRemoveOne,
  appVersionPlatVersionPlanUpsertOne,
} from '../domain/app_version_plat_version_plan/appVersionPlatVersionPlanSlice';
import { appVersionPlatVersionPlanFetchOneThunk } from '../domain/app_version_plat_version_plan/appVersionPlatVersionPlanThunk';
import { attachmentSelectors } from '../domain/attachment/attachmentSelector';
import {
  attachmentRemoveOne,
  attachmentUpsertOne,
} from '../domain/attachment/attachmentSlice';
import { attachmentFetchOneThunk } from '../domain/attachment/attachmentThunk';
import { baseCapabilitySelectors } from '../domain/base_capability/baseCapabilitySelector';
import { baseCapabilityRemoveOne } from '../domain/base_capability/baseCapabilitySlice';
import { baseCapabilityFetchOneThunk } from '../domain/base_capability/baseCapabilityThunk';
import { baseCapabilityBaseIntegrationSelectors } from '../domain/base_capability_base_integration/baseCapabilityBaseIntegrationSelector';
import {
  baseCapabilityBaseIntegrationRemoveOne,
  baseCapabilityBaseIntegrationUpsertOne,
} from '../domain/base_capability_base_integration/baseCapabilityBaseIntegrationSlice';
import { baseCapabilityBaseIntegrationFetchOneThunk } from '../domain/base_capability_base_integration/baseCapabilityBaseIntegrationThunk';
import { baseIntegrationSelectors } from '../domain/base_integration/baseIntegrationSelector';
import { baseIntegrationRemoveOne } from '../domain/base_integration/baseIntegrationSlice';
import { baseIntegrationFetchOneThunk } from '../domain/base_integration/baseIntegrationThunk';
import { billingContactSelectors } from '../domain/billing_contact/billingContactSelector';
import {
  billingContactRemoveOne,
  billingContactUpsertOne,
} from '../domain/billing_contact/billingContactSlice';
import { billingContactFetchOneThunk } from '../domain/billing_contact/billingContactThunk';
import { capabilitySelectors } from '../domain/capability/capabilitySelector';
import { capabilityRemoveOne } from '../domain/capability/capabilitySlice';
import { capabilityFetchOneThunk } from '../domain/capability/capabilityThunk';
import { capabilityIntegrationSelectors } from '../domain/capability_integration/capabilityIntegrationSelector';
import {
  capabilityIntegrationRemoveOne,
  capabilityIntegrationUpsertOne,
} from '../domain/capability_integration/capabilityIntegrationSlice';
import { capabilityIntegrationFetchOneThunk } from '../domain/capability_integration/capabilityIntegrationThunk';
import { casePrioritySelectors } from '../domain/case_priority/casePrioritySelector';
import {
  casePriorityRemoveOne,
  casePriorityUpsertOne,
} from '../domain/case_priority/casePrioritySlice';
import { casePriorityFetchOneThunk } from '../domain/case_priority/casePriorityThunk';
import { caseTypeSelectors } from '../domain/case_type/caseTypeSelector';
import {
  caseTypeRemoveOne,
  caseTypeUpsertOne,
} from '../domain/case_type/caseTypeSlice';
import { caseTypeFetchOneThunk } from '../domain/case_type/caseTypeThunk';
import { checkRunSelectors } from '../domain/check_run/checkRunSelector';
import {
  checkRunRemoveOne,
  checkRunUpsertOne,
} from '../domain/check_run/checkRunSlice';
import { checkRunFetchOneThunk } from '../domain/check_run/checkRunThunk';
import { checkSuiteSelectors } from '../domain/check_suite/checkSuiteSelector';
import {
  checkSuiteRemoveOne,
  checkSuiteUpsertOne,
} from '../domain/check_suite/checkSuiteSlice';
import { checkSuiteFetchOneThunk } from '../domain/check_suite/checkSuiteThunk';
import { clientSelectors } from '../domain/client/clientSelector';
import { clientRemoveOne, clientUpsertOne } from '../domain/client/clientSlice';
import { clientFetchOneThunk } from '../domain/client/clientThunk';
import { commentSelectors } from '../domain/comment/commentSelector';
import {
  commentRemoveOne,
  commentUpsertOne,
} from '../domain/comment/commentSlice';
import { commentFetchOneThunk } from '../domain/comment/commentThunk';
import { couponSelectors } from '../domain/coupon/couponSelector';
import { couponRemoveOne, couponUpsertOne } from '../domain/coupon/couponSlice';
import { couponFetchOneThunk } from '../domain/coupon/couponThunk';
import { dataSetSelectors } from '../domain/data_set/dataSetSelector';
import {
  dataSetRemoveOne,
  dataSetUpsertOne,
} from '../domain/data_set/dataSetSlice';
import { dataSetFetchOneThunk } from '../domain/data_set/dataSetThunk';
import { defectSelectors } from '../domain/defect/defectSelector';
import { defectRemoveOne } from '../domain/defect/defectSlice';
import { defectFetchOneThunk } from '../domain/defect/defectThunk';
import { defectResSelectors } from '../domain/defect_res/defectResSelector';
import { defectResRemoveOne } from '../domain/defect_res/defectResSlice';
import { defectResFetchOneThunk } from '../domain/defect_res/defectResThunk';
import { defectResNativeDefectResSelectors } from '../domain/defect_res_native_defect_res/defectResNativeDefectResSelector';
import {
  defectResNativeDefectResRemoveOne,
  defectResNativeDefectResUpsertOne,
} from '../domain/defect_res_native_defect_res/defectResNativeDefectResSlice';
import { defectResNativeDefectResFetchOneThunk } from '../domain/defect_res_native_defect_res/defectResNativeDefectResThunk';
import { defectRunResultSelectors } from '../domain/defect_run_result/defectRunResultSelector';
import {
  defectRunResultRemoveOne,
  defectRunResultUpsertOne,
} from '../domain/defect_run_result/defectRunResultSlice';
import { defectRunResultFetchOneThunk } from '../domain/defect_run_result/defectRunResultThunk';
import { defectStatusSelectors } from '../domain/defect_status/defectStatusSelector';
import { defectStatusRemoveOne } from '../domain/defect_status/defectStatusSlice';
import { defectStatusFetchOneThunk } from '../domain/defect_status/defectStatusThunk';
import { defectStatusNativeDefectStatusSelectors } from '../domain/defect_status_native_defect_status/defectStatusNativeDefectStatusSelector';
import {
  defectStatusNativeDefectStatusRemoveOne,
  defectStatusNativeDefectStatusUpsertOne,
} from '../domain/defect_status_native_defect_status/defectStatusNativeDefectStatusSlice';
import { defectStatusNativeDefectStatusFetchOneThunk } from '../domain/defect_status_native_defect_status/defectStatusNativeDefectStatusThunk';
import { filterSelectors } from '../domain/filter/filterSelector';
import { filterRemoveOne, filterUpsertOne } from '../domain/filter/filterSlice';
import { filterFetchOneThunk } from '../domain/filter/filterThunk';
import { integrationSelectors } from '../domain/integration/integrationSelector';
import { integrationRemoveOne } from '../domain/integration/integrationSlice';
import { integrationFetchOneThunk } from '../domain/integration/integrationThunk';
import { integrationProjectSelectors } from '../domain/integration_project/integrationProjectSelector';
import {
  integrationProjectRemoveOne,
  integrationProjectUpsertOne,
} from '../domain/integration_project/integrationProjectSlice';
import { integrationProjectFetchOneThunk } from '../domain/integration_project/integrationProjectThunk';
import { integrationTemplateSelectors } from '../domain/integration_template/integrationTemplateSelector';
import {
  integrationTemplateRemoveOne,
  integrationTemplateUpsertOne,
} from '../domain/integration_template/integrationTemplateSlice';
import { integrationTemplateFetchOneThunk } from '../domain/integration_template/integrationTemplateThunk';
import { integrationUserSelectors } from '../domain/integration_user/integrationUserSelector';
import {
  integrationUserRemoveOne,
  integrationUserUpsertOne,
} from '../domain/integration_user/integrationUserSlice';
import { integrationUserFetchOneThunk } from '../domain/integration_user/integrationUserThunk';
import { invoiceSelectors } from '../domain/invoice/invoiceSelector';
import {
  invoiceRemoveOne,
  invoiceUpsertOne,
} from '../domain/invoice/invoiceSlice';
import { invoiceFetchOneThunk } from '../domain/invoice/invoiceThunk';
import { labelSelectors } from '../domain/label/labelSelector';
import { labelRemoveOne, labelUpsertOne } from '../domain/label/labelSlice';
import { labelFetchOneThunk } from '../domain/label/labelThunk';
import { labelAssignedSelectors } from '../domain/label_assigned/labelAssignedSelector';
import {
  labelAssignedRemoveOne,
  labelAssignedUpsertOne,
} from '../domain/label_assigned/labelAssignedSlice';
import { labelAssignedFetchOneThunk } from '../domain/label_assigned/labelAssignedThunk';
import { milestoneSelectors } from '../domain/milestone/milestoneSelector';
import {
  milestoneRemoveOne,
  milestoneUpsertOne,
} from '../domain/milestone/milestoneSlice';
import { milestoneFetchOneThunk } from '../domain/milestone/milestoneThunk';
import { nativeDefectResSelectors } from '../domain/native_defect_res/nativeDefectResSelector';
import { nativeDefectResRemoveOne } from '../domain/native_defect_res/nativeDefectResSlice';
import { nativeDefectResFetchOneThunk } from '../domain/native_defect_res/nativeDefectResThunk';
import { nativeDefectStatusSelectors } from '../domain/native_defect_status/nativeDefectStatusSelector';
import { nativeDefectStatusRemoveOne } from '../domain/native_defect_status/nativeDefectStatusSlice';
import { nativeDefectStatusFetchOneThunk } from '../domain/native_defect_status/nativeDefectStatusThunk';
import { notificationsSelectors } from '../domain/notifications/notificationsSelector';
import {
  notificationsRemoveOne,
  notificationsUpsertOne,
} from '../domain/notifications/notificationsSlice';
import { notificationsFetchOneThunk } from '../domain/notifications/notificationsThunk';
import { planSelectors } from '../domain/plan/planSelector';
import { planRemoveOne } from '../domain/plan/planSlice';
import { planFetchOneThunk } from '../domain/plan/planThunk';
import { planPullRequestSelectors } from '../domain/plan_pull_request/planPullRequestSelector';
import {
  planPullRequestRemoveOne,
  planPullRequestUpsertOne,
} from '../domain/plan_pull_request/planPullRequestSlice';
import { planPullRequestFetchOneThunk } from '../domain/plan_pull_request/planPullRequestThunk';
import { planPurposeSelectors } from '../domain/plan_purpose/planPurposeSelector';
import {
  planPurposeRemoveOne,
  planPurposeUpsertOne,
} from '../domain/plan_purpose/planPurposeSlice';
import { planPurposeFetchOneThunk } from '../domain/plan_purpose/planPurposeThunk';
import { planSuiteSelectors } from '../domain/plan_suite/planSuiteSelector';
import {
  planSuiteRemoveOne,
  planSuiteUpsertOne,
} from '../domain/plan_suite/planSuiteSlice';
import { planSuiteFetchOneThunk } from '../domain/plan_suite/planSuiteThunk';
import { planSuiteTestIncludeSelectors } from '../domain/plan_suite_test_include/planSuiteTestIncludeSelector';
import {
  planSuiteTestIncludeRemoveOne,
  planSuiteTestIncludeUpsertOne,
} from '../domain/plan_suite_test_include/planSuiteTestIncludeSlice';
import { planSuiteTestIncludeFetchOneThunk } from '../domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { platSelectors } from '../domain/plat/platSelector';
import { platRemoveOne, platUpsertOne } from '../domain/plat/platSlice';
import { platFetchOneThunk } from '../domain/plat/platThunk';
import { platVersionSelectors } from '../domain/plat_version/platVersionSelector';
import {
  platVersionRemoveOne,
  platVersionUpsertOne,
} from '../domain/plat_version/platVersionSlice';
import { platVersionFetchOneThunk } from '../domain/plat_version/platVersionThunk';
import { policySelectors } from '../domain/policy/policySelector';
import { policyRemoveOne, policyUpsertOne } from '../domain/policy/policySlice';
import { policyFetchOneThunk } from '../domain/policy/policyThunk';
import { policyRowsSelectors } from '../domain/policy_rows/policyRowsSelector';
import {
  policyRowsRemoveOne,
  policyRowsUpsertOne,
} from '../domain/policy_rows/policyRowsSlice';
import { policyRowsFetchOneThunk } from '../domain/policy_rows/policyRowsThunk';
import { productSelectors } from '../domain/product/productSelector';
import {
  productRemoveOne,
  productUpsertOne,
} from '../domain/product/productSlice';
import { productFetchOneThunk } from '../domain/product/productThunk';
import { projectSelectors } from '../domain/project/projectSelector';
import { projectRemoveOne } from '../domain/project/projectSlice';
import { projectFetchOneThunk } from '../domain/project/projectThunk';
import { pullRequestSelectors } from '../domain/pull_request/pullRequestSelector';
import {
  pullRequestRemoveOne,
  pullRequestUpsertOne,
} from '../domain/pull_request/pullRequestSlice';
import { pullRequestFetchOneThunk } from '../domain/pull_request/pullRequestThunk';
import { purposeSelectors } from '../domain/purpose/purposeSelector';
import { purposeRemoveOne } from '../domain/purpose/purposeSlice';
import { purposeFetchOneThunk } from '../domain/purpose/purposeThunk';
import { requirementSelectors } from '../domain/requirement/requirementSelector';
import {
  requirementRemoveOne,
  requirementUpsertOne,
} from '../domain/requirement/requirementSlice';
import { requirementFetchOneThunk } from '../domain/requirement/requirementThunk';
import { requirementTestSelectors } from '../domain/requirement_test/requirementTestSelector';
import {
  requirementTestRemoveOne,
  requirementTestUpsertOne,
} from '../domain/requirement_test/requirementTestSlice';
import { requirementTestFetchOneThunk } from '../domain/requirement_test/requirementTestThunk';
import { runSelectors } from '../domain/run/runSelector';
import { runRemoveOne, runUpsertOne } from '../domain/run/runSlice';
import { runFetchOneThunk } from '../domain/run/runThunk';
import { runResultSelectors } from '../domain/run_result/runResultSelector';
import { runResultRemoveOne } from '../domain/run_result/runResultSlice';
import { runResultFetchOneThunk } from '../domain/run_result/runResultThunk';
import { runResultStepSelectors } from '../domain/run_result_step/runResultStepSelector';
import {
  runResultStepRemoveOne,
  runResultStepUpsertOne,
} from '../domain/run_result_step/runResultStepSlice';
import { runResultStepFetchOneThunk } from '../domain/run_result_step/runResultStepThunk';
import { runSuiteSelectors } from '../domain/run_suite/runSuiteSelector';
import {
  runSuiteRemoveOne,
  runSuiteUpsertOne,
} from '../domain/run_suite/runSuiteSlice';
import { runSuiteFetchOneThunk } from '../domain/run_suite/runSuiteThunk';
import { sharedPreconditionSelectors } from '../domain/shared_precondition/sharedPreconditionSelector';
import {
  sharedPreconditionRemoveOne,
  sharedPreconditionUpsertOne,
} from '../domain/shared_precondition/sharedPreconditionSlice';
import { sharedPreconditionFetchOneThunk } from '../domain/shared_precondition/sharedPreconditionThunk';
import { sharedStepSelectors } from '../domain/shared_step/sharedStepSelector';
import {
  sharedStepRemoveOne,
  sharedStepUpsertOne,
} from '../domain/shared_step/sharedStepSlice';
import { sharedStepFetchOneThunk } from '../domain/shared_step/sharedStepThunk';
import { signupOptionSelectors } from '../domain/signup_option/signupOptionSelector';
import {
  signupOptionRemoveOne,
  signupOptionUpsertOne,
} from '../domain/signup_option/signupOptionSlice';
import { signupOptionFetchOneThunk } from '../domain/signup_option/signupOptionThunk';
import { statusSelectors } from '../domain/status/statusSelector';
import { statusRemoveOne, statusUpsertOne } from '../domain/status/statusSlice';
import { statusFetchOneThunk } from '../domain/status/statusThunk';
import { statusTypeSelectors } from '../domain/status_type/statusTypeSelector';
import {
  statusTypeRemoveOne,
  statusTypeUpsertOne,
} from '../domain/status_type/statusTypeSlice';
import { statusTypeFetchOneThunk } from '../domain/status_type/statusTypeThunk';
import { stepSelectors } from '../domain/step/stepSelector';
import { stepRemoveOne, stepUpsertOne } from '../domain/step/stepSlice';
import { stepFetchOneThunk } from '../domain/step/stepThunk';
import { subscriptionUserSelectors } from '../domain/subscription_user/subscriptionUserSelector';
import {
  subscriptionUserRemoveOne,
  subscriptionUserUpsertOne,
} from '../domain/subscription_user/subscriptionUserSlice';
import { subscriptionUserFetchOneThunk } from '../domain/subscription_user/subscriptionUserThunk';
import { subscriptionsSelectors } from '../domain/subscriptions/subscriptionsSelector';
import {
  subscriptionsRemoveOne,
  subscriptionsUpsertOne,
} from '../domain/subscriptions/subscriptionsSlice';
import { subscriptionsFetchOneThunk } from '../domain/subscriptions/subscriptionsThunk';
import { suiteSelectors } from '../domain/suite/suiteSelector';
import { suiteRemoveOne } from '../domain/suite/suiteSlice';
import { suiteFetchOneThunk } from '../domain/suite/suiteThunk';
import { suiteTestSelectors } from '../domain/suite_test/suiteTestSelector';
import {
  suiteTestRemoveOne,
  suiteTestUpsertOne,
} from '../domain/suite_test/suiteTestSlice';
import { suiteTestFetchOneThunk } from '../domain/suite_test/suiteTestThunk';
import { testSelectors } from '../domain/test/testSelector';
import { testRemoveOne } from '../domain/test/testSlice';
import { testFetchOneThunk } from '../domain/test/testThunk';
import { testQualitySelectors } from '../domain/test_quality/testQualitySelector';
import {
  testQualityRemoveOne,
  testQualityUpsertOne,
} from '../domain/test_quality/testQualitySlice';
import { testQualityFetchOneThunk } from '../domain/test_quality/testQualityThunk';
import { testQualityTypeSelectors } from '../domain/test_quality_type/testQualityTypeSelector';
import {
  testQualityTypeRemoveOne,
  testQualityTypeUpsertOne,
} from '../domain/test_quality_type/testQualityTypeSlice';
import { testQualityTypeFetchOneThunk } from '../domain/test_quality_type/testQualityTypeThunk';
import { userSelectors } from '../domain/user/userSelector';
import { userRemoveOne } from '../domain/user/userSlice';
import { userFetchOneThunk } from '../domain/user/userThunk';
import { virtualSelectors } from '../domain/virtual/virtualSelector';
import {
  virtualRemoveOne,
  virtualUpsertOne,
} from '../domain/virtual/virtualSlice';
import { virtualFetchOneThunk } from '../domain/virtual/virtualThunk';
import { watchSelectors } from '../domain/watch/watchSelector';
import { watchRemoveOne, watchUpsertOne } from '../domain/watch/watchSlice';
import { watchFetchOneThunk } from '../domain/watch/watchThunk';
import {
  notifiableNotificationTypes,
  watchNotificationTypes,
} from './notificationTypes';

const log = new ConsoleLogger('handleNotificationAction');

export const handleNotificationAction = (
  notification: Notifications,
): AppThunk<any> => {
  return (dispatch, getState) => {
    if (isAuthenticatedSelector(getState())) {
      const currentProjectId = Number(localStorage.getItem('lastProject'));
      const data = notification.data as any;
      const truncated = (notification as any).truncated as boolean;
      const isFetchNeeded = data.project_id
        ? data.project_id === currentProjectId // if is same project as current we fetch
        : true;

      if (notification.type === 'App\\Notifications\\ActivityCreate') {
        if (data.metadata_model === 'AccessRole') {
          const item = accessRoleSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              accessRoleFetchOneThunk({
                id: data.id,
                params: { _with: 'user' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AccessRoleUser') {
          const item = accessRoleUserSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(accessRoleUserUpsertOne(data));
            }
            return dispatch(accessRoleUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'App') {
          const item = appSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appUpsertOne(data));
            }
            return dispatch(appFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppConfig') {
          const item = appConfigSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appConfigUpsertOne(data));
            }
            return dispatch(appConfigFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstall') {
          const item = appInstallSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appInstallUpsertOne(data));
            }
            return dispatch(appInstallFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstallProject') {
          const item = appInstallProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appInstallProjectUpsertOne(data));
            }
            return dispatch(appInstallProjectFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersion') {
          const item = appVersionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appVersionUpsertOne(data));
            }
            return dispatch(appVersionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersion') {
          const item = appVersionPlatVersionSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            return dispatch(
              appVersionPlatVersionFetchOneThunk({
                id: data.id,
                params: { _with: 'plan' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersionPlan') {
          const item = appVersionPlatVersionPlanSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(appVersionPlatVersionPlanUpsertOne(data));
            }
            return dispatch(
              appVersionPlatVersionPlanFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Attachment') {
          const item = attachmentSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(attachmentUpsertOne(data));
            }
            return dispatch(attachmentFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapability') {
          const item = baseCapabilitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              baseCapabilityFetchOneThunk({
                id: data.id,
                params: { _with: 'baseIntegration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapabilityBaseIntegration') {
          const item = baseCapabilityBaseIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(baseCapabilityBaseIntegrationUpsertOne(data));
            }
            return dispatch(
              baseCapabilityBaseIntegrationFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseIntegration') {
          const item = baseIntegrationSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              baseIntegrationFetchOneThunk({
                id: data.id,
                params: { _with: 'baseCapability' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BillingContact') {
          const item = billingContactSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(billingContactUpsertOne(data));
            }
            return dispatch(billingContactFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Capability') {
          const item = capabilitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              capabilityFetchOneThunk({
                id: data.id,
                params: { _with: 'integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CapabilityIntegration') {
          const item = capabilityIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(capabilityIntegrationUpsertOne(data));
            }
            return dispatch(
              capabilityIntegrationFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CasePriority') {
          const item = casePrioritySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(casePriorityUpsertOne(data));
            }
            return dispatch(casePriorityFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CaseType') {
          const item = caseTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(caseTypeUpsertOne(data));
            }
            return dispatch(caseTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckRun') {
          const item = checkRunSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(checkRunUpsertOne(data));
            }
            return dispatch(checkRunFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckSuite') {
          const item = checkSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(checkSuiteUpsertOne(data));
            }
            return dispatch(checkSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Client') {
          const item = clientSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(clientUpsertOne(data));
            }
            return dispatch(clientFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Comment') {
          const item = commentSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(commentUpsertOne(data));
            }
            return dispatch(commentFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Coupon') {
          const item = couponSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(couponUpsertOne(data));
            }
            return dispatch(couponFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DataSet') {
          const item = dataSetSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(dataSetUpsertOne(data));
            }
            return dispatch(dataSetFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Defect') {
          const item = defectSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              defectFetchOneThunk({
                id: data.id,
                params: { _with: 'runResult' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRes') {
          const item = defectResSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              defectResFetchOneThunk({
                id: data.id,
                params: { _with: 'nativeDefectRes' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectResNativeDefectRes') {
          const item = defectResNativeDefectResSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(defectResNativeDefectResUpsertOne(data));
            }
            return dispatch(
              defectResNativeDefectResFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRunResult') {
          const item = defectRunResultSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(defectRunResultUpsertOne(data));
            }
            return dispatch(defectRunResultFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatus') {
          const item = defectStatusSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              defectStatusFetchOneThunk({
                id: data.id,
                params: { _with: 'nativeDefectStatus' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatusNativeDefectStatus') {
          const item = defectStatusNativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(defectStatusNativeDefectStatusUpsertOne(data));
            }
            return dispatch(
              defectStatusNativeDefectStatusFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Filter') {
          const item = filterSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(filterUpsertOne(data));
            }
            return dispatch(filterFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Integration') {
          const item = integrationSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              integrationFetchOneThunk({
                id: data.id,
                params: { _with: 'project,user,capability' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationProject') {
          const item = integrationProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(integrationProjectUpsertOne(data));
            }
            return dispatch(integrationProjectFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationTemplate') {
          const item = integrationTemplateSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(integrationTemplateUpsertOne(data));
            }
            return dispatch(integrationTemplateFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationUser') {
          const item = integrationUserSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(integrationUserUpsertOne(data));
            }
            return dispatch(integrationUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Invoice') {
          const item = invoiceSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(invoiceUpsertOne(data));
            }
            return dispatch(invoiceFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Label') {
          const item = labelSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(labelUpsertOne(data));
            }
            return dispatch(labelFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'LabelAssigned') {
          const item = labelAssignedSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(labelAssignedUpsertOne(data));
            }
            return dispatch(labelAssignedFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Milestone') {
          const item = milestoneSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(milestoneUpsertOne(data));
            }
            return dispatch(milestoneFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectRes') {
          const item = nativeDefectResSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              nativeDefectResFetchOneThunk({
                id: data.id,
                params: { _with: 'defectRes' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectStatus') {
          const item = nativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            return dispatch(
              nativeDefectStatusFetchOneThunk({
                id: data.id,
                params: { _with: 'defectStatus' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Notifications') {
          const item = notificationsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(notificationsUpsertOne(data));
            }
            return dispatch(notificationsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plan') {
          const item = planSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              planFetchOneThunk({
                id: data.id,
                params: { _with: 'purpose,suite,appVersionPlatVersion' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPullRequest') {
          const item = planPullRequestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(planPullRequestUpsertOne(data));
            }
            return dispatch(planPullRequestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPurpose') {
          const item = planPurposeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(planPurposeUpsertOne(data));
            }
            return dispatch(planPurposeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuite') {
          const item = planSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(planSuiteUpsertOne(data));
            }
            return dispatch(planSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuiteTestInclude') {
          const item = planSuiteTestIncludeSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(planSuiteTestIncludeUpsertOne(data));
            }
            return dispatch(planSuiteTestIncludeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plat') {
          const item = platSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(platUpsertOne(data));
            }
            return dispatch(platFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlatVersion') {
          const item = platVersionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(platVersionUpsertOne(data));
            }
            return dispatch(platVersionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Policy') {
          const item = policySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(policyUpsertOne(data));
            }
            return dispatch(policyFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PolicyRows') {
          const item = policyRowsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(policyRowsUpsertOne(data));
            }
            return dispatch(policyRowsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Product') {
          const item = productSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(productUpsertOne(data));
            }
            return dispatch(productFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Project') {
          const item = projectSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              projectFetchOneThunk({
                id: data.id,
                params: { _with: 'integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PullRequest') {
          const item = pullRequestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(pullRequestUpsertOne(data));
            }
            return dispatch(pullRequestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Purpose') {
          const item = purposeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              purposeFetchOneThunk({ id: data.id, params: { _with: 'plan' } }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Requirement') {
          const item = requirementSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(requirementUpsertOne(data));
            }
            return dispatch(requirementFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RequirementTest') {
          const item = requirementTestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(requirementTestUpsertOne(data));
            }
            return dispatch(requirementTestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Run') {
          const item = runSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(runUpsertOne(data));
            }
            return dispatch(runFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResult') {
          const item = runResultSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              runResultFetchOneThunk({
                id: data.id,
                params: { _with: 'defect' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResultStep') {
          const item = runResultStepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(runResultStepUpsertOne(data));
            }
            return dispatch(runResultStepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunSuite') {
          const item = runSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(runSuiteUpsertOne(data));
            }
            return dispatch(runSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedPrecondition') {
          const item = sharedPreconditionSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(sharedPreconditionUpsertOne(data));
            }
            return dispatch(sharedPreconditionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedStep') {
          const item = sharedStepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(sharedStepUpsertOne(data));
            }
            return dispatch(sharedStepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SignupOption') {
          const item = signupOptionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(signupOptionUpsertOne(data));
            }
            return dispatch(signupOptionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Status') {
          const item = statusSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(statusUpsertOne(data));
            }
            return dispatch(statusFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'StatusType') {
          const item = statusTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(statusTypeUpsertOne(data));
            }
            return dispatch(statusTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Step') {
          const item = stepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(stepUpsertOne(data));
            }
            return dispatch(stepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SubscriptionUser') {
          const item = subscriptionUserSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(subscriptionUserUpsertOne(data));
            }
            return dispatch(subscriptionUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Subscriptions') {
          const item = subscriptionsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(subscriptionsUpsertOne(data));
            }
            return dispatch(subscriptionsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Suite') {
          const item = suiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              suiteFetchOneThunk({
                id: data.id,
                params: { _with: 'test,plan' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SuiteTest') {
          const item = suiteTestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(suiteTestUpsertOne(data));
            }
            return dispatch(suiteTestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Test') {
          const item = testSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              testFetchOneThunk({ id: data.id, params: { _with: 'suite' } }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQuality') {
          const item = testQualitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(testQualityUpsertOne(data));
            }
            return dispatch(testQualityFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQualityType') {
          const item = testQualityTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(testQualityTypeUpsertOne(data));
            }
            return dispatch(testQualityTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'User') {
          const item = userSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            return dispatch(
              userFetchOneThunk({
                id: data.id,
                params: { _with: 'accessRole,integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Virtual') {
          const item = virtualSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(virtualUpsertOne(data));
            }
            return dispatch(virtualFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Watch') {
          const item = watchSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && !item) {
            if (!truncated) {
              return dispatch(watchUpsertOne(data));
            }
            return dispatch(watchFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
      } else if (notification.type === 'App\\Notifications\\ActivityUpdate') {
        if (data.metadata_model === 'AccessRole') {
          const item = accessRoleSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              accessRoleFetchOneThunk({
                id: data.id,
                params: { _with: 'user' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AccessRoleUser') {
          const item = accessRoleUserSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(accessRoleUserUpsertOne(data));
            }
            return dispatch(accessRoleUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'App') {
          const item = appSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appUpsertOne(data));
            }
            return dispatch(appFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppConfig') {
          const item = appConfigSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appConfigUpsertOne(data));
            }
            return dispatch(appConfigFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstall') {
          const item = appInstallSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appInstallUpsertOne(data));
            }
            return dispatch(appInstallFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstallProject') {
          const item = appInstallProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appInstallProjectUpsertOne(data));
            }
            return dispatch(appInstallProjectFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersion') {
          const item = appVersionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appVersionUpsertOne(data));
            }
            return dispatch(appVersionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersion') {
          const item = appVersionPlatVersionSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              appVersionPlatVersionFetchOneThunk({
                id: data.id,
                params: { _with: 'plan' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersionPlan') {
          const item = appVersionPlatVersionPlanSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(appVersionPlatVersionPlanUpsertOne(data));
            }
            return dispatch(
              appVersionPlatVersionPlanFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Attachment') {
          const item = attachmentSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(attachmentUpsertOne(data));
            }
            return dispatch(attachmentFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapability') {
          const item = baseCapabilitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              baseCapabilityFetchOneThunk({
                id: data.id,
                params: { _with: 'baseIntegration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapabilityBaseIntegration') {
          const item = baseCapabilityBaseIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(baseCapabilityBaseIntegrationUpsertOne(data));
            }
            return dispatch(
              baseCapabilityBaseIntegrationFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseIntegration') {
          const item = baseIntegrationSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              baseIntegrationFetchOneThunk({
                id: data.id,
                params: { _with: 'baseCapability' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BillingContact') {
          const item = billingContactSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(billingContactUpsertOne(data));
            }
            return dispatch(billingContactFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Capability') {
          const item = capabilitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              capabilityFetchOneThunk({
                id: data.id,
                params: { _with: 'integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CapabilityIntegration') {
          const item = capabilityIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(capabilityIntegrationUpsertOne(data));
            }
            return dispatch(
              capabilityIntegrationFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CasePriority') {
          const item = casePrioritySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(casePriorityUpsertOne(data));
            }
            return dispatch(casePriorityFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CaseType') {
          const item = caseTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(caseTypeUpsertOne(data));
            }
            return dispatch(caseTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckRun') {
          const item = checkRunSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(checkRunUpsertOne(data));
            }
            return dispatch(checkRunFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckSuite') {
          const item = checkSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(checkSuiteUpsertOne(data));
            }
            return dispatch(checkSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Client') {
          const item = clientSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(clientUpsertOne(data));
            }
            return dispatch(clientFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Comment') {
          const item = commentSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(commentUpsertOne(data));
            }
            return dispatch(commentFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Coupon') {
          const item = couponSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(couponUpsertOne(data));
            }
            return dispatch(couponFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DataSet') {
          const item = dataSetSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(dataSetUpsertOne(data));
            }
            return dispatch(dataSetFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Defect') {
          const item = defectSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              defectFetchOneThunk({
                id: data.id,
                params: { _with: 'runResult' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRes') {
          const item = defectResSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              defectResFetchOneThunk({
                id: data.id,
                params: { _with: 'nativeDefectRes' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectResNativeDefectRes') {
          const item = defectResNativeDefectResSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(defectResNativeDefectResUpsertOne(data));
            }
            return dispatch(
              defectResNativeDefectResFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRunResult') {
          const item = defectRunResultSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(defectRunResultUpsertOne(data));
            }
            return dispatch(defectRunResultFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatus') {
          const item = defectStatusSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              defectStatusFetchOneThunk({
                id: data.id,
                params: { _with: 'nativeDefectStatus' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatusNativeDefectStatus') {
          const item = defectStatusNativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(defectStatusNativeDefectStatusUpsertOne(data));
            }
            return dispatch(
              defectStatusNativeDefectStatusFetchOneThunk({ id: data.id }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Filter') {
          const item = filterSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(filterUpsertOne(data));
            }
            return dispatch(filterFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Integration') {
          const item = integrationSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              integrationFetchOneThunk({
                id: data.id,
                params: { _with: 'project,user,capability' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationProject') {
          const item = integrationProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(integrationProjectUpsertOne(data));
            }
            return dispatch(integrationProjectFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationTemplate') {
          const item = integrationTemplateSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(integrationTemplateUpsertOne(data));
            }
            return dispatch(integrationTemplateFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationUser') {
          const item = integrationUserSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(integrationUserUpsertOne(data));
            }
            return dispatch(integrationUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Invoice') {
          const item = invoiceSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(invoiceUpsertOne(data));
            }
            return dispatch(invoiceFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Label') {
          const item = labelSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(labelUpsertOne(data));
            }
            return dispatch(labelFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'LabelAssigned') {
          const item = labelAssignedSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(labelAssignedUpsertOne(data));
            }
            return dispatch(labelAssignedFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Milestone') {
          const item = milestoneSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(milestoneUpsertOne(data));
            }
            return dispatch(milestoneFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectRes') {
          const item = nativeDefectResSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              nativeDefectResFetchOneThunk({
                id: data.id,
                params: { _with: 'defectRes' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectStatus') {
          const item = nativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              nativeDefectStatusFetchOneThunk({
                id: data.id,
                params: { _with: 'defectStatus' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Notifications') {
          const item = notificationsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(notificationsUpsertOne(data));
            }
            return dispatch(notificationsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plan') {
          const item = planSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              planFetchOneThunk({
                id: data.id,
                params: { _with: 'purpose,suite,appVersionPlatVersion' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPullRequest') {
          const item = planPullRequestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(planPullRequestUpsertOne(data));
            }
            return dispatch(planPullRequestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPurpose') {
          const item = planPurposeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(planPurposeUpsertOne(data));
            }
            return dispatch(planPurposeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuite') {
          const item = planSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(planSuiteUpsertOne(data));
            }
            return dispatch(planSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuiteTestInclude') {
          const item = planSuiteTestIncludeSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(planSuiteTestIncludeUpsertOne(data));
            }
            return dispatch(planSuiteTestIncludeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plat') {
          const item = platSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(platUpsertOne(data));
            }
            return dispatch(platFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlatVersion') {
          const item = platVersionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(platVersionUpsertOne(data));
            }
            return dispatch(platVersionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Policy') {
          const item = policySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(policyUpsertOne(data));
            }
            return dispatch(policyFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PolicyRows') {
          const item = policyRowsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(policyRowsUpsertOne(data));
            }
            return dispatch(policyRowsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Product') {
          const item = productSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(productUpsertOne(data));
            }
            return dispatch(productFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Project') {
          const item = projectSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              projectFetchOneThunk({
                id: data.id,
                params: { _with: 'integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PullRequest') {
          const item = pullRequestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(pullRequestUpsertOne(data));
            }
            return dispatch(pullRequestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Purpose') {
          const item = purposeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              purposeFetchOneThunk({ id: data.id, params: { _with: 'plan' } }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Requirement') {
          const item = requirementSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(requirementUpsertOne(data));
            }
            return dispatch(requirementFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RequirementTest') {
          const item = requirementTestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(requirementTestUpsertOne(data));
            }
            return dispatch(requirementTestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Run') {
          const item = runSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(runUpsertOne(data));
            }
            return dispatch(runFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResult') {
          const item = runResultSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              runResultFetchOneThunk({
                id: data.id,
                params: { _with: 'defect' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResultStep') {
          const item = runResultStepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(runResultStepUpsertOne(data));
            }
            return dispatch(runResultStepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunSuite') {
          const item = runSuiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(runSuiteUpsertOne(data));
            }
            return dispatch(runSuiteFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedPrecondition') {
          const item = sharedPreconditionSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(sharedPreconditionUpsertOne(data));
            }
            return dispatch(sharedPreconditionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedStep') {
          const item = sharedStepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(sharedStepUpsertOne(data));
            }
            return dispatch(sharedStepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SignupOption') {
          const item = signupOptionSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(signupOptionUpsertOne(data));
            }
            return dispatch(signupOptionFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Status') {
          const item = statusSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(statusUpsertOne(data));
            }
            return dispatch(statusFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'StatusType') {
          const item = statusTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(statusTypeUpsertOne(data));
            }
            return dispatch(statusTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Step') {
          const item = stepSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(stepUpsertOne(data));
            }
            return dispatch(stepFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SubscriptionUser') {
          const item = subscriptionUserSelectors.selectById(
            getState(),
            data.id,
          );
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(subscriptionUserUpsertOne(data));
            }
            return dispatch(subscriptionUserFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Subscriptions') {
          const item = subscriptionsSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(subscriptionsUpsertOne(data));
            }
            return dispatch(subscriptionsFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Suite') {
          const item = suiteSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              suiteFetchOneThunk({
                id: data.id,
                params: { _with: 'test,plan' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SuiteTest') {
          const item = suiteTestSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(suiteTestUpsertOne(data));
            }
            return dispatch(suiteTestFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Test') {
          const item = testSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              testFetchOneThunk({ id: data.id, params: { _with: 'suite' } }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQuality') {
          const item = testQualitySelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(testQualityUpsertOne(data));
            }
            return dispatch(testQualityFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQualityType') {
          const item = testQualityTypeSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(testQualityTypeUpsertOne(data));
            }
            return dispatch(testQualityTypeFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'User') {
          const item = userSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            return dispatch(
              userFetchOneThunk({
                id: data.id,
                params: { _with: 'accessRole,integration' },
              }),
            );
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Virtual') {
          const item = virtualSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(virtualUpsertOne(data));
            }
            return dispatch(virtualFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Watch') {
          const item = watchSelectors.selectById(getState(), data.id);
          if (isFetchNeeded && (!item || item.epoch !== data.epoch)) {
            if (!truncated) {
              return dispatch(watchUpsertOne(data));
            }
            return dispatch(watchFetchOneThunk({ id: data.id }));
          }
          return Promise.resolve();
        }
      } else if (notification.type === 'App\\Notifications\\ActivityDelete') {
        if (data.metadata_model === 'AccessRole') {
          const item = accessRoleSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(accessRoleRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AccessRoleUser') {
          const item = accessRoleUserSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(accessRoleUserRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'App') {
          const item = appSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(appRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppConfig') {
          const item = appConfigSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(appConfigRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstall') {
          const item = appInstallSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(appInstallRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppInstallProject') {
          const item = appInstallProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(appInstallProjectRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersion') {
          const item = appVersionSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(appVersionRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersion') {
          const item = appVersionPlatVersionSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(appVersionPlatVersionRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'AppVersionPlatVersionPlan') {
          const item = appVersionPlatVersionPlanSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(appVersionPlatVersionPlanRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Attachment') {
          const item = attachmentSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(attachmentRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapability') {
          const item = baseCapabilitySelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(baseCapabilityRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseCapabilityBaseIntegration') {
          const item = baseCapabilityBaseIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(baseCapabilityBaseIntegrationRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BaseIntegration') {
          const item = baseIntegrationSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(baseIntegrationRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'BillingContact') {
          const item = billingContactSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(billingContactRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Capability') {
          const item = capabilitySelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(capabilityRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CapabilityIntegration') {
          const item = capabilityIntegrationSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(capabilityIntegrationRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CasePriority') {
          const item = casePrioritySelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(casePriorityRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CaseType') {
          const item = caseTypeSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(caseTypeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckRun') {
          const item = checkRunSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(checkRunRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'CheckSuite') {
          const item = checkSuiteSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(checkSuiteRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Client') {
          const item = clientSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(clientRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Comment') {
          const item = commentSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(commentRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Coupon') {
          const item = couponSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(couponRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DataSet') {
          const item = dataSetSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(dataSetRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Defect') {
          const item = defectSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(defectRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRes') {
          const item = defectResSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(defectResRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectResNativeDefectRes') {
          const item = defectResNativeDefectResSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(defectResNativeDefectResRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectRunResult') {
          const item = defectRunResultSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(defectRunResultRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatus') {
          const item = defectStatusSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(defectStatusRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'DefectStatusNativeDefectStatus') {
          const item = defectStatusNativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(defectStatusNativeDefectStatusRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Filter') {
          const item = filterSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(filterRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Integration') {
          const item = integrationSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(integrationRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationProject') {
          const item = integrationProjectSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(integrationProjectRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationTemplate') {
          const item = integrationTemplateSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(integrationTemplateRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'IntegrationUser') {
          const item = integrationUserSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(integrationUserRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Invoice') {
          const item = invoiceSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(invoiceRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Label') {
          const item = labelSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(labelRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'LabelAssigned') {
          const item = labelAssignedSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(labelAssignedRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Milestone') {
          const item = milestoneSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(milestoneRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectRes') {
          const item = nativeDefectResSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(nativeDefectResRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'NativeDefectStatus') {
          const item = nativeDefectStatusSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(nativeDefectStatusRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Notifications') {
          const item = notificationsSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(notificationsRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plan') {
          const item = planSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(planRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPullRequest') {
          const item = planPullRequestSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(planPullRequestRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanPurpose') {
          const item = planPurposeSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(planPurposeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuite') {
          const item = planSuiteSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(planSuiteRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlanSuiteTestInclude') {
          const item = planSuiteTestIncludeSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(planSuiteTestIncludeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Plat') {
          const item = platSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(platRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PlatVersion') {
          const item = platVersionSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(platVersionRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Policy') {
          const item = policySelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(policyRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PolicyRows') {
          const item = policyRowsSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(policyRowsRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Product') {
          const item = productSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(productRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Project') {
          const item = projectSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(projectRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'PullRequest') {
          const item = pullRequestSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(pullRequestRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Purpose') {
          const item = purposeSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(purposeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Requirement') {
          const item = requirementSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(requirementRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RequirementTest') {
          const item = requirementTestSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(requirementTestRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Run') {
          const item = runSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(runRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResult') {
          const item = runResultSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(runResultRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunResultStep') {
          const item = runResultStepSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(runResultStepRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'RunSuite') {
          const item = runSuiteSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(runSuiteRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedPrecondition') {
          const item = sharedPreconditionSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(sharedPreconditionRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SharedStep') {
          const item = sharedStepSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(sharedStepRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SignupOption') {
          const item = signupOptionSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(signupOptionRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Status') {
          const item = statusSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(statusRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'StatusType') {
          const item = statusTypeSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(statusTypeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Step') {
          const item = stepSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(stepRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SubscriptionUser') {
          const item = subscriptionUserSelectors.selectById(
            getState(),
            data.id,
          );
          if (item) {
            return dispatch(subscriptionUserRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Subscriptions') {
          const item = subscriptionsSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(subscriptionsRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Suite') {
          const item = suiteSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(suiteRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'SuiteTest') {
          const item = suiteTestSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(suiteTestRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Test') {
          const item = testSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(testRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQuality') {
          const item = testQualitySelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(testQualityRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'TestQualityType') {
          const item = testQualityTypeSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(testQualityTypeRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'User') {
          const item = userSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(userRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Virtual') {
          const item = virtualSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(virtualRemoveOne(data.id));
          }
          return Promise.resolve();
        }
        if (data.metadata_model === 'Watch') {
          const item = watchSelectors.selectById(getState(), data.id);
          if (item) {
            return dispatch(watchRemoveOne(data.id));
          }
          return Promise.resolve();
        }
      }

      if (notifiableNotificationTypes.includes(notification.type)) {
        dispatch(notifiableNotificationThunk(notification));
      }
      if (watchNotificationTypes.includes(notification.type)) {
        dispatch(watchNotificationThunk(notification));
      }

      log.warn(`Unknown Notification ${notification.type}`);
    }
    return Promise.resolve();
  };
};
