import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  CompleteRunDialog,
  Drawer,
  HotkeysDialog,
  ImportDataDialog,
  MilestoneCreateDialog,
  PlanConfigurationDialog,
  StartRunForm,
  SuiteCreate,
  SuiteEditDialog,
} from 'src/components/organisms';
import AnalyzeView from 'src/components/views/AnalyzeView';
import DefectCreateView from 'src/components/views/DefectCreateView';
import GetStartedView from 'src/components/views/GetStartedView';
import GithubInstallView from 'src/components/views/GithubInstallView';
import GithubPurchasesView from 'src/components/views/GithubPurchasesView';
import HomeView from 'src/components/views/HomeView';
import MainView from 'src/components/views/MainView';
import OverviewView from 'src/components/views/OverviewView';
import PlanCreateView from 'src/components/views/PlanCreateView';
import PlanEditView from 'src/components/views/PlanEditView';
import PlansView from 'src/components/views/PlansView';
import PlanView from 'src/components/views/PlanView';
import ProjectCreateView from 'src/components/views/ProjectCreateView';
import ProjectEmptyView from 'src/components/views/ProjectEmptyView';
import ProjectView from 'src/components/views/ProjectView';
import RequirementCreateView from 'src/components/views/RequirementCreateView';
import RunEditDialog from 'src/components//organisms/RunEditDialog';
import RunsView from 'src/components/views/RunsView';
import RunView from 'src/components/views/RunView';
import SettingsRoutes from 'src/modules/settings/routes';
import TestCreateView from 'src/components/views/TestCreateView';
import TestsView from 'src/components/views/TestsView';
import authenticationRoutes from 'src/modules/authentication/routes';
import AuthRoute from './AuthRoute';
import routes from './routes';
import RouteNested from './RouteNested';
import AppRoute from './AppRoute';
import CloneDialog from '../organisms/CloneDialog';
import RequirementsView from '../views/RequirementsView';
import RequirementView from '../views/RequirementView';
import ImportAutomationDialog from '../views/ImportAutomationDialog';

export default function Routes() {
  const location = useLocation<{
    modalParentLocation?: any;
    modalName?: string;
    modalProps: any;
  }>();
  const modalParentLocation = location?.state?.modalParentLocation;
  const modalName = location?.state?.modalName;

  const CurrentDialog = {
    clone: CloneDialog,
    completeDialog: CompleteRunDialog,
    createPlan: PlanCreateView,
    editPlan: PlanEditView,
    hotkeys: HotkeysDialog,
    milestoneCreate: MilestoneCreateDialog,
    planConfiguration: PlanConfigurationDialog,
    runEdit: RunEditDialog,
    startRun: StartRunForm,
    suiteCreate: SuiteCreate,
    suiteEdit: SuiteEditDialog,
    testCreate: TestCreateView,
  }[modalName || ''];

  return (
    <>
      <Switch location={modalParentLocation || location}>
        {authenticationRoutes}
        <AppRoute
          path={routes.GITHUB_INSTALL.path}
          exact
          component={GithubInstallView}
        />
        <RouteNested as={AppRoute} component={MainView}>
          <AppRoute
            path={routes.GITHUB_MARKET.path}
            exact
            component={GithubPurchasesView}
          />
          <AuthRoute
            path={['', routes.HOME.path, routes.SITE_EMPTY.path]}
            exact
            component={HomeView}
          />
          <AuthRoute
            path={routes.GET_STARTED.path}
            exact
            component={GetStartedView}
          />
          <AuthRoute
            path={routes.PROJECT_EMPTY.path}
            exact
            component={ProjectEmptyView}
          />
          <RouteNested
            as={AuthRoute}
            path={routes.PROJECT.path}
            component={ProjectView}
            withSwitch={false}>
            <Drawer />
            <AuthRoute
              path={routes.OVERVIEW.path}
              component={OverviewView}
              exact
            />
            <AuthRoute
              path={routes.ANALYZE.path}
              component={AnalyzeView}
              exact
            />
            <AuthRoute
              path={routes.PROJECT_TESTS.path}
              exact
              component={TestsView}
            />
            <RouteNested
              path={[routes.PLAN.path, routes.PLANS.path]}
              component={PlansView}>
              <AuthRoute path={routes.PLAN.path} exact component={PlanView} />
            </RouteNested>
            <RouteNested
              path={[routes.REQUIREMENT.path, routes.REQUIREMENTS.path]}
              component={RequirementsView}>
              <AuthRoute
                path={routes.REQUIREMENT.path}
                exact
                component={RequirementView}
              />
            </RouteNested>
            <RouteNested
              path={[routes.RUNS.path, routes.RUN.path]}
              component={RunsView}
              exact>
              <AuthRoute path={routes.RUN.path} component={RunView} exact />
            </RouteNested>
          </RouteNested>
          <SettingsRoutes />
        </RouteNested>
      </Switch>

      <AnimatePresence>
        {CurrentDialog && (
          <CurrentDialog {...location?.state?.modalProps} key={modalName} />
        )}
        <Switch location={location} key={location.pathname}>
          <AuthRoute
            path={routes.PROJECT_CREATE.path}
            exact
            component={ProjectCreateView}
          />
          <AuthRoute
            path={routes.IMPORT.path}
            exact
            component={ImportDataDialog}
          />
          <AuthRoute
            path={routes.IMPORT_SLUG.path}
            exact
            component={ImportAutomationDialog}
          />
          <AuthRoute
            path={routes.PLAN_CREATE.path}
            exact
            component={PlanCreateView}
          />
          <AuthRoute
            path={routes.REQUIREMENT_CREATE.path}
            exact
            component={RequirementCreateView}
          />
          <AuthRoute
            path={routes.DEFECT_CREATE.path}
            exact
            component={DefectCreateView}
          />
        </Switch>
      </AnimatePresence>
    </>
  );
}
