import {
  getResponse,
  QueryParams,
  IntegrationProject,
  IntegrationRoute,
} from '@testquality/sdk';

import { getClient } from 'src/Client';

export function attachProject(args: {
  integrationId: number;
  projectReferenceId: string;
  projectId: number;
  userName?: string;
  org?: string;
}): Promise<IntegrationProject[]> {
  const config: QueryParams<any> = {
    method: 'post',
    url: `${IntegrationRoute()}/${args.integrationId}/attach_project`,
    data: {
      project: [
        {
          id: args.projectId,
          org: args.org || args.userName,
          project_reference_id: args.projectReferenceId,
        },
      ],
    },
  };
  return getResponse<IntegrationProject[]>(getClient().api, config);
}
