import React, { ReactElement } from 'react';
import classnames from 'classnames';
import styles from './Tabs.module.scss';

type Props<ID> = {
  className?: string;
  children: ReactElement[];
  onChange: (tab: ID) => void;
  size?: 'medium' | 'large';
  tab: ID;
  withBarBackground?: boolean;
};

function Tabs<ID>({
  className = '',
  children,
  onChange,
  size = 'medium',
  tab,
  withBarBackground = false,
}: Props<ID>) {
  const tabs = children.map((child) => {
    return React.cloneElement(child, {
      key: child.props.id,
      onClick: () => onChange(child.props.id),
      selected: tab === child.props.id,
      size,
      withBarBackground,
    });
  });

  const tabWidth = 100 / tabs.length;

  const tabIndex = children.findIndex((child) => child.props.id === tab);
  const selectedTabIndex = tabIndex === -1 ? 0 : tabIndex;

  const tabsCN = classnames(styles.tabs, className);

  return (
    <div className={tabsCN}>
      {tabs}
      <span
        className={styles.inkBar}
        style={{
          left: `${tabWidth * selectedTabIndex}%`,
          width: `${tabWidth}%`,
        }}
      />
    </div>
  );
}

export default React.memo(Tabs) as typeof Tabs;
