/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Integration,
  IntegrationApi,
  integrationGetMany,
  integrationGetOne,
  integrationDeleteOne,
  integrationUpdateOne,
  integrationCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { integrationNormalizer } from './integrationNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const integrationFetchManyThunk = createAsyncThunk(
  'integration/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Integration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await integrationGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, integrationNormalizer);
      }
      return {
        integration: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationFetchOneThunk = createAsyncThunk(
  'integration/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Integration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'integration/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await integrationGetOne(arg.id as number, arg);
      return executeNormalizer([data], integrationNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationDeleteOneThunk = createAsyncThunk(
  'integration/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Integration>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'integration/DeleteOneThunk');
      }
      return await integrationDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationUpdateOneThunk = createAsyncThunk(
  'integration/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<IntegrationApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'integration/UpdateOneThunk');
      }
      const data = await integrationUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Integration>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Integration>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationCreateOneThunk = createAsyncThunk(
  'integration/CreateOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<IntegrationApi>,
    { dispatch },
  ) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'integration/CreateOneThunk',
        );
      }
      const value = await integrationCreateOne(arg.data, arg);
      dispatch(
        integrationFetchOneThunk({
          id: value.id,
          params: { _with: 'capability' },
        }),
      );
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
