import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';
import {
  BaseCapabilityBaseIntegration,
  BaseIntegration,
} from '@testquality/sdk';
import { IntegrationType, integrationKey } from 'src/enums/IntegrationEnums';
import { baseIntegrationSelectors } from 'src/gen/domain/base_integration/baseIntegrationSelector';
import { baseCapabilityBaseIntegrationSelectors } from '../../../../gen/domain/base_capability_base_integration/baseCapabilityBaseIntegrationSelector';

export const baseIntegrationsSelector = createSelector(
  [
    (state: State) => state.gen.baseIntegration.entities,
    (state: State) => state.gen.baseCapabilityBaseIntegration.entities,
  ],
  (baseIntegrations, baseCapabilities): BaseIntegration[] => {
    return filter(baseIntegrations, (baseIntegration?: BaseIntegration) => {
      const baseCapability = find(
        baseCapabilities,
        (bc?: BaseCapabilityBaseIntegration) =>
          bc !== undefined && bc.base_integration_id === baseIntegration?.id,
      );
      return baseCapability !== undefined;
    }) as BaseIntegration[];
  },
);

export const baseIntegrationByIdSelector = createSelector(
  [
    (state: State) => state.gen.baseIntegration.entities,
    (state: State, { id }: { id?: number }) => id,
  ],
  (baseIntegrations, id) => {
    if (!id) return undefined;
    return baseIntegrations[id];
  },
);

export const githubBaseIntegrationSelector = createSelector(
  baseIntegrationSelectors.selectAll,
  (baseIntegrations) => {
    return baseIntegrations.find(
      (baseIntegration) =>
        integrationKey(baseIntegration) === IntegrationType.GITHUB,
    );
  },
);

export const jiraBaseIntegrationSelector = createSelector(
  baseIntegrationSelectors.selectAll,
  (baseIntegrations) => {
    return baseIntegrations.find(
      (baseIntegration) =>
        integrationKey(baseIntegration) === IntegrationType.JIRA,
    );
  },
);

export const baseIntegrationByBaseCapabilityIdSelector = createSelector(
  [
    baseIntegrationSelectors.selectAll,
    baseCapabilityBaseIntegrationSelectors.selectAll,
    (state: State, { baseCapabilityId }: { baseCapabilityId?: number }) =>
      baseCapabilityId,
  ],
  (baseIntegrations, baseCapabilityBaseIntegrations, id): BaseIntegration[] => {
    if (!id) return [];

    return baseIntegrations.filter((baseIntegration) => {
      return baseCapabilityBaseIntegrations.some(
        (baseCapabilityBaseIntegration) => {
          return (
            baseCapabilityBaseIntegration.base_integration_id ===
              baseIntegration.id &&
            baseCapabilityBaseIntegration.base_capability_id === id
          );
        },
      );
    });
  },
);
