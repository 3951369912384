import { App } from '@testquality/sdk';
import { useFormik } from 'formik';
import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Dialog, Input } from '@bitmodern/bit-ui';
import { appUpdateOneThunk } from 'src/gen/domain/app/appThunk';
import { useAppDispatch } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './EditAppFormDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('platForm.form.name'),
});

type Props = {
  app?: App;
} & Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>;

export default function EditAppFormDialog({ app, onClose, isOpen }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(() => ({ name: app?.name || '' }), [app]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  function onSubmit({ name }) {
    return dispatch(
      appUpdateOneThunk({ id: app?.id, data: { name, is_default: false } }),
    ).then(onClose);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title={t('editAppForm.title')}>
      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          autoFocus
          error={formikError(formik, 'name')}
          fullWidth
          label={t('editAppForm.form.name')}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('editAppForm.form.namePlaceholder')}
          value={formik.values.name}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit">
          {t('editAppForm.form.submit')}
        </Button>
      </form>
    </Dialog>
  );
}
