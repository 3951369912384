import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { routes } from 'src/components/Router';
import { useTranslation } from 'src/i18n/hooks';
import { EmailPanel, ReviewPanel } from 'src/modules/authentication/elements';
import { SignInStep1 } from './SignInStep1';
import { SignInStep2 } from './SignInStep2';
import styles from './SignInView.module.scss';

export function SignInView() {
  const { t } = useTranslation();
  const emailMatch = useRouteMatch(routes.LOGIN_EMAIL.path);
  const history = useHistory();
  const location = useLocation();
  const search = location.search;

  const onChangeStep = useCallback(
    (step) => {
      const pathname = step === 2 ? routes.LOGIN_EMAIL({}) : routes.LOGIN({});
      history.push({ search, pathname });
    },
    [history, search],
  );

  return (
    <div className={styles.main}>
      <Helmet>
        <title>{t('signIn.head.title')}</title>
      </Helmet>
      <EmailPanel
        onChangeStep={onChangeStep}
        step={emailMatch ? 2 : 1}
        Step1={SignInStep1}
        Step2={SignInStep2}
      />
      <ReviewPanel />
    </div>
  );
}
