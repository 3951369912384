/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { VirtualHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { virtualHistoryAdapter } from './virtualHistoryAdapter';
import { virtualHistoryFetchThunk } from './virtualHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const virtualHistorySlice = createSlice({
  name: 'virtualHistory',
  initialState: virtualHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    virtualHistoryCleared(state) {
      state.loading = 'idle';
      virtualHistoryAdapter.removeAll(state);
    },
    virtualHistoryUpsertMany(
      state,
      action: PayloadAction<VirtualHistory[] | Record<number, VirtualHistory>>,
    ) {
      virtualHistoryAdapter.upsertMany(state, action.payload);
    },
    virtualHistoryUpsertOne(state, action: PayloadAction<VirtualHistory>) {
      virtualHistoryAdapter.upsertOne(state, action.payload);
    },
    virtualHistoryUpdateOne(
      state,
      action: PayloadAction<Update<VirtualHistory>>,
    ) {
      virtualHistoryAdapter.updateOne(state, action.payload);
    },
    virtualHistoryRemoveOne(state, action: PayloadAction<number>) {
      virtualHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(virtualHistoryFetchThunk.pending, handlePending);
    builder.addCase(virtualHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      virtualHistoryAdapter.upsertMany(state, action.payload.virtualHistory);
    });
    builder.addCase(virtualHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  virtualHistoryCleared,
  virtualHistoryUpsertMany,
  virtualHistoryUpsertOne,
  virtualHistoryUpdateOne,
  virtualHistoryRemoveOne,
} = virtualHistorySlice.actions;

export const virtualHistoryReducer = virtualHistorySlice.reducer;
