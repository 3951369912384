/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { requirementTestAdapter } from './requirementTestAdapter';

export const requirementTestSelectors = requirementTestAdapter.getSelectors(
  (state: State) => state.gen.requirementTest,
);
