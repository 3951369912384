import React from 'react';
import { UploadIcon } from '@bitmodern/bit-ui/icons';
import { SettingsPanel } from 'src/components/organisms';
import useParams from 'src/hooks/useParams';
import routes from 'src/components/Router/routes';
import vars from 'src/export.scss';
import CSV from 'src/components/organisms/ImportDataForm/CSV';
import Gherkin from 'src/components/organisms/ImportDataForm/Gherkin';
import Junit from 'src/components/organisms/ImportDataForm/Junit';

export default function ImportDataView() {
  const { slug } = useParams<typeof routes.IMPORT_DATA.params>();

  const title = slug;
  return (
    <SettingsPanel
      icon={<UploadIcon color={vars.textPrimary} size={24} />}
      title={title}>
      {slug === 'xml' && <Junit />}
      {slug === 'csv' && <CSV />}
      {slug === 'gherkin' && <Gherkin />}
    </SettingsPanel>
  );
}
