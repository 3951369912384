import React, { CSSProperties, memo, useCallback, ReactNode } from 'react';
import { TreeItem } from 'react-sortable-tree';
import { Test, SuiteTest } from '@testquality/sdk';
import { SUITE_TYPE, TEST_TYPE } from '../TreeBase/treeTypes';
import TreeBase from '../TreeBase';
import SuiteListed from '../SuiteListed';
import TestAnalyzeListed from '../TestAnalyzeListed';

type Props = {
  current?: number;
  id: string;
  onClickTest: (test: Test, suiteTest: SuiteTest) => void;
  suitesTree: TreeItem[];
  infoTagsToRender?: (test: Test) => ReactNode;
};

function AnalyzeTree({
  current,
  id,
  onClickTest,
  suitesTree,
  infoTagsToRender,
}: Props) {
  const canNodeHaveChildren = useCallback((node) => {
    return node.type !== TEST_TYPE;
  }, []);

  const getNodeKey = useCallback((data) => {
    return data.node.nodeKey;
  }, []);

  return (
    <TreeBase
      canDrag={false}
      id={id}
      getNodeKey={getNodeKey}
      canNodeHaveChildren={canNodeHaveChildren}
      renderNodeType={{
        [TEST_TYPE]: (item) => {
          const { node, path } = item;
          const { data } = node;
          return (
            <TestAnalyzeListed
              nestingLevel={path.length - 1}
              onClick={() => onClickTest(data.test, data.suiteTest)}
              selected={data?.suiteTest?.id === current}
              suiteTest={data.suiteTest}
              test={data.test}
              infoTagsToRender={infoTagsToRender}
            />
          );
        },

        [SUITE_TYPE]: (item) => {
          const { node, path, style, toggleChildrenVisibility, treeIndex } =
            item;
          const { data } = node;
          return (
            <SuiteListed
              collapsed={node.expanded || false}
              collapsible={Boolean(node.children?.length)}
              nestingLevel={path.length - 1}
              onClick={() => {
                if (!toggleChildrenVisibility) return;
                toggleChildrenVisibility({ node, path, treeIndex });
              }}
              planSuite={data.planSuite}
              style={style as CSSProperties}
              suite={data.suite}
              withActions={false}
            />
          );
        },
      }}
      treeData={suitesTree}
    />
  );
}

export default memo(AnalyzeTree);
