import React, { useCallback } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Meter } from '@bitmodern/bit-ui';
import { productsSelector } from '@bitmodern/redux/state/products/selectors';
import {
  activeSubscriptionsSelector,
  commercialSubscriptionsSelector,
  userCommertialSubscriptionSelector,
} from '@bitmodern/redux/state/subscriptions/selectors';
import { invoicesSortedSelector } from '@bitmodern/redux/state/invoices/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { cancelSubscriptionThunk } from '@bitmodern/redux/state/subscriptions/thunks';
import BillingHistory from '../BillingHistory/BillingHistory';
import SubscriptionsList from '../SubscriptionsList';
import BillingContacts from '../BillingContacts';
import ChangeCardForm from '../ChangeCardForm';
import SubscriptionForm from '../SubscriptionForm';
import CancelSubscription from '../CancelSubscription';
import styles from './Subscription.module.scss';
import CommercialSubscription from '../CommercialSubscription';
import { subscriptionUserSelectors } from 'src/gen/domain/subscription_user/subscriptionUserSelector';

export default function Subscription() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(invoicesSortedSelector);
  const products = useAppSelector(productsSelector);
  const activeSubscription = useAppSelector(userCommertialSubscriptionSelector);
  const subscriptions = useAppSelector(commercialSubscriptionsSelector);
  const activeSubscriptions = useAppSelector(activeSubscriptionsSelector);
  const users = useAppSelector(usersSelector);
  const subscriptionUsers = useAppSelector(subscriptionUserSelectors.selectAll);

  const cancelSubscription = useCallback(async () => {
    if (!activeSubscription) return undefined;
    return dispatch(cancelSubscriptionThunk(activeSubscription.id));
  }, [dispatch, activeSubscription]);

  const seats = activeSubscriptions.reduce(
    (acu, subscription) => acu + subscription.quantity,
    0,
  );

  const currentSeats = users.length;

  return (
    <div>
      <SubscriptionForm />
      <SubscriptionsList
        products={products}
        subscriptions={subscriptions}
        users={users}
        subscriptionUsers={subscriptionUsers}
      />
      {activeSubscriptions.length > 0 && (
        <>
          <div className={styles.title}>{t('billing.managePlan.usage')}</div>
          <Meter
            label={t('billing.managePlan.seats')}
            minValue={0}
            maxValue={seats}
            value={currentSeats}
            valueLabel={`${currentSeats}/${seats}`}
          />
        </>
      )}

      {activeSubscription ? (
        <ChangeCardForm />
      ) : (
        <CommercialSubscription className={styles.commertial} />
      )}
      <BillingHistory invoices={invoices} />
      <BillingContacts />
      {activeSubscription && (
        <CancelSubscription
          className={styles.cancelSubscription}
          onCancel={cancelSubscription}
        />
      )}
    </div>
  );
}
