/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  TestQuality,
  testQualityGetMany,
  testQualityGetOne,
  testQualityDeleteOne,
  testQualityUpdateOne,
  testQualityCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { testQualityNormalizer } from './testQualityNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const testQualityFetchManyThunk = createAsyncThunk(
  'testQuality/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<TestQuality>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await testQualityGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, testQualityNormalizer);
      }
      return {
        testQuality: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityFetchOneThunk = createAsyncThunk(
  'testQuality/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<TestQuality>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'testQuality/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await testQualityGetOne(arg.id as number, arg);
      return executeNormalizer([data], testQualityNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityDeleteOneThunk = createAsyncThunk(
  'testQuality/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<TestQuality>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'testQuality/DeleteOneThunk');
      }
      return await testQualityDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityUpdateOneThunk = createAsyncThunk(
  'testQuality/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<TestQuality>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'testQuality/UpdateOneThunk');
      }
      const data = await testQualityUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<TestQuality>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<TestQuality>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityCreateOneThunk = createAsyncThunk(
  'testQuality/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<TestQuality>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'testQuality/CreateOneThunk',
        );
      }
      return await testQualityCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
