/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlatApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { platVersionNormalizer } from '../plat_version/platVersionNormalize';

export const platNormalizer = (model: PlatApi, cache: NormalizerCache) => {
  if (!cache.plat) {
    cache.plat = [];
  }
  cache.plat.push(model);

  if (model.plat_version && model.plat_version.length) {
    model.plat_version.forEach((child) => {
      platVersionNormalizer(child, cache);
    });
    model.plat_version = undefined;
  }
};
