import React, { cloneElement, ReactElement, ReactNode } from 'react';
import { useHover } from 'react-aria';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './AsideMenuItem.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  icon: ReactElement;
  selected?: boolean;
  to: string;
};

export default function AsideMenuItem({
  children,
  className = '',
  icon,
  selected = false,
  to,
}: Props) {
  const { hoverProps, isHovered } = useHover({});

  const menuItemCN = classnames(styles.menuItem, className, {
    [styles.hover]: selected || isHovered,
  });

  return (
    <li className={menuItemCN} {...hoverProps}>
      <Link to={to}>
        {cloneElement(icon, {
          className: styles.menuIcon,
        })}
        {children}
      </Link>
    </li>
  );
}
