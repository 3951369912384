/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PolicyHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { policyHistoryAdapter } from './policyHistoryAdapter';
import { policyHistoryFetchThunk } from './policyHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const policyHistorySlice = createSlice({
  name: 'policyHistory',
  initialState: policyHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    policyHistoryCleared(state) {
      state.loading = 'idle';
      policyHistoryAdapter.removeAll(state);
    },
    policyHistoryUpsertMany(
      state,
      action: PayloadAction<PolicyHistory[] | Record<number, PolicyHistory>>,
    ) {
      policyHistoryAdapter.upsertMany(state, action.payload);
    },
    policyHistoryUpsertOne(state, action: PayloadAction<PolicyHistory>) {
      policyHistoryAdapter.upsertOne(state, action.payload);
    },
    policyHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PolicyHistory>>,
    ) {
      policyHistoryAdapter.updateOne(state, action.payload);
    },
    policyHistoryRemoveOne(state, action: PayloadAction<number>) {
      policyHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(policyHistoryFetchThunk.pending, handlePending);
    builder.addCase(policyHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyHistoryAdapter.upsertMany(state, action.payload.policyHistory);
    });
    builder.addCase(policyHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  policyHistoryCleared,
  policyHistoryUpsertMany,
  policyHistoryUpsertOne,
  policyHistoryUpdateOne,
  policyHistoryRemoveOne,
} = policyHistorySlice.actions;

export const policyHistoryReducer = policyHistorySlice.reducer;
