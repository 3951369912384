import React, { LabelHTMLAttributes, ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { useFocusAccent } from '../hooks/useFocusAccent';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';
import styles from './Field.module.scss';

type Props = {
  children: (borderProps, ref) => ReactNode;
  className?: string;
  endAdornment?: ReactNode;
  error?: ReactNode;
  focused?: boolean;
  fullWidth?: boolean;
  info?: ReactNode;
  isDisabled?: boolean;
  label?: ReactNode;
  labelClassName?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  required?: boolean;
  showLabel?: boolean;
  size?: 'small' | 'medium';
  startAdornment?: ReactNode;
  isReadOnly?: boolean;
};

export default function Field({
  children,
  className = '',
  endAdornment,
  error,
  focused,
  fullWidth = false,
  info,
  isDisabled,
  label,
  labelClassName = '',
  labelProps = {},
  required,
  showLabel = true,
  size = 'medium',
  startAdornment,
  isReadOnly,
}: Props) {
  const { borderClassName, borderProps } = useFocusAccent({
    isFocused: focused,
  });
  const innerRef = useRef<any>(null);

  const onClickInputWrap = () => {
    if (innerRef.current && innerRef.current.focus) {
      innerRef.current.focus();
    }
  };

  const inputWrapCN = classnames(styles.inputWrap, styles[size], className, {
    [styles.fullwidth]: fullWidth,
    [styles.withEndAdornment]: !!endAdornment,
    [styles.disabled]: isDisabled,
    [borderClassName]: !isDisabled && !isReadOnly,
  });

  return (
    <>
      {showLabel && (
        <FieldLabel
          className={labelClassName}
          required={required}
          {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <div className={inputWrapCN} onClick={onClickInputWrap} {...borderProps}>
        {startAdornment && (
          <div className={styles.startAdornment}>{startAdornment}</div>
        )}
        <div className={styles.input}>{children(borderProps, innerRef)}</div>
        {endAdornment && (
          <div className={styles.endAdornment}>{endAdornment}</div>
        )}
      </div>
      {info}
      {error && <FieldError>{error}</FieldError>}
    </>
  );
}

export function useFieldProps(props) {
  return {
    className: props.className,
    endAdornment: props.endAdornment,
    error: props.error,
    focused: props.focused,
    fullWidth: props.fullWidth,
    info: props.info,
    isDisabled: props.isDisabled,
    label: props.label,
    labelClassName: props.labelClassName,
    required: props.required,
    showLabel: props.showLabel,
    size: props.size,
    startAdornment: props.startAdornment,
    isReadOnly: props.isReadOnly,
  };
}
