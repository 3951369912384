import { createAction } from '@reduxjs/toolkit';
import { State } from './reducer';

type FilterPath<T extends keyof State = any> = {
  type: T;
  filter: keyof State[T];
};

export const toggleFilterAction = createAction<
  FilterPath & {
    value: any;
  }
>('filters/toggleFilter');

export const emptyFiltersAction = createAction<FilterPath>(
  'filters/emptyFilters',
);

export const clearAllFiltersAction = createAction<FilterPath['type']>(
  'filters/clearAllFilters',
);
