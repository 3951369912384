/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { statusTypeAdapter } from './statusTypeAdapter';

export const statusTypeSelectors = statusTypeAdapter.getSelectors(
  (state: State) => state.gen.statusType,
);
