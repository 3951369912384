import { Test, TestHistory } from '@testquality/sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import AnalyzeStats from 'src/components/organisms/AnalyzeStats';
import { DateRange } from 'src/common/DataRange';
import vars from 'src/export.scss';
import styles from './GrowthMetrics.module.scss';

type Props = {
  tests: Test[];
  testHistory: TestHistory[];
  range: DateRange;
  className?: string;
};

export default function GrowthMetrics({
  tests,
  testHistory,
  range,
  className = '',
}: Props) {
  const { t } = useTranslation();

  const { totalAdded, totalDeleted, percentageManual } = useMemo(() => {
    const filteredTests = tests.filter((test) => {
      return (
        new Date(test.created_at).getTime() >= range[0].getTime() &&
        new Date(test.created_at).getTime() <= range[1].getTime()
      );
    });
    const manualTests = filteredTests.filter((test) => !test.is_automated);
    const deletedTests = testHistory.filter(
      (test) =>
        test.operation === 'delete' &&
        new Date(test.updated_at).getTime() >= range[0].getTime() &&
        new Date(test.updated_at).getTime() <= range[1].getTime(),
    );

    return {
      totalAdded: filteredTests.length,
      totalDeleted: deletedTests.length,
      percentageManual:
        Math.floor((manualTests.length / filteredTests.length) * 10000) / 100 ||
        0,
    };
  }, [tests, testHistory, range]);

  return (
    <div className={`${styles.container} ${className}`}>
      <AnalyzeStats
        title={t('analyze.testGrowth.metrics.totalTests.title')}
        subtitle={t('analyze.testGrowth.metrics.totalTests.subtitle')}
        stat={tests.length}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        title={t('analyze.testGrowth.metrics.totalAdded.title')}
        subtitle={t('analyze.testGrowth.metrics.totalAdded.subtitle')}
        stat={totalAdded}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        title={t('analyze.testGrowth.metrics.percentageManual.title')}
        subtitle={t('analyze.testGrowth.metrics.percentageManual.subtitle')}
        stat={`${percentageManual}%`}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        title={t('analyze.testGrowth.metrics.totalDeleted.title')}
        subtitle={t('analyze.testGrowth.metrics.totalDeleted.subtitle')}
        stat={totalDeleted}
        statColor={vars.warningMain}
      />
    </div>
  );
}
