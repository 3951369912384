/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectUser } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectUserAdapter } from './defectUserAdapter';
import { defectUserFetchManyThunk } from './defectUserThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectUserSlice = createSlice({
  name: 'defectUser',
  initialState: defectUserAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectUserCleared(state) {
      state.loading = 'idle';
      defectUserAdapter.removeAll(state);
    },
    defectUserUpsertMany(
      state,
      action: PayloadAction<DefectUser[] | Record<number, DefectUser>>,
    ) {
      defectUserAdapter.upsertMany(state, action.payload);
    },
    defectUserUpsertOne(state, action: PayloadAction<DefectUser>) {
      defectUserAdapter.upsertOne(state, action.payload);
    },
    defectUserUpdateOne(state, action: PayloadAction<Update<DefectUser>>) {
      defectUserAdapter.updateOne(state, action.payload);
    },
    defectUserRemoveOne(state, action: PayloadAction<number>) {
      defectUserAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectUserFetchManyThunk.pending, handlePending);
    builder.addCase(defectUserFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectUserAdapter.upsertMany(state, action.payload.defectUser);
    });
    builder.addCase(defectUserFetchManyThunk.rejected, handleRejected);
  },
});

export const {
  defectUserCleared,
  defectUserUpsertMany,
  defectUserUpsertOne,
  defectUserUpdateOne,
  defectUserRemoveOne,
} = defectUserSlice.actions;

export const defectUserReducer = defectUserSlice.reducer;
