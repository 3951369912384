import React from 'react';
import { Link } from 'react-router-dom';
import useParams from 'src/hooks/useParams';
import { Avatar, TableCell, TableRow } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import { RunResult, Status } from '@testquality/sdk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { runSelector } from '@bitmodern/redux/state/runs/selectors';
import { userSelectorById } from '@bitmodern/redux/state/users/selectors';

import StatusIcon from '../StatusIcon';
import styles from './RunResultHistoryItem.module.scss';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';
import { testSelectors } from '../../../gen/domain/test/testSelector';

type Props = {
  runResult: RunResult;
  withRunResultName: boolean;
  defectId?: number;
};

export default function RunHistoryItem({
  runResult,
  withRunResultName,
  defectId,
}: Props) {
  const { t } = useTranslation();
  const { generateDrawerUrlParams } = useDrawerManager();
  const { site } = useParams<typeof routes.HOME.params>();

  const status = useAppSelector((state) => statusSelectors.selectAll(state));
  const runResultStatus = status.find(
    (s) => s.id === runResult.status_id,
  ) as Status;
  const test = useAppSelector((state) =>
    testSelectors.selectById(state, runResult.test_id),
  );
  const run = useAppSelector((state) => runSelector(state, runResult.run_id));
  const assignedTo = useAppSelector((state) =>
    userSelectorById(state, runResult.assigned_to_tester || -1),
  );

  const pathToRun = routes.RUN({
    site,
    projectId: runResult.project_id.toString(),
    runId: runResult.run_id.toString(),
  });
  const pathToRunResult = `${pathToRun}?${generateDrawerUrlParams({
    resultId: runResult.id,
  })}`;
  const pathToDefect = `${pathToRun}?${generateDrawerUrlParams({
    defectId: defectId || 0,
  })}`;

  return (
    <TableRow>
      <TableCell>
        <Link className={styles.run} to={defectId ? pathToDefect : pathToRun}>
          {run?.name}
        </Link>
      </TableCell>
      {withRunResultName && (
        <TableCell>
          <Link className={styles.run} to={pathToRunResult}>
            {test?.name}
          </Link>
        </TableCell>
      )}
      <TableCell>
        <div className={styles.status}>
          <StatusIcon status={runResultStatus} size={16} />
          <div className={styles.statusName}>{runResultStatus.name}</div>
        </div>
      </TableCell>
      <TableCell>
        {assignedTo && (
          <div className={styles.assigned}>
            <Avatar
              className={styles.avatar}
              size={22}
              src={processAttachmentPath(assignedTo.picture)}
              name={formatUserName(assignedTo)}
            />
            <span>{formatUserName(assignedTo)}</span>
          </div>
        )}
      </TableCell>
      <TableCell className={styles.secondary}>
        {t('runResultHistoryItem.updatedAt', {
          date: new Date(runResult.updated_at),
        })}
      </TableCell>
    </TableRow>
  );
}
