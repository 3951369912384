/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  RunResult,
  runResultGetMany,
  runResultGetOne,
  runResultDeleteOne,
  runResultUpdateOne,
  runResultCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { runResultNormalizer } from './runResultNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const runResultFetchManyThunk = createAsyncThunk(
  'runResult/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<RunResult>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await runResultGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, runResultNormalizer);
      }
      return {
        runResult: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runResultFetchOneThunk = createAsyncThunk(
  'runResult/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<RunResult>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'runResult/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await runResultGetOne(arg.id as number, arg);
      return executeNormalizer([data], runResultNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runResultDeleteOneThunk = createAsyncThunk(
  'runResult/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<RunResult>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'runResult/DeleteOneThunk');
      }
      return await runResultDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runResultUpdateOneThunk = createAsyncThunk(
  'runResult/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<RunResult>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'runResult/UpdateOneThunk');
      }
      const data = await runResultUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<RunResult>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<RunResult>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runResultCreateOneThunk = createAsyncThunk(
  'runResult/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<RunResult>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'runResult/CreateOneThunk',
        );
      }
      return await runResultCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
