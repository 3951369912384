import { useMemo } from 'react';
import { Impersonate } from '@bitmodern/redux/domain/auth/Impersonate';
import { useQuery } from 'src/hooks/useQuery';

import Debug from 'debug';
const debug = Debug('useImpersonate');

function getImpersonation(
  userId?: string,
  clientId?: string,
  email?: string,
): Impersonate | undefined {
  let res: Impersonate | undefined;
  if (userId) {
    res = { user_id: parseInt(userId, 10) };
  } else if (clientId) {
    res = { client_id: parseInt(clientId, 10) };
  } else if (email) {
    res = { email };
  }
  debug('impersonate:', res);
  return res;
}

export default function useImpersonate() {
  const {
    client_id: clientId,
    email,
    user_id: userId,
  } = useQuery(['client_id', 'email', 'user_id']);

  const impersonate = useMemo(
    () => getImpersonation(userId, clientId, email),
    [clientId, email, userId],
  );

  return impersonate;
}
