/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  PlanPurpose,
  planPurposeDetach,
  planPurposeUpdateOne,
  planPurposeCreateOne,
  planPurposeGetMany,
  planPurposeGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const planPurposeDetachThunk = createAsyncThunk(
  'planPurpose/DetachThunk',
  async (arg: QueryParams<PlanPurpose>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'planPurpose/DetachThunk',
      );
    }
    return planPurposeDetach(arg.data, arg).then(() => arg.data);
  },
);

export const planPurposeUpdateOneThunk = createAsyncThunk(
  'planPurpose/UpdateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanPurpose>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'planPurpose/UpdateOneThunk');
      }
      const data = await planPurposeUpdateOne(arg.id as number, arg.data, arg);
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<PlanPurpose>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planPurposeCreateOneThunk = createAsyncThunk(
  'planPurpose/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanPurpose>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'planPurpose/CreateOneThunk',
        );
      }
      return await planPurposeCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planPurposeFetchManyThunk = createAsyncThunk(
  'planPurpose/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PlanPurpose>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await planPurposeGetMany(inlineArgs);
      return {
        planPurpose: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planPurposeFetchOneThunk = createAsyncThunk(
  'planPurpose/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PlanPurpose>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'planPurpose/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await planPurposeGetOne(arg.id as number, arg);
      return {
        planPurpose: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
