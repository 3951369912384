/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { DefectResApi } from '@testquality/sdk';
import { DefectResNativeDefectRes } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { nativeDefectResNormalizer } from '../native_defect_res/nativeDefectResNormalize';
import { defectResNativeDefectResNormalizer } from '../defect_res_native_defect_res/defectResNativeDefectResNormalize';
import { defectNormalizer } from '../defect/defectNormalize';
import { requirementNormalizer } from '../requirement/requirementNormalize';

export const defectResNormalizer = (
  model: DefectResApi,
  cache: NormalizerCache,
) => {
  if (!cache.defectRes) {
    cache.defectRes = [];
  }
  cache.defectRes.push(model);

  if (model.native_defect_res && model.native_defect_res.length) {
    model.native_defect_res.forEach((child) => {
      defectResNativeDefectResNormalizer(
        child.pivot as DefectResNativeDefectRes,
        cache,
      );
      child.pivot = undefined;
      nativeDefectResNormalizer(child, cache);
    });
    model.native_defect_res = undefined;
  }
  if (model.defect && model.defect.length) {
    model.defect.forEach((child) => {
      defectNormalizer(child, cache);
    });
    model.defect = undefined;
  }
  if (model.requirement && model.requirement.length) {
    model.requirement.forEach((child) => {
      requirementNormalizer(child, cache);
    });
    model.requirement = undefined;
  }
};
