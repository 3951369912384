import { useOverlayTriggerState } from 'react-stately';
import React, { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Button } from '@bitmodern/bit-ui';
import { DeleteIcon } from '@bitmodern/bit-ui/icons';
import {
  ConfirmDialog,
  DescriptiveAction,
  IntegrationTagsDialog,
  IntegrationTemplatesDialog,
  SettingsPanel,
} from 'src/components/organisms';
import {
  Integration,
  IntegrationUser,
  BaseIntegration,
  BatchService,
} from '@testquality/sdk';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';
import {
  integrationKey,
  IntegrationType,
  JiraInstanceType,
} from 'src/enums/IntegrationEnums';
import BaseIntegrationIcon from 'src/components/organisms/BaseIntegrationIcon';
import { capabilityIntegrationUpdateOneThunk } from 'src/gen/domain/capability_integration/capabilityIntegrationThunk';
import { capabilityIntegrationBySchemaSelector } from '@bitmodern/redux/state/capabilityIntegration/selectors';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';
import { routes } from 'src/components/Router';
import { useAppSelector, useAppDispatch } from '@bitmodern/redux/store';
import { useLocation } from 'react-router-dom';
import useParams from 'src/hooks/useParams';
import JiraIntegrationForm from 'src/components/organisms/JiraIntegrationForm';
import { useQuery } from 'src/hooks/useQuery';
import { JiraAppSetup } from 'src/components/organisms/JiraAppSetup/JiraAppSetup';
import { getEnv } from 'src/env';
import vars from 'src/export.scss';
import styles from './IntegrationSetupView.module.scss';

type Props = {
  baseIntegration?: BaseIntegration;
  integration?: Integration;
  integrationUser?: IntegrationUser;
  onAuthorize: () => Promise<any>;
  onConfirmDelete: () => Promise<any>;
  onSubmitJira: ComponentProps<typeof JiraIntegrationForm>['onSubmit'];
  onDisassociate: () => Promise<any>;
};

export default function IntegrationSetupView({
  baseIntegration,
  integration,
  integrationUser,
  onAuthorize,
  onConfirmDelete,
  onSubmitJira,
  onDisassociate,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { site } = useParams<typeof routes.INTEGRATION_SETUP.params>();
  const { has_auth, project_create, has_project_auth } = useQuery([
    'has_auth',
    'project_create',
    'has_project_auth',
  ]);
  const { state }: { state: { openTemplatesDialog?: boolean } } = useLocation();
  const integrationId = integration?.id.toString() || '';
  const dispatch = useAppDispatch();
  const deleteDialog = useOverlayTriggerState({});
  const tagsDialog = useOverlayTriggerState({});
  const templatesDialog = useOverlayTriggerState({
    defaultOpen: !!state?.openTemplatesDialog,
  });
  const capabilityIntegrationDefectSelector = useMemo(
    () =>
      capabilityIntegrationBySchemaSelector(
        IntegrationSchema.DEFECT,
        integration?.id,
      ),
    [integration],
  );
  const defectCapabilityIntegration = useAppSelector(
    capabilityIntegrationDefectSelector,
  );
  const capabilityIntegrationRequirementSelector = useMemo(
    () =>
      capabilityIntegrationBySchemaSelector(
        IntegrationSchema.REQUIREMENT,
        integration?.id,
      ),
    [integration],
  );
  const requirementCapabilityIntegration = useAppSelector(
    capabilityIntegrationRequirementSelector,
  );

  const initialValues = useMemo(
    () => ({
      requirementTag: requirementCapabilityIntegration?.value || '',
      defectTag: defectCapabilityIntegration?.value || '',
    }),
    [defectCapabilityIntegration, requirementCapabilityIntegration],
  );

  const handleTagsUpdate = useCallback(
    (values: typeof initialValues) => {
      const batch = new BatchService();
      if (values.defectTag !== initialValues.defectTag) {
        dispatch(
          capabilityIntegrationUpdateOneThunk({
            id: defectCapabilityIntegration?.id,
            data: { value: values.defectTag },
            batch,
          }),
        );
      }
      if (values.requirementTag !== initialValues.requirementTag) {
        dispatch(
          capabilityIntegrationUpdateOneThunk({
            id: requirementCapabilityIntegration?.id,
            data: { value: values.requirementTag },
            batch,
          }),
        );
      }
      return batch.executeBatch().then(tagsDialog.close);
    },
    [
      defectCapabilityIntegration,
      requirementCapabilityIntegration,
      dispatch,
      initialValues,
      tagsDialog,
    ],
  );

  const userIsAuthorized = Boolean(integrationUser?.access_token);
  const integrationType = baseIntegration
    ? integrationKey(baseIntegration)
    : IntegrationType.UNKNOWN;

  const onDelete = useCallback(() => {
    return onConfirmDelete().finally(deleteDialog.close);
  }, [deleteDialog, onConfirmDelete]);

  const deleteMutation = useMutation(onDelete);
  const authorizeMutation = useMutation(onAuthorize);
  const disassociateMutation = useMutation(onDisassociate);

  const integrationName = useMemo(() => {
    if (!baseIntegration) return '';
    return `${baseIntegration.name} Integration`;
  }, [baseIntegration]);

  const getIntegrationSetupContent = () => {
    if (!baseIntegration) return null;
    if (integrationType === IntegrationType.JIRA) {
      const jiraInitialValues = integration
        ? {
            jiraInstance: integration.name as JiraInstanceType,
            org: integration.org,
            serverUrl: integration.default_url,
            appVersion: integration.app_version,
          }
        : undefined;
      if (
        jiraInitialValues?.jiraInstance !== JiraInstanceType.Server &&
        getEnv().features.jiraAppIntegration
      ) {
        return (
          <JiraAppSetup
            appVersion={jiraInitialValues?.appVersion}
            jiraInstance={jiraInitialValues?.jiraInstance}></JiraAppSetup>
        );
      }

      return (
        <JiraIntegrationForm
          baseIntegration={baseIntegration}
          initialValues={jiraInitialValues}
          integration={integration}
          onSubmit={onSubmitJira}
        />
      );
    }

    if (integrationType === IntegrationType.GITHUB) {
      return (
        <div className={styles.githubSetup}>
          Use our GitHub native two-way integration to Link requirements and
          defects directly into your GitHub repository. Push and receive changes
          to the defects or simply read your developer’s comments and reply
          straight back in the TestQuality UI!
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (has_auth && userIsAuthorized) {
      history.push(
        routes.INTEGRATION_PROJECT_CREATE({
          site,
          integrationId,
        }),
      );
    } else if (has_project_auth && userIsAuthorized && integration) {
      history.push({
        pathname: routes.PROJECT_CREATE({
          site,
        }),
        search: `?integration_id=${integration.id}`,
      });
    }
  }, [
    has_auth,
    has_project_auth,
    userIsAuthorized,
    history,
    site,
    integrationId,
    integration,
  ]);

  function showSetupFields() {
    if (getEnv().features.jiraAppIntegration) {
      return (
        integrationType === IntegrationType.GITHUB ||
        integration?.name === JiraInstanceType.Server
      );
    }
    return true;
  }

  return (
    <>
      <Helmet>
        <title>{t('integrationSetup.head.title')}</title>
      </Helmet>
      <SettingsPanel
        back={
          project_create
            ? routes.PROJECT_CREATE({ site })
            : routes.INTEGRATIONS({ site })
        }
        icon={
          <BaseIntegrationIcon
            baseIntegration={baseIntegration}
            color={vars.textPrimary}
            size={24}
          />
        }
        title={integrationName}>
        {getIntegrationSetupContent()}
        {showSetupFields() && (
          <DescriptiveAction
            action={
              <Button
                loading={authorizeMutation.isLoading}
                onClick={authorizeMutation.mutate}
                size="small">
                {userIsAuthorized
                  ? t('integrationSetup.actions.reAuthorize')
                  : t('integrationSetup.actions.authorize')}
              </Button>
            }
            description={
              userIsAuthorized
                ? t('integrationSetup.authorized', {
                    integrationName: integration?.name || '',
                  })
                : t('integrationSetup.notAuthorized')
            }
            title={t('integrationSetup.authorization')}
          />
        )}

        {userIsAuthorized && showSetupFields() && (
          <DescriptiveAction
            action={
              <Button
                onClick={() =>
                  history.push(
                    routes.INTEGRATION_PROJECT_CREATE({
                      site,
                      integrationId,
                    }),
                  )
                }
                size="small">
                {t('integrationSetup.actions.addProject')}
              </Button>
            }
            description={t('integrationSetup.addProjectDescription', {
              integrationName: integration?.name || '',
            })}
            title={t('integrationSetup.actions.addProject')}
          />
        )}
        {integrationType === IntegrationType.GITHUB && integration && (
          <DescriptiveAction
            action={
              <Button onClick={tagsDialog.open} size="small">
                {t('integrationSetup.integrationTags.button')}
              </Button>
            }
            description={t('integrationSetup.integrationTags.description')}
            title={t('integrationSetup.integrationTags.title')}
          />
        )}
        {integration && (
          <DescriptiveAction
            action={
              <Button
                color="accent"
                onClick={templatesDialog.open}
                size="small">
                {t('integrationSetup.integrationTemplates.action')}
              </Button>
            }
            description={t('integrationSetup.integrationTemplates.description')}
            title={t('integrationSetup.integrationTemplates.title')}
          />
        )}
        {integration && (
          <DescriptiveAction
            action={
              <Button
                color="error"
                icon={<DeleteIcon color={vars.onError} size={18} />}
                onClick={deleteDialog.open}
                size="small">
                {t('integrationSetup.delete.button')}
              </Button>
            }
            description={t('integrationSetup.delete.description')}
            title={t('integrationSetup.delete.title')}
          />
        )}

        {userIsAuthorized && (
          <DescriptiveAction
            action={
              <Button
                color="error"
                loading={disassociateMutation.isLoading}
                onClick={disassociateMutation.mutate}
                size="small">
                {t('integrationSetup.actions.disassociate')}
              </Button>
            }
            description={t('integrationSetup.disassociationDescription')}
            title={t('integrationSetup.disassociation')}
          />
        )}
      </SettingsPanel>
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('integrationSetup.deleteDialog.title')}>
        {t('integrationSetup.deleteDialog.content')}
      </ConfirmDialog>
      {integration && (
        <IntegrationTagsDialog
          isOpen={tagsDialog.isOpen}
          onClose={tagsDialog.close}
          initialValues={initialValues}
          onSubmit={handleTagsUpdate}
        />
      )}
      {integration && (
        <IntegrationTemplatesDialog
          integration={integration}
          isOpen={templatesDialog.isOpen}
          onClose={templatesDialog.close}
        />
      )}
    </>
  );
}
