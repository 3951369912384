/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  SharedPrecondition,
  sharedPreconditionGetMany,
  sharedPreconditionGetOne,
  sharedPreconditionDeleteOne,
  sharedPreconditionUpdateOne,
  sharedPreconditionCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { sharedPreconditionNormalizer } from './sharedPreconditionNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const sharedPreconditionFetchManyThunk = createAsyncThunk(
  'sharedPrecondition/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<SharedPrecondition>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await sharedPreconditionGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(
          resourceList.data,
          sharedPreconditionNormalizer,
        );
      }
      return {
        sharedPrecondition: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const sharedPreconditionFetchOneThunk = createAsyncThunk(
  'sharedPrecondition/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<SharedPrecondition>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'sharedPrecondition/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await sharedPreconditionGetOne(arg.id as number, arg);
      return executeNormalizer([data], sharedPreconditionNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const sharedPreconditionDeleteOneThunk = createAsyncThunk(
  'sharedPrecondition/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<SharedPrecondition>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'sharedPrecondition/DeleteOneThunk',
        );
      }
      return await sharedPreconditionDeleteOne(arg.id as number, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const sharedPreconditionUpdateOneThunk = createAsyncThunk(
  'sharedPrecondition/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<SharedPrecondition>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'sharedPrecondition/UpdateOneThunk',
        );
      }
      const data = await sharedPreconditionUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<SharedPrecondition>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<SharedPrecondition>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const sharedPreconditionCreateOneThunk = createAsyncThunk(
  'sharedPrecondition/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<SharedPrecondition>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'sharedPrecondition/CreateOneThunk',
        );
      }
      return await sharedPreconditionCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
