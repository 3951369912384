/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectTypeAdapter } from './defectTypeAdapter';

export const defectTypeSelectors = defectTypeAdapter.getSelectors(
  (state: State) => state.gen.defectType,
);
