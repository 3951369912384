import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FlatList, Surface } from '@bitmodern/bit-ui';
import { Option } from '@bitmodern/bit-ui/Select/Select';
import { routes } from 'src/components/Router';
import EmptyList from 'src/components/organisms/EmptyList';
import { PlayIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { RunFilter } from 'src/enums/RunFilterEnum';
import { Run } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import useParams from 'src/hooks/useParams';
import RunsFilter from './components/RunsFilter';
import RunItem from '../RunItem';
import styles from './Runs.module.scss';

type Props = {
  className?: string;
  filter: RunFilter;
  filters: Option[];
  search: string;
  onChangeFilter: (option) => void;
  onChangeSearch: (text) => void;
  runs: Run[];
};

export function Runs({
  className = '',
  filter,
  filters,
  search,
  onChangeFilter,
  onChangeSearch,
  runs,
}: Props) {
  const { site, projectId, runId } = useParams<typeof routes.RUN.params>();
  const { t } = useTranslation();
  const currentRunId = parseInt(runId, 10);

  const context = useMemo(
    () => ({
      currentRunId,
      projectId,
      site,
    }),
    [currentRunId, projectId, site],
  );

  const renderItem = useCallback(({ item, context }) => {
    return (
      <Link
        className={styles.run}
        key={item.id}
        to={routes.RUN({
          site: context.site,
          projectId: context.projectId,
          runId: String(item.id),
        })}>
        <RunItem run={item} selected={context.currentRunId === item.id} />
      </Link>
    );
  }, []);

  const keyExtractor = useCallback((item) => item.id, []);

  const renderEmpty = useCallback(
    () => (
      <EmptyList
        className={styles.empty}
        description={t('runs.empty.description')}
        icon={<PlayIcon color={vars.textSecondary} size={72} />}
        size="small"
        variant="secondary"
        title={t('runs.empty.title')}
      />
    ),
    [t],
  );

  return (
    <Surface className={`${styles.runs} ${className}`}>
      <RunsFilter
        onChangeFilter={onChangeFilter}
        onChangeSearch={onChangeSearch}
        options={filters}
        value={filter}
        search={search}
      />

      <div className={styles.content}>
        <FlatList
          className={styles.list}
          context={context}
          data={runs}
          gap={8}
          keyExtractor={keyExtractor}
          renderEmpty={renderEmpty}
          renderItem={renderItem}
          scrollToElement={currentRunId}
          scrollClassName={styles.scroll}
        />
      </div>
    </Surface>
  );
}
