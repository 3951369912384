/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  CheckRun,
  checkRunGetMany,
  checkRunGetOne,
  checkRunDeleteOne,
  checkRunUpdateOne,
  checkRunCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { checkRunNormalizer } from './checkRunNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const checkRunFetchManyThunk = createAsyncThunk(
  'checkRun/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CheckRun>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await checkRunGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, checkRunNormalizer);
      }
      return {
        checkRun: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const checkRunFetchOneThunk = createAsyncThunk(
  'checkRun/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CheckRun>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'checkRun/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await checkRunGetOne(arg.id as number, arg);
      return executeNormalizer([data], checkRunNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const checkRunDeleteOneThunk = createAsyncThunk(
  'checkRun/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CheckRun>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'checkRun/DeleteOneThunk');
      }
      return await checkRunDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const checkRunUpdateOneThunk = createAsyncThunk(
  'checkRun/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<CheckRun>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'checkRun/UpdateOneThunk');
      }
      const data = await checkRunUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<CheckRun>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<CheckRun>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const checkRunCreateOneThunk = createAsyncThunk(
  'checkRun/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CheckRun>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'checkRun/CreateOneThunk',
        );
      }
      return await checkRunCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
