import React, { ReactNode } from 'react';
import Surface from '../Surface';
import styles from './Menu.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function Menu({ children, className = '' }: Props) {
  return (
    <Surface className={`${styles.menu} ${className}`}>
      <ul>{children}</ul>
    </Surface>
  );
}
