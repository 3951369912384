import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { walk } from 'react-sortable-tree';
import { useHistory } from 'react-router';
import { showNotificationError } from '@testquality/sdk';
import Yup from 'src/utils/yup';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { selectLastRun } from '@bitmodern/redux/state/runs/selectors';
import { milestonesByProjectSelector } from '@bitmodern/redux/state/milestones/selectors';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { routes } from 'src/components/Router';
import { leadingZeros } from 'src/utils/common';
import {
  planByIdSelector,
  plansByProjectSelector,
  rootPlanSelector,
} from '@bitmodern/redux/state/plans/selectors';
import {
  virtualsByTableNameSelector,
  requiredVirtualsByTableNameSelector,
} from '@bitmodern/redux/state/virtuals/selectors';
import { createRunThunk } from '@bitmodern/redux/state/runs/thunks';
import { planTreeFilteredSelector } from '@bitmodern/redux/state/suites/selectors';
import isEmpty from 'lodash/isEmpty';
import { appVersionPlatVersionsByPlanSelector } from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import {
  buildEmptyVirtuals,
  buildRequiredVirtualsValidationSchema,
  buildRequiredVirtualsInitialValues,
} from 'src/components/views/TestCreateView/TestCreateViewContainer';
import { generateSuiteTestNodeKey } from 'src/utils/tree';
import StartRunForm, { FormValues } from './StartRunForm';
import { TEST_TYPE } from '../TreeBase/treeTypes';

type Props = {
  planId?: number;
  suiteTestsIds?: number[];
  milestoneId?: number;
  isPermanent?: boolean;
};

export default function StartRunFormContainer({
  planId,
  suiteTestsIds,
  milestoneId,
  isPermanent,
}: Props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [checkeds, setCheckeds] = useState({});
  const [suiteTests, setSuiteTests] = useState(suiteTestsIds);

  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const projectIdNum = parseInt(projectId, 10);
  const milestones = useAppSelector((state) =>
    milestonesByProjectSelector(state, projectIdNum),
  );
  const plans = useAppSelector((state) =>
    plansByProjectSelector(state, { projectId: projectIdNum }),
  );
  const lastRun = useAppSelector((state) => selectLastRun(state, projectIdNum));
  const avpvp = useAppSelector((state) =>
    appVersionPlatVersionsByPlanSelector(state, planId),
  );
  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, projectIdNum),
  )!;
  const virtuals = useAppSelector((state) =>
    virtualsByTableNameSelector(state, 'run'),
  );

  const requiredVirtuals = useAppSelector((state) =>
    requiredVirtualsByTableNameSelector(state, 'run'),
  );

  const validationSchema = Yup.object().shape({
    ...buildRequiredVirtualsValidationSchema(requiredVirtuals),
    name: Yup.string().required(),
  });
  const initialStartTime = useMemo(() => new Date(), []);

  const initialValues = useMemo<FormValues>(
    () => ({
      name: lastRun
        ? `Run ${leadingZeros(lastRun.key, 3)}`
        : `Run ${leadingZeros(1, 3)}`,
      startTime: initialStartTime,
      plan: planId,
      appVersionPlatVersions: avpvp.map((avpv) => avpv.id),
      milestone: milestoneId,
      ...(!isEmpty(requiredVirtuals) && {
        ...buildRequiredVirtualsInitialValues(requiredVirtuals),
      }),
    }),
    [avpvp, lastRun, planId, milestoneId, requiredVirtuals, initialStartTime],
  );

  const onSubmit = useCallback(
    ({
      appVersionPlatVersions,
      milestone,
      name,
      plan,
      startTime,
      ...rest
    }: FormValues) => {
      return dispatch(
        createRunThunk({
          appVersionPlatVersions,
          is_permanent: isPermanent || false,
          milestone,
          name,
          planId: plan || rootPlan.id,
          projectId: parseInt(projectId, 10),
          startTime,
          suiteTestsIds: suiteTests,
          ...(!isEmpty(virtuals) && {
            virtual: { ...buildEmptyVirtuals(virtuals), ...rest },
          }),
        }),
      ).then((res) => {
        if (res.payload) {
          const runId = res.payload.id;
          history.push(routes.RUN({ site, projectId, runId }));
        }
      }, showNotificationError);
    },
    [
      dispatch,
      history,
      isPermanent,
      projectId,
      rootPlan,
      suiteTests,
      site,
      virtuals,
    ],
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validateOnMount: true,
    validationSchema,
  });

  const tree = useAppSelector((state) => {
    const plan = formik.values.plan
      ? planByIdSelector(state, formik.values.plan)
      : undefined;

    return planTreeFilteredSelector(state, {
      projectId: parseInt(projectId, 10),
      planId: formik.values.plan,
      filterType: plan?.is_root === false ? 'plans' : 'tests',
    });
  });

  useEffect(() => {
    if (suiteTestsIds?.length && planId === formik.values.plan) {
      setCheckeds(
        suiteTestsIds.reduce((acu, stId) => {
          const nodeKey = generateSuiteTestNodeKey(stId);
          acu[nodeKey] = true;
          return acu;
        }, {}),
      );
    } else {
      // set all checked when plan change
      const nextCheckeds = {};
      walk({
        treeData: tree,
        getNodeKey: (item) => item.node.nodeKey,
        callback: (item) => {
          nextCheckeds[item.node.nodeKey] = true;
        },
        ignoreCollapsed: false,
      });
      setCheckeds(nextCheckeds);
    }
    // we don't want to set all checked when tree changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.plan, suiteTestsIds]);

  const onCheckItems = useCallback((items) => {
    setSuiteTests(
      items
        .filter((item) => item.type === TEST_TYPE)
        .map((item) => item.data.suiteTest.id),
    );
  }, []);

  return (
    <StartRunForm
      appVersionPlatVersions={avpvp}
      checkeds={checkeds}
      formik={formik}
      milestones={milestones}
      plans={plans}
      onCheckItems={onCheckItems}
      tree={tree}
      virtuals={requiredVirtuals}
    />
  );
}
