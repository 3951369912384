import React, { useEffect, useCallback } from 'react';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { runSelectors } from 'src/gen/domain/run/runSelector';
import { runResultFetchManyThunk } from 'src/gen/domain/run_result/runResultThunk';
import useFetch from 'src/hooks/useFetch';
import { Loading } from '@bitmodern/bit-ui';
import styles from './RunOverviewDrawerView.module.scss';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { runDeleteOneThunk } from 'src/gen/domain/run/runThunk';
import { runResultsByRunSelector } from 'src/packages/redux/state/runResults/selectors';
import { Run } from 'src/components/organisms';
import { useQuery } from 'src/hooks/useQuery';

type Props = {
  runId: number;
};

export default function RunOverviewDrawerViewContainer({ runId }: Props) {
  const { site } = useParams<typeof routes.RUN.params>();
  const { closeDrawer } = useDrawerManager();
  const run = useAppSelector((state) => runSelectors.selectById(state, runId));
  const dispatch = useAppDispatch();
  const runResultsTree = useAppSelector((state) =>
    runResultsByRunSelector(state, { runId }),
  );
  const { resultId: runResultId } = useQuery(['resultId']);

  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const request = useCallback(() => {
    return dispatch(
      runResultFetchManyThunk({
        params: {
          run_id: runId,
          project_id: run!.project_id,
          _with: 'runResultStep',
          per_page: -1,
        },
      }),
    );
  }, [dispatch, run, runId]);

  const { isLoading } = useFetch(request, `runView-${runId}`, {
    fetchOnce: true,
  });

  useEffect(() => {
    if (!run) {
      closeDrawer();
    }
  }, [closeDrawer, run]);

  const onDeleteRun = useCallback(() => {
    if (!run) return Promise.resolve();
    return dispatch(runDeleteOneThunk({ id: run.id })).then(() => {
      onClose();
    });
  }, [run, dispatch, onClose]);

  if (!runId || !run) return null;

  return !isLoading ? (
    <Run
      current={parseInt(runResultId, 10)}
      loading={isLoading}
      onDeleteRun={onDeleteRun}
      run={run}
      site={site}
      runResultsTree={runResultsTree}
      onClose={onClose}
    />
  ) : (
    <div className={styles.loading}>
      <Loading />
    </div>
  );
}
