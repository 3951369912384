/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Attachment } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { attachmentAdapter } from './attachmentAdapter';
import {
  attachmentFetchManyThunk,
  attachmentFetchOneThunk,
  attachmentDeleteOneThunk,
  attachmentUpdateOneThunk,
  attachmentCreateOneThunk,
} from './attachmentThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const attachmentSlice = createSlice({
  name: 'attachment',
  initialState: attachmentAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    attachmentCleared(state) {
      state.loading = 'idle';
      attachmentAdapter.removeAll(state);
    },
    attachmentUpsertMany(
      state,
      action: PayloadAction<Attachment[] | Record<number, Attachment>>,
    ) {
      attachmentAdapter.upsertMany(state, action.payload);
    },
    attachmentUpsertOne(state, action: PayloadAction<Attachment>) {
      attachmentAdapter.upsertOne(state, action.payload);
    },
    attachmentUpdateOne(state, action: PayloadAction<Update<Attachment>>) {
      attachmentAdapter.updateOne(state, action.payload);
    },
    attachmentRemoveOne(state, action: PayloadAction<number>) {
      attachmentAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attachmentFetchManyThunk.pending, handlePending);
    builder.addCase(attachmentFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      attachmentAdapter.upsertMany(state, action.payload.attachment);
    });
    builder.addCase(attachmentFetchManyThunk.rejected, handleRejected);
    builder.addCase(attachmentFetchOneThunk.pending, handlePending);
    builder.addCase(attachmentFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      attachmentAdapter.upsertOne(state, action.payload.attachment[0]);
    });
    builder.addCase(attachmentFetchOneThunk.rejected, handleRejected);
    builder.addCase(attachmentDeleteOneThunk.pending, handlePending);
    builder.addCase(attachmentDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        attachmentAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(attachmentDeleteOneThunk.rejected, handleRejected);
    builder.addCase(attachmentUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, attachmentAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(attachmentUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      attachmentAdapter.updateOne(state, action.payload);
    });
    builder.addCase(attachmentUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          attachmentAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(attachmentCreateOneThunk.pending, handlePending);
    builder.addCase(attachmentCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      attachmentAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(attachmentCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  attachmentCleared,
  attachmentUpsertMany,
  attachmentUpsertOne,
  attachmentUpdateOne,
  attachmentRemoveOne,
} = attachmentSlice.actions;

export const attachmentReducer = attachmentSlice.reducer;
