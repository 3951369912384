/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectResNativeDefectResAdapter } from './defectResNativeDefectResAdapter';

export const defectResNativeDefectResSelectors =
  defectResNativeDefectResAdapter.getSelectors(
    (state: State) => state.gen.defectResNativeDefectRes,
  );
