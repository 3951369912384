import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { IconButton } from '@bitmodern/bit-ui';
import { DeleteIcon, EditIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import styles from './CustomValue.module.scss';

type Props = {
  description?: ReactNode;
  name: ReactNode;
  onDelete?: () => void;
  onEdit?: () => void;
  variant?: 'default' | 'compact';
};

export default function CustomValue({
  description,
  name,
  onDelete,
  onEdit,
  variant = 'default',
}: Props) {
  const customValueCN = classnames(styles.customValue, {
    [styles.compact]: variant === 'compact',
  });

  const nameCN = classnames(styles.name, {
    [styles.compactName]: variant === 'compact',
  });

  return (
    <li className={customValueCN}>
      <div className={styles.meta}>
        <div className={nameCN}>{name}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {onEdit && (
        <IconButton className={styles.delete} onClick={onEdit} size="small">
          <EditIcon color={vars.textPrimary} size={16} />
        </IconButton>
      )}
      {onDelete && (
        <IconButton className={styles.delete} onClick={onDelete} size="small">
          <DeleteIcon color={vars.textPrimary} size={16} />
        </IconButton>
      )}
    </li>
  );
}
