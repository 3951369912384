import React, {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Integration } from '@testquality/sdk';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { integrationKey } from 'src/enums/IntegrationEnums';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import { currentClientSelector } from '@bitmodern/redux/state/clients/selectors';
import { useSelector } from 'react-redux';
import { baseIntegrationByIdSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { capabilitySelectors } from 'src/gen/domain/capability/capabilitySelector';
import {
  integrationTemplateUpdateOneThunk,
  integrationTemplateCreateOneThunk,
} from 'src/gen/domain/integration_template/integrationTemplateThunk';
import { integrationTemplatesSelector } from '@bitmodern/redux/state/integrationTemplate/selectors';
import { getDefaultIntegrationDescriptionTemplate } from 'src/utils/integrationTemplateHelper';
import {
  capabilityIntegrationBySchemaSelector,
  capabilityIntegrationByIntegrationSelector,
} from '@bitmodern/redux/state/capabilityIntegration/selectors';
import Yup from 'src/utils/yup';

import IntegrationTemplatesDialog from './IntegrationTemplatesDialog';

type IntegrationTemplatesDialogProps = ComponentProps<
  typeof IntegrationTemplatesDialog
>;

type InitialValues = {
  template?: string;
  capabilityIntegrationId: number;
};

type OnSubmit = (initialValues: InitialValues) => Promise<any>;

type Props = Omit<
  IntegrationTemplatesDialogProps,
  | 'formik'
  | 'defaultDescriptionTemplate'
  | 'baseIntegration'
  | 'selectedCapabilityName'
> & {
  integration?: Integration;
};

const validationSchema = Yup.object().shape({
  template: Yup.string(),
  capabilityIntegrationId: Yup.number().required(),
});

export default function IntegrationTemplatesDialogContainer({
  onClose,
  integration,
  ...props
}: Props) {
  // Base integration
  const baseIntegration = useAppSelector(
    (state) =>
      baseIntegrationByIdSelector(state, {
        id: integration?.base_integration_id,
      })!,
  );
  const baseIntegrationType = integrationKey(baseIntegration);

  // Capability
  const capabilities = useAppSelector(capabilitySelectors.selectAll);

  // Capability integrations
  const rawCapabilityIntegrations = useAppSelector((state) =>
    capabilityIntegrationByIntegrationSelector(state, integration?.id),
  );
  const capabilityIntegrations = rawCapabilityIntegrations.map(
    (capabilityIntegration) => ({
      ...capabilityIntegration,
      name: capabilities.find(
        (cap) => cap.id === capabilityIntegration.capability_id,
      )?.name!,
    }),
  );

  const capabilityIntegrationDefectSelector = useMemo(
    () =>
      capabilityIntegrationBySchemaSelector(
        IntegrationSchema.DEFECT,
        integration?.id,
      ),
    [integration],
  );
  const defectCapabilityIntegration = useAppSelector(
    capabilityIntegrationDefectSelector,
  );

  const [selectedCapabilityIntegrationId, setSelectedCapabilityIntegrationId] =
    useState<number>(defectCapabilityIntegration?.id!);

  const selectedCapabilityIntegration = capabilityIntegrations.find(
    (capabilityIntegration) =>
      capabilityIntegration?.id === selectedCapabilityIntegrationId,
  );

  const capability = useAppSelector((state) =>
    capabilitySelectors.selectById(
      state,
      selectedCapabilityIntegration?.capability_id!,
    ),
  );

  // Templates
  const integrationTemplates = useSelector(integrationTemplatesSelector);
  const defaultDescriptionTemplate = getDefaultIntegrationDescriptionTemplate(
    baseIntegrationType,
    capability?.name as CapabilityEnum,
  );

  const currentTemplate = useMemo(
    () =>
      integrationTemplates.find(
        (integrationTemplate) =>
          integrationTemplate.capability_integration_id ===
          selectedCapabilityIntegrationId,
      ),
    [integrationTemplates, selectedCapabilityIntegrationId],
  );

  const currentClient = useAppSelector(currentClientSelector);
  const dispatch = useAppDispatch();
  const onSubmit = useCallback<OnSubmit>(
    async (values: InitialValues) => {
      let response: ReturnType<typeof dispatch>;
      const data = {
        template: values.template,
        capability_integration_id: values.capabilityIntegrationId,
        client_id: currentClient?.id,
        part: 'Description', // TODO: Hardcoded for the moment
      };

      if (currentTemplate) {
        response = await dispatch(
          integrationTemplateUpdateOneThunk({
            id: currentTemplate.id,
            data,
          }),
        );
      } else {
        response = await dispatch(integrationTemplateCreateOneThunk({ data }));
      }

      onClose();

      return response;
    },
    [currentClient, currentTemplate, dispatch, onClose],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      template: currentTemplate?.template ?? defaultDescriptionTemplate,
      capabilityIntegrationId: selectedCapabilityIntegrationId,
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    setSelectedCapabilityIntegrationId(formik.values.capabilityIntegrationId);
  }, [formik.values.capabilityIntegrationId]);

  function handleClose() {
    formik.resetForm();
    onClose();
  }

  return (
    <IntegrationTemplatesDialog
      {...props}
      baseIntegration={baseIntegration}
      capabilityIntegrations={capabilityIntegrations}
      defaultDescriptionTemplate={defaultDescriptionTemplate}
      selectedCapabilityName={capability?.name as CapabilityEnum}
      formik={formik}
      onClose={handleClose}
    />
  );
}
