/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import {
  integrationProjectDetachThunk,
  integrationProjectFetchManyThunk,
  integrationProjectFetchOneThunk,
  integrationProjectUpdateOneThunk,
  integrationProjectCreateOneThunk,
} from './integrationProjectThunk';
import { TQStateProperties } from '../../store/TQState';
import { integrationProjectAdapter } from './integrationProjectAdapter';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const integrationProjectSlice = createSlice({
  name: 'integrationProject',
  initialState: integrationProjectAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    integrationProjectCleared: integrationProjectAdapter.removeAll,
    integrationProjectUpsertMany: integrationProjectAdapter.upsertMany,
    integrationProjectUpsertOne: integrationProjectAdapter.upsertOne,
    integrationProjectUpdateOne: integrationProjectAdapter.updateOne,
    integrationProjectRemoveOne: integrationProjectAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(integrationProjectDetachThunk.pending, handlePending);
    builder.addCase(
      integrationProjectDetachThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload && action.payload.id) {
          integrationProjectAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(integrationProjectDetachThunk.rejected, handleRejected);
    builder.addCase(integrationProjectFetchManyThunk.pending, handlePending);
    builder.addCase(
      integrationProjectFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      },
    );
    builder.addCase(integrationProjectFetchManyThunk.rejected, handleRejected);
    builder.addCase(integrationProjectFetchOneThunk.pending, handlePending);
    builder.addCase(
      integrationProjectFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationProjectAdapter.upsertOne(
          state,
          action.payload.integrationProject[0],
        );
      },
    );
    builder.addCase(integrationProjectFetchOneThunk.rejected, handleRejected);
    builder.addCase(
      integrationProjectUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, integrationProjectAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      integrationProjectUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationProjectAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      integrationProjectUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            integrationProjectAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(integrationProjectCreateOneThunk.pending, handlePending);
    builder.addCase(
      integrationProjectCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationProjectAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(integrationProjectCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.integrationProject) {
          integrationProjectAdapter.upsertMany(
            state,
            action.payload.integrationProject,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.integrationProject) {
          integrationProjectAdapter.upsertMany(
            state,
            action.payload.integrationProject,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.integrationProject) {
          integrationProjectAdapter.upsertMany(
            state,
            action.payload.integrationProject,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationProject) {
        integrationProjectAdapter.upsertMany(
          state,
          action.payload.integrationProject,
        );
      }
    });
  },
});

export const {
  integrationProjectCleared,
  integrationProjectUpsertMany,
  integrationProjectUpsertOne,
  integrationProjectUpdateOne,
  integrationProjectRemoveOne,
} = integrationProjectSlice.actions;

export const integrationProjectReducer = integrationProjectSlice.reducer;
