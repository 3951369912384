/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { accessRoleAdapter } from './accessRoleAdapter';

export const accessRoleSelectors = accessRoleAdapter.getSelectors(
  (state: State) => state.gen.accessRole,
);
