import { milestoneSelectors } from 'src/gen/domain/milestone/milestoneSelector';
import { State } from '@bitmodern/redux/store';

export function milestonesByProjectSelector(state: State, projectId: number) {
  return milestoneSelectors
    .selectAll(state)
    .filter((milestone) => milestone.project_id === projectId);
}

export function getMilestoneById(state, args: { milestoneId?: number }) {
  return args.milestoneId
    ? milestoneSelectors.selectById(state, args.milestoneId)
    : undefined;
}
