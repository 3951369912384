import React, { ComponentProps, useCallback, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'src/i18n/hooks';
import { DropPlaceholder, IconButton } from '@bitmodern/bit-ui';
import { DndTypes } from 'src/common/DndTypes';
import { CopyIcon, DeleteIcon, DragIcon } from '@bitmodern/bit-ui/icons';
import { Step } from '@testquality/sdk';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import ConfirmDialog from '../ConfirmDialog';
import StepEditor from '../StepEditor';
import styles from './TestStep.module.scss';

type Props = Pick<
  ComponentProps<typeof StepEditor>,
  'onFileUpload' | 'parentId' | 'parentType'
> & {
  editable?: boolean;
  index: number;
  onChangeOrder?: (fromIndex: number, toIndex: number) => void;
  onChangeStep?: (step: Step) => void;
  onClone?: (step: Step) => void;
  onCommit?: (step: Step) => void;
  onDelete?: (step: Step) => Promise<any>;
  onDropEnd?: () => void;
  step: Step;
  highlights?: string[];
};

export default function TestStep({
  editable = true,
  index,
  onChangeOrder,
  onChangeStep,
  onClone,
  onCommit,
  onDelete,
  onDropEnd,
  onFileUpload,
  parentId,
  parentType,
  step,
  highlights,
}: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const deleteDialog = useOverlayTriggerState({});

  const onConfirmDelete = useCallback(() => {
    const promise = onDelete ? onDelete(step) : Promise.resolve();
    return promise.finally(deleteDialog.close);
  }, [deleteDialog, onDelete, step]);

  const deleteMutation = useMutation(onConfirmDelete);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DndTypes.Step, id: step.id, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: () => {
      if (onDropEnd) {
        onDropEnd();
      }
    },
  });

  const [, drop] = useDrop({
    accept: DndTypes.Step,
    hover(item: { type: string; id: any; index: number }, monitor) {
      if (!ref.current || !onChangeOrder || item.id === step.id) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onChangeOrder(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const onCommitStep = (values) => {
    if (onCommit) {
      onCommit({
        ...step,
        step: values.step,
        expected_result: values.expectedResult,
      });
    }
    return Promise.resolve();
  };

  const onChange = (values) => {
    if (onChangeStep) {
      onChangeStep({
        ...step,
        step: values.step,
        expected_result: values.expectedResult,
      });
    }
  };

  const handleClone = () => {
    if (onClone) {
      onClone(step);
    }
  };

  preview(drop(ref));

  return (
    <div className={styles.testStep} ref={ref}>
      {isDragging && <DropPlaceholder className={styles.dropPlaceholder} />}
      <div className={styles.header}>
        <span className={styles.sequence}>{step.sequence}</span>
        <div className={styles.actions}>
          <IconButton
            onClick={handleClone}
            boxed={false}
            title={t('step.actions.clone')}>
            <CopyIcon color={vars.textPrimary} size={18} />
          </IconButton>
          <IconButton
            onClick={deleteDialog.open}
            boxed={false}
            title={t('step.actions.delete')}>
            <DeleteIcon color={vars.textPrimary} size={18} />
          </IconButton>
          <div ref={drag} className={styles.drag}>
            <IconButton boxed={false} title={t('step.actions.drag')}>
              <DragIcon color={vars.textPrimary} size={18} />
            </IconButton>
          </div>
        </div>
      </div>
      <StepEditor
        editable={editable}
        onCommit={onCommit ? onCommitStep : undefined}
        onChange={onChange}
        onFileUpload={onFileUpload}
        parentId={parentId}
        parentType={parentType}
        step={step}
        highlights={highlights}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('step.deleteDialog.title')}>
        {t('step.deleteDialog.content')}
      </ConfirmDialog>
    </div>
  );
}
