import React, { ComponentProps } from 'react';
import styles from './FieldError.module.scss';

type Props = ComponentProps<'div'>;

export default function FieldError({
  children,
  className = '',
  ...rest
}: Props) {
  return (
    <div className={`${styles.error} ${className}`} {...rest}>
      {children}
    </div>
  );
}
