import {
  BatchService,
  IntegrationProject,
  HttpError,
  projectCreateOne,
  showNotificationError,
} from '@testquality/sdk';
import { batch as reduxBatch } from 'react-redux';
import { AppThunk } from '@bitmodern/redux/store';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { ProjectColors, ProjectIcons } from 'src/enums/ProjectEnums';
import { runFetchManyThunk } from 'src/gen/domain/run/runThunk';
import { milestoneFetchManyThunk } from 'src/gen/domain/milestone/milestoneThunk';
import { defectFetchManyThunk } from 'src/gen/domain/defect/defectThunk';
import { planSuiteTestIncludeFetchManyThunk } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { requirementFetchManyThunk } from 'src/gen/domain/requirement/requirementThunk';
import { planFetchManyThunk } from 'src/gen/domain/plan/planThunk';
import { commentFetchManyThunk } from 'src/gen/domain/comment/commentThunk';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { unwrapResult } from '@reduxjs/toolkit';
import { projectUpsertOne } from 'src/gen/domain/project/projectSlice';
import { runResultFetchManyThunk } from 'src/gen/domain/run_result/runResultThunk';
import { integrationsSelector } from '@bitmodern/redux/state/integration/selectors';
import { attachProjectThunk } from '@bitmodern/redux/state/integrationProject/thunks';
import { dataSetFetchManyThunk } from 'src/gen/domain/data_set/dataSetThunk';
import { upsertEntitiesAction } from 'src/packages/redux/RootReducer/batchSetGenReducer';
import { combineBatchedEntitiesCache } from 'src/utils/cache';

type CreateProjectArgs = {
  values: {
    name: string;
    description: string;
    icon: ProjectIcons.Test;
    color: ProjectColors.Blue;
    baseIntegrationId?: number;
    integrationProject?: IntegrationProject;
  };
};

export function createProjectThunk({ values }: CreateProjectArgs) {
  return async (dispatch, getState) => {
    try {
      const project = await projectCreateOne({
        name: values.name,
        description: values.description,
        picture: values.icon.toString(),
        color: values.color.toString(),
      });

      // we need plan to be in store before otherwise project could be
      // deemed as not migrated
      dispatch(projectUpsertOne(project));

      if (values.baseIntegrationId && values.integrationProject) {
        const integrations = integrationsSelector(getState());
        const integration = integrations.find(
          (i) => i.base_integration_id === values.baseIntegrationId,
        );
        if (integration) {
          await dispatch(
            attachProjectThunk({
              integration_id: integration.id,
              project_reference_id:
                values.integrationProject.project_reference_id,
              project_id: project.id,
              username: values.integrationProject.username,
              org: values.integrationProject.org,
            }),
          );
        } else {
          throw new Error('Integration not found');
        }
      }
      return { project };
    } catch (error) {
      showNotificationError(error as HttpError);
      return Promise.reject(error);
    }
  };
}

export function getProjectDataThunk({
  dataCached,
  projectId,
  onMigrateProject,
}: {
  dataCached?: any;
  projectId: number;
  onMigrateProject: () => void;
}): AppThunk<Promise<any>> {
  return (dispatch, getState) => {
    const entities = combineBatchedEntitiesCache(dataCached);
    dispatch(upsertEntitiesAction(entities));

    const projects = projectsSelector(getState());
    const projectExists = projects.some((project) => project.id === projectId);

    if (!projectExists) {
      const error = new Error();
      error.name = 'ProjectNotFound';
      return Promise.reject(error);
    }

    return new Promise((resolve, reject) => {
      reduxBatch(() => {
        const batch = new BatchService();
        const thunks = Promise.all([
          dispatch(
            planFetchManyThunk({
              params: {
                per_page: -1,
                project_id: projectId,
                _with: 'purpose,suite,suite.test,suite.test.step',
              },
              batch,
            }),
          )
            .then(unwrapResult)
            .then((res) => {
              const plans = res.plan;
              const rootPlan = plans.find((plan) => plan.is_root);
              if (!rootPlan) {
                onMigrateProject();
              }
              return res;
            }),
          dispatch(
            defectFetchManyThunk({
              params: {
                per_page: -1,
                project_id: projectId,
                _with: 'runResult',
              },
              batch,
            }),
          ),
          dispatch(
            requirementFetchManyThunk({
              params: {
                project_id: projectId,
                per_page: -1,
                _with: 'requirementTest',
              },
              batch,
            }),
          ),
          dispatch(
            planSuiteTestIncludeFetchManyThunk({
              params: { project_id: projectId, per_page: -1 },
              batch,
            }),
          ),
          dispatch(
            runFetchManyThunk({
              params: {
                project_id: projectId,
                _sort: '-start_time',
                per_page: -1,
              },
              batch,
            }),
          ),
          dispatch(
            milestoneFetchManyThunk({
              params: { per_page: -1, project_id: projectId },
              batch,
            }),
          ),
          dispatch(commentFetchManyThunk({ batch, params: { per_page: -1 } })),

          dispatch(
            dataSetFetchManyThunk({
              batch,
              params: { per_page: -1, project_id: projectId },
            }),
          ),
        ]);

        batch.executeBatch().then((res) => {
          const runResultIds: number[] = [];
          res?.responses?.[0]?.[1]?.data?.data.forEach((defect) => {
            if (
              defect.related_type === RelatedType.RUN_RESULT &&
              defect.related_id
            ) {
              runResultIds.push(defect.related_id);
            }
          });

          if (runResultIds.length) {
            dispatch(
              runResultFetchManyThunk({
                params: {
                  // @ts-expect-error not defined in typescript
                  'id-in': runResultIds.join(','),
                },
              }),
            );
          }
        });

        thunks
          .then((responses) => {
            return responses.map((res) => {
              if (!res.type) return res;
              return unwrapResult(res as any);
            });
          })
          .then(resolve)
          .catch(reject);
      });
    });
  };
}
