import React, { ComponentProps } from 'react';
import { Col as AntCol, Row as AntRow } from 'antd/es/grid';
import 'antd/es/grid/style/index.less';

type RowProps = ComponentProps<typeof AntRow>;

function Row({ children, gutter = [16, 16], ...rest }: RowProps) {
  return (
    <AntRow gutter={gutter} {...rest}>
      {children}
    </AntRow>
  );
}

type Span = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColProps = ComponentProps<typeof AntCol> & {
  span?: Span;
  xs?: Span;
  sm?: Span;
  md?: Span;
  lg?: Span;
  xl?: Span;
  xxl?: Span;
};

function Col({ children, span, xs, sm, md, lg, xl, xxl, ...rest }: ColProps) {
  return (
    <AntCol
      span={span ? span * 2 : undefined}
      xs={xs ? xs * 2 : undefined}
      sm={sm ? sm * 2 : undefined}
      md={md ? md * 2 : undefined}
      lg={lg ? lg * 2 : undefined}
      xl={xl ? xl * 2 : undefined}
      xxl={xxl ? xxl * 2 : undefined}
      {...rest}>
      {children}
    </AntCol>
  );
}

export default { Col, Row };
