import React, { ReactNode } from 'react';
import {
  AriaDialogProps,
  AriaOverlayProps,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Modal.module.scss';

type Props = AriaOverlayProps &
  AriaDialogProps & {
    animatePresence?: boolean;
    className?: string;
    children: ReactNode;
  };

export default function Modal({
  animatePresence = true,
  className = '',
  children,
  isDismissable = true,
  isOpen = true,
  ...rest
}: Props) {
  const props = { isDismissable, isOpen, ...rest };
  const ref = React.useRef(null);
  usePreventScroll({ isDisabled: !isOpen });
  const { overlayProps } = useOverlay(props, ref);
  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  const modal = (
    <OverlayContainer>
      <FocusScope contain restoreFocus autoFocus>
        <motion.div
          animate="visible"
          initial="hidden"
          exit="hidden"
          variants={{
            visible: {
              opacity: 1,
            },
            hidden: {
              opacity: 0,
            },
          }}
          className={`${styles.modal} ${className}`}
          {...(mergeProps(overlayProps, dialogProps, modalProps) as any)}
          ref={ref}>
          {children}
        </motion.div>
      </FocusScope>
    </OverlayContainer>
  );

  if (!animatePresence) return modal;

  return <AnimatePresence>{isOpen && modal}</AnimatePresence>;
}
