import { AppThunk } from '@bitmodern/redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  PlanSuite,
  BatchService,
  SuiteTest,
  Suite,
  Label,
} from '@testquality/sdk';
import { labelCreateOneThunk } from 'src/gen/domain/label/labelThunk';
import { labelAssignedCreateOneThunk } from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import {
  suiteCreateOneThunk,
  suiteDeleteOneThunk,
} from 'src/gen/domain/suite/suiteThunk';
import { rootPlanSelector } from '../plans/selectors';
import { suiteChildrenSelector, suiteSelector } from './selectors';
import { updateSiblingsSequenceThunk } from '../treeThunks';
import { planSuiteTestIncludeCreateOneThunk } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { labelsSelector } from '../label/selectors';

export function createSuiteThunk({
  projectId,
  name,
  labels,
  under,
  planId,
}: {
  projectId: number;
  name: string;
  labels: Array<string | number>;
  under?: { planSuite?: PlanSuite; suiteTest?: SuiteTest };
  planId?: number;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
}): AppThunk<Promise<Suite | void>> {
  return (dispatch, getState) => {
    if (!under?.planSuite && !under?.suiteTest) return Promise.resolve();

    const parentSuiteId =
      under.planSuite?.suite_id || under.suiteTest?.suite_id || 0;
    const underSuite = suiteSelector(getState(), parentSuiteId);
    if (!underSuite) return Promise.resolve();

    const rootPlan = rootPlanSelector(getState(), underSuite?.project_id);
    if (!rootPlan) return Promise.resolve();

    const allLabels = labelsSelector(getState());
    const labelIds = allLabels.map((label) => label.id);
    const batch = new BatchService();

    const newSuite = dispatch(
      suiteCreateOneThunk({
        batch,
        data: {
          name,
          project_id: projectId,
          plan_id: rootPlan.id,
          plan_suite: {
            parent_id: underSuite.id,
            plan_id: rootPlan.id,
            sequence_plan: 1,
          },
        },
      }),
    )
      .then(unwrapResult)
      .then((suite) => {
        if (suite && planId) {
          const suiteId = suite.id;
          return dispatch(
            planSuiteTestIncludeCreateOneThunk({
              data: {
                project_id: projectId,
                plan_id: planId,
                suite_id: suiteId,
              },
            }),
          ).then(() => suite);
        }
        return suite;
      })
      .then(async (suite) => {
        if (!suite) throw new Error('Error on suite creation');
        const labelsBatch = new BatchService();
        const existingLabels = labels.filter((value) => {
          if (labelIds.includes(value as number)) return true;
          /* NOTE: If not included in the array of label IDs */
          /* they are new and should therefore be created */
          dispatch(
            labelCreateOneThunk({
              data: { label: value as string },
              batch: labelsBatch,
            }),
          );
          return false;
        });

        const newLabels: Label[] = await labelsBatch
          .executeBatch()
          .then(({ responses }) => {
            const lbls: Label[] = [];
            if (responses) {
              responses.forEach((response1) => {
                if (response1) {
                  response1.forEach((response2) => {
                    if (response2.data) {
                      lbls.push(response2.data);
                    }
                  });
                }
              });
            }
            return lbls;
          });
        const labelsIds = [
          ...(existingLabels as number[]),
          ...newLabels.map((l) => l.id),
        ];

        const labelsAssignedBatch = new BatchService();
        labelsIds.forEach((labelId) => {
          dispatch(
            labelAssignedCreateOneThunk({
              data: {
                related_id: suite.id,
                related_type: RelatedType.SUITE,
                label_id: labelId,
              },
              batch: labelsAssignedBatch,
            }),
          );
        });

        await labelsAssignedBatch.executeBatch();
        return suite;
      });

    const siblings = suiteChildrenSelector(getState(), parentSuiteId);
    updateSiblingsSequenceThunk(siblings, batch);

    return batch.executeBatch().then(() => newSuite);
  };
}

export function deleteSuitesThunk(planSuites: PlanSuite[]): AppThunk {
  return (dispatch, getState): Promise<any> => {
    const batch = new BatchService();

    const promises = planSuites.map((planSuite) => {
      return dispatch(suiteDeleteOneThunk({ id: planSuite.suite_id, batch }));
    });

    const parentSuitesIds = planSuites
      .map((s) => s.parent_id)
      .filter((id) => typeof id === 'number') as number[];

    parentSuitesIds.forEach((suiteId) => {
      const siblings = suiteChildrenSelector(getState(), suiteId);
      updateSiblingsSequenceThunk(siblings, batch);
    });

    batch.executeBatch();

    return Promise.all(promises);
  };
}
