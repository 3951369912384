import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Button, ComboBox, notification } from 'src/packages/bit-ui';
import IntegrationProjectItem from 'src/components/organisms/IntegrationProject/IntegrationProjectItem';
import { formikError } from 'src/utils/formik';
import { projectsSelector } from 'src/packages/redux/state/projects/selectors';
import { useAppSelector } from 'src/packages/redux/store';
import { useFormik } from 'formik';
import { useAppDispatch } from '@bitmodern/redux/store';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import Yup from 'src/utils/yup';
import { useParamSelector } from 'src/packages/redux/hooks';
import { integrationSelector } from 'src/packages/redux/state/integration/selectors';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { attachProjectThunk } from '@bitmodern/redux/state/integrationProject/thunks';
import { useTranslation } from 'react-i18next';
import styles from './ProjectIntegrationCreateView.module.scss';
import { SettingsPanel } from 'src/components/organisms';
import { IntegrationProject } from '@testquality/sdk';

type Values = {
  isUser: boolean;
  org: number;
  project: number;
  repository: number;
  integrationProject: IntegrationProject;
};

export function ProjectIntegrationCreateView() {
  const { t } = useTranslation();
  const { site, integrationId } =
    useParams<typeof routes.INTEGRATION_PROJECT_CREATE.params>();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const projects = useAppSelector(projectsSelector);
  const integration = useParamSelector(integrationSelector, {
    integrationId: parseInt(integrationId, 10),
  });

  const isGithub = integration
    ? integrationKey(integration) === IntegrationType.GITHUB
    : false;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        project: Yup.mixed()
          .required()
          .label('integrationProjectEdit.form.project'),
        org: isGithub
          ? Yup.number().required().label('integrationProject.orgSelection')
          : Yup.number().label('integrationProject.orgSelection'),
      }),
    [isGithub],
  );

  const formik = useFormik<Values>({
    initialValues: {} as Values,
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  async function onSubmit(values: Values) {
    if (!integration)
      return notification.open({
        message: 'Error',
        description: 'Integration not found',
      });

    const data = {
      integration_id: integration.id,
      project_id: values.project,
      project_reference_id: values.integrationProject.project_reference_id,
      username: values.integrationProject.username,
      org: values.integrationProject.org,
    };

    if (!data.org) {
      data.org = integration.org;
    }

    await dispatch(attachProjectThunk(data));

    history.replace(routes.INTEGRATIONS({ site }));
  }

  const setIntegrationProject = (values) => {
    formik.setFieldValue('integrationProject', values);
  };

  const onChangeProject = (value) => {
    formik.setFieldValue('project', value);
  };

  const projectsOptions = useMemo(() => {
    return projects.map((p) => ({
      label: p.name,
      value: p.id,
    }));
  }, [projects]);

  return (
    <SettingsPanel
      back={routes.INTEGRATIONS({ site })}
      title={t('integrationProjectEdit.headCreate.title')}>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <ComboBox
          error={formikError(formik, 'project')}
          label={t('integrationProjectEdit.form.project')}
          name="project"
          onChange={onChangeProject}
          onFocus={formik.handleBlur}
          options={projectsOptions}
          placeholder="Select Project..."
          required
          value={formik.values.project}
        />
        <IntegrationProjectItem
          integration={integration}
          setIntegrationProject={setIntegrationProject}
          required
          formik={formik as any}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit"
          size="small">
          {t('integrationProjectEdit.form.create')}
        </Button>
      </form>
    </SettingsPanel>
  );
}
