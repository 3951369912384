/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { TestQuality } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { testQualityAdapter } from './testQualityAdapter';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
  testQualityDeleteOneThunk,
  testQualityUpdateOneThunk,
  testQualityCreateOneThunk,
} from './testQualityThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const testQualitySlice = createSlice({
  name: 'testQuality',
  initialState: testQualityAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    testQualityCleared(state) {
      state.loading = 'idle';
      testQualityAdapter.removeAll(state);
    },
    testQualityUpsertMany(
      state,
      action: PayloadAction<TestQuality[] | Record<number, TestQuality>>,
    ) {
      testQualityAdapter.upsertMany(state, action.payload);
    },
    testQualityUpsertOne(state, action: PayloadAction<TestQuality>) {
      testQualityAdapter.upsertOne(state, action.payload);
    },
    testQualityUpdateOne(state, action: PayloadAction<Update<TestQuality>>) {
      testQualityAdapter.updateOne(state, action.payload);
    },
    testQualityRemoveOne(state, action: PayloadAction<number>) {
      testQualityAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testQualityFetchManyThunk.pending, handlePending);
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityAdapter.upsertMany(state, action.payload.testQuality);
    });
    builder.addCase(testQualityFetchManyThunk.rejected, handleRejected);
    builder.addCase(testQualityFetchOneThunk.pending, handlePending);
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityAdapter.upsertOne(state, action.payload.testQuality[0]);
    });
    builder.addCase(testQualityFetchOneThunk.rejected, handleRejected);
    builder.addCase(testQualityDeleteOneThunk.pending, handlePending);
    builder.addCase(testQualityDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        testQualityAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(testQualityDeleteOneThunk.rejected, handleRejected);
    builder.addCase(testQualityUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, testQualityAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(testQualityUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityAdapter.updateOne(state, action.payload);
    });
    builder.addCase(testQualityUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          testQualityAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(testQualityCreateOneThunk.pending, handlePending);
    builder.addCase(testQualityCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(testQualityCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.testQuality) {
          testQualityAdapter.upsertMany(state, action.payload.testQuality);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.testQuality) {
        testQualityAdapter.upsertMany(state, action.payload.testQuality);
      }
    });
  },
});

export const {
  testQualityCleared,
  testQualityUpsertMany,
  testQualityUpsertOne,
  testQualityUpdateOne,
  testQualityRemoveOne,
} = testQualitySlice.actions;

export const testQualityReducer = testQualitySlice.reducer;
