/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { NativeDefectStatusHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { nativeDefectStatusHistoryAdapter } from './nativeDefectStatusHistoryAdapter';
import { nativeDefectStatusHistoryFetchThunk } from './nativeDefectStatusHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const nativeDefectStatusHistorySlice = createSlice({
  name: 'nativeDefectStatusHistory',
  initialState:
    nativeDefectStatusHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    nativeDefectStatusHistoryCleared(state) {
      state.loading = 'idle';
      nativeDefectStatusHistoryAdapter.removeAll(state);
    },
    nativeDefectStatusHistoryUpsertMany(
      state,
      action: PayloadAction<
        NativeDefectStatusHistory[] | Record<number, NativeDefectStatusHistory>
      >,
    ) {
      nativeDefectStatusHistoryAdapter.upsertMany(state, action.payload);
    },
    nativeDefectStatusHistoryUpsertOne(
      state,
      action: PayloadAction<NativeDefectStatusHistory>,
    ) {
      nativeDefectStatusHistoryAdapter.upsertOne(state, action.payload);
    },
    nativeDefectStatusHistoryUpdateOne(
      state,
      action: PayloadAction<Update<NativeDefectStatusHistory>>,
    ) {
      nativeDefectStatusHistoryAdapter.updateOne(state, action.payload);
    },
    nativeDefectStatusHistoryRemoveOne(state, action: PayloadAction<number>) {
      nativeDefectStatusHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nativeDefectStatusHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      nativeDefectStatusHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectStatusHistoryAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatusHistory,
        );
      },
    );
    builder.addCase(
      nativeDefectStatusHistoryFetchThunk.rejected,
      handleRejected,
    );
  },
});

export const {
  nativeDefectStatusHistoryCleared,
  nativeDefectStatusHistoryUpsertMany,
  nativeDefectStatusHistoryUpsertOne,
  nativeDefectStatusHistoryUpdateOne,
  nativeDefectStatusHistoryRemoveOne,
} = nativeDefectStatusHistorySlice.actions;

export const nativeDefectStatusHistoryReducer =
  nativeDefectStatusHistorySlice.reducer;
