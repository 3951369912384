import React, {
  ComponentProps,
  CSSProperties,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TreeItem } from 'react-sortable-tree';
import { useOverlayTriggerState } from 'react-stately';
import {
  BookmarkIcon,
  MoreIcon,
  PlanIcon,
  PlayIcon,
} from '@bitmodern/bit-ui/icons';
import {
  Test,
  SuiteTest,
  Plan,
  Requirement,
  BatchService,
  showNotificationError,
  RequirementTest,
} from '@testquality/sdk';
import useModalManager from 'src/hooks/useModalManager';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { moveNodeThunk } from '@bitmodern/redux/state/treeThunks';
import {
  includeTestInPlanThunk,
  excludeSuiteFromPlanThunk,
  excludeTestFromPlanThunk,
  includeSuiteInPlanThunk,
} from '@bitmodern/redux/state/planSuitesTestsIncludes/thunks';
import useParams from 'src/hooks/useParams';
import vars from 'src/export.scss';
import { routes } from 'src/components/Router';
import { planSuiteTestIncludeByPlanSelector } from '@bitmodern/redux/state/planSuitesTestsIncludes/selectors';
import useMutation from 'src/hooks/useMutation';
import { Loading } from '@bitmodern/bit-ui';
import { SUITE_TYPE, TEST_TYPE } from '../TreeBase/treeTypes';
import TreeBase from '../TreeBase';
import CommandBarTests from '../CommandBarTests/CommandBarTests';
import SuiteListed from '../SuiteListed';
import TestListed from '../TestListed';
import {
  requirementTestCreateOneThunk,
  requirementTestDetachThunk,
} from 'src/gen/domain/requirement_test/requirementTestThunk';
import { useTranslation } from 'src/i18n/hooks';
import { useParamSelector } from 'src/packages/redux/hooks';
import { suiteTestSelector } from 'src/packages/redux/state/suiteTests/selectors';
import { generateNodeKey } from 'src/utils/tree';

type TreeBaseProps = ComponentProps<typeof TreeBase>;
type OnMoveNode = Exclude<TreeBaseProps['onMoveNode'], undefined>;

type Props = {
  canDrag?: boolean;
  current?: number;
  id: string;
  onClickTest: (test: Test, suiteTest: SuiteTest) => void;
  plan: Plan;
  suitesTree: TreeItem[];
  withInclude: boolean;
  withoutActions?: boolean;
  includeRequirement?: boolean;
  requirementTests?: RequirementTest[];
  requirement?: Requirement;
};

function SuitesTree({
  canDrag,
  current,
  id,
  onClickTest,
  plan,
  suitesTree,
  withInclude,
  withoutActions,
  includeRequirement,
  requirementTests = [],
  requirement,
}: Props) {
  const { projectId } = useParams<typeof routes.PROJECT.params>();
  const [checkedItems, setCheckedItems] = useState<TreeItem[]>([]);
  const commandBar = useOverlayTriggerState({});
  const { showModal } = useModalManager();
  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const { t } = useTranslation();

  const pstIncludes = useAppSelector((state) =>
    planSuiteTestIncludeByPlanSelector(state, { planId: plan.id }),
  );

  const currentSuiteTest = useParamSelector(suiteTestSelector, {
    suiteTestId: current,
  });

  const scrollToElement = useMemo(
    () => generateNodeKey({ suiteTest: currentSuiteTest }),
    [currentSuiteTest],
  );

  const canNodeHaveChildren = useCallback((node) => {
    return node.type !== TEST_TYPE;
  }, []);

  const getNodeKey = useCallback((data) => {
    return data.node.nodeKey;
  }, []);

  const onMoveNode = useCallback<OnMoveNode>(
    (params) => {
      dispatch(moveNodeThunk(params, parseInt(projectId, 10)));
    },
    [dispatch, projectId],
  );

  const selectedItems = useMemo(
    () =>
      checkedItems.filter((item) => {
        return (
          item &&
          (item.type === TEST_TYPE ||
            (item.type === SUITE_TYPE && !item.children?.length))
        );
      }),
    [checkedItems],
  );

  const addToRequirement = useMutation(async () => {
    const batch = new BatchService();
    // Remove already linked tests before sending
    const toSend = selectedItems.filter(
      (si) => !requirementTests.some((rt) => rt.test_id === si.data.test.id),
    );
    toSend.forEach((value) => {
      dispatch(
        requirementTestCreateOneThunk({
          data: {
            test_id: value.data.test.id,
            requirement_id: requirement?.id,
            suite_id: value.data.suiteTest.suite_id,
          },
          batch,
        }),
      );
    });
    await batch.executeBatch().catch((err) => {
      showNotificationError(err);
    });
  });

  const removeFromRequirement = useMutation(async () => {
    const batch = new BatchService();
    const related = requirementTests.filter((rt) =>
      selectedItems.some((si) => rt.test_id === si.data.test.id),
    );
    related.forEach((value) => {
      dispatch(
        requirementTestDetachThunk({
          data: {
            id: value.id,
          },
          batch,
        }),
      );
    });
    await batch.executeBatch().catch((err) => {
      showNotificationError(err);
    });
  });

  const addToPlan = useMutation(async () => {
    if (!plan.is_root) {
      await dispatch(
        includeTestInPlanThunk(
          plan.id,
          selectedItems
            .filter((item) => item.type === TEST_TYPE)
            .map((item) => item.data.suiteTest),
        ),
      );
      await dispatch(
        includeSuiteInPlanThunk(
          plan.id,
          selectedItems
            .filter((item) => item.type === SUITE_TYPE)
            .map((item) => item.data.suite),
        ),
      );
    } else if (ref.current) {
      ref.current.setCurrent('addToPlan');
      commandBar.open();
    }
  });

  const removeFromPlan = useMutation(async () => {
    const suiteTests = selectedItems
      .filter((item) => item.type === TEST_TYPE)
      .map((item) => item.data.suiteTest);
    await dispatch(excludeTestFromPlanThunk(plan.id, suiteTests));
    const suites = selectedItems
      .filter((item) => item.type === SUITE_TYPE)
      .map((item) => item.data.suite);
    await dispatch(excludeSuiteFromPlanThunk(plan.id, suites));
  });

  const allTestsAreIncluded = plan.is_root
    ? false
    : selectedItems.some((item) => {
        if (item.type === TEST_TYPE) {
          const { suiteTest } = item.data;
          const included = pstIncludes.find(
            (pstInclude) =>
              pstInclude.test_id === suiteTest.test_id &&
              pstInclude.suite_id === suiteTest.suite_id,
          );
          return !included;
        }
        if (item.type === SUITE_TYPE) {
          const { suite } = item.data;
          const included = pstIncludes.find(
            (pstInclude) => pstInclude.suite_id === suite.id,
          );
          return !included;
        }
        return false;
      }) === false;

  const requirementIncludesSelectedTests =
    selectedItems.some((item) => {
      if (item.type === TEST_TYPE) {
        const { suiteTest } = item.data;
        const included = requirementTests.find(
          (requirementTest) => requirementTest.test_id === suiteTest.test_id,
        );
        return !included;
      }
      return false;
    }) === false;

  const cicleIcon =
    removeFromPlan.isLoading ||
    addToPlan.isLoading ||
    removeFromRequirement.isLoading ||
    addToRequirement.isLoading ? (
      <Loading color={vars.textPrimary} delay={0} size={14} />
    ) : includeRequirement ? (
      <BookmarkIcon color={vars.textSecondary} size={16} />
    ) : (
      <PlanIcon color={vars.textPrimary} size={16} />
    );

  const toggleInclusion = allTestsAreIncluded
    ? {
        icon: cicleIcon,
        label: t('commandBarTests.quickCommands.removeFromCycle'),
        handler: removeFromPlan.mutate,
      }
    : {
        icon: cicleIcon,
        label: t('commandBarTests.quickCommands.addToCycle'),
        handler: addToPlan.mutate,
      };

  const requirementToggle = requirementIncludesSelectedTests
    ? {
        icon: cicleIcon,
        label: t('commandBarTests.quickCommands.removeFromStory'),
        handler: removeFromRequirement.mutate,
      }
    : {
        icon: cicleIcon,
        label: t('commandBarTests.quickCommands.addToStory'),
        handler: addToRequirement.mutate,
      };

  const actions = [
    includeRequirement ? requirementToggle : toggleInclusion,
    {
      icon: <PlayIcon color={vars.textPrimary} size={16} />,
      label: 'Run',
      handler: () => {
        showModal({
          modalName: 'startRun',
          modalProps: {
            planId: plan.id,
            suiteTestsIds: selectedItems
              .filter((item) => item.type === TEST_TYPE)
              .map((item) => item.data.suiteTest.id),
          },
          type: 'modal',
        });
      },
    },
    {
      icon: <MoreIcon color={vars.textPrimary} size={16} />,
      label: 'More',
      handler: commandBar.open,
    },
  ];

  return (
    <>
      <TreeBase
        actions={withoutActions ? undefined : actions}
        canDrag={canDrag}
        canNodeHaveChildren={canNodeHaveChildren}
        getNodeKey={getNodeKey}
        id={id}
        onCheckItems={setCheckedItems}
        onMoveNode={onMoveNode}
        onSelectAll={setCheckedItems}
        onDeselect={setCheckedItems}
        scrollToElement={scrollToElement}
        renderNodeType={{
          [TEST_TYPE]: (item) => {
            const { node, onCheckParentChange, path, treeIndex } = item;
            const { data } = node;

            return (
              <TestListed
                checked={node.checked}
                nestingLevel={path.length - 1}
                onClick={() => onClickTest(data.test, data.suiteTest)}
                onChangeChecked={(value) => {
                  if (onCheckParentChange)
                    onCheckParentChange(value, { node, treeIndex, path });
                }}
                planSuiteTestInclude={data.include}
                requirementTestIncluded={requirementTests.some(
                  (rt) => rt.test_id === data.test.id,
                )}
                plan={plan}
                selected={data?.suiteTest?.id === current}
                suiteTest={data.suiteTest}
                test={data.test}
                withInclude={withInclude}
                includeRequirement={includeRequirement}
                requirementId={requirement?.id}
              />
            );
          },

          [SUITE_TYPE]: (item) => {
            const {
              node,
              onCheckParentChange,
              path,
              style,
              toggleChildrenVisibility,
              treeIndex,
            } = item;
            const { data } = node;
            let include = false;
            if (withInclude) {
              include = !hasIncludedChildren(node);
            } else if (includeRequirement) {
              include = !childrenHasRequirement(
                node,
                requirementTests.map((rt) => rt.test_id),
              );
            } else {
              include = false;
            }

            return (
              <SuiteListed
                checked={node.checked}
                collapsed={node.expanded || false}
                collapsible={Boolean(node.children?.length)}
                nestingLevel={path.length - 1}
                onChangeChecked={(value) => {
                  if (onCheckParentChange)
                    onCheckParentChange(value, { node, treeIndex, path });
                }}
                onClick={() => {
                  if (!toggleChildrenVisibility) return;
                  toggleChildrenVisibility({ node, path, treeIndex });
                }}
                planSuite={data.planSuite}
                plan={plan}
                notIncluded={include}
                style={style as CSSProperties}
                suite={data.suite}
                requirementId={requirement?.id}
              />
            );
          },
        }}
        treeData={suitesTree}
      />
      <CommandBarTests
        items={checkedItems}
        onClose={commandBar.close}
        open={commandBar.isOpen}
        plan={!plan.is_root ? plan : undefined}
        ref={ref}
      />
    </>
  );
}

function hasIncludedChildren(node): boolean {
  const included = Boolean(node?.data?.include);
  if (included) return true;
  return node.children?.some(hasIncludedChildren);
}

function childrenHasRequirement(node, requirementTests): boolean {
  const included = requirementTests.includes(node?.data?.test?.id);
  if (included) return true;
  return node.children?.some((child) =>
    childrenHasRequirement(child, requirementTests),
  );
}

export default memo(SuitesTree);
