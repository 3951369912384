import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path d="M15,9H9c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1v0C16,9.45,15.55,9,15,9z" />
    <path d="M15,12H9c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1v0C16,12.45,15.55,12,15,12z" />
    <path d="M9,17h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v0C8,16.55,8.45,17,9,17z" />
    <path d="M19,19H5V5h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-8 c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1V19z" />
    <path d="M19,5V4c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v1h-1c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h1v1c0,0.55,0.45,1,1,1h0 c0.55,0,1-0.45,1-1V7h1c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H19z" />
  </>,
  'TestCreate',
);
