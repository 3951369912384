import React from 'react';
import classnames from 'classnames';
import { Status } from '@testquality/sdk';
import styles from './ToggleStatus.module.scss';
import StatusIcon from '../StatusIcon';

type Props = {
  onToggle: (status: Status) => void;
  selected: boolean;
  status: Status;
};

export default function ToggleStatus({ onToggle, selected, status }: Props) {
  const handleToggle = () => {
    onToggle(status);
  };

  const toggleCN = classnames(styles.toggle, {
    [styles.selected]: selected,
  });

  return (
    <div className={toggleCN} onClick={handleToggle}>
      <StatusIcon className={styles.icon} size={14} status={status} />
      {status.name}
    </div>
  );
}
