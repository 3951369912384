import React, { useState } from 'react';
import { attachmentCreateOneThunk } from 'src/gen/domain/attachment/attachmentThunk';
import {
  postRunResultAttachmentParams,
  postTestAttachmentParams,
} from '@bitmodern/services/AttachmentService';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { attachmentsByRelatedIdSelector } from '@bitmodern/redux/state/attachments/selectors';
import Attachments from './Attachments';

type ParentType = 'test' | 'runResult';

type Props = {
  parentType: ParentType;
  parentId: number;
};

export default function AttachmentsContainer({ parentId, parentType }: Props) {
  const dispatch = useAppDispatch();
  const attachments = useAppSelector((state) =>
    attachmentsByRelatedIdSelector(state, parentId),
  );
  const [isUploading, setIsUploading] = useState(false);

  const onDropFiles = (acceptedFiles: File[]) => {
    setIsUploading(true);
    const params: { [key in ParentType]: any } = {
      runResult: postRunResultAttachmentParams,
      test: postTestAttachmentParams,
    };

    Promise.all(
      acceptedFiles.map((file: File) => {
        return dispatch(
          attachmentCreateOneThunk(params[parentType](file, parentId)),
        );
      }),
    ).finally(() => setIsUploading(false));
  };

  return (
    <Attachments
      attachments={attachments}
      loading={isUploading}
      onDropFiles={onDropFiles}
    />
  );
}
