/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  capabilityIntegrationDetachThunk,
  capabilityIntegrationFetchManyThunk,
  capabilityIntegrationFetchOneThunk,
  capabilityIntegrationUpdateOneThunk,
  capabilityIntegrationCreateOneThunk,
} from './capabilityIntegrationThunk';
import { TQStateProperties } from '../../store/TQState';
import { capabilityIntegrationAdapter } from './capabilityIntegrationAdapter';
import {
  capabilityDeleteOneThunk,
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { capabilityRemoveOne } from '../capability/capabilitySlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const capabilityIntegrationSlice = createSlice({
  name: 'capabilityIntegration',
  initialState: capabilityIntegrationAdapter.getInitialState<TQStateProperties>(
    {
      loading: 'idle',
      error: undefined,
    },
  ),
  reducers: {
    capabilityIntegrationCleared: capabilityIntegrationAdapter.removeAll,
    capabilityIntegrationUpsertMany: capabilityIntegrationAdapter.upsertMany,
    capabilityIntegrationUpsertOne: capabilityIntegrationAdapter.upsertOne,
    capabilityIntegrationUpdateOne: capabilityIntegrationAdapter.updateOne,
    capabilityIntegrationRemoveOne: capabilityIntegrationAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(capabilityIntegrationDetachThunk.pending, handlePending);
    builder.addCase(
      capabilityIntegrationDetachThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload && action.payload.id) {
          capabilityIntegrationAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(capabilityIntegrationDetachThunk.rejected, handleRejected);
    builder.addCase(capabilityIntegrationFetchManyThunk.pending, handlePending);
    builder.addCase(
      capabilityIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      },
    );
    builder.addCase(
      capabilityIntegrationFetchManyThunk.rejected,
      handleRejected,
    );
    builder.addCase(capabilityIntegrationFetchOneThunk.pending, handlePending);
    builder.addCase(
      capabilityIntegrationFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        capabilityIntegrationAdapter.upsertOne(
          state,
          action.payload.capabilityIntegration[0],
        );
      },
    );
    builder.addCase(
      capabilityIntegrationFetchOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      capabilityIntegrationUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, capabilityIntegrationAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      capabilityIntegrationUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        capabilityIntegrationAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      capabilityIntegrationUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            capabilityIntegrationAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(capabilityIntegrationCreateOneThunk.pending, handlePending);
    builder.addCase(
      capabilityIntegrationCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        capabilityIntegrationAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(
      capabilityIntegrationCreateOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.capabilityIntegration) {
          capabilityIntegrationAdapter.upsertMany(
            state,
            action.payload.capabilityIntegration,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.capabilityIntegration) {
          capabilityIntegrationAdapter.upsertMany(
            state,
            action.payload.capabilityIntegration,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.capabilityIntegration) {
          capabilityIntegrationAdapter.upsertMany(
            state,
            action.payload.capabilityIntegration,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.capabilityIntegration) {
        capabilityIntegrationAdapter.upsertMany(
          state,
          action.payload.capabilityIntegration,
        );
      }
    });

    builder.addCase(capabilityDeleteOneThunk.fulfilled, (state, action) => {
      capabilityIntegrationAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.capability_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(capabilityRemoveOne, (state, action) => {
      capabilityIntegrationAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.capability_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  capabilityIntegrationCleared,
  capabilityIntegrationUpsertMany,
  capabilityIntegrationUpsertOne,
  capabilityIntegrationUpdateOne,
  capabilityIntegrationRemoveOne,
} = capabilityIntegrationSlice.actions;

export const capabilityIntegrationReducer = capabilityIntegrationSlice.reducer;
