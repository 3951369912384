import React from 'react';
import classnames from 'classnames';
import {
  DeleteIcon,
  NotificationIcon,
  ErrorIcon,
  WarningIcon,
  DoneIcon,
} from '@bitmodern/bit-ui/icons';
import { IconButton, Button, NotificationBubble } from '@bitmodern/bit-ui';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import {
  NotificationItemType,
  NotificationItemName,
} from 'src/enums/NotificationEnums';
import styles from './NotificationItem.module.scss';

type NotificationActions =
  | 'subscribe'
  | `resend.${'label' | 'failed.message' | 'success.message'}`
  | 'delete';

type Props = {
  type: NotificationItemType;
  name: NotificationItemName;
  translations: {
    date: string | null;
    description:
      | string
      | {
          later: string;
          today: string;
        };
  };
  wasRead: boolean;
  onClick?: () => void;
  onDelete?: () => void;
  action?: {
    onClick: () => void;
    label: NotificationActions;
    isLoading?: boolean;
  };
};

export default function NotificationItem({
  type,
  name,
  translations,
  wasRead,
  onClick,
  onDelete,
  action,
}: Props) {
  const { t } = useTranslation();

  function getNotificationIcon(
    notificationType: NotificationItemType,
    isHighlighted: boolean,
  ) {
    const color = isHighlighted ? vars.textPrimary : vars.textSecondary;
    switch (notificationType) {
      case NotificationItemType.DANGER:
        return <ErrorIcon color={color} />;
      case NotificationItemType.INFO:
        return <NotificationIcon color={color} />;
      case NotificationItemType.SUCCESS:
        return <DoneIcon color={color} />;
      case NotificationItemType.WARNING:
        return <WarningIcon color={color} />;
      default:
        return <NotificationIcon color={color} />;
    }
  }

  const notificationCN = classnames(styles.notification, {
    [styles.interactive]: onClick,
  });

  const descriptionCN = classnames(styles.description, {
    [styles.highlighted]: !wasRead,
  });

  return (
    <li aria-label={`${name}-notification`}>
      <div className={notificationCN} onClick={onClick}>
        <div className={styles.typeIcon}>
          {!wasRead && <NotificationBubble size="small" position="left" />}
          {getNotificationIcon(type, !wasRead)}
        </div>
        <div className={styles.content}>
          {typeof translations.description === 'string' && (
            <h5 className={descriptionCN}>{translations.description}</h5>
          )}
          {translations.date && (
            <p className={styles.date}>{translations.date}</p>
          )}
        </div>

        {action && (
          <Button
            className={styles.actionButton}
            loading={action.isLoading}
            size="small"
            onClick={action.onClick}>
            <>{t(`notification.actions.${action.label}`)}</>
          </Button>
        )}
        {onDelete && (
          <IconButton
            title={t('notification.actions.delete')}
            onClick={onDelete}
            boxed={false}>
            <DeleteIcon color={vars.textSecondary} size={22} />
          </IconButton>
        )}
      </div>
    </li>
  );
}
