/* eslint-disable react/self-closing-comp */
import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <g fill="none" fillRule="evenodd">
    <path
      d="M138.244 105.944H19.247a6.747 6.747 0 01-6.747-6.748v-75.29a6.747 6.747 0 016.747-6.747h118.997a6.747 6.747 0 016.747 6.747v75.29a6.747 6.747 0 01-6.747 6.748"
      fill="#384070"
    />
    <path
      d="M134.792 98.85H22.7a2.762 2.762 0 01-2.762-2.762V27.7a2.762 2.762 0 012.762-2.762h112.093a2.763 2.763 0 012.762 2.762v68.387a2.762 2.762 0 01-2.762 2.762"
      fill="#5376BA"
    />
    <path
      d="M115.891 89.998H41.993a4.72 4.72 0 01-4.72-4.72V4.72A4.72 4.72 0 0141.993 0h73.898a4.72 4.72 0 014.72 4.72v80.558a4.72 4.72 0 01-4.72 4.72"
      fill="#FEFEFE"
    />
    <g transform="translate(48 17)">
      <path d="M0 0h64v64H0z" />
      <circle cx={32} cy={9.333} r={2} />
      <circle cx={32} cy={9.333} r={2} />
      <circle cx={32} cy={9.333} r={2} />
      <path
        d="M50.667 8H39.52C38.4 4.907 35.467 2.667 32 2.667c-3.467 0-6.4 2.24-7.52 5.333H13.333c-.373 0-.72.027-1.066.107a5.354 5.354 0 00-2.694 1.466c-.48.48-.88 1.067-1.146 1.707A5.137 5.137 0 008 13.333v37.334c0 .72.16 1.44.427 2.08.266.64.666 1.2 1.146 1.706a5.354 5.354 0 002.694 1.467c.346.053.693.08 1.066.08h37.334C53.6 56 56 53.6 56 50.667V13.333C56 10.4 53.6 8 50.667 8zm-23.2 31.68a2.688 2.688 0 01-3.787 0l-5.787-5.787a2.656 2.656 0 010-3.76l5.787-5.786a2.688 2.688 0 013.787 0 2.656 2.656 0 010 3.76L23.547 32l3.893 3.893c1.04 1.04 1.067 2.747.027 3.787zM32 11.333c-1.093 0-2-.906-2-2 0-1.093.907-2 2-2s2 .907 2 2c0 1.094-.907 2-2 2zm4.533 28.347a2.656 2.656 0 010-3.76l3.92-3.92-3.92-3.92a2.656 2.656 0 010-3.76 2.688 2.688 0 013.787 0l5.787 5.787a2.656 2.656 0 010 3.76l-5.787 5.786a2.664 2.664 0 01-3.787.027z"
        fill="#0054FE"
        fillRule="nonzero"
      />
    </g>
    <path
      d="M156.188 115.054H1.303c-.72 0-1.303-.583-1.303-1.303v-6.434c0-.72.584-1.303 1.303-1.303h154.885c.72 0 1.303.584 1.303 1.303v6.434c0 .72-.583 1.303-1.303 1.303"
      fill="#F5F9FD"
    />
    <path
      d="M151.916 119.943H5.574A5.575 5.575 0 010 114.368v-1.303h157.491v1.303a5.575 5.575 0 01-5.575 5.575"
      fill="#B7C8E6"
    />
    <path
      d="M101.723 115.592H55.768c-.72 0-1.303-.584-1.303-1.304v-2.54c0-.72.583-1.303 1.303-1.303h45.955c.72 0 1.303.584 1.303 1.304v2.54c0 .72-.583 1.303-1.303 1.303"
      fill="#B7C8E6"
    />
    <path
      d="M112.336 11.167c-1.253.006-2.056-.767-2.062-1.983-.005-1.232.789-2.01 2.046-2.004 1.229.006 2 .751 2.019 1.95.019 1.232-.766 2.03-2.003 2.037M98.762 11.165c-1.277-.009-1.984-.754-2.004-2.11-.016-1.057.918-1.888 2.102-1.874 1.184.015 1.961.81 1.957 2.004-.003 1.223-.8 1.99-2.055 1.98M105.547 11.167c-1.343.002-1.992-.647-1.993-1.995-.001-1.312.692-1.998 2.013-1.992 1.202.006 1.966.77 1.97 1.97.003 1.304-.698 2.015-1.99 2.017"
      fill="#5376BA"
    />
    <path
      d="M112.336 11.167c-1.253.006-2.056-.767-2.062-1.983-.005-1.232.789-2.01 2.046-2.004 1.229.006 2 .751 2.019 1.95.019 1.232-.766 2.03-2.003 2.037M98.762 11.165c-1.277-.009-1.984-.754-2.004-2.11-.016-1.057.918-1.888 2.102-1.874 1.184.015 1.961.81 1.957 2.004-.003 1.223-.8 1.99-2.055 1.98M105.547 11.167c-1.343.002-1.992-.647-1.993-1.995-.001-1.312.692-1.998 2.013-1.992 1.202.006 1.966.77 1.97 1.97.003 1.304-.698 2.015-1.99 2.017"
      fill="#5376BA"
    />
  </g>,
  'LaptopTest',
  {},
  120,
  158,
);
