import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@bitmodern/bit-ui';
import { useAppSelector } from '@bitmodern/redux/store';
import { appVersionSelectors } from 'src/gen/domain/app_version/appVersionSelector';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { platVersionSelectors } from 'src/gen/domain/plat_version/platVersionSelector';
import { platSelectors } from 'src/gen/domain/plat/platSelector';
import {
  formatAppName,
  formatPlatName,
} from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import { runResultsConfigurationStatusByRunId } from '@bitmodern/redux/state/runResults/selectors';
import {
  failStatusSelector,
  passStatusSelector,
  skipStatusSelector,
} from 'src/packages/redux/state/statuses/selectors';
import { AppVersionPlatVersion } from '@testquality/sdk';
import StatusIcon from '../StatusIcon';
import styles from './RunsConfigurations.module.scss';

type Props = {
  appVersionPlatVersions: AppVersionPlatVersion[];
  runId: number;
};

export default function RunsConfigurations({
  appVersionPlatVersions,
  runId,
}: Props) {
  const { t } = useTranslation();
  const appVersionEntities = useAppSelector(appVersionSelectors.selectEntities);
  const appEntities = useAppSelector(appSelectors.selectEntities);
  const platVersionEntities = useAppSelector(
    platVersionSelectors.selectEntities,
  );
  const platEntities = useAppSelector(platSelectors.selectEntities);

  const configStatus = useAppSelector((state) =>
    runResultsConfigurationStatusByRunId(state, runId),
  );

  const passStatus = useAppSelector(passStatusSelector);
  const failStatus = useAppSelector(failStatusSelector);
  const skipStatus = useAppSelector(skipStatusSelector);

  return (
    <Table outlined rounded>
      <TableHead className={styles.head}>
        <TableCell>{t('configurationsTable.name')}</TableCell>
        <TableCell>
          {passStatus && (
            <Tooltip enterDelay={100} tooltip={passStatus.name}>
              <span>
                <StatusIcon status={passStatus} />
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {failStatus && (
            <Tooltip enterDelay={100} tooltip={failStatus.name}>
              <span>
                <StatusIcon status={failStatus} />
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          {skipStatus && (
            <Tooltip enterDelay={100} tooltip={skipStatus.name}>
              <span>
                <StatusIcon status={skipStatus} />
              </span>
            </Tooltip>
          )}
        </TableCell>

        <TableCell>{t('configurationsTable.defects')}</TableCell>
      </TableHead>
      <TableBody>
        {appVersionPlatVersions.map((avpv) => {
          const appVersion = appVersionEntities[avpv.app_version_id];
          const app = appEntities[appVersion?.app_id || ''];
          const platVersion = platVersionEntities[avpv.plat_version_id];
          const plat = platEntities[platVersion?.plat_id || ''];
          if (configStatus[avpv.id] === undefined) return null;
          return (
            <TableRow key={avpv.id}>
              <TableCell>
                {formatPlatName({ platVersion, plat })}
                {' - '}
                {formatAppName({ app, appVersion })}
              </TableCell>
              <TableCell>{configStatus[avpv.id].pass}</TableCell>
              <TableCell>{configStatus[avpv.id].fail}</TableCell>
              <TableCell>{configStatus[avpv.id].skip}</TableCell>
              <TableCell>{configStatus[avpv.id].defects}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
