import {
  Suite,
  PlanSuite,
  SuiteTest,
  Test,
  PlanSuiteTestInclude,
  RequirementTest,
} from '@testquality/sdk';
import { TreeItem } from 'react-sortable-tree';
import { State } from '@bitmodern/redux/store';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import { suiteSelectors } from 'src/gen/domain/suite/suiteSelector';
import {
  planSuitesByParentSelector,
  planSuitesByPlanSelector,
  planSuitesSelector,
} from '@bitmodern/redux/state/planSuites/selectors';
import {
  suiteTestsBySuiteSelector,
  suiteTestsSelector,
} from '@bitmodern/redux/state/suiteTests/selectors';
import { getTreeFromArray, generateNodeKey, filterTree } from 'src/utils/tree';

import {
  makeRunResultsByDefect,
  removeRootFolder,
} from '../runResults/selectors';
import { rootPlanSelector } from '../plans/selectors';
import {
  includesBySuiteTestSelector,
  planIncludeSelectorBySuite,
  planIncludeSelectorByTest,
} from '../planSuitesTestsIncludes/selectors';
import {
  labelAssignedBySuiteSelector,
  labelAssignedByTestSelector,
} from '../label_assigned/selectors';
import { filtersByTypeSelector } from '../filters/selectors';
import {
  suiteFiltersKeys,
  testsFiltersKeys,
  TestsFiltersKeys,
  TreeFilters,
} from '../filters/reducer';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import {
  requirementsTestByRequirementSelector,
  requirementsTestByTestSelector,
} from '../requirementTest/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { dateRangeByTimeframe } from 'src/packages/bit-ui/InputDateRange/InputDateRange';

export function suitesSelector(state: State) {
  return suiteSelectors.selectAll(state);
}

export function suiteSelector(state: State, id: number) {
  return suiteSelectors.selectById(state, id);
}

export const suitesEntitiesSelector = suiteSelectors.selectEntities;

export function suitesByPlan(state: State, planId: number): Suite[] {
  return planSuitesByPlanSelector(state, planId)
    .map((planSuite) => planSuite.suite_id)
    .map((suiteId) => suiteSelector(state, suiteId) as Suite);
}

const DUMMY_ROOT_KEY = 0;

export function filterTest(
  filters: State['filters']['tests'],
  labels,
  {
    test,
    planSuiteTestIncludes,
    requirementTest,
  }: {
    test: Test;
    planSuiteTestIncludes?: PlanSuiteTestInclude[];
    requirementTest?: RequirementTest[];
  },
  ignoredFilters: { assignee?: boolean } = {},
): boolean {
  if (filters.author && !filters.author[test.created_by]) return false;
  if (filters.caseType && !filters.caseType[test.case_type_id]) return false;
  if (filters.casePriority && !filters.casePriority[test.case_priority_id])
    return false;
  if (ignoredFilters.assignee !== true && filters.assignee) {
    if (!test.assigned_to_tester) return false;
    if (!filters.assignee[test.assigned_to_tester]) return false;
  }

  if (
    typeof filters.automated !== 'undefined' &&
    test.is_automated !== filters.automated
  )
    return false;

  if (filters.label) {
    const labelsIds = labels.map((la) => la.label_id);
    const finded = Object.values(filters.label).find((label) =>
      labelsIds.includes(label),
    );
    if (!finded) return false;
  }

  if (filters.name) {
    const includes = test.name
      .toString()
      .toLowerCase()
      .includes(filters.name.toLowerCase());
    if (!includes) return false;
  }

  if (filters.plan) {
    if (!planSuiteTestIncludes) return false;

    const includesIds = planSuiteTestIncludes.map((i) => i.plan_id);
    const finded = Object.values(filters.plan).find((planId) =>
      includesIds.includes(planId),
    );

    if (!finded) return false;
  }
  if (filters.requirement && requirementTest) {
    const requirementsRelatedToTest = requirementTest.map(
      (rt) => rt.requirement_id,
    );

    const finded = Object.values(filters.requirement).find((requirementId) =>
      requirementsRelatedToTest.includes(requirementId),
    );
    if (!finded) return false;
  }
  const timeframeRange = dateRangeByTimeframe(filters.timeframe);
  if (timeframeRange) {
    if (
      timeframeRange[0] &&
      new Date(test.created_at).getTime() < timeframeRange[0].getTime()
    ) {
      return false;
    }
    if (
      timeframeRange[1] &&
      new Date(test.created_at).getTime() > timeframeRange[1].getTime()
    ) {
      return false;
    }
  } else {
    if (filters.startDate) {
      if (
        new Date(test.created_at).getTime() <
        new Date(filters.startDate).getTime()
      ) {
        return false;
      }
    }
    if (filters.endDate) {
      if (
        new Date(test.created_at).getTime() >
        new Date(filters.endDate).getTime()
      ) {
        return false;
      }
    }
  }

  if (
    filters.testQuality &&
    test.test_quality_id &&
    !filters.testQuality[test.test_quality_id]
  )
    return false;

  return true;
}

function filterSuite(
  filters: State['filters']['tests'],
  state: State,
  { suite }: { suite: Suite },
) {
  if (filters.suiteName) {
    const includes = suite.name
      .toString()
      .toLowerCase()
      .includes(filters.suiteName.toLowerCase());
    if (!includes) return false;
  }
  if (filters.suiteLabel) {
    const labelsIds = labelAssignedBySuiteSelector(state, {
      suiteId: suite.id,
    }).map((la) => la.label_id);
    const finded = Object.values(filters.suiteLabel).find((label) =>
      labelsIds.includes(label),
    );
    if (!finded) return false;
  }
  return true;
}

export function suitesTreeByPlanSelector(
  state: State,
  { projectId, planId }: { projectId?: number; planId?: number },
) {
  if (!projectId) return [];
  const rootPlan = rootPlanSelector(state, projectId);
  if (!rootPlan) return [];

  const planSuites = planSuitesByPlanSelector(state, rootPlan.id).map((ps) => {
    if (!ps.parent_id) return { ...ps, parent_id: DUMMY_ROOT_KEY };
    return ps;
  });

  const suitesIds = planSuites.map((planSuite) => planSuite.suite_id);
  const suiteTests = suiteTestsSelector(state).filter((suiteTest) =>
    suitesIds.includes(suiteTest.suite_id),
  );

  const flatData = [...planSuites, ...suiteTests].sort((a, b) => {
    const seqA = 'sequence_suite' in a ? a.sequence_suite : a.sequence_plan;
    const seqB = 'sequence_suite' in b ? b.sequence_suite : b.sequence_plan;
    return seqA - seqB;
  });

  const data = getTreeFromArray({
    flatData,
    rootKey: `S-${DUMMY_ROOT_KEY}`,
    getKey: (node) => {
      if (node.test_id) return node.id?.toString();
      return `S-${node.suite_id}`;
    },
    getParentKey: (node) => {
      if (node.test_id) return `S-${node.suite_id}`;
      return `S-${node.parent_id}`;
    },
    transform: (item) => {
      if (item.test_id) {
        const suiteTest = item;
        let include;
        if (planId) {
          include = planIncludeSelectorByTest(
            state,
            planId,
            suiteTest.suite_id,
            suiteTest.test_id,
          );
        }
        const includes = includesBySuiteTestSelector(state, {
          suiteId: suiteTest.suite_id,
          testId: suiteTest.test_id,
        });

        const test = testSelectors.selectById(state, suiteTest.test_id);
        return {
          type: TEST_TYPE,
          title: `TC${test?.key} ${test?.name}`,
          data: { test, suiteTest, include, includes },
          nodeKey: generateNodeKey({ suiteTest }),
        };
      }

      // assume suite given not test
      const planSuite = item;
      const include = planId
        ? planIncludeSelectorBySuite(state, planId, planSuite.suite_id)
        : undefined;
      const suite = suiteSelector(state, planSuite.suite_id);
      return {
        type: SUITE_TYPE,
        title: suite?.name,
        data: { suite, planSuite, include },
        nodeKey: generateNodeKey({ planSuite }),
      };
    },
  });

  return removeRootFolder(data);
}

export function filteredSuitesTreeByPlanSelector(
  state: State,
  {
    projectId,
    planId,
    filterType,
  }: {
    projectId: number;
    planId?: number;
    filterType?: TestsFiltersKeys;
  },
) {
  let tree = suitesTreeByPlanSelector(state, {
    projectId,
    planId,
  });

  if (!filterType) return tree;

  const filters = filtersByTypeSelector(state, filterType);

  if (Object.keys(filters).length === 0) return tree;

  const exclusions = 'exclusions' in filters ? filters?.exclusions : undefined;

  // trim folders that are not included
  if (hasSuiteFilters(filters)) {
    tree = filterTree(
      tree,
      (item) => {
        if (item.type === SUITE_TYPE) {
          return filterSuite(filters, state, { suite: item.data.suite });
        }
        return false;
      },
      true,
    );
  }

  if (hasTestFilters(filters) || typeof exclusions !== 'undefined') {
    tree = filterTree(tree, (item) => {
      if (item.type === TEST_TYPE) {
        const showNonIncluded = planId && !exclusions && !item.data.include;
        if (showNonIncluded) return false;

        const labels = labelAssignedByTestSelector(state, {
          testId: item.data.test.id,
        });

        return filterTest(filters, labels, {
          test: item.data.test,
          planSuiteTestIncludes: item.data.includes,
          requirementTest: requirementsTestByTestSelector(
            state,
            item.data.test.id,
          ),
        });
      }
      if (item.type === SUITE_TYPE) {
        if (!planId) return false;
        if (!exclusions && !item.data.include) return false;
        return filterSuite(filters, state, { suite: item.data.suite });
      }
      return true;
    });
  }

  return tree;
}

export function planTreeFilteredSelector(
  state: State,
  {
    projectId,
    planId,
    filterType,
  }: {
    projectId: number;
    planId?: number;
    filterType: TestsFiltersKeys;
  },
): TreeItem[] {
  let tree = suitesTreeByPlanSelector(state, {
    projectId,
    planId,
  });
  const filters = filtersByTypeSelector(state, filterType);

  tree = filterTree(
    tree,
    (item) => {
      if (item.type === TEST_TYPE) {
        const hideNonIncluded = filterType === 'plans' && !item.data.include;
        if (hideNonIncluded) return false;

        const labels = labelAssignedByTestSelector(state, {
          testId: item.data.test.id,
        });
        return filterTest(filters, labels, {
          test: item.data.test,
          planSuiteTestIncludes: item.data.includes,
        });
      }
      return false;
    },
    true,
  );
  return tree;
}

function hasSuiteFilters(filters: TreeFilters) {
  return hasFilters(filters, suiteFiltersKeys);
}
function hasTestFilters(filters: TreeFilters) {
  return hasFilters(filters, testsFiltersKeys);
}

function hasFilters(filters: TreeFilters, keys: string[]) {
  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index];
    if (filters[key]) return true;
  }
  return false;
}

export function suiteChildrenSelector(
  state: State,
  suiteId,
): Array<PlanSuite | SuiteTest> {
  const suiteTests = suiteTestsBySuiteSelector(state, suiteId);
  const planSuites = planSuitesByParentSelector(state, suiteId);

  return [...planSuites, ...suiteTests].sort((a, b) => {
    const seqA = 'sequence_suite' in a ? a.sequence_suite : a.sequence_plan;
    const seqB = 'sequence_suite' in b ? b.sequence_suite : b.sequence_plan;
    return seqA - seqB;
  });
}

export function suitesByRootPlanSelector(state: State, projectId) {
  const rootPlan = rootPlanSelector(state, projectId);
  if (!rootPlan) return suitesSelector(state);
  return suitesByPlan(state, rootPlan.id);
}

export function rootSuiteSelector(state: State, planId: number) {
  const rootPlanSuite = planSuitesSelector(state)
    .filter((ps) => ps.plan_id === planId)
    .find((ps) => suiteSelector(state, ps.suite_id)?.is_root);

  if (!rootPlanSuite) return undefined;
  return suiteSelector(state, rootPlanSuite.suite_id);
}

export function suitesFlatTreeSelector(state: State, projectId: number) {
  const rootPlan = rootPlanSelector(state, projectId);
  if (!rootPlan) return [];

  const planSuites = planSuitesByPlanSelector(state, rootPlan.id)
    .sort((a, b) => a.sequence_plan - b.sequence_plan)
    .map((ps) => {
      if (!ps.parent_id) return { ...ps, parent_id: DUMMY_ROOT_KEY };
      return ps;
    });

  const tree = getTreeFromArray({
    flatData: planSuites,
    rootKey: `S-${DUMMY_ROOT_KEY}`,
    getKey: (node) => `S-${node.suite_id}`,
    getParentKey: (node) => `S-${node.parent_id}`,
    transform: (item) => {
      const planSuite = item;
      const suite = suiteSelector(state, planSuite.suite_id);
      return {
        type: SUITE_TYPE,
        title: suite?.name,
        data: { suite, planSuite },
        nodeKey: generateNodeKey({ planSuite }),
      };
    },
  });

  const flattened = [] as any[];
  const trav = (item, nestingLevel) => {
    flattened.push({ ...item, nestingLevel });
    if (item.children?.length) {
      item.children.forEach((child) => trav(child, nestingLevel + 1));
    }
  };

  tree.forEach((item) => trav(item, 0));

  return flattened;
}

export function filteredSuitesTreeByRequirements(
  state: State,
  {
    projectId,
    requirementId,
    filterType,
  }: {
    projectId: number;
    requirementId: number;
    filterType?: TestsFiltersKeys;
  },
) {
  let tree = suitesTreeByPlanSelector(state, {
    projectId,
  });
  if (!filterType) return tree;

  const filters = filtersByTypeSelector(state, filterType);

  if (!filters) return tree;
  if (Object.keys(filters).length === 0) return tree;

  const exclusions = 'exclusions' in filters ? filters?.exclusions : undefined;

  if (hasSuiteFilters(filters)) {
    tree = filterTree(
      tree,
      (item) => {
        if (item.type === SUITE_TYPE) {
          return filterSuite(filters, state, { suite: item.data.suite });
        }
        return false;
      },
      true,
    );
  }

  if (hasTestFilters(filters)) {
    tree = filterTree(
      tree,
      (item) => {
        if (item.type === TEST_TYPE) {
          return filterTest(filters, state, {
            test: item.data.test,
            requirementTest: requirementsTestByTestSelector(
              state,
              item.data.test.id,
            ),
          });
        }
        return false;
      },
      true,
    );
  }

  if (!exclusions) {
    const relatedTests = requirementsTestByRequirementSelector(
      state,
      requirementId,
    ).map((rt) => rt.test_id);
    tree = filterTree(tree, (item) => {
      if (item.type === TEST_TYPE) {
        if (!relatedTests.includes(item.data.test.id)) return false;

        const labels = labelAssignedByTestSelector(state, {
          testId: item.data.test.id,
        });

        return filterTest(filters, labels, {
          test: item.data.test,
          requirementTest: requirementsTestByTestSelector(
            state,
            item.data.test.id,
          ),
        });
      }
      if (item.type === SUITE_TYPE) {
        if (!item.data.include) return false;
        return filterSuite(filters, state, { suite: item.data.suite });
      }
      return true;
    });
  }
  return tree;
}

export const suitesTreeByDefect = () =>
  createSelector(
    [suitesTreeByPlanSelector, makeRunResultsByDefect()],
    (tree, tests) => {
      const testIds = tests.map((t) => t.test_id);
      if (!testIds) return tree;
      tree = filterTree(tree, (item) => {
        if (item.type === TEST_TYPE) {
          if (!testIds.includes(item.data.test.id)) return false;
        }
        if (item.type === SUITE_TYPE) {
          if (!item.data.include) return false;
        }
        return true;
      });
      return tree;
    },
  );
