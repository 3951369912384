import React, { ReactElement, useCallback } from 'react';
import { Loading } from '@bitmodern/bit-ui';
import { baseIntegrationFetchManyThunk } from 'src/gen/domain/base_integration/baseIntegrationThunk';
import { nativeDefectResFetchManyThunk } from 'src/gen/domain/native_defect_res/nativeDefectResThunk';
import { nativeDefectStatusFetchManyThunk } from 'src/gen/domain/native_defect_status/nativeDefectStatusThunk';
import { integrationTemplateFetchManyThunk } from 'src/gen/domain/integration_template/integrationTemplateThunk';
import useFetch from 'src/hooks/useFetch';
import { useAppDispatch } from '@bitmodern/redux/store';
import { BatchService } from '@testquality/sdk';
import styles from './IntegrationsSectionView.module.scss';

type Props = {
  children: ReactElement;
};

export default function IntegrationsSectionView({ children }: Props) {
  const dispatch = useAppDispatch();

  const request = useCallback(() => {
    const batch = new BatchService();
    dispatch(
      nativeDefectResFetchManyThunk({
        batch,
        params: { per_page: -1, _with: 'DefectRes' },
      }),
    );
    dispatch(
      nativeDefectStatusFetchManyThunk({
        batch,
        params: { per_page: -1, _with: 'DefectStatus' },
      }),
    );
    dispatch(
      baseIntegrationFetchManyThunk({
        batch,
        params: {
          per_page: -1,
          _with: 'baseCapability,integration.project,integration.user',
        },
      }),
    );
    dispatch(
      integrationTemplateFetchManyThunk({
        batch,
        params: {
          per_page: -1,
        },
      }),
    );

    return batch.executeBatch();
  }, [dispatch]);

  const integrationsFetch = useFetch(request, 'baseIntegrations', {
    fetchOnce: true,
  });

  if (integrationsFetch.isLoading) {
    return (
      <div className={styles.loading}>
        <Loading size={48} />
      </div>
    );
  }

  return children;
}
