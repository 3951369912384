import React, { ComponentProps, ReactNode, createContext } from 'react';
import styles from './TableHead.module.scss';

type Props = ComponentProps<'thead'> & {
  children: ReactNode;
};

export default function TableHead({
  children,
  className = '',
  ...rest
}: Props) {
  return (
    <thead className={`${styles.tableHead} ${className}`} {...rest}>
      <tr>
        <TableHeadContext.Provider value={true}>
          {children}
        </TableHeadContext.Provider>
      </tr>
    </thead>
  );
}

export const TableHeadContext = createContext(false);
