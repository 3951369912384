import React, { useState, useCallback } from 'react';
import { SuiteTest, Test } from '@testquality/sdk';
import { Grid, PanelHeader, Spacer, Surface, Switch } from '@bitmodern/bit-ui';
import {
  AnalyzeTree,
  AnalyzeFilters,
  Requirements,
  RequirementsChart,
  RequirementsMetrics,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { requirementsByProjectSelector } from '@bitmodern/redux/state/requirements/selectors';
import { requirementsTestByRequirementsSelector } from '@bitmodern/redux/state/requirementTest/selectors';
import styles from './AnalyzeRequirementsView.module.scss';
import { useParamSelector } from 'src/packages/redux/hooks';
import { getDateRangeByTimeframe } from 'src/packages/redux/state/date/selectors';

export default function AnalyzeRequirementsView() {
  const { projectId: projeIdUrl } = useParams<typeof routes.ANALYZE.params>();
  const { t } = useTranslation();
  const projectId = parseInt(projeIdUrl, 10);
  const [showRequirements, setShowRequirements] = useState(true);
  const { openDrawer } = useDrawerManager();
  const timeframe = useParamSelector(getDateRangeByTimeframe, {
    type: 'analyze',
  });
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const allTests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId }),
  );
  const requirements = useAppSelector((state) =>
    requirementsByProjectSelector(state, { projectId }),
  );
  const requirementsTest = useAppSelector((state) =>
    requirementsTestByRequirementsSelector(
      state,
      requirements.map((rq) => rq.id),
    ),
  );

  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId,
      filterType: 'analyze',
    }),
  );

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const infoTagsToRender = (test: Test) => {
    const requirement = requirements.find((req) => req.related_id === test.id);

    return requirement ? (
      <span className={styles.badge}>{requirement.payload.summary}</span>
    ) : null;
  };

  if (!project || !timeframe) return null;

  return (
    <>
      <Surface>
        <PanelHeader
          title={project.name}
          actions={
            <Spacer className={styles.spacer}>
              <Switch
                checkedChildren={t(
                  'analyze.testRequirements.switch.byRequirements',
                )}
                unCheckedChildren={t('analyze.testRequirements.switch.byTests')}
                checked={showRequirements}
                onChange={setShowRequirements}
              />
              <AnalyzeFilters />
            </Spacer>
          }
        />
        <Grid.Row gutter={0} className={styles.rowContainer}>
          <Grid.Col span={12} md={5} className={styles.column}>
            <RequirementsMetrics
              range={timeframe}
              tests={allTests}
              requirements={requirements}
              requirementsTest={requirementsTest}
            />
          </Grid.Col>
          <Grid.Col span={12} md={7}>
            <RequirementsChart range={timeframe} requirements={requirements} />
          </Grid.Col>
        </Grid.Row>
      </Surface>
      <div style={{ height: 400 }}>
        {showRequirements ? (
          <Requirements requirements={requirements} />
        ) : (
          <AnalyzeTree
            id={project.id.toString()}
            onClickTest={onClickTest}
            suitesTree={tree}
            infoTagsToRender={infoTagsToRender}
          />
        )}
      </div>
    </>
  );
}
