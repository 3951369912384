/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PlatHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { platHistoryAdapter } from './platHistoryAdapter';
import { platHistoryFetchThunk } from './platHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const platHistorySlice = createSlice({
  name: 'platHistory',
  initialState: platHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    platHistoryCleared(state) {
      state.loading = 'idle';
      platHistoryAdapter.removeAll(state);
    },
    platHistoryUpsertMany(
      state,
      action: PayloadAction<PlatHistory[] | Record<number, PlatHistory>>,
    ) {
      platHistoryAdapter.upsertMany(state, action.payload);
    },
    platHistoryUpsertOne(state, action: PayloadAction<PlatHistory>) {
      platHistoryAdapter.upsertOne(state, action.payload);
    },
    platHistoryUpdateOne(state, action: PayloadAction<Update<PlatHistory>>) {
      platHistoryAdapter.updateOne(state, action.payload);
    },
    platHistoryRemoveOne(state, action: PayloadAction<number>) {
      platHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(platHistoryFetchThunk.pending, handlePending);
    builder.addCase(platHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platHistoryAdapter.upsertMany(state, action.payload.platHistory);
    });
    builder.addCase(platHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  platHistoryCleared,
  platHistoryUpsertMany,
  platHistoryUpsertOne,
  platHistoryUpdateOne,
  platHistoryRemoveOne,
} = platHistorySlice.actions;

export const platHistoryReducer = platHistorySlice.reducer;
