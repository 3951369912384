import { Attachment, User } from '@testquality/sdk';
import { getApiBaseUrl } from '../Client';

export function getFileSize(file: File | Attachment) {
  if (file.size! < 1024) {
    return `${file.size} bytes`;
  }

  if (Math.round(Number(file.size!) / 1024 / 1024) >= 1) {
    return `${Math.round(Number(file.size!) / 1024 / 1024)} MB`;
  }

  if (file.size! > 1024) {
    return `${Math.round(Number(file.size!) / 1024)} KB`;
  }

  return '';
}

export function processAttachmentPath(path?: string) {
  if (!path) return '';

  if (path.startsWith('/storage/')) {
    return `${getApiBaseUrl()}/${path?.replace('/storage/', '')}`;
  }

  return path;
}

export function formatUserName(user?: User) {
  if (user) {
    if (user.given_name) {
      let name = user.given_name;
      if (user.family_name) {
        name += ` ${user.family_name}`;
      }
      return name;
    }
    return user.email;
  }
  return '';
}

export function getUsersMap(users: User[]) {
  return users.map((user) => ({
    value: user.id,
    label: formatUserName(user),
    picture: processAttachmentPath(user.picture),
  }));
}
