import React, { ReactNode } from 'react';
import styles from './Surface.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
};

export default function Surface({ className = '', children, onClick }: Props) {
  return (
    <div className={`${styles.surface} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
}
