import React from 'react';
import { Attachment, IntegrationUser, User } from '@testquality/sdk';
import { toM } from 'src/utils/J2M';
import { Avatar, MarkdownView } from '@bitmodern/bit-ui';
import { RelatedType } from '@bitmodern/services/AttachmentService';
import styles from './Comments.module.scss';
import CommentCreate from '../CommentCreate';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';
import { useTranslation } from 'src/i18n/hooks';

// If the comments are generated in the backend we may need to check if we can auto generate a type for this
type JIRAComment = {
  body: string;
  id: string;
  url: string;
  user: string;
};

type GithubComment = {
  body: string;
  id: number;
  url: string;
  user: string;
  updated_at: string;
  created_at: string;
};

type Props = {
  // Array of both kinds because it can have 2 different integrations
  comments?: Array<JIRAComment | GithubComment>;
  integrationUsers?: IntegrationUser[];
  isJira?: boolean;
  onComment: (value?: string) => void;
  onFileUpload?: (attachment: Attachment) => void;
  parentId: number;
  parentType: RelatedType;
  rendered?: boolean;
  users: User[];
};

export default function Comments({
  comments,
  integrationUsers,
  isJira,
  onComment,
  onFileUpload,
  parentId,
  parentType,
  rendered,
  users,
}: Props) {
  const { t } = useTranslation();
  const getUserPicture = (userName: string) => {
    const integrationUser = integrationUsers?.find(
      (iu) => iu.username === userName,
    );
    if (!integrationUser) return '';
    const user = users.find((u) => u.id === integrationUser?.user_id);
    if (user) {
      return processAttachmentPath(user.picture);
    }
    return '';
  };

  const getUserNameFormat = (userName: string) => {
    const integrationUser = integrationUsers?.find(
      (iu) => iu.username === userName,
    );
    if (!integrationUser) return userName;
    const user = users.find((u) => u.id === integrationUser?.user_id);
    if (user) {
      return formatUserName(user);
    }
    return userName;
  };

  return (
    <>
      {comments?.map((comment: JIRAComment | GithubComment) => (
        <div className={styles.comment} key={comment.id}>
          <Avatar
            className={styles.avatar}
            size={24}
            name={getUserNameFormat(comment.user)}
            src={getUserPicture(comment.user)}
          />
          <div className={styles.comentContent}>
            <div>
              {getUserNameFormat(comment.user)}
              {/* Property exists, so it's a GitHub comment and a check for it to bet set */}
              {'created_at' in comment && comment.created_at && (
                <span className={styles.ago}>
                  {t('comment.ago', { date: new Date(comment.created_at) })}{' '}
                </span>
              )}
            </div>
            {rendered ? (
              <MarkdownView
                className={styles.comentBody}
                markdown={comment.body}
                read
              />
            ) : (
              <MarkdownView
                className={styles.comentBody}
                markdown={isJira ? toM(comment.body || '') : comment.body || ''}
                read
              />
            )}
          </div>
        </div>
      ))}
      <CommentCreate
        onComment={onComment}
        onFileUpload={onFileUpload}
        parentId={parentId}
        parentType={parentType}
        withFormatting={!isJira}
      />
    </>
  );
}
