/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { runAdapter } from './runAdapter';

export const runSelectors = runAdapter.getSelectors(
  (state: State) => state.gen.run,
);
