/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectStatusNativeDefectStatusAdapter } from './defectStatusNativeDefectStatusAdapter';

export const defectStatusNativeDefectStatusSelectors =
  defectStatusNativeDefectStatusAdapter.getSelectors(
    (state: State) => state.gen.defectStatusNativeDefectStatus,
  );
