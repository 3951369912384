import React, { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './TableRow.module.scss';

type Props = ComponentProps<'tr'> & {
  children: ReactNode;
  collapsed?: boolean;
  selected?: boolean;
};

export default function TableRow({
  className,
  collapsed = false,
  children,
  selected = false,
  ...rest
}: Props) {
  const rowCN = classnames(styles.tableRow, className, {
    [styles.selected]: selected,
    [styles.collapsed]: collapsed,
  });

  return (
    <tr className={rowCN} {...rest}>
      {children}
    </tr>
  );
}
