/* eslint-disable consistent-return */
import React, { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { Button } from '@bitmodern/bit-ui';
import { FlagIcon } from '@bitmodern/bit-ui/icons';
import { Milestone } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import useModalManager from 'src/hooks/useModalManager';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import { ConfirmDialog, MilestoneFormDialog } from 'src/components/organisms';
import EmptyList from 'src/components/organisms/EmptyList';
import styles from './Milestones.module.scss';
import MilestoneItem from '../MilestoneItem';

type Props = {
  current: null | number;
  milestones: Milestone[];
  onChangeCurrent: (current: number | null) => void;
  onComplete: (milestone: Milestone) => void;
  onDeleteMilestone: (milestone: Milestone) => Promise<any>;
  onEditMilestone: ComponentProps<typeof MilestoneFormDialog>['onSubmit'];
  onIncomplete: (milestone: Milestone) => void;
  onChangeDropdownVisible: (value: boolean) => void;
};

export default function Milestones({
  current,
  milestones,
  onChangeCurrent,
  onComplete,
  onDeleteMilestone,
  onEditMilestone,
  onIncomplete,
  onChangeDropdownVisible,
}: Props) {
  const { t } = useTranslation();
  const [currentMilestone, setCurrentMilestone] = useState<number>();
  const editMilestone = useOverlayTriggerState({});
  const deleteMilestone = useOverlayTriggerState({});
  const { showModal } = useModalManager();

  const onConfirmDelete = useCallback(() => {
    const milestone = milestones.find((m) => m.id === currentMilestone);
    if (!milestone) return Promise.resolve();

    return onDeleteMilestone(milestone).finally(deleteMilestone.close);
  }, [currentMilestone, deleteMilestone, milestones, onDeleteMilestone]);

  const deleteMutation = useMutation(onConfirmDelete);

  const onClickMilestone = (milestone) => {
    if (milestone.id === current) {
      onChangeCurrent(null);
    } else {
      onChangeCurrent(milestone.id);
    }
  };

  const handleEdit = (values) => {
    return onEditMilestone({ ...values, id: currentMilestone }).finally(
      editMilestone.close,
    );
  };

  const onEdit = (milestone: Milestone) => {
    onChangeDropdownVisible(false);
    setCurrentMilestone(milestone.id);
    editMilestone.open();
  };

  const onDelete = (milestone: Milestone) => {
    onChangeDropdownVisible(false);
    setCurrentMilestone(milestone.id);
    deleteMilestone.open();
  };

  const openMilestoneCreateModal = () => {
    onChangeDropdownVisible(false);
    showModal({
      modalName: 'milestoneCreate',
      type: 'modal',
    });
  };

  const initialMilestone = useMemo(() => {
    const milestone = milestones.find((m) => m.id === currentMilestone);
    if (!milestone) return;

    return {
      name: milestone.name,
      description: milestone.description || '',
      startDate: milestone.start_date
        ? new Date(milestone.start_date)
        : undefined,
      releaseDate: milestone.release_date
        ? new Date(milestone.release_date)
        : undefined,
    };
  }, [currentMilestone, milestones]);

  if (milestones.length === 0)
    return (
      <div className={styles.milestones}>
        <EmptyList
          button={
            <Button
              color="accent"
              icon={<FlagIcon color={vars.onSuccess} />}
              onClick={openMilestoneCreateModal}>
              {t('milestones.create')}
            </Button>
          }
          className={styles.empty}
          icon={<FlagIcon color={vars.textPrimary} size={64} />}
          description={t('milestones.empty.desc')}
          title={t('milestones.empty.title')}
        />
      </div>
    );

  return (
    <>
      {milestones.map((milestone) => (
        <MilestoneItem
          key={milestone.id}
          milestone={milestone}
          onClick={onClickMilestone}
          onComplete={onComplete}
          onIncomplete={onIncomplete}
          onDelete={onDelete}
          onEdit={onEdit}
          selected={current === milestone.id}
        />
      ))}

      <MilestoneFormDialog
        initialValues={initialMilestone}
        onClose={editMilestone.close}
        onSubmit={handleEdit}
        open={editMilestone.isOpen}
      />
      <ConfirmDialog
        isDismissable={false}
        loading={deleteMutation.isLoading}
        onCancel={deleteMilestone.close}
        onConfirm={deleteMutation.mutate}
        open={deleteMilestone.isOpen}
        title={t('milestones.deleteDialog.title')}>
        {t('milestones.deleteDialog.content')}
      </ConfirmDialog>
    </>
  );
}
