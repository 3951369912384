/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Invoice,
  invoiceHistoryGet,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';

export const invoiceHistoryFetchThunk = createAsyncThunk(
  'invoiceHistory/FetchThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Invoice>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      arg.cancelToken = source.token;
      const data = await invoiceHistoryGet(arg);
      return {
        invoiceHistory: data,
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
