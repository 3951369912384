/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlanPullRequestApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const planPullRequestNormalizer = (
  model: PlanPullRequestApi,
  cache: NormalizerCache,
) => {
  if (!cache.planPullRequest) {
    cache.planPullRequest = [];
  }
  cache.planPullRequest.push(model);
};
