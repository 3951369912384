/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Virtual } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { virtualAdapter } from './virtualAdapter';
import {
  virtualFetchManyThunk,
  virtualFetchOneThunk,
  virtualDeleteOneThunk,
  virtualUpdateOneThunk,
  virtualCreateOneThunk,
} from './virtualThunk';
import {
  clientFetchManyThunk,
  clientFetchOneThunk,
} from '../client/clientThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const virtualSlice = createSlice({
  name: 'virtual',
  initialState: virtualAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    virtualCleared(state) {
      state.loading = 'idle';
      virtualAdapter.removeAll(state);
    },
    virtualUpsertMany(
      state,
      action: PayloadAction<Virtual[] | Record<number, Virtual>>,
    ) {
      virtualAdapter.upsertMany(state, action.payload);
    },
    virtualUpsertOne(state, action: PayloadAction<Virtual>) {
      virtualAdapter.upsertOne(state, action.payload);
    },
    virtualUpdateOne(state, action: PayloadAction<Update<Virtual>>) {
      virtualAdapter.updateOne(state, action.payload);
    },
    virtualRemoveOne(state, action: PayloadAction<number>) {
      virtualAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(virtualFetchManyThunk.pending, handlePending);
    builder.addCase(virtualFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      virtualAdapter.upsertMany(state, action.payload.virtual);
    });
    builder.addCase(virtualFetchManyThunk.rejected, handleRejected);
    builder.addCase(virtualFetchOneThunk.pending, handlePending);
    builder.addCase(virtualFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      virtualAdapter.upsertOne(state, action.payload.virtual[0]);
    });
    builder.addCase(virtualFetchOneThunk.rejected, handleRejected);
    builder.addCase(virtualDeleteOneThunk.pending, handlePending);
    builder.addCase(virtualDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        virtualAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(virtualDeleteOneThunk.rejected, handleRejected);
    builder.addCase(virtualUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, virtualAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(virtualUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      virtualAdapter.updateOne(state, action.payload);
    });
    builder.addCase(virtualUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          virtualAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(virtualCreateOneThunk.pending, handlePending);
    builder.addCase(virtualCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      virtualAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(virtualCreateOneThunk.rejected, handleRejected);
    builder.addCase(clientFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.virtual) {
        virtualAdapter.upsertMany(state, action.payload.virtual);
      }
    });
    builder.addCase(clientFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.virtual) {
        virtualAdapter.upsertMany(state, action.payload.virtual);
      }
    });
  },
});

export const {
  virtualCleared,
  virtualUpsertMany,
  virtualUpsertOne,
  virtualUpdateOne,
  virtualRemoveOne,
} = virtualSlice.actions;

export const virtualReducer = virtualSlice.reducer;
