import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Surface } from '@bitmodern/bit-ui';
import classnames from 'classnames';
import { Requirement } from '@testquality/sdk';
import { routes } from 'src/components/Router';
import styles from './RequirementListed.module.scss';
import { useAppSelector } from 'src/packages/redux/store';
import { getIntegrationProjectById } from 'src/packages/redux/state/integrationProject/selectors';
import { useTranslation } from 'react-i18next';
import { integrationByIdSelector } from 'src/packages/redux/state/integration/selectors';
import { baseIntegrationByIdSelector } from 'src/packages/redux/state/baseIntegration/selectors';
import BaseIntegrationIcon from '../BaseIntegrationIcon';
import vars from 'src/export.scss';
import { useMaker } from 'src/packages/redux/hooks';
import { makeTestsCountByRequirement } from 'src/packages/redux/state/tests/selectors';
import { makeDefectsCountByRequirement } from 'src/packages/redux/state/defects/selectors';
import { BugIcon, TestIcon } from 'src/packages/bit-ui/icons';

type Props = {
  requirement: Requirement;
  selected?: boolean;
  site: string;
};

function RequirementListed({ requirement, selected = false, site }: Props) {
  const { t } = useTranslation();
  const requirementListedCN = classnames(styles.requirementListed, {
    [styles.selected]: selected,
  });

  const integrationProject = useAppSelector((state) =>
    getIntegrationProjectById(state, {
      id: requirement?.integration_project_id,
    }),
  );

  const integration = useAppSelector((state) =>
    integrationByIdSelector(state, {
      id: integrationProject?.integration_id,
    }),
  );

  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, {
      id: integration?.base_integration_id,
    }),
  );

  const testsCount = useMaker(makeTestsCountByRequirement, {
    requirementId: requirement.id,
  });

  const defectsCount = useMaker(makeDefectsCountByRequirement, {
    requirementId: requirement.id,
  });

  return (
    <Link
      className={styles.link}
      to={routes.REQUIREMENT({
        site,
        projectId: requirement.project_id.toString(),
        requirementId: String(requirement.id),
      })}>
      <Surface className={requirementListedCN}>
        <div className={styles.cycleHeader}>
          <div className={styles.main}>
            <div className={styles.name}>
              <span key="key" className={styles.key}>
                {requirement.external_reference_id}
              </span>
              {requirement.payload.summary}
            </div>
          </div>

          <div className={styles.info}>
            <div className={`${styles.infoItem} ${styles.tests}`}>
              <TestIcon
                className={styles.icon}
                color={vars.textSecondary}
                size={16}
              />
              <span>{t('requirements.item.tests', { count: testsCount })}</span>
            </div>
            <div className={styles.infoItem}>
              <BugIcon
                className={styles.icon}
                color={vars.textSecondary}
                size={18}
              />
              {defectsCount}
            </div>
          </div>

          {integrationProject && (
            <div className={styles.footer}>
              <div className={styles.integrationIcon}>
                <BaseIntegrationIcon
                  baseIntegration={baseIntegration}
                  color={vars.textPrimary}
                  size={18}
                />
              </div>
              <div className={styles.integration}>
                {t('requirement.integrationText', {
                  org: integrationProject?.org,
                  name: integrationProject?.project_reference_id,
                })}
              </div>
            </div>
          )}
        </div>
      </Surface>
    </Link>
  );
}

export default memo(RequirementListed);
