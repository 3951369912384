import {
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useKeyboard,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useStateProp from 'src/hooks/useStateProp';
import styles from './CommandBar.module.scss';
import { CommandBarRow } from './CommandBarRow';
import { CommandBarGroup } from './CommandBarGroup';
import { CommandItem } from './CommandItem';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export type Command = {
  id: string;
  command: string;
  label: ReactNode;
  withSeparator?: boolean;
  checked?: boolean;
  sublabel?: ReactNode;
  icon?: ReactElement;
  extraActions?: ReactElement;
  handler: (command: Command) => void;
  onChangeChecked?: (checked: boolean) => void;
  isLoading?: boolean;
};

export type CommandGroup = {
  id: string;
  command: string;
  label?: ReactNode;
  withSeparator?: boolean;
  commands: Command[];
};

export type CommandBarType = Array<CommandGroup | Command>;

export type CommandBarProps = {
  elements: CommandBarType;
  id: string;
  onChangeSearch?: (search: string) => void;
  onClose: () => void;
  open?: boolean;
  placeholder?: string;
  search?: string;
  extraActions?: ReactElement;
  isLoading?: boolean;
  onBackpress?: () => void;
};

export default function CommandBar(props: CommandBarProps) {
  const {
    elements,
    id,
    onChangeSearch,
    onClose,
    open = false,
    placeholder,
    extraActions,
    search: searchProp = '',
    isLoading = false,
    onBackpress,
  } = props;
  const [current, setCurrent] = useState(0);
  const [search, setSearch] = useStateProp(searchProp, onChangeSearch);
  const modalRef = React.useRef(null);
  usePreventScroll({ isDisabled: !open });
  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen: open,
      isDismissable: true,
    },
    modalRef,
  );

  const { t } = useTranslation();

  const searchCN = classnames(styles.search, {
    [styles.disabled]: isLoading,
  });

  // Filter
  const filteredCommands = useMemo(() => {
    const cmds: CommandBarType = elements.reduce<CommandBarType>(
      (acu, element) => {
        if (!('commands' in element)) {
          if (
            element.command.toLowerCase().includes(search.toLocaleLowerCase())
          ) {
            acu.push(element);
          }
        } else {
          const toPush = { ...element };
          toPush.commands = element.commands.filter((e) =>
            e.command.toLowerCase().includes(search.toLocaleLowerCase()),
          );
          if (toPush.commands.length > 0) {
            acu.push(toPush);
          }
        }

        return acu;
      },
      [],
    );

    if (search === '') {
      return elements;
    } else {
      return cmds;
    }
  }, [elements, search]);

  // Use to navigate and to set current commmand
  const flatCommands: Command[] = filteredCommands.flatMap((cmd) => {
    if ('commands' in cmd) return cmd.commands;
    return cmd;
  });
  const currentCommand = flatCommands[current];

  const handleOnBackpress = () => {
    if (!isLoading && onBackpress) {
      onBackpress();
    }
  };

  const onKeyDown = (event) => {
    if (isLoading) return;
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setCurrent((s) => (current === 0 ? flatCommands.length - 1 : s - 1));
        break;
      case 'ArrowDown':
        event.preventDefault();
        setCurrent((s) => (current === flatCommands.length - 1 ? 0 : s + 1));
        break;
      case 'Enter':
        if (!currentCommand) return;
        if (current !== -1 && 'handler' in currentCommand) {
          event.preventDefault();
          currentCommand.handler(currentCommand);
        }
        break;
      case 'Backspace':
        if (search === '') {
          event.preventDefault();
          handleOnBackpress();
        }
        break;
      case ' ': {
        if (!currentCommand || !search || current === -1) return;
        const { onChangeChecked, checked } = currentCommand;
        if (onChangeChecked) {
          event.preventDefault();
          onChangeChecked(typeof checked === 'undefined' ? true : !checked);
        }
        break;
      }

      default:
        break;
    }
  };
  const { keyboardProps } = useKeyboard({ onKeyDown });

  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, modalRef);

  useEffect(() => {
    if (!open) {
      setSearch('');
      setCurrent(0);
    }
  }, [open, setSearch]);

  useEffect(() => {
    setSearch('');
    setCurrent(0);
  }, [id, setSearch]);

  const handleChangeSearch = (event) => {
    setCurrent(0);
    setSearch(event.target.value);
  };

  const onHoverCommand = (command: Command) => {
    setCurrent(flatCommands.findIndex((i) => i.command === command.command));
  };

  const onClickCommand = (command: Command) => {
    setCurrent(flatCommands.findIndex((i) => i.command === command.command));
    command.handler(command);
  };

  return (
    <AnimatePresence>
      {open && (
        <OverlayContainer>
          <div className={styles.wrapper}>
            <FocusScope contain restoreFocus autoFocus>
              <motion.div
                animate="visible"
                initial="initial"
                exit="hidden"
                variants={{
                  initial: {
                    opacity: 0.8,
                    scale: 0.8,
                    y: 40,
                  },
                  visible: {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                  },
                  hidden: {
                    opacity: 0,
                    scale: 1,
                    y: 0,
                  },
                }}
                className={styles.commandBar}
                {...(mergeProps(
                  overlayProps,
                  dialogProps,
                  modalProps,
                  keyboardProps,
                ) as any)}
                ref={modalRef}>
                <>
                  <div className={styles.searchWrapper}>
                    <input
                      className={searchCN}
                      onChange={handleChangeSearch}
                      placeholder={placeholder}
                      value={search}
                      disabled={isLoading}
                    />
                  </div>
                  <ul className={styles.list} role="listbox">
                    {filteredCommands.length === 0 && (
                      <CommandItem
                        key={'no-results'}
                        selected={true}
                        onHover={() => null}
                        onClick={() => null}>
                        <div>
                          <div>{t('commandBar.noResults')}</div>
                        </div>
                      </CommandItem>
                    )}
                    {filteredCommands.map((element) => {
                      if ('commands' in element) {
                        if (element.commands.length === 0) return null;
                        return (
                          <CommandBarGroup
                            key={element.id}
                            group={element}
                            withSeparator={element.withSeparator}>
                            {element.commands.map((c) => (
                              <CommandBarRow
                                key={element.id}
                                command={c}
                                onClickCommand={onClickCommand}
                                onHoverCommand={onHoverCommand}
                                current={currentCommand.command === c.command}
                                withSeparator={c.withSeparator}
                                isLoading={isLoading || c.isLoading}
                              />
                            ))}
                          </CommandBarGroup>
                        );
                      } else {
                        return (
                          <CommandBarRow
                            key={element.id}
                            command={element}
                            onClickCommand={onClickCommand}
                            onHoverCommand={onHoverCommand}
                            current={
                              currentCommand?.command === element.command
                            }
                            withSeparator={element.withSeparator}
                            isLoading={isLoading || element.isLoading}
                          />
                        );
                      }
                    })}
                  </ul>
                  {extraActions}
                </>
              </motion.div>
            </FocusScope>
          </div>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
}
