import React from 'react';
import classnames from 'classnames';
import { SeparatorProps, useSeparator } from 'react-aria';
import styles from './Separator.module.scss';

type Props = SeparatorProps & {
  className?: string;
  color?: 'primaryMain' | 'primaryDark';
  size?: 'minimal' | 'small' | 'medium';
};

export default function Separator(props: Props) {
  const { separatorProps } = useSeparator(props);
  const {
    orientation,
    className = '',
    color = 'primaryDark',
    size = 'medium',
  } = props;

  const separatorCN = classnames(
    styles.separator,
    styles[color],
    styles[size],
    className,
    {
      [styles.vertical]: orientation === 'vertical',
    },
  );

  return <hr {...separatorProps} className={separatorCN} />;
}
