import { getClient } from 'src/Client';

export function postFile(
  file: File,
  id: number,
  metadataModel: string,
  urlAttribute: string,
): Promise<void> {
  const data = new FormData();
  data.append('id', id.toString());
  data.append('metadata_model', metadataModel);
  data.append('url_attribute', urlAttribute);
  data.append('file', file);
  return getClient().api.post('/file/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
