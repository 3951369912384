import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import {
  DataSet,
  DASET_ID_KEY,
} from 'src/packages/redux/state/dataSets/selectors';
import {
  Button,
  notification,
  Table,
  ErrorDisplay,
  useFocusAccent,
} from '@bitmodern/bit-ui';
import { ErrorBoundary } from 'src/modules/core/elements';
import classNames from 'classnames';
import DataSetHeader from './components/DataSetHeader';
import { DataSetBody, DataSetBodyRef } from './components/DataSetBody';
import DataSetColumnDialog from './components/DataSetColumnDialog';
import { ConfirmDialog } from 'src/components/organisms';
import { useOverlayTriggerState } from 'react-stately';
import { useTranslation } from 'src/i18n/hooks';
import { useEditing } from '@bitmodern/bit-ui/InlineEdit/useEditing';
import { notificationErrorTimeout } from 'src/constants';
import { DeleteIcon } from 'src/packages/bit-ui/icons';
import { InlineEditButtons } from 'src/packages/bit-ui/InlineEdit/InlineEditButtons';
import vars from 'src/export.scss';
import { useDataset, DataSetReq } from './useDataSet';
import styles from './DataSet.module.scss';
import useMutation from 'src/hooks/useMutation';

type Props = {
  dataSet?: DataSetReq;
  onCancel: () => void;
  onCommit: (dataSet: Partial<DataSet>) => Promise<any>;
  onDelete: () => Promise<any>;
};

const tq_id: DataSet['schema'][typeof DASET_ID_KEY] = { type: 'pk', count: 0 };
const dataSetDefaultProps = {
  schema: { tq_id },
  data: [],
};

export default function DataSetTable({
  dataSet: dataSetProps = dataSetDefaultProps,
  onCancel,
  onCommit,
  onDelete,
}: Props) {
  const {
    dataSet,
    handleAddColumn,
    handleAddRow,
    handleDeleteColumn,
    handleEditColumName,
    handleTableCellChange,
    handleDeleteRow,
    setDataSet,
  } = useDataset({ dataSet: dataSetProps });
  const { t } = useTranslation();
  const dataSetBody = useRef<DataSetBodyRef>(null);
  const [focusCell, setFocusCell] = useState<[string, number] | null>(null);

  const [editColumnNameKey, setEditColumnNameKey] = useState<string>();
  const deleteDataSetDialog = useOverlayTriggerState({});
  const columnDialog = useOverlayTriggerState({
    defaultOpen: Object.keys(dataSetProps.schema).length === 1,
  });

  const inlineEdit = useEditing({
    onCommit: () => onCommit(dataSet),
    onCancel: () => setDataSet(dataSetProps),
  });
  const { borderClassName, borderProps } = useFocusAccent();

  const deleteMutation = useMutation(onDelete);
  const numberOfColumns = Object.keys(dataSet.schema).length;

  useEffect(() => {
    if (focusCell) {
      dataSetBody.current?.focusCell(...focusCell);
    }
  }, [focusCell]);

  const onAddRow = () => {
    handleAddRow();
    // focus next row
    setFocusCell([
      Object.keys(dataSet.schema).filter((c) => c !== DASET_ID_KEY)[0],
      dataSet.data.length,
    ]);
  };

  const upsertColumn = async (newName: string) => {
    if (editColumnNameKey)
      return handleEditColumName(newName, editColumnNameKey);
    return handleAddColumn(newName);
  };

  const onColumnDialogSubmit = async (newName: string) => {
    return upsertColumn(newName)
      .then(async (ds) => {
        if (ds && ds.id === undefined) {
          await onCommit(ds);
        }
        inlineEdit.open();
        // dialog has focus traped until it is closed. Currenlty there is
        // no way to perform actions after the dialog is closed
        setTimeout(() => setFocusCell([newName, 0]), 300);
      })
      .catch((error) => {
        if (error.name === 'duplicatedColumn') {
          notification.open({
            type: 'error',
            message: 'Error',
            description: t('dataSet.errors.duplicatedColumn'),
            duration: notificationErrorTimeout,
          });
        }
      });
  };

  const handleColumnDialogClose = () => {
    setEditColumnNameKey(undefined);
    columnDialog.close();
    onCancel();
  };

  const handleTableClick = () => {
    if (!inlineEdit.isEditing) inlineEdit.open();
  };

  const handleTableBodyClick = (event: MouseEvent<HTMLTableSectionElement>) => {
    if (event.target instanceof HTMLElement) {
      const { column, row } = event.target.dataset;
      if (column && row) {
        setFocusCell([column, parseInt(row, 10)]);
      }
    }
  };

  const columns = Object.keys(dataSet.schema).filter((c) => c !== DASET_ID_KEY);

  const tableCN = classNames(styles.table, {
    [borderClassName]: !inlineEdit.isEditing,
  });

  return (
    <div className={styles.dataset}>
      <ErrorBoundary
        fallback={<ErrorDisplay>{t('dataSet.errors.default')}</ErrorDisplay>}>
        {Boolean(numberOfColumns) && (
          <>
            <div className={styles.heading}>
              <div className={styles.title}>{t('dataSet.title')}</div>
              <Button
                size="small"
                color="primaryLight"
                icon={<DeleteIcon color={vars.textPrimary} size={16} />}
                onClick={deleteDataSetDialog.open}>
                {t('test.dataSet.remove')}
              </Button>
            </div>
            <Table
              outlined
              rounded
              className={tableCN}
              onClick={handleTableClick}
              {...borderProps}>
              <DataSetHeader
                columns={columns}
                isEditing={inlineEdit.isEditing}
                onAddColumn={columnDialog.open}
                onAddRow={onAddRow}
                onDeleteColumn={handleDeleteColumn}
                onEditColumnName={(columnName) => {
                  setEditColumnNameKey(columnName);
                  columnDialog.open();
                }}
              />
              <DataSetBody
                dataSetData={dataSet.data}
                isEditing={inlineEdit.isEditing}
                onAddRow={onAddRow}
                onDeleteRow={handleDeleteRow}
                onTableBodyClick={handleTableBodyClick}
                onTableCellChange={handleTableCellChange}
                ref={dataSetBody}
              />
            </Table>
            <InlineEditButtons
              compact={false}
              isEditing={inlineEdit.isEditing}
              onCancel={inlineEdit.cancel}
              onCommit={inlineEdit.commit}
            />
          </>
        )}

        <DataSetColumnDialog
          columnName={editColumnNameKey}
          isOpen={columnDialog.isOpen}
          onSubmit={onColumnDialogSubmit}
          onClose={handleColumnDialogClose}
        />
        <ConfirmDialog
          onCancel={deleteDataSetDialog.close}
          onConfirm={deleteMutation.mutate}
          loading={deleteMutation.isLoading}
          open={deleteDataSetDialog.isOpen}
          title={t('test.dataSet.remove')}>
          {t('test.dataSet.confirm')}
        </ConfirmDialog>
      </ErrorBoundary>
    </div>
  );
}
