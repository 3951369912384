import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useOverlayTriggerState } from 'react-stately';
import { Comment, User } from '@testquality/sdk';

import {
  Avatar,
  Dropdown,
  EasyMDEEditor,
  IconButton,
  MarkdownView,
  Menu,
  MenuItem,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { DeleteIcon, EditIcon, MoreIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { useEditing } from '@bitmodern/bit-ui/InlineEdit/useEditing';
import { useEditingKeyboard } from '@bitmodern/bit-ui/InlineEdit/useEditingKeyboard';
import useMutation from 'src/hooks/useMutation';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import styles from './ActivityComment.module.scss';
import ConfirmDialog from '../ConfirmDialog';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';

type Props = {
  comment: Comment;
  onDeleteComment: (commentId: number) => Promise<any>;
  onEditComment: (comment: Comment) => Promise<any>;
  user?: User;
};

export default function ActivityComment({
  comment,
  onEditComment,
  onDeleteComment,
  user,
}: Props) {
  const { t } = useTranslation();
  const deleteDialog = useOverlayTriggerState({});
  const [valueInline, setValueInline] = useState(comment.body);
  const { projectId } = useParams<typeof routes.PROJECT.params>();

  const handleOnDelete = useCallback(
    () => onDeleteComment(comment.id),
    [onDeleteComment, comment.id],
  );

  const deleteMutation = useMutation(handleOnDelete);

  useEffect(() => {
    setValueInline(comment.body);
  }, [comment.body]);

  const initialValue = comment.body;

  const inlineEdit = useEditing({
    onCommit: () => {
      if (initialValue !== valueInline && onEditComment) {
        return onEditComment({
          ...comment,
          updated_at: new Date().toISOString(),
          body: valueInline,
        });
      }
      return Promise.resolve();
    },
    onCancel: () => setValueInline(initialValue),
    id: comment.id.toString(),
  });

  const { keyboardProps } = useEditingKeyboard({
    commitOnEnter: false,
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });

  const handleCancel = () => {
    inlineEdit.cancel();
    setValueInline(initialValue);
  };

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          <MenuItem
            startAdornment={<EditIcon color={vars.textPrimary} size={18} />}
            onClick={inlineEdit.open}>
            {t('comment.actions.edit')}
          </MenuItem>
          <MenuItem
            startAdornment={<DeleteIcon color={vars.textPrimary} size={18} />}
            onClick={deleteDialog.open}>
            {t('comment.actions.delete')}
          </MenuItem>
        </Menu>
      }>
      <IconButton color="main" size="small" title={t('comment.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  const edited = comment.created_at !== comment.updated_at;

  const bodyHeadCN = classnames(styles.bodyHead, {
    [styles.isEditing]: inlineEdit.isEditing,
  });

  return (
    <>
      <li className={styles.comment}>
        <Avatar
          className={styles.avatar}
          name={user?.given_name}
          size={24}
          src={processAttachmentPath(user?.picture)}
        />
        <div className={styles.body} {...keyboardProps}>
          <div className={bodyHeadCN}>
            <div className={styles.name}>
              {formatUserName(user)}
              <span className={styles.ago}>
                {t('comment.ago', { date: new Date(comment.created_at) })}{' '}
                {edited && <span>{t('comment.edited')}</span>}
              </span>
            </div>
            {more}
          </div>

          {inlineEdit.isEditing ? (
            <EasyMDEEditor
              autofocus
              className={styles.editor}
              id={comment.id.toString()}
              minHeight="32px"
              onCancel={handleCancel}
              onChange={setValueInline}
              onCommit={inlineEdit.commit}
              parentId={parseInt(projectId, 10)}
              parentType="Project"
              value={valueInline}
            />
          ) : (
            <MarkdownView
              className={styles.markdown}
              markdown={comment.body}
              read
            />
          )}
        </div>
      </li>
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('comment.deleteDialog.title')}>
        {t('comment.deleteDialog.content')}
      </ConfirmDialog>
    </>
  );
}
