import { LocationState } from 'history';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { routes } from 'src/components/Router';
import { notification } from '@bitmodern/bit-ui';
import { useTranslation } from '../i18n/hooks';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { integrationProjectByProjectIdSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { integrationUserByIntegrationAndUserSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { redirectForAuth } from '@bitmodern/services/integrationService';

export function useIntegrationCheck(
  site: string,
  projectId?: number,
  integrationId?: number,
) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ modalParentLocation?: string }>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const integrationProject = useAppSelector((state) =>
    integrationProjectByProjectIdSelector(state, { projectId }),
  );
  const user = useAppSelector(currentUserSelector);
  const integrationUser = useAppSelector((state) =>
    integrationUserByIntegrationAndUserSelector(state, {
      integrationId: integrationProject?.integration_id || integrationId,
      userId: user?.id,
    }),
  );

  useEffect(() => {
    if (integrationUser?.length) {
      integrationUser.forEach((iu) => {
        if (iu && iu.access_token && iu.access_token !== '') {
          setIsAuthenticated(true);
        }
      });
    }
  }, [integrationUser]);

  const checkIntegrationProject = (): boolean => {
    if (!integrationProject) {
      notification.open({
        type: 'warning',
        message: t('notifications.integrationProjectNotification.message'),
        description: t(
          'notifications.integrationProjectNotification.description',
        ),
      });
      history.push(routes.INTEGRATIONS({ site }));
      return false;
    }
    return true;
  };

  const checkIntegrationUser = (): boolean => {
    if (!isAuthenticated) {
      notification.open({
        type: 'warning',
        message: t('notifications.integrationUserNotification.message'),
        description: t('notifications.integrationUserNotification.description'),
      });
      return false;
    }
    return true;
  };

  const checkIntegrationRedirect = async <S = LocationState>(
    pathname: string,
    search?: string,
    state?: S,
  ): Promise<boolean> => {
    if (checkIntegrationUser()) {
      history.push({
        pathname,
        search,
        state,
      });
    } else {
      const intId = integrationProject?.integration_id || integrationId;
      if (intId) {
        await redirectForAuth(intId, pathname, search);
      } else {
        throw new Error('Unknown Integration');
      }
    }

    return true;
  };

  const openCreateDefect = (
    runId: number,
    runResultIds: number[],
  ): Promise<boolean> => {
    if (checkIntegrationProject()) {
      const search = `?resultIds=${runResultIds.join(',')}`;
      const pathname = routes.DEFECT_CREATE({
        site,
        projectId: projectId !== undefined ? projectId.toFixed(0) : 'undefined',
        runId: runId.toFixed(0),
      });
      return checkIntegrationRedirect(pathname, search, {
        modalParentLocation: location,
      });
    }
    return Promise.resolve(false);
  };

  const openCreateRequirement = (testId: number): Promise<boolean> => {
    if (checkIntegrationProject()) {
      const pathname = routes.REQUIREMENT_CREATE({
        site,
        projectId: projectId !== undefined ? projectId.toFixed(0) : 'undefined',
        testId: testId?.toString(),
      });
      return checkIntegrationRedirect(pathname, undefined, {
        modalParentLocation: location,
      });
    }
    return Promise.resolve(false);
  };

  return {
    isAuthenticated,
    checkIntegrationProject,
    checkIntegrationRedirect,
    openCreateDefect,
    openCreateRequirement,
  };
}
