import React from 'react';
import {
  BlockIcon,
  DoneIcon,
  FailIcon,
  PendingIcon,
  SkipIcon,
} from '@bitmodern/bit-ui/icons';
import { StatusEnum, StatusTypeEnum } from 'src/enums/StatusEnum';
import { Status } from '@testquality/sdk';
import { getStatusColor, getStatusTypeColor } from 'src/utils/statusHelper';
import styles from './StatusIcon.module.scss';
import { statusTypesSelector } from 'src/packages/redux/state/statusTypes/selectors';
import { useAppSelector } from 'src/packages/redux/store';

type Props = {
  className?: string;
  circleColor?: string;
  iconColor?: string;
  size?: number;
  status: Status;
};

export default function StatusIcon({
  className = '',
  circleColor,
  iconColor,
  size = 16,
  status,
}: Props) {
  const color = getStatusColor(status);

  const statusTypes = useAppSelector(statusTypesSelector);
  const statusType = statusTypes.find((s) => s.id === status.status_type_id);
  const colorType = getStatusTypeColor(statusType);

  const iconStyle = {
    backgroundColor: circleColor || color || colorType,
    height: size,
    width: size,
  };

  let icon;

  switch (status.key) {
    case StatusEnum.Pass:
      icon = <DoneIcon color={iconColor || 'white'} size={size - 4} />;
      break;
    case StatusEnum.Pending:
      icon = <PendingIcon color={iconColor || 'white'} size={size - 4} />;
      break;
    case StatusEnum.Fail:
      icon = <FailIcon color={iconColor || 'white'} size={size - 4} />;
      break;
    case StatusEnum.Block:
      icon = <BlockIcon color={iconColor || 'white'} size={size - 4} />;
      break;
    case StatusEnum.Retest:
      // Todo: Needs Retest icon
      icon = <PendingIcon color={iconColor || 'white'} size={size - 4} />;
      break;
    case StatusEnum.Skip:
      icon = <SkipIcon color={iconColor || 'black'} size={size - 4} />;
      break;
    default:
      icon = getIconByType(statusType, size, iconColor);
  }

  return (
    <span style={iconStyle} className={`${styles.icon} ${className}`}>
      {icon}
    </span>
  );
}

const getIconByType = (statusType, size, iconColor = 'white') => {
  switch (statusType?.key) {
    case StatusTypeEnum.Positive:
      return <DoneIcon color={iconColor} size={size - 4} />;
    case StatusTypeEnum.Neutral:
      return <PendingIcon color={iconColor} size={size - 4} />;
    case StatusTypeEnum.Negative:
      return <FailIcon color={iconColor} size={size - 4} />;
    default:
      return null;
  }
};
