import React, { useMemo } from 'react';
import { Plan as PlanModel, SuiteTest, Test } from '@testquality/sdk';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { rootPlanSelector } from '@bitmodern/redux/state/plans/selectors';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import SuitesTree from '../SuitesTree';
import EmptyTests from '../EmptyTests';
import styles from './Plan.module.scss';

type Props = {
  current?: number;
  onClickTest: (test: Test, suiteTest: SuiteTest) => void;
  plan: PlanModel;
};

function Plan({ current, onClickTest, plan }: Props) {
  const planTree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      planId: plan.id,
      projectId: plan.project_id,
      filterType: 'plans',
    }),
  );

  const testsTree = useAppSelector((state) => {
    if (planTree.length) return [];
    const rootPlan = rootPlanSelector(state, plan.project_id);
    if (!rootPlan) return [];
    return filteredSuitesTreeByPlanSelector(state, {
      planId: rootPlan.id,
      projectId: rootPlan.project_id,
    });
  });

  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: plan.project_id }),
  );

  const filters = useAppSelector((state) =>
    filtersByTypeSelector(state, 'plans'),
  );

  const tree = useMemo(() => {
    if (!planTree.length && Object.keys(filters).length === 0) return testsTree;
    return planTree;
  }, [planTree, testsTree, filters]);

  return (
    <div className={styles.contentBorder}>
      <div className={styles.content}>
        {tree.length === 0 ? (
          <EmptyTests filterType="plans" potentialAmount={tests.length} />
        ) : (
          <SuitesTree
            canDrag={false}
            current={current}
            id={plan.id.toString()}
            onClickTest={onClickTest}
            plan={plan}
            suitesTree={tree}
            withInclude
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(Plan);
