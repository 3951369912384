import React, { useCallback, useMemo } from 'react';
import ShowdownView from 'react-showdown';
import frontMatter from 'front-matter';
import { UploadIcon } from '@bitmodern/bit-ui/icons';
import { Loading } from '@bitmodern/bit-ui';
import { SettingsPanel, PersonalAccessToken } from 'src/components/organisms';
import useFetch from 'src/hooks/useFetch';
import useParams from 'src/hooks/useParams';
import routes from 'src/components/Router/routes';
import vars from 'src/export.scss';
import Code from './components/Code';
import styles from './ImportAutomationView.module.scss';
import CSV from 'src/components/organisms/ImportDataForm/CSV';
import Gherkin from 'src/components/organisms/ImportDataForm/Gherkin';
import JUnit from 'src/components/organisms/ImportDataForm/Junit';

function fetchUploadMD() {
  return Promise.all([import(`./automation/upload.md`)]).then((res) => ({
    upload: frontMatter(res[0].default),
  }));
}

export default function ImportAutomationView() {
  const { slug } = useParams<typeof routes.IMPORT_AUTOMATION.params>();

  const fetchMarkdown = useCallback(() => {
    return Promise.all([import(`./automation/${slug}.md`)]).then((res) => ({
      automation: frontMatter<{ title: string }>(res[0].default),
    }));
  }, [slug]);
  const markdownFetch = useFetch(fetchMarkdown, `import-${slug}`);
  const uploadMDFetch = useFetch(fetchUploadMD, `import-updateMD`);

  const { automation } = markdownFetch.data || {};
  const { upload } = uploadMDFetch.data || {};

  const markdown = useMemo(() => {
    if (!upload || !automation) return '';
    return `${automation.body} ${upload.body}`;
  }, [automation, upload]);

  const title = automation?.attributes?.title || slug;
  return (
    <SettingsPanel
      icon={<UploadIcon color={vars.textPrimary} size={24} />}
      title={title}>
      {markdownFetch.isLoading && <Loading />}
      {markdown && (
        <div className={styles.instructions}>
          <ShowdownView
            components={{
              CSV,
              Gherkin,
              JUnit,
              PersonalAccessToken,
              code: Code,
            }}
            markdown={markdown}
            options={{ tables: true }}
            flavor="github"
          />
        </div>
      )}
    </SettingsPanel>
  );
}
