import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import {
  Alert,
  Button,
  Content,
  Loading,
  notification,
  Spacer,
  Surface,
} from '@bitmodern/bit-ui';
import useFetch from 'src/hooks/useFetch';
import { useQuery } from 'src/hooks/useQuery';
import { currentClientSelector } from '@bitmodern/redux/state/clients/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  getGithubPurchases,
  githubAttachTokenService,
} from '@bitmodern/services/githubService';
import { integrationUsersByCurrentUserSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { useTranslation } from 'src/i18n/hooks';
import useMutation from 'src/hooks/useMutation';
import { attachGithubPurchaseThunk } from '@bitmodern/redux/state/subscriptions/thunks';
import { showNotificationError } from '@testquality/sdk';
import styles from './GithubPurchasesView.module.scss';

export default function GithubPurchasesView() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const history = useHistory();
  const { token } = useQuery(['marketplace_listing_plan_id', 'token']);
  const dispatch = useAppDispatch();
  const client = useAppSelector(currentClientSelector);
  const integrationUser = useAppSelector((state) =>
    integrationUsersByCurrentUserSelector(state, 2),
  );

  const githubToken = token || integrationUser?.access_token;

  const fetchPurchases = useCallback(async () => {
    if (!githubToken) return [];
    if (!integrationUser) {
      await githubAttachTokenService(token).catch(showNotificationError);
    }

    const purchases = await getGithubPurchases(githubToken);
    if (purchases.length) {
      setSelected(purchases[0].account.id);
    }
    return purchases;
  }, [githubToken, integrationUser, token]);

  const purchasesFetch = useFetch(fetchPurchases, 'github-purchases', {
    initialData: [],
  });

  const onClickPurchase = (purchase) => {
    setSelected(purchase.account.id);
  };

  const onAdd = useCallback(() => {
    if (!selected) return Promise.resolve();
    return dispatch(attachGithubPurchaseThunk(selected))
      .then(() => {
        notification.open({
          type: 'success',
          message: t('githubPurchases.success.message'),
        });
        history.push('/');
      })
      .catch(showNotificationError);
  }, [dispatch, history, t, selected]);

  const addMutation = useMutation(onAdd);

  return (
    <Content fullHeight>
      <Surface className={styles.purchases}>
        {purchasesFetch.isLoading ? (
          <Loading className={styles.loading} size={40} />
        ) : (
          <>
            <div className={styles.info}>
              <h1>{t('githubPurchases.title')}</h1>
              <p>{t('githubPurchases.description')}</p>
              <p>{t('githubPurchases.select')}</p>

              {purchasesFetch.data.length === 0 && (
                <Alert icon="" title={t('githubPurchases.noPurchases.title')}>
                  {t('githubPurchases.noPurchases.content')}
                </Alert>
              )}
            </div>

            {purchasesFetch.data.map((purchase) => (
              <div
                className={classnames(styles.purchase, {
                  [styles.selected]: purchase.account.id === selected,
                })}
                key={purchase.account.id}
                onClick={() => onClickPurchase(purchase)}>
                <div>
                  <div className={styles.name}>{purchase.plan.name}</div>
                  <div>{purchase.plan.description}</div>
                </div>
                <div className={styles.type}>
                  <div>
                    {purchase.account.type}:{' '}
                    <span className={styles.account}>
                      {purchase.account.login}
                    </span>
                  </div>
                  {purchase.allocated &&
                    purchase.allocated.client_id !== client?.id && (
                      <div>
                        {t('githubPurchases.inUseBy', {
                          name: purchase.allocated.name,
                          by: purchase.allocated.fullname,
                        })}
                      </div>
                    )}
                  {purchase.allocated &&
                    purchase.allocated.client_id === client?.id && (
                      <div>{t('githubPurchases.currentLicense')}</div>
                    )}
                </div>
              </div>
            ))}

            {purchasesFetch.data.length > 0 && (
              <Spacer className={styles.buttons}>
                <Button
                  loading={addMutation.isLoading}
                  onClick={addMutation.mutate}>
                  {t('githubPurchases.add')}
                </Button>
              </Spacer>
            )}
          </>
        )}
      </Surface>
    </Content>
  );
}
