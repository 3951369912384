import React, { ComponentProps, useMemo } from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { BillingContact } from '@testquality/sdk';
import { Button, Dialog, Input, Select } from '@bitmodern/bit-ui';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { commercialSubscriptionsSelector } from '@bitmodern/redux/state/subscriptions/selectors';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import {
  billingContactCreateOneThunk,
  billingContactUpdateOneThunk,
} from 'src/gen/domain/billing_contact/billingContactThunk';
import styles from './BillingContactFormDialog.module.scss';

type InitialValues = {
  given_name: string;
  family_name: string;
  email: string;
  subscription: number;
};

const validationSchema = Yup.object().shape({
  given_name: Yup.string().required().label('billingContactForm.given_name'),
  family_name: Yup.string().required().label('billingContactForm.family_name'),
  email: Yup.string().email().required().label('billingContactForm.email'),
  subscription: Yup.string()
    .required()
    .label('billingContactForm.subscription'),
});

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  billingContact?: BillingContact;
};

export default function BillingContactFormDialog({
  billingContact,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isUpdate = Boolean(billingContact);

  const initialValues = useMemo<InitialValues>(() => {
    return billingContact
      ? {
          given_name: billingContact.given_name || '',
          family_name: billingContact.family_name || '',
          email: billingContact.email || '',
          subscription: billingContact.subscriptions_id,
        }
      : {
          given_name: '',
          family_name: '',
          email: '',
          subscription: 0,
        };
  }, [billingContact]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const subscriptions = useAppSelector(commercialSubscriptionsSelector);

  async function onSubmit({
    email,
    given_name,
    family_name,
    subscription,
  }: InitialValues) {
    const data = {
      given_name,
      family_name,
      email,
      subscriptions_id: subscription,
    };

    if (billingContact) {
      await dispatch(
        billingContactUpdateOneThunk({
          id: billingContact.id,
          data,
        }),
      );
    } else {
      await dispatch(billingContactCreateOneThunk({ data }));
    }

    if (onClose) {
      onClose();
    }
  }

  const onChangeSubscription = (value) => {
    formik.setFieldValue('subscription', value);
  };

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      title={t('billingContactForm.createTitle')}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        <Input
          error={formikError(formik, 'given_name')}
          fullWidth
          label={t('billingContactForm.given_name')}
          name="given_name"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.given_name}
        />
        <Input
          error={formikError(formik, 'family_name')}
          fullWidth
          label={t('billingContactForm.family_name')}
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          name="family_name"
          required
          value={formik.values.family_name}
        />
        <Input
          error={formikError(formik, 'email')}
          fullWidth
          label={t('billingContactForm.email')}
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          name="email"
          required
          value={formik.values.email}
        />
        <Select
          empty={t('billingContactForm.subscriptionEmpty')}
          error={formikError(formik, 'subscription')}
          label={t('billingContactForm.subscription')}
          name="subscription"
          options={subscriptions.map((subscription) => ({
            label: subscription.name,
            value: subscription.id,
          }))}
          onChange={onChangeSubscription}
          onFocus={formik.handleBlur}
          placeholder={t('billingContactForm.subscriptionPlaceholder')}
          required
          value={formik.values.subscription}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit">
          {isUpdate
            ? t('billingContactForm.update')
            : t('billingContactForm.create')}
        </Button>
      </form>
    </Dialog>
  );
}
