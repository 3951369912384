/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { UserHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { userHistoryAdapter } from './userHistoryAdapter';
import { userHistoryFetchThunk } from './userHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const userHistorySlice = createSlice({
  name: 'userHistory',
  initialState: userHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    userHistoryCleared(state) {
      state.loading = 'idle';
      userHistoryAdapter.removeAll(state);
    },
    userHistoryUpsertMany(
      state,
      action: PayloadAction<UserHistory[] | Record<number, UserHistory>>,
    ) {
      userHistoryAdapter.upsertMany(state, action.payload);
    },
    userHistoryUpsertOne(state, action: PayloadAction<UserHistory>) {
      userHistoryAdapter.upsertOne(state, action.payload);
    },
    userHistoryUpdateOne(state, action: PayloadAction<Update<UserHistory>>) {
      userHistoryAdapter.updateOne(state, action.payload);
    },
    userHistoryRemoveOne(state, action: PayloadAction<number>) {
      userHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userHistoryFetchThunk.pending, handlePending);
    builder.addCase(userHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      userHistoryAdapter.upsertMany(state, action.payload.userHistory);
    });
    builder.addCase(userHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  userHistoryCleared,
  userHistoryUpsertMany,
  userHistoryUpsertOne,
  userHistoryUpdateOne,
  userHistoryRemoveOne,
} = userHistorySlice.actions;

export const userHistoryReducer = userHistorySlice.reducer;
