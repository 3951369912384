import React, { useEffect, useState } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { Tab, Tabs } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { routes } from 'src/components/Router';
import {
  AnalyzeIcon,
  BookmarkIcon,
  OverviewIcon,
  PlanIcon,
  PlayIcon,
  TestIcon,
} from '@bitmodern/bit-ui/icons';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import usePathname from 'src/hooks/usePathname';
import vars from 'src/export.scss';
import styles from './MainMenu.module.scss';

type Mapping = {
  [k: string]: string | string[];
};

export default function MainMenu() {
  const { t } = useTranslation();
  const pathname = usePathname();
  const [tab, setTab] = useState('');
  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  useEffect(() => {
    const mapping: Mapping = {
      overview: routes.OVERVIEW.path,
      tests: routes.PROJECT_TESTS.path,
      run: [routes.RUNS.path, routes.RUN.path],
      plans: [routes.PLANS.path, routes.PLAN.path],
      requirements: [routes.REQUIREMENTS.path, routes.REQUIREMENT.path],
      analyze: routes.ANALYZE.path,
    };

    // TODO: extract to function
    const nextTab = Object.entries(mapping).find((entry) => {
      const values = Array.isArray(entry[1]) ? entry[1] : [entry[1]];
      return values
        .map(
          (path) =>
            matchPath(pathname, {
              path,
              exact: true,
              strict: true,
            }) !== null,
        )
        .includes(true);
    });
    setTab(nextTab?.[0] || '');
  }, [pathname]);

  if (!projectId) return null;

  return (
    <div className={styles.mainMenu}>
      <Tabs tab={tab} onChange={setTab} size="large">
        <Tab className={styles.tab} id="overview">
          <Link to={routes.OVERVIEW({ site, projectId })}>
            <OverviewIcon color={vars.textSecondary} size={20} />
            {t('mainMenu.overview')}
          </Link>
        </Tab>
        <Tab className={styles.tab} id="tests">
          <Link to={routes.PROJECT_TESTS({ site, projectId })}>
            <TestIcon color={vars.textSecondary} size={20} />
            {t('mainMenu.tests')}
          </Link>
        </Tab>
        <Tab className={styles.tab} id="plans">
          <Link to={routes.PLANS({ site, projectId })}>
            <PlanIcon color={vars.textSecondary} size={20} />
            {t('mainMenu.plans')}
          </Link>
        </Tab>
        <Tab className={styles.tab} id="requirements">
          <Link to={routes.REQUIREMENTS({ site, projectId })}>
            <BookmarkIcon color={vars.textSecondary} size={20} />
            {t('mainMenu.requirements')}
          </Link>
        </Tab>
        <Tab className={styles.tab} id="run">
          <Link to={routes.RUNS({ site, projectId })}>
            <PlayIcon color={vars.textSecondary} />
            {t('mainMenu.run')}
          </Link>
        </Tab>
        <Tab className={styles.tab} id="analyze">
          <Link to={routes.ANALYZE({ site, projectId })}>
            <AnalyzeIcon color={vars.textSecondary} size={20} />
            {t('mainMenu.analyze')}
          </Link>
        </Tab>
      </Tabs>
    </div>
  );
}
