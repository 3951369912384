import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import { DefectRunResult } from '@testquality/sdk';
import { filter } from 'lodash';

export const defectRunResultsByDefects = createSelector(
  [
    (state: State) => state.gen.defectRunResult.entities,
    (state: State, defectsIds: number[]) => defectsIds,
  ],
  (entities, ids) =>
    (filter(
      entities,
      (defectRunResult) =>
        defectRunResult !== undefined &&
        ids.includes(defectRunResult.defect_id!),
    ) || []) as DefectRunResult[],
);

export const defectRunResultsByRunResults = createSelector(
  [
    (state: State) => state.gen.defectRunResult.entities,
    (state: State, runResultsIds: number[]) => runResultsIds,
  ],
  (entities, ids) =>
    (filter(
      entities,
      (defectRunResult) =>
        defectRunResult !== undefined &&
        ids.includes(defectRunResult.run_result_id!),
    ) || []) as DefectRunResult[],
);
