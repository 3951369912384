import React, { useCallback, useMemo } from 'react';
import { Avatar, Grid, Select, SelectMultiple } from '@bitmodern/bit-ui';
import { processAttachmentPath } from 'src/utils/fileHelper';
import styles from './DefectOverview.module.scss';
import EasyMEDInlineEdit from '@bitmodern/bit-ui/InlineEdit/EasyMEDInlineEdit';
import { BaseIntegrationIcon, UserSelect } from 'src/components/organisms';
import {
  BatchService,
  Defect,
  IntegrationProject,
  RunResult,
  User,
} from '@testquality/sdk';
import { toM } from 'src/utils/J2M';
import { useTranslation } from 'react-i18next';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import { useAppDispatch, useAppSelector } from 'src/packages/redux/store';
import { defectUpdateOneThunk } from 'src/gen/domain/defect/defectThunk';
import useDefectOverview from '../../hooks/useDefectOverview';
import useDefectFormOptions from '../../hooks/useDefectFormOptions';
import useDefectFormValues from '../../hooks/useDefectFormValues';
import { userSelectorById } from 'src/packages/redux/state/users/selectors';
import { useLocation } from 'react-router';
import { runResultFetchOneThunk } from 'src/gen/domain/run_result/runResultThunk';
import { defectComponentFetchManyThunk } from 'src/gen/domain/defect_component/defectComponentThunk';
import { defectLabelFetchManyThunk } from 'src/gen/domain/defect_label/defectLabelThunk';
import { defectPriorityFetchManyThunk } from 'src/gen/domain/defect_priority/defectPriorityThunk';
import { defectUserFetchManyThunk } from 'src/gen/domain/defect_user/defectUserThunk';
import { defectTypeFetchManyThunk } from 'src/gen/domain/defect_type/defectTypeThunk';
import { nativeDefectResFetchManyThunk } from 'src/gen/domain/native_defect_res/nativeDefectResThunk';
import useFetch from 'src/hooks/useFetch';
import vars from 'src/export.scss';
import OpenInNew from 'src/packages/bit-ui/icons/OpenInNew';

type Props = {
  defect: Defect;
  projectId: number;
  isJira?: boolean;
  site: string;
  currentUser: User;
  integrationProject: IntegrationProject;
  associatedRunResults: RunResult[];
  handleOpenDefectIntegration: () => void;
};

type Payload = {
  assignee?: { name: string | undefined; id?: number };
  comments?: any[];
  description?: string;
  labels?: Array<{ id: string; name: string }>;
  priority?: { name: string; id: string };
  resolution?: { name: string };
  status?: { name: string };
  type?: { name: string };
  summary?: string;
  components?: Array<{ id: string; name: string }>;
};

export default function DefectsOverview({
  defect,
  projectId,
  isJira,
  site,
  integrationProject,
  associatedRunResults,
  handleOpenDefectIntegration,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const reporter = useAppSelector((state) =>
    userSelectorById(state, defect.created_by),
  );
  const reporterName = reporter?.given_name
    ? `${reporter.given_name} ${reporter.family_name}`
    : reporter?.email;

  const request = useCallback(() => {
    const batch = new BatchService();

    associatedRunResults.forEach((runResult) => {
      dispatch(
        runResultFetchOneThunk({
          batch,
          id: runResult.id,
        }),
      );
      [
        defectComponentFetchManyThunk,
        defectLabelFetchManyThunk,
        defectPriorityFetchManyThunk,
        defectUserFetchManyThunk,
        defectTypeFetchManyThunk,
        nativeDefectResFetchManyThunk,
      ].forEach((fetchManyThunk) => {
        dispatch(
          fetchManyThunk({
            batch,
            params: {
              integration_project_id: integrationProject?.id,
              per_page: -1,
            },
          }),
        );
      });
    });
    return batch.executeBatch();
  }, [dispatch, associatedRunResults, integrationProject?.id]);

  const { isLoading } = useFetch(request, `defect-${defect.id}`, {
    fetchOnce: true,
  });

  const {
    defectPriorities,
    defectStatuses,
    defectResolutions,
    defectUsers,
    defectTypes,
    defectLabels,
    defectComponents,
    baseIntegration,
  } = useDefectOverview({
    integrationProjectId: integrationProject?.id,
    integrationId: integrationProject?.integration_id,
  });

  const {
    priorityOptions,
    statusOptions,
    resolutionOptions,
    userOptions,
    typeOptions,
    labelsOptions,
    componentOptions,
  } = useDefectFormOptions({ integrationProjectId: integrationProject?.id });

  const {
    priorityValue,
    statusValue,
    resolutionValue,
    assignedToId,
    typeValue,
    labelsValues,
    componentsValues,
  } = useDefectFormValues({
    integrationProjectId: integrationProject?.id,
    defect,
  });

  const preview = useMemo(() => {
    const renderedFieldsDescription =
      defect.payload?.renderedFields?.description;
    const defectDescription = defect.payload?.description;
    if (isJira) {
      return renderedFieldsDescription || toM(defectDescription || '');
    }
    return defectDescription;
  }, [defect, isJira]);

  const getDefectPayload = useCallback(() => {
    return JSON.parse(JSON.stringify(defect.payload)) as Payload;
  }, [defect]);

  const { checkIntegrationRedirect } = useIntegrationCheck(site, projectId);

  const updateDefect = useCallback(
    (value: Partial<Defect>, optimistic = false): Promise<void> => {
      return new Promise((resolve, reject) => {
        return checkIntegrationRedirect(
          location.pathname,
          location.search,
          location.state,
        ).then(() => {
          const thunk = dispatch(
            defectUpdateOneThunk({
              data: value,
              id: defect.id,
              optimistic,
              refreshData: true,
            }),
          );
          if (optimistic) {
            return resolve();
          }
          return thunk.then(() => resolve(), reject);
        }, reject);
      });
    },
    [checkIntegrationRedirect, dispatch, defect, location],
  );

  const onCommitDescription = (value) => {
    const payload = getDefectPayload();
    payload.description = value;
    return updateDefect({ payload }, !isJira);
  };

  const onChangePriority = (value) => {
    const payload = getDefectPayload();

    updateDefect(
      {
        payload: {
          ...payload,
          priority: {
            id: value,
            name: defectPriorities.find(({ id }) => id === value)?.name,
          },
        },
      },
      true,
    );
  };

  const onChangeDefectStatus = (value) => {
    const payload = getDefectPayload();
    const status = defectStatuses.find(({ id }) => id === value);

    updateDefect(
      {
        payload: {
          ...payload,
          status,
        },
      },
      true,
    );
  };

  const onChangeDefectResolution = (value) => {
    const payload = getDefectPayload();
    const resolution = defectResolutions.find(({ id }) => id === value);

    updateDefect(
      {
        payload: {
          ...payload,
          resolution,
        },
      },
      true,
    );
  };

  const onChangeAssignedTo = (value) => {
    const payload = getDefectPayload();

    updateDefect(
      {
        payload: {
          ...payload,
          assignee: defectUsers.find(({ id }) => id === value),
        },
      },
      true,
    );
  };

  const onChangeType = (value) => {
    const payload = getDefectPayload();

    updateDefect(
      {
        payload: {
          ...payload,
          type: defectTypes.find(({ id }) => id === value),
        },
      },
      true,
    );
  };

  const onChangeLabels = (values: string[]) => {
    const payload = getDefectPayload();

    updateDefect(
      {
        payload: {
          ...payload,
          labels: values.map((labelName) => ({
            name: labelName,
            id: defectLabels.find(({ name }) => name === labelName)?.id,
          })),
        },
      },
      true,
    );
  };

  const onChangeComponent = (values) => {
    const payload = getDefectPayload();
    updateDefect(
      {
        payload: {
          ...payload,
          components: values.map((id) => ({
            id,
            name: defectComponents.find(({ id: dId }) => dId === id)?.name,
          })),
        },
      },
      true,
    );
  };

  return (
    <>
      {integrationProject && (
        <Grid.Row>
          <Grid.Col span={12}>
            <div
              className={styles.integrationDisplay}
              onClick={handleOpenDefectIntegration}>
              <div className={styles.integration}>
                <BaseIntegrationIcon
                  baseIntegration={baseIntegration}
                  color={vars.textPrimary}
                  size={18}
                />
                <div className={styles.integrationText}>
                  {t('requirement.integrationText', {
                    org: integrationProject?.org,
                    name: integrationProject?.project_reference_id,
                  })}
                </div>
              </div>
              <OpenInNew size={18} className={styles.integrationLinkIcon} />
            </div>
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row gutter={[0, 0]} className={styles.statusRow}>
        <Grid.Col span={4}>
          <Select
            onChange={onChangeDefectStatus}
            options={statusOptions}
            placeholder={t('defect.statusPlaceholder')}
            value={statusValue}
            loading={isLoading}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <div className={styles.reporter}>
            <Avatar
              className={styles.reporterAvatar}
              name={reporter?.given_name}
              size={30}
              src={processAttachmentPath(reporter?.picture)}
            />
            <div>
              <div className={styles.reporterName}>{reporterName}</div>
              <div>
                {t('defectEvent.date', { date: new Date(defect.updated_at) })}
              </div>
            </div>
          </div>
        </Grid.Col>
      </Grid.Row>

      <div className={styles.label}>{t('defect.description')}</div>
      <EasyMEDInlineEdit
        id="defect-description"
        onCommit={onCommitDescription}
        parentId={defect.related_id!}
        parentType="RunResult"
        preview={preview}
        value={defect.payload?.description}
        withFormatting={!isJira}
      />
      <Grid.Row>
        <Grid.Col span={7}>
          {isJira && (
            <>
              <Select
                label={t('defect.type')}
                onChange={onChangeType}
                options={typeOptions}
                placeholder={t('defect.typePlaceholder')}
                value={typeValue}
                loading={isLoading}
              />
              <SelectMultiple
                label={t('defect.component')}
                placeholder={t('defect.componentPlaceholder')}
                onChange={onChangeComponent}
                options={componentOptions}
                values={componentsValues}
                loading={isLoading}
              />
            </>
          )}
          <Select
            label={t('defect.priority')}
            placeholder={t('defect.priorityPlaceholder')}
            onChange={onChangePriority}
            options={priorityOptions}
            value={priorityValue}
            loading={isLoading}
          />
          <SelectMultiple
            label={t('defect.label')}
            placeholder={t('defect.labelsPlaceholder')}
            tags
            onChange={onChangeLabels}
            options={labelsOptions}
            values={labelsValues}
            loading={isLoading}
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <Select
            label={t('defect.resolution')}
            placeholder="Select Resolution..."
            onChange={onChangeDefectResolution}
            options={resolutionOptions}
            value={resolutionValue}
            loading={isLoading}
          />
          <UserSelect
            label={t('defect.assignee')}
            onChange={onChangeAssignedTo}
            options={userOptions}
            placeholder={t('defect.assigneePlaceholder')}
            value={assignedToId}
            loading={isLoading}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
}
