/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { baseCapabilityAdapter } from './baseCapabilityAdapter';

export const baseCapabilitySelectors = baseCapabilityAdapter.getSelectors(
  (state: State) => state.gen.baseCapability,
);
