/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { CheckSuite } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { checkSuiteAdapter } from './checkSuiteAdapter';
import {
  checkSuiteFetchManyThunk,
  checkSuiteFetchOneThunk,
  checkSuiteDeleteOneThunk,
  checkSuiteUpdateOneThunk,
  checkSuiteCreateOneThunk,
} from './checkSuiteThunk';
import {
  pullRequestFetchManyThunk,
  pullRequestFetchOneThunk,
} from '../pull_request/pullRequestThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const checkSuiteSlice = createSlice({
  name: 'checkSuite',
  initialState: checkSuiteAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    checkSuiteCleared(state) {
      state.loading = 'idle';
      checkSuiteAdapter.removeAll(state);
    },
    checkSuiteUpsertMany(
      state,
      action: PayloadAction<CheckSuite[] | Record<number, CheckSuite>>,
    ) {
      checkSuiteAdapter.upsertMany(state, action.payload);
    },
    checkSuiteUpsertOne(state, action: PayloadAction<CheckSuite>) {
      checkSuiteAdapter.upsertOne(state, action.payload);
    },
    checkSuiteUpdateOne(state, action: PayloadAction<Update<CheckSuite>>) {
      checkSuiteAdapter.updateOne(state, action.payload);
    },
    checkSuiteRemoveOne(state, action: PayloadAction<number>) {
      checkSuiteAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkSuiteFetchManyThunk.pending, handlePending);
    builder.addCase(checkSuiteFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
    });
    builder.addCase(checkSuiteFetchManyThunk.rejected, handleRejected);
    builder.addCase(checkSuiteFetchOneThunk.pending, handlePending);
    builder.addCase(checkSuiteFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkSuiteAdapter.upsertOne(state, action.payload.checkSuite[0]);
    });
    builder.addCase(checkSuiteFetchOneThunk.rejected, handleRejected);
    builder.addCase(checkSuiteDeleteOneThunk.pending, handlePending);
    builder.addCase(checkSuiteDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        checkSuiteAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(checkSuiteDeleteOneThunk.rejected, handleRejected);
    builder.addCase(checkSuiteUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, checkSuiteAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(checkSuiteUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkSuiteAdapter.updateOne(state, action.payload);
    });
    builder.addCase(checkSuiteUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          checkSuiteAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(checkSuiteCreateOneThunk.pending, handlePending);
    builder.addCase(checkSuiteCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkSuiteAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(checkSuiteCreateOneThunk.rejected, handleRejected);
    builder.addCase(pullRequestFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(pullRequestFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkSuite) {
          checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkSuite) {
          checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkSuite) {
          checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkSuite) {
        checkSuiteAdapter.upsertMany(state, action.payload.checkSuite);
      }
    });
  },
});

export const {
  checkSuiteCleared,
  checkSuiteUpsertMany,
  checkSuiteUpsertOne,
  checkSuiteUpdateOne,
  checkSuiteRemoveOne,
} = checkSuiteSlice.actions;

export const checkSuiteReducer = checkSuiteSlice.reducer;
