/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  AppInstallProject,
  appInstallProjectGetMany,
  appInstallProjectGetOne,
  appInstallProjectDeleteOne,
  appInstallProjectUpdateOne,
  appInstallProjectCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { appInstallProjectNormalizer } from './appInstallProjectNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const appInstallProjectFetchManyThunk = createAsyncThunk(
  'appInstallProject/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<AppInstallProject>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await appInstallProjectGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(
          resourceList.data,
          appInstallProjectNormalizer,
        );
      }
      return {
        appInstallProject: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appInstallProjectFetchOneThunk = createAsyncThunk(
  'appInstallProject/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<AppInstallProject>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'appInstallProject/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await appInstallProjectGetOne(arg.id as number, arg);
      return executeNormalizer([data], appInstallProjectNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appInstallProjectDeleteOneThunk = createAsyncThunk(
  'appInstallProject/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppInstallProject>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'appInstallProject/DeleteOneThunk',
        );
      }
      return await appInstallProjectDeleteOne(arg.id as number, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appInstallProjectUpdateOneThunk = createAsyncThunk(
  'appInstallProject/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<AppInstallProject>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'appInstallProject/UpdateOneThunk',
        );
      }
      const data = await appInstallProjectUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<AppInstallProject>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<AppInstallProject>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appInstallProjectCreateOneThunk = createAsyncThunk(
  'appInstallProject/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppInstallProject>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'appInstallProject/CreateOneThunk',
        );
      }
      return await appInstallProjectCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
