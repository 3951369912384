import React, { ComponentProps, ReactNode, useContext } from 'react';
import { TableHeadContext } from '../TableHead';
import classNames from 'classnames';
import styles from './TableCell.module.scss';

type Variants = 'edge' | 'dark';

type BaseProps = {
  children: ReactNode;
  className?: string;
  variant?: Variants;
  variants?: readonly Variants[];
};

type CellProps = Omit<ComponentProps<'td'>, 'scope'> &
  BaseProps & {
    role?: 'cell';
  };

type HeadProps = ComponentProps<'th'> &
  BaseProps & {
    role: 'head';
    scope: 'col' | 'row';
  };

type Props = CellProps | HeadProps;

export default function TableCell({
  children,
  className = '',
  role = 'cell',
  variants = [],
  variant,
  ...rest
}: Props) {
  const Tag = useContext(TableHeadContext) || role === 'head' ? 'th' : 'td';

  const CN = classNames(styles.tableCell, className, {
    [styles.tableCellEdge]: variants.includes('edge'),
    [styles.tableCellDark]: variants.includes('dark'),
  });

  return (
    <Tag className={CN} {...rest}>
      {children}
    </Tag>
  );
}
