import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import { Trans } from 'react-i18next';
import { useFormik } from 'formik';
import { useReCaptcha } from '@bitmodern/bit-ui';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useAppDispatch } from '@bitmodern/redux/store';
import { showNotificationError, HttpError } from '@testquality/sdk';
import { getClient } from 'src/Client';
import { TermsOfServiceDialog } from 'src/components/organisms';
import { trackEvent } from 'src/components/organisms/Tracker/Tracker';
import routes from 'src/components/Router/routes';
import useLocation from 'src/hooks/useLocation';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Input, InputPassword } from 'src/packages/bit-ui';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './SignUpStep2.module.scss';

import Debug from 'debug';
const debug = Debug('SignUpStep2');

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('signUp.form.email'),
  password: Yup.string().required().min(6).label('signUp.form.password'),
});

type InitialValues = {
  email: string;
  password: string;
};

export type SignUpStep2Props = {
  email: string;
};

export function SignUpStep2({ email }: SignUpStep2Props) {
  const dispatch = useAppDispatch();
  const { executeRecaptcha } = useReCaptcha();
  const location = useLocation();
  const terms = useOverlayTriggerState({});
  const { t } = useTranslation();

  const initialValues = useMemo<InitialValues>(
    () => ({
      email,
      password: '',
    }),
    [email],
  );

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  async function onSubmit({ email, password }: InitialValues) {
    debug('onSubmit', { email, password });

    if (!executeRecaptcha) {
      showNotificationError(new HttpError(t('signUp.step2.recaptchaError')));
      return;
    }

    const recaptcha = await executeRecaptcha('signUpStep2');
    debug('recaptcha', recaptcha);

    return getClient()
      .getAuth()
      .signUpWithEmail(email, password, recaptcha)
      .then((token) => {
        trackEvent('userRegistered');
        debug('onSubmit.signUpWithEmail', token);
        dispatch(authenticateAction(token));
      })
      .catch((error) => showNotificationError(error));
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>{t('signUp.step2.title')}</div>
      <div className={styles.login}>
        <Link
          to={{
            pathname: routes.LOGIN({}),
            state: location?.state?.from
              ? { from: location.state.from }
              : undefined,
          }}>
          {t('signUp.step2.login')}{' '}
          <span className={styles.loginLink}>
            {t('signUp.step2.loginLink')}
          </span>
        </Link>
      </div>
      <form
        className={styles.form}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          aria-label={t('signUp.form.email')}
          error={formikError(formik, 'email')}
          fullWidth
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('signUp.form.email')}
          value={formik.values.email}
        />
        <InputPassword
          aria-label={t('signUp.form.password')}
          autoFocus={Boolean(formik.values.email)}
          error={formikError(formik, 'password')}
          fullWidth
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('signUp.form.password')}
          value={formik.values.password}
        />
        <Button
          className={styles.formSubmit}
          color="primaryLight"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('signUp.form.submit')}
        </Button>
      </form>

      <div className={styles.terms}>
        <Trans i18nKey="signUp.step2.terms">
          I agree to TestQuality&apos;s{' '}
          <span className={styles.termsLink} onClick={terms.open}>
            Privacy Policy
          </span>{' '}
          and{' '}
          <span className={styles.termsLink} onClick={terms.open}>
            Terms of Service
          </span>
          .
        </Trans>
      </div>

      <div className={styles.termsGoogle}>
        <Trans i18nKey="signUp.step2.termsGoogle">
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            className={styles.termsLink}
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className={styles.termsLink}
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer">
            Terms of Service
          </a>{' '}
          apply.
        </Trans>
      </div>

      <TermsOfServiceDialog isOpen={terms.isOpen} onClose={terms.close} />
    </div>
  );
}
