/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { integrationProjectAdapter } from './integrationProjectAdapter';

export const integrationProjectSelectors =
  integrationProjectAdapter.getSelectors(
    (state: State) => state.gen.integrationProject,
  );
