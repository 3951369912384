import { State } from '@bitmodern/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { Defect, DefectHistory } from '@testquality/sdk';
import { defectHistorySelectors } from 'src/gen/domain/defect/defectHistorySelector';

export const defectHistoryByDefectSelector = createSelector(
  [
    defectHistorySelectors.selectEntities,
    (state: State, params: { defect?: Defect }) => params.defect,
  ],
  (defectHistory, defect) => {
    const toReturn: DefectHistory[] = [];
    if (!defect) return toReturn;

    for (const id in defectHistory) {
      const history = defectHistory[id];
      if (history?.id === defect.id) toReturn.push(history);
    }

    return toReturn;
  },
);
