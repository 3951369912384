import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { configure } from 'react-hotkeys';

import * as Sentry from '@sentry/react';
import { AuthCallback, AuthCallbackActions, Trace } from '@testquality/sdk';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from '@bitmodern/redux/store';
import {
  Provider as BitUIProvider,
  Loading,
  ReCaptchaProvider,
  notification,
} from '@bitmodern/bit-ui';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import Router, { RouterRoutes } from 'src/components/Router';
import { useTranslation } from 'src/i18n/hooks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DrawerProvider } from 'src/hooks/useDrawerManager';
import { setClient } from 'src/Client';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { logoutThunk } from '@bitmodern/redux/state/authentication/thunks';
import { ActivityCollector } from 'src/components/ActivityCollector';
import { getEnv } from 'src/env';
import Tracker from '../Tracker';
import Drift from '../Drift';
import { GlobalLoadingProvider } from '../GlobalLoading';
import './App.scss';
import ErrorBoundary from '../AppErrorBoundary';
import { notificationErrorTimeout } from '../../../constants';
import { CommandBarProvider } from 'src/hooks/useCommandBar';
import Debug from 'debug';

const debug = Debug('App');
const log = new ConsoleLogger('App');

configure({
  stopEventPropagationAfterHandling: true,
  ignoreEventsCondition: (event: KeyboardEvent) => {
    const target = event.target as any;
    const ignoreTags = ['input', 'select', 'textarea'];

    if (target?.type?.toLowerCase() === 'checkbox') return false;

    if (target?.tagName) {
      const tagName = target.tagName.toLowerCase();
      return ignoreTags.includes(tagName) || target.isContentEditable;
    }

    return false;
  },
});

const authCallback: AuthCallback = async (action, token) => {
  debug('authCallback', { action, token });

  switch (action) {
    case AuthCallbackActions.SubscriptionExpired:
      log.warn('SubscriptionExpired');
      notification.open({
        type: 'warning',
        message: 'Subscription Expired',
        description: 'Need to create new subscription',
        duration: 10,
      });
      break;

    case AuthCallbackActions.TrialExpired:
      log.warn('TrialExpired');
      notification.open({
        type: 'warning',
        message: 'Trial Expired',
        description: 'Need to create a subscription',
        duration: notificationErrorTimeout,
      });
      break;

    case AuthCallbackActions.Unauthorized:
      // Unauthorized should mean there is an issue with token
      log.warn('Unauthorized');
      store.dispatch(logoutThunk());
      break;

    case AuthCallbackActions.TokenUpdated:
      if (token) {
        debug('dispatch authenticate');
        store.dispatch(authenticateAction(token));
      }
      break;
  }
};
const client = setClient(authCallback);

// defer errorHandler initiation as it can cause loading conflicts
const getTraceString = (trace?: Trace[]): string => {
  let traceString =
    trace && Array.isArray(trace)
      ? trace
          .map((item) => {
            // can take a lot of space const args = item.args.map((arg) => JSON.stringify(arg)).join(', ');
            // ${item.class}${item.type}${item.function}(${args})
            const file =
              item.file && item.file.startsWith('/var/www/testQuality')
                ? item.file.replace('/var/www/testQuality', '')
                : item.file;
            return `${file}(${item.line}):${item.function}`;
          })
          .join(' > ')
      : '';
  if (traceString.length > 1500) {
    traceString = traceString.substring(0, 1500);
  }
  return traceString;
};
let lastError: any;
let lastTime: DOMHighResTimeStamp;
client.setErrorHandler((newError) => {
  // back pressure for repeat errors
  const endTime = performance.now();

  if (
    lastError &&
    lastError.message &&
    newError.message === lastError.message &&
    endTime - lastTime < 500
  ) {
    // ignore so we don't get lots of messages
    log.warn(`Repeat error from ${endTime - lastTime} milliseconds ago`);
    lastTime = endTime;
    return;
  }
  lastTime = endTime;
  lastError = newError;

  // use console because it will include typescript information
  // eslint-disable-next-line no-console
  console.error(
    newError, // .stack ? newError.stack : newError.message,
    newError.title,
    newError.status,
    newError.code,
    newError.url,
    newError.trace,
  );
  const trace = getTraceString(newError.trace);
  if (!newError.status || newError.status === 400 || newError.status >= 500) {
    Sentry.withScope((scope) => {
      scope.setExtra('data', {
        title: newError.title,
        status: newError.status,
        code: newError.code,
        url: newError.url,
        trace,
      });
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureMessage(newError.stack ? newError.stack : newError.message);
    });
  }
  notification.open({
    type: 'error',
    message: newError.title ? newError.title : 'Error',
    description: newError.message,
    duration: notificationErrorTimeout,
  });
});

function App() {
  const { t } = useTranslation();
  const persistor = persistStore(store);

  return (
    <ReduxProvider store={store}>
      <ErrorBoundary>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <ReCaptchaProvider siteKey={getEnv().reCaptchaSiteKey}>
            <BitUIProvider overlayProviderId="overlayProvider">
              {/* @ts-expect-error */}
              <DndProvider backend={HTML5Backend}>
                <GlobalLoadingProvider>
                  <Drift />
                  <Router>
                    <DrawerProvider>
                      <CommandBarProvider>
                        {getEnv().isProduction && <Tracker />}
                        <Helmet
                          defaultTitle={t('app.head.title')}
                          titleTemplate="%s - TestQuality"
                        />
                        <ActivityCollector />
                        <RouterRoutes />
                      </CommandBarProvider>
                    </DrawerProvider>
                  </Router>
                </GlobalLoadingProvider>
              </DndProvider>
            </BitUIProvider>
          </ReCaptchaProvider>
        </PersistGate>
      </ErrorBoundary>
    </ReduxProvider>
  );
}

export default App;
