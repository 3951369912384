/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { subscriptionUserAdapter } from './subscriptionUserAdapter';

export const subscriptionUserSelectors = subscriptionUserAdapter.getSelectors(
  (state: State) => state.gen.subscriptionUser,
);
