import React, { memo, useCallback, useMemo, useState } from 'react';
import useParams from 'src/hooks/useParams';
import { routes } from 'src/components/Router';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useMaker } from '@bitmodern/redux/hooks';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import { makeRequirementsByProjectWithStatus } from '@bitmodern/redux/state/requirements/selectors';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import {
  RequirementFilterGithub,
  RequirementFilterGithubDisplay,
  RequirementFilterJira,
  RequirementFilterJiraDisplay,
} from 'src/enums/RequirementFilterEnum';
import { useTranslation } from 'react-i18next';
import { Option } from '@bitmodern/bit-ui/Select/Select';
import { integrationProjectByProjectIdSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { integrationByIdSelector } from '@bitmodern/redux/state/integration/selectors';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import Requirements from './Requirements';
// FIXME: Fix filters
function RequirementsContainer() {
  const { site, requirementId, projectId } =
    useParams<typeof routes.REQUIREMENT.params>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const integrationProject = useAppSelector((state) =>
    integrationProjectByProjectIdSelector(state, {
      projectId: parseInt(projectId, 10),
    }),
  );
  const integration = useAppSelector((state) =>
    integrationByIdSelector(state, {
      id: integrationProject?.integration_id,
    }),
  );
  const isJira =
    integration && integrationKey(integration) === IntegrationType.JIRA;
  const [filter, setFilter] = useState(
    isJira ? RequirementFilterJira.All : RequirementFilterGithub.All,
  );

  const { openCreateRequirement } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const filters = useMemo(() => {
    const toFilter = isJira
      ? Array.from(RequirementFilterJiraDisplay)
      : Array.from(RequirementFilterGithubDisplay);
    return toFilter.map(([value, label]) => ({
      label: t(label),
      value,
    }));
  }, [t, isJira]);

  const search = useAppSelector((state) =>
    filtersByTypeSelector(state, 'requirementsList'),
  );

  const requirements = useMaker(makeRequirementsByProjectWithStatus, {
    projectId: parseInt(projectId, 10),
    search: search?.summary,
    statusFilter: filter,
  });

  const onChangeFilter = useCallback(
    (option: Option) => setFilter(option.value),
    [],
  );

  const onChangeSearch = useCallback(
    (text) => {
      dispatch(
        toggleFilterAction({
          type: 'requirementsList',
          filter: 'summary',
          value: text,
        }),
      );
    },
    [dispatch],
  );

  const onAddRequirement = () => {
    return openCreateRequirement(0);
  };

  return (
    <Requirements
      filters={filters}
      filter={filter}
      currentRequirementId={parseInt(requirementId, 10)}
      initialSearch={search?.summary}
      requirements={requirements}
      onChangeSearch={onChangeSearch}
      onChangeFilter={onChangeFilter}
      site={site}
      onAddRequirement={onAddRequirement}
    />
  );
}

export default memo(RequirementsContainer);
