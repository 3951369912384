import React, { useState } from 'react';
import { usePress } from 'react-aria';
import { Milestones } from 'src/components/organisms';
import { Milestone } from '@testquality/sdk';
import {
  milestoneDeleteOneThunk,
  milestoneUpdateOneThunk,
} from 'src/gen/domain/milestone/milestoneThunk';
import { useAppDispatch } from '@bitmodern/redux/store';
import vars from 'src/export.scss';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import classNames from 'classnames';
import { CancelIcon, FlagIcon } from '@bitmodern/bit-ui/icons';
import { Button, Dropdown } from '@bitmodern/bit-ui';
import styles from './MilestoneDropdown.module.scss';

type Props = {
  milestones: Milestone[];
  selectedMilestone: number | null;
  onChangeFilters: (milestone: Milestone | null) => void;
  selected?: boolean;
};

export default function MilestoneDropdown({
  milestones,
  selectedMilestone,
  onChangeFilters,
  selected = false,
}: Props) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { showModal } = useModalManager();

  const [isVisible, setIsVisible] = useState(false);
  const milestoneName =
    milestones.find((m) => m.id === selectedMilestone)?.name || '';

  const cancel = usePress({
    onPress: () => {
      onChangeMilestone(null);
    },
  });

  const onEditMilestone = (values) => {
    setIsVisible(false);
    return dispatch(
      milestoneUpdateOneThunk({
        id: values.id,
        data: {
          name: values.name,
          description: values.description,
          release_date: values.releaseDate,
          start_date: values.startDate,
        },
      }),
    );
  };

  const onChangeMilestone = (selectedMilestoneId: number | null) => {
    setIsVisible(false);

    const milestone = milestones.find((m) => m.id === selectedMilestoneId);
    onChangeFilters(milestone || null);
  };

  const onDeleteMilestone = (milestone) => {
    setIsVisible(false);

    return dispatch(milestoneDeleteOneThunk({ id: milestone.id }));
  };

  const onComplete = (milestone: Milestone) => {
    setIsVisible(false);

    dispatch(
      milestoneUpdateOneThunk({
        id: milestone.id,
        data: { is_complete: true },
        optimistic: true,
      }),
    );
  };

  const openMilestoneCreateModal = () => {
    setIsVisible(false);

    showModal({
      modalName: 'milestoneCreate',
      type: 'modal',
    });
  };

  const onIncomplete = (milestone: Milestone) => {
    setIsVisible(false);

    dispatch(
      milestoneUpdateOneThunk({
        id: milestone.id,
        data: { is_complete: false },
        optimistic: true,
      }),
    );
  };

  const container = classNames({ [styles.selected]: selected });

  return (
    <Dropdown
      className={container}
      onChangeVisible={setIsVisible}
      visible={isVisible}
      overlay={
        <div className={styles.collapse}>
          <div className={styles.milestones}>
            <Milestones
              current={selectedMilestone}
              onChangeDropdownVisible={setIsVisible}
              milestones={milestones}
              onChangeCurrent={onChangeMilestone}
              onComplete={onComplete}
              onIncomplete={onIncomplete}
              onEditMilestone={onEditMilestone}
              onDeleteMilestone={onDeleteMilestone}
            />
          </div>
          {milestones.length !== 0 && (
            <div className={styles.createMilestone}>
              <Button
                color="accent"
                onClick={openMilestoneCreateModal}
                icon={<FlagIcon color={vars.onSuccess} size={20} />}
                fullWidth>
                {t('overview.actions.createMilestone')}
              </Button>
            </div>
          )}
        </div>
      }
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}>
      <div className={styles.container}>
        <FlagIcon color={vars.textPrimary} className={styles.icon} size={18} />
        {selectedMilestone ? (
          <>
            {milestoneName}
            <span className={styles.cancel} {...cancel.pressProps}>
              <CancelIcon color={vars.textPrimary} size={18} />
            </span>
          </>
        ) : (
          t('runs.overview.milestone.placeholder')
        )}
      </div>
    </Dropdown>
  );
}
