/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { FilterApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const filterNormalizer = (model: FilterApi, cache: NormalizerCache) => {
  if (!cache.filter) {
    cache.filter = [];
  }
  cache.filter.push(model);
};
