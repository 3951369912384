import React from 'react';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { getStatusColor } from 'src/utils/statusHelper';
import { PendingIcon } from '@bitmodern/bit-ui/icons';
import { StatusEnum } from 'src/enums/StatusEnum';
import styles from './Status.module.scss';
import StatusIcon from '../StatusIcon';

type Props = {
  className?: string;
  statusId: number;
};

export default function Status({ className = '', statusId }: Props) {
  const status = useAppSelector((state) =>
    statusSelectors.selectById(state, statusId),
  );

  if (!status) return null;

  if (status.key === StatusEnum.Pending) {
    return (
      <div
        className={`${styles.status} ${styles.pending} ${className}`}
        style={{ backgroundColor: getStatusColor(status) }}>
        <PendingIcon className={styles.icon} size={16} />
        {status.name}
      </div>
    );
  }

  return (
    <div className={`${styles.status} ${className}`}>
      <StatusIcon className={styles.icon} size={14} status={status} />
      {status.name}
    </div>
  );
}
