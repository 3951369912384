import React from 'react';
import { IconButton, Spacer } from '@bitmodern/bit-ui';
import {
  AttachmentIcon,
  DeleteIcon,
  DownloadIcon,
} from '@bitmodern/bit-ui/icons';
import { Attachment } from '@testquality/sdk';
import { getFileSize, processAttachmentPath } from 'src/utils/fileHelper';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import { useDispatch } from 'react-redux';
import { attachmentDeleteOneThunk } from 'src/gen/domain/attachment/attachmentThunk';
import styles from './AttachmentItem.module.scss';

type Props = {
  item: Attachment;
};

export default function AttachmentItem({ item }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const EXT = ['jpg', 'png'];

  const handleDelete = () => {
    dispatch(attachmentDeleteOneThunk({ id: item.id }));
  };

  const handleDownload = () => {
    const Url = processAttachmentPath(item.url);
    window.open(Url, '_blank', 'noopener');
  };

  const isImage = () => {
    if (!item) return false;
    if (item.url?.includes('.')) {
      let fileExt = item.url?.split('.')?.pop();
      fileExt = fileExt!.toLowerCase();
      return EXT.includes(fileExt);
    }
    return false;
  };

  return (
    <div className={styles.attachmentItem}>
      <div className={styles.attachmentIcon}>
        {isImage() ? (
          <img src={processAttachmentPath(item.url)} alt="" />
        ) : (
          <AttachmentIcon color={vars.textPrimary} size={40} />
        )}
      </div>
      <div className={styles.attachmentDetails}>
        <div className={styles.name} title={item.original_file_name}>
          {item.original_file_name}
        </div>
        <div className={styles.size}>{getFileSize(item)}</div>
      </div>
      <Spacer>
        <IconButton
          boxed
          className={styles.actionButton}
          onClick={handleDownload}
          title={t('attachments.actions.download')}>
          <DownloadIcon color={vars.textPrimary} size={24} />
        </IconButton>
        <IconButton
          boxed
          className={styles.actionButton}
          onClick={handleDelete}
          title={t('attachments.actions.delete')}>
          <DeleteIcon color={vars.textPrimary} size={24} />
        </IconButton>
      </Spacer>
    </div>
  );
}
