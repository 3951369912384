import { getResponse } from '@testquality/sdk';
import { getClient } from 'src/Client';
import { isPopularDomain } from 'src/utils/common';

export async function generateSiteFromEmail(email: string): Promise<string> {
  const domainNameRegex = /(@)([^.]+(?=\.))/;
  const usernameRegex = /[^@]+/;
  const domainName = email.match(domainNameRegex);
  const username = email.match(usernameRegex);
  if (!domainName || !username) {
    throw new Error('Invalid email address');
  }

  const name = isPopularDomain(domainName[2]) ? username[0] : domainName[2];
  const alternativeName = await validateSiteName(name);
  return alternativeName.nextName || name;
}

type ValidateSiteNameRes = {
  valid: boolean;
  client_id?: number;
  next_name?: string;
};

export async function validateSiteName(name: string) {
  return getResponse<ValidateSiteNameRes, { name: string }>(getClient().api, {
    method: 'post',
    url: '/system/validate_site_name',
    data: { name },
  }).then((res) => ({
    valid: res.valid,
    clientId: res.client_id,
    nextName: res.next_name,
  }));
}
