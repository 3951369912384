import React, { useState } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Button, EasyMDEEditor } from '@bitmodern/bit-ui';
import { Attachment } from '@testquality/sdk';
import { RelatedType } from '@bitmodern/services/AttachmentService';
import styles from './CommentCreate.module.scss';

type Props = {
  onComment: (value: string) => void;
  onFileUpload?: (attachment: Attachment) => void;
  parentId: number;
  parentType: RelatedType;
  withFormatting?: boolean;
};

export default function CommentCreate({
  onComment,
  onFileUpload,
  parentId,
  parentType,
  withFormatting,
}: Props) {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');

  const handleComment = () => {
    if (comment) {
      onComment(comment);
      setComment('');
    }
  };

  return (
    <>
      <h3 className={styles.title}>{t('commentCreate.title')}</h3>
      <EasyMDEEditor
        minHeight="52px"
        id="comment"
        onChange={setComment}
        onFileUpload={onFileUpload}
        parentId={parentId}
        parentType={parentType}
        value={comment}
        withFormatting={withFormatting}
      />
      <div className={styles.newComment}>
        <Button color="primaryLight" onClick={handleComment}>
          {t('commentCreate.submit')}
        </Button>
      </div>
    </>
  );
}
