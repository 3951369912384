import { format } from 'date-fns';
import { getResponse, TestRoute } from '@testquality/sdk';
import { getClient } from 'src/Client';

export type TestQualityTimeSeries = {
  client_id: number;
  days: {
    [day: string]: { totals: { [testQuality: string]: number } };
  };
  project_id: number;
};

export function putTestQualityUserOverride(
  userOverride: boolean,
  testId: number,
) {
  const URL = `${TestRoute()}/${testId}${
    userOverride ? '?user_override' : '?clear_user_override'
  }`;
  return getClient().api.put(URL);
}

export async function getTestQualityAnalysis(
  projectId: number,
  startDate: Date,
): Promise<TestQualityTimeSeries> {
  return getResponse(getClient().api, {
    url: `${TestRoute()}/test_quality_time_series`,
    params: {
      project_id: projectId,
      start_date: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"),
    },
  });
}
