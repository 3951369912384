import { useKeyboard } from 'react-aria';

type Params = {
  commitOnEnter?: boolean;
  isEditing: boolean;
  onCancel?: () => void;
  onCommit?: () => void;
  onOpen?: () => void;
  onPressTab?: () => void;
};

export function useEditingKeyboard({
  commitOnEnter = true,
  isEditing,
  onCancel,
  onCommit,
  onOpen,
}: Params) {
  const handleKeyDown = (event) => {
    const { key } = event;
    if (isEditing) {
      if (key === 'Escape' && onCancel) {
        onCancel();
      }
      if (key === 'Enter' && commitOnEnter && onCommit) {
        onCommit();
      }
    } else if ((key === 'Enter' || key === ' ') && onOpen) {
      onOpen();
    }
  };

  const keyboardProps = useKeyboard({
    onKeyDown: handleKeyDown,
  });

  return keyboardProps;
}
