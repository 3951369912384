/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Filter,
  filterGetMany,
  filterGetOne,
  filterDeleteOne,
  filterUpdateOne,
  filterCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const filterFetchManyThunk = createAsyncThunk(
  'filter/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Filter>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await filterGetMany(inlineArgs);
      return {
        filter: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const filterFetchOneThunk = createAsyncThunk(
  'filter/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Filter>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'filter/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await filterGetOne(arg.id as number, arg);
      return {
        filter: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const filterDeleteOneThunk = createAsyncThunk(
  'filter/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Filter>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'filter/DeleteOneThunk');
      }
      return await filterDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const filterUpdateOneThunk = createAsyncThunk(
  'filter/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Filter>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'filter/UpdateOneThunk');
      }
      const data = await filterUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Filter>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Filter>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const filterCreateOneThunk = createAsyncThunk(
  'filter/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Filter>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'filter/CreateOneThunk',
        );
      }
      return await filterCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
