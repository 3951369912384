/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { StatusHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { statusHistoryAdapter } from './statusHistoryAdapter';
import { statusHistoryFetchThunk } from './statusHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const statusHistorySlice = createSlice({
  name: 'statusHistory',
  initialState: statusHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    statusHistoryCleared(state) {
      state.loading = 'idle';
      statusHistoryAdapter.removeAll(state);
    },
    statusHistoryUpsertMany(
      state,
      action: PayloadAction<StatusHistory[] | Record<number, StatusHistory>>,
    ) {
      statusHistoryAdapter.upsertMany(state, action.payload);
    },
    statusHistoryUpsertOne(state, action: PayloadAction<StatusHistory>) {
      statusHistoryAdapter.upsertOne(state, action.payload);
    },
    statusHistoryUpdateOne(
      state,
      action: PayloadAction<Update<StatusHistory>>,
    ) {
      statusHistoryAdapter.updateOne(state, action.payload);
    },
    statusHistoryRemoveOne(state, action: PayloadAction<number>) {
      statusHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(statusHistoryFetchThunk.pending, handlePending);
    builder.addCase(statusHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusHistoryAdapter.upsertMany(state, action.payload.statusHistory);
    });
    builder.addCase(statusHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  statusHistoryCleared,
  statusHistoryUpsertMany,
  statusHistoryUpsertOne,
  statusHistoryUpdateOne,
  statusHistoryRemoveOne,
} = statusHistorySlice.actions;

export const statusHistoryReducer = statusHistorySlice.reducer;
