import React from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import {
  Button,
  Dialog,
  Input,
  notification,
  PanelActions,
  SelectMultiple,
} from '@bitmodern/bit-ui';
import { AddFolderIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { routes } from 'src/components/Router';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { PlanSuite, SuiteTest } from '@testquality/sdk';
import { createSuiteThunk } from '@bitmodern/redux/state/suites/thunks';
import styles from './SuiteCreate.module.scss';
import { notificationErrorTimeout } from '../../../constants';

const initialValues = {
  name: '',
  labels: [],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('suiteCreate.form.name'),
});

type Props = {
  planSuite?: PlanSuite;
  suiteTest?: SuiteTest;
  planId?: number; // to include in that plan if necessary
};

export default function SuiteCreate({ planSuite, suiteTest, planId }: Props) {
  const { t } = useTranslation();
  const { hideModal } = useModalManager();
  const { projectId } = useParamsInRoute<typeof routes.PLAN.params>(
    routes.PROJECT.path,
  );
  const dispatch = useAppDispatch();
  const labels = useAppSelector(labelsSelector);
  const formik = useFormik({ onSubmit, initialValues, validationSchema });

  const labelOptions = labels.map((label) => ({
    value: label.id,
    label: label.label,
  }));

  const onChangeLabel = (values) => {
    formik.setFieldValue('labels', values);
  };

  function onSubmit(values) {
    return dispatch(
      createSuiteThunk({
        projectId: parseInt(projectId, 10),
        name: values.name,
        labels: values.labels,
        under: { planSuite, suiteTest },
        planId,
      }),
    ).then(
      () => {
        hideModal();
      },
      (error) => {
        notification.open({
          type: 'error',
          message: error.title ? error.title : 'Error',
          description: error.message,
          duration: notificationErrorTimeout,
        });
      },
    );
  }

  return (
    <Dialog
      animatePresence={false}
      title={
        <div>
          <AddFolderIcon
            color={vars.textPrimary}
            className={styles.titleIcon}
            size={18}
          />
          {t('suiteCreate.title')}
        </div>
      }
      isDismissable={false}
      onClose={hideModal}
      size="small"
      isOpen>
      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <div className={styles.content}>
          <Input
            autoComplete="off"
            error={formikError(formik, 'name')}
            fullWidth
            label={t('suiteCreate.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.name}
            autoFocus
          />
          <SelectMultiple
            label={t('suiteCreate.form.labels')}
            onChange={onChangeLabel}
            options={labelOptions}
            placeholder={t('suiteCreate.form.labelsPlaceholder')}
            tags
            values={formik.values.labels}
          />
        </div>
        <PanelActions>
          <Button
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
            type="submit">
            {t('suiteCreate.form.submit')}
          </Button>
        </PanelActions>
      </form>
    </Dialog>
  );
}
