import { RunResultStep, Status, StatusType } from '@testquality/sdk';
import { StatusTypeEnum } from 'src/enums/StatusEnum';

export default function runResultStatusFromSteps(
  statuses: Status[],
  runResultSteps: RunResultStep[],
  statusTypes: StatusType[],
) {
  const negative = statusTypes.find((st) => st.key === StatusTypeEnum.Negative);
  const neutral = statusTypes.find((st) => st.key === StatusTypeEnum.Neutral);
  const positive = statusTypes.find((st) => st.key === StatusTypeEnum.Positive);

  const statusesMap: { [key: number]: Status } = statuses.reduce(
    (acu, status) => {
      acu[status.id] = status;
      return acu;
    },
    {},
  );

  return runResultSteps.reduce<Status | undefined>((acu, rrs) => {
    const status = statusesMap[rrs.status_id];
    const acuKey = acu?.key || 0;
    if (status) {
      if (status.status_type_id === negative?.id) {
        if (acuKey < status.key) return status;
        return acu;
      }
      if (status.status_type_id === neutral?.id) {
        if (acuKey < status.key) return status;
        return acu;
      }
      if (status.status_type_id === positive?.id) {
        if (acuKey < status.key) return status;
        return acu;
      }
    }
    return acu;
  }, undefined);
}
