import { unwrapResult } from '@reduxjs/toolkit';
import {
  PlanRoute,
  ProjectRoute,
  SuiteRoute,
  TestRoute,
  Watch,
} from '@testquality/sdk';
import { watchCreateOneThunk } from 'src/gen/domain/watch/watchThunk';
import { AppThunk } from '@bitmodern/redux/store';

export function watchSuiteThunk(folderId: number): AppThunk<Promise<Watch>> {
  return (dispatch) => {
    const url = `${SuiteRoute()}/${folderId}/watch`;
    return dispatch(watchCreateOneThunk({ url, data: {} })).then(unwrapResult);
  };
}

export function watchTestThunk(testId: number): AppThunk<Promise<Watch>> {
  return (dispatch) => {
    const url = `${TestRoute()}/${testId}/watch`;
    return dispatch(watchCreateOneThunk({ url, data: {} })).then(unwrapResult);
  };
}

export function watchCycleThunk(cycleId: number): AppThunk<Promise<Watch>> {
  return (dispatch) => {
    const url = `${PlanRoute()}/${cycleId}/watch`;
    return dispatch(watchCreateOneThunk({ url, data: {} })).then(unwrapResult);
  };
}

export function watchProjectThunk(projectId: number): AppThunk<Promise<Watch>> {
  return (dispatch) => {
    const url = `${ProjectRoute()}/${projectId}/watch`;
    return dispatch(watchCreateOneThunk({ url, data: {} })).then(unwrapResult);
  };
}
