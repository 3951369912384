/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { CapabilityApi } from '@testquality/sdk';
import { CapabilityIntegration } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { integrationNormalizer } from '../integration/integrationNormalize';
import { capabilityIntegrationNormalizer } from '../capability_integration/capabilityIntegrationNormalize';

export const capabilityNormalizer = (
  model: CapabilityApi,
  cache: NormalizerCache,
) => {
  if (!cache.capability) {
    cache.capability = [];
  }
  cache.capability.push(model);

  if (model.integration && model.integration.length) {
    model.integration.forEach((child) => {
      capabilityIntegrationNormalizer(
        child.pivot as CapabilityIntegration,
        cache,
      );
      child.pivot = undefined;
      integrationNormalizer(child, cache);
    });
    model.integration = undefined;
  }
};
