/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { ClientHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { clientHistoryAdapter } from './clientHistoryAdapter';
import { clientHistoryFetchThunk } from './clientHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const clientHistorySlice = createSlice({
  name: 'clientHistory',
  initialState: clientHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    clientHistoryCleared(state) {
      state.loading = 'idle';
      clientHistoryAdapter.removeAll(state);
    },
    clientHistoryUpsertMany(
      state,
      action: PayloadAction<ClientHistory[] | Record<number, ClientHistory>>,
    ) {
      clientHistoryAdapter.upsertMany(state, action.payload);
    },
    clientHistoryUpsertOne(state, action: PayloadAction<ClientHistory>) {
      clientHistoryAdapter.upsertOne(state, action.payload);
    },
    clientHistoryUpdateOne(
      state,
      action: PayloadAction<Update<ClientHistory>>,
    ) {
      clientHistoryAdapter.updateOne(state, action.payload);
    },
    clientHistoryRemoveOne(state, action: PayloadAction<number>) {
      clientHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clientHistoryFetchThunk.pending, handlePending);
    builder.addCase(clientHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      clientHistoryAdapter.upsertMany(state, action.payload.clientHistory);
    });
    builder.addCase(clientHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  clientHistoryCleared,
  clientHistoryUpsertMany,
  clientHistoryUpsertOne,
  clientHistoryUpdateOne,
  clientHistoryRemoveOne,
} = clientHistorySlice.actions;

export const clientHistoryReducer = clientHistorySlice.reducer;
