import { DefectLabel } from '@testquality/sdk';
import { defectLabelSelectors } from 'src/gen/domain/defect_label/defectLabelSelector';
import { defectPrioritySelector } from '@bitmodern/redux/state/defectPriorities/selectors';
import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';

export function defectLabelSelector(state: State) {
  return defectLabelSelectors.selectAll(state);
}

export const ownDefectLabelsByIntegrationProjectSelector = (
  integrationProjectId?: number,
) =>
  createSelector(
    [defectLabelSelector, defectPrioritySelector],
    (labels, priorities): DefectLabel[] => {
      const ownLabels = labels
        .filter(
          ({ integration_project_id }) =>
            integration_project_id === integrationProjectId,
        )
        .filter(({ name: labelName }) => {
          return priorities.every(
            ({ name: priorityName }) =>
              labelName !== priorityName && !/^resolution_.*/.test(labelName),
          );
        });

      return uniqBy(ownLabels, 'name');
    },
  );
