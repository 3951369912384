import { AppThunk } from '../../store';
import { logoutThunk } from '../authentication/thunks';
import { clientDeleteOneThunk } from 'src/gen/domain/client/clientThunk';

/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export function deleteClientThunk(clientId: number): AppThunk {
  return async (dispatch) => {
    await dispatch(clientDeleteOneThunk({ id: clientId }));
    await dispatch(logoutThunk());
  };
}
