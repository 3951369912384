import { ReturnToken, TokenStorage } from '@testquality/sdk';
import { LocalStorageHelper } from './localstorage-helper';
import Debug from 'debug';

/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const debug = Debug('bitmodern:token:storage');
export class TokenStorageImpl implements TokenStorage {
  private token?: ReturnToken;
  private remember?: boolean;
  private readonly localStorage = new LocalStorageHelper(false);
  private readonly sessionStorage = new LocalStorageHelper(true);

  public async getRemember(): Promise<boolean | undefined> {
    if (this.remember === undefined) {
      // always put remember in localStorage
      this.remember = await this.localStorage.get('remember');
    }
    return this.remember;
  }

  public async getToken(): Promise<ReturnToken | undefined> {
    if (this.token === undefined) {
      if (await this.getRemember()) {
        this.token = await this.localStorage.get('token');
      } else {
        this.token = await this.sessionStorage.get('token');
      }
    }
    return this.token;
  }

  public async setToken(
    token?: ReturnToken,
    remember?: boolean,
  ): Promise<ReturnToken | undefined> {
    this.token = token;
    this.remember =
      remember !== undefined ? remember : await this.getRemember();
    if (this.remember) {
      await this.localStorage.set('token', token);
      await this.sessionStorage.set('token', undefined);
    } else {
      await this.localStorage.set('token', undefined);
      await this.sessionStorage.set('token', token);
    }
    debug('setToken', token, this.remember);
    await this.localStorage.set('remember', this.remember);
    return this.token;
  }
}
