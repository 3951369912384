/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { Suspense } from 'react'; // eslint-disable-line
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from 'src/components/organisms/App';
import { getEnv } from './env';

if (!getEnv().isDevelopment) {
  Sentry.init({
    dsn: 'https://49e0c71a6c1540f1aa80e2fdfe6225c6@o138922.ingest.sentry.io/324666',
    integrations: [new Integrations.BrowserTracing()],
    environment: getEnv().node,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

export function reactBootstrap() {
  const container = document.getElementById('root');
  if (!container) return;
  const root = createRoot(container);

  root.render(
    <Suspense fallback="loading">
      <App />
    </Suspense>,
  );
}
