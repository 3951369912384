import React from 'react';
import { useTranslation } from 'react-i18next';
import { GithubIcon } from '@bitmodern/bit-ui/icons';
import { useIntegration } from '../../hooks/useIntegration';
import SuccessIcons from '../SuccessIcons';
import { StepContent } from '../StepContent';
import { StepActions } from '../StepActions';
import { StepProps } from '../Step';
import StaggerWrapper from '../StaggerWrapper';
import styles from './GithubStep.module.scss';

export function GithubStep({ onNextStep, currentStep, stepTotal }: StepProps) {
  const { t } = useTranslation();
  const { authorize, loading, success, user } = useIntegration('github');

  let action;
  let content;
  let title;
  let onClickAction = onNextStep;

  if (success) {
    title = t('getStarted.githubStep.success.title');
    content = (
      <div>
        <SuccessIcons IntegrationIcon={GithubIcon} />
        <p>{t('getStarted.githubStep.success.text')}</p>
      </div>
    );
  } else if (user) {
    title = t('getStarted.githubStep.integration.title');
    content = (
      <div>
        <ul className={styles.list}>
          <li>{t('getStarted.githubStep.integration.one')}</li>
          <li>{t('getStarted.githubStep.integration.two')}</li>
          <li>{t('getStarted.githubStep.integration.three')}</li>
        </ul>
      </div>
    );
  } else {
    action = t('getStarted.githubStep.authorizeButton');
    title = t('getStarted.githubStep.title');
    content = (
      <div>
        <ul className={styles.list}>
          <li>{t('getStarted.githubStep.features.one')}</li>
          <li>{t('getStarted.githubStep.features.two')}</li>
          <li>{t('getStarted.githubStep.features.three')}</li>
        </ul>
      </div>
    );
    onClickAction = authorize;
  }

  return (
    <>
      <StepContent
        currentStep={currentStep}
        stepTotal={stepTotal}
        title={title}>
        <StaggerWrapper>{content}</StaggerWrapper>
      </StepContent>
      <StepActions
        action={action}
        loading={loading}
        onClickAction={onClickAction}
        onSkip={onNextStep}
      />
    </>
  );
}
