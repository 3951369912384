import React, { useState } from 'react';
import { FormikProps } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
} from '@bitmodern/bit-ui';
import { TreeItem } from 'react-sortable-tree';
import useModalManager from 'src/hooks/useModalManager';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import {
  SuiteListed,
  TestListed,
  VirtualInputs,
} from 'src/components/organisms';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import TreeBase from 'src/components/organisms/TreeBase';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { VirtualSchema } from '@bitmodern/redux/state/virtuals/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { routes } from 'src/components/Router';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import styles from './PlanCreateView.module.scss';

type Props = {
  formik: FormikProps<{
    name: string;
    includes: TreeItem[];
  }>;
  withTree: boolean;
  virtuals: { [key: string]: VirtualSchema };
};

export default function PlanCreateView({ formik, withTree, virtuals }: Props) {
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const [checkeds, setCheckeds] = useState({});
  const { hideModal } = useModalManager();
  const { t } = useTranslation();
  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId: parseInt(projectId, 10),
      filterType: 'tests',
    }),
  );

  const onCheckItems = (items) => {
    formik.setFieldValue('includes', items);
  };

  const hasTree = withTree && tree.length > 0;
  const dialogSize = hasTree ? 'xlarge' : 'small';

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size={dialogSize}
      title={t('planCreate.title')}>
      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <DialogContent className={styles.content}>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('planCreate.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.name}
            autoFocus
          />
          {hasTree && (
            <>
              <div className={styles.treeLabel}>{t('planCreate.form.add')}</div>
              <div className={styles.treeWrap}>
                <TreeBase
                  checkeds={checkeds}
                  canDrag={false}
                  id="startRun"
                  onCheck={setCheckeds}
                  getNodeKey={(data) => data.node.nodeKey}
                  canNodeHaveChildren={(node) => node.type !== TEST_TYPE}
                  onCheckItems={onCheckItems}
                  renderNodeType={{
                    [TEST_TYPE]: (item) => {
                      const { node, onCheckParentChange, path, treeIndex } =
                        item;
                      const { data } = node;
                      return (
                        <TestListed
                          checked={node.checked}
                          nestingLevel={path.length - 1}
                          onChangeChecked={(value) => {
                            if (onCheckParentChange)
                              onCheckParentChange(value, {
                                node,
                                treeIndex,
                                path,
                              });
                          }}
                          suiteTest={data.suiteTest}
                          test={data.test}
                          withActions={false}
                        />
                      );
                    },

                    [SUITE_TYPE]: (item) => {
                      const {
                        node,
                        onCheckParentChange,
                        path,
                        toggleChildrenVisibility,
                        treeIndex,
                      } = item;
                      const { data } = node;
                      return (
                        <SuiteListed
                          checked={node.checked}
                          collapsed={node.expanded || false}
                          collapsible={Boolean(node.children?.length)}
                          nestingLevel={path.length - 1}
                          onChangeChecked={(value) => {
                            if (onCheckParentChange)
                              onCheckParentChange(value, {
                                node,
                                treeIndex,
                                path,
                              });
                          }}
                          onClick={() => {
                            if (!toggleChildrenVisibility) return;
                            toggleChildrenVisibility({ node, path, treeIndex });
                          }}
                          planSuite={data.planSuite}
                          suite={data.suite}
                          withActions={false}
                        />
                      );
                    },
                  }}
                  treeData={tree}
                />
              </div>
            </>
          )}
          <VirtualInputs formik={formik} virtuals={virtuals} />
        </DialogContent>
        <PanelActions>
          <Button
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
            type="submit">
            {t('planCreate.form.submit')}
          </Button>
        </PanelActions>
      </form>
    </Dialog>
  );
}
