import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { MarkdownView } from '@bitmodern/bit-ui';
import EasyMEDInlineEdit from '@bitmodern/bit-ui/InlineEdit/EasyMEDInlineEdit';
import {
  Step,
  RunResultStep as RunResultStepModel,
  Status,
} from '@testquality/sdk';
import { DataSet } from '@bitmodern/redux/state/dataSets/selectors';

import { ActualResultIcon, ExpectedResultIcon } from '@bitmodern/bit-ui/icons';
import useDebouncedCallback from 'src/hooks/useDebounceCallback';
import vars from 'src/export.scss';
import Duration from '../Duration';
import styles from './RunResultStep.module.scss';
import QuickStatus from './QuickStatus';
import interpolateDataSetRow from 'src/utils/dataSet/interpolateDataSetRow';

type Props = {
  dataSet?: DataSet;
  dataSetRow?: number;
  disabled: boolean;
  onChangeStatus: (status: Status) => void;
  onChangeStepResult: (result?: string) => Promise<any>;
  runResultStep?: RunResultStepModel;
  step: Step;
  onChangeStepElapsed: (elapsed: number) => void;
};

export default function RunResultStep({
  dataSet,
  dataSetRow,
  disabled,
  onChangeStatus,
  onChangeStepElapsed,
  onChangeStepResult,
  runResultStep,
  step,
}: Props) {
  const { t } = useTranslation();

  const [duration, setDuration] = useDebouncedCallback(
    onChangeStepElapsed,
    runResultStep?.elapsed || 0,
    500,
  );

  const interpolatedStep = useMemo(
    () => interpolateDataSetRow(step.step, dataSet, dataSetRow),
    [step.step, dataSet, dataSetRow],
  );
  const interpolatedResult = useMemo(
    () => interpolateDataSetRow(step.expected_result, dataSet, dataSetRow),
    [step.expected_result, dataSet, dataSetRow],
  );

  return (
    <div className={styles.stepResult}>
      <div className={styles.header}>
        <span className={styles.sequence}>{step.sequence}</span>
        {runResultStep && (
          <div className={styles.actions}>
            <Duration
              className={styles.duration}
              disabled={disabled}
              onChange={setDuration}
              value={duration}
            />
            <QuickStatus
              disabled={disabled}
              onChange={onChangeStatus}
              value={runResultStep.status_id}
            />
          </div>
        )}
      </div>
      {step.step && (
        <MarkdownView
          className={styles.stepView}
          markdown={interpolatedStep}
          read
        />
      )}
      <div className={styles.step}>
        {step.expected_result && (
          <div className={styles.expectedResult}>
            <div className={styles.connector}>
              <ExpectedResultIcon size={24} />
            </div>
            <div className={styles.expectedResultContent}>
              <div className={styles.resultLabel}>
                {t('runResultStep.expectedResult')}
              </div>
              <MarkdownView
                className={styles.resultText}
                markdown={interpolatedResult}
                read
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.result}>
        <ActualResultIcon color={vars.textPrimary} size={24} />
        <div className={styles.resultContent}>
          <div className={styles.resultLabel}>
            {t('runResultStep.actualResult')}
          </div>
          {disabled ? (
            <MarkdownView
              className={styles.actualResultDisabled}
              markdown={runResultStep?.result}
              read
            />
          ) : (
            <EasyMEDInlineEdit
              className={styles.actualResult}
              onCommit={onChangeStepResult}
              parentId={runResultStep?.run_result_id || 0}
              parentType="RunResult"
              value={runResultStep?.result}
            />
          )}
        </div>
      </div>
    </div>
  );
}
