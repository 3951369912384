import React, { ComponentProps, useCallback } from 'react';
import { useAppDispatch } from '@bitmodern/redux/store';
import { inviteUsersThunk } from '@bitmodern/redux/state/users/thunks';
import InviteUsersDialog from './InviteUsersDialog';

type InviteUsersDialogProps = ComponentProps<typeof InviteUsersDialog>;

type Props = Omit<InviteUsersDialogProps, 'onSubmit'>;

export default function InviteUsersDialogContainer({
  onClose,
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(
    (values) => {
      dispatch(
        inviteUsersThunk({
          values,
          onClose,
        }),
      );
    },
    [dispatch, onClose],
  );

  return <InviteUsersDialog onSubmit={onSubmit} onClose={onClose} {...props} />;
}
