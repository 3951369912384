import React, { useCallback, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {
  Dropdown,
  IconButton,
  Loading,
  Menu,
  MenuItem,
  PanelHeader,
  Spacer,
  Surface,
  Tab,
  Tabs,
} from '@bitmodern/bit-ui';
import {
  CancelIcon,
  CompletedIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PendingIcon,
  PlanIcon,
  PlayIcon,
} from '@bitmodern/bit-ui/icons';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import { Run as RunModel, RunResult } from '@testquality/sdk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import RunOverviewView from 'src/components/views/RunOverviewView';
import { selectRunsByPlanId } from '@bitmodern/redux/state/runs/selectors';
import { makeDefectsByPlan } from '@bitmodern/redux/state/defects/selectors';
import useModalManager from 'src/hooks/useModalManager';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { rootPlanSelector } from '@bitmodern/redux/state/plans/selectors';
import { suiteTestsSelector } from '@bitmodern/redux/state/suiteTests/selectors';
import { customFilterByTypeSelector } from '@bitmodern/redux/state/customFilters/selectors';
import {
  clearAllFiltersAction,
  toggleFilterAction,
} from '@bitmodern/redux/state/filters/actions';
import {
  runResultsByRunIdAssignedToSelfSelector,
  runResultSelector,
  RunResultTreeItem,
} from '@bitmodern/redux/state/runResults/selectors';
import {
  pendingStatusSelector,
  retestStatusSelector,
} from '@bitmodern/redux/state/statuses/selectors';
import { finishRunThunk } from '@bitmodern/redux/state/runs/thunks';
import {
  exportRunsToCSVThunk,
  exportRunsToGherkinThunk,
  exportRunsToXLSThunk,
} from '@bitmodern/redux/state/exportData/thunks';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import { treeToRunResultData } from 'src/utils/export';
import { useMaker } from 'src/packages/redux/hooks';
import CommandBarRunExport from '../CommandBarRunExport';
import RunTree from '../RunTree';
import ConfirmDialog from '../ConfirmDialog';
import Filters from '../Filters';
import styles from './Run.module.scss';
import EmptyTests from '../EmptyTests';
import RunAndDefectsList from '../RunAndDefectsList';

type Props = {
  className?: string;
  current: number;
  loading: boolean;
  onDeleteRun: () => Promise<any>;
  run: RunModel;
  runResultsTree: RunResultTreeItem[];
  site: string;
  onClose?: () => void;
};

export default function Run({
  className = '',
  current,
  loading,
  onDeleteRun,
  run,
  runResultsTree = [],
  site,
  onClose,
}: Props) {
  const { openDrawer } = useDrawerManager();
  const { showModal } = useModalManager();
  const [runTabs, setRunTabs] = useState('overview');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const commandBar = useOverlayTriggerState({});
  const deleteRun = useOverlayTriggerState({});

  const currentUser = useAppSelector(currentUserSelector);
  const customFilter = useAppSelector((state) =>
    customFilterByTypeSelector(state, 'runs'),
  );

  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, run.project_id),
  );

  const runs = useAppSelector((state) =>
    selectRunsByPlanId(state, run.plan_id),
  );

  const pendingStatus = useAppSelector(pendingStatusSelector);
  const retestStatus = useAppSelector(retestStatusSelector);

  const defects = useMaker(makeDefectsByPlan, { planId: run.plan_id });

  const currentRunResult = useAppSelector((state) =>
    runResultSelector(state, current),
  );

  const runResults = useAppSelector((state) =>
    runResultSelectors
      .selectAll(state)
      .filter((runResult) => runResult.run_id === run.id),
  );

  const suiteTestsIds = useAppSelector((state) =>
    suiteTestsSelector(state)
      .filter((st) => runResults.map((rr) => rr.test_id).includes(st.test_id))
      .map((item) => item.id),
  );

  const runResultsByRunIdAssignedToSelf = useAppSelector((state) =>
    runResultsByRunIdAssignedToSelfSelector(state, {
      runId: run.id,
    }),
  );

  const onConfirmDelete = useCallback(() => {
    return onDeleteRun().finally(deleteRun.close);
  }, [deleteRun, onDeleteRun]);

  const deleteRunMutation = useMutation(onConfirmDelete);

  const onFinishRun = () => {
    dispatch(finishRunThunk(run));
  };

  const onStartRun = () => {
    showModal({
      modalName: 'startRun',
      modalProps: { planId: run.plan_id, suiteTestsIds },
      type: 'modal',
    });
  };

  const onCreateCycle = () => {
    showModal({
      modalName: 'createPlan',
      modalProps: { projectId: run.project_id, runId: run.id },
      type: 'modal',
    });
  };

  const onClickEdit = () => {
    showModal({
      modalName: 'runEdit',
      modalProps: { runId: run.id },
      type: 'modal',
    });
  };

  const handleCSVExport = () => {
    const runResultData = treeToRunResultData(runResultsTree);
    dispatch(
      exportRunsToCSVThunk([{ runResultsData: runResultData, runId: run.id }]),
    );
    commandBar.close();
  };

  const handleGherkinExport = () => {
    const runResultData = treeToRunResultData(runResultsTree);
    dispatch(
      exportRunsToGherkinThunk(
        [{ runResultsData: runResultData, runId: run.id }],
        site,
      ),
    );
    commandBar.close();
  };

  const handleXLSExport = () => {
    const runResultData = treeToRunResultData(runResultsTree);
    dispatch(
      exportRunsToXLSThunk([{ runResultsData: runResultData, runId: run.id }]),
    );
    commandBar.close();
  };

  const onClickTest = useCallback(
    (_, runResult: RunResult) => {
      openDrawer({ resultId: runResult.id });
    },
    [openDrawer],
  );

  const onClickTodoShortcut = () => {
    /* NOTE: Navigate to test tab (if needed) and activate assignedToSelf filter */
    if (runTabs !== 'test') {
      setRunTabs('test');
    }

    if (customFilter) {
      /* NOTE: Clear current filters when custom filter is active */
      dispatch(clearAllFiltersAction({ type: 'runs' }));
    }

    dispatch(
      toggleFilterAction({
        type: 'runs',
        filter: 'status',
        value: pendingStatus?.id,
      }),
    );
    dispatch(
      toggleFilterAction({
        type: 'runs',
        filter: 'status',
        value: retestStatus?.id,
      }),
    );
    if (currentUser) {
      dispatch(
        toggleFilterAction({
          type: 'runs',
          filter: 'assignee',
          value: currentUser.id,
        }),
      );
    }
  };

  const renderTodoShortcut = () =>
    runResultsByRunIdAssignedToSelf.length !== 0 && (
      <div className={styles.todoButton} onClick={onClickTodoShortcut}>
        <PendingIcon className={styles.todoIcon} size={16} />
        {t('run.actions.todo', {
          count: runResultsByRunIdAssignedToSelf.length,
        })}
      </div>
    );

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          {run.is_running ? (
            <MenuItem
              onClick={onFinishRun}
              startAdornment={
                <CompletedIcon color={vars.textPrimary} size={18} />
              }>
              {t('run.actions.finish')}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={onStartRun}
              startAdornment={<PlayIcon color={vars.textPrimary} size={18} />}>
              {t('run.actions.start')}
            </MenuItem>
          )}
          {run.plan_id === rootPlan?.id && (
            <MenuItem
              onClick={onCreateCycle}
              startAdornment={<PlanIcon color={vars.textPrimary} size={18} />}>
              {t('run.actions.createCycle')}
            </MenuItem>
          )}
          <MenuItem
            onClick={commandBar.open}
            startAdornment={
              <DownloadIcon color={vars.textPrimary} size={18} />
            }>
            {t('run.actions.export')}
          </MenuItem>
          <MenuItem
            onClick={onClickEdit}
            startAdornment={<EditIcon color={vars.textPrimary} size={18} />}>
            {t('run.actions.edit')}
          </MenuItem>
          <MenuItem
            onClick={deleteRun.open}
            startAdornment={<DeleteIcon color={vars.textPrimary} size={18} />}>
            {t('run.actions.delete')}
          </MenuItem>
        </Menu>
      }>
      <IconButton title={t('overview.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  const testsTab =
    runResultsTree.length === 0 ? (
      <EmptyTests filterType="runs" potentialAmount={runResults.length} />
    ) : (
      <RunTree
        currentRunResult={currentRunResult}
        run={run}
        onClickTest={onClickTest}
        runsTree={runResultsTree}
        planId={run.plan_id.toString()}
      />
    );

  return (
    <>
      <Surface className={`${styles.run} ${className}`}>
        <PanelHeader
          title={run.name}
          actions={
            <Spacer>
              {renderTodoShortcut()}
              {runTabs === 'test' && (
                <Filters type="runs" parentId={run.id.toString()} />
              )}
              {more}
              {onClose && (
                <IconButton
                  boxed={false}
                  onClick={onClose}
                  title={t('run.actions.close')}>
                  <CancelIcon color={vars.textPrimary} size={18} />
                </IconButton>
              )}
            </Spacer>
          }
        />
        <Tabs tab={runTabs} onChange={setRunTabs} withBarBackground>
          <Tab id="overview">{t('run.tabs.overview')}</Tab>
          <Tab id="test" badge={!run.is_complete}>
            {run.is_complete
              ? t('run.tabs.runResults.completed')
              : t('run.tabs.runResults.active')}
          </Tab>
          <Tab id="defects">{t('run.tabs.defects')}</Tab>
        </Tabs>
        {loading ? (
          <Loading className={styles.loading} size={48} />
        ) : (
          <div className={styles.panelContent}>
            {runTabs === 'overview' && <RunOverviewView run={run} />}
            {runTabs === 'test' && testsTab}
            {runTabs === 'defects' && (
              <RunAndDefectsList runs={runs} defects={defects} />
            )}
          </div>
        )}
        <ConfirmDialog
          loading={deleteRunMutation.isLoading}
          onCancel={deleteRun.close}
          onConfirm={deleteRunMutation.mutate}
          open={deleteRun.isOpen}
          title={t('run.deleteRun.title')}>
          {t('run.deleteRun.content')}
        </ConfirmDialog>
        <CommandBarRunExport
          handleCSVExport={handleCSVExport}
          handleXLSExport={handleXLSExport}
          handleGherkinExport={handleGherkinExport}
          onClose={commandBar.close}
          open={commandBar.isOpen}
        />
      </Surface>
    </>
  );
}
