import React, { ReactNode } from 'react';
import styles from './Imports.module.scss';
import { FileIcon } from 'src/packages/bit-ui/icons';
import vars from 'src/export.scss';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'src/components/Router';

type SupportedImports = {
  slug: string;
  title: string;
  icon: ReactNode;
};

const imports: SupportedImports[] = [
  {
    slug: 'xml',
    title: 'XML',
    icon: <FileIcon size={40} color={vars.textPrimary} />,
  },
  {
    slug: 'csv',
    title: 'CSV',
    icon: <FileIcon size={40} color={vars.textPrimary} />,
  },
  {
    slug: 'gherkin',
    title: 'Gherkin',
    icon: <FileIcon size={40} color={vars.textPrimary} />,
  },
];

export default function Imports() {
  const { site, projectId } = useParams<typeof routes.PROJECT.params>();

  return (
    <div className={styles.imports}>
      {imports.map((imp) => (
        <Link
          to={
            projectId
              ? routes.IMPORT_SLUG({
                  site,
                  slug: imp.slug,
                  projectId,
                })
              : routes.IMPORT_DATA({
                  site,
                  slug: imp.slug,
                })
          }
          className={styles.imp}
          key={imp.title}>
          {imp.icon}
          <div className={styles.name}>{imp.title}</div>
        </Link>
      ))}
    </div>
  );
}
