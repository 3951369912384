/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Suite,
  SuiteApi,
  suiteGetMany,
  suiteGetOne,
  suiteDeleteOne,
  suiteUpdateOne,
  suiteCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { suiteNormalizer } from './suiteNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const suiteFetchManyThunk = createAsyncThunk(
  'suite/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Suite>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await suiteGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, suiteNormalizer);
      }
      return {
        suite: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const suiteFetchOneThunk = createAsyncThunk(
  'suite/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Suite>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'suite/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await suiteGetOne(arg.id as number, arg);
      return executeNormalizer([data], suiteNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const suiteDeleteOneThunk = createAsyncThunk(
  'suite/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Suite>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'suite/DeleteOneThunk');
      }
      return await suiteDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const suiteUpdateOneThunk = createAsyncThunk(
  'suite/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<SuiteApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'suite/UpdateOneThunk');
      }
      const data = await suiteUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Suite>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Suite>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const suiteCreateOneThunk = createAsyncThunk(
  'suite/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<SuiteApi>, { dispatch }) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'suite/CreateOneThunk',
        );
      }
      const value = await suiteCreateOne(arg.data, arg);
      dispatch(suiteFetchOneThunk({ id: value.id, params: { _with: 'plan' } }));
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
