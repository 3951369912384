/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { LabelAssignedHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { labelAssignedHistoryAdapter } from './labelAssignedHistoryAdapter';
import { labelAssignedHistoryFetchThunk } from './labelAssignedHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const labelAssignedHistorySlice = createSlice({
  name: 'labelAssignedHistory',
  initialState: labelAssignedHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    labelAssignedHistoryCleared(state) {
      state.loading = 'idle';
      labelAssignedHistoryAdapter.removeAll(state);
    },
    labelAssignedHistoryUpsertMany(
      state,
      action: PayloadAction<
        LabelAssignedHistory[] | Record<number, LabelAssignedHistory>
      >,
    ) {
      labelAssignedHistoryAdapter.upsertMany(state, action.payload);
    },
    labelAssignedHistoryUpsertOne(
      state,
      action: PayloadAction<LabelAssignedHistory>,
    ) {
      labelAssignedHistoryAdapter.upsertOne(state, action.payload);
    },
    labelAssignedHistoryUpdateOne(
      state,
      action: PayloadAction<Update<LabelAssignedHistory>>,
    ) {
      labelAssignedHistoryAdapter.updateOne(state, action.payload);
    },
    labelAssignedHistoryRemoveOne(state, action: PayloadAction<number>) {
      labelAssignedHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(labelAssignedHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      labelAssignedHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        labelAssignedHistoryAdapter.upsertMany(
          state,
          action.payload.labelAssignedHistory,
        );
      },
    );
    builder.addCase(labelAssignedHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  labelAssignedHistoryCleared,
  labelAssignedHistoryUpsertMany,
  labelAssignedHistoryUpsertOne,
  labelAssignedHistoryUpdateOne,
  labelAssignedHistoryRemoveOne,
} = labelAssignedHistorySlice.actions;

export const labelAssignedHistoryReducer = labelAssignedHistorySlice.reducer;
