import { planGetMany, suiteGetMany } from '@testquality/sdk';
import React, { useCallback } from 'react';
import { Select } from '@bitmodern/bit-ui';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import useFetch from 'src/hooks/useFetch';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import { CSVFormikType, FormikType } from '../FormikType';
import styles from './ExtraFields.module.scss';

type Props = {
  extraFields: {
    project: boolean;
    plan: boolean;
    suite: boolean;
    projectId?: number;
  };
  formik: FormikType | CSVFormikType;
};

function ExtraFields({ extraFields, formik }: Props) {
  const { t } = useTranslation();

  const projectId =
    formik.values.extraFields?.project_id || extraFields.projectId;
  const projects = useAppSelector(projectsSelector);

  const fetchPlans = useCallback(() => {
    if (!projectId) return Promise.reject();
    return planGetMany({
      params: { project_id: projectId, per_page: -1 },
    }).then((res) => res.data);
  }, [projectId]);

  const plansFetch = useFetch(
    fetchPlans,
    `projectPlans-${formik.values.extraFields?.project_id}`,
    { initialData: [] },
  );

  const fetchSuites = useCallback(() => {
    if (!projectId) return Promise.reject();
    return suiteGetMany({
      params: { project_id: projectId, per_page: -1 },
    }).then((res) => res.data);
  }, [projectId]);

  const suitesFetch = useFetch(
    fetchSuites,
    `suitesPlan-${formik.values.extraFields?.project_id}`,
    { initialData: [] },
  );

  const onChange = (item: string, idItem: number) => {
    formik.setFieldValue('extraFields', {
      ...formik.values.extraFields,
      [`${item}_id`]: idItem,
    });
  };

  const getOptions = (elements: any[]) =>
    elements.map((element) => ({ label: element.name, value: element.id }));

  return (
    <div className={styles.container}>
      {extraFields.project && (
        <Select
          error={formikError(formik, 'extraFields')}
          label={t('importDataForm.csv.extraFields.project')}
          onChange={(event) => onChange('project', event)}
          placeholder={t('testCreate.form.projectPlaceholder')}
          required
          options={getOptions(projects)}
          value={formik.values.extraFields?.project_id}
          fullWidth
        />
      )}
      {extraFields.plan && (
        <Select
          loading={plansFetch.isLoading}
          label={t('importDataForm.csv.extraFields.cycle')}
          placeholder={t('testCreate.form.plansPlaceholder')}
          onChange={(event) => onChange('plan', event)}
          options={getOptions(plansFetch.data || [])}
          value={formik.values.extraFields?.plan_id}
          fullWidth
        />
      )}
      {extraFields.suite && (
        <Select
          loading={suitesFetch.isLoading}
          label={t('importDataForm.csv.extraFields.folder')}
          onChange={(event) => onChange('suite', event)}
          options={getOptions(suitesFetch.data || [])}
          placeholder={t('testCreate.form.suitesPlaceholder')}
          value={formik.values.extraFields?.suite_id}
          fullWidth
        />
      )}
    </div>
  );
}

export default ExtraFields;
