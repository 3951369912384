/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { nativeDefectStatusAdapter } from './nativeDefectStatusAdapter';

export const nativeDefectStatusSelectors =
  nativeDefectStatusAdapter.getSelectors(
    (state: State) => state.gen.nativeDefectStatus,
  );
