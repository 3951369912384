import { useRouteMatch, matchPath } from 'react-router';
import usePathname from './usePathname';

export default function useParams<
  Params extends { [K in keyof Params]?: string } = {},
>(): Params {
  const pathname = usePathname();
  const m = useRouteMatch();
  const match = matchPath<Params>(pathname, {
    path: m.path,
  });

  return match?.params || ({} as Params);
}
