import React, { ComponentProps, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import Yup from 'src/utils/yup';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { planCreateOneThunk } from 'src/gen/domain/plan/planThunk';
import { routes } from 'src/components/Router';
import { BatchService } from '@testquality/sdk';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import {
  virtualsByTableNameSelector,
  requiredVirtualsByTableNameSelector,
} from '@bitmodern/redux/state/virtuals/selectors';
import { planSuiteTestIncludeCreateOneThunk } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import isEmpty from 'lodash/isEmpty';
import {
  includeSuiteInPlanThunk,
  includeTestInPlanThunk,
} from '@bitmodern/redux/state/planSuitesTestsIncludes/thunks';
import {
  buildEmptyVirtuals,
  buildRequiredVirtualsValidationSchema,
  buildRequiredVirtualsInitialValues,
} from '../TestCreateView/TestCreateViewContainer';
import PlanCreateView from './PlanCreateView';

type Values = ComponentProps<typeof PlanCreateView>['formik']['values'];

type Props = {
  projectId: number;
  runId?: number;
};

export default function PlanCreateViewContainer({ projectId, runId }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { site } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  const runResults = useAppSelector((state) =>
    runResultSelectors
      .selectAll(state)
      .filter((runResult) => runResult.run_id === runId),
  );

  const virtuals = useAppSelector((state) =>
    virtualsByTableNameSelector(state, 'plan'),
  );

  const requiredVirtuals = useAppSelector((state) =>
    requiredVirtualsByTableNameSelector(state, 'plan'),
  );

  const requiredVirtualsValidationSchema =
    buildRequiredVirtualsValidationSchema(requiredVirtuals);

  const initialValues: Values = {
    name: '',
    includes: [],
    ...(!isEmpty(requiredVirtuals) && {
      ...buildRequiredVirtualsInitialValues(requiredVirtuals),
    }),
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...requiredVirtualsValidationSchema,
        name: Yup.string().required().label('planCreate.form.name'),
      }),
    [requiredVirtualsValidationSchema],
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  async function onSubmit(values: Values) {
    const { name, ...rest } = values;
    const plan = await dispatch(
      planCreateOneThunk({
        data: {
          name,
          project_id: projectId,
          ...(!isEmpty(virtuals) && {
            virtual: { ...buildEmptyVirtuals(virtuals), ...rest },
          }),
        },
      }),
    ).then(unwrapResult);

    if (values.includes.length) {
      await dispatch(
        includeTestInPlanThunk(
          plan.id,
          values.includes
            .filter((item) => item.type === TEST_TYPE)
            .map((item) => item.data.suiteTest),
        ),
      );
      await dispatch(
        includeSuiteInPlanThunk(
          plan.id,
          values.includes
            .filter((item) => item.type === SUITE_TYPE)
            .map((item) => item.data.suite),
        ),
      );
    }

    if (runResults) {
      const batch = new BatchService();
      runResults.forEach((rr) => {
        dispatch(
          planSuiteTestIncludeCreateOneThunk({
            data: {
              project_id: projectId,
              plan_id: plan.id,
              suite_id: rr.suite_id,
              test_id: rr.test_id,
            },
            batch,
          }),
        );
      });
      batch.executeBatch();
    }

    history.push(
      routes.PLAN({
        site,
        projectId: projectId.toString(),
        planId: plan.id.toString(),
      }),
    );
  }

  return (
    <PlanCreateView
      formik={formik}
      withTree={!runResults.length}
      virtuals={requiredVirtuals}
    />
  );
}
