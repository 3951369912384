import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { Dialog } from '@bitmodern/bit-ui';
import { useAppDispatch } from '@bitmodern/redux/store';
import { routes } from 'src/components/Router';
import { createProjectThunk } from '@bitmodern/redux/state/projects/thunks';
import { ProjectForm } from 'src/components/organisms';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';

export default function ProjectCreate() {
  const { site } = useParams<typeof routes.PROJECT_CREATE.params>();
  const { hideModal, isModal } = useModalManager();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const createProject = useCallback(
    (values) => {
      dispatch(createProjectThunk({ values })).then(({ project }) => {
        history.push(
          routes.PROJECT_TESTS({ site, projectId: project.id.toString() }),
        );
      });
    },
    [dispatch, history, site],
  );

  const onClose = useCallback(() => {
    if (isModal) {
      hideModal();
    } else {
      history.push(routes.HOME({ site }));
    }
  }, [isModal, hideModal, history, site]);

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={onClose}
      size="medium"
      title={t('projectCreate.title')}>
      <ProjectForm onSubmit={createProject} />
    </Dialog>
  );
}
