import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <defs>
      <path id="d" d="M16 144h112v37H16z" />
      <path id="e" d="M0 0h25v24H0z" />
      <path id="g" d="M0 0h25v24H0z" />
      <filter
        x="-51.8%"
        y="-151.4%"
        width="203.6%"
        height="413.5%"
        filterUnits="objectBoundingBox"
        id="c">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={19}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.128032124 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x={0} y={0} width={140} height={140} rx={10} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#F1F3F9" width={164} height={164} rx={16} />
      <g transform="translate(12 12)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FFF" xlinkHref="#a" />
        <rect
          strokeOpacity={0.25}
          stroke="#676767"
          strokeWidth={3}
          fill="#F1F3F9"
          mask="url(#b)"
          x={21}
          y={28}
          width={101}
          height={153}
          rx={8}
        />
        <g mask="url(#b)">
          <use fill="#000" filter="url(#c)" xlinkHref="#d" />
          <use fill="#D8D8D8" xlinkHref="#d" />
        </g>
      </g>
      <g transform="translate(118 30)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          d="M12.508.032c1.52-.238 1.875.901 2.31 2.084 1.46 3.974 4.213 6.64 8.45 7.487 1.693.339 1.729 1.36 1.732 2.604.005 1.2-.327 2.102-1.759 2.144-4.853.143-6.66 3.696-8.353 7.346-.492 1.063-.61 2.282-2.294 2.303-1.61.019-1.926-.974-2.426-2.128-1.663-3.833-3.612-7.414-8.625-7.55-1.217-.032-1.563-.884-1.53-1.919.035-1.015-.315-2.36 1.14-2.63 4.98-.925 7.886-3.985 9.366-8.597.298-.928 1.01-1.157 1.989-1.144"
          fill="#8FB694"
          mask="url(#f)"
        />
      </g>
      <g transform="translate(20 115)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path
          d="M12.508.032c1.52-.238 1.875.901 2.31 2.084 1.46 3.974 4.213 6.64 8.45 7.487 1.693.339 1.729 1.36 1.732 2.604.005 1.2-.327 2.102-1.759 2.144-4.853.143-6.66 3.696-8.353 7.346-.492 1.063-.61 2.282-2.294 2.303-1.61.019-1.926-.974-2.426-2.128-1.663-3.833-3.612-7.414-8.625-7.55-1.217-.032-1.563-.884-1.53-1.919.035-1.015-.315-2.36 1.14-2.63 4.98-.925 7.886-3.985 9.366-8.597.298-.928 1.01-1.157 1.989-1.144"
          fill="#8FB694"
          mask="url(#h)"
        />
      </g>
      <rect fill="#D6D8E1" x={45} y={59} width={78} height={12} rx={4} />
      <rect fill="#D6D8E1" x={45} y={101} width={78} height={12} rx={4} />
      <rect fill="#8FB694" x={43} y={77} width={82} height={18} rx={4} />
    </g>
  </>,
  'ProjectNewLight',
  {},
  164,
  164,
);
