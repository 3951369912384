/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { NativeDefectRes } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { nativeDefectResAdapter } from './nativeDefectResAdapter';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
  nativeDefectResDeleteOneThunk,
  nativeDefectResUpdateOneThunk,
  nativeDefectResCreateOneThunk,
} from './nativeDefectResThunk';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
} from '../defect_res/defectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const nativeDefectResSlice = createSlice({
  name: 'nativeDefectRes',
  initialState: nativeDefectResAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    nativeDefectResCleared(state) {
      state.loading = 'idle';
      nativeDefectResAdapter.removeAll(state);
    },
    nativeDefectResUpsertMany(
      state,
      action: PayloadAction<
        NativeDefectRes[] | Record<number, NativeDefectRes>
      >,
    ) {
      nativeDefectResAdapter.upsertMany(state, action.payload);
    },
    nativeDefectResUpsertOne(state, action: PayloadAction<NativeDefectRes>) {
      nativeDefectResAdapter.upsertOne(state, action.payload);
    },
    nativeDefectResUpdateOne(
      state,
      action: PayloadAction<Update<NativeDefectRes>>,
    ) {
      nativeDefectResAdapter.updateOne(state, action.payload);
    },
    nativeDefectResRemoveOne(state, action: PayloadAction<number>) {
      nativeDefectResAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nativeDefectResFetchManyThunk.pending, handlePending);
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      },
    );
    builder.addCase(nativeDefectResFetchManyThunk.rejected, handleRejected);
    builder.addCase(nativeDefectResFetchOneThunk.pending, handlePending);
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      nativeDefectResAdapter.upsertOne(
        state,
        action.payload.nativeDefectRes[0],
      );
    });
    builder.addCase(nativeDefectResFetchOneThunk.rejected, handleRejected);
    builder.addCase(nativeDefectResDeleteOneThunk.pending, handlePending);
    builder.addCase(
      nativeDefectResDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          nativeDefectResAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(nativeDefectResDeleteOneThunk.rejected, handleRejected);
    builder.addCase(nativeDefectResUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, nativeDefectResAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      nativeDefectResUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectResAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(nativeDefectResUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          nativeDefectResAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(nativeDefectResCreateOneThunk.pending, handlePending);
    builder.addCase(
      nativeDefectResCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectResAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(nativeDefectResCreateOneThunk.rejected, handleRejected);
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectRes) {
          nativeDefectResAdapter.upsertMany(
            state,
            action.payload.nativeDefectRes,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectRes) {
          nativeDefectResAdapter.upsertMany(
            state,
            action.payload.nativeDefectRes,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectRes) {
          nativeDefectResAdapter.upsertMany(
            state,
            action.payload.nativeDefectRes,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectRes) {
        nativeDefectResAdapter.upsertMany(
          state,
          action.payload.nativeDefectRes,
        );
      }
    });
  },
});

export const {
  nativeDefectResCleared,
  nativeDefectResUpsertMany,
  nativeDefectResUpsertOne,
  nativeDefectResUpdateOne,
  nativeDefectResRemoveOne,
} = nativeDefectResSlice.actions;

export const nativeDefectResReducer = nativeDefectResSlice.reducer;
