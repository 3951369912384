import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpError, showNotificationError } from '@testquality/sdk';
import { ThunkArgs } from 'src/gen/actions/ThunkArgs';
import { AppThunk } from '@bitmodern/redux/store';
import { getClient } from 'src/Client';
import { clearFetchCache } from 'src/hooks/useFetch';

export function logoutThunk(): AppThunk<void> {
  return (dispatch) => {
    clearFetchCache();
    getClient().getAuth().logout();
    dispatch({ type: 'RESET_STATE' });
  };
}

export const emailVerificationCheckThunk = createAsyncThunk(
  'auth/emailVerificationCheck',
  async ({
    handleError = true,
    data,
    params,
  }: ThunkArgs<{
    verificationToken: string;
    email: string;
  }>) => {
    try {
      if (!params?.verificationToken || !data?.email) {
        throw new Error('Must supply email and token');
      }
      const { api } = getClient();
      return await api({
        method: 'post',
        url: `email-verification/check/${params.verificationToken}`,
        data,
      });
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const resendEmailVerificationThunk = createAsyncThunk(
  'auth/resendEmailVerification',
  async ({ handleError = true }: ThunkArgs<void>) => {
    try {
      const { api } = getClient();
      return await api({
        url: 'system/resend_account_verification',
        params: { is_web: true },
      });
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
