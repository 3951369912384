import { useMemo } from 'react';
import { State, useAppSelector } from './store';

type Params = { [key: string]: any };
type Selector<P extends Params> = (state: State, params: P) => unknown;

export function useMaker<P extends Params, S extends Selector<P>>(
  maker: () => S,
  params: Parameters<S>[1],
): ReturnType<S> {
  const selector = useMemo(maker, [maker]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parameters = useMemo(() => params, Object.values(params));
  const selected = useAppSelector((state) => selector(state, parameters));
  return selected as ReturnType<S>; // try with satisfies someday
}

export function useParamSelector<P extends Params, S extends Selector<P>>(
  selector: S,
  params: Parameters<S>[1],
): ReturnType<S> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parameters = useMemo(() => params, Object.values(params));
  const selected = useAppSelector((state) => selector(state, parameters));
  return selected as ReturnType<S>; // try with satisfies someday
}
