/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Alert, Loading, RadioGroup, RadioIconText } from '@bitmodern/bit-ui';
import { SettingsPanel } from 'src/components/organisms';
import { tokenSelector } from '@bitmodern/redux/state/authentication/selectors';
import { useTranslation } from 'src/i18n/hooks';
import { InfoIcon, SubscriptionsIcon } from '@bitmodern/bit-ui/icons';
import {
  activeMarketplaceSubscriptionsSelector,
  activeOpenSourceSubscriptionSelector,
  commercialSubscriptionsSelector,
} from '@bitmodern/redux/state/subscriptions/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import vars from 'src/export.scss';
import CommercialSubscription from './CommercialSubscription';
import OpenSourceSubscription from './OpenSourceSubscription';
import Subscription from './Subscription';
import MarketplaceSubscription from './MarketplaceSubscription';
import styles from './BillingView.module.scss';

const TYPE_COMMERCIAL = 'commercial';
const TYPE_OPEN_SOURCE = 'openSource';
const TYPE_MARKETPLACE = 'marketPlace';

type Props = {
  loading: boolean;
};

export default function BillingView({ loading }: Props) {
  const { t } = useTranslation();
  const [type, setType] = useState(TYPE_COMMERCIAL);
  const token = useAppSelector(tokenSelector);

  const commercialSubscriptions = useAppSelector(
    commercialSubscriptionsSelector,
  );
  const activeOpenSourceSubscription = useAppSelector(
    activeOpenSourceSubscriptionSelector,
  );
  const activeMarketplaceSubscription = useAppSelector(
    activeMarketplaceSubscriptionsSelector,
  );

  console.log('activeMarketplaceSubscription', activeMarketplaceSubscription);
  useEffect(() => {
    if (activeOpenSourceSubscription) {
      setType(TYPE_OPEN_SOURCE);
    } else if (
      activeMarketplaceSubscription &&
      activeMarketplaceSubscription.length > 0
    ) {
      setType(TYPE_MARKETPLACE);
    }
  }, [activeOpenSourceSubscription, activeMarketplaceSubscription]);

  const isExpired = Boolean(token?.is_expired);

  let expiredAlert;
  if (isExpired && token && !token.github_open_source_subscription_invalid) {
    const sType = token.subscription_ended_at
      ? t('billing.subscription')
      : t('billing.trial');
    let endedAt = 'unknown';
    if (token.trial_ended_at) {
      endedAt = format(new Date(token.trial_ended_at), 'MMM d, yyyy');
    } else if (token.subscription_ended_at) {
      const pos = token.subscription_ended_at.indexOf(' 00:00');
      let subscriptionEndedAt = token.subscription_ended_at;
      if (pos > -1) {
        subscriptionEndedAt = subscriptionEndedAt.substring(0, pos);
      }
      endedAt = format(new Date(subscriptionEndedAt), 'MMM d, yyyy');
    }
    expiredAlert = (
      <Alert
        className={styles.billingAlert}
        icon={<InfoIcon color={vars.textPrimary} size={24} />}
        title={t('billing.expired', { type: sType })}>
        {t('billing.expiredDescription', { type: sType, endedAt })}
      </Alert>
    );
  }
  let content = (
    <div className={styles.loading}>
      <Loading size={40} />
    </div>
  );

  if (!loading) {
    content = (
      <>
        {expiredAlert}
        <RadioGroup
          name="type"
          className={styles.radioGroup}
          onChange={setType}
          orientation="horizontal"
          value={type}>
          <RadioIconText value={TYPE_COMMERCIAL}>
            {t('billing.types.commercial')}
          </RadioIconText>
          {activeOpenSourceSubscription && (
            <RadioIconText value={TYPE_OPEN_SOURCE}>
              {t('billing.types.openSource')}
            </RadioIconText>
          )}
          <RadioIconText value={TYPE_MARKETPLACE}>
            {t('billing.types.marketPlace')}
          </RadioIconText>
        </RadioGroup>
        {type === TYPE_COMMERCIAL ? (
          commercialSubscriptions.length ? (
            <Subscription />
          ) : (
            <CommercialSubscription />
          )
        ) : null}
        {type === TYPE_OPEN_SOURCE && <OpenSourceSubscription />}
        {type === TYPE_MARKETPLACE && <MarketplaceSubscription />}
      </>
    );
  }

  return (
    <SettingsPanel
      icon={<SubscriptionsIcon color={vars.textPrimary} size={24} />}
      title={t('billing.title')}>
      {content}
    </SettingsPanel>
  );
}
