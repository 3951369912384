/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { PolicyRows } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { policyRowsAdapter } from './policyRowsAdapter';
import {
  policyRowsFetchManyThunk,
  policyRowsFetchOneThunk,
  policyRowsDeleteOneThunk,
  policyRowsUpdateOneThunk,
  policyRowsCreateOneThunk,
} from './policyRowsThunk';
import {
  policyFetchManyThunk,
  policyFetchOneThunk,
} from '../policy/policyThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const policyRowsSlice = createSlice({
  name: 'policyRows',
  initialState: policyRowsAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    policyRowsCleared(state) {
      state.loading = 'idle';
      policyRowsAdapter.removeAll(state);
    },
    policyRowsUpsertMany(
      state,
      action: PayloadAction<PolicyRows[] | Record<number, PolicyRows>>,
    ) {
      policyRowsAdapter.upsertMany(state, action.payload);
    },
    policyRowsUpsertOne(state, action: PayloadAction<PolicyRows>) {
      policyRowsAdapter.upsertOne(state, action.payload);
    },
    policyRowsUpdateOne(state, action: PayloadAction<Update<PolicyRows>>) {
      policyRowsAdapter.updateOne(state, action.payload);
    },
    policyRowsRemoveOne(state, action: PayloadAction<number>) {
      policyRowsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(policyRowsFetchManyThunk.pending, handlePending);
    builder.addCase(policyRowsFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyRowsAdapter.upsertMany(state, action.payload.policyRows);
    });
    builder.addCase(policyRowsFetchManyThunk.rejected, handleRejected);
    builder.addCase(policyRowsFetchOneThunk.pending, handlePending);
    builder.addCase(policyRowsFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyRowsAdapter.upsertOne(state, action.payload.policyRows[0]);
    });
    builder.addCase(policyRowsFetchOneThunk.rejected, handleRejected);
    builder.addCase(policyRowsDeleteOneThunk.pending, handlePending);
    builder.addCase(policyRowsDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        policyRowsAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(policyRowsDeleteOneThunk.rejected, handleRejected);
    builder.addCase(policyRowsUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, policyRowsAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(policyRowsUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyRowsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(policyRowsUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          policyRowsAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(policyRowsCreateOneThunk.pending, handlePending);
    builder.addCase(policyRowsCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyRowsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(policyRowsCreateOneThunk.rejected, handleRejected);
    builder.addCase(policyFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(policyFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.policyRows) {
          policyRowsAdapter.upsertMany(state, action.payload.policyRows);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.policyRows) {
          policyRowsAdapter.upsertMany(state, action.payload.policyRows);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.policyRows) {
          policyRowsAdapter.upsertMany(state, action.payload.policyRows);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policyRows) {
        policyRowsAdapter.upsertMany(state, action.payload.policyRows);
      }
    });
  },
});

export const {
  policyRowsCleared,
  policyRowsUpsertMany,
  policyRowsUpsertOne,
  policyRowsUpdateOne,
  policyRowsRemoveOne,
} = policyRowsSlice.actions;

export const policyRowsReducer = policyRowsSlice.reducer;
