import { BatchService } from '@testquality/sdk';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { routes } from 'src/components/Router';
import {
  commentCreateOneThunk,
  commentDeleteOneThunk,
  commentUpdateOneThunk,
} from 'src/gen/domain/comment/commentThunk';
import { runResultFetchManyThunk } from 'src/gen/domain/run_result/runResultThunk';
import { stepHistoryFetchThunk } from 'src/gen/domain/step/stepHistoryThunk';
import { testHistoryFetchThunk } from 'src/gen/domain/test/testHistoryThunk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useFetch from 'src/hooks/useFetch';
import useParams from 'src/hooks/useParams';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { testActivitySelector } from '@bitmodern/redux/state/activity/selectors';
// import { defectsByRunResultsSelector } from '@bitmodern/redux/state/defects/selectors';
import { requirementsByTestIdSelector } from '@bitmodern/redux/state/requirements/selectors';
import { stepsByTestSelector } from '@bitmodern/redux/state/steps/selectors';
import { deleteTestThunk } from '@bitmodern/redux/state/tests/thunks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useIntegrationCheck } from '../../../hooks/useIntegrationCheck';
import Test from './Test';
import { testSelectors } from '../../../gen/domain/test/testSelector';
import { defectsByTestSelector } from 'src/packages/redux/state/defects/selectors';
// import { runResultsByTestSelector } from 'src/packages/redux/state/runResults/selectors';

type Props = {
  testId: number;
};

export default function TestContainer({ testId }: Props) {
  const { site, projectId } = useParams<typeof routes.PLAN.params>();
  const { closeDrawer } = useDrawerManager();
  const dispatch = useAppDispatch();
  const { openCreateRequirement } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const test = useAppSelector((state) =>
    testSelectors.selectById(state, testId),
  );
  const steps = useAppSelector((state) =>
    stepsByTestSelector(state, { testId }),
  );
  const requirements = useAppSelector((state) =>
    requirementsByTestIdSelector(state, testId),
  );
  const defects = useAppSelector((state) =>
    defectsByTestSelector(state, { testId }),
  );
  const testActivity = useAppSelector((state) =>
    testActivitySelector(state, testId),
  );

  useEffect(() => {
    if (!test) {
      closeDrawer();
    }
  }, [closeDrawer, test]);

  const request = useCallback(() => {
    const batch = new BatchService();
    dispatch(
      runResultFetchManyThunk({
        batch,
        params: {
          test_id: testId,
          project_id: parseInt(projectId, 10),
          per_page: -1,
        },
      }),
    );
    dispatch(
      testHistoryFetchThunk({
        params: { id: testId, project_id: parseInt(projectId, 10) },
        batch,
      }),
    );
    dispatch(
      stepHistoryFetchThunk({
        params: { project_id: parseInt(projectId, 10), test_id: testId },
        batch,
      }),
    );
    return batch.executeBatch();
  }, [dispatch, testId, projectId]);

  const { isLoading } = useFetch(request, `Test-${testId}`, {
    fetchOnce: true,
  });

  const onEditComment = (comment) => {
    dispatch(
      commentUpdateOneThunk({
        id: comment.id,
        data: comment,
        optimistic: true,
      }),
    );
    return Promise.resolve();
  };

  const onDeleteComment = (commentId: number) => {
    return dispatch(commentDeleteOneThunk({ id: commentId }));
  };

  const onClose = () => {
    closeDrawer();
  };

  const onDelete = () => {
    if (!test) return Promise.resolve();
    return dispatch(deleteTestThunk(test.id)).then(closeDrawer);
  };

  const onComment = (comment: string) => {
    if (!test) return;
    dispatch(
      commentCreateOneThunk({
        data: {
          body: comment,
          related_id: test.id,
          related_type: RelatedType.TEST,
        },
      }),
    );
  };

  const onAddRequirement = () => {
    if (test) {
      return openCreateRequirement(test.id);
    }
    return Promise.resolve();
  };

  if (!test) return null;

  return (
    <>
      <Helmet>
        <title>{test.name}</title>
      </Helmet>
      <Test
        activity={testActivity}
        defects={defects}
        loadingRuns={isLoading}
        onAddRequirement={onAddRequirement}
        onClose={onClose}
        onComment={onComment}
        onDelete={onDelete}
        onDeleteComment={onDeleteComment}
        onEditComment={onEditComment}
        requirements={requirements}
        steps={steps}
        test={test}
      />
    </>
  );
}
