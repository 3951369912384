/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { DefectApi } from '@testquality/sdk';
import { DefectRunResult } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { defectRunResultNormalizer } from '../defect_run_result/defectRunResultNormalize';

export const defectNormalizer = (model: DefectApi, cache: NormalizerCache) => {
  if (!cache.defect) {
    cache.defect = [];
  }
  cache.defect.push(model);

  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      defectRunResultNormalizer(child.pivot as DefectRunResult, cache);
      child.pivot = undefined;
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
};
