/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { ProjectHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { projectHistoryAdapter } from './projectHistoryAdapter';
import { projectHistoryFetchThunk } from './projectHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const projectHistorySlice = createSlice({
  name: 'projectHistory',
  initialState: projectHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    projectHistoryCleared(state) {
      state.loading = 'idle';
      projectHistoryAdapter.removeAll(state);
    },
    projectHistoryUpsertMany(
      state,
      action: PayloadAction<ProjectHistory[] | Record<number, ProjectHistory>>,
    ) {
      projectHistoryAdapter.upsertMany(state, action.payload);
    },
    projectHistoryUpsertOne(state, action: PayloadAction<ProjectHistory>) {
      projectHistoryAdapter.upsertOne(state, action.payload);
    },
    projectHistoryUpdateOne(
      state,
      action: PayloadAction<Update<ProjectHistory>>,
    ) {
      projectHistoryAdapter.updateOne(state, action.payload);
    },
    projectHistoryRemoveOne(state, action: PayloadAction<number>) {
      projectHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectHistoryFetchThunk.pending, handlePending);
    builder.addCase(projectHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      projectHistoryAdapter.upsertMany(state, action.payload.projectHistory);
    });
    builder.addCase(projectHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  projectHistoryCleared,
  projectHistoryUpsertMany,
  projectHistoryUpsertOne,
  projectHistoryUpdateOne,
  projectHistoryRemoveOne,
} = projectHistorySlice.actions;

export const projectHistoryReducer = projectHistorySlice.reducer;
