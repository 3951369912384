import { Filter } from '@testquality/sdk';
import { isSameDay } from 'date-fns';
import { createReducer } from '@reduxjs/toolkit';
import { TestsFiltersKeys, entities } from '../filters/reducer';
import { clearAllFiltersAction, toggleFilterAction } from '../filters/actions';
import { applyCustomFilterAction, breakCustomFilterAction } from './actions';

type State = Record<TestsFiltersKeys, Filter | null>;

const initialState: State = {
  plans: null,
  tests: null,
  runs: null,
  analyze: null,
  analyzeTestQuality: null,
  requirements: null,
};

/* NOTE: This reducer keeps stores the custom filter being applied */
/* to each one of the different parts of the app where we have a tree */

export default createReducer(initialState, (builder) => {
  builder.addCase(applyCustomFilterAction, (state, { payload }) => {
    const { filter: customFilter, type } = payload;
    state[type] = customFilter;
  });

  builder.addCase(breakCustomFilterAction, (state, { payload }) => {
    state[payload.type] = initialState[payload.type];
  });

  builder.addCase(clearAllFiltersAction, (state, { payload }) => {
    state[payload.type] = null;
  });

  /* NOTE: Break custom filter in case 1 of its filters is removed/changed */
  builder.addCase(
    toggleFilterAction,
    (state, { payload: { type, filter, value } }) => {
      const currentFilterValue = state[type] && state[type].payload[filter];
      if (!currentFilterValue) return;

      if (entities.includes(String(filter))) {
        // obj entities
        if (currentFilterValue[value]) {
          state[type] = initialState[type];
        }
      } else if (value instanceof Date) {
        // date entities
        if (!isSameDay(value, new Date(currentFilterValue))) {
          state[type] = initialState[type];
        }
      } else if (currentFilterValue !== value) {
        // primitives entities
        state[type] = initialState[type];
      }
    },
  );
});
