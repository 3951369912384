export enum SignUpType {
  SSO = 1,
  GITHUB = 2,
  JIRA = 3,
  DOMAIN = 4,
  GOOGLE = 5,
}

export const SignUpTypeDisplay: ReadonlyMap<SignUpType, string> = new Map([
  [SignUpType.SSO, 'signupType.sso'],
  [SignUpType.GITHUB, 'signupType.github'],
  [SignUpType.JIRA, 'signupType.jira'],
  [SignUpType.DOMAIN, 'signupType.domain'],
  [SignUpType.GOOGLE, 'signupType.google'],
]);
