import React, { useCallback, useMemo, useState } from 'react';
import { Button, Tab, Tabs } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { statusesSelector } from '@bitmodern/redux/state/statuses/selectors';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  ConfirmDialog,
  CustomValue,
  CustomValueDialog,
  SettingsPanel,
} from 'src/components/organisms';
import { pursposesSelector } from '@bitmodern/redux/state/purpuses/selectors';
import { useOverlayTriggerState } from 'react-stately';
import {
  statusCreateOneThunk,
  statusDeleteOneThunk,
  statusUpdateOneThunk,
} from 'src/gen/domain/status/statusThunk';
import {
  purposeCreateOneThunk,
  purposeDeleteOneThunk,
  purposeUpdateOneThunk,
} from 'src/gen/domain/purpose/purposeThunk';
import {
  caseTypeCreateOneThunk,
  caseTypeDeleteOneThunk,
  caseTypeUpdateOneThunk,
} from 'src/gen/domain/case_type/caseTypeThunk';
import {
  casePriorityCreateOneThunk,
  casePriorityDeleteOneThunk,
  casePriorityUpdateOneThunk,
} from 'src/gen/domain/case_priority/casePriorityThunk';
import {
  labelCreateOneThunk,
  labelDeleteOneThunk,
  labelUpdateOneThunk,
} from 'src/gen/domain/label/labelThunk';
import useMutation from 'src/hooks/useMutation';
import { LookupIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { statusTypePositiveSelector } from '@bitmodern/redux/state/statusTypes/selectors';
import styles from './LookupDataView.module.scss';

enum LookupTabs {
  Status,
  Purpose,
  CaseType,
  CasePriority,
  Label,
}

export default function LookupDataView() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(LookupTabs.Status);
  const [currentValue, setCurrentValue] = useState<any>();
  const customValue = useOverlayTriggerState({});
  const deleteCustomValue = useOverlayTriggerState({});
  const dispatch = useAppDispatch();

  const statuses = useAppSelector(statusesSelector);
  const purposes = useAppSelector(pursposesSelector);
  const labels = useAppSelector(labelsSelector);
  const caseTypes = useAppSelector(caseTypesSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const statusTypePossitive = useAppSelector(statusTypePositiveSelector);

  const current = useMemo(() => {
    if (!currentValue) return undefined;
    if (currentValue.label)
      return {
        name: currentValue.label,
        description: '',
        statusType: currentValue.statusType,
      };
    return {
      name: currentValue.name,
      description: currentValue.description || '',
      statusType: currentValue.statusType || statusTypePossitive?.id,
    };
  }, [currentValue, statusTypePossitive]);

  const customValueClose = useCallback(() => {
    setCurrentValue(undefined);
    customValue.close();
  }, [customValue]);

  const deleteCustomValueClose = useCallback(() => {
    setCurrentValue(undefined);
    deleteCustomValue.close();
  }, [deleteCustomValue]);

  const onCreate = async ({
    description,
    name,
    statusType = statusTypePossitive?.id,
  }) => {
    if (tab === LookupTabs.Status) {
      await dispatch(
        statusCreateOneThunk({
          data: { name, description, status_type_id: statusType },
        }),
      );
    } else if (tab === LookupTabs.Purpose) {
      await dispatch(
        purposeCreateOneThunk({
          data: { name, description, is_default: false },
        }),
      );
    } else if (tab === LookupTabs.CaseType) {
      await dispatch(
        caseTypeCreateOneThunk({
          data: { name, description, is_default: false },
        }),
      );
    } else if (tab === LookupTabs.CasePriority) {
      await dispatch(
        casePriorityCreateOneThunk({
          data: { name, description, is_default: false },
        }),
      );
    } else if (tab === LookupTabs.Label) {
      await dispatch(
        labelCreateOneThunk({
          data: { label: name },
        }),
      );
    }
    customValueClose();
  };

  const onEdit = async ({
    name,
    description,
    statusType = statusTypePossitive?.id,
  }) => {
    if (currentValue) {
      if (tab === LookupTabs.Status) {
        await dispatch(
          statusUpdateOneThunk({
            id: currentValue.id,
            data: { name, description, status_type_id: statusType },
          }),
        );
      } else if (tab === LookupTabs.Purpose) {
        await dispatch(
          purposeUpdateOneThunk({
            id: currentValue.id,
            data: { name, description },
          }),
        );
      } else if (tab === LookupTabs.CaseType) {
        await dispatch(
          caseTypeUpdateOneThunk({
            id: currentValue.id,
            data: { name, description },
          }),
        );
      } else if (tab === LookupTabs.CasePriority) {
        await dispatch(
          casePriorityUpdateOneThunk({
            id: currentValue.id,
            data: { name, description },
          }),
        );
      } else if (tab === LookupTabs.Label) {
        await dispatch(
          labelUpdateOneThunk({
            id: currentValue.id,
            data: { label: name },
          }),
        );
      }
    }
    customValueClose();
  };

  const onDelete = useCallback(async () => {
    if (currentValue) {
      const thunk = {
        [LookupTabs.Status]: statusDeleteOneThunk,
        [LookupTabs.Purpose]: purposeDeleteOneThunk,
        [LookupTabs.CaseType]: caseTypeDeleteOneThunk,
        [LookupTabs.CasePriority]: casePriorityDeleteOneThunk,
        [LookupTabs.Label]: labelDeleteOneThunk,
      }[tab];

      await dispatch(thunk({ id: currentValue.id }));
    }
    deleteCustomValueClose();
  }, [currentValue, deleteCustomValueClose, dispatch, tab]);

  const deleteValueMutation = useMutation(onDelete);

  const renderCustomValues = (
    values: Array<{
      name: string;
      description?: string;
      id: number;
      is_system?: boolean;
    }>,
  ) => {
    const system = values.filter((value) => value.is_system);
    const custom = values.filter((value) => !value.is_system);

    return (
      <>
        {system.length > 0 && (
          <div className={styles.type}>{t('lookup.system')}</div>
        )}
        <ul className={styles.list}>
          {system.map((value) => (
            <CustomValue
              description={value.description}
              key={value.id}
              name={value.name}
            />
          ))}
        </ul>
        <div className={styles.type}>{t('lookup.custom')}</div>
        <ul className={styles.list}>
          {custom.map((value) => (
            <CustomValue
              description={value.description}
              key={value.id}
              onDelete={() => {
                setCurrentValue(value);
                deleteCustomValue.open();
              }}
              onEdit={() => {
                setCurrentValue(value);
                customValue.open();
              }}
              name={value.name}
            />
          ))}
        </ul>
      </>
    );
  };

  return (
    <SettingsPanel
      paddingLess
      icon={<LookupIcon color={vars.textPrimary} size={24} />}
      title={t('lookup.title')}>
      <Tabs onChange={setTab} tab={tab} withBarBackground>
        <Tab id={LookupTabs.Status}>{t('lookup.tabs.status')}</Tab>
        <Tab id={LookupTabs.Purpose}>{t('lookup.tabs.purpose')}</Tab>
        <Tab id={LookupTabs.CaseType}>{t('lookup.tabs.caseType')}</Tab>
        <Tab id={LookupTabs.CasePriority}>{t('lookup.tabs.casePriority')}</Tab>
        <Tab id={LookupTabs.Label}>{t('lookup.tabs.label')}</Tab>
      </Tabs>
      {tab === LookupTabs.Status &&
        renderCustomValues(
          statuses.map((status) => ({
            name: status.name,
            description: status.description,
            id: status.id,
            statusType: status.status_type_id,
            is_system: status.is_system,
          })),
        )}
      {tab === LookupTabs.Purpose &&
        renderCustomValues(
          purposes.map((purpose) => ({
            name: purpose.name,
            description: purpose.description,
            id: purpose.id,
            is_system: purpose.is_system,
          })),
        )}
      {tab === LookupTabs.CaseType &&
        renderCustomValues(
          caseTypes.map((caseType) => ({
            name: caseType.name,
            description: caseType.description,
            id: caseType.id,
            is_system: caseType.is_system,
          })),
        )}
      {tab === LookupTabs.CasePriority &&
        renderCustomValues(
          casePriorities.map((casePriority) => ({
            name: casePriority.name,
            description: casePriority.description,
            id: casePriority.id,
            is_system: casePriority.is_system,
          })),
        )}
      {tab === LookupTabs.Label &&
        renderCustomValues(
          labels.map((label) => ({
            name: label.label,
            id: label.id,
          })),
        )}
      <Button className={styles.add} onClick={customValue.open}>
        {t('lookup.add')}
      </Button>
      <CustomValueDialog
        initialValues={current}
        isOpen={customValue.isOpen}
        onClose={customValueClose}
        onSubmit={current ? onEdit : onCreate}
        title={current ? t('lookup.form.edit') : t('lookup.form.create')}
        withDescription={tab !== LookupTabs.Label}
        withStatusType={tab === LookupTabs.Status}
      />
      <ConfirmDialog
        loading={deleteValueMutation.isLoading}
        open={deleteCustomValue.isOpen}
        onCancel={deleteCustomValueClose}
        onConfirm={deleteValueMutation.mutate}
        title={t('lookup.deleteValue.title')}>
        {t('lookup.deleteValue.content')}
      </ConfirmDialog>
    </SettingsPanel>
  );
}
