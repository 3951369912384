import React, { ReactElement, useCallback } from 'react';
import { Button } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import tqPreview from './testQuality.png';
import styles from './LearnMorePanel.module.scss';

export type LearnMorePanelProps = {
  title: string;
  text: string;
  icon?: ReactElement;
  url: string;
};

export function LearnMorePanel({
  title,
  text,
  icon,
  url,
}: LearnMorePanelProps) {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [url]);

  return (
    <div className={styles.main}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.images}>
        <img className={styles.tqPreview} src={tqPreview} />
      </div>
      <div className={styles.button}>
        <Button
          color="primaryLight"
          fullWidth
          onClick={onClick}
          size="large"
          type="submit">
          {t('getStarted.learnMoreButton')}
        </Button>
      </div>
    </div>
  );
}
