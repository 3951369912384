/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

/**
 * Takes Jira Markdown converts to GFM Markdown
 */
export function toM(input: string): string {
  input = input.replace(/^bq\.(.*)$/gm, function (match, content) {
    return `> ${content}\n`;
  });

  // multi-level numbered list
  input = input.replace(
    /^((?:#|-|\+|\*)+) (.*)$/gm,
    function (match, level: string, content: string) {
      let len = 0;
      if (level.length > 1) {
        len = (level.length - 1) * 2;
      }

      // take the last character of the level to determine the replacement
      let prefix = level[level.length - 1];
      if (prefix === '#') {
        prefix = '1.';
      }

      return `${' '.repeat(len) + prefix} ${content}`;
    },
  );

  input = input.replace(/\*(?! )(.*?)\*/g, (match) => {
    // /([*_])(.*)\1/g
    // let to = (wrapper === '*') ? '**' : '*';
    // let from = to;
    // if (content.slice(0, 2) == ' *') {
    //   content = content.slice(2);
    //   to = '* **';
    // }
    // return to + content + from;
    return `*${match}*`;
  });

  // Images with alt= among their parameters
  input = input.replace(
    /!([^|\n\s]+)\|([^\n!]*)alt=([^\n!\,]+?)(,([^\n!]*))?!/g,
    '![$3]($1)',
  );
  // Images with just other parameters (ignore them)
  input = input.replace(/!([^|\n\s]+)\|([^\n!]*)!/g, '![]($1)');
  // Images without any parameters or alt
  input = input.replace(/!([^\n\s!]+)!/g, '![]($1)');

  // headers, must be after numbered lists
  input = input.replace(/^h([0-6])\.(.*)$/gm, (match, level, content) => {
    return '#'.repeat(parseInt(level, 10)) + content;
  });

  input = input.replace(/\{\{([^}]+)\}\}/g, '`$1`');
  input = input.replace(/\?\?((?:.[^?]|[^?].)+)\?\?/g, '<cite>$1</cite>');
  input = input.replace(/\+([^+]*)\+/g, '<ins>$1</ins>');
  input = input.replace(/\^([^^]*)\^/g, '<sup>$1</sup>');
  input = input.replace(/~([^~]*)~/g, '<sub>$1</sub>');
  input = input.replace(/-([^-]*)-/g, '-$1-');

  input = input.replace(/\{code(:([a-z]+))?\}([^]*?)\{code\}/gm, '```$2$3```');
  input = input.replace(
    /\{quote\}([^]*?)\{quote\}/gm,
    function (match, content) {
      const lines = content.split(/\r?\n/gm);

      for (let i = 0; i < lines.length; i++) {
        lines[i] = `> ${lines[i]}`;
      }

      return lines.join('\n');
    },
  );

  input = input.replace(/!([^\n\s]+)!/, '![]($1)');
  input = input.replace(/\[([^|]+)\|(.+?)\]/g, '[$1]($2)');

  // no sure what this is for
  // input = input.replace(/\[(.+?)\]([^\(]+)/g, '<$1>$2');

  input = input.replace(/{noformat}/g, '```');
  input = input.replace(
    /{color:([^}]+)}([^]*?){color}/gm,
    '<span style="color:$1">$2</span>',
  );

  // Convert header rows of tables by splitting input on lines
  const lines = input.split(/\r?\n/gm);
  for (let i = 0; i < lines.length; i++) {
    const line_content = lines[i];

    const seperators = line_content.match(/\|\|/g);
    if (seperators != null) {
      lines[i] = lines[i].replace(/\|\|/g, '|');

      // Add a new line to mark the header in Markdown,
      // we require that at least 3 -'s are between each |
      let header_line = '';
      for (let j = 0; j < seperators.length - 1; j++) {
        header_line += '|---';
      }

      header_line += '|';

      lines.splice(i + 1, 0, header_line);
    }
  }

  // Join the split lines back
  input = '';
  for (let i = 0; i < lines.length; i++) {
    input += lines[i] + (i === lines.length - 1 ? '' : '\n');
  }

  return input;
}

/**
 * Takes Markdown and converts it to Jira formatted text
 *
 * @param {string} src
 * @returns {string}
 */
export function toJ(str: string): string {
  const lookup = {
    del: '-',
    ins: '+',
    sup: '^',
    sub: '~',
  };

  return (
    str
      // tables
      .replace(
        /^\n((?:\|.*?)+\|)[ \t]*\n((?:\|\s*?\-{3,}\s*?)+\|)[ \t]*\n((?:(?:\|.*?)+\|[ \t]*\n)*)$/gm,
        function (match, headerLine, separatorLine, rowstr) {
          const headers = headerLine.match(/[^|]+(?=\|)/g);
          const separators = separatorLine.match(/[^|]+(?=\|)/g);
          if (headers.length !== separators.length) {
            return match;
          }
          const rows = rowstr.split('\n');
          if (rows.length === 1 + 1 && headers.length === 1) {
            // panel
            return `{panel:title=${headers[0].trim()}}\n${rowstr
              .replace(/^\|(.*)[ \t]*\|/, '$1')
              .trim()}\n{panel}\n`;
          }
          return '||' + headers.join('||') + '||\n' + rowstr;
        },
      )
      // Bold, Italic, and Combined (bold+italic)
      .replace(/([*_]+)(\S.*?)\1/g, function (match, wrapper, content) {
        switch (wrapper.length) {
          case 1:
            return `_${content}_`;
          case 2:
            return `*${content}*`;
          case 3:
            return `_*${content}*_`;
          default:
            return wrapper + content * wrapper;
        }
      })
      // All Headers (# format)
      .replace(/^([#]+)(.*?)$/gm, function (match, level, content) {
        return 'h' + level.length + '.' + content;
      })
      // Headers (H1 and H2 underlines)
      .replace(/^(.*?)\n([=-]+)$/gm, function (match, content, level) {
        return 'h' + (level[0] === '=' ? 1 : 2) + '. ' + content;
      })
      // Ordered lists
      .replace(/^([ \t]*)\d+\.\s+/gm, function (match, spaces) {
        return Array(Math.floor(spaces.length / 2 + 1)).join('#') + '# ';
      })
      // Un-Ordered Lists
      .replace(/^([ \t]*)\*\s+/gm, function (match, spaces) {
        return Array(Math.floor(spaces.length / 2 + 1)).join('*') + '* ';
      })
      // Headers (h1 or h2) (lines "underlined" by ---- or =====)
      // Citations, Inserts, Subscripts, Superscripts, and Strikethroughs
      .replace(
        new RegExp('<(' + Object.keys(lookup).join('|') + ')>(.*?)</\\1>', 'g'),
        function (match, from, content) {
          const to = lookup[from];
          return to + content + to;
        },
      )
      // Other kind of strikethrough
      .replace(/(\s+)~~(.*?)~~(\s+)/g, '$1-$2-$3')
      // Named/Un-Named Code Block
      .replace(/```(.+\n)?((?:.|\n)*?)```/g, function (match, synt, content) {
        let code = '{code}';
        if (synt) {
          code = '{code:' + synt.replace(/\n/g, '') + '}\n';
        }
        return code + content + '{code}';
      })
      // Inline-Preformatted Text
      .replace(/`([^`]+)`/g, '{{$1}}')
      // Images
      .replace(/!\[[^\]]*\]\(([^)]+)\)/g, '!$1!')
      // Special Named Link
      .replace(/\[([^\]]+)\]\(<([^)]+)>\)/g, '[$1|$2]')
      // Named Link
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '[$1|$2]')
      // Un-Named Link
      .replace(/<([^>]+)>/g, '[$1]')
      // Single Paragraph Blockquote
      .replace(/^>/gm, 'bq.')
  );
}
