/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { couponAdapter } from './couponAdapter';

export const couponSelectors = couponAdapter.getSelectors(
  (state: State) => state.gen.coupon,
);
