import { ResponsivePie } from '@nivo/pie';
import React, { useCallback, useMemo } from 'react';
import { SpaceIcon } from '@bitmodern/bit-ui/icons';
import { SettingsPanel } from 'src/components/organisms';
import { showNotificationError } from '@testquality/sdk';
import vars from 'src/export.scss';
import useFetch from 'src/hooks/useFetch';
import { useTranslation } from 'src/i18n/hooks';
import { spaceAnalisysByProject } from '@bitmodern/services/spaceService';

export default function SpaceView() {
  const { t } = useTranslation();

  const fetchSpace = useCallback(
    () => spaceAnalisysByProject().catch(showNotificationError),
    [],
  );
  const space = useFetch(fetchSpace, 'space', {
    initialData: [],
    staleWhileRevalidate: true,
  });

  const data = useMemo(() => {
    if (!space.data.length) return [];

    const totalUsed = space.data[0].total_used;
    const free = space.data[0].space_free;
    const total = totalUsed + free;

    const d = space.data.map((stat) => ({
      id: stat.project_name,
      label: stat.project_name,
      value: (stat.space_used / total) * 100,
    }));
    d.push({
      id: t('space.free'),
      label: t('space.free'),
      value: (free / total) * 100,
    });
    return d;
  }, [t, space.data]);

  return (
    <SettingsPanel
      icon={<SpaceIcon color={vars.textPrimary} size={24} />}
      title={t('space.title')}>
      <div style={{ height: 300 }}>
        <ResponsivePie
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          borderWidth={0}
          cornerRadius={4}
          data={data}
          enableArcLinkLabels={false}
          innerRadius={0.5}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          padAngle={0.7}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={vars.textPrimary}
          theme={{
            crosshair: {
              line: {
                stroke: vars.textPrimary,
              },
            },
            background: 'transparent',
            textColor: vars.textSecondary,
            fontSize: 12,
            tooltip: {
              container: { background: vars.primaryDark },
            },
          }}
          valueFormat={(value) => `${value.toFixed(1)}%`}
        />
      </div>
    </SettingsPanel>
  );
}
