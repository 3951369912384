/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Plan,
  PlanApi,
  planGetMany,
  planGetOne,
  planDeleteOne,
  planUpdateOne,
  planCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { planNormalizer } from './planNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const planFetchManyThunk = createAsyncThunk(
  'plan/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plan>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await planGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, planNormalizer);
      }
      return {
        plan: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planFetchOneThunk = createAsyncThunk(
  'plan/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plan>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'plan/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await planGetOne(arg.id as number, arg);
      return executeNormalizer([data], planNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planDeleteOneThunk = createAsyncThunk(
  'plan/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plan>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'plan/DeleteOneThunk');
      }
      return await planDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planUpdateOneThunk = createAsyncThunk(
  'plan/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<PlanApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'plan/UpdateOneThunk');
      }
      const data = await planUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Plan>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Plan>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planCreateOneThunk = createAsyncThunk(
  'plan/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanApi>, { dispatch }) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'plan/CreateOneThunk',
        );
      }
      const value = await planCreateOne(arg.data, arg);
      dispatch(
        planFetchOneThunk({ id: value.id, params: { _with: 'purpose' } }),
      );
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
