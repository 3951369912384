import find from 'lodash/find';
import values from 'lodash/values';
import { State } from '@bitmodern/redux/store';
import planAdapter from 'src/gen/domain/plan/planAdapter';
import { createSelector } from 'reselect';
import { Plan } from '@testquality/sdk';
import {
  planSuiteTestIncludeByProjectSelector,
  includesByTestSelector,
} from '../planSuitesTestsIncludes/selectors';
import { filtersByTypeSelector } from '../filters/selectors';

const plansSelectors = planAdapter.getSelectors(
  (state: State) => state.gen.plan,
);

export function plansSelector(state: State) {
  return plansSelectors.selectAll(state);
}

export function planByIdSelector(state: State, planId: number) {
  return plansSelectors.selectById(state, planId);
}

export function getPlanById(state, args: { planId?: number }) {
  return args.planId
    ? plansSelectors.selectById(state, args.planId)
    : undefined;
}

export const plansByProjectSelector = createSelector(
  [plansSelector, (_, args: { projectId: number }) => args.projectId],
  (plans, projectId) =>
    plans.filter((plan) => plan.project_id === projectId && !plan.is_root),
);

export const plansListSelector = createSelector(
  [
    plansByProjectSelector,
    (state) => filtersByTypeSelector(state, 'plansList'),
  ],
  (plans, filters) => {
    const planName = filters.name;
    if (!planName) return sortPlansByUpdate(plans);
    const filtered = plans.filter((plan) =>
      plan.name.toLocaleLowerCase().includes(planName.toLocaleLowerCase()),
    );
    return sortPlansByUpdate(filtered);
  },
);

const sortPlansByUpdate = (plans: Plan[]) => {
  return [...plans].sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );
};

export const plansAndRootPlanByProjectSelector = createSelector(
  [plansSelector, (_, args: { projectId: number }) => args.projectId],
  (plans, projectId) => plans.filter((plan) => plan.project_id === projectId),
);

export const rootPlanSelector = createSelector(
  [
    (state: State) => state.gen.plan.entities,
    (state: State, projectId: number) => projectId,
  ],
  (entities, projectId) =>
    find(entities, (plan) => {
      return plan?.is_root === true && plan?.project_id === projectId;
    }),
);

export function planSelector(state: State, id: number): Plan {
  return plansSelectors.selectById(state, id) as Plan;
}

export const makeTestsCountsByPlan = () =>
  createSelector(
    [planSuiteTestIncludeByProjectSelector],
    (planSuiteTestInclude) => {
      const counts: { [key: number]: number } = {};
      planSuiteTestInclude.forEach((include) => {
        if (!include.test_id) return;
        if (counts[include.plan_id]) {
          counts[include.plan_id] += 1;
        } else {
          counts[include.plan_id] = 1;
        }
      });
      return counts;
    },
  );

export const lastCycleSelector = createSelector(
  [(state: State) => state.gen.plan.entities],
  (entities) => {
    const list = values(entities).sort((a, b) => {
      if (a !== undefined && b !== undefined) {
        return b.key - a.key;
      }
      return 0;
    });
    if (list.length > 0) {
      return list[0];
    }
    return undefined;
  },
);

export const plansIncludesTest = createSelector(
  [
    (state: State) => state.gen.plan.entities,
    (state: State, testId: number) => includesByTestSelector(state, { testId }),
  ],
  (entities, planSuiteTestInclude) => {
    const plans: Plan[] = [];
    planSuiteTestInclude.forEach((include) => {
      const plan = entities[include.plan_id];
      if (plan) plans.push(plan);
    });
    return plans;
  },
);
