/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { DefectStatusApi } from '@testquality/sdk';
import { DefectStatusNativeDefectStatus } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { defectNormalizer } from '../defect/defectNormalize';
import { nativeDefectStatusNormalizer } from '../native_defect_status/nativeDefectStatusNormalize';
import { defectStatusNativeDefectStatusNormalizer } from '../defect_status_native_defect_status/defectStatusNativeDefectStatusNormalize';
import { requirementNormalizer } from '../requirement/requirementNormalize';

export const defectStatusNormalizer = (
  model: DefectStatusApi,
  cache: NormalizerCache,
) => {
  if (!cache.defectStatus) {
    cache.defectStatus = [];
  }
  cache.defectStatus.push(model);

  if (model.defect && model.defect.length) {
    model.defect.forEach((child) => {
      defectNormalizer(child, cache);
    });
    model.defect = undefined;
  }
  if (model.native_defect_status && model.native_defect_status.length) {
    model.native_defect_status.forEach((child) => {
      defectStatusNativeDefectStatusNormalizer(
        child.pivot as DefectStatusNativeDefectStatus,
        cache,
      );
      child.pivot = undefined;
      nativeDefectStatusNormalizer(child, cache);
    });
    model.native_defect_status = undefined;
  }
  if (model.requirement && model.requirement.length) {
    model.requirement.forEach((child) => {
      requirementNormalizer(child, cache);
    });
    model.requirement = undefined;
  }
};
