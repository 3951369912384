import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import AnalyzeStats from 'src/components/organisms/AnalyzeStats';
import { ReliabilityAnalysis } from '@bitmodern/services/ReliabilityService';
import { FlakinessEnum } from 'src/enums/FlakinessEnum';
import vars from 'src/export.scss';
import styles from './FlakinessMetrics.module.scss';

type Props = {
  flakyAnalysis: { [testId: string]: ReliabilityAnalysis[] };
  className?: string;
};

export default function FlakinessMetrics({
  flakyAnalysis,
  className = '',
}: Props) {
  const { t } = useTranslation();

  const { totalFlaky, percentageFlaky } = useMemo(() => {
    const analysisArray = Object.values(flakyAnalysis);
    const totalFlakyAmount = analysisArray.reduce((acc, testAnalysis) => {
      /* NOTE: The first element in the analysis array is the most recent one */
      /* and therefore its flakiness is the only relevant one (current flakiness) */
      if (testAnalysis[0].flakiness === FlakinessEnum.FLAKY) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return {
      totalFlaky: totalFlakyAmount,
      percentageFlaky:
        Math.floor((totalFlakyAmount / analysisArray.length) * 10000) / 100 ||
        0,
    };
  }, [flakyAnalysis]);

  return (
    <div className={`${styles.container} ${className}`}>
      <AnalyzeStats
        title={t('analyze.testReliability.metrics.totalFlaky.title')}
        subtitle={t('analyze.testReliability.metrics.totalFlaky.subtitle')}
        stat={totalFlaky}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        title={t('analyze.testReliability.metrics.percentageFlaky.title')}
        subtitle={t('analyze.testReliability.metrics.percentageFlaky.subtitle')}
        stat={`${percentageFlaky}%`}
        statColor={vars.successMain}
      />
    </div>
  );
}
