/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import {
  requirementTestDetachThunk,
  requirementTestFetchManyThunk,
  requirementTestFetchOneThunk,
  requirementTestUpdateOneThunk,
  requirementTestCreateOneThunk,
} from './requirementTestThunk';
import { TQStateProperties } from '../../store/TQState';
import { requirementTestAdapter } from './requirementTestAdapter';
import {
  requirementFetchManyThunk,
  requirementFetchOneThunk,
} from '../requirement/requirementThunk';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
} from '../defect_res/defectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
} from '../native_defect_res/nativeDefectResThunk';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import { suiteFetchManyThunk, suiteFetchOneThunk } from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import { planFetchManyThunk, planFetchOneThunk } from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const requirementTestSlice = createSlice({
  name: 'requirementTest',
  initialState: requirementTestAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    requirementTestCleared: requirementTestAdapter.removeAll,
    requirementTestUpsertMany: requirementTestAdapter.upsertMany,
    requirementTestUpsertOne: requirementTestAdapter.upsertOne,
    requirementTestUpdateOne: requirementTestAdapter.updateOne,
    requirementTestRemoveOne: requirementTestAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(requirementTestDetachThunk.pending, handlePending);
    builder.addCase(requirementTestDetachThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload && action.payload.id) {
        requirementTestAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(requirementTestDetachThunk.rejected, handleRejected);
    builder.addCase(requirementTestFetchManyThunk.pending, handlePending);
    builder.addCase(
      requirementTestFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      },
    );
    builder.addCase(requirementTestFetchManyThunk.rejected, handleRejected);
    builder.addCase(requirementTestFetchOneThunk.pending, handlePending);
    builder.addCase(requirementTestFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementTestAdapter.upsertOne(
        state,
        action.payload.requirementTest[0],
      );
    });
    builder.addCase(requirementTestFetchOneThunk.rejected, handleRejected);
    builder.addCase(requirementTestUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, requirementTestAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      requirementTestUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        requirementTestAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(requirementTestUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          requirementTestAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(requirementTestCreateOneThunk.pending, handlePending);
    builder.addCase(
      requirementTestCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        requirementTestAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(requirementTestCreateOneThunk.rejected, handleRejected);
    builder.addCase(requirementFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(requirementFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirementTest) {
          requirementTestAdapter.upsertMany(
            state,
            action.payload.requirementTest,
          );
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirementTest) {
        requirementTestAdapter.upsertMany(
          state,
          action.payload.requirementTest,
        );
      }
    });
  },
});

export const {
  requirementTestCleared,
  requirementTestUpsertMany,
  requirementTestUpsertOne,
  requirementTestUpdateOne,
  requirementTestRemoveOne,
} = requirementTestSlice.actions;

export const requirementTestReducer = requirementTestSlice.reducer;
