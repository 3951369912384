/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { integrationTemplateAdapter } from './integrationTemplateAdapter';

export const integrationTemplateSelectors =
  integrationTemplateAdapter.getSelectors(
    (state: State) => state.gen.integrationTemplate,
  );
