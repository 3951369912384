import React from 'react';
import { UploadIcon } from '@bitmodern/bit-ui/icons';
import { SettingsPanel } from 'src/components/organisms';
import vars from 'src/export.scss';
import { useTranslation } from 'react-i18next';
import Automations from 'src/components/organisms/Automations';
import Imports from 'src/components/organisms/ImportDataForm';
import styles from './AutomationsView.module.scss';

export default function AutomationsView() {
  const { t } = useTranslation();
  return (
    <SettingsPanel
      icon={<UploadIcon color={vars.textPrimary} size={24} />}
      title={t('import.automation')}>
      <div>
        <div className={styles.title}>Automations</div>
        <Automations />
        <div className={styles.title}>Imports</div>
        <Imports />
      </div>
    </SettingsPanel>
  );
}
