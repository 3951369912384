import React from 'react';
import { useAppSelector } from '@bitmodern/redux/store';
import { isAuthenticatedSelector } from '@bitmodern/redux/state/authentication/selectors';
import { Redirect } from 'react-router';
import { routes } from 'src/components/Router';
import GithubInstallView from './GithubInstallView';

type Props = {
  location: any;
};

export default function GithubInstallViewContainer({ location }: Props) {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.FREETRIAL({}),
          state: { from: location },
        }}
      />
    );
  }

  return <GithubInstallView />;
}
