import { AccessRole } from '@testquality/sdk';
import React from 'react';
import { Dialog, DialogContent } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import RoleForm from '../RoleForm';
import { RoleFormDetails } from '../RoleForm/RoleForm';

type Props = {
  onCancel: () => void;
  onSubmit: (values) => Promise<any>;
  open: boolean;
  currentAccessRole?: AccessRole;
  initialValues: RoleFormDetails;
};

export default function RoleFormDialog({
  onCancel,
  onSubmit,
  open,
  currentAccessRole,
  initialValues,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      isDismissable={false}
      isOpen={open}
      onClose={onCancel}
      title={t('roleFormDialog.title')}>
      <DialogContent>
        <RoleForm
          onSubmit={onSubmit}
          currentAccessRole={currentAccessRole}
          initialValues={initialValues}
        />
      </DialogContent>
    </Dialog>
  );
}
