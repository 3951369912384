import React, { memo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'src/i18n/hooks';
import { BugIcon, DoneIcon, SettingsIcon } from '@bitmodern/bit-ui/icons';
import { Run } from '@testquality/sdk';
import vars from 'src/export.scss';
import { useAppSelector } from '@bitmodern/redux/store';
import { planSelector } from '@bitmodern/redux/state/plans/selectors';
import styles from './RunItem.module.scss';
import RunChipProgress from 'src/components/organisms/RunChipProgress';
import { useMaker } from '@bitmodern/redux/hooks';
import { makeAppVersionPlatVersionPlansByPlan } from '@bitmodern/redux/state/appVersionPlatVersionPlans/selectors';

type Props = {
  selected: boolean;
  run: Run;
};

function RunItem({ selected, run }: Props) {
  const { t } = useTranslation();
  const plan = useAppSelector((state) => planSelector(state, run.plan_id));
  const appVersionPlatVersionPlans = useMaker(
    makeAppVersionPlatVersionPlansByPlan,
    { planId: run.plan_id },
  );
  const configurationsCount = appVersionPlatVersionPlans.length;

  const itemCN = classnames(styles.item, {
    [styles.selected]: selected,
  });

  return (
    <div className={itemCN}>
      <div className={styles.header}>
        <div className={styles.name}>{run.name}</div>
        <div className={styles.plan}>
          {plan?.is_root ? t('runItem.planRoot') : plan?.name}
        </div>
        <div className={styles.date}>
          {run.is_complete ? (
            <>
              <DoneIcon
                className={styles.doneIcon}
                color={vars.successMain}
                size={16}
              />
              {t('runItem.endTime', {
                endTime: run.end_time ? new Date(run.end_time) : run.start_time,
              })}
            </>
          ) : (
            run.analysis?.todo &&
            t('runItem.todo', { count: run.analysis.todo })
          )}
        </div>
      </div>

      <div className={styles.stats}>
        <RunChipProgress run={run} />

        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <BugIcon color={vars.textPrimary} size={20} />
            <span
              className={styles.bugs}
              style={{
                color:
                  run.analysis?.defect_count && run.analysis?.defect_count > 0
                    ? vars.textPrimary
                    : vars.textSecondary,
              }}>
              {t('runItem.bug', { count: run.analysis?.defect_count || 0 })}
            </span>
          </div>

          {configurationsCount > 0 && (
            <div className={styles.info}>
              <SettingsIcon color={vars.textPrimary} size={18} />
              <span className={styles.configurations}>
                {t('runItem.conf', { count: configurationsCount })}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(RunItem);
