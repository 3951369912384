/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { BaseCapabilityBaseIntegrationApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const baseCapabilityBaseIntegrationNormalizer = (
  model: BaseCapabilityBaseIntegrationApi,
  cache: NormalizerCache,
) => {
  if (!cache.baseCapabilityBaseIntegration) {
    cache.baseCapabilityBaseIntegration = [];
  }
  cache.baseCapabilityBaseIntegration.push(model);
};
