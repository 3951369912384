import React from 'react';
import Yup from 'src/utils/yup';
import { useFormik } from 'formik';
import { Button, Input, InputDate } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import styles from './MilestoneForm.module.scss';

export type FormValues = {
  name: string;
  description: string;
  startDate?: Date;
  releaseDate?: Date;
};

const defaultInitialValues: FormValues = {
  name: '',
  description: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('milestoneForm.name'),
  startDate: Yup.date().label('milestoneForm.startDate'),
  releaseDate: Yup.date()
    .min(Yup.ref('startDate'), "Release date can't be before start date")
    .label('milestoneForm.releaseDate'),
});

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => Promise<any>;
};

export default function MilestoneForm({ initialValues, onSubmit }: Props) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues || defaultInitialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form
      className={styles.container}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}>
      <Input
        error={formikError(formik, 'name')}
        fullWidth
        label={t('milestoneForm.name')}
        labelClassName={styles.firstLabel}
        name="name"
        onChange={formik.handleChange}
        onFocus={formik.handleBlur}
        required
        value={formik.values.name}
      />
      <Input
        error={formikError(formik, 'description')}
        fullWidth
        label={t('milestoneForm.description')}
        name="description"
        onChange={formik.handleChange}
        onFocus={formik.handleBlur}
        value={formik.values.description}
      />
      <InputDate
        error={formikError(formik, 'startDate')}
        fullWidth
        label={t('milestoneForm.startDate')}
        name="startDate"
        onChange={(date) => formik.setFieldValue('startDate', date)}
        onFocus={formik.handleBlur}
        value={formik.values.startDate}
      />
      <InputDate
        error={formikError(formik, 'releaseDate')}
        fullWidth
        label={t('milestoneForm.releaseDate')}
        name="releaseDate"
        onChange={(date) => formik.setFieldValue('releaseDate', date)}
        onFocus={formik.handleBlur}
        value={formik.values.releaseDate}
      />
      <div className={styles.submit}>
        <Button loading={formik.isSubmitting} type="submit">
          {t('milestoneForm.submitEdit')}
        </Button>
      </div>
    </form>
  );
}
