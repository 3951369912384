/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AppVersionPlatVersionHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appVersionPlatVersionHistoryAdapter } from './appVersionPlatVersionHistoryAdapter';
import { appVersionPlatVersionHistoryFetchThunk } from './appVersionPlatVersionHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appVersionPlatVersionHistorySlice = createSlice({
  name: 'appVersionPlatVersionHistory',
  initialState:
    appVersionPlatVersionHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    appVersionPlatVersionHistoryCleared(state) {
      state.loading = 'idle';
      appVersionPlatVersionHistoryAdapter.removeAll(state);
    },
    appVersionPlatVersionHistoryUpsertMany(
      state,
      action: PayloadAction<
        | AppVersionPlatVersionHistory[]
        | Record<number, AppVersionPlatVersionHistory>
      >,
    ) {
      appVersionPlatVersionHistoryAdapter.upsertMany(state, action.payload);
    },
    appVersionPlatVersionHistoryUpsertOne(
      state,
      action: PayloadAction<AppVersionPlatVersionHistory>,
    ) {
      appVersionPlatVersionHistoryAdapter.upsertOne(state, action.payload);
    },
    appVersionPlatVersionHistoryUpdateOne(
      state,
      action: PayloadAction<Update<AppVersionPlatVersionHistory>>,
    ) {
      appVersionPlatVersionHistoryAdapter.updateOne(state, action.payload);
    },
    appVersionPlatVersionHistoryRemoveOne(
      state,
      action: PayloadAction<number>,
    ) {
      appVersionPlatVersionHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      appVersionPlatVersionHistoryFetchThunk.pending,
      handlePending,
    );
    builder.addCase(
      appVersionPlatVersionHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        appVersionPlatVersionHistoryAdapter.upsertMany(
          state,
          action.payload.appVersionPlatVersionHistory,
        );
      },
    );
    builder.addCase(
      appVersionPlatVersionHistoryFetchThunk.rejected,
      handleRejected,
    );
  },
});

export const {
  appVersionPlatVersionHistoryCleared,
  appVersionPlatVersionHistoryUpsertMany,
  appVersionPlatVersionHistoryUpsertOne,
  appVersionPlatVersionHistoryUpdateOne,
  appVersionPlatVersionHistoryRemoveOne,
} = appVersionPlatVersionHistorySlice.actions;

export const appVersionPlatVersionHistoryReducer =
  appVersionPlatVersionHistorySlice.reducer;
