/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Project,
  ProjectApi,
  projectGetMany,
  projectGetOne,
  projectDeleteOne,
  projectUpdateOne,
  projectCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { projectNormalizer } from './projectNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const projectFetchManyThunk = createAsyncThunk(
  'project/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Project>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await projectGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, projectNormalizer);
      }
      return {
        project: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const projectFetchOneThunk = createAsyncThunk(
  'project/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Project>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'project/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await projectGetOne(arg.id as number, arg);
      return executeNormalizer([data], projectNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const projectDeleteOneThunk = createAsyncThunk(
  'project/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Project>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'project/DeleteOneThunk');
      }
      return await projectDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const projectUpdateOneThunk = createAsyncThunk(
  'project/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<ProjectApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'project/UpdateOneThunk');
      }
      const data = await projectUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Project>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Project>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const projectCreateOneThunk = createAsyncThunk(
  'project/CreateOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<ProjectApi>,
    { dispatch },
  ) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'project/CreateOneThunk',
        );
      }
      const value = await projectCreateOne(arg.data, arg);
      dispatch(
        projectFetchOneThunk({
          id: value.id,
          params: { _with: 'integration' },
        }),
      );
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
