/* eslint-disable react/jsx-key */
import React from 'react';
import { routes } from 'src/components/Router';
import AppRoute from 'src/components/Router/AppRoute';
import GuestRoute from 'src/components/Router/GuestRoute';
import AuthRoute from 'src/components/Router/AuthRoute';
import { CompleteInviteUserView } from 'src/modules/authentication/views/CompleteInviteUserView';
import EmailVerificationView from 'src/modules/authentication/views/EmailVerificationView';
import PasswordRecovery from 'src/modules/authentication/views/PasswordRecovery';
import PasswordRecoveryChange from 'src/modules/authentication/views/PasswordRecoveryChange';
import { LoginSSO } from 'src/modules/authentication/views/LoginSSO';
import { LoginTokenView } from './views/LoginTokenView';
import { SignUpView } from './views/SignUpView';
import { SignInView } from './views/SignInView';
import { ConnectAppView } from './views/ConnectAppView';

const authenticationRoutes = [
  <GuestRoute
    path={[routes.LOGIN.path, routes.LOGIN_EMAIL.path]}
    exact
    component={SignInView}
  />,
  <GuestRoute path={routes.LOGIN_SSO.path} exact component={LoginSSO} />,
  <GuestRoute
    path={routes.LOGIN_TOKEN.path}
    exact
    component={LoginTokenView}
  />,
  <AuthRoute path={routes.CONNECT_APP.path} exact component={ConnectAppView} />,
  <GuestRoute
    path={[routes.FREETRIAL.path, routes.FREETRIAL_EMAIL.path]}
    exact
    component={SignUpView}
  />,
  <AppRoute
    path={routes.EMAIL_VERIFICATION.path}
    exact
    component={EmailVerificationView}
  />,
  <GuestRoute
    path={routes.COMPLETE_INVITE_USER.path}
    exact
    component={CompleteInviteUserView}
  />,
  <AppRoute path={routes.RECOVERY.path} exact component={PasswordRecovery} />,
  <AppRoute
    path={routes.RECOVERY_CHANGE.path}
    exact
    component={PasswordRecoveryChange}
  />,
];

export default authenticationRoutes;
