/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { FilterHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { filterHistoryAdapter } from './filterHistoryAdapter';
import { filterHistoryFetchThunk } from './filterHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const filterHistorySlice = createSlice({
  name: 'filterHistory',
  initialState: filterHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    filterHistoryCleared(state) {
      state.loading = 'idle';
      filterHistoryAdapter.removeAll(state);
    },
    filterHistoryUpsertMany(
      state,
      action: PayloadAction<FilterHistory[] | Record<number, FilterHistory>>,
    ) {
      filterHistoryAdapter.upsertMany(state, action.payload);
    },
    filterHistoryUpsertOne(state, action: PayloadAction<FilterHistory>) {
      filterHistoryAdapter.upsertOne(state, action.payload);
    },
    filterHistoryUpdateOne(
      state,
      action: PayloadAction<Update<FilterHistory>>,
    ) {
      filterHistoryAdapter.updateOne(state, action.payload);
    },
    filterHistoryRemoveOne(state, action: PayloadAction<number>) {
      filterHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterHistoryFetchThunk.pending, handlePending);
    builder.addCase(filterHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      filterHistoryAdapter.upsertMany(state, action.payload.filterHistory);
    });
    builder.addCase(filterHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  filterHistoryCleared,
  filterHistoryUpsertMany,
  filterHistoryUpsertOne,
  filterHistoryUpdateOne,
  filterHistoryRemoveOne,
} = filterHistorySlice.actions;

export const filterHistoryReducer = filterHistorySlice.reducer;
