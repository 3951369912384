import React, { ComponentProps } from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { Button, Dialog, Input, Checkbox } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import styles from './SaveFilterDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('commandBarFilter.customFilter.save.dialog.form.name'),
  share: Yup.boolean().required(),
});

type SaveFilterValues = {
  name: string;
  share: boolean;
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  onSubmit: (values: SaveFilterValues) => Promise<any>;
  initialValues?: SaveFilterValues;
};

export default function SaveFilterDialog({
  isOpen,
  onClose,
  onSubmit,
  initialValues = { name: '', share: false },
}: Props) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const onChangeShare = (checked) => {
    formik.setFieldValue('share', checked);
  };

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title={t('commandBarFilter.customFilter.save.dialog.title')}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        <Input
          error={formikError(formik, 'name')}
          label={t('commandBarFilter.customFilter.save.dialog.form.name')}
          name="name"
          fullWidth
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.name}
        />
        <Checkbox
          className={styles.share}
          checked={formik.values.share}
          name="share"
          onChange={onChangeShare}>
          {t('commandBarFilter.customFilter.save.dialog.form.share')}
        </Checkbox>
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit"
          disabled={!formik.dirty}>
          {t('commandBarFilter.customFilter.save.dialog.form.submit')}
        </Button>
      </form>
    </Dialog>
  );
}
