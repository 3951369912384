import React, {
  ComponentProps,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Filter } from '@testquality/sdk';
import isEmpty from 'lodash/isEmpty';
import { useOverlayTriggerState } from 'react-stately';
import { useParams } from 'react-router';
import { Avatar, IconButton } from '@bitmodern/bit-ui';
import {
  DeleteIcon,
  EditIcon,
  FilterIcon,
  PlanIcon,
  SaveIcon,
  SearchIcon,
} from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import {
  BooleanEnumDisplay,
  booleanEnumToBoolean,
} from 'src/enums/BooleanEnum';
import { useTranslation } from 'src/i18n/hooks';
import {
  filterCreateOneThunk,
  filterDeleteOneThunk,
  filterUpdateOneThunk,
} from 'src/gen/domain/filter/filterThunk';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import {
  applyCustomFilterAction,
  breakCustomFilterAction,
} from '@bitmodern/redux/state/customFilters/actions';
import { TestsFiltersKeys } from '@bitmodern/redux/state/filters/reducer';
import {
  filtersByTypeSelector,
  filtersDisplaySelector,
} from '@bitmodern/redux/state/filters/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { plansByProjectSelector } from '@bitmodern/redux/state/plans/selectors';
import { customFilterByTypeSelector } from '@bitmodern/redux/state/customFilters/selectors';
import { createFiltersByTypeSelector } from '@bitmodern/redux/state/filter/selectors';
import {
  pendingStatusSelector,
  retestStatusSelector,
  statusesSelector,
} from '@bitmodern/redux/state/statuses/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import useMutation from 'src/hooks/useMutation';
import { formatUserName, processAttachmentPath } from 'src/utils/fileHelper';
import vars from 'src/export.scss';
import { requirementsByProjectSelector } from '@bitmodern/redux/state/requirements/selectors';
import CommandBar from '../CommandBar/CommandBar';
import SaveFilterDialog from '../SaveFilterDialog';
import SaveFilterMenuItem from './SaveFilterMenuItem';
import ConfirmDialog from '../ConfirmDialog';

type Commands = ComandBarProps['commands'];
type ComandBarProps = ComponentProps<typeof CommandBar>;

type TestCommandList =
  | 'default'
  | 'assignedToSelf'
  | 'author'
  | 'caseType'
  | 'casePriority'
  | 'assignee'
  | 'automated'
  | 'label'
  | 'suiteLabel'
  | 'name'
  | 'plan'
  | 'suiteName'
  | 'requirement';

type RunResultCommandList = 'status' | 'actualResult' | 'hasActualResult';
type PlanCommandList = 'exclusions';

type CommandList = TestCommandList | RunResultCommandList | PlanCommandList;

type Props = Pick<ComandBarProps, 'onClose' | 'open'> & {
  command?: CommandList;
  type: TestsFiltersKeys;
};

const DEFAULT = 'default';

export default function CommandBarFilters({
  command,
  onClose,
  open,
  type,
}: Props) {
  const [current, setCurrent] = useState<CommandList>(command || DEFAULT);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const saveFilterDialog = useOverlayTriggerState({});
  const updateFilterDialog = useOverlayTriggerState({});
  const confirmUpdateDialog = useOverlayTriggerState({});
  const confirmDeleteDialog = useOverlayTriggerState({});
  const { projectId } = useParams<typeof routes.PROJECT.params>();
  const dispatch = useAppDispatch();

  const customFiltersByTypeSelector = useMemo(
    () => createFiltersByTypeSelector(type),
    [type],
  );
  const filters = useAppSelector((state) => filtersByTypeSelector(state, type));
  const displayFilters = useAppSelector((state) =>
    filtersDisplaySelector(state, type),
  );
  const customFilters = useAppSelector(customFiltersByTypeSelector);
  const selectedCustomFilter = useAppSelector((state) =>
    customFilterByTypeSelector(state, type),
  );
  const users = useAppSelector(usersSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const caseTypes = useAppSelector(caseTypesSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const labels = useAppSelector(labelsSelector);
  const statuses = useAppSelector(statusesSelector);
  const pendingStatus = useAppSelector(pendingStatusSelector);
  const retestStatus = useAppSelector(retestStatusSelector);
  const plans = useAppSelector((state) =>
    plansByProjectSelector(state, { projectId: parseInt(projectId, 10) }),
  );
  const requirements = useAppSelector((state) =>
    requirementsByProjectSelector(state, {
      projectId: parseInt(projectId, 10),
    }),
  );
  const isDisplayingFilterTiles = !isEmpty(displayFilters);

  const handleClose = useCallback(() => {
    setCurrent('default');
    onClose();
  }, [setCurrent, onClose]);

  useEffect(() => {
    setCurrent(command || 'default');
  }, [command]);

  const [customFilterToUpdate, setCustomFilterToUpdate] = useState<Filter>();
  const updateCustomFilter = useCallback(() => {
    if (!customFilterToUpdate) return Promise.resolve();
    return dispatch(
      filterUpdateOneThunk({
        id: customFilterToUpdate.id,
        data: {
          payload: filters,
          is_shareable: customFilterToUpdate.is_shareable,
        },
      }),
    )
      .then(unwrapResult)
      .then(({ changes: { payload } }) => {
        const updatedFilter = { ...customFilterToUpdate, payload };
        dispatch(applyCustomFilterAction({ type, filter: updatedFilter }));
      })
      .finally(() => {
        confirmUpdateDialog.close();
        saveFilterDialog.close();
        handleClose();
      });
  }, [
    dispatch,
    customFilterToUpdate,
    confirmUpdateDialog,
    filters,
    handleClose,
    saveFilterDialog,
    type,
  ]);
  const updateCustomFilterMutation = useMutation(updateCustomFilter);

  const [customFilterToDelete, setCustomFilterToDelete] = useState<Filter>();
  const deleteCustomFilter = useCallback(() => {
    if (!customFilterToDelete) return Promise.resolve();
    return dispatch(filterDeleteOneThunk({ id: customFilterToDelete.id }))
      .then(unwrapResult)
      .then(() => {
        if (customFilterToDelete.id === selectedCustomFilter?.id) {
          dispatch(breakCustomFilterAction({ type }));
        }
      })
      .finally(confirmDeleteDialog.close);
  }, [
    dispatch,
    customFilterToDelete,
    confirmDeleteDialog,
    type,
    selectedCustomFilter,
  ]);
  const deleteCustomFilterMutation = useMutation(deleteCustomFilter);

  const nameSearch = search || filters.name || '';
  const suiteNameSearch = search || filters.suiteName || '';
  const actualResultSearch = search || filters.actualResult || '';

  const runsCommands = [
    {
      command: t('commandBarFilter.status'),
      key: 'status',
      handler: () => setCurrent('status'),
      label: t('commandBarFilter.status'),
      icon: <SearchIcon color={vars.textPrimary} size={18} />,
    },
    {
      command: t('commandBarFilter.actualResult'),
      key: 'actualResult',
      handler: () => setCurrent('actualResult'),
      label: t('commandBarFilter.actualResult'),
      icon: <SearchIcon color={vars.textPrimary} size={18} />,
    },
    {
      command: t('commandBarFilter.hasActualResult'),
      key: 'hasActualResult',
      handler: () => handleEntityFilter(true, 'hasActualResult'),
      label: t('commandBarFilter.hasActualResult'),
      icon: <SearchIcon color={vars.textPrimary} size={18} />,
    },
  ];

  const plansCommands = [
    {
      command: t('commandBarFilter.exclusions'),
      key: 'exclusions',
      handler: () => setCurrent('exclusions'),
      label: t('commandBarFilter.exclusions'),
      icon: <SearchIcon color={vars.textPrimary} size={18} />,
    },
  ];

  const customFiltersCommands = customFilters.map((customFilter) => ({
    command: customFilter.name,
    key: `customFilter-${customFilter.name}`,
    handler: () => {
      dispatch(applyCustomFilterAction({ filter: customFilter, type }));
      handleClose();
    },
    label: t('commandBarFilter.customFilter.apply', {
      name: customFilter.name,
    }),
    icon: <FilterIcon color={vars.textPrimary} size={18} />,
    extraActions: (
      <div style={{ display: 'flex' }}>
        {selectedCustomFilter?.id === customFilter.id &&
          isDisplayingFilterTiles && (
            <IconButton
              title={t('commandBarFilter.customFilter.save.tooltip')}
              onClick={() => {
                setCustomFilterToUpdate(customFilter);
                updateCustomFilterMutation.mutate();
              }}
              boxed={false}
              isLoading={updateCustomFilterMutation.isLoading}>
              <SaveIcon color={vars.textSecondary} size={22} />
            </IconButton>
          )}
        <IconButton
          title={t('commandBarFilter.customFilter.update.tooltip')}
          onClick={() => {
            setCustomFilterToUpdate(customFilter);
            updateFilterDialog.open();
          }}
          boxed={false}>
          <EditIcon color={vars.textSecondary} size={22} />
        </IconButton>
        <IconButton
          title={t('commandBarFilter.customFilter.delete.tooltip')}
          onClick={() => {
            setCustomFilterToDelete(customFilter);
            confirmDeleteDialog.open();
          }}
          boxed={false}>
          <DeleteIcon color={vars.textSecondary} size={22} />
        </IconButton>
      </div>
    ),
  }));

  const commands: Record<CommandList, Commands> = {
    default: [
      ...customFiltersCommands,
      {
        command: t('commandBarFilter.assignedToSelf'),
        key: 'assignedToSelf',
        handler: () => {
          handleEntityFilter(currentUser?.id, 'assignee');
          handleEntityFilter(pendingStatus?.id, 'status');
          handleEntityFilter(retestStatus?.id, 'status');
        },
        label: t('commandBarFilter.assignedToSelf'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.author'),
        key: 'author',
        handler: () => setCurrent('author'),
        label: t('commandBarFilter.author'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.caseType'),
        key: 'caseType',
        handler: () => setCurrent('caseType'),
        label: t('commandBarFilter.caseType'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.casePriority'),
        key: 'casePriority',
        handler: () => setCurrent('casePriority'),
        label: t('commandBarFilter.casePriority'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.assignee'),
        key: 'assignee',
        handler: () => setCurrent('assignee'),
        label: t('commandBarFilter.assignee'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.automated'),
        key: 'automated',
        handler: () => setCurrent('automated'),
        label: t('commandBarFilter.automated'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.label'),
        key: 'label',
        handler: () => setCurrent('label'),
        label: t('commandBarFilter.label'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.suiteLabel'),
        key: 'suiteLabel',
        handler: () => setCurrent('suiteLabel'),
        label: t('commandBarFilter.suiteLabel'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.plan'),
        key: 'plan',
        handler: () => setCurrent('plan'),
        label: t('commandBarFilter.plan'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.requirement'),
        key: 'requirement',
        handler: () => setCurrent('requirement'),
        label: t('commandBarFilter.requirement'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.suiteName'),
        key: 'suiteName',
        handler: () => setCurrent('suiteName'),
        label: t('commandBarFilter.suiteName'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },
      {
        command: t('commandBarFilter.name'),
        key: 'name',
        handler: () => setCurrent('name'),
        label: t('commandBarFilter.name'),
        icon: <SearchIcon color={vars.textPrimary} size={18} />,
      },

      ...(type === 'runs' ? runsCommands : ([] as any)),
      ...(type === 'plans' ? plansCommands : ([] as any)),
    ],

    assignedToSelf: [],
    author: users.map((user) => ({
      checked: !!filters?.author?.[user.id],
      command: user.given_name || user.email,
      onChangeChecked: () => handleChangeChecked('author', user.id),
      key: user.id,
      handler: () => handleEntityFilter(user.id, 'author'),
      icon: <Avatar size={18} src={processAttachmentPath(user.picture)} />,
      label: formatUserName(user),
    })),
    caseType: caseTypes.map((caseType) => ({
      checked: !!filters?.caseType?.[caseType.id],
      onChangeChecked: () => handleChangeChecked('caseType', caseType.id),
      command: caseType.name,
      key: caseType.id,
      handler: () => handleEntityFilter(caseType.id, 'caseType'),
      icon: <span />,
      label: caseType.name,
    })),
    casePriority: casePriorities.map((casePriority) => ({
      checked: !!filters?.casePriority?.[casePriority.id],
      onChangeChecked: () =>
        handleChangeChecked('casePriority', casePriority.id),
      command: casePriority.name,
      key: casePriority.id,
      handler: () => handleEntityFilter(casePriority.id, 'casePriority'),
      icon: <span />,
      label: casePriority.name,
    })),
    assignee: users.map((user) => ({
      checked: !!filters?.assignee?.[user.id],
      onChangeChecked: () => handleChangeChecked('assignee', user.id),
      command: user.given_name || user.email,
      key: user.id,
      handler: () => handleEntityFilter(user.id, 'assignee'),
      icon: <Avatar size={18} src={processAttachmentPath(user.picture)} />,
      label: formatUserName(user),
    })),
    automated: Array.from(BooleanEnumDisplay).map(([value, label]) => ({
      command: t(label),
      key: t(label),
      handler: () =>
        handleEntityFilter(booleanEnumToBoolean(value), 'automated'),
      icon: <span />,
      label: t(label),
    })),
    label: labels.map((label) => ({
      checked: !!filters?.label?.[label.id],
      onChangeChecked: () => handleChangeChecked('label', label.id),
      command: label.label,
      key: label.id,
      handler: () => handleEntityFilter(label.id, 'label'),
      icon: <span />,
      label: label.label,
    })),
    suiteLabel: labels.map((label) => ({
      checked: !!filters?.suiteLabel?.[label.id],
      onChangeChecked: () => handleChangeChecked('suiteLabel', label.id),
      command: label.label,
      key: label.id,
      handler: () => handleEntityFilter(label.id, 'suiteLabel'),
      icon: <span />,
      label: label.label,
    })),
    plan: plans.map((plan) => ({
      checked: !!filters?.plan?.[plan.id],
      onChangeChecked: () => handleChangeChecked('plan', plan.id),
      command: plan.name,
      key: plan.id,
      handler: () => handleEntityFilter(plan.id, 'plan'),
      icon: <PlanIcon color={vars.textPrimary} size={18} />,
      label: plan.name,
    })),
    requirement: requirements.map((requirement) => ({
      checked: !!filters?.requirement?.[requirement.id],
      onChangeChecked: () => handleChangeChecked('requirement', requirement.id),
      command: requirement.payload.summary,
      key: requirement.id,
      handler: () => handleEntityFilter(requirement.id, 'requirement'),
      icon: <PlanIcon color={vars.textPrimary} size={18} />,
      label: requirement.payload.summary,
    })),
    suiteName:
      current === 'suiteName' && suiteNameSearch
        ? [
            {
              command: suiteNameSearch,
              key: suiteNameSearch,
              handler: () => handleEntityFilter(suiteNameSearch, 'suiteName'),
              icon: <SearchIcon color={vars.textPrimary} size={18} />,
              label: t('commandBarFilter.suiteNameFilter', {
                search: suiteNameSearch,
              }),
            },
          ]
        : [],
    name:
      current === 'name' && nameSearch
        ? [
            {
              command: nameSearch,
              key: nameSearch,
              handler: () => handleEntityFilter(nameSearch, 'name'),
              icon: <SearchIcon color={vars.textPrimary} size={18} />,
              label: t('commandBarFilter.nameFilter', {
                search: nameSearch,
              }),
            },
          ]
        : [],
    actualResult:
      current === 'actualResult' && actualResultSearch
        ? [
            {
              command: actualResultSearch,
              key: actualResultSearch,
              handler: () =>
                handleEntityFilter(actualResultSearch, 'actualResult'),
              icon: <SearchIcon color={vars.textPrimary} size={18} />,
              label: t('commandBarFilter.actualResultFilter', {
                search: actualResultSearch,
              }),
            },
          ]
        : [],
    hasActualResult: [],
    status: statuses.map((status) => ({
      checked: filters.status ? !!filters?.status[status.id] : false,
      onChangeChecked: () => handleChangeChecked('status', status.id),
      command: status.name,
      key: status.id,
      handler: () => handleEntityFilter(status.id, 'status'),
      icon: <span />,
      label: status.name,
    })),
    exclusions: Array.from(BooleanEnumDisplay).map(([value, label]) => ({
      command: t(label),
      key: t(label),
      handler: () =>
        handleEntityFilter(!booleanEnumToBoolean(value), 'exclusions'),
      icon: <span />,
      label: t(label),
    })),
  };

  function handleChangeChecked(filter, value) {
    dispatch(
      toggleFilterAction({
        type,
        filter,
        value,
      }),
    );
  }

  function handleEntityFilter(entityId, entity) {
    dispatch(
      toggleFilterAction({
        type,
        filter: entity,
        value: entityId,
      }),
    );
    handleClose();
  }

  function handleSaveCustomFilter(values) {
    const sameNameFilter = customFilters.find((f) => f.name === values.name);

    if (sameNameFilter) {
      /* NOTE: Set is_shareable just in case the user changed it */
      setCustomFilterToUpdate({
        ...sameNameFilter,
        is_shareable: values.share,
      });
      confirmUpdateDialog.open();
      return Promise.resolve();
    }

    return dispatch(
      filterCreateOneThunk({
        data: {
          payload: filters,
          project_id: parseInt(projectId, 10),
          related_type: type,
          name: values.name,
          is_shareable: values.share,
        },
      }),
    )
      .then(unwrapResult)
      .then((filter) => {
        dispatch(applyCustomFilterAction({ type, filter }));
      })
      .finally(() => {
        saveFilterDialog.close();
        handleClose();
      });
  }

  function onUpdateFilter(values) {
    if (!customFilterToUpdate) return Promise.resolve();
    return dispatch(
      filterUpdateOneThunk({
        id: customFilterToUpdate.id,
        data: { name: values.name, is_shareable: values.share },
      }),
    )
      .then(unwrapResult)
      .finally(updateFilterDialog.close);
  }

  const placeholders = {
    default: t('commandBarFilter.placeholder.default'),
    author: t('commandBarFilter.placeholder.author'),
    caseType: t('commandBarFilter.placeholder.caseType'),
    casePriority: t('commandBarFilter.placeholder.casePriority'),
    assignee: t('commandBarFilter.placeholder.assignee'),
    automated: t('commandBarFilter.placeholder.automated'),
    label: t('commandBarFilter.placeholder.label'),
    suiteLabel: t('commandBarFilter.placeholder.suiteLabel'),
    plan: t('commandBarFilter.placeholder.plan'),
    suiteName: t('commandBarFilter.placeholder.suiteName'),
    name: t('commandBarFilter.placeholder.name'),
    exclusions: t('commandBarFilter.placeholder.exclusions'),
  };

  return (
    <>
      <CommandBar
        commands={commands[current]}
        id={current}
        onChangeSearch={setSearch}
        onClose={handleClose}
        open={open}
        placeholder={placeholders[current] || placeholders.default}
        search={search}
        extraActions={
          isDisplayingFilterTiles && current === DEFAULT ? (
            <SaveFilterMenuItem onSave={saveFilterDialog.open} />
          ) : undefined
        }
      />
      <ConfirmDialog
        open={confirmUpdateDialog.isOpen}
        onCancel={confirmUpdateDialog.close}
        onConfirm={updateCustomFilterMutation.mutate}
        loading={updateCustomFilterMutation.isLoading}
        title={t('commandBarFilter.customFilter.update.dialog.title')}>
        {t('commandBarFilter.customFilter.update.dialog.description', {
          name: customFilterToUpdate?.name || '',
        })}
      </ConfirmDialog>
      <ConfirmDialog
        open={confirmDeleteDialog.isOpen}
        onCancel={confirmDeleteDialog.close}
        onConfirm={deleteCustomFilterMutation.mutate}
        loading={deleteCustomFilterMutation.isLoading}
        title={t('commandBarFilter.customFilter.delete.dialog.title')}>
        {t('commandBarFilter.customFilter.delete.dialog.description', {
          name: customFilterToDelete?.name || '',
        })}
      </ConfirmDialog>
      <SaveFilterDialog
        isOpen={saveFilterDialog.isOpen}
        onClose={saveFilterDialog.close}
        onSubmit={handleSaveCustomFilter}
      />
      <SaveFilterDialog
        isOpen={updateFilterDialog.isOpen}
        onClose={updateFilterDialog.close}
        onSubmit={onUpdateFilter}
        initialValues={{
          name: customFilterToUpdate?.name || '',
          share: customFilterToUpdate?.is_shareable || false,
        }}
      />
    </>
  );
}
