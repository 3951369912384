import { matchPath, useLocation } from 'react-router';
import { Location } from 'history';

export default function useParamsInRoute<
  Params extends { [K in keyof Params]?: string } = {},
>(route: string): Params {
  const location = useLocation<{ modalParentLocation?: Location }>();
  const pathname =
    location.state?.modalParentLocation?.pathname || location.pathname;
  const match = matchPath(pathname, {
    path: route,
  });

  return (match?.params || {}) as Params;
}
