/* NOTE: The Notification entity is a mix of different notifications the app */
/* receives. Some of them are meant to be displayed in the Notifications, */
/* some are meant to update the redux store to keep the app synced, and other */
/* are meant to be delivered to the user's inbox. Depends on its type property. */

export const notifiableNotificationTypes = [
  'App\\Notifications\\AccountVerified',
  'App\\Notifications\\InvoiceCreated',
  'App\\Notifications\\InvoicePaymentFailed',
  'App\\Notifications\\InvoicePaymentSuccessful',
  'App\\Notifications\\SubscriptionAtLimit',
  'App\\Notifications\\SubscriptionAutoUpgradeAttempt',
  'App\\Notifications\\SubscriptionOverLimit',
  'App\\Notifications\\LowSpace',
  'App\\Notifications\\NoSpace',
  'App\\Notifications\\UserInviteComplete',
];

export const watchNotificationTypes = [
  'App\\Notifications\\RunResultUpdate',
  'App\\Notifications\\ProjectComplete',
  'App\\Notifications\\PlanComplete',
  'App\\Notifications\\SuiteComplete',
];
