/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PullRequestApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { checkSuiteNormalizer } from '../check_suite/checkSuiteNormalize';
import { planPullRequestNormalizer } from '../plan_pull_request/planPullRequestNormalize';

export const pullRequestNormalizer = (
  model: PullRequestApi,
  cache: NormalizerCache,
) => {
  if (!cache.pullRequest) {
    cache.pullRequest = [];
  }
  cache.pullRequest.push(model);

  if (model.check_suite && model.check_suite.length) {
    model.check_suite.forEach((child) => {
      checkSuiteNormalizer(child, cache);
    });
    model.check_suite = undefined;
  }
  if (model.plan_pull_request && model.plan_pull_request.length) {
    model.plan_pull_request.forEach((child) => {
      planPullRequestNormalizer(child, cache);
    });
    model.plan_pull_request = undefined;
  }
};
