import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { State } from '@bitmodern/redux/store';
import { IntegrationUser, User } from '@testquality/sdk';
import { userSelectors } from 'src/gen/domain/user/userSelector';

export const usersSelector = createSelector(
  (state: State) => state.gen.user.entities,
  (users) =>
    filter(
      users,
      (user?: User) => user !== undefined && !user.is_system,
    ) as User[],
);

export function userSelectorById(state: State, id: number) {
  return userSelectors.selectById(state, id);
}

export const userByIntegrationUsernameSelector = createSelector(
  [
    (state: State) => state.gen.user.entities,
    (state: State) => state.gen.integrationUser.entities,
    (state: State, username: string) => username,
  ],
  (users, integrationUsers, username) => {
    const ius = filter(
      integrationUsers,
      (iu?: IntegrationUser) => iu?.username === username,
    ) as IntegrationUser[];
    if (ius.length === 0 || !ius[0].user_id) return undefined;

    // todo: not sure why this is considered singular
    const user = users[ius[0].user_id];
    return user;
  },
);
