/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { appVersionPlatVersionPlanAdapter } from './appVersionPlatVersionPlanAdapter';

export const appVersionPlatVersionPlanSelectors =
  appVersionPlatVersionPlanAdapter.getSelectors(
    (state: State) => state.gen.appVersionPlatVersionPlan,
  );
