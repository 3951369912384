/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { appInstallProjectAdapter } from './appInstallProjectAdapter';

export const appInstallProjectSelectors = appInstallProjectAdapter.getSelectors(
  (state: State) => state.gen.appInstallProject,
);
