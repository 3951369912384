/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { capabilityIntegrationAdapter } from './capabilityIntegrationAdapter';

export const capabilityIntegrationSelectors =
  capabilityIntegrationAdapter.getSelectors(
    (state: State) => state.gen.capabilityIntegration,
  );
