import { useCallback, useRef, useState } from 'react';

export default function usePersistedState<S>(
  key: string,
  initialValue: S,
): [S, (value: S | ((value: S) => S)) => void] {
  const [storedValue, setStoredValue] = useState<S>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const current = useRef<S>(storedValue);

  const setValue = useCallback(
    (value: any | ((value: any) => any)) => {
      const valueToStore =
        value instanceof Function ? value(current.current) : value;
      current.current = valueToStore;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    },
    [key],
  );

  return [storedValue, setValue];
}
