import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { InvitesStepFormValues } from './InvitesStepForm';

const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .transform(function stringToArray(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/).filter(Boolean) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(),
  accessRole: Yup.mixed()
    .required()
    .label('getStarted.invitesStep.form.accessRole'),
});

const defaultInitialValues: InvitesStepFormValues = {
  emails: '',
  accessRole: undefined,
};

export type useInvitesStepFormOptions = {
  intitalValues?: InvitesStepFormValues;
  onSubmit: () => void;
};

export function useInvitesStepForm({
  initialValues = defaultInitialValues,
  onSubmit,
}) {
  const formik = useFormik<InvitesStepFormValues>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });
  return formik;
}
