/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AccessRoleApi } from '@testquality/sdk';
import { AccessRoleUser } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { policyNormalizer } from '../policy/policyNormalize';
import { projectNormalizer } from '../project/projectNormalize';
import { userNormalizer } from '../user/userNormalize';
import { accessRoleUserNormalizer } from '../access_role_user/accessRoleUserNormalize';

export const accessRoleNormalizer = (
  model: AccessRoleApi,
  cache: NormalizerCache,
) => {
  if (!cache.accessRole) {
    cache.accessRole = [];
  }
  cache.accessRole.push(model);

  if (model.policy && model.policy.length) {
    model.policy.forEach((child) => {
      policyNormalizer(child, cache);
    });
    model.policy = undefined;
  }
  if (model.project && model.project.length) {
    model.project.forEach((child) => {
      projectNormalizer(child, cache);
    });
    model.project = undefined;
  }
  if (model.user && model.user.length) {
    model.user.forEach((child) => {
      accessRoleUserNormalizer(child.pivot as AccessRoleUser, cache);
      child.pivot = undefined;
      userNormalizer(child, cache);
    });
    model.user = undefined;
  }
};
