/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Label,
  labelGetMany,
  labelGetOne,
  labelDeleteOne,
  labelUpdateOne,
  labelCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { labelNormalizer } from './labelNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const labelFetchManyThunk = createAsyncThunk(
  'label/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Label>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await labelGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, labelNormalizer);
      }
      return {
        label: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const labelFetchOneThunk = createAsyncThunk(
  'label/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Label>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'label/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await labelGetOne(arg.id as number, arg);
      return executeNormalizer([data], labelNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const labelDeleteOneThunk = createAsyncThunk(
  'label/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Label>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'label/DeleteOneThunk');
      }
      return await labelDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const labelUpdateOneThunk = createAsyncThunk(
  'label/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Label>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'label/UpdateOneThunk');
      }
      const data = await labelUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Label>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Label>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const labelCreateOneThunk = createAsyncThunk(
  'label/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Label>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'label/CreateOneThunk',
        );
      }
      return await labelCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
