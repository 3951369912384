/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { userAdapter } from './userAdapter';

export const userSelectors = userAdapter.getSelectors(
  (state: State) => state.gen.user,
);
