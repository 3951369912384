import React from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
} from '@bitmodern/bit-ui';
import useModalManager from 'src/hooks/useModalManager';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch } from '@bitmodern/redux/store';
import { planUpdateOneThunk } from 'src/gen/domain/plan/planThunk';
import { Plan } from '@testquality/sdk';
import styles from './PlanEditView.module.scss';

const initialValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('planEdit.form.name'),
});

type Props = {
  plan: Plan;
};

export default function PlanEditView({ plan }: Props) {
  const { hideModal } = useModalManager();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { name: plan.name },
    onSubmit,
    validationSchema,
  });

  function onSubmit({ name }: typeof initialValues) {
    if (!plan) return Promise.resolve();

    return dispatch(
      planUpdateOneThunk({ id: plan.id, data: { name }, optimistic: true }),
    ).finally(hideModal);
  }

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="small"
      title={t('planEdit.title')}>
      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <DialogContent className={styles.content}>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('planEdit.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.name}
          />
        </DialogContent>
        <PanelActions>
          <Button loading={formik.isSubmitting} type="submit">
            {t('planEdit.form.submit')}
          </Button>
        </PanelActions>
      </form>
    </Dialog>
  );
}
