import React from 'react';
import { useParams } from 'react-router';
import { SettingsPanel, UserForm } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { userSelectorById } from '@bitmodern/redux/state/users/selectors';
import { useAppSelector } from '@bitmodern/redux/store';

export default function UserEditView() {
  const { site, userId } = useParams<typeof routes.USER.params>();

  const user = useAppSelector((state) =>
    userSelectorById(state, parseInt(userId, 10)),
  );

  if (!user) return null;

  const getTitle = () => {
    const title: string[] = [];

    if (user.given_name) title.push(user.given_name);
    if (user.family_name) title.push(user.family_name);

    if (!title.length) return null;

    return title.join(' ');
  };

  return (
    <SettingsPanel back={routes.USERS({ site })} title={getTitle()}>
      <UserForm user={user} />
    </SettingsPanel>
  );
}
