/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { NativeDefectResHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { nativeDefectResHistoryAdapter } from './nativeDefectResHistoryAdapter';
import { nativeDefectResHistoryFetchThunk } from './nativeDefectResHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const nativeDefectResHistorySlice = createSlice({
  name: 'nativeDefectResHistory',
  initialState:
    nativeDefectResHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    nativeDefectResHistoryCleared(state) {
      state.loading = 'idle';
      nativeDefectResHistoryAdapter.removeAll(state);
    },
    nativeDefectResHistoryUpsertMany(
      state,
      action: PayloadAction<
        NativeDefectResHistory[] | Record<number, NativeDefectResHistory>
      >,
    ) {
      nativeDefectResHistoryAdapter.upsertMany(state, action.payload);
    },
    nativeDefectResHistoryUpsertOne(
      state,
      action: PayloadAction<NativeDefectResHistory>,
    ) {
      nativeDefectResHistoryAdapter.upsertOne(state, action.payload);
    },
    nativeDefectResHistoryUpdateOne(
      state,
      action: PayloadAction<Update<NativeDefectResHistory>>,
    ) {
      nativeDefectResHistoryAdapter.updateOne(state, action.payload);
    },
    nativeDefectResHistoryRemoveOne(state, action: PayloadAction<number>) {
      nativeDefectResHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nativeDefectResHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      nativeDefectResHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectResHistoryAdapter.upsertMany(
          state,
          action.payload.nativeDefectResHistory,
        );
      },
    );
    builder.addCase(nativeDefectResHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  nativeDefectResHistoryCleared,
  nativeDefectResHistoryUpsertMany,
  nativeDefectResHistoryUpsertOne,
  nativeDefectResHistoryUpdateOne,
  nativeDefectResHistoryRemoveOne,
} = nativeDefectResHistorySlice.actions;

export const nativeDefectResHistoryReducer =
  nativeDefectResHistorySlice.reducer;
