import { IDBPDatabase, openDB } from 'idb';
import { Cache } from './Cache';

const CACHE_STORE = 'keyvalCache';
const SCHEMA_VERSION = 1;

export default class IndexedDbCache implements Cache {
  db: Promise<IDBPDatabase<unknown>>;

  constructor() {
    this.db = openDB('TQCache', SCHEMA_VERSION, {
      upgrade(db) {
        try {
          db.deleteObjectStore(CACHE_STORE);
        } catch (error) {}
        db.createObjectStore(CACHE_STORE);
      },
    });
  }

  async get(key: string) {
    const db = await this.db;
    const value = await db.get(CACHE_STORE, key);
    if (!value) return undefined;
    if (
      value.expiresAt &&
      new Date().getTime() > new Date(value.expiresAt).getTime()
    ) {
      this.delete(key);
      return undefined;
    }
    return value.value;
  }

  async set(key: string, value, expiresIn?: number) {
    const expiresAt = expiresIn ? new Date() : undefined;
    (await this.db).put(
      CACHE_STORE,
      {
        addedAt: new Date().toISOString(),
        expiresAt,
        value,
      },
      key,
    );
  }

  async delete(key: string) {
    (await this.db).delete(CACHE_STORE, key);
  }

  async clear() {
    (await this.db).clear(CACHE_STORE);
  }
}
