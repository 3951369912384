/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { IntegrationApi } from '@testquality/sdk';
import { IntegrationProject } from '@testquality/sdk';
import { IntegrationUser } from '@testquality/sdk';
import { CapabilityIntegration } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { projectNormalizer } from '../project/projectNormalize';
import { integrationProjectNormalizer } from '../integration_project/integrationProjectNormalize';
import { userNormalizer } from '../user/userNormalize';
import { integrationUserNormalizer } from '../integration_user/integrationUserNormalize';
import { capabilityNormalizer } from '../capability/capabilityNormalize';
import { capabilityIntegrationNormalizer } from '../capability_integration/capabilityIntegrationNormalize';
import { appInstallNormalizer } from '../app_install/appInstallNormalize';

export const integrationNormalizer = (
  model: IntegrationApi,
  cache: NormalizerCache,
) => {
  if (!cache.integration) {
    cache.integration = [];
  }
  cache.integration.push(model);

  if (model.project && model.project.length) {
    model.project.forEach((child) => {
      integrationProjectNormalizer(child.pivot as IntegrationProject, cache);
      child.pivot = undefined;
      projectNormalizer(child, cache);
    });
    model.project = undefined;
  }
  if (model.user && model.user.length) {
    model.user.forEach((child) => {
      integrationUserNormalizer(child.pivot as IntegrationUser, cache);
      child.pivot = undefined;
      userNormalizer(child, cache);
    });
    model.user = undefined;
  }
  if (model.capability && model.capability.length) {
    model.capability.forEach((child) => {
      capabilityIntegrationNormalizer(
        child.pivot as CapabilityIntegration,
        cache,
      );
      child.pivot = undefined;
      capabilityNormalizer(child, cache);
    });
    model.capability = undefined;
  }
  if (model.app_install && model.app_install.length) {
    model.app_install.forEach((child) => {
      appInstallNormalizer(child, cache);
    });
    model.app_install = undefined;
  }
};
