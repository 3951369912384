/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AppVersionPlatVersionApi } from '@testquality/sdk';
import { AppVersionPlatVersionPlan } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { planNormalizer } from '../plan/planNormalize';
import { appVersionPlatVersionPlanNormalizer } from '../app_version_plat_version_plan/appVersionPlatVersionPlanNormalize';
import { runResultNormalizer } from '../run_result/runResultNormalize';

export const appVersionPlatVersionNormalizer = (
  model: AppVersionPlatVersionApi,
  cache: NormalizerCache,
) => {
  if (!cache.appVersionPlatVersion) {
    cache.appVersionPlatVersion = [];
  }
  cache.appVersionPlatVersion.push(model);

  if (model.plan && model.plan.length) {
    model.plan.forEach((child) => {
      appVersionPlatVersionPlanNormalizer(
        child.pivot as AppVersionPlatVersionPlan,
        cache,
      );
      child.pivot = undefined;
      planNormalizer(child, cache);
    });
    model.plan = undefined;
  }
  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
};
