import React, { useCallback } from 'react';
import {
  Alert,
  Button,
  notification,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@bitmodern/bit-ui';
import { ErrorIcon } from '@bitmodern/bit-ui/icons';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import {
  cancelSubscriptionThunk,
  refreshAndFetch,
  subscribeThunk,
} from '@bitmodern/redux/state/subscriptions/thunks';
import {
  activeOpenSourceSubscriptionSelector,
  activeSubscriptionsSelector,
} from '@bitmodern/redux/state/subscriptions/selectors';
import { tokenSelector } from '@bitmodern/redux/state/authentication/selectors';
import { githubProductSelector } from '@bitmodern/redux/state/products/selectors';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import CancelSubscription from '../CancelSubscription';
import IntegrationProjectItem from './IntegrationProjectItem';
import styles from './OpenSourceSubscription.module.scss';

export default function OpenSourceSubscription() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeSubscriptions = useAppSelector(activeSubscriptionsSelector);
  const token = useAppSelector(tokenSelector);
  const projects = useAppSelector(projectsSelector);
  const githubProduct = useAppSelector(githubProductSelector);
  const activeOpenSourceSubscription = useAppSelector(
    activeOpenSourceSubscriptionSelector,
  );

  const onSubscribe = useCallback(async () => {
    if (!githubProduct) return;
    if (activeSubscriptions.length) return;

    await dispatch(subscribeThunk(githubProduct.id, 1, false));
    notification.open({
      type: 'success',
      message: 'Subscription created',
    });
  }, [dispatch, githubProduct, activeSubscriptions]);

  const subscribeMutation = useMutation(onSubscribe);

  const cancelSubscription = () => {
    if (!activeOpenSourceSubscription) return Promise.resolve();
    return dispatch(cancelSubscriptionThunk(activeOpenSourceSubscription.id));
  };

  const onProjectDeleted = () => {
    return dispatch(refreshAndFetch());
  };

  return (
    <div className={styles.openSource}>
      {token?.failures?.length ? (
        <Alert
          icon={<ErrorIcon color={vars.errorMain} />}
          size="large"
          title={t('integrationProjectCreate.invalid.title')}>
          <ul className={styles.errorList}>
            {
              (token.failures,
              token.failures.map((failure, i) => (
                <li className={styles.error} key={i}>
                  {failure.message}
                </li>
              )))
            }
          </ul>
        </Alert>
      ) : (
        <div className={styles.explain}>
          {t('integrationProjectCreate.description')}
        </div>
      )}

      <Table>
        <TableHead className={styles.head}>
          <TableCell>{t('integrationProjectCreate.head.project')}</TableCell>
          <TableCell colSpan={2}>
            {t('integrationProjectCreate.head.remoteProject')}
          </TableCell>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <IntegrationProjectItem
              key={project.id}
              onProjectDeleted={onProjectDeleted}
              project={project}
            />
          ))}
        </TableBody>
      </Table>

      {activeSubscriptions.length === 0 && (
        <Button
          className={styles.subscribe}
          loading={subscribeMutation.isLoading}
          onClick={subscribeMutation.mutate}>
          Subscribe
        </Button>
      )}

      {activeOpenSourceSubscription && (
        <CancelSubscription
          className={styles.cancelSubscription}
          onCancel={cancelSubscription}
        />
      )}
    </div>
  );
}
