import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import {
  defaultGetNodeKey,
  getFlatDataFromTree,
  TreeItem,
} from 'react-sortable-tree';
import {
  Defect,
  PlanSuite,
  RunResult,
  RunResultStep,
  SuiteTest,
  Test,
} from '@testquality/sdk';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import { filterTree, generateNodeKey, getTreeFromArray } from 'src/utils/tree';

import { filterTest } from '../suites/selectors';
import { currentUserSelector } from '../authentication/selectors';
import {
  pendingStatusSelector,
  retestStatusSelector,
  statusSelector,
} from '../statuses/selectors';
import { filterLabelAssignedByTestId } from '../label_assigned/selectors';
import { defectRunResultSelectors } from 'src/gen/domain/defect_run_result/defectRunResultSelector';
// import { defectsByTestSelector } from '../defects/selectors';

const DUMMY_ROOT_KEY = 0;

export type RunResultTreeItem = TreeItem & {
  data: {
    test: Test;
    runResults: RunResult[];
    runResultSteps: RunResultStep[];
    suiteTest: SuiteTest;
  };
};

export function runResultsSelector(state) {
  return runResultSelectors.selectAll(state);
}

function filterRunResults(
  filters: State['filters']['runs'],
  labelAssigneds,
  { runResults, test, runResultSteps }: RunResultTreeItem['data'],
) {
  const labels = filterLabelAssignedByTestId(labelAssigneds, test.id);

  const filtered = filterTest(filters, labels, { test }, { assignee: true });
  if (!filtered) return false;

  if (filters.assignee) {
    if (
      !runResults.some((rr) => {
        if (!filters.assignee) return false;
        return !!filters.assignee[rr?.assigned_to_tester || ''];
      })
    ) {
      return false;
    }
  }
  if (filters.status) {
    if (
      !runResults.some((rr) => {
        if (!filters.status) return false;
        return filters.status[rr.status_id];
      })
    )
      return false;
  }
  if (filters.hasActualResult) {
    const hasResult = runResultSteps.some((rrs) => !!rrs.result);
    if (!hasResult) return false;
  }
  if (filters.actualResult) {
    const actualResults = runResultSteps
      .filter((rrs) => Boolean(rrs.result))
      .map((rrs) => rrs.result);
    if (!actualResults.length) return false;
    const actualResultFilter = filters.actualResult.toLowerCase();
    const includes = actualResults.some((actualResult) => {
      if (!actualResult || !actualResultFilter) return false;
      return actualResult.toLowerCase().includes(actualResultFilter);
    });
    if (!includes) return false;
  }

  return true;
}

export const runResultsByRunSelector = createSelector(
  [
    (state: State) => state.gen.run.entities,
    (state: State) => state.gen.plan.entities,
    (state: State) => state.gen.runResult.entities,
    (state: State) => state.gen.planSuite.entities,
    (state: State) => state.gen.suiteTest.entities,
    (state: State) => state.gen.runResultStep.entities,
    (state: State) => state.gen.test.entities,
    (state: State) => state.gen.suite.entities,
    (state: State) => state.gen.labelAssigned.entities,
    (state: State) => state.filters.runs,
    (state: State, params: { runId?: number }) => params.runId,
  ],
  (
    runs,
    plans,
    runResults,
    planSuites,
    suiteTests,
    runResultSteps,
    tests,
    suites,
    labelAssigneds,
    filters,
    runId,
  ): RunResultTreeItem[] => {
    if (runId === undefined) return [];
    const run = runs[runId];
    if (!run) return [];

    const rootPlan = find(plans, (plan) => {
      return plan?.is_root === true && plan?.project_id === run.project_id;
    });
    if (!rootPlan) return [];

    const runRunResults = filter(
      runResults,
      (runResult?: RunResult) => runResult?.run_id === runId,
    ) as RunResult[];
    if (!runRunResults.length) return [];

    const runResultsMap = runRunResults.reduce<{ [key: string]: RunResult[] }>(
      (map, runResult) => {
        const key = `T${runResult.test_id}-S${runResult.suite_id}`;
        if (!map[key]) map[key] = [];
        map[key].push(runResult);
        return map;
      },
      {},
    );

    const planSuitesFiltered = filter(
      planSuites,
      (ps1?: PlanSuite): boolean => ps1?.plan_id === rootPlan.id,
    ).map((ps) => {
      if (!ps?.parent_id) return { ...ps, parent_id: DUMMY_ROOT_KEY };
      return ps;
    }) as PlanSuite[];

    const suitesIds = planSuitesFiltered.map((planSuite) => planSuite.suite_id);
    // .filter((id, index, array) => {
    //   return array.indexOf(id) === index;
    // });

    // suiteTests with run results
    const suiteTestsFiltered = filter(
      suiteTests,
      (suiteTest?: SuiteTest): boolean => {
        return (
          suiteTest !== undefined &&
          suitesIds.includes(suiteTest.suite_id) &&
          runResultsMap[`T${suiteTest.test_id}-S${suiteTest.suite_id}`] !==
            undefined
        );
      },
    ) as SuiteTest[];

    const flatData = [...planSuitesFiltered, ...suiteTestsFiltered].sort(
      (a, b) => {
        const seqA = 'sequence_suite' in a ? a.sequence_suite : a.sequence_plan;
        const seqB = 'sequence_suite' in b ? b.sequence_suite : b.sequence_plan;
        return seqA - seqB;
      },
    );

    const runResultsStepsMap = reduce(
      runResultSteps,
      (acu, runResultStep) => {
        if (runResultStep) {
          if (!acu[runResultStep.run_result_id]) {
            acu[runResultStep.run_result_id] = [];
          }
          acu[runResultStep.run_result_id].push(runResultStep);
        }
        return acu;
      },
      {},
    );

    const data = getTreeFromArray({
      flatData,
      rootKey: `S-${DUMMY_ROOT_KEY}`,
      getKey: (node) => {
        if (node.test_id) return node.id?.toString();
        return `S-${node.suite_id}`;
      },
      getParentKey: (node) => {
        if (node.test_id) return `S-${node.suite_id}`;
        return `S-${node.parent_id}`;
      },
      transform: (item) => {
        if (item.test_id) {
          const suiteTest = item;
          const test = tests[suiteTest.test_id];
          const runResultsNode =
            runResultsMap[`T${suiteTest.test_id}-S${suiteTest.suite_id}`];
          const runResultStepsReduce = runResultsNode.reduce<RunResultStep[]>(
            (acu, runResultItem) => {
              const steps = runResultsStepsMap[runResultItem.id];
              if (steps) acu.push(...steps);
              return acu;
            },
            [],
          );

          return {
            type: TEST_TYPE,
            title: `TC${test?.key} ${test?.name}`,
            data: {
              test,
              runResults: runResultsNode,
              runResultSteps: runResultStepsReduce,
              suiteTest,
            },
            nodeKey: generateNodeKey({ runResult: runResultsNode[0] }),
          };
        }

        const planSuite = item;
        const suite = suites[planSuite.suite_id];
        return {
          type: SUITE_TYPE,
          title: suite?.name,
          data: { suite, planSuite },
          nodeKey: generateNodeKey({ planSuite }),
        };
      },
    }) as RunResultTreeItem[];

    const withoutRoot = removeRootFolder(data);

    const hasFilters = Object.keys(filters).length > 0;

    return filterTree(withoutRoot, (item) => {
      if (item.type === SUITE_TYPE && !item?.children?.length) return false;
      if (hasFilters && item.type === TEST_TYPE) {
        return filterRunResults(filters, labelAssigneds, {
          test: item.data.test,
          runResults: item.data.runResults,
          runResultSteps: item.data.runResultSteps,
          suiteTest: item.data.suiteTest,
        });
      }
      return true;
    });
  },
);

export function runResultsByRunIdsSelector(state, runsIds: number[]) {
  let runResults: RunResultTreeItem[] = [];
  runsIds.forEach((id) => {
    runResults = runResults.concat(
      runResultsByRunSelector(state, { runId: id }),
    );
  });
  return runResults;
}

export function removeRootFolder(tree: TreeItem) {
  if (tree?.[0]?.data?.suite.is_root) {
    return tree?.[0]?.children || [];
  }

  return tree;
}

export const testByRunResultSelector = createSelector(
  [
    (state: State) => state.gen.runResult.entities,
    (state: State) => state.gen.test.entities,
    (state: State, runResultId: number) => runResultId,
  ],
  (runResults, tests, runResultId) => {
    const runResult = runResults[runResultId];
    if (!runResult) return undefined;
    return find(tests, (test?: Test) => test?.id === runResult.test_id);
  },
);

export const runResultsByTestSelector = createSelector(
  [runResultSelectors.selectAll, (_, testId: number) => testId],
  (RunResults, testId) => {
    return RunResults.filter((rr) => rr.test_id === testId);
  },
);

export const runResultsByRunIdSelector = createSelector(
  [runResultSelectors.selectAll, (_, { runId }: { runId?: number }) => runId],
  (RunResults, runId) => {
    return RunResults.filter((rr) => rr.run_id === runId);
  },
);

export const runResultsByRunIdAssignedToSelfSelector = createSelector(
  runResultsByRunIdSelector,
  currentUserSelector,
  pendingStatusSelector,
  retestStatusSelector,
  (RunResults, currentUser, pendingStatus, retestStatus) => {
    return RunResults.filter((rr) => {
      if (rr.assigned_to_tester !== currentUser?.id) return false;
      if (
        rr.status_id === pendingStatus?.id ||
        rr.status_id === retestStatus?.id
      )
        return true;

      return false;
    });
  },
);

export function treePrevNextSelector(
  state: State,
  runId: number,
  runResultId: number,
): { prev?: RunResult; next?: RunResult } {
  const flat = getFlatDataFromTree({
    treeData: runResultsByRunSelector(state, { runId }),
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
  });
  const index = flat.findIndex((item) => {
    return item.node?.data?.runResults?.some((rr) => rr.id === runResultId);
  });
  if (index === -1) return {};

  const runResult = runResultSelector(state, runResultId);
  if (!runResult) return {};
  const prevItem = findPrevious(flat, index);
  const nextItem = findNextRunResult(flat, index);

  const prevResults = prevItem?.node?.data?.runResults || [];
  const nextResults = nextItem?.node?.data?.runResults || [];
  const prev = prevResults.find(
    (rr) =>
      rr?.app_version_plat_version_id === runResult.app_version_plat_version_id,
  );
  const next = nextResults.find(
    (rr) =>
      rr?.app_version_plat_version_id === runResult.app_version_plat_version_id,
  );

  return {
    prev: prev || prevResults[0],
    next: next || nextResults[0],
  };
}

function findPrevious<A extends any[]>(array: A, startIndex: number = 0) {
  for (let i = startIndex - 1; i > 0; i -= 1) {
    if (array[i].node?.type === TEST_TYPE) {
      return array[i];
    }
  }

  for (let i = array.length - 1; i > startIndex; i -= 1) {
    if (array[i].node?.type === TEST_TYPE) {
      return array[i];
    }
  }
  return undefined;
}

function findNextRunResult<A extends any[]>(array: A, startIndex: number = 0) {
  for (let i = startIndex + 1; i < array.length; i += 1) {
    if (array[i].node?.type === TEST_TYPE) {
      return array[i];
    }
  }

  for (let i = 0; i < startIndex; i += 1) {
    if (array[i].node?.type === TEST_TYPE) {
      return array[i];
    }
  }
  return undefined;
}

export function runResultSelector(state: State, runResultId: number) {
  return runResultSelectors.selectById(state, runResultId);
}

export const runResultByDefectSelector = createSelector(
  [
    (state: State) => state.gen.runResult.entities,
    (state: State, params: { defect?: Defect }) => params.defect,
  ],
  (runResults, defect) =>
    find(runResults, (rr?: RunResult) => defect?.related_id === rr?.id),
);

const createRunResultsByDefectSelector = () =>
  createSelector(
    [runResultSelectors.selectEntities, (_, args: { defect?: Defect }) => args],
    (runResults, { defect }) => {
      if (!runResults || !defect) return [];
      const toReturn: RunResult[] = [];
      for (const id in runResults) {
        const runResult = runResults[id];
        if (runResult && runResult.id === defect.related_id)
          toReturn.push(runResult);
      }
      return toReturn;
    },
  );

export const selectRunResultsByRequirementId = createSelector(
  [
    (state: State) => state,
    (state: State) => state.gen.runResult.entities,
    (state: State) => state.gen.requirementTest.entities,
    (state: State, requirementId) => requirementId,
  ],
  (state, runResults, requirementTest, requirementId) => {
    const testIds = filter(
      requirementTest,
      (rt) => rt?.requirement_id === requirementId,
    ).map((rt) => rt?.test_id);
    return filter(runResults, (rr) =>
      testIds.some((id) => id === rr?.test_id),
    ) as RunResult[];
  },
);

export function runResultsConfigurationStatusByRunId(
  state: State,
  runId: number,
) {
  const runResults = runResultsByRunIdSelector(state, { runId });
  return runResults.reduce((acc, next) => {
    const key = next.app_version_plat_version_id!;
    if (!acc[key]) {
      acc[key] = {
        pass: 0,
        fail: 0,
        skip: 0,
        defects: 0,
      };
    }

    const status = statusSelector(state, next.status_id);
    switch (status?.key) {
      case 1:
        acc[key].pass += 1;
        break;
      case 3:
        acc[key].fail += 1;
        break;
      case 6:
        acc[key].skip += 1;
        break;
      default:
        break;
    }
    // FIXME: Get the correct amount of defects
    // acc[key].defects += defectsByTestSelector(
    //   state,
    //   next.test_id,
    // ).length;
    return acc;
  }, {});
}

export const runResultByIdSelector = createSelector(
  [
    (state: State) => state.gen.runResult.entities,
    (state: State, params: { ids: number[] }) => params.ids,
  ],
  (runResults, ids) =>
    (filter(runResults, (rr) => ids.includes(rr!.id)) || []) as RunResult[],
);

export const makeRunResultsByDefectId = () =>
  createSelector(
    [
      defectRunResultSelectors.selectEntities,
      runResultSelectors.selectAll,
      (_, args: { defect?: Defect }) => args,
    ],
    (defectRunResults, runResults, { defect }) => {
      const runResultIds: number[] = [];
      if (!defectRunResults || !runResults.length || !defect) return [];

      for (const id in defectRunResults) {
        const defectRunResult = defectRunResults[id];
        if (
          defectRunResult?.run_result_id &&
          defectRunResult?.defect_id === defect.id
        ) {
          runResultIds.push(defectRunResult.run_result_id);
        }
      }
      return runResults.filter((rr) => runResultIds.some((id) => rr.id === id));
    },
  );

// This will get all runResults related by related_id and by the join table, then filter duplicates
// TODO: Should be deprecated after migrating defects with related_id
export const makeRunResultsByDefect = () =>
  createSelector(
    [
      createRunResultsByDefectSelector(),
      makeRunResultsByDefectId(),
      (_, args: { defect?: Defect }) => args,
    ],
    (runResults, runResultsJoinTable, { defect }) => {
      if (!runResults || !runResultsJoinTable || !defect) return [];

      const results = [...runResults, ...runResultsJoinTable];

      const filteredResults = results.reduce((acc: RunResult[], next) => {
        if (!acc.some((a) => a.id === next.id)) {
          acc.push(next);
        }
        return acc;
      }, []);
      return filteredResults;
    },
  );

export const runResultByDefectWithJoinTable = makeRunResultsByDefect();
export const runResultsByDefectId = makeRunResultsByDefectId();
