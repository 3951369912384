/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { capabilityAdapter } from './capabilityAdapter';

export const capabilitySelectors = capabilityAdapter.getSelectors(
  (state: State) => state.gen.capability,
);
