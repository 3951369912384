/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  NativeDefectStatus,
  NativeDefectStatusApi,
  nativeDefectStatusGetMany,
  nativeDefectStatusGetOne,
  nativeDefectStatusDeleteOne,
  nativeDefectStatusUpdateOne,
  nativeDefectStatusCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { nativeDefectStatusNormalizer } from './nativeDefectStatusNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const nativeDefectStatusFetchManyThunk = createAsyncThunk(
  'nativeDefectStatus/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<NativeDefectStatus>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await nativeDefectStatusGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(
          resourceList.data,
          nativeDefectStatusNormalizer,
        );
      }
      return {
        nativeDefectStatus: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const nativeDefectStatusFetchOneThunk = createAsyncThunk(
  'nativeDefectStatus/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<NativeDefectStatus>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'nativeDefectStatus/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await nativeDefectStatusGetOne(arg.id as number, arg);
      return executeNormalizer([data], nativeDefectStatusNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const nativeDefectStatusDeleteOneThunk = createAsyncThunk(
  'nativeDefectStatus/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<NativeDefectStatus>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'nativeDefectStatus/DeleteOneThunk',
        );
      }
      return await nativeDefectStatusDeleteOne(arg.id as number, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const nativeDefectStatusUpdateOneThunk = createAsyncThunk(
  'nativeDefectStatus/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<NativeDefectStatusApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'nativeDefectStatus/UpdateOneThunk',
        );
      }
      const data = await nativeDefectStatusUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<NativeDefectStatus>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<NativeDefectStatus>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const nativeDefectStatusCreateOneThunk = createAsyncThunk(
  'nativeDefectStatus/CreateOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<NativeDefectStatusApi>,
    { dispatch },
  ) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'nativeDefectStatus/CreateOneThunk',
        );
      }
      const value = await nativeDefectStatusCreateOne(arg.data, arg);
      dispatch(
        nativeDefectStatusFetchOneThunk({
          id: value.id,
          params: { _with: 'defect_status' },
        }),
      );
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
