import React, { useMemo, useState, useCallback } from 'react';
import { Checkbox, IconButton, Select } from '@bitmodern/bit-ui';
import { ArrowDownIcon } from '@bitmodern/bit-ui/icons';
import { CasePriority, CaseType, User } from '@testquality/sdk';

import { useTranslation } from 'src/i18n/hooks';
import {
  EntityAttribute,
  mappingSelectOptions,
} from './utils/constants/MappingItemSelectOptions';
import { MappingType, CustomMappingType } from '../MappingTypes';
import getId from '../utils/functions/generateId';
import styles from './MappingItem.module.scss';

type Props = {
  mapping: MappingType;
  onChangeMapping: (mapping: MappingType) => void;
  caseTypes: CaseType[];
  casePriorities: CasePriority[];
  users: User[];
  mappingOptions: EntityAttribute[];
};

export default function MappingItem({
  mapping,
  onChangeMapping,
  caseTypes,
  casePriorities,
  users,
  mappingOptions,
}: Props) {
  const { t } = useTranslation();

  const [isOpenCustomize, setIsOpenCustomize] = useState(false);

  const customizeOptions = mapping.data;

  const onChangeSelect = useCallback(
    (value: string) => {
      const option = mappingSelectOptions.find(
        (optionSO) => value === getId(optionSO),
      );

      onChangeMapping({
        ...mapping,
        to: option,
      });
    },
    [mapping, onChangeMapping],
  );

  const options = useMemo(
    () =>
      mappingOptions.map((option) => ({
        label: option.display_name,
        value: getId(option),
      })),
    [mappingOptions],
  );

  const associations = useMemo<any[]>(() => {
    switch (mapping.to?.association_entity) {
      case 'case_type':
        return caseTypes;
      case 'case_priority':
        return casePriorities;
      case 'user':
        return users;
      default:
        return [];
    }
  }, [casePriorities, caseTypes, mapping.to, users]);

  const customOptions = useMemo(() => {
    return associations.map((cuOp, index) => ({
      id: index,
      from: cuOp.name || cuOp.email,
      to: {
        id: cuOp.id,
        key: cuOp.key,
        name: cuOp.name || cuOp.email,
        description: cuOp.description,
        client_id: cuOp.client_id,
        virtual: cuOp.virtual,
        metadata_model: cuOp.metadata_model,
      },
    }));
  }, [associations]);

  const entityOptions = useMemo(() => {
    return associations.map((op) => ({
      label: op.name || op.email,
      value: `${op.id}.${op.name || op.email}`,
    }));
  }, [associations]);

  const onChangeCustomMapping = (value, cuOption) => {
    if (!mapping.data) return;
    const customFounded = customOptions.find(
      (option) => value === customMappingToValue(option),
    );
    if (!customFounded || !mapping.data) return;
    onChangeMapping({
      ...mapping,
      data: mapping.data.map((item) => {
        if (cuOption.from !== item.from) return item;
        return { ...item, to: customFounded.to };
      }),
    });
  };
  return (
    <>
      <div className={styles.container}>
        <Checkbox
          checked={mapping.include}
          onChange={(value) => {
            onChangeMapping({
              ...mapping,
              include: value,
            });
          }}
        />
        <div className={styles.headers}>{mapping.from?.name}</div>
        <div className={styles.option}>
          <Select
            className={styles.select}
            onChange={onChangeSelect}
            options={options}
            placeholder={t('importDataForm.csv.extraFields.selectPlaceholder')}
            value={getId(mapping.to)}
          />
          {customizeOptions && customizeOptions?.length > 0 && (
            <IconButton
              boxed
              className={styles.collapse}
              onClick={() => setIsOpenCustomize((s) => !s)}
              title={t('importDataForm.csv.openAdvance')}>
              <ArrowDownIcon size={24} />
            </IconButton>
          )}
        </div>
      </div>

      {isOpenCustomize && (
        <div className={styles.customizeContainer}>
          {mapping?.data?.map((cuOption) => (
            <div key={cuOption.id} className={styles.customizeItem}>
              <div className={styles.headers}>{cuOption.from} </div>
              <Select
                className={styles.select}
                onChange={(value) => onChangeCustomMapping(value, cuOption)}
                options={entityOptions}
                placeholder="Select options..."
                value={customMappingToValue(cuOption)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

const customMappingToValue = (value: CustomMappingType) =>
  value && value.to ? `${value.to.id}.${value.to.name}` : '';
