import { useFormik } from 'formik';
import React, { ChangeEvent } from 'react';
import {
  Button,
  Clipboard,
  Grid,
  Input,
  Select,
  Separator,
} from '@bitmodern/bit-ui';
import { BaseIntegration, Integration } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { JiraInstanceType } from 'src/enums/IntegrationEnums';
import styles from './JiraIntegrationForm.module.scss';

const JIRA_PUBLIC_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCw1qpd8ymF0j3EmcgDUBbrErCrbwEQwBNXVGXmrsdMiJlgCcFQzv7auSYW/vvPqthGRnOpE4FoDs5CbXxadhM/iBOP9pL2Zv7jpsetDYW13/cWjMcyKBbPJaWhSqJvbX91Yu5dOC4AOt9RRB3AWDyFqqr9PT4mrxbdwRgW+DYa1wIDAQAB';
const JIRA_CLOUD_REGEX =
  /(http|https):\/\/([a-zA-Z0-9_-]+)\.atlassian\.net([^\s]*)/g;

type Values = {
  jiraInstance: JiraInstanceType;
  serverUrl: string;
  org: string;
};

type Props = {
  baseIntegration: BaseIntegration;
  className?: string;
  initialValues?: Partial<Values>;
  integration?: Integration;
  onSubmit: (values: Values) => Promise<any>;
};

export default function JiraIntegrationForm({
  baseIntegration,
  className = '',
  initialValues = {},
  integration,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const formik = useFormik<Values>({
    onSubmit,
    initialValues: {
      jiraInstance: JiraInstanceType.Cloud,
      serverUrl: '',
      org: '',
      ...initialValues,
    },
  });

  const onChangeJiraInstance = (value) => {
    formik.setFieldValue('jiraInstance', value);
  };

  const onChangeOrg = (event: ChangeEvent<HTMLInputElement>) => {
    // Exract site name from jira url
    const org = JIRA_CLOUD_REGEX.exec(event.target.value)?.[2];
    formik.setFieldValue('org', org || event.target.value);
  };

  const jiraIntanceOptions = Object.values(JiraInstanceType).map((value) => ({
    value,
    label: value,
  }));

  const serverUrl = formik.values.org
    ? baseIntegration.default_url.replace('{{org}}', formik.values.org)
    : '';

  return (
    <form
      className={className}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}>
      <Select
        label={t('jiraIntegrationForm.form.jiraInstance')}
        onChange={onChangeJiraInstance}
        onFocus={formik.handleBlur}
        options={jiraIntanceOptions}
        placeholder="Select Jira Instance..."
        required
        value={formik.values.jiraInstance}
      />
      {formik.values.jiraInstance === JiraInstanceType.Cloud ? (
        <Input
          fullWidth
          label={`${t('jiraIntegrationForm.form.subDomain')} (https://${
            formik.values.org ? formik.values.org : '[JIRA_SUB_DOMAIN]'
          }.atlassian.net)`}
          name="org"
          onChange={onChangeOrg}
          onFocus={formik.handleBlur}
          required
          value={formik.values.org}
        />
      ) : (
        <Input
          fullWidth
          label={t('jiraIntegrationForm.form.serverURL')}
          name="serverUrl"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.serverUrl}
        />
      )}
      {integration && (
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          size="small"
          type="submit">
          {t('jiraIntegrationForm.form.update')}
        </Button>
      )}

      <h3 className={styles.title}>
        1. Go to{' '}
        <a
          className={serverUrl !== '' ? styles.link : styles.disabledLink}
          target="_blank"
          rel="noopener noreferrer"
          href={`${serverUrl}plugins/servlet/applinks/listApplicationLinks`}>
          {t('JiraSetupView.jiraApplicationLinks')}
        </a>
      </h3>
      <p>Ensure you login as administrator.</p>
      <Separator />
      <h3 className={styles.title}>2. Create new Link</h3>
      <div className={styles.container}>
        Enter TestQuality url as application URL
      </div>
      <Clipboard
        className={styles.testQualityUrl}
        text="https://web.testquality.com">
        https://web.testquality.com
      </Clipboard>
      <p>
        Click on <strong>Create new link</strong>.
      </p>
      <p>
        Click on <strong>Continue</strong> in Configure Application URL warning.
      </p>
      <Separator />
      <h3 className={styles.title}>
        3. Fill the popup from with the following values
      </h3>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>APPLICATION NAME</div>
        </Grid.Col>
        <Grid.Col>
          <Clipboard text="TestQuality">TestQuality</Clipboard>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>APPLICATION TYPE</div>
        </Grid.Col>
        <Grid.Col>
          <Clipboard text="Generic Application">Generic Application</Clipboard>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>CHECK INCOMING LINK</div>
        </Grid.Col>
        <Grid.Col>
          <strong>
            <div className={styles.description}>True</div>
          </strong>
        </Grid.Col>
      </Grid.Row>
      <p>
        Leave all other fields empty, make sure you check{' '}
        <strong>Create incoming link</strong>.
      </p>
      <p>
        Click <strong>Continue</strong>.
      </p>
      <Separator />
      <h3 className={styles.title}>
        4. Fill next popup screen with the following values
      </h3>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>CONSUMER KEY</div>
        </Grid.Col>
        <Grid.Col>
          <Clipboard text="testquality.com">testquality.com</Clipboard>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>CONSUMER NAME</div>
        </Grid.Col>
        <Grid.Col>
          <Clipboard text="TestQuality">TestQuality</Clipboard>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col span={2}>
          <div className={styles.description}>PUBLIC KEY</div>
        </Grid.Col>
        <Grid.Col span={8}>
          <Clipboard text={JIRA_PUBLIC_KEY}>{JIRA_PUBLIC_KEY}</Clipboard>
        </Grid.Col>
      </Grid.Row>
      <p>Click Continue.</p>
      <p>
        That’s it! You’ve now configured the TestQuality OAuth consumer in JIRA.
        Users will be asked to Authenticate with JIRA when using JIRA
        functionality in TestQuality.
      </p>

      {!integration && (
        <div className={styles.container}>
          <Button
            className={styles.integrate}
            loading={formik.isSubmitting}
            type="submit">
            {t('jiraIntegrationForm.form.integrate')}
          </Button>
        </div>
      )}
    </form>
  );
}
