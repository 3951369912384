/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AppApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { appVersionNormalizer } from '../app_version/appVersionNormalize';

export const appNormalizer = (model: AppApi, cache: NormalizerCache) => {
  if (!cache.app) {
    cache.app = [];
  }
  cache.app.push(model);

  if (model.app_version && model.app_version.length) {
    model.app_version.forEach((child) => {
      appVersionNormalizer(child, cache);
    });
    model.app_version = undefined;
  }
};
