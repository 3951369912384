import React, { ComponentProps, ReactNode } from 'react';
import styles from './TableBody.module.scss';

type Props = ComponentProps<'tbody'> & {
  children: ReactNode;
};

export default function TableBody({ children, ...rest }: Props) {
  return (
    <tbody className={styles.tableBody} {...rest}>
      {children}
    </tbody>
  );
}
