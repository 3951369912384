import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import {
  mergeProps,
  VisuallyHidden,
  AriaRadioProps,
  useRadio,
} from 'react-aria';
import { useFocusAccent } from '@bitmodern/bit-ui';
import { RadioContext } from '../RadioGroup/RadioGroup';
import styles from './Radio.module.scss';

interface Props extends AriaRadioProps {
  children: ReactNode;
  value: any;
}

export default function Radio({ children, ...props }: Props) {
  const ref = useRef(null);
  const state = React.useContext(RadioContext);
  const { inputProps } = useRadio(props, state as any, ref);
  const { borderClassName, borderProps } = useFocusAccent({});

  const { value } = props;

  const isSelected = state.selectedValue === value;

  const radioCN = classnames(styles.radio, borderClassName, {
    [styles.selected]: isSelected,
  });

  return (
    <label className={styles.label} htmlFor={inputProps.id} {...borderProps}>
      <VisuallyHidden>
        <input {...mergeProps(inputProps, borderProps)} ref={ref} />
      </VisuallyHidden>
      <div className={radioCN} />
      {children}
    </label>
  );
}
