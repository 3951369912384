/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Test,
  TestApi,
  testGetMany,
  testGetOne,
  testDeleteOne,
  testUpdateOne,
  testCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { testNormalizer } from './testNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const testFetchManyThunk = createAsyncThunk(
  'test/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Test>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await testGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, testNormalizer);
      }
      return {
        test: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testFetchOneThunk = createAsyncThunk(
  'test/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Test>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'test/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await testGetOne(arg.id as number, arg);
      return executeNormalizer([data], testNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testDeleteOneThunk = createAsyncThunk(
  'test/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Test>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'test/DeleteOneThunk');
      }
      return await testDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testUpdateOneThunk = createAsyncThunk(
  'test/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<TestApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'test/UpdateOneThunk');
      }
      const data = await testUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Test>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Test>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testCreateOneThunk = createAsyncThunk(
  'test/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<TestApi>, { dispatch }) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'test/CreateOneThunk',
        );
      }
      const value = await testCreateOne(arg.data, arg);
      dispatch(testFetchOneThunk({ id: value.id, params: { _with: 'suite' } }));
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
