import { useAppSelector } from '@bitmodern/redux/store';
import { Defect } from '@testquality/sdk';
import { useParamSelector } from 'src/packages/redux/hooks';
import { defectEventsByDefectSelector } from 'src/packages/redux/state/defects/selectors';
import { defectStatusNativeDefectStatusByNativeStatus } from 'src/packages/redux/state/defectStatusNativeDefectStatus/selectors';
import { nativeDefectStatusesByIntegrationProject } from 'src/packages/redux/state/nativeDefectStatuses/selectors';

type Args = {
  defect: Defect;
  integrationProjectId: number;
};

export default function useEvents({ defect, integrationProjectId }: Args) {
  const nativeDefectStatuses = useAppSelector((state) =>
    nativeDefectStatusesByIntegrationProject(state, integrationProjectId),
  );
  const defectStatusesNativeDefectStatuses = useAppSelector((state) =>
    defectStatusNativeDefectStatusByNativeStatus(
      state,
      nativeDefectStatuses.map((status) => status.id),
    ),
  );

  const events = useGetDefectEvents({ defect });

  return {
    events,
    defectStatusesNativeDefectStatuses,
    nativeDefectStatuses,
  };
}

function useGetDefectEvents({ defect }) {
  const events = useParamSelector(defectEventsByDefectSelector, {
    defectId: defect.id,
  });
  return events;
}
