export enum AttributeDataType {
  string = 1,
  number = 2,
  boolean = 3,
  Date = 4,
  any = 5,
  integer = 6,
}

export enum AssociationType {
  none = 1,
  select = 2,
  many = 3,
  parent = 4,
  dependency = 5,
  custom = 6, // requires custom handling
  child = 7,
}

export enum AssociationEntity {
  caseType = 'case_type',
  casePriority = 'case_priority',
  user = 'user',
  attachment = 'attachment',
  plan = 'plan',
  status = 'status',
}
