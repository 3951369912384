import React from 'react';
import { InputDirectInlineEdit } from '@bitmodern/bit-ui/InlineEdit/InputDirectInlineEdit';
import InputDateInlineEdit from '@bitmodern/bit-ui/InlineEdit/InputDateInlineEdit';
import { Checkbox } from '@bitmodern/bit-ui';
import { Test, Plan, Project, Run, Milestone, Suite } from '@testquality/sdk';
import { virtualsByTableNameSelector } from '@bitmodern/redux/state/virtuals/selectors';
import { useAppSelector } from '@bitmodern/redux/store';

import { VirtualSchemaType } from 'src/enums/VirtualSchemaTypeEmum';
import styles from './CustomProperties.module.scss';

interface InputProps {
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

type Props = {
  onChange: (virtual: {
    [key: string]: Date | string | number | boolean;
  }) => void;
  model: Project | Milestone | Suite | Test | Plan | Run;
  inputProps?: InputProps;
};

export default function CustomProperties({
  onChange,
  model,
  inputProps,
}: Props) {
  const virtuals = useAppSelector((state) =>
    virtualsByTableNameSelector(state, model.metadata_model),
  );

  const handleChange = (value, field) => {
    const defaults = {};
    Object.entries(virtuals).forEach(([virtual]) => {
      if (!model.virtual || typeof model?.virtual[virtual] === 'undefined') {
        defaults[virtual] =
          virtuals[virtual].type === VirtualSchemaType.Boolean ? false : null;
      }
    });

    onChange({
      ...model.virtual,
      ...defaults,
      [field]: value,
    });
  };

  const renderVirtual = (key, virtual) => {
    if (virtual.type === VirtualSchemaType.Date) {
      return (
        <InputDateInlineEdit
          fullWidth
          isReadOnly={inputProps?.isReadOnly}
          isDisabled={inputProps?.isDisabled}
          label={key}
          onCommit={(value) => {
            handleChange(value, key);
            return Promise.resolve();
          }}
          value={model?.virtual?.[key]}
        />
      );
    }

    if (virtual.type === VirtualSchemaType.Number) {
      return (
        <InputDirectInlineEdit
          fullWidth
          isReadOnly={inputProps?.isReadOnly}
          isDisabled={inputProps?.isDisabled}
          label={key}
          onCommit={(value) => {
            handleChange(value, key);
            return Promise.resolve();
          }}
          type="number"
          value={model?.virtual?.[key]}
        />
      );
    }

    if (virtual.type === VirtualSchemaType.Boolean) {
      return (
        <Checkbox
          checked={model?.virtual?.[key]}
          className={styles.checkbox}
          isDisabled={inputProps?.isDisabled}
          isReadOnly={inputProps?.isReadOnly}
          name={key}
          onChange={(value) => {
            handleChange(value, key);
            return Promise.resolve();
          }}>
          {key}
        </Checkbox>
      );
    }

    return (
      <InputDirectInlineEdit
        fullWidth
        isReadOnly={inputProps?.isReadOnly}
        isDisabled={inputProps?.isDisabled}
        label={key}
        onCommit={(value) => {
          handleChange(value, key);
          return Promise.resolve();
        }}
        value={model?.virtual?.[key]}
      />
    );
  };

  return (
    <>
      {Object.entries(virtuals).map(([key, value]) => (
        <div key={key}>{renderVirtual(key, value)}</div>
      ))}
    </>
  );
}
