/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { watchAdapter } from './watchAdapter';

export const watchSelectors = watchAdapter.getSelectors(
  (state: State) => state.gen.watch,
);
