import { useOverlayTriggerState } from 'react-stately';
import { Defect } from '@testquality/sdk';
import React, { useCallback, useState } from 'react';
import { IconButton, PanelHeader, Spacer, Surface } from '@bitmodern/bit-ui';
import { CancelIcon, DeleteIcon, EditIcon } from '@bitmodern/bit-ui/icons';
import InputDirectInlineEdit from '@bitmodern/bit-ui/InlineEdit/InputDirectInlineEdit';
import {
  BaseIntegrationIcon,
  Comments,
  ConfirmDialog,
} from 'src/components/organisms';
import vars from 'src/export.scss';
import {
  defectDeleteOneThunk,
  defectUpdateOneThunk,
} from 'src/gen/domain/defect/defectThunk';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import { useAppDispatch } from '@bitmodern/redux/store';
import { useIntegrationCheck } from '../../../hooks/useIntegrationCheck';
import useLocation from '../../../hooks/useLocation';
import DefectEvents from './components/DefectEvents';
import styles from './DefectView.module.scss';
import DefectsOverview from './components/DefectsOverview/DefectsOverview';
import { Helmet } from 'react-helmet';
import { routes } from 'src/components/Router';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useDefectView from './useDefectView';
import DefectTests from './components/DefectTests';
import { Loading } from 'src/packages/bit-ui';

type Props = {
  defectId: number;
};

type Payload = {
  assignee?: { name: string | undefined; id?: number };
  comments?: any[];
  description?: string;
  labels?: Array<{ id: string; name: string }>;
  priority?: { name: string; id: string };
  resolution?: { name: string };
  status?: { name: string };
  type?: { name: string };
  summary?: string;
  components?: Array<{ id: string; name: string }>;
};

export default function DefectView({ defectId }: Props) {
  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { closeDrawer } = useDrawerManager();
  const [editingTitle, setEditingTitle] = useState(false);

  const {
    baseIntegration,
    defect,
    integration,
    integrationProject,
    integrationUsers,
    associatedRunResults,
    users,
    currentUser,
    comments,
    isLoading,
    isLoadingStatus,
  } = useDefectView({ defectId });

  const isJira =
    integration && integrationKey(integration) === IntegrationType.JIRA;

  const deleteDialog = useOverlayTriggerState({});

  const { checkIntegrationRedirect } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const updateDefect = useCallback(
    (value: Partial<Defect>, optimistic = false): Promise<void> => {
      return new Promise((resolve, reject) => {
        return checkIntegrationRedirect(
          location.pathname,
          location.search,
          location.state,
        ).then(() => {
          let thunk;
          if (defect) {
            thunk = dispatch(
              defectUpdateOneThunk({
                data: value,
                id: defect.id,
                optimistic,
                refreshData: true,
              }),
            );
          }
          if (optimistic) {
            return resolve();
          }
          return thunk.then(() => resolve(), reject);
        }, reject);
      });
    },
    [checkIntegrationRedirect, dispatch, defect, location],
  );

  const onDelete = useCallback(() => {
    if (!defect) return Promise.resolve();
    return dispatch(defectDeleteOneThunk({ id: defect.id })).then(closeDrawer);
  }, [closeDrawer, dispatch, defect]);

  const onConfirmDelete = useCallback(() => {
    return onDelete().finally(deleteDialog.close);
  }, [deleteDialog, onDelete]);
  const deleteMutation = useMutation(onConfirmDelete);

  const getDefectPayload = useCallback(() => {
    if (defect) {
      return JSON.parse(JSON.stringify(defect.payload)) as Payload;
    }
    return {} as Payload;
  }, [defect]);

  const onComment = useCallback(
    (value) => {
      const payload = getDefectPayload();
      const integrationUser = integrationUsers.find(
        (iu) => iu.user_id === currentUser?.id,
      );
      const userName = integrationUser?.username;
      let newComment;
      if (!isJira) {
        newComment = {
          body: value,
          created_at: `${new Date().toISOString().slice(0, -5)}'Z'`,
          user: userName,
        };
      } else {
        newComment = {
          body: value,
          user: userName,
        };
      }
      if (payload?.comments) {
        payload.comments.push(newComment);
      }
      return updateDefect({ payload });
    },
    [currentUser, getDefectPayload, integrationUsers, isJira, updateDefect],
  );

  const onChangeSummary = (value) => {
    // if (defect?.payload.summary === value) {
    //   setEditingTitle(false);
    //   return Promise.resolve();
    // }

    const payload = getDefectPayload();
    if (payload) {
      payload.summary = value;
    }
    setEditingTitle(false);
    return updateDefect({ payload }, true);
  };

  const handleOpenDefectIntegration = () => {
    if (integrationProject && defect?.external_reference_id) {
      window.open(
        `${integrationProject?.app_url}${defect.external_reference_id}`,
        '_blank',
        'noopener',
      );
    }
  };

  const onClose = () => {
    closeDrawer();
  };

  if (!defect) return null;

  return (
    <>
      <Helmet>
        <title>{defect.payload.summary}</title>
      </Helmet>
      <Surface className={styles.defect}>
        <PanelHeader
          title={
            !editingTitle ? (
              <div
                className={styles.panelHeaderTitle}
                onClick={() => setEditingTitle(true)}>
                <div>{defect.payload?.summary}</div>
                <EditIcon
                  className={styles.panelHeaderIcon}
                  color={vars.textSecondary}
                  size={18}
                />
              </div>
            ) : (
              <InputDirectInlineEdit
                className={styles.summary}
                onCommit={onChangeSummary}
                value={defect.payload?.summary}
                showLabel={false}
              />
            )
          }
          actions={
            <Spacer>
              <IconButton
                title={t('defect.actions.openIntegration', {
                  integrationName: integration?.name || '',
                })}
                onClick={handleOpenDefectIntegration}>
                <BaseIntegrationIcon
                  baseIntegration={baseIntegration}
                  color={vars.textPrimary}
                  size={18}
                />
              </IconButton>
              <IconButton
                onClick={deleteDialog.open}
                title={t('defect.actions.delete')}>
                <DeleteIcon color={vars.textPrimary} size={18} />
              </IconButton>
              <IconButton
                boxed={false}
                onClick={onClose}
                title={t('defect.actions.close')}>
                <CancelIcon color={vars.textPrimary} size={22} />
              </IconButton>
            </Spacer>
          }
        />
        {integrationProject && currentUser && (
          <div className={styles.panelContent}>
            <div className={styles.section}>
              {!isLoadingStatus && (
                <DefectsOverview
                  defect={defect}
                  projectId={parseInt(projectId, 10)}
                  isJira={isJira}
                  site={site}
                  currentUser={currentUser}
                  integrationProject={integrationProject}
                  associatedRunResults={associatedRunResults}
                  handleOpenDefectIntegration={handleOpenDefectIntegration}
                />
              )}
              {isLoadingStatus && (
                <div className={styles.loading}>
                  <Loading />
                </div>
              )}
            </div>
            <PanelHeader title={t('defects.defectEvents.title')} />

            {!isLoading && !isLoadingStatus && (
              <DefectEvents
                defect={defect}
                integrationProjectId={integrationProject.id}
              />
            )}
            {(isLoading || isLoadingStatus) && (
              <div className={styles.loading}>
                <Loading />
              </div>
            )}

            <DefectTests defect={defect} projectId={parseInt(projectId, 10)} />

            <div className={styles.section}>
              <h3 className={styles.title}>{t('defect.activity')}</h3>
              <Comments
                comments={comments}
                integrationUsers={integrationUsers}
                isJira={isJira}
                onComment={onComment}
                parentId={defect.related_id!}
                parentType="RunResult"
                rendered={Boolean(defect.payload?.renderedFields)}
                users={users}
              />
            </div>
          </div>
        )}
        <ConfirmDialog
          loading={deleteMutation.isLoading}
          onCancel={deleteDialog.close}
          onConfirm={deleteMutation.mutate}
          open={deleteDialog.isOpen}
          title={t('defect.deleteDialog.title')}>
          {t('defect.deleteDialog.content')}
        </ConfirmDialog>
      </Surface>
    </>
  );
}
