import { getResponse, ProjectRoute } from '@testquality/sdk';
import { format } from 'date-fns';
import { FlakinessEnum } from 'src/enums/FlakinessEnum';
import { getClient } from 'src/Client';

export type ReliabilityAnalysis = {
  flakiness: FlakinessEnum;
  assigned_to_tester: number;
  created_at: string;
  run_id: number;
  run_result_id: number;
  status_id: number;
  test_id: number;
};

export type ReliabilityTimeSeries = {
  total: number;
  updated_at: string;
};

export async function getReliabilityAnalysis(
  projectId: number,
): Promise<{ [testId: string]: ReliabilityAnalysis[] }> {
  const response = await getResponse<any>(getClient().api, {
    url: `${ProjectRoute()}/${projectId}/flakiness`,
  });

  return response.reduce((acc, analysis) => {
    if (acc[analysis.test_id]) {
      acc[analysis.test_id].push(analysis);
    } else {
      acc[analysis.test_id] = [analysis];
    }
    return acc;
  }, {});
}

export async function getReliabilityTimeSeries(
  projectId: number,
  startDate: Date,
  endDate: Date,
): Promise<ReliabilityTimeSeries[]> {
  return getResponse(getClient().api, {
    url: `${ProjectRoute()}/${projectId}/flakiness_series`,
    params: {
      start_date: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"),
      end_date: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'"),
    },
  });
}
