/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { LabelHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { labelHistoryAdapter } from './labelHistoryAdapter';
import { labelHistoryFetchThunk } from './labelHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const labelHistorySlice = createSlice({
  name: 'labelHistory',
  initialState: labelHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    labelHistoryCleared(state) {
      state.loading = 'idle';
      labelHistoryAdapter.removeAll(state);
    },
    labelHistoryUpsertMany(
      state,
      action: PayloadAction<LabelHistory[] | Record<number, LabelHistory>>,
    ) {
      labelHistoryAdapter.upsertMany(state, action.payload);
    },
    labelHistoryUpsertOne(state, action: PayloadAction<LabelHistory>) {
      labelHistoryAdapter.upsertOne(state, action.payload);
    },
    labelHistoryUpdateOne(state, action: PayloadAction<Update<LabelHistory>>) {
      labelHistoryAdapter.updateOne(state, action.payload);
    },
    labelHistoryRemoveOne(state, action: PayloadAction<number>) {
      labelHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(labelHistoryFetchThunk.pending, handlePending);
    builder.addCase(labelHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelHistoryAdapter.upsertMany(state, action.payload.labelHistory);
    });
    builder.addCase(labelHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  labelHistoryCleared,
  labelHistoryUpsertMany,
  labelHistoryUpsertOne,
  labelHistoryUpdateOne,
  labelHistoryRemoveOne,
} = labelHistorySlice.actions;

export const labelHistoryReducer = labelHistorySlice.reducer;
