import { AppVersionPlatVersion } from '@testquality/sdk';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ComponentProps, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
  Spacer,
} from '@bitmodern/bit-ui';
import { platSelectors } from 'src/gen/domain/plat/platSelector';
import { platVersionSelectors } from 'src/gen/domain/plat_version/platVersionSelector';
import useDebouncedState from 'src/hooks/useDebouncedState';
import { useAppSelector } from '@bitmodern/redux/store';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { appVersionSelectors } from 'src/gen/domain/app_version/appVersionSelector';
import { useTranslation } from 'src/i18n/hooks';
import useStateProp from 'src/hooks/useStateProp';
import ConfigurationItem from './ConfigurationItem';
import styles from './ConfigurationEditDialog.module.scss';

enum Steps {
  Platforms = 0,
  Apps = 1,
}

enum Directions {
  Forward = 1,
  Backwards = -1,
}

type Props = {
  appVersionPlatVersion?: AppVersionPlatVersion;
} & Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>;

export default function ConfigurationEditDialog({
  appVersionPlatVersion,
  onClose,
  isOpen,
}: Props) {
  const { t } = useTranslation();
  const platformEntities = useAppSelector(platSelectors.selectEntities);
  const platformVersions = useAppSelector(platVersionSelectors.selectAll);
  const appEntities = useAppSelector(appSelectors.selectEntities);
  const appVersions = useAppSelector(appVersionSelectors.selectAll);
  const [[step, direction], _setStep] = useState([
    Steps.Platforms,
    Directions.Forward,
  ]);

  const setStep = (nextStep: Steps) => {
    _setStep(([current]) => {
      return [
        nextStep,
        nextStep >= current ? Directions.Forward : Directions.Backwards,
      ];
    });
  };

  const [platVersion, setPlatVersion] = useStateProp(
    appVersionPlatVersion?.plat_version_id,
  );
  const [platformSearch, setPlatformSearch] = useState('');
  const platformSearchDebounced = useDebouncedState(platformSearch, 100);

  const platforms = platformVersions.filter((version) => {
    if (!platformSearchDebounced) return true;
    const platform = platformEntities[version.plat_id];
    if (!platform) return false;
    return (
      platform.name
        .toLowerCase()
        .includes(platformSearchDebounced.toLowerCase()) ||
      version.major
        .toLowerCase()
        .includes(platformSearchDebounced.toLowerCase())
    );
  });

  const [appVersion, setAppVersion] = useStateProp(
    appVersionPlatVersion?.app_version_id,
  );
  const [appSearch, setAppSearch] = useState('');
  const appSearchDebounced = useDebouncedState(appSearch, 100);

  const apps = appVersions.filter((version) => {
    if (!appSearchDebounced) return true;
    const app = appEntities[version.app_id];
    if (!app) return false;
    return (
      app.name.toLowerCase().includes(appSearchDebounced.toLowerCase()) ||
      version.major.toLowerCase().includes(appSearchDebounced.toLowerCase())
    );
  });

  const dialogStep = {
    [Steps.Platforms]: {
      title: t('editConfiguration.title'),
      actions: <Button onClick={() => setStep(Steps.Apps)}>Next</Button>,
      content: (
        <>
          <Input
            fullWidth
            label="Platforms"
            onChange={(event) => setPlatformSearch(event.target.value)}
            placeholder={t('editConfiguration.search')}
            value={platformSearch}
          />
          <ul className={styles.list}>
            {platforms.map((platformVersion) => (
              <ConfigurationItem
                key={platformVersion.id}
                onClick={() => setPlatVersion(platformVersion.id)}
                selected={platVersion === platformVersion.id}>
                {platformEntities[platformVersion.plat_id]?.name}{' '}
                {platformVersion.major} {platformVersion.minor}
              </ConfigurationItem>
            ))}
          </ul>
        </>
      ),
    },
    [Steps.Apps]: {
      title: t('editConfiguration.title'),
      actions: (
        <Spacer>
          <Button color="primaryLight" onClick={() => setStep(Steps.Platforms)}>
            Back
          </Button>
          <Button onClick={() => {}}>Update</Button>
        </Spacer>
      ),
      content: (
        <>
          <Input
            fullWidth
            label="Applications"
            onChange={(event) => setAppSearch(event.target.value)}
            placeholder={t('editConfiguration.search')}
            value={appSearch}
          />
          <ul className={styles.list}>
            {apps.map((version) => (
              <ConfigurationItem
                key={version.id}
                onClick={() => setAppVersion(version.id)}
                selected={appVersion === version.id}>
                {appEntities[version.app_id]?.name} {version.major}{' '}
                {version.minor}
              </ConfigurationItem>
            ))}
          </ul>
        </>
      ),
    },
  }[step];

  return (
    <Dialog onClose={onClose} isOpen={isOpen} title={dialogStep.title}>
      <DialogContent className={styles.content}>
        <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
          <motion.div
            key={step}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.15 }}>
            {dialogStep.content}
          </motion.div>
        </AnimatePresence>
      </DialogContent>
      <PanelActions>{dialogStep.actions}</PanelActions>
    </Dialog>
  );
}

const variants = {
  enter: (direction: Directions) => ({
    opacity: 0,
    x: 20 * direction,
  }),
  center: { opacity: 1, x: 0 },
  exit: (direction: Directions) => ({
    opacity: 0,
    x: -20 * direction,
  }),
};
