import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { usePress } from 'react-aria';
import { CancelIcon } from '@bitmodern/bit-ui/icons';
import { BaseButton } from '@bitmodern/bit-ui';
import vars from 'src/export.scss';
import styles from './FilterItem.module.scss';

type Props = {
  icon?: ReactNode;
  className?: string;
  filter?: ReactNode;
  onClick: () => void;
  onRemove?: () => void;
};

export default function FilterItem({
  icon,
  filter,
  onClick,
  className,
  onRemove,
}: Props) {
  const remove = usePress({
    onPress: onRemove,
  });

  const itemCN = classnames(styles.item, className);
  const iconCN = classnames(styles.icon, { [styles.iconMargin]: filter });

  return (
    <BaseButton className={itemCN} onClick={onClick}>
      {icon && <span className={iconCN}>{icon}</span>}
      {filter && <span className={styles.filter}>{filter}</span>}
      {onRemove && (
        <span
          className={styles.remove}
          onClick={onRemove}
          {...remove.pressProps}>
          <CancelIcon color={vars.textPrimary} size={14} />
        </span>
      )}
    </BaseButton>
  );
}
