import { HotkeysEnum, keyMap } from 'src/enums/HotkeysEnum';

function getOS(): Platforms {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = [
    'Macintosh',
    'MacIntel',
    'MacPPC',
    'Mac68K',
    'iPhone',
    'iPad',
    'iPod',
  ];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  if (macosPlatforms.includes(platform)) return 'mac';
  if (windowsPlatforms.includes(platform)) return 'windows';
  if (userAgent.includes('Android')) return 'android';
  if (platform.includes('Linux')) return 'linux';

  return 'other';
}

const os: Platforms = getOS();

function processKey(key: string) {
  if (os === 'mac') {
    if (key === '$cmd') return 'cmd';
  }
  if (key === '$cmd') return 'ctrl';
  return key;
}

type Platforms = 'mac' | 'windows' | 'linux' | 'android' | 'other';

export function getKeyMaps(...maps: HotkeysEnum[]) {
  return maps.reduce((acu, item) => {
    acu[item] = keyMap[item].map(processKey).join('+');
    return acu;
  }, {});
}

export function renderKey(key: string, platform: Platforms = os): string {
  const realKey = processKey(key);

  let platformKeys: { [key: string]: string } = {
    shift: 'shift',
    alt: 'alt',
    ctrl: 'ctrl',
  };

  if (platform === 'mac') {
    platformKeys = {
      cmd: '⌘',
      shift: '⇧',
      alt: '⌥',
      ctrl: '⌃',
    };
  }

  return platformKeys[realKey] || realKey;
}
