import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { BatchService } from '@testquality/sdk';
import {
  BaseButton,
  Dropdown,
  IconButton,
  Keyboard,
  NotificationBubble,
} from '@bitmodern/bit-ui';
import {
  DarkModeIcon,
  InboxIcon,
  LightModeIcon,
  NotificationActiveIcon,
  NotificationIcon,
} from '@bitmodern/bit-ui/icons';
import { useTheme } from 'src/hooks/useTheme';
import { routes } from 'src/components/Router';
import { DateFormat } from 'src/enums/DateFormatEnum';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  isTrialSelector,
  isVerifiedUserSelector,
  notifiableNotificationsSelector,
  unreadNotifiableNotificationsSelector,
  unreadWatchNotificationsSelector,
  watchNotificationsSelector,
} from '@bitmodern/redux/state/notifications/selectors';
import { notificationsUpdateOneThunk } from 'src/gen/domain/notifications/notificationsThunk';
import { useTranslation } from 'src/i18n/hooks';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { GlobalLoading } from 'src/components/organisms/GlobalLoading';
import WatchTray from 'src/components/organisms/WatchTray';
import Notifications from 'src/components/organisms/Notifications';
import vars from 'src/export.scss';
import { DocumentationAction } from '../DocumentationAction';
import { renderKey } from 'src/utils/hotkeys';
import { keyMap, HotkeysEnum } from 'src/enums/HotkeysEnum';
import { useCommandBar } from 'src/hooks/useCommandBar';
import { getEnv } from 'src/env';
import styles from './HeaderActions.module.scss';

export function HeaderActions() {
  const { t } = useTranslation();
  const history = useHistory();
  const { site } = useParamsInRoute<typeof routes.HOME.params>(
    routes.HOME.path,
  );
  const commandPalette = useCommandBar();
  const [theme, setTheme] = useTheme();

  const [watchVisible, setWatchVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  const dispatch = useAppDispatch();

  const isTrial = useAppSelector(isTrialSelector);
  const isVerifiedUser = useAppSelector(isVerifiedUserSelector);

  const notifiableNotifications = useAppSelector(
    notifiableNotificationsSelector,
  );
  const watchNotifications = useAppSelector(watchNotificationsSelector);
  const unreadWatchNotifications = useAppSelector(
    unreadWatchNotificationsSelector,
  );
  const unreadNotifiableNotifications = useAppSelector(
    unreadNotifiableNotificationsSelector,
  );

  const isLightTheme = (tColor) => tColor === 'light';

  const getNotificationsAmount = (notifications) => {
    let amount = notifications.length;

    if (isTrial) {
      amount += 1;
    }
    if (!isVerifiedUser) {
      amount += 1;
    }

    return amount;
  };

  const onClickTheme = () => {
    setTheme((prev) => (isLightTheme(prev) ? 'dark' : 'light'));
  };

  const onCloseNotifications = (visible: boolean) => {
    const batch = new BatchService();
    if (!visible && unreadNotifiableNotifications.length) {
      /* NOTE: Mark unread notifications as read on notifications close */
      unreadNotifiableNotifications.forEach((unreadNotification) => {
        dispatch(
          notificationsUpdateOneThunk({
            id: unreadNotification.id,
            data: {
              read_at: format(new Date(), DateFormat.UpdateEntity),
            },
            batch,
          }),
        );
      });
    }
    batch.executeBatch();
    setNotificationsVisible(visible);
  };

  const onChangeWatchVisible = (visible: boolean) => {
    const batch = new BatchService();
    if (!visible && unreadWatchNotifications.length) {
      /* NOTE: Mark unread watches as read on click on watch close */
      unreadWatchNotifications.forEach((unreadNotification) => {
        dispatch(
          notificationsUpdateOneThunk({
            id: unreadNotification.id,
            data: {
              read_at: format(new Date(), DateFormat.UpdateEntity),
            },
            batch,
          }),
        );
      });
    }
    batch.executeBatch();
    setWatchVisible(visible);
  };

  const goToWatchSettings = () => {
    setWatchVisible(false);
    history.push(routes.WATCH({ site }));
  };

  const onClickSubscribe = () => {
    setNotificationsVisible(false);
    history.push(routes.SUBSCRIPTIONS({ site }));
  };

  return (
    <div className={styles.headerActions}>
      <GlobalLoading />
      {getEnv().features.commandPaletteButton && (
        <BaseButton
          className={styles.commandPalette}
          onClick={commandPalette.show}>
          <span className={styles.commandContent}>
            {t('app.header.commandPalette')}
            <Keyboard
              keys={keyMap[HotkeysEnum.CommandBar].map((key) => renderKey(key))}
              size="small"
            />
          </span>
        </BaseButton>
      )}
      <Dropdown
        onChangeVisible={onChangeWatchVisible}
        popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
        overlay={
          <WatchTray
            onClickSettings={goToWatchSettings}
            watchCount={watchNotifications.length}
          />
        }
        visible={watchVisible}>
        <IconButton
          title={
            unreadWatchNotifications.length
              ? t('app.header.activeWatches')
              : t('app.header.noWatches')
          }>
          {unreadWatchNotifications.length !== 0 && (
            <NotificationBubble>
              {unreadWatchNotifications.length}
            </NotificationBubble>
          )}
          <InboxIcon color={vars.textPrimary} size={20} />
        </IconButton>
      </Dropdown>
      <Dropdown
        onChangeVisible={onCloseNotifications}
        closeOnClick={false}
        popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
        overlay={
          <Notifications
            onClickSubscribe={onClickSubscribe}
            notificationsAmount={getNotificationsAmount(
              notifiableNotifications,
            )}
          />
        }
        visible={notificationsVisible}>
        <IconButton
          title={
            getNotificationsAmount(unreadNotifiableNotifications)
              ? t('app.header.activeNotifications')
              : t('app.header.noNotifications')
          }>
          {getNotificationsAmount(unreadNotifiableNotifications) ? (
            <>
              <NotificationBubble>
                {getNotificationsAmount(unreadNotifiableNotifications)}
              </NotificationBubble>
              <NotificationActiveIcon color={vars.textPrimary} size={20} />
            </>
          ) : (
            <NotificationIcon color={vars.textPrimary} size={20} />
          )}
        </IconButton>
      </Dropdown>
      <IconButton
        onClick={onClickTheme}
        title={
          isLightTheme(theme) ? t('app.header.dark') : t('app.header.light')
        }>
        {isLightTheme(theme) ? (
          <DarkModeIcon color={vars.textPrimary} size={20} />
        ) : (
          <LightModeIcon color={vars.textPrimary} size={20} />
        )}
      </IconButton>
      <DocumentationAction />
    </div>
  );
}
