import { AnyAction, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { GenReducer } from 'src/gen/actions/GenReducers';
import authentication from '../state/authentication/reducer';
import { themeReducer } from '../state/theme/themeSlice';
import { currentUserReducer } from '../state/currentUser/currentUserSlice';
import filtersReducer from '../state/filters/reducer';
import customFiltersReducer from '../state/customFilters/reducer';
import { batchSetGenReducer } from './batchSetGenReducer';

const filtersPersistConfig = {
  key: 'filters',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

const customFiltersPersistConfig = {
  key: 'customFilters',
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
};

export type CustomFilterState = ReturnType<typeof customFiltersReducer>;
export type FilterState = ReturnType<typeof filtersReducer>;

const rootReducer = combineReducers({
  authentication,
  customFilters: persistReducer<CustomFilterState, AnyAction>(
    customFiltersPersistConfig,
    customFiltersReducer,
  ),
  filters: persistReducer<FilterState, AnyAction>(
    filtersPersistConfig,
    filtersReducer,
  ),
  theme: themeReducer,
  currentUser: currentUserReducer,
  gen: batchSetGenReducer(GenReducer),
});

export default rootReducer;
