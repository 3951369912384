/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  IntegrationProject,
  integrationProjectDetach,
  integrationProjectUpdateOne,
  integrationProjectCreateOne,
  integrationProjectGetMany,
  integrationProjectGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const integrationProjectDetachThunk = createAsyncThunk(
  'integrationProject/DetachThunk',
  async (arg: QueryParams<IntegrationProject>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'integrationProject/DetachThunk',
      );
    }
    return integrationProjectDetach(arg.data, arg).then(() => arg.data);
  },
);

export const integrationProjectUpdateOneThunk = createAsyncThunk(
  'integrationProject/UpdateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<IntegrationProject>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'integrationProject/UpdateOneThunk',
        );
      }
      const data = await integrationProjectUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<IntegrationProject>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationProjectCreateOneThunk = createAsyncThunk(
  'integrationProject/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<IntegrationProject>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'integrationProject/CreateOneThunk',
        );
      }
      return await integrationProjectCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationProjectFetchManyThunk = createAsyncThunk(
  'integrationProject/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<IntegrationProject>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await integrationProjectGetMany(inlineArgs);
      return {
        integrationProject: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const integrationProjectFetchOneThunk = createAsyncThunk(
  'integrationProject/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<IntegrationProject>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'integrationProject/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await integrationProjectGetOne(arg.id as number, arg);
      return {
        integrationProject: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
