import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { CSVFormValuesType, FormValuesType, FormikType } from '../FormikType';
import {
  planCreateOneThunk,
  planFetchOneThunk,
} from 'src/gen/domain/plan/planThunk';
import { useAppDispatch, useAppSelector } from 'src/packages/redux/store';
import {
  lastCycleSelector,
  rootPlanSelector,
} from 'src/packages/redux/state/plans/selectors';
import {
  postGherkinFiles,
  postGherkinRunResultFiles,
} from 'src/packages/services/PlanService';
import { BatchService, Plan } from '@testquality/sdk';
import { planSuiteTestIncludeFetchManyThunk } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { attachmentFetchManyThunk } from 'src/gen/domain/attachment/attachmentThunk';
import { runFetchOneThunk } from 'src/gen/domain/run/runThunk';
import useModalManager from 'src/hooks/useModalManager';
import { dataSetFetchManyThunk } from 'src/gen/domain/data_set/dataSetThunk';
import { labelFetchManyThunk } from 'src/gen/domain/label/labelThunk';
import { labelAssignedFetchManyThunk } from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { notification } from 'src/packages/bit-ui';
import { useTranslation } from 'react-i18next';
import { leadingZeros } from 'src/utils/common';
import { useEffect, useState } from 'react';

export type Args = {
  projectId: number;
};

export type FormValues = FormValuesType | CSVFormValuesType;

const initialValues: FormValues = {
  planId: 0,
  milestoneId: 0,
  automatedRunResults: false,
  filesToSend: [],
  delimiter: ',',
  escape: '\\',
  enclosure: '"',
  useUtf8Encode: true,
  mappings: [],
  extraFields: {},
};

const validationSchema = Yup.object().shape({
  filesToSend: Yup.array<File>().required(),
});

export type ReturnType = {
  formik: FormikType;
  uploadFinished: boolean;
  handleDropFiles: (files) => void;
};

export default function useGherkin({ projectId }: Args): ReturnType {
  const { t } = useTranslation();
  const { hideModal } = useModalManager();
  const dispatch = useAppDispatch();
  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, projectId),
  );
  const lastCycle = useAppSelector(lastCycleSelector);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fetchData = (rootPlanId: number, runId: number) => {
    const batch = new BatchService();
    dispatch(
      planFetchOneThunk({
        id: rootPlanId,
        params: {
          _with: 'suite,suite.test,suite.test.step',
          per_page: -1,
        },
        batch,
      }),
    );
    dispatch(
      runFetchOneThunk({
        id: runId,
        params: {
          _with: 'runResult.runResultStep',
          per_page: -1,
        },
        batch,
      }),
    );
    dispatch(
      attachmentFetchManyThunk({
        params: {
          per_page: -1,
          related_type: RelatedType.RUN_RESULT,
        },
        batch,
      }),
    );
    dispatch(
      planSuiteTestIncludeFetchManyThunk({
        params: {
          project_id: projectId,
          per_page: -1,
        },
        batch,
      }),
    );
    batch.executeBatch();
  };

  const importGherkinRunResults = (values) => {
    if (!rootPlan) return Promise.resolve();
    if (!values.planId) {
      return dispatch(
        planCreateOneThunk({
          data: {
            project_id: projectId,
            name: lastCycle ? `Cycle ${leadingZeros(lastCycle.key, 3)}` : '',
            is_root: false,
          },
        }),
      ).then(({ payload }) => {
        return postGherkinRunResultFiles({
          files: values.filesToSend,
          planId: (payload as Plan).id,
          projectId: projectId.toString(),
          milestoneId: values.milestoneId,
        }).then((resp) => {
          fetchData(rootPlan.id, resp.run_id);
        });
      });
    }
    return postGherkinRunResultFiles({
      files: values.filesToSend,
      planId: values.planId,
      projectId: projectId.toString(),
      milestoneId: values.milestoneId,
    }).then((payload) => {
      fetchData(rootPlan.id, payload.run_id);
    });
  };

  const onSubmit = (values) => {
    const percentValue = 100 / values.filesToSend.length;
    let progress = 0;
    if (!rootPlan) {
      return Promise.resolve();
    }

    function refetchData() {
      if (!rootPlan) {
        return;
      }
      dispatch(
        planFetchOneThunk({
          id: rootPlan.id,
          params: {
            _with: 'suite,suite.test,suite.test.step',
          },
        }),
      );
      dispatch(
        dataSetFetchManyThunk({
          params: { per_page: -1, project_id: projectId },
        }),
      );
      dispatch(
        labelFetchManyThunk({
          params: { per_page: -1 },
        }),
      );
      dispatch(labelAssignedFetchManyThunk({ params: { per_page: -1 } }));
    }

    if (values.automatedRunResults) {
      return importGherkinRunResults(values).then(() => {
        refetchData();
        notification.open({
          type: 'success',
          message: t('notifications.importFile.message'),
        });
      });
      // return Promise.resolve();
    }

    return Promise.all(
      values.filesToSend.map((file) => {
        return postGherkinFiles(file, projectId.toString()).then(() => {
          progress += percentValue;
          setUploadProgress(progress);
        });
      }),
    ).then(() => {
      refetchData();
      notification.open({
        type: 'success',
        message: t('notifications.importFile.message'),
      });
      hideModal();
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [uploadFinished, setUploadFinished] = useState<boolean>(false);

  useEffect(() => {
    setUploadFinished(uploadProgress >= 100);
  }, [uploadProgress]);

  const handleDropFiles = (files) => {
    formik.setFieldValue('filesToSend', files);
  };

  return { formik, uploadFinished, handleDropFiles };
}
