import filter from 'lodash/filter';
import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import labelAssignedAdapter from 'src/gen/domain/label_assigned/labelAssignedAdapter';
import { Label, LabelAssigned } from '@testquality/sdk';
import { labelSelector, labelsSelector } from '../label/selectors';

const labelAssignedSelectors = labelAssignedAdapter.getSelectors(
  (state: State) => state.gen.labelAssigned,
);

export function labelAssignedSelector(state: State) {
  return labelAssignedSelectors.selectAll(state);
}

export const filterLabelAssignedByTestId = (entities, testId) => {
  return (filter(
    entities,
    (labelAssigned) =>
      labelAssigned.related_id === testId &&
      labelAssigned.related_type === RelatedType.TEST,
  ) || []) as LabelAssigned[];
};

export const labelAssignedByTestSelector = createSelector(
  [
    (state: State) => state.gen.labelAssigned.entities,
    (_, { testId }: { testId?: number }) => testId,
  ],
  (entities, testId) => {
    return filterLabelAssignedByTestId(entities, testId);
  },
);

export const selectLabelAssignedByTestIds = createSelector(
  [labelAssignedByTestSelector, labelsSelector],
  (labelsAssigned, labels) => {
    const ids = labelsAssigned.map((el) => el.label_id);
    return labels.filter((el) => ids.includes(el.id)).map((el) => el.id);
  },
);

export const labelAssignedByPlanSelector = createSelector(
  [
    labelAssignedSelectors.selectAll,
    (_, { planId }: { planId?: number }) => planId,
  ],
  (planLabel, planId) => {
    return planLabel.filter(
      (labelAssigned) =>
        labelAssigned.related_id === planId &&
        labelAssigned.related_type === RelatedType.PLAN,
    );
  },
);

export const labelAssignedBySuiteSelector = createSelector(
  [
    labelAssignedSelectors.selectAll,
    (_, { suiteId }: { suiteId?: number }) => suiteId,
  ],
  (suiteLabels, suiteId) => {
    return suiteLabels.filter(
      (labelAssigned) =>
        labelAssigned.related_id === suiteId &&
        labelAssigned.related_type === RelatedType.SUITE,
    );
  },
);

export const labelAssignedByDefectSelector = createSelector(
  [
    labelAssignedSelectors.selectAll,
    (_, { defectId }: { defectId?: number }) => defectId,
  ],
  (labelDefect, defectId) => {
    return labelDefect.filter(
      (labelAssigned) =>
        labelAssigned.related_id === defectId &&
        labelAssigned.related_type === RelatedType.DEFECT,
    );
  },
);

export function testLabelsSelector(state: State) {
  const labels: { [key: number]: Label } = {};
  labelAssignedSelectors.selectAll(state).forEach((labelAssigned) => {
    if (
      labelAssigned.related_type === RelatedType.TEST &&
      !labels[labelAssigned.label_id]
    ) {
      const label = labelSelector(state, labelAssigned.label_id);
      if (label) {
        labels[label.id] = label;
      }
    }
  });
  return Object.values(labels);
}
