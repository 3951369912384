import React from 'react';
import { Link } from 'react-router-dom';
import { Requirement } from '@testquality/sdk';
import { Avatar, TableCell, TableRow, Tooltip } from '@bitmodern/bit-ui';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppSelector } from '@bitmodern/redux/store';
import styles from './RequirementItem.module.scss';
import { formatUserName } from '../../../utils/fileHelper';
import { defectStatusSelectors } from '../../../gen/domain/defect_status/defectStatusSelector';
import { DefectStatusItem } from '../DefectStatusItem';
import { useMaker } from 'src/packages/redux/hooks';
import { makeDefectsCountByRequirement } from 'src/packages/redux/state/defects/selectors';
import {
  makeTestsCountByRequirement,
  makeTestsPercentage,
} from 'src/packages/redux/state/tests/selectors';

type Props = {
  requirement: Requirement;
};

export function RequirementItem({ requirement }: Props) {
  const { generateDrawerPath } = useDrawerManager();

  const defectStatus = useAppSelector((state) => {
    if (requirement.defect_status_id) {
      return defectStatusSelectors.selectById(
        state,
        requirement.defect_status_id,
      );
    }
    const defectStatuses = defectStatusSelectors.selectAll(state);
    return defectStatuses.find((df) => df.key === 1);
  });

  const requirementId = requirement.id;
  const defectsCount = useMaker(makeDefectsCountByRequirement, {
    requirementId,
  });
  const testsCount = useMaker(makeTestsCountByRequirement, { requirementId });
  const testPercentageInclusion = useMaker(makeTestsPercentage, {
    projectId: requirement.project_id,
    testsCount,
  });

  return (
    <TableRow key={requirement.id}>
      <TableCell className={styles.key}>
        <Link
          to={generateDrawerPath({
            requirementId: requirement.id,
          })}>
          {requirement.payload?.key}
        </Link>
      </TableCell>
      <TableCell>{requirement.payload?.summary}</TableCell>
      <TableCell>{defectsCount}</TableCell>
      <TableCell>{testsCount}</TableCell>
      <TableCell>{testPercentageInclusion}%</TableCell>
      <TableCell>
        {new Date(
          requirement.payload?.created_at || requirement.created_at,
        ).toLocaleDateString()}
      </TableCell>
      <TableCell>
        {requirement.payload?.assignee && (
          <Tooltip
            tooltip={formatUserName(requirement?.payload?.assignee?.name)}>
            <span>
              <Avatar
                src={requirement.payload?.assignee?.avatar_url}
                name={requirement.payload?.assignee?.name}
                size={24}
              />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <DefectStatusItem defectStatus={defectStatus} />
      </TableCell>
    </TableRow>
  );
}
