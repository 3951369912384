/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Comment } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { commentAdapter } from './commentAdapter';
import {
  commentFetchManyThunk,
  commentFetchOneThunk,
  commentDeleteOneThunk,
  commentUpdateOneThunk,
  commentCreateOneThunk,
} from './commentThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const commentSlice = createSlice({
  name: 'comment',
  initialState: commentAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    commentCleared(state) {
      state.loading = 'idle';
      commentAdapter.removeAll(state);
    },
    commentUpsertMany(
      state,
      action: PayloadAction<Comment[] | Record<number, Comment>>,
    ) {
      commentAdapter.upsertMany(state, action.payload);
    },
    commentUpsertOne(state, action: PayloadAction<Comment>) {
      commentAdapter.upsertOne(state, action.payload);
    },
    commentUpdateOne(state, action: PayloadAction<Update<Comment>>) {
      commentAdapter.updateOne(state, action.payload);
    },
    commentRemoveOne(state, action: PayloadAction<number>) {
      commentAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(commentFetchManyThunk.pending, handlePending);
    builder.addCase(commentFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      commentAdapter.upsertMany(state, action.payload.comment);
    });
    builder.addCase(commentFetchManyThunk.rejected, handleRejected);
    builder.addCase(commentFetchOneThunk.pending, handlePending);
    builder.addCase(commentFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      commentAdapter.upsertOne(state, action.payload.comment[0]);
    });
    builder.addCase(commentFetchOneThunk.rejected, handleRejected);
    builder.addCase(commentDeleteOneThunk.pending, handlePending);
    builder.addCase(commentDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        commentAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(commentDeleteOneThunk.rejected, handleRejected);
    builder.addCase(commentUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, commentAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(commentUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      commentAdapter.updateOne(state, action.payload);
    });
    builder.addCase(commentUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          commentAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(commentCreateOneThunk.pending, handlePending);
    builder.addCase(commentCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      commentAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(commentCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  commentCleared,
  commentUpsertMany,
  commentUpsertOne,
  commentUpdateOne,
  commentRemoveOne,
} = commentSlice.actions;

export const commentReducer = commentSlice.reducer;
