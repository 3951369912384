/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import {
  integrationUserDetachThunk,
  integrationUserFetchManyThunk,
  integrationUserFetchOneThunk,
  integrationUserUpdateOneThunk,
  integrationUserCreateOneThunk,
} from './integrationUserThunk';
import { TQStateProperties } from '../../store/TQState';
import { integrationUserAdapter } from './integrationUserAdapter';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const integrationUserSlice = createSlice({
  name: 'integrationUser',
  initialState: integrationUserAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    integrationUserCleared: integrationUserAdapter.removeAll,
    integrationUserUpsertMany: integrationUserAdapter.upsertMany,
    integrationUserUpsertOne: integrationUserAdapter.upsertOne,
    integrationUserUpdateOne: integrationUserAdapter.updateOne,
    integrationUserRemoveOne: integrationUserAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(integrationUserDetachThunk.pending, handlePending);
    builder.addCase(integrationUserDetachThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload && action.payload.id) {
        integrationUserAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(integrationUserDetachThunk.rejected, handleRejected);
    builder.addCase(integrationUserFetchManyThunk.pending, handlePending);
    builder.addCase(
      integrationUserFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      },
    );
    builder.addCase(integrationUserFetchManyThunk.rejected, handleRejected);
    builder.addCase(integrationUserFetchOneThunk.pending, handlePending);
    builder.addCase(integrationUserFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      integrationUserAdapter.upsertOne(
        state,
        action.payload.integrationUser[0],
      );
    });
    builder.addCase(integrationUserFetchOneThunk.rejected, handleRejected);
    builder.addCase(integrationUserUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, integrationUserAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      integrationUserUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationUserAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(integrationUserUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          integrationUserAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(integrationUserCreateOneThunk.pending, handlePending);
    builder.addCase(
      integrationUserCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationUserAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(integrationUserCreateOneThunk.rejected, handleRejected);
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.integrationUser) {
          integrationUserAdapter.upsertMany(
            state,
            action.payload.integrationUser,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.integrationUser) {
          integrationUserAdapter.upsertMany(
            state,
            action.payload.integrationUser,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.integrationUser) {
          integrationUserAdapter.upsertMany(
            state,
            action.payload.integrationUser,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.integrationUser) {
        integrationUserAdapter.upsertMany(
          state,
          action.payload.integrationUser,
        );
      }
    });
  },
});

export const {
  integrationUserCleared,
  integrationUserUpsertMany,
  integrationUserUpsertOne,
  integrationUserUpdateOne,
  integrationUserRemoveOne,
} = integrationUserSlice.actions;

export const integrationUserReducer = integrationUserSlice.reducer;
