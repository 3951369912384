import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './PanelHeader.module.scss';

type Props = {
  actions?: ReactNode;
  title?: ReactNode;
  notAlign?: boolean;
};

export default function PanelHeader({ actions, title, notAlign }: Props) {
  const titleCN = classnames(styles.title, {
    [styles.withActions]: Boolean(actions),
  });

  const headerCN = classnames(styles.header, {
    [styles.notAlign]: notAlign,
  });

  return (
    <div className={headerCN}>
      {title && <div className={titleCN}>{title}</div>}
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
}
