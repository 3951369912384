import { NormalizerCache } from './NormalizeCache';

export function executeNormalizer(entities, normalizer) {
  const cache: NormalizerCache = {};
  entities.forEach((entity) => {
    normalizer(entity, cache);
  });

  return cache;
}
