/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  BaseIntegration,
  baseIntegrationHistoryGet,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';

export const baseIntegrationHistoryFetchThunk = createAsyncThunk(
  'baseIntegrationHistory/FetchThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<BaseIntegration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      arg.cancelToken = source.token;
      const data = await baseIntegrationHistoryGet(arg);
      return {
        baseIntegrationHistory: data,
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
