import { useFormik } from 'formik';
import React from 'react';
import Yup from 'src/utils/yup';
import { Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { showNotificationError } from '@testquality/sdk';
import { Button, Input, notification } from '@bitmodern/bit-ui';
import { getClient } from 'src/Client';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import { LogoPanel } from 'src/modules/authentication/elements';
import { notificationErrorTimeout } from 'src/constants';
import styles from './PasswordRecovery.module.scss';
import { Link } from 'react-router-dom';
import { routes } from 'src/components/Router';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('passwordRecovery.form.email'),
});

const initialValues = {
  email: '',
};

export default function PasswordRecovery() {
  const { t } = useTranslation();

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  function onSubmit({ email }) {
    return getClient()
      .getAuth()
      .passwordRecovery(email)
      .then(() => {
        notification.open({
          type: 'success',
          message: 'Success',
          description: 'Password reset has been sent to your email account',
        });
      })
      .catch((error) => {
        if (error.message.indexOf('verification_ended_at') !== -1) {
          notification.open({
            type: 'error',
            message: 'Error',
            description:
              'Your email requires verification, please check your Inbox for verification email',
            duration: notificationErrorTimeout,
          });
        }
        showNotificationError(error);
      });
  }

  return (
    <LogoPanel
      title={t('passwordRecovery.title')}
      footer={
        <Link className={styles.link} to={routes.FREETRIAL}>
          <Trans i18nKey="passwordRecovery.signIn">
            Know your credentials? <span>Return to Sign in</span>
          </Trans>
        </Link>
      }>
      <Helmet>
        <title>{t('passwordRecovery.head.title')}</title>
      </Helmet>

      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          error={formikError(formik, 'email')}
          fullWidth
          label={t('passwordRecovery.form.email')}
          name="email"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.email}
        />

        <Button
          className={styles.submit}
          color="primaryLight"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('passwordRecovery.form.submit')}
        </Button>
      </form>
    </LogoPanel>
  );
}
