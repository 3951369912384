import { createSelector } from 'reselect';
import { dataSetSelectors } from 'src/gen/domain/data_set/dataSetSelector';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import { State } from '@bitmodern/redux/store';
import { DataSet as DataSetModel } from '@testquality/sdk';

export const DASET_ID_KEY = 'tq_id';

type DataSetTypes = 'string' | 'pk';

type DataSetSchema = Record<string, { type: DataSetTypes }> & {
  [DASET_ID_KEY]: { type: 'pk'; count: number };
};

type DataSetData = Array<
  Record<string, string | number> & { readonly [DASET_ID_KEY]: number }
>;

export type DataSet = Omit<DataSetModel, 'data' | 'schema'> & {
  data: DataSetData;
  schema: DataSetSchema;
};

export function dataSetsSelector(state: State) {
  return dataSetSelectors.selectAll(state) as DataSet[];
}

export function dataSetSelector(state: State, args: { dataSetId?: number }) {
  if (!args.dataSetId) return undefined;

  return dataSetSelectors.selectById(state, args.dataSetId);
}

export const makeDataSetByTestSelector = () =>
  createSelector(
    [
      testSelectors.selectEntities,
      dataSetSelectors.selectEntities,
      (_, args: { testId?: number }) => args,
    ],
    (tests, dataSets, { testId }) => {
      if (!tests || !testId) return undefined;

      const test = tests[testId];

      if (test && test.data_set_id) {
        const dataSet = dataSets[test.data_set_id];

        if (dataSet) return dataSet as DataSet;
      }

      return undefined;
    },
  );
