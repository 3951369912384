import React, { useMemo } from 'react';
import Yup from 'src/utils/yup';
import { useFormik } from 'formik';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { Input, Button, Select } from '@bitmodern/bit-ui';
import classNames from 'classnames';
import {
  accessRolesSelector,
  isCurrentUserAdminSelector,
} from '@bitmodern/redux/state/accessRoles/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { AccessRoleEnum } from 'src/enums/AccessRoleEnum';
import styles from './InviteUsersForm.module.scss';

const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .transform(function stringToArray(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(),
  accessRole: Yup.mixed().required().label('inviteUsersForm.accessRole'),
});

export type InviteUsersFormValues = {
  emails: string;
  accessRole?: number;
};

const defaultInitialValues: InviteUsersFormValues = {
  emails: '',
  accessRole: undefined,
};

type Props = {
  onSubmit: (initialValues) => void;
  submitClassName?: string;
};

export default function InviteUsersForm({ onSubmit, submitClassName }: Props) {
  const { t } = useTranslation();
  const accessRoles = useAppSelector(accessRolesSelector);
  const isCurrentUserAdmin = useAppSelector(isCurrentUserAdminSelector);
  const accessRolesOptions = useMemo(() => {
    return accessRoles.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  }, [accessRoles]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultInitialValues,
    onSubmit,
    validationSchema,
  });

  const onChangeAccessRoles = (value) => {
    formik.setFieldValue('accessRole', value);
  };

  const onPasteEmails = (e) => {
    const emailRegex = new RegExp(
      /[a-zA-Z0-9+._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+/,
      'gi',
    );
    const traillingCommasRegex = new RegExp(/^[,\s]+|[,\s]+$/, 'g');
    const pastedText: string = e.clipboardData.getData('Text');
    const parsedEmails: string[] | null = pastedText.match(emailRegex);

    if (parsedEmails?.length) {
      const newEmailsValue = formik.values.emails
        ? `${formik.values.emails.replace(
            traillingCommasRegex,
            '',
          )}, ${parsedEmails.join(', ')}`
        : parsedEmails.join(', ');

      formik.setFieldValue('emails', newEmailsValue);
      e.preventDefault();
    }
  };

  /* NOTE: Only admins can invite users with roles other than viewer */
  const disabledAccessRoles = isCurrentUserAdmin
    ? undefined
    : new Set(
        accessRoles
          .filter((ar) => ar.key !== AccessRoleEnum.VIEWER)
          .map((role) => role.id),
      );

  const submitButtonCN = classNames(styles.submit, submitClassName);

  return (
    <form
      className={styles.form}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}>
      <Input
        error={formikError(formik, 'emails')}
        fullWidth
        label={t('inviteUsersForm.emails')}
        placeholder={t('inviteUsersForm.emailsPlaceholder')}
        name="emails"
        onChange={formik.handleChange}
        onPaste={onPasteEmails}
        onFocus={formik.handleBlur}
        required
        value={formik.values.emails}
      />

      <Select
        label={t('inviteUsersForm.accessRole')}
        error={formikError(formik, 'accessRole')}
        onChange={onChangeAccessRoles}
        name="accessRole"
        required
        options={accessRolesOptions}
        value={formik.values.accessRole}
        disabledKeys={disabledAccessRoles}
      />

      <div className={styles.submit}>
        <Button
          className={submitButtonCN}
          loading={formik.isSubmitting}
          type="submit">
          {t('inviteUsersForm.submit')}
        </Button>
      </div>
    </form>
  );
}
