import React, {
  MouseEvent,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  DASET_ID_KEY,
  DataSet,
} from 'src/packages/redux/state/dataSets/selectors';
import { IconButton, TableBody, TableCell, TableRow } from '@bitmodern/bit-ui';
import { CancelIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import classNames from 'classnames';
import vars from 'src/export.scss';
import styles from './DataSetBody.module.scss';

export type DataSetBodyProps = {
  dataSetData: DataSet['data'];
  isEditing: boolean;
  onDeleteRow: (idx: number) => void;
  onTableBodyClick: (event: MouseEvent<HTMLTableSectionElement>) => void;
  onTableCellChange: (key: string, idx: number, value: string) => void;
  onAddRow: () => void;
};

export type DataSetBodyRef = { focusCell: (col: string, row: number) => void };

export const DataSetBody = forwardRef<DataSetBodyRef, DataSetBodyProps>(
  (
    {
      dataSetData,
      isEditing,
      onDeleteRow,
      onTableBodyClick,
      onTableCellChange,
      onAddRow,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const cells = useRef(new Map<string, HTMLInputElement | null>());

    useImperativeHandle(
      ref,
      () => ({
        focusCell: (col, row) => {
          const cell = cells.current.get(cellKey(col, row));
          if (!cell) return;
          cell.focus();
        },
      }),
      [],
    );

    const totalRows = dataSetData.length;
    const lastColumnName = Object.keys(dataSetData[0] || {})
      .filter((c) => c !== DASET_ID_KEY)
      .at(-1);
    const lastCellVariants = isEditing
      ? (['edge', 'dark'] as const)
      : (['edge'] as const);

    const renderTableCell = ({
      column,
      row,
      value,
    }: {
      column: string;
      row: number;
      value: string;
    }) => (
      <TableCell
        key={column}
        data-column={column}
        data-row={row}
        className={styles.tableCell}>
        {isEditing ? (
          <input
            className={styles.input}
            value={value}
            onChange={(event) =>
              onTableCellChange(column, row, event.target.value)
            }
            ref={(inputRef) =>
              cells.current.set(cellKey(column, row), inputRef)
            }
            onKeyDown={(event) => {
              const isLastCell =
                column === lastColumnName && row === totalRows - 1;
              if (event.key === 'Tab' && !hasMetaKeys(event) && isLastCell) {
                event.preventDefault();
                onAddRow();
              }
            }}
          />
        ) : (
          <span className={styles.contentCell}>{value}</span>
        )}
      </TableCell>
    );

    const renderTableRow = (rowData: Record<string, any>, index: number) => (
      <TableRow key={rowData[DASET_ID_KEY]} className={styles.row}>
        <TableCell
          role="head"
          className={styles.header}
          scope="row"
          variants={['edge']}>
          # {index + 1}
        </TableCell>
        {Object.entries(rowData)
          .filter(([column]) => column !== DASET_ID_KEY)
          .map(([column, value]) => ({ column, value, row: index }))
          .map(renderTableCell)}
        {isEditing && (
          <TableCell
            className={`${styles.tableCell} ${styles.deleteRow}`}
            variants={lastCellVariants}>
            <IconButton
              color="transparent"
              title={t('dataSet.deleteRow')}
              onClick={() => onDeleteRow(index)}
              tabIndex={-1}>
              <CancelIcon size={20} color={vars.textSecondary} />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );

    const tableBodyCN = classNames(styles.body, {
      [styles.bodyEditing]: isEditing,
    });

    return (
      <TableBody className={tableBodyCN} onClick={onTableBodyClick}>
        {dataSetData.map(renderTableRow)}
      </TableBody>
    );
  },
);

function hasMetaKeys(event: KeyboardEvent): boolean {
  return event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
}

function cellKey(columName: string, row: number): string {
  return `${columName}~${row}`;
}
