/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { TestQualityTypeHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { testQualityTypeHistoryAdapter } from './testQualityTypeHistoryAdapter';
import { testQualityTypeHistoryFetchThunk } from './testQualityTypeHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const testQualityTypeHistorySlice = createSlice({
  name: 'testQualityTypeHistory',
  initialState:
    testQualityTypeHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    testQualityTypeHistoryCleared(state) {
      state.loading = 'idle';
      testQualityTypeHistoryAdapter.removeAll(state);
    },
    testQualityTypeHistoryUpsertMany(
      state,
      action: PayloadAction<
        TestQualityTypeHistory[] | Record<number, TestQualityTypeHistory>
      >,
    ) {
      testQualityTypeHistoryAdapter.upsertMany(state, action.payload);
    },
    testQualityTypeHistoryUpsertOne(
      state,
      action: PayloadAction<TestQualityTypeHistory>,
    ) {
      testQualityTypeHistoryAdapter.upsertOne(state, action.payload);
    },
    testQualityTypeHistoryUpdateOne(
      state,
      action: PayloadAction<Update<TestQualityTypeHistory>>,
    ) {
      testQualityTypeHistoryAdapter.updateOne(state, action.payload);
    },
    testQualityTypeHistoryRemoveOne(state, action: PayloadAction<number>) {
      testQualityTypeHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testQualityTypeHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      testQualityTypeHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        testQualityTypeHistoryAdapter.upsertMany(
          state,
          action.payload.testQualityTypeHistory,
        );
      },
    );
    builder.addCase(testQualityTypeHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  testQualityTypeHistoryCleared,
  testQualityTypeHistoryUpsertMany,
  testQualityTypeHistoryUpsertOne,
  testQualityTypeHistoryUpdateOne,
  testQualityTypeHistoryRemoveOne,
} = testQualityTypeHistorySlice.actions;

export const testQualityTypeHistoryReducer =
  testQualityTypeHistorySlice.reducer;
