/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlanApi } from '@testquality/sdk';
import { PlanPurpose } from '@testquality/sdk';
import { PlanSuite } from '@testquality/sdk';
import { AppVersionPlatVersionPlan } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { purposeNormalizer } from '../purpose/purposeNormalize';
import { planPurposeNormalizer } from '../plan_purpose/planPurposeNormalize';
import { suiteNormalizer } from '../suite/suiteNormalize';
import { planSuiteNormalizer } from '../plan_suite/planSuiteNormalize';
import { appVersionPlatVersionNormalizer } from '../app_version_plat_version/appVersionPlatVersionNormalize';
import { appVersionPlatVersionPlanNormalizer } from '../app_version_plat_version_plan/appVersionPlatVersionPlanNormalize';
import { planSuiteTestIncludeNormalizer } from '../plan_suite_test_include/planSuiteTestIncludeNormalize';
import { runNormalizer } from '../run/runNormalize';
import { planPullRequestNormalizer } from '../plan_pull_request/planPullRequestNormalize';

export const planNormalizer = (model: PlanApi, cache: NormalizerCache) => {
  if (!cache.plan) {
    cache.plan = [];
  }
  cache.plan.push(model);

  if (model.purpose && model.purpose.length) {
    model.purpose.forEach((child) => {
      planPurposeNormalizer(child.pivot as PlanPurpose, cache);
      child.pivot = undefined;
      purposeNormalizer(child, cache);
    });
    model.purpose = undefined;
  }
  if (model.suite && model.suite.length) {
    model.suite.forEach((child) => {
      planSuiteNormalizer(child.pivot as PlanSuite, cache);
      child.pivot = undefined;
      suiteNormalizer(child, cache);
    });
    model.suite = undefined;
  }
  if (model.app_version_plat_version && model.app_version_plat_version.length) {
    model.app_version_plat_version.forEach((child) => {
      appVersionPlatVersionPlanNormalizer(
        child.pivot as AppVersionPlatVersionPlan,
        cache,
      );
      child.pivot = undefined;
      appVersionPlatVersionNormalizer(child, cache);
    });
    model.app_version_plat_version = undefined;
  }
  if (model.plan_suite_test_include && model.plan_suite_test_include.length) {
    model.plan_suite_test_include.forEach((child) => {
      planSuiteTestIncludeNormalizer(child, cache);
    });
    model.plan_suite_test_include = undefined;
  }
  if (model.run && model.run.length) {
    model.run.forEach((child) => {
      runNormalizer(child, cache);
    });
    model.run = undefined;
  }
  if (model.plan_pull_request && model.plan_pull_request.length) {
    model.plan_pull_request.forEach((child) => {
      planPullRequestNormalizer(child, cache);
    });
    model.plan_pull_request = undefined;
  }
};
