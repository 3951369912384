import { DataSet } from '@testquality/sdk';

export default function interpolateDataSetRow(
  content = '',
  dataSet?: DataSet,
  dataSetRow?: number,
): string {
  const dataSetRowData = dataSet?.data?.find((row) => row.tq_id === dataSetRow);
  if (!dataSetRowData) {
    return content;
  }
  let result = content;

  Object.entries(dataSetRowData).forEach(([key, val]) => {
    result = result?.replaceAll(new RegExp(`{{${key}}}`, 'g'), String(val));
  });

  return result;
}
