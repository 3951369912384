/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { ProductHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { productHistoryAdapter } from './productHistoryAdapter';
import { productHistoryFetchThunk } from './productHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const productHistorySlice = createSlice({
  name: 'productHistory',
  initialState: productHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    productHistoryCleared(state) {
      state.loading = 'idle';
      productHistoryAdapter.removeAll(state);
    },
    productHistoryUpsertMany(
      state,
      action: PayloadAction<ProductHistory[] | Record<number, ProductHistory>>,
    ) {
      productHistoryAdapter.upsertMany(state, action.payload);
    },
    productHistoryUpsertOne(state, action: PayloadAction<ProductHistory>) {
      productHistoryAdapter.upsertOne(state, action.payload);
    },
    productHistoryUpdateOne(
      state,
      action: PayloadAction<Update<ProductHistory>>,
    ) {
      productHistoryAdapter.updateOne(state, action.payload);
    },
    productHistoryRemoveOne(state, action: PayloadAction<number>) {
      productHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(productHistoryFetchThunk.pending, handlePending);
    builder.addCase(productHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      productHistoryAdapter.upsertMany(state, action.payload.productHistory);
    });
    builder.addCase(productHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  productHistoryCleared,
  productHistoryUpsertMany,
  productHistoryUpsertOne,
  productHistoryUpdateOne,
  productHistoryRemoveOne,
} = productHistorySlice.actions;

export const productHistoryReducer = productHistorySlice.reducer;
