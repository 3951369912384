/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Policy,
  policyGetMany,
  policyGetOne,
  policyDeleteOne,
  policyUpdateOne,
  policyCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { policyNormalizer } from './policyNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const policyFetchManyThunk = createAsyncThunk(
  'policy/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Policy>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await policyGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, policyNormalizer);
      }
      return {
        policy: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyFetchOneThunk = createAsyncThunk(
  'policy/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Policy>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'policy/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await policyGetOne(arg.id as number, arg);
      return executeNormalizer([data], policyNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyDeleteOneThunk = createAsyncThunk(
  'policy/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Policy>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'policy/DeleteOneThunk');
      }
      return await policyDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyUpdateOneThunk = createAsyncThunk(
  'policy/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Policy>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'policy/UpdateOneThunk');
      }
      const data = await policyUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Policy>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Policy>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyCreateOneThunk = createAsyncThunk(
  'policy/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Policy>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'policy/CreateOneThunk',
        );
      }
      return await policyCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
