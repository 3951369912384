/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { AppVersion } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appVersionAdapter } from './appVersionAdapter';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
  appVersionDeleteOneThunk,
  appVersionUpdateOneThunk,
  appVersionCreateOneThunk,
} from './appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appVersionSlice = createSlice({
  name: 'appVersion',
  initialState: appVersionAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appVersionCleared(state) {
      state.loading = 'idle';
      appVersionAdapter.removeAll(state);
    },
    appVersionUpsertMany(
      state,
      action: PayloadAction<AppVersion[] | Record<number, AppVersion>>,
    ) {
      appVersionAdapter.upsertMany(state, action.payload);
    },
    appVersionUpsertOne(state, action: PayloadAction<AppVersion>) {
      appVersionAdapter.upsertOne(state, action.payload);
    },
    appVersionUpdateOne(state, action: PayloadAction<Update<AppVersion>>) {
      appVersionAdapter.updateOne(state, action.payload);
    },
    appVersionRemoveOne(state, action: PayloadAction<number>) {
      appVersionAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appVersionFetchManyThunk.pending, handlePending);
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appVersionAdapter.upsertMany(state, action.payload.appVersion);
    });
    builder.addCase(appVersionFetchManyThunk.rejected, handleRejected);
    builder.addCase(appVersionFetchOneThunk.pending, handlePending);
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appVersionAdapter.upsertOne(state, action.payload.appVersion[0]);
    });
    builder.addCase(appVersionFetchOneThunk.rejected, handleRejected);
    builder.addCase(appVersionDeleteOneThunk.pending, handlePending);
    builder.addCase(appVersionDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        appVersionAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(appVersionDeleteOneThunk.rejected, handleRejected);
    builder.addCase(appVersionUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, appVersionAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(appVersionUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appVersionAdapter.updateOne(state, action.payload);
    });
    builder.addCase(appVersionUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          appVersionAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(appVersionCreateOneThunk.pending, handlePending);
    builder.addCase(appVersionCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appVersionAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(appVersionCreateOneThunk.rejected, handleRejected);
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appVersion) {
        appVersionAdapter.upsertMany(state, action.payload.appVersion);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appVersion) {
        appVersionAdapter.upsertMany(state, action.payload.appVersion);
      }
    });
  },
});

export const {
  appVersionCleared,
  appVersionUpsertMany,
  appVersionUpsertOne,
  appVersionUpdateOne,
  appVersionRemoveOne,
} = appVersionSlice.actions;

export const appVersionReducer = appVersionSlice.reducer;
