import React, { CSSProperties } from 'react';
import { TreeRendererProps } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

function NodeTreeRenderer({
  children,
  canDrop,
  connectDropTarget,
  draggedNode,
  isOver,
  listIndex,
  lowerSiblingCounts,
  scaffoldBlockPxWidth,
  swapDepth,
  swapFrom,
  swapLength,
  treeIndex,
  style,
  treeId, // Delete from otherProps
  getPrevRow, // Delete from otherProps
  node, // Delete from otherProps
  path, // Delete from otherProps
  rowDirection,
  ...rest
}: TreeRendererProps) {
  return connectDropTarget(
    <div
      style={{
        ...(style as CSSProperties),
      }}
      {...rest}>
      {children}
    </div>,
  );
}

export default NodeTreeRenderer;
