/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { useOption } from 'react-aria';
import classnames from 'classnames';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { DoneIcon } from '../icons';
import styles from './Option.module.scss';

export default function Option({ item, state }) {
  const ref = useRef(null);
  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { optionProps, isFocused } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldFocusOnHover: true,
    },
    state,
    ref,
  );
  const optionCN = classnames(styles.option, {
    [styles.optionFocused]: isFocused,
    [styles.disabled]: isDisabled,
  });

  return (
    <li {...optionProps} ref={ref} className={optionCN}>
      {item.rendered}
      {isSelected && (
        <DoneIcon
          className={styles.selectedIcon}
          color={vars.textPrimary}
          size={20}
        />
      )}
    </li>
  );
}
