/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { CasePriority } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { casePriorityAdapter } from './casePriorityAdapter';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
  casePriorityDeleteOneThunk,
  casePriorityUpdateOneThunk,
  casePriorityCreateOneThunk,
} from './casePriorityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const casePrioritySlice = createSlice({
  name: 'casePriority',
  initialState: casePriorityAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    casePriorityCleared(state) {
      state.loading = 'idle';
      casePriorityAdapter.removeAll(state);
    },
    casePriorityUpsertMany(
      state,
      action: PayloadAction<CasePriority[] | Record<number, CasePriority>>,
    ) {
      casePriorityAdapter.upsertMany(state, action.payload);
    },
    casePriorityUpsertOne(state, action: PayloadAction<CasePriority>) {
      casePriorityAdapter.upsertOne(state, action.payload);
    },
    casePriorityUpdateOne(state, action: PayloadAction<Update<CasePriority>>) {
      casePriorityAdapter.updateOne(state, action.payload);
    },
    casePriorityRemoveOne(state, action: PayloadAction<number>) {
      casePriorityAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(casePriorityFetchManyThunk.pending, handlePending);
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      casePriorityAdapter.upsertMany(state, action.payload.casePriority);
    });
    builder.addCase(casePriorityFetchManyThunk.rejected, handleRejected);
    builder.addCase(casePriorityFetchOneThunk.pending, handlePending);
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      casePriorityAdapter.upsertOne(state, action.payload.casePriority[0]);
    });
    builder.addCase(casePriorityFetchOneThunk.rejected, handleRejected);
    builder.addCase(casePriorityDeleteOneThunk.pending, handlePending);
    builder.addCase(casePriorityDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        casePriorityAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(casePriorityDeleteOneThunk.rejected, handleRejected);
    builder.addCase(casePriorityUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, casePriorityAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(casePriorityUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      casePriorityAdapter.updateOne(state, action.payload);
    });
    builder.addCase(casePriorityUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          casePriorityAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(casePriorityCreateOneThunk.pending, handlePending);
    builder.addCase(casePriorityCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      casePriorityAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(casePriorityCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  casePriorityCleared,
  casePriorityUpsertMany,
  casePriorityUpsertOne,
  casePriorityUpdateOne,
  casePriorityRemoveOne,
} = casePrioritySlice.actions;

export const casePriorityReducer = casePrioritySlice.reducer;
