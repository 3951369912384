/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { projectAdapter } from './projectAdapter';

export const projectSelectors = projectAdapter.getSelectors(
  (state: State) => state.gen.project,
);
