import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import classnames from 'classnames';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import IconButton from '../../IconButton/IconButton';
import { CancelIcon, DoneIcon } from '../../icons';
import Spacer from '../../Spacer';
import Button from '../../Button';
import styles from './InlineEditButtons.module.scss';

export type InlineEditButtonsProps = {
  className?: string;
  compact?: boolean;
  isEditing: boolean;
  onCancel: () => void;
  onCommit: () => void;
};

export function InlineEditButtons({
  className,
  compact = true,
  isEditing,
  onCancel,
  onCommit,
}: InlineEditButtonsProps) {
  const { t } = useTranslation();

  if (!isEditing) return null;

  const inlineCN = classnames(className, {
    [styles.compact]: compact,
    [styles.noCompact]: !compact,
  });

  if (!compact) {
    return (
      <Spacer className={inlineCN}>
        <Button
          color="primaryDark"
          name="cancel"
          onClick={onCancel}
          size="small">
          {t('inlineEdit.cancel')}
        </Button>
        <Button color="success" name="save" onClick={onCommit} size="small">
          {t('inlineEdit.done')}
        </Button>
      </Spacer>
    );
  }

  return (
    <Spacer className={inlineCN}>
      <IconButton onClick={onCancel} title={t('inlineEdit.cancel')}>
        <CancelIcon color={vars.textPrimary} size={16} />
      </IconButton>
      <IconButton
        boxed={false}
        color="success"
        onClick={onCommit}
        title={t('inlineEdit.done')}>
        <DoneIcon color={vars.textPrimary} size={16} />
      </IconButton>
    </Spacer>
  );
}
