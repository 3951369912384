/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { RunHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runHistoryAdapter } from './runHistoryAdapter';
import { runHistoryFetchThunk } from './runHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runHistorySlice = createSlice({
  name: 'runHistory',
  initialState: runHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runHistoryCleared(state) {
      state.loading = 'idle';
      runHistoryAdapter.removeAll(state);
    },
    runHistoryUpsertMany(
      state,
      action: PayloadAction<RunHistory[] | Record<number, RunHistory>>,
    ) {
      runHistoryAdapter.upsertMany(state, action.payload);
    },
    runHistoryUpsertOne(state, action: PayloadAction<RunHistory>) {
      runHistoryAdapter.upsertOne(state, action.payload);
    },
    runHistoryUpdateOne(state, action: PayloadAction<Update<RunHistory>>) {
      runHistoryAdapter.updateOne(state, action.payload);
    },
    runHistoryRemoveOne(state, action: PayloadAction<number>) {
      runHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runHistoryFetchThunk.pending, handlePending);
    builder.addCase(runHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runHistoryAdapter.upsertMany(state, action.payload.runHistory);
    });
    builder.addCase(runHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  runHistoryCleared,
  runHistoryUpsertMany,
  runHistoryUpsertOne,
  runHistoryUpdateOne,
  runHistoryRemoveOne,
} = runHistorySlice.actions;

export const runHistoryReducer = runHistorySlice.reducer;
