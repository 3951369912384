/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { sharedPreconditionAdapter } from './sharedPreconditionAdapter';

export const sharedPreconditionSelectors =
  sharedPreconditionAdapter.getSelectors(
    (state: State) => state.gen.sharedPrecondition,
  );
