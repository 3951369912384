import { useState } from 'react';
import { mergeProps, useFocus, useHover } from 'react-aria';
import classnames from 'classnames';
import styles from './useFocusAccent.module.scss';

type Props = { isFocused?: boolean } | undefined;

export function useFocusAccent(props: Props = {}) {
  const { isFocused } = { isFocused: false, ...props };
  const [focused, setFocused] = useState(false);
  const { focusProps } = useFocus({
    onFocusChange: setFocused,
  });
  const { hoverProps, isHovered } = useHover({});

  const borderClassName = getFocusAccentCN(isHovered, focused || isFocused);

  const borderProps = mergeProps(hoverProps, focusProps);

  return { borderClassName, borderProps, focused, isHovered };
}

export function getFocusAccentCN(
  isHovered: boolean,
  isFocused: boolean,
): string {
  return classnames({
    [styles.hovered]: isHovered,
    [styles.focused]: isFocused,
  });
}
