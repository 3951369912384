/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { commentAdapter } from './commentAdapter';

export const commentSelectors = commentAdapter.getSelectors(
  (state: State) => state.gen.comment,
);
