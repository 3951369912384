/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { planPullRequestAdapter } from './planPullRequestAdapter';

export const planPullRequestSelectors = planPullRequestAdapter.getSelectors(
  (state: State) => state.gen.planPullRequest,
);
