/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PolicyRowsHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { policyRowsHistoryAdapter } from './policyRowsHistoryAdapter';
import { policyRowsHistoryFetchThunk } from './policyRowsHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const policyRowsHistorySlice = createSlice({
  name: 'policyRowsHistory',
  initialState: policyRowsHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    policyRowsHistoryCleared(state) {
      state.loading = 'idle';
      policyRowsHistoryAdapter.removeAll(state);
    },
    policyRowsHistoryUpsertMany(
      state,
      action: PayloadAction<
        PolicyRowsHistory[] | Record<number, PolicyRowsHistory>
      >,
    ) {
      policyRowsHistoryAdapter.upsertMany(state, action.payload);
    },
    policyRowsHistoryUpsertOne(
      state,
      action: PayloadAction<PolicyRowsHistory>,
    ) {
      policyRowsHistoryAdapter.upsertOne(state, action.payload);
    },
    policyRowsHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PolicyRowsHistory>>,
    ) {
      policyRowsHistoryAdapter.updateOne(state, action.payload);
    },
    policyRowsHistoryRemoveOne(state, action: PayloadAction<number>) {
      policyRowsHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(policyRowsHistoryFetchThunk.pending, handlePending);
    builder.addCase(policyRowsHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyRowsHistoryAdapter.upsertMany(
        state,
        action.payload.policyRowsHistory,
      );
    });
    builder.addCase(policyRowsHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  policyRowsHistoryCleared,
  policyRowsHistoryUpsertMany,
  policyRowsHistoryUpsertOne,
  policyRowsHistoryUpdateOne,
  policyRowsHistoryRemoveOne,
} = policyRowsHistorySlice.actions;

export const policyRowsHistoryReducer = policyRowsHistorySlice.reducer;
