import React, { ReactNode, FocusEvent, useState } from 'react';
import Select from 'rc-select';
import classNames from 'classnames';
import { getFocusAccentCN } from '@bitmodern/bit-ui';
import 'rc-select/assets/index.less';
import styles from './SelectMultiple.module.scss';
import FieldLabel from '../FieldLabel';
import Loading from '../Loading';
import FieldError from '../FieldError';

type Props = {
  error?: string;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  name?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange: (values: any[]) => void;
  onFocus?: (event: FocusEvent<HTMLElement>) => void;
  options: any[];
  placeholder?: ReactNode;
  required?: boolean;
  showLabel?: boolean;
  tags?: boolean;
  values: any[];
};

export default function SelectMultiple({
  error,
  label,
  labelClassName,
  loading = false,
  name,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  required,
  showLabel = true,
  tags,
  values,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const loadingProps = loading
    ? {
        inputIcon: <Loading className={styles.loading} delay={0} size={20} />,
        loading,
      }
    : {};

  const handleBlur = (event) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const selectCN = classNames(
    styles.select,
    getFocusAccentCN(false, isFocused),
  );

  return (
    <>
      {showLabel && (
        <FieldLabel className={labelClassName} required={required}>
          {label}
        </FieldLabel>
      )}
      <Select
        className={selectCN}
        id={name}
        mode={tags ? 'tags' : 'multiple'}
        optionFilterProp="label"
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        prefixCls="rc-select"
        removeIcon="x"
        tokenSeparators={[',', '\n']}
        virtual={false}
        options={options}
        value={values}
        getPopupContainer={(trigger) => trigger.parentNode}
        {...loadingProps}
      />
      {error && <FieldError>{error}</FieldError>}
    </>
  );
}
