import { saveAs } from 'file-saver';
import { SuiteTest } from '@testquality/sdk';
import { AppThunk } from '@bitmodern/redux/store';
import { generateCsv, genereateXlsx } from 'src/utils/xlsx';
import { stepsByTestSelector } from '../steps/selectors';
import { suiteSelector } from '../suites/selectors';
import { runSelector } from '../runs/selectors';
import {
  runDataToExportSelector,
  runDataToGherkinSelector,
  testDataToExportSelector,
} from './selectors';
import {
  RunResultTreeItem,
  runResultsByRunIdsSelector,
} from '../runResults/selectors';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import { dataSetSelector } from '../dataSets/selectors';
import {
  labelAssignedBySuiteSelector,
  labelAssignedByTestSelector,
} from '../label_assigned/selectors';
import { labelsSelector } from '../label/selectors';
import { GherkinFeature } from './types';
import featureToString from './utils/featureToString';
import createFeature from './utils/createFeature';
import createScenario from './utils/createScenario';
import { treeToRunResultData } from 'src/utils/export';

type AttachmentFile = {
  url: string;
  original_file_name: string;
  attachment_type_id: number;
};

type RequirementDetails = {
  requirement_name: string;
  url: string;
};

export interface ITestRowData {
  folder_key: string;
  folder_name: string;
  test_key: string;
  test_name: string;
  test_type: string | undefined;
  test_priority: string | undefined;
  test_is_automated: string;
  test_precondition: string | undefined;
  test_estimate: string | undefined;
  test_assigned_to: string | undefined;
  test_attachments: AttachmentFile[] | undefined;
  test_requirements: RequirementDetails[] | undefined;
  step_description: string | undefined;
  step_expected_result: string | undefined;
  step_sequence: string;
  data_set: string;
}

export interface IRunRowData {
  run_name: string;
  cycle_name: string | undefined;
  folder_name: string;
  test_key: string;
  test_name: string;
  test_type: string | undefined;
  test_priority: string | undefined;
  test_is_automated: string;
  test_precondition: string | undefined;
  test_estimate: string | undefined;
  test_attachments: AttachmentFile[] | undefined;
  run_result_assigned_to_tester: string | undefined;
  run_result_status: string | undefined;
  run_result_created_by: string | undefined;
  run_result_updated_by: string | undefined;
  run_result_created_at: string;
  run_result_updated_at: string;
  run_result_status_type: string | undefined;
  run_result_flakiness: string | undefined;
  run_result_attachments: string | undefined;
  run_result_defects: string | undefined;
  step_description: string | undefined;
  step_expected_result: string | undefined;
  run_result_step_status: string | undefined;
  run_result_step_elapsed_time: string | undefined;
  run_result_stories: string | undefined;
  data_set: string;
}

export enum ExportType {
  CSV = 'CSV',
  XLS = 'XLS',
  Gherkin = 'Gherkin',
}

export function exportTestsToGherkinThunk(
  suiteTest: SuiteTest[],
): AppThunk<Promise<GherkinFeature[]>> {
  return (dispatch, getState) => {
    const labels = labelsSelector(getState());
    const labelsById = labels.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    const features: GherkinFeature[] = [];

    suiteTest.forEach((st) => {
      const suite = suiteSelector(getState(), st.suite_id);
      const test = testSelectors.selectById(getState(), st.test_id);
      const steps = stepsByTestSelector(getState(), { testId: st.test_id });

      const dataSet = dataSetSelector(getState(), {
        dataSetId: test?.data_set_id,
      });
      const feature = features.find((f) => f.title === suite?.name);
      if (suite && test) {
        const testLabels = labelAssignedByTestSelector(getState(), {
          testId: test.id,
        });
        const testLabelsNames = testLabels.map(
          (item) => labelsById[item.label_id].label,
        );
        if (feature) {
          const oldScenario = feature.scenarios.find(
            (s) => s.description === test.name,
          );
          if (oldScenario) {
            oldScenario.steps = steps
              .map((item) => ({
                when: item.step,
                then: item.expected_result,
                examples: null,
              }))
              .slice();
          } else {
            feature.scenarios.push(
              createScenario(test, steps, testLabelsNames, dataSet),
            );
          }
        } else {
          const featureLabels = labelAssignedBySuiteSelector(getState(), {
            suiteId: suite.id,
          });
          const featureLabelsNames = featureLabels.map(
            (item) => labelsById[item.label_id].label,
          );

          features.push(
            createFeature(
              suite,
              test,
              steps,
              featureLabelsNames ?? [],
              testLabelsNames ?? [],
              dataSet,
            ),
          );
        }
      }
    });

    features.forEach((feature: GherkinFeature) => {
      if (feature.scenarios.length > 0) {
        const content = featureToString(feature);
        const fileName = feature.title.split(' ').join('_').toLowerCase();
        const blob = new Blob([content], { type: 'text/plain' });
        saveAs(blob, `${fileName}.feature`);
      }
    });

    return Promise.resolve(features);
  };
}

export function exportTestsToCSVThunk(
  suiteTest: SuiteTest[],
): AppThunk<Promise<ITestRowData[]>> {
  return (dispatch, getState) => {
    const csvData = testDataToExportSelector(getState(), suiteTest);

    const csvOutput = generateCsv(csvData);
    const data = new Blob([csvOutput], { type: 'text/csv' });
    saveAs(data, 'tests.csv');
    return Promise.resolve(csvData);
  };
}

export function exportTestsToXLSThunk(
  suiteTest: SuiteTest[],
): AppThunk<Promise<ITestRowData[]>> {
  return (dispatch, getState) => {
    const csvData = testDataToExportSelector(getState(), suiteTest);
    if (!csvData) {
      return Promise.reject(new Error('No data to export'));
    }
    return genereateXlsx('tests', csvData).then(() => csvData);
  };
}

export function prepareDataToExport(runs, exportType, site?): AppThunk<void> {
  return (dispatch, getState) => {
    const allRunResultsTrees = runResultsByRunIdsSelector(
      getState(),
      runs.map((r) => r.id),
    );
    const runResultData = treeToRunResultData(allRunResultsTrees);
    const toExport = runResultData.reduce(
      (
        acc: Array<{ runId: number; runResultsData: RunResultData[] }>,
        next,
      ) => {
        const index: number = acc.findIndex(
          (a) => a.runId === next.runResults[0].run_id,
        );
        if (index === -1) {
          acc.push({
            runId: next.runResults[0].run_id,
            runResultsData: [next],
          });
          return acc;
        }

        acc[index].runResultsData.push(next);

        return acc;
      },
      [],
    );

    switch (exportType) {
      case ExportType.CSV:
        dispatch(exportRunsToCSVThunk(toExport));
        break;
      case ExportType.XLS:
        dispatch(exportRunsToXLSThunk(toExport));
        break;
      case ExportType.Gherkin:
        if (site) {
          dispatch(exportRunsToGherkinThunk(toExport, site));
        }
        break;
      default:
        break;
    }
  };
}

export type RunResultData = RunResultTreeItem['data'];

export function exportRunsToCSVThunk(
  runs: Array<{
    runResultsData: RunResultData[];
    runId: number;
  }>,
): AppThunk<Promise<IRunRowData[]>> {
  return (_, getState) => {
    if (!runs) return Promise.reject(new Error('No associated runs'));

    let csvData: IRunRowData[] = [];

    runs.forEach((r) => {
      const run = runSelector(getState(), r.runId);
      if (run) {
        csvData = csvData.concat(
          runDataToExportSelector(getState(), run, r.runResultsData),
        );
      }
    });

    const csvOutput = generateCsv(csvData);
    const data = new Blob([csvOutput], { type: 'text/csv' });
    saveAs(data, 'tests.csv');

    return Promise.resolve(csvData);
  };
}
export function exportRunsToGherkinThunk(
  runs: Array<{
    runResultsData: RunResultData[];
    runId: number;
  }>,
  siteId: string,
): AppThunk<Promise<IRunRowData[]>> {
  return (_, getState) => {
    if (!runs) return Promise.reject(new Error('No associated runs'));

    let gherkinData: any[] = [];

    runs.forEach((r) => {
      const run = runSelector(getState(), r.runId);
      if (run) {
        gherkinData = gherkinData.concat(
          runDataToGherkinSelector(getState(), {
            run,
            runResultsData: r.runResultsData,
            siteId,
          }),
        );
      }
    });

    const data = new Blob([JSON.stringify(gherkinData)], {
      type: 'application/json',
    });
    saveAs(data, 'tests.json');

    return Promise.resolve(gherkinData);
  };
}

export function exportRunsToXLSThunk(
  runs: Array<{
    runResultsData: RunResultData[];
    runId: number;
  }>,
): AppThunk<Promise<IRunRowData[]>> {
  return (_, getState) => {
    if (!runs) return Promise.reject(new Error('No associated runs'));
    let csvData: IRunRowData[] = [];

    runs.forEach((r) => {
      const run = runSelector(getState(), r.runId);
      if (run) {
        csvData = csvData.concat(
          runDataToExportSelector(getState(), run, r.runResultsData),
        );
      }
    });

    if (!csvData) {
      return Promise.reject(new Error('No data to export'));
    }
    return genereateXlsx('tests', csvData).then(() => csvData);
  };
}
