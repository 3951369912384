import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { planSelector } from '@bitmodern/redux/state/plans/selectors';
import useParams from 'src/hooks/useParams';
import { Test, SuiteTest } from '@testquality/sdk';
import { useQuery } from 'src/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';

import { routes } from 'src/components/Router';
import { planDeleteOneThunk } from 'src/gen/domain/plan/planThunk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import PlanView from './PlanView';

export default function PlanViewContainer() {
  const { projectId, planId, site } = useParams<typeof routes.PLAN.params>();
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawerManager();
  const { folderId } = useQuery(['folderId']);
  const history = useHistory();
  const plan = useAppSelector((state) =>
    planSelector(state, parseInt(planId, 10)),
  );

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const onDelete = useCallback(() => {
    if (!plan) return Promise.resolve();
    return dispatch(planDeleteOneThunk({ id: plan.id })).then(() => {
      history.push(routes.PLANS({ site, projectId }));
    });
  }, [dispatch, history, plan, projectId, site]);

  if (!plan) return null;

  return (
    <PlanView
      current={parseInt(folderId, 10)}
      onClickTest={onClickTest}
      onDelete={onDelete}
      plan={plan}
    />
  );
}
