import React, {
  ComponentProps,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  AddFolderIcon,
  AddIcon,
  BookmarkIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  LabelIcon,
  ListBulletedIcon,
  PlanIcon,
  UserIcon,
} from '@bitmodern/bit-ui/icons';
import { TreeItem } from 'react-sortable-tree';
import { useOverlayTriggerState } from 'react-stately';
import vars from 'src/export.scss';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { plansByProjectSelector } from '@bitmodern/redux/state/plans/selectors';
import { useTranslation } from 'src/i18n/hooks';
import { Avatar } from '@bitmodern/bit-ui';
import {
  TestApi,
  BatchService,
  Plan,
  showNotificationError,
  Requirement,
} from '@testquality/sdk';
import { testUpdateOneThunk } from 'src/gen/domain/test/testThunk';
import {
  exportTestsToCSVThunk,
  exportTestsToGherkinThunk,
  exportTestsToXLSThunk,
} from '@bitmodern/redux/state/exportData/thunks';

import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import {
  includeTestInPlanThunk,
  excludeTestFromPlanThunk,
  includeSuiteInPlanThunk,
  excludeSuiteFromPlanThunk,
} from '@bitmodern/redux/state/planSuitesTestsIncludes/thunks';

import {
  cloneTestBatchThunk,
  deleteSuiTestsTestsThunk,
} from '@bitmodern/redux/state/tests/thunks';
import { deleteSuitesThunk } from '@bitmodern/redux/state/suites/thunks';
import { suitesFlatTreeSelector } from '@bitmodern/redux/state/suites/selectors';
import { planCreateOneThunk } from 'src/gen/domain/plan/planThunk';
import {
  labelAssignedSelector,
  testLabelsSelector,
} from '@bitmodern/redux/state/label_assigned/selectors';

import { labelCreateOneThunk } from 'src/gen/domain/label/labelThunk';
import { labelAssignedCreateOneThunk } from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { suiteTestCreateOneThunk } from 'src/gen/domain/suite_test/suiteTestThunk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { requirementsByProjectSelector } from '@bitmodern/redux/state/requirements/selectors';
import { requirementTestCreateOneThunk } from 'src/gen/domain/requirement_test/requirementTestThunk';
import { allIntegrationsProjectByProjectIdSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { SUITE_TYPE, TEST_TYPE } from '../TreeBase/treeTypes';
import CommandBar from '../CommandBar';
import ConfirmDialog from '../ConfirmDialog';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';
import CloneDialog from '../CloneDialog';
import { useParamSelector } from 'src/packages/redux/hooks';
// import { allIntegrationsProjectByProjectIdSelector } from 'src/packages/redux/state/integrationProject/selectors';

/* Commented code are related to create and link a new story dirrectly from command bar.
With multiingrations we will need to chose with what integration story will be connected. */

type ComandBarProps = ComponentProps<typeof CommandBar>;
type Commands = ComandBarProps['commands'];
type Command = Commands[number];
type ConfirmDialogProps = ComponentProps<typeof ConfirmDialog>;

type Props = Pick<ComandBarProps, 'onClose' | 'open'> & {
  plan?: Plan;
  items: TreeItem[];
};
// FIXME: Show all integrations
function CommandBarTests({ plan, open, items, onClose }: Props, ref) {
  const { t } = useTranslation();
  const { site, projectId } = useParams<typeof routes.PROJECT.params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const cloneDialog = useOverlayTriggerState({});
  const confirmDialog = useOverlayTriggerState({});
  const [isConfirmDialogLoading, setIsConfirmDialogLoading] = useState(false);
  const [search, setSearch] = useState('');
  // const [selectedIp, setSelectedIp] = useState<any>();

  const [confirmDialogProps, setConfirmDialogProps] =
    useState<ConfirmDialogProps>();
  const [current, setCurrent] = useState('default');
  const [isLoading, setIsLoading] = useState(false);
  const users = useAppSelector(usersSelector);
  const caseTypes = useAppSelector(caseTypesSelector);
  const labels = useAppSelector(testLabelsSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const suitesTree = useAppSelector((state) =>
    suitesFlatTreeSelector(state, parseInt(projectId, 10)),
  );
  const plans = useAppSelector((state) =>
    plansByProjectSelector(state, { projectId: parseInt(projectId, 10) }),
  );

  // const integrationProject = useAppSelector((state) =>
  //   integrationProjectByProjectIdSelector(state, {
  //     projectId: parseInt(projectId, 10),
  //   }),
  // );

  const integrationsByProjectId = useAppSelector((state) => {
    return allIntegrationsProjectByProjectIdSelector(state, {
      projectId: parseInt(projectId, 10),
    });
  });

  // const integrationProjectOptions =
  //   integrationsByProjectId?.map((ip) => ({
  //     id: ip.project_reference_id,
  //     label: ip.project_reference_id,
  //     value: ip.project_reference_id,
  //   })) || [];

  // const integration = useAppSelector((state) => {
  //   return integrationByIdSelector(state, {
  //     id: integrationProject?.integration_id,
  //   });
  // });

  const requirements = useParamSelector(requirementsByProjectSelector, {
    projectId: parseInt(projectId, 10),
  });

  useImperativeHandle(ref, () => ({ setCurrent }), []);

  const labelsAssigned = useAppSelector(labelAssignedSelector);

  const tests = items.filter((item) => item.type === TEST_TYPE);

  const suites = items.filter((item) => item.type === SUITE_TYPE);
  const emptySuites = suites.filter(
    (item) => item.children === undefined || item.children.length === 0,
  );

  const handleClose = useCallback(() => {
    setCurrent('default');
    // setSelectedIp(null)
    onClose();
    setIsLoading(false);
  }, [onClose]);

  const removeFromPlanHandler = useCallback(() => {
    if (!plan) return;
    setIsLoading(true);
    const suiteTests = tests.map((item) => item.data.suiteTest);
    dispatch(excludeTestFromPlanThunk(plan.id, suiteTests));
    dispatch(
      excludeSuiteFromPlanThunk(
        plan.id,
        emptySuites.map((s) => s.data.suite),
      ),
    );
    handleClose();
  }, [plan, tests, emptySuites, dispatch, handleClose]);

  const addToPlanHandler = useCallback(
    async (action) => {
      setIsLoading(true);
      await dispatch(
        includeTestInPlanThunk(
          action.key,
          tests.map((item) => item.data.suiteTest),
        ),
      );
      await dispatch(
        includeSuiteInPlanThunk(
          action.key,
          emptySuites.map((s) => s.data.suite),
        ),
      );
      history.replace(routes.PLAN({ site, planId: action.key, projectId }));
      handleClose();
    },
    [site, dispatch, handleClose, history, projectId, tests, emptySuites],
  );

  const addToRequirementHandler = async (action) => {
    setIsLoading(true);

    if (action.key) {
      return createRequirementTest(action?.key, tests)
        .then(handleClose)
        .catch(showNotificationError);
    }

    return Promise.resolve();
  };

  // const handleAddNewRequirement = async (action) => {
  //   if(selectedIp){
  //     const req = await createRequirement({
  //       payload: {
  //         summary: action.command,
  //         description: '',
  //         labels: [],
  //         priority: {},
  //         resolution: {},
  //         assignee: {},
  //       },
  //       project_id: parseInt(projectId, 10),
  //       integration_project_id: selectedIp.id,
  //     });
  //     return createRequirementTest((req as any).payload.id, tests)
  //       .catch((err) => {
  //         handleClose()
  //         showNotificationError(err);
  //       })
  //   }

  //   // setIsLoading(true);
  //   // setOpenSelectIntegration(true)

  // };

  // const createRequirement = async (data) => {
  //   return dispatch(
  //     requirementCreateOneThunk({
  //       data,
  //     } as unknown as Requirement),
  //   );
  // };

  const createRequirementTest = (requirementId, testItems) => {
    const batch = new BatchService();
    testItems.forEach((value) => {
      dispatch(
        requirementTestCreateOneThunk({
          data: {
            test_id: value.data.test.id,
            requirement_id: requirementId,
            suite_id: value.data.suiteTest.suite_id,
          },
          batch,
        }),
      );
    });
    return batch
      .executeBatch()
      .then(handleClose)
      .catch((err) => {
        handleClose();
        showNotificationError(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAddToCurrentPlan = useCallback(async () => {
    if (plan) {
      await dispatch(
        includeTestInPlanThunk(
          plan.id,
          tests.map((item) => item.data.suiteTest),
        ),
      );
      await dispatch(
        includeSuiteInPlanThunk(
          plan.id,
          emptySuites.map((s) => s.data.suite),
        ),
      );
    }
    handleClose();
  }, [dispatch, handleClose, plan, tests, emptySuites]);

  const testsCommands: Commands = useMemo(() => {
    const cmds = [
      {
        command: 'add to cycle',
        key: 'addToPlan',
        handler: () => setCurrent('addToPlan'),
        icon: <PlanIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.addToCycle'),
      },
      {
        command: 'assign to',
        key: 'assigTo',
        handler: () => setCurrent('assign'),
        icon: <UserIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.assignTo'),
      },
      {
        command: 'set case type',
        key: 'caseType',
        handler: () => setCurrent('caseType'),
        icon: <ListBulletedIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.caseType'),
      },
      {
        command: 'set case priority',
        key: 'casePriority',
        handler: () => setCurrent('casePriority'),
        icon: <ListBulletedIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.casePriority'),
      },
      {
        command: 'clone',
        key: 'clone',
        handler: cloneDialog.open,
        icon: <CopyIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.clone'),
      },
      {
        command: 'export tests',
        key: 'export',
        handler: () => setCurrent('exportFormat'),
        icon: <DownloadIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.export'),
      },
      {
        command: 'labels',
        key: 'label',
        handler: () => setCurrent('label'),
        icon: <LabelIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.labels'),
      },
    ];

    if (integrationsByProjectId) {
      cmds.splice(1, 0, {
        command: 'add to requirement',
        key: 'addToRequirement',
        handler: () => setCurrent('addToRequirement'),
        icon: <BookmarkIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.addToRequirement'),
      });
    }

    if (plan) {
      const planCommands = [
        {
          command: `add to ${plan.name}`,
          key: 'addToCurrentPlan',
          handler: handleAddToCurrentPlan,
          icon: <PlanIcon color={vars.textPrimary} size={18} />,
          label: t('commandBarTests.testCommands.addTo', {
            planName: plan.name,
          }),
        },
        {
          command: 'remove from cycle',
          key: 'removeFromPlan',
          handler: removeFromPlanHandler,
          icon: <PlanIcon color={vars.textPrimary} size={18} />,
          label: t('commandBarTests.testCommands.removeFrom', {
            planName: plan.name,
          }),
        },
      ];

      return [...cmds, ...planCommands];
    }
    return cmds;
  }, [
    plan,
    integrationsByProjectId,
    cloneDialog,
    handleAddToCurrentPlan,
    removeFromPlanHandler,
    setCurrent,
    t,
  ]);

  // const onChangeIp = (value) => {
  //   if (value) {
  //     const ip = integrationsByProjectId?.find(
  //       (el) => el.project_reference_id === value,
  //     );
  //     setSelectedIp(ip);
  //   }
  // };

  // const onChangeIp = (value) => {
  //   if (value) {
  //     const ip = integrationsByProjectId?.find(
  //       (el) => el.project_reference_id === value,
  //     );
  //     setSelectedIp(ip);
  //   }
  // };

  const commands: { [key: string]: Commands } = {
    default: [
      ...(tests.length ? testsCommands : []),
      {
        command: 'delete',
        key: 'delete',
        handler: deleteHandler,
        icon: <DeleteIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.delete'),
      },
      {
        command: 'add to folder',
        key: 'addToFolder',
        handler: () => setCurrent('addToFolder'),
        icon: <AddFolderIcon color={vars.textPrimary} size={18} />,
        label: t('commandBarTests.testCommands.addToFolder'),
      },
    ],

    addToPlan: (() => {
      const addToPlanCommands = plans.map<Command>((p) => ({
        command: p.name,
        key: p.id,
        handler: addToPlanHandler,
        icon: <PlanIcon color={vars.textPrimary} size={18} />,
        label: p.name,
      }));

      if (search) {
        addToPlanCommands.push({
          command: search,
          key: search,
          handler: handleAddNewPlan,
          icon: <AddIcon color={vars.textPrimary} size={18} />,
          label: t('commandBarTests.testCommands.create', { search }),
        });
      }

      return addToPlanCommands;
    })(),

    addToRequirement: (() => {
      let requirementsCommands: Command[] = [];
      if (requirements?.length) {
        requirementsCommands = requirements.map<Command>((r: Requirement) => {
          const integrationProject = integrationsByProjectId?.find(
            (ip) => ip.id === r.integration_project_id,
          );
          return {
            command: r.payload.summary || '',
            key: r.id,
            handler: addToRequirementHandler,
            icon: <BookmarkIcon color={vars.textPrimary} size={18} />,
            label: r.payload.summary || '',
            sublabel: integrationProject
              ? integrationProject?.org +
                '/' +
                integrationProject?.project_reference_id
              : null,
          };
        });
      }

      // if (search) {
      //   requirementsCommands.push({
      //     command: search,
      //     key: search,
      //     handler: handleAddNewRequirement,
      //     icon: <AddIcon color={vars.textPrimary} size={18} />,
      //     label: t('commandBarTests.testCommands.create', { search }),
      //   });
      // }

      return requirementsCommands;
    })(),

    assign: [
      {
        command: 'unassign',
        key: null,
        handler: unassign,
        icon: <UserIcon size={18} />,
        label: t('commandBarTests.testCommands.unassign'),
      },
      ...users.map((user) => ({
        command: user.given_name || user.email,
        key: user.id,
        handler: assignToHandler,
        icon: <Avatar size={18} src={processAttachmentPath(user.picture)} />,
        label: formatUserName(user),
      })),
    ],
    caseType: caseTypes.map((caseType) => ({
      command: caseType.name,
      key: caseType.id,
      handler: caseTypeHandler,
      icon: <span />,
      label: caseType.name,
    })),
    casePriority: casePriorities.map((casePriority) => ({
      command: casePriority.name,
      key: casePriority.id,
      handler: casePrioritiesHandler,
      icon: <span />,
      label: casePriority.name,
    })),
    addToFolder: suitesTree.map((item) => ({
      command: item.data.suite.name,
      key: item.data.suite.id,
      handler: addToFolderHandler,
      icon: <span style={{ width: item.nestingLevel * 16 }} />,
      label: item.data.suite.name,
    })),

    label: (() => {
      const assigneToLabel = labels.map<Command>((label) => ({
        command: label.label,
        key: label.id,
        handler: labelHandler,
        icon: <LabelIcon color={vars.textPrimary} size={18} />,
        label: label.label,
      }));

      if (search) {
        assigneToLabel.push({
          command: search,
          key: search,
          handler: handleAddNewLabel,
          icon: <AddIcon color={vars.textPrimary} size={18} />,
          label: t('commandBarTests.testCommands.createAssigned', { search }),
        });
      }
      return assigneToLabel;
    })(),
    exportFormat: [
      {
        command: 'gherkin',
        key: 'gerkinExport',
        handler: handleGherkinExport,
        icon: <span />,
        label: t('commandBarTests.fileFormats.gherkin'),
      },
      {
        command: 'csv',
        key: 'csvExport',
        handler: handleCSVExport,
        icon: <span />,
        label: t('commandBarTests.fileFormats.csv'),
      },
      {
        command: 'excel',
        key: 'xlsExport',
        handler: handleXLSExport,
        icon: <span />,
        label: t('commandBarTests.fileFormats.excel'),
      },
    ],
  };

  const placeholders = {
    default: t('commandBarTests.placeholder.default'),
    caseType: t('commandBarTests.placeholder.caseType'),
    casePriority: t('commandBarTests.placeholder.casePriority'),
    addToPlan: t('commandBarTests.placeholder.addToPlan'),
    addToRequirement: t('commandBarTests.placeholder.addToRequirement'),
    label: t('commandBarTests.placeholder.assignedToLabel'),
  };

  function handleAddNewLabel(action) {
    const labelToAssignedId = action.key;
    dispatch(labelCreateOneThunk({ data: { label: labelToAssignedId } }))
      .then(unwrapResult)
      .then((label) => {
        labelHandler({ ...action, key: label.id });
      });
  }

  function labelHandler(action) {
    const batch = new BatchService();
    const labelToAssignedId = action.key;

    items.forEach((item) => {
      const id = item.data?.test?.id || item.data?.suite?.id;
      const addToLabel = labelsAssigned.find(
        (tla) => tla.label_id === labelToAssignedId && tla.related_id === id,
      );

      if (!addToLabel) {
        let relatedType = '';
        if (item.type === TEST_TYPE) {
          relatedType = RelatedType.TEST;
        } else if (item.type === SUITE_TYPE) {
          relatedType = RelatedType.SUITE;
        }

        dispatch(
          labelAssignedCreateOneThunk({
            data: {
              related_id: id,
              related_type: relatedType,
              label_id: labelToAssignedId,
            },
            batch,
          }),
        );
      }
    });
    batch.executeBatch();

    handleClose();
  }

  async function handleAddNewPlan(action) {
    try {
      const newPlan = await dispatch(
        planCreateOneThunk({
          data: {
            name: action.key,
            project_id: parseInt(projectId, 10),
          },
        }),
      ).then(unwrapResult);
      await addToPlanHandler({ ...action, key: newPlan.id });
    } finally {
      handleClose();
    }
  }

  function caseTypeHandler(action) {
    const caseType = caseTypes.find((c) => action.key === c.id);
    if (caseType) {
      const values = tests.map((item) => ({
        case_type_id: caseType.id,
        id: item.data.test.id,
      }));
      updateTests(values);
    }
    handleClose();
  }

  async function deleteHandler() {
    const handler = () => {
      setIsConfirmDialogLoading(true);
      const suiteTests = tests.map((item) => item.data.suiteTest);
      dispatch(deleteSuiTestsTestsThunk(suiteTests)).finally(() => {
        dispatch(
          deleteSuitesThunk(suites.map((item) => item.data.planSuite)),
        ).finally(() => {
          confirmDialog.close();
          handleClose();
          setIsConfirmDialogLoading(false);
        });
      });
    };
    setConfirmDialogProps({
      onCancel: confirmDialog.close,
      onConfirm: handler,
      open: confirmDialog.isOpen,
      title: t('commandBarTests.deleteDialog.title'),
      children: t('commandBarTests.deleteDialog.content'),
    });
    confirmDialog.open();
  }

  const onClone = ({ suite }: any) => {
    const suiteTestsToClone = tests.map((item) => item.data.suiteTest);
    return dispatch(
      cloneTestBatchThunk({
        suiteTests: suiteTestsToClone,
        plan,
        suiteId: suite,
      }),
    ).then(
      () => {
        cloneDialog.close();
        handleClose();
      },
      (error) => showNotificationError(error),
    );
  };

  function assignToHandler(action) {
    const user = users.find((u) => action.key === u.id);
    if (user) {
      const values = tests.map((item) => ({
        assigned_to_tester: user.id,
        id: item.data.test.id,
      }));
      updateTests(values);
    }
    handleClose();
  }

  function unassign() {
    const values = tests.map((item) => ({
      assigned_to_tester: null,
      id: item.data.test.id,
    }));
    // We can't fix this type error without modifying the model
    // @ts-expect-error
    updateTests(values);
    handleClose();
  }

  function handleGherkinExport() {
    const suiteTests = tests.map((item) => item.data?.suiteTest);
    dispatch(exportTestsToGherkinThunk(suiteTests));
  }

  function handleCSVExport() {
    const suiteTests = tests.map((item) => item.data?.suiteTest);
    dispatch(exportTestsToCSVThunk(suiteTests));
  }

  function handleXLSExport() {
    const suiteTests = tests.map((item) => item.data?.suiteTest);
    dispatch(exportTestsToXLSThunk(suiteTests));
  }

  function casePrioritiesHandler(action) {
    const casePriority = casePriorities.find((c) => action.key === c.id);
    if (casePriority) {
      const values = tests.map((item) => ({
        case_priority_id: casePriority.id,
        id: item.data.test.id,
      }));
      updateTests(values);
    }
    handleClose();
  }

  function addToFolderHandler(action) {
    const suiteTests = tests.map((i) => i.data?.suiteTest);
    const batch = new BatchService();
    suiteTests.forEach((st) => {
      dispatch(
        suiteTestCreateOneThunk({
          data: { test_id: st.test_id, suite_id: action.key },
          batch,
        }),
      );
    });
    batch.executeBatch();
    handleClose();
  }

  const updateTests = (values: Array<Partial<TestApi>>) => {
    const batch = new BatchService();
    values.forEach((value) => {
      dispatch(
        testUpdateOneThunk({
          data: value,
          id: value.id,
          optimistic: true,
          batch,
        }),
      );
    });
    batch.executeBatch();
  };

  return (
    <>
      <CommandBar
        commands={commands[current]}
        id={current}
        onChangeSearch={setSearch}
        onClose={handleClose}
        open={open}
        placeholder={placeholders[current] || placeholders.default}
        search={search}
        isLoading={isLoading}
      />
      <ConfirmDialog
        loading={isConfirmDialogLoading}
        onCancel={confirmDialogProps?.onCancel}
        onConfirm={confirmDialogProps?.onConfirm}
        open={confirmDialog.isOpen}
        title={confirmDialogProps?.title}>
        {confirmDialogProps?.children}
      </ConfirmDialog>

      <CloneDialog
        isOpen={cloneDialog.isOpen}
        onClone={onClone}
        onClose={cloneDialog.close}
      />
    </>
  );
}

export default forwardRef(CommandBarTests);
