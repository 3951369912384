/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { combineReducers } from 'redux';
import { accessRoleReducer } from '../domain/access_role/accessRoleSlice';
import { accessRoleHistoryReducer } from '../domain/access_role/accessRoleHistorySlice';
import { accessRoleUserReducer } from '../domain/access_role_user/accessRoleUserSlice';
import { appReducer } from '../domain/app/appSlice';
import { appHistoryReducer } from '../domain/app/appHistorySlice';
import { appConfigReducer } from '../domain/app_config/appConfigSlice';
import { appConfigHistoryReducer } from '../domain/app_config/appConfigHistorySlice';
import { appInstallReducer } from '../domain/app_install/appInstallSlice';
import { appInstallHistoryReducer } from '../domain/app_install/appInstallHistorySlice';
import { appInstallProjectReducer } from '../domain/app_install_project/appInstallProjectSlice';
import { appInstallProjectHistoryReducer } from '../domain/app_install_project/appInstallProjectHistorySlice';
import { appVersionReducer } from '../domain/app_version/appVersionSlice';
import { appVersionHistoryReducer } from '../domain/app_version/appVersionHistorySlice';
import { appVersionPlatVersionReducer } from '../domain/app_version_plat_version/appVersionPlatVersionSlice';
import { appVersionPlatVersionHistoryReducer } from '../domain/app_version_plat_version/appVersionPlatVersionHistorySlice';
import { appVersionPlatVersionPlanReducer } from '../domain/app_version_plat_version_plan/appVersionPlatVersionPlanSlice';
import { attachmentReducer } from '../domain/attachment/attachmentSlice';
import { attachmentHistoryReducer } from '../domain/attachment/attachmentHistorySlice';
import { baseCapabilityReducer } from '../domain/base_capability/baseCapabilitySlice';
import { baseCapabilityHistoryReducer } from '../domain/base_capability/baseCapabilityHistorySlice';
import { baseCapabilityBaseIntegrationReducer } from '../domain/base_capability_base_integration/baseCapabilityBaseIntegrationSlice';
import { baseIntegrationReducer } from '../domain/base_integration/baseIntegrationSlice';
import { baseIntegrationHistoryReducer } from '../domain/base_integration/baseIntegrationHistorySlice';
import { billingContactReducer } from '../domain/billing_contact/billingContactSlice';
import { billingContactHistoryReducer } from '../domain/billing_contact/billingContactHistorySlice';
import { capabilityReducer } from '../domain/capability/capabilitySlice';
import { capabilityHistoryReducer } from '../domain/capability/capabilityHistorySlice';
import { capabilityIntegrationReducer } from '../domain/capability_integration/capabilityIntegrationSlice';
import { casePriorityReducer } from '../domain/case_priority/casePrioritySlice';
import { casePriorityHistoryReducer } from '../domain/case_priority/casePriorityHistorySlice';
import { caseTypeReducer } from '../domain/case_type/caseTypeSlice';
import { caseTypeHistoryReducer } from '../domain/case_type/caseTypeHistorySlice';
import { checkRunReducer } from '../domain/check_run/checkRunSlice';
import { checkRunHistoryReducer } from '../domain/check_run/checkRunHistorySlice';
import { checkSuiteReducer } from '../domain/check_suite/checkSuiteSlice';
import { checkSuiteHistoryReducer } from '../domain/check_suite/checkSuiteHistorySlice';
import { clientReducer } from '../domain/client/clientSlice';
import { clientHistoryReducer } from '../domain/client/clientHistorySlice';
import { commentReducer } from '../domain/comment/commentSlice';
import { commentHistoryReducer } from '../domain/comment/commentHistorySlice';
import { couponReducer } from '../domain/coupon/couponSlice';
import { couponHistoryReducer } from '../domain/coupon/couponHistorySlice';
import { dataSetReducer } from '../domain/data_set/dataSetSlice';
import { dataSetHistoryReducer } from '../domain/data_set/dataSetHistorySlice';
import { defectReducer } from '../domain/defect/defectSlice';
import { defectHistoryReducer } from '../domain/defect/defectHistorySlice';
import { defectComponentReducer } from '../domain/defect_component/defectComponentSlice';
import { defectLabelReducer } from '../domain/defect_label/defectLabelSlice';
import { defectPriorityReducer } from '../domain/defect_priority/defectPrioritySlice';
import { defectResReducer } from '../domain/defect_res/defectResSlice';
import { defectResHistoryReducer } from '../domain/defect_res/defectResHistorySlice';
import { defectResNativeDefectResReducer } from '../domain/defect_res_native_defect_res/defectResNativeDefectResSlice';
import { defectRunResultReducer } from '../domain/defect_run_result/defectRunResultSlice';
import { defectStatusReducer } from '../domain/defect_status/defectStatusSlice';
import { defectStatusHistoryReducer } from '../domain/defect_status/defectStatusHistorySlice';
import { defectStatusNativeDefectStatusReducer } from '../domain/defect_status_native_defect_status/defectStatusNativeDefectStatusSlice';
import { defectTypeReducer } from '../domain/defect_type/defectTypeSlice';
import { defectUserReducer } from '../domain/defect_user/defectUserSlice';
import { filterReducer } from '../domain/filter/filterSlice';
import { filterHistoryReducer } from '../domain/filter/filterHistorySlice';
import { integrationReducer } from '../domain/integration/integrationSlice';
import { integrationHistoryReducer } from '../domain/integration/integrationHistorySlice';
import { integrationProjectReducer } from '../domain/integration_project/integrationProjectSlice';
import { integrationTemplateReducer } from '../domain/integration_template/integrationTemplateSlice';
import { integrationTemplateHistoryReducer } from '../domain/integration_template/integrationTemplateHistorySlice';
import { integrationUserReducer } from '../domain/integration_user/integrationUserSlice';
import { invoiceReducer } from '../domain/invoice/invoiceSlice';
import { invoiceHistoryReducer } from '../domain/invoice/invoiceHistorySlice';
import { labelReducer } from '../domain/label/labelSlice';
import { labelHistoryReducer } from '../domain/label/labelHistorySlice';
import { labelAssignedReducer } from '../domain/label_assigned/labelAssignedSlice';
import { labelAssignedHistoryReducer } from '../domain/label_assigned/labelAssignedHistorySlice';
import { milestoneReducer } from '../domain/milestone/milestoneSlice';
import { milestoneHistoryReducer } from '../domain/milestone/milestoneHistorySlice';
import { nativeDefectResReducer } from '../domain/native_defect_res/nativeDefectResSlice';
import { nativeDefectResHistoryReducer } from '../domain/native_defect_res/nativeDefectResHistorySlice';
import { nativeDefectStatusReducer } from '../domain/native_defect_status/nativeDefectStatusSlice';
import { nativeDefectStatusHistoryReducer } from '../domain/native_defect_status/nativeDefectStatusHistorySlice';
import { notificationsReducer } from '../domain/notifications/notificationsSlice';
import { planReducer } from '../domain/plan/planSlice';
import { planHistoryReducer } from '../domain/plan/planHistorySlice';
import { planPullRequestReducer } from '../domain/plan_pull_request/planPullRequestSlice';
import { planPurposeReducer } from '../domain/plan_purpose/planPurposeSlice';
import { planSuiteReducer } from '../domain/plan_suite/planSuiteSlice';
import { planSuiteTestIncludeReducer } from '../domain/plan_suite_test_include/planSuiteTestIncludeSlice';
import { planSuiteTestIncludeHistoryReducer } from '../domain/plan_suite_test_include/planSuiteTestIncludeHistorySlice';
import { platReducer } from '../domain/plat/platSlice';
import { platHistoryReducer } from '../domain/plat/platHistorySlice';
import { platVersionReducer } from '../domain/plat_version/platVersionSlice';
import { platVersionHistoryReducer } from '../domain/plat_version/platVersionHistorySlice';
import { policyReducer } from '../domain/policy/policySlice';
import { policyHistoryReducer } from '../domain/policy/policyHistorySlice';
import { policyRowsReducer } from '../domain/policy_rows/policyRowsSlice';
import { policyRowsHistoryReducer } from '../domain/policy_rows/policyRowsHistorySlice';
import { productReducer } from '../domain/product/productSlice';
import { productHistoryReducer } from '../domain/product/productHistorySlice';
import { projectReducer } from '../domain/project/projectSlice';
import { projectHistoryReducer } from '../domain/project/projectHistorySlice';
import { pullRequestReducer } from '../domain/pull_request/pullRequestSlice';
import { pullRequestHistoryReducer } from '../domain/pull_request/pullRequestHistorySlice';
import { purposeReducer } from '../domain/purpose/purposeSlice';
import { purposeHistoryReducer } from '../domain/purpose/purposeHistorySlice';
import { requirementReducer } from '../domain/requirement/requirementSlice';
import { requirementHistoryReducer } from '../domain/requirement/requirementHistorySlice';
import { requirementTestReducer } from '../domain/requirement_test/requirementTestSlice';
import { runReducer } from '../domain/run/runSlice';
import { runHistoryReducer } from '../domain/run/runHistorySlice';
import { runResultReducer } from '../domain/run_result/runResultSlice';
import { runResultHistoryReducer } from '../domain/run_result/runResultHistorySlice';
import { runResultStepReducer } from '../domain/run_result_step/runResultStepSlice';
import { runResultStepHistoryReducer } from '../domain/run_result_step/runResultStepHistorySlice';
import { runSuiteReducer } from '../domain/run_suite/runSuiteSlice';
import { runSuiteHistoryReducer } from '../domain/run_suite/runSuiteHistorySlice';
import { sharedPreconditionReducer } from '../domain/shared_precondition/sharedPreconditionSlice';
import { sharedPreconditionHistoryReducer } from '../domain/shared_precondition/sharedPreconditionHistorySlice';
import { sharedStepReducer } from '../domain/shared_step/sharedStepSlice';
import { sharedStepHistoryReducer } from '../domain/shared_step/sharedStepHistorySlice';
import { signupOptionReducer } from '../domain/signup_option/signupOptionSlice';
import { signupOptionHistoryReducer } from '../domain/signup_option/signupOptionHistorySlice';
import { statusReducer } from '../domain/status/statusSlice';
import { statusHistoryReducer } from '../domain/status/statusHistorySlice';
import { statusTypeReducer } from '../domain/status_type/statusTypeSlice';
import { statusTypeHistoryReducer } from '../domain/status_type/statusTypeHistorySlice';
import { stepReducer } from '../domain/step/stepSlice';
import { stepHistoryReducer } from '../domain/step/stepHistorySlice';
import { subscriptionUserReducer } from '../domain/subscription_user/subscriptionUserSlice';
import { subscriptionUserHistoryReducer } from '../domain/subscription_user/subscriptionUserHistorySlice';
import { subscriptionsReducer } from '../domain/subscriptions/subscriptionsSlice';
import { subscriptionsHistoryReducer } from '../domain/subscriptions/subscriptionsHistorySlice';
import { suiteReducer } from '../domain/suite/suiteSlice';
import { suiteHistoryReducer } from '../domain/suite/suiteHistorySlice';
import { suiteTestReducer } from '../domain/suite_test/suiteTestSlice';
import { testReducer } from '../domain/test/testSlice';
import { testHistoryReducer } from '../domain/test/testHistorySlice';
import { testQualityReducer } from '../domain/test_quality/testQualitySlice';
import { testQualityHistoryReducer } from '../domain/test_quality/testQualityHistorySlice';
import { testQualityTypeReducer } from '../domain/test_quality_type/testQualityTypeSlice';
import { testQualityTypeHistoryReducer } from '../domain/test_quality_type/testQualityTypeHistorySlice';
import { userReducer } from '../domain/user/userSlice';
import { userHistoryReducer } from '../domain/user/userHistorySlice';
import { virtualReducer } from '../domain/virtual/virtualSlice';
import { virtualHistoryReducer } from '../domain/virtual/virtualHistorySlice';
import { watchReducer } from '../domain/watch/watchSlice';
import { watchHistoryReducer } from '../domain/watch/watchHistorySlice';

export const GenReducer = combineReducers({
  accessRole: accessRoleReducer,
  accessRoleHistory: accessRoleHistoryReducer,
  accessRoleUser: accessRoleUserReducer,
  app: appReducer,
  appHistory: appHistoryReducer,
  appConfig: appConfigReducer,
  appConfigHistory: appConfigHistoryReducer,
  appInstall: appInstallReducer,
  appInstallHistory: appInstallHistoryReducer,
  appInstallProject: appInstallProjectReducer,
  appInstallProjectHistory: appInstallProjectHistoryReducer,
  appVersion: appVersionReducer,
  appVersionHistory: appVersionHistoryReducer,
  appVersionPlatVersion: appVersionPlatVersionReducer,
  appVersionPlatVersionHistory: appVersionPlatVersionHistoryReducer,
  appVersionPlatVersionPlan: appVersionPlatVersionPlanReducer,
  attachment: attachmentReducer,
  attachmentHistory: attachmentHistoryReducer,
  baseCapability: baseCapabilityReducer,
  baseCapabilityHistory: baseCapabilityHistoryReducer,
  baseCapabilityBaseIntegration: baseCapabilityBaseIntegrationReducer,
  baseIntegration: baseIntegrationReducer,
  baseIntegrationHistory: baseIntegrationHistoryReducer,
  billingContact: billingContactReducer,
  billingContactHistory: billingContactHistoryReducer,
  capability: capabilityReducer,
  capabilityHistory: capabilityHistoryReducer,
  capabilityIntegration: capabilityIntegrationReducer,
  casePriority: casePriorityReducer,
  casePriorityHistory: casePriorityHistoryReducer,
  caseType: caseTypeReducer,
  caseTypeHistory: caseTypeHistoryReducer,
  checkRun: checkRunReducer,
  checkRunHistory: checkRunHistoryReducer,
  checkSuite: checkSuiteReducer,
  checkSuiteHistory: checkSuiteHistoryReducer,
  client: clientReducer,
  clientHistory: clientHistoryReducer,
  comment: commentReducer,
  commentHistory: commentHistoryReducer,
  coupon: couponReducer,
  couponHistory: couponHistoryReducer,
  dataSet: dataSetReducer,
  dataSetHistory: dataSetHistoryReducer,
  defect: defectReducer,
  defectHistory: defectHistoryReducer,
  defectComponent: defectComponentReducer,
  defectLabel: defectLabelReducer,
  defectPriority: defectPriorityReducer,
  defectRes: defectResReducer,
  defectResHistory: defectResHistoryReducer,
  defectResNativeDefectRes: defectResNativeDefectResReducer,
  defectRunResult: defectRunResultReducer,
  defectStatus: defectStatusReducer,
  defectStatusHistory: defectStatusHistoryReducer,
  defectStatusNativeDefectStatus: defectStatusNativeDefectStatusReducer,
  defectType: defectTypeReducer,
  defectUser: defectUserReducer,
  filter: filterReducer,
  filterHistory: filterHistoryReducer,
  integration: integrationReducer,
  integrationHistory: integrationHistoryReducer,
  integrationProject: integrationProjectReducer,
  integrationTemplate: integrationTemplateReducer,
  integrationTemplateHistory: integrationTemplateHistoryReducer,
  integrationUser: integrationUserReducer,
  invoice: invoiceReducer,
  invoiceHistory: invoiceHistoryReducer,
  label: labelReducer,
  labelHistory: labelHistoryReducer,
  labelAssigned: labelAssignedReducer,
  labelAssignedHistory: labelAssignedHistoryReducer,
  milestone: milestoneReducer,
  milestoneHistory: milestoneHistoryReducer,
  nativeDefectRes: nativeDefectResReducer,
  nativeDefectResHistory: nativeDefectResHistoryReducer,
  nativeDefectStatus: nativeDefectStatusReducer,
  nativeDefectStatusHistory: nativeDefectStatusHistoryReducer,
  notifications: notificationsReducer,
  plan: planReducer,
  planHistory: planHistoryReducer,
  planPullRequest: planPullRequestReducer,
  planPurpose: planPurposeReducer,
  planSuite: planSuiteReducer,
  planSuiteTestInclude: planSuiteTestIncludeReducer,
  planSuiteTestIncludeHistory: planSuiteTestIncludeHistoryReducer,
  plat: platReducer,
  platHistory: platHistoryReducer,
  platVersion: platVersionReducer,
  platVersionHistory: platVersionHistoryReducer,
  policy: policyReducer,
  policyHistory: policyHistoryReducer,
  policyRows: policyRowsReducer,
  policyRowsHistory: policyRowsHistoryReducer,
  product: productReducer,
  productHistory: productHistoryReducer,
  project: projectReducer,
  projectHistory: projectHistoryReducer,
  pullRequest: pullRequestReducer,
  pullRequestHistory: pullRequestHistoryReducer,
  purpose: purposeReducer,
  purposeHistory: purposeHistoryReducer,
  requirement: requirementReducer,
  requirementHistory: requirementHistoryReducer,
  requirementTest: requirementTestReducer,
  run: runReducer,
  runHistory: runHistoryReducer,
  runResult: runResultReducer,
  runResultHistory: runResultHistoryReducer,
  runResultStep: runResultStepReducer,
  runResultStepHistory: runResultStepHistoryReducer,
  runSuite: runSuiteReducer,
  runSuiteHistory: runSuiteHistoryReducer,
  sharedPrecondition: sharedPreconditionReducer,
  sharedPreconditionHistory: sharedPreconditionHistoryReducer,
  sharedStep: sharedStepReducer,
  sharedStepHistory: sharedStepHistoryReducer,
  signupOption: signupOptionReducer,
  signupOptionHistory: signupOptionHistoryReducer,
  status: statusReducer,
  statusHistory: statusHistoryReducer,
  statusType: statusTypeReducer,
  statusTypeHistory: statusTypeHistoryReducer,
  step: stepReducer,
  stepHistory: stepHistoryReducer,
  subscriptionUser: subscriptionUserReducer,
  subscriptionUserHistory: subscriptionUserHistoryReducer,
  subscriptions: subscriptionsReducer,
  subscriptionsHistory: subscriptionsHistoryReducer,
  suite: suiteReducer,
  suiteHistory: suiteHistoryReducer,
  suiteTest: suiteTestReducer,
  test: testReducer,
  testHistory: testHistoryReducer,
  testQuality: testQualityReducer,
  testQualityHistory: testQualityHistoryReducer,
  testQualityType: testQualityTypeReducer,
  testQualityTypeHistory: testQualityTypeHistoryReducer,
  user: userReducer,
  userHistory: userHistoryReducer,
  virtual: virtualReducer,
  virtualHistory: virtualHistoryReducer,
  watch: watchReducer,
  watchHistory: watchHistoryReducer,
});
