import React, { useEffect, useState } from 'react';
import { useProgressBar } from 'react-aria';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import styles from './Loading.module.scss';
import classNames from 'classnames';

type Props = {
  className?: string;
  center?: boolean;
  color?: string;
  delay?: number;
  size?: number;
};

export default function Loading({
  className = '',
  center = true,
  color = vars.loading,
  delay = 250,
  size = 24,
}: Props) {
  const [isShowing, setIsShowing] = useState(!(delay > 0));

  useEffect(() => {
    const timeout = setTimeout(() => setIsShowing(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  const { progressBarProps } = useProgressBar({ isIndeterminate: true });

  if (!isShowing) return null;

  const loadingCN = classNames(
    styles.loading,
    { [styles.center]: center },
    className,
  );

  // loading from https://uiball.com
  return (
    <div className={loadingCN}>
      <svg
        className={styles.ring}
        style={{ height: size, width: size }}
        viewBox="25 25 50 50"
        strokeWidth="4"
        {...progressBarProps}>
        <circle style={{ stroke: color }} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
}
