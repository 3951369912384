import { AxiosResponse, AxiosRequestConfig } from 'axios';
import Debug from 'debug';
import { v4 as uuidV4 } from 'uuid';
import {
  AuthCallbackActions,
  BatchRequest,
  BatchResponses,
  APIWorkerInterface,
  ReturnToken,
  AuthCallback,
} from '@testquality/sdk';

const debug = Debug('bitmodern:workers:api');

enum APIWorkerState {
  Updating = 'updating',
  Active = 'active',
}

export enum APIWorkerRequests {
  SetClient = 'API_WORKER_SET_CLIENT',
  SetToken = 'API_WORKER_SET_TOKEN',
  PostBatch = 'API_WORKER_POST_BATCH',
  Request = 'API_WORKER_REQUEST',
}

const queueActions = [APIWorkerRequests.PostBatch, APIWorkerRequests.Request];

export enum APIWorkerThreadRequests {
  AuthCallback = 'API_WORKER_THREAD_AUTH_CALLBACK',
}

export interface APIWorkerSdkOptions {
  baseUrl?: string;
  clientId: string;
  clientSecret: string;
  debug?: boolean;
}

export interface APIWorkerEventData {
  id: string;
  action: string;
  authCallbackArgs?: { action: AuthCallbackActions; token?: ReturnToken };
  payload: any;
  response?: string;
  error?: string;
  messageError?: string;
}

function areTokensEqual(
  a: ReturnToken | undefined,
  b: ReturnToken | undefined,
): boolean {
  let res: boolean;
  if (!a && !b) {
    res = true;
  } else if (!a || !b) {
    res = false;
  } else if (a.access_token !== b.access_token) {
    res = false;
  } else if (a.expires_in !== b.expires_in) {
    res = false;
  } else {
    res = true;
  }
  debug('areTokensEqual', { res, a, b });
  return res;
}

function createDebug(data: APIWorkerEventData) {
  return data.id ? debug.extend(data.id.split('-')[0]) : debug;
}

export class APIWorker implements APIWorkerInterface {
  private readonly worker: Worker;
  private readonly pendingActions: Record<string, any> = {};
  private readonly messageQueue: any[] = [];
  private state = APIWorkerState.Updating;

  constructor(
    clientOptions: APIWorkerSdkOptions,
    private token?: ReturnToken,
    private authCallback?: AuthCallback,
  ) {
    if (!Worker) {
      throw new Error(
        'This class is only available for browsers with web worker API support',
      );
    }
    debug('constructor', {
      clientOptions,
      token,
      authCallback: !!authCallback,
    });
    this.worker = new Worker(new URL('APIWorkerThread.ts', import.meta.url));
    this.worker.onmessage = this.messageHandler.bind(this);
    this.worker.onmessageerror = this.messageErrorHandler;
    this.worker.onerror = this.errorHandler;
    this.setClient(clientOptions, token);
  }

  private async setClient(options: APIWorkerSdkOptions, token?: ReturnToken) {
    debug('setClient');
    await this.postMessage(APIWorkerRequests.SetClient, {
      options,
      token,
      debug: debug.enabled,
    });
    this.stateUpdateHandler(APIWorkerState.Active);
  }

  private postMessage<T>(action: APIWorkerRequests, payload: any) {
    const id = uuidV4();
    debug('postMessage', { id, action, state: this.state, payload });
    return new Promise<T>((resolve, reject) => {
      this.pendingActions[id] = {
        id,
        action,
        payload,
        resolve,
        reject,
      };
      const message = { id, action, payload };
      if (
        this.state !== APIWorkerState.Active &&
        queueActions.includes(action)
      ) {
        debug('queueing %s %s', action, id);
        this.messageQueue.push(message);
      } else {
        debug('sending %s %s', action, id);
        this.worker.postMessage(message);
      }
    });
  }

  private stateUpdateHandler(newState: APIWorkerState) {
    debug('stateUpdateHandler', { currentState: this.state, newState });
    if (this.state === newState) {
      return;
    }
    this.state = newState;
    if (newState === APIWorkerState.Active) {
      this.processMessageQueue();
    }
  }

  private processMessageQueue() {
    debug('processMessageQueue: %d', this.messageQueue.length);
    while (this.messageQueue.length) {
      // avoid potential race condition errors
      const message = this.messageQueue.shift();
      if (message) {
        debug('sending queued %s %s', message.action, message.id);
        this.worker.postMessage(message);
      } else {
        break;
      }
    }
  }

  private async messageHandler(e: MessageEvent<APIWorkerEventData>) {
    const debugMsg = createDebug(e.data);
    debugMsg('messageHandler', e.data);

    const { id, response, error, messageError } = e.data || {};
    if (messageError) {
      debugMsg('WARN: unable to process message', e.data.payload);
    }

    if (id === APIWorkerThreadRequests.AuthCallback) {
      const { action, token } = e.data.authCallbackArgs || {};
      if (areTokensEqual(this.token, token)) {
        return;
      }
      if (this.authCallback && action) {
        await this.authCallback(action, token);
      }
      this.token = token;
      return;
    }

    if (!id || (!response && !error)) {
      debugMsg('WARN: bad message format');
      return;
    }

    const { resolve, reject } = this.pendingActions[id] || {};
    if (!resolve || !reject) {
      debugMsg(`WARN: unable to find pending action ${id}`);
      return;
    }

    if (error) {
      const err = JSON.parse(error);
      debugMsg('WARN: error from worker', err);
      reject(err);
    } else {
      const res = JSON.parse(response ?? '{"ok":1}');
      debugMsg('response from worker', res);
      resolve(res);
    }
    delete this.pendingActions[id];
  }

  private messageErrorHandler(e: MessageEvent) {
    debug('messageErrorHandler:', e);
  }

  private errorHandler(e: ErrorEvent) {
    debug('errorHandler:', e);
  }

  // Public interface

  public async setAuthCallback(authCallback: AuthCallback) {
    this.authCallback = authCallback;
  }

  public async setToken(token?: ReturnToken) {
    debug('setToken');
    if (areTokensEqual(this.token, token)) {
      return;
    }
    this.state = APIWorkerState.Updating;
    this.token = token;
    await this.postMessage(APIWorkerRequests.SetToken, token);
    this.stateUpdateHandler(APIWorkerState.Active);
  }

  public postBatch(
    requests: BatchRequest[],
  ): Promise<AxiosResponse<BatchResponses>> {
    debug('postBatch');
    return this.postMessage(APIWorkerRequests.PostBatch, requests);
  }

  public request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig) {
    debug('request');
    return this.postMessage<R>(APIWorkerRequests.Request, config);
  }
}
