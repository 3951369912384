/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { WatchHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { watchHistoryAdapter } from './watchHistoryAdapter';
import { watchHistoryFetchThunk } from './watchHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const watchHistorySlice = createSlice({
  name: 'watchHistory',
  initialState: watchHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    watchHistoryCleared(state) {
      state.loading = 'idle';
      watchHistoryAdapter.removeAll(state);
    },
    watchHistoryUpsertMany(
      state,
      action: PayloadAction<WatchHistory[] | Record<number, WatchHistory>>,
    ) {
      watchHistoryAdapter.upsertMany(state, action.payload);
    },
    watchHistoryUpsertOne(state, action: PayloadAction<WatchHistory>) {
      watchHistoryAdapter.upsertOne(state, action.payload);
    },
    watchHistoryUpdateOne(state, action: PayloadAction<Update<WatchHistory>>) {
      watchHistoryAdapter.updateOne(state, action.payload);
    },
    watchHistoryRemoveOne(state, action: PayloadAction<number>) {
      watchHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(watchHistoryFetchThunk.pending, handlePending);
    builder.addCase(watchHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      watchHistoryAdapter.upsertMany(state, action.payload.watchHistory);
    });
    builder.addCase(watchHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  watchHistoryCleared,
  watchHistoryUpsertMany,
  watchHistoryUpsertOne,
  watchHistoryUpdateOne,
  watchHistoryRemoveOne,
} = watchHistorySlice.actions;

export const watchHistoryReducer = watchHistorySlice.reducer;
