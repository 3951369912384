import { BaseIntegration, Integration } from '@testquality/sdk';

export enum IntegrationType {
  JIRA = 'JIRA',
  GITHUB = 'github',
  ISSUE_QUALITY = 'issueQuality',
  UNKNOWN = 'Unknown Integration',
}

export enum JiraInstanceType {
  Cloud = 'JIRA Cloud',
  Server = 'JIRA Server',
}

export const integrationKey = (
  integration: BaseIntegration | Integration,
): IntegrationType => {
  if (!integration?.name) return IntegrationType.UNKNOWN;

  if (
    integration.name === IntegrationType.JIRA ||
    integration.name === JiraInstanceType.Cloud ||
    integration.name === JiraInstanceType.Server
  ) {
    return IntegrationType.JIRA;
  }

  if (
    integration.name === 'Github' ||
    integration.name === 'GitHub Enterprise Server'
  ) {
    return IntegrationType.GITHUB;
  }

  if (integration.name === 'IssueQuality') {
    return IntegrationType.ISSUE_QUALITY;
  }

  return IntegrationType.UNKNOWN;
};
