/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { UserApi } from '@testquality/sdk';
import { AccessRoleUser } from '@testquality/sdk';
import { IntegrationUser } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { subscriptionsNormalizer } from '../subscriptions/subscriptionsNormalize';
import { subscriptionUserNormalizer } from '../subscription_user/subscriptionUserNormalize';
import { accessRoleNormalizer } from '../access_role/accessRoleNormalize';
import { accessRoleUserNormalizer } from '../access_role_user/accessRoleUserNormalize';
import { billingContactNormalizer } from '../billing_contact/billingContactNormalize';
import { integrationNormalizer } from '../integration/integrationNormalize';
import { integrationUserNormalizer } from '../integration_user/integrationUserNormalize';

export const userNormalizer = (model: UserApi, cache: NormalizerCache) => {
  if (!cache.user) {
    cache.user = [];
  }
  cache.user.push(model);

  if (model.subscriptions && model.subscriptions.length) {
    model.subscriptions.forEach((child) => {
      subscriptionsNormalizer(child, cache);
    });
    model.subscriptions = undefined;
  }
  if (model.subscription_user && model.subscription_user.length) {
    model.subscription_user.forEach((child) => {
      subscriptionUserNormalizer(child, cache);
    });
    model.subscription_user = undefined;
  }
  if (model.access_role && model.access_role.length) {
    model.access_role.forEach((child) => {
      accessRoleUserNormalizer(child.pivot as AccessRoleUser, cache);
      child.pivot = undefined;
      accessRoleNormalizer(child, cache);
    });
    model.access_role = undefined;
  }
  if (model.billing_contact && model.billing_contact.length) {
    model.billing_contact.forEach((child) => {
      billingContactNormalizer(child, cache);
    });
    model.billing_contact = undefined;
  }
  if (model.integration && model.integration.length) {
    model.integration.forEach((child) => {
      integrationUserNormalizer(child.pivot as IntegrationUser, cache);
      child.pivot = undefined;
      integrationNormalizer(child, cache);
    });
    model.integration = undefined;
  }
};
