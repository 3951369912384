import { SubscriptionIntervalEnum } from 'src/enums/SubscriptionIntervalEnum';
import { productSelectors } from 'src/gen/domain/product/productSelector';
import { State } from '@bitmodern/redux/store';

export function productsSelector(state: State) {
  return productSelectors.selectAll(state);
}

export function productSelector(state: State, productId: number) {
  return productSelectors.selectById(state, productId);
}

export function githubProductSelector(state: State) {
  return productsSelector(state).find(
    (product) => product.name === 'GitHub Open Source Project',
  );
}

export function productByBilledSelector(
  state: State,
  billed: SubscriptionIntervalEnum,
) {
  return productsSelector(state).find((product) => {
    if (
      billed === SubscriptionIntervalEnum.Monthly &&
      product.interval === 'month' &&
      product.amount > 0 &&
      product.is_active
    ) {
      return true;
    }
    if (
      billed === SubscriptionIntervalEnum.Yearly &&
      product.interval === 'year' &&
      product.amount > 0 &&
      product.is_active
    ) {
      return true;
    }
    return false;
  });
}
