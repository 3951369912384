import React from 'react';
import { Resizable } from 're-resizable';
import {
  Loading,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@bitmodern/bit-ui';
import { Defect, RunResult } from '@testquality/sdk';

import { useTranslation } from 'src/i18n/hooks';
import DefectItem from '../DefectItem';
import RunResultHistory from '../RunResultHistory';
import styles from './RunResultsAndDefects.module.scss';

type Props = {
  defects: Defect[];
  loading: boolean;
  runResults: RunResult[];
};

export default function RunResultsAndDefects({
  defects,
  loading,
  runResults,
}: Props) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={styles.container}>
        <Loading className={styles.loading} size={48} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Resizable
        bounds="parent"
        style={{ flexBasis: '60%' }}
        enable={{ bottom: true }}
        maxHeight="70%"
        minHeight="30%">
        <div className={styles.wrap}>
          <RunResultHistory runResults={runResults} />
        </div>
      </Resizable>
      <div className={styles.wrap}>
        <div className={styles.absoluteWrap}>
          <Table height="100%">
            <TableHead className={styles.head}>
              <TableCell>{t('defects.head.key')}</TableCell>
              <TableCell>{t('defects.head.summary')}</TableCell>
              <TableCell>{t('defects.head.created')}</TableCell>
              <TableCell>{t('defects.head.assigned')}</TableCell>
              <TableCell>{t('defects.head.status')}</TableCell>
            </TableHead>
            <TableBody>
              {defects.map((defect) => (
                <DefectItem defect={defect} key={defect.id} />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
