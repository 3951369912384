import { nativeDefectStatusSelectors } from 'src/gen/domain/native_defect_status/nativeDefectStatusSelector';
import { State } from '@bitmodern/redux/store';

export function nativeDefectStatusesSelector(state: State) {
  return nativeDefectStatusSelectors.selectAll(state);
}

export function nativeDefectStatusesByIntegrationProject(
  state: State,
  integrationProjectId: number,
) {
  return nativeDefectStatusesSelector(state).filter(
    (ds) => ds.integration_project_id === integrationProjectId,
  );
}
