/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectHistoryAdapter } from './defectHistoryAdapter';

export const defectHistorySelectors = defectHistoryAdapter.getSelectors(
  (state: State) => state.gen.defectHistory,
);
