import React, { ComponentProps } from 'react';
import { FormikProps } from 'formik';
import { BaseIntegration, CapabilityIntegration } from '@testquality/sdk';
import {
  Dialog,
  Button,
  Select,
  EasyMDEEditor,
  DialogContent,
  notification,
  PanelActions,
  Spacer,
} from '@bitmodern/bit-ui';
import { AddVariableIcon } from '@bitmodern/bit-ui/icons';
import { Trans } from 'react-i18next';
import { IntegrationType, integrationKey } from 'src/enums/IntegrationEnums';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import styles from './IntegrationTemplatesDialog.module.scss';

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen'> & {
  baseIntegration: BaseIntegration;
  capabilityIntegrations?: Array<CapabilityIntegration & { name: string }>;
  selectedCapabilityName: CapabilityEnum;
  defaultDescriptionTemplate: string;
  onClose: () => void;
  formik: FormikProps<{
    template: string;
    capabilityIntegrationId: number;
  }>;
};

export default function IntegrationTemplatesDialog({
  baseIntegration,
  capabilityIntegrations = [],
  selectedCapabilityName,
  defaultDescriptionTemplate,
  isOpen,
  onClose,
  formik,
}: Props) {
  const { t } = useTranslation();
  const baseIntegrationType = integrationKey(baseIntegration);
  const onChangeCapabilityIntegrationSelect = (value: string) => {
    formik.setFieldValue('capabilityIntegrationId', value);
  };
  const onChangeTemplate = (value: string) => {
    formik.setFieldValue('template', value);
  };
  const onBackToDefaultTemplateClick = () => {
    formik.setFieldValue('template', defaultDescriptionTemplate);
  };

  if (!baseIntegration) {
    notification.open({
      type: 'error',
      message: 'Internal Error',
      description: 'Integration does not exist.',
      duration: 30,
    });
    return <></>;
  }
  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('integrationTemplatesDialog.title')}
      size="large">
      <DialogContent className={styles.content}>
        <Trans i18nKey="integrationTemplatesDialog.description">
          Use{' '}
          <a
            className={styles.link}
            href="https://handlebarsjs.com/guide/"
            rel="noopener noreferrer"
            target="_blank">
            handlebars/mustache
          </a>{' '}
          syntax to build templates to use with this integration. Add variables
          <AddVariableIcon color={vars.textPrimary} size={16} /> to access
          issue&apos;s data.
        </Trans>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Select
            className={styles.typeSelect}
            label={t('integrationTemplatesDialog.selectCapabilitiesLabel')}
            name="capabilityIntegrationId"
            options={capabilityIntegrations.map(({ name, id }) => ({
              value: id,
              label: name,
            }))}
            onChange={onChangeCapabilityIntegrationSelect}
            onFocus={formik.handleBlur}
            value={formik.values.capabilityIntegrationId}
          />
          <EasyMDEEditor
            onChange={onChangeTemplate}
            parentId={baseIntegration.id}
            parentType="Integration"
            value={formik.values.template}
            withCapabilityTemplateEditingFor={selectedCapabilityName}
            withFormatting={baseIntegrationType !== IntegrationType.JIRA}
          />
          <PanelActions>
            <Spacer>
              <Button
                type="button"
                onClick={onBackToDefaultTemplateClick}
                color="primaryLight"
                disabled={
                  formik.values.template === defaultDescriptionTemplate
                }>
                {t('integrationTemplatesDialog.backToDefault')}
              </Button>
              <Button
                loading={formik.isSubmitting}
                type="submit"
                disabled={
                  formik.initialValues.template === formik.values.template
                }>
                {t('integrationTemplatesDialog.save')}
              </Button>
            </Spacer>
          </PanelActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
