import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path d="M24 0v24H0V0h24z" fill="none" opacity=".87" />
    <path d="M11.71 15.29l2.59-2.59c.39-.39.39-1.02 0-1.41L11.71 8.7c-.63-.62-1.71-.18-1.71.71v5.17c0 .9 1.08 1.34 1.71.71z" />
  </>,
  'ArrowRight',
);
