/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { App } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appAdapter } from './appAdapter';
import {
  appFetchManyThunk,
  appFetchOneThunk,
  appDeleteOneThunk,
  appUpdateOneThunk,
  appCreateOneThunk,
} from './appThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appSlice = createSlice({
  name: 'app',
  initialState: appAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appCleared(state) {
      state.loading = 'idle';
      appAdapter.removeAll(state);
    },
    appUpsertMany(state, action: PayloadAction<App[] | Record<number, App>>) {
      appAdapter.upsertMany(state, action.payload);
    },
    appUpsertOne(state, action: PayloadAction<App>) {
      appAdapter.upsertOne(state, action.payload);
    },
    appUpdateOne(state, action: PayloadAction<Update<App>>) {
      appAdapter.updateOne(state, action.payload);
    },
    appRemoveOne(state, action: PayloadAction<number>) {
      appAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appFetchManyThunk.pending, handlePending);
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appAdapter.upsertMany(state, action.payload.app);
    });
    builder.addCase(appFetchManyThunk.rejected, handleRejected);
    builder.addCase(appFetchOneThunk.pending, handlePending);
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appAdapter.upsertOne(state, action.payload.app[0]);
    });
    builder.addCase(appFetchOneThunk.rejected, handleRejected);
    builder.addCase(appDeleteOneThunk.pending, handlePending);
    builder.addCase(appDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        appAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(appDeleteOneThunk.rejected, handleRejected);
    builder.addCase(appUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, appAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(appUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appAdapter.updateOne(state, action.payload);
    });
    builder.addCase(appUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          appAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(appCreateOneThunk.pending, handlePending);
    builder.addCase(appCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(appCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  appCleared,
  appUpsertMany,
  appUpsertOne,
  appUpdateOne,
  appRemoveOne,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
