import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path
      fill="currentColor"
      d="M13,19V16H21V19H13M8.5,13L2.47,7H6.71L11.67,11.95C12.25,12.54 12.25,13.5 11.67,14.07L6.74,19H2.5L8.5,13Z"
    />
  </>,
  'Command',
);
