/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { StatusType } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { statusTypeAdapter } from './statusTypeAdapter';
import {
  statusTypeFetchManyThunk,
  statusTypeFetchOneThunk,
  statusTypeDeleteOneThunk,
  statusTypeUpdateOneThunk,
  statusTypeCreateOneThunk,
} from './statusTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const statusTypeSlice = createSlice({
  name: 'statusType',
  initialState: statusTypeAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    statusTypeCleared(state) {
      state.loading = 'idle';
      statusTypeAdapter.removeAll(state);
    },
    statusTypeUpsertMany(
      state,
      action: PayloadAction<StatusType[] | Record<number, StatusType>>,
    ) {
      statusTypeAdapter.upsertMany(state, action.payload);
    },
    statusTypeUpsertOne(state, action: PayloadAction<StatusType>) {
      statusTypeAdapter.upsertOne(state, action.payload);
    },
    statusTypeUpdateOne(state, action: PayloadAction<Update<StatusType>>) {
      statusTypeAdapter.updateOne(state, action.payload);
    },
    statusTypeRemoveOne(state, action: PayloadAction<number>) {
      statusTypeAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(statusTypeFetchManyThunk.pending, handlePending);
    builder.addCase(statusTypeFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusTypeAdapter.upsertMany(state, action.payload.statusType);
    });
    builder.addCase(statusTypeFetchManyThunk.rejected, handleRejected);
    builder.addCase(statusTypeFetchOneThunk.pending, handlePending);
    builder.addCase(statusTypeFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusTypeAdapter.upsertOne(state, action.payload.statusType[0]);
    });
    builder.addCase(statusTypeFetchOneThunk.rejected, handleRejected);
    builder.addCase(statusTypeDeleteOneThunk.pending, handlePending);
    builder.addCase(statusTypeDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        statusTypeAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(statusTypeDeleteOneThunk.rejected, handleRejected);
    builder.addCase(statusTypeUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, statusTypeAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(statusTypeUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusTypeAdapter.updateOne(state, action.payload);
    });
    builder.addCase(statusTypeUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          statusTypeAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(statusTypeCreateOneThunk.pending, handlePending);
    builder.addCase(statusTypeCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusTypeAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(statusTypeCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  statusTypeCleared,
  statusTypeUpsertMany,
  statusTypeUpsertOne,
  statusTypeUpdateOne,
  statusTypeRemoveOne,
} = statusTypeSlice.actions;

export const statusTypeReducer = statusTypeSlice.reducer;
