/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { PlanSuiteTestInclude } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { planSuiteTestIncludeAdapter } from './planSuiteTestIncludeAdapter';
import {
  planSuiteTestIncludeFetchManyThunk,
  planSuiteTestIncludeFetchOneThunk,
  planSuiteTestIncludeDeleteOneThunk,
  planSuiteTestIncludeUpdateOneThunk,
  planSuiteTestIncludeCreateOneThunk,
} from './planSuiteTestIncludeThunk';
import { suiteFetchManyThunk, suiteFetchOneThunk } from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import { planFetchManyThunk, planFetchOneThunk } from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const planSuiteTestIncludeSlice = createSlice({
  name: 'planSuiteTestInclude',
  initialState: planSuiteTestIncludeAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    planSuiteTestIncludeCleared(state) {
      state.loading = 'idle';
      planSuiteTestIncludeAdapter.removeAll(state);
    },
    planSuiteTestIncludeUpsertMany(
      state,
      action: PayloadAction<
        PlanSuiteTestInclude[] | Record<number, PlanSuiteTestInclude>
      >,
    ) {
      planSuiteTestIncludeAdapter.upsertMany(state, action.payload);
    },
    planSuiteTestIncludeUpsertOne(
      state,
      action: PayloadAction<PlanSuiteTestInclude>,
    ) {
      planSuiteTestIncludeAdapter.upsertOne(state, action.payload);
    },
    planSuiteTestIncludeUpdateOne(
      state,
      action: PayloadAction<Update<PlanSuiteTestInclude>>,
    ) {
      planSuiteTestIncludeAdapter.updateOne(state, action.payload);
    },
    planSuiteTestIncludeRemoveOne(state, action: PayloadAction<number>) {
      planSuiteTestIncludeAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(planSuiteTestIncludeFetchManyThunk.pending, handlePending);
    builder.addCase(
      planSuiteTestIncludeFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      },
    );
    builder.addCase(
      planSuiteTestIncludeFetchManyThunk.rejected,
      handleRejected,
    );
    builder.addCase(planSuiteTestIncludeFetchOneThunk.pending, handlePending);
    builder.addCase(
      planSuiteTestIncludeFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        planSuiteTestIncludeAdapter.upsertOne(
          state,
          action.payload.planSuiteTestInclude[0],
        );
      },
    );
    builder.addCase(planSuiteTestIncludeFetchOneThunk.rejected, handleRejected);
    builder.addCase(planSuiteTestIncludeDeleteOneThunk.pending, handlePending);
    builder.addCase(
      planSuiteTestIncludeDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          planSuiteTestIncludeAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(
      planSuiteTestIncludeDeleteOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      planSuiteTestIncludeUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, planSuiteTestIncludeAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      planSuiteTestIncludeUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        planSuiteTestIncludeAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      planSuiteTestIncludeUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            planSuiteTestIncludeAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(planSuiteTestIncludeCreateOneThunk.pending, handlePending);
    builder.addCase(
      planSuiteTestIncludeCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        planSuiteTestIncludeAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(
      planSuiteTestIncludeCreateOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuiteTestInclude) {
          planSuiteTestIncludeAdapter.upsertMany(
            state,
            action.payload.planSuiteTestInclude,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuiteTestInclude) {
        planSuiteTestIncludeAdapter.upsertMany(
          state,
          action.payload.planSuiteTestInclude,
        );
      }
    });
  },
});

export const {
  planSuiteTestIncludeCleared,
  planSuiteTestIncludeUpsertMany,
  planSuiteTestIncludeUpsertOne,
  planSuiteTestIncludeUpdateOne,
  planSuiteTestIncludeRemoveOne,
} = planSuiteTestIncludeSlice.actions;

export const planSuiteTestIncludeReducer = planSuiteTestIncludeSlice.reducer;
