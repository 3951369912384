/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { testQualityAdapter } from './testQualityAdapter';

export const testQualitySelectors = testQualityAdapter.getSelectors(
  (state: State) => state.gen.testQuality,
);
