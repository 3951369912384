/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AppHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appHistoryAdapter } from './appHistoryAdapter';
import { appHistoryFetchThunk } from './appHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appHistorySlice = createSlice({
  name: 'appHistory',
  initialState: appHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appHistoryCleared(state) {
      state.loading = 'idle';
      appHistoryAdapter.removeAll(state);
    },
    appHistoryUpsertMany(
      state,
      action: PayloadAction<AppHistory[] | Record<number, AppHistory>>,
    ) {
      appHistoryAdapter.upsertMany(state, action.payload);
    },
    appHistoryUpsertOne(state, action: PayloadAction<AppHistory>) {
      appHistoryAdapter.upsertOne(state, action.payload);
    },
    appHistoryUpdateOne(state, action: PayloadAction<Update<AppHistory>>) {
      appHistoryAdapter.updateOne(state, action.payload);
    },
    appHistoryRemoveOne(state, action: PayloadAction<number>) {
      appHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appHistoryFetchThunk.pending, handlePending);
    builder.addCase(appHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appHistoryAdapter.upsertMany(state, action.payload.appHistory);
    });
    builder.addCase(appHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  appHistoryCleared,
  appHistoryUpsertMany,
  appHistoryUpsertOne,
  appHistoryUpdateOne,
  appHistoryRemoveOne,
} = appHistorySlice.actions;

export const appHistoryReducer = appHistorySlice.reducer;
