import React, { ReactElement, ReactNode } from 'react';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import './Tooltip.module.scss';

type Props = {
  children: ReactElement | string;
  enterDelay?: number;
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
  tooltip: ReactNode;
  disabled?: boolean;
};

export default function Tooltip({
  children,
  placement = 'top',
  enterDelay = 1000,
  tooltip,
  disabled = false,
}: Props) {
  const element =
    typeof children === 'string' ? <div>{children}</div> : children;

  if (disabled) return element;

  return (
    <RcTooltip
      overlay={tooltip}
      mouseEnterDelay={enterDelay / 1000}
      placement={placement}>
      {element}
    </RcTooltip>
  );
}
