/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PurposeApi } from '@testquality/sdk';
import { PlanPurpose } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { planNormalizer } from '../plan/planNormalize';
import { planPurposeNormalizer } from '../plan_purpose/planPurposeNormalize';

export const purposeNormalizer = (
  model: PurposeApi,
  cache: NormalizerCache,
) => {
  if (!cache.purpose) {
    cache.purpose = [];
  }
  cache.purpose.push(model);

  if (model.plan && model.plan.length) {
    model.plan.forEach((child) => {
      planPurposeNormalizer(child.pivot as PlanPurpose, cache);
      child.pivot = undefined;
      planNormalizer(child, cache);
    });
    model.plan = undefined;
  }
};
