import {
  FocusScope,
  mergeProps,
  OverlayContainer,
  useDialog,
  useKeyboard,
  useModal,
  useOverlay,
  usePreventScroll,
} from 'react-aria';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useStateProp from 'src/hooks/useStateProp';
import { Loading } from '@bitmodern/bit-ui';
import MenuItem from './MenuItem';
import styles from './CommandBar.module.scss';

type Command = {
  checked?: boolean;
  command: string;
  key: any;
  handler: (command: Command) => void;
  onChangeChecked?: (checked: boolean) => void;
  icon?: ReactElement;
  label: ReactNode;
  extraActions?: ReactElement;
  sublabel?: ReactNode;
};

type Props = {
  commands: Command[];
  id: string;
  onChangeSearch?: (search: string) => void;
  onClose: () => void;
  open?: boolean;
  placeholder?: string;
  search?: string;
  extraActions?: ReactElement;
  isLoading?: boolean;
};

export default function CommandBar({
  commands,
  id,
  onChangeSearch,
  onClose,
  open = false,
  placeholder,
  extraActions,
  search: searchProp = '',
  isLoading = false,
}: Props) {
  const [current, setCurrent] = useState(0);
  const [search, setSearch] = useStateProp(searchProp, onChangeSearch);
  const modalRef = React.useRef(null);
  usePreventScroll({ isDisabled: !open });
  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen: open,
      isDismissable: true,
    },
    modalRef,
  );

  const filteredCommands = commands.filter((command) =>
    command.command.toLowerCase().includes(search.toLocaleLowerCase()),
  );

  const currentCommand = filteredCommands[current];

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setCurrent((s) =>
          current === 0 ? filteredCommands.length - 1 : s - 1,
        );
        break;
      case 'ArrowDown':
        event.preventDefault();
        setCurrent((s) =>
          current === filteredCommands.length - 1 ? 0 : s + 1,
        );
        break;

      case 'Enter':
        if (!currentCommand) return;
        if (current !== -1) {
          currentCommand.handler(currentCommand);
        }
        break;
      case ' ': {
        if (!currentCommand || !search || current === -1) return;
        const { onChangeChecked, checked } = currentCommand;
        if (onChangeChecked) {
          event.preventDefault();
          onChangeChecked(typeof checked === 'undefined' ? true : !checked);
        }
        break;
      }

      default:
        break;
    }
  };
  const { keyboardProps } = useKeyboard({ onKeyDown });
  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, modalRef);

  useEffect(() => {
    if (!open) {
      setSearch('');
      setCurrent(0);
    }
  }, [open, setSearch]);

  useEffect(() => {
    setSearch('');
    setCurrent(0);
  }, [id, setSearch]);

  const handleChangeSearch = (event) => {
    setCurrent(0);
    setSearch(event.target.value);
  };

  const onHoverCommand = () => {
    // because of focus can cause list to jump on hover
    // setCurrent(
    //   filteredCommands.findIndex((i) => i.command === command.command),
    // );
  };

  const onClickCommand = (command) => {
    setCurrent(
      filteredCommands.findIndex((i) => i.command === command.command),
    );
    command.handler(command);
  };

  return (
    <AnimatePresence>
      {open && (
        <OverlayContainer>
          <div className={styles.wrapper}>
            <FocusScope contain restoreFocus autoFocus>
              <motion.div
                animate="visible"
                initial="initial"
                exit="hidden"
                variants={{
                  initial: {
                    opacity: 0.8,
                    scale: 0.8,
                    y: 40,
                  },
                  visible: {
                    opacity: 1,
                    scale: 1,
                    y: 0,
                  },
                  hidden: {
                    opacity: 0,
                    scale: 1,
                    y: 0,
                  },
                }}
                className={styles.commandBar}
                {...(mergeProps(
                  overlayProps,
                  dialogProps,
                  modalProps,
                  keyboardProps,
                ) as any)}
                ref={modalRef}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <input
                      className={styles.search}
                      onChange={handleChangeSearch}
                      placeholder={placeholder}
                      value={search}
                    />
                    <ul className={styles.list} role="listbox">
                      {filteredCommands.map((command, i) => (
                        <MenuItem
                          checked={command.checked}
                          extraActions={command.extraActions}
                          key={command.command}
                          icon={command.icon}
                          onClick={() => onClickCommand(command)}
                          onHover={() => onHoverCommand()}
                          onChangeChecked={command.onChangeChecked}
                          selected={current === i}>
                          <div>
                            <div>{command.label}</div>
                            <div>
                              {command.sublabel ? (
                                <span className={styles.sublabel}>
                                  {command.sublabel}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </ul>
                    {extraActions}
                  </>
                )}
              </motion.div>
            </FocusScope>
          </div>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
}
