import { createSelector } from '@reduxjs/toolkit';

import { State } from '@bitmodern/redux/store';
import { policyRowsSelectors } from 'src/gen/domain/policy_rows/policyRowsSelector';
import { PolicyRows } from '@testquality/sdk';

export function policyRowsSelector(state: State) {
  return policyRowsSelectors.selectAll(state);
}

export const createPolicyRowsByPoliciesSelector = (policyIds: number[]) =>
  createSelector(policyRowsSelector, (policyRows) =>
    policyRows.filter((policyRow) => {
      return policyIds.includes(policyRow.policy_id);
    }),
  );

export function policyRowsByPolilicyIds(
  state: State,
  { policiesIds }: { policiesIds: number[] },
): PolicyRows[] {
  return policyRowsSelector(state).filter((pr) =>
    policiesIds.includes(pr.policy_id),
  );
}
