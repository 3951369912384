import React from 'react';
import logoDark from './tq-logo-dark.png';
import logoLight from './tq-logo-light.png';

const images = {
  dark: logoDark,
  light: logoLight,
};

type Props = {
  className?: string;
  size?: number;
  theme?: 'dark' | 'light';
};

export default function TestQualityLogoIcon({
  className,
  size,
  theme = 'dark',
}: Props) {
  return (
    <img className={className} style={{ width: size }} src={images[theme]} />
  );
}
