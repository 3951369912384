import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import {
  mergeProps,
  VisuallyHidden,
  AriaRadioProps,
  useRadio,
} from 'react-aria';
import { useFocusAccent } from '@bitmodern/bit-ui';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { RadioContext } from '../RadioGroup/RadioGroup';
import { DoneIcon } from '../icons';
import styles from './RadioIconText.module.scss';

interface Props extends AriaRadioProps {
  children: ReactNode;
  value: any;
  icon?: ReactNode;
}

export default function RadioIconText({ children, icon, ...props }: Props) {
  const ref = useRef(null);
  const state = React.useContext(RadioContext);
  const { inputProps } = useRadio(props, state as any, ref);
  const { borderClassName, borderProps } = useFocusAccent({});

  const { value } = props;
  const isSelected = state.selectedValue === value;

  const labelCN = classnames(styles.label, borderClassName, {
    [styles.labelSelected]: isSelected,
  });

  const radioCN = classnames(styles.icon, {
    [styles.selected]: isSelected,
  });

  return (
    <label className={labelCN} htmlFor={inputProps.id} {...borderProps}>
      <VisuallyHidden>
        <input {...mergeProps(inputProps, borderProps)} ref={ref} />
      </VisuallyHidden>

      <div className={radioCN}>
        {isSelected && <DoneIcon color={vars.onSuccess} size={16} />}
      </div>

      {children}
    </label>
  );
}
