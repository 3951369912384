/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  CapabilityIntegration,
  capabilityIntegrationDetach,
  capabilityIntegrationUpdateOne,
  capabilityIntegrationCreateOne,
  capabilityIntegrationGetMany,
  capabilityIntegrationGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const capabilityIntegrationDetachThunk = createAsyncThunk(
  'capabilityIntegration/DetachThunk',
  async (arg: QueryParams<CapabilityIntegration>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'capabilityIntegration/DetachThunk',
      );
    }
    return capabilityIntegrationDetach(arg.data, arg).then(() => arg.data);
  },
);

export const capabilityIntegrationUpdateOneThunk = createAsyncThunk(
  'capabilityIntegration/UpdateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CapabilityIntegration>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'capabilityIntegration/UpdateOneThunk',
        );
      }
      const data = await capabilityIntegrationUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<CapabilityIntegration>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const capabilityIntegrationCreateOneThunk = createAsyncThunk(
  'capabilityIntegration/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<CapabilityIntegration>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'capabilityIntegration/CreateOneThunk',
        );
      }
      return await capabilityIntegrationCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const capabilityIntegrationFetchManyThunk = createAsyncThunk(
  'capabilityIntegration/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<CapabilityIntegration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await capabilityIntegrationGetMany(inlineArgs);
      return {
        capabilityIntegration: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const capabilityIntegrationFetchOneThunk = createAsyncThunk(
  'capabilityIntegration/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<CapabilityIntegration>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'capabilityIntegration/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await capabilityIntegrationGetOne(arg.id as number, arg);
      return {
        capabilityIntegration: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
