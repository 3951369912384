import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { Button, Surface, Tooltip } from '@bitmodern/bit-ui';
import { CancelIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import styles from './SelectionBar.module.scss';

type Props = {
  actions?: Array<{
    icon: ReactNode;
    label: ReactNode;
    handler: () => void;
  }>;
  count: number;
  onClose: () => void;
};

export default function SelecionBar({ count, actions = [], onClose }: Props) {
  const { t } = useTranslation();

  const isOpen = count && actions.length;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          animate="visible"
          initial="initial"
          exit="hidden"
          variants={{
            initial: {
              opacity: 0.5,
              y: 40,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: {
              opacity: 0,
              y: 40,
            },
          }}
          className={styles.wrap}>
          <Surface className={styles.selectionBar}>
            <div className={styles.count} onClick={onClose}>
              {t('selectionBar.count', { count })}
              <Tooltip tooltip={t('selectionBar.deselect')}>
                <CancelIcon
                  className={styles.close}
                  color={vars.textPrimary}
                  size={16}
                />
              </Tooltip>
            </div>
            {actions.map((action, i) => (
              <Button
                className={styles.action}
                color="primaryDark"
                key={i} // eslint-disable-line react/no-array-index-key
                icon={action.icon}
                onClick={action.handler}
                size="small">
                {action.label}
              </Button>
            ))}
          </Surface>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
