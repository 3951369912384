import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { Input } from '@bitmodern/bit-ui';
import { ProjectNewIcon, ProjectNewLightIcon } from '@bitmodern/bit-ui/icons';
import { createProjectThunk } from '@bitmodern/redux/state/projects/thunks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { ProjectColors, ProjectIcons } from 'src/enums/ProjectEnums';
import { useTheme } from 'src/hooks/useTheme';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import StaggerWrapper from '../StaggerWrapper/StaggerWrapper';
import { StepContent } from '../StepContent';
import { StepActions } from '../StepActions';
import { StepProps } from '../Step';
import styles from './ProjectAndThemeStep.module.scss';

import Debug from 'debug';
const debug = Debug('ProjectAndThemeStep');

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('getStarted.projectAndThemeStep.form.nameLabel'),
});

const initialValues = {
  name: '',
  description: '',
  icon: ProjectIcons.Test,
  color: ProjectColors.Blue,
};

export function ProjectAndThemeStep({
  currentStep,
  stepTotal,
  onNextStep,
}: StepProps) {
  const { t } = useTranslation();
  const [theme, setTheme] = useTheme();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);

  const hasProjects = Boolean(projects.length);

  const onSubmit = useCallback(
    (values) => {
      debug('onSubmit', { values });
      dispatch(createProjectThunk({ values })).then(() => {
        onNextStep();
      });
    },
    [dispatch, onNextStep],
  );

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  const onClickLight = () => setTheme('light');
  const onClickDark = () => setTheme('dark');

  let action;
  let loading;
  let onClickAction = onNextStep;

  if (!hasProjects) {
    action = t('getStarted.projectAndThemeStep.form.submitButton');
    loading = formik.isSubmitting;
    onClickAction = formik.submitForm;
  }

  return (
    <>
      <StepContent
        currentStep={currentStep}
        stepTotal={stepTotal}
        title={t('getStarted.projectAndThemeStep.title')}>
        <StaggerWrapper>
          <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            {!hasProjects && (
              <Input
                error={formikError(formik, 'name')}
                fullWidth
                label={t('getStarted.projectAndThemeStep.form.nameLabel')}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t(
                  'getStarted.projectAndThemeStep.form.namePlaceholder',
                )}
                value={formik.values.name}
              />
            )}
            <div className={styles.themeText}>
              {t('getStarted.projectAndThemeStep.themeText')}
            </div>
            <div className={styles.themeOptions}>
              <div
                onClick={onClickLight}
                className={classnames(styles.themeOption, {
                  [styles.themeSelected]: theme === 'light',
                })}>
                <ProjectNewLightIcon size={70} />
                <span className={styles.themeLabel}>
                  {t('getStarted.projectAndThemeStep.theme.light')}
                </span>
              </div>
              <div
                className={classnames(styles.themeOption, {
                  [styles.themeSelected]: theme === 'dark',
                })}
                onClick={onClickDark}>
                <ProjectNewIcon size={70} />
                <span className={styles.themeLabel}>
                  {t('getStarted.projectAndThemeStep.theme.dark')}
                </span>
              </div>
            </div>
          </form>
        </StaggerWrapper>
      </StepContent>
      <StepActions
        action={action}
        hasSkip={false}
        loading={loading}
        onSkip={onNextStep}
        onClickAction={onClickAction}
      />
    </>
  );
}
