import React, { useState, useCallback, useRef, useMemo } from 'react';
import { BaseButton } from '@bitmodern/bit-ui';
import { CollapseIcon, ExpandIcon } from '@bitmodern/bit-ui/icons';
import classnames from 'classnames';
import styles from './ActivityTextFieldDiff.module.scss';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';

type ActivityDiff = {
  from?: string;
  to?: string;
};

type Props = {
  operation: 'create' | 'comment' | 'update' | 'add' | 'remove' | 'stepCreate';
  field: string;
  textFieldsItems?: string[];
  containerWidth: number | null;
  diff: ActivityDiff;
};

export default function ActivityTextFieldDiff({
  operation,
  field,
  diff,
  textFieldsItems = [],
  containerWidth,
}: Props) {
  const { t } = useTranslation();
  const fromRef = useRef<HTMLParagraphElement>(null);
  const toRef = useRef<HTMLParagraphElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const isExceeding = useMemo(() => {
    if (hasSroll(fromRef.current)) return true;
    if (hasSroll(toRef.current)) return true;
    return false;
    // we want to run calculation everytime width change
  }, [containerWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((p) => !p);
  }, []);

  const textCN = classnames(styles.text, {
    [styles.textExpanded]: isExceeding && isExpanded,
  });

  const Icon = isExpanded ? CollapseIcon : ExpandIcon;

  if (operation !== 'update' || !textFieldsItems.includes(field)) return null;

  return (
    <div className={styles.container}>
      {diff.to && (
        <p className={textCN} ref={toRef}>
          {diff.to}
        </p>
      )}
      {diff.from && (
        <div className={styles.from}>
          <p className={styles.previously}>{t('activity.diff.previously')}</p>
          <p className={textCN} ref={fromRef}>
            {diff.from}
          </p>
        </div>
      )}
      {isExceeding && (
        <BaseButton className={styles.icon} onClick={toggleIsExpanded}>
          <Icon color={vars.textPrimary} size={18} />
        </BaseButton>
      )}
    </div>
  );
}

function hasSroll(element: HTMLElement | null = null): boolean {
  if (!element) return false;
  return element.scrollHeight > element.offsetHeight;
}
