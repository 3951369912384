/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { attachmentAdapter } from './attachmentAdapter';

export const attachmentSelectors = attachmentAdapter.getSelectors(
  (state: State) => state.gen.attachment,
);
