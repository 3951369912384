import * as Yup from 'yup';
import merge from 'lodash/merge';

/**
 * Validation schema
 */
const nodeEnvs = ['development', 'production', 'test'] as const;

const schema = Yup.object()
  .required()
  .shape({
    isDevelopment: Yup.bool().required(),
    isProduction: Yup.bool().required(),
    isTest: Yup.bool().required(),
    log: Yup.object()
      .shape({
        level: Yup.string(),
        showPackets: Yup.bool(),
      })
      .required(),
    node: Yup.string().required().oneOf(nodeEnvs).default('development'),
    serviceWorker: Yup.object()
      .shape({
        enabled: Yup.bool().required().default(false),
      })
      .required(),
    api: Yup.string().required('API should be a valid URL'),
    drift: Yup.string(),
    stripeKey: Yup.string().required(),
    githubMarketplace: Yup.string().url().required(),
    reCaptchaSiteKey: Yup.string().required(),
    tagManager: Yup.string(),
    hotjar: Yup.number(),
    xDebug: Yup.bool(),
    features: Yup.object({
      atlassianLogin: Yup.bool(),
      dataCache: Yup.bool(),
      jiraAppIntegration: Yup.bool(),
      commandPaletteButton: Yup.bool(),
    }).required(),
  });

let env = schema.cast({
  isDevelopment: process.env.NODE_ENV === 'development',
  isProduction: process.env.NODE_ENV === 'production',
  isTest: process.env.NODE_ENV === 'test',
  log: {
    level: process.env.REACT_APP_LOG_LEVEL,
    showPackets: process.env.REACT_APP_LOG_PACKETS,
  },
  node: process.env.NODE_ENV,
  serviceWorker: {
    enabled: process.env.REACT_APP_SERVICE_WORKER_ENABLED,
  },
  xDebug: process.env.REACT_APP_XDEBUG,
  features: {
    dataset: false,
    jiraAppIntegration: false,
  },
});

/**
 * Environment variables
 */
export function setEnv(values: Partial<typeof env>) {
  const envValues = schema.cast(merge(env, values));

  try {
    schema.validateSync(envValues);
  } catch (err) {
    console.error(err);
    alert('Wrong environment variables, check console!');
  }

  env = envValues;
}

export function getEnv() {
  return env;
}
