import React from 'react';
import classnames from 'classnames';
import { Project, Status, User } from '@testquality/sdk';
import { DeleteIcon } from '@bitmodern/bit-ui/icons';
import { Avatar, IconButton, NotificationBubble } from '@bitmodern/bit-ui';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import styles from './WatchItem.module.scss';
import StatusIcon from '../StatusIcon';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';

type Props = {
  runName: string | boolean;
  runResultName: string | boolean;
  project: Project;
  status: Status;
  updatedAt: string;
  updatedBy: User;
  wasRead: boolean;
  onClick: () => void;
  onDelete: () => void;
};

export default function WatchItem({
  runName,
  runResultName,
  project,
  status,
  updatedAt,
  updatedBy,
  wasRead,
  onClick,
  onDelete,
}: Props) {
  const { t } = useTranslation();

  const description = runName
    ? t('watch.item.descriptionRun', {
        runName,
        project: project.name,
      })
    : t('watch.item.descriptionRunResult', {
        runResultName,
        project: project.name,
      });

  const descriptionCN = classnames(styles.description, {
    [styles.highlighted]: !wasRead,
  });

  return (
    <li aria-label={`${runName || runResultName}-watch`}>
      <div className={styles.watch} onClick={onClick}>
        <div className={styles.statusIcon}>
          {!wasRead && <NotificationBubble size="small" position="left" />}
          <StatusIcon status={status} />
        </div>
        <div className={styles.content}>
          <h5 className={descriptionCN}>{description}</h5>
          <p className={styles.secondary}>
            <>
              <Avatar
                className={styles.avatar}
                name={formatUserName(updatedBy)}
                size={18}
                src={processAttachmentPath(updatedBy.picture)}
              />
              {t('watch.item.updated', {
                updatedBy: formatUserName(updatedBy),
                date: new Date(updatedAt),
              })}
            </>
          </p>
        </div>

        <IconButton
          title={t('watch.item.delete')}
          onClick={onDelete}
          boxed={false}>
          <DeleteIcon color={vars.textSecondary} size={22} />
        </IconButton>
      </div>
    </li>
  );
}
