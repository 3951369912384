import React from 'react';
import useTests from '../hooks/useTests';
import { Defect } from '@testquality/sdk';
import DefectsTree from './DefectsTree';

type Props = {
  defect: Defect;
  projectId: number;
};

export default function DefectTests({ defect, projectId }: Props): JSX.Element {
  const { tree } = useTests({ defect, projectId });
  return <DefectsTree id={defect.id.toString()} tree={tree} />;
}
