import React from 'react';
import { NativeDefectStatus } from '@testquality/sdk';
import DoneIcon from '@bitmodern/bit-ui/icons/DoneIcon';
import PendingIcon from '@bitmodern/bit-ui/icons/PendingIcon';
import styles from './NativeDefectStatusItem.module.scss';
import { StatusTypeEnum } from 'src/enums/StatusEnum';

type Props = {
  nativeDefectStatus?: NativeDefectStatus;
  statusKey: number;
};

export function NativeDefectStatusItem({
  nativeDefectStatus,
  statusKey,
}: Props) {
  if (!nativeDefectStatus) {
    return null;
  }

  let icon;
  switch (statusKey) {
    case StatusTypeEnum.Positive: // Open
      icon = (
        <div className={`${styles.icon} ${styles.iconWarning}`}>
          <PendingIcon size={20} />
        </div>
      );
      break;
    case StatusTypeEnum.Neutral: // In Progress
      icon = (
        <div className={`${styles.icon} ${styles.iconWarning}`}>
          <PendingIcon size={20} />
        </div>
      );
      break;
    case StatusTypeEnum.Negative: // Closed
      icon = (
        <div className={`${styles.icon} ${styles.iconSuccess}`}>
          <DoneIcon size={20} />
        </div>
      );
      break;

    default:
      icon = null;
  }

  return (
    <>
      {icon}
      <div className={styles.statusText}>{nativeDefectStatus.name}</div>
    </>
  );
}
