import React, { createContext, useCallback, useContext, useState } from 'react';
import { CommandBarType } from 'src/packages/bit-ui/CommandBar/CommandBar';

type Context = {
  isOpen: boolean;
  isLoading: boolean;
  commands: CommandBarType;
  hide: () => void;
  show: () => void;
  handleSetLoading: (loading: boolean) => void;
  handleSetCommands: (cmds: CommandBarType) => void;
};

const CommandBarContext = createContext<Context | undefined>(undefined);

export function CommandBarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commands, setCommands] = useState<CommandBarType>([]);

  const show = useCallback(() => {
    setIsOpen(true);
  }, []);

  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSetLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const handleSetCommands = useCallback((cmds: CommandBarType) => {
    setCommands(cmds);
  }, []);

  return (
    <CommandBarContext.Provider
      value={{
        isOpen,
        isLoading,
        commands,
        show,
        hide,
        handleSetLoading,
        handleSetCommands,
      }}>
      {children}
    </CommandBarContext.Provider>
  );
}

export function useCommandBar() {
  const commandBar = useContext(CommandBarContext);

  if (commandBar === undefined) {
    throw Error('Command bar provider missing');
  }
  return commandBar;
}
