/**
 * Copyright (C) 2020 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Pusher, { Channel, Runtime } from 'pusher-js';
import { useEffect, useRef } from 'react';
import { getHttpResponse } from '@testquality/sdk';
import { tokenSelector } from '@bitmodern/redux/state/authentication/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Debug from 'debug';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { handleNotificationAction } from 'src/gen/actions/HandleNotificationAction';
import { getApiBaseUrl, getClient } from '../Client';

const WAIT_BEFORE_WEBSOCKET_CONNECT = 2000;

const NOTIFICATION =
  'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
const APP_KEY = '5f9dc6c0b50b60a5a8d3';

const clientChannelName = (clientId: number) =>
  `private-App.Models.Client.${clientId}`;
const userChannelName = (userId: number) => `private-App.Models.User.${userId}`;

const log = new ConsoleLogger('ActivityCollector');
const debug = Debug('activity');

function ourCustomAuthorizer(
  this: any,
  context: Runtime,
  socketId: string,
  callback: Function,
) {
  const data = this.composeQuery(socketId);

  debug('Pusher Auth');
  getClient()
    .api.post('/broadcast_auth', data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then(
      (response) => {
        if (response.data) {
          callback(false, response.data);
        } else {
          callback(true, 'No data');
        }
      },
      (error) => {
        const message = getHttpResponse(error);
        callback(true, message);
      },
    );
}

const supportedAuthorizers = Pusher.Runtime.getAuthorizers();
supportedAuthorizers.ourCustomAuthorizer = ourCustomAuthorizer;
Pusher.Runtime.getAuthorizers = () => supportedAuthorizers;

export function ActivityCollector() {
  const token = useSelector(tokenSelector);
  const pusher = useRef<Pusher | undefined>();
  const lastNotification = useRef<any>();
  const dispatch = useDispatch();

  const accessToken = token?.access_token;
  const clientId = token?.client_id;
  const userId = token?.user_id;

  useEffect(() => {
    let clientChannel: Channel;
    let userChannel: Channel;
    let lastTimer: NodeJS.Timeout | undefined;

    if (!pusher.current && accessToken && clientId && userId) {
      lastTimer = setTimeout(() => {
        pusher.current = new Pusher(APP_KEY, {
          authEndpoint: `${getApiBaseUrl()}/api/broadcast_auth`,
          authTransport: 'ourCustomAuthorizer' as any,
          auth: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });

        pusher.current.connection.bind('failed', (err) => {
          log.error('Pusher Failed Connected', err);
        });

        pusher.current.connection.bind('error', (err) => {
          log.error('Pusher error', err);
        });

        debug('Pusher User Subscribe');
        userChannel = pusher.current.subscribe(userChannelName(userId));
        userChannel.bind(NOTIFICATION, (notification) => {
          log.info('User notification', notification);

          // ignore own notifications in current tab
          if (lastNotification.current?.id !== notification.id) {
            lastNotification.current = notification;
            dispatch(handleNotificationAction(notification));
          }
        });

        debug('Pusher Client Subscribe');
        clientChannel = pusher.current.subscribe(clientChannelName(clientId));
        clientChannel.bind(NOTIFICATION, (notification) => {
          log.info('Client notification', notification);

          // ignore own notifications in current tab
          if (lastNotification.current?.id !== notification.id) {
            lastNotification.current = notification;
            dispatch(handleNotificationAction(notification));
          }
        });
      }, WAIT_BEFORE_WEBSOCKET_CONNECT);
    }

    return () => {
      if (lastTimer) clearTimeout(lastTimer);
      // don't want to constantly switching pusher connection
      // todo: check if access_token changes and then disconnect
      // if (pusher.current) {
      //   pusher.current.disconnect();
      // }
    };
  }, [accessToken, clientId, userId, dispatch]);

  return null;
}
