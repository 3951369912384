/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PolicyApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { policyRowsNormalizer } from '../policy_rows/policyRowsNormalize';

export const policyNormalizer = (model: PolicyApi, cache: NormalizerCache) => {
  if (!cache.policy) {
    cache.policy = [];
  }
  cache.policy.push(model);

  if (model.policy_rows && model.policy_rows.length) {
    model.policy_rows.forEach((child) => {
      policyRowsNormalizer(child, cache);
    });
    model.policy_rows = undefined;
  }
};
