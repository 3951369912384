/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AttachmentHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { attachmentHistoryAdapter } from './attachmentHistoryAdapter';
import { attachmentHistoryFetchThunk } from './attachmentHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const attachmentHistorySlice = createSlice({
  name: 'attachmentHistory',
  initialState: attachmentHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    attachmentHistoryCleared(state) {
      state.loading = 'idle';
      attachmentHistoryAdapter.removeAll(state);
    },
    attachmentHistoryUpsertMany(
      state,
      action: PayloadAction<
        AttachmentHistory[] | Record<number, AttachmentHistory>
      >,
    ) {
      attachmentHistoryAdapter.upsertMany(state, action.payload);
    },
    attachmentHistoryUpsertOne(
      state,
      action: PayloadAction<AttachmentHistory>,
    ) {
      attachmentHistoryAdapter.upsertOne(state, action.payload);
    },
    attachmentHistoryUpdateOne(
      state,
      action: PayloadAction<Update<AttachmentHistory>>,
    ) {
      attachmentHistoryAdapter.updateOne(state, action.payload);
    },
    attachmentHistoryRemoveOne(state, action: PayloadAction<number>) {
      attachmentHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attachmentHistoryFetchThunk.pending, handlePending);
    builder.addCase(attachmentHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      attachmentHistoryAdapter.upsertMany(
        state,
        action.payload.attachmentHistory,
      );
    });
    builder.addCase(attachmentHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  attachmentHistoryCleared,
  attachmentHistoryUpsertMany,
  attachmentHistoryUpsertOne,
  attachmentHistoryUpdateOne,
  attachmentHistoryRemoveOne,
} = attachmentHistorySlice.actions;

export const attachmentHistoryReducer = attachmentHistorySlice.reducer;
