/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { RunResultStep } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runResultStepAdapter } from './runResultStepAdapter';
import {
  runResultStepFetchManyThunk,
  runResultStepFetchOneThunk,
  runResultStepDeleteOneThunk,
  runResultStepUpdateOneThunk,
  runResultStepCreateOneThunk,
} from './runResultStepThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  runResultFetchManyThunk,
  runResultFetchOneThunk,
} from '../run_result/runResultThunk';
import {
  defectFetchManyThunk,
  defectFetchOneThunk,
} from '../defect/defectThunk';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
} from '../defect_res/defectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
} from '../native_defect_res/nativeDefectResThunk';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import { suiteFetchManyThunk, suiteFetchOneThunk } from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import { planFetchManyThunk, planFetchOneThunk } from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import { stepFetchManyThunk, stepFetchOneThunk } from '../step/stepThunk';
import {
  sharedStepFetchManyThunk,
  sharedStepFetchOneThunk,
} from '../shared_step/sharedStepThunk';
import {
  statusFetchManyThunk,
  statusFetchOneThunk,
} from '../status/statusThunk';
import {
  statusTypeFetchManyThunk,
  statusTypeFetchOneThunk,
} from '../status_type/statusTypeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import { runFetchManyThunk, runFetchOneThunk } from '../run/runThunk';
import {
  checkRunFetchManyThunk,
  checkRunFetchOneThunk,
} from '../check_run/checkRunThunk';
import {
  checkSuiteFetchManyThunk,
  checkSuiteFetchOneThunk,
} from '../check_suite/checkSuiteThunk';
import {
  pullRequestFetchManyThunk,
  pullRequestFetchOneThunk,
} from '../pull_request/pullRequestThunk';
import {
  milestoneFetchManyThunk,
  milestoneFetchOneThunk,
} from '../milestone/milestoneThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runResultStepSlice = createSlice({
  name: 'runResultStep',
  initialState: runResultStepAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runResultStepCleared(state) {
      state.loading = 'idle';
      runResultStepAdapter.removeAll(state);
    },
    runResultStepUpsertMany(
      state,
      action: PayloadAction<RunResultStep[] | Record<number, RunResultStep>>,
    ) {
      runResultStepAdapter.upsertMany(state, action.payload);
    },
    runResultStepUpsertOne(state, action: PayloadAction<RunResultStep>) {
      runResultStepAdapter.upsertOne(state, action.payload);
    },
    runResultStepUpdateOne(
      state,
      action: PayloadAction<Update<RunResultStep>>,
    ) {
      runResultStepAdapter.updateOne(state, action.payload);
    },
    runResultStepRemoveOne(state, action: PayloadAction<number>) {
      runResultStepAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runResultStepFetchManyThunk.pending, handlePending);
    builder.addCase(runResultStepFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
    });
    builder.addCase(runResultStepFetchManyThunk.rejected, handleRejected);
    builder.addCase(runResultStepFetchOneThunk.pending, handlePending);
    builder.addCase(runResultStepFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultStepAdapter.upsertOne(state, action.payload.runResultStep[0]);
    });
    builder.addCase(runResultStepFetchOneThunk.rejected, handleRejected);
    builder.addCase(runResultStepDeleteOneThunk.pending, handlePending);
    builder.addCase(runResultStepDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        runResultStepAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(runResultStepDeleteOneThunk.rejected, handleRejected);
    builder.addCase(runResultStepUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, runResultStepAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(runResultStepUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultStepAdapter.updateOne(state, action.payload);
    });
    builder.addCase(runResultStepUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          runResultStepAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(runResultStepCreateOneThunk.pending, handlePending);
    builder.addCase(runResultStepCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultStepAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(runResultStepCreateOneThunk.rejected, handleRejected);
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(runResultFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(runResultFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(stepFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(stepFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(sharedStepFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(sharedStepFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(statusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(statusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(statusTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(statusTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResultStep) {
          runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(runFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(runFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(checkRunFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(checkRunFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(checkSuiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(checkSuiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(pullRequestFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(pullRequestFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(milestoneFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(milestoneFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResultStep) {
        runResultStepAdapter.upsertMany(state, action.payload.runResultStep);
      }
    });
  },
});

export const {
  runResultStepCleared,
  runResultStepUpsertMany,
  runResultStepUpsertOne,
  runResultStepUpdateOne,
  runResultStepRemoveOne,
} = runResultStepSlice.actions;

export const runResultStepReducer = runResultStepSlice.reducer;
