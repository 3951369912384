import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import isPlainObject from 'lodash/isPlainObject';
import IndexedDbCache from './IndexedDbCache';
import InMemoryCache from './InMemoryCache';
import { Cache } from './Cache';

export const fetchCache: Cache = new (
  window.indexedDB ? IndexedDbCache : InMemoryCache
)();

const cache = fetchCache;

const fetchOneStatus = new InMemoryCache();

export function clearFetchCache() {
  cache.clear();
}

enum Status {
  Idle,
  Loading,
  Staled,
  Success,
  Error,
}

interface State {
  data?: any;
  error?: any;
  status: Status;
}

interface Options {
  /** Cache time in seconds */
  cacheTime?: number;
  initialData?: any;
  ignoreCallback?: boolean;
  /**
   * Fetch only one time, after that fetch will no triggered.
   * Unlike cache this is allways stored in memeory in a global
   * state
   */
  fetchOnce?: boolean;
  /** Fetch when component mounts */
  fetchInitial?: boolean;
  staleWhileRevalidate?: boolean;
}

type UseFetchReturn<D> = {
  data: D;
  error: any;
  isFetched: boolean;
  isLoading: boolean;
  isRefetching: boolean;
  refresh: () => void;
  setData;
};

export default function useFetch<D = any>(
  fetcher: (args: { dataCached?: D; setData: (data: D) => void }) => Promise<D>,
  key,
  options: Options = {},
): UseFetchReturn<D> {
  const {
    cacheTime = 0,
    fetchInitial = true,
    fetchOnce = false,
    ignoreCallback = false,
    initialData,
    staleWhileRevalidate = false,
  } = options;

  const [state, _dispatch] = useReducer(reducer, {
    data: initialData,
    status: Status.Idle,
  });

  const eventCallback = useEventCallback(fetcher);
  const fecherFn = ignoreCallback ? eventCallback : fetcher;

  const cacheKey = useMemo(() => keysToString(key), [key]);

  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const dispatch = useCallback((action) => {
    if (mounted.current) {
      _dispatch(action);
    }
  }, []);

  const setData = useCallback(
    (data) => {
      dispatch({ type: 'SET_DATA', payload: data });
      if (staleWhileRevalidate) {
        cache.set(cacheKey, data, cacheTime);
      }
    },
    [cacheKey, cacheTime, dispatch, staleWhileRevalidate],
  );

  const fetch = useCallback(async () => {
    if (fetchOnce) {
      const fetchedBefore = await fetchOneStatus.get(cacheKey);
      if (fetchedBefore) return;
    }

    let dataCached;
    if (staleWhileRevalidate) {
      dataCached = await cache.get(cacheKey);
    }

    if (typeof dataCached === 'undefined') {
      dispatch({ type: 'PENDING' });
    } else {
      dispatch({ type: 'STALED', payload: dataCached });
    }

    fecherFn({ setData, dataCached })
      .then((res) => {
        dispatch({ type: 'FULFILLED', payload: res });
        if (staleWhileRevalidate) {
          cache.set(cacheKey, res);
        }
        if (fetchOnce) {
          fetchOneStatus.set(cacheKey, true);
        }
      })
      .catch((error) => {
        dispatch({ type: 'FAILED', payload: error });
      });
  }, [fecherFn, dispatch, cacheKey, setData, fetchOnce, staleWhileRevalidate]);

  useEffect(() => {
    if (fetchInitial) {
      fetch();
    }
  }, [fetch, fetchInitial]);

  return {
    data: state.data,
    error: state.error,
    isFetched: [Status.Error, Status.Staled, Status.Success].includes(
      state.status,
    ),
    isLoading: state.status === Status.Loading,
    isRefetching: state.status === Status.Staled,
    refresh: fetch,
    setData,
  };
}

function reducer(state: State, action): State {
  switch (action.type) {
    case 'PENDING':
      return { ...state, status: Status.Loading };
    case 'STALED':
      if (action.payload) {
        return {
          ...state,
          data: action.payload,
          status: Status.Staled,
        };
      }
      return state;
    case 'FULFILLED':
      return {
        data: action.payload,
        status: Status.Success,
      };
    case 'FAILED':
      return {
        ...state,
        error: action.payload,
        status: Status.Error,
      };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

function useEventCallback<T extends (...args: any[]) => any>(fn: T): T {
  const ref: any = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: any[]) => ref.current(...args), []) as T;
}

type Primitive = string | number | boolean | null | undefined | Date;
type Key = Primitive | Record<string | number, Primitive>;

function keysToString(keys: Key | Key[]): string {
  const arr = Array.isArray(keys) ? keys : [keys];
  return JSON.stringify(arr, (_, value) => {
    // keep keys sorted to keep object consisten just in case
    // keys change order during mutation
    return isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce((acc, key) => {
            acc[key] = value[key];
            return acc;
          }, {})
      : value;
  });
}
