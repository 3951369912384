import React from 'react';
import classnames from 'classnames';
import {
  CancelIcon,
  DeleteIcon,
  DoneIcon,
  EditIcon,
  MoreIcon,
} from '@bitmodern/bit-ui/icons';
import { Dropdown, IconButton, Menu, MenuItem } from '@bitmodern/bit-ui';
import vars from 'src/export.scss';
import { Milestone } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import styles from './MilestoneItem.module.scss';

type Props = {
  milestone: Milestone;
  onClick?: (milestone: Milestone) => void;
  onComplete: (milestone: Milestone) => void;
  onDelete: (milestone: Milestone) => void;
  onEdit: (milestone: Milestone) => void;
  onIncomplete: (milestone: Milestone) => void;
  selected?: boolean;
};

export default function MilestoneItem({
  milestone,
  onClick,
  onComplete,
  onDelete,
  onEdit,
  onIncomplete,
  selected = false,
}: Props) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick(milestone);
    }
  };

  const handleDelete = () => {
    onDelete(milestone);
  };

  const handleEdit = () => {
    onEdit(milestone);
  };

  const handleComplete = () => {
    onComplete(milestone);
  };

  const hundleIncomplete = () => {
    onIncomplete(milestone);
  };

  const milestoneCN = classnames(styles.milestone, {
    [styles.selected]: selected,
    [styles.interactive]: Boolean(onClick),
  });

  return (
    <div className={milestoneCN} onClick={handleClick}>
      <div className={styles.milestoneInfo}>
        <div>
          {milestone.name}
          {selected && (
            <DoneIcon
              className={styles.doneIcon}
              color={vars.successMain}
              size={18}
            />
          )}
        </div>
        {milestone.release_date && (
          <div className={styles.date}>
            {t('milestone.release', {
              release: new Date(milestone.release_date),
            })}
          </div>
        )}
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
          overlay={
            <Menu>
              {milestone.is_complete ? (
                <MenuItem
                  startAdornment={
                    <CancelIcon color={vars.textPrimary} size={18} />
                  }
                  onClick={hundleIncomplete}>
                  {t('milestone.actions.incomplete')}
                </MenuItem>
              ) : (
                <MenuItem
                  startAdornment={
                    <DoneIcon color={vars.textPrimary} size={18} />
                  }
                  onClick={handleComplete}>
                  {t('milestone.actions.complete')}
                </MenuItem>
              )}
              <MenuItem
                startAdornment={<EditIcon color={vars.textPrimary} size={18} />}
                onClick={handleEdit}>
                {t('milestone.actions.edit')}
              </MenuItem>
              <MenuItem
                startAdornment={
                  <DeleteIcon color={vars.textPrimary} size={18} />
                }
                onClick={handleDelete}>
                {t('milestone.actions.delete')}
              </MenuItem>
            </Menu>
          }>
          <IconButton size="small" title={t('milestone.actions.more')}>
            <MoreIcon color={vars.textPrimary} size={18} />
          </IconButton>
        </Dropdown>
      </div>
    </div>
  );
}
