/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  TestQuality,
  testQualityHistoryGet,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';

export const testQualityHistoryFetchThunk = createAsyncThunk(
  'testQualityHistory/FetchThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<TestQuality>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      arg.cancelToken = source.token;
      const data = await testQualityHistoryGet(arg);
      return {
        testQualityHistory: data,
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
