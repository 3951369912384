import React from 'react';
import { Select } from '@bitmodern/bit-ui';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { Status } from '@testquality/sdk';
import { StatusEnum } from 'src/enums/StatusEnum';
import StatusIcon from 'src/components/organisms/StatusIcon';
import StatusButton from '../StatusButton';
import styles from './QuickStatus.module.scss';

type Props = {
  disabled?: boolean;
  onChange: (status: Status) => void;
  value: number;
};

export default function QuickStatus({ disabled, onChange, value }: Props) {
  let statuses = useAppSelector(statusSelectors.selectAll);

  const passStatus = statuses.find((s) => s.key === StatusEnum.Pass);
  const failStatus = statuses.find((s) => s.key === StatusEnum.Fail);
  const exclusions = [StatusEnum.Pass, StatusEnum.Fail, StatusEnum.Pending];
  statuses = statuses.filter((s) => !exclusions.includes(s.key));

  const handleChange = (val) => {
    const current = statuses.find((s) => s.id === val);
    if (onChange && current) {
      onChange(current);
    }
  };

  const renderOption = (option) => (
    <>
      <StatusIcon status={option.status} />
      <span className={styles.label}>{option.label}</span>
    </>
  );

  return (
    <div className={styles.quickStatus}>
      {passStatus && (
        <StatusButton
          disabled={disabled}
          onClick={() => !disabled && onChange(passStatus)}
          selected={passStatus.id === value}
          status={passStatus}
        />
      )}
      {failStatus && (
        <StatusButton
          disabled={disabled}
          onClick={() => !disabled && onChange(failStatus)}
          selected={failStatus.id === value}
          status={failStatus}
        />
      )}
      <Select
        className={styles.select}
        disabled={disabled}
        placeholder="Other"
        onChange={handleChange}
        options={statuses.map((status) => ({
          label: status.name,
          status,
          value: status.id,
        }))}
        renderOption={renderOption}
        value={value}
        size="small"
      />
    </div>
  );
}
