import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useOverlayTriggerState } from 'react-stately';
import { Milestone } from '@testquality/sdk';
import { InputDateRange, notification, Spacer } from '@bitmodern/bit-ui';
import { Range } from '@bitmodern/bit-ui/InputDateRange/InputDateRange';
import { FormValues } from 'src/components/organisms/MilestoneForm/MilestoneForm';
import {
  Filters,
  MilestoneDropdown,
  MilestoneFormDialog,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { milestonesByProjectSelector } from '@bitmodern/redux/state/milestones/selectors';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import { TestsFiltersKeys } from '@bitmodern/redux/state/filters/reducer';
import { milestoneUpdateOneThunk } from 'src/gen/domain/milestone/milestoneThunk';
import styles from './AnalyzeFilters.module.scss';
import { DateRange } from '../../../common/DataRange';
import { notificationErrorTimeout } from '../../../constants';
import { getDateRangeByTimeframe } from 'src/packages/redux/state/date/selectors';
import { useParamSelector } from 'src/packages/redux/hooks';

type Props = { extraFilters?: boolean; type?: TestsFiltersKeys };

const BASE_FILTER_TYPE = 'analyze';

export default function AnalyzeFilters({
  extraFilters = false,
  type = BASE_FILTER_TYPE,
}: Props) {
  const { t } = useTranslation();
  const { projectId: projeIdUrl } = useParams<typeof routes.ANALYZE.params>();
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone | null>(
    null,
  );
  const timeframe = useParamSelector(getDateRangeByTimeframe, {
    type: BASE_FILTER_TYPE,
  });
  const projectId = parseInt(projeIdUrl, 10);
  const dispatch = useAppDispatch();
  const handleFilterChange = (
    milestone: Milestone | null,
    rangeValue: DateRange,
    timeframe?: string,
  ) => {
    setSelectedMilestone(milestone);
    handleDateRangeFilter(rangeValue, timeframe);
  };
  const [milestoneEditInitialValues, setMilestoneEditInitialValues] =
    useState<FormValues>();
  const editMilestone = useOverlayTriggerState({});
  const milestones = useAppSelector((state) =>
    milestonesByProjectSelector(state, projectId),
  );

  const onChangeDateRange = (rangeValue: Range<Date>, timeframe) => {
    console.log(rangeValue, timeframe);
    if (!rangeValue || !rangeValue[0] || !rangeValue[1]) return;
    handleFilterChange(null, rangeValue as DateRange, timeframe);
  };

  const onChangeMilestone = (milestone: Milestone | null) => {
    if (milestone?.start_date && milestone?.release_date) {
      const dateRange: DateRange = [
        new Date(milestone.start_date),
        new Date(milestone.release_date),
      ];
      handleFilterChange(milestone || null, dateRange);
    } else if (!milestone) {
      /* NOTE: When the user deselects the milestone as a */
      /* filter, we set the date range back at default range */
      handleFilterChange(
        null,
        InputDateRange.defaultTimeranges[1].value as DateRange,
      );
    } else {
      /* NOTE: We set the milestone because we need it to update the milestone */
      setSelectedMilestone(milestone);
      setMilestoneEditInitialValues({
        name: milestone.name,
        description: milestone.description || '',
        startDate: milestone.start_date
          ? new Date(milestone.start_date)
          : undefined,
        releaseDate: milestone.release_date
          ? new Date(milestone.release_date)
          : undefined,
      });
      editMilestone.open();
    }
  };

  function handleMilestoneEdit(values) {
    if (!selectedMilestone) return Promise.reject();

    const editMilestonePromise = dispatch(
      milestoneUpdateOneThunk({
        id: selectedMilestone.id,
        data: {
          name: values.name,
          description: values.description,
          release_date: values.releaseDate,
          start_date: values.startDate,
        },
      }),
    ).then(unwrapResult);

    /* NOTE: After updating the milestone check the relevant dates are */
    /* set and if so, set them as filters before closing the modal */
    if (values.startDate && values.releaseDate) {
      return editMilestonePromise.finally(() => {
        const newRange: DateRange = [values.startDate, values.releaseDate];
        handleFilterChange(selectedMilestone || null, newRange);
        editMilestone.close();
      });
    }

    /* NOTE: Otherwise set the milestone to null and inform the user */
    return editMilestonePromise.finally(() => {
      setSelectedMilestone(null);
      notification.open({
        type: 'error',
        message: t('analyze.testGrowth.filter.milestone.missingDates'),
        duration: notificationErrorTimeout,
      });
      editMilestone.close();
    });
  }

  function handleDateRangeFilter(rangeValue: DateRange, timeframe) {
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'startDate',
        value: rangeValue[0].toISOString(),
      }),
    );
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'endDate',
        value: rangeValue[1].toISOString(),
      }),
    );
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'timeframe',
        value: timeframe,
      }),
    );

    if (type !== BASE_FILTER_TYPE) {
      dispatch(
        toggleFilterAction({
          type,
          filter: 'endDate',
          value: rangeValue[1].toISOString(),
        }),
      );
      dispatch(
        toggleFilterAction({
          type,
          filter: 'startDate',
          value: rangeValue[0].toISOString(),
        }),
      );
      dispatch(
        toggleFilterAction({
          type,
          filter: 'timeframe',
          value: timeframe,
        }),
      );
    }
  }

  if (!timeframe) return null;

  return (
    <>
      <Spacer>
        {extraFilters && <Filters type={type} />}
        <MilestoneDropdown
          milestones={milestones}
          selectedMilestone={selectedMilestone?.id || null}
          onChangeFilters={onChangeMilestone}
          selected={Boolean(selectedMilestone)}
        />
        <InputDateRange
          className={styles.range}
          onChange={onChangeDateRange}
          value={timeframe}
          selected
        />
      </Spacer>
      <MilestoneFormDialog
        initialValues={milestoneEditInitialValues}
        additionalInfo="analyze.testGrowth.filter.milestone.missingDates"
        onClose={editMilestone.close}
        onSubmit={handleMilestoneEdit}
        open={editMilestone.isOpen}
      />
    </>
  );
}
