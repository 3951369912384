import React, { useCallback, useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Run, Status, SuiteTest, Test } from '@testquality/sdk';
import {
  Grid,
  Loading,
  PanelHeader,
  Surface,
  Tooltip,
} from '@bitmodern/bit-ui';
import {
  AnalyzeFilters,
  FlakinessChart,
  FlakinessMetrics,
  AnalyzeTree,
  StatusIcon,
} from 'src/components/organisms';
import { ArrowRightIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import { FlakinessEnum } from 'src/enums/FlakinessEnum';
import useParams from 'src/hooks/useParams';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useFetch from 'src/hooks/useFetch';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { statusesSelector } from '@bitmodern/redux/state/statuses/selectors';
import { runsSelector } from '@bitmodern/redux/state/runs/selectors';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import {
  getReliabilityAnalysis,
  getReliabilityTimeSeries,
  ReliabilityAnalysis,
} from '@bitmodern/services/ReliabilityService';
import vars from 'src/export.scss';
import styles from './AnalyzeReliabilityView.module.scss';
import { dateRangeByTimeframe } from 'src/packages/bit-ui/InputDateRange/InputDateRange';

export default function AnalyzeReliabilityView() {
  const { projectId: projectIdString, site } =
    useParams<typeof routes.ANALYZE.params>();
  const { t } = useTranslation();
  const projectId = parseInt(projectIdString, 10);
  const { openDrawer, generateDrawerUrlParams } = useDrawerManager();
  const baseFilters = useAppSelector((state) =>
    filtersByTypeSelector(state, 'analyze'),
  );

  const timeframeRange = dateRangeByTimeframe(baseFilters.timeframe);
  const startDate = timeframeRange ? timeframeRange[0] : baseFilters.startDate;
  const endDate = timeframeRange ? timeframeRange[1] : baseFilters.endDate;
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const statuses = useAppSelector(statusesSelector);
  const runs = useAppSelector(runsSelector);
  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, { projectId }),
  );
  const parsedStartDate = useMemo(
    () => (startDate ? new Date(startDate) : new Date()),
    [startDate],
  );
  const parsedEndDate = useMemo(
    () => (endDate ? new Date(endDate) : new Date()),
    [endDate],
  );

  const runsMap = useMemo(() => {
    return runs.reduce<{ [key: number]: Run }>((acc, run) => {
      acc[run.id] = run;
      return acc;
    }, {});
  }, [runs]);

  const statusesMap = useMemo(() => {
    return statuses.reduce<{ [key: number]: Status }>((acc, status) => {
      acc[status.id] = status;
      return acc;
    }, {});
  }, [statuses]);

  const analysisRequest = useCallback(() => {
    return getReliabilityAnalysis(projectId);
  }, [projectId]);

  const timeSeriesRequest = useCallback(() => {
    return getReliabilityTimeSeries(projectId, parsedStartDate, parsedEndDate);
  }, [projectId, parsedStartDate, parsedEndDate]);

  const flakinessFetch = useFetch(
    analysisRequest,
    `flakiness-analysis-${projectId}`,
  );

  const timeseriesFetch = useFetch(
    timeSeriesRequest,
    `flakiness-time-seires-${projectId}`,
  );

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const renderCreatedAtTag = (test: Test) => {
    const flakinessSeries: ReliabilityAnalysis[] | undefined =
      flakinessFetch.data[test.id];

    if (!flakinessSeries) return null;
    /* NOTE: The first element in the analysis array is the most recent one */
    /* and therefore its flakiness is the only relevant one (current flakiness) */
    const isTestFlaky = flakinessSeries[0].flakiness === FlakinessEnum.FLAKY;

    return (
      <>
        {isTestFlaky && (
          <span className={`${styles.badge} ${styles.warning}`}>
            {t('analyze.testReliability.badge.flaky')}
          </span>
        )}
        {flakinessSeries[0].run_id && (
          <span className={styles.badge}>
            {[...flakinessSeries].reverse().map((analysis, index) => {
              const status = statusesMap[analysis.status_id];
              const run = runsMap[analysis.run_id];

              const pathToRun = routes.RUN({
                site,
                projectId: projectIdString,
                runId: analysis.run_id.toString(),
              });
              const pathToRunResult = `${pathToRun}?${generateDrawerUrlParams({
                resultId: analysis.run_result_id,
              })}`;

              if (!status || !run) return null;
              return (
                <Fragment key={analysis.run_result_id}>
                  <Tooltip enterDelay={100} tooltip={run.name}>
                    <span className={styles.statusWrap}>
                      <Link
                        to={pathToRunResult}
                        onClick={(e) => e.stopPropagation()}>
                        <StatusIcon status={status} />
                      </Link>
                    </span>
                  </Tooltip>
                  {index + 1 !== flakinessSeries.length && (
                    <ArrowRightIcon color={vars.textSecondary} />
                  )}
                </Fragment>
              );
            })}
          </span>
        )}
      </>
    );
  };

  if (!project) return null;

  const isFetched = flakinessFetch.isFetched && timeseriesFetch.isFetched;
  const isLoading = flakinessFetch.isLoading || timeseriesFetch.isLoading;

  return (
    <>
      <Surface>
        <PanelHeader title={project.name} actions={<AnalyzeFilters />} />
        <Grid.Row gutter={0} className={styles.rowContainer}>
          {isLoading && <Loading className={styles.loading} size={48} />}
          {isFetched && (
            <>
              <Grid.Col span={12} md={5} className={styles.column}>
                <FlakinessMetrics flakyAnalysis={flakinessFetch.data} />
              </Grid.Col>
              <Grid.Col span={12} md={7}>
                <FlakinessChart flakyTimeSeries={timeseriesFetch.data} />
              </Grid.Col>
            </>
          )}
        </Grid.Row>
      </Surface>
      <div style={{ height: 400 }}>
        {flakinessFetch.data && (
          <AnalyzeTree
            id={project.id.toString()}
            onClickTest={onClickTest}
            suitesTree={tree}
            infoTagsToRender={renderCreatedAtTag}
          />
        )}
      </div>
    </>
  );
}
