import React, { useCallback } from 'react';
import { SuiteTest, Test, TQRequestParameters } from '@testquality/sdk';
import {
  Grid,
  Loading,
  PanelHeader,
  Surface,
  Tooltip,
} from '@bitmodern/bit-ui';
import {
  AnalyzeFilters,
  GrowthChart,
  GrowthMetrics,
  AnalyzeTree,
} from 'src/components/organisms';
import { AutoIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useFetch from 'src/hooks/useFetch';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { testsFilteredSelector } from '@bitmodern/redux/state/tests/selectors';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { testHistoryByProjectSelector } from '@bitmodern/redux/state/testHistory/selectors';
import { testHistoryFetchThunk } from 'src/gen/domain/test/testHistoryThunk';
import vars from 'src/export.scss';
import styles from './AnalyzeGrowthView.module.scss';
import { getDateRangeByTimeframe } from 'src/packages/redux/state/date/selectors';
import { useParamSelector } from 'src/packages/redux/hooks';

export default function AnalyzeGrowthView() {
  const { projectId: projeIdUrl } = useParams<typeof routes.ANALYZE.params>();
  const { t } = useTranslation();
  const projectId = parseInt(projeIdUrl, 10);
  const { openDrawer } = useDrawerManager();
  const dispatch = useAppDispatch();
  const timeframe = useParamSelector(getDateRangeByTimeframe, {
    type: 'analyze',
  });
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const filteredTests = useAppSelector((state) =>
    testsFilteredSelector(state, projectId, 'analyze'),
  );
  const testHistory = useAppSelector((state) =>
    testHistoryByProjectSelector(state, projectId),
  );
  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId,
      filterType: 'analyze',
    }),
  );

  const request = useCallback(() => {
    return dispatch(
      testHistoryFetchThunk({
        params: {
          project_id: projectId,
          operation: 'delete',
        } as TQRequestParameters,
      }),
    );
  }, [dispatch, projectId]);

  const { isLoading } = useFetch(request, `deletedTestHistory-${projectId}`, {
    fetchOnce: true,
  });

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const renderInfoTags = (test: Test) => {
    const badges = [
      <span key="createdAt" className={styles.badge}>
        {t('testAnalyzeListed.createdAt', {
          date: new Date(test.created_at),
        })}
      </span>,
    ];

    if (test.is_automated) {
      badges.unshift(
        <Tooltip
          key="auto"
          enterDelay={100}
          tooltip={t('testAnalyzeListed.automated')}>
          <span className={`${styles.badge} ${styles.automated}`}>
            <AutoIcon color={vars.successMain} size={20} />
          </span>
        </Tooltip>,
      );
    }

    return badges;
  };

  if (!project || !timeframe) return null;

  return (
    <>
      <Surface>
        <PanelHeader
          title={project.name}
          actions={<AnalyzeFilters extraFilters />}
        />
        <Grid.Row gutter={0} className={styles.rowContainer}>
          <Grid.Col span={12} md={5} className={styles.column}>
            {isLoading ? (
              <Loading className={styles.loading} size={48} />
            ) : (
              <GrowthMetrics
                range={timeframe}
                tests={filteredTests}
                testHistory={testHistory}
              />
            )}
          </Grid.Col>
          <Grid.Col span={12} md={7}>
            <GrowthChart range={timeframe} tests={filteredTests} />
          </Grid.Col>
        </Grid.Row>
      </Surface>
      <div style={{ height: 400 }}>
        <AnalyzeTree
          id={project.id.toString()}
          onClickTest={onClickTest}
          suitesTree={tree}
          infoTagsToRender={renderInfoTags}
        />
      </div>
    </>
  );
}
