/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { BaseIntegrationApi } from '@testquality/sdk';
import { BaseCapabilityBaseIntegration } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { integrationNormalizer } from '../integration/integrationNormalize';
import { baseCapabilityNormalizer } from '../base_capability/baseCapabilityNormalize';
import { baseCapabilityBaseIntegrationNormalizer } from '../base_capability_base_integration/baseCapabilityBaseIntegrationNormalize';
import { appConfigNormalizer } from '../app_config/appConfigNormalize';

export const baseIntegrationNormalizer = (
  model: BaseIntegrationApi,
  cache: NormalizerCache,
) => {
  if (!cache.baseIntegration) {
    cache.baseIntegration = [];
  }
  cache.baseIntegration.push(model);

  if (model.integration && model.integration.length) {
    model.integration.forEach((child) => {
      integrationNormalizer(child, cache);
    });
    model.integration = undefined;
  }
  if (model.base_capability && model.base_capability.length) {
    model.base_capability.forEach((child) => {
      baseCapabilityBaseIntegrationNormalizer(
        child.pivot as BaseCapabilityBaseIntegration,
        cache,
      );
      child.pivot = undefined;
      baseCapabilityNormalizer(child, cache);
    });
    model.base_capability = undefined;
  }
  if (model.app_config && model.app_config.length) {
    model.app_config.forEach((child) => {
      appConfigNormalizer(child, cache);
    });
    model.app_config = undefined;
  }
};
