/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { AppInstall } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appInstallAdapter } from './appInstallAdapter';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
  appInstallDeleteOneThunk,
  appInstallUpdateOneThunk,
  appInstallCreateOneThunk,
} from './appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appInstallSlice = createSlice({
  name: 'appInstall',
  initialState: appInstallAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appInstallCleared(state) {
      state.loading = 'idle';
      appInstallAdapter.removeAll(state);
    },
    appInstallUpsertMany(
      state,
      action: PayloadAction<AppInstall[] | Record<number, AppInstall>>,
    ) {
      appInstallAdapter.upsertMany(state, action.payload);
    },
    appInstallUpsertOne(state, action: PayloadAction<AppInstall>) {
      appInstallAdapter.upsertOne(state, action.payload);
    },
    appInstallUpdateOne(state, action: PayloadAction<Update<AppInstall>>) {
      appInstallAdapter.updateOne(state, action.payload);
    },
    appInstallRemoveOne(state, action: PayloadAction<number>) {
      appInstallAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appInstallFetchManyThunk.pending, handlePending);
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appInstallAdapter.upsertMany(state, action.payload.appInstall);
    });
    builder.addCase(appInstallFetchManyThunk.rejected, handleRejected);
    builder.addCase(appInstallFetchOneThunk.pending, handlePending);
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appInstallAdapter.upsertOne(state, action.payload.appInstall[0]);
    });
    builder.addCase(appInstallFetchOneThunk.rejected, handleRejected);
    builder.addCase(appInstallDeleteOneThunk.pending, handlePending);
    builder.addCase(appInstallDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        appInstallAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(appInstallDeleteOneThunk.rejected, handleRejected);
    builder.addCase(appInstallUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, appInstallAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(appInstallUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appInstallAdapter.updateOne(state, action.payload);
    });
    builder.addCase(appInstallUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          appInstallAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(appInstallCreateOneThunk.pending, handlePending);
    builder.addCase(appInstallCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appInstallAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(appInstallCreateOneThunk.rejected, handleRejected);
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.appInstall) {
          appInstallAdapter.upsertMany(state, action.payload.appInstall);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.appInstall) {
          appInstallAdapter.upsertMany(state, action.payload.appInstall);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.appInstall) {
          appInstallAdapter.upsertMany(state, action.payload.appInstall);
        }
      },
    );
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstall) {
        appInstallAdapter.upsertMany(state, action.payload.appInstall);
      }
    });
  },
});

export const {
  appInstallCleared,
  appInstallUpsertMany,
  appInstallUpsertOne,
  appInstallUpdateOne,
  appInstallRemoveOne,
} = appInstallSlice.actions;

export const appInstallReducer = appInstallSlice.reducer;
