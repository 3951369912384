import React, { ComponentProps } from 'react';
import { Avatar, Select } from '@bitmodern/bit-ui';
import styles from './UserSelect.module.scss';

type Props = ComponentProps<typeof Select>;

export default function UserSelect({ ...rest }: Props) {
  const renderOption = (option) => (
    <>
      <Avatar
        className={styles.avatar}
        name={option.label}
        size={22}
        src={option.picture}
      />
      {option.label}
    </>
  );

  return <Select renderOption={renderOption} {...rest} />;
}
