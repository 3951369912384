import { useCallback, useEffect, useReducer, useRef } from 'react';

interface Options {
  initialData?: any;
}

interface State {
  data?: any;
  error?: any;
  isLoading: boolean;
}

export default function useMutation(
  callback: (...args: any) => Promise<any>,
  options: Options = {},
) {
  const { initialData } = options;

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    data: initialData,
  });
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const setData = useCallback((data) => {
    dispatch({ type: 'SET_DATA', payload: data });
  }, []);

  const mutate = useCallback(
    (...args) => {
      dispatch({ type: 'PENDING' });
      callback(...args)
        .then((res) => {
          if (mounted.current) {
            dispatch({ type: 'FULFILLED', payload: res });
          }
        })
        .catch((error) => {
          if (mounted.current) {
            dispatch({ type: 'FAILED', payload: error });
          }
        });
    },
    [callback],
  );

  return { data: state.data, isLoading: state.isLoading, mutate, setData };
}

function reducer(state: State, action) {
  switch (action.type) {
    case 'PENDING':
      return { ...state, isLoading: true };
    case 'FULFILLED':
      return { ...state, data: action.payload, isLoading: false };
    case 'FAILED':
      return { ...state, error: action.payload, isLoading: false };
    case 'SET_DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
