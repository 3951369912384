import React, { ComponentProps, useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOverlayTriggerState } from 'react-stately';
import { Tooltip } from '@bitmodern/bit-ui';
import { AddIcon, SaveIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import {
  clearAllFiltersAction,
  emptyFiltersAction,
} from '@bitmodern/redux/state/filters/actions';
import {
  applyCustomFilterAction,
  breakCustomFilterAction,
} from '@bitmodern/redux/state/customFilters/actions';
import {
  filtersByTypeSelector,
  filtersDisplaySelector,
} from '@bitmodern/redux/state/filters/selectors';
import { customFilterByTypeSelector } from '@bitmodern/redux/state/customFilters/selectors';
import { TestsFiltersKeys } from '@bitmodern/redux/state/filters/reducer';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import FilterItem from '../FilterItem';
import CommandBarFilters from '../CommandBarFilters';
import styles from './Filters.module.scss';
import SaveFilterDialog from '../SaveFilterDialog';
import { createFiltersByTypeSelector } from 'src/packages/redux/state/filter/selectors';
import {
  filterCreateOneThunk,
  filterUpdateOneThunk,
} from 'src/gen/domain/filter/filterThunk';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { unwrapResult } from '@reduxjs/toolkit';
import { Filter } from '@testquality/sdk';
import ConfirmDialog from '../ConfirmDialog';
import useMutation from 'src/hooks/useMutation';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

type FilterKeys =
  | 'author_one'
  | 'author_other'
  | 'caseType_one'
  | 'caseType_other'
  | 'casePriority_one'
  | 'casePriority_other'
  | 'assignee_one'
  | 'assignee_other'
  | 'automated_one'
  | 'label_one'
  | 'label_other'
  | 'suiteLabel_one'
  | 'suiteLabel_other'
  | 'plan_one'
  | 'plan_other'
  | 'status_one'
  | 'status_other'
  | 'name'
  | 'actualResult'
  | 'filter'
  | 'filterTitle'
  | 'requirement_one'
  | 'requirement_other';

type CommandList = ComponentProps<typeof CommandBarFilters>['command'];

type Props = {
  type: TestsFiltersKeys;
  parentId?: string;
};

const motionVariants = {
  hidden: {
    width: 0,
    opacity: 0,
    scale: 0,
  },
  show: {
    width: 'auto',
    opacity: 1,
    scale: 1,
  },
};

export default function Filters({ type, parentId }: Props) {
  const { projectId } = useParams<typeof routes.PROJECT.params>();
  const commandBarFilters = useOverlayTriggerState({});
  const dispatch = useAppDispatch();
  const [command, setCommand] = useState<CommandList>();
  const { t } = useTranslation();
  const saveFilterDialog = useOverlayTriggerState({});
  const confirmUpdateDialog = useOverlayTriggerState({});

  const filters = useAppSelector((state) =>
    filtersDisplaySelector(state, type),
  );
  const isDisplayingFilterTiles = !isEmpty(filters);

  const customFilter = useAppSelector((state) =>
    customFilterByTypeSelector(state, type),
  );

  // For creation
  const customFilters = useAppSelector(createFiltersByTypeSelector(type));
  const filtersByType = useAppSelector((state) =>
    filtersByTypeSelector(state, type),
  );

  const onRemove = (filter) => {
    dispatch(emptyFiltersAction({ type, filter }));
  };

  const onClickFilter = (c) => {
    setCommand(c);
    setTimeout(commandBarFilters.open);
  };

  const onClickCustomFilter = () => {
    dispatch(breakCustomFilterAction({ type }));
  };

  const onRemoveCustomFilter = () => {
    dispatch(clearAllFiltersAction({ type }));
  };

  const onCloseCommand = useCallback(() => {
    commandBarFilters.close();
    setCommand('default');
  }, [commandBarFilters]);

  function handleSaveCustomFilter(values) {
    const sameNameFilter = customFilters.find((f) => f.name === values.name);

    if (sameNameFilter) {
      /* NOTE: Set is_shareable just in case the user changed it */
      setCustomFilterToUpdate({
        ...sameNameFilter,
        is_shareable: values.share,
      });
      confirmUpdateDialog.open();
      return Promise.resolve();
    }

    return dispatch(
      filterCreateOneThunk({
        data: {
          payload: filtersByType,
          project_id: parseInt(projectId, 10),
          related_type: type,
          name: values.name,
          is_shareable: values.share,
        },
      }),
    )
      .then(unwrapResult)
      .then((filter) => {
        dispatch(applyCustomFilterAction({ type, filter }));
      })
      .finally(() => {
        saveFilterDialog.close();
        onCloseCommand();
      });
  }

  const [customFilterToUpdate, setCustomFilterToUpdate] = useState<Filter>();
  const updateCustomFilter = useCallback(() => {
    if (!customFilterToUpdate) return Promise.resolve();
    return dispatch(
      filterUpdateOneThunk({
        id: customFilterToUpdate.id,
        data: {
          payload: filters,
          is_shareable: customFilterToUpdate.is_shareable,
        },
      }),
    )
      .then(unwrapResult)
      .then(({ changes: { payload } }) => {
        const updatedFilter = { ...customFilterToUpdate, payload };
        dispatch(applyCustomFilterAction({ type, filter: updatedFilter }));
      })
      .finally(() => {
        confirmUpdateDialog.close();
        saveFilterDialog.close();
        onCloseCommand();
      });
  }, [
    dispatch,
    customFilterToUpdate,
    confirmUpdateDialog,
    filters,
    onCloseCommand,
    saveFilterDialog,
    type,
  ]);
  const updateCustomFilterMutation = useMutation(updateCustomFilter);

  const buttonCN = classnames({
    [styles.button]: isDisplayingFilterTiles,
  });

  const customFilterCN = classnames({
    [styles.firstButton]: isDisplayingFilterTiles,
  });

  return (
    <AnimatePresence initial={false} key={`key-${parentId}`}>
      <div className={styles.filters}>
        {customFilter && (
          <motion.div
            animate="show"
            initial="hidden"
            exit="hidden"
            className={styles.tileWrapper}
            variants={motionVariants}
            key={`key-${parentId}-customFilterTile`}>
            <FilterItem
              onClick={onClickCustomFilter}
              filter={customFilter.name}
              onRemove={onRemoveCustomFilter}
              className={customFilterCN}
            />
          </motion.div>
        )}
        {(Object.entries(filters) as Array<[FilterKeys, string | number]>).map(
          ([key, value], index) => {
            const firstCN = classnames(buttonCN, {
              [styles.firstButton]:
                isDisplayingFilterTiles && index === 0 && !customFilter,
            });

            return (
              <motion.div
                animate="show"
                initial="hidden"
                exit="hidden"
                className={styles.tileWrapper}
                variants={motionVariants}
                key={`key-${parentId}-${key}`}>
                <FilterItem
                  key={key + value}
                  filter={
                    typeof value === 'string'
                      ? value
                      : t(`filters.${key}`, { count: value })
                  }
                  onClick={() => onClickFilter(key)}
                  onRemove={() => onRemove(key)}
                  className={firstCN}
                />
              </motion.div>
            );
          },
        )}
        {isDisplayingFilterTiles && (
          <motion.div
            animate="show"
            initial="hidden"
            exit="hidden"
            className={styles.tileWrapper}
            variants={motionVariants}
            key={`key-${parentId}-save`}>
            <Tooltip tooltip={t('filters.saveFilter')}>
              <FilterItem
                icon={<SaveIcon color={vars.textPrimary} size={14} />}
                onClick={saveFilterDialog.open}
                className={styles.saveButton}
              />
            </Tooltip>
          </motion.div>
        )}
      </div>
      <motion.div
        animate="show"
        initial="hidden"
        exit="hidden"
        className={styles.tileFilter}
        variants={motionVariants}
        key={`key-${parentId}-filter`}>
        <Tooltip tooltip={t('filters.filterTitle')}>
          <FilterItem
            icon={<AddIcon color={vars.textPrimary} size={14} />}
            filter={t('filters.filter')}
            onClick={commandBarFilters.open}
          />
        </Tooltip>
      </motion.div>
      <CommandBarFilters
        command={command}
        open={commandBarFilters.isOpen}
        onClose={onCloseCommand}
        type={type}
      />
      <SaveFilterDialog
        isOpen={saveFilterDialog.isOpen}
        onClose={saveFilterDialog.close}
        onSubmit={handleSaveCustomFilter}
      />
      <ConfirmDialog
        open={confirmUpdateDialog.isOpen}
        onCancel={confirmUpdateDialog.close}
        onConfirm={updateCustomFilterMutation.mutate}
        loading={updateCustomFilterMutation.isLoading}
        title={t('filters.update.dialog.title')}>
        {t('filters.update.dialog.description', {
          name: customFilterToUpdate?.name || '',
        })}
      </ConfirmDialog>
    </AnimatePresence>
  );
}
