import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  color: 'dark' | 'light';
};

type Colors = State['color'];

const initialState: State = { color: 'dark' };

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    themeCleared: (state) => {
      state.color = 'dark';
    },
    themeSet: (state, action: PayloadAction<Colors>) => {
      state.color = action.payload;
    },
  },
});

export const { themeCleared, themeSet } = themeSlice.actions;

export const themeReducer = themeSlice.reducer;
