import { AnyAction, createAction } from '@reduxjs/toolkit';
import { GenReducer as genReducer } from 'src/gen/actions/GenReducers';

type GenReducer = typeof genReducer;
type GenState = ReturnType<GenReducer>;

type Payload = {
  [K in keyof GenState]: any[];
};

/**
 * Allows upsert many different entities in a single action.
 * ie: Projects, Tests, Users, etc...
 */
export const upsertEntitiesAction = createAction<Payload>(
  'rooReducer/upsertEntities',
);

/**
 * Allows set many different entities in a single action.
 * ie: Projects, Tests, Users, etc...
 */
export const setEntitiesAction = createAction<Payload>(
  'rooReducer/setEntities',
);

export function batchSetGenReducer(reducer: GenReducer): GenReducer {
  return (state: GenState | undefined, action: AnyAction): GenState => {
    if (!state) return reducer(undefined, action);

    switch (action.type) {
      case upsertEntitiesAction.type:
        return Object.entries(action.payload).reduce((acu, [entity, arr]) => {
          const upsert = upsertEntity(state[entity], arr as any[]);
          if (upsert) {
            acu[entity] = upsert;
          }
          return acu;
        }, state);

      case setEntitiesAction.type:
        return Object.entries(action.payload).reduce((acu, [entity, arr]) => {
          const upsert = setEntity(state[entity], arr as any[]);
          if (upsert) {
            acu[entity] = upsert;
          }
          return acu;
        }, state);
      default:
        return reducer(state, action);
    }
  };
}

function upsertEntity<S extends GenState[keyof GenState]>(
  entitySlice: S,
  values: any[],
): S | undefined {
  if (!entitySlice) return undefined;

  const entity = {
    ...entitySlice,
    entities: { ...entitySlice.entities },
    ids: [...entitySlice.ids],
  };

  for (const item of values) {
    if (!entity.entities[item.id]) {
      entity.ids.push(item.id);
    }
    entity.entities[item.id] = item;
  }

  return entity;
}

function setEntity<S extends GenState[keyof GenState]>(
  entitySlice: S,
  values: any[],
): S | undefined {
  if (!entitySlice) return undefined;

  const entity = {
    ...entitySlice,
    entities: {},
    ids: [],
  };

  for (const item of values) {
    entity.ids.push(item.id);
    entity.entities[item.id] = item;
  }

  return entity;
}
