import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Helmet } from 'react-helmet';
import { useOverlayTriggerState } from 'react-stately';
import { Milestone } from '@testquality/sdk';
import { useHistory } from 'react-router';
import { useTranslation } from 'src/i18n/hooks';
import {
  Content,
  Dropdown,
  Grid,
  IconButton,
  InputDateRange,
  Menu,
  MenuItem,
  notification,
  PanelHeader,
  Spacer,
  Surface,
} from '@bitmodern/bit-ui';
import {
  dateRangeByTimeframe,
  Range,
} from '@bitmodern/bit-ui/InputDateRange/InputDateRange';
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  MoreIcon,
  PlayIcon,
  WatchIcon,
  WatchRemoveIcon,
} from '@bitmodern/bit-ui/icons';
import {
  Defects,
  ProjectEditDialog,
  StatusesChart,
  MilestoneDropdown,
  DeleteProjectDialog,
  CommandBarRunExport,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { selectRunsByRange } from '@bitmodern/redux/state/runs/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { defectsByRangeSelector } from '@bitmodern/redux/state/defects/selectors';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { projectUpdateOneThunk } from 'src/gen/domain/project/projectThunk';
import { watchByProjectIdSelector } from '@bitmodern/redux/state/watch/selectors';
import { milestonesByProjectSelector } from '@bitmodern/redux/state/milestones/selectors';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import { watchProjectThunk } from '@bitmodern/redux/state/watch/thunks';
import vars from 'src/export.scss';
import StaticsRuns from './StaticsRuns';
import styles from './OverviewView.module.scss';
import { filtersByTypeSelector } from 'src/packages/redux/state/filters/selectors';
import { toggleFilterAction } from 'src/packages/redux/state/filters/actions';
import { runResultFetchManyThunk } from 'src/gen/domain/run_result/runResultThunk';
import {
  ExportType,
  prepareDataToExport,
} from 'src/packages/redux/state/exportData/thunks';
import RunListItem from 'src/components/organisms/RunListItem';
import EmptyList from 'src/components/organisms/EmptyList';
interface Filters {
  milestone: null | number;
  range: Range<Date>;
  timeframe?: string;
}

const BASE_FILTER_TYPE = 'overview';

export default function OverviewView() {
  const baseFilters = useAppSelector((state) =>
    filtersByTypeSelector(state, BASE_FILTER_TYPE),
  );

  const timeframeRange = dateRangeByTimeframe(baseFilters.timeframe);
  const startDate = timeframeRange ? timeframeRange[0] : baseFilters.startDate;
  const endDate = timeframeRange ? timeframeRange[1] : baseFilters.endDate;

  const { projectId: projeIdUrl, site } =
    useParams<typeof routes.OVERVIEW.params>();
  const projectId = parseInt(projeIdUrl, 10);
  const [filters, setFilters] = useState<Filters>({
    milestone: null,
    range:
      startDate && endDate
        ? [new Date(startDate), new Date(endDate)]
        : InputDateRange.defaultTimeranges[2].value,
    timeframe: baseFilters.timeframe,
  });

  const projectWatch = useAppSelector((state) =>
    watchByProjectIdSelector(state, projectId),
  );
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const milestones = useAppSelector((state) =>
    milestonesByProjectSelector(state, projectId),
  );
  const runs = useAppSelector((state) =>
    selectRunsByRange(state, {
      projectId,
      start: filters.range?.[0],
      end: filters.range?.[1],
      milestoneId: filters.milestone ? filters.milestone : undefined,
    }),
  );
  const defects = useAppSelector((state) =>
    defectsByRangeSelector(state, projectId, {
      start: filters.range?.[0],
      end: filters.range?.[1],
      milestoneId: filters.milestone ? filters.milestone : undefined,
    }),
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const history = useHistory();
  const commandBar = useOverlayTriggerState({});
  const deleteProject = useOverlayTriggerState({});
  const editProject = useOverlayTriggerState({});

  const dispatchRange = (rangeValue, timeframe) => {
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'startDate',
        value: rangeValue[0].toISOString(),
      }),
    );
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'endDate',
        value: rangeValue[1].toISOString(),
      }),
    );
    dispatch(
      toggleFilterAction({
        type: BASE_FILTER_TYPE,
        filter: 'timeframe',
        value: timeframe,
      }),
    );
  };

  const exportData = async (exportType) => {
    await dispatch(
      runResultFetchManyThunk({
        params: {
          _with: 'runResultStep',
          per_page: -1,
          // @ts-expect-error not defined in typescript
          'run_id-in': runs.map((r) => r.id).join(','),
        },
      }),
    );

    dispatch(prepareDataToExport(runs, exportType, site));
    commandBar.close();
  };

  const onProjectDeleted = () => {
    history.push(routes.HOME({ site }));
  };

  const onChangeDateRange = (value, timeframe) => {
    setFilters({
      milestone: null,
      range: value,
      timeframe,
    });
    dispatchRange(value, timeframe);
  };

  const onChangeMilestone = (milestone: Milestone | null) => {
    setFilters({
      milestone: milestone?.id || null,
      range: milestone ? null : InputDateRange.defaultTimeranges[2].value,
    });
  };

  const onSubmitEdit = async (values) => {
    await dispatch(
      projectUpdateOneThunk({
        id: projectId,
        data: {
          color: values.color,
          description: values.description,
          picture: values.icon,
          name: values.name,
        },
      }),
    );

    editProject.close();
  };

  const onWatchProject = () => {
    dispatch(watchProjectThunk(projectId)).then(() =>
      notification.open({
        type: 'success',
        message: t('notifications.watchAdded.message'),
        description: t('notifications.watchAdded.description'),
      }),
    );
  };

  const onUnwatchProject = () => {
    dispatch(watchDeleteOneThunk({ id: projectWatch?.id }))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notifications.watchRemoved.message'),
          description: t('notifications.watchRemoved.description'),
        }),
      );
  };

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          {projectWatch ? (
            <MenuItem
              startAdornment={
                <WatchRemoveIcon color={vars.textPrimary} size={18} />
              }
              onClick={onUnwatchProject}>
              {t('overview.actions.unwatchProject')}
            </MenuItem>
          ) : (
            <MenuItem
              startAdornment={<WatchIcon color={vars.textPrimary} size={18} />}
              onClick={onWatchProject}>
              {t('overview.actions.watchProject')}
            </MenuItem>
          )}
          <MenuItem
            startAdornment={<DownloadIcon color={vars.textPrimary} size={18} />}
            onClick={commandBar.open}>
            {t('overview.actions.export')}
          </MenuItem>
          <MenuItem
            startAdornment={<EditIcon color={vars.textPrimary} size={18} />}
            onClick={editProject.open}>
            {t('overview.actions.editProject')}
          </MenuItem>
          <MenuItem
            startAdornment={<DeleteIcon color={vars.textPrimary} size={18} />}
            onClick={deleteProject.open}>
            {t('overview.actions.deleteProject')}
          </MenuItem>
        </Menu>
      }>
      <IconButton title={t('overview.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  return (
    <>
      <Helmet>
        <title>{t('overview.head.title')}</title>
      </Helmet>
      <Content fullHeight>
        <Surface>
          <PanelHeader
            title={project?.name}
            actions={
              <Spacer>
                <MilestoneDropdown
                  selectedMilestone={filters.milestone}
                  milestones={milestones}
                  onChangeFilters={onChangeMilestone}
                  selected={Boolean(filters.milestone)}
                />
                <InputDateRange
                  className={styles.range}
                  onChange={onChangeDateRange}
                  value={filters.range}
                  selected={Boolean(filters.range)}
                />
                {more}
              </Spacer>
            }
          />
          <Grid.Row gutter={0} className={styles.rowContainer}>
            <Grid.Col span={12} md={5} className={styles.column}>
              <StaticsRuns runs={runs} />
            </Grid.Col>
            <Grid.Col span={12} md={7}>
              <StatusesChart range={filters.range} runs={runs} />
            </Grid.Col>
          </Grid.Row>
        </Surface>
        <Surface className={styles.runHistory}>
          <PanelHeader title={'Runs'} />
          <div className={styles.runsWrapper}>
            {runs.map((run) => (
              <RunListItem key={run.id} run={run} openInDrawer />
            ))}
            {runs.length === 0 && (
              <EmptyList
                variant="secondary"
                icon={<PlayIcon color={vars.textSecondary} size={64} />}
                title={t('runHistory.empty.title')}
                description={t('runHistory.empty.description')}
              />
            )}
          </div>
        </Surface>
        <Defects defects={defects} />
      </Content>
      {/* Dialogs */}
      <ProjectEditDialog
        projectId={projectId}
        open={editProject.isOpen}
        onClose={editProject.close}
        onSubmit={onSubmitEdit}
      />
      <DeleteProjectDialog
        onClose={deleteProject.close}
        onProjectDeleted={onProjectDeleted}
        isOpen={deleteProject.isOpen}
        projectId={projectId}
      />
      <CommandBarRunExport
        handleCSVExport={() => exportData(ExportType.CSV)}
        handleXLSExport={() => exportData(ExportType.XLS)}
        handleGherkinExport={() => exportData(ExportType.Gherkin)}
        onClose={commandBar.close}
        open={commandBar.isOpen}
      />
    </>
  );
}
