import {
  Attachment,
  Requirement,
  Run,
  SuiteTest,
  Step,
  DataSet,
  Test,
  RunResultStep,
} from '@testquality/sdk';

import { State } from '@bitmodern/redux/store';
import { parseFlakinessStatus, FlakinessEnum } from 'src/enums/FlakinessEnum';

import { processAttachmentPath } from 'src/utils/fileHelper';
import { stepsByTestSelector, stepSelector } from '../steps/selectors';
import { suiteSelector } from '../suites/selectors';
import { planByIdSelector } from '../plans/selectors';
import { casePrioritySelector } from '../casePriorities/selectors';
import { caseTypeSelector } from '../caseTypes/selectors';
import { userSelectorById } from '../users/selectors';
import { statusSelector } from '../statuses/selectors';
import { statusTypeSelector } from '../statusTypes/selectors';
import { attachmentsByRelatedIdSelector } from '../attachments/selectors';
import { defectsByRunResultSelector } from '../defects/selectors';
import { requirementsByTestIdSelector } from '../requirements/selectors';
import { IRunRowData, ITestRowData, RunResultData } from './thunks';
import { runResultSelector } from '../runResults/selectors';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import { dataSetSelector } from '../dataSets/selectors';
import interpolateDataSetRow from 'src/utils/dataSet/interpolateDataSetRow';
import { routes } from 'src/components/Router';
import { labelsSelector } from '../label/selectors';
import {
  labelAssignedBySuiteSelector,
  labelAssignedByTestSelector,
} from '../label_assigned/selectors';

function getFormattedDataSet(dataSet?: DataSet) {
  if (!dataSet) {
    return '';
  }
  return JSON.stringify({
    schema: dataSet.schema,
    data: dataSet.data,
  });
}

function getFormattedAttachments(attachments: Attachment[]) {
  if (attachments.length === 0) {
    return undefined;
  }

  return attachments.map(({ url, original_file_name, attachment_type_id }) => ({
    url: processAttachmentPath(url),
    original_file_name,
    attachment_type_id,
  }));
}

function getFormattedRequirements(requirements: Requirement[]) {
  if (requirements.length === 0) {
    return undefined;
  }

  return requirements.map((requirement) => ({
    requirement_name: requirement.payload.summary,
    url: requirement.payload.url,
  }));
}

function getSteps(state: State, testId: number): Step[] {
  const stepsByTest = stepsByTestSelector(state, { testId });
  const stepFallback = {
    step: '',
    expected_result: '',
    sequence: 1,
  } as Step;

  return stepsByTest.length ? stepsByTest : [stepFallback];
}

export function testDataToExportSelector(
  state: State,
  suiteTest: SuiteTest[],
): ITestRowData[] {
  return suiteTest.flatMap((st) => {
    const suite = suiteSelector(state, st.suite_id);
    const test = testSelectors.selectById(state, st.test_id);
    const steps = getSteps(state, st.test_id);

    if (suite && test) {
      const casePriority = casePrioritySelector(state, test.case_priority_id);
      const dataSet = dataSetSelector(state, { dataSetId: test.data_set_id });
      const caseType = caseTypeSelector(state, test.case_type_id);
      const assigned = userSelectorById(state, test.assigned_to_tester || 0);
      const attachments = attachmentsByRelatedIdSelector(state, st.test_id);
      const requirements = requirementsByTestIdSelector(state, st.test_id);
      return steps.map((step) => ({
        folder_key: suite.key.toString(),
        folder_name: suite.name,
        test_key: test.key.toString(),
        test_name: test.name,
        test_type: caseType?.name,
        test_priority: casePriority?.name,
        test_precondition: test.precondition,
        test_assigned_to: assigned?.given_name || assigned?.email || '',
        test_is_automated: test.is_automated ? '1' : '0',
        test_estimate: test.estimate?.toString(),
        test_attachments: getFormattedAttachments(attachments),
        test_requirements: getFormattedRequirements(requirements),
        step_description: step.step,
        step_expected_result: step.expected_result,
        step_sequence: step.sequence.toString(),
        data_set: getFormattedDataSet(dataSet),
      }));
    }
    return [];
  });
}

export function runDataToExportSelector(
  state: State,
  run: Run,
  runResultsData: RunResultData[],
): IRunRowData[] {
  const ret = runResultsData.flatMap(({ test, runResultSteps }) => {
    return runResultSteps.reduce<IRunRowData[]>((acu, rrStep) => {
      const runResult = runResultSelector(state, rrStep.run_result_id);
      if (!runResult) {
        return acu;
      }
      const suite = suiteSelector(state, runResult.suite_id);
      const plan = planByIdSelector(state, run.plan_id);
      if (!suite || !test || !plan) {
        return acu;
      }
      const casePriority = casePrioritySelector(state, test.case_priority_id);
      const caseType = caseTypeSelector(state, test.case_type_id);
      const assigned = userSelectorById(
        state,
        runResult.assigned_to_tester || 0,
      );
      const createdBy = userSelectorById(state, runResult.created_by);
      const updatedBy = userSelectorById(state, runResult.updated_by);
      const status = statusSelector(state, runResult.status_id);
      const statusType = statusTypeSelector(state, status?.status_type_id || 0);
      const attachments = attachmentsByRelatedIdSelector(state, runResult.id);
      const defects = defectsByRunResultSelector(state, runResult.id).map(
        (defect) => ({
          external_key: defect.external_reference_id,
          summary: defect.payload.summary,
          url: defect.payload.url,
        }),
      );

      const step = stepSelector(state, rrStep.step_id);
      const rrStatus = statusSelector(state, rrStep.status_id || 0);

      const dataSet = dataSetSelector(state, { dataSetId: test.data_set_id });

      const requirements = requirementsByTestIdSelector(state, test.id).map(
        (requirement) => ({
          external_key: requirement.external_reference_id,
          summary: requirement.payload.summary,
          url: requirement.payload.url,
        }),
      );

      const stepDescription = interpolateDataSetRow(
        step?.step,
        dataSet,
        runResult.data_set_row,
      );

      const expectedResult = interpolateDataSetRow(
        step?.expected_result,
        dataSet,
        runResult.data_set_row,
      );

      acu.push({
        run_name: run.name,
        cycle_name: plan.is_root ? undefined : plan.name,
        folder_name: suite.name,
        test_key: test.key.toString(),
        test_name: test.name,
        test_type: caseType?.name,
        test_priority: casePriority?.name,
        test_is_automated: test.is_automated ? '1' : '0',
        test_precondition: test.precondition,
        test_estimate: test.estimate?.toString(),
        test_attachments: getFormattedAttachments(attachments),
        run_result_assigned_to_tester: assigned?.given_name || assigned?.email,
        run_result_status: status?.name,
        run_result_created_by: createdBy?.given_name || createdBy?.email,
        run_result_created_at: runResult.created_at,
        run_result_updated_by: updatedBy?.given_name || updatedBy?.email,
        run_result_updated_at: runResult.updated_at,
        run_result_status_type: statusType?.name,
        run_result_flakiness: parseFlakinessStatus(
          runResult.flakiness as FlakinessEnum,
        ),
        run_result_attachments: attachments.length
          ? JSON.stringify(attachments)
          : undefined,
        run_result_defects: defects.length
          ? JSON.stringify(defects)
          : undefined,
        step_description: stepDescription,
        step_expected_result: expectedResult,
        run_result_step_status: rrStatus?.name,
        run_result_step_elapsed_time: rrStep.elapsed?.toString(),
        run_result_stories: requirements.length
          ? JSON.stringify(requirements)
          : undefined,
        data_set: getFormattedDataSet(dataSet),
      });

      return acu;
    }, []);
  });

  return ret;
}

export function runDataToGherkinSelector(
  state: State,
  {
    run,
    runResultsData,
    siteId,
  }: {
    run: Run;
    runResultsData: RunResultData[];
    siteId: string;
  },
) {
  const labels = labelsSelector(state);
  const labelsById = labels.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const gherkinFeatures = runResultsData.reduce(
    (acc, { suiteTest, test, runResultSteps }) => {
      const background: Record<string, any> | null = extractBackground(test);
      const suite = suiteSelector(state, suiteTest.suite_id);
      if (!suite) {
        return acc;
      }
      const gherkinSteps = runResultSteps.reduce<any[]>(
        (acu, rrStep) =>
          extractGherkinStep(acu, rrStep, run, test.data_set_id, state),
        [],
      );

      const testLabels = labelAssignedByTestSelector(state, {
        testId: test.id,
      });
      const testLabelsNames = testLabels.map(
        (item) => labelsById[item.label_id].label,
      );

      const gherkinScenario = {
        description: '',
        id: `${test.id}`,
        keyword: 'Scenario',
        line: '',
        name: test.name
          .replace('Scenario Outline: ', '')
          .replace('Scenario Outline', '')
          .replace('Scenario: ', '')
          .replace('Scenario', ''),
        type: 'scenario',
        tags: testLabelsNames,
        steps: gherkinSteps,
      };

      const elements = background
        ? [background, gherkinScenario]
        : [gherkinScenario];

      if (acc[suite.id]) {
        return {
          ...acc,
          [suite.id]: {
            ...acc[suite.id],
            elements: [...acc[suite.id].elements, ...elements],
          },
        };
      } else {
        const featureLabels = labelAssignedBySuiteSelector(state, {
          suiteId: suite.id,
        });
        const featureLabelsNames = featureLabels.map(
          (item) => labelsById[item.label_id].label,
        );

        return {
          ...acc,
          [suite.id]: {
            description: '',
            id: suite.id,
            keyword: 'Feature',
            line: '',
            name: suite.name.replace('Feature: ', '').replace('Feature ', ''),
            uri: routes.RUN({
              site: siteId,
              projectId: `${run.project_id}`,
              runId: `${run.id}`,
            }),
            tags: featureLabelsNames,
            elements,
          },
        };
      }
    },
    {},
  );
  return Object.values(gherkinFeatures);
}

// function that extract one of the following keywords: Given, When, Then, And, But from the text and returns a tuple with the keyword and the text without the keyword
function extractGherkinKeyWord(text: string) {
  const keywords = ['Given', 'When', 'Then', 'And', 'But'];
  const keyword = keywords.find((keyword) => text.startsWith(keyword));
  if (!keyword) {
    return [null, text];
  }
  return [keyword, text.slice(keyword.length).trim()];
}

function extractBackground(test: Test) {
  let background: Record<string, any> | null = null;
  if (test.precondition) {
    // test.precondition = Background: \nGiven a global administrator named \"Greg\"\nAnd a blog named \"Greg's anti-tax rants\"\nAnd a customer named \"Dr. Bill\"\nAnd a blog named \"Expensive Therapy\" owned by \"Dr. Bill\"\n\nGiven I last used the app yesterday\nAnd I have logged out\n
    // Remove Background string from string
    // split on \n
    const precondition = test.precondition.replace('Background: ', '');
    const preconditionLines = precondition.split('\n');
    // Remove empty lines
    const preconditionLinesFiltered = preconditionLines.filter(
      (pL) => pL.trim() !== '',
    );
    const steps = preconditionLinesFiltered.map((pL) => {
      const [keyword, text] = extractGherkinKeyWord(pL);
      return {
        keyword: keyword ?? 'Given',
        line: '',
        name: text,
      };
    });
    background = {
      description: '',
      keyword: 'Background',
      line: '',
      name: '',
      steps,
      type: 'background',
    };
  }
  return background;
}

function extractGherkinStep(
  acu: any[],
  rrStep: RunResultStep,
  run: Run,
  dataSetId: number | undefined,
  state: State,
) {
  const runResult = runResultSelector(state, rrStep.run_result_id);
  if (!runResult) {
    return acu;
  }
  const suite = suiteSelector(state, runResult.suite_id);
  const plan = planByIdSelector(state, run.plan_id);
  if (!suite || !plan) {
    return acu;
  }
  const status = statusSelector(state, runResult.status_id);

  const step = stepSelector(state, rrStep.step_id);
  if (!step) {
    return acu;
  }

  const dataSet = dataSetSelector(state, { dataSetId });

  const stepDescription =
    dataSet && step?.step
      ? interpolateDataSetRow(step.step, dataSet, runResult.data_set_row)
      : step?.step;

  const expectedResult =
    dataSet && step?.expected_result
      ? interpolateDataSetRow(
          step.expected_result,
          dataSet,
          runResult.data_set_row,
        )
      : step?.expected_result;

  const [whenKeyword, stepStepText] = extractGherkinKeyWord(
    stepDescription ?? '',
  );
  const when = {
    keyword: whenKeyword ?? 'When ',
    line: '',
    name: stepStepText,
  };

  const [thenKeyword, thenStepText] = extractGherkinKeyWord(
    expectedResult ?? '',
  );
  const then = {
    keyword: thenKeyword ?? 'Then ',
    line: '',
    name: thenStepText,
    result: {
      status: status?.name,
    },
  };

  return [...acu, ...[when, then]];
}
