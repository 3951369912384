/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { RequirementHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { requirementHistoryAdapter } from './requirementHistoryAdapter';
import { requirementHistoryFetchThunk } from './requirementHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const requirementHistorySlice = createSlice({
  name: 'requirementHistory',
  initialState: requirementHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    requirementHistoryCleared(state) {
      state.loading = 'idle';
      requirementHistoryAdapter.removeAll(state);
    },
    requirementHistoryUpsertMany(
      state,
      action: PayloadAction<
        RequirementHistory[] | Record<number, RequirementHistory>
      >,
    ) {
      requirementHistoryAdapter.upsertMany(state, action.payload);
    },
    requirementHistoryUpsertOne(
      state,
      action: PayloadAction<RequirementHistory>,
    ) {
      requirementHistoryAdapter.upsertOne(state, action.payload);
    },
    requirementHistoryUpdateOne(
      state,
      action: PayloadAction<Update<RequirementHistory>>,
    ) {
      requirementHistoryAdapter.updateOne(state, action.payload);
    },
    requirementHistoryRemoveOne(state, action: PayloadAction<number>) {
      requirementHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requirementHistoryFetchThunk.pending, handlePending);
    builder.addCase(requirementHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementHistoryAdapter.upsertMany(
        state,
        action.payload.requirementHistory,
      );
    });
    builder.addCase(requirementHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  requirementHistoryCleared,
  requirementHistoryUpsertMany,
  requirementHistoryUpsertOne,
  requirementHistoryUpdateOne,
  requirementHistoryRemoveOne,
} = requirementHistorySlice.actions;

export const requirementHistoryReducer = requirementHistorySlice.reducer;
