/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { virtualAdapter } from './virtualAdapter';

export const virtualSelectors = virtualAdapter.getSelectors(
  (state: State) => state.gen.virtual,
);
