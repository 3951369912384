import React from 'react';
import classnames from 'classnames';
import { TestQuality } from '@testquality/sdk';
import { Tooltip } from '@bitmodern/bit-ui';
import { DoneIcon, PendingIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector, useAppDispatch } from '@bitmodern/redux/store';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import styles from './TestQualityBreakdownList.module.scss';

type Props = {
  testQuality: TestQuality;
  testQualityAmount: number;
  isGoodQuality: boolean;
};

export default function TestQualityBreakdownListItem({
  testQuality,
  testQualityAmount,
  isGoodQuality,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { testQuality: testQualityFilters } = useAppSelector((state) =>
    filtersByTypeSelector(state, 'analyzeTestQuality'),
  );

  const iconCN = classnames(styles.icon, {
    [styles.goodIcon]: isGoodQuality,
    [styles.warningIcon]: !isGoodQuality,
  });

  const itemCN = classnames(styles.item, {
    [styles.selected]: testQualityFilters?.[testQuality.id],
  });

  const setTQFilter = () => {
    dispatch(
      toggleFilterAction({
        type: 'analyzeTestQuality',
        filter: 'testQuality',
        value: testQuality.id,
      }),
    );
  };

  return (
    <Tooltip enterDelay={200} tooltip={testQuality.description}>
      <div onClick={setTQFilter} className={itemCN}>
        {isGoodQuality ? (
          <DoneIcon className={iconCN} size={20} />
        ) : (
          <PendingIcon className={iconCN} size={20} />
        )}
        <div>
          <p className={styles.name}>{testQuality.name}</p>
          <p className={styles.amount}>
            {t('analyze.testQuality.breakdown.amount', {
              count: testQualityAmount,
            })}
          </p>
        </div>
      </div>
    </Tooltip>
  );
}
