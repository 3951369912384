import { Defect } from '@testquality/sdk';
import useDefectForm from './useDefectOverview';

export type FormArgs = {
  integrationProjectId: number;
  defect: Defect;
};

export default function useDefectFormValues({
  integrationProjectId,
  defect,
}: FormArgs) {
  const { defectPriorities, defectStatuses, defectResolutions } = useDefectForm(
    { integrationProjectId },
  );

  return useGetDefectFormValues(
    defectPriorities,
    defectStatuses,
    defectResolutions,
    defect,
  );
}

function useGetDefectFormValues(
  defectPriorities,
  defectStatuses,
  defectResolutions,
  defect,
) {
  const componentsValues = defect.payload?.components
    ?.map(({ id }) => id)
    .sort();

  const priorityValue = defectPriorities.find(
    ({ name }) => name === defect.payload?.priority?.name,
  )?.id;

  const assignedToId = defect.payload?.assignee?.id;

  const typeValue = defect.payload?.type?.id;

  const statusValue = defectStatuses.find(
    ({ name }) => name === defect.payload?.status?.name,
  )?.id;

  const labelsValues = formatLabels(defect.payload?.labels) || [];

  const resolutionValue = defectResolutions.find(
    ({ name }) => name === defect.payload?.resolution?.name,
  )?.id;

  return {
    priorityValue,
    statusValue,
    resolutionValue,
    assignedToId,
    typeValue,
    labelsValues,
    componentsValues,
  };
}

const formatLabels = (labels) => {
  const normalizedLabels = Array.isArray(labels)
    ? labels
    : Object.values(labels);

  return normalizedLabels.map(({ name }) => name).sort();
};
