/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { RunSuiteApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const runSuiteNormalizer = (
  model: RunSuiteApi,
  cache: NormalizerCache,
) => {
  if (!cache.runSuite) {
    cache.runSuite = [];
  }
  cache.runSuite.push(model);
};
