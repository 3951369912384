import React, { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import {
  Watch,
  BatchService,
  Plan,
  Suite,
  Test,
  Project,
} from '@testquality/sdk';
import {
  IconButton,
  Loading,
  notification,
  Table,
  TableBody,
  TableCell,
  TableEmpty,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { DeleteIcon, WatchIcon } from '@bitmodern/bit-ui/icons';
import { SettingsPanel } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { useTranslation } from 'src/i18n/hooks';
import useFetch from 'src/hooks/useFetch';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { watchSelector } from '@bitmodern/redux/state/watch/selectors';
import {
  getWatchType,
  WatchTypeNewNaming,
  WatchType,
} from 'src/enums/WatchEnum';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import { testFetchManyThunk } from 'src/gen/domain/test/testThunk';
import { planFetchManyThunk } from 'src/gen/domain/plan/planThunk';
import { suiteFetchManyThunk } from 'src/gen/domain/suite/suiteThunk';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { plansSelector } from '@bitmodern/redux/state/plans/selectors';
import { suitesSelector } from '@bitmodern/redux/state/suites/selectors';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import vars from 'src/export.scss';
import styles from './WatchView.module.scss';

const log = new ConsoleLogger('WatchView');

export default function WatchView() {
  const { site } = useParamsInRoute<typeof routes.WATCH.params>(
    routes.WATCH.path,
  );
  const { t } = useTranslation();
  const allWatches = useAppSelector(watchSelector);
  const projects = useAppSelector(projectsSelector);
  const tests = useAppSelector(testSelectors.selectAll);
  const plans = useAppSelector(plansSelector);
  const suites = useAppSelector(suitesSelector);
  const drawer = useDrawerManager();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fetchGlobal = useCallback(() => {
    const neededEntities = allWatches.reduce((acc, watch) => {
      const watchType = getWatchType(watch.related_type);
      if (acc[watchType]) {
        acc[watchType].push(watch.related_id);
      } else {
        acc[watchType] = [watch.related_id];
      }
      return acc;
    }, {});

    const batch = new BatchService();
    if (neededEntities[WatchType.TEST]) {
      dispatch(
        testFetchManyThunk({
          batch,
          // @ts-expect-error
          params: { 'id-in': neededEntities[WatchType.TEST].join(',') },
        }),
      );
    }
    if (neededEntities[WatchType.PLAN]) {
      dispatch(
        planFetchManyThunk({
          batch,
          // @ts-expect-error
          params: { 'id-in': neededEntities[WatchType.PLAN].join(',') },
        }),
      );
    }
    if (neededEntities[WatchType.SUITE]) {
      dispatch(
        suiteFetchManyThunk({
          batch,
          // @ts-expect-error
          params: { 'id-in': neededEntities[WatchType.SUITE].join(',') },
        }),
      );
    }

    return batch
      .executeBatch()
      .catch((error) => log.warn('batch failed', error));
  }, [dispatch, allWatches]);

  const watchesFetch = useFetch(fetchGlobal, 'watches', {
    fetchOnce: true,
  });

  function getRelatedEntity(type, id) {
    switch (type) {
      case WatchType.SUITE:
        return suites.find((e) => e.id === id);
      case WatchType.PLAN:
        return plans.find((e) => e.id === id);
      case WatchType.PROJECT:
        return projects.find((e) => e.id === id);
      default:
        return tests.find((e) => e.id === id);
    }
  }

  function getPushOptions(entity: Plan | Suite | Test | Project) {
    let pathname;
    let search;

    switch (entity.metadata_model) {
      case WatchType.PLAN:
        pathname = routes.PLAN({
          site,
          projectId: (entity as Plan).project_id.toString(),
          planId: (entity as Plan).id.toString(),
        });
        break;
      case WatchType.SUITE:
        pathname = routes.PROJECT_TESTS({
          site,
          projectId: (entity as Suite).project_id.toString(),
        });
        break;
      case WatchType.PROJECT:
        pathname = routes.PROJECT({
          site,
          projectId: (entity as Project).id.toString(),
        });
        break;
      default:
        pathname = routes.PROJECT_TESTS({
          site,
          projectId: (entity as Test).project_id.toString(),
        });
        search = drawer.generateDrawerUrlParams({
          testId: (entity as Test).id,
        });
        break;
    }

    return {
      pathname,
      search,
    };
  }

  const renderWatch = (watch: Watch) => {
    const watchType = getWatchType(watch.related_type);
    const relatedEntity = getRelatedEntity(watchType, watch.related_id);
    if (!relatedEntity) return null;

    const watchTypeNewNaming = WatchTypeNewNaming.get(watchType);
    const name =
      relatedEntity.metadata_model === WatchType.TEST
        ? `${relatedEntity.name} (TC${relatedEntity.key})`
        : relatedEntity.name;
    const onClickWatch = () => {
      history.push(getPushOptions(relatedEntity));
    };
    const onDelete = () => {
      dispatch(watchDeleteOneThunk({ id: watch.id }))
        .then(unwrapResult)
        .then(() =>
          notification.open({
            type: 'success',
            message: t('notifications.watchRemoved.message'),
            description: t('notifications.watchRemoved.description'),
          }),
        );
    };
    const relatedProject =
      watchType !== WatchType.PROJECT &&
      projects.find(
        (e) => e.id === (relatedEntity as Plan | Suite | Test).project_id,
      );

    return (
      <TableRow key={watch.id} onClick={() => onClickWatch()}>
        <TableCell className={styles.cell}>{name}</TableCell>
        <TableCell className={styles.cell}>{watchTypeNewNaming}</TableCell>
        <TableCell className={styles.cell}>
          {relatedProject && relatedProject.name}
        </TableCell>
        <TableCell className={styles.cell}>
          {t('watches.ago', { date: new Date(watch.created_at) })}
        </TableCell>
        <TableCell className={`${styles.cell} ${styles.actions}`}>
          <IconButton
            onClick={onDelete}
            title={t('watches.delete')}
            size="small">
            <DeleteIcon color={vars.textPrimary} size={16} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <SettingsPanel
      icon={<WatchIcon color={vars.textPrimary} size={20} />}
      paddingLess
      title={t('watches.title')}>
      <Table>
        <TableHead>
          <TableCell>{t('watches.name')}</TableCell>
          <TableCell>{t('watches.type')}</TableCell>
          <TableCell>{t('watches.project')}</TableCell>
          <TableCell colSpan={2}>{t('watches.creationTime')}</TableCell>
        </TableHead>
        {watchesFetch.isLoading && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}>
                <Loading className={styles.loading} size={48} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}

        {!watchesFetch.isLoading && allWatches.length > 0 && (
          <TableBody>{allWatches.map(renderWatch)}</TableBody>
        )}

        {!watchesFetch.isLoading && allWatches.length === 0 && (
          <TableEmpty
            colSpan={5}
            description={t('watches.empty.description')}
            icon={<WatchIcon color={vars.textSecondary} size={64} />}
            title={t('watches.empty.title')}
          />
        )}
      </Table>
    </SettingsPanel>
  );
}
