import React, { useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useFormik, FormikHelpers } from 'formik';
import Yup from 'src/utils/yup';
import {
  Step,
  Attachment,
  PlanSuite,
  TestApi,
  SuiteTest,
} from '@testquality/sdk';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import { routes } from 'src/components/Router';
import {
  Schemas as VirtualSchemas,
  requiredVirtualsByTableNameSelector,
  virtualsByTableNameSelector,
} from '@bitmodern/redux/state/virtuals/selectors';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { suitesByRootPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import isEmpty from 'lodash/isEmpty';

import { VirtualSchemaType } from 'src/enums/VirtualSchemaTypeEmum';
import { notification } from '@bitmodern/bit-ui';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { createTestThunk } from '@bitmodern/redux/state/tests/thunks';
import TestCreateView from './TestCreateView';
import { planSuiteTestIncludeCreateOneThunk } from '../../../gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { notificationErrorTimeout } from '../../../constants';
import { requirementTestCreateOneThunk } from 'src/gen/domain/requirement_test/requirementTestThunk';

export function buildRequiredVirtualsValidationSchema(
  requiredVirtuals: VirtualSchemas,
) {
  const virtualsValidation = {};

  Object.keys(requiredVirtuals).forEach((key) => {
    const virtual = requiredVirtuals[key];
    if (virtual.type === VirtualSchemaType.String) {
      virtualsValidation[key] = Yup.string().required();
    } else if (virtual.type === VirtualSchemaType.Boolean) {
      virtualsValidation[key] = Yup.boolean().required();
    } else if (virtual.type === VirtualSchemaType.Number) {
      virtualsValidation[key] = Yup.number().required();
    } else if (virtual.type === VirtualSchemaType.Date) {
      virtualsValidation[key] = Yup.date().required();
    }
  });

  return virtualsValidation;
}

export function buildEmptyVirtuals(virtuals: VirtualSchemas) {
  return Object.keys(virtuals).reduce((acu, key) => {
    acu[key] = virtuals[key].type === VirtualSchemaType.Boolean ? false : null;
    return acu;
  }, {});
}

export function buildRequiredVirtualsInitialValues(virtuals: VirtualSchemas) {
  return Object.keys(virtuals).reduce((acu, key) => {
    if (virtuals[key].type === VirtualSchemaType.Boolean) acu[key] = false;
    if (virtuals[key].type === VirtualSchemaType.String) acu[key] = '';
    if (virtuals[key].type === VirtualSchemaType.Number) acu[key] = 0;
    if (virtuals[key].type === VirtualSchemaType.Date) acu[key] = null;
    return acu;
  }, {});
}

type InitialValues = {
  name: string;
  precondition: string;
  steps: Step[];
  suites: number[];
  attachments: Attachment[];
  createAnother: boolean;
  [key: string]: any;
};

type Props = {
  planSuite?: PlanSuite;
  suiteTest?: SuiteTest;
  planId?: number; // to include in that plan
  requirementId?: number; // to include in a requirement
};

export default function TestCreateViewContainer({
  planSuite,
  suiteTest,
  planId,
  requirementId,
}: Props) {
  const formRef = useRef<any>(null);
  const history = useHistory();
  const { generateDrawerUrlParams } = useDrawerManager();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  const suites = useAppSelector((state) =>
    suitesByRootPlanSelector(state, parseInt(projectId, 10)),
  );
  const caseTypes = useAppSelector(caseTypesSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const virtuals = useAppSelector((state) =>
    virtualsByTableNameSelector(state, 'test'),
  );
  const requiredVirtuals = useAppSelector((state) =>
    requiredVirtualsByTableNameSelector(state, 'test'),
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required(),
      suites: Yup.array<number>().optional(),
      attachments: Yup.array<Attachment>().optional(),
      ...buildRequiredVirtualsValidationSchema(requiredVirtuals),
    });
  }, [requiredVirtuals]);

  const under = planSuite?.suite_id || suiteTest?.suite_id;

  const initialValues = useMemo<InitialValues>(
    () => ({
      name: '',
      precondition: '',
      steps: [],
      suites: under ? [under] : [],
      attachments: [],
      createAnother: false,
      ...(!isEmpty(requiredVirtuals) && {
        ...buildRequiredVirtualsInitialValues(requiredVirtuals),
      }),
    }),
    [under, requiredVirtuals],
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  function onSubmit(
    {
      name,
      precondition,
      suites: suitesField,
      steps,
      createAnother,
      attachments,
      ...rest
    }: InitialValues,
    actions: FormikHelpers<InitialValues>,
  ) {
    const suiteId = suitesField[0] || suites.find((s) => s.is_root)?.id;

    if (!suiteId) {
      notification.open({
        type: 'error',
        message: t('testCreate.notifications.noDefaultSuiteFound.message'),
        duration: notificationErrorTimeout,
      });
      return Promise.resolve();
    }

    const caseTypeDefault = caseTypes.find(
      (caseType) => caseType.name === 'Functionality',
    );
    const casePriorityDefault = casePriorities.find(
      (casePriority) => casePriority.name === 'Medium',
    );

    if (!caseTypeDefault || !casePriorityDefault) {
      notification.open({
        type: 'error',
        message: t('testCreate.notifications.noDefaultsFound.message'),
        duration: notificationErrorTimeout,
      });
      return Promise.resolve();
    }

    const data: Partial<TestApi> = {
      name,
      precondition,
      project_id: parseInt(projectId, 10),
      case_type_id: caseTypeDefault.id,
      case_priority_id: casePriorityDefault.id,
      is_automated: false,
      suite_id: suiteId,
    };

    if (!isEmpty(virtuals)) {
      const emptyVirtuals = buildEmptyVirtuals(virtuals);

      data.virtual = { ...emptyVirtuals, ...rest };
    }

    return dispatch(
      createTestThunk({
        attachments,
        steps,
        suitesIds: suitesField,
        test: data,
        under: { planSuite, suiteTest },
      }),
    ).then(async (res) => {
      const test = res?.test;
      const folder = res?.folder;
      if (test) {
        if (planId) {
          await dispatch(
            planSuiteTestIncludeCreateOneThunk({
              data: {
                project_id: test.project_id,
                plan_id: planId,
                suite_id: suiteId,
                test_id: test.id,
              },
            }),
          );
        }
        if (requirementId) {
          await dispatch(
            requirementTestCreateOneThunk({
              data: {
                test_id: test.id,
                requirement_id: requirementId,
                suite_id: suiteId,
              },
            }),
          );
        }
        if (createAnother) {
          actions.resetForm({
            values: {
              ...initialValues,
              suites: suitesField,
              precondition,
              createAnother,
            },
          });
          if (formRef.current) {
            formRef.current.name.focus();
          }

          notification.open({
            type: 'success',
            message: t(
              'testCreate.notifications.testSuccessfullyCreated.message',
            ),
          });
        } else {
          history.push({
            search: generateDrawerUrlParams({
              folderId: folder?.id || 0,
              testId: test.id,
            }),
          });
        }
      }
    });
  }

  return (
    <TestCreateView
      formik={formik}
      suites={suites}
      ref={formRef}
      virtuals={requiredVirtuals}
    />
  );
}
