/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { RunResultHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runResultHistoryAdapter } from './runResultHistoryAdapter';
import { runResultHistoryFetchThunk } from './runResultHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runResultHistorySlice = createSlice({
  name: 'runResultHistory',
  initialState: runResultHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runResultHistoryCleared(state) {
      state.loading = 'idle';
      runResultHistoryAdapter.removeAll(state);
    },
    runResultHistoryUpsertMany(
      state,
      action: PayloadAction<
        RunResultHistory[] | Record<number, RunResultHistory>
      >,
    ) {
      runResultHistoryAdapter.upsertMany(state, action.payload);
    },
    runResultHistoryUpsertOne(state, action: PayloadAction<RunResultHistory>) {
      runResultHistoryAdapter.upsertOne(state, action.payload);
    },
    runResultHistoryUpdateOne(
      state,
      action: PayloadAction<Update<RunResultHistory>>,
    ) {
      runResultHistoryAdapter.updateOne(state, action.payload);
    },
    runResultHistoryRemoveOne(state, action: PayloadAction<number>) {
      runResultHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runResultHistoryFetchThunk.pending, handlePending);
    builder.addCase(runResultHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultHistoryAdapter.upsertMany(
        state,
        action.payload.runResultHistory,
      );
    });
    builder.addCase(runResultHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  runResultHistoryCleared,
  runResultHistoryUpsertMany,
  runResultHistoryUpsertOne,
  runResultHistoryUpdateOne,
  runResultHistoryRemoveOne,
} = runResultHistorySlice.actions;

export const runResultHistoryReducer = runResultHistorySlice.reducer;
