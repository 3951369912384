import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <g id="Group-14">
      <g
        width="24"
        height="24"
        id="bookmark-solid"
        transform="translate(6.000000, 4.000000)"
        fillRule="nonzero">
        <path
          d="M1.5,0 L10.5,0 C11.328125,0 12,0.6715625 12,1.5 L12,15.240625 C12,15.659375 11.659375,16 11.240625,16 C11.084375,16 10.93125,15.953125 10.803125,15.8625 L6,12.5 L1.19625,15.8625 C1.0684375,15.953125 0.91625,16 0.7603125,16 C0.3403125,16 0,15.659375 0,15.240625 L0,1.5 C0,0.6715625 0.6715625,0 1.5,0 Z"
          id="Path"
        />
      </g>
    </g>
  </>,
  'Bookmark',
);
