/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectLabel } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectLabelAdapter } from './defectLabelAdapter';
import { defectLabelFetchManyThunk } from './defectLabelThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectLabelSlice = createSlice({
  name: 'defectLabel',
  initialState: defectLabelAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectLabelCleared(state) {
      state.loading = 'idle';
      defectLabelAdapter.removeAll(state);
    },
    defectLabelUpsertMany(
      state,
      action: PayloadAction<DefectLabel[] | Record<number, DefectLabel>>,
    ) {
      defectLabelAdapter.upsertMany(state, action.payload);
    },
    defectLabelUpsertOne(state, action: PayloadAction<DefectLabel>) {
      defectLabelAdapter.upsertOne(state, action.payload);
    },
    defectLabelUpdateOne(state, action: PayloadAction<Update<DefectLabel>>) {
      defectLabelAdapter.updateOne(state, action.payload);
    },
    defectLabelRemoveOne(state, action: PayloadAction<number>) {
      defectLabelAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectLabelFetchManyThunk.pending, handlePending);
    builder.addCase(defectLabelFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectLabelAdapter.upsertMany(state, action.payload.defectLabel);
    });
    builder.addCase(defectLabelFetchManyThunk.rejected, handleRejected);
  },
});

export const {
  defectLabelCleared,
  defectLabelUpsertMany,
  defectLabelUpsertOne,
  defectLabelUpdateOne,
  defectLabelRemoveOne,
} = defectLabelSlice.actions;

export const defectLabelReducer = defectLabelSlice.reducer;
