import React, { CSSProperties } from 'react';
import styles from './Avatar.module.scss';

type Props = {
  className?: string;
  name?: string;
  size?: number;
  src?: string;
  style?: CSSProperties;
};

export default function Avatar({
  className = '',
  size = 32,
  name = '',
  src,
  style,
}: Props) {
  const letter = (name || '').charAt(0).toUpperCase();

  return (
    <span
      className={`${styles.avatar} ${className}`}
      style={{
        fontSize: size * 0.7,
        height: size,
        width: size,
        ...style,
      }}>
      <span
        className={styles.image}
        style={{
          borderRadius: '50%',
          backgroundImage: src ? `url(${src})` : undefined,
        }}
      />
      {letter}
    </span>
  );
}
