import React, { useMemo } from 'react';
import {
  motion,
  AnimateSharedLayout,
  AnimatePresence,
  MotionConfig,
} from 'framer-motion';
import { usePress } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import ImageFullscreen from '../ImageFullscreen';
import styles from './Image.module.scss';

const LAYOUT_ID = 'imageFullscreen';

type Props = {
  height?: string;
  src: string;
  width?: string;
};

export default function Image({ src, height, width, ...other }: Props) {
  const fullscreen = useOverlayTriggerState({});
  const imagePress = usePress({ onPress: fullscreen.open });

  const heightUnit = useMemo(() => processUnit(height), [height]);
  const widthUnit = useMemo(() => processUnit(width), [width]);

  return (
    <MotionConfig transition={{ duration: 0.25 }}>
      <AnimateSharedLayout>
        <motion.img
          {...(imagePress.pressProps as any)}
          className={styles.image}
          layoutId={LAYOUT_ID}
          src={src}
          style={{ height: heightUnit, width: widthUnit }}
        />
        <AnimatePresence>
          {fullscreen.isOpen && (
            <ImageFullscreen
              layoutId={LAYOUT_ID}
              onClose={fullscreen.close}
              src={src}
            />
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </MotionConfig>
  );
}

function processUnit(unit?: string): string | undefined {
  if (!unit) return;
  return /^\d+$/.test(unit) ? `${unit}px` : unit;
}
