import {
  Attachment,
  AttachmentRoute,
  ProjectAttachmentRoute,
  RequirementAttachmentRoute,
  RunResultAttachmentRoute,
  TestAttachmentRoute,
  attachmentGetMany,
} from '@testquality/sdk';
import { getClient } from 'src/Client';

export function getTestAttachmentParams(testId: number) {
  return {
    url: TestAttachmentRoute(testId),
    params: { per_page: -1 },
  };
}

export function getRunResultAttachmentParams(runResultId: number) {
  return {
    url: RunResultAttachmentRoute(runResultId),
    params: { per_page: -1 },
  };
}

export function getTestAttachments(testId: number): Promise<Attachment[]> {
  return attachmentGetMany(getTestAttachmentParams(testId)).then(
    (resourceList) => resourceList.data,
  );
}

export function getRunResultAttachments(
  runResultId: number,
): Promise<Attachment[]> {
  return attachmentGetMany(getRunResultAttachmentParams(runResultId)).then(
    (resourceList) => resourceList.data,
  );
}

export function postTestAttachmentParams(file: File, testId: number) {
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return {
    url: TestAttachmentRoute(testId),
    data: data as any,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
}

export function postRunResultAttachmentParams(file: File, runResultId: number) {
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return {
    url: RunResultAttachmentRoute(runResultId),
    data: data as any,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
}

export type RelatedType =
  | 'Test'
  | 'RunResult'
  | 'Project'
  | 'Integration'
  | 'Requirement';

const getUrl = (relatedType: RelatedType = 'Project', relatedId) => {
  switch (relatedType) {
    case 'Requirement':
      return RequirementAttachmentRoute(relatedId);
    case 'Test':
      return TestAttachmentRoute(relatedId);
    case 'RunResult':
      return RunResultAttachmentRoute(relatedId);
    case 'Project':
      return ProjectAttachmentRoute(relatedId);
    default:
      return ProjectAttachmentRoute(relatedId);
  }
};

export function postAttachment(
  file: File,
  relatedType: RelatedType,
  relatedId: number,
): Promise<Attachment> {
  const URL = getUrl(relatedType, relatedId);
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return getClient()
    .api.post(URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((attachment) => attachment.data);
}

export function postTestAttachment(
  file: File,
  testId: number,
): Promise<Attachment> {
  const URL = TestAttachmentRoute(testId);
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return getClient()
    .api.post(URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((attachment) => attachment.data);
}

export function postRunResultAttachment(
  file: File,
  runResultId: number,
): Promise<Attachment> {
  const URL = RunResultAttachmentRoute(runResultId);
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return getClient()
    .api.post(URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((attachment) => attachment.data);
}

export function postProjectAttachment(
  file: File,
  projectId: number,
): Promise<Attachment> {
  const URL = ProjectAttachmentRoute(projectId);
  const data = new FormData();
  data.append('attachment_type_id', '1');
  data.append('file', file);
  return getClient()
    .api.post(URL, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((attachment) => attachment.data);
}

export function deleteTestAttachment(
  attachmentId: number,
  testId: number,
): Promise<Attachment> {
  const URL = `${TestAttachmentRoute(testId)}/${attachmentId}`;
  return getClient().api.delete(URL);
}

export function deleteAttachment(attachmentId: number): Promise<Attachment> {
  const URL = `${AttachmentRoute()}/${attachmentId}`;
  return getClient().api.delete(URL);
}
