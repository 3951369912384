import { Test, Requirement, RequirementTest } from '@testquality/sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import AnalyzeStats from 'src/components/organisms/AnalyzeStats';
import { DateRange } from 'src/common/DataRange';
import vars from 'src/export.scss';
import styles from './RequirementsMetrics.module.scss';

type Props = {
  tests: Test[];
  requirements: Requirement[];
  requirementsTest: RequirementTest[];
  range: DateRange;
  className?: string;
};

export default function RequirementsMetrics({
  tests,
  requirements,
  requirementsTest,
  range,
  className = '',
}: Props) {
  const { t } = useTranslation();

  const { totalAdded, percentageWithReq } = useMemo(() => {
    const filteredRequirements = requirements.filter((test) => {
      return (
        new Date(test.created_at).getTime() >= range[0].getTime() &&
        new Date(test.created_at).getTime() <= range[1].getTime()
      );
    });
    let testWithReq = 0;
    tests.forEach((test) => {
      if (!requirementsTest.find((rt) => rt.test_id === test.id)) {
        testWithReq += 1;
      }
    });

    return {
      totalAdded: filteredRequirements.length,
      percentageWithReq: Math.floor((testWithReq / tests.length) * 10000) / 100,
    };
  }, [tests, requirements, range, requirementsTest]);

  return (
    <div className={`${styles.container} ${className}`}>
      <AnalyzeStats
        title={t('analyze.testRequirements.metrics.totalRequirements.title')}
        subtitle={t(
          'analyze.testRequirements.metrics.totalRequirements.subtitle',
        )}
        stat={requirements.length}
        statColor={vars.successMain}
      />
      {Boolean(percentageWithReq) && (
        <AnalyzeStats
          title={t(
            'analyze.testRequirements.metrics.percentageRequirements.title',
          )}
          subtitle={t(
            'analyze.testRequirements.metrics.percentageRequirements.subtitle',
          )}
          stat={`${percentageWithReq}%`}
          statColor={vars.successMain}
        />
      )}
      <AnalyzeStats
        title={t('analyze.testRequirements.metrics.totalAdded.title')}
        subtitle={t('analyze.testRequirements.metrics.totalAdded.subtitle')}
        stat={totalAdded}
        statColor={vars.successMain}
      />
    </div>
  );
}
