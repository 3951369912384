import { Client } from '@testquality/sdk';
import React, { useMemo } from 'react';
import { Button, Grid, Input } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { useFormik } from 'formik';
import { useTranslation } from 'src/i18n/hooks';
import Yup from 'src/utils/yup';
import styles from './SSOConfig.module.scss';

type Values = Pick<Client, 'openid_url' | 'openid_client_id'>;

const validationSchema = Yup.object().shape({
  openid_url: Yup.string().url().nullable().label('client.form.openid_url'),
  openid_client_id: Yup.string().label('client.form.openid_client_id'),
});

export type SSOConfigProps = {
  client?: Client;
  onSubmit: (values: Values) => Promise<any>;
};

export function SSOConfig({ client, onSubmit }: SSOConfigProps) {
  const { t } = useTranslation();

  const initialValues = useMemo<Values>(
    () => ({
      openid_url: client?.openid_url || '',
      openid_client_id: client?.openid_client_id || '',
    }),
    [client],
  );

  const formik = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <div className={styles.openId}>{t('client.openIdConnect')}</div>
      <Grid.Row gutter={[16, 0]}>
        <Grid.Col lg={7} span={12}>
          <Input
            error={formikError(formik, 'openid_url')}
            fullWidth
            label={t('client.form.openIdUrl')}
            name="openid_url"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            placeholder={t('client.form.openIdUrlPlaceholder')}
            value={formik.values.openid_url}
          />
        </Grid.Col>
        <Grid.Col lg={5} span={12}>
          <Input
            error={formikError(formik, 'openid_client_id')}
            fullWidth
            label={t('client.form.openIdClientId')}
            name="openid_client_id"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.openid_client_id}
          />
        </Grid.Col>
      </Grid.Row>
      <div className={styles.submit}>
        <Button loading={formik.isSubmitting} type="submit">
          {t('client.form.submit')}
        </Button>
      </div>
    </form>
  );
}
