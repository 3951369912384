import React from 'react';
import {
  Dropzone,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { DeleteIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import styles from '../Junit/JUnit.module.scss';

type Props = {
  files: File[];
  filesTypeAccepted: string[];
  onChangeFiles: (acceptedFiles: File[]) => void;
};

export default function ImportTable({
  onChangeFiles,
  filesTypeAccepted,
  files,
}: Props) {
  const { t } = useTranslation();

  const handleDropFiles = (dropfiles: File[]) => {
    onChangeFiles([...files, ...dropfiles]);
  };

  const handleDelete = (file: File) => {
    const newFiles = files.filter((f) => f.name !== file.name);
    onChangeFiles(newFiles);
  };

  return (
    <>
      {files.length > 0 && (
        <div className={styles.acceptedFiles}>
          <Table maxHeight={400}>
            <TableHead className={styles.head}>
              <TableCell>{t('importFile.head.name')}</TableCell>
              <TableCell>{t('importFile.head.size')}</TableCell>
              <TableCell>{t('importFile.head.type')}</TableCell>
              <TableCell>{t('importFile.head.action')}</TableCell>
            </TableHead>
            <TableBody>
              {files.map((file) => (
                <TableRow
                  className={styles.row}
                  key={`${file.type}-${file.name}-${file.lastModified}`}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{`${(file.size / 1024).toFixed(0)} kb`}</TableCell>
                  <TableCell>{file.type}</TableCell>
                  <TableCell>
                    <IconButton
                      boxed={false}
                      onClick={() => handleDelete(file)}
                      className={styles.actionButton}
                      title={t('importFile.actions.delete')}>
                      <DeleteIcon color={vars.textPrimary} size={16} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      <Dropzone accept={filesTypeAccepted} onDrop={handleDropFiles} />
    </>
  );
}
