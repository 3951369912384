import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type BaseModal = { modalProps?: { [key: string]: any } };

type ModalParams = BaseModal & {
  modalName: string;
  type: 'modal'; // needed for typescript :(
};

type RouteModal = BaseModal & {
  pathname: string;
  type: 'routeModal'; // needed for typescript :(
};

type Params = RouteModal | ModalParams;

export default function useModalManager() {
  const history = useHistory();
  const location = useLocation<any>();

  const showModal = useCallback(
    (params: Params) => {
      const descriptor: LocationDescriptor<any> = {
        state: {
          modalParentLocation: location,
          modalProps: params.modalProps || {},
        },
      };

      if (params.type === 'routeModal') {
        const { pathname } = params;
        descriptor.pathname = pathname;
        history.push(descriptor);
      }

      if (params.type === 'modal') {
        const { modalName } = params;
        descriptor.state.modalName = modalName;
        descriptor.pathname = location.pathname;
        descriptor.search = location.search;
        descriptor.hash = location.hash;
        history.replace(descriptor);
      }
    },
    [history, location],
  );

  const hideModal = useCallback(() => {
    if (location.state?.modalParentLocation) {
      history.replace(location.state?.modalParentLocation);
    }
  }, [history, location]);

  const setModalParent = useCallback(
    (modalParentLocation: LocationDescriptor<any>) => {
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: {
          ...((history?.location?.state as {}) || {}),
          modalParentLocation,
        },
      });
    },
    [history],
  );

  const setDefaultModalParent = useCallback(
    (modalParentLocation: LocationDescriptor<any>) => {
      const locationState = history.location.state as any;
      if (!locationState?.modalParentLocation) {
        setModalParent(modalParentLocation);
      }
    },
    [history, setModalParent],
  );

  const isModal = Boolean(location.state?.modalParentLocation);

  return {
    showModal,
    hideModal,
    isModal,
    setDefaultModalParent,
  };
}
