/* eslint-disable no-restricted-globals */
import React from 'react';
import { Project } from '@testquality/sdk';
import { DropdownDownIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import ProjectIcon from '../ProjectIcon';
import styles from './ProjectItem.module.scss';

type Props = {
  className?: string;
  hasDropdown?: boolean;
  onClick?: () => void;
  project: Project;
};

export default function ProjectItem({
  className = '',
  hasDropdown = false,
  onClick,
  project,
}: Props) {
  const { t } = useTranslation();
  const totalTodo = project.analysis?.todo;

  return (
    <div className={`${styles.projectItem} ${className}`} onClick={onClick}>
      <ProjectIcon
        className={styles.projectIcon}
        color={project.color as any}
        icon={project.picture as any}
      />
      <div className={styles.content}>
        <div className={styles.projectName}>{project.name}</div>
        {totalTodo !== undefined && totalTodo !== null && (
          <div className={styles.projectRunned}>
            {t('projectSelect.todo', {
              count: totalTodo,
            })}
          </div>
        )}
      </div>
      {hasDropdown && <DropdownDownIcon color={vars.textPrimary} />}
    </div>
  );
}
