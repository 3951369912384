import React, { useCallback, useState, useMemo } from 'react';
import { AccessRole, Policy } from '@testquality/sdk';
import { useOverlayTriggerState } from 'react-stately';
import {
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  notification,
} from '@bitmodern/bit-ui';
import { AccessRoleIcon, DeleteIcon } from '@bitmodern/bit-ui/icons';
import {
  ConfirmDialog,
  RoleFormDialog,
  SettingsPanel,
} from 'src/components/organisms';
import {
  initialValuesBuilder,
  RoleFormDetails,
  PermissionBreakdown,
  allProjectsOption,
} from 'src/components/organisms/RoleForm/RoleForm';
import vars from 'src/export.scss';
import { accessRoleDeleteOneThunk } from 'src/gen/domain/access_role/accessRoleThunk';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';
import { permissionTypes } from 'src/enums/PermissionsEnum';
import { AccessRoleEnum } from 'src/enums/AccessRoleEnum';
import {
  accessRolesSelector,
  accessRolesCountByUserSelector,
} from '@bitmodern/redux/state/accessRoles/selectors';
import { createPoliciesByRoleIdSelector } from '@bitmodern/redux/state/policy/selectors';
import { createPolicyRowsByPoliciesSelector } from '@bitmodern/redux/state/policyRows/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { notificationErrorTimeout } from 'src/constants';
import { upsertAccessRoleThunk } from 'src/packages/redux/state/accessRoles/thunks';
import styles from './AccessRolesView.module.scss';

const canView = (policy: Policy) => {
  if (policy.can_view || policy.can_list) return true;

  return false;
};
const canEdit = (policy: Policy) => {
  if (
    policy.can_close ||
    policy.can_create ||
    policy.can_delete ||
    policy.can_edit ||
    policy.can_execute
  ) {
    return true;
  }

  return false;
};

export default function AccessRolesView() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentAccessRole, setCurrentAccessRole] = useState<AccessRole>();
  const deleteAccessRole = useOverlayTriggerState({});
  const upsertAccessRole = useOverlayTriggerState({});
  const accessRoles = useAppSelector(accessRolesSelector);
  const accessRolesCounts = useAppSelector(accessRolesCountByUserSelector);
  const policiesByRoleIdSelector = useMemo(
    () => createPoliciesByRoleIdSelector(currentAccessRole?.id),
    [currentAccessRole],
  );
  const policies = useAppSelector(policiesByRoleIdSelector);
  const policyRowsByPoliciesSelector = useMemo(
    () => createPolicyRowsByPoliciesSelector(policies.map((p) => p.id)),
    [policies],
  );
  const policyRows = useAppSelector(policyRowsByPoliciesSelector);
  const initialValues = useMemo(() => {
    if (currentAccessRole) {
      const isAllProjects = policies.every((policy) => policy.all_rows);
      const projectIds = isAllProjects
        ? [allProjectsOption.value]
        : policyRows
            .map((row) => row.column_value)
            .filter((item, pos, arr) => arr.indexOf(item) === pos); // filter repeated

      return Object.keys(permissionTypes).reduce<RoleFormDetails>(
        (acc, permissionType) => {
          acc[permissionType] = permissionTypes[
            permissionType
          ].reduce<PermissionBreakdown>(
            (breakdown, permission) => {
              const policy = policies.find((p) => p.name === permission);
              if (!policy) return breakdown;
              if (!breakdown.view) breakdown.view = canView(policy);
              if (!breakdown.edit) breakdown.edit = canEdit(policy);
              return breakdown;
            },
            { view: false, edit: false },
          );
          return acc;
        },
        initialValuesBuilder(
          currentAccessRole?.name,
          projectIds,
          currentAccessRole?.is_default,
          {
            view: false,
            edit: false,
          },
        ),
      );
    }

    return initialValuesBuilder();
  }, [policies, policyRows, currentAccessRole]);

  const onCloseDeleteOverlay = useCallback(() => {
    deleteAccessRole.close();
    setCurrentAccessRole(undefined);
  }, [deleteAccessRole, setCurrentAccessRole]);

  const onCloseUpsertOverlay = useCallback(() => {
    upsertAccessRole.close();
    setCurrentAccessRole(undefined);
  }, [upsertAccessRole, setCurrentAccessRole]);

  const onDeleteRole = useCallback(() => {
    if (!currentAccessRole) return Promise.resolve();
    if (currentAccessRole.key === AccessRoleEnum.ADMINISTRATOR) {
      notification.open({
        type: 'error',
        message: t('accessRoles.deleteAccessRole.adminError'),
        duration: notificationErrorTimeout,
      });
      return Promise.resolve();
    }
    return dispatch(
      accessRoleDeleteOneThunk({ id: currentAccessRole.id }),
    ).finally(onCloseDeleteOverlay);
  }, [dispatch, currentAccessRole, onCloseDeleteOverlay, t]);

  const deleteAccessRoleMutation = useMutation(onDeleteRole);

  const onUpsertRole = useCallback(
    (values: RoleFormDetails) => {
      return dispatch(
        upsertAccessRoleThunk(values, initialValues, currentAccessRole?.id),
      ).finally(upsertAccessRole.close);
    },
    [dispatch, currentAccessRole, upsertAccessRole, initialValues],
  );

  const onClickAccessRole = (accessRole: AccessRole) => {
    setCurrentAccessRole(accessRole);
    upsertAccessRole.open();
  };

  return (
    <SettingsPanel
      actions={
        <Button onClick={upsertAccessRole.open} size="small">
          {t('accessRoles.add')}
        </Button>
      }
      icon={<AccessRoleIcon color={vars.textPrimary} size={20} />}
      paddingLess
      title={t('accessRoles.title')}>
      <Table>
        <TableHead>
          <TableCell>{t('accessRoles.name')}</TableCell>
          <TableCell colSpan={2}>{t('accessRoles.noUsers')}</TableCell>
        </TableHead>
        <TableBody>
          {accessRoles.map((accessRole) => (
            <TableRow
              key={accessRole.id}
              onClick={() => onClickAccessRole(accessRole)}>
              <TableCell className={styles.cell}>
                {accessRole.name}
                {accessRole.is_default && (
                  <Chip className={styles.defaultChip}>
                    {t('accessRoles.default')}
                  </Chip>
                )}
              </TableCell>
              <TableCell className={styles.cell}>
                {accessRolesCounts[accessRole.id]}
              </TableCell>
              <TableCell className={`${styles.cell} ${styles.actions}`}>
                <IconButton
                  disabled={accessRole.key === AccessRoleEnum.ADMINISTRATOR}
                  onClick={() => {
                    setCurrentAccessRole(accessRole);
                    deleteAccessRole.open();
                  }}
                  title={t('accessRoles.delete')}
                  size="small">
                  <DeleteIcon color={vars.textPrimary} size={16} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog
        loading={deleteAccessRoleMutation.isLoading}
        open={deleteAccessRole.isOpen}
        onCancel={onCloseDeleteOverlay}
        onConfirm={deleteAccessRoleMutation.mutate}
        title={t('accessRoles.deleteAccessRole.title')}>
        {t('accessRoles.deleteAccessRole.content', {
          accessRole: currentAccessRole?.name,
        })}
      </ConfirmDialog>
      <RoleFormDialog
        open={upsertAccessRole.isOpen}
        onCancel={onCloseUpsertOverlay}
        onSubmit={onUpsertRole}
        currentAccessRole={currentAccessRole}
        initialValues={initialValues}
      />
    </SettingsPanel>
  );
}
