/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Label } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { labelAdapter } from './labelAdapter';
import {
  labelFetchManyThunk,
  labelFetchOneThunk,
  labelDeleteOneThunk,
  labelUpdateOneThunk,
  labelCreateOneThunk,
} from './labelThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const labelSlice = createSlice({
  name: 'label',
  initialState: labelAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    labelCleared(state) {
      state.loading = 'idle';
      labelAdapter.removeAll(state);
    },
    labelUpsertMany(
      state,
      action: PayloadAction<Label[] | Record<number, Label>>,
    ) {
      labelAdapter.upsertMany(state, action.payload);
    },
    labelUpsertOne(state, action: PayloadAction<Label>) {
      labelAdapter.upsertOne(state, action.payload);
    },
    labelUpdateOne(state, action: PayloadAction<Update<Label>>) {
      labelAdapter.updateOne(state, action.payload);
    },
    labelRemoveOne(state, action: PayloadAction<number>) {
      labelAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(labelFetchManyThunk.pending, handlePending);
    builder.addCase(labelFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAdapter.upsertMany(state, action.payload.label);
    });
    builder.addCase(labelFetchManyThunk.rejected, handleRejected);
    builder.addCase(labelFetchOneThunk.pending, handlePending);
    builder.addCase(labelFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAdapter.upsertOne(state, action.payload.label[0]);
    });
    builder.addCase(labelFetchOneThunk.rejected, handleRejected);
    builder.addCase(labelDeleteOneThunk.pending, handlePending);
    builder.addCase(labelDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        labelAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(labelDeleteOneThunk.rejected, handleRejected);
    builder.addCase(labelUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, labelAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(labelUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAdapter.updateOne(state, action.payload);
    });
    builder.addCase(labelUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          labelAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(labelCreateOneThunk.pending, handlePending);
    builder.addCase(labelCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(labelCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  labelCleared,
  labelUpsertMany,
  labelUpsertOne,
  labelUpdateOne,
  labelRemoveOne,
} = labelSlice.actions;

export const labelReducer = labelSlice.reducer;
