/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectResHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectResHistoryAdapter } from './defectResHistoryAdapter';
import { defectResHistoryFetchThunk } from './defectResHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectResHistorySlice = createSlice({
  name: 'defectResHistory',
  initialState: defectResHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectResHistoryCleared(state) {
      state.loading = 'idle';
      defectResHistoryAdapter.removeAll(state);
    },
    defectResHistoryUpsertMany(
      state,
      action: PayloadAction<
        DefectResHistory[] | Record<number, DefectResHistory>
      >,
    ) {
      defectResHistoryAdapter.upsertMany(state, action.payload);
    },
    defectResHistoryUpsertOne(state, action: PayloadAction<DefectResHistory>) {
      defectResHistoryAdapter.upsertOne(state, action.payload);
    },
    defectResHistoryUpdateOne(
      state,
      action: PayloadAction<Update<DefectResHistory>>,
    ) {
      defectResHistoryAdapter.updateOne(state, action.payload);
    },
    defectResHistoryRemoveOne(state, action: PayloadAction<number>) {
      defectResHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectResHistoryFetchThunk.pending, handlePending);
    builder.addCase(defectResHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectResHistoryAdapter.upsertMany(
        state,
        action.payload.defectResHistory,
      );
    });
    builder.addCase(defectResHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  defectResHistoryCleared,
  defectResHistoryUpsertMany,
  defectResHistoryUpsertOne,
  defectResHistoryUpdateOne,
  defectResHistoryRemoveOne,
} = defectResHistorySlice.actions;

export const defectResHistoryReducer = defectResHistorySlice.reducer;
