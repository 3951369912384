import React, { ReactNode, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useHistory, useParams } from 'react-router';
import { Content } from '@bitmodern/bit-ui';
import { getSize, setSize } from '@bitmodern/services/ResizingService';
import { EmptyTests, Plans } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { useAppSelector } from '@bitmodern/redux/store';
import { plansByProjectSelector } from '@bitmodern/redux/state/plans/selectors';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import EmptyPlans from './EmptyPlans';
import styles from './PlansView.module.scss';

const STORAGE_KEY = 'plansWidth';

type Props = {
  children: ReactNode;
};

export default function PlansView({ children }: Props) {
  const { projectId, planId, site } = useParams<typeof routes.PLAN.params>();
  const history = useHistory();
  const [initialWidth] = useState(() => getSize(STORAGE_KEY) || 350);
  const projectIdNum = parseInt(projectId, 10);
  const plans = useAppSelector((state) =>
    plansByProjectSelector(state, { projectId: projectIdNum }),
  );
  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: projectIdNum }),
  );

  useEffect(() => {
    if (!planId && plans.length > 0) {
      history.replace(
        routes.PLAN({ site, planId: plans[0].id.toString(), projectId }),
      );
    }
  }, [history, planId, plans, projectId, site]);

  const onResizeStop = (event, direaction, elementRef) => {
    setSize(STORAGE_KEY, elementRef.offsetWidth);
  };

  const testsEmpty = tests.length === 0;
  const plansEmpty = plans.length === 0;

  let empty;
  if (testsEmpty) {
    empty = <EmptyTests filterType="plans" potentialAmount={tests.length} />;
  } else if (plansEmpty) {
    empty = <EmptyPlans projectId={parseInt(projectId, 10)} />;
  }

  return (
    <Content fullHeight>
      {!empty ? (
        <div className={styles.plans}>
          <Resizable
            className={styles.aside}
            defaultSize={{
              height: '100%',
              width: initialWidth,
            }}
            enable={{ right: true }}
            maxWidth={450}
            minWidth={350}
            onResizeStop={onResizeStop}>
            <Plans />
          </Resizable>
          <div className={styles.content}>{children}</div>
        </div>
      ) : (
        empty
      )}
    </Content>
  );
}
