import React, { ReactNode } from 'react';
import { Router as BaseRouter } from 'react-router-dom';
import { history } from './history';

type Props = {
  children: ReactNode;
};

export default function Router({ children }: Props) {
  return <BaseRouter history={history}>{children}</BaseRouter>;
}
