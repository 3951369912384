/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Invoice } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { invoiceAdapter } from './invoiceAdapter';
import {
  invoiceFetchManyThunk,
  invoiceFetchOneThunk,
  invoiceDeleteOneThunk,
  invoiceUpdateOneThunk,
  invoiceCreateOneThunk,
} from './invoiceThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: invoiceAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    invoiceCleared(state) {
      state.loading = 'idle';
      invoiceAdapter.removeAll(state);
    },
    invoiceUpsertMany(
      state,
      action: PayloadAction<Invoice[] | Record<number, Invoice>>,
    ) {
      invoiceAdapter.upsertMany(state, action.payload);
    },
    invoiceUpsertOne(state, action: PayloadAction<Invoice>) {
      invoiceAdapter.upsertOne(state, action.payload);
    },
    invoiceUpdateOne(state, action: PayloadAction<Update<Invoice>>) {
      invoiceAdapter.updateOne(state, action.payload);
    },
    invoiceRemoveOne(state, action: PayloadAction<number>) {
      invoiceAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(invoiceFetchManyThunk.pending, handlePending);
    builder.addCase(invoiceFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      invoiceAdapter.upsertMany(state, action.payload.invoice);
    });
    builder.addCase(invoiceFetchManyThunk.rejected, handleRejected);
    builder.addCase(invoiceFetchOneThunk.pending, handlePending);
    builder.addCase(invoiceFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      invoiceAdapter.upsertOne(state, action.payload.invoice[0]);
    });
    builder.addCase(invoiceFetchOneThunk.rejected, handleRejected);
    builder.addCase(invoiceDeleteOneThunk.pending, handlePending);
    builder.addCase(invoiceDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        invoiceAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(invoiceDeleteOneThunk.rejected, handleRejected);
    builder.addCase(invoiceUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, invoiceAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(invoiceUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      invoiceAdapter.updateOne(state, action.payload);
    });
    builder.addCase(invoiceUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          invoiceAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(invoiceCreateOneThunk.pending, handlePending);
    builder.addCase(invoiceCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      invoiceAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(invoiceCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  invoiceCleared,
  invoiceUpsertMany,
  invoiceUpsertOne,
  invoiceUpdateOne,
  invoiceRemoveOne,
} = invoiceSlice.actions;

export const invoiceReducer = invoiceSlice.reducer;
