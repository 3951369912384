import React, { ComponentProps, forwardRef, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import {
  AriaNumberFieldProps,
  useNumberField,
  mergeProps,
  useLocale,
  useButton,
} from 'react-aria';
import { useNumberFieldState } from 'react-stately';
import Field, { useFieldProps } from '../Field';
import styles from './InputNumber.module.scss';

type FieldProps = Omit<ComponentProps<typeof Field>, 'children' | 'labelProps'>;

type Props = AriaNumberFieldProps &
  FieldProps & {
    name?: string;
  };

const InputNumber = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const inputRef = useRef<HTMLInputElement>(null);
  const incrementRef = useRef(null);
  const decrementRef = useRef(null);
  const {
    groupProps,
    labelProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, inputRef);

  const fieldProps = useFieldProps(props);

  const { buttonProps: incrementProps } = useButton(
    incrementButtonProps,
    incrementRef,
  );
  const { buttonProps: decrementProps } = useButton(
    decrementButtonProps,
    decrementRef,
  );

  const { name } = props; // eslint-disable-line react/prop-types

  return (
    <Field {...fieldProps} labelProps={labelProps}>
      {(borderProps, fieldRef) => (
        <div {...groupProps} className={styles.group}>
          <input
            {...mergeProps(borderProps, inputProps)}
            className={styles.input}
            name={name}
            ref={mergeRefs([ref, inputRef, fieldRef])}
          />
          <div className={styles.steps}>
            <button
              className={styles.stepUp}
              type="button"
              {...incrementProps}
            />
            <button
              className={styles.stepDown}
              type="button"
              {...decrementProps}
            />
          </div>
        </div>
      )}
    </Field>
  );
});

export default InputNumber;
