/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { integrationUserAdapter } from './integrationUserAdapter';

export const integrationUserSelectors = integrationUserAdapter.getSelectors(
  (state: State) => state.gen.integrationUser,
);
