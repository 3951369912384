import React, { useState } from 'react';
import { Dialog } from '@bitmodern/bit-ui';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import { NoteAddIcon } from '@bitmodern/bit-ui/icons';
import { useHistory, useLocation, useParams } from 'react-router';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import { TreeItem } from 'react-sortable-tree';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import {
  BatchService,
  Requirement,
  showNotificationError,
} from '@testquality/sdk';
import { requirementTestCreateOneThunk } from 'src/gen/domain/requirement_test/requirementTestThunk';
import { requirementsByProjectSelector } from '@bitmodern/redux/state/requirements/selectors';
import { requirementCreateOneThunk } from 'src/gen/domain/requirement/requirementThunk';
import { suiteTestsByTestSelector } from '@bitmodern/redux/state/suiteTests/selectors';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';
import { requirementsTestByRequirementsSelector } from '@bitmodern/redux/state/requirementTest/selectors';
import { Step1, Step2 } from '../MultiIssueItem';
import styles from './RequirementCreateView.module.scss';

export default function RequirementCreateView() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation<{ modalParentLocation? }>();

  const { site, projectId, testId } =
    useParams<typeof routes.REQUIREMENT_CREATE.params>();
  const suiteTest = useAppSelector((state) =>
    suiteTestsByTestSelector(state, parseInt(testId ?? '', 10)),
  );

  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId: parseInt(projectId, 10),
    }),
  );

  const requirements = useAppSelector((state) =>
    requirementsByProjectSelector(state, {
      projectId: parseInt(projectId, 10),
    }),
  );

  const requirementTest = useAppSelector((state) =>
    requirementsTestByRequirementsSelector(
      state,
      requirements.map((req) => req.id),
    ),
  );

  const [step, setStep] = useState('step1');
  const [initialSummary, setInitialSummary] = useState('');
  const [tests, setTests] = useState<TreeItem[]>([]);
  const [selectedIp, setSelectedIp] = useState<any>();

  const onCloseModal = (requirementId?: string) => {
    if (location.state?.modalParentLocation?.pathname?.includes('tests')) {
      history.push(location.state.modalParentLocation);
    } else if (
      location.state?.modalParentLocation?.pathname?.includes('stories')
    ) {
      if (requirementId) {
        history.push({
          pathname: routes.REQUIREMENT({
            site,
            projectId,
            requirementId,
          }),
        });
      } else {
        history.push(location.state.modalParentLocation);
      }
    } else {
      history.replace({
        pathname: routes.PROJECT_TESTS({
          site,
          projectId,
        }),
        search: `?testId=${'0'}`,
      });
    }
  };

  const onLinkRequirement = async (data: {
    issue;
    testItems: TreeItem[];
    integration_project_id: number;
  }) => {
    // Check if requirement is on the store/DB
    const requirement = requirements.find(
      (req) =>
        req.integration_project_id === data.integration_project_id &&
        req.external_reference_id === data.issue.number,
    );
    let newReq;
    if (!requirement) {
      // Insert issue into DB before trying to link to tests
      newReq = await createRequirement({
        payload: {
          summary: data.issue.title,
          description: data.issue.body,
          labels: data.issue.labels,
          priority: {},
          resolution: {},
          assignee: data.issue.assignee,
        },
        defect_status_id: undefined,
        defect_res_id: undefined,
        project_id: parseInt(projectId, 10),
        external_reference_id: data.issue.number.toString(),
        integration_project_id: data.integration_project_id,
      });
    }
    if (newReq?.payload || requirement) {
      const requirementId = newReq?.payload?.id || requirement?.id;
      if (data.testItems.length > 0) {
        const toCreate = data.testItems.filter((ti) => {
          return (
            typeof requirementTest.find(
              (rt) =>
                rt.test_id === ti.data.test.id &&
                rt.requirement_id === requirementId,
            ) === 'undefined'
          );
        });
        createRequirementTest(requirementId, toCreate).catch(
          showNotificationError,
        );
      } else {
        onCloseModal(requirementId.toString());
      }
    }

    return Promise.resolve();
  };

  const onCreateRequirement = async ({
    components,
    summary,
    description,
    labels,
    priority,
    assignee,
    type,
    integrationProjectId,
  }) => {
    const req = await createRequirement({
      payload: {
        components: components.map(({ value, label }) => ({
          id: value,
          name: label,
        })),
        summary,
        description,
        labels: labels.map(({ id, label }) => ({
          id,
          name: label,
        })),
        priority,
        assignee,
        type,
      },
      project_id: parseInt(projectId, 10),
      integration_project_id: integrationProjectId,
    });
    if (tests.length) {
      createRequirementTest((req as any).payload.id, tests).catch(
        showNotificationError,
      );
    } else {
      onCloseModal((req as any).payload.id.toString());
    }
  };

  const createRequirement = async (data) => {
    return dispatch(
      requirementCreateOneThunk({
        data,
      } as unknown as Requirement),
    );
  };

  const createRequirementTest = (requirementId, testItems) => {
    const batch = new BatchService();
    testItems.forEach((value) => {
      dispatch(
        requirementTestCreateOneThunk({
          data: {
            test_id: value.data.test.id,
            requirement_id: requirementId,
            suite_id: value.data.suiteTest.suite_id,
          },
          batch,
        }),
      );
    });
    return batch
      .executeBatch()
      .then(() => onCloseModal(requirementId.toString()))
      .catch(showNotificationError);
  };

  const onNext = ({ summary, testItems }, selectedIp) => {
    setInitialSummary(summary);
    setTests(testItems);
    setSelectedIp(selectedIp);
    setStep('step2');
  };

  return (
    <Dialog
      animatePresence={false}
      size="xlarge"
      title={
        <div className={styles.head}>
          <NoteAddIcon
            color={vars.textPrimary}
            className={styles.titleIcon}
            size={18}
          />
          {t('requirementCreate.title')}
        </div>
      }
      isDismissable={false}
      onClose={onCloseModal}
      isOpen>
      {step === 'step1' && suiteTest && (
        <Step1
          onNext={onNext}
          onLinkIssue={onLinkRequirement}
          tree={tree}
          selectedTests={suiteTest}
          includedIssues={requirements}
        />
      )}
      {step === 'step2' && (
        <Step2
          onCreateDefect={onCreateRequirement}
          initialSummary={initialSummary}
          integrationProject={selectedIp}
          issueType={IntegrationSchema.REQUIREMENT}
        />
      )}
    </Dialog>
  );
}
