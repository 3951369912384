import { DataSet, Step, Suite, Test } from '@testquality/sdk';
import { GherkinFeature, GherkinScenario } from '../types';
import createScenario from './createScenario';

export default function createFeature(
  suite: Suite,
  test: Test,
  steps: Step[],
  featureLabels: string[],
  testLabels: string[],
  dataSet?: DataSet,
): GherkinFeature {
  let title = suite.name;
  if (!title.startsWith('Feature')) {
    title = `Feature: ${title}`;
  }

  return {
    title,
    keyword: 'Feature',
    tags: featureLabels ?? [],
    scenarios: [
      createScenario(test, steps, testLabels, dataSet),
    ] as GherkinScenario[],
  };
}
