import React, { ComponentProps } from 'react';
import { Test, RunResult } from '@testquality/sdk';
import { Avatar, ChipProgress, Tooltip } from '@bitmodern/bit-ui';
import { useAppSelector } from '@bitmodern/redux/store';
import { userSelectorById } from '@bitmodern/redux/state/users/selectors';
import { pendingStatusSelector } from '@bitmodern/redux/state/statuses/selectors';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { statusTypeSelectors } from 'src/gen/domain/status_type/statusTypeSelector';
import { getStatusTypeColor } from 'src/utils/statusHelper';
import Status from '../Status';
import TreeItem from '../TreeItem';
import styles from './RunResultListed.module.scss';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';

type Props = ComponentProps<'div'> & {
  checked: boolean;
  nestingLevel?: number;
  onChangeChecked: (value: boolean) => void;
  runResults: RunResult[];
  selected?: boolean;
  test: Test;
};

export default function RunResultListed({
  checked,
  nestingLevel = 0,
  onChangeChecked,
  runResults,
  selected = false,
  style,
  test,
  ...rest
}: Props) {
  const pendingStatus = useAppSelector(pendingStatusSelector);
  const statusEntities = useAppSelector(statusSelectors.selectEntities);
  const statusTypeEntities = useAppSelector(statusTypeSelectors.selectEntities);

  const progress = runResults.reduce((acu, runResult, i, arr) => {
    const status = statusEntities[runResult.status_id];
    const type = statusTypeEntities[status?.status_type_id || ''];
    if (!type) return acu;
    if (!acu[type.id]) {
      acu[type.id] = { color: getStatusTypeColor(type), percent: 0 };
    }
    acu[type.id].percent += 100 / arr.length;
    return acu;
  }, {});

  const asignedUser = useAppSelector((state) => {
    if (!runResults[0].assigned_to_tester) return undefined;
    return userSelectorById(state, runResults[0].assigned_to_tester);
  });

  const isPending = runResults.some(
    (runResult) => runResult.status_id === pendingStatus?.id,
  );

  const mainData = [
    <span key="key" className={styles.testId}>
      TC{test.key}
    </span>,
    <span key="name" className={styles.name}>
      {test.name}
    </span>,
  ];

  const statusRendered =
    isPending || runResults.length === 1 ? (
      <Status
        key="status"
        className={styles.status}
        statusId={
          runResults.length === 1
            ? runResults[0]?.status_id
            : pendingStatus?.id || 0
        }
      />
    ) : (
      <ChipProgress
        idValue={test.id}
        className={styles.progress}
        progress={Object.values(progress)}
      />
    );

  const rightSection = [
    asignedUser && (
      <Tooltip key="avatar" tooltip={asignedUser.given_name}>
        <span>
          <Avatar
            className={styles.avatar}
            src={processAttachmentPath(asignedUser.picture)}
            name={formatUserName(asignedUser)}
            size={24}
          />
        </span>
      </Tooltip>
    ),
    statusRendered,
  ];

  return (
    <TreeItem
      {...rest}
      checked={checked}
      onChangeChecked={onChangeChecked}
      nestingLevel={nestingLevel}
      notIncluded={false}
      selected={selected}
      mainData={mainData}
      rightSection={rightSection}
    />
  );
}
