/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { DefectRes } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectResAdapter } from './defectResAdapter';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
  defectResDeleteOneThunk,
  defectResUpdateOneThunk,
  defectResCreateOneThunk,
} from './defectResThunk';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
} from '../native_defect_res/nativeDefectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectResSlice = createSlice({
  name: 'defectRes',
  initialState: defectResAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectResCleared(state) {
      state.loading = 'idle';
      defectResAdapter.removeAll(state);
    },
    defectResUpsertMany(
      state,
      action: PayloadAction<DefectRes[] | Record<number, DefectRes>>,
    ) {
      defectResAdapter.upsertMany(state, action.payload);
    },
    defectResUpsertOne(state, action: PayloadAction<DefectRes>) {
      defectResAdapter.upsertOne(state, action.payload);
    },
    defectResUpdateOne(state, action: PayloadAction<Update<DefectRes>>) {
      defectResAdapter.updateOne(state, action.payload);
    },
    defectResRemoveOne(state, action: PayloadAction<number>) {
      defectResAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectResFetchManyThunk.pending, handlePending);
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectResAdapter.upsertMany(state, action.payload.defectRes);
    });
    builder.addCase(defectResFetchManyThunk.rejected, handleRejected);
    builder.addCase(defectResFetchOneThunk.pending, handlePending);
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectResAdapter.upsertOne(state, action.payload.defectRes[0]);
    });
    builder.addCase(defectResFetchOneThunk.rejected, handleRejected);
    builder.addCase(defectResDeleteOneThunk.pending, handlePending);
    builder.addCase(defectResDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        defectResAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(defectResDeleteOneThunk.rejected, handleRejected);
    builder.addCase(defectResUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, defectResAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(defectResUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectResAdapter.updateOne(state, action.payload);
    });
    builder.addCase(defectResUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          defectResAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(defectResCreateOneThunk.pending, handlePending);
    builder.addCase(defectResCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectResAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(defectResCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectRes) {
          defectResAdapter.upsertMany(state, action.payload.defectRes);
        }
      },
    );
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectRes) {
          defectResAdapter.upsertMany(state, action.payload.defectRes);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectRes) {
          defectResAdapter.upsertMany(state, action.payload.defectRes);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectRes) {
          defectResAdapter.upsertMany(state, action.payload.defectRes);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectRes) {
        defectResAdapter.upsertMany(state, action.payload.defectRes);
      }
    });
  },
});

export const {
  defectResCleared,
  defectResUpsertMany,
  defectResUpsertOne,
  defectResUpdateOne,
  defectResRemoveOne,
} = defectResSlice.actions;

export const defectResReducer = defectResSlice.reducer;
