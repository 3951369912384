import sortBy from 'lodash/sortBy';
import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { AppThunk } from '@bitmodern/redux/store';
import {
  BatchService,
  SuiteTest,
  TestApi,
  Test,
  PlanSuite,
  Plan,
  showNotificationError,
  getResponse,
  HttpError,
} from '@testquality/sdk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { NormalizerCache } from 'src/gen/actions/NormalizeCache';
import {
  suiteTestCreateOneThunk,
  suiteTestUpdateOneThunk,
  suiteTestDetachThunk,
} from 'src/gen/domain/suite_test/suiteTestThunk';
import { stepCreateOneThunk } from 'src/gen/domain/step/stepThunk';
import { attachmentUpdateOneThunk } from 'src/gen/domain/attachment/attachmentThunk';
import {
  suiteTestsByTestSelector,
  suiteTestsSelector,
} from '@bitmodern/redux/state/suiteTests/selectors';
import {
  testCreateOneThunk,
  testDeleteOneThunk,
  testFetchOneThunk,
} from 'src/gen/domain/test/testThunk';
import { getClient } from 'src/Client';
import { suiteChildrenSelector, suiteSelector } from '../suites/selectors';
import { updateSiblingsSequenceThunk } from '../treeThunks';

const emptySteps = [
  {
    step: '',
    sequence: 1,
    expected_result: '',
  },
];

export function createTestThunk({
  attachments,
  suitesIds,
  steps,
  test,
  under,
}: {
  attachments: any[];
  suitesIds: number[];
  steps: any[];
  test: Partial<TestApi>;
  under: { planSuite?: PlanSuite; suiteTest?: SuiteTest };
}): AppThunk<Promise<{ test: Test; folder?: SuiteTest }>> {
  return async (dispatch, getState) => {
    if (!under.planSuite && !under.suiteTest) return Promise.reject();

    try {
      const sequence_suite = under.suiteTest
        ? under.suiteTest.sequence_suite + 1
        : 1;
      const resultAction = await dispatch(
        testCreateOneThunk({
          data: {
            ...test,
            suite_id: suitesIds[0],
            requirement_id: 0,
            suite_test: {
              suite_id: suitesIds[0],
              sequence_suite,
            },
          } as any,
        }),
      );
      const newTest = unwrapResult(resultAction);

      const testId = newTest.id;
      const batch = new BatchService();

      // Test extra suites association
      suitesIds.forEach(async (suite_id) => {
        if (suite_id !== suitesIds[0]) {
          dispatch(
            suiteTestCreateOneThunk({
              data: {
                test_id: testId,
                suite_id,
              },
              batch,
            }),
          );
        }
      });

      // Create test steps
      const stepsToCreate = steps.length ? steps : emptySteps;
      stepsToCreate.forEach((step) => {
        dispatch(
          stepCreateOneThunk({
            data: {
              step: step.step,
              sequence: step.sequence,
              expected_result: step.expected_result,
              project_id: test.project_id,
              test_id: testId,
            },
            batch,
          }),
        );
      });

      // reorder siblings
      const suiteId = under.planSuite?.suite_id || under.suiteTest?.suite_id;
      if (suiteId) {
        const siblings = suiteChildrenSelector(getState(), suiteId);
        updateSiblingsSequenceThunk(siblings, batch);
      }

      // Move attachments from project to test
      attachments.forEach((attachment) => {
        dispatch(
          attachmentUpdateOneThunk({
            id: attachment.id,
            data: {
              related_type: RelatedType.TEST,
              related_id: testId,
            },
          }),
        );
      });

      await batch.executeBatch();
      const suiteTests = suiteTestsSelector(getState());
      const folder = suiteTests.find((st) => st.test_id === newTest.id);
      return { test: newTest, folder };
    } catch (error) {
      showNotificationError(error as HttpError);
      throw error;
    }
  };
}

export function deleteSuiTestsTestsThunk(suiteTests: SuiteTest[]): AppThunk {
  return async (dispatch, getState) => {
    const batch = new BatchService();
    suiteTests.forEach((suiteTest) => {
      dispatch(suiteTestDetachThunk({ data: suiteTest, batch }));
    });

    // Update siblings secuence
    const suitesIDsObject = {};
    const suitesIds: number[] = [];
    suiteTests.forEach((suiteTest) => {
      if (!suitesIDsObject[suiteTest.suite_id]) {
        suitesIds.push(suiteTest.suite_id);
        suitesIDsObject[suiteTest.suite_id] = 0;
      }
    });

    suitesIds.forEach((suiteId) => {
      const siblings = suiteChildrenSelector(getState(), suiteId);
      updateSiblingsSequenceThunk(siblings, batch);
    });

    await batch.executeBatch();
  };
}

export function deleteTestThunk(testId: number): AppThunk {
  return async (dispatch, getState) => {
    const batch = new BatchService();
    dispatch(testDeleteOneThunk({ id: testId }));

    // Update siblings secuence
    const testSuiteTests = suiteTestsByTestSelector(getState(), testId);
    const suitesIds = testSuiteTests.map((st) => st.suite_id);
    suitesIds.forEach((suiteId) => {
      const siblings = suiteChildrenSelector(getState(), suiteId);
      updateSiblingsSequenceThunk(siblings, batch);
    });

    await batch.executeBatch();
  };
}

export function updateTestSiblingsSequenceThunk(
  siblings: SuiteTest[],
  batch: BatchService,
): AppThunk {
  return async (dispatch) => {
    if (!siblings.length) return;

    const suiteTestsSiblings = sortBy(siblings, ['suite_id', 'sequence_suite']);

    let sequence_suite = 1;
    let suite = suiteTestsSiblings[0].suite_id;
    suiteTestsSiblings.forEach((suiteTest) => {
      dispatch(
        suiteTestUpdateOneThunk({
          id: suiteTest.id,
          data: { sequence_suite },
          batch,
          optimistic: true,
        }),
      );
      sequence_suite += 1;
      if (suite !== suiteTest.suite_id) {
        suite = suiteTest.suite_id;
        sequence_suite = 1;
      }
    });
  };
}

export function cloneTestBatchThunk({
  suiteTests,
  plan,
  suiteId,
}: {
  suiteTests: SuiteTest[];
  plan?: Plan;
  suiteId?: number;
}): AppThunk {
  return async (dispatch, getState) => {
    const batch = new BatchService();
    const suite = suiteId ? suiteSelector(getState(), suiteId) : null;

    // Don't add to plan if cloning to other project
    const planTarget =
      plan?.project_id === suite?.project_id ? plan : undefined;

    const promises: Array<Promise<NormalizerCache>> = [];
    suiteTests.forEach((suiteTest) => {
      const suiteTarget = suiteId || suiteTest.suite_id;
      promises.push(
        batch
          .addBatch<Test>({
            url: `/suite_test/${suiteTest.id}/clone`,
            method: 'POST',
            data: {},
            params: {
              ...(suiteTarget && { suite_id: suiteTarget }),
              ...(planTarget && { plan_id: planTarget.id }),
            },
          })
          .then(
            (newTest) =>
              dispatch(
                testFetchOneThunk({
                  id: newTest.id,
                  params: { _with: 'suite,planSuiteTestInclude,step' },
                }),
              ),
            (error) => Promise.reject(error),
          )
          .then(unwrapResult, (error) => Promise.reject(error)),
      );
    });

    await batch.executeBatch();
    /* NOTE: Wait to fullfil until all new tests have been fetched */
    await Promise.all(promises);
  };
}

export const testCloneOneThunk = createAsyncThunk(
  'test/CloneOneThunk',
  async (
    {
      suiteTestId,
      suiteId,
      planId,
    }: {
      suiteTestId: number;
      suiteId?: number;
      planId?: number;
    },
    { dispatch },
  ) => {
    try {
      if (!suiteTestId) throw new Error('Test folder not found');
      const { api } = getClient();

      const newTest = await getResponse<
        Test,
        { suite_id?: number; plan_id?: number }
      >(api, {
        url: `/suite_test/${suiteTestId}/clone`,
        method: 'POST',
        params: {
          ...(suiteId && { suite_id: suiteId }),
          ...(planId && { plan_id: planId }),
        },
      });
      await dispatch(
        testFetchOneThunk({
          id: newTest.id,
          params: { _with: 'suite,planSuiteTestInclude,step' },
        }),
      );
      return newTest;
    } catch (error) {
      getClient().errorHandler(error as any);
      throw error;
    }
  },
);
