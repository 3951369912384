import React from 'react';
import { Link } from 'react-router-dom';
import { Requirement } from '@testquality/sdk';
import { TableCell, TableRow } from '@bitmodern/bit-ui';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppSelector } from '@bitmodern/redux/store';
import styles from './RunsRequirementItem.module.scss';
import { defectStatusSelectors } from 'src/gen/domain/defect_status/defectStatusSelector';
import { DefectStatusItem } from 'src/components/organisms/DefectStatusItem';

type Props = {
  requirement: Requirement;
  testStatus;
  width?: number | string;
};

export default function RunsRequirementItem({
  requirement,
  testStatus,
  width,
}: Props) {
  const { generateDrawerPath } = useDrawerManager();

  const defectStatus = useAppSelector((state) => {
    if (requirement?.defect_status_id) {
      return defectStatusSelectors.selectById(
        state,
        requirement.defect_status_id,
      );
    }
    const defectStatuses = defectStatusSelectors.selectAll(state);
    return defectStatuses.find((df) => df.key === 1);
  });

  return (
    <TableRow key={requirement?.id}>
      <TableCell className={styles.key}>
        <Link to={generateDrawerPath({ requirementId: requirement?.id })}>
          {requirement?.payload?.key}
        </Link>
      </TableCell>
      <TableCell>{requirement?.payload?.summary}</TableCell>
      <TableCell>{testStatus.pass}</TableCell>
      <TableCell>{testStatus.fail}</TableCell>
      {(!width || (width && width > 700)) && (
        <>
          <TableCell>{testStatus.skip}</TableCell>
          <TableCell>{testStatus.defects}</TableCell>
        </>
      )}
      <TableCell>
        <DefectStatusItem defectStatus={defectStatus} />
      </TableCell>
    </TableRow>
  );
}
