import { getNodeAtPath } from 'react-sortable-tree';
import { AppThunk } from '@bitmodern/redux/store';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import { planSuiteUpdateOneThunk } from 'src/gen/domain/plan_suite/planSuiteThunk';
import { BatchService, PlanSuite, Suite, SuiteTest } from '@testquality/sdk';

import { suiteTestUpdateOneThunk } from 'src/gen/domain/suite_test/suiteTestThunk';

import { rootPlanSelector } from './plans/selectors';
import { rootSuiteSelector } from './suites/selectors';

export function moveNodeThunk(params, projectId: number): AppThunk<void> {
  return async (dispatch, getState) => {
    const rootPlan = rootPlanSelector(getState(), projectId);
    if (!rootPlan) return;
    const rootSuite = rootSuiteSelector(getState(), rootPlan.id);
    if (!rootSuite) return;

    const nextSuite = (params.nextParentNode?.data?.suite ||
      rootSuite) as Suite;

    const currentSuiteId =
      params?.node?.data?.suiteTest?.suite_id ||
      params?.node?.data?.planSuite?.parent_id;

    const batch = new BatchService();

    // Update to new parent
    if (nextSuite.id !== currentSuiteId) {
      if (params.node.type === TEST_TYPE) {
        const suiteTest = params.node.data.suiteTest as SuiteTest;
        dispatch(
          suiteTestUpdateOneThunk({
            id: suiteTest.id,
            data: { suite_id: nextSuite.id },
            batch,
            optimistic: true,
          }),
        );
      } else if (params.node.type === SUITE_TYPE) {
        const planSuite = params.node.data.planSuite as PlanSuite;
        dispatch(
          planSuiteUpdateOneThunk({
            id: planSuite.id,
            data: { parent_id: nextSuite.id },
            batch,
            optimistic: true,
          }),
        );
      }

      // resequence previous siblings
      const prevSiblings: Array<PlanSuite | SuiteTest> = [];
      const path = params.prevPath.slice(0, -1);
      const branch = getNodeAtPath({
        treeData: params.treeData,
        path,
        getNodeKey: (node) => node.node.nodeKey,
        ignoreCollapsed: true,
      });
      const nodes =
        branch && Array.isArray(branch?.node?.children)
          ? branch.node.children
          : [];
      nodes.forEach((node) => {
        if (node?.data?.planSuite) {
          prevSiblings.push(node.data.planSuite as PlanSuite);
        } else if (node?.data?.suiteTest) {
          prevSiblings.push(node.data.suiteTest as SuiteTest);
        }
      });
      dispatch(updateSiblingsSequenceThunk(prevSiblings, batch));
    }

    // resecuence siblings and current
    const siblings: Array<PlanSuite | SuiteTest> = [];
    const treeBranch = params.nextParentNode?.children || params.treeData;
    treeBranch.forEach((node) => {
      if (node?.data?.planSuite) {
        siblings.push(node.data.planSuite as PlanSuite);
      } else if (node?.data?.suiteTest) {
        siblings.push(node.data.suiteTest as SuiteTest);
      }
    });
    dispatch(updateSiblingsSequenceThunk(siblings, batch));

    await batch.executeBatch();
  };
}

export function updateSiblingsSequenceThunk(
  siblings: Array<SuiteTest | PlanSuite>,
  batch: BatchService,
): AppThunk<void> {
  return async (dispatch) => {
    siblings.forEach((sibling, i) => {
      dispatch(updateSiblingThunk(sibling, i + 1, batch));
    });
  };
}

export function updateSiblingThunk(
  sibling: PlanSuite | SuiteTest,
  sequence: number,
  batch: BatchService,
): AppThunk<void> {
  return (dispatch) => {
    if ('sequence_plan' in sibling) {
      if (sibling.sequence_plan !== sequence) {
        dispatch(
          planSuiteUpdateOneThunk({
            id: sibling.id,
            data: { sequence_plan: sequence },
            batch,
            optimistic: true,
          }),
        );
      }
    } else if (sibling.sequence_suite !== sequence) {
      dispatch(
        suiteTestUpdateOneThunk({
          id: sibling.id,
          data: { sequence_suite: sequence },
          batch,
          optimistic: true,
        }),
      );
    }
  };
}
