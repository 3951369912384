import React, { useState } from 'react';
import Button from '../Button';
import Spacer from '../Spacer';
import styles from './ActionButtons.module.scss';

type Props = {
  onCancel?: () => void;
  onCommit?: () => Promise<any>;
};

export default function ActionButtons({ onCancel, onCommit }: Props) {
  const [loading, setLoading] = useState(false);

  const onCommitHandler = () => {
    if (!onCommit) return;
    setLoading(true);
    onCommit().finally(() => setLoading(false));
  };

  return (
    <Spacer className={styles.actions}>
      {onCancel && (
        <Button
          color="primaryDark"
          name="cancel"
          onClick={onCancel}
          size="small">
          Cancel
        </Button>
      )}
      {onCommit && (
        <Button
          color="success"
          loading={loading}
          name="save"
          onClick={onCommitHandler}
          size="small">
          Save
        </Button>
      )}
    </Spacer>
  );
}
