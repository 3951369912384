import {
  BaseIntegration,
  Integration,
  showNotificationError,
} from '@testquality/sdk';
import {
  integrationCreateOneThunk,
  integrationUpdateOneThunk,
} from 'src/gen/domain/integration/integrationThunk';
import { authenticateWithRedirect } from '@bitmodern/services/integrationService';
import { JiraInstanceType } from 'src/enums/IntegrationEnums';

type AuthorizeIntegrationArgs = {
  integration?: Integration;
  baseIntegration: BaseIntegration;
  projectCreate: boolean;
  redirectUrl?: string;
  site: string;
};

export function authorizeIntegrationThunk({
  baseIntegration,
  redirectUrl = window.location.href,
  projectCreate,
  integration,
  site,
}: AuthorizeIntegrationArgs) {
  return async (dispatch) => {
    try {
      const getIntegrationId = async (pIntegration?: Integration) => {
        if (pIntegration) {
          return pIntegration.id;
        }
        const res = await dispatch(
          createIntegrationThunk({ site, baseIntegration }),
        );
        if (!res) {
          throw new Error('Could not create integration.');
        }
        return res.id;
      };
      const integrationId = await getIntegrationId(integration);

      const authParam = projectCreate ? 'has_project_auth' : 'has_auth';
      const redirectTo = new URL(redirectUrl);
      redirectTo.searchParams.set(authParam, String(true));

      const resp = await authenticateWithRedirect(
        integrationId,
        redirectUrl.toString(),
      );

      window.location.href = resp.redirect_url;
    } catch (error) {
      showNotificationError(error as any);
    }
  };
}

type SetupIntegrationParams = {
  org: string;
  jiraInstance: any;
  serverUrl: string;
  baseIntegration: BaseIntegration;
  integration?: Integration;
};

export function setupIntegrationThunk({
  baseIntegration,
  integration,
  org,
  jiraInstance,
  serverUrl,
}: SetupIntegrationParams) {
  return (dispatch) => {
    let params = {};

    if (baseIntegration && jiraInstance === JiraInstanceType.Cloud) {
      params = {
        name: JiraInstanceType.Cloud,
        org,
        default_url: baseIntegration.default_url.replace('{{org}}', org),
        default_app_url: baseIntegration.default_app_url?.replace(
          '{{org}}',
          org,
        ),
      };
    } else if (integration) {
      const lastChar = integration.default_app_url?.charAt(
        integration.default_app_url.length - 1,
      );

      params = {
        name: JiraInstanceType.Server,
        default_app_url:
          lastChar === '/'
            ? (integration.default_app_url = `${serverUrl}browse/`)
            : (integration.default_app_url = `${serverUrl}/browse/`),
        org: '',
      };
    }

    if (!integration) {
      return dispatch(
        createIntegrationThunk({ baseIntegration, params, site: org }),
      );
    }

    return dispatch(updateIntegrationThunk({ ...params, id: integration.id }));
  };
}

type CreateIntegrationThunkParams = {
  baseIntegration: BaseIntegration;
  site: string;
  params?: Partial<Integration>;
};

function createIntegrationThunk({
  baseIntegration,
  site,
  params,
}: CreateIntegrationThunkParams) {
  return (dispatch) => {
    return dispatch(
      integrationCreateOneThunk({
        data: {
          base_integration_id: baseIntegration.id,
          default_app_url: baseIntegration.default_app_url,
          default_url: baseIntegration.default_url,
          is_basic_authentication: baseIntegration.is_basic_authentication,
          is_oauth2_authentication: baseIntegration.is_oauth2_authentication,
          name: baseIntegration.name,
          is_project_default: true,
          is_reported_by: false,
          use_master_login: false,
          org: site,
          ...params,
        },
      }),
    )
      .then(({ payload }) => payload as Integration)
      .catch((error) => {
        showNotificationError(error);
        // avoid unhandled rejection by not returning anything
      });
  };
}

function updateIntegrationThunk(data: Partial<Integration>) {
  return (dispatch) => {
    return dispatch(
      integrationUpdateOneThunk({
        id: data.id,
        data,
      }),
    ).catch((error) => {
      showNotificationError(error);
      // avoid unhandled rejection by not returning anything
    });
  };
}
