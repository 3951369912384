/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { productAdapter } from './productAdapter';

export const productSelectors = productAdapter.getSelectors(
  (state: State) => state.gen.product,
);
