import React, { ReactNode } from 'react';
import classnames from 'classnames';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { CancelIcon } from '../icons';
import styles from './Chip.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
  onRemove?: () => void;
  size?: 'small' | 'medium';
};

export default function Chip({
  className,
  children,
  onRemove,
  size = 'small',
}: Props) {
  const chipCN = classnames(styles.chip, className, {
    [styles.small]: size === 'small',
    [styles.withRemove]: Boolean(onRemove),
  });

  return (
    <div className={chipCN}>
      {children}
      {onRemove && (
        <div className={styles.remove} onClick={onRemove}>
          <CancelIcon color={vars.textPrimary} size={14} />
        </div>
      )}
    </div>
  );
}
