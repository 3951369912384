import React, { forwardRef, memo } from 'react';

type IconProps = {
  size?: number;
};

export default function makeFileIcon(
  src: string,
  displayName: string,
  imgProps = {},
) {
  const Icon = ({ size = 24 }: IconProps, ref) => (
    <img
      src={src}
      alt={displayName}
      style={{ maxHeight: size, width: size, objectFit: 'contain' }}
      ref={ref}
      {...imgProps}
    />
  );

  if (process.env.NODE_ENV !== 'production') {
    Icon.displayName = `${displayName}Icon`;
  }

  return memo(forwardRef(Icon));
}
