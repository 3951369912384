/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Requirement } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { requirementAdapter } from './requirementAdapter';
import {
  requirementFetchManyThunk,
  requirementFetchOneThunk,
  requirementDeleteOneThunk,
  requirementUpdateOneThunk,
  requirementCreateOneThunk,
} from './requirementThunk';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
} from '../defect_res/defectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
} from '../native_defect_res/nativeDefectResThunk';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const requirementSlice = createSlice({
  name: 'requirement',
  initialState: requirementAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    requirementCleared(state) {
      state.loading = 'idle';
      requirementAdapter.removeAll(state);
    },
    requirementUpsertMany(
      state,
      action: PayloadAction<Requirement[] | Record<number, Requirement>>,
    ) {
      requirementAdapter.upsertMany(state, action.payload);
    },
    requirementUpsertOne(state, action: PayloadAction<Requirement>) {
      requirementAdapter.upsertOne(state, action.payload);
    },
    requirementUpdateOne(state, action: PayloadAction<Update<Requirement>>) {
      requirementAdapter.updateOne(state, action.payload);
    },
    requirementRemoveOne(state, action: PayloadAction<number>) {
      requirementAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requirementFetchManyThunk.pending, handlePending);
    builder.addCase(requirementFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementAdapter.upsertMany(state, action.payload.requirement);
    });
    builder.addCase(requirementFetchManyThunk.rejected, handleRejected);
    builder.addCase(requirementFetchOneThunk.pending, handlePending);
    builder.addCase(requirementFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementAdapter.upsertOne(state, action.payload.requirement[0]);
    });
    builder.addCase(requirementFetchOneThunk.rejected, handleRejected);
    builder.addCase(requirementDeleteOneThunk.pending, handlePending);
    builder.addCase(requirementDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        requirementAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(requirementDeleteOneThunk.rejected, handleRejected);
    builder.addCase(requirementUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, requirementAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(requirementUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementAdapter.updateOne(state, action.payload);
    });
    builder.addCase(requirementUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          requirementAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(requirementCreateOneThunk.pending, handlePending);
    builder.addCase(requirementCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      requirementAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(requirementCreateOneThunk.rejected, handleRejected);
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.requirement) {
          requirementAdapter.upsertMany(state, action.payload.requirement);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.requirement) {
        requirementAdapter.upsertMany(state, action.payload.requirement);
      }
    });
  },
});

export const {
  requirementCleared,
  requirementUpsertMany,
  requirementUpsertOne,
  requirementUpdateOne,
  requirementRemoveOne,
} = requirementSlice.actions;

export const requirementReducer = requirementSlice.reducer;
