import React, { useLayoutEffect, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Content.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  fullHeight?: boolean;
};

export default function Content({
  children,
  className = '',
  fullHeight = false,
}: Props) {
  useLayoutEffect(() => {
    if (fullHeight) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = '';
    };
  }, [fullHeight]);

  if (!fullHeight) {
    return <div className={`${styles.content} ${className}`}>{children}</div>;
  }

  const contentCN = classnames(styles.content, {
    [styles.fullHeight]: fullHeight,
  });

  return (
    <div className={contentCN}>
      <div className={`${styles.fullHeightContent} ${className}`}>
        {children}
      </div>
    </div>
  );
}
