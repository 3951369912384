import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Yup from 'src/utils/yup';
import {
  differenceInCalendarDays,
  format as formatDate,
  formatDistanceToNow,
} from 'date-fns';
import { getEnv } from 'src/env';
import authenticationTranslationsEN from 'src/modules/authentication/translations/en/translation.json';
import coreTranslationsEN from './translations/en/translation.json';

const translationEN = {
  ...coreTranslationsEN,
  ...authenticationTranslationsEN,
};

export const resources = {
  en: {
    translation: translationEN,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: getEnv().isDevelopment,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (value instanceof Date) {
        if (format === 'timeago') {
          return differenceInCalendarDays(new Date(), value) > 30
            ? formatDate(value, 'MM/dd/yyyy')
            : formatDistanceToNow(value, { addSuffix: true });
        }
        return formatDate(value, format || 'yyyy/MM/dd');
      }

      if (typeof value === 'number' && format === 'price') {
        return Intl.NumberFormat(lng, {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      }

      return value;
    },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  },
});

function tLabel<P extends Record<string, any> & Partial<Yup.TestMessageParams>>(
  params: P,
): P {
  return {
    ...params,
    label: params.label ? i18n.t(params.label) : params.path,
  };
}

Yup.setLocale({
  mixed: {
    default: (params) => i18n.t('yup.mixed.default', tLabel(params)),
    required: (params) => i18n.t('yup.mixed.required', tLabel(params)),
    oneOf: (params) => i18n.t('yup.mixed.oneOf', tLabel(params)),
    notOneOf: (params) => i18n.t('yup.mixed.notOneOf', tLabel(params)),
  },
  string: {
    length: ({ length, ...params }) =>
      i18n.t('yup.string.length', tLabel({ ...params, count: length })),
    min: ({ min, ...params }) =>
      i18n.t('yup.string.min', tLabel({ ...params, count: min })),
    max: ({ max, ...params }) =>
      i18n.t('yup.string.max', tLabel({ ...params, count: max })),
    matches: (params) => i18n.t('yup.string.matches', tLabel(params)),
    email: (params) => i18n.t('yup.string.email', tLabel(params)),
    url: (params) => i18n.t('yup.string.url', tLabel(params)),
    uuid: (params) => i18n.t('yup.string.uuid', tLabel(params)),
    trim: (params) => i18n.t('yup.string.trim', tLabel(params)),
    lowercase: (params) => i18n.t('yup.string.lowercase', tLabel(params)),
    uppercase: (params) => i18n.t('yup.string.uppercase', tLabel(params)),
  },
  number: {
    min: (params) => i18n.t('yup.number.min', tLabel(params)),
    max: (params) => i18n.t('yup.number.max', tLabel(params)),
    lessThan: (params) => i18n.t('yup.number.lessThan', tLabel(params)),
    moreThan: (params) => i18n.t('yup.number.moreThan', tLabel(params)),
    positive: (params) => i18n.t('yup.number.positive', tLabel(params)),
    negative: (params) => i18n.t('yup.number.negative', tLabel(params)),
    integer: (params) => i18n.t('yup.number.integer', tLabel(params)),
  },
  date: {
    min: (params) => i18n.t('yup.date.min', tLabel(params)),
    max: (params) => i18n.t('yup.date.max', tLabel(params)),
  },
  boolean: {
    isValue: (params) => i18n.t('yup.boolean.isValue', tLabel(params)),
  },
  object: {
    noUnknown: (params) => i18n.t('yup.boolean.noUnknown', tLabel(params)),
  },
  array: {
    min: ({ min, ...params }) =>
      i18n.t('yup.array.min', tLabel({ ...params, count: min })),
    max: ({ max, ...params }) =>
      i18n.t('yup.array.max', tLabel({ ...params, count: max })),
  },
});

export default i18n;
