import React, { ComponentProps, useRef, useState } from 'react';
import classnames from 'classnames';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import { useFocusWithin, useHover, mergeProps } from 'react-aria';
import { getFocusAccentCN } from '@bitmodern/bit-ui';
import ActionButtons from './ActionButtons';
import MarkdownEditor from './MarkdownEditor';
import Toolbar from './Toolbar';
import styles from './EasyMDE.module.scss';

type Props = ComponentProps<typeof MarkdownEditor> & {
  onCancel?: () => void;
  onCommit?: (value?: string) => Promise<any>;
  withFormatting?: boolean;
  withCapabilityTemplateEditingFor?: CapabilityEnum | null;
};

function EasyMDEEditor({
  onFocus,
  onCommit,
  onCancel,
  value,
  withFormatting = true,
  withCapabilityTemplateEditingFor = null,
  ...rest
}: Props) {
  const ref = useRef<any>();

  const [state, setState] = useState({});
  const [isFocused, setIsFocused] = useState(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: setIsFocused,
  });
  const { hoverProps, isHovered } = useHover({});

  const onClickToolbar = (action: string, templateVariable?: string) => {
    if (ref.current[action]) {
      ref.current[action](templateVariable);
    }
  };

  const onCommitHandler = () => {
    if (!onCommit) return Promise.resolve();
    return onCommit(value);
  };

  const borderClassName = getFocusAccentCN(isHovered, isFocused);

  return (
    <div>
      <div
        className={classnames(styles.editor, borderClassName)}
        {...mergeProps(focusWithinProps, hoverProps)}>
        <MarkdownEditor
          {...rest}
          onChangeState={setState}
          ref={ref}
          value={value}
          withCapabilityTemplateEditingFor={withCapabilityTemplateEditingFor}
          withFormatting={withFormatting}
        />
        <Toolbar
          onClick={onClickToolbar}
          state={state}
          withCapabilityTemplateEditingFor={withCapabilityTemplateEditingFor}
          withFormatting={withFormatting}
        />
      </div>
      {(onCommit || onCancel) && (
        <ActionButtons onCommit={onCommitHandler} onCancel={onCancel} />
      )}
    </div>
  );
}

export default React.memo(EasyMDEEditor);
