import React, { ComponentProps, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { getClient } from 'src/Client';
import {
  Button,
  Grid,
  Input,
  InputCardStripe,
  Spacer,
} from '@bitmodern/bit-ui';
import { BatchService, showNotificationError } from '@testquality/sdk';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { formikError } from 'src/utils/formik';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { subscriptionsFetchManyThunk } from 'src/gen/domain/subscriptions/subscriptionsThunk';
import { useTranslation } from 'src/i18n/hooks';
import { updateCard } from '@bitmodern/services/subscriptionsService';
import { themeColorSelector } from '@bitmodern/redux/state/theme/selectors';
import { userFetchManyThunk } from 'src/gen/domain/user/userThunk';
import styles from './ChangeCardForm.module.scss';

type InitialValues = {
  card: Parameters<ComponentProps<typeof InputCardStripe>['onChange']>[0];
  cardholderName: string;
};

const initialValues: InitialValues = {
  card: null,
  cardholderName: '',
};

const validationSchema = Yup.object().shape({
  card: Yup.mixed().required().label('changeCard.form.card'),
  cardholderName: Yup.string()
    .required()
    .label('changeCard.form.cardholderName'),
});

export default function ChangeCardForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showCardForm, setShowCardForm] = useState(false);
  const user = useAppSelector(currentUserSelector);
  const themeColor = useAppSelector(themeColorSelector);
  const stripe = useStripe();
  const formik = useFormik({ onSubmit, initialValues, validationSchema });

  async function onSubmit({ card }) {
    if (!stripe || !card) return;
    const { token } = await stripe.createToken(card, {});
    try {
      await updateCard(token);
      const batch = new BatchService();
      dispatch(
        subscriptionsFetchManyThunk({
          params: { per_page: -1, _with: 'user' },
          batch,
        }),
      );
      dispatch(userFetchManyThunk({ params: { per_page: -1 }, batch }));
      await batch.executeBatch();
      await getClient().getAuth().refresh();
      setShowCardForm(false);
    } catch (error) {
      showNotificationError(error as any);
    }
  }

  const onChangeCard = (card) => formik.setFieldValue('card', card);

  const onClickChange = () => setShowCardForm(true);

  const onCancel = () => setShowCardForm(false);

  return (
    <>
      <div className={styles.element}>
        <div className={`${styles.title} ${styles.elementContent}`}>
          {t('changeCard.details')}
        </div>
        {!showCardForm && (
          <Button color="primaryLight" onClick={onClickChange} size="medium">
            {t('changeCard.button')}
          </Button>
        )}
      </div>

      {showCardForm ? (
        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
          <Input
            error={formikError(formik, 'cardholderName')}
            fullWidth
            label={t('changeCard.form.cardholderName')}
            name="cardholderName"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.cardholderName}
          />
          <InputCardStripe
            label={t('changeCard.form.card')}
            onChange={onChangeCard}
            required
            theme={themeColor}
          />
          <Spacer className={styles.submit}>
            <Button color="primaryLight" onClick={onCancel}>
              {t('changeCard.form.cancel')}
            </Button>
            <Button
              disabled={!stripe}
              loading={formik.isSubmitting}
              type="submit">
              {t('changeCard.form.submit')}
            </Button>
          </Spacer>
        </form>
      ) : (
        <>
          <Grid.Row>
            <Grid.Col span={3}>{t('changeCard.form.cardholderName')}</Grid.Col>
            <Grid.Col span={3} className={styles.info}>
              {user?.given_name}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={styles.infoContainer}>
            <Grid.Col span={3}>{t('changeCard.cardNumber')}</Grid.Col>
            <Grid.Col span={3} className={styles.info}>
              **** **** **** {user?.card_last_four} {user?.card_brand}
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </>
  );
}
