/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { CaseType } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { caseTypeAdapter } from './caseTypeAdapter';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
  caseTypeDeleteOneThunk,
  caseTypeUpdateOneThunk,
  caseTypeCreateOneThunk,
} from './caseTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const caseTypeSlice = createSlice({
  name: 'caseType',
  initialState: caseTypeAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    caseTypeCleared(state) {
      state.loading = 'idle';
      caseTypeAdapter.removeAll(state);
    },
    caseTypeUpsertMany(
      state,
      action: PayloadAction<CaseType[] | Record<number, CaseType>>,
    ) {
      caseTypeAdapter.upsertMany(state, action.payload);
    },
    caseTypeUpsertOne(state, action: PayloadAction<CaseType>) {
      caseTypeAdapter.upsertOne(state, action.payload);
    },
    caseTypeUpdateOne(state, action: PayloadAction<Update<CaseType>>) {
      caseTypeAdapter.updateOne(state, action.payload);
    },
    caseTypeRemoveOne(state, action: PayloadAction<number>) {
      caseTypeAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(caseTypeFetchManyThunk.pending, handlePending);
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      caseTypeAdapter.upsertMany(state, action.payload.caseType);
    });
    builder.addCase(caseTypeFetchManyThunk.rejected, handleRejected);
    builder.addCase(caseTypeFetchOneThunk.pending, handlePending);
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      caseTypeAdapter.upsertOne(state, action.payload.caseType[0]);
    });
    builder.addCase(caseTypeFetchOneThunk.rejected, handleRejected);
    builder.addCase(caseTypeDeleteOneThunk.pending, handlePending);
    builder.addCase(caseTypeDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        caseTypeAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(caseTypeDeleteOneThunk.rejected, handleRejected);
    builder.addCase(caseTypeUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, caseTypeAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(caseTypeUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      caseTypeAdapter.updateOne(state, action.payload);
    });
    builder.addCase(caseTypeUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          caseTypeAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(caseTypeCreateOneThunk.pending, handlePending);
    builder.addCase(caseTypeCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      caseTypeAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(caseTypeCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  caseTypeCleared,
  caseTypeUpsertMany,
  caseTypeUpsertOne,
  caseTypeUpdateOne,
  caseTypeRemoveOne,
} = caseTypeSlice.actions;

export const caseTypeReducer = caseTypeSlice.reducer;
