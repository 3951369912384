import { useCallback, useEffect } from 'react';
import { routes } from 'src/components/Router';
import { useQuery } from 'src/hooks/useQuery';
import { authenticateGithub } from '@bitmodern/services/githubService';

export default function GithubInstallView() {
  const { marketplace_listing_plan_id } = useQuery([
    'marketplace_listing_plan_id',
  ]);
  const githubAuthenticate = useCallback(async () => {
    const redirect = window.location.origin + routes.GITHUB_MARKET({});
    const res = await authenticateGithub(
      `${redirect}?marketplace_listing_plan_id=${marketplace_listing_plan_id}`,
    );
    window.location.href = res.redirect_url;
  }, [marketplace_listing_plan_id]);

  useEffect(() => {
    githubAuthenticate();
  }, [githubAuthenticate]);

  return null;
}
