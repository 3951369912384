import React, { useCallback, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { CustomizeIcon, DeleteIcon } from '@bitmodern/bit-ui/icons';
import {
  ConfirmDialog,
  CustomFieldDialog,
  SettingsPanel,
} from 'src/components/organisms';
import {
  BooleanEnumDisplay,
  booleanToBooleanEnum,
} from 'src/enums/BooleanEnum';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import { virtualsSelector } from '@bitmodern/redux/state/virtuals/selectors';
import { virtualUpdateOneThunk } from 'src/gen/domain/virtual/virtualThunk';
import useMutation from 'src/hooks/useMutation';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import styles from './CustomizationView.module.scss';

export default function CustomizationView() {
  const { t } = useTranslation();
  const customField = useOverlayTriggerState({});
  const deleteField = useOverlayTriggerState({});
  const [currentField, setCurrentField] = useState({
    tableName: '',
    field: '',
  });
  const virtuals = useAppSelector(virtualsSelector);
  const dispatch = useAppDispatch();

  const virtual = virtuals.find((v) => v.table_name === currentField.tableName);

  const onDelete = useCallback(() => {
    if (!currentField.field || !virtual) {
      deleteField.close();
      return Promise.resolve();
    }

    const { [currentField.field]: removedValue, ...columns } =
      virtual.virtual_schema?.columns || {};

    return dispatch(
      virtualUpdateOneThunk({
        id: virtual.id,
        data: {
          virtual_schema: { columns },
        },
      }),
    ).finally(deleteField.close);
  }, [dispatch, currentField, deleteField, virtual]);

  const deleteFieldMutation = useMutation(onDelete);

  const onAdd = () => {
    setCurrentField({ tableName: '', field: '' });
    customField.open();
  };

  const onClickField = (name: string, tableName: string) => {
    setCurrentField({ tableName, field: name });
    customField.open();
  };

  const fields = virtuals.flatMap((v) => {
    return Object.entries(v.virtual_schema?.columns || {}).reduce<any[]>(
      (acu, [name, field]) => {
        if (typeof field !== 'object') return acu;
        acu.push({
          ...field,
          name,
          tableName: v.table_name,
        });
        return acu;
      },
      [],
    );
  });

  return (
    <SettingsPanel
      actions={
        <Button onClick={onAdd} size="small">
          {t('customization.add')}
        </Button>
      }
      icon={<CustomizeIcon color={vars.textPrimary} size={20} />}
      title={t('customization.title')}
      paddingLess>
      <Table>
        <TableHead>
          <TableCell>{t('customization.field.objectType')}</TableCell>
          <TableCell>{t('customization.field.field')}</TableCell>
          <TableCell>{t('customization.field.mandatory')}</TableCell>
          <TableCell colSpan={2}>{t('customization.field.type')}</TableCell>
        </TableHead>
        <TableBody>
          {fields.map((field) => {
            const mandatory = BooleanEnumDisplay.get(
              booleanToBooleanEnum(field.is_mandatory),
            );
            return (
              <TableRow
                key={field.name}
                onClick={() => onClickField(field.name, field.tableName)}>
                <TableCell className={styles.cell}>
                  {t(
                    `customization.objectTypes.${
                      field.tableName as 'plan' | 'run' | 'test'
                    }`,
                  )}
                </TableCell>
                <TableCell className={styles.cell}>{field.name}</TableCell>
                <TableCell className={styles.cell}>
                  {mandatory ? t(mandatory) : ''}
                </TableCell>
                <TableCell className={styles.cell}>{field.type}</TableCell>
                <TableCell className={`${styles.cell} ${styles.actions}`}>
                  <IconButton
                    onClick={() => {
                      setCurrentField({
                        tableName: field.tableName,
                        field: field.name,
                      });
                      deleteField.open();
                    }}
                    size="small"
                    title={t('customization.delete')}>
                    <DeleteIcon color={vars.textPrimary} size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ConfirmDialog
        loading={deleteFieldMutation.isLoading}
        open={deleteField.isOpen}
        onCancel={deleteField.close}
        onConfirm={deleteFieldMutation.mutate}
        title={t('customization.deleteField.title')}>
        {t('customization.deleteField.content')}
      </ConfirmDialog>
      <CustomFieldDialog
        fieldName={currentField.field}
        isOpen={customField.isOpen}
        onClose={customField.close}
        title="Custom Field"
        virtualId={virtual?.id}
      />
    </SettingsPanel>
  );
}
