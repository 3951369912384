/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { TestApi } from '@testquality/sdk';
import { SuiteTest } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { suiteNormalizer } from '../suite/suiteNormalize';
import { suiteTestNormalizer } from '../suite_test/suiteTestNormalize';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { stepNormalizer } from '../step/stepNormalize';
import { planSuiteTestIncludeNormalizer } from '../plan_suite_test_include/planSuiteTestIncludeNormalize';
import { requirementTestNormalizer } from '../requirement_test/requirementTestNormalize';

export const testNormalizer = (model: TestApi, cache: NormalizerCache) => {
  if (!cache.test) {
    cache.test = [];
  }
  cache.test.push(model);

  if (model.suite && model.suite.length) {
    model.suite.forEach((child) => {
      suiteTestNormalizer(child.pivot as SuiteTest, cache);
      child.pivot = undefined;
      suiteNormalizer(child, cache);
    });
    model.suite = undefined;
  }
  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
  if (model.step && model.step.length) {
    model.step.forEach((child) => {
      stepNormalizer(child, cache);
    });
    model.step = undefined;
  }
  if (model.plan_suite_test_include && model.plan_suite_test_include.length) {
    model.plan_suite_test_include.forEach((child) => {
      planSuiteTestIncludeNormalizer(child, cache);
    });
    model.plan_suite_test_include = undefined;
  }
  if (model.requirement_test && model.requirement_test.length) {
    model.requirement_test.forEach((child) => {
      requirementTestNormalizer(child, cache);
    });
    model.requirement_test = undefined;
  }
};
