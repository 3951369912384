import React, { ReactNode } from 'react';
import { TestQualityLogoIcon } from 'src/packages/bit-ui/icons';
import { useTheme } from 'src/hooks/useTheme';
import { useTranslation } from 'src/i18n/hooks';
import styles from './StepContent.module.scss';

export type StepContentProps = {
  children: ReactNode;
  currentStep: number;
  stepTotal: number;
  title: ReactNode;
};

export function StepContent({
  children,
  stepTotal,
  currentStep,
  title,
}: StepContentProps) {
  const [theme] = useTheme();
  const { t } = useTranslation();

  return (
    <div className={styles.stepContent}>
      <TestQualityLogoIcon className={styles.tqLogo} size={200} theme={theme} />
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <div className={styles.stepText}>
            {t('getStarted.stepText', { step: currentStep, total: stepTotal })}
          </div>
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
