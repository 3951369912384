/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { SharedStepApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { stepNormalizer } from '../step/stepNormalize';

export const sharedStepNormalizer = (
  model: SharedStepApi,
  cache: NormalizerCache,
) => {
  if (!cache.sharedStep) {
    cache.sharedStep = [];
  }
  cache.sharedStep.push(model);

  if (model.step && model.step.length) {
    model.step.forEach((child) => {
      stepNormalizer(child, cache);
    });
    model.step = undefined;
  }
};
