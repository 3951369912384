import React, { ComponentProps, useCallback, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useHover } from 'react-aria';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import { getFocusAccentCN } from '@bitmodern/bit-ui';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import styles from './InputCardStripe.module.scss';

/* Due to stripe api design validation is handled on this component */

type Props = {
  label: string;
  onChange: (cardElement: StripeCardElement | null) => void;
  theme: 'light' | 'dark';
} & Pick<ComponentProps<typeof FieldLabel>, 'required'>;

const getStylePropertyValue = (propertyName: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(propertyName);

const getRuntimeColors = (theme: string) => ({
  textPrimary: getStylePropertyValue('--textPrimary'),
  textSecondary: getStylePropertyValue('--textSecondary'),
  errorMain: getStylePropertyValue('--errorMain'),
});

export default function InputCardStripe({
  label,
  onChange,
  required,
  theme,
}: Props) {
  const [error, setError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { hoverProps, isHovered } = useHover({});
  const runtimeColors = useMemo(() => getRuntimeColors(theme), [theme]);

  const elements = useElements();

  const handleChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      // event.error;
      setError(event.error?.message || '');
      if (event.complete) {
        const cardElement = elements?.getElement(CardElement);
        if (cardElement) {
          onChange(cardElement);
        }
      } else {
        onChange(null);
      }
    },
    [elements, onChange],
  );

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const fieldCN = classnames(
    styles.field,
    getFocusAccentCN(isHovered, isFocused),
  );

  return (
    <>
      <FieldLabel required={required}>{label}</FieldLabel>
      <div className={fieldCN} {...hoverProps}>
        <CardElement
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          options={{
            style: {
              base: {
                color: runtimeColors.textPrimary,
                fontSize: '14px',
                iconColor: runtimeColors.textPrimary,
                '::placeholder': {
                  color: runtimeColors.textSecondary,
                },
              },
              invalid: {
                color: runtimeColors.errorMain,
              },
            },
            classes: {
              base: styles.base,
            },
            hidePostalCode: true,
          }}
        />
      </div>
      {error && <FieldError>{error}</FieldError>}
    </>
  );
}
