import React, { Children, ReactNode } from 'react';
import { motion, Variants } from 'framer-motion';
import styles from './StaggerWrapper.module.scss';

const itemVariants: Variants = {
  appear: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
  },
  leave: {
    opacity: 0,
    y: -30,
  },
};

type Props = {
  className?: string;
  children: ReactNode;
};

export default function StaggerWrapper({ className = '', children }: Props) {
  return (
    <div className={`${styles.stagger} ${className}`}>
      {Children.map(children, (child) => (
        <motion.div variants={itemVariants}>{child}</motion.div>
      ))}
    </div>
  );
}
