import React, { ComponentProps, ReactNode } from 'react';
import { Test, PlanSuiteTestInclude, SuiteTest } from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { labelAssignedByTestSelector } from '@bitmodern/redux/state/label_assigned/selectors';

import TreeItem from '../TreeItem';
import styles from './TestAnalyzeListed.module.scss';

type Props = ComponentProps<'div'> & {
  checked?: boolean;
  nestingLevel?: number;
  onChangeChecked?: (value: boolean) => void;
  planSuiteTestInclude?: PlanSuiteTestInclude;
  selected?: boolean;
  suiteTest: SuiteTest;
  test: Test;
  infoTagsToRender?: (test: Test) => ReactNode;
};

export default function TestAnalyzeListed({
  checked,
  nestingLevel = 0,
  onChangeChecked,
  planSuiteTestInclude,
  selected = false,
  style,
  suiteTest,
  test,
  infoTagsToRender,
  ...rest
}: Props) {
  const labels = useAppSelector(labelsSelector);
  const labelsAssigned = useAppSelector((state) =>
    labelAssignedByTestSelector(state, { testId: test.id }),
  );
  const labelsIdsFromAssigned = labelsAssigned.map((la) => la.label_id);

  const mainData = [
    <span key="key" className={styles.key}>
      TC{test.key}
    </span>,
    <span key="name" className={styles.name}>
      {test.name}
    </span>,
  ];

  const testBadges = labels
    .filter((label) => labelsIdsFromAssigned.includes(label.id))
    .map((label) => (
      <span key={`testLabel${label.id}`} className={styles.badge}>
        {label.label}
      </span>
    ));

  const rightSection = infoTagsToRender && infoTagsToRender(test);

  return (
    <TreeItem
      {...rest}
      checked={checked}
      onChangeChecked={onChangeChecked}
      nestingLevel={nestingLevel}
      notIncluded={false}
      selected={selected}
      mainData={mainData}
      rightSection={[...testBadges, rightSection]}
    />
  );
}
