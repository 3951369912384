/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { BaseCapability } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { baseCapabilityAdapter } from './baseCapabilityAdapter';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
  baseCapabilityDeleteOneThunk,
  baseCapabilityUpdateOneThunk,
  baseCapabilityCreateOneThunk,
} from './baseCapabilityThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const baseCapabilitySlice = createSlice({
  name: 'baseCapability',
  initialState: baseCapabilityAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    baseCapabilityCleared(state) {
      state.loading = 'idle';
      baseCapabilityAdapter.removeAll(state);
    },
    baseCapabilityUpsertMany(
      state,
      action: PayloadAction<BaseCapability[] | Record<number, BaseCapability>>,
    ) {
      baseCapabilityAdapter.upsertMany(state, action.payload);
    },
    baseCapabilityUpsertOne(state, action: PayloadAction<BaseCapability>) {
      baseCapabilityAdapter.upsertOne(state, action.payload);
    },
    baseCapabilityUpdateOne(
      state,
      action: PayloadAction<Update<BaseCapability>>,
    ) {
      baseCapabilityAdapter.updateOne(state, action.payload);
    },
    baseCapabilityRemoveOne(state, action: PayloadAction<number>) {
      baseCapabilityAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(baseCapabilityFetchManyThunk.pending, handlePending);
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      baseCapabilityAdapter.upsertMany(state, action.payload.baseCapability);
    });
    builder.addCase(baseCapabilityFetchManyThunk.rejected, handleRejected);
    builder.addCase(baseCapabilityFetchOneThunk.pending, handlePending);
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      baseCapabilityAdapter.upsertOne(state, action.payload.baseCapability[0]);
    });
    builder.addCase(baseCapabilityFetchOneThunk.rejected, handleRejected);
    builder.addCase(baseCapabilityDeleteOneThunk.pending, handlePending);
    builder.addCase(baseCapabilityDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        baseCapabilityAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(baseCapabilityDeleteOneThunk.rejected, handleRejected);
    builder.addCase(baseCapabilityUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, baseCapabilityAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(baseCapabilityUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      baseCapabilityAdapter.updateOne(state, action.payload);
    });
    builder.addCase(baseCapabilityUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          baseCapabilityAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(baseCapabilityCreateOneThunk.pending, handlePending);
    builder.addCase(baseCapabilityCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      baseCapabilityAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(baseCapabilityCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.baseCapability) {
          baseCapabilityAdapter.upsertMany(
            state,
            action.payload.baseCapability,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.baseCapability) {
        baseCapabilityAdapter.upsertMany(state, action.payload.baseCapability);
      }
    });
  },
});

export const {
  baseCapabilityCleared,
  baseCapabilityUpsertMany,
  baseCapabilityUpsertOne,
  baseCapabilityUpdateOne,
  baseCapabilityRemoveOne,
} = baseCapabilitySlice.actions;

export const baseCapabilityReducer = baseCapabilitySlice.reducer;
