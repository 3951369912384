import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useDebouncedCallback<Val>(
  callback: (val: Val) => void,
  val: Val,
  delay: number,
): [Val, (val: Val) => void] {
  const [value, setValue] = useState(val);

  const debouncedCallback = useMemo(() => {
    return debounce(callback, delay);
  }, [callback, delay]);

  const onChangeValue = (v: Val) => {
    setValue(v);
    debouncedCallback(v);
  };

  return [value, onChangeValue];
}
