import { Invoice } from '@testquality/sdk';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { attachmentsByInvoiceSelector } from '@bitmodern/redux/state/attachments/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import styles from './BillingHistory.module.scss';

type Props = {
  invoices: Invoice[];
};

export default function BillingHistory({ invoices }: Props) {
  const { t } = useTranslation();
  const attachments = useAppSelector(attachmentsByInvoiceSelector);

  return (
    <>
      <div className={styles.title}>{t('billing.history.title')}</div>
      <Table maxHeight={400} outlined rounded>
        <TableHead className={styles.head}>
          <TableCell>{t('billing.history.date')}</TableCell>
          <TableCell>{t('billing.history.item')}</TableCell>
          <TableCell>{t('billing.history.charge')}</TableCell>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => {
            const attachment = attachments.find(
              (a) => a.related_id === invoice.id,
            );
            return (
              <TableRow key={invoice.id}>
                <TableCell className={styles.cell}>
                  {t('dates.readable', { date: new Date(invoice.created_at) })}
                </TableCell>
                <TableCell className={styles.cell}>
                  {attachment && (
                    <>
                      Receipt{' '}
                      <a
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={attachment.url}>
                        (PDF)
                      </a>
                    </>
                  )}
                </TableCell>
                <TableCell className={styles.cell}>
                  {t('price', {
                    value: parseInt(invoice.amount.toString(), 10),
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
