import { showNotificationError } from '@testquality/sdk';
import { AppThunk } from '@bitmodern/redux/store';
import { getInitialFetchThunk } from '@bitmodern/redux/state/initialFetchThunks';
import {
  attachGithubPurchaseService,
  cancelSubscriptionService,
} from '@bitmodern/services/subscriptionsService';
import { getClient } from 'src/Client';
import { subscriptionsCreateOneThunk } from 'src/gen/domain/subscriptions/subscriptionsThunk';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { authenticateAction } from '../authentication/actions';

const log = new ConsoleLogger('SubscriptionsThunks');

export function cancelSubscriptionThunk(subscriptionId: number): AppThunk {
  return async (dispatch) => {
    await cancelSubscriptionService(subscriptionId)
      .then(() => dispatch(refreshAndFetch()))
      .catch((error) => {
        showNotificationError(error);
        return Promise.reject(error);
      });
  };
}

export function subscribeThunk(
  productId: number,
  quantity: number,
  isAutoUpgrade: boolean,
  stripeToken?: any,
): AppThunk {
  return async (dispatch) => {
    try {
      await dispatch(
        subscriptionsCreateOneThunk({
          url: `subscription/order/${productId}`,
          data: {
            quantity,
            // @ts-expect-error
            token: stripeToken,
            is_auto_upgrade: isAutoUpgrade,
          },
        }),
      );
      await dispatch(refreshAndFetch());
    } catch (e) {
      log.error('order failed', e);
    }
  };
}

export function attachGithubPurchaseThunk(purchase): AppThunk {
  return async (dispatch) => {
    await attachGithubPurchaseService(purchase);
    await dispatch(refreshAndFetch());
  };
}

export function refreshAndFetch(): AppThunk {
  return async (dispatch) => {
    const token = await getClient().getAuth().refresh();
    if (token) {
      dispatch(authenticateAction(token));
      await dispatch(
        getInitialFetchThunk({
          userId: token?.user_id,
          clientId: token?.client_id,
        }),
      );
    }
  };
}
