import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { ResponsiveLine } from '@nivo/line';
import { format, isSameDay } from 'date-fns';
import { TestQuality, TestQualityType } from '@testquality/sdk';
import { DateRange } from 'src/common/DataRange';
import { useAppSelector } from '@bitmodern/redux/store';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import { allTestQualitySelector } from '@bitmodern/redux/state/testQuality/selectors';
import {
  excellentTQTypeSelector,
  goodTQTypeSelector,
} from '@bitmodern/redux/state/testQualityType/selectors';
import { TestQualityTimeSeries } from '@bitmodern/services/TestQualityService';
import { DateFormat } from 'src/enums/DateFormatEnum';
import vars from 'src/export.scss';

type Props = {
  range: DateRange;
  timeSeries: TestQualityTimeSeries;
};

function TestQualityChart({ timeSeries, range }: Props) {
  const allTestQuality = useAppSelector(allTestQualitySelector);
  const excellentTQType = useAppSelector(excellentTQTypeSelector);
  const goodTQType = useAppSelector(goodTQTypeSelector);
  const { testQuality: testQualityFilters } = useAppSelector((state) =>
    filtersByTypeSelector(state, 'analyzeTestQuality'),
  );

  if (isEmpty(timeSeries.days) || !excellentTQType || !goodTQType) return null;

  const testQualityData = chartData(timeSeries, {
    allTestQuality,
    goodTestQualityTypes: [excellentTQType, goodTQType],
    dateRange: range,
    filteredTQ: Object.keys(testQualityFilters || {}),
  });

  const dateMin = format(range[0], DateFormat.Day);
  const dateMax = format(range[1], DateFormat.Day);

  return (
    <div style={{ height: 300 }}>
      <ResponsiveLine
        enableGridX={false}
        enableGridY={false}
        enableArea
        enablePointLabel
        colors={{ datum: 'color' }}
        data={testQualityData}
        lineWidth={3}
        margin={{ top: 24, right: 40, bottom: 24, left: 40 }}
        xScale={{
          format: '%Y-%m-%d',
          max: dateMax,
          min: dateMin,
          precision: 'day',
          type: 'time',
          useUTC: false,
        }}
        xFormat="time:%Y-%m-%d"
        axisLeft={null}
        axisBottom={{
          tickValues: 8,
          format: '%b %d',
        }}
        pointSize={6}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointColor="#ffffff"
        enableSlices="x"
        useMesh
        theme={{
          background: 'transparent',
          textColor: vars.textSecondary,
          fontSize: 12,
          tooltip: {
            container: { background: vars.primaryDark },
          },
        }}
      />
    </div>
  );
}

type Entry = { x: string; y: number };
type Data = { id: string; data: Entry[] };
type Options = {
  allTestQuality: TestQuality[];
  goodTestQualityTypes: TestQualityType[];
  dateRange: DateRange;
  filteredTQ: string[];
};

function chartData(
  timeSeries: TestQualityTimeSeries,
  { allTestQuality, goodTestQualityTypes, dateRange, filteredTQ }: Options,
): Data[] {
  /* NOTE: The time series' last day will contain */
  /* all the TestQualities in the entire time series. */
  const entries = Object.keys(timeSeries.days);
  const daysWithEntries: Date[] = [];
  const entriesInRange = [...entries]
    /* NOTE: Reverse order to get newest entries first. */
    .reverse()
    .filter((date) => {
      const parsedDate = new Date(date);
      if (daysWithEntries.some((entry) => isSameDay(parsedDate, entry))) {
        /* NOTE: If this date already has an entry, skip it. Only 1 entry per day. */
        /* The newest entry is the one with the most up to date data. */
        return false;
      }
      if (parsedDate.getTime() > dateRange[1].getTime()) {
        return false;
      }
      daysWithEntries.push(parsedDate);
      return true;
    })
    .reverse();

  const lastEntryDay = entriesInRange[entriesInRange.length - 1];
  if (typeof lastEntryDay === 'undefined') return [];

  const totals = Object.keys(timeSeries.days[lastEntryDay]?.totals || {});

  const relevantTestQualities = filteredTQ.length
    ? totals.filter((id) => filteredTQ.includes(id))
    : totals;

  const isGoodTest = (testQualityTypeId: number | undefined) =>
    goodTestQualityTypes.some((type) => type.id === testQualityTypeId);

  return relevantTestQualities.map((testQualityId) => {
    const testQuality = allTestQuality.find(
      (tq) => tq.id.toString() === testQualityId,
    );
    const data = entriesInRange.map((dateString) => ({
      x: format(new Date(dateString), DateFormat.Day),
      y: timeSeries.days[dateString]?.totals[testQualityId] || 0,
    }));

    return {
      id: testQuality?.name || '',
      data,
      color: isGoodTest(testQuality?.test_quality_type_id)
        ? vars.successMain
        : vars.warningMain,
    };
  });
}

export default React.memo(TestQualityChart);
