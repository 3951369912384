/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SubscriptionsHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { subscriptionsHistoryAdapter } from './subscriptionsHistoryAdapter';
import { subscriptionsHistoryFetchThunk } from './subscriptionsHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const subscriptionsHistorySlice = createSlice({
  name: 'subscriptionsHistory',
  initialState: subscriptionsHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    subscriptionsHistoryCleared(state) {
      state.loading = 'idle';
      subscriptionsHistoryAdapter.removeAll(state);
    },
    subscriptionsHistoryUpsertMany(
      state,
      action: PayloadAction<
        SubscriptionsHistory[] | Record<number, SubscriptionsHistory>
      >,
    ) {
      subscriptionsHistoryAdapter.upsertMany(state, action.payload);
    },
    subscriptionsHistoryUpsertOne(
      state,
      action: PayloadAction<SubscriptionsHistory>,
    ) {
      subscriptionsHistoryAdapter.upsertOne(state, action.payload);
    },
    subscriptionsHistoryUpdateOne(
      state,
      action: PayloadAction<Update<SubscriptionsHistory>>,
    ) {
      subscriptionsHistoryAdapter.updateOne(state, action.payload);
    },
    subscriptionsHistoryRemoveOne(state, action: PayloadAction<number>) {
      subscriptionsHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriptionsHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      subscriptionsHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionsHistoryAdapter.upsertMany(
          state,
          action.payload.subscriptionsHistory,
        );
      },
    );
    builder.addCase(subscriptionsHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  subscriptionsHistoryCleared,
  subscriptionsHistoryUpsertMany,
  subscriptionsHistoryUpsertOne,
  subscriptionsHistoryUpdateOne,
  subscriptionsHistoryRemoveOne,
} = subscriptionsHistorySlice.actions;

export const subscriptionsHistoryReducer = subscriptionsHistorySlice.reducer;
