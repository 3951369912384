import { defectResNativeDefectResSelectors } from 'src/gen/domain/defect_res_native_defect_res/defectResNativeDefectResSelector';
import { State } from '@bitmodern/redux/store';

export function defectResNativeDefectResByNativeRes(
  state: State,
  nativeDefectResIds: number[],
) {
  return defectResNativeDefectResSelectors
    .selectAll(state)
    .filter((pivot) => nativeDefectResIds.includes(pivot.native_defect_res_id));
}
