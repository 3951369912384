import { useOverlayTriggerState } from 'react-stately';
import React, { useCallback } from 'react';
import { Button } from '@bitmodern/bit-ui';
import { ConfirmDialog, DescriptiveAction } from 'src/components/organisms';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';

type Props = {
  className?: string;
  onCancel: () => Promise<any>;
};

export default function CancelSubscription({
  className = '',
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const cancelDialog = useOverlayTriggerState({});
  const handleCancel = useCallback(
    () => onCancel().finally(cancelDialog.close),
    [cancelDialog.close, onCancel],
  );
  const cancelMutation = useMutation(handleCancel);

  return (
    <>
      <DescriptiveAction
        action={
          <Button
            color="primaryLight"
            onClick={cancelDialog.open}
            size="medium">
            {t('cancelSubscription.action')}
          </Button>
        }
        className={className}
        description={t('cancelSubscription.description')}
        title={t('cancelSubscription.title')}
      />
      <ConfirmDialog
        loading={cancelMutation.isLoading}
        onCancel={cancelDialog.close}
        onConfirm={cancelMutation.mutate}
        open={cancelDialog.isOpen}
        title={t('cancelSubscription.cancelDialog.title')}>
        {t('cancelSubscription.cancelDialog.content')}
      </ConfirmDialog>
    </>
  );
}
