import { createSelector } from 'reselect';
import stepAdapter from 'src/gen/domain/step/stepAdapter';
import { State } from '@bitmodern/redux/store';

const stepsSelectors = stepAdapter.getSelectors(
  (state: State) => state.gen.step,
);

export function stepsSelector(state: State) {
  return stepsSelectors.selectAll(state);
}

export function stepSelector(state: State, id: number) {
  return stepsSelectors.selectById(state, id);
}

export const stepsByTestSelector = createSelector(
  [stepsSelector, (_, { testId }: { testId?: number }) => testId],
  (steps, testId) => {
    return steps
      .filter((step) => step.test_id === testId)
      .sort((a, b) => a.sequence - b.sequence);
  },
);
