import React from 'react';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import ProjectIntegrationEditView from './ProjectIntegrationEditView';
import { integrationProjectByProjectIdAndRefSelector } from 'src/packages/redux/state/integrationProject/selectors';
import { useParamSelector } from 'src/packages/redux/hooks';
import { integrationSelector } from 'src/packages/redux/state/integration/selectors';

export default function ProjectIntegrationEditViewContainer() {
  const { integrationId, projectId, externalRef } =
    useParams<typeof routes.PROJECT_INTEGRATION.params>();

  const integrationProject = useParamSelector(
    integrationProjectByProjectIdAndRefSelector,
    { projectId: parseInt(projectId, 10), externalReferenceId: externalRef },
  );
  const integration = useParamSelector(integrationSelector, {
    integrationId: parseInt(integrationId, 10),
  });

  return (
    <ProjectIntegrationEditView
      integrationProject={integrationProject}
      integration={integration}
    />
  );
}
