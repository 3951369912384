/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef, ReactNode, useRef, useState } from 'react';
import { useToggleState } from 'react-stately';
import {
  AriaCheckboxProps,
  mergeProps,
  useCheckbox,
  useFocus,
  useHover,
} from 'react-aria';
import classnames from 'classnames';
import { getFocusAccentCN } from '@bitmodern/bit-ui';
import FieldError from '../FieldError';
import styles from './PillCheckbox.module.scss';

type PillCheckboxProps = Exclude<
  AriaCheckboxProps,
  'isSelected' | 'isRequired' | 'isDisabled'
> & {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: ReactNode;
  required?: boolean;
  content: string;
};

const AriaPillCheckbox = forwardRef<HTMLLabelElement, PillCheckboxProps>(
  (props: PillCheckboxProps, ref) => {
    const {
      checked = false,
      className = '',
      disabled = false,
      error,
      isReadOnly,
      content,
      onChange,
      required = false,
    } = props;

    const ariaProps = {
      ...props,
      isDisabled: disabled,
      isRequired: required,
      isSelected: checked,
    };

    const isDisabled = disabled || isReadOnly || !onChange;

    const inputRef = useRef<HTMLInputElement>(null);
    const state = useToggleState(ariaProps);
    const [isFocused, setIsFocused] = useState(false);
    const { inputProps } = useCheckbox(ariaProps, state, inputRef);
    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { focusProps } = useFocus({
      onFocusChange: setIsFocused,
      isDisabled,
    });

    const pillCN = classnames(
      styles.checkbox,
      getFocusAccentCN(isHovered, isFocused),
      {
        [styles.checked]: inputProps.checked,
        [styles.disabled]: inputProps.disabled,
      },
    );

    return (
      <>
        <label
          {...mergeProps(hoverProps)}
          className={classnames(styles.root, className, {
            [styles.noInteractive]: isDisabled,
          })}
          ref={ref}>
          <span className={pillCN}>
            <input
              {...mergeProps(inputProps, focusProps)}
              className={classnames(styles.input, {
                [styles.noInteractiveInput]: isDisabled,
              })}
              ref={inputRef}
            />
            {content}
          </span>
        </label>
        {error && <FieldError className={styles.error}>{error}</FieldError>}
      </>
    );
  },
);

export default AriaPillCheckbox;
