/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { AppConfig } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appConfigAdapter } from './appConfigAdapter';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
  appConfigDeleteOneThunk,
  appConfigUpdateOneThunk,
  appConfigCreateOneThunk,
} from './appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: appConfigAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appConfigCleared(state) {
      state.loading = 'idle';
      appConfigAdapter.removeAll(state);
    },
    appConfigUpsertMany(
      state,
      action: PayloadAction<AppConfig[] | Record<number, AppConfig>>,
    ) {
      appConfigAdapter.upsertMany(state, action.payload);
    },
    appConfigUpsertOne(state, action: PayloadAction<AppConfig>) {
      appConfigAdapter.upsertOne(state, action.payload);
    },
    appConfigUpdateOne(state, action: PayloadAction<Update<AppConfig>>) {
      appConfigAdapter.updateOne(state, action.payload);
    },
    appConfigRemoveOne(state, action: PayloadAction<number>) {
      appConfigAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appConfigFetchManyThunk.pending, handlePending);
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appConfigAdapter.upsertMany(state, action.payload.appConfig);
    });
    builder.addCase(appConfigFetchManyThunk.rejected, handleRejected);
    builder.addCase(appConfigFetchOneThunk.pending, handlePending);
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appConfigAdapter.upsertOne(state, action.payload.appConfig[0]);
    });
    builder.addCase(appConfigFetchOneThunk.rejected, handleRejected);
    builder.addCase(appConfigDeleteOneThunk.pending, handlePending);
    builder.addCase(appConfigDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        appConfigAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(appConfigDeleteOneThunk.rejected, handleRejected);
    builder.addCase(appConfigUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, appConfigAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(appConfigUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appConfigAdapter.updateOne(state, action.payload);
    });
    builder.addCase(appConfigUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          appConfigAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(appConfigCreateOneThunk.pending, handlePending);
    builder.addCase(appConfigCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appConfigAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(appConfigCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.appConfig) {
          appConfigAdapter.upsertMany(state, action.payload.appConfig);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appConfig) {
        appConfigAdapter.upsertMany(state, action.payload.appConfig);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appConfig) {
        appConfigAdapter.upsertMany(state, action.payload.appConfig);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appConfig) {
        appConfigAdapter.upsertMany(state, action.payload.appConfig);
      }
    });
  },
});

export const {
  appConfigCleared,
  appConfigUpsertMany,
  appConfigUpsertOne,
  appConfigUpdateOne,
  appConfigRemoveOne,
} = appConfigSlice.actions;

export const appConfigReducer = appConfigSlice.reducer;
