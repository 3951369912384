import { createAction } from '@reduxjs/toolkit';
import { Filter } from '@testquality/sdk';
import { TestsFiltersKeys } from '../filters/reducer';

export const applyCustomFilterAction = createAction<{
  filter: Filter;
  type: TestsFiltersKeys;
}>('customFilters/applyCustomFilter');

export const breakCustomFilterAction = createAction<{
  type: TestsFiltersKeys;
}>('customFilters/removeCustomFilter');
