import React, { ReactNode } from 'react';
import styles from './TableEmpty.module.scss';

type Props = {
  button?: ReactNode;
  className?: string;
  colSpan: number;
  description: ReactNode;
  icon?: ReactNode;
  title: ReactNode;
};

export default function TableEmpty({
  className,
  button,
  colSpan,
  description,
  icon,
  title,
}: Props) {
  return (
    <tbody className={className}>
      <tr>
        <td className={styles.column} colSpan={colSpan}>
          <div className={styles.empty}>
            {icon && icon}
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {button && button}
          </div>
        </td>
      </tr>
    </tbody>
  );
}
