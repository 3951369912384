import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'src/components/Router/routes';
import { useQuery } from 'src/hooks/useQuery';
import { getClient } from 'src/Client';

import Debug from 'debug';
const debug = Debug('useSocialLogin');

function generateLoginTokenURL() {
  const webURL = window.location.origin;
  const loginTokenPath = routes.LOGIN_TOKEN({});
  debug('generateLoginTokenURL', { webURL, loginTokenPath });

  return `${webURL}${loginTokenPath}/?token=`;
}

function generateOnClick(
  loginMethod: string,
  loginArgs: Array<string | undefined>,
  setLoading: (val: boolean) => void,
  onError: (error) => void,
): () => void {
  return function () {
    // we only stop loading on error to keep it while doing href redirection
    debug('onClick.%s', loginMethod, { loginArgs });

    setLoading(true);
    return getClient()
      .getAuth()
      [loginMethod](...loginArgs)
      .then((res) => {
        debug('onClick.%s.res', loginMethod, res);
        debug('onClick.%s.redirect', loginMethod, res.redirect_url);
        window.location.href = res.redirect_url;
      })
      .catch((error) => {
        setLoading(false);
        onError(error);
      });
  };
}

export function useSocialLogin({
  onError = (error) => debug(error),
  verificationToken,
}: {
  onError?: (error) => void;
  verificationToken?: string;
}) {
  const history = useHistory();
  const { callback } = useQuery(['callback']);

  const [atlassianLoading, setAtlassianLoading] = useState(false);
  const [githubLoading, setGithubLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const callbackURL = useMemo(
    () => callback || generateLoginTokenURL(),
    [callback],
  );

  const onClickSSO = () => {
    history.push({
      pathname: routes.LOGIN_SSO({}),
      ...(callback && {
        search: `?${new URLSearchParams({ callback })}`,
      }),
    });
  };

  return {
    atlassian: {
      loading: atlassianLoading,
      handleClick: generateOnClick(
        'loginAtlassian',
        [callbackURL],
        setAtlassianLoading,
        onError,
      ),
    },
    github: {
      loading: githubLoading,
      handleClick: generateOnClick(
        'loginGithub',
        [callbackURL, verificationToken],
        setGithubLoading,
        onError,
      ),
    },
    google: {
      loading: googleLoading,
      handleClick: generateOnClick(
        'loginGoogle',
        [callbackURL, verificationToken],
        setGoogleLoading,
        onError,
      ),
    },
    sso: { handleClick: onClickSSO },
  };
}
