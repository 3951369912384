import React, { useRef } from 'react';
import classnames from 'classnames';
import {
  AriaRadioProps,
  mergeProps,
  useRadio,
  VisuallyHidden,
} from 'react-aria';
import { useFocusAccent } from '@bitmodern/bit-ui';
import { RadioContext } from '../RadioGroup/RadioGroup';
import styles from './RadioColor.module.scss';

interface Props extends AriaRadioProps {
  color: string;
  value: any;
}

export default function RadioColor({ color, ...props }: Props) {
  const { value } = props;

  const ref = useRef(null);
  const state = React.useContext(RadioContext);
  const { inputProps } = useRadio(props, state as any, ref);

  const { borderClassName, borderProps } = useFocusAccent({});

  const isSelected = state.selectedValue === value;

  const radioColorCN = classnames(styles.radioColor, borderClassName, {
    [styles.selected]: isSelected,
  });

  return (
    <label htmlFor={inputProps.id}>
      <VisuallyHidden>
        <input {...mergeProps(inputProps, borderProps)} ref={ref} />
      </VisuallyHidden>
      <div className={radioColorCN}>
        <div className={styles.color} style={{ backgroundColor: color }} />
      </div>
    </label>
  );
}
