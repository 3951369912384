import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
  Content,
  Loading,
  PanelHeader,
  Spacer,
  Surface,
  MenuItem,
  Menu,
  Dropdown,
  IconButton,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppSelector } from '@bitmodern/redux/store';
import { useQuery } from 'src/hooks/useQuery';
import { Test, SuiteTest } from '@testquality/sdk';
import { SuitesTree, EmptyTests, Filters } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import {
  AddFolderIcon,
  MoreIcon,
  TestCreateIcon,
  UploadIcon,
} from '@bitmodern/bit-ui/icons';
import { rootPlanSelector } from '@bitmodern/redux/state/plans/selectors';
import useParams from 'src/hooks/useParams';
import useModalManager from 'src/hooks/useModalManager';
import { filteredSuitesTreeByPlanSelector } from '@bitmodern/redux/state/suites/selectors';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { rootPlanSuiteSelector } from '@bitmodern/redux/state/planSuites/selectors';
import vars from 'src/export.scss';
import styles from './TestsView.module.scss';
import { useParamSelector } from 'src/packages/redux/hooks';
import { TEST_TYPE } from 'src/components/organisms/TreeBase/treeTypes';

type Props = {
  loading: boolean;
};

export default function TestsView({ loading }: Props) {
  const { t } = useTranslation();
  const { showModal } = useModalManager();
  const { site, projectId } = useParams<typeof routes.PROJECT.params>();
  const { folderId } = useQuery(['folderId']);
  const { openDrawer } = useDrawerManager();

  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, parseInt(projectId, 10)),
  );
  const rootPlanSuite = useParamSelector(rootPlanSuiteSelector, {
    projectId: parseInt(projectId, 10),
  });
  const tree = useAppSelector((state) =>
    filteredSuitesTreeByPlanSelector(state, {
      projectId: parseInt(projectId, 10),
      filterType: 'tests',
    }),
  );
  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: parseInt(projectId, 10) }),
  );
  const suiteTests = tree.filter((t) => t.type === TEST_TYPE);
  const lastSuiteTest = suiteTests[suiteTests.length - 1]?.data.suiteTest;

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const onClickCreateTest = () => {
    showModal({
      modalName: 'testCreate',
      modalProps: { planSuite: rootPlanSuite, suiteTest: lastSuiteTest },
      type: 'modal',
    });
  };

  const onClickCreateFolder = () => {
    showModal({
      modalName: 'suiteCreate',
      modalProps: { planSuite: rootPlanSuite },
      type: 'modal',
    });
  };

  const handleImportData = () => {
    showModal({
      pathname: routes.IMPORT({ site, projectId }),
      type: 'routeModal',
    });
  };

  if (!rootPlan) return null;

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          <MenuItem
            startAdornment={<UploadIcon color={vars.textPrimary} size={20} />}
            onClick={handleImportData}>
            {t('plans.actions.import')}
          </MenuItem>
        </Menu>
      }>
      <IconButton title={t('plans.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  return (
    <>
      <Helmet>
        <title>{t('tests.head.title')}</title>
      </Helmet>
      {loading ? (
        <Loading className={styles.loading} size={48} />
      ) : (
        <Content fullHeight>
          <Surface className={styles.tests}>
            <PanelHeader
              actions={
                <Spacer>
                  <Filters type="tests" parentId={projectId} />
                  <IconButton
                    onClick={onClickCreateFolder}
                    title={t('tests.actions.createFolder')}>
                    <AddFolderIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                  <IconButton
                    onClick={onClickCreateTest}
                    title={t('tests.actions.createTest')}>
                    <TestCreateIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                  {more}
                </Spacer>
              }
              title={t('tests.title')}
            />
            <div className={styles.panelContent}>
              {tree.length === 0 ? (
                <EmptyTests filterType="tests" potentialAmount={tests.length} />
              ) : (
                <SuitesTree
                  current={parseInt(folderId, 10)}
                  id={rootPlan.id.toString()}
                  onClickTest={onClickTest}
                  plan={rootPlan}
                  suitesTree={tree}
                  withInclude={false}
                />
              )}
            </div>
          </Surface>
        </Content>
      )}
    </>
  );
}
