import React from 'react';
import { Attachment } from '@testquality/sdk';
import { Dropzone } from '@bitmodern/bit-ui';
import AttachmentItem from '../AttachmentItem';
import styles from './Attachments.module.scss';

type Props = {
  attachments: Attachment[];
  loading: boolean;
  onDropFiles: (acceptedFiles: File[]) => void;
};

export default function Attachments({
  attachments,
  loading,
  onDropFiles,
}: Props) {
  return (
    <div className={styles.attachmentsContainer}>
      {attachments.map((attachment) => (
        <AttachmentItem key={attachment.id} item={attachment} />
      ))}
      <Dropzone
        className={styles.dropzone}
        onDrop={onDropFiles}
        loading={loading}
      />
    </div>
  );
}
