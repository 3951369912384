import { useEffect, useState } from 'react';
import { useAppSelector } from '@bitmodern/redux/store';
import { themeSet } from '@bitmodern/redux/state/theme/themeSlice';
import { themeColorSelector } from '@bitmodern/redux/state/theme/selectors';
import { useDispatch } from 'react-redux';

type ThemeColor = 'light' | 'dark';

export function useTheme(): [ThemeColor, (ThemeColor) => void] {
  const themeColor = useAppSelector(themeColorSelector);
  const [theme, setTheme] = useState(themeColor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.documentElement.getAttribute('data-theme') !== theme) {
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
      dispatch(themeSet(theme));
    }
  }, [theme, dispatch]);

  return [themeColor, setTheme];
}
