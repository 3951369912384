/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { Link, useParams } from 'react-router-dom';
import { ArrowDownIcon, UploadIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import { useAppSelector } from '@bitmodern/redux/store';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { Button, Checkbox, IconButton, Input } from '@bitmodern/bit-ui';
import { virtualsSelector } from '@bitmodern/redux/state/virtuals/selectors';
import { parseCSV } from 'src/utils/csv';
import { CustomMappingType, MappingType } from '../MappingTypes';
import ImportTable from '../ImportTable/ImportTable';
import MappingItem from '../Mapping/MappingItem';
import {
  EntityAttribute,
  mappingSelectOptions,
  matchMapping,
} from '../Mapping/utils/constants/MappingItemSelectOptions';

import getId from '../utils/functions/generateId';
import styles from './CSV.module.scss';
import ExtraFields from '../ExtraFields';
import {
  AssociationType,
  AttributeDataType,
} from '../../../../enums/MappingItemsType';
import useCSV from '../Junit/useJUnit';
import useModalManager from 'src/hooks/useModalManager';

export default function CSV() {
  const { t } = useTranslation();
  const { site, projectId } = useParams<typeof routes.PLAN.params>();
  const { hideModal } = useModalManager();
  const { formik, uploadFinished, handleDropFiles } = useCSV({
    projectId: parseInt(projectId, 10),
  });
  const [openAdvanced, setIsOpenAdvanced] = useState(false);
  const [extraInfo, setExtraInfo] = useState({
    project: false,
    plan: false,
    suite: false,
  });

  const virtuals = useAppSelector(virtualsSelector);
  const caseTypes = useAppSelector(caseTypesSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const users = useAppSelector(usersSelector);

  useEffect(() => {
    if (formik.values.mappings.length > 0) {
      setExtraInfo((s) => {
        return Object.keys(s).reduce(
          (acu, item) => {
            acu[item] = !formik.values.mappings.some(
              (map) =>
                map.to?.entity.toLowerCase() === item.toLowerCase() &&
                map.include,
            );
            return acu;
          },
          {} as {
            project: boolean;
            plan: boolean;
            suite: boolean;
          },
        );
      });
    }
  }, [formik.values.mappings]);

  const onChangeMapping = useCallback(
    (newMapping) => {
      const indexFound = formik.values.mappings.findIndex(
        (m) => newMapping.from === m.from,
      );
      if (indexFound !== -1) {
        const copy = [...formik.values.mappings];
        copy.splice(indexFound, 1, newMapping);
        formik.setFieldValue('mappings', copy);
      } else {
        formik.setFieldValue('mappings', newMapping);
      }
    },
    [formik],
  );

  const getOptionsToMap = useCallback(
    (attribute: EntityAttribute) => {
      switch (attribute.association_entity) {
        case 'case_type':
          return caseTypes;
        case 'case_priority':
          return casePriorities;
        case 'user':
          return users;
        default:
          return [];
      }
    },
    [casePriorities, caseTypes, users],
  );

  const getCustomizeOptions = useCallback(
    (attribute: EntityAttribute, mappingName, rowsF) => {
      const optionsToMap: any[] = getOptionsToMap(attribute);

      const matching: any[] = [];
      rowsF.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          if (key === mappingName) {
            matching.push(value);
          }
        });
      });
      const uniqueMatching = matching.filter(
        (v, i, a) => a.indexOf(v) === i && v,
      );

      const customizeValuesMap: CustomMappingType[] = uniqueMatching.map(
        (match, index) => {
          const optionFounded = optionsToMap.find((option) => {
            if (!match) return false;
            if (attribute.association_entity === 'user' && option.email)
              return option.email.toLowerCase() === match.toLowerCase();
            if (attribute.association_entity !== 'user' && option.name)
              return option.name.toLowerCase() === match.toLowerCase();
            return false;
          });

          return {
            id: index,
            from: match,
            to: {
              id: optionFounded ? optionFounded.id : match,
              key: optionFounded ? optionFounded.key : match,
              name: optionFounded
                ? optionFounded.name || optionFounded.email
                : '',
              description: optionFounded ? optionFounded.description : '',
              client_id: optionFounded ? optionFounded.client_id : '',
              virtual: optionFounded ? optionFounded.virtual : '',
              metadata_model: optionFounded ? optionFounded.metadata_model : '',
            },
          };
        },
      );

      return customizeValuesMap;
    },
    [getOptionsToMap],
  );

  const mappingOptions = useMemo(() => {
    const allMappingOptions = mappingSelectOptions;
    virtuals.forEach((virtual) => {
      const virtualSch = virtual.virtual_schema || {};
      if (virtualSch.columns) {
        Object.entries(virtualSch.columns).forEach(([name, value]: any) => {
          allMappingOptions.push({
            entity: virtual.table_name,
            id_display: 'TC',
            // @ts-expect-error
            type: AttributeDataType[value.type],
            display_name: `${
              virtual.table_name.charAt(0).toUpperCase() +
              virtual.table_name.slice(1)
            } ${name}`,
            domain: name,
            name,
            association_type: AssociationType.custom,
          });
        });
      }
    });
    return allMappingOptions;
  }, [virtuals]);

  const csvToMapping = useCallback(
    (str: string) => {
      const { headers, rows } = parseCSV(str, formik.values.delimiter);

      const fileFormatted: MappingType[] = headers.map((header, index) => {
        const newMapping: MappingType = {
          include: true,
          from: { name: header, index },
        };

        const headerTrim = header.trim().toLowerCase();
        const match = Object.entries(matchMapping).find(([, value]) =>
          value.find((option) => option.toLowerCase() === headerTrim),
        );
        const [optionFounded] = match || [];

        if (optionFounded) {
          const mappingTo = mappingOptions.find(
            (item) => getId(item) === optionFounded,
          );
          newMapping.to = mappingTo;

          if (mappingTo?.association_entity) {
            newMapping.data = getCustomizeOptions(
              mappingTo,
              newMapping.from.name,
              rows,
            );
          }
        }
        return newMapping;
      });
      return fileFormatted;
    },
    [formik.values.delimiter, mappingOptions, getCustomizeOptions],
  );

  const { setFieldValue } = formik;

  const onParseFile = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const reader = new FileReader();

        const result = files[0];
        reader.onload = () => {
          const text = reader.result;
          if (typeof text === 'string') {
            setFieldValue('mappings', csvToMapping(text));
          }
        };
        reader.readAsText(result);
      }
    },
    [csvToMapping, setFieldValue],
  );

  useEffect(() => {
    onParseFile(formik.values.filesToSend);
  }, [formik.values.delimiter, formik.values.filesToSend, onParseFile]);

  const handleChangeFiles = (files: File[]) => {
    handleDropFiles(files);
    onParseFile(files);
  };

  const onSaveConfiguration = () => {
    const config = {
      delimiter: formik.values.delimiter,
      useUtf8Encode: formik.values.useUtf8Encode,
      escape: formik.values.escape,
      enclosure: formik.values.enclosure,
      mappings: formik.values.mappings,
      importDataType: {
        id: 'Tests Cases',
      },
    };
    const file = new Blob([JSON.stringify(config)], { type: 'text/plain' });
    saveAs(file, 'tests.conf');
  };

  const onLoadConfiguration = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.conf';
    input.onchange = (event) => {
      // @ts-expect-error
      const file = event?.target?.files?.[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        if (typeof text === 'string') {
          const config = JSON.parse(text);
          formik.setFieldValue('delimiter', config.delimiter);
          formik.setFieldValue('escape', config.escape);
          formik.setFieldValue('enclosure', config.enclosure);
          formik.setFieldValue('useUtf8Encode', config.useUtf8Encode);
          formik.setFieldValue('mappings', config.mappings);
        }
      };
      reader.readAsText(file);
    };
    input.click();
  };

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <div className={styles.content}>
        <div className={styles.container}>
          <UploadIcon color={vars.textPrimary} size={24} />
          <div className={styles.title}>
            <span> {t('importDataForm.csv.title')}</span>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.description}>
            <p>{t('importDataForm.csv.description')}</p>
          </div>
        </div>
        <div className={styles.dropzone}>
          <ImportTable
            onChangeFiles={handleChangeFiles}
            filesTypeAccepted={['.csv']}
            files={formik.values.filesToSend}
          />
        </div>
        {uploadFinished && (
          <div className={styles.container}>
            <Link className={styles.link} to={routes.RUNS({ site, projectId })}>
              {t('importDataForm.importResults')}
            </Link>
          </div>
        )}
        <div className={styles.mappings}>
          {formik.values.mappings.map((mapping) => (
            <MappingItem
              mappingOptions={mappingOptions}
              caseTypes={caseTypes}
              casePriorities={casePriorities}
              users={users}
              key={mapping.from.index}
              mapping={mapping}
              onChangeMapping={onChangeMapping}
            />
          ))}
        </div>
        <ExtraFields formik={formik} extraFields={extraInfo} />

        {formik.values.mappings.length > 0 && (
          <div className={styles.configuration}>
            <Button
              color="primaryLight"
              onClick={onSaveConfiguration}
              size="small">
              {t('importDataForm.mappings.save')}
            </Button>{' '}
            {t('importDataForm.mappings.or')}{' '}
            <Button
              color="primaryLight"
              onClick={onLoadConfiguration}
              size="small">
              {t('importDataForm.mappings.load')}
            </Button>{' '}
            {t('importDataForm.mappings.confFile')}
          </div>
        )}

        {formik.values.mappings.length > 0 && (
          <div className={styles.advanced}>
            {t('importDataForm.mappings.advanced')}
            <IconButton
              boxed
              className={styles.actionButton}
              onClick={() => setIsOpenAdvanced((s) => !s)}>
              <ArrowDownIcon size={24} />
            </IconButton>
          </div>
        )}
        {openAdvanced && (
          <div className={styles.mappings}>
            <Input
              autoComplete="off"
              fullWidth
              maxLength={1}
              label={t('importDataForm.mappings.delimiter')}
              name="delimiter"
              onChange={(event) =>
                formik.setFieldValue('delimiter', event.target.value)
              }
              value={formik.values.delimiter}
            />
            <Input
              autoComplete="off"
              label={t('importDataForm.mappings.encoding')}
              name="encoding"
              fullWidth
              placeholder="UTF-8, Windows-1251, macintosh, CP430, etc"
              onChange={(event) =>
                formik.setFieldValue('encoding', event.target.value)
              }
              value={formik.values.encoding}
            />
            <Input
              autoComplete="off"
              fullWidth
              maxLength={1}
              label={t('importDataForm.mappings.escape')}
              name="escape"
              onChange={(event) =>
                formik.setFieldValue('escape', event.target.value)
              }
              value={formik.values.escape}
            />
            <Input
              autoComplete="off"
              fullWidth
              maxLength={1}
              label={t('importDataForm.mappings.enclosure')}
              name="enclosure"
              onChange={(event) =>
                formik.setFieldValue('enclosure', event.target.value)
              }
              value={formik.values.enclosure}
            />
            <Checkbox
              checked={formik.values.useUtf8Encode}
              className={styles.checkbox}
              onChange={(checked) =>
                formik.setFieldValue('useUtf8Encode', checked)
              }>
              {t('importDataForm.mappings.useUtf8Encode')}
            </Checkbox>
          </div>
        )}
        <div className={styles.submit}>
          {uploadFinished ? (
            <Button color="primaryDark" name="cancel" onClick={hideModal}>
              {t('importDataForm.close')}
            </Button>
          ) : (
            <Button
              loading={formik.isSubmitting}
              type="submit"
              disabled={formik.values.filesToSend.length === 0}>
              {t('importDataForm.submit')}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
