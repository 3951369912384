import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { Select, TextArea } from '@bitmodern/bit-ui';
import {
  accessRolesSelector,
  isCurrentUserAdminSelector,
} from '@bitmodern/redux/state/accessRoles/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { AccessRoleEnum } from 'src/enums/AccessRoleEnum';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';

import styles from './InvitesStepForm.module.scss';

export * from './useInvitesStepForm';

export type InvitesStepFormValues = {
  emails: string;
  accessRole?: number;
};

export type InvitesStepFormProps = {
  formik: FormikProps<InvitesStepFormValues>;
};

export function InvitesStepForm({ formik }: InvitesStepFormProps) {
  const { t } = useTranslation();
  const accessRoles = useAppSelector(accessRolesSelector);
  const isCurrentUserAdmin = useAppSelector(isCurrentUserAdminSelector);

  /* NOTE: Only admins can invite users with roles other than viewer */
  const disabledAccessRoles = isCurrentUserAdmin
    ? undefined
    : new Set(
        accessRoles
          .filter((ar) => ar.key !== AccessRoleEnum.VIEWER)
          .map((role) => role.id),
      );

  const accessRolesOptions = useMemo(() => {
    return accessRoles.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  }, [accessRoles]);

  const onChangeAccessRoles = (value) => {
    formik.setFieldValue('accessRole', value);
  };

  const onPasteEmails = (e) => {
    const emailRegex = new RegExp(
      /[a-zA-Z0-9+._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+/,
      'gi',
    );
    const traillingCommasRegex = new RegExp(/^[,\s]+|[,\s]+$/, 'g');
    const pastedText: string = e.clipboardData.getData('Text');
    const parsedEmails: string[] | null = pastedText.match(emailRegex);

    if (parsedEmails?.length) {
      const newEmailsValue = formik.values.emails
        ? `${formik.values.emails.replace(
            traillingCommasRegex,
            '',
          )}, ${parsedEmails.join(', ')}`
        : parsedEmails.join(', ');

      formik.setFieldValue('emails', newEmailsValue);
      e.preventDefault();
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}>
      <TextArea
        className={styles.textArea}
        error={formikError(formik, 'emails')}
        placeholder={t('getStarted.invitesStep.form.emailsPlaceholder')}
        name="emails"
        onChange={formik.handleChange}
        onPaste={onPasteEmails}
        onFocus={formik.handleBlur}
        showLabel={false}
        value={formik.values.emails}
      />
      <Select
        aria-label={t('getStarted.invitesStep.form.accessRole')}
        className={styles.select}
        disabledKeys={disabledAccessRoles}
        error={formikError(formik, 'accessRole')}
        name="accessRole"
        placeholder={t('getStarted.invitesStep.form.accessRolePlaceholder')}
        onChange={onChangeAccessRoles}
        options={accessRolesOptions}
        value={formik.values.accessRole}
      />
    </form>
  );
}
