import { getResponse } from '@testquality/sdk';
import { getClient } from 'src/Client';

export function getPersonalAccessTokens() {
  return getResponse<any>(getClient().api, {
    method: 'get',
    url: 'oauth/personal-access-tokens',
  });
}

export function createPersonalAccessToken(params: { name: string }) {
  return getResponse<any>(getClient().api, {
    method: 'post',
    url: 'oauth/personal-access-tokens',
    data: params,
  });
}

export function deletePersonalAccesToken(id: string) {
  return getResponse<any>(getClient().api, {
    method: 'delete',
    url: `oauth/personal-access-tokens/${id}`,
  }).catch(() => {});
}
