/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  baseCapabilityBaseIntegrationDetachThunk,
  baseCapabilityBaseIntegrationFetchManyThunk,
  baseCapabilityBaseIntegrationFetchOneThunk,
  baseCapabilityBaseIntegrationUpdateOneThunk,
  baseCapabilityBaseIntegrationCreateOneThunk,
} from './baseCapabilityBaseIntegrationThunk';
import { TQStateProperties } from '../../store/TQState';
import { baseCapabilityBaseIntegrationAdapter } from './baseCapabilityBaseIntegrationAdapter';
import {
  baseIntegrationDeleteOneThunk,
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityDeleteOneThunk,
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import { baseIntegrationRemoveOne } from '../base_integration/baseIntegrationSlice';
import { baseCapabilityRemoveOne } from '../base_capability/baseCapabilitySlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const baseCapabilityBaseIntegrationSlice = createSlice({
  name: 'baseCapabilityBaseIntegration',
  initialState:
    baseCapabilityBaseIntegrationAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    baseCapabilityBaseIntegrationCleared:
      baseCapabilityBaseIntegrationAdapter.removeAll,
    baseCapabilityBaseIntegrationUpsertMany:
      baseCapabilityBaseIntegrationAdapter.upsertMany,
    baseCapabilityBaseIntegrationUpsertOne:
      baseCapabilityBaseIntegrationAdapter.upsertOne,
    baseCapabilityBaseIntegrationUpdateOne:
      baseCapabilityBaseIntegrationAdapter.updateOne,
    baseCapabilityBaseIntegrationRemoveOne:
      baseCapabilityBaseIntegrationAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      baseCapabilityBaseIntegrationDetachThunk.pending,
      handlePending,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationDetachThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload && action.payload.id) {
          baseCapabilityBaseIntegrationAdapter.removeOne(
            state,
            action.payload.id,
          );
        }
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationDetachThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchManyThunk.pending,
      handlePending,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseCapabilityBaseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseCapabilityBaseIntegration,
        );
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchManyThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchOneThunk.pending,
      handlePending,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseCapabilityBaseIntegrationAdapter.upsertOne(
          state,
          action.payload.baseCapabilityBaseIntegration[0],
        );
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationFetchOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, baseCapabilityBaseIntegrationAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseCapabilityBaseIntegrationAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            baseCapabilityBaseIntegrationAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationCreateOneThunk.pending,
      handlePending,
    );
    builder.addCase(
      baseCapabilityBaseIntegrationCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseCapabilityBaseIntegrationAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(
      baseCapabilityBaseIntegrationCreateOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.baseCapabilityBaseIntegration) {
          baseCapabilityBaseIntegrationAdapter.upsertMany(
            state,
            action.payload.baseCapabilityBaseIntegration,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.baseCapabilityBaseIntegration) {
        baseCapabilityBaseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseCapabilityBaseIntegration,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.baseCapabilityBaseIntegration) {
        baseCapabilityBaseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseCapabilityBaseIntegration,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.baseCapabilityBaseIntegration) {
        baseCapabilityBaseIntegrationAdapter.upsertMany(
          state,
          action.payload.baseCapabilityBaseIntegration,
        );
      }
    });

    builder.addCase(
      baseIntegrationDeleteOneThunk.fulfilled,
      (state, action) => {
        baseCapabilityBaseIntegrationAdapter.removeMany(
          state,
          values(state.entities)
            .filter(
              (value) =>
                value !== undefined &&
                value.base_integration_id === action.payload.id,
            )
            .map<number | undefined>((m) => (m ? m.id : undefined))
            .filter((f) => f !== undefined) as number[],
        );
      },
    );
    builder.addCase(baseIntegrationRemoveOne, (state, action) => {
      baseCapabilityBaseIntegrationAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined &&
              value.base_integration_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(baseCapabilityDeleteOneThunk.fulfilled, (state, action) => {
      baseCapabilityBaseIntegrationAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined &&
              value.base_capability_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(baseCapabilityRemoveOne, (state, action) => {
      baseCapabilityBaseIntegrationAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined &&
              value.base_capability_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  baseCapabilityBaseIntegrationCleared,
  baseCapabilityBaseIntegrationUpsertMany,
  baseCapabilityBaseIntegrationUpsertOne,
  baseCapabilityBaseIntegrationUpdateOne,
  baseCapabilityBaseIntegrationRemoveOne,
} = baseCapabilityBaseIntegrationSlice.actions;

export const baseCapabilityBaseIntegrationReducer =
  baseCapabilityBaseIntegrationSlice.reducer;
