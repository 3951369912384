/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { RequirementApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { requirementTestNormalizer } from '../requirement_test/requirementTestNormalize';

export const requirementNormalizer = (
  model: RequirementApi,
  cache: NormalizerCache,
) => {
  if (!cache.requirement) {
    cache.requirement = [];
  }
  cache.requirement.push(model);

  if (model.requirement_test && model.requirement_test.length) {
    model.requirement_test.forEach((child) => {
      requirementTestNormalizer(child, cache);
    });
    model.requirement_test = undefined;
  }
};
