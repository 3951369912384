import React from 'react';
import { Clipboard } from '@bitmodern/bit-ui';

export default function Code({
  children,
  className = '',
}: {
  children: string;
  className?: string;
}) {
  const type = className.includes('language-') ? 'horizontal' : 'inline';

  return (
    <Clipboard text={children} type={type}>
      {children}
    </Clipboard>
  );
}
