import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch } from '@bitmodern/redux/store';
import { Dialog } from '@bitmodern/bit-ui';
import { milestoneCreateOneThunk } from 'src/gen/domain/milestone/milestoneThunk';
import { routes } from 'src/components/Router';
import useModalManager from 'src/hooks/useModalManager';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import MilestoneForm from '../MilestoneForm';

export default function MilestoneCreateDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { hideModal } = useModalManager();
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );

  const onCreateMilestone = (values) => {
    return dispatch(
      milestoneCreateOneThunk({
        data: {
          description: values.description,
          is_complete: false,
          name: values.name,
          project_id: parseInt(projectId, 10),
          release_date: values.releaseDate,
          start_date: values.startDate,
        },
      }),
    ).then(hideModal);
  };

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="small"
      title={t('milestoneFormDialog.title')}>
      <MilestoneForm onSubmit={onCreateMilestone} />
    </Dialog>
  );
}
