import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import {
  Notifications,
  Project,
  Run,
  RunResult,
  BatchService,
} from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { IconButton, Button } from '@bitmodern/bit-ui';
import SettingsIcon from '@bitmodern/bit-ui/icons/SettingsIcon';
import { routes } from 'src/components/Router';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import useMutation from 'src/hooks/useMutation';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  notificationsDeleteOneThunk,
  notificationsUpdateOneThunk,
} from 'src/gen/domain/notifications/notificationsThunk';
import { watchNotificationsSelector } from '@bitmodern/redux/state/notifications/selectors';
import {
  passStatusSelector,
  statusesSelector,
} from '@bitmodern/redux/state/statuses/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { DateFormat } from 'src/enums/DateFormatEnum';
import vars from 'src/export.scss';
import WatchItem from '../WatchItem';
import styles from './WatchTray.module.scss';

export enum WatchEntity {
  RUN_RESULT = 'RunResult',
  RUN = 'Run',
}

export interface NotificationRunResult extends RunResult {
  test_key: number;
  test_name: string;
  run_name: string;
}

type Props = {
  onClickSettings: () => void;
  watchCount: number;
};

function WatchTray({ onClickSettings, watchCount }: Props) {
  const { site } = useParamsInRoute<typeof routes.HOME.params>(
    routes.HOME.path,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const watchNotifications = useAppSelector(watchNotificationsSelector);
  const statuses = useAppSelector(statusesSelector);
  const passStatus = useAppSelector(passStatusSelector);
  const users = useAppSelector(usersSelector);
  const projects = useAppSelector(projectsSelector);
  const drawer = useDrawerManager();
  const history = useHistory();

  const deleteAllWatches = useCallback(() => {
    const batch = new BatchService();
    watchNotifications.forEach((unreadNotification) => {
      dispatch(
        notificationsDeleteOneThunk({
          id: unreadNotification.id,
          batch,
        }),
      );
    });
    return batch.executeBatch();
  }, [watchNotifications, dispatch]);

  const deleteAllWatchesMutation = useMutation(deleteAllWatches);

  const navigationBar = (
    <div className={styles.navigationBar}>
      <h3 className={styles.navigationTitle}>{t('watch.navigation.title')}</h3>
      <div className={styles.navigationActions}>
        {Boolean(watchCount) && (
          <Button
            size="small"
            color="primaryLight"
            onClick={deleteAllWatchesMutation.mutate}
            loading={deleteAllWatchesMutation.isLoading}>
            {t('watch.navigation.clearAll')}
          </Button>
        )}
        <IconButton
          onClick={onClickSettings}
          title={t('watch.navigation.settings')}>
          <SettingsIcon color={vars.textPrimary} size={20} />
        </IconButton>
      </div>
    </div>
  );

  if (!watchCount) {
    return (
      <div className={styles.trayContainer}>
        {navigationBar}
        <div className={styles.empty}>
          <h5 className={styles.emptyHeader}>{t('watch.empty.header')}</h5>
          <p className={styles.emptyDescription}>
            {t('watch.empty.description')}
          </p>
        </div>
      </div>
    );
  }

  function renderWatch(notification: Notifications) {
    /* NOTE: Temporary check while we migrate to data as an object */
    const updatedEntity: NotificationRunResult | Run = Array.isArray(
      notification.data,
    )
      ? notification.data[0]
      : notification.data;
    const project = projects.find((p) => p.id === updatedEntity.project_id);
    const updatedBy = users.find(
      (user) => user.id === updatedEntity.updated_by,
    );
    const status = getStatus(updatedEntity);

    if (!status || !updatedBy || !project) return null;

    const wasRead = Boolean(notification.read_at);
    const onDelete = () => {
      return dispatch(
        notificationsDeleteOneThunk({
          id: notification.id,
        }),
      );
    };
    const pushOptions = getPushOptions(updatedEntity, project);
    const onClick = () => {
      history.push(pushOptions);
      if (!wasRead) {
        dispatch(
          notificationsUpdateOneThunk({
            id: notification.id,
            data: {
              read_at: format(new Date(), DateFormat.UpdateEntity),
            },
          }),
        );
      }
    };

    const getRunResultName = (runResult: NotificationRunResult) => {
      if (runResult.test_key && runResult.test_name) {
        return `TC-${runResult.test_key} ${runResult.test_name}`;
      }

      /* NOTE: Fallback for old notifications format */
      return `Run ${runResult.key}`;
    };

    const runName =
      updatedEntity.metadata_model === WatchEntity.RUN &&
      (updatedEntity as Run).name;
    const runResultName =
      updatedEntity.metadata_model === WatchEntity.RUN_RESULT &&
      getRunResultName(updatedEntity as NotificationRunResult);

    return (
      <WatchItem
        key={notification.id}
        runName={runName}
        runResultName={runResultName}
        project={project}
        status={status}
        updatedAt={notification.updated_at}
        updatedBy={updatedBy}
        wasRead={wasRead}
        onClick={onClick}
        onDelete={onDelete}
      />
    );
  }

  function getStatus(entity: NotificationRunResult | Run) {
    if (entity.metadata_model === WatchEntity.RUN_RESULT) {
      return statuses.find(
        (s) => s.id === (entity as NotificationRunResult).status_id,
      );
    }
    return passStatus;
  }

  function getPushOptions(
    entity: NotificationRunResult | Run,
    project: Project,
  ) {
    let search;
    let runId;

    if (entity.metadata_model === WatchEntity.RUN_RESULT) {
      search = drawer.generateDrawerUrlParams({
        resultId: (entity as NotificationRunResult).id,
      });
      runId = (entity as NotificationRunResult).run_id.toString();
    }
    if (entity.metadata_model === WatchEntity.RUN) {
      runId = (entity as Run).id.toString();
    }

    return {
      pathname: routes.RUN({
        site,
        projectId: project.id.toString(),
        runId,
      }),
      search,
    };
  }

  return (
    <div className={styles.trayContainer}>
      {navigationBar}
      <ol className={styles.watches}>{watchNotifications.map(renderWatch)}</ol>
    </div>
  );
}

export default WatchTray;
