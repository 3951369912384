/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectComponentAdapter } from './defectComponentAdapter';

export const defectComponentSelectors = defectComponentAdapter.getSelectors(
  (state: State) => state.gen.defectComponent,
);
