import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { FormikProps } from 'formik';
import {
  Button,
  DialogContent,
  Input,
  PanelActions,
  RadioColor,
  RadioGroup,
  RadioIcon,
  Select,
} from '@bitmodern/bit-ui';
import {
  ProjectColors,
  ProjectColorsDisplay,
  ProjectIcons,
  ProjectIconsDisplay,
} from 'src/enums/ProjectEnums';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { IntegrationProject, BaseIntegration, Project } from '@testquality/sdk';
import { integrationSelectors } from 'src/gen/domain/integration/integrationSelector';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { integrationUserByUserIdSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { redirectForAuth } from '@bitmodern/services/integrationService';
import vars from 'src/export.scss';
import styles from './ProjectForm.module.scss';
import BaseIntegrationIcon from '../BaseIntegrationIcon';
import { routes } from '../../Router';
import { useQuery } from '../../../hooks/useQuery';
import IntegrationProjectItem from '../IntegrationProject/IntegrationProjectItem';
import { integrationProjectsSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { baseIntegrationByBaseCapabilityIdSelector } from '@bitmodern/redux/state/baseIntegration/selectors';

type Props = {
  edit: boolean;
  formik: FormikProps<{
    name?: string;
    description?: string;
    icon?: ProjectIcons;
    color?: ProjectColors;
    baseIntegrationId?: number;
    // integrationProject?: Partial<IntegrationProject>;
  }>;
  project?: Project;
  submitClassName?: string;
  /** Setup integration flow if selectect integration is not added to client site */
  setupIntegrations?: boolean;
  fields?: {
    description?: boolean;
    icon?: boolean;
    color?: boolean;
  };
};

export default function ProjectForm({
  edit,
  fields = {},
  formik,
  project,
  submitClassName,
  setupIntegrations = true,
}: Props) {
  const defaultFields = { description: true, icon: true, color: true };
  const formFields = { ...defaultFields, ...fields };

  const { t } = useTranslation();
  const history = useHistory();
  const { site } = useParams<typeof routes.PROJECT_CREATE.params>();
  const { integration_id } = useQuery(['integration_id']);

  const user = useAppSelector(currentUserSelector);
  const integrationUsers = useAppSelector((state) =>
    integrationUserByUserIdSelector(state, { userId: user?.id }),
  );
  const integrations = useAppSelector(integrationSelectors.selectAll);
  const baseIntegrations = useAppSelector((state) =>
    baseIntegrationByBaseCapabilityIdSelector(state, { baseCapabilityId: 5 }),
  );

  const integrationProjects = useAppSelector(integrationProjectsSelector);
  const location = useLocation();

  const integration = useMemo(() => {
    const localInt = integrations.find(
      (i) => i.base_integration_id === formik.values.baseIntegrationId,
    );
    if (localInt) {
      const localIu = integrationUsers.find(
        (iu) => iu.integration_id === localInt.id,
      );
      if (localIu) {
        return localInt;
      }
    }
    return undefined;
  }, [formik.values.baseIntegrationId, integrations, integrationUsers]);

  const getBaseIntegration = useCallback(
    (integrationId) => {
      const localIntegration = integrations.find((i) => i.id === integrationId);
      return baseIntegrations.find(
        (bi) => bi.id === localIntegration?.base_integration_id,
      )?.id;
    },
    [integrations, baseIntegrations],
  );

  useEffect(() => {
    if (formik.values?.baseIntegrationId === undefined) {
      if (integration_id) {
        const integrationId = parseInt(integration_id, 10);
        formik.setFieldValue(
          'baseIntegrationId',
          getBaseIntegration(integrationId),
        );
      } else {
        const integrationProject = project
          ? integrationProjects.find((ip) => ip.project_id === project.id)
          : undefined;
        if (integrationProject) {
          formik.setFieldValue(
            'baseIntegrationId',
            getBaseIntegration(integrationProject.integration_id),
          );
        }
      }
    }
  }, [
    integration_id,
    formik.values.baseIntegrationId,
    formik,
    project,
    integrationProjects,
    getBaseIntegration,
  ]);

  const baseIntegrationOptions = useMemo(() => {
    const validBaseIntegrations = baseIntegrations.map(
      (i: BaseIntegration) => ({
        label: i.name,
        value: i.id,
        icon: (
          <BaseIntegrationIcon
            baseIntegration={i}
            color={vars.textPrimary}
            size={16}
          />
        ),
      }),
    );
    if (setupIntegrations) return validBaseIntegrations;

    return validBaseIntegrations.filter(({ value }) => {
      return integrations.some(
        ({ base_integration_id, id }) =>
          value === base_integration_id &&
          integrationUsers.find((iu) => iu.integration_id === id),
      );
    });
  }, [baseIntegrations, integrations, integrationUsers, setupIntegrations]);

  const onChangeColor = (value) => {
    formik.setFieldValue('color', value);
  };

  const onChangeIcon = (value) => {
    formik.setFieldValue('icon', value);
  };

  const setIntegrationProject = (ip: Partial<IntegrationProject>) => {
    formik.setFieldValue('integrationProject', ip);
    if (!formik.values.name) {
      formik.setFieldValue('name', ip.project_reference_id);
    }
  };

  const onChangeBaseIntegration = (value) => {
    const localIntegration = integrations.find(
      (i) => i.base_integration_id === value,
    );
    if (localIntegration) {
      const iu = integrationUsers.find(
        (iu2) => iu2.integration_id === localIntegration.id,
      );
      if (iu) {
        formik.setFieldValue('baseIntegrationId', value);
        formik.setFieldValue('integrationProject', undefined);
      } else {
        const urlParams = new URLSearchParams({
          integration_id: localIntegration.id.toString(),
        } as any);
        redirectForAuth(
          localIntegration.id,
          location.pathname,
          `?${urlParams.toString()}`,
        );
      }
    } else {
      const urlParams = new URLSearchParams({ project_create: true } as any);
      history.push({
        pathname: routes.INTEGRATION_SETUP({
          site,
          baseIntegrationId: value.toString(),
        }),
        search: urlParams.toString(),
      });
    }
  };

  const integrationRenderOption = (option) => (
    <>
      {option.icon}
      <span className={styles.integrationName}>{option.label}</span>
    </>
  );

  return (
    <form
      className={styles.form}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}>
      <DialogContent className={styles.panelContent}>
        <Input
          autoComplete="off"
          error={formikError(formik, 'name')}
          fullWidth
          label={t('projectForm.name')}
          name="name"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.name}
        />
        {formFields.description && (
          <Input
            autoComplete="off"
            error={formikError(formik, 'description')}
            fullWidth
            label={t('projectForm.description')}
            name="description"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.description}
          />
        )}
        {!edit && setupIntegrations && (
          <div className={styles.explain}>
            {t('projectForm.integrationExplain')}
          </div>
        )}
        {!edit && Boolean(integrationUsers.length) && (
          <Select
            label={t('projectForm.integrationSelection')}
            onChange={onChangeBaseIntegration}
            options={baseIntegrationOptions}
            placeholder="Select Integration..."
            renderOption={integrationRenderOption}
            value={formik.values.baseIntegrationId}
          />
        )}
        {!edit && (
          <IntegrationProjectItem
            integration={integration}
            setIntegrationProject={setIntegrationProject}
            project={project}
            formik={formik as any}
          />
        )}
        {formFields.icon && (
          <RadioGroup
            error={formikError(formik, 'icon')}
            label={t('projectForm.icon')}
            name="icon"
            onChange={onChangeIcon}
            orientation="horizontal"
            value={formik.values.icon}>
            {Array.from(ProjectIconsDisplay).map(([value, Icon]) => (
              <RadioIcon
                key={value}
                icon={<Icon color={vars.textPrimary} />}
                value={value}
              />
            ))}
          </RadioGroup>
        )}

        {formFields.color && (
          <RadioGroup
            error={formikError(formik, 'color')}
            label={t('projectForm.color')}
            name="color"
            onChange={onChangeColor}
            orientation="horizontal"
            value={formik.values.color}>
            {Array.from(ProjectColorsDisplay).map(([value, color]) => (
              <RadioColor color={color} value={value} key={value} />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <PanelActions>
        <Button
          className={submitClassName}
          loading={formik.isSubmitting}
          type="submit">
          {edit ? t('projectForm.submitEdit') : t('projectForm.submitCreate')}
        </Button>
      </PanelActions>
    </form>
  );
}
