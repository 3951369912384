/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { runResultHistoryAdapter } from './runResultHistoryAdapter';

export const runResultHistorySelectors = runResultHistoryAdapter.getSelectors(
  (state: State) => state.gen.runResultHistory,
);
