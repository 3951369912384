import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import Switch from 'antd/es/switch';
import { BaseButton, Loading, notification, TextArea } from '@bitmodern/bit-ui';
import { CodeIcon } from '@bitmodern/bit-ui/icons';
import { Step } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { stepParser, stepsToString } from 'src/common/stepParser';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import { getEnv } from 'src/env';
import TestStep from '../TestStep';
import styles from './StepsForm.module.scss';
import { notificationErrorTimeout } from '../../../constants';

type Props = Pick<
  ComponentProps<typeof TestStep>,
  'onFileUpload' | 'parentId' | 'parentType'
> & {
  className?: string;
  edit?: boolean;
  extraNode?: ReactNode;
  highlights?: string[];
  onAddStep: () => Promise<any>;
  onChangeOrder: (steps: Step[]) => void;
  onChangeStep?: (step: Step) => void;
  onClone: (step: Step) => void;
  onCommitStep?: (step: Step) => void;
  onDelete: (step: Step) => Promise<any>;
  steps: Step[];
};

export default function StepsForm({
  className = '',
  edit = true,
  extraNode = null,
  highlights,
  onAddStep: onAddStepProp,
  onChangeOrder,
  onChangeStep,
  onClone,
  onCommitStep,
  onDelete,
  onFileUpload,
  parentId,
  parentType,
  steps = [],
}: Props) {
  const [markdown, setMarkdown] = useState(false);
  const [currentSteps, setCurrentSteps] = useState(steps);
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (markdown) {
      setValue(stepsToString(currentSteps));
    } else {
      setCurrentSteps(stepParser(value));
    }
    // eslint-disable-next-line
  }, [markdown, steps]);

  useEffect(() => {
    setCurrentSteps(steps);
  }, [steps]);

  const showCodeSwitch = useMemo(() => {
    if (!getEnv().isDevelopment) return false;
    if (edit) return true;
    return false;
  }, [edit]);

  const onAddStep = useMutation(onAddStepProp);

  const onClickMarkdown = () => setMarkdown((s) => !s);

  const onChangeValue = (event) => setValue(event.target.value);

  const handleChangeOrder = (fromIndex: number, toIndex: number) => {
    setCurrentSteps((s) => {
      const nextSteps = [...s];
      const itemToMove = nextSteps.splice(fromIndex, 1)[0];
      nextSteps.splice(toIndex, 0, itemToMove);
      return nextSteps;
    });
  };

  const onDropEnd = () => onChangeOrder(currentSteps);

  const handleOnDelete = async (step: Step) => {
    if (steps.length > 1) {
      onDelete(step);
    } else {
      notification.open({
        type: 'error',
        message: t('stepsForm.atLeastOneError.message'),
        description: t('stepsForm.atLeastOneError.description'),
        duration: notificationErrorTimeout,
      });
    }
  };

  return (
    <div className={className}>
      <div className={styles.stepsHeader}>
        <div className={styles.stepsLabel}>{t('stepsForm.steps')}</div>
        <>
          {extraNode}
          {showCodeSwitch && (
            <div className={styles.editorSwitch}>
              <div className={styles.mdEditorIcon}>
                <CodeIcon color={vars.textPrimary} size={20} />
              </div>
              <div>
                <Switch
                  checked={markdown}
                  onChange={onClickMarkdown}
                  size="small"
                  title="code editor"
                />
              </div>
            </div>
          )}
        </>
      </div>
      {markdown ? (
        <TextArea
          className={styles.stepText}
          fullWidth
          onChange={onChangeValue}
          required
          showLabel={false}
          type="text"
          value={value}
        />
      ) : (
        <div className={styles.testSteps}>
          {currentSteps.map((step, index) => (
            <TestStep
              index={index}
              key={step.id}
              onChangeOrder={handleChangeOrder}
              onChangeStep={onChangeStep}
              onClone={onClone}
              onCommit={onCommitStep}
              onDelete={handleOnDelete}
              onDropEnd={onDropEnd}
              onFileUpload={onFileUpload}
              parentId={parentId}
              parentType={parentType}
              step={step}
              highlights={highlights}
            />
          ))}
          {edit && (
            <BaseButton
              className={styles.newStep}
              fullWidth
              onClick={onAddStep.mutate}>
              {onAddStep.isLoading && (
                <Loading className={styles.loading} size={28} />
              )}
              {t('stepsForm.newStep')}
            </BaseButton>
          )}
        </div>
      )}
    </div>
  );
}
