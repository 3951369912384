import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Surface } from '@bitmodern/bit-ui';
import { ArrowBackIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import styles from './SettingsPanel.module.scss';

type Props = {
  actions?: ReactNode;
  back?: string;
  children?: ReactNode;
  className?: string;
  fullHeight?: boolean;
  icon?: ReactNode;
  paddingLess?: boolean;
  title: ReactNode;
};

export default function SettingsPanel({
  actions,
  back,
  children,
  className,
  fullHeight = false,
  icon,
  paddingLess,
  title,
}: Props) {
  const panelCN = classnames(className, {
    [styles.fullHeight]: fullHeight,
  });

  const contentCN = classnames(styles.content, {
    [styles.paddingLess]: paddingLess,
    [styles.fullHeight]: fullHeight,
  });

  return (
    <Surface className={panelCN}>
      <div className={styles.header}>
        {back && (
          <Link className={styles.back} to={back}>
            <ArrowBackIcon color={vars.textPrimary} size={24} />
          </Link>
        )}
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.title}>{title}</div>
        {actions || ''}
      </div>
      <div className={contentCN}>{children}</div>
    </Surface>
  );
}
