import React from 'react';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { baseIntegrationSelectors } from 'src/gen/domain/base_integration/baseIntegrationSelector';
import { integrationSelectors } from 'src/gen/domain/integration/integrationSelector';
import { useQuery } from 'src/hooks/useQuery';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { integrationUserByIntegrationAndUserSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { IntegrationCommon } from './IntegrationCommon';

export default function IntegrationViewContainer() {
  const params = useParams<typeof routes.INTEGRATION.params>();
  const { site } = params;
  const integrationId = parseInt(params.integrationId, 10);
  const queryParams = useQuery(['project_create']);
  const projectCreate = !!queryParams.project_create;

  const integration = useAppSelector((state) =>
    integrationSelectors.selectById(state, integrationId),
  );
  const baseIntegration = useAppSelector((state) =>
    baseIntegrationSelectors.selectById(
      state,
      integration!.base_integration_id!,
    ),
  );
  const currentUser = useAppSelector(currentUserSelector);
  const integrationUsers = useAppSelector((state) =>
    integrationUserByIntegrationAndUserSelector(state, {
      integrationId,
      userId: currentUser?.id,
    }),
  );

  return (
    <IntegrationCommon
      site={site}
      projectCreate={projectCreate}
      baseIntegration={baseIntegration!}
      integration={integration}
      integrationUser={integrationUsers ? integrationUsers[0] : undefined}
    />
  );
}
