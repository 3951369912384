export function combineBatchedEntitiesCache(
  dataCached: Array<{ [key in string]: any[] }>,
): any {
  return dataCached.reduce((acc, data: any) => {
    for (const key in data) {
      if (Array.isArray(acc[key]) && Array.isArray(data[key])) {
        acc[key].push(...data[key]);
      } else {
        acc[key] = data[key];
      }
    }
    return acc;
  }, {});
}
