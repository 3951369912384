import React, { ReactNode } from 'react';
import styles from './Automations.module.scss';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'src/components/Router';
import {
  CypressIcon,
  SeleniumIcon,
  MochaIcon,
  JUnitIcon,
  JestIcon,
  WatirIcon,
  PytestIcon,
  NUnitIcon,
  TestCompleteIcon,
  WebdriverIcon,
  PyUnitIcon,
  JasmineIcon,
  RanorexIcon,
  PhPUnitIcon,
  CucumberIcon,
} from 'src/packages/bit-ui/icons';
import { useTranslation } from 'react-i18next';

type Automation = {
  slug: string;
  title: string;
  icon: ReactNode;
  enabled?: boolean;
};

const automations: Automation[] = [
  {
    slug: 'cypress',
    title: 'Cypress',
    icon: <CypressIcon size={40} />,
    enabled: true,
  },
  {
    slug: 'selenium',
    title: 'Selenium',
    icon: <SeleniumIcon size={40} />,
    enabled: true,
  },
  {
    slug: 'mocha',
    title: 'Mocha',
    icon: <MochaIcon size={40} />,
    enabled: true,
  },
  {
    slug: 'junit',
    title: 'JUnit',
    icon: <JUnitIcon size={40} />,
    enabled: true,
  },
  { slug: 'jest', title: 'Jest', icon: <JestIcon size={40} />, enabled: true },
  { slug: 'watir', title: 'Watir', icon: <WatirIcon size={40} /> },
  { slug: 'pytest', title: 'Pytest', icon: <PytestIcon size={40} /> },
  { slug: 'nunit', title: 'NUnit', icon: <NUnitIcon size={40} /> },
  {
    slug: 'testcomplete',
    title: 'TestComplete',
    icon: <TestCompleteIcon size={40} color="#4169E1" />,
  },
  {
    slug: 'webdriver',
    title: 'Webdriver',
    icon: <WebdriverIcon size={40} />,
  },
  { slug: 'pyunit', title: 'PyUnit', icon: <PyUnitIcon size={40} /> },
  { slug: 'jasmine', title: 'Jasmine', icon: <JasmineIcon size={40} /> },
  { slug: 'ranorex', title: 'Ranorex', icon: <RanorexIcon size={40} /> },
  { slug: 'phpunit', title: 'PHPUnit', icon: <PhPUnitIcon size={40} /> },
  { slug: 'cucumber', title: 'Cucumber', icon: <CucumberIcon size={40} /> },
];

export default function Automations() {
  const { site, projectId } = useParams<typeof routes.PROJECT.params>();
  const { t } = useTranslation();
  return (
    <div className={styles.automations}>
      {automations.map((automation) =>
        automation.enabled ? (
          <Link
            to={
              projectId
                ? routes.IMPORT_SLUG({
                    site,
                    slug: automation.slug,
                    projectId,
                  })
                : routes.IMPORT_AUTOMATION({
                    site,
                    slug: automation.slug,
                  })
            }
            className={styles.automation}
            key={automation.title}>
            {automation.icon}
            <div className={styles.name}>{automation.title}</div>
          </Link>
        ) : (
          <span
            title={t('import.commingSoon')}
            className={classnames(styles.automation, {
              [styles.disabled]: !automation.enabled,
            })}
            key={automation.title}>
            <span className={styles.disabledIcon}>{automation.icon}</span>
            <div className={styles.name}>{automation.title}</div>
          </span>
        ),
      )}
    </div>
  );
}
