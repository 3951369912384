import React from 'react';
import styles from './RunChipProgress.module.scss';
import { Run } from '@testquality/sdk';
import { StatusTypeEnum } from 'src/enums/StatusEnum';
import { useAppSelector } from 'src/packages/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { statusTypeSelectors } from 'src/gen/domain/status_type/statusTypeSelector';
import { pendingStatusSelector } from 'src/packages/redux/state/statuses/selectors';
import { ChipProgress, Tooltip } from 'src/packages/bit-ui';
import StatusIcon from 'src/components/organisms/StatusIcon';
import vars from 'src/packages/bit-ui/styling/exports.scss';

type Props = {
  run: Run;
  size?: number;
};

type Summary = {
  [K in StatusTypeEnum | 'pending']?: number;
};

export default function RunChipProgress({ run, size }: Props) {
  const statuses = useAppSelector(statusSelectors.selectAll);
  const statusTypeEntities = useAppSelector(statusTypeSelectors.selectEntities);
  const pendingStatus = useAppSelector(pendingStatusSelector);

  const quantityPerStatus: Record<string, number> =
    run.analysis?.status?.reduce((acc, status) => {
      acc[status.status_key] = status.total || 0;
      return acc;
    }, {}) || {};

  const totalTest = Object.values(quantityPerStatus).reduce(
    (acu, val) => acu + val,
    0,
  );

  const summary = statuses.reduce<Summary>((acc, status) => {
    const statusQuantity = quantityPerStatus[status.key];
    if (!statusQuantity) return acc;
    const statusType = statusTypeEntities[status.status_type_id];
    if (status.key === pendingStatus?.key) {
      if (acc.pending) {
        acc.pending += statusQuantity;
      } else {
        acc.pending = statusQuantity;
      }
      return acc;
    }
    if (statusType && status.key) {
      if (acc[statusType.key]) {
        acc[statusType.key] += statusQuantity;
      } else {
        acc[statusType.key] = statusQuantity;
      }
    }
    return acc;
  }, {});

  const positiveSummary = summary[StatusTypeEnum.Positive] || 0;
  const negativeSummary = summary[StatusTypeEnum.Negative] || 0;
  const neutralSummary = summary[StatusTypeEnum.Neutral] || 0;
  const pendingSummary = summary.pending || 0;

  const negativeProgress = (negativeSummary / totalTest) * 100;
  const positiveProgress = (positiveSummary / totalTest) * 100;
  const neutralProgress = (neutralSummary / totalTest) * 100;
  const pendingProgress = (pendingSummary / totalTest) * 100;

  const executed = negativeSummary + positiveSummary + neutralSummary;
  const total = executed + pendingSummary;

  function getToolTipContent() {
    return (
      <table className={styles.chipTooltip}>
        {Object.entries(quantityPerStatus).map(([statusKey, count]) => {
          const status = statuses.find((s) => s.key === parseInt(statusKey));
          return (
            <tr key={statusKey}>
              <td className={styles.status}>
                {status && <StatusIcon status={status} size={16} />}
                <span className={styles.statusName}>{status?.name}</span>
              </td>
              <td className={styles.count}>{count}</td>
            </tr>
          );
        })}
      </table>
    );
  }

  return (
    <div className={styles.stats}>
      <span>
        {negativeSummary + positiveSummary + neutralSummary}/{total}
      </span>
      <Tooltip
        enterDelay={200}
        key={run.id}
        placement="topLeft"
        tooltip={getToolTipContent()}
        disabled={!Object.keys(quantityPerStatus).length}>
        <div>
          <ChipProgress
            className={styles.progress}
            idValue={run.id}
            size={size}
            progress={[
              {
                color: vars.successMain,
                percent: positiveProgress || 0,
              },
              {
                color: vars.errorMain,
                percent: negativeProgress || 0,
              },
              {
                color: vars.warningMain,
                percent: neutralProgress || 0,
              },
              {
                color: 'transparent',
                percent: pendingProgress || 0,
              },
            ]}
          />
        </div>
      </Tooltip>
    </div>
  );
}
