import React from 'react';
import classmames from 'classnames';
import { BaseButton } from '@bitmodern/bit-ui';
import { Status } from '@testquality/sdk';
import { getStatusColor } from 'src/utils/statusHelper';
import { StatusEnum } from 'src/enums/StatusEnum';
import vars from 'src/export.scss';
import StatusIcon from 'src/components/organisms/StatusIcon';
import styles from './StatusButton.module.scss';

type StatusButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  selected: boolean;
  status: Status;
};

export default function StatusButton({
  disabled = false,
  onClick,
  selected,
  status,
}: StatusButtonProps) {
  const buttonCN = classmames(styles.button, {
    [styles.pass]: selected && status.key === StatusEnum.Pass,
    [styles.fail]: selected && status.key === StatusEnum.Fail,
  });

  const statusColor = getStatusColor(status);

  return (
    <BaseButton disabled={disabled} className={buttonCN} onClick={onClick}>
      <StatusIcon
        className={styles.icon}
        iconColor={selected ? statusColor : vars.primaryDark}
        circleColor={selected ? vars.onSuccess : vars.textSecondary}
        status={status}
        size={14}
      />
      {status.name}
    </BaseButton>
  );
}
