import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M8.71 12.29L11.3 9.7c.39-.39 1.02-.39 1.41 0l2.59 2.59c.63.63.18 1.71-.71 1.71H9.41c-.89 0-1.33-1.08-.7-1.71z" />
  </>,
  'DropdownUp',
);
