/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { SubscriptionUser } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { subscriptionUserAdapter } from './subscriptionUserAdapter';
import {
  subscriptionUserFetchManyThunk,
  subscriptionUserFetchOneThunk,
  subscriptionUserDeleteOneThunk,
  subscriptionUserUpdateOneThunk,
  subscriptionUserCreateOneThunk,
} from './subscriptionUserThunk';
import {
  subscriptionsFetchManyThunk,
  subscriptionsFetchOneThunk,
} from '../subscriptions/subscriptionsThunk';
import {
  couponFetchManyThunk,
  couponFetchOneThunk,
} from '../coupon/couponThunk';
import {
  productFetchManyThunk,
  productFetchOneThunk,
} from '../product/productThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const subscriptionUserSlice = createSlice({
  name: 'subscriptionUser',
  initialState: subscriptionUserAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    subscriptionUserCleared(state) {
      state.loading = 'idle';
      subscriptionUserAdapter.removeAll(state);
    },
    subscriptionUserUpsertMany(
      state,
      action: PayloadAction<
        SubscriptionUser[] | Record<number, SubscriptionUser>
      >,
    ) {
      subscriptionUserAdapter.upsertMany(state, action.payload);
    },
    subscriptionUserUpsertOne(state, action: PayloadAction<SubscriptionUser>) {
      subscriptionUserAdapter.upsertOne(state, action.payload);
    },
    subscriptionUserUpdateOne(
      state,
      action: PayloadAction<Update<SubscriptionUser>>,
    ) {
      subscriptionUserAdapter.updateOne(state, action.payload);
    },
    subscriptionUserRemoveOne(state, action: PayloadAction<number>) {
      subscriptionUserAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriptionUserFetchManyThunk.pending, handlePending);
    builder.addCase(
      subscriptionUserFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      },
    );
    builder.addCase(subscriptionUserFetchManyThunk.rejected, handleRejected);
    builder.addCase(subscriptionUserFetchOneThunk.pending, handlePending);
    builder.addCase(
      subscriptionUserFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionUserAdapter.upsertOne(
          state,
          action.payload.subscriptionUser[0],
        );
      },
    );
    builder.addCase(subscriptionUserFetchOneThunk.rejected, handleRejected);
    builder.addCase(subscriptionUserDeleteOneThunk.pending, handlePending);
    builder.addCase(
      subscriptionUserDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          subscriptionUserAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(subscriptionUserDeleteOneThunk.rejected, handleRejected);
    builder.addCase(subscriptionUserUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, subscriptionUserAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      subscriptionUserUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionUserAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      subscriptionUserUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            subscriptionUserAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(subscriptionUserCreateOneThunk.pending, handlePending);
    builder.addCase(
      subscriptionUserCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionUserAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(subscriptionUserCreateOneThunk.rejected, handleRejected);
    builder.addCase(subscriptionsFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(subscriptionsFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(couponFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(couponFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(productFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(productFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.subscriptionUser) {
          subscriptionUserAdapter.upsertMany(
            state,
            action.payload.subscriptionUser,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.subscriptionUser) {
          subscriptionUserAdapter.upsertMany(
            state,
            action.payload.subscriptionUser,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.subscriptionUser) {
          subscriptionUserAdapter.upsertMany(
            state,
            action.payload.subscriptionUser,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.subscriptionUser) {
        subscriptionUserAdapter.upsertMany(
          state,
          action.payload.subscriptionUser,
        );
      }
    });
  },
});

export const {
  subscriptionUserCleared,
  subscriptionUserUpsertMany,
  subscriptionUserUpsertOne,
  subscriptionUserUpdateOne,
  subscriptionUserRemoveOne,
} = subscriptionUserSlice.actions;

export const subscriptionUserReducer = subscriptionUserSlice.reducer;
