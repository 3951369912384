import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { stepsByTestSelector } from '@bitmodern/redux/state/steps/selectors';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import { runResultStepsByRunResultSelector } from '@bitmodern/redux/state/RunResultSteps/selectors';
import {
  RunResultStep,
  Status,
  BatchService,
  RunResult,
} from '@testquality/sdk';
import { runResultStepUpdateOneThunk } from 'src/gen/domain/run_result_step/runResultStepThunk';
import { requirementsByTestIdSelector } from '@bitmodern/redux/state/requirements/selectors';
import { defectsByRunResultSelector } from '@bitmodern/redux/state/defects/selectors';
import {
  runResultFetchManyThunk,
  runResultUpdateOneThunk,
} from 'src/gen/domain/run_result/runResultThunk';
import useFetch from 'src/hooks/useFetch';
import { useMaker } from '@bitmodern/redux/hooks';

import { runSelectors } from 'src/gen/domain/run/runSelector';
import useModalManager from 'src/hooks/useModalManager';
import {
  changeRunResultStatusThunk,
  onChangeStepStatusThunk,
  onChangeStepElapsedThunk,
} from '@bitmodern/redux/state/runs/thunks';
import {
  runResultsByTestSelector,
  treePrevNextSelector,
} from '@bitmodern/redux/state/runResults/selectors';
import runResultStatusFromSteps from 'src/utils/runResultStatusFromSteps';
import { runResultActivitySelector } from '@bitmodern/redux/state/activity/selectors';
import { statusesSelector } from '@bitmodern/redux/state/statuses/selectors';
import { labelsByTestSelector } from '@bitmodern/redux/state/label/selectors';
import { planSelector } from '@bitmodern/redux/state/plans/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { makeDataSetByTestSelector } from '@bitmodern/redux/state/dataSets/selectors';
import { caseTypeSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { casePrioritySelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { statusTypesSelector } from '@bitmodern/redux/state/statusTypes/selectors';
import {
  commentCreateOneThunk,
  commentDeleteOneThunk,
  commentUpdateOneThunk,
} from 'src/gen/domain/comment/commentThunk';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import { runResultHistoryFetchThunk } from 'src/gen/domain/run_result/runResultHistoryThunk';
import { testSelectors } from '../../../gen/domain/test/testSelector';

type Params = {
  projectId: number;
  runResultId: number;
  site: string;
};

export default function useRunResultView({
  projectId,
  runResultId,
  site,
}: Params) {
  const dispatch = useAppDispatch();
  const { showModal } = useModalManager();

  const { openCreateDefect, openCreateRequirement } = useIntegrationCheck(
    site,
    projectId,
  );

  const {
    nextRunResult,
    previousRunResult,
    run,
    runResult,
    runResultSteps,
    statuses,
    statusTypes,
    test,
    ...rest
  } = useRunResultViewSelector({ runResultId });

  const testId = test?.id;

  const request = useCallback(() => {
    const batch = new BatchService();
    dispatch(
      runResultFetchManyThunk({
        params: {
          test_id: testId,
          project_id: projectId,
          per_page: -1,
        },
      }),
    );
    dispatch(
      runResultHistoryFetchThunk({
        params: { id: runResultId },
        batch,
      }),
    );
    return batch.executeBatch();
  }, [dispatch, testId, runResultId, projectId]);

  const runResultsFetch = useFetch(request, `TestRunResults-${testId}`, {
    fetchOnce: true,
  });

  const setRunResultStatus = (rr?: RunResult, status?: Status) => {
    if (rr && status) {
      dispatch(changeRunResultStatusThunk(rr, status, onCompleteRun));
    }
  };

  const onChangeRunResultStatus = (status: Status) => {
    setRunResultStatus(runResult, status);
  };

  const onChangeRunResult = (data) => {
    dispatch(
      runResultUpdateOneThunk({
        id: runResultId,
        optimistic: true,
        data,
      }),
    );
  };

  const onChangeStepStatus = (status: Status, runResultStep: RunResultStep) => {
    if (runResult) {
      dispatch(
        onChangeStepStatusThunk(
          status,
          runResultStep,
          runResult,
          onCompleteRun,
        ),
      );
    }
  };

  const onCompleteRun = () => {
    showModal({
      modalName: 'completeDialog',
      modalProps: { run },
      type: 'modal',
    });
  };

  const onAddDefect = (): Promise<any> => {
    if (run && runResult) {
      return openCreateDefect(run.id, [runResult.id]);
    }
    return Promise.resolve();
  };

  const onAddRequirement = () => {
    if (test) {
      return openCreateRequirement(test.id);
    }
    return Promise.resolve();
  };

  const onEditComment = (comment) => {
    dispatch(
      commentUpdateOneThunk({
        id: comment.id,
        data: comment,
        optimistic: true,
      }),
    );
    return Promise.resolve();
  };

  const onChangeStepElapsed = (
    elapsed: number,
    runResultStep: RunResultStep,
  ) => {
    if (runResultStep)
      dispatch(onChangeStepElapsedThunk(elapsed, runResultStep));
  };

  const onChangeStepResult = (
    result: string | undefined,
    runResultStep: RunResultStep,
  ) => {
    dispatch(
      runResultStepUpdateOneThunk({
        id: runResultStep.id,
        data: { result },
        optimistic: true,
      }),
    );
    return Promise.resolve();
  };

  const onDeleteComment = (commentId: number) => {
    return dispatch(commentDeleteOneThunk({ id: commentId }));
  };

  const onComment = (comment: string) => {
    if (!runResult) return;
    dispatch(
      commentCreateOneThunk({
        data: {
          body: comment,
          related_id: runResult.id,
          related_type: RelatedType.RUN_RESULT,
        },
      }),
    );
  };

  const runResultStatus = runResultStatusFromSteps(
    statuses,
    runResultSteps,
    statusTypes,
  );

  return {
    isLoadingRuns: runResultsFetch.isLoading,
    nextRunResult,
    onAddDefect,
    onAddRequirement,
    onChangeRunResult,
    onChangeRunResultStatus,
    onChangeStepElapsed,
    onChangeStepResult,
    onChangeStepStatus,
    onComment,
    onDeleteComment,
    onEditComment,
    previousRunResult,
    run,
    runResult,
    runResultStatus,
    runResultSteps,
    setRunResultStatus,
    test,
    ...rest,
  };
}

function useRunResultViewSelector({ runResultId }: { runResultId: number }) {
  const runResult = useAppSelector((state) =>
    runResultSelectors.selectById(state, runResultId),
  );
  const statuses = useAppSelector(statusesSelector);
  const statusTypes = useAppSelector(statusTypesSelector);
  const run = useAppSelector((state) =>
    runSelectors.selectById(state, runResult?.run_id || 0),
  );
  const runResultSteps = useAppSelector((state) =>
    runResultStepsByRunResultSelector(state, runResult?.id || 0),
  );
  const testId = runResult?.test_id;
  const test = useAppSelector((state) =>
    testSelectors.selectById(state, testId || 0),
  );
  const dataSet = useMaker(makeDataSetByTestSelector, {
    testId: test?.id,
  });
  const steps = useAppSelector((state) =>
    stepsByTestSelector(state, { testId }).filter((s) =>
      runResultSteps.map((rrs) => rrs.step_id).includes(s.id),
    ),
  );
  const requirements = useAppSelector((state) =>
    requirementsByTestIdSelector(state, testId || 0),
  );
  const defects = useAppSelector((state) =>
    defectsByRunResultSelector(state, runResultId),
  );

  const controls = useAppSelector((state) => {
    if (!runResult || run?.is_complete || !run?.is_running) return {};
    return treePrevNextSelector(state, run.id, runResult?.id);
  });

  const activity = useAppSelector((state) =>
    runResultActivitySelector(state, runResultId),
  );

  const users = useAppSelector(usersSelector);
  const runResults = useAppSelector((state) =>
    runResultsByTestSelector(state, testId || 0),
  );
  const caseType = useAppSelector((state) =>
    caseTypeSelector(state, test?.case_type_id || 0),
  );
  const casePriority = useAppSelector((state) =>
    casePrioritySelector(state, test?.case_priority_id || 0),
  );
  const testLabels = useAppSelector((state) =>
    labelsByTestSelector(state, testId || 0),
  );
  const plan = useAppSelector((state) =>
    planSelector(state, run?.plan_id || 0),
  );

  const currentRunResults = runResults.filter(
    (rr) =>
      rr.test_id === runResult?.test_id && runResult?.run_id === rr.run_id,
  );

  return {
    activity,
    casePriority,
    caseType,
    currentRunResults,
    dataSet,
    defects,
    nextRunResult: controls.next,
    plan,
    previousRunResult: controls.prev,
    requirements,
    run,
    runResult,
    runResults,
    runResultSteps,
    statuses,
    statusTypes,
    steps,
    test,
    testId,
    testLabels,
    users,
  };
}
