import { createSelector } from '@reduxjs/toolkit';
import { defectPrioritySelectors } from 'src/gen/domain/defect_priority/defectPrioritySelector';
import { State } from '@bitmodern/redux/store';

export function defectPrioritySelector(state: State) {
  return defectPrioritySelectors.selectAll(state);
}

export const defectPrioritiesByIntegrationProjectSelector = (
  integrationProjectId?: number,
) =>
  createSelector(defectPrioritySelector, (defectPriorities) =>
    defectPriorities.filter(
      ({ integration_project_id }) =>
        integration_project_id === integrationProjectId,
    ),
  );
