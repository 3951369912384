import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SettingsIcon from '@bitmodern/bit-ui/icons/SettingsIcon';
import ProjectIcon from 'src/components/organisms/ProjectIcon';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import {
  BaseIntegration,
  Integration,
  IntegrationProject,
} from '@testquality/sdk';
import { integrationProjectsByIntegrationSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { useTranslation } from 'src/i18n/hooks';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { integrationUsersSelectors } from '@bitmodern/redux/state/integrationUser/selectors';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { DeleteIcon } from 'src/packages/bit-ui/icons';
import { IconButton } from 'src/packages/bit-ui';
import { useOverlayTriggerState } from 'react-stately';
import { ConfirmDialog } from 'src/components/organisms';
import useMutation from 'src/hooks/useMutation';
import { integrationProjectDetachThunk } from 'src/gen/domain/integration_project/integrationProjectThunk';
import { integrationProjectRemoveOne } from 'src/gen/domain/integration_project/integrationProjectSlice';
import styles from './ExistingIntegrationsView.module.scss';

type Props = {
  baseIntegration: BaseIntegration;
  integration: Integration | undefined;
};

export default function ExistingIntegrationsView({
  baseIntegration,
  integration,
}: Props) {
  const { t } = useTranslation();
  const { site } = useParamsInRoute<typeof routes.INTEGRATIONS.params>(
    routes.INTEGRATIONS.path,
  );

  const currentUser = useAppSelector(currentUserSelector);
  const integrationUsers = useAppSelector(integrationUsersSelectors);
  const users = useAppSelector(usersSelector);
  const projects = useAppSelector(projectsSelector);
  const integrationProjects = useAppSelector((state) =>
    integrationProjectsByIntegrationSelector(state, {
      integrationId: integration?.id,
    }),
  );

  const dispatch = useAppDispatch();
  // const history = useHistory();

  const [ipToDelete, setIpToDelete] = useState<
    IntegrationProject | undefined
  >();

  const deleteDialog = useOverlayTriggerState({});

  const onConfirmDelete = useCallback(() => {
    if (!ipToDelete) return Promise.resolve();

    // todo: why are detach then removing?
    return dispatch(integrationProjectDetachThunk({ data: ipToDelete })).then(
      () => {
        dispatch(integrationProjectRemoveOne(ipToDelete.id));
        deleteDialog.close();
      },
    );
  }, [deleteDialog, dispatch, ipToDelete]);

  const deleteMutation = useMutation(onConfirmDelete);

  const handleDeleteIp = (ip: IntegrationProject) => {
    setIpToDelete(ip);

    deleteDialog.open();
  };

  const userHasAccessToken = useMemo(() => {
    const integrationUser = integrationUsers.find(
      (iu) =>
        iu.integration_id === integration?.id &&
        iu.user_id === currentUser?.id &&
        iu.access_token,
    );
    return Boolean(integrationUser);
  }, [currentUser, integration, integrationUsers]);

  const getProjectMetaData = () => {
    let metaData = '';
    const iu = integrationUsers.find(
      (u) => u.integration_id === integration?.id,
    );
    if (iu) {
      const user = users.find((u) => u.id === iu.user_id);
      if (user) {
        metaData = t('integrationProject.addedBy', {
          userName: user.given_name || user.email,
          date: new Date(iu.created_at),
        });
      }
    }
    return metaData;
  };

  const integrationMetaData = useMemo(() => {
    if (!integration) return '';
    const user = users.find((u) => u.id === integration.created_by);
    if (!user) return '';

    return t('integrationProject.addedBy', {
      userName: user.given_name || user.email,
      date: new Date(integration.created_at),
    });
  }, [t, integration, users]);

  return (
    <div className={styles.container}>
      {integrationProjects.map((integrationProject) => {
        const project = projects.find(
          (p) => integrationProject.project_id === p.id,
        );
        if (!project) return null;
        return (
          <div className={styles.projectItem} key={integrationProject.id}>
            <Link
              className={styles.projectItemLink}
              to={routes.PROJECT_INTEGRATION({
                site,
                integrationId: integration?.id?.toString() || '',
                projectId: project.id.toString(),
                externalRef: integrationProject.project_reference_id,
              })}>
              <div className={styles.project}>
                <ProjectIcon
                  color={project.color as any}
                  icon={project.picture as any}
                />
                <div className={styles.projectName}>
                  {project.name}
                  {' - '}
                  <em>
                    {t('integrationSetup.mappedTo', {
                      name: integrationProject.project_reference_id,
                    })}
                  </em>
                  <div className={styles.projectMetadata}>
                    {getProjectMetaData()}
                  </div>
                </div>
              </div>
            </Link>
            <div className={styles.deleteButton}>
              <IconButton onClick={() => handleDeleteIp(integrationProject)}>
                <DeleteIcon color={vars.textPrimary} size={18} />
              </IconButton>
            </div>
          </div>
        );
      })}

      {integration ? (
        <Link
          to={routes.INTEGRATION_SETUP({
            site,
            baseIntegrationId: baseIntegration.id.toString(),
          })}>
          <div className={styles.projectItem}>
            <div className={styles.project}>
              <div className={styles.settingsIcon}>
                <SettingsIcon color={vars.textSecondary} size={26} />
              </div>
              <div className={styles.projectName}>
                {integration.name}
                <div className={styles.projectMetadata}>
                  {integrationMetaData}
                  {!userHasAccessToken && (
                    <div className={styles.requiresAuth}>
                      {t('integrationSetup.needsAuthorize')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          to={routes.INTEGRATION_SETUP({
            site,
            baseIntegrationId: baseIntegration.id.toString(),
          })}>
          <div className={styles.projectItem}>
            <div className={styles.project}>
              <div className={styles.settingsIcon}>
                <SettingsIcon color={vars.textSecondary} size={26} />
              </div>
              <div className={styles.projectName}>
                <div>{t('integrationSetup.notConfigured.title')}</div>
                <div className={styles.projectMetadata}>
                  {t('integrationSetup.notConfigured.message')}
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('integrationProjectEdit.deleteDialog.title')}>
        <h4>{t('integrationProjectEdit.deleteDialog.content')}</h4>
        {t('integrationProjectEdit.delete.description')}
      </ConfirmDialog>
    </div>
  );
}
