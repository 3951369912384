/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Run,
  runGetMany,
  runGetOne,
  runDeleteOne,
  runUpdateOne,
  runCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { runNormalizer } from './runNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const runFetchManyThunk = createAsyncThunk(
  'run/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Run>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await runGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, runNormalizer);
      }
      return {
        run: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runFetchOneThunk = createAsyncThunk(
  'run/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Run>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'run/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await runGetOne(arg.id as number, arg);
      return executeNormalizer([data], runNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runDeleteOneThunk = createAsyncThunk(
  'run/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Run>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'run/DeleteOneThunk');
      }
      return await runDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runUpdateOneThunk = createAsyncThunk(
  'run/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Run>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'run/UpdateOneThunk');
      }
      const data = await runUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Run>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Run>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const runCreateOneThunk = createAsyncThunk(
  'run/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Run>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'run/CreateOneThunk',
        );
      }
      return await runCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
