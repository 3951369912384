import { createSelector } from '@reduxjs/toolkit';
import { State } from '@bitmodern/redux/store';
import { accessRoleSelectors } from 'src/gen/domain/access_role/accessRoleSelector';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { accessRoleUsersSelector } from '@bitmodern/redux/state/accessRolesUsers/selectors';
import { AccessRoleEnum } from 'src/enums/AccessRoleEnum';
import { isDefined } from 'src/utils/common';

export function accessRolesSelector(state: State) {
  return accessRoleSelectors.selectAll(state);
}

export function accessRoleSelector(
  state: State,
  { accessRoleId }: { accessRoleId?: number },
) {
  if (!accessRoleId) return;
  return accessRoleSelectors.selectById(state, accessRoleId);
}

export function accessRolesCountByUserSelector(state: State) {
  const counts = {};
  accessRolesSelector(state).forEach((accessRole) => {
    counts[accessRole.id] = 0;
  });

  accessRoleUsersSelector(state).forEach((a) => {
    counts[a.access_role_id] += 1;
  });

  return counts;
}

export const createAccessRolesByUserSelector = (userId) =>
  createSelector(
    accessRoleUsersSelector,
    accessRolesSelector,
    (accessRoleUsers, accessRoles) => {
      const accessRolesUser = accessRoleUsers.filter(
        (a) => a.user_id === userId,
      );
      return accessRolesUser
        .map((accessRoleUser) =>
          accessRoles.find((role) => role.id === accessRoleUser.access_role_id),
        )
        .filter(isDefined);
    },
  );

export const isCurrentUserAdminSelector = createSelector(
  [currentUserSelector, accessRoleUsersSelector, accessRolesSelector],
  (currentUser, accessRolesUsers, accessRoles): boolean => {
    return accessRolesUsers
      .filter(({ user_id }) => user_id === currentUser?.id)
      .map((aru) => accessRoles.find((ar) => ar.id === aru.access_role_id))
      .some((accessRole) => accessRole?.key === AccessRoleEnum.ADMINISTRATOR);
  },
);
