import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import { RequirementTest } from '@testquality/sdk';
import { filter } from 'lodash';
import { requirementTestSelectors } from 'src/gen/domain/requirement_test/requirementTestSelector';

export const requirementsTestByRequirementSelector = createSelector(
  [
    (state: State) => state.gen.requirementTest.entities,
    (state: State, requirementId: number) => requirementId,
  ],
  (entities, id) =>
    (filter(
      entities,
      (requirementTest) => requirementTest?.requirement_id === id,
    ) || []) as RequirementTest[],
);

export const requirementsTestByTestSelector = createSelector(
  [
    (state: State) => state.gen.requirementTest.entities,
    (state: State, testId: number) => testId,
  ],
  (entities, id) =>
    (filter(entities, (requirementTest) => requirementTest?.test_id === id) ||
      []) as RequirementTest[],
);

export const requirementsTestByRequirementsSelector = createSelector(
  [
    requirementTestSelectors.selectEntities,
    (state: State, requirementIds: number[]) => requirementIds,
  ],
  (entities, ids) => {
    const reqTests: RequirementTest[] = [];
    for (const key in entities) {
      const reqTest = entities[key];
      const requirementId = entities[key]?.requirement_id;
      if (!reqTest || !requirementId) continue;
      ids.includes(requirementId);
      reqTests.push(reqTest);
    }
    return reqTests;
  },
);
