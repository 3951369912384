/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Notifications } from '@testquality/sdk';
import { TQStateStringId } from '../../store/TQState';
import { notificationsAdapter } from './notificationsAdapter';
import {
  notificationsFetchManyThunk,
  notificationsFetchOneThunk,
  notificationsDeleteOneThunk,
  notificationsUpdateOneThunk,
  notificationsCreateOneThunk,
} from './notificationsThunk';

const handleRejected = (
  state: TQStateStringId,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateStringId) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState<TQStateStringId>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    notificationsCleared(state) {
      state.loading = 'idle';
      notificationsAdapter.removeAll(state);
    },
    notificationsUpsertMany(
      state,
      action: PayloadAction<Notifications[] | Record<string, Notifications>>,
    ) {
      notificationsAdapter.upsertMany(state, action.payload);
    },
    notificationsUpsertOne(state, action: PayloadAction<Notifications>) {
      notificationsAdapter.upsertOne(state, action.payload);
    },
    notificationsUpdateOne(
      state,
      action: PayloadAction<Update<Notifications>>,
    ) {
      notificationsAdapter.updateOne(state, action.payload);
    },
    notificationsRemoveOne(state, action: PayloadAction<string>) {
      notificationsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(notificationsFetchManyThunk.pending, handlePending);
    builder.addCase(notificationsFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      notificationsAdapter.upsertMany(state, action.payload.notifications);
    });
    builder.addCase(notificationsFetchManyThunk.rejected, handleRejected);
    builder.addCase(notificationsFetchOneThunk.pending, handlePending);
    builder.addCase(notificationsFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      notificationsAdapter.upsertOne(state, action.payload.notifications[0]);
    });
    builder.addCase(notificationsFetchOneThunk.rejected, handleRejected);
    builder.addCase(notificationsDeleteOneThunk.pending, handlePending);
    builder.addCase(notificationsDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        notificationsAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(notificationsDeleteOneThunk.rejected, handleRejected);
    builder.addCase(notificationsUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, notificationsAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(notificationsUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      notificationsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(notificationsUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          notificationsAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(notificationsCreateOneThunk.pending, handlePending);
    builder.addCase(notificationsCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      notificationsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(notificationsCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  notificationsCleared,
  notificationsUpsertMany,
  notificationsUpsertOne,
  notificationsUpdateOne,
  notificationsRemoveOne,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
