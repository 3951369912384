export default function nestingSize(nestingLevel: number): number {
  if (nestingLevel === 0) return 24;
  const size = (nestingLevel + 1) * 32;

  // compensate smaller 0 level size
  return size - 8;
}

export function dropPlaceholderSize(nestingLevel: number): number {
  return nestingLevel === 0 ? 0 : nestingLevel * 32 + 24;
}
