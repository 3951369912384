import React from 'react';
import useParams from 'src/hooks/useParams';
import {
  plansListSelector,
  makeTestsCountsByPlan,
} from '@bitmodern/redux/state/plans/selectors';
import { routes } from 'src/components/Router';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import Plans from './Plans';
import { useMaker } from 'src/packages/redux/hooks';

function PlansContainer() {
  const { site, planId, projectId } = useParams<typeof routes.PLAN.params>();
  const dispatch = useAppDispatch();
  const plans = useAppSelector((state) =>
    plansListSelector(state, { projectId: parseInt(projectId, 10) }),
  );
  const plansTestCount = useMaker(makeTestsCountsByPlan, {
    projectId: parseInt(projectId, 10),
  });

  const filters = useAppSelector((state) =>
    filtersByTypeSelector(state, 'plansList'),
  );

  const onChangeFilter = (filter) => {
    dispatch(
      toggleFilterAction({
        type: 'plansList',
        filter: 'name',
        value: filter,
      }),
    );
  };

  return (
    <Plans
      currentPlanId={planId}
      currentProjetId={projectId}
      initialFilter={filters.name}
      plans={plans}
      plansTestCount={plansTestCount}
      onChangeFilter={onChangeFilter}
      site={site}
    />
  );
}

export default React.memo(PlansContainer);
