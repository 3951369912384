import React, { ReactNode } from 'react';
import { useRadioGroupState, RadioGroupState } from '@react-stately/radio';
import { AriaRadioGroupProps, useRadioGroup } from 'react-aria';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export const RadioContext = React.createContext<RadioGroupState>(
  {} as RadioGroupState,
);

type Props = AriaRadioGroupProps & {
  children: ReactNode;
  error?: ReactNode;
  className?: string;
  required?: boolean;
};

export default function RadioGroup(props: Props) {
  const { children, error, label, required, className = '' } = props;

  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state as any);

  return (
    <div {...radioGroupProps}>
      {label && (
        <FieldLabel required={required} {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <div className={className}>
        <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
        {error && <FieldError>{error}</FieldError>}
      </div>
    </div>
  );
}
