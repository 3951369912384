import React, { ElementType } from 'react';
import { useSelector } from 'react-redux';
import { DoneIcon, TqLogoIcon } from '@bitmodern/bit-ui/icons';
import { themeColorSelector } from '@bitmodern/redux/state/theme/selectors';
import vars from 'src/export.scss';

import styles from './SuccessIcons.module.scss';

type Props = {
  IntegrationIcon: ElementType;
};

export default function SuccessIcons({ IntegrationIcon }: Props) {
  const themeColor = useSelector(themeColorSelector);

  return (
    <div className={styles.successIcons}>
      <IntegrationIcon size={65} />
      <DoneIcon size={32} className={styles.successDoneIcon} />
      <TqLogoIcon
        size={55}
        color={themeColor === 'dark' ? undefined : vars.tqLogoCheckLight}
      />
    </div>
  );
}
