import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { showNotificationError, HttpError } from '@testquality/sdk';
import { routes } from 'src/components/Router';
import { useQuery } from 'src/hooks/useQuery';
import { useTranslation } from 'src/i18n/hooks';
import { EmailPanel, ReviewPanel } from 'src/modules/authentication/elements';
import { CompleteInviteUserStep1 } from './CompleteInviteUserStep1';
import styles from './CompleteInviteUserView.module.scss';

export function CompleteInviteUserView() {
  const history = useHistory();
  const { email, token } = useQuery(['email', 'token']);
  const { t } = useTranslation();

  useEffect(() => {
    if (!email || !token) {
      showNotificationError(new HttpError(t('completeInviteUser.queryError')));
      history.replace(routes.LOGIN({}));
    }
  }, [email, token, history, t]);

  return (
    <div className={styles.main}>
      <Helmet>
        <title>{t('completeInviteUser.head.title')}</title>
      </Helmet>
      <EmailPanel
        onChangeStep={() => {}}
        step={1}
        Step1={CompleteInviteUserStep1}
      />
      <ReviewPanel />
    </div>
  );
}
