/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { RunResult } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runResultAdapter } from './runResultAdapter';
import {
  runResultFetchManyThunk,
  runResultFetchOneThunk,
  runResultDeleteOneThunk,
  runResultUpdateOneThunk,
  runResultCreateOneThunk,
} from './runResultThunk';
import {
  defectFetchManyThunk,
  defectFetchOneThunk,
} from '../defect/defectThunk';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  defectResFetchManyThunk,
  defectResFetchOneThunk,
} from '../defect_res/defectResThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  nativeDefectResFetchManyThunk,
  nativeDefectResFetchOneThunk,
} from '../native_defect_res/nativeDefectResThunk';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import { suiteFetchManyThunk, suiteFetchOneThunk } from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import { planFetchManyThunk, planFetchOneThunk } from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import {
  statusFetchManyThunk,
  statusFetchOneThunk,
} from '../status/statusThunk';
import {
  statusTypeFetchManyThunk,
  statusTypeFetchOneThunk,
} from '../status_type/statusTypeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import { runFetchManyThunk, runFetchOneThunk } from '../run/runThunk';
import {
  checkRunFetchManyThunk,
  checkRunFetchOneThunk,
} from '../check_run/checkRunThunk';
import {
  checkSuiteFetchManyThunk,
  checkSuiteFetchOneThunk,
} from '../check_suite/checkSuiteThunk';
import {
  pullRequestFetchManyThunk,
  pullRequestFetchOneThunk,
} from '../pull_request/pullRequestThunk';
import {
  milestoneFetchManyThunk,
  milestoneFetchOneThunk,
} from '../milestone/milestoneThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runResultSlice = createSlice({
  name: 'runResult',
  initialState: runResultAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runResultCleared(state) {
      state.loading = 'idle';
      runResultAdapter.removeAll(state);
    },
    runResultUpsertMany(
      state,
      action: PayloadAction<RunResult[] | Record<number, RunResult>>,
    ) {
      runResultAdapter.upsertMany(state, action.payload);
    },
    runResultUpsertOne(state, action: PayloadAction<RunResult>) {
      runResultAdapter.upsertOne(state, action.payload);
    },
    runResultUpdateOne(state, action: PayloadAction<Update<RunResult>>) {
      runResultAdapter.updateOne(state, action.payload);
    },
    runResultRemoveOne(state, action: PayloadAction<number>) {
      runResultAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runResultFetchManyThunk.pending, handlePending);
    builder.addCase(runResultFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultAdapter.upsertMany(state, action.payload.runResult);
    });
    builder.addCase(runResultFetchManyThunk.rejected, handleRejected);
    builder.addCase(runResultFetchOneThunk.pending, handlePending);
    builder.addCase(runResultFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultAdapter.upsertOne(state, action.payload.runResult[0]);
    });
    builder.addCase(runResultFetchOneThunk.rejected, handleRejected);
    builder.addCase(runResultDeleteOneThunk.pending, handlePending);
    builder.addCase(runResultDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        runResultAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(runResultDeleteOneThunk.rejected, handleRejected);
    builder.addCase(runResultUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, runResultAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(runResultUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultAdapter.updateOne(state, action.payload);
    });
    builder.addCase(runResultUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          runResultAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(runResultCreateOneThunk.pending, handlePending);
    builder.addCase(runResultCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      runResultAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(runResultCreateOneThunk.rejected, handleRejected);
    builder.addCase(defectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(defectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(defectResFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(defectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      nativeDefectResFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(nativeDefectResFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(statusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(statusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(statusTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(statusTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.runResult) {
          runResultAdapter.upsertMany(state, action.payload.runResult);
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(runFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(runFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(checkRunFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(checkRunFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(checkSuiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(checkSuiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(pullRequestFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(pullRequestFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(milestoneFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(milestoneFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.runResult) {
        runResultAdapter.upsertMany(state, action.payload.runResult);
      }
    });
  },
});

export const {
  runResultCleared,
  runResultUpsertMany,
  runResultUpsertOne,
  runResultUpdateOne,
  runResultRemoveOne,
} = runResultSlice.actions;

export const runResultReducer = runResultSlice.reducer;
