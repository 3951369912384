/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { RunResultStepApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const runResultStepNormalizer = (
  model: RunResultStepApi,
  cache: NormalizerCache,
) => {
  if (!cache.runResultStep) {
    cache.runResultStep = [];
  }
  cache.runResultStep.push(model);
};
