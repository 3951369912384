import { createSelector } from '@reduxjs/toolkit';
import { defectComponentSelectors } from 'src/gen/domain/defect_component/defectComponentSelector';
import { State } from '@bitmodern/redux/store';

export function defectComponentSelector(state: State) {
  return defectComponentSelectors.selectAll(state);
}

export const defectComponentsByIntegrationProjectSelector = (
  integrationProjectId?: number,
) =>
  createSelector(defectComponentSelector, (defectComponents) =>
    defectComponents.filter(
      ({ integration_project_id }) =>
        integration_project_id === integrationProjectId,
    ),
  );
