import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import {
  AriaRadioProps,
  mergeProps,
  useRadio,
  VisuallyHidden,
} from 'react-aria';
import { useFocusAccent } from '@bitmodern/bit-ui';
import { RadioContext } from '../RadioGroup/RadioGroup';
import styles from './RadioIcon.module.scss';

interface Props extends AriaRadioProps {
  icon: ReactNode;
  value: any;
}

export default function RadioIcon({ icon, ...props }: Props) {
  const ref = useRef(null);
  const state = React.useContext(RadioContext);
  const { inputProps } = useRadio(props, state as any, ref);
  const { borderClassName, borderProps } = useFocusAccent({});

  const { value } = props;

  const isSelected = state.selectedValue === value;

  const radioIconCN = classnames(styles.radioIcon, borderClassName, {
    [styles.selected]: !borderClassName && isSelected,
  });

  return (
    <label htmlFor={inputProps.id}>
      <VisuallyHidden>
        <input {...mergeProps(inputProps, borderProps)} ref={ref} />
      </VisuallyHidden>
      <div className={radioIconCN}>{icon}</div>
    </label>
  );
}
