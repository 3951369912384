import useDefectForm from './useDefectOverview';

export type FormArgs = {
  integrationProjectId: number;
};

export default function useDefectFormOptions({
  integrationProjectId,
}: FormArgs) {
  const {
    defectPriorities,
    defectStatuses,
    defectResolutions,
    defectUsers,
    defectTypes,
    defectLabels,
    defectComponents,
  } = useDefectForm({ integrationProjectId });

  return useGetDefectFormOptions(
    defectPriorities,
    defectStatuses,
    defectResolutions,
    defectUsers,
    defectTypes,
    defectLabels,
    defectComponents,
  );
}

function useGetDefectFormOptions(
  defectPriorities,
  defectStatuses,
  defectResolutions,
  defectUsers,
  defectTypes,
  defectLabels,
  defectComponents,
) {
  const priorityOptions = defectPriorities.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const statusOptions = defectStatuses.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const resolutionOptions = defectResolutions.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const userOptions = defectUsers.map(({ id, name, email, avatar_url }) => ({
    label: name || email,
    value: id,
    picture: avatar_url,
  }));

  const typeOptions = defectTypes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const labelsOptions = defectLabels.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const componentOptions = defectComponents.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return {
    priorityOptions,
    statusOptions,
    resolutionOptions,
    userOptions,
    typeOptions,
    labelsOptions,
    componentOptions,
  };
}
