import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  Dropdown,
  IconButton,
  InputSearch,
  Menu,
  MenuItem,
  PanelHeader,
} from '@bitmodern/bit-ui';
import { RunFilter } from 'src/enums/RunFilterEnum';
import { Option } from '@bitmodern/bit-ui/Select/Select';
import { ArrowDownIcon, FilterIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { Expandable } from 'src/modules/core/elements';
import debounce from 'lodash/debounce';
import vars from 'src/export.scss';
import styles from './RunsFilter.module.scss';

type Props = {
  onChangeFilter: (option: Option) => void;
  onChangeSearch: (text: string) => void;
  options: Option[];
  value: any;
  search: string;
};

export default function RunsFilter({
  onChangeFilter,
  onChangeSearch,
  options,
  value,
  search,
}: Props) {
  const { t } = useTranslation();
  const [showingFilters, setShowingFilters] = useState(false);
  const [searchText, setSearchText] = useState(search);

  const handleChangeSearch = (searchValue) => {
    setSearchText(searchValue);
    onChangeDebounced(searchValue);
  };

  const onChangeDebounced = useMemo(() => {
    return debounce(onChangeSearch, 250);
  }, [onChangeSearch]);

  const onClick = (option) => {
    if (option.value !== value) {
      onChangeFilter(option);
    }
  };

  const toggleFilters = () => {
    setShowingFilters((s) => !s);
  };

  const overlay = (
    <Menu className={styles.overlay}>
      {options.map((option) => (
        <MenuItem
          color="primaryDark"
          key={option.value}
          onClick={() => onClick(option)}>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  const option = options.find((o) => o.value === value);
  const hasRunStatusFilter = Boolean(value) && value !== RunFilter.All;

  const filtersCN = classNames({
    [styles.hasFilters]: Boolean(search) || hasRunStatusFilter,
  });

  return (
    <>
      <PanelHeader
        actions={
          <IconButton
            className={filtersCN}
            onClick={toggleFilters}
            title={t('runs.filter')}>
            <FilterIcon color={vars.textPrimary} size={20} />
          </IconButton>
        }
        title={t('runs.title')}
      />
      <Expandable
        className={styles.filters}
        expanded={showingFilters}
        trigger={0.8}>
        <Dropdown
          className={styles.runsFilter}
          classNameOverlay={styles.overlay}
          overlay={overlay}
          stretch="width">
          <div className={styles.selected}>
            <FilterIcon color={vars.accentMain} size={24} />
            <div className={styles.content}>
              <div className={styles.filter}>{t('runs.filter')}</div>
              <div className={styles.option}>{option?.label}</div>
            </div>
            <ArrowDownIcon color={vars.textPrimary} />
          </div>
        </Dropdown>
        <InputSearch
          autoFocus
          fullWidth
          onChange={handleChangeSearch}
          showLabel={false}
          value={searchText}
        />
      </Expandable>
    </>
  );
}
