import { getResponse } from '@testquality/sdk';
import { getClient } from 'src/Client';

export function cancelSubscriptionService(subscriptionId: number) {
  return getResponse(getClient().api, {
    url: `subscription/cancel/${subscriptionId}`,
    method: 'POST',
  });
}

export function updateSubscription(
  subscriptionId: number,
  productId: number,
  quantity,
  isAutoUpgrade: boolean,
) {
  return getClient().api.post(
    `subscription/change/${subscriptionId}/product/${productId}`,
    { quantity, is_auto_upgrade: isAutoUpgrade, preview: false },
  );
}

export function updateCard(token) {
  return getClient().api.post('subscription/update_card', { token });
}

export function attachGithubPurchaseService(purchase) {
  return getClient().api.post(
    `/subscription/order/github_marketplace/${purchase}`,
  );
}
