/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  AppVersion,
  appVersionGetMany,
  appVersionGetOne,
  appVersionDeleteOne,
  appVersionUpdateOne,
  appVersionCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { appVersionNormalizer } from './appVersionNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const appVersionFetchManyThunk = createAsyncThunk(
  'appVersion/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppVersion>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await appVersionGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, appVersionNormalizer);
      }
      return {
        appVersion: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appVersionFetchOneThunk = createAsyncThunk(
  'appVersion/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppVersion>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'appVersion/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await appVersionGetOne(arg.id as number, arg);
      return executeNormalizer([data], appVersionNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appVersionDeleteOneThunk = createAsyncThunk(
  'appVersion/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppVersion>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'appVersion/DeleteOneThunk');
      }
      return await appVersionDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appVersionUpdateOneThunk = createAsyncThunk(
  'appVersion/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<AppVersion>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'appVersion/UpdateOneThunk');
      }
      const data = await appVersionUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<AppVersion>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<AppVersion>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const appVersionCreateOneThunk = createAsyncThunk(
  'appVersion/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<AppVersion>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'appVersion/CreateOneThunk',
        );
      }
      return await appVersionCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
