import React, { ComponentProps, useMemo, useState } from 'react';
import { usePress } from 'react-aria';
import classnames from 'classnames';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import Input from '../Input';
import Tooltip from '../Tooltip';
import styles from './InputPassword.module.scss';
import { InfoIcon, VisibilityIcon, VisibilityOffIcon } from '../icons';
import { ConsoleLogger } from '../../../common/ConsoleLogger';

const log = new ConsoleLogger('InputPassword');

type Props = Omit<ComponentProps<typeof Input>, 'type'> & {
  onChangeStrength?: (strength: number) => void;
  strengthIndicator?: boolean;
};

export default function InputPassword({
  onChangeStrength,
  value,
  onChange,
  strengthIndicator,
  ...rest
}: Props) {
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState(value);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const onPasswordChange = (event) => {
    const val = event.target.value;
    setPassword(val);
    if (onChange) {
      onChange(event);
    }
    import('zxcvbn').then(
      ({ default: zxcvbn }) => {
        const { score } = zxcvbn(val);
        setPasswordStrength(score);
        if (onChangeStrength) {
          onChangeStrength(score);
        }
      },
      (err) => log.error(err),
    );
  };

  const scoreText = useMemo(() => {
    if (passwordStrength === 0) return 'Too Weak';
    if (passwordStrength === 1) return 'Too Weak';
    if (passwordStrength === 2) return 'Ok';
    if (passwordStrength === 3) return 'Strong';
    if (passwordStrength === 4) return 'Very Strong';
    return 'Ok';
  }, [passwordStrength]);

  const toggleVisibility = usePress({
    onPress: () => setVisible((s) => !s),
  });

  const showScore = Boolean(onChangeStrength) || strengthIndicator;

  return (
    <>
      <Input
        onChange={onPasswordChange}
        endAdornment={
          <span className={styles.visibleIcon} {...toggleVisibility.pressProps}>
            {visible ? (
              <VisibilityIcon color={vars.textSecondary} size={20} />
            ) : (
              <VisibilityOffIcon color={vars.textSecondary} size={20} />
            )}
          </span>
        }
        info={
          showScore && (
            <div className={styles.score}>
              {new Array(5).fill(0).map((_, i) => (
                <span
                  className={classnames(styles.scoreItem, {
                    [styles.selected]: password && i <= passwordStrength,
                  })}
                  key={i} // eslint-disable-line react/no-array-index-key
                />
              ))}
              <Tooltip
                enterDelay={250}
                tooltip={`Password strength: ${scoreText}`}>
                <InfoIcon
                  className={styles.strengthInfo}
                  color={vars.textSecondary}
                  size={16}
                />
              </Tooltip>
            </div>
          )
        }
        type={visible ? 'text' : 'password'}
        value={password}
        {...rest}
      />
    </>
  );
}
