import React, {
  memo,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useMemo,
  ReactElement,
} from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import styles from './FlatList.module.scss';

type KeyExtractor = (item: any, index: number) => string | number;

const defaultKeyExtractor: KeyExtractor = (item, index) => index;

type Props = {
  className?: string;
  context?: any;
  data: any[];
  gap?: number;
  keyExtractor?: KeyExtractor;
  renderEmpty?: (args: { context?: any }) => ReactElement;
  renderItem: (args: {
    item: any;
    context: any;
    index: number;
  }) => ReactElement;
  scrollClassName?: string;
  scrollToElement?: number | string;
};

function FlatList({
  className = '',
  context,
  data,
  gap,
  keyExtractor = defaultKeyExtractor,
  renderEmpty,
  renderItem,
  scrollClassName = '',
  scrollToElement,
}: Props) {
  const list = useRef<VirtuosoHandle>(null);

  const keyExtractorRef = useRef(keyExtractor);
  const dataRef = useRef(data);
  keyExtractorRef.current = keyExtractor;
  dataRef.current = data;

  useEffect(() => {
    if (list.current && scrollToElement) {
      const index = dataRef.current.findIndex(
        (item) => keyExtractorRef.current(item, 0) === scrollToElement,
      );
      if (index >= 0) {
        list.current.scrollIntoView({ index });
      }
    }
  }, [scrollToElement]);

  const initialTopMostItemIndex = useMemo(() => {
    if (typeof scrollToElement === 'undefined') return 0;
    const index = data.findIndex(
      (item) => keyExtractorRef.current(item, 0) === scrollToElement,
    );
    if (index === -1) return 0;
    return index;
  }, [data, scrollToElement]);

  const itemContent = useCallback(
    (index, item, context) => renderItem({ index, item, context }),
    [renderItem],
  );

  const computeItemKey = useCallback(
    (index, item) => keyExtractor(item, index),
    [keyExtractor],
  );

  const List = useMemo(() => {
    return forwardRef<any, any>(
      ({ className = '', style, children, ...props }, ref) => (
        <div className={scrollClassName}>
          <div
            className={`${styles.list} ${className}`}
            ref={ref}
            style={{ gap, ...style }}
            {...props}>
            {children}
          </div>
        </div>
      ),
    );
  }, [gap, scrollClassName]);

  return (
    <Virtuoso
      components={{
        List,
        EmptyPlaceholder: renderEmpty,
      }}
      context={context}
      increaseViewportBy={{ bottom: 1500, top: 1500 }}
      className={className}
      computeItemKey={computeItemKey}
      data={data}
      itemContent={itemContent}
      initialTopMostItemIndex={initialTopMostItemIndex}
      ref={list}
    />
  );
}

export default memo(FlatList);
