import React from 'react';
import { useHistory } from 'react-router-dom';
import { Table, TableBody, TableCell, TableRow } from '@bitmodern/bit-ui';
import StatusIcon from 'src/components/organisms/StatusIcon';
import { RunResult } from '@testquality/sdk';
import {
  formatAppName,
  formatPlatName,
} from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { appVersionSelectors } from 'src/gen/domain/app_version/appVersionSelector';
import { appVersionPlatVersionSelectors } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionSelector';
import { testByRunResultSelector } from '@bitmodern/redux/state/runResults/selectors';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { platVersionSelectors } from 'src/gen/domain/plat_version/platVersionSelector';
import { platSelectors } from 'src/gen/domain/plat/platSelector';
import isNil from 'lodash/isNil';
import styles from './RunResults.module.scss';

export type RunResultsProps = {
  current: number;
  runResults: RunResult[];
};

export function RunResults({ current, runResults }: RunResultsProps) {
  const { generateDrawerPath } = useDrawerManager();
  const history = useHistory();
  const appVersionPlatVersions = useAppSelector(
    appVersionPlatVersionSelectors.selectEntities,
  );
  const appVersionEntities = useAppSelector(appVersionSelectors.selectEntities);
  const appEntities = useAppSelector(appSelectors.selectEntities);
  const platEntities = useAppSelector(platSelectors.selectEntities);
  const statuses = useAppSelector(statusSelectors.selectEntities);
  const platVersionEntities = useAppSelector(
    platVersionSelectors.selectEntities,
  );
  const test = useAppSelector((state) =>
    testByRunResultSelector(state, current),
  );

  const onClickRow = (runResultId: number) => {
    history.push(generateDrawerPath({ resultId: runResultId }));
  };

  const formatAvpv = (avpvId: number): string => {
    const avpv = appVersionPlatVersions[avpvId];
    if (!avpv) return '';
    const platVersion = platVersionEntities[avpv.plat_version_id];
    const plat = platEntities[platVersion?.plat_id || ''];
    const appVersion = appVersionEntities[avpv?.app_version_id || ''];
    const app = appEntities[appVersion?.app_id || ''];

    return [
      formatPlatName({ platVersion, plat }),
      formatAppName({ app, appVersion }),
    ]
      .filter(Boolean)
      .join(' ');
  };

  const data = runResults.map((runResult) => {
    if (
      !isNil(runResult.data_set_row) &&
      !isNil(runResult.app_version_plat_version_id)
    ) {
      // runResults with configurations and dataset
      const avpvName = formatAvpv(runResult.app_version_plat_version_id);
      return { runResult, title: `#${runResult.data_set_row} - ${avpvName}` };
    } else if (!isNil(runResult.data_set_row)) {
      // runResults with dataset table only
      const title = `#${runResult.data_set_row} - ${test?.name}`;
      return { runResult, title };
    } else if (!isNil(runResult.app_version_plat_version_id)) {
      // runResults with configurations only
      const title = formatAvpv(runResult.app_version_plat_version_id);
      return { runResult, title };
    }

    return { runResult, title: test?.name };
  });

  // has no confirgurations and no dataset
  if (
    runResults.length === 1 &&
    !runResults[0].app_version_plat_version_id &&
    isNil(runResults[0].data_set_row)
  )
    return null;

  return (
    <div className={styles.runResults}>
      <Table rounded maxHeight={220}>
        <TableBody>
          {data.map(({ runResult, title }) => {
            const status = statuses[runResult.status_id];

            return (
              <TableRow
                onClick={() => onClickRow(runResult.id)}
                key={runResult.id}
                selected={runResult.id === current}>
                <TableCell className={styles.cell}>{title}</TableCell>
                <TableCell className={styles.cell}>
                  {status && (
                    <span className={styles.status}>
                      <StatusIcon status={status} />
                      <span>{status.name}</span>
                    </span>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
