/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { NativeDefectStatus } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { nativeDefectStatusAdapter } from './nativeDefectStatusAdapter';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
  nativeDefectStatusDeleteOneThunk,
  nativeDefectStatusUpdateOneThunk,
  nativeDefectStatusCreateOneThunk,
} from './nativeDefectStatusThunk';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const nativeDefectStatusSlice = createSlice({
  name: 'nativeDefectStatus',
  initialState: nativeDefectStatusAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    nativeDefectStatusCleared(state) {
      state.loading = 'idle';
      nativeDefectStatusAdapter.removeAll(state);
    },
    nativeDefectStatusUpsertMany(
      state,
      action: PayloadAction<
        NativeDefectStatus[] | Record<number, NativeDefectStatus>
      >,
    ) {
      nativeDefectStatusAdapter.upsertMany(state, action.payload);
    },
    nativeDefectStatusUpsertOne(
      state,
      action: PayloadAction<NativeDefectStatus>,
    ) {
      nativeDefectStatusAdapter.upsertOne(state, action.payload);
    },
    nativeDefectStatusUpdateOne(
      state,
      action: PayloadAction<Update<NativeDefectStatus>>,
    ) {
      nativeDefectStatusAdapter.updateOne(state, action.payload);
    },
    nativeDefectStatusRemoveOne(state, action: PayloadAction<number>) {
      nativeDefectStatusAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nativeDefectStatusFetchManyThunk.pending, handlePending);
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      },
    );
    builder.addCase(nativeDefectStatusFetchManyThunk.rejected, handleRejected);
    builder.addCase(nativeDefectStatusFetchOneThunk.pending, handlePending);
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectStatusAdapter.upsertOne(
          state,
          action.payload.nativeDefectStatus[0],
        );
      },
    );
    builder.addCase(nativeDefectStatusFetchOneThunk.rejected, handleRejected);
    builder.addCase(nativeDefectStatusDeleteOneThunk.pending, handlePending);
    builder.addCase(
      nativeDefectStatusDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          nativeDefectStatusAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(nativeDefectStatusDeleteOneThunk.rejected, handleRejected);
    builder.addCase(
      nativeDefectStatusUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, nativeDefectStatusAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      nativeDefectStatusUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectStatusAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      nativeDefectStatusUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            nativeDefectStatusAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(nativeDefectStatusCreateOneThunk.pending, handlePending);
    builder.addCase(
      nativeDefectStatusCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        nativeDefectStatusAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(nativeDefectStatusCreateOneThunk.rejected, handleRejected);
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectStatus) {
          nativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.nativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectStatus) {
          nativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.nativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.nativeDefectStatus) {
          nativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.nativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.nativeDefectStatus) {
        nativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.nativeDefectStatus,
        );
      }
    });
  },
});

export const {
  nativeDefectStatusCleared,
  nativeDefectStatusUpsertMany,
  nativeDefectStatusUpsertOne,
  nativeDefectStatusUpdateOne,
  nativeDefectStatusRemoveOne,
} = nativeDefectStatusSlice.actions;

export const nativeDefectStatusReducer = nativeDefectStatusSlice.reducer;
