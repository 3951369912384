/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { IntegrationProjectApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { defectNormalizer } from '../defect/defectNormalize';
import { requirementNormalizer } from '../requirement/requirementNormalize';
import { nativeDefectResNormalizer } from '../native_defect_res/nativeDefectResNormalize';
import { nativeDefectStatusNormalizer } from '../native_defect_status/nativeDefectStatusNormalize';

export const integrationProjectNormalizer = (
  model: IntegrationProjectApi,
  cache: NormalizerCache,
) => {
  if (!cache.integrationProject) {
    cache.integrationProject = [];
  }
  cache.integrationProject.push(model);

  if (model.defect && model.defect.length) {
    model.defect.forEach((child) => {
      defectNormalizer(child, cache);
    });
    model.defect = undefined;
  }
  if (model.requirement && model.requirement.length) {
    model.requirement.forEach((child) => {
      requirementNormalizer(child, cache);
    });
    model.requirement = undefined;
  }
  if (model.native_defect_res && model.native_defect_res.length) {
    model.native_defect_res.forEach((child) => {
      nativeDefectResNormalizer(child, cache);
    });
    model.native_defect_res = undefined;
  }
  if (model.native_defect_status && model.native_defect_status.length) {
    model.native_defect_status.forEach((child) => {
      nativeDefectStatusNormalizer(child, cache);
    });
    model.native_defect_status = undefined;
  }
};
