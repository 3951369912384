import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import { linearGradientDef } from '@nivo/core';
import vars from 'src/export.scss';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { Status, Run } from '@testquality/sdk';

import { getStatusColor, getStatusTypeColor } from 'src/utils/statusHelper';
import { Range } from '@bitmodern/bit-ui/InputDateRange/InputDateRange';
import { DateFormat } from 'src/enums/DateFormatEnum';
import styles from './StatusesChart.module.scss';
import ToggleStatus from './ToggleStatus';
import { statusTypesSelector } from 'src/packages/redux/state/statusTypes/selectors';

type Props = {
  range?: Range<Date>;
  runs: Run[];
};

type Data = { id: number; data: { [key: string]: { x: any; y: any } } };

export default function StatusesChart({ runs, range }: Props) {
  const statuses = useAppSelector(statusSelectors.selectAll);
  const [selected, setSeleted] = useState<number[]>(statuses.map((s) => s.id));

  const onToggle = (status: Status) => {
    setSeleted((sel) => {
      if (sel.includes(status.id)) {
        return sel.filter((s) => status.id !== s);
      }
      return [...sel, status.id];
    });
  };

  const currentStatuses = statuses.filter((s) => selected.includes(s.id));
  const statusTypes = useAppSelector(statusTypesSelector);
  const checkStatusColor = (status) => {
    let color = getStatusColor(status);
    if (!color.length) {
      const statusType = statusTypes.find(
        (s) => s.id === status.status_type_id,
      );
      color = getStatusTypeColor(statusType);
    }
    return color;
  };

  const start = range?.[0];
  const min = start ? format(start, DateFormat.Day) : 'auto';
  const max = range?.[1] ? format(range[1], DateFormat.Day) : 'auto';

  return (
    <>
      <div style={{ height: 350 }}>
        <ResponsiveLine
          enableGridX={false}
          enableGridY={false}
          colors={{ datum: 'color' }}
          data={chartData(currentStatuses, runs, checkStatusColor, start)}
          enableArea
          margin={{ top: 20, right: 30, bottom: 40, left: 40 }}
          xScale={{
            format: '%Y-%m-%d',
            max,
            min,
            precision: 'day',
            type: 'time',
            useUTC: false,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
          }}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 80, color: 'inherit', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          pointSize={6}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointColor="#ffffff"
          axisBottom={{
            tickValues: 8,
            format: '%b %d',
          }}
          enableSlices="x"
          useMesh
          theme={{
            crosshair: {
              line: {
                stroke: vars.textPrimary,
              },
            },
            background: 'transparent',
            textColor: vars.textSecondary,
            fontSize: 12,
            tooltip: {
              container: { background: vars.primaryDark },
            },
            axis: {
              domain: {
                line: {
                  stroke: vars.primaryLight,
                  strokeWidth: 1,
                },
              },
              ticks: {
                line: {
                  stroke: vars.primaryLight,
                  strokeWidth: 1,
                },
              },
            },
          }}
        />
      </div>
      <div className={styles.statuses}>
        {statuses.map((status) => (
          <ToggleStatus
            key={status.id}
            status={status}
            selected={selected.includes(status.id)}
            onToggle={onToggle}
          />
        ))}
      </div>
    </>
  );
}

const chartData = (currentStatuses, runs, checkStatusColor, start) => {
  if (!currentStatuses.length || !runs.length) return [];

  const data: Data[] = currentStatuses.map((status) => ({
    id: status.key,
    color: checkStatusColor(status),
    data: {},
  }));

  if (data.length) {
    runs
      .sort(
        (a, b) =>
          new Date(b.start_time || '').getTime() -
          new Date(a.start_time || '').getTime(),
      )
      .forEach((run) => {
        if (!run.analysis?.status?.length || !run.start_time) return;

        const date = format(new Date(run.start_time), DateFormat.Day);
        data.forEach((item) => {
          const total =
            run.analysis?.status.find((s) => s.status_key === item.id)?.total ||
            0;
          if (item.data[date]) {
            item.data[date].y += total;
          } else {
            item.data[date] = { x: date, y: total };
          }
        });
      });
  }

  if (start) {
    data.forEach((item) => {
      const date = format(start, DateFormat.Day);
      item.data[date] = { x: date, y: 0 };
    });
  }

  const series = data
    .map((item) => ({
      ...item,
      id: currentStatuses.find((status) => status.key === item.id)?.name || '',
      data: Object.values(item.data),
    }))
    .filter((item) => item.data.length > 0);

  return series;
};
