/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SharedPreconditionHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { sharedPreconditionHistoryAdapter } from './sharedPreconditionHistoryAdapter';
import { sharedPreconditionHistoryFetchThunk } from './sharedPreconditionHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const sharedPreconditionHistorySlice = createSlice({
  name: 'sharedPreconditionHistory',
  initialState:
    sharedPreconditionHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    sharedPreconditionHistoryCleared(state) {
      state.loading = 'idle';
      sharedPreconditionHistoryAdapter.removeAll(state);
    },
    sharedPreconditionHistoryUpsertMany(
      state,
      action: PayloadAction<
        SharedPreconditionHistory[] | Record<number, SharedPreconditionHistory>
      >,
    ) {
      sharedPreconditionHistoryAdapter.upsertMany(state, action.payload);
    },
    sharedPreconditionHistoryUpsertOne(
      state,
      action: PayloadAction<SharedPreconditionHistory>,
    ) {
      sharedPreconditionHistoryAdapter.upsertOne(state, action.payload);
    },
    sharedPreconditionHistoryUpdateOne(
      state,
      action: PayloadAction<Update<SharedPreconditionHistory>>,
    ) {
      sharedPreconditionHistoryAdapter.updateOne(state, action.payload);
    },
    sharedPreconditionHistoryRemoveOne(state, action: PayloadAction<number>) {
      sharedPreconditionHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedPreconditionHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      sharedPreconditionHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        sharedPreconditionHistoryAdapter.upsertMany(
          state,
          action.payload.sharedPreconditionHistory,
        );
      },
    );
    builder.addCase(
      sharedPreconditionHistoryFetchThunk.rejected,
      handleRejected,
    );
  },
});

export const {
  sharedPreconditionHistoryCleared,
  sharedPreconditionHistoryUpsertMany,
  sharedPreconditionHistoryUpsertOne,
  sharedPreconditionHistoryUpdateOne,
  sharedPreconditionHistoryRemoveOne,
} = sharedPreconditionHistorySlice.actions;

export const sharedPreconditionHistoryReducer =
  sharedPreconditionHistorySlice.reducer;
