import { unwrapResult } from '@reduxjs/toolkit';
import { AppVersion, PlatVersion } from '@testquality/sdk';
import { useFormik } from 'formik';
import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Dialog, Grid, Input } from '@bitmodern/bit-ui';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { appCreateOneThunk } from 'src/gen/domain/app/appThunk';
import { appVersionCreateOneThunk } from 'src/gen/domain/app_version/appVersionThunk';
import { appVersionPlatVersionCreateOneThunk } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionThunk';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './AppFormDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('appForm.form.name'),
  major: Yup.string().required().label('appForm.form.major'),
  minor: Yup.string().required().label('appForm.form.minor'),
});

type Props = {
  appVersion?: AppVersion;
  platVersion?: PlatVersion;
} & Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>;

export default function AppFormDialog({
  appVersion,
  isOpen,
  onClose,
  platVersion,
}: Props) {
  const isEdit = Boolean(appVersion);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const apps = useAppSelector(appSelectors.selectAll);

  const initialValues = useMemo(() => {
    return {
      name: '',
      major: appVersion?.major || '',
      minor: appVersion?.minor || '',
    };
  }, [appVersion]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  function onSubmit(values) {
    if (!platVersion) return Promise.reject();

    if (isEdit) {
      return undefined;
    }

    const app = apps.find(
      (a) => a.name.toLowerCase() === values.name.toLowerCase(),
    );

    const appRequest = app
      ? Promise.resolve(app)
      : dispatch(
          appCreateOneThunk({
            data: {
              name: values.name,
              is_default: false,
            },
          }),
        ).then(unwrapResult);

    return appRequest
      .then((res) => {
        return dispatch(
          appVersionCreateOneThunk({
            data: {
              is_default: false,
              major: values.major,
              minor: values.minor,
              app_id: res.id,
            },
          }),
        );
      })
      .then(unwrapResult)
      .then((res) => {
        return dispatch(
          appVersionPlatVersionCreateOneThunk({
            data: {
              app_version_id: res.id,
              plat_version_id: platVersion.id,
            },
          }),
        );
      })
      .then(onClose);
  }

  const title = isEdit ? t('appForm.edit') : t('appForm.create');
  const submit = isEdit ? t('appForm.form.update') : t('appForm.form.create');

  return (
    <Dialog isOpen={isOpen} onClose={onClose} size="medium" title={title}>
      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          error={formikError(formik, 'name')}
          fullWidth
          label={t('appForm.form.name')}
          name="name"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          placeholder={t('appForm.form.namePlaceholder')}
          value={formik.values.name}
        />
        <Grid.Row gutter={[16, 0]}>
          <Grid.Col span={12} sm={6}>
            <Input
              error={formikError(formik, 'major')}
              fullWidth
              label={t('appForm.form.major')}
              name="major"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              placeholder={t('appForm.form.majorPlaceholder')}
              value={formik.values.major}
            />
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <Input
              error={formikError(formik, 'minor')}
              fullWidth
              label={t('appForm.form.minor')}
              name="minor"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              placeholder={t('appForm.form.minorPlaceholder')}
              value={formik.values.minor}
            />
          </Grid.Col>
        </Grid.Row>
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit">
          {submit}
        </Button>
      </form>
    </Dialog>
  );
}
