/* eslint-disable react/prop-types */
import React, { useLayoutEffect } from 'react';
import {
  DismissButton,
  FocusScope,
  mergeProps,
  OverlayContainer,
  useListBox,
  useOverlay,
  useOverlayPosition,
} from 'react-aria';
import Option from './Option';
import styles from './Options.module.scss';

export default function Options({
  listRef,
  overlayRef,
  state,
  targetRef,
  width,
  ...otherProps
}: any) {
  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy || true,
      disallowEmptySelection: false,
      shouldFocusWrap: true,
      ...otherProps,
    },
    state,
    listRef,
  );

  const { overlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: state.isOpen,
      onClose: state.close,
      shouldCloseOnBlur: true,
    },
    overlayRef,
  );

  const { overlayProps: positionProps, updatePosition } = useOverlayPosition({
    isOpen: state.isOpen,
    offset: 8,
    onClose: state.close,
    overlayRef,
    scrollRef: listRef,
    targetRef,
  });

  useLayoutEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(updatePosition);
    }
  }, [state.isOpen, updatePosition]);

  return (
    <OverlayContainer>
      <FocusScope restoreFocus>
        <div
          {...mergeProps(overlayProps, {
            style: { ...positionProps.style, width },
          })}
          ref={overlayRef}>
          <DismissButton onDismiss={state.close} />
          <ul
            {...mergeProps(listBoxProps, otherProps)}
            ref={listRef}
            className={styles.options}>
            {[...state.collection].map((item) => (
              <Option key={item.key} item={item} state={state} />
            ))}
          </ul>
          <DismissButton onDismiss={state.close} />
        </div>
      </FocusScope>
    </OverlayContainer>
  );
}
