import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useOverlayTriggerState } from 'react-stately';
import {
  Checkbox,
  Chip,
  Dropdown,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  notification,
  PanelHeader,
  Select,
  SelectMultiple,
  Spacer,
  Surface,
  Tab,
  Tabs,
  Button,
} from '@bitmodern/bit-ui';
import { ConfirmDialog, DataSet } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import {
  CancelIcon,
  CollapseIcon,
  CopyIcon,
  ExpandIcon,
  MoreIcon,
  WatchIcon,
  WatchRemoveIcon,
  TableCharIcon,
  DeleteIcon,
} from '@bitmodern/bit-ui/icons';
import useDrawerManager from 'src/hooks/useDrawerManager';
import {
  Test as TestModel,
  Step,
  TestApi,
  showNotificationError,
  Requirement,
  Defect,
  BatchService,
} from '@testquality/sdk';
import {
  DataSet as DataSetModel,
  dataSetSelector,
} from 'src/packages/redux/state/dataSets/selectors';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useQuery } from 'src/hooks/useQuery';
import { useParamSelector } from 'src/packages/redux/hooks';
import { caseTypesSelector } from '@bitmodern/redux/state/caseTypes/selectors';
import { inlineEditManager } from '@bitmodern/bit-ui/InlineEdit/useEditing';

import { watchByTestIdSelector } from '@bitmodern/redux/state/watch/selectors';
import { casePrioritiesSelector } from '@bitmodern/redux/state/casePriorities/selectors';
import { plansIncludesTest } from '@bitmodern/redux/state/plans/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { suitesFlatTreeSelector } from '@bitmodern/redux/state/suites/selectors';
import {
  suitesByTest,
  suiteTestsByTestSelector,
} from '@bitmodern/redux/state/suiteTests/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { testQualitySelectorById } from '@bitmodern/redux/state/testQuality/selectors';
import { dataSetDeleteOneThunk } from 'src/gen/domain/data_set/dataSetThunk';
import EasyMEDInlineEdit from '@bitmodern/bit-ui/InlineEdit/EasyMEDInlineEdit';
import { testUpdateOneThunk } from 'src/gen/domain/test/testThunk';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import {
  stepCreateOneThunk,
  stepDeleteOneThunk,
  stepUpdateOneThunk,
} from 'src/gen/domain/step/stepThunk';
import {
  labelAssignedByTestSelector,
  selectLabelAssignedByTestIds,
} from '@bitmodern/redux/state/label_assigned/selectors';
import {
  labelAssignedCreateOneThunk,
  labelAssignedDeleteOneThunk,
} from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { cloneTestStep } from '@bitmodern/services/TestStepService';
import { watchTestThunk } from '@bitmodern/redux/state/watch/thunks';
import {
  suiteTestCreateOneThunk,
  suiteTestDetachThunk,
} from 'src/gen/domain/suite_test/suiteTestThunk';
import { testUserOverride } from 'src/constants';
import { putTestQualityUserOverride } from '@bitmodern/services/TestQualityService';
import { labelCreateOneThunk } from 'src/gen/domain/label/labelThunk';
import { runResultsByTestSelector } from '@bitmodern/redux/state/runResults/selectors';
import { InputDirectInlineEdit } from '@bitmodern/bit-ui/InlineEdit/InputDirectInlineEdit';
import useMutation from 'src/hooks/useMutation';
import { testCloneOneThunk } from '@bitmodern/redux/state/tests/thunks';
import vars from 'src/export.scss';
import InputNumberInlineEdit from '@bitmodern/bit-ui/InlineEdit/InputNumberInlineEdit';
import { upsertDataSetThunk } from 'src/packages/redux/state/dataSets/thunks';
import Attachments from '../Attachments';
import TestQualityField from '../TestQualityField';
import StepsForm from '../StepsForm';
import CustomProperties from '../CustomProperties/CustomProperties';
import Requirements from '../Requirements';
import UserSelect from '../UserSelect';
import Activity from '../Activity';
import CommentCreate from '../CommentCreate';
import RunResultsAndDefects from '../RunResultsAndDefects';
import styles from './Test.module.scss';
import { getUsersMap } from '../../../utils/fileHelper';
import CloneDialog from '../CloneDialog';

type Props = {
  activity: any;
  defects: Defect[];
  loadingRuns: boolean;
  onAddRequirement: () => Promise<any>;
  onClose: () => void;
  onComment: (comment: string) => void;
  onDelete: () => Promise<any>;
  requirements: Requirement[];
  steps: Step[];
  test: TestModel;
} & Pick<ComponentProps<typeof Activity>, 'onDeleteComment' | 'onEditComment'>;

export default function Test({
  activity,
  defects,
  loadingRuns,
  onAddRequirement,
  onClose,
  onComment,
  onDelete,
  onDeleteComment,
  onEditComment,
  requirements,
  steps,
  test,
}: Props) {
  const content = useRef<HTMLDivElement>(null);
  const { site, projectId } = useParams<typeof routes.PLAN.params>();
  const { folderId } = useQuery(['folderId']);
  const { t } = useTranslation();
  const drawer = useDrawerManager();
  const history = useHistory();
  const [tabs, setTabs] = useState('overview');
  const [suiteIds, setSuiteIds] = useState<number[]>([]);
  const [changeLabel, setChangeLabel] = useState(false);
  const [isDataSetAdded, setIsDataSetAdded] = useState(false);
  const deleteDialog = useOverlayTriggerState({});
  const [userOverride, setUserOverride] = useState<boolean>(
    // eslint-disable-next-line no-bitwise
    (test.state_mask! & testUserOverride) > 0,
  );

  const dispatch = useAppDispatch();
  const cloneDialog = useOverlayTriggerState({});
  const runResults = useAppSelector((state) =>
    runResultsByTestSelector(state, test.id),
  );
  const caseTypes = useAppSelector(caseTypesSelector);
  const casePriorities = useAppSelector(casePrioritiesSelector);
  const users = useAppSelector(usersSelector);
  const suitesInCurrentTest = useAppSelector((state) =>
    suitesByTest(state, { testId: test.id }),
  );
  const testLabels = useAppSelector((state) =>
    labelAssignedByTestSelector(state, { testId: test.id }),
  );
  const labelIds = useAppSelector((state) =>
    selectLabelAssignedByTestIds(state, { testId: test.id }),
  );
  const plans = useAppSelector((state) => plansIncludesTest(state, test.id));
  const labels = useAppSelector(labelsSelector);
  const suiteTests = useAppSelector((state) =>
    suiteTestsByTestSelector(state, test.id),
  );
  const testWatch = useAppSelector((state) =>
    watchByTestIdSelector(state, test.id),
  );
  const testQuality = useAppSelector((state) =>
    testQualitySelectorById(state, test.test_quality_id!),
  );
  const suitesTree = useAppSelector((state) =>
    suitesFlatTreeSelector(state, parseInt(projectId, 10)),
  );

  const dataSet = useParamSelector(dataSetSelector, {
    dataSetId: test.data_set_id,
  });

  const onConfirmDelete = useCallback(() => {
    return onDelete().finally(deleteDialog.close);
  }, [deleteDialog, onDelete]);

  const deleteTestMutation = useMutation(onConfirmDelete);

  useLayoutEffect(() => {
    inlineEditManager.commit();
    if (content.current) {
      content.current.scrollTo({ top: 0 });
    }
  }, [test.id]);

  useEffect(() => {
    // eslint-disable-next-line no-bitwise
    setUserOverride((test.state_mask! & testUserOverride) > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [test.state_mask, testUserOverride]);

  useEffect(() => {
    setSuiteIds(suitesInCurrentTest.map((suite) => suite.id));
  }, [suitesInCurrentTest]);

  const assignedToOptions = getUsersMap(users);

  const suiteOptions = suitesTree.map((item) => ({
    value: item.data.suite.id,
    label: item.data.suite?.is_root
      ? t('test.rootFolder')
      : item.data?.suite?.name,
    style: { paddingLeft: Math.max(item.nestingLevel, 1) * 16 },
  }));

  const labelOptions = labels.map((label) => ({
    value: label.id,
    label: label.label,
  }));

  const caseTypesOptions = caseTypes.map((caseType) => ({
    value: caseType.id,
    label: caseType.name,
  }));

  const casePriorityOptions = casePriorities.map((casePriority) => ({
    value: casePriority.id,
    label: casePriority.name,
  }));

  const onChangeCaseType = (value) => {
    return updateTest({ case_type_id: value });
  };

  const onChangeCasePriority = (value) => {
    return updateTest({ case_priority_id: value });
  };

  const onChangeAssignedTo = (value) => {
    return updateTest({ assigned_to_tester: value === '' ? null : value });
  };

  const onChangeSuites = useCallback(
    (updatedSuiteIds) => {
      if (updatedSuiteIds.length === 0) {
        notification.open({
          type: 'warning',
          message: t('test.errors.detachSuiteErrorMsg'),
          description: t('test.errors.detachSuiteError'),
        });
        return Promise.reject(t('test.errors.detachSuiteError'));
      }

      /* NOTE: We can only detach or attach ONE suite at a time (hence we use .find) */
      const suiteToDetach = suiteIds.find(
        (id) => !updatedSuiteIds.includes(id),
      );
      const suiteToAttach = updatedSuiteIds.find(
        (id) => !suiteIds.includes(id),
      );

      // Detach test from suites
      if (suiteToDetach) {
        const suiteTest = suiteTests.find(
          (entity) => entity.suite_id === suiteToDetach,
        );
        return dispatch(
          suiteTestDetachThunk({
            data: {
              id: suiteTest?.id,
              test_id: test.id,
              suite_id: suiteToDetach,
            },
          }),
        )
          .then(unwrapResult)
          .then((res) => {
            setSuiteIds(suiteIds.filter((value) => value !== suiteToDetach));
            return res;
          });
      }

      // Attach test to suites
      if (suiteToAttach) {
        return dispatch(
          suiteTestCreateOneThunk({
            data: {
              test_id: test.id,
              suite_id: suiteToAttach,
            },
          }),
        )
          .then(unwrapResult)
          .then((res) => {
            setSuiteIds([...suiteIds, suiteToAttach]);
            return res;
          });
      }

      return Promise.reject();
    },
    [dispatch, test.id, t, suiteIds, suiteTests],
  );

  const changeSuitesMutation = useMutation(onChangeSuites);

  const onChangeLabels = useCallback(
    (values) => {
      if (changeLabel) {
        return Promise.resolve();
      }
      setChangeLabel(true);
      const batch = new BatchService();
      const reqs: Array<Promise<any>> = [];

      // remove items
      labelIds.forEach((id) => {
        if (!values.includes(id)) {
          const labelAssigned = testLabels.find((la) => la.label_id === id);
          if (labelAssigned) {
            dispatch(
              labelAssignedDeleteOneThunk({ id: labelAssigned.id, batch }),
            );
          }
        }
      });

      // add items
      values.forEach((value) => {
        if (!labelIds.includes(value)) {
          let labelIdPromise = Promise.resolve(value);
          if (typeof value === 'string') {
            // Add new label first
            labelIdPromise = dispatch(
              labelCreateOneThunk({ data: { label: value } }),
            )
              .then(unwrapResult)
              .then((label) => label.id);
          }
          reqs.push(
            labelIdPromise.then((labelId) => {
              return dispatch(
                labelAssignedCreateOneThunk({
                  data: {
                    related_id: test.id,
                    related_type: `App\\Models\\${test.metadata_model}`,
                    label_id: labelId,
                  },
                }),
              ).then(unwrapResult);
            }),
          );
        }
      });

      return Promise.all([batch.executeBatch(), ...reqs]).then(() => {
        setChangeLabel(false);
      });
    },
    [
      dispatch,
      labelIds,
      testLabels,
      test.id,
      setChangeLabel,
      changeLabel,
      test.metadata_model,
    ],
  );

  const changeLabelsMutation = useMutation(onChangeLabels);

  const updateTest = useCallback(
    (value: Partial<TestModel> | Partial<TestApi>) => {
      return dispatch(
        testUpdateOneThunk({ data: value, id: test.id, optimistic: true }),
      );
    },
    [dispatch, test.id],
  );

  const onChangePrecondition = useCallback(
    (precondition) => {
      updateTest({ precondition });
      return Promise.resolve();
    },
    [updateTest],
  );

  const onChangeIsAutomated = (checked) => {
    updateTest({ is_automated: checked });
  };

  const onChangeEstimate = (estimate) => {
    return updateTest({ estimate });
  };

  const onChangeTestQuality = (value: boolean): Promise<any> => {
    return putTestQualityUserOverride(value, test.id).then(() =>
      setUserOverride(!value),
    );
  };

  const onCommitName = (name) => {
    return updateTest({ name });
  };

  const onChangeVirtual = (virtual) => {
    updateTest({ virtual });
  };

  const addStep = () => {
    return dispatch(
      stepCreateOneThunk({
        data: {
          test_id: test.id,
          project_id: parseInt(projectId, 10),
        },
      }),
    );
  };

  const onCommitStep = (step) => {
    dispatch(stepUpdateOneThunk({ data: step, id: step.id }));
  };

  const cloneStep = (step: Step) => {
    cloneTestStep(step.id)
      .then(() => {
        notification.open({
          type: 'success',
          message: 'Success',
          description: t('step.actions.notifications.cloneSuccess'),
        });
      })
      .catch(showNotificationError);
  };

  const deleteStep = async (step: Step) => {
    await dispatch(stepDeleteOneThunk(step));
    const newSteps = steps.filter((s) => s.id !== step.id);
    onChangeOrder(newSteps);
  };

  const onDeleteDataSet = useCallback(() => {
    if (!dataSet) return Promise.reject();
    return dispatch(dataSetDeleteOneThunk({ id: dataSet.id })).then(() =>
      updateTest({ data_set_id: undefined }),
    );
  }, [dispatch, dataSet, updateTest]);

  const onCloneTest = ({ suite }: any) => {
    return dispatch(
      testCloneOneThunk({
        suiteTestId: parseInt(folderId, 10),
        suiteId: suite,
      }),
    )
      .then(unwrapResult)
      .then((newTest) => {
        notification.open({
          type: 'success',
          message: `Success created ${newTest.name}`,
          description: t('test.actions.notifications.cloneSuccess'),
        });

        if (newTest.project_id === parseInt(projectId, 10)) {
          history.push({
            search: drawer.generateDrawerUrlParams({ testId: newTest.id }),
          });
        }
      })
      .catch(showNotificationError);
  };

  const onWatchTest = () => {
    dispatch(watchTestThunk(test.id)).then(() =>
      notification.open({
        type: 'success',
        message: t('notifications.watchAdded.message'),
        description: t('notifications.watchAdded.description'),
      }),
    );
  };

  const onChangeOrder = (stepsToOrder: Step[]) => {
    const batch = new BatchService();
    const newSteps = stepsToOrder.map((step, i) => ({
      ...step,
      sequence: i + 1,
    }));
    newSteps.forEach((step) => {
      dispatch(
        stepUpdateOneThunk({
          id: step.id,
          data: step,
          optimistic: true,
          batch,
        }),
      );
    });
    batch.executeBatch();
  };

  const onUnwatchTest = () => {
    dispatch(watchDeleteOneThunk({ id: testWatch?.id }))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notifications.watchRemoved.message'),
          description: t('notifications.watchRemoved.description'),
        }),
      );
  };

  const handleDataSetCommit = (value: Partial<DataSetModel>) => {
    return dispatch(
      upsertDataSetThunk({
        dataSet: {
          ...value,
          project_id: parseInt(projectId, 10),
        },
        testId: test.id,
      }),
    );
  };

  const handleDataSetCancel = () => {
    setIsDataSetAdded(false);
  };

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          {testWatch ? (
            <MenuItem
              startAdornment={
                <WatchRemoveIcon color={vars.textPrimary} size={18} />
              }
              onClick={onUnwatchTest}>
              {t('test.actions.unwatchTest')}
            </MenuItem>
          ) : (
            <MenuItem
              startAdornment={<WatchIcon color={vars.textPrimary} size={18} />}
              onClick={onWatchTest}>
              {t('test.actions.watchTest')}
            </MenuItem>
          )}
          <MenuItem
            startAdornment={<CopyIcon color={vars.textPrimary} size={18} />}
            onClick={cloneDialog.open}>
            {t('test.actions.clone')}
          </MenuItem>
          <MenuItem
            startAdornment={<DeleteIcon color={vars.textPrimary} size={18} />}
            onClick={deleteDialog.open}>
            {t('test.actions.delete')}
          </MenuItem>
        </Menu>
      }>
      <IconButton title={t('test.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  const DataSetAddButton = useMemo(() => {
    if (dataSet) return null;
    return (
      <Button
        size="small"
        color="primaryLight"
        icon={<TableCharIcon color={vars.textPrimary} size={16} />}
        onClick={() => {
          setIsDataSetAdded(true);
        }}>
        {t('test.dataSet.add')}
      </Button>
    );
  }, [setIsDataSetAdded, t, dataSet]);

  return (
    <Surface className={styles.test}>
      <PanelHeader
        title={
          <InputDirectInlineEdit
            className={styles.name}
            label="Test Name"
            onCommit={onCommitName}
            showLabel={false}
            value={test.name}
          />
        }
        actions={
          <Spacer>
            {more}
            {drawer.isExpanded ? (
              <IconButton
                onClick={drawer.collapse}
                title={t('test.actions.collapse')}>
                <CollapseIcon color={vars.textPrimary} size={18} />
              </IconButton>
            ) : (
              <IconButton
                onClick={drawer.expand}
                title={t('test.actions.expand')}>
                <ExpandIcon color={vars.textPrimary} size={18} />
              </IconButton>
            )}
            <IconButton
              boxed={false}
              onClick={onClose}
              title={t('test.actions.close')}>
              <CancelIcon color={vars.textPrimary} size={18} />
            </IconButton>
          </Spacer>
        }
      />
      <div className={styles.content} ref={content}>
        <Tabs tab={tabs} onChange={setTabs} withBarBackground>
          <Tab id="overview">{t('test.tabs.overview')}</Tab>
          <Tab id="defects">{t('test.tabs.runsAndDefects')}</Tab>
          <Tab id="requirements">{t('test.tabs.requirements')}</Tab>
        </Tabs>
        {tabs === 'overview' && (
          <>
            <div className={styles.section}>
              <div className={styles.title}>
                {t('test.overview.precondition')}
              </div>
              <EasyMEDInlineEdit
                onCommit={onChangePrecondition}
                parentId={test.id}
                parentType="Test"
                value={test.precondition}
              />
            </div>
            <div className={styles.section}>
              {(isDataSetAdded || dataSet) && (
                <DataSet
                  dataSet={dataSet}
                  onCancel={handleDataSetCancel}
                  onCommit={handleDataSetCommit}
                  onDelete={onDeleteDataSet}
                />
              )}
              <StepsForm
                className={styles.steps}
                extraNode={DataSetAddButton}
                onAddStep={addStep}
                onClone={cloneStep}
                onCommitStep={onCommitStep}
                onChangeOrder={onChangeOrder}
                onDelete={deleteStep}
                parentId={test.id}
                parentType="Test"
                steps={steps}
                highlights={Object.keys(dataSet?.schema || {})}
              />
            </div>
            <div className={styles.section}>
              <Grid.Row gutter={8}>
                <Grid.Col span={6}>
                  <Select
                    label={t('test.overview.caseType')}
                    placeholder="Select"
                    onChange={onChangeCaseType}
                    options={caseTypesOptions}
                    value={test.case_type_id}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Select
                    label={t('test.overview.casePriority')}
                    placeholder="Select"
                    onChange={onChangeCasePriority}
                    options={casePriorityOptions}
                    value={test.case_priority_id}
                  />
                </Grid.Col>
              </Grid.Row>
              <UserSelect
                label={t('test.overview.assignedTo')}
                allowClear
                onChange={onChangeAssignedTo}
                options={assignedToOptions}
                placeholder="Select"
                value={test.assigned_to_tester}
              />
              <Checkbox
                checked={test.is_automated}
                className={styles.isAutomated}
                onChange={onChangeIsAutomated}>
                {t('test.overview.isAutomated')}
              </Checkbox>

              {plans.length > 0 && (
                <>
                  <div className={styles.label}>{t('test.overview.plans')}</div>
                  {plans.map((plan) => (
                    <Link
                      key={plan.id}
                      className={styles.plan}
                      to={routes.PLAN({
                        site,
                        projectId,
                        planId: plan.id.toString(),
                      })}>
                      <Chip key={plan.id}>{plan.name}</Chip>
                    </Link>
                  ))}
                </>
              )}

              <SelectMultiple
                label={t('test.overview.suites')}
                loading={changeSuitesMutation.isLoading}
                onChange={changeSuitesMutation.mutate}
                options={suiteOptions}
                placeholder={t('test.overview.suitesPlaceholder')}
                values={suiteIds}
              />
              <SelectMultiple
                label={t('test.overview.labels')}
                loading={changeLabelsMutation.isLoading}
                onChange={changeLabelsMutation.mutate}
                options={labelOptions}
                placeholder={t('test.overview.labelsPlaceholder')}
                tags
                values={labelIds}
              />
              <div className={styles.label}>
                {t('test.overview.testQuality')}
              </div>
              <TestQualityField
                userOverride={userOverride}
                onChange={onChangeTestQuality}
                testQuality={testQuality}
              />
              <InputNumberInlineEdit
                label={t('test.overview.estimate')}
                fullWidth
                onCommit={onChangeEstimate}
                value={test.estimate}
              />

              {Object.keys(test.virtual || {}).length > 0 && (
                <h3 className={styles.title}>
                  {t('test.overview.customProperties')}
                </h3>
              )}
              <CustomProperties onChange={onChangeVirtual} model={test} />
              <h3 className={styles.title}>{t('test.overview.attachments')}</h3>
              <Attachments parentType="test" parentId={test.id} />
            </div>
            <div className={styles.section}>
              <Activity
                items={activity}
                textFieldsItems={['precondition', 'step', 'expected_result']}
                onDeleteComment={onDeleteComment}
                onEditComment={onEditComment}
              />
              <CommentCreate
                onComment={onComment}
                parentId={test.id}
                parentType="Test"
              />
            </div>
          </>
        )}
        {tabs === 'defects' && (
          <RunResultsAndDefects
            defects={defects}
            runResults={runResults}
            loading={loadingRuns}
          />
        )}
        {tabs === 'requirements' && (
          <Requirements
            onAddRequirement={onAddRequirement}
            requirements={requirements}
          />
        )}

        <ConfirmDialog
          loading={deleteTestMutation.isLoading}
          onCancel={deleteDialog.close}
          onConfirm={deleteTestMutation.mutate}
          open={deleteDialog.isOpen}
          title={t('test.deleteDialog.title')}>
          {t('test.deleteDialog.content')}
        </ConfirmDialog>
      </div>
      <CloneDialog
        isOpen={cloneDialog.isOpen}
        onClone={onCloneTest}
        onClose={cloneDialog.close}
      />
    </Surface>
  );
}
