/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { BaseCapabilityApi } from '@testquality/sdk';
import { BaseCapabilityBaseIntegration } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { capabilityNormalizer } from '../capability/capabilityNormalize';
import { baseIntegrationNormalizer } from '../base_integration/baseIntegrationNormalize';
import { baseCapabilityBaseIntegrationNormalizer } from '../base_capability_base_integration/baseCapabilityBaseIntegrationNormalize';

export const baseCapabilityNormalizer = (
  model: BaseCapabilityApi,
  cache: NormalizerCache,
) => {
  if (!cache.baseCapability) {
    cache.baseCapability = [];
  }
  cache.baseCapability.push(model);

  if (model.capability && model.capability.length) {
    model.capability.forEach((child) => {
      capabilityNormalizer(child, cache);
    });
    model.capability = undefined;
  }
  if (model.base_integration && model.base_integration.length) {
    model.base_integration.forEach((child) => {
      baseCapabilityBaseIntegrationNormalizer(
        child.pivot as BaseCapabilityBaseIntegration,
        cache,
      );
      child.pivot = undefined;
      baseIntegrationNormalizer(child, cache);
    });
    model.base_integration = undefined;
  }
};
