import { defaultGetNodeKey, getFlatDataFromTree } from 'react-sortable-tree';
import { TEST_TYPE } from 'src/components/organisms/TreeBase/treeTypes';
import { RunResultTreeItem } from '@bitmodern/redux/state/runResults/selectors';

export const treeToRunResultData = (runResultsTree: RunResultTreeItem[]) => {
  return getFlatDataFromTree({
    treeData: runResultsTree,
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
  }).reduce<Array<RunResultTreeItem['data']>>((acc, item) => {
    if (item.node.type === TEST_TYPE) {
      acc.push(item.node.data);
    }
    return acc;
  }, []);
};
