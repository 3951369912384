import React, { ComponentProps, PureComponent } from 'react';
import classnames from 'classnames';
import ShowdownView from 'react-showdown';
import DOMPurify from 'dompurify';
import ErrorDisplay from '../ErrorDisplay';
import escapeRegExp from 'lodash/escapeRegExp';
import Image from './components/Image';
import styles from './MarkdownView.module.scss';

type ShowdownViewProps = ComponentProps<typeof ShowdownView>;

type Props = {
  className?: string;
  markdown?: string;
  read?: boolean;
  rendered?: string;
  highlights?: string[];
} & Pick<ShowdownViewProps, 'components'>;

export default class MarkdownView extends PureComponent<Props> {
  static defaultProps = {
    className: '',
    markdown: '',
    read: false,
    rendered: '',
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  render() {
    const { className, markdown = '', read, rendered } = this.props;
    const { hasError } = this.state;

    const markdownCN = classnames(styles.markdownView, className, {
      [styles.read]: read,
    });

    if (hasError) {
      return (
        <ErrorDisplay className={styles.error}>
          Error rendering markup.
        </ErrorDisplay>
      );
    }

    if (rendered) {
      return (
        <div
          className={styles.markdownCN}
          dangerouslySetInnerHTML={{ __html: rendered }} // eslint-disable-line react/no-danger
        />
      );
    }

    return (
      <div className={markdownCN}>
        <ShowdownView
          components={{
            img: Image,
          }}
          extensions={this.extensions}
          markdown={markdown}
          options={{ tables: true }}
          flavor="github"
          sanitizeHtml={DOMPurify.sanitize}
        />
      </div>
    );
  }

  get extensions() {
    const { highlights } = this.props;
    if (!highlights?.length) return undefined;

    return [
      {
        regex: new RegExp(
          `{{(${highlights.map(escapeHighlight).join('|')})}}`,
          'g',
        ),
        replace: (str) => `<span class=${styles.highlight}>${str}</span>`,
        type: 'lang',
      },
    ];
  }
}

function escapeHighlight(str: string) {
  return escapeRegExp(str.replace('\\', ''));
}
