import React from 'react';
import { DocsIcon, HelpIcon, SlackLogoIcon } from '@bitmodern/bit-ui/icons';
import { Dropdown, IconButton } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import styles from './DocumentationAction.module.scss';

const DOCS_URL = 'https://doc.testquality.com/';
const SLACK_URL =
  'https://join.slack.com/t/testqualitycommunity/shared_invite/zt-se7iq9x1-xYwbTBlq47M09Bhxbu2irA';

export function DocumentationAction() {
  const { t } = useTranslation();

  return (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <ul className={styles.overlay}>
          <li>
            <a
              className={styles.item}
              href={SLACK_URL}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.icon}>
                <SlackLogoIcon size={22} />
              </div>
              <div>
                <div className={styles.title}>{t('app.header.help.slack')}</div>
                <div className={styles.desc}>
                  {t('app.header.help.slackDesc')}
                </div>
              </div>
            </a>
          </li>
          <li>
            <a
              className={styles.item}
              href={DOCS_URL}
              target="_blank"
              rel="noopener noreferrer">
              <div className={styles.icon}>
                <DocsIcon size={22} />
              </div>
              <div>
                <div className={styles.title}>{t('app.header.help.docs')}</div>
                <div className={styles.desc}>
                  {t('app.header.help.docsDesc')}
                </div>
              </div>
            </a>
          </li>
        </ul>
      }>
      <IconButton title={t('app.header.help.title')}>
        <HelpIcon color={vars.textPrimary} size={20} />
      </IconButton>
    </Dropdown>
  );
}
