export enum NotificationItemType {
  INFO = 'info',
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum NotificationItemName {
  StaticVerify = 'verify',
  StaticTrial = 'trial',
  AccountVerified = 'verified',
  InvoiceCreated = 'invoiceCreated',
  InvoicePaymentFailed = 'invoiceFailed',
  InvoicePaymentSuccessful = 'invoiceSuccess',
  SubscriptionAtLimit = 'subscriptionAtLimit',
  SubscriptionAutoUpgradeAttempt = 'subscriptionAutoUpgradeAttempt',
  SubscriptionOverLimit = 'subscriptionOverLimit',
  LowSpace = 'lowSpace',
  NoSpace = 'noSpace',
  UserInviteComplete = 'userInviteComplete',
  Unknown = 'unknown',
}

export function getNotificationName(rawType: string): NotificationItemName {
  const prefix = 'App\\Notifications\\';
  const sufix = rawType.replace(prefix, '');

  return NotificationItemName[sufix] || NotificationItemName.Unknown;
}

export const NotificationNameToType: ReadonlyMap<
  NotificationItemName,
  NotificationItemType
> = new Map([
  [NotificationItemName.StaticVerify, NotificationItemType.WARNING],
  [NotificationItemName.StaticTrial, NotificationItemType.INFO],
  [NotificationItemName.AccountVerified, NotificationItemType.SUCCESS],
  [NotificationItemName.InvoiceCreated, NotificationItemType.INFO],
  [NotificationItemName.InvoicePaymentFailed, NotificationItemType.DANGER],
  [NotificationItemName.InvoicePaymentSuccessful, NotificationItemType.SUCCESS],
  [NotificationItemName.SubscriptionAtLimit, NotificationItemType.WARNING],
  [
    NotificationItemName.SubscriptionAutoUpgradeAttempt,
    NotificationItemType.INFO,
  ],
  [NotificationItemName.SubscriptionOverLimit, NotificationItemType.DANGER],
  [NotificationItemName.LowSpace, NotificationItemType.INFO],
  [NotificationItemName.NoSpace, NotificationItemType.DANGER],
  [NotificationItemName.UserInviteComplete, NotificationItemType.INFO],
  [NotificationItemName.Unknown, NotificationItemType.WARNING],
]);
