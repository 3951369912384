/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  DefectRunResult,
  defectRunResultDetach,
  defectRunResultUpdateOne,
  defectRunResultCreateOne,
  defectRunResultGetMany,
  defectRunResultGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const defectRunResultDetachThunk = createAsyncThunk(
  'defectRunResult/DetachThunk',
  async (arg: QueryParams<DefectRunResult>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'defectRunResult/DetachThunk',
      );
    }
    return defectRunResultDetach(arg.data, arg).then(() => arg.data);
  },
);

export const defectRunResultUpdateOneThunk = createAsyncThunk(
  'defectRunResult/UpdateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<DefectRunResult>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'defectRunResult/UpdateOneThunk',
        );
      }
      const data = await defectRunResultUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<DefectRunResult>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectRunResultCreateOneThunk = createAsyncThunk(
  'defectRunResult/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<DefectRunResult>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'defectRunResult/CreateOneThunk',
        );
      }
      return await defectRunResultCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectRunResultFetchManyThunk = createAsyncThunk(
  'defectRunResult/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectRunResult>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await defectRunResultGetMany(inlineArgs);
      return {
        defectRunResult: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectRunResultFetchOneThunk = createAsyncThunk(
  'defectRunResult/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectRunResult>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'defectRunResult/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await defectRunResultGetOne(arg.id as number, arg);
      return {
        defectRunResult: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
