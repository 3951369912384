/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectRunResultAdapter } from './defectRunResultAdapter';

export const defectRunResultSelectors = defectRunResultAdapter.getSelectors(
  (state: State) => state.gen.defectRunResult,
);
