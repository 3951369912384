import { Client } from '@testquality/sdk';
import { clientSelectors } from 'src/gen/domain/client/clientSelector';
import { State } from '@bitmodern/redux/store';
import { tokenSelector } from '../authentication/selectors';

export function currentClientSelector(state: State): Client | undefined {
  const token = tokenSelector(state);
  if (!token?.client_id) return undefined;
  return clientSelectors.selectById(state, token.client_id);
}
