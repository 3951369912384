import React, { ReactNode } from 'react';
import { Button } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import styles from './StepActions.module.scss';

export type StepActionsProps = {
  action?: ReactNode;
  disabled?: boolean;
  hasSkip?: boolean;
  loading?: boolean;
  onClickAction: () => void;
  onSkip: () => void;
};

export function StepActions({
  action,
  disabled = false,
  loading = false,
  hasSkip = true,
  onClickAction,
  onSkip,
}: StepActionsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.actions}>
      <Button disabled={disabled} loading={loading} onClick={onClickAction}>
        {action || t('getStarted.continueButton')}
      </Button>
      {hasSkip && (
        <Button color="primaryLight" onClick={onSkip}>
          {t('getStarted.skipButton')}
        </Button>
      )}
    </div>
  );
}
