/* eslint-disable no-empty */
const STORAGE_KEY = 'resizing';

export function getSize(key: string): number {
  let sizes = {};
  try {
    sizes = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
  } catch (err) {}

  return parseInt(sizes[key] || '0', 10);
}

export function setSize(key: string, size: number) {
  let sizes = {};
  try {
    sizes = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
  } catch (err) {}

  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...sizes,
      [key]: size,
    }),
  );
}
