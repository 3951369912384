import React, { ComponentProps, ReactNode, useEffect, useMemo } from 'react';
import Yup from 'src/utils/yup';
import { useFormik } from 'formik';
import { Input, Dialog, Button, TextArea, Select } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusTypesSelector } from '@bitmodern/redux/state/statusTypes/selectors';
import styles from './CustomValueDialog.module.scss';

type InitialValues = {
  name: string;
  description: string;
  statusType?: number;
};

const defaultInitialValues = {
  name: '',
  description: '',
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  initialValues?: {
    name: string;
    description: string;
    statusType: number;
  };
  onSubmit: (initialValues: InitialValues) => Promise<any>;
  title: ReactNode;
  withDescription?: boolean;
  withStatusType?: boolean;
};

export default function CustomValueDialog({
  initialValues,
  isOpen,
  onClose,
  onSubmit,
  title,
  withDescription = true,
  withStatusType = false,
}: Props) {
  const { t } = useTranslation();
  const statusTypes = useAppSelector(statusTypesSelector);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required().label('customValueDialog.form.name'),
        ...(withStatusType && {
          statusType: Yup.number()
            .required()
            .label('customValueDialog.form.statusType'),
        }),
      }),
    [withStatusType],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || defaultInitialValues,
    isInitialValid: !!initialValues,
    onSubmit,
    validationSchema,
    validateOnMount: true,
  });

  const statusTypesOptions = statusTypes.map((statusType) => ({
    label: statusType.name,
    value: statusType.id,
  }));

  const onChangeStatusType = (value) => {
    formik.setFieldValue('statusType', value);
  };

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      title={title}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        <Input
          error={formikError(formik, 'name')}
          fullWidth
          label={t('customValueDialog.form.name')}
          name="name"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.name}
        />
        {withStatusType && (
          <Select
            allowClear
            label={t('customValueDialog.form.statusType')}
            onChange={onChangeStatusType}
            name="statusType"
            required
            options={statusTypesOptions}
            placeholder={t('customValueDialog.form.statusTypePlaceholder')}
            value={formik.values.statusType}
          />
        )}
        {withDescription && (
          <TextArea
            error={formikError(formik, 'description')}
            fullWidth
            label={t('customValueDialog.form.description')}
            name="description"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.description}
          />
        )}

        <div className={styles.submit}>
          <Button
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
            type="submit">
            {initialValues
              ? t('customValueDialog.form.edit')
              : t('customValueDialog.form.create')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
