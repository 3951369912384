/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CommentHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { commentHistoryAdapter } from './commentHistoryAdapter';
import { commentHistoryFetchThunk } from './commentHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const commentHistorySlice = createSlice({
  name: 'commentHistory',
  initialState: commentHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    commentHistoryCleared(state) {
      state.loading = 'idle';
      commentHistoryAdapter.removeAll(state);
    },
    commentHistoryUpsertMany(
      state,
      action: PayloadAction<CommentHistory[] | Record<number, CommentHistory>>,
    ) {
      commentHistoryAdapter.upsertMany(state, action.payload);
    },
    commentHistoryUpsertOne(state, action: PayloadAction<CommentHistory>) {
      commentHistoryAdapter.upsertOne(state, action.payload);
    },
    commentHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CommentHistory>>,
    ) {
      commentHistoryAdapter.updateOne(state, action.payload);
    },
    commentHistoryRemoveOne(state, action: PayloadAction<number>) {
      commentHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(commentHistoryFetchThunk.pending, handlePending);
    builder.addCase(commentHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      commentHistoryAdapter.upsertMany(state, action.payload.commentHistory);
    });
    builder.addCase(commentHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  commentHistoryCleared,
  commentHistoryUpsertMany,
  commentHistoryUpsertOne,
  commentHistoryUpdateOne,
  commentHistoryRemoveOne,
} = commentHistorySlice.actions;

export const commentHistoryReducer = commentHistorySlice.reducer;
