/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { RunResultApi } from '@testquality/sdk';
import { DefectRunResult } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runResultStepNormalizer } from '../run_result_step/runResultStepNormalize';
import { defectNormalizer } from '../defect/defectNormalize';
import { defectRunResultNormalizer } from '../defect_run_result/defectRunResultNormalize';

export const runResultNormalizer = (
  model: RunResultApi,
  cache: NormalizerCache,
) => {
  if (!cache.runResult) {
    cache.runResult = [];
  }
  cache.runResult.push(model);

  if (model.run_result_step && model.run_result_step.length) {
    model.run_result_step.forEach((child) => {
      runResultStepNormalizer(child, cache);
    });
    model.run_result_step = undefined;
  }
  if (model.defect && model.defect.length) {
    model.defect.forEach((child) => {
      defectRunResultNormalizer(child.pivot as DefectRunResult, cache);
      child.pivot = undefined;
      defectNormalizer(child, cache);
    });
    model.defect = undefined;
  }
};
