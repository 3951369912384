import React, {
  ComponentProps,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useEvent from 'react-use-event-hook';
import AutoSizer from 'react-virtualized-auto-sizer';
import SortableTree, {
  map,
  walk,
  TreeItem,
  NodeData,
  defaultGetNodeKey,
} from 'react-sortable-tree';
import { GlobalHotKeys } from 'react-hotkeys';
import { HotkeysEnum } from 'src/enums/HotkeysEnum';
import { getKeyMaps } from 'src/utils/hotkeys';
import { getCheckedNodesCount, getCheckedList } from 'src/utils/tree';
import { RunResultTreeItem } from '@bitmodern/redux/state/runResults/selectors';
import NodeTreeRenderer from './NodeTreeRenderer';
import SelecionBar from '../SelectionBar';
import NodeContentRenderer from './NodeContentRenderer';

type SortableTreeProps = ComponentProps<typeof SortableTree>;

type Checkeds = { [key: string]: boolean };

type Props = Pick<
  SortableTreeProps,
  | 'canDrag'
  | 'canNodeHaveChildren'
  | 'generateNodeProps'
  | 'getNodeKey'
  | 'onMoveNode'
  | 'treeData'
> &
  Pick<ComponentProps<typeof SelecionBar>, 'actions'> & {
    checkeds?: Checkeds;
    id: string;
    onSelectAll?: (items: TreeItem[] | RunResultTreeItem[]) => void;
    onDeselect?: (items: TreeItem[] | RunResultTreeItem[]) => void;
    onCheck?: (checkeds: Checkeds) => void;
    onCheckItems?: (items: TreeItem[]) => void;
    renderNodeType?: {
      [key: string]: (
        nodeProps: ComponentProps<typeof NodeContentRenderer>,
      ) => ReactNode;
    };
    scrollToElement?: string;
  };

function TreeBase({
  actions,
  canDrag,
  canNodeHaveChildren,
  checkeds: checkedsInProps,
  generateNodeProps,
  getNodeKey = defaultGetNodeKey,
  id,
  onCheck,
  onCheckItems,
  onMoveNode,
  renderNodeType,
  treeData,
  onSelectAll: onSelectAllProp,
  onDeselect: onDeselectProp,
  scrollToElement,
}: Props) {
  const [tree, setTree] = useState<TreeItem[]>(treeData);
  const expandeds = useRef<{ [key: string]: boolean }>({});
  const [checkeds, setCheckeds] = useState<Checkeds>({});
  const [scrollIndex, setScrollIndex] = useState();

  const keyExtractorRef = useRef(getNodeKey);
  keyExtractorRef.current = getNodeKey;

  const scrollToNode = useEvent((scrollIndex?: string) => {
    if (!scrollIndex) return;
    walk({
      treeData: tree,
      callback: ({ node, treeIndex }) => {
        if (node.nodeKey === scrollIndex) {
          setScrollIndex(treeIndex);
          // stops walk
          return false;
        }
        return true;
      },
      ignoreCollapsed: false,
      getNodeKey,
    });
  });

  useEffect(() => {
    scrollToNode(scrollToElement);
  }, [scrollToNode, scrollToElement]);

  useEffect(() => {
    // reset on new tree
    setCheckeds({});
    expandeds.current = {};
  }, [id]);

  useEffect(() => {
    if (typeof checkedsInProps !== 'undefined') {
      setCheckeds(checkedsInProps);
    }
  }, [checkedsInProps]);

  const addExpanded = useCallback((node) => {
    if (typeof expandeds.current[node.nodeKey] !== 'undefined') {
      node.expanded = expandeds.current[node.nodeKey];
    } else {
      node.expanded = true;
    }
    return node;
  }, []);

  const extendsTree = useCallback(
    (newTree) => {
      setCheckeds((sChecked) => {
        const newCheckeds = {};
        const newExpandeds = {};

        const nextTree = map({
          treeData: newTree,
          callback: ({ node }) => {
            if (sChecked[node.nodeKey]) {
              newCheckeds[node.nodeKey] = true;
            }

            addExpanded(node);
            newExpandeds[node.nodeKey] = node.expanded;

            return node;
          },
          ignoreCollapsed: false,
          getNodeKey,
        });
        expandeds.current = newExpandeds;
        setTree(nextTree);
        return newCheckeds;
      });
    },
    [getNodeKey, addExpanded],
  );

  useEffect(() => {
    extendsTree(treeData);
  }, [extendsTree, treeData]);

  const onChangeCheck = useCallback(
    (value, nodeItem: NodeData) => {
      setCheckeds((sChecked) => {
        const newCheckeds = {};
        walk({
          treeData: [nodeItem.node],
          callback: ({ node }) => {
            newCheckeds[node.nodeKey] = value;
          },
          ignoreCollapsed: false,
          getNodeKey,
        });
        const nextCheckeds = { ...sChecked, ...newCheckeds };
        if (onCheck) {
          onCheck(nextCheckeds);
        }
        if (onCheckItems) {
          const list = getCheckedList(tree, nextCheckeds);
          onCheckItems(list);
        }

        return nextCheckeds;
      });
    },
    [getNodeKey, onCheckItems, onCheck, tree],
  );

  const onVisibilityToggle = useCallback(({ node, expanded }) => {
    expandeds.current = {
      ...expandeds.current,
      [node.nodeKey]: expanded,
    };
  }, []);

  const handleGenerateNodeProps = useCallback<
    Exclude<SortableTreeProps['generateNodeProps'], undefined>
  >(
    (data) => {
      // @ts-expect-error
      data.onCheckParentChange = onChangeCheck;
      // @ts-expect-error
      data.renderNodeType = renderNodeType;
      data.node.checked = checkeds[data.node.nodeKey] || false;
      if (generateNodeProps) return generateNodeProps(data);
      return data;
    },
    [checkeds, generateNodeProps, onChangeCheck, renderNodeType],
  );

  const count = useMemo(() => getCheckedNodesCount(checkeds), [checkeds]);

  const onDeselect = useCallback(
    (event?) => {
      if (event.preventDefault) {
        event.preventDefault();
      }
      setCheckeds({});
      // force tree re-render
      setTree([...tree]);
      if (typeof onDeselectProp === 'function') {
        onDeselectProp(getCheckedList(tree, {}));
      }
    },
    [tree, onDeselectProp],
  );

  const onSelectAll = useCallback(
    (event) => {
      event.preventDefault();
      const nextCheckeds = {};
      walk({
        treeData: tree,
        callback: ({ node }) => {
          nextCheckeds[node.nodeKey] = true;
          // return node;
        },
        ignoreCollapsed: false,
        getNodeKey,
      });

      setCheckeds(nextCheckeds);
      // force tree re-render
      setTree([...tree]);
      if (typeof onSelectAllProp === 'function') {
        onSelectAllProp(getCheckedList(tree, nextCheckeds));
      }
    },
    [getNodeKey, tree, onSelectAllProp],
  );

  const setTreeNodeExpanded = ({
    treeToChange,
    expanded,
  }: {
    treeToChange: TreeItem[];
    expanded: boolean;
  }) => {
    return map({
      treeData: treeToChange,
      callback: ({ node }) => {
        expandeds.current[node.nodeKey] = expanded;
        return { ...node, expanded };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false,
    });
  };

  const onCollapse = (event) => {
    event.preventDefault();
    setTree(
      setTreeNodeExpanded({
        treeToChange: tree,
        expanded: false,
      }),
    );
  };

  const onExpand = (event) => {
    event.preventDefault();
    setTree(
      setTreeNodeExpanded({
        treeToChange: tree,
        expanded: true,
      }),
    );
  };

  return (
    <GlobalHotKeys
      allowChanges
      handlers={{
        [HotkeysEnum.DeselectAllTreeItems]: onDeselect,
        [HotkeysEnum.SelectAllTreeItems]: onSelectAll,
        [HotkeysEnum.CollapseAllTreeItems]: onCollapse,
        [HotkeysEnum.ExpandAllTreeItems]: onExpand,
      }}
      keyMap={getKeyMaps(
        HotkeysEnum.DeselectAllTreeItems,
        HotkeysEnum.SelectAllTreeItems,
        HotkeysEnum.CollapseAllTreeItems,
        HotkeysEnum.ExpandAllTreeItems,
      )}>
      <AutoSizer>
        {({ width, height }) => (
          <>
            <SortableTree
              canNodeHaveChildren={canNodeHaveChildren}
              canDrag={canDrag}
              isVirtualized
              getNodeKey={getNodeKey}
              theme={{
                nodeContentRenderer: NodeContentRenderer as any,
                rowHeight: 8 * 6,
                scaffoldBlockPxWidth: 24,
                treeNodeRenderer: NodeTreeRenderer,
              }}
              generateNodeProps={handleGenerateNodeProps}
              onVisibilityToggle={onVisibilityToggle}
              onChange={setTree}
              onMoveNode={onMoveNode}
              reactVirtualizedListProps={{
                overscanRowCount: 20,
                scrollToIndex: scrollIndex,
                scrollToAlignment: 'auto',
              }}
              style={{ width, height }}
              treeData={tree}
            />
            <SelecionBar actions={actions} count={count} onClose={onDeselect} />
          </>
        )}
      </AutoSizer>
    </GlobalHotKeys>
  );
}

export default memo(TreeBase);
