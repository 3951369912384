/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { LabelAssigned } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { labelAssignedAdapter } from './labelAssignedAdapter';
import {
  labelAssignedFetchManyThunk,
  labelAssignedFetchOneThunk,
  labelAssignedDeleteOneThunk,
  labelAssignedUpdateOneThunk,
  labelAssignedCreateOneThunk,
} from './labelAssignedThunk';
import { labelFetchManyThunk, labelFetchOneThunk } from '../label/labelThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const labelAssignedSlice = createSlice({
  name: 'labelAssigned',
  initialState: labelAssignedAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    labelAssignedCleared(state) {
      state.loading = 'idle';
      labelAssignedAdapter.removeAll(state);
    },
    labelAssignedUpsertMany(
      state,
      action: PayloadAction<LabelAssigned[] | Record<number, LabelAssigned>>,
    ) {
      labelAssignedAdapter.upsertMany(state, action.payload);
    },
    labelAssignedUpsertOne(state, action: PayloadAction<LabelAssigned>) {
      labelAssignedAdapter.upsertOne(state, action.payload);
    },
    labelAssignedUpdateOne(
      state,
      action: PayloadAction<Update<LabelAssigned>>,
    ) {
      labelAssignedAdapter.updateOne(state, action.payload);
    },
    labelAssignedRemoveOne(state, action: PayloadAction<number>) {
      labelAssignedAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(labelAssignedFetchManyThunk.pending, handlePending);
    builder.addCase(labelAssignedFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAssignedAdapter.upsertMany(state, action.payload.labelAssigned);
    });
    builder.addCase(labelAssignedFetchManyThunk.rejected, handleRejected);
    builder.addCase(labelAssignedFetchOneThunk.pending, handlePending);
    builder.addCase(labelAssignedFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAssignedAdapter.upsertOne(state, action.payload.labelAssigned[0]);
    });
    builder.addCase(labelAssignedFetchOneThunk.rejected, handleRejected);
    builder.addCase(labelAssignedDeleteOneThunk.pending, handlePending);
    builder.addCase(labelAssignedDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        labelAssignedAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(labelAssignedDeleteOneThunk.rejected, handleRejected);
    builder.addCase(labelAssignedUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, labelAssignedAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(labelAssignedUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAssignedAdapter.updateOne(state, action.payload);
    });
    builder.addCase(labelAssignedUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          labelAssignedAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(labelAssignedCreateOneThunk.pending, handlePending);
    builder.addCase(labelAssignedCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      labelAssignedAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(labelAssignedCreateOneThunk.rejected, handleRejected);
    builder.addCase(labelFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.labelAssigned) {
        labelAssignedAdapter.upsertMany(state, action.payload.labelAssigned);
      }
    });
    builder.addCase(labelFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.labelAssigned) {
        labelAssignedAdapter.upsertMany(state, action.payload.labelAssigned);
      }
    });
  },
});

export const {
  labelAssignedCleared,
  labelAssignedUpsertMany,
  labelAssignedUpsertOne,
  labelAssignedUpdateOne,
  labelAssignedRemoveOne,
} = labelAssignedSlice.actions;

export const labelAssignedReducer = labelAssignedSlice.reducer;
