/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlatVersionApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { appVersionPlatVersionNormalizer } from '../app_version_plat_version/appVersionPlatVersionNormalize';

export const platVersionNormalizer = (
  model: PlatVersionApi,
  cache: NormalizerCache,
) => {
  if (!cache.platVersion) {
    cache.platVersion = [];
  }
  cache.platVersion.push(model);

  if (model.app_version_plat_version && model.app_version_plat_version.length) {
    model.app_version_plat_version.forEach((child) => {
      appVersionPlatVersionNormalizer(child, cache);
    });
    model.app_version_plat_version = undefined;
  }
};
