/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AppConfigApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { appInstallNormalizer } from '../app_install/appInstallNormalize';

export const appConfigNormalizer = (
  model: AppConfigApi,
  cache: NormalizerCache,
) => {
  if (!cache.appConfig) {
    cache.appConfig = [];
  }
  cache.appConfig.push(model);

  if (model.app_install && model.app_install.length) {
    model.app_install.forEach((child) => {
      appInstallNormalizer(child, cache);
    });
    model.app_install = undefined;
  }
};
