/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { filterAdapter } from './filterAdapter';

export const filterSelectors = filterAdapter.getSelectors(
  (state: State) => state.gen.filter,
);
