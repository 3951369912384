import React, { ReactNode } from 'react';
import styles from './CommandBar.module.scss';
import Separator from '../Separator';
import { CommandGroup } from './CommandBar';

type Props = {
  children: ReactNode;
  group: CommandGroup;
  withSeparator?: boolean;
};

export const CommandBarGroup = ({ children, group, withSeparator }: Props) => {
  return (
    <>
      <div className={styles.groupTitle}>{group.label}</div>
      <ul>{children}</ul>
      {withSeparator && (
        <Separator
          className={styles.separator}
          color={'primaryMain'}
          size={'minimal'}
        />
      )}
    </>
  );
};
