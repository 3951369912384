import React, { ComponentProps, useEffect, useState } from 'react';
import classnames from 'classname';
import { mergeProps } from 'react-aria';
import { useFocusAccent } from '@bitmodern/bit-ui';
import EasyMDEEditor from '../EasyMDE';
import MarkdownView from '../MarkdownView';
import { useEditingKeyboard } from './useEditingKeyboard';
import { useEditing } from './useEditing';
import styles from './EasyMEDInlineEdit.module.scss';

type Props = ComponentProps<typeof EasyMDEEditor> & {
  initialOpen?: boolean;
  preview?: string;
};

export default function EasyMEDInlineEdit({
  id,
  initialOpen = false,
  onCommit,
  preview,
  value,
  ...rest
}: Props) {
  const [valueInline, setValueInline] = useState(value);
  const { borderClassName, borderProps } = useFocusAccent();
  const inlineEdit = useEditing({
    onCommit: () => {
      if (value !== valueInline && onCommit) {
        return onCommit(valueInline);
      }
      return Promise.resolve();
    },
    onCancel: () => setValueInline(value),
    id,
  });

  const { keyboardProps } = useEditingKeyboard({
    commitOnEnter: false,
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });

  useEffect(() => {
    setValueInline(value);
  }, [value]);

  useEffect(() => {
    if (initialOpen) {
      inlineEdit.open();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    inlineEdit.cancel();
    setValueInline(value);
  };

  const inlineCN = classnames(styles.inline, {
    [borderClassName]: !inlineEdit.isEditing,
  });

  return (
    <div
      className={inlineCN}
      onClick={inlineEdit.open}
      {...mergeProps(borderProps, keyboardProps)}>
      {inlineEdit.isEditing ? (
        <EasyMDEEditor
          autofocus
          id={id}
          onCancel={handleCancel}
          onChange={setValueInline}
          onCommit={inlineEdit.commit}
          value={value}
          {...rest}
        />
      ) : (
        <MarkdownView markdown={preview || value || ''} />
      )}
    </div>
  );
}
