import { State } from '@bitmodern/redux/store';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { IntegrationUser } from '@testquality/sdk';
import { currentUserSelector } from '../authentication/selectors';
import { integrationsByBaseSelector } from '../integration/selectors';
import { integrationUserSelectors } from 'src/gen/domain/integration_user/integrationUserSelector';

export function integrationUsersSelectors(state: State) {
  return integrationUserSelectors.selectAll(state);
}

export const integrationUserSelector = (
  state: State,
  integrationUserId: number,
) => integrationUserSelectors.selectById(state, integrationUserId);

export const integrationUserByIntegrationSelector = createSelector(
  [
    (state: State) => state.gen.integrationUser.entities,
    (state: State, { integrationId }: { integrationId?: number }) =>
      integrationId,
  ],
  (integrationUsers, integrationId) =>
    (filter(integrationUsers, (iu) => iu?.integration_id === integrationId) ||
      []) as IntegrationUser[],
);

export const integrationUserByUserIdSelector = createSelector(
  [
    (state: State) => state.gen.integrationUser.entities,
    (state: State, { userId }: { userId?: number }) => userId,
  ],
  (integrationUsers, userId) =>
    (filter(integrationUsers, (iu) => iu?.user_id === userId) ||
      []) as IntegrationUser[],
);

export const integrationUsersByCurrentUserSelector = createSelector(
  [
    currentUserSelector,
    (state: State) => state.gen.integrationUser.entities,
    integrationsByBaseSelector,
  ],
  (currentUser, integrationUsers, integrations) => {
    if (!currentUser) return undefined;
    if (!integrations) return undefined;

    return find(
      integrationUsers,
      (integrationUser) =>
        integrationUser?.user_id === currentUser.id &&
        integrations.some(
          (integration) => integrationUser?.integration_id === integration?.id,
        ),
    );
  },
);

export const integrationUserByIntegrationAndUserSelector = createSelector(
  [
    (state: State) => state.gen.integrationUser.entities,
    (state: State, params: { integrationId?: number; userId?: number }) =>
      params,
  ],
  (integrationUsers, { integrationId, userId }) =>
    filter(
      integrationUsers,
      (integrationUser?: IntegrationUser) =>
        integrationUser?.integration_id === integrationId &&
        integrationUser?.user_id === userId,
    ) as IntegrationUser[],
);
