import React, { ComponentProps, useMemo } from 'react';
import Yup from 'src/utils/yup';
import { useId } from 'react-aria';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import styles from './DataSetColumnDialog.module.scss';

type Props = Required<
  Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'>
> & {
  columnName?: string;
  onSubmit: (newName: string) => Promise<any>;
};

export default function DataSetColumnDialog({
  columnName = '',
  onSubmit,
  isOpen,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const id = useId();

  const initialValues = useMemo(() => ({ columnName }), [columnName]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        columnName: Yup.string()
          .required()
          .label(t('dataSetColumnDialog.columnName')),
      }),
    [t],
  );

  const handleSubmit = async ({ columnName: name }: typeof initialValues) => {
    await onSubmit(name);
    formik.resetForm();
    onClose();
  };

  const formik = useFormik({
    validationSchema,
    isInitialValid: true,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const isEditing = Boolean(columnName);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      size="small"
      title={
        isEditing
          ? t('dataSet.columnDialog.editColumn')
          : t('dataSet.columnDialog.createColumn')
      }>
      <DialogContent className={styles.content}>
        <form
          id={id}
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}>
          <Input
            autoFocus
            error={formikError(formik, 'columnName')}
            fullWidth
            label={t('dataSet.columnDialog.columnName')}
            name="columnName"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.columnName}
          />
        </form>
      </DialogContent>
      <PanelActions>
        <Button form={id} loading={formik.isSubmitting} type="submit">
          {isEditing
            ? t('dataSet.columnDialog.save')
            : t('dataSet.columnDialog.create')}
        </Button>
      </PanelActions>
    </Dialog>
  );
}
