import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Tab.module.scss';

type TabProps<ID> = {
  badge?: boolean;
  children: ReactNode;
  className?: string;
  id: ID;
  onClick?: () => void;
  selected?: boolean;
  size?: string;
  withBarBackground?: boolean;
};

export default function Tab<ID = string>({
  badge,
  children,
  className = '',
  id,
  onClick,
  selected,
  size,
  withBarBackground,
}: TabProps<ID>) {
  const tabCN = classnames(styles.tab, className, {
    [styles.barBackground]: withBarBackground,
    [styles.medium]: size === 'medium',
    [styles.selected]: selected,
    [styles.badge]: badge,
  });

  return (
    <div className={tabCN} id={String(id)} onClick={onClick}>
      {children}
    </div>
  );
}
