/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { purposeAdapter } from './purposeAdapter';

export const purposeSelectors = purposeAdapter.getSelectors(
  (state: State) => state.gen.purpose,
);
