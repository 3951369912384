import {
  AssociationEntity,
  AssociationType,
  AttributeDataType,
} from '../../../../../../enums/MappingItemsType';

export type EntityAttribute = {
  entity: string;
  id_display: string;
  type: AttributeDataType;
  display_name: string;
  domain: string;
  name: string;
  association_type?: AssociationType;
  association_entity?: AssociationEntity;
};
export const matchMapping = {
  // [`${item.entity}-${item.name}`]: ['Project name'],
  'project.name': ['project name', 'project_name'],
  'project.key': ['project key', 'project_key'],
  'project.created_by': ['project created by', 'project_created_by'],
  'project.created_at': ['project created at', 'project_created_at'],
  'project.updated_by': ['project updated by', 'project_updated_by'],
  'project.updated_at': ['project updated at', 'project_updated_at'],
  'project.description': ['project description', 'project_description'],
  'project.access_role_id': ['project access role', 'project_access_role'],

  'suite.name': ['Test Suite Name', 'Suite name', 'folder name', 'folder_name'],
  'suite.key': ['Test Suite Key', 'Suite Key', 'Folder Key', 'folder_key'],
  'suite.created_by': [
    'Test Suite Created By',
    'suite created by',
    'folder created by',
    'folder_created_by',
  ],
  'suite.created_at': [
    'Test Suite Created At',
    'suite created at',
    'Folder created at',
    'Folder_created_at',
  ],
  'suite.updated_by': [
    'Test Suite Updated By',
    'suite updated by',
    'Folder updated by',
    'Folder_updated_by',
  ],
  'suite.updated_at': [
    'Test Suite Updated At',
    'suite updated at',
    'Folder updated at',
    'Folder_updated_at',
  ],
  'suite.description': [
    'Test Suite Description',
    'suite description',
    'Folder description',
    'Folder_description',
  ],
  'suite.assigned_to_tester': [
    'Test Suite Assigned To',
    'suite assigned to',
    'Folder assigned to',
    'Folder_assigned_to',
  ],
  'suite.parent_suite': [
    'Folder Parent',
    'Test Suite Parent',
    'suite parent',
    'parent suite',
    'parent folder',
    'suite_parent',
    'parent_suite',
  ],

  'plan.name': ['Test Plan Name', 'Plan name', 'Cycle Name', 'cycle_Name'],
  'plan.key': ['Test Plan Key', 'Plan Key', 'cycle Key', 'cycle_Key'],
  'plan.created_by': [
    'Test Plan Created By',
    'plan created by',
    'cycle created by',
    'cycle_created_by',
  ],
  'plan.created_at': [
    'Test Plan Created At',
    'plan created at',
    'cycle created at',
    'cycle_created_at',
  ],
  'plan.updated_by': [
    'Test Plan Updated By',
    'plan updated by',
    'cycle updated by',
    'cycle_updated_by',
  ],
  'plan.updated_at': [
    'Test Plan Updated At',
    'plan updated at',
    'cycle updated at',
    'cycle_updated_at',
  ],
  'plan.description': [
    'Test Plan Description',
    'plan description',
    'cycle description',
    'cycle_description',
  ],
  'plan.assigned_to_tester': [
    'Test Plan Assigned To',
    'plan assigned to',
    'cycle assigned to',
    'cycle_assigned to',
  ],
  'plan.Team': ['Test Plan Team', 'plan team', 'cycle team', 'cycle_team'],
  'test.name': ['Test Case Name', 'Test name', 'Test_name'],
  'test.key': ['Test Case Key', 'Test Key', 'Test_Key'],
  'test.case_type_id': [
    'Test Case Type',
    'Test Case Case Type',
    'Test Type',
    'Test_Type',
  ],
  'test.created_by': [
    'Test Case Created By',
    'test created by',
    'test_created_by',
  ],
  'test.created_at': [
    'Test Case Created At',
    'test created at',
    'test_created_at',
  ],
  'test.updated_by': [
    'Test Case Updated By',
    'test updated by',
    'test_updated_by',
  ],
  'test.updated_at': [
    'Test Case Updated At',
    'test updated at',
    'test_updated_at',
  ],
  'test.description': [
    'Test Case Description',
    'test description',
    'test_description',
  ],
  'test.assigned_to_tester': [
    'Test Case Assigned To',
    'test assigned to',
    'test_assigned_to',
  ],
  'test.case_priority_id': [
    'test priority',
    'test case priority',
    'test case case priority',
    'test_priority',
  ],
  'test.estimate': ['test estimate', 'test case estimate', 'test_estimate'],
  'test.precondition': [
    'test precondition',
    'test case precondition',
    'test_precondition',
  ],
  'test.is_automated': [
    'test is automated',
    'test case is automated',
    'test_is_automated',
  ],
  'test.requirement_reference_id': [
    'test requirements',
    'test case requirements',
    'test_requirements',
  ],
  'test.notes': ['test notes', 'test_notes', 'test case notes'],
  'test.Custom_Date': [
    'Test Case Custom date',
    'test custom date',
    'test_custom_date',
  ],
  'test.attachment': [
    'Test Case attachments',
    'test attachments',
    'test_attachments',
  ],

  'step.name': ['step name', 'step_name'],
  'step.key': ['step Key', 'step_key'],
  'step.type': ['step Type', 'step_type'],
  'step.created_by': ['step created by', 'step_created_by'],
  'step.created_at': ['step created at', 'step_created_at'],
  'step.updated_by': ['step updated by', 'step_updated_by'],
  'step.updated_at': ['step updated at', 'step_updated_at'],
  'step.step': ['step description', 'step_description'],
  'step.sequence': ['step sequence', 'step_sequence'],
  'step.expected_result': ['step expected result', 'step_expected_result'],
  'step.result': ['step result', 'step_result'],

  'run.name': ['run name', 'run_name'],
  'run.key': ['run Key', 'run_key'],
  'run.created_by': ['run created by', 'run_created_by'],
  'run.created_at': ['run created at', 'run_created_at'],
  'run.updated_by': ['run updated by', 'run_updated_by'],
  'run.updated_at': ['run updated at', 'run_updated_at'],
  'run.start_time': ['run start time', 'run_start_time'],
  'run.end_time': ['run end time', 'run_end_time'],
  'run.is_complete': ['run complete', 'run_complete'],
  'run.is_running': ['run running', 'run_running'],
  'run.is_permanet': ['run save permanently', 'run_save_permanently'],
  'run.plan_id': ['Run Cycle', 'Run_Cycle', 'Run Test plan'],

  'run_result_step.status_id': [
    'Step Result Status',
    'Step_Result_Status',
    'run_result_step_status',
  ],
  'run_result_step.elapsed': ['Step Result Elapsed', 'Step_Result_Elapsed'],
  'run_result_step.result': [
    'Step Result Reason for Status',
    'Step_Result_Reason _for_Status',
  ],

  'run_result.status_id': [
    'Test Case Result Status',
    'test_result_status',
    'Test Result Status',
    'run_result_status',
  ],
  'run_result.assigned_to_tester': [
    'Test Case Result Assigned To',
    'Test_Result_Assigned_To',
    'Test Result Assigned To',
  ],

  'run_result.updated_at': [
    'Test Case Result Updated At',
    'Test Result Updated At',
    'Test_Result_Updated_At',
  ],
  'run_result.updated_by': [
    'Test Case Result Updated By',
    'Test_Result_Updated_By',
    'Test Result Updated By',
  ],
  'run_result.created_at': [
    'Test Case Result Created At',
    'Test Result Created At',
    'Test_Result_Updated_At',
  ],
  'run_result.created_by': [
    'Test Case Result Created By',
    'Test Result Created By',
    'Test_Result_Updated_By',
  ],
  'data_set.json': ['data_set', 'data set', 'DataSet'],
};

export const mappingSelectOptions: EntityAttribute[] = [
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.number,
    display_name: 'Project Key',
    domain: 'pk',
    name: 'key',
  },
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.string,
    display_name: 'Project Name',
    domain: 'name',
    name: 'name',
  },

  // {
  //   entity: 'project',
  //   id_display: 'P',
  //   type: AttributeDataType.number,
  //   display_name: 'Project Priority',
  //   domain: 'pk',
  //   name: 'key',
  // },
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.number,
    display_name: 'Project Created By',
    domain: 'created by',
    name: 'created_by',
  },
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.Date,
    display_name: 'Project Created At',
    domain: 'created at',
    name: 'created_at',
  },

  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.number,
    display_name: 'Project Updated By',
    domain: 'updated by',
    name: 'updated_by',
  },
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.Date,
    display_name: 'Project Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },
  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.string,
    display_name: 'Project Description',
    domain: 'description',
    name: 'description',
  },

  {
    entity: 'project',
    id_display: 'P',
    type: AttributeDataType.number,
    display_name: 'Project Access Role',
    domain: 'pk',
    name: 'access_role_id',
  },

  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.number,
    display_name: 'Folder Key',
    domain: 'pk',
    name: 'key',
  },

  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.string,
    display_name: 'Folder Name',
    domain: 'name',
    name: 'name',
  },
  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.number,
    display_name: 'Folder Created By',
    domain: 'created by',
    name: 'created_by',
  },

  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.Date,
    display_name: 'Folder Created At',
    domain: 'created at',
    name: 'created_at',
  },

  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.number,
    display_name: 'Folder Updated By',
    domain: 'updated by',
    name: 'updated_by',
  },
  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.Date,
    display_name: 'Folder Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },
  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.string,
    display_name: 'Folder Description',
    domain: 'description',
    name: 'description',
  },
  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.number,
    display_name: 'Folder Assigned To',
    domain: 'assigned to',
    name: 'assigned_to_tester',
  },

  {
    entity: 'suite',
    id_display: 'TS',
    type: AttributeDataType.string,
    display_name: 'Folder Parent',
    domain: 'name',
    name: 'parent_suite',
  },
  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.number,
    display_name: 'Cycle Key',
    domain: 'pk',
    name: 'key',
  },

  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.string,
    display_name: 'Cycle Name',
    domain: 'name',
    name: 'name',
  },
  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.number,
    display_name: 'Cycle Created By',
    domain: 'created by',
    name: 'created_by',
  },

  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.Date,
    display_name: 'Cycle Created At',
    domain: 'created_at',
    name: 'created_at',
  },
  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.number,
    display_name: 'Cycle Updated By',
    domain: 'update_by',
    name: 'update_by',
  },

  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.Date,
    display_name: 'Cycle Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },

  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.string,
    display_name: 'Cycle Description',
    domain: 'description',
    name: 'description',
  },
  {
    entity: 'plan',
    id_display: 'TP',
    type: AttributeDataType.number,
    display_name: 'Cycle Assigned To',
    domain: 'assigned to',
    name: 'assigned_to_tester',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Key',
    domain: 'pk',
    name: 'key',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.string,
    display_name: 'Test Case Name',
    domain: 'name',
    name: 'name',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Type',
    domain: 'pk',
    name: 'case_type_id',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.caseType,
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Created By',
    domain: 'created by',
    name: 'created_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.Date,
    display_name: 'Test Case Created At',
    domain: 'created at',
    name: 'created_at',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Updated By',
    domain: 'updated by',
    name: 'updated_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.Date,
    display_name: 'Test Case Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.string,
    display_name: 'Test Case Description',
    domain: 'description',
    name: 'description',
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Assigned To',
    domain: 'assigned to',
    name: 'assigned_to_tester',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Priority',
    domain: 'pk',
    name: 'case_priority_id',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.casePriority,
  },

  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.number,
    display_name: 'Test Case Estimate',
    domain: 'estimate',
    name: 'estimate',
  },
  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.string,
    display_name: 'Test Case Precondition',
    domain: 'long entry field',
    name: 'precondition',
  },
  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.boolean,
    display_name: 'Test Case Is Automated',
    domain: 'is automated',
    name: 'is_automated',
  },
  {
    entity: 'test',
    id_display: 'TC',
    type: AttributeDataType.string,
    display_name: 'Test Case Requirements',
    domain: 'external reference id',
    name: 'requirement_reference_id',
  },

  {
    entity: 'test',
    id_display: 'A',
    type: AttributeDataType.string,
    display_name: 'Test Case Attachments',
    domain: 'attachment',
    name: 'attachment',
    association_type: AssociationType.child,
    association_entity: AssociationEntity.attachment,
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.number,
    display_name: 'Step Key',
    domain: 'pk',
    name: 'key',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.number,
    display_name: 'Step Created By',
    domain: 'created by',
    name: 'created_by',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.Date,
    display_name: 'Step Created At',
    domain: 'created at',
    name: 'created_at',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.number,
    display_name: 'Step Updated By',
    domain: 'updated by',
    name: 'updated_by',
  },

  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.Date,
    display_name: 'Step Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.string,
    display_name: 'Step Description',
    domain: 'long entry field',
    name: 'step',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.number,
    display_name: 'Step Sequence',
    domain: 'sequence',
    name: 'sequence',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.string,
    display_name: 'Step Expected Result',
    domain: 'long entry field',
    name: 'expected_result',
  },
  {
    entity: 'step',
    id_display: 'S',
    type: AttributeDataType.string,
    display_name: 'Step Result',
    domain: 'long entry field',
    name: 'result',
  },

  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.number,
    display_name: 'Run Key',
    domain: 'pk',
    name: 'key',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.number,
    display_name: 'Run Created By',
    domain: 'created by',
    name: 'created_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.Date,
    display_name: 'Run Created At',
    domain: 'created at',
    name: 'created_at',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.number,
    display_name: 'Run Updated By',
    domain: 'updated by',
    name: 'updated_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.Date,
    display_name: 'Run Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.Date,
    display_name: 'Run Start Time',
    domain: 'start time',
    name: 'start_time',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.Date,
    display_name: 'Run End Time',
    domain: 'end time',
    name: 'end_time',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.boolean,
    display_name: 'Run Complete',
    domain: 'is complete',
    name: 'is_complete',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.boolean,
    display_name: 'Run Running',
    domain: 'is running',
    name: 'is_running',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.boolean,
    display_name: 'Run Save Permanently',
    domain: 'is permanent',
    name: 'is_permanent',
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.number,
    display_name: 'Run Cycle',
    domain: 'pk',
    name: 'plan_id',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.plan,
  },
  {
    entity: 'run',
    id_display: 'TPR',
    type: AttributeDataType.string,
    display_name: 'Run Name',
    domain: 'name',
    name: 'name',
  },
  {
    entity: 'run_result_step',
    id_display: 'SR',
    type: AttributeDataType.string,
    display_name: 'Step Result Reason for Status',
    domain: 'long entry field',
    name: 'result',
  },
  {
    entity: 'run_result_step',
    id_display: 'SR',
    type: AttributeDataType.number,
    display_name: 'Step Result Status',
    domain: 'pk',
    name: 'status_id',
  },
  {
    entity: 'run_result_step',
    id_display: 'SR',
    type: AttributeDataType.number,
    display_name: 'Step Result Elapsed',
    domain: 'elapsed',
    name: 'elapsed',
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.number,
    display_name: 'Test Case Result Status',
    domain: 'pk',
    name: 'status_id',
    association_type: AssociationType.many,
    association_entity: AssociationEntity.status,
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.number,
    display_name: 'Test Case Result Assigned To',
    domain: 'assigned to',
    name: 'assigned_to_tester',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.Date,
    display_name: 'Test Case Result Updated At',
    domain: 'updated at',
    name: 'updated_at',
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.number,
    display_name: 'Test Case Result Updated By',
    domain: 'updated by',
    name: 'updated_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.Date,
    display_name: 'Test Case Result Created At',
    domain: 'created at',
    name: 'created_at',
  },
  {
    entity: 'run_result',
    id_display: 'TCR',
    type: AttributeDataType.number,
    display_name: 'Test Case Result Created By',
    domain: 'created by',
    name: 'created_by',
    association_type: AssociationType.select,
    association_entity: AssociationEntity.user,
  },
  {
    entity: 'data_set',
    id_display: 'DS',
    type: AttributeDataType.string,
    display_name: 'Data set',
    domain: '',
    name: 'json',
  },
];
