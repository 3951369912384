import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
} from '@bitmodern/bit-ui';
import { Requirement } from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import {
  failStatusSelector,
  passStatusSelector,
  skipStatusSelector,
} from 'src/packages/redux/state/statuses/selectors';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import EmptyList from '../EmptyList';
import StatusIcon from '../StatusIcon';
import RunsRequirementItem from './components/RunsRequirementsItem';
import styles from './RunsRequirements.module.scss';

type Props = {
  runRequirements: Array<{
    requirement: Requirement;
    testStatus;
  }>;
  width?: string | number;
};

export default function RunsRequirements({ runRequirements, width }: Props) {
  const { t } = useTranslation();
  const { site, projectId } = useParams<typeof routes.REQUIREMENTS.params>();

  const { openCreateRequirement } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const onAddRequirement = () => {
    return openCreateRequirement(0);
  };

  const passStatus = useAppSelector(passStatusSelector);
  const failStatus = useAppSelector(failStatusSelector);
  const skipStatus = useAppSelector(skipStatusSelector);

  const isEmpty = runRequirements.length === 0;

  return (
    <div>
      {isEmpty ? (
        <EmptyList
          className={styles.empty}
          description={t('requirements.empty.desc')}
          title={t('requirements.empty.title')}
          button={
            <Button onClick={onAddRequirement}>
              {t('emptyRequirements.button')}
            </Button>
          }
        />
      ) : (
        <Table rounded outlined>
          <TableHead className={styles.head}>
            <TableCell>{t('requirements.head.key')}</TableCell>
            <TableCell>{t('requirements.head.name')}</TableCell>
            <TableCell>
              {passStatus && (
                <Tooltip enterDelay={100} tooltip={passStatus.name}>
                  <span>
                    <StatusIcon status={passStatus} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
            <TableCell>
              {failStatus && (
                <Tooltip enterDelay={100} tooltip={failStatus.name}>
                  <span>
                    <StatusIcon status={failStatus} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
            <TableCell>
              {skipStatus && (
                <Tooltip enterDelay={100} tooltip={skipStatus.name}>
                  <span>
                    <StatusIcon status={skipStatus} />
                  </span>
                </Tooltip>
              )}
            </TableCell>
            {(!width || (width && width > 700)) && (
              <TableCell>{t('requirements.head.defects')}</TableCell>
            )}
            <TableCell>{t('requirements.head.status')}</TableCell>
          </TableHead>
          <TableBody>
            {runRequirements.map(({ requirement, testStatus }) => (
              <RunsRequirementItem
                key={requirement.id}
                requirement={requirement}
                testStatus={testStatus}
                width={width}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
