/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PlanSuiteTestIncludeHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { planSuiteTestIncludeHistoryAdapter } from './planSuiteTestIncludeHistoryAdapter';
import { planSuiteTestIncludeHistoryFetchThunk } from './planSuiteTestIncludeHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const planSuiteTestIncludeHistorySlice = createSlice({
  name: 'planSuiteTestIncludeHistory',
  initialState:
    planSuiteTestIncludeHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    planSuiteTestIncludeHistoryCleared(state) {
      state.loading = 'idle';
      planSuiteTestIncludeHistoryAdapter.removeAll(state);
    },
    planSuiteTestIncludeHistoryUpsertMany(
      state,
      action: PayloadAction<
        | PlanSuiteTestIncludeHistory[]
        | Record<number, PlanSuiteTestIncludeHistory>
      >,
    ) {
      planSuiteTestIncludeHistoryAdapter.upsertMany(state, action.payload);
    },
    planSuiteTestIncludeHistoryUpsertOne(
      state,
      action: PayloadAction<PlanSuiteTestIncludeHistory>,
    ) {
      planSuiteTestIncludeHistoryAdapter.upsertOne(state, action.payload);
    },
    planSuiteTestIncludeHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PlanSuiteTestIncludeHistory>>,
    ) {
      planSuiteTestIncludeHistoryAdapter.updateOne(state, action.payload);
    },
    planSuiteTestIncludeHistoryRemoveOne(state, action: PayloadAction<number>) {
      planSuiteTestIncludeHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      planSuiteTestIncludeHistoryFetchThunk.pending,
      handlePending,
    );
    builder.addCase(
      planSuiteTestIncludeHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        planSuiteTestIncludeHistoryAdapter.upsertMany(
          state,
          action.payload.planSuiteTestIncludeHistory,
        );
      },
    );
    builder.addCase(
      planSuiteTestIncludeHistoryFetchThunk.rejected,
      handleRejected,
    );
  },
});

export const {
  planSuiteTestIncludeHistoryCleared,
  planSuiteTestIncludeHistoryUpsertMany,
  planSuiteTestIncludeHistoryUpsertOne,
  planSuiteTestIncludeHistoryUpdateOne,
  planSuiteTestIncludeHistoryRemoveOne,
} = planSuiteTestIncludeHistorySlice.actions;

export const planSuiteTestIncludeHistoryReducer =
  planSuiteTestIncludeHistorySlice.reducer;
