/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectStatusHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectStatusHistoryAdapter } from './defectStatusHistoryAdapter';
import { defectStatusHistoryFetchThunk } from './defectStatusHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectStatusHistorySlice = createSlice({
  name: 'defectStatusHistory',
  initialState: defectStatusHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectStatusHistoryCleared(state) {
      state.loading = 'idle';
      defectStatusHistoryAdapter.removeAll(state);
    },
    defectStatusHistoryUpsertMany(
      state,
      action: PayloadAction<
        DefectStatusHistory[] | Record<number, DefectStatusHistory>
      >,
    ) {
      defectStatusHistoryAdapter.upsertMany(state, action.payload);
    },
    defectStatusHistoryUpsertOne(
      state,
      action: PayloadAction<DefectStatusHistory>,
    ) {
      defectStatusHistoryAdapter.upsertOne(state, action.payload);
    },
    defectStatusHistoryUpdateOne(
      state,
      action: PayloadAction<Update<DefectStatusHistory>>,
    ) {
      defectStatusHistoryAdapter.updateOne(state, action.payload);
    },
    defectStatusHistoryRemoveOne(state, action: PayloadAction<number>) {
      defectStatusHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectStatusHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      defectStatusHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectStatusHistoryAdapter.upsertMany(
          state,
          action.payload.defectStatusHistory,
        );
      },
    );
    builder.addCase(defectStatusHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  defectStatusHistoryCleared,
  defectStatusHistoryUpsertMany,
  defectStatusHistoryUpsertOne,
  defectStatusHistoryUpdateOne,
  defectStatusHistoryRemoveOne,
} = defectStatusHistorySlice.actions;

export const defectStatusHistoryReducer = defectStatusHistorySlice.reducer;
