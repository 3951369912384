import React, { ReactNode, useCallback, useState, useRef } from 'react';
import classnames from 'classnames';
import useMutation from 'src/hooks/useMutation';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { useTranslation } from 'src/i18n/hooks';
import notification from '../notification';
import Button from '../Button';
import IconButton from '../IconButton';
import { CopyIcon, DoneIcon } from '../icons';
import Tooltip from '../Tooltip';
import styles from './Clipboard.module.scss';
import { notificationErrorTimeout } from '../../../constants';

type Props = {
  children: ReactNode;
  className?: string;
  text: string;
  type?: 'inline' | 'horizontal';
};

export default function Clipboard({
  children,
  className = '',
  text,
  type = 'inline',
}: Props) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleCopyText = useCallback(() => {
    if (timeout.current) {
      /* NOTE: Clear timeout to avoid turning isCopied to false before time */
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    return navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        timeout.current = setTimeout(() => setIsCopied(false), 2000);
      })
      .catch(() =>
        notification.open({
          type: 'error',
          message: t('clipboard.error'),
          duration: notificationErrorTimeout,
        }),
      );
  }, [text, t]);

  const copyMutation = useMutation(handleCopyText);

  const clipboardCN = classnames(styles.clipboard, className, {
    [styles.horizontal]: type === 'horizontal',
  });

  const Icon = isCopied ? (
    <DoneIcon color={vars.successMain} size={15} />
  ) : (
    <CopyIcon color={vars.textPrimary} size={15} />
  );

  return (
    <div className={clipboardCN}>
      <div className={styles.children}>{children}</div>
      {type === 'inline' && (
        <Tooltip enterDelay={500} tooltip={t('clipboard.tooltip')}>
          <IconButton
            boxed={false}
            className={styles.icon}
            onClick={copyMutation.mutate}>
            {Icon}
          </IconButton>
        </Tooltip>
      )}
      {type === 'horizontal' && (
        <Button
          color="primaryLight"
          icon={Icon}
          className={styles.horizontalIcon}
          onClick={copyMutation.mutate}
          loading={copyMutation.isLoading}
          size="small">
          {t('clipboard.tooltip')}
        </Button>
      )}
    </div>
  );
}
