import {
  BooleanEnumDisplay,
  booleanToBooleanEnum,
} from 'src/enums/BooleanEnum';
import { objDifferences } from 'src/utils/common';
import i18n from 'src/i18n';
import { State } from '@bitmodern/redux/store';
import _ from 'lodash';
import { caseTypeSelector } from '../caseTypes/selectors';
import { labelSelector } from '../label/selectors';
import { planSelector } from '../plans/selectors';
import { customFilterByTypeSelector } from '../customFilters/selectors';
import { statusSelector } from '../statuses/selectors';
import { userSelectorById } from '../users/selectors';
import { TestsFiltersKeys } from './reducer';
import { casePrioritySelectors } from 'src/gen/domain/case_priority/casePrioritySelector';
import { requirementByIdSelector } from '../requirements/selectors';

export function filtersByTypeSelector<T extends keyof State['filters']>(
  state: State,
  type: T,
): State['filters'][T] {
  return state.filters[type];
}

type TestFiltersKeys = keyof State['filters']['plans'];
export function filtersDisplaySelector(
  state: State,
  type: TestsFiltersKeys,
): Partial<Record<TestFiltersKeys, string | number>> {
  const customFilter = customFilterByTypeSelector(state, type);
  const filters = state.filters[type];
  const nonCustomFilters: any = customFilter
    ? objDifferences(customFilter.payload, filters)
    : filters;
  const display: Partial<Record<TestFiltersKeys, string | number>> = {};

  entityFilter(
    display,
    state,
    nonCustomFilters,
    'author',
    userSelectorById,
    'given_name',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'caseType',
    caseTypeSelector,
    'name',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'casePriority',
    casePrioritySelectors.selectById,
    'name',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'assignee',
    userSelectorById,
    'given_name',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'label',
    labelSelector,
    'label',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'suiteLabel',
    labelSelector,
    'label',
  );
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'status',
    statusSelector,
    'name',
  );
  entityFilter(display, state, nonCustomFilters, 'plan', planSelector, 'name');
  entityFilter(
    display,
    state,
    nonCustomFilters,
    'requirement',
    requirementByIdSelector,
    'payload.summary',
  );
  if (nonCustomFilters.name) display.name = `Name: ${nonCustomFilters.name}`;
  if (nonCustomFilters.suiteName)
    display.suiteName = `Folder: ${nonCustomFilters.suiteName}`;
  if (nonCustomFilters.actualResult)
    display.actualResult = `Actual Result: ${nonCustomFilters.actualResult}`;
  if (nonCustomFilters.hasActualResult)
    display.hasActualResult = 'Actual Result has content';
  if (typeof nonCustomFilters.automated !== 'undefined') {
    const booleanEnum = booleanToBooleanEnum(nonCustomFilters.automated);
    const trans = BooleanEnumDisplay.get(booleanEnum);
    const value = trans ? i18n.t(trans) : '';
    display.automated = value;
  }

  if (nonCustomFilters.exclusions === false)
    display.exclusions = type === 'plans' ? 'In Cycle' : 'In Story';

  return display;
}

function entityFilter(
  display,
  state: State,
  filtersByType,
  filter,
  selector: (state: State, id: number) => any,
  prop,
) {
  if (!filtersByType) return;
  const filters = filtersByType[filter];
  if (typeof filters === 'undefined') return;

  if (Object.keys(filters || {}).length === 1) {
    display[filter] = _.get(
      selector(state, filters[Object.keys(filters)[0]]),
      prop,
    );
  } else {
    display[filter] = Object.keys(filters || {}).length;
  }
}
