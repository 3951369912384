/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Filter } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { filterAdapter } from './filterAdapter';
import {
  filterFetchManyThunk,
  filterFetchOneThunk,
  filterDeleteOneThunk,
  filterUpdateOneThunk,
  filterCreateOneThunk,
} from './filterThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const filterSlice = createSlice({
  name: 'filter',
  initialState: filterAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    filterCleared(state) {
      state.loading = 'idle';
      filterAdapter.removeAll(state);
    },
    filterUpsertMany(
      state,
      action: PayloadAction<Filter[] | Record<number, Filter>>,
    ) {
      filterAdapter.upsertMany(state, action.payload);
    },
    filterUpsertOne(state, action: PayloadAction<Filter>) {
      filterAdapter.upsertOne(state, action.payload);
    },
    filterUpdateOne(state, action: PayloadAction<Update<Filter>>) {
      filterAdapter.updateOne(state, action.payload);
    },
    filterRemoveOne(state, action: PayloadAction<number>) {
      filterAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterFetchManyThunk.pending, handlePending);
    builder.addCase(filterFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      filterAdapter.upsertMany(state, action.payload.filter);
    });
    builder.addCase(filterFetchManyThunk.rejected, handleRejected);
    builder.addCase(filterFetchOneThunk.pending, handlePending);
    builder.addCase(filterFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      filterAdapter.upsertOne(state, action.payload.filter[0]);
    });
    builder.addCase(filterFetchOneThunk.rejected, handleRejected);
    builder.addCase(filterDeleteOneThunk.pending, handlePending);
    builder.addCase(filterDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        filterAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(filterDeleteOneThunk.rejected, handleRejected);
    builder.addCase(filterUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, filterAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(filterUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      filterAdapter.updateOne(state, action.payload);
    });
    builder.addCase(filterUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          filterAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(filterCreateOneThunk.pending, handlePending);
    builder.addCase(filterCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      filterAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(filterCreateOneThunk.rejected, handleRejected);
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.filter) {
          filterAdapter.upsertMany(state, action.payload.filter);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.filter) {
          filterAdapter.upsertMany(state, action.payload.filter);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.filter) {
          filterAdapter.upsertMany(state, action.payload.filter);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.filter) {
        filterAdapter.upsertMany(state, action.payload.filter);
      }
    });
  },
});

export const {
  filterCleared,
  filterUpsertMany,
  filterUpsertOne,
  filterUpdateOne,
  filterRemoveOne,
} = filterSlice.actions;

export const filterReducer = filterSlice.reducer;
