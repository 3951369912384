import React, { useState } from 'react';
import { Button, Checkbox, Tooltip } from '@bitmodern/bit-ui';
import { InfoIcon, UploadIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/i18n/hooks';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { routes } from 'src/components/Router';
import styles from './Gherkin.module.scss';
import ImportTable from '../ImportTable/ImportTable';
import useGherkin from './useGherkin';
import useModalManager from 'src/hooks/useModalManager';
import ExtraFields from '../ExtraFields';

export default function Gherkin() {
  const { t } = useTranslation();
  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const { hideModal } = useModalManager();

  const { formik, uploadFinished, handleDropFiles } = useGherkin({
    projectId: parseInt(projectId, 10),
  });

  const [automatedResults, setautomatedResults] = useState(
    !!formik.values?.automatedRunResults,
  );
  const [acceptFiles, setacceptFiles] = useState(['.feature']);

  const extraInfo = {
    project: !projectId && automatedResults,
    plan: automatedResults,
    suite: automatedResults,
    projectId: parseInt(projectId, 10) ?? null,
  };

  const onChangeAutomatedResults = (checked) => {
    setautomatedResults(checked);
    if (checked) {
      setacceptFiles(['.json']);
    } else {
      setacceptFiles(['.feature']);
    }
    formik.setFieldValue('automatedRunResults', checked);
    setautomatedResults(checked);
    formik.setFieldValue('filesToSend', []);
  };

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <div className={styles.content}>
        <div className={styles.container}>
          <UploadIcon color={vars.textPrimary} size={24} />
          <div className={styles.title}>
            <span>{t('importDataForm.tabs.gherkin')}</span>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.description}>
            <p>{t('importDataForm.help.Gherkin')}</p>
            <p>{t('importDataForm.help.GherkinTestResults')}</p>
          </div>
        </div>
        <div className={styles.dropzone}>
          <ImportTable
            files={formik.values.filesToSend || []}
            filesTypeAccepted={acceptFiles}
            onChangeFiles={handleDropFiles}
          />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Checkbox
            className={styles.automatedResults}
            onChange={onChangeAutomatedResults}
            checked={formik.values.automatedRunResults}>
            {t('importFile.options.automatedResults')}
          </Checkbox>
          <Tooltip
            tooltip={
              <div className={styles.infoTooltip}>
                {t('importFile.options.automatedResultsTooltip')}
              </div>
            }>
            <InfoIcon
              className={styles.infoIcon}
              color={vars.textPrimary}
              size={16}
            />
          </Tooltip>
        </div>
        <div className={styles.options}>
          <ExtraFields formik={formik} extraFields={extraInfo} />
          {uploadFinished && (
            <div className={styles.container}>
              <Link
                className={styles.link}
                to={routes.RUNS({ site, projectId })}>
                {t('importDataForm.importResults')}
              </Link>
            </div>
          )}
        </div>
        <div className={styles.submit}>
          {uploadFinished ? (
            <Button color="primaryDark" name="cancel" onClick={hideModal}>
              {t('importDataForm.close')}
            </Button>
          ) : (
            <Button
              loading={formik.isSubmitting}
              type="submit"
              disabled={formik.values.filesToSend.length === 0}>
              {t('importDataForm.submit')}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
