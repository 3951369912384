import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useAppDispatch } from '@bitmodern/redux/store';
import { showNotificationError } from '@testquality/sdk';
import { getClient } from 'src/Client';
import routes from 'src/components/Router/routes';
import { useQuery } from 'src/hooks/useQuery';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Input, InputPassword } from 'src/packages/bit-ui';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './SignInStep2.module.scss';
import useImpersonate from './useImpersonate';

import Debug from 'debug';
const debug = Debug('SignInStep2');

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('signIn.form.email'),
  password: Yup.string().required().min(6).label('signIn.form.password'),
});

type InitialValues = {
  email: string;
  password: string;
};

export type SignInStep2Props = {
  email: string;
};

export function SignInStep2({ email }: SignInStep2Props) {
  const dispatch = useAppDispatch();
  const impersonate = useImpersonate();
  const { callback } = useQuery(['callback']);
  const { t } = useTranslation();

  const initialValues = useMemo<InitialValues>(
    () => ({
      email,
      password: '',
    }),
    [email],
  );

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  async function onSubmit({ email, password }: InitialValues) {
    const remember = true;
    debug('onSubmit', { email, password });
    return getClient()
      .getAuth()
      .login(email, password, remember, { impersonate })
      .then((token) => {
        debug('onSubmit.login', token);
        if (callback) {
          window.location.href = callback + JSON.stringify(token);
        } else {
          dispatch(authenticateAction(token));
        }
      })
      .catch(showNotificationError);
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>{t('signIn.step2.title')}</div>

      <Link to={routes.LOGIN({})} className={styles.login}>
        {t('signIn.step2.login')}{' '}
        <span className={styles.loginLink}>{t('signIn.step2.loginLink')}</span>
      </Link>

      <form
        className={styles.form}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          aria-label={t('signIn.form.email')}
          error={formikError(formik, 'email')}
          fullWidth
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('signIn.form.email')}
          value={formik.values.email}
        />
        <InputPassword
          aria-label={t('signIn.form.password')}
          autoFocus={Boolean(formik.values.email)}
          error={formikError(formik, 'password')}
          fullWidth
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('signIn.form.password')}
          value={formik.values.password}
        />
        <Button
          className={styles.formSubmit}
          color="primaryLight"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('signIn.form.submit')}
        </Button>
      </form>
      <div className={styles.reset}>
        {t('signIn.step2.reset')}{' '}
        <Link className={styles.resetLink} to={routes.RECOVERY}>
          {t('signIn.step2.resetLink')}
        </Link>
      </div>
    </div>
  );
}
