/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Coupon } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { couponAdapter } from './couponAdapter';
import {
  couponFetchManyThunk,
  couponFetchOneThunk,
  couponDeleteOneThunk,
  couponUpdateOneThunk,
  couponCreateOneThunk,
} from './couponThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const couponSlice = createSlice({
  name: 'coupon',
  initialState: couponAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    couponCleared(state) {
      state.loading = 'idle';
      couponAdapter.removeAll(state);
    },
    couponUpsertMany(
      state,
      action: PayloadAction<Coupon[] | Record<number, Coupon>>,
    ) {
      couponAdapter.upsertMany(state, action.payload);
    },
    couponUpsertOne(state, action: PayloadAction<Coupon>) {
      couponAdapter.upsertOne(state, action.payload);
    },
    couponUpdateOne(state, action: PayloadAction<Update<Coupon>>) {
      couponAdapter.updateOne(state, action.payload);
    },
    couponRemoveOne(state, action: PayloadAction<number>) {
      couponAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(couponFetchManyThunk.pending, handlePending);
    builder.addCase(couponFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      couponAdapter.upsertMany(state, action.payload.coupon);
    });
    builder.addCase(couponFetchManyThunk.rejected, handleRejected);
    builder.addCase(couponFetchOneThunk.pending, handlePending);
    builder.addCase(couponFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      couponAdapter.upsertOne(state, action.payload.coupon[0]);
    });
    builder.addCase(couponFetchOneThunk.rejected, handleRejected);
    builder.addCase(couponDeleteOneThunk.pending, handlePending);
    builder.addCase(couponDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        couponAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(couponDeleteOneThunk.rejected, handleRejected);
    builder.addCase(couponUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, couponAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(couponUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      couponAdapter.updateOne(state, action.payload);
    });
    builder.addCase(couponUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          couponAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(couponCreateOneThunk.pending, handlePending);
    builder.addCase(couponCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      couponAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(couponCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  couponCleared,
  couponUpsertMany,
  couponUpsertOne,
  couponUpdateOne,
  couponRemoveOne,
} = couponSlice.actions;

export const couponReducer = couponSlice.reducer;
