/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { MilestoneHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { milestoneHistoryAdapter } from './milestoneHistoryAdapter';
import { milestoneHistoryFetchThunk } from './milestoneHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const milestoneHistorySlice = createSlice({
  name: 'milestoneHistory',
  initialState: milestoneHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    milestoneHistoryCleared(state) {
      state.loading = 'idle';
      milestoneHistoryAdapter.removeAll(state);
    },
    milestoneHistoryUpsertMany(
      state,
      action: PayloadAction<
        MilestoneHistory[] | Record<number, MilestoneHistory>
      >,
    ) {
      milestoneHistoryAdapter.upsertMany(state, action.payload);
    },
    milestoneHistoryUpsertOne(state, action: PayloadAction<MilestoneHistory>) {
      milestoneHistoryAdapter.upsertOne(state, action.payload);
    },
    milestoneHistoryUpdateOne(
      state,
      action: PayloadAction<Update<MilestoneHistory>>,
    ) {
      milestoneHistoryAdapter.updateOne(state, action.payload);
    },
    milestoneHistoryRemoveOne(state, action: PayloadAction<number>) {
      milestoneHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(milestoneHistoryFetchThunk.pending, handlePending);
    builder.addCase(milestoneHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      milestoneHistoryAdapter.upsertMany(
        state,
        action.payload.milestoneHistory,
      );
    });
    builder.addCase(milestoneHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  milestoneHistoryCleared,
  milestoneHistoryUpsertMany,
  milestoneHistoryUpsertOne,
  milestoneHistoryUpdateOne,
  milestoneHistoryRemoveOne,
} = milestoneHistorySlice.actions;

export const milestoneHistoryReducer = milestoneHistorySlice.reducer;
