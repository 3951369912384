import * as React from 'react';

const SvgComponent = (props) => (
  <svg
    width={40}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${256} ${256}`}
    preserveAspectRatio="xMidYMid"
    {...props}>
    <g fill="#8A4182">
      <path d="M118.267 105.334h19.795l4.918-6.733V45.874h-29.627v52.733l4.914 6.727M105.509 128.851l6.115-18.717-4.919-6.73L56.271 87.11l-9.154 28.016 50.44 16.296 7.952-2.57M124.06 148.187l-16.012-11.57-7.96 2.572-31.168 42.66 23.968 17.313 31.172-42.66v-8.315M148.285 136.614l-16.016 11.57.004 8.32 31.169 42.658 23.969-17.314-31.175-42.663-7.95-2.57M144.702 110.13l6.118 18.72 7.958 2.569 50.435-16.293-9.157-28.018-50.436 16.297-4.918 6.725" />
      <path d="M128.17.106C57.71.106.384 57.1.384 127.152c0 70.078 57.326 127.087 127.788 127.087 70.454 0 127.774-57.009 127.774-127.087 0-70.053-57.32-127.046-127.774-127.046Zm0 25.725c56.27 0 101.9 45.357 101.9 101.32 0 55.983-45.63 101.36-101.9 101.36-56.287 0-101.912-45.377-101.912-101.36 0-55.963 45.625-101.32 101.913-101.32Z" />
      <path d="m205.565 155.391-38.338-12.387 2.535-7.765 38.338 12.387-2.535 7.765M156.67 94.19l-6.645-4.798 23.693-32.429 6.644 4.799-23.693 32.428M100.072 93.895l-23.69-32.43 6.644-4.798 23.69 32.428-6.644 4.8M50.608 154.907l-2.536-7.765 38.334-12.382 2.537 7.765-38.335 12.382M123.807 208.159h8.211v-40.085h-8.21v40.085Z" />
    </g>
  </svg>
);

export default SvgComponent;
