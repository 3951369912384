import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import useParams from 'src/hooks/useParams';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { requirementByIdSelector } from '@bitmodern/redux/state/requirements/selectors';
import { routes } from 'src/components/Router';
import { requirementDeleteOneThunk } from 'src/gen/domain/requirement/requirementThunk';
import { getIntegrationProjectById } from '@bitmodern/redux/state/integrationProject/selectors';
import { integrationByIdSelector } from '@bitmodern/redux/state/integration/selectors';
import { integrationUserByIntegrationSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { BatchService, getRequirementRunsAnalysis } from '@testquality/sdk';
import useFetch from 'src/hooks/useFetch';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { defectComponentFetchManyThunk } from 'src/gen/domain/defect_component/defectComponentThunk';
import { defectLabelFetchManyThunk } from 'src/gen/domain/defect_label/defectLabelThunk';
import { defectPriorityFetchManyThunk } from 'src/gen/domain/defect_priority/defectPriorityThunk';
import { nativeDefectStatusFetchManyThunk } from 'src/gen/domain/native_defect_status/nativeDefectStatusThunk';
import { nativeDefectResFetchManyThunk } from 'src/gen/domain/native_defect_res/nativeDefectResThunk';
import { defectComponentsByIntegrationProjectSelector } from '@bitmodern/redux/state/defectComponents/selectors';
import { defectPrioritiesByIntegrationProjectSelector } from '@bitmodern/redux/state/defectPriorities/selectors';
import { defectUsersByIntegrationProjectSelector } from '@bitmodern/redux/state/defectUsers/selectors';
import { defectTypesByIntegrationProjectSelector } from '@bitmodern/redux/state/defectTypes/selectors';
import { ownDefectLabelsByIntegrationProjectSelector } from '@bitmodern/redux/state/defectLabels/selectors';
import { defectUserFetchManyThunk } from 'src/gen/domain/defect_user/defectUserThunk';
import { defectTypeFetchManyThunk } from 'src/gen/domain/defect_type/defectTypeThunk';
import { nativeDefectStatusesByIntegrationProject } from '@bitmodern/redux/state/nativeDefectStatuses/selectors';
import { nativeDefectResByIntegrationProjectSelectors } from '@bitmodern/redux/state/nativeDefectRes/selectors';
import { getEnv } from 'src/env';
import RequirementView from '../RequirementView/RequirementView';
import styles from './RequirementViewDrawer.module.scss';

type Props = {
  requirementId: number;
};

export default function RequirementViewContainer({ requirementId }: Props) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { site, projectId } = useParams<typeof routes.PROJECT.params>();
  const { closeDrawer } = useDrawerManager();
  const requirement = useAppSelector((state) =>
    requirementByIdSelector(state, requirementId),
  );

  const integrationProject = useAppSelector((state) =>
    getIntegrationProjectById(state, {
      id: requirement?.integration_project_id,
    }),
  );
  const integration = useAppSelector((state) => {
    return integrationByIdSelector(state, {
      id: integrationProject?.integration_id,
    });
  });
  const integrationUsers = useAppSelector((state) => {
    return integrationUserByIntegrationSelector(state, {
      integrationId: integration?.id,
    });
  });

  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: parseInt(projectId, 10) }),
  );

  const defectComponents = useAppSelector(
    defectComponentsByIntegrationProjectSelector(integrationProject?.id),
  );
  const defectPriorities = useAppSelector(
    defectPrioritiesByIntegrationProjectSelector(integrationProject?.id),
  );
  const defectUsers = useAppSelector(
    defectUsersByIntegrationProjectSelector(integrationProject?.id),
  );
  const defectTypes = useAppSelector(
    defectTypesByIntegrationProjectSelector(integrationProject?.id),
  );
  const defectLabels = useAppSelector(
    ownDefectLabelsByIntegrationProjectSelector(integrationProject?.id),
  );

  const defectStatuses = useAppSelector((state) =>
    nativeDefectStatusesByIntegrationProject(state, integrationProject?.id!),
  );
  const defectResolutions = useAppSelector((state) =>
    nativeDefectResByIntegrationProjectSelectors(
      state,
      integrationProject?.id!,
    ),
  );

  const onClose = () => {
    history.goBack();
  };

  const onDelete = () => {
    if (!requirement) return Promise.resolve();
    return dispatch(requirementDeleteOneThunk({ id: requirement.id })).then(
      history.goBack,
    );
  };

  useEffect(() => {
    if (!requirement) {
      closeDrawer();
    }
  }, [closeDrawer, requirement]);

  const reqAnalysis = useCallback(() => {
    return getRequirementRunsAnalysis(requirementId);
  }, [requirementId]);

  const reqAnalysisFetch = useFetch(
    reqAnalysis,
    `RequirementRunAnalysis-${requirementId}`,
    {
      ...(getEnv()?.features?.dataCache && { staleWhileRevalidate: true }),
      initialData: [],
    },
  );

  const request = useCallback(() => {
    const batch = new BatchService();

    [
      defectComponentFetchManyThunk,
      defectLabelFetchManyThunk,
      defectPriorityFetchManyThunk,
      defectUserFetchManyThunk,
      defectTypeFetchManyThunk,
      nativeDefectStatusFetchManyThunk,
      nativeDefectResFetchManyThunk,
    ].forEach((fetchManyThunk) => {
      dispatch(
        fetchManyThunk({
          batch,
          params: {
            integration_project_id: integrationProject?.id,
            per_page: -1,
          },
        }),
      );
    });

    return batch.executeBatch();
  }, [dispatch, integrationProject]);

  const requestFetch = useFetch(request, 'requirement-view');

  if (!requirementId || !requirement) return null;

  return (
    <>
      <Helmet>
        <title>{requirement.payload.summary}</title>
      </Helmet>

      <RequirementView
        className={styles.requirement}
        onClose={onClose}
        onDelete={onDelete}
        requirement={requirement}
        integration={integration}
        integrationProject={integrationProject}
        integrationUsers={integrationUsers}
        defectComponents={defectComponents}
        defectLabels={defectLabels}
        defectStatuses={defectStatuses}
        defectPriorities={defectPriorities}
        defectResolutions={defectResolutions}
        defectTypes={defectTypes}
        defectUsers={defectUsers}
        projectId={projectId}
        runsAnalysis={reqAnalysisFetch.data}
        isLoading={requestFetch.isLoading || reqAnalysisFetch.isLoading}
        site={site}
        tests={tests}
      />
    </>
  );
}
