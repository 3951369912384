import { State } from '@bitmodern/redux/store';
import planSuitesAdapter from 'src/gen/domain/plan_suite/planSuiteAdapter';
import {
  plansByProjectSelector,
  planSelector,
} from '@bitmodern/redux/state/plans/selectors';
import { suiteSelector } from '../suites/selectors';

const planSuitesSelectors = planSuitesAdapter.getSelectors(
  (state: State) => state.gen.planSuite,
);

export function planSuitesSelector(state: State) {
  return planSuitesSelectors
    .selectAll(state)
    .sort((a, b) => a.sequence_plan - b.sequence_plan);
}

export function planSuitesByPlanSelector(state: State, planId: number) {
  return planSuitesSelector(state).filter(
    (planSuites) => planSuites.plan_id === planId,
  );
}
export function planSuitesByProjectSelector(state: State, projectId: number) {
  const plans = plansByProjectSelector(state, { projectId });
  if (!plans.length) return [];

  const plansIds = plans.map((plan) => plan.id);

  return planSuitesSelector(state).filter((planSuites) =>
    plansIds.includes(planSuites.plan_id),
  );
}

export function planSuitesBySuiteSelector(state: State, suiteId: number) {
  return planSuitesSelector(state).filter(
    (planSuites) => planSuites.suite_id === suiteId,
  );
}

export function planSuitesByParentSelector(state: State, parentId: number) {
  return planSuitesSelector(state).filter((planSuite) => {
    if (typeof planSuite.parent_id !== 'number') return false;
    return planSuite.parent_id === parentId;
  });
}

export function planSuitesBySuiteParentsSelector(
  state: State,
  suiteParents: number[],
) {
  return planSuitesSelector(state).filter((planSuite) => {
    if (typeof planSuite.parent_id !== 'number') return false;
    return suiteParents.includes(planSuite.parent_id);
  });
}

export function rootPlanSuiteSelector(
  state: State,
  args: { projectId?: number },
) {
  if (!args.projectId) return undefined;
  const planSuites = planSuitesSelector(state);

  for (let i = 0; i < planSuites.length; i += 1) {
    const plan = planSelector(state, planSuites[i].plan_id);
    const suite = suiteSelector(state, planSuites[i].suite_id);

    if (
      plan?.is_root &&
      plan?.project_id === args.projectId &&
      suite?.is_root
    ) {
      return planSuites[i];
    }
  }
  return undefined;
}
