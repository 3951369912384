import { FormikProps } from 'formik';

export function formikError<P extends any>(
  formik: FormikProps<P>,
  name: keyof P,
): string {
  const error = formik.errors[name];

  if (formik.touched[name] && error) {
    if (typeof error === 'string') {
      return error;
    }
    if (Array.isArray(error) && formik.submitCount) {
      return (error as string[]).filter((e) => e).join(', ');
    }
  }

  return '';
}

export function formikErrorArray<P extends any>(
  formik: FormikProps<P>,
  name: keyof P,
  index: number,
): any {
  const error = formik.errors[name]?.[index] as string[] | undefined;

  if (formik.touched[name]?.[index] && error) {
    return error;
  }

  return undefined;
}
