import React, { ComponentProps, useState } from 'react';
import {
  BugIcon,
  CancelIcon,
  DownloadIcon,
  ListBulletedIcon,
  UserIcon,
} from '@bitmodern/bit-ui/icons';
import useParams from 'src/hooks/useParams';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { useTranslation } from 'src/i18n/hooks';
import { Avatar } from '@bitmodern/bit-ui';
import { changeRunResultsStatusThunk } from '@bitmodern/redux/state/runResults/thunks';
import { runResultUpdateOneThunk } from 'src/gen/domain/run_result/runResultThunk';
import {
  exportRunsToCSVThunk,
  exportRunsToXLSThunk,
} from '@bitmodern/redux/state/exportData/thunks';
import { RunResult, BatchService } from '@testquality/sdk';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import { RunResultTreeItem } from '@bitmodern/redux/state/runResults/selectors';
import { TEST_TYPE } from '../TreeBase/treeTypes';
import CommandBar from '../CommandBar';
import {
  formatUserName,
  processAttachmentPath,
} from '../../../utils/fileHelper';
import { useIntegrationCheck } from '../../../hooks/useIntegrationCheck';

type ComandBarProps = ComponentProps<typeof CommandBar>;
type Commands = ComandBarProps['commands'];

type Props = Pick<ComandBarProps, 'onClose' | 'open'> & {
  items: RunResultTreeItem[];
};

export default function CommandBarRunResults({ open, items, onClose }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersSelector);
  const statuses = useAppSelector(statusSelectors.selectAll);
  const { site, projectId, runId } = useParams<typeof routes.RUN.path>();
  const { openCreateDefect } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const commands: { [key: string]: Commands } = {
    default: [
      {
        command: 'log defect',
        key: 'logDefect',
        handler: handleLogDefect,
        icon: <BugIcon color={vars.textPrimary} size={18} />,
        label: 'Log Defect',
      },
      {
        command: 'assign to',
        key: 'assigTo',
        handler: () => setCurrent('assign'),
        icon: <UserIcon color={vars.textPrimary} size={18} />,
        label: 'Assign to',
      },
      {
        command: 'set status',
        key: 'status',
        handler: () => setCurrent('status'),
        icon: <ListBulletedIcon color={vars.textPrimary} size={18} />,
        label: 'Set status',
      },
      {
        command: 'export tests',
        key: 'export',
        handler: () => setCurrent('exportFormat'),
        icon: <DownloadIcon color={vars.textPrimary} size={18} />,
        label: 'Export tests',
      },
    ],
    assign: [
      {
        command: 'unassign',
        key: null,
        handler: unassign,
        icon: <CancelIcon size={18} />,
        label: t('commandBarRunResults.testCommands.unassign'),
      },
      ...users.map((user) => ({
        command: user.given_name || user.email,
        key: user.id,
        handler: assigtToHandler,
        icon: <Avatar size={18} src={processAttachmentPath(user.picture)} />,
        label: formatUserName(user),
      })),
    ],
    status: statuses.map((status) => ({
      command: status.name,
      key: status.id,
      handler: statusHandler,
      icon: <span />,
      label: status.name,
    })),
    exportFormat: [
      {
        command: 'csv',
        key: 'csvExport',
        handler: handleCSVExport,
        icon: <span />,
        label: 'CSV',
      },
      {
        command: 'excel',
        key: 'xlsExport',
        handler: handleXLSExport,
        icon: <span />,
        label: 'Excel',
      },
    ],
  };

  const placeholders = {
    default: t('commandBar.placeholder.default'),
  };

  const runResults = items.filter((item) => item.type === TEST_TYPE);
  const runResultIds = runResults
    .flatMap((item) => item.data.runResults)
    .map((runResult) => runResult.id);

  function assigtToHandler(command) {
    const user = users.find((u) => command.key === u.id);
    if (user) {
      const values = runResultIds.map((id) => ({
        assigned_to_tester: user.id,
        id,
      }));
      updateRunResults(values);
    }
    handleClose();
  }

  function handleLogDefect() {
    if (runResults && runResults.length > 0) {
      return openCreateDefect(parseInt(runId, 10), runResultIds);
    }
    return Promise.resolve();
  }

  function statusHandler(command) {
    dispatch(changeRunResultsStatusThunk(runResultIds, command.key));
    handleClose();
  }

  const updateRunResults = (values: Array<Partial<RunResult>>) => {
    const batch = new BatchService();
    values.forEach((value) => {
      dispatch(
        runResultUpdateOneThunk({
          data: value,
          id: value.id,
          optimistic: true,
          batch,
        }),
      );
    });
    batch.executeBatch();
  };

  function handleCSVExport() {
    const runResultData = runResults.map((item) => item.data);
    dispatch(
      exportRunsToCSVThunk([
        { runResultsData: runResultData, runId: parseInt(runId, 10) },
      ]),
    );
  }

  function handleXLSExport() {
    const runResultData = runResults.map((item) => item.data);
    dispatch(
      exportRunsToXLSThunk([
        { runResultsData: runResultData, runId: parseInt(runId, 10) },
      ]),
    );
  }

  const [current, setCurrent] = useState('default');

  function handleClose() {
    setCurrent('default');
    onClose();
  }

  function unassign() {
    const values = runResultIds.map((id) => ({
      assigned_to_tester: null,
      id,
    }));
    // We can't fix this type error without modifying the model
    // @ts-expect-error
    updateRunResults(values);
    handleClose();
  }

  return (
    <CommandBar
      commands={commands[current]}
      id={current}
      open={open}
      onClose={handleClose}
      placeholder={placeholders[current] || placeholders.default}
    />
  );
}
