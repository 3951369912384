import React from 'react';
import { Dialog, DialogContent } from '@bitmodern/bit-ui';
import useModalManager from 'src/hooks/useModalManager';
import ImportAutomationView from '../ImportAutomationView';
import { routes } from 'src/components/Router';
import { useParams } from 'react-router';
import JUnit from 'src/components/organisms/ImportDataForm/Junit/JUnit';
import CSV from 'src/components/organisms/ImportDataForm/CSV';
import Gherkin from 'src/components/organisms/ImportDataForm/Gherkin';
import { useTranslation } from 'react-i18next';

export default function ImportAutomationDialog() {
  const { hideModal, setDefaultModalParent } = useModalManager();
  const { t } = useTranslation();
  const { site, projectId, slug } =
    useParams<typeof routes.IMPORT_SLUG.params>();

  const component = () => {
    setDefaultModalParent({
      pathname: routes.PROJECT_TESTS({
        site,
        projectId,
      }),
    });

    switch (slug) {
      case 'xml':
        return <JUnit />;
      case 'csv':
        return <CSV />;
      case 'gherkin':
        return <Gherkin />;
      default:
        return <ImportAutomationView />;
    }
  };

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="large"
      title={t('importDataForm.title')}>
      <DialogContent>{component()}</DialogContent>
    </Dialog>
  );
}
