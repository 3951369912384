/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PurposeHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { purposeHistoryAdapter } from './purposeHistoryAdapter';
import { purposeHistoryFetchThunk } from './purposeHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const purposeHistorySlice = createSlice({
  name: 'purposeHistory',
  initialState: purposeHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    purposeHistoryCleared(state) {
      state.loading = 'idle';
      purposeHistoryAdapter.removeAll(state);
    },
    purposeHistoryUpsertMany(
      state,
      action: PayloadAction<PurposeHistory[] | Record<number, PurposeHistory>>,
    ) {
      purposeHistoryAdapter.upsertMany(state, action.payload);
    },
    purposeHistoryUpsertOne(state, action: PayloadAction<PurposeHistory>) {
      purposeHistoryAdapter.upsertOne(state, action.payload);
    },
    purposeHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PurposeHistory>>,
    ) {
      purposeHistoryAdapter.updateOne(state, action.payload);
    },
    purposeHistoryRemoveOne(state, action: PayloadAction<number>) {
      purposeHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(purposeHistoryFetchThunk.pending, handlePending);
    builder.addCase(purposeHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      purposeHistoryAdapter.upsertMany(state, action.payload.purposeHistory);
    });
    builder.addCase(purposeHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  purposeHistoryCleared,
  purposeHistoryUpsertMany,
  purposeHistoryUpsertOne,
  purposeHistoryUpdateOne,
  purposeHistoryRemoveOne,
} = purposeHistorySlice.actions;

export const purposeHistoryReducer = purposeHistorySlice.reducer;
