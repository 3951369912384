/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { User } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { userAdapter } from './userAdapter';
import {
  userFetchManyThunk,
  userFetchOneThunk,
  userDeleteOneThunk,
  userUpdateOneThunk,
  userCreateOneThunk,
} from './userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: userAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    userCleared(state) {
      state.loading = 'idle';
      userAdapter.removeAll(state);
    },
    userUpsertMany(
      state,
      action: PayloadAction<User[] | Record<number, User>>,
    ) {
      userAdapter.upsertMany(state, action.payload);
    },
    userUpsertOne(state, action: PayloadAction<User>) {
      userAdapter.upsertOne(state, action.payload);
    },
    userUpdateOne(state, action: PayloadAction<Update<User>>) {
      userAdapter.updateOne(state, action.payload);
    },
    userRemoveOne(state, action: PayloadAction<number>) {
      userAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userFetchManyThunk.pending, handlePending);
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      userAdapter.upsertMany(state, action.payload.user);
    });
    builder.addCase(userFetchManyThunk.rejected, handleRejected);
    builder.addCase(userFetchOneThunk.pending, handlePending);
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      userAdapter.upsertOne(state, action.payload.user[0]);
    });
    builder.addCase(userFetchOneThunk.rejected, handleRejected);
    builder.addCase(userDeleteOneThunk.pending, handlePending);
    builder.addCase(userDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        userAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(userDeleteOneThunk.rejected, handleRejected);
    builder.addCase(userUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, userAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(userUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      userAdapter.updateOne(state, action.payload);
    });
    builder.addCase(userUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          userAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(userCreateOneThunk.pending, handlePending);
    builder.addCase(userCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      userAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(userCreateOneThunk.rejected, handleRejected);
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.user) {
          userAdapter.upsertMany(state, action.payload.user);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.user) {
          userAdapter.upsertMany(state, action.payload.user);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.user) {
          userAdapter.upsertMany(state, action.payload.user);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.user) {
        userAdapter.upsertMany(state, action.payload.user);
      }
    });
  },
});

export const {
  userCleared,
  userUpsertMany,
  userUpsertOne,
  userUpdateOne,
  userRemoveOne,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
