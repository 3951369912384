import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { AccessRole } from '@testquality/sdk';
import Yup from 'src/utils/yup';
import { formikError } from 'src/utils/formik';
import {
  Grid,
  FieldLabel,
  Button,
  Input,
  Loading,
  Checkbox,
  PillCheckbox,
  SelectMultiple,
} from '@bitmodern/bit-ui';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import useFetch from 'src/hooks/useFetch';
import { Permission } from 'src/enums/PermissionsEnum';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { policyFetchManyThunk } from 'src/gen/domain/policy/policyThunk';
import styles from './RoleForm.module.scss';

export type PermissionBreakdown = { view: boolean; edit: boolean };

const defaultPermissionsBreakdown = { view: true, edit: false };

export const allProjectsOption = {
  label: 'All Projects',
  value: 0,
};

export type RoleFormDetails = {
  name: string;
  projects: number[];
  isDefault: boolean;
  [Permission.COMPANY]: PermissionBreakdown;
  [Permission.INTEGRATIONS]: PermissionBreakdown;
  [Permission.USER_MANAGEMENT]: PermissionBreakdown;
  [Permission.CUSTOMIZATIONS]: PermissionBreakdown;
  [Permission.PROJECT]: PermissionBreakdown;
  [Permission.MILESTONE]: PermissionBreakdown;
  [Permission.TEST]: PermissionBreakdown;
  [Permission.RUN]: PermissionBreakdown;
};

export function initialValuesBuilder(
  name = '',
  projects = [0],
  isDefault = false,
  defaultPermission = defaultPermissionsBreakdown,
): RoleFormDetails {
  return {
    name,
    projects,
    isDefault,
    [Permission.COMPANY]: defaultPermission,
    [Permission.INTEGRATIONS]: defaultPermission,
    [Permission.USER_MANAGEMENT]: defaultPermission,
    [Permission.CUSTOMIZATIONS]: defaultPermission,
    [Permission.PROJECT]: defaultPermission,
    [Permission.MILESTONE]: defaultPermission,
    [Permission.TEST]: defaultPermission,
    [Permission.RUN]: defaultPermission,
  };
}

type Props = {
  onSubmit: (values: RoleFormDetails) => Promise<any>;
  currentAccessRole?: AccessRole;
  initialValues: RoleFormDetails;
};

export default function RoleForm({
  onSubmit,
  currentAccessRole,
  initialValues,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);

  const projectsOptions = useMemo(
    () =>
      projects.map((project) => ({ label: project.name, value: project.id })),
    [projects],
  );
  const request = useCallback(() => {
    if (!currentAccessRole) return Promise.reject();

    return dispatch(
      policyFetchManyThunk({
        params: {
          access_role_id: currentAccessRole.id,
          per_page: -1,
          _with: 'PolicyRows',
        },
      }),
    );
  }, [dispatch, currentAccessRole]);

  const { isLoading } = useFetch(request, `policies-${currentAccessRole?.id}`, {
    fetchOnce: true,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('roleForm.name'),
    isDefault: Yup.bool().required(),
    projects: Yup.array().required().label('roleForm.projects'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const onSelectChange = useCallback(
    (values: number[]) => {
      const isAllProjectsSelectedNow = values.includes(allProjectsOption.value);
      const isAllProjectsAlreadySelected = formik.values.projects.includes(
        allProjectsOption.value,
      );

      if (isAllProjectsAlreadySelected && isAllProjectsSelectedNow) {
        /* NOTE: If "All Projects" is selected and the user selects */
        /* another project we automatically filter "All Projects" out */
        const filteredValues = values.filter(
          (value) => value !== allProjectsOption.value,
        );
        formik.setFieldValue('projects', filteredValues);
        return undefined;
      }
      if (isAllProjectsSelectedNow) {
        formik.setFieldValue('projects', [allProjectsOption.value]);
        return undefined;
      }
      formik.setFieldValue('projects', values);
      return undefined;
    },
    [formik],
  );

  const createCheckboxOnChange = (name: string) => (checked: boolean) =>
    formik.setFieldValue(name, checked);

  if (isLoading) return <Loading className={styles.loading} size={48} />;

  return (
    <form
      className={styles.container}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}>
      <Grid.Row gutter={[12, 0]}>
        <Grid.Col span={12} md={4}>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('roleForm.name')}
            labelClassName={styles.firstLabel}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.name}
          />
        </Grid.Col>
        <Grid.Col span={12} md={8}>
          <SelectMultiple
            label="Projects"
            required
            error={formikError(formik, 'projects')}
            onChange={onSelectChange}
            options={[allProjectsOption, ...projectsOptions]}
            values={formik.values.projects}
          />
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Checkbox
            className={styles.default}
            checked={formik.values.isDefault}
            name="isDefault"
            onChange={createCheckboxOnChange('isDefault')}>
            {t('roleForm.default')}
          </Checkbox>
        </Grid.Col>
      </Grid.Row>
      <h3 className={styles.sectionTitle}>
        {t('roleForm.section.administration.title')}
      </h3>
      <div className={styles.sectionContainer}>
        <Grid.Row gutter={0}>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.administration.company')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.company.edit}
              name="company.edit"
              onChange={createCheckboxOnChange('company.edit')}
              content={t('roleForm.edit')}
            />
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.administration.integrations')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.integrations.edit}
              name="integrations.edit"
              onChange={createCheckboxOnChange('integrations.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.administration.userManagement')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.userManagement.edit}
              name="userManagement.edit"
              onChange={createCheckboxOnChange('userManagement.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.administration.customizations')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.customizations.edit}
              name="customizations.edit"
              onChange={createCheckboxOnChange('customizations.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
        </Grid.Row>
      </div>

      <h3 className={styles.sectionTitle}>
        {t('roleForm.section.project.title')}
      </h3>
      <div className={styles.sectionContainer}>
        <Grid.Row gutter={0}>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.project.project')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.project.view}
              name="project.view"
              disabled
              onChange={createCheckboxOnChange('project.view')}
              content={t('roleForm.view')}
            />
            <PillCheckbox
              checked={formik.values.project.edit}
              name="project.edit"
              onChange={createCheckboxOnChange('project.edit')}
              content={t('roleForm.edit')}
            />
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.project.milestone')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.milestone.view}
              name="milestone.view"
              onChange={createCheckboxOnChange('milestone.view')}
              content={t('roleForm.view')}
            />
            <PillCheckbox
              checked={formik.values.milestone.edit}
              name="milestone.edit"
              onChange={createCheckboxOnChange('milestone.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.project.test')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.test.view}
              name="test.view"
              onChange={createCheckboxOnChange('test.view')}
              content={t('roleForm.view')}
            />
            <PillCheckbox
              checked={formik.values.test.edit}
              name="test.edit"
              onChange={createCheckboxOnChange('test.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <FieldLabel className={styles.permissionLabel}>
              <span>{t('roleForm.section.project.run')}</span>
            </FieldLabel>
            <PillCheckbox
              checked={formik.values.run.view}
              name="run.view"
              onChange={createCheckboxOnChange('run.view')}
              content={t('roleForm.view')}
            />
            <PillCheckbox
              checked={formik.values.run.edit}
              name="run.edit"
              onChange={createCheckboxOnChange('run.edit')}
              content={t('roleForm.edit')}
            />
          </Grid.Col>
        </Grid.Row>
      </div>
      <div className={styles.submit}>
        <Button
          loading={formik.isSubmitting}
          disabled={!formik.dirty}
          type="submit">
          {t('roleForm.submit')}
        </Button>
      </div>
    </form>
  );
}
