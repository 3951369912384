/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Plan } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { planAdapter } from './planAdapter';
import {
  planFetchManyThunk,
  planFetchOneThunk,
  planDeleteOneThunk,
  planUpdateOneThunk,
  planCreateOneThunk,
} from './planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import { suiteFetchManyThunk, suiteFetchOneThunk } from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const planSlice = createSlice({
  name: 'plan',
  initialState: planAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    planCleared(state) {
      state.loading = 'idle';
      planAdapter.removeAll(state);
    },
    planUpsertMany(
      state,
      action: PayloadAction<Plan[] | Record<number, Plan>>,
    ) {
      planAdapter.upsertMany(state, action.payload);
    },
    planUpsertOne(state, action: PayloadAction<Plan>) {
      planAdapter.upsertOne(state, action.payload);
    },
    planUpdateOne(state, action: PayloadAction<Update<Plan>>) {
      planAdapter.updateOne(state, action.payload);
    },
    planRemoveOne(state, action: PayloadAction<number>) {
      planAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(planFetchManyThunk.pending, handlePending);
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planAdapter.upsertMany(state, action.payload.plan);
    });
    builder.addCase(planFetchManyThunk.rejected, handleRejected);
    builder.addCase(planFetchOneThunk.pending, handlePending);
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planAdapter.upsertOne(state, action.payload.plan[0]);
    });
    builder.addCase(planFetchOneThunk.rejected, handleRejected);
    builder.addCase(planDeleteOneThunk.pending, handlePending);
    builder.addCase(planDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        planAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(planDeleteOneThunk.rejected, handleRejected);
    builder.addCase(planUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, planAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(planUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planAdapter.updateOne(state, action.payload);
    });
    builder.addCase(planUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          planAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(planCreateOneThunk.pending, handlePending);
    builder.addCase(planCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(planCreateOneThunk.rejected, handleRejected);
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.plan) {
          planAdapter.upsertMany(state, action.payload.plan);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.plan) {
        planAdapter.upsertMany(state, action.payload.plan);
      }
    });
  },
});

export const {
  planCleared,
  planUpsertMany,
  planUpsertOne,
  planUpdateOne,
  planRemoveOne,
} = planSlice.actions;

export const planReducer = planSlice.reducer;
