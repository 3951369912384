import useLocation from './useLocation';

export function useQuery(keys, search = ''): Record<string, string> {
  const location = useLocation();
  const query = new URLSearchParams(search || location.search);
  return keys.reduce((obj, key) => {
    obj[key] = query.get(key);
    return obj;
  }, {});
}
