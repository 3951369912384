import React from 'react';
import { Command } from './CommandBar';
import { CommandItem } from './CommandItem';
import styles from './CommandBar.module.scss';
import Separator from '../Separator';
import { CommandIcon } from '../icons';
import vars from '../styling/exports.scss';

type Props = {
  command: Command;
  onClickCommand;
  onHoverCommand;
  current: boolean;
  withSeparator?: boolean;
  isLoading?: boolean;
};

export const CommandBarRow = ({
  command,
  onClickCommand,
  onHoverCommand,
  current,
  withSeparator,
  isLoading,
}: Props) => {
  const defaultIcon = <CommandIcon color={vars.textSecondary} size={22} />;
  return (
    <>
      <CommandItem
        checked={command.checked}
        extraActions={command.extraActions}
        key={command.command}
        icon={command.icon ? command.icon : defaultIcon}
        onClick={() => (!isLoading ? onClickCommand(command) : null)}
        onHover={() => (!isLoading ? onHoverCommand(command) : null)}
        onChangeChecked={command.onChangeChecked}
        selected={current}
        isLoading={isLoading}>
        <div>
          <div>{command.label}</div>
          <div>
            {command.sublabel ? (
              <span className={styles.sublabel}>{command.sublabel}</span>
            ) : null}
          </div>
        </div>
      </CommandItem>
      {withSeparator && (
        <Separator
          className={styles.separator}
          color={'primaryMain'}
          size={'minimal'}
        />
      )}
    </>
  );
};
