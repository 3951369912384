/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { SharedStep } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { sharedStepAdapter } from './sharedStepAdapter';
import {
  sharedStepFetchManyThunk,
  sharedStepFetchOneThunk,
  sharedStepDeleteOneThunk,
  sharedStepUpdateOneThunk,
  sharedStepCreateOneThunk,
} from './sharedStepThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const sharedStepSlice = createSlice({
  name: 'sharedStep',
  initialState: sharedStepAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    sharedStepCleared(state) {
      state.loading = 'idle';
      sharedStepAdapter.removeAll(state);
    },
    sharedStepUpsertMany(
      state,
      action: PayloadAction<SharedStep[] | Record<number, SharedStep>>,
    ) {
      sharedStepAdapter.upsertMany(state, action.payload);
    },
    sharedStepUpsertOne(state, action: PayloadAction<SharedStep>) {
      sharedStepAdapter.upsertOne(state, action.payload);
    },
    sharedStepUpdateOne(state, action: PayloadAction<Update<SharedStep>>) {
      sharedStepAdapter.updateOne(state, action.payload);
    },
    sharedStepRemoveOne(state, action: PayloadAction<number>) {
      sharedStepAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedStepFetchManyThunk.pending, handlePending);
    builder.addCase(sharedStepFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      sharedStepAdapter.upsertMany(state, action.payload.sharedStep);
    });
    builder.addCase(sharedStepFetchManyThunk.rejected, handleRejected);
    builder.addCase(sharedStepFetchOneThunk.pending, handlePending);
    builder.addCase(sharedStepFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      sharedStepAdapter.upsertOne(state, action.payload.sharedStep[0]);
    });
    builder.addCase(sharedStepFetchOneThunk.rejected, handleRejected);
    builder.addCase(sharedStepDeleteOneThunk.pending, handlePending);
    builder.addCase(sharedStepDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        sharedStepAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(sharedStepDeleteOneThunk.rejected, handleRejected);
    builder.addCase(sharedStepUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, sharedStepAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(sharedStepUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      sharedStepAdapter.updateOne(state, action.payload);
    });
    builder.addCase(sharedStepUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          sharedStepAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(sharedStepCreateOneThunk.pending, handlePending);
    builder.addCase(sharedStepCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      sharedStepAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(sharedStepCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  sharedStepCleared,
  sharedStepUpsertMany,
  sharedStepUpsertOne,
  sharedStepUpdateOne,
  sharedStepRemoveOne,
} = sharedStepSlice.actions;

export const sharedStepReducer = sharedStepSlice.reducer;
