import React, { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './MenuItem.module.scss';

type Props = ComponentProps<'li'> & {
  children: ReactNode;
  color?: 'primaryDark' | 'primaryMain';
  size?: 'small' | 'medium';
  startAdornment?: ReactNode;
};

export default function MenuItem({
  children,
  className = '',
  color = 'primaryMain',
  onClick,
  size = 'medium',
  startAdornment,
  ...rest
}: Props) {
  const menuItemCN = classnames(styles.menuItem, className, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.interactive]: typeof onClick === 'function',
    [styles.primaryDark]: color === 'primaryDark',
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={menuItemCN} onClick={onClick} {...rest}>
      {startAdornment && (
        <div className={styles.startAdornment}>{startAdornment}</div>
      )}
      <div>{children}</div>
    </li>
  );
}
