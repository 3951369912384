export enum RunFilter {
  All = 'all',
  Running = 'running',
  Completed = 'completed',
  Paused = 'paused',
}

type RunFilterKeys = `runFilter.${RunFilter}`;

export const RunFilterDisplay: ReadonlyMap<RunFilter, RunFilterKeys> = new Map([
  [RunFilter.All, 'runFilter.all'],
  [RunFilter.Running, 'runFilter.running'],
  [RunFilter.Completed, 'runFilter.completed'],
  [RunFilter.Paused, 'runFilter.paused'],
]);
