/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { AppInstallProject } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appInstallProjectAdapter } from './appInstallProjectAdapter';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
  appInstallProjectDeleteOneThunk,
  appInstallProjectUpdateOneThunk,
  appInstallProjectCreateOneThunk,
} from './appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appInstallProjectSlice = createSlice({
  name: 'appInstallProject',
  initialState: appInstallProjectAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appInstallProjectCleared(state) {
      state.loading = 'idle';
      appInstallProjectAdapter.removeAll(state);
    },
    appInstallProjectUpsertMany(
      state,
      action: PayloadAction<
        AppInstallProject[] | Record<number, AppInstallProject>
      >,
    ) {
      appInstallProjectAdapter.upsertMany(state, action.payload);
    },
    appInstallProjectUpsertOne(
      state,
      action: PayloadAction<AppInstallProject>,
    ) {
      appInstallProjectAdapter.upsertOne(state, action.payload);
    },
    appInstallProjectUpdateOne(
      state,
      action: PayloadAction<Update<AppInstallProject>>,
    ) {
      appInstallProjectAdapter.updateOne(state, action.payload);
    },
    appInstallProjectRemoveOne(state, action: PayloadAction<number>) {
      appInstallProjectAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appInstallProjectFetchManyThunk.pending, handlePending);
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      },
    );
    builder.addCase(appInstallProjectFetchManyThunk.rejected, handleRejected);
    builder.addCase(appInstallProjectFetchOneThunk.pending, handlePending);
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        appInstallProjectAdapter.upsertOne(
          state,
          action.payload.appInstallProject[0],
        );
      },
    );
    builder.addCase(appInstallProjectFetchOneThunk.rejected, handleRejected);
    builder.addCase(appInstallProjectDeleteOneThunk.pending, handlePending);
    builder.addCase(
      appInstallProjectDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          appInstallProjectAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(appInstallProjectDeleteOneThunk.rejected, handleRejected);
    builder.addCase(
      appInstallProjectUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, appInstallProjectAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      appInstallProjectUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        appInstallProjectAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      appInstallProjectUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            appInstallProjectAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(appInstallProjectCreateOneThunk.pending, handlePending);
    builder.addCase(
      appInstallProjectCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        appInstallProjectAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(appInstallProjectCreateOneThunk.rejected, handleRejected);
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.appInstallProject) {
          appInstallProjectAdapter.upsertMany(
            state,
            action.payload.appInstallProject,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.appInstallProject) {
        appInstallProjectAdapter.upsertMany(
          state,
          action.payload.appInstallProject,
        );
      }
    });
  },
});

export const {
  appInstallProjectCleared,
  appInstallProjectUpsertMany,
  appInstallProjectUpsertOne,
  appInstallProjectUpdateOne,
  appInstallProjectRemoveOne,
} = appInstallProjectSlice.actions;

export const appInstallProjectReducer = appInstallProjectSlice.reducer;
