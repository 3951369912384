import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Resizable } from 're-resizable';
import classNames from 'classnames';
import { getSize, setSize } from '@bitmodern/services/ResizingService';
import { useQuery } from 'src/hooks/useQuery';
import Test from 'src/components/organisms/Test';
import RequirementViewContainer from 'src/components/views/RequirementViewDrawer';
import useDrawerManager from 'src/hooks/useDrawerManager';
import RunResultViewContainer from 'src/components/views/RunResultView';
import { useAppSelector } from '@bitmodern/redux/store';
import { defectSelectors } from 'src/gen/domain/defect/defectSelector';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import { requirementSelectors } from 'src/gen/domain/requirement/requirementSelector';
import RunOverviewDrawerViewContainer from 'src/components/views/RunOverviewDrawerView/RunOverviewDrawerViewContainer';
import styles from './Drawer.module.scss';
import { testSelectors } from '../../../gen/domain/test/testSelector';
import DefectView from 'src/components/views/DefectView';

const MAX_WIDTH = '100%';
const MIN_WIDTH = 630;
const STORAGE_KEY = 'drawerWidth';

export function Drawer() {
  const { expand, setIsExpanded, setIsOpen, setWidth, width } =
    useDrawerManager({
      initialWidth: () => ({
        max: MAX_WIDTH,
        min: MIN_WIDTH,
        value: getSize(STORAGE_KEY) || undefined,
      }),
    });
  const [isResizing, setIsResizing] = useState(false);

  const tests = useAppSelector(testSelectors.selectAll);
  const runResults = useAppSelector(runResultSelectors.selectAll);
  const defects = useAppSelector(defectSelectors.selectAll);
  const requirements = useAppSelector(requirementSelectors.selectAll);
  const { testId, resultId, defectId, requirementId, runId } = useQuery([
    'testId',
    'folderId',
    'defectId',
    'requirementId',
    'resultId',
    'runId',
  ]);

  useEffect(() => {
    setIsOpen(true);
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const onResizeStop = useCallback(
    (event, direction, elementRef) => {
      setIsResizing(false);
      const nextWidth = elementRef.offsetWidth;
      if (nextWidth >= window.innerWidth) {
        expand();
      } else {
        setIsExpanded(false);
        setWidth(nextWidth);
      }
      setSize(STORAGE_KEY, nextWidth);
    },
    [expand, setIsExpanded, setWidth, setIsResizing],
  );

  const onResizeStart = () => {
    setIsResizing(true);
  };

  let component: ReactNode = null;
  if (tests.length && testId) {
    component = <Test testId={parseInt(testId, 10)} />;
  } else if (defects.length && defectId) {
    component = <DefectView defectId={parseInt(defectId, 10)} />;
  } else if (runResults.length && resultId) {
    component = <RunResultViewContainer runResultId={parseInt(resultId, 10)} />;
  } else if (runId) {
    component = <RunOverviewDrawerViewContainer runId={parseInt(runId, 10)} />;
  } else if (requirements.length && requirementId) {
    component = (
      <RequirementViewContainer requirementId={parseInt(requirementId, 10)} />
    );
  }

  return (
    <AnimatePresence>
      {component && (
        <Resizable
          as={motion.div}
          // @ts-expect-error
          animate="visible"
          initial="hidden"
          exit="hidden"
          variants={{
            visible: {
              opacity: 1,
              x: 0,
            },
            hidden: {
              opacity: 0,
              x: 70,
            },
          }}
          bounds="window"
          boundsByDirection
          className={classNames(styles.drawer, {
            [styles.resizing]: isResizing,
          })}
          enable={{ left: true }}
          onResizeStart={onResizeStart}
          size={{ height: 'auto', width }}
          maxWidth={MAX_WIDTH}
          minWidth={MIN_WIDTH}
          onResizeStop={onResizeStop}>
          {component}
          <div className={styles.shadow} />
        </Resizable>
      )}
    </AnimatePresence>
  );
}
