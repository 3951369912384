import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useQuery } from 'src/hooks/useQuery';
import { userUpdateOneThunk } from 'src/gen/domain/user/userThunk';
import { isCurrentUserAdminSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { GithubStep, GithubStepInfo } from './components/GithubStep';
import { JiraStep, JiraStepInfo } from './components/JiraStep';
import { InvitesStep, InvitesStepInfo } from './components/InvitesStep';
import {
  ProjectAndThemeStep,
  ProjectAndThemeStepInfo,
} from './components/ProjectAndThemeStep';
import styles from './GetStartedView.module.scss';

const motionVariants: Variants = {
  enter: {
    transition: {
      staggerChildren: 0.07,
      duration: 0.3,
    },
  },
  leave: {
    transition: { duration: 0.2 },
  },
};

const allSteps = [
  {
    name: 'GithubStep',
    component: GithubStep,
    infoComponent: GithubStepInfo,
    skip: true,
    onlyAdmin: true,
  },
  {
    name: 'JiraStep',
    component: JiraStep,
    infoComponent: JiraStepInfo,
    skip: true,
    onlyAdmin: true,
    omit: true,
  },
  {
    name: 'InvitesStep',
    component: InvitesStep,
    infoComponent: InvitesStepInfo,
    skip: true,
    onlyAdmin: false,
  },
  {
    name: 'ProjectAndThemetStep',
    component: ProjectAndThemeStep,
    infoComponent: ProjectAndThemeStepInfo,
    onlyAdmin: false,
  },
];

const GITHUB_SUCCESS = 'github-step-success';
const JIRA_SUCCESS = 'jira-step-success';

export default function GetStartedView() {
  const { site } = useParams<typeof routes.GET_STARTED.params>();
  const history = useHistory();
  const location = useLocation<{ step?: number }>();
  const dispatch = useAppDispatch();
  const user = useAppSelector(currentUserSelector);
  const isUserAdmin = useAppSelector(isCurrentUserAdminSelector);

  const currentStep = location.state?.step || 0;

  const {
    'github-step-success': isGithubStepSuccess,
    'jira-step-success': isJiraStepSuccess,
  } = useQuery([GITHUB_SUCCESS, JIRA_SUCCESS]);

  const steps = useMemo(() => {
    let steps = allSteps;

    if (!isUserAdmin) {
      steps = steps.filter((step) => !step.onlyAdmin);
    }

    steps = steps.filter((step) => !step.omit);

    return steps;
  }, [isUserAdmin]);

  const isLastStep = currentStep >= steps.length - 1;

  const setCurrentStep = useCallback(
    (step: number) => history.push({ state: { step } }),
    [history],
  );

  useEffect(() => {
    if (isGithubStepSuccess) {
      history.replace({
        search: `?${GITHUB_SUCCESS}=true`,
        state: {
          step: steps.findIndex((step) => step.name === 'GithubStep'),
        },
      });
    }
    if (isJiraStepSuccess) {
      history.replace({
        search: `?${JIRA_SUCCESS}=true`,
        state: {
          step: steps.findIndex((step) => step.name === 'JiraStep'),
        },
      });
    }
  }, [history, steps, isGithubStepSuccess, isJiraStepSuccess]);

  const completeUserIntro = useCallback(() => {
    dispatch(
      userUpdateOneThunk({
        id: user?.id,
        data: {
          settings: {
            ...user?.settings,
            has_completed_intro: true,
          },
        },
      }),
    ).then(() => history.push(routes.PROJECT_EMPTY({ site })));
  }, [dispatch, history, user?.id, user?.settings, site]);

  const onNextStep = useCallback(() => {
    if (isLastStep) {
      completeUserIntro();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [setCurrentStep, currentStep, isLastStep, completeUserIntro]);

  const step = steps[currentStep];
  const Step = step?.component;
  const StepInfoPanel = step?.infoComponent;

  if (!Step) return <div>No step</div>;

  return (
    <div className={styles.main}>
      <AnimatePresence>
        <motion.div
          className={styles.leftContainer}
          variants={motionVariants}
          initial="appear"
          animate="enter"
          exit="leave">
          <Step
            currentStep={currentStep + 1}
            onNextStep={onNextStep}
            stepTotal={steps.length}
          />
        </motion.div>
      </AnimatePresence>
      <div className={styles.rightContainer}>
        <StepInfoPanel />
      </div>
    </div>
  );
}
