import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { appVersionSelectors } from 'src/gen/domain/app_version/appVersionSelector';
import { appVersionPlatVersionSelectors } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionSelector';
import { platVersionSelectors } from 'src/gen/domain/plat_version/platVersionSelector';
import { platSelectors } from 'src/gen/domain/plat/platSelector';
import {
  App,
  AppVersion,
  AppVersionPlatVersion,
  Plat,
  PlatVersion,
} from '@testquality/sdk';
import { getTreeFromArray } from 'src/utils/tree';
import { appVersionPlatVersionPlansSelectorByPlan } from '../appVersionPlatVersionPlans/selectors';

const DUMMY_ROOT_KEY = 'dummy';

/* eslint-disable @typescript-eslint/no-shadow */
export enum ConfigurationTreeType {
  App = 'app',
  AppVersion = 'appVersion',
  Platform = 'plat',
  PlatVersion = 'platVersion',
}
/* eslint-enable @typescript-eslint/no-shadow */

function idToTreeId(
  id: string | number,
  entity:
    | 'plat'
    | 'platVersion'
    | 'app'
    | 'appVersion'
    | 'appVersionPlatVersion',
) {
  const treeId = {
    plat: `P-${id}`,
    platVersion: `$PV-${id}`,
    app: `A-${id}`,
    appVersion: `AV-${id}`,
    appVersionPlatVersion: `AVPV-${id}`,
  }[entity];

  return treeId || DUMMY_ROOT_KEY;
}

type AppItem =
  | { app: App; appVersionPlatVersion: AppVersionPlatVersion }
  | {
      appVersion: AppVersion;
      appVersionPlatVersion: AppVersionPlatVersion;
    };

export const appVersionPlatVersionsTreeSelector = createSelector(
  [
    appSelectors.selectEntities,
    appVersionSelectors.selectEntities,
    platSelectors.selectAll,
    platVersionSelectors.selectAll,
    appVersionPlatVersionSelectors.selectAll,
    platSelectors.selectEntities,
  ],
  (
    appEntities,
    appVersionsEntities,
    platforms,
    platformVersions,
    appVersionPlatVersions,
    platformEntities,
  ) => {
    const platformsSorterd = [...platforms].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    const getAppId = (avpv: AppVersionPlatVersion) => {
      const appVersion = appVersionsEntities[avpv.app_version_id];
      const appId = appEntities[appVersion?.app_id || '']?.id;
      return `${avpv.plat_version_id}-${appId}`;
    };

    const appVersions = appVersionPlatVersions.reduce<AppItem[]>(
      (acu, avpv) => {
        const appVersion = appVersionsEntities[avpv.app_version_id];
        const app = appEntities[appVersion?.app_id || ''];
        if (!app || !appVersion) return acu;
        acu.push({ appVersion, appVersionPlatVersion: avpv });
        return acu;
      },
      [],
    );

    const apps = Object.values(
      appVersionPlatVersions.reduce<{}>((acu, avpv) => {
        const appVersion = appVersionsEntities[avpv.app_version_id];
        const app = appEntities[appVersion?.app_id || ''];
        if (!app || !appVersion) return acu;
        const appId = getAppId(avpv);
        acu[appId] = { app, appVersionPlatVersion: avpv };
        return acu;
      }, {}),
    );

    const flatData = [
      ...platformsSorterd,
      ...platformVersions,
      ...apps,
      ...appVersions,
    ];

    const tree = getTreeFromArray({
      flatData,
      rootKey: DUMMY_ROOT_KEY,
      getKey: (item) => {
        const { appVersion, app, appVersionPlatVersion } = item;
        if (appVersion) return idToTreeId(appVersion.id, 'appVersion');
        if (app) {
          return idToTreeId(
            getAppId(appVersionPlatVersion),
            'appVersionPlatVersion',
          );
        }
        if (item.metadata_model === 'PlatVersion')
          return idToTreeId(item.id, 'platVersion');
        if (item.metadata_model === 'Plat') return idToTreeId(item.id, 'plat');
        return DUMMY_ROOT_KEY;
      },
      getParentKey: (item) => {
        const { appVersion, app, appVersionPlatVersion } = item;
        if (appVersion)
          return idToTreeId(
            getAppId(appVersionPlatVersion),
            'appVersionPlatVersion',
          );
        if (app)
          return idToTreeId(
            appVersionPlatVersion.plat_version_id,
            'platVersion',
          );
        if (item.metadata_model === 'PlatVersion')
          return idToTreeId(item.plat_id, 'plat');
        if (item.metadata_model === 'Plat') return DUMMY_ROOT_KEY;
        return DUMMY_ROOT_KEY;
      },
      transform: (item) => {
        let title = '';
        let type;

        if (item.appVersion) {
          const { appVersion } = item;
          title = formatAppName({
            app: appEntities[appVersion?.app_id],
            appVersion,
          });
          type = ConfigurationTreeType.AppVersion;
        }
        if (item.app) {
          const { app } = item;
          title = formatAppName({ app });
          type = ConfigurationTreeType.App;
        }
        if (item.metadata_model === 'PlatVersion') {
          type = ConfigurationTreeType.PlatVersion;
          const plat = platformEntities[item.plat_id];
          title = formatPlatName({ plat, platVersion: item });
        }
        if (item.metadata_model === 'Plat') {
          type = ConfigurationTreeType.Platform;
          title = item.name;
        }

        return { type, data: item, title };
      },
    });

    return tree;
  },
);

export function appVersionPlatVersionsByPlanSelector(
  state: State,
  planId?: number,
) {
  return appVersionPlatVersionPlansSelectorByPlan(state, { planId }).reduce<
    AppVersionPlatVersion[]
  >((acu, item) => {
    const avpv = appVersionPlatVersionSelectors.selectById(
      state,
      item.app_version_plat_version_id || 0,
    );
    if (avpv) acu.push(avpv);
    return acu;
  }, []);
}

export function formatAppName({
  app,
  appVersion,
}: {
  app?: App;
  appVersion?: AppVersion;
}) {
  if (!app && !appVersion) return '';

  const name = app?.name || '';

  let formated = name;

  if (appVersion) {
    const major = appVersion.major || '';
    const minor = appVersion.minor || '';
    formated += ` (${major} ${minor})`;
  }

  return formated.trim();
}

export function formatPlatName({
  plat,
  platVersion,
}: {
  plat?: Plat;
  platVersion?: PlatVersion;
}) {
  if (!plat && !platVersion) return '';

  const name = plat?.name || '';
  const major = platVersion?.major || '';
  const minor = platVersion?.minor || '';
  return `${name} (${major} ${minor})`;
}

type FormatParms = Parameters<typeof formatAppName>[0] &
  Parameters<typeof formatPlatName>[0];
export function formatAppVersionPlatVersion({
  app,
  appVersion,
  plat,
  platVersion,
}: FormatParms) {
  const appFormated = formatAppName({ app, appVersion });
  const platFormated = formatPlatName({ plat, platVersion });
  return `${appFormated} - ${platFormated}`;
}

export function configurationFormatedSelector(
  state: State,
  appVersionPlatVersionId?: number,
): string {
  if (!appVersionPlatVersionId) return '';
  const avpv = appVersionPlatVersionSelectors.selectById(
    state,
    appVersionPlatVersionId,
  );
  if (!avpv) return '';
  const appVersion = appVersionSelectors.selectById(state, avpv.app_version_id);
  const app = appSelectors.selectById(state, appVersion?.app_id || 0);
  const platVersion = platVersionSelectors.selectById(
    state,
    avpv.plat_version_id,
  );
  const plat = platSelectors.selectById(state, platVersion?.plat_id || 0);
  return formatAppVersionPlatVersion({ app, appVersion, plat, platVersion });
}

export function appVersionPlatVersionByPlan(
  state: State,
  { planId }: { planId?: number },
) {
  return appVersionPlatVersionPlansSelectorByPlan(state, {
    planId,
  }).reduce<AppVersionPlatVersion[]>((acu, avpvp) => {
    const avpvId = avpvp.app_version_plat_version_id;
    if (!avpvId) return acu;
    const avpv = appVersionPlatVersionSelectors.selectById(state, avpvId);
    if (avpv) acu.push(avpv);
    return acu;
  }, []);
}
