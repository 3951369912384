/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { RunResultStepHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { runResultStepHistoryAdapter } from './runResultStepHistoryAdapter';
import { runResultStepHistoryFetchThunk } from './runResultStepHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const runResultStepHistorySlice = createSlice({
  name: 'runResultStepHistory',
  initialState: runResultStepHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    runResultStepHistoryCleared(state) {
      state.loading = 'idle';
      runResultStepHistoryAdapter.removeAll(state);
    },
    runResultStepHistoryUpsertMany(
      state,
      action: PayloadAction<
        RunResultStepHistory[] | Record<number, RunResultStepHistory>
      >,
    ) {
      runResultStepHistoryAdapter.upsertMany(state, action.payload);
    },
    runResultStepHistoryUpsertOne(
      state,
      action: PayloadAction<RunResultStepHistory>,
    ) {
      runResultStepHistoryAdapter.upsertOne(state, action.payload);
    },
    runResultStepHistoryUpdateOne(
      state,
      action: PayloadAction<Update<RunResultStepHistory>>,
    ) {
      runResultStepHistoryAdapter.updateOne(state, action.payload);
    },
    runResultStepHistoryRemoveOne(state, action: PayloadAction<number>) {
      runResultStepHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(runResultStepHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      runResultStepHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        runResultStepHistoryAdapter.upsertMany(
          state,
          action.payload.runResultStepHistory,
        );
      },
    );
    builder.addCase(runResultStepHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  runResultStepHistoryCleared,
  runResultStepHistoryUpsertMany,
  runResultStepHistoryUpsertOne,
  runResultStepHistoryUpdateOne,
  runResultStepHistoryRemoveOne,
} = runResultStepHistorySlice.actions;

export const runResultStepHistoryReducer = runResultStepHistorySlice.reducer;
