/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectLabelAdapter } from './defectLabelAdapter';

export const defectLabelSelectors = defectLabelAdapter.getSelectors(
  (state: State) => state.gen.defectLabel,
);
