import React, {
  forwardRef,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import { FormikProps } from 'formik';
import {
  Button,
  Checkbox,
  Content,
  Dialog,
  DialogContent,
  EasyMDEEditor,
  Input,
  PanelActions,
  SelectMultiple,
} from '@bitmodern/bit-ui';
import { StepsForm, VirtualInputs } from 'src/components/organisms';
import { Attachment, Suite, Step } from '@testquality/sdk';
import useModalManager from 'src/hooks/useModalManager';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import { NoteAddIcon } from '@bitmodern/bit-ui/icons';
import { suitesFlatTreeSelector } from '@bitmodern/redux/state/suites/selectors';
import { VirtualSchema } from '@bitmodern/redux/state/virtuals/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import vars from 'src/export.scss';
import styles from './TestCreate.module.scss';

type Props = {
  formik: FormikProps<{
    name: string;
    precondition: string;
    steps: Step[];
    suites: number[];
    attachments: Attachment[];
    createAnother: boolean;
  }>;
  suites: Suite[];
  virtuals: { [key: string]: VirtualSchema };
};

function TestCreateView({ formik, suites, virtuals }: Props, ref) {
  const { hideModal, isModal } = useModalManager();
  const nameRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const projectId = suites[0]?.project_id;
  const suitesTree = useAppSelector((state) =>
    suitesFlatTreeSelector(state, projectId),
  );

  useImperativeHandle(ref, () => ({
    name: {
      focus: () => {
        if (nameRef.current) {
          nameRef.current.focus();
        }
      },
    },
  }));

  const renderLabel = useCallback(
    (item) => {
      const { suite } = item.data;
      const suiteName = suite.is_root ? t('test.rootFolder') : suite.name;
      return (
        <div style={{ marginLeft: item.nestingLevel * 16 }}>{suiteName}</div>
      );
    },
    [t],
  );

  const onChagePrecondition = useCallback(
    (value) => {
      formik.setFieldValue('precondition', value);
    },
    [formik],
  );

  const onChangeSuite = (values) => {
    formik.setFieldValue('suites', values);
  };

  const onChangeCreateAnother = (value) => {
    formik.setFieldValue('createAnother', value);
  };

  const onChangeStep = (value: Step) => {
    const nextSteps = formik.values.steps.map((step) => {
      if (step.id !== value.id) return step;
      return {
        ...step,
        step: value.step,
        expected_result: value.expected_result,
      };
    });
    formik.setFieldValue('steps', nextSteps);
  };

  const onAddStep = async () => {
    formik.setFieldValue('steps', [
      ...formik.values.steps,
      {
        id: formik.values.steps.length + 1,
        step: '',
        expected_result: '',
        sequence: formik.values.steps.length + 1,
      },
    ]);
  };

  const onDeleteStep = async (step) => {
    let stepDeleted: boolean = false;
    const newSteps: Step[] = formik.values.steps.reduce(
      (acu: Step[], item: Step, index: number) => {
        if (item.id !== step.id) {
          const seqAndId: number = stepDeleted ? index : index + 1;
          const newItem: Step = { ...item, id: seqAndId, sequence: seqAndId };
          return acu.concat(newItem);
        }
        stepDeleted = true;
        return acu;
      },
      [],
    );
    formik.setFieldValue('steps', newSteps);
  };

  const onCloneStep = (step: Step) => {
    formik.setFieldValue('steps', [
      ...formik.values.steps,
      {
        ...step,
        id: formik.values.steps.length + 1,
        sequence: formik.values.steps.length + 1,
      },
    ]);
  };

  const onFileUpload = (attachment: Attachment) => {
    formik.setFieldValue('attachments', [
      ...formik.values.attachments,
      attachment,
    ]);
  };

  const onChangeOrder = (steps: Step[]) => {
    const newSteps = steps.map((step, i) => ({
      ...step,
      sequence: i + 1,
    }));
    formik.setFieldValue('steps', newSteps);
  };

  const suiteOptions = suitesTree.map((item) => ({
    value: item.data.suite.id,
    label: renderLabel(item),
  }));

  const content = (
    <>
      <DialogContent className={styles.panelContent}>
        <form
          id="test-create-form"
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}>
          <Input
            autoComplete="off"
            error={formikError(formik, 'name')}
            fullWidth
            label={t('testCreate.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            ref={nameRef}
            value={formik.values.name}
            autoFocus
          />

          <SelectMultiple
            label={t('testCreate.form.suites')}
            placeholder={t('testCreate.form.suitesPlaceholder')}
            onChange={onChangeSuite}
            onFocus={formik.handleBlur}
            options={suiteOptions}
            name="suites"
            values={formik.values.suites}
          />

          <div>
            <div className={styles.label}>
              {t('testCreate.form.precondition')}
            </div>
            <EasyMDEEditor
              onChange={onChagePrecondition}
              onFileUpload={onFileUpload}
              parentId={projectId}
              parentType="Project"
              value={formik.values.precondition}
            />
          </div>
          <StepsForm
            className={styles.stepsContainer}
            onAddStep={onAddStep}
            onClone={onCloneStep}
            onChangeStep={onChangeStep}
            onChangeOrder={onChangeOrder}
            onDelete={onDeleteStep}
            onFileUpload={onFileUpload}
            parentId={projectId}
            parentType="Project"
            steps={formik.values.steps}
          />
          <VirtualInputs formik={formik} virtuals={virtuals} />
        </form>
      </DialogContent>
      <PanelActions className={styles.panelActions}>
        <Checkbox
          className={styles.createAnother}
          checked={formik.values.createAnother}
          onChange={onChangeCreateAnother}>
          {t('testCreate.form.createAnother')}
        </Checkbox>
        <Button
          disabled={!formik.isValid}
          loading={formik.isSubmitting}
          form="test-create-form"
          type="submit">
          {t('testCreate.form.submit')}
        </Button>
      </PanelActions>
    </>
  );

  if (!isModal) {
    return (
      <Content className={styles.content} fullHeight>
        {content}
      </Content>
    );
  }

  return (
    <Dialog
      animatePresence={false}
      title={
        <div>
          <NoteAddIcon
            color={vars.textPrimary}
            className={styles.titleIcon}
            size={18}
          />
          {t('testCreate.title')}
        </div>
      }
      isDismissable={false}
      onClose={hideModal}
      size="xlarge"
      isOpen>
      {content}
    </Dialog>
  );
}

export default forwardRef<{ name: { focus: () => void } }, Props>(
  TestCreateView,
);
