/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CaseTypeHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { caseTypeHistoryAdapter } from './caseTypeHistoryAdapter';
import { caseTypeHistoryFetchThunk } from './caseTypeHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const caseTypeHistorySlice = createSlice({
  name: 'caseTypeHistory',
  initialState: caseTypeHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    caseTypeHistoryCleared(state) {
      state.loading = 'idle';
      caseTypeHistoryAdapter.removeAll(state);
    },
    caseTypeHistoryUpsertMany(
      state,
      action: PayloadAction<
        CaseTypeHistory[] | Record<number, CaseTypeHistory>
      >,
    ) {
      caseTypeHistoryAdapter.upsertMany(state, action.payload);
    },
    caseTypeHistoryUpsertOne(state, action: PayloadAction<CaseTypeHistory>) {
      caseTypeHistoryAdapter.upsertOne(state, action.payload);
    },
    caseTypeHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CaseTypeHistory>>,
    ) {
      caseTypeHistoryAdapter.updateOne(state, action.payload);
    },
    caseTypeHistoryRemoveOne(state, action: PayloadAction<number>) {
      caseTypeHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(caseTypeHistoryFetchThunk.pending, handlePending);
    builder.addCase(caseTypeHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      caseTypeHistoryAdapter.upsertMany(state, action.payload.caseTypeHistory);
    });
    builder.addCase(caseTypeHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  caseTypeHistoryCleared,
  caseTypeHistoryUpsertMany,
  caseTypeHistoryUpsertOne,
  caseTypeHistoryUpdateOne,
  caseTypeHistoryRemoveOne,
} = caseTypeHistorySlice.actions;

export const caseTypeHistoryReducer = caseTypeHistorySlice.reducer;
