import React from 'react';
import { Table, TableBody, TableCell, TableHead } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { RunResult } from '@testquality/sdk';
import RunResultHistoryItem from '../RunResultHistoryItem';
import styles from './RunResultHistory.module.scss';

type Props = {
  runResults: RunResult[];
  withRunResultName?: boolean;
  defectId?: number;
};

export default function RunResultHistory({
  runResults,
  defectId,
  withRunResultName = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <Table height="100%">
      <TableHead className={styles.head}>
        <TableCell>{t('runResultHistory.head.run')}</TableCell>
        {withRunResultName && (
          <TableCell>{t('runResultHistory.head.runResult')}</TableCell>
        )}
        <TableCell>{t('runResultHistory.head.status')}</TableCell>
        <TableCell>{t('runResultHistory.head.assigned')}</TableCell>
        <TableCell>{t('runResultHistory.head.resultDate')}</TableCell>
      </TableHead>
      <TableBody>
        {runResults.map((result) => (
          <RunResultHistoryItem
            defectId={defectId}
            key={result.id}
            runResult={result}
            withRunResultName={withRunResultName}
          />
        ))}
      </TableBody>
    </Table>
  );
}
