import config from '../public/config.json';
import Debug from 'debug';
import { APIWorker } from '@bitmodern/workers';
import { AuthCallback, ClientSdk, setGlobalClient } from '@testquality/sdk';
import { setEnv, getEnv } from './env';
import { TokenStorageImpl } from './common/TokenStorageImpl';

setEnv(config as any);

/**
 * Copyright (C) 2020 BitModern, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const debug = Debug('Client');
const enableAPIWorker = Worker && localStorage.enableAPIWorker === 'true';
const options = {
  baseUrl: getEnv().api,
  clientId: '2',
  clientSecret: '93MBS86X7JrK4Mrr1mk4PKfo6b1zRVx9Mrmx0nTa',
  debug: getEnv().xDebug,
};
const tokenStorage = new TokenStorageImpl();

let client: ClientSdk;

debug('init', { options, enableAPIWorker });

export function getApiBaseUrl() {
  return options.baseUrl;
}

export function getClient(): ClientSdk {
  return client;
}

export function setClient(authCallback?: AuthCallback): ClientSdk {
  debug('setClient');
  if (client) {
    return client;
  }
  if (enableAPIWorker) {
    tokenStorage.getToken().then((token) => {
      debug('new APIWorker');
      client.setAPIWorker(new APIWorker(options, token));
    });
  }
  debug('new ClientSdk');
  client = new ClientSdk({ ...options, authCallback, tokenStorage });
  setGlobalClient(client);
  return client;
}
