export enum HotkeysEnum {
  CollapseAllTreeItems = 'collapseAllTreeItems',
  DeselectAllTreeItems = 'deselectAllTreeItems',
  ExpandAllTreeItems = 'expandAllTreeItems',
  SelectAllTreeItems = 'selectAllTreeItems',
  HotkeyList = 'hotkeyList',
  CommandBar = 'commandBar',
}

/**
 * In order to handle different platform with ease. Espcial modifiers
 * have been created.
 *
 * $cmd - Command key in mac / control in other platforms
 */
export const keyMap: { [key in HotkeysEnum]: string[] } = {
  [HotkeysEnum.CollapseAllTreeItems]: ['$cmd', 'h'],
  [HotkeysEnum.DeselectAllTreeItems]: ['esc'],
  [HotkeysEnum.ExpandAllTreeItems]: ['$cmd', 'j'],
  [HotkeysEnum.SelectAllTreeItems]: ['$cmd', 'a'],
  [HotkeysEnum.HotkeyList]: ['$cmd', '/'],
  [HotkeysEnum.CommandBar]: ['$cmd', 'k'],
};
