/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  defectStatusNativeDefectStatusDetachThunk,
  defectStatusNativeDefectStatusFetchManyThunk,
  defectStatusNativeDefectStatusFetchOneThunk,
  defectStatusNativeDefectStatusUpdateOneThunk,
  defectStatusNativeDefectStatusCreateOneThunk,
} from './defectStatusNativeDefectStatusThunk';
import { TQStateProperties } from '../../store/TQState';
import { defectStatusNativeDefectStatusAdapter } from './defectStatusNativeDefectStatusAdapter';
import {
  defectStatusDeleteOneThunk,
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
} from '../defect_status/defectStatusThunk';
import {
  nativeDefectStatusDeleteOneThunk,
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import { defectStatusRemoveOne } from '../defect_status/defectStatusSlice';
import { nativeDefectStatusRemoveOne } from '../native_defect_status/nativeDefectStatusSlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectStatusNativeDefectStatusSlice = createSlice({
  name: 'defectStatusNativeDefectStatus',
  initialState:
    defectStatusNativeDefectStatusAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    defectStatusNativeDefectStatusCleared:
      defectStatusNativeDefectStatusAdapter.removeAll,
    defectStatusNativeDefectStatusUpsertMany:
      defectStatusNativeDefectStatusAdapter.upsertMany,
    defectStatusNativeDefectStatusUpsertOne:
      defectStatusNativeDefectStatusAdapter.upsertOne,
    defectStatusNativeDefectStatusUpdateOne:
      defectStatusNativeDefectStatusAdapter.updateOne,
    defectStatusNativeDefectStatusRemoveOne:
      defectStatusNativeDefectStatusAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      defectStatusNativeDefectStatusDetachThunk.pending,
      handlePending,
    );
    builder.addCase(
      defectStatusNativeDefectStatusDetachThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload && action.payload.id) {
          defectStatusNativeDefectStatusAdapter.removeOne(
            state,
            action.payload.id,
          );
        }
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusDetachThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchManyThunk.pending,
      handlePending,
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchManyThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchOneThunk.pending,
      handlePending,
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectStatusNativeDefectStatusAdapter.upsertOne(
          state,
          action.payload.defectStatusNativeDefectStatus[0],
        );
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusFetchOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(
      defectStatusNativeDefectStatusUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(
            state,
            defectStatusNativeDefectStatusAdapter,
            {
              id: action.meta.arg.id?.toString(),
              changes: action.meta.arg.data,
            },
          );
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectStatusNativeDefectStatusAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            defectStatusNativeDefectStatusAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusCreateOneThunk.pending,
      handlePending,
    );
    builder.addCase(
      defectStatusNativeDefectStatusCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectStatusNativeDefectStatusAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(
      defectStatusNativeDefectStatusCreateOneThunk.rejected,
      handleRejected,
    );
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.defectStatusNativeDefectStatus) {
          defectStatusNativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.defectStatusNativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatusNativeDefectStatus) {
          defectStatusNativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.defectStatusNativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.defectStatusNativeDefectStatus) {
          defectStatusNativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.defectStatusNativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatusNativeDefectStatus) {
          defectStatusNativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.defectStatusNativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.defectStatusNativeDefectStatus) {
          defectStatusNativeDefectStatusAdapter.upsertMany(
            state,
            action.payload.defectStatusNativeDefectStatus,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatusNativeDefectStatus) {
        defectStatusNativeDefectStatusAdapter.upsertMany(
          state,
          action.payload.defectStatusNativeDefectStatus,
        );
      }
    });

    builder.addCase(defectStatusDeleteOneThunk.fulfilled, (state, action) => {
      defectStatusNativeDefectStatusAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined &&
              value.defect_status_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(defectStatusRemoveOne, (state, action) => {
      defectStatusNativeDefectStatusAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.defect_status_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(
      nativeDefectStatusDeleteOneThunk.fulfilled,
      (state, action) => {
        defectStatusNativeDefectStatusAdapter.removeMany(
          state,
          values(state.entities)
            .filter(
              (value) =>
                value !== undefined &&
                value.native_defect_status_id === action.payload.id,
            )
            .map<number | undefined>((m) => (m ? m.id : undefined))
            .filter((f) => f !== undefined) as number[],
        );
      },
    );
    builder.addCase(nativeDefectStatusRemoveOne, (state, action) => {
      defectStatusNativeDefectStatusAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined &&
              value.native_defect_status_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  defectStatusNativeDefectStatusCleared,
  defectStatusNativeDefectStatusUpsertMany,
  defectStatusNativeDefectStatusUpsertOne,
  defectStatusNativeDefectStatusUpdateOne,
  defectStatusNativeDefectStatusRemoveOne,
} = defectStatusNativeDefectStatusSlice.actions;

export const defectStatusNativeDefectStatusReducer =
  defectStatusNativeDefectStatusSlice.reducer;
