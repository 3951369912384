import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useOverlayTriggerState } from 'react-stately';
import {
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import { DeleteIcon, UsersIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import {
  ConfirmDialog,
  SettingsPanel,
  InviteUsersDialog,
} from 'src/components/organisms';
import vars from 'src/export.scss';
import { User } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { accessRolesSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import { accessRoleUsersSelector } from '@bitmodern/redux/state/accessRolesUsers/selectors';
import { deleteUserThunk } from '@bitmodern/redux/state/users/thunks';
import useMutation from 'src/hooks/useMutation';
import styles from './UsersView.module.scss';

export default function UsersView() {
  const { t } = useTranslation();
  const history = useHistory();
  const { site } = useParams<typeof routes.USERS.params>();
  const dispatch = useAppDispatch();
  const [currentUser, setCurrentUser] = useState<User>();
  const deleteUser = useOverlayTriggerState({});
  const inviteUser = useOverlayTriggerState({});
  const users = useAppSelector(usersSelector);
  const accessRoles = useAppSelector(accessRolesSelector);
  const accessRoleUsers = useAppSelector(accessRoleUsersSelector);

  const onDelete = useCallback(() => {
    if (!currentUser) return Promise.resolve();
    return dispatch(deleteUserThunk(currentUser.id)).finally(deleteUser.close);
  }, [dispatch, deleteUser, currentUser]);

  const deleteUserMutation = useMutation(onDelete);

  const onClickUser = (user: User) => {
    history.push(routes.USER({ site, userId: user.id.toString() }));
  };

  const onInvite = () => {
    inviteUser.open();
  };

  return (
    <SettingsPanel
      actions={
        <Button onClick={onInvite} size="small">
          {t('users.invite')}
        </Button>
      }
      icon={<UsersIcon color={vars.textPrimary} size={24} />}
      paddingLess
      title={t('users.title')}>
      <Table>
        <TableHead>
          <TableCell>{t('users.name')}</TableCell>
          <TableCell>{t('users.email')}</TableCell>
          <TableCell colSpan={2}>{t('users.roles')}</TableCell>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id} onClick={() => onClickUser(user)}>
              <TableCell className={styles.cell}>
                {user.given_name} {user.family_name}
              </TableCell>
              <TableCell className={styles.cell}>{user.email}</TableCell>
              <TableCell className={styles.cell}>
                {accessRoleUsers
                  .filter((a) => a.user_id === user.id)
                  .map((a) => {
                    const name = accessRoles.find(
                      (r) => r.id === a.access_role_id,
                    )?.name;
                    return <Chip key={a.id}>{name}</Chip>;
                  })}
              </TableCell>
              <TableCell className={`${styles.cell} ${styles.actions}`}>
                <IconButton
                  onClick={() => {
                    setCurrentUser(user);
                    deleteUser.open();
                  }}
                  size="small"
                  title={t('users.delete')}>
                  <DeleteIcon color={vars.textPrimary} size={16} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog
        loading={deleteUserMutation.isLoading}
        open={deleteUser.isOpen}
        onCancel={deleteUser.close}
        onConfirm={deleteUserMutation.mutate}
        title={t('users.deleteUser.title')}>
        {t('users.deleteUser.content', { user: currentUser?.email })}
      </ConfirmDialog>
      <InviteUsersDialog
        isOpen={inviteUser.isOpen}
        onClose={inviteUser.close}
        title={t('inviteUsersDialog.title')}
      />
    </SettingsPanel>
  );
}
