import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import styles from './DefectListed.module.scss';

type Props = ComponentProps<'div'> & {
  data: any;
  selected?: boolean;
  included?: boolean;
  isDefect?: boolean;
  onClick: (number: number) => void;
};

export default function DefectListed({
  className = '',
  data,
  selected = false,
  included = false,
  isDefect = false,
  ...rest
}: Props) {
  const testCN = classnames(styles.defectListed, className, {
    [styles.selected]: selected,
    [styles.disabled]: !isDefect && included,
  });

  return (
    <div className={testCN} {...rest}>
      <div className={styles.data}>
        <span className={styles.id}>{data.number}</span>
        <span className={styles.title}>{data.title}</span>
      </div>
      {included && <span className={styles.included}>linked</span>}
    </div>
  );
}
