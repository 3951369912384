/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { TestQualityType } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { testQualityTypeAdapter } from './testQualityTypeAdapter';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
  testQualityTypeDeleteOneThunk,
  testQualityTypeUpdateOneThunk,
  testQualityTypeCreateOneThunk,
} from './testQualityTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const testQualityTypeSlice = createSlice({
  name: 'testQualityType',
  initialState: testQualityTypeAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    testQualityTypeCleared(state) {
      state.loading = 'idle';
      testQualityTypeAdapter.removeAll(state);
    },
    testQualityTypeUpsertMany(
      state,
      action: PayloadAction<
        TestQualityType[] | Record<number, TestQualityType>
      >,
    ) {
      testQualityTypeAdapter.upsertMany(state, action.payload);
    },
    testQualityTypeUpsertOne(state, action: PayloadAction<TestQualityType>) {
      testQualityTypeAdapter.upsertOne(state, action.payload);
    },
    testQualityTypeUpdateOne(
      state,
      action: PayloadAction<Update<TestQualityType>>,
    ) {
      testQualityTypeAdapter.updateOne(state, action.payload);
    },
    testQualityTypeRemoveOne(state, action: PayloadAction<number>) {
      testQualityTypeAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testQualityTypeFetchManyThunk.pending, handlePending);
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        testQualityTypeAdapter.upsertMany(
          state,
          action.payload.testQualityType,
        );
      },
    );
    builder.addCase(testQualityTypeFetchManyThunk.rejected, handleRejected);
    builder.addCase(testQualityTypeFetchOneThunk.pending, handlePending);
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityTypeAdapter.upsertOne(
        state,
        action.payload.testQualityType[0],
      );
    });
    builder.addCase(testQualityTypeFetchOneThunk.rejected, handleRejected);
    builder.addCase(testQualityTypeDeleteOneThunk.pending, handlePending);
    builder.addCase(
      testQualityTypeDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          testQualityTypeAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(testQualityTypeDeleteOneThunk.rejected, handleRejected);
    builder.addCase(testQualityTypeUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, testQualityTypeAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(
      testQualityTypeUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        testQualityTypeAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(testQualityTypeUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          testQualityTypeAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(testQualityTypeCreateOneThunk.pending, handlePending);
    builder.addCase(
      testQualityTypeCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        testQualityTypeAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(testQualityTypeCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  testQualityTypeCleared,
  testQualityTypeUpsertMany,
  testQualityTypeUpsertOne,
  testQualityTypeUpdateOne,
  testQualityTypeRemoveOne,
} = testQualityTypeSlice.actions;

export const testQualityTypeReducer = testQualityTypeSlice.reducer;
