/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Plat,
  platGetMany,
  platGetOne,
  platDeleteOne,
  platUpdateOne,
  platCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { platNormalizer } from './platNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const platFetchManyThunk = createAsyncThunk(
  'plat/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plat>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await platGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, platNormalizer);
      }
      return {
        plat: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platFetchOneThunk = createAsyncThunk(
  'plat/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plat>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'plat/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await platGetOne(arg.id as number, arg);
      return executeNormalizer([data], platNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platDeleteOneThunk = createAsyncThunk(
  'plat/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plat>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'plat/DeleteOneThunk');
      }
      return await platDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platUpdateOneThunk = createAsyncThunk(
  'plat/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Plat>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'plat/UpdateOneThunk');
      }
      const data = await platUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Plat>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Plat>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platCreateOneThunk = createAsyncThunk(
  'plat/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Plat>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'plat/CreateOneThunk',
        );
      }
      return await platCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
