import React from 'react';
import useParams from 'src/hooks/useParams';
import { Redirect } from 'react-router-dom';
import { routes } from 'src/components/Router';
import { useAppSelector } from '@bitmodern/redux/store';
import { siteSelector } from '@bitmodern/redux/state/authentication/selectors';

export default function HomeView() {
  const { site: urlSite } = useParams<typeof routes.HOME.params>();
  const site = useAppSelector(siteSelector);

  if (!urlSite && !site)
    return <Redirect from={routes.HOME.path} exact to={routes.LOGIN({})} />;

  return (
    <Redirect from="/" to={routes.PROJECT_EMPTY({ site: site || urlSite })} />
  );
}
