/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { SuiteApi } from '@testquality/sdk';
import { SuiteTest } from '@testquality/sdk';
import { PlanSuite } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { testNormalizer } from '../test/testNormalize';
import { suiteTestNormalizer } from '../suite_test/suiteTestNormalize';
import { planNormalizer } from '../plan/planNormalize';
import { planSuiteNormalizer } from '../plan_suite/planSuiteNormalize';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { planSuiteTestIncludeNormalizer } from '../plan_suite_test_include/planSuiteTestIncludeNormalize';
import { requirementTestNormalizer } from '../requirement_test/requirementTestNormalize';

export const suiteNormalizer = (model: SuiteApi, cache: NormalizerCache) => {
  if (!cache.suite) {
    cache.suite = [];
  }
  cache.suite.push(model);

  if (model.test && model.test.length) {
    model.test.forEach((child) => {
      suiteTestNormalizer(child.pivot as SuiteTest, cache);
      child.pivot = undefined;
      testNormalizer(child, cache);
    });
    model.test = undefined;
  }
  if (model.plan && model.plan.length) {
    model.plan.forEach((child) => {
      planSuiteNormalizer(child.pivot as PlanSuite, cache);
      child.pivot = undefined;
      planNormalizer(child, cache);
    });
    model.plan = undefined;
  }
  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
  if (model.plan_suite_test_include && model.plan_suite_test_include.length) {
    model.plan_suite_test_include.forEach((child) => {
      planSuiteTestIncludeNormalizer(child, cache);
    });
    model.plan_suite_test_include = undefined;
  }
  if (model.requirement_test && model.requirement_test.length) {
    model.requirement_test.forEach((child) => {
      requirementTestNormalizer(child, cache);
    });
    model.requirement_test = undefined;
  }
};
