import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <defs>
      <filter
        x="-59.8%"
        y="-175.7%"
        width="219.6%"
        height="462.2%"
        filterUnits="objectBoundingBox"
        id="c">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={22}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="a" x={0} y={0} width={140} height={140} rx={10} />
      <path id="d" d="M16 144h112v37H16z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect fill="#141328" width={164} height={164} rx={16} />
      <g transform="translate(12 12)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#202642" xlinkHref="#a" />
        <rect
          strokeOpacity={0.25}
          stroke="#FFF"
          strokeWidth={3}
          fill="#141328"
          mask="url(#b)"
          x={21}
          y={28}
          width={101}
          height={153}
          rx={8}
        />
        <g mask="url(#b)">
          <use fill="#000" filter="url(#c)" xlinkHref="#d" />
          <use fill="#D8D8D8" xlinkHref="#d" />
        </g>
      </g>
      <path d="M52 64h65v65H52z" />
      <path
        d="M87.03 72.71v18.368c0 1.484 1.225 2.698 2.723 2.698h18.486c1.743 0 3.05-1.592 2.668-3.291-2.314-10.142-10.346-18.126-20.555-20.42-1.716-.377-3.322.918-3.322 2.644Z"
        fill="#E63E3E"
        fillRule="nonzero"
      />
      <path
        d="M87.03 101.922v18.369c0 1.726 1.606 3.02 3.322 2.643 10.237-2.293 18.268-10.304 20.583-20.446.38-1.672-.953-3.29-2.669-3.29H89.78c-1.525.026-2.75 1.24-2.75 2.724Z"
        fill="#478FC4"
      />
      <path
        d="M81.503 72.71v47.58c0 1.727-1.606 3.022-3.294 2.644C66.039 120.237 57 109.42 57 96.5c0-12.92 9.039-23.737 21.209-26.434 1.688-.378 3.294.917 3.294 2.643Z"
        fill="#D27E30"
      />
    </g>
  </>,
  'ProjectDemo',
  {},
  164,
  164,
);
