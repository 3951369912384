import { parse } from 'csv-parse/browser/esm/sync';

export function parseCSV(
  str: string,
  delimiter: string,
): { headers: string[]; rows: Record<string, any> } {
  const parsed = parse(str, { delimiter }) as any[][];

  const headers = parsed[0];
  const rows = parsed.slice(1).map((el) => {
    return el.reduce((row, cell, i) => {
      row[headers[i]] = cell;
      return row;
    }, {});
  });

  return { headers, rows };
}
