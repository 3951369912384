import { Run } from '@testquality/sdk';
import React, { useMemo } from 'react';
import StatusIcon from 'src/components/organisms/StatusIcon';
import vars from 'src/export.scss';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import AnalyzeStats from 'src/components/organisms/AnalyzeStats';
import { statusTypesSelector } from '@bitmodern/redux/state/statusTypes/selectors';
import { StatusEnum, StatusTypeEnum } from 'src/enums/StatusEnum';
import styles from './StaticsRun.module.scss';

type Props = {
  runs: Run[];
  className?: string;
};
export default function StaticsRuns({ runs, className = '' }: Props) {
  const { t } = useTranslation();

  const statuses = useAppSelector(statusSelectors.selectAll);
  const statusTypes = useAppSelector(statusTypesSelector);
  const passStatus = statuses.find((s) => s.key === StatusEnum.Pass);
  const failStatus = statuses.find((s) => s.key === StatusEnum.Fail);

  const { passPercentage, failPercentage } = useMemo(() => {
    const statusMap = statuses.reduce((map, status) => {
      map[status.key] = status;
      return map;
    }, {});

    const positiveType = statusTypes.find(
      (st) => st.key === StatusTypeEnum.Positive,
    );
    const negativeType = statusTypes.find(
      (st) => st.key === StatusTypeEnum.Negative,
    );

    const { pass, fail, total } = runs
      .flatMap((run) => run.analysis?.status)
      .reduce(
        (statusCount, item) => {
          if (!item) return statusCount;
          const status = statusMap[item.status_key];
          if (status?.status_type_id === positiveType?.id) {
            statusCount.pass += item.total || 0;
          } else if (status?.status_type_id === negativeType?.id) {
            statusCount.fail += item.total || 0;
          }
          statusCount.total += item.total;
          return statusCount;
        },
        { pass: 0, fail: 0, total: 0 },
      );

    const runPass = (pass / total) * 100;
    const runFail = (fail / total) * 100;

    return {
      passPercentage: Math.round(runPass * 100) / 100 || 0,
      failPercentage: Math.round(runFail * 100) / 100 || 0,
    };
  }, [runs, statusTypes, statuses]);

  return (
    <div className={`${styles.container} ${className}`}>
      <AnalyzeStats
        title={
          <div className={styles.title}>
            {passStatus && <StatusIcon status={passStatus} />}
            {t('plans.overview.statics.passed')}
          </div>
        }
        stat={`${passPercentage} %`}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        title={
          <div className={styles.title}>
            {failStatus && <StatusIcon status={failStatus} />}
            {t('plans.overview.statics.failed')}
          </div>
        }
        stat={`${failPercentage} %`}
        statColor={vars.errorMain}
      />
    </div>
  );
}
