/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Plat } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { platAdapter } from './platAdapter';
import {
  platFetchManyThunk,
  platFetchOneThunk,
  platDeleteOneThunk,
  platUpdateOneThunk,
  platCreateOneThunk,
} from './platThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const platSlice = createSlice({
  name: 'plat',
  initialState: platAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    platCleared(state) {
      state.loading = 'idle';
      platAdapter.removeAll(state);
    },
    platUpsertMany(
      state,
      action: PayloadAction<Plat[] | Record<number, Plat>>,
    ) {
      platAdapter.upsertMany(state, action.payload);
    },
    platUpsertOne(state, action: PayloadAction<Plat>) {
      platAdapter.upsertOne(state, action.payload);
    },
    platUpdateOne(state, action: PayloadAction<Update<Plat>>) {
      platAdapter.updateOne(state, action.payload);
    },
    platRemoveOne(state, action: PayloadAction<number>) {
      platAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(platFetchManyThunk.pending, handlePending);
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platAdapter.upsertMany(state, action.payload.plat);
    });
    builder.addCase(platFetchManyThunk.rejected, handleRejected);
    builder.addCase(platFetchOneThunk.pending, handlePending);
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platAdapter.upsertOne(state, action.payload.plat[0]);
    });
    builder.addCase(platFetchOneThunk.rejected, handleRejected);
    builder.addCase(platDeleteOneThunk.pending, handlePending);
    builder.addCase(platDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        platAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(platDeleteOneThunk.rejected, handleRejected);
    builder.addCase(platUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, platAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(platUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platAdapter.updateOne(state, action.payload);
    });
    builder.addCase(platUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          platAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(platCreateOneThunk.pending, handlePending);
    builder.addCase(platCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      platAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(platCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  platCleared,
  platUpsertMany,
  platUpsertOne,
  platUpdateOne,
  platRemoveOne,
} = platSlice.actions;

export const platReducer = platSlice.reducer;
