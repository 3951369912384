/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { PullRequest } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { pullRequestAdapter } from './pullRequestAdapter';
import {
  pullRequestFetchManyThunk,
  pullRequestFetchOneThunk,
  pullRequestDeleteOneThunk,
  pullRequestUpdateOneThunk,
  pullRequestCreateOneThunk,
} from './pullRequestThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const pullRequestSlice = createSlice({
  name: 'pullRequest',
  initialState: pullRequestAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    pullRequestCleared(state) {
      state.loading = 'idle';
      pullRequestAdapter.removeAll(state);
    },
    pullRequestUpsertMany(
      state,
      action: PayloadAction<PullRequest[] | Record<number, PullRequest>>,
    ) {
      pullRequestAdapter.upsertMany(state, action.payload);
    },
    pullRequestUpsertOne(state, action: PayloadAction<PullRequest>) {
      pullRequestAdapter.upsertOne(state, action.payload);
    },
    pullRequestUpdateOne(state, action: PayloadAction<Update<PullRequest>>) {
      pullRequestAdapter.updateOne(state, action.payload);
    },
    pullRequestRemoveOne(state, action: PayloadAction<number>) {
      pullRequestAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pullRequestFetchManyThunk.pending, handlePending);
    builder.addCase(pullRequestFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
    });
    builder.addCase(pullRequestFetchManyThunk.rejected, handleRejected);
    builder.addCase(pullRequestFetchOneThunk.pending, handlePending);
    builder.addCase(pullRequestFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      pullRequestAdapter.upsertOne(state, action.payload.pullRequest[0]);
    });
    builder.addCase(pullRequestFetchOneThunk.rejected, handleRejected);
    builder.addCase(pullRequestDeleteOneThunk.pending, handlePending);
    builder.addCase(pullRequestDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        pullRequestAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(pullRequestDeleteOneThunk.rejected, handleRejected);
    builder.addCase(pullRequestUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, pullRequestAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(pullRequestUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      pullRequestAdapter.updateOne(state, action.payload);
    });
    builder.addCase(pullRequestUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          pullRequestAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(pullRequestCreateOneThunk.pending, handlePending);
    builder.addCase(pullRequestCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      pullRequestAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(pullRequestCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.pullRequest) {
          pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.pullRequest) {
          pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.pullRequest) {
          pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.pullRequest) {
        pullRequestAdapter.upsertMany(state, action.payload.pullRequest);
      }
    });
  },
});

export const {
  pullRequestCleared,
  pullRequestUpsertMany,
  pullRequestUpsertOne,
  pullRequestUpdateOne,
  pullRequestRemoveOne,
} = pullRequestSlice.actions;

export const pullRequestReducer = pullRequestSlice.reducer;
