import React, { useMemo } from 'react';
import { Resizable } from 're-resizable';
import { useTranslation } from 'src/i18n/hooks';
import {
  Loading,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@bitmodern/bit-ui';
import { Run, Defect } from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import { useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { defectsByRunSelector } from '@bitmodern/redux/state/defects/selectors';
import styles from './RunAndDefectsList.module.scss';
import DefectItem from '../DefectItem';
import RunListItem from '../RunListItem';
import EmptyList from '../EmptyList/EmptyList';

type Props = {
  runs: Run[];
  defects: Defect[];
  loading?: boolean;
};

export default function RunAndDefectsList({
  runs,
  defects,
  loading = false,
}: Props) {
  const { t } = useTranslation();
  const { runId } = useParams<typeof routes.RUN.params>();

  const runDefects = useAppSelector((state) =>
    defectsByRunSelector(state, parseInt(runId, 10)),
  );

  /* NOTE: We create a defect entity for each run result that has */
  /* an issue asssociated with it. The issue could be the same. */
  const uniqueDefects = useMemo(() => {
    const defectExtKeys: string[] = [];
    const defectsIds = runDefects.map((rd) => rd?.id);
    return defects.filter((defect) => {
      // Don't add the defect if it's already in the array or the defect is not related to the run. Shouldn't affect display outside of Runs
      if (
        defectExtKeys.includes(defect.external_reference_id) ||
        (runId && !defectsIds.includes(defect.id))
      ) {
        return false;
      }

      defectExtKeys.push(defect.external_reference_id);
      return true;
    });
  }, [defects, runDefects, runId]);

  if (loading) {
    return (
      <div className={styles.container}>
        <Loading className={styles.loading} size={48} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Resizable
        bounds="parent"
        style={{ flexBasis: '60%' }}
        enable={{ bottom: true }}
        maxHeight="70%"
        minHeight="30%">
        <div className={styles.wrap}>
          <div>
            {runs.map((run) => (
              <RunListItem key={run.id} run={run} />
            ))}
            {runs.length === 0 && (
              <EmptyList
                title={t('runList.empty.title')}
                description={t('runList.empty.description')}
              />
            )}
          </div>
        </div>
      </Resizable>
      <div className={styles.wrap}>
        <div className={styles.absoluteWrap}>
          <Table height="100%">
            <TableHead className={styles.head}>
              <TableCell>{t('defects.head.key')}</TableCell>
              <TableCell>{t('defects.head.summary')}</TableCell>
              <TableCell>{t('defects.head.created')}</TableCell>
              <TableCell>{t('defects.head.assigned')}</TableCell>
              <TableCell>{t('defects.head.status')}</TableCell>
            </TableHead>
            <TableBody>
              {uniqueDefects.map((defect) => (
                <DefectItem key={defect.id} defect={defect} />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
