import React from 'react';
import { useAppSelector } from '@bitmodern/redux/store';
import { isAuthenticatedSelector } from '@bitmodern/redux/state/authentication/selectors';
import { Redirect } from 'react-router';
import { routes } from 'src/components/Router';
import GithubPurchasesView from './GithubPurchasesView';

export default function GithubPurchasesViewContainer() {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return <Redirect to={routes.FREETRIAL({})} />;
  }

  return <GithubPurchasesView />;
}
