/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  SignupOption,
  signupOptionGetMany,
  signupOptionGetOne,
  signupOptionDeleteOne,
  signupOptionUpdateOne,
  signupOptionCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const signupOptionFetchManyThunk = createAsyncThunk(
  'signupOption/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<SignupOption>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await signupOptionGetMany(inlineArgs);
      return {
        signupOption: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const signupOptionFetchOneThunk = createAsyncThunk(
  'signupOption/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<SignupOption>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'signupOption/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await signupOptionGetOne(arg.id as number, arg);
      return {
        signupOption: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const signupOptionDeleteOneThunk = createAsyncThunk(
  'signupOption/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<SignupOption>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'signupOption/DeleteOneThunk',
        );
      }
      return await signupOptionDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const signupOptionUpdateOneThunk = createAsyncThunk(
  'signupOption/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<SignupOption>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'signupOption/UpdateOneThunk',
        );
      }
      const data = await signupOptionUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<SignupOption>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<SignupOption>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const signupOptionCreateOneThunk = createAsyncThunk(
  'signupOption/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<SignupOption>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'signupOption/CreateOneThunk',
        );
      }
      return await signupOptionCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
