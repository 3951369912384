import React, { ComponentProps, ReactNode, useEffect } from 'react';
import Yup from 'src/utils/yup';
import { useFormik } from 'formik';
import {
  Input,
  Dialog,
  Button,
  TextArea,
  Select,
  RadioGroup,
  Radio,
} from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { useTranslation } from 'src/i18n/hooks';
import {
  VirtualSchemaType,
  VirtualSchemaTypeDisplay,
} from 'src/enums/VirtualSchemaTypeEmum';
import { BooleanEnum } from 'src/enums/BooleanEnum';
import styles from './CustomFieldDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('customFieldDialog.form.name'),
});

type InitialValues = {
  objectType: 'test' | 'run' | 'plan';
  name: string;
  description: string;
  mandatory: BooleanEnum;
  type: VirtualSchemaType;
};

const defaultInitialValues: InitialValues = {
  objectType: 'test',
  name: '',
  description: '',
  mandatory: BooleanEnum.False,
  type: VirtualSchemaType.String,
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  initialValues?: InitialValues;
  onSubmit: (initialValues: InitialValues) => Promise<any>;
  title: ReactNode;
};

export default function CustomFieldDialog({
  initialValues,
  isOpen,
  onClose,
  onSubmit,
  title,
}: Props) {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || defaultInitialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const objectTypeOptions = [
    {
      value: 'plan',
      label: t('customization.objectTypes.plan'),
    },
    {
      value: 'run',
      label: t('customization.objectTypes.run'),
    },
    {
      value: 'test',
      label: t('customization.objectTypes.test'),
    },
  ];

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      title={title}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        <Select
          error={formikError(formik, 'objectType')}
          label={t('customFieldDialog.form.objectType')}
          onChange={formik.handleChange('objectType')}
          required
          options={objectTypeOptions}
          value={formik.values.objectType}
        />
        <Input
          error={formikError(formik, 'name')}
          fullWidth
          label={t('customFieldDialog.form.name')}
          name="name"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.name}
        />

        <RadioGroup
          label={t('customFieldDialog.form.mandatory')}
          name="color"
          onChange={formik.handleChange('mandatory')}
          orientation="horizontal"
          value={formik.values.mandatory.toString()}>
          <Radio value={BooleanEnum.True}>{t('booleanEnum.true')}</Radio>
          <Radio value={BooleanEnum.False}>{t('booleanEnum.false')}</Radio>
        </RadioGroup>

        <Select
          label={t('customFieldDialog.form.type')}
          onChange={formik.handleChange('type')}
          options={Array.from(VirtualSchemaTypeDisplay).map(
            ([value, label]) => ({
              label: t(label),
              value,
            }),
          )}
          value={formik.values.type}
        />

        <TextArea
          error={formikError(formik, 'description')}
          fullWidth
          label={t('customFieldDialog.form.description')}
          name="description"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          value={formik.values.description}
        />

        <div className={styles.submit}>
          <Button loading={formik.isSubmitting} type="submit">
            {initialValues
              ? t('customFieldDialog.form.edit')
              : t('customFieldDialog.form.create')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
