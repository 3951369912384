/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Policy } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { policyAdapter } from './policyAdapter';
import {
  policyFetchManyThunk,
  policyFetchOneThunk,
  policyDeleteOneThunk,
  policyUpdateOneThunk,
  policyCreateOneThunk,
} from './policyThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const policySlice = createSlice({
  name: 'policy',
  initialState: policyAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    policyCleared(state) {
      state.loading = 'idle';
      policyAdapter.removeAll(state);
    },
    policyUpsertMany(
      state,
      action: PayloadAction<Policy[] | Record<number, Policy>>,
    ) {
      policyAdapter.upsertMany(state, action.payload);
    },
    policyUpsertOne(state, action: PayloadAction<Policy>) {
      policyAdapter.upsertOne(state, action.payload);
    },
    policyUpdateOne(state, action: PayloadAction<Update<Policy>>) {
      policyAdapter.updateOne(state, action.payload);
    },
    policyRemoveOne(state, action: PayloadAction<number>) {
      policyAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(policyFetchManyThunk.pending, handlePending);
    builder.addCase(policyFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyAdapter.upsertMany(state, action.payload.policy);
    });
    builder.addCase(policyFetchManyThunk.rejected, handleRejected);
    builder.addCase(policyFetchOneThunk.pending, handlePending);
    builder.addCase(policyFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyAdapter.upsertOne(state, action.payload.policy[0]);
    });
    builder.addCase(policyFetchOneThunk.rejected, handleRejected);
    builder.addCase(policyDeleteOneThunk.pending, handlePending);
    builder.addCase(policyDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        policyAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(policyDeleteOneThunk.rejected, handleRejected);
    builder.addCase(policyUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, policyAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(policyUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyAdapter.updateOne(state, action.payload);
    });
    builder.addCase(policyUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          policyAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(policyCreateOneThunk.pending, handlePending);
    builder.addCase(policyCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      policyAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(policyCreateOneThunk.rejected, handleRejected);
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.policy) {
          policyAdapter.upsertMany(state, action.payload.policy);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.policy) {
          policyAdapter.upsertMany(state, action.payload.policy);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.policy) {
          policyAdapter.upsertMany(state, action.payload.policy);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.policy) {
        policyAdapter.upsertMany(state, action.payload.policy);
      }
    });
  },
});

export const {
  policyCleared,
  policyUpsertMany,
  policyUpsertOne,
  policyUpdateOne,
  policyRemoveOne,
} = policySlice.actions;

export const policyReducer = policySlice.reducer;
