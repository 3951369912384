/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { TestQualityTypeApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { testQualityNormalizer } from '../test_quality/testQualityNormalize';

export const testQualityTypeNormalizer = (
  model: TestQualityTypeApi,
  cache: NormalizerCache,
) => {
  if (!cache.testQualityType) {
    cache.testQualityType = [];
  }
  cache.testQualityType.push(model);

  if (model.test_quality && model.test_quality.length) {
    model.test_quality.forEach((child) => {
      testQualityNormalizer(child, cache);
    });
    model.test_quality = undefined;
  }
};
