import React, { useCallback, useState } from 'react';

import { useOverlayTriggerState } from 'react-stately';
import {
  IconButton,
  Loading,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bitmodern/bit-ui';
import useFetch from 'src/hooks/useFetch';
import {
  deletePersonalAccesToken,
  getPersonalAccessTokens,
} from '@bitmodern/services/personalAccessToken';
import { useTranslation } from 'src/i18n/hooks';
import { AddIcon, DeleteIcon } from '@bitmodern/bit-ui/icons';
import { ConfirmDialog } from 'src/components/organisms';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import { getEnv } from 'src/env';
import PersonalAccessTokenCreate from '../PersonalAccessTokenCreate';
import styles from './PersonalAccessToken.module.scss';

export default function PersonalAccessToken() {
  const { t } = useTranslation();
  const [currentToken, setCurrentToken] = useState<any>();
  const deleteToken = useOverlayTriggerState({});
  const addToken = useOverlayTriggerState({});

  const personalAccessTokens = useFetch(
    getPersonalAccessTokens,
    'personalAccessTokens',
    {
      ...(getEnv()?.features?.dataCache && { staleWhileRevalidate: true }),
      initialData: [],
    },
  );

  const onDelete = useCallback(() => {
    if (!currentToken) return Promise.resolve();
    return deletePersonalAccesToken(currentToken.id)
      .then(() => {
        personalAccessTokens.setData(
          personalAccessTokens.data.filter((d) => currentToken.id !== d.id),
        );
      })
      .finally(deleteToken.close);
  }, [currentToken, deleteToken, personalAccessTokens]);

  const deleteMutation = useMutation(onDelete);

  const onCreateToken = (token) => {
    personalAccessTokens.setData([...personalAccessTokens.data, token]);
  };

  if (personalAccessTokens.isLoading) return <Loading size={42} />;

  return (
    <div>
      <div className={styles.head}>
        <div className={styles.title}>{t('personalToken.title')}</div>
        <IconButton onClick={addToken.open}>
          <AddIcon color={vars.textPrimary} size={20} />
        </IconButton>
      </div>

      {personalAccessTokens.data.length > 0 ? (
        <Table outlined rounded>
          <TableHead>
            <TableCell>{t('personalToken.name')}</TableCell>
            <TableCell>{t('personalToken.createdAt')}</TableCell>
            <TableCell colSpan={2}>{t('personalToken.expiresAt')}</TableCell>
          </TableHead>
          <TableBody>
            {personalAccessTokens.data.map((pat) => (
              <TableRow key={pat.id}>
                <TableCell className={styles.cell}>{pat.name}</TableCell>
                <TableCell className={styles.cell}>
                  {t('dates.default', { date: new Date(pat.created_at) })}
                </TableCell>
                <TableCell className={styles.cell}>
                  {t('dates.default', { date: new Date(pat.expires_at) })}
                </TableCell>
                <TableCell className={`${styles.cell} ${styles.actions}`}>
                  <IconButton
                    onClick={() => {
                      setCurrentToken(pat);
                      deleteToken.open();
                    }}
                    size="small"
                    title={t('personalToken.deleteToken')}>
                    <DeleteIcon color={vars.textPrimary} size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className={styles.explain}>{t('personalToken.explain')}</div>
      )}
      <PersonalAccessTokenCreate
        onCreate={onCreateToken}
        open={addToken.isOpen}
        onClose={addToken.close}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        open={deleteToken.isOpen}
        onCancel={deleteToken.close}
        onConfirm={deleteMutation.mutate}
        title={t('personalToken.delete.title')}>
        {t('personalToken.delete.content')}
      </ConfirmDialog>
    </div>
  );
}
