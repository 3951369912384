/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { MilestoneApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runNormalizer } from '../run/runNormalize';

export const milestoneNormalizer = (
  model: MilestoneApi,
  cache: NormalizerCache,
) => {
  if (!cache.milestone) {
    cache.milestone = [];
  }
  cache.milestone.push(model);

  if (model.run && model.run.length) {
    model.run.forEach((child) => {
      runNormalizer(child, cache);
    });
    model.run = undefined;
  }
};
