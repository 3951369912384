/* eslint-disable react/self-closing-comp */
import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <g
      id="Exploration-05"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="Artboard" transform="translate(-3866.000000, -10928.000000)">
        <g id="Group-5" transform="translate(3669.000000, 10928.000000)">
          <g id="Group-2" transform="translate(197.000000, 0.000000)">
            <g id="Group-25" transform="translate(0.000000, 0.000000)">
              <path
                d="M92.1625252,70.6292414 L12.8315968,70.6292414 C10.3471406,70.6292414 8.33334748,68.6152361 8.33334748,66.130992 L8.33334748,15.9374642 C8.33334748,13.453008 10.3471406,11.4392149 12.8315968,11.4392149 L92.1625252,11.4392149 C94.6467692,11.4392149 96.6605623,13.453008 96.6605623,15.9374642 L96.6605623,66.130992 C96.6605623,68.6152361 94.6467692,70.6292414 92.1625252,70.6292414"
                id="Fill-1"
                fill="#384070"></path>
              <path
                d="M89.8614748,65.9000743 L15.1327745,65.9000743 C14.1156923,65.9000743 13.2912891,65.0756711 13.2912891,64.0585889 L13.2912891,18.4672891 C13.2912891,17.4504191 14.1156923,16.6258037 15.1327745,16.6258037 L89.8614748,16.6258037 C90.8783448,16.6258037 91.7029602,17.4504191 91.7029602,18.4672891 L91.7029602,64.0585889 C91.7029602,65.0756711 90.8783448,65.9000743 89.8614748,65.9000743"
                id="Fill-3"
                fill="#5376BA"></path>
              <path
                d="M77.2609655,59.9984297 L27.9953952,59.9984297 C26.257252,59.9984297 24.8484456,58.5896233 24.8484456,56.8516923 L24.8484456,3.14686472 C24.8484456,1.40893369 26.257252,0.000127320955 27.9953952,0.000127320955 L77.2609655,0.000127320955 C78.9988966,0.000127320955 80.4077029,1.40893369 80.4077029,3.14686472 L80.4077029,56.8516923 C80.4077029,58.5896233 78.9988966,59.9984297 77.2609655,59.9984297"
                id="Fill-5"
                fill="#FEFEFE"></path>
              <path
                d="M104.125199,76.7026844 L0.868965517,76.7026844 C0.389177719,76.7026844 0,76.3137188 0,75.8337188 L0,71.5449125 C0,71.0651247 0.389177719,70.6761592 0.868965517,70.6761592 L104.125199,70.6761592 C104.605199,70.6761592 104.994164,71.0651247 104.994164,71.5449125 L104.994164,75.8337188 C104.994164,76.3137188 104.605199,76.7026844 104.125199,76.7026844"
                id="Fill-7"
                fill="#F5F9FD"></path>
              <path
                d="M101.277666,79.9617613 L3.71628647,79.9617613 C1.66387268,79.9617613 0,78.2978886 0,76.2452626 L0,75.3765093 L104.994164,75.3765093 L104.994164,76.2452626 C104.994164,78.2978886 103.330292,79.9617613 101.277666,79.9617613"
                id="Fill-9"
                fill="#B7C8E6"></path>
              <path
                d="M67.8152997,77.0611777 L37.178695,77.0611777 C36.6989072,77.0611777 36.3097294,76.6722122 36.3097294,76.1922122 L36.3097294,74.4990557 C36.3097294,74.0190557 36.6989072,73.6300902 37.178695,73.6300902 L67.8152997,73.6300902 C68.2952997,73.6300902 68.6842653,74.0190557 68.6842653,74.4990557 L68.6842653,76.1922122 C68.6842653,76.6722122 68.2952997,77.0611777 67.8152997,77.0611777"
                id="Fill-11"
                fill="#B7C8E6"></path>
              <path
                d="M74.8904828,7.44447974 C74.0554695,7.44848806 73.5200849,6.9334748 73.5162469,6.12265252 C73.5126578,5.30122016 74.0418886,4.78281167 74.8798727,4.78682015 C75.699183,4.79066313 76.2135597,5.28742706 76.2258674,6.08636605 C76.2385995,6.90779841 75.7150981,7.4404244 74.8904828,7.44447974"
                id="Fill-13"
                fill="#5376BA"></path>
              <path
                d="M65.841252,7.44362865 C64.9903236,7.4374748 64.5188117,6.94049867 64.5052308,6.03609549 C64.4948329,5.33222281 65.1174324,4.77774005 65.9068223,4.78728912 C66.6962122,4.7972626 67.2141963,5.32734218 67.2116592,6.12331034 C67.2091034,6.93837666 66.6785995,7.44957029 65.841252,7.44362865"
                id="Fill-15"
                fill="#5376BA"></path>
              <path
                d="M70.3648382,7.44477734 C69.4691353,7.44604775 69.0368806,7.0135809 69.0360307,6.11490716 C69.035183,5.24 69.4971459,4.78270557 70.3777825,4.7869496 C71.1792679,4.79076923 71.6883395,5.29984085 71.6908981,6.09984085 C71.6936446,6.96901857 71.2259523,7.44350133 70.3648382,7.44477734"
                id="Fill-17"
                fill="#5376BA"></path>
              <path
                d="M74.8904828,7.44447974 C74.0554695,7.44848806 73.5200849,6.9334748 73.5162469,6.12265252 C73.5126578,5.30122016 74.0418886,4.78281167 74.8798727,4.78682015 C75.699183,4.79066313 76.2135597,5.28742706 76.2258674,6.08636605 C76.2385995,6.90779841 75.7150981,7.4404244 74.8904828,7.44447974"
                id="Fill-19"
                fill="#5376BA"></path>
              <path
                d="M65.841252,7.44362865 C64.9903236,7.4374748 64.5188117,6.94049867 64.5052308,6.03609549 C64.4948329,5.33222281 65.1174324,4.77774005 65.9068223,4.78728912 C66.6962122,4.7972626 67.2141963,5.32734218 67.2116592,6.12331034 C67.2091034,6.93837666 66.6785995,7.44957029 65.841252,7.44362865"
                id="Fill-21"
                fill="#5376BA"></path>
              <path
                d="M70.3648382,7.44477734 C69.4691353,7.44604775 69.0368806,7.0135809 69.0360307,6.11490716 C69.035183,5.24 69.4971459,4.78270557 70.3777825,4.7869496 C71.1792679,4.79076923 71.6883395,5.29984085 71.6908981,6.09984085 C71.6936446,6.96901857 71.2259523,7.44350133 70.3648382,7.44477734"
                id="Fill-23"
                fill="#5376BA"></path>
            </g>
            <g
              id="play_arrow-24px-(5)"
              transform="translate(43.820513, 19.843137)"
              fill="#0054FE"
              fillRule="nonzero">
              <path
                d="M0.179487179,2.10089574 L0.179487179,22.2128298 C0.179487179,23.7464618 1.89461779,24.6782888 3.21546549,23.8435271 L19.2627794,13.7875601 C20.4850564,13.0304506 20.4850564,11.2832749 19.2627794,10.5067523 L3.21546549,0.470198386 C1.89461779,-0.364563353 0.179487179,0.567263705 0.179487179,2.10089574 Z"
                id="Path"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </>,
  'LaptopRun',
  {},
  80,
  106,
);
