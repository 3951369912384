import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import { showNotificationError } from '@testquality/sdk';
import Yup from 'src/utils/yup';
import { Helmet } from 'react-helmet';
import { Button, Input, InputPassword } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import { useQuery } from 'src/hooks/useQuery';
import useParams from 'src/hooks/useParams';
import { routes } from 'src/components/Router';
import { getClient } from 'src/Client';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useAppDispatch } from '@bitmodern/redux/store';
import { LogoPanel } from 'src/modules/authentication/elements';
import styles from './PasswordRecoveryChange.module.scss';

const validationSchema = Yup.object().shape({
  password: Yup.string().required().label('recoveryChange.form.password'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Password does not match')
    .required()
    .label('recoveryChange.form.password'),
});

const initialValues = {
  password: '',
  passwordConfirm: '',
};

export default function PasswordRecoveryChange() {
  const { t } = useTranslation();
  const { token } = useParams<typeof routes.RECOVERY_CHANGE.params>();
  const formik = useFormik({ initialValues, onSubmit, validationSchema });
  const { email } = useQuery(['email']);
  const dispatch = useAppDispatch();
  const history = useHistory();

  function onSubmit({ password }) {
    return getClient()
      .getAuth()
      .passwordReset(email, password, token)
      .then(() => {
        return getClient().getAuth().login(email, password, true);
      })
      .then((loginToken) => {
        if (loginToken) {
          dispatch(authenticateAction(loginToken));
          history.replace(
            loginToken.client_name
              ? routes.HOME({ site: loginToken.client_name })
              : '#',
          );
        }
      })
      .catch(showNotificationError);
  }

  return (
    <LogoPanel title={t('recoveryChange.welcome')}>
      <Helmet>
        <title>{t('recoveryChange.head.title')}</title>
      </Helmet>
      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <div className={styles.desc}>{t('recoveryChange.desc', { email })}</div>

        <InputPassword
          error={formikError(formik, 'password')}
          fullWidth
          label={t('recoveryChange.form.password')}
          name="password"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          strengthIndicator
          value={formik.values.password}
        />

        <Input
          error={formikError(formik, 'passwordConfirm')}
          fullWidth
          label={t('recoveryChange.form.passwordConfirm')}
          name="passwordConfirm"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          type="password"
          value={formik.values.passwordConfirm}
        />

        <Button
          className={styles.submit}
          color="primaryLight"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('recoveryChange.form.submit')}
        </Button>
      </form>
    </LogoPanel>
  );
}
