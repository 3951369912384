/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Comment,
  commentGetMany,
  commentGetOne,
  commentDeleteOne,
  commentUpdateOne,
  commentCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const commentFetchManyThunk = createAsyncThunk(
  'comment/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Comment>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await commentGetMany(inlineArgs);
      return {
        comment: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const commentFetchOneThunk = createAsyncThunk(
  'comment/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Comment>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'comment/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await commentGetOne(arg.id as number, arg);
      return {
        comment: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const commentDeleteOneThunk = createAsyncThunk(
  'comment/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Comment>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'comment/DeleteOneThunk');
      }
      return await commentDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const commentUpdateOneThunk = createAsyncThunk(
  'comment/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Comment>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'comment/UpdateOneThunk');
      }
      const data = await commentUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Comment>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Comment>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const commentCreateOneThunk = createAsyncThunk(
  'comment/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Comment>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'comment/CreateOneThunk',
        );
      }
      return await commentCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
