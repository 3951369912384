import React, { ReactNode } from 'react';
import { PanelHeader, Surface } from '@bitmodern/bit-ui';
import { TestQualityLogoIcon } from '@bitmodern/bit-ui/icons';
import { useTheme } from 'src/hooks/useTheme';
import styles from './LogoPanel.module.scss';

export type LogoPanelProps = {
  children: ReactNode;
  footer?: ReactNode;
  title: ReactNode;
};

export function LogoPanel({ children, footer, title }: LogoPanelProps) {
  const [theme] = useTheme();
  return (
    <div className={styles.root}>
      <TestQualityLogoIcon className={styles.logo} size={200} theme={theme} />
      <Surface className={styles.panel}>
        <PanelHeader title={title} />
        {children}
      </Surface>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
}
