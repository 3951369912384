import React, { ReactNode } from 'react';
import styles from './PanelActions.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

export default function PanelActions({ children, className = '' }: Props) {
  return (
    <div className={`${styles.panelActions} ${className}`}>{children}</div>
  );
}
