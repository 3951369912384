import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'src/components/Router';
import { useQuery } from 'src/hooks/useQuery';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useAppDispatch } from 'src/packages/redux/store';
import { getClient } from 'src/Client';
import {
  HttpError,
  ReturnToken,
  showNotificationError,
} from '@testquality/sdk';

import Debug from 'debug';
const debug = Debug('LoginTokenView');

export function LoginTokenView() {
  const history = useHistory();
  const { token } = useQuery(['token']);
  const dispatch = useAppDispatch();

  const onError = useCallback(
    (err) => {
      showNotificationError(new HttpError(err.message));
      history.replace({
        pathname: routes.LOGIN.path,
      });
    },
    [history],
  );

  useEffect(() => {
    debug('token:', { token });
    try {
      if (!token) {
        throw new Error('token parameter is missing');
      }
      const returnToken = JSON.parse(token) as ReturnToken;
      getClient()
        .getAuth()
        .performLogin(returnToken)
        .then(() => {
          dispatch(authenticateAction(returnToken));
        })
        .catch(onError);
    } catch (err) {
      onError(err);
    }
  }, [dispatch, onError, token]);

  return null;
}
