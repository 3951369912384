import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import { IntegrationProject } from '@testquality/sdk';
import { integrationProjectSelectors } from 'src/gen/domain/integration_project/integrationProjectSelector';
import { requirementByIdSelector } from '../requirements/selectors';

export function integrationProjectsSelector(state: State) {
  return integrationProjectSelectors.selectAll(state);
}

export function getIntegrationProjectById(state, args: { id?: number }) {
  return args.id
    ? integrationProjectSelectors.selectById(state, args.id)
    : undefined;
}

export const makeIntegrationProjectByRequirementId = createSelector(
  [
    integrationProjectSelectors.selectAll,
    requirementByIdSelector,
    (state: State, params: { id?: number }) => params.id,
  ],
  (integrationProjects, requirement, id) => {
    if (!id || !integrationProjects) return undefined;
    return integrationProjects.find(
      (ip) => ip.id === requirement?.integration_project_id,
    );
  },
);
export const integrationProjectByProjectIdSelector = createSelector(
  [
    (state: State) => state.gen.integrationProject.entities,
    (state: State, params: { projectId?: number }) => params.projectId,
  ],
  (integrationProjects, projectId) => {
    const ips = (filter(
      integrationProjects,
      (integrationProject) => integrationProject?.project_id === projectId,
    ) || []) as IntegrationProject[];
    return ips.length > 0 ? ips[0] : undefined;
  },
);

export const allIntegrationsProjectByProjectIdSelector = createSelector(
  [
    (state: State) => state.gen.integrationProject.entities,
    (state: State, params: { projectId?: number }) => params.projectId,
  ],
  (integrationProjects, projectId) => {
    const ips = (filter(
      integrationProjects,
      (integrationProject) => integrationProject?.project_id === projectId,
    ) || []) as IntegrationProject[];
    return ips.length > 0 ? ips : undefined;
  },
);

export const integrationProjectByProjectIdAndRefSelector = createSelector(
  [
    integrationProjectSelectors.selectEntities,
    (state: State, params: { projectId?: number }) => params.projectId,
    (state: State, params: { externalReferenceId?: string }) =>
      params.externalReferenceId,
  ],
  (integrationProjects, projectId, externalReferenceId) => {
    if (!projectId || !externalReferenceId) return undefined;
    const ip = Object.values(integrationProjects)?.find(
      (ip) =>
        ip?.project_id === projectId &&
        ip?.project_reference_id === externalReferenceId,
    );

    return ip;
  },
);

export const integrationProjectsByIntegrationSelector = createSelector(
  [
    (state: State) => state.gen.integrationProject.entities,
    (state: State, { integrationId }: { integrationId?: number }) =>
      integrationId,
  ],
  (integrationProjects, integrationId) =>
    (filter(
      integrationProjects,
      (integrationProject) =>
        integrationProject?.integration_id === integrationId,
    ) || []) as IntegrationProject[],
);

export const integrationProjectsByBaseIntegrationSelector = createSelector(
  [
    (state: State) => state.gen.integrationProject.entities,
    (state: State) => state.gen.integration.entities,
    (state: State, baseIntegrationId: number) => baseIntegrationId,
  ],
  (integrationProjects, integrations, baseIntegrationId) => {
    const integrationsByBase = filter(
      integrations,
      (integration) => integration?.base_integration_id === baseIntegrationId,
    );
    return (filter(integrationProjects, (integrationProject) =>
      integrationsByBase.some(
        (integration) => integrationProject?.integration_id === integration?.id,
      ),
    ) || []) as IntegrationProject[];
  },
);

export const integrationsProjectByProjectIdAndBaseIntegrationSelector =
  createSelector(
    [
      (state: State) => state.gen.integrationProject.entities,
      (state: State, params: { integrationId?: number; projectId?: number }) =>
        params,
    ],
    (integrationProject, { integrationId, projectId }) => {
      const ips: IntegrationProject[] = Object.values(
        integrationProject,
      ) as IntegrationProject[];

      return ips?.filter(
        (ip) =>
          ip.integration_id === integrationId && ip.project_id === projectId,
      );
    },
  );

export const integrationProjectsCountSelector = createSelector(
  integrationProjectsByBaseIntegrationSelector,
  (integrationProjects) => integrationProjects.length,
);
