/* eslint-disable react/require-default-props */
import React, { ComponentProps, forwardRef, useRef } from 'react';
import { mergeProps, useTextField, AriaTextFieldOptions } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import Field, { useFieldProps } from '../Field';
import styles from './Input.module.scss';

type FieldProps = Omit<ComponentProps<typeof Field>, 'children' | 'labelProps'>;
type InputProps = Omit<AriaTextFieldOptions<'input'>, 'onChange'> &
  FieldProps & {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tabIndex?: number;
  };

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const { onChange, tabIndex, ...rest } = props;

    const innerRef = useRef<HTMLInputElement>(null);
    const { labelProps, inputProps } = useTextField(rest, innerRef);
    const fieldProps = useFieldProps(props);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (inputProps.onChange) {
        inputProps.onChange(
          event as React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
        );
      }
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <Field labelProps={labelProps} {...fieldProps}>
        {(borderProps, fieldRef) => (
          <input
            {...mergeProps(inputProps, borderProps)}
            data-id={props.id}
            onChange={handleChange}
            tabIndex={tabIndex}
            className={styles.input}
            ref={mergeRefs([innerRef, ref, fieldRef])}
          />
        )}
      </Field>
    );
  },
);

export default Input;
