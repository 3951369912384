import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './AnalyzeStats.module.scss';

type Props = {
  stat: ReactNode;
  statColor: string;
  subtitle?: ReactNode;
  title: ReactNode;
  onClick?: () => void;
};

export default function AnalyzeStats({
  stat,
  statColor,
  subtitle,
  title,
  onClick,
}: Props) {
  const containerCN = classnames(styles.container, {
    [styles.interactive]: onClick,
  });

  return (
    <div className={containerCN} onClick={onClick}>
      <div className={styles.stat} style={{ color: statColor }}>
        {stat}
      </div>
      <div className={styles.title}>{title}</div>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
}
