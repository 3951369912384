/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PolicyRowsApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const policyRowsNormalizer = (
  model: PolicyRowsApi,
  cache: NormalizerCache,
) => {
  if (!cache.policyRows) {
    cache.policyRows = [];
  }
  cache.policyRows.push(model);
};
