import React, { useEffect, useMemo, useState } from 'react';
import { BaseButton } from '@bitmodern/bit-ui';
import { getEnv } from 'src/env';
import { useTranslation } from 'src/i18n/hooks';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import styles from './Drift.module.scss';

export default function Drift() {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [_, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const user = useAppSelector(currentUserSelector);

  useEffect(() => {
    const code = `
    "use strict";
    !function() {
      var t = window.driftt = window.drift = window.driftt || [];
      if (!t.init) {
        if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
        t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
        t.factory = function(e) {
          return function() {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }, t.methods.forEach(function(e) {
          t[e] = t.factory(e);
        }), t.load = function(t) {
          var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
          o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
    }();
    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('${getEnv().drift}');
  `;

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = code;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const { drift } = window;
    if (!drift) return;

    drift.config({
      enableWelcomeMessage: false,
      enableCampaigns: false,
      enableChatTargeting: false,
    });

    drift.on('ready', (api) => {
      setLoaded(true);
      api.widget.hide();

      api.on('message', () => {
        api.openChat();
        setIsOpen(true);
      });

      api.on('chatClose', () => {
        setIsOpen(false);
        api.widget.hide();
      });
    });
  }, []);

  const onClickChat = () => {
    setIsOpen((sIsOpen) => {
      if (sIsOpen) {
        if (window.drift?.api?.widget?.hide) {
          window.drift.api.widget.hide();
        }
        return false;
      }
      if (window.drift?.api?.openChat) {
        window.drift.api.openChat();
      }
      return true;
    });
  };

  const attributes = useMemo(() => {
    if (!user) return undefined;
    return {
      email: user.email,
      name: user.given_name
        ? `${user.given_name} ${user.family_name}`
        : user.email,
      clientId: user.client_id,
    };
  }, [user]);

  useEffect(() => {
    if (attributes && window.drift?.api?.setUserAttributes) {
      window.drift.api.setUserAttributes({
        email: attributes.email,
        name: attributes.name,
        companyId: `TQ_CLIENT_ID_${attributes.clientId}`,
      });
    }
  }, [attributes]);

  if (!loaded) return null;

  return (
    <BaseButton className={styles.chat} onClick={onClickChat}>
      {t('drift.button')}
    </BaseButton>
  );
}
