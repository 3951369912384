import { StatusTypeEnum } from 'src/enums/StatusEnum';
import { statusTypeSelectors } from 'src/gen/domain/status_type/statusTypeSelector';
import { State } from '@bitmodern/redux/store';

export function statusTypesSelector(state: State) {
  return statusTypeSelectors.selectAll(state);
}

export function statusTypeSelector(state: State, id: number) {
  return statusTypeSelectors.selectById(state, id);
}

export function statusTypePositiveSelector(state: State) {
  return statusTypeSelectors
    .selectAll(state)
    .find((status) => status.key === StatusTypeEnum.Positive);
}

export function statusTypeNeutralSelector(state: State) {
  return statusTypeSelectors
    .selectAll(state)
    .find((status) => status.key === StatusTypeEnum.Neutral);
}

export function statusTypeNegativeSelector(state: State) {
  return statusTypeSelectors
    .selectAll(state)
    .find((status) => status.key === StatusTypeEnum.Negative);
}
