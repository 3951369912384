import React from 'react';
import { Button } from '@bitmodern/bit-ui';
import { LaptopRunIcon } from '@bitmodern/bit-ui/icons';
import EmptyList from 'src/components/organisms/EmptyList';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';

export default function EmptyRuns() {
  const { t } = useTranslation();
  const { showModal } = useModalManager();

  const onClick = () => {
    showModal({
      modalName: 'startRun',
      type: 'modal',
    });
  };

  return (
    <EmptyList
      button={<Button onClick={onClick}>{t('emptyRuns.button')}</Button>}
      description={t('emptyRuns.description')}
      icon={<LaptopRunIcon size={130} />}
      title={t('emptyRuns.title')}
    />
  );
}
