import {
  Status,
  BatchService,
  RunResult,
  HttpError,
  Run,
  RunResultStep,
  RunStatusAnalysisApi,
  SuiteTest,
} from '@testquality/sdk';
import { AppThunk, State } from '@bitmodern/redux/store';
import { runResultUpdateOne } from 'src/gen/domain/run_result/runResultSlice';
import {
  runCreateOneThunk,
  runUpdateOneThunk,
} from 'src/gen/domain/run/runThunk';
import { runUpdateOne } from 'src/gen/domain/run/runSlice';
import { runResultStepUpdateOneThunk } from 'src/gen/domain/run_result_step/runResultStepThunk';
import { runSelectors } from 'src/gen/domain/run/runSelector';
import runResultStatusFromSteps from 'src/utils/runResultStatusFromSteps';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { StatusEnum } from 'src/enums/StatusEnum';
import i18n from 'src/i18n';
import { Schemas as VirtualSchemas } from '@bitmodern/redux/state/virtuals/selectors';
import {
  runResultStepsByRunResultSelector,
  runResultStepsByRunStatusSelector,
} from '../RunResultSteps/selectors';
import { selectCanCompleteRun } from './selectors';
import {
  runResultsByRunIdSelector,
  runResultSelector,
} from '../runResults/selectors';
import { statusesSelector } from '../statuses/selectors';
import { statusTypesSelector } from '../statusTypes/selectors';
import {
  suiteTestsByIncludesSelector,
  suiteTestsByPlanSelector,
} from '../suiteTests/selectors';
import { planSuiteTestIncludeByPlanSelector } from '../planSuitesTestsIncludes/selectors';
import { planSelector, rootPlanSelector } from '../plans/selectors';

export function changeRunResultStatusThunk(
  runResult: RunResult,
  status: Status,
  onCompleteRun?: () => void,
): AppThunk {
  return async (dispatch, getState) => {
    const run = runSelectors.selectById(getState(), runResult.run_id);
    if (!run) return;
    const runResultSteps = runResultStepsByRunResultSelector(
      getState(),
      runResult.id,
    );
    const statuses = statusesSelector(getState());
    const statusTypes = statusTypesSelector(getState());
    const prevStatus = runResultStatusFromSteps(
      statuses,
      runResultSteps,
      statusTypes,
    );

    const batch = new BatchService();
    runResultSteps.forEach((runResultStep) => {
      dispatch(
        runResultStepUpdateOneThunk({
          batch,
          optimistic: true,
          id: runResultStep.id,
          data: { ...runResultStep, status_id: status.id },
        }),
      );
    });

    dispatch(
      runResultUpdateOne({
        id: runResult.id,
        changes: { status_id: status.id },
      }),
    );

    // update analysis counter
    const analysisStatuses = updateRunAnalysisStatus(run, status, prevStatus);

    dispatch(
      runUpdateOne({
        id: run.id,
        changes: {
          analysis: {
            defect_count: 0,
            ...run.analysis,
            status: analysisStatuses,
          },
        },
      }),
    );

    batch.executeBatch();
    const canCompleteRun = selectCanCompleteRun(getState(), run.id);
    if (canCompleteRun && onCompleteRun) onCompleteRun();
  };
}

function updateRunAnalysisStatus(
  run: Run,
  status: Status,
  prevStatus?: Status,
) {
  const analysisStatus = run.analysis?.status.find(
    (s) => s.status_key === status.key,
  );

  let analysisStatuses = run.analysis?.status || [];

  analysisStatuses = analysisStatuses.map((s) => {
    if (s.status_key !== prevStatus?.key) return s;
    return { ...s, total: s.total - 1 };
  });

  if (analysisStatus) {
    analysisStatuses = analysisStatuses.map((s) => {
      if (s.status_key !== analysisStatus.status_key) return s;
      return { ...s, total: s.total + 1 };
    });
  } else {
    analysisStatuses = [
      ...analysisStatuses,
      { run_id: run.id, status_key: status.key, total: 1 },
    ];
  }
  return analysisStatuses;
}

function getRunAnalysisStatuses(
  state: State,
  run_id: number,
): RunStatusAnalysisApi[] {
  const statuses = statusesSelector(state);
  const resultsByRun = runResultsByRunIdSelector(state, {
    runId: run_id,
  });

  return statuses.map(({ id: status_id, key: status_key }) => ({
    total: resultsByRun.filter((rResult) => rResult.status_id === status_id)
      .length,
    status_key,
    resultsByRun,
    run_id,
  }));
}

export function onChangeStepElapsedThunk(
  elapsed: number,
  runResultStep: RunResultStep,
): AppThunk {
  return async (dispatch) => {
    dispatch(
      runResultStepUpdateOneThunk({
        id: runResultStep.id,
        optimistic: true,
        data: { ...runResultStep, elapsed },
      }),
    );
  };
}

export function onChangeStepStatusThunk(
  status: Status,
  runResultStep: RunResultStep,
  runResult: RunResult,
  onCompleteRun?: () => void,
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(
      runResultStepUpdateOneThunk({
        id: runResultStep.id,
        optimistic: true,
        data: { ...runResultStep, status_id: status.id },
      }),
    );

    const runResultSteps = runResultStepsByRunResultSelector(
      getState(),
      runResultStep.run_result_id,
    );
    const statuses = statusesSelector(getState());
    const statusTypes = statusTypesSelector(getState());
    const resultStatus = runResultStatusFromSteps(
      statuses,
      runResultSteps,
      statusTypes,
    );

    if (resultStatus) {
      dispatch(
        runResultUpdateOne({
          id: runResultStep.run_result_id,
          changes: { status_id: resultStatus.id },
        }),
      );
    }

    const run = runSelectors.selectById(getState(), runResult.run_id);
    if (!run) return;

    dispatch(
      runUpdateOne({
        id: run.id,
        changes: {
          analysis: {
            defect_count: 0,
            ...run.analysis,
            status: getRunAnalysisStatuses(getState(), run.id),
          },
        },
      }),
    );

    const canCompleteRun = selectCanCompleteRun(getState(), run.id);
    if (canCompleteRun && onCompleteRun) onCompleteRun();
  };
}

export function completeRunThunk(run: Run): AppThunk {
  return async (dispatch) => {
    dispatch(
      runUpdateOneThunk({
        data: {
          is_complete: true,
          is_running: false,
          end_time: new Date().toISOString(),
        },
        id: run.id,
        optimistic: true,
      }),
    );
  };
}

export function finishRunThunk(run: Run): AppThunk {
  return async (dispatch, getState) => {
    const statuses = statusSelectors.selectAll(getState());
    const pendingStatus = statuses.find((s) => s.key === StatusEnum.Pending);
    const skipStatus = statuses.find((s) => s.key === StatusEnum.Skip);
    const passStatus = statuses.find((s) => s.key === StatusEnum.Pass);
    if (!pendingStatus || !skipStatus) return;

    const runResultSteps = runResultStepsByRunStatusSelector(
      getState(),
      run.id,
      pendingStatus.id,
    );

    const batch = new BatchService();

    runResultSteps.forEach((runResultStep) => {
      dispatch(
        runResultStepUpdateOneThunk({
          batch,
          optimistic: true,
          id: runResultStep.id,
          data: { ...runResultStep, status_id: skipStatus.id },
        }),
      );
    });

    const runResultsIds = {};
    runResultSteps.forEach((runResultStep) => {
      runResultsIds[runResultStep.run_result_id] = runResultStep.run_result_id;
    });

    Object.keys(runResultsIds).forEach((key) => {
      const runResult = runResultSelector(getState(), runResultsIds[key]);
      if (!runResult) return;
      if (
        !runResult?.status_id ||
        runResult?.status_id === pendingStatus.id ||
        runResult?.status_id === passStatus?.id
      ) {
        dispatch(
          runResultUpdateOne({
            id: runResult.id,
            changes: { status_id: skipStatus.id },
          }),
        );
      }
    });

    batch.executeBatch();

    dispatch(
      runUpdateOne({
        id: run.id,
        changes: {
          analysis: {
            ...run.analysis!,
            status: getRunAnalysisStatuses(getState(), run.id),
          },
        },
      }),
    );

    dispatch(completeRunThunk(run));
  };
}

export function createRunThunk({
  appVersionPlatVersions,
  is_permanent,
  milestone,
  name,
  planId,
  projectId,
  startTime,
  suiteTestsIds,
  virtual,
}: {
  appVersionPlatVersions: number[];
  is_permanent: boolean;
  milestone?: number;
  name: string;
  planId?: number;
  projectId: number;
  startTime?: Date;
  suiteTestsIds?: number[];
  virtual?: VirtualSchemas;
}): AppThunk<any> {
  return (dispatch, getState) => {
    let ids = suiteTestsIds;

    const plan = planId
      ? planSelector(getState(), planId)
      : rootPlanSelector(getState(), projectId);

    if (!plan)
      return Promise.reject(
        new HttpError('No plan', 'noPlan', i18n.t('errors.error')),
      );

    if (!ids?.length) {
      let suiteTests: SuiteTest[] = [];
      if (plan.is_root) {
        suiteTests = suiteTestsByPlanSelector(getState(), plan.id);
        ids = [];
      } else {
        const planSuiteTestIncludes = planSuiteTestIncludeByPlanSelector(
          getState(),
          { planId },
        );
        suiteTests = suiteTestsByIncludesSelector(
          getState(),
          planSuiteTestIncludes.map((i) => i.id),
          projectId,
        );
      }
      ids = suiteTests.map((st) => st.id);
    }

    if (!ids.length) {
      return Promise.reject(
        new HttpError(
          i18n.t('errors.noTestToRun'),
          'noTestToRun',
          i18n.t('errors.error'),
        ),
      );
    }

    return dispatch(
      runCreateOneThunk({
        data: {
          name,
          is_permanent,
          milestone_id: milestone,
          start_time: (startTime || new Date()).toISOString(),
          is_complete: false,
          is_running: true,
          plan_id: plan.id,
          project_id: projectId,
          // @ts-expect-error // not in type
          app_version_plat_version_id: appVersionPlatVersions,
          suiteTest: ids,
          analysis: {
            defect_count: 0,
            status: [],
          },
          virtual,
        },
      }),
    );
  };
}
