import classnames from 'classnames';
import React, { Children, cloneElement, ReactElement } from 'react';
import Avatar from '../Avatar';
import styles from './AvatarGroup.module.scss';
import { Tooltip } from 'src/packages/bit-ui';

type AvatarElement = ReactElement<typeof Avatar>;

type Props = {
  children: AvatarElement | AvatarElement[];
  className?: string;
  max?: number;
};

export default function AvatarGroup({
  children: childrenProps,
  className,
  max = 4,
}: Props) {
  const children = Children.toArray(childrenProps).splice(
    0,
    4 - 1,
  ) as AvatarElement[];
  // @ts-expect-error
  const size = children[0].props.size || 32;
  const total = Children.count(childrenProps);
  const more = total - max <= 99 ? total - max : 99;

  return (
    <div className={`${styles.avatarGroup} ${className}`}>
      {children.map((child, index) => {
        return (
          <Tooltip tooltip={child.props.name} key={child.props.name}>
            <span key={index}>
              {cloneElement(child, {
                // @ts-expect-error
                className: classnames(child.props.className, styles.avatar),
                // @ts-expect-error
                style: { marginLeft: child.size || 32 / -8 },
              })}
            </span>
          </Tooltip>
        );
      })}

      {more > 0 && (
        <span className={styles.more} style={{ height: size, width: size }}>
          +{more}
        </span>
      )}
    </div>
  );
}
