import React, { ComponentProps } from 'react';
import Yup from 'src/utils/yup';
import { useFormik } from 'formik';
import { ProjectColors, ProjectIcons } from 'src/enums/ProjectEnums';
import { IntegrationProject, Project } from '@testquality/sdk';
import ProjectForm from './ProjectForm';

export type InitialValues = {
  name?: string;
  description?: string;
  icon?: ProjectIcons;
  color?: ProjectColors;
  baseIntegrationId?: number;
  integrationProject?: Partial<IntegrationProject>;
};

const defaultInitialValues: InitialValues = {
  name: '',
  description: '',
  icon: ProjectIcons.Test,
  color: ProjectColors.Blue,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('projectForm.name'),
  icon: Yup.string().required().label('projectForm.icon'),
  color: Yup.string().required().label('projectForm.color'),
});

type ProjectFormProps = ComponentProps<typeof ProjectForm>;

type Props = Pick<ProjectFormProps, 'fields' | 'setupIntegrations'> & {
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => void;
  project?: Project;
  submitClassName?: string;
};

export default function ProjectFormContainer({
  fields,
  initialValues,
  onSubmit,
  project,
  setupIntegrations,
  submitClassName,
}: Props) {
  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: initialValues
      ? { ...defaultInitialValues, ...initialValues }
      : defaultInitialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <ProjectForm
      edit={Boolean(initialValues)}
      fields={fields}
      formik={formik}
      project={project}
      setupIntegrations={setupIntegrations}
      submitClassName={submitClassName}
    />
  );
}
