import React, { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import BaseButton from '../BaseButton';
import Loading from '../Loading';
import styles from './Button.module.scss';

type Colors =
  | 'accent'
  | 'error'
  | 'success'
  | 'primaryMain'
  | 'primaryDark'
  | 'primaryLight'
  | 'onWarning';
type Sizes = 'small' | 'medium' | 'large';

type Props = ComponentProps<typeof BaseButton> & {
  color?: Colors;
  fullWidth?: boolean;
  icon?: ReactNode;
  loading?: boolean;
  rounded?: boolean;
  size?: Sizes;
};

const loadingColors: { [key in Colors]: string } = {
  accent: vars.onAccent,
  error: vars.onError,
  success: vars.onSuccess,
  primaryDark: vars.textPrimary,
  primaryLight: vars.textPrimary,
  primaryMain: vars.textPrimary,
  onWarning: vars.onWarning,
};

const loadingSizes: { [key in Sizes]: number } = {
  small: 14,
  medium: 18,
  large: 18,
};

export default function Button({
  children,
  className = '',
  color = 'accent',
  disabled,
  fullWidth = false,
  icon,
  loading,
  name,
  onClick,
  rounded = false,
  size = 'medium',
  type,
  ...rest
}: Props) {
  const buttonCN = classnames(styles.button, styles[color], className, {
    [styles.rounded]: rounded,
    [styles.small]: size === 'small',
    [styles.fullWidth]: fullWidth,
  });

  const handleClick = () => {
    if (!onClick || loading) return;
    onClick();
  };

  return (
    <BaseButton
      className={buttonCN}
      disabled={disabled}
      onClick={handleClick}
      name={name}
      type={type}
      {...rest}>
      {loading && (
        <Loading
          center={false}
          color={loadingColors[color]}
          className={styles.loading}
          delay={0}
          size={loadingSizes[size]}
        />
      )}
      {!loading && icon && <span className={styles.icon}>{icon}</span>}
      {children}
    </BaseButton>
  );
}
