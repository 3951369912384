/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { ClientApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { virtualNormalizer } from '../virtual/virtualNormalize';
import { signupOptionNormalizer } from '../signup_option/signupOptionNormalize';

export const clientNormalizer = (model: ClientApi, cache: NormalizerCache) => {
  if (!cache.client) {
    cache.client = [];
  }
  cache.client.push(model);

  if (model.virtual && model.virtual.length) {
    model.virtual.forEach((child) => {
      virtualNormalizer(child, cache);
    });
    model.virtual = undefined;
  }
  if (model.signup_option && model.signup_option.length) {
    model.signup_option.forEach((child) => {
      signupOptionNormalizer(child, cache);
    });
    model.signup_option = undefined;
  }
};
