/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { notificationsAdapter } from './notificationsAdapter';

export const notificationsSelectors = notificationsAdapter.getSelectors(
  (state: State) => state.gen.notifications,
);
