/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { Helmet } from 'react-helmet';
import { useOverlayTriggerState } from 'react-stately';
import { routes } from 'src/components/Router';
import {
  Button,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
  notification,
  PanelHeader,
  Spacer,
  Surface,
  Switch,
  Tab,
  Tabs,
  Tooltip,
} from '@bitmodern/bit-ui';
import {
  AddFolderIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  PlayIcon,
  SettingsIcon,
  TestCreateIcon,
  WatchIcon,
  WatchRemoveIcon,
} from '@bitmodern/bit-ui/icons';
import {
  ConfirmDialog,
  Filters,
  Plan,
  RunAndDefectsList,
} from 'src/components/organisms';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { Plan as PlanModel, Test, SuiteTest } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { useMaker, useParamSelector } from 'src/packages/redux/hooks';
import { makeDefectsByPlan } from '@bitmodern/redux/state/defects/selectors';
import { selectRunsByPlanId } from '@bitmodern/redux/state/runs/selectors';
import useModalManager from 'src/hooks/useModalManager';
import useMutation from 'src/hooks/useMutation';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import { watchCycleThunk } from '@bitmodern/redux/state/watch/thunks';
import { watchByCycleIdSelector } from '@bitmodern/redux/state/watch/selectors';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import vars from 'src/export.scss';
import styles from './PlanView.module.scss';
import PlanOverviewView from '../PlanOverviewView/PlanOverviewView';
import { rootPlanSuiteSelector } from '@bitmodern/redux/state/planSuites/selectors';

type Props = {
  current?: number;
  onClickTest: (test: Test, suiteTest: SuiteTest) => void;
  onDelete: () => Promise<any>;
  plan: PlanModel;
};

enum PlanTabs {
  Overview,
  Tests,
  Defects,
}

export default function PlanView({
  current,
  onClickTest,
  onDelete,
  plan,
}: Props) {
  const { t } = useTranslation();
  const [planTabs, setPlanTabs] = useState(PlanTabs.Tests);
  const deleteDialog = useOverlayTriggerState({});
  const { projectId } = useParams<typeof routes.PROJECT.params>();
  const dispatch = useAppDispatch();
  const { showModal } = useModalManager();

  const cycleWatch = useAppSelector((state) =>
    watchByCycleIdSelector(state, plan.id),
  );
  const plansFilter = useAppSelector((state) =>
    filtersByTypeSelector(state, 'plans'),
  );

  const runs = useAppSelector((state) => selectRunsByPlanId(state, plan.id));
  const defects = useMaker(makeDefectsByPlan, { planId: plan.id });

  const rootPlanSuite = useParamSelector(rootPlanSuiteSelector, {
    projectId: parseInt(projectId, 10),
  });

  const onConfirmDelete = useCallback(() => {
    return onDelete().finally(deleteDialog.close);
  }, [deleteDialog, onDelete]);

  const deletePlanMutation = useMutation(onConfirmDelete);

  const onStartRun = () => {
    showModal({
      modalName: 'startRun',
      modalProps: { planId: plan.id },
      type: 'modal',
    });
  };

  const onWatchCycle = () => {
    dispatch(watchCycleThunk(plan.id)).then(() =>
      notification.open({
        type: 'success',
        message: t('notifications.watchAdded.message'),
        description: t('notifications.watchAdded.description'),
      }),
    );
  };

  const onUnwatchCycle = () => {
    dispatch(watchDeleteOneThunk({ id: cycleWatch?.id }))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notifications.watchRemoved.message'),
          description: t('notifications.watchRemoved.description'),
        }),
      );
  };

  const onClickToggleHide = () => {
    dispatch(
      toggleFilterAction({
        type: 'plans',
        filter: 'exclusions',
        value: !plansFilter.exclusions,
      }),
    );
  };

  const onEditPlan = () => {
    showModal({
      modalName: 'editPlan',
      modalProps: { plan },
      type: 'modal',
    });
  };

  const onClickCreateTest = () => {
    showModal({
      modalName: 'testCreate',
      modalProps: { planSuite: rootPlanSuite, planId: plan?.id },
      type: 'modal',
    });
  };

  const onClickCreateFolder = () => {
    showModal({
      modalName: 'suiteCreate',
      modalProps: { planSuite: rootPlanSuite, planId: plan?.id },
      type: 'modal',
    });
  };

  const onPlanConfiguration = () => {
    showModal({
      modalName: 'planConfiguration',
      modalProps: { planId: plan?.id },
      type: 'modal',
    });
  };

  const more = (
    <Dropdown
      popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
      overlay={
        <Menu>
          {cycleWatch ? (
            <MenuItem
              startAdornment={
                <WatchRemoveIcon color={vars.textPrimary} size={18} />
              }
              onClick={onUnwatchCycle}>
              {t('plans.actions.unwatchPlan')}
            </MenuItem>
          ) : (
            <MenuItem
              startAdornment={<WatchIcon color={vars.textPrimary} size={18} />}
              onClick={onWatchCycle}>
              {t('plans.actions.watchPlan')}
            </MenuItem>
          )}
          <MenuItem
            startAdornment={<SettingsIcon color={vars.textPrimary} size={18} />}
            onClick={onPlanConfiguration}>
            {t('plans.actions.configuration')}
          </MenuItem>
          <MenuItem
            startAdornment={<EditIcon color={vars.textPrimary} size={18} />}
            onClick={onEditPlan}>
            {t('plans.actions.edit')}
          </MenuItem>
          <MenuItem
            startAdornment={<DeleteIcon color={vars.textPrimary} size={18} />}
            onClick={deleteDialog.open}>
            {t('plans.actions.delete')}
          </MenuItem>
        </Menu>
      }>
      <IconButton title={t('plans.actions.more')}>
        <MoreIcon color={vars.textPrimary} size={18} />
      </IconButton>
    </Dropdown>
  );

  return (
    <>
      <Helmet>
        <title>{plan.name}</title>
      </Helmet>
      <Surface className={styles.plan}>
        <PanelHeader
          title={plan.name}
          actions={
            <Spacer>
              {planTabs === PlanTabs.Tests && (
                <>
                  <Filters type="plans" />
                  <Tooltip
                    tooltip={
                      plansFilter.exclusions
                        ? t('plans.actions.showAllTests')
                        : t('plans.actions.hideAllTests')
                    }>
                    <span className={styles.actionSwitch}>
                      <Switch
                        checked={!plansFilter.exclusions}
                        onChange={onClickToggleHide}
                      />
                    </span>
                  </Tooltip>
                  <IconButton
                    onClick={onClickCreateFolder}
                    title={t('tests.actions.createFolder')}>
                    <AddFolderIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                  <IconButton
                    onClick={onClickCreateTest}
                    title={t('tests.actions.createTest')}>
                    <TestCreateIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                </>
              )}
              {more}
              <Button
                icon={<PlayIcon color={vars.onAccent} />}
                onClick={onStartRun}
                size="small">
                {t('plans.runCycle')}
              </Button>
            </Spacer>
          }
        />
        <Tabs tab={planTabs} onChange={setPlanTabs} withBarBackground>
          <Tab id={PlanTabs.Overview}>{t('plans.tabs.overview')}</Tab>
          <Tab id={PlanTabs.Tests}>{t('plans.tabs.test')}</Tab>
          <Tab id={PlanTabs.Defects}>{t('plans.tabs.defects')}</Tab>
        </Tabs>
        <div className={styles.panelContent}>
          {planTabs === PlanTabs.Overview && <PlanOverviewView plan={plan} />}
          {planTabs === PlanTabs.Tests && (
            <Plan current={current} onClickTest={onClickTest} plan={plan} />
          )}
          {planTabs === PlanTabs.Defects && (
            <RunAndDefectsList runs={runs} defects={defects} />
          )}
        </div>
        <ConfirmDialog
          loading={deletePlanMutation.isLoading}
          onCancel={deleteDialog.close}
          onConfirm={deletePlanMutation.mutate}
          open={deleteDialog.isOpen}
          title={t('plans.deletePlan.title')}>
          {t('plans.deletePlan.content')}
        </ConfirmDialog>
      </Surface>
    </>
  );
}
