/* eslint-disable no-unused-expressions */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useId } from 'react-aria';
import EasyMDE from 'easymde';
import showdown from 'showdown';
import { processAttachmentPath } from 'src/utils/fileHelper';
import {
  getTestAttachments,
  getRunResultAttachments,
  postAttachment,
  deleteAttachment,
  RelatedType,
} from '@bitmodern/services/AttachmentService';
import { Attachment, showNotificationError } from '@testquality/sdk';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import CodeMirror from 'codemirror';
import { useTranslation } from 'src/i18n/hooks';
import Loading from '../Loading/Loading';
import styles from './EasyMDE.module.scss';
import 'easymde/dist/easymde.min.css';
import './easy-mde.scss';
import 'codemirror/mode/handlebars/handlebars';
import notification from '../notification';
import { notificationErrorTimeout } from '../../../constants';

const showdownConverter = new showdown.Converter();
showdownConverter.setFlavor('github');
showdownConverter.setOption('tables', true);

const attachmentRegExp = new RegExp(/[(]((http:|https:).+)[)]/);
const imageRegExp = new RegExp(/((http:|https:).+)(')/);

type State =
  | 'heading'
  | 'bold'
  | 'italic'
  | 'quote'
  | 'code'
  | 'link'
  | 'unordered-list'
  | 'ordered-list';

type Props = {
  autofocus?: boolean;
  className?: string;
  fileSizeLimit?: number;
  id?: string;
  minHeight?: string;
  onChange?: (value) => void;
  onChangeState?: (state: State) => void;
  onFileUpload?: (attachment: Attachment) => void;
  onFocus?: () => void;
  options?: EasyMDE.Options;
  parentId: number;
  parentType: RelatedType;
  value?: string;
  withCapabilityTemplateEditingFor?: CapabilityEnum | null;
  withFormatting?: boolean;
};

function MarkdownEditor(
  {
    autofocus,
    className = '',
    fileSizeLimit = 10,
    id: propId,
    minHeight = '94px',
    onChange,
    onChangeState,
    onFileUpload,
    onFocus,
    options = {},
    parentId,
    parentType,
    value = '',
    withCapabilityTemplateEditingFor,
    withFormatting = true,
  }: Props,
  ref,
) {
  const [uploading, setUploading] = useState(false);
  const id = useId(propId);
  const deletedAttachments = useRef<string[]>([]);
  const easyMDE = useRef<EasyMDE | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    togglePreview: () => {
      if (easyMDE.current) EasyMDE.togglePreview(easyMDE.current);
    },
    toggleHeadingSmaller: () => {
      if (easyMDE.current) EasyMDE.toggleHeadingSmaller(easyMDE.current);
    },
    toggleBold: () => {
      if (easyMDE.current) EasyMDE.toggleBold(easyMDE.current);
    },
    toggleItalic: () => {
      if (easyMDE.current) EasyMDE.toggleItalic(easyMDE.current);
    },
    toggleBlockquote: () => {
      if (easyMDE.current) EasyMDE.toggleBlockquote(easyMDE.current);
    },
    toggleCodeBlock: () => {
      if (easyMDE.current) EasyMDE.toggleCodeBlock(easyMDE.current);
    },
    drawLink: () => {
      if (easyMDE.current) EasyMDE.drawLink(easyMDE.current);
    },
    toggleUnorderedList: () => {
      if (easyMDE.current) EasyMDE.toggleUnorderedList(easyMDE.current);
    },
    toggleOrderedList: () => {
      if (easyMDE.current) EasyMDE.toggleOrderedList(easyMDE.current);
    },
    drawImage: () => {
      if (easyMDE.current) EasyMDE.drawImage(easyMDE.current);
    },
    drawTable: () => {
      if (easyMDE.current) EasyMDE.drawTable(easyMDE.current);
    },
    focus: () => {
      if (easyMDE.current) easyMDE.current.codemirror.focus();
    },
    drawTemplateVariable: (templateVariable: string) => {
      updateContent(templateVariable);
    },
    uploadFile: () => {
      if (fileRef.current) {
        fileRef.current.click();
      }
    },
  }));

  useLayoutEffect(() => {
    const element = document.getElementById(id);
    if (easyMDE.current === null && element) {
      easyMDE.current = new EasyMDE({
        ...options,
        element,
        imageUploadFunction: fileUploadFunction,
        initialValue: htmlToMarkdown(value || ''),
        minHeight,
        spellChecker: false,
        status: false,
        toolbar: false,
        uploadImage: true,
        theme:
          !withFormatting && !withCapabilityTemplateEditingFor
            ? 'jira'
            : undefined,
        ...(withCapabilityTemplateEditingFor && {
          overlayMode: {
            combine: false,
            mode: CodeMirror.modes.handlebars({}),
          },
        }),
      });
      easyMDE.current.codemirror.addKeyMap({
        Tab: false,
        'Shift-Tab': false,
      });
      addEvents();
    }

    return () => {
      easyMDE.current?.toTextArea();
      easyMDE.current = null;
      deleteAttachments();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value !== easyMDE.current?.value() && typeof value === 'string') {
      easyMDE.current?.value(value || '');
    }
  }, [value]);

  useEffect(() => {
    if (autofocus && easyMDE.current) {
      easyMDE.current.codemirror.focus();
    }
  }, [autofocus]);

  // image upload function
  const fileUploadFunction = useCallback(
    (file: File) => {
      if (file.size / 1000000 > fileSizeLimit) {
        notification.open({
          type: 'error',
          message: t('errors.file.tooBig'),
          duration: notificationErrorTimeout,
        });
        return undefined;
      }

      setUploading(true);
      return postAttachment(file, parentType, parentId)
        .then((attachment: Attachment) => {
          if (onFileUpload) {
            onFileUpload(attachment);
          }
          let content = '';
          let isImage: boolean = false;
          const EXT = ['jpg', 'png'];
          const fileName = attachment.original_file_name;
          const fileExt = fileName.split('.').pop();
          isImage = EXT.includes(fileExt!.toLowerCase());
          const url = processAttachmentPath(attachment.url);
          content = isImage
            ? content.concat(`![](${url})`)
            : content.concat(`[${fileName}](${url})\n\n`);
          updateContent(content);
        })
        .catch(showNotificationError)
        .finally(() => {
          setUploading(false);
        });
    },
    [onFileUpload, fileSizeLimit, parentType, parentId, t],
  );

  const updateContent = (content) => {
    easyMDE.current?.codemirror?.focus();
    easyMDE.current?.codemirror
      ?.getDoc()
      .replaceRange(
        content,
        easyMDE.current?.codemirror?.getDoc()?.getCursor(),
      );
    setUploading(false);
  };

  const addEvents = () => {
    if (easyMDE.current) {
      easyMDE.current.codemirror.on('cursorActivity', onCursorActivity);
      easyMDE.current.codemirror.on('focus', handleOnFocus);
    }
  };

  const handleOnFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);

  const onCursorActivity = useCallback(() => {
    if (onChangeState) {
      // @ts-expect-error not typed in library
      onChangeState({ ...easyMDE.current?.getState() });
    }
  }, [onChangeState]);

  const htmlToMarkdown = (input: string) => {
    if (input.match(new RegExp(/<[^<]+>/))) {
      let output = showdownConverter.makeMarkdown(input);
      output = output.replace(new RegExp(/\\/g), '');
      return output;
    }
    return input;
  };

  const onChangeEventHandler = useCallback(
    (cm, change) => {
      if (
        change.origin === '+delete' ||
        change.origin === 'cut' ||
        change.origin === 'undo'
      ) {
        if (change.origin === 'undo') {
          change.text.forEach((element: string) => {
            if (isAttachment(element)) {
              const attachment: string = getAttachmentName(element);
              if (deletedAttachments.current.includes(attachment)) {
                deletedAttachments.current.splice(
                  deletedAttachments.current.indexOf(attachment),
                  1,
                );
              }
            }
          });
        } else {
          change.removed.forEach((element: string) => {
            if (isAttachment(element)) {
              deletedAttachments.current.push(getAttachmentName(element));
            }
          });
        }
      }

      if (onChange) {
        onChange(cm?.getValue() || '');
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (easyMDE.current) {
      easyMDE.current.codemirror.on('change', onChangeEventHandler);
    }

    return () => {
      if (easyMDE.current) {
        easyMDE.current.codemirror.off('change', onChangeEventHandler);
      }
    };
  }, [onChangeEventHandler]);

  const deleteTestAttachments = () => {
    getTestAttachments(parentId).then((attachments) => {
      attachments
        .filter((attachment) => {
          const url = attachment.url!.substring(
            attachment.url!.lastIndexOf('/') + 1,
          );
          return deletedAttachments.current.includes(url);
        })
        .forEach((attachment) => {
          deleteAttachment(attachment.id);
        });
    });
  };

  const deleteRunResultAttachments = () => {
    getRunResultAttachments(parentId).then((attachments) => {
      attachments
        .filter((attachment) => {
          const url = attachment.url!.substring(
            attachment.url!.lastIndexOf('/') + 1,
          );
          return deletedAttachments.current.includes(url);
        })
        .forEach((attachment) => {
          deleteAttachment(attachment.id);
        });
    });
  };

  const deleteAttachments = () => {
    if (deletedAttachments.current.length > 0) {
      switch (parentType) {
        case 'Test':
          deleteTestAttachments();
          break;
        case 'RunResult':
          deleteRunResultAttachments();
          break;
        default:
          break;
      }
    }
  };

  const onChangeFileInput = useCallback(
    (event) => {
      if (event.target.files[0]) {
        fileUploadFunction(event.target.files[0]);
      }
    },
    [fileUploadFunction],
  );

  const isAttachment = (element: string) => {
    return element.match(attachmentRegExp) || element.match(imageRegExp);
  };

  const getAttachmentName = (element: string) => {
    const url = element.match(attachmentRegExp) || element.match(imageRegExp);
    if (url) {
      return url[1].substring(url[1].lastIndexOf('/') + 1);
    }
    return '';
  };

  return (
    <div
      className={`${styles.container} ${className}`}
      id={`${id}-container`}
      style={{ minHeight }}>
      <textarea className={easyMDE.current ? '' : styles.hidden} id={id} />
      {uploading && (
        <div className={styles.spinner}>
          <Loading />
        </div>
      )}
      <input
        ref={fileRef}
        onChange={onChangeFileInput}
        type="file"
        className={styles.fileInput}
        tabIndex={-1}
      />
    </div>
  );
}

export default React.memo(forwardRef(MarkdownEditor));
