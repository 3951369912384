/* eslint-disable react/prop-types */
import React, { CSSProperties, memo, useCallback, useState } from 'react';
import { Test, RunResult, Run } from '@testquality/sdk';
import { useOverlayTriggerState } from 'react-stately';
import { BugIcon, PlayIcon, MoreIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import { RunResultTreeItem } from '@bitmodern/redux/state/runResults/selectors';
import vars from 'src/export.scss';
import useModalManager from 'src/hooks/useModalManager';
import TreeBase from '../TreeBase';
import CommandBarRunResults from '../CommandBarRunResults';
import { SUITE_TYPE, TEST_TYPE } from '../TreeBase/treeTypes';
import SuiteListed from '../SuiteListed';
import RunResultListed from '../RunResultListed';
import { useIntegrationCheck } from '../../../hooks/useIntegrationCheck';

type Props = {
  currentRunResult?: RunResult;
  run: Run;
  onClickTest: (test: Test, runResult: RunResult) => void;
  runsTree: RunResultTreeItem[];
  planId: string;
};

function RunTree({
  currentRunResult,
  run,
  onClickTest,
  runsTree,
  planId,
}: Props) {
  const [checkedItems, setCheckedItems] = useState<RunResultTreeItem[]>([]);
  const commandBar = useOverlayTriggerState({});
  const { site, projectId } = useParams<typeof routes.PROJECT.path>();
  const { showModal } = useModalManager();
  const { openCreateDefect } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const onCheckItems = useCallback((items) => {
    setCheckedItems(items);
  }, []);

  const actions = [
    {
      icon: <BugIcon color={vars.textPrimary} size={16} />,
      label: 'Log Defect',
      handler: () => {
        if (checkedItems && checkedItems.length > 0) {
          const runResultIds = checkedItems
            .filter((item) => item.type === TEST_TYPE)
            .flatMap((item) => item.data.runResults)
            .map((runResult) => runResult.id);
          return openCreateDefect(run.id, runResultIds);
        }
        return Promise.resolve();
      },
    },
    {
      icon: <PlayIcon color={vars.textPrimary} size={16} />,
      label: 'Re-Run',
      handler: () => {
        if (checkedItems && checkedItems.length > 0) {
          const suiteTestsIds = checkedItems
            .filter((item) => item.type === TEST_TYPE)
            .flatMap((item) => item.data.suiteTest)
            .map((suiteTest) => suiteTest.id);

          showModal({
            modalName: 'startRun',
            modalProps: {
              suiteTestsIds,
              planId: parseInt(planId, 10),
              isPermanent: run.is_permanent,
              milestoneId: run.milestone_id,
            },
            type: 'modal',
          });
        }
        return Promise.resolve();
      },
    },
    {
      icon: <MoreIcon color={vars.textPrimary} size={16} />,
      label: 'More',
      handler: commandBar.open,
    },
  ];

  return (
    <>
      <TreeBase
        actions={actions}
        canDrag={false}
        id={run.id.toString()}
        onCheckItems={onCheckItems}
        onSelectAll={onCheckItems}
        onDeselect={onCheckItems}
        renderNodeType={{
          [TEST_TYPE]: (item) => {
            const { node, treeIndex, path, onCheckParentChange } = item;
            const { runResults, test } = node.data as {
              test: Test;
              runResults: RunResult[];
            };
            const selected = runResults.some(
              (rr) => rr.id === currentRunResult?.id,
            );

            return (
              <RunResultListed
                checked={node.checked}
                nestingLevel={path.length - 1}
                onChangeChecked={(value) => {
                  if (onCheckParentChange)
                    onCheckParentChange(value, { node, treeIndex, path });
                }}
                onClick={() => {
                  const runResult =
                    runResults.find(
                      (rr) =>
                        rr.app_version_plat_version_id ===
                        currentRunResult?.app_version_plat_version_id,
                    ) || runResults[0];
                  onClickTest(test, runResult);
                }}
                runResults={runResults}
                selected={selected}
                test={test}
              />
            );
          },
          [SUITE_TYPE]: (item) => {
            const {
              node,
              style,
              treeIndex,
              path,
              onCheckParentChange,
              toggleChildrenVisibility,
            } = item;
            const { data } = node;
            return (
              <SuiteListed
                checked={node.checked}
                collapsed={node.expanded || false}
                collapsible={Boolean(node.children?.length)}
                nestingLevel={path.length - 1}
                onChangeChecked={(value) => {
                  if (onCheckParentChange)
                    onCheckParentChange(value, { node, treeIndex, path });
                }}
                onClick={() => {
                  if (!toggleChildrenVisibility) return;
                  toggleChildrenVisibility({ node, path, treeIndex });
                }}
                planSuite={data.planSuite}
                style={style as CSSProperties}
                suite={data.suite}
                withActions={false}
              />
            );
          },
        }}
        treeData={runsTree}
      />
      <CommandBarRunResults
        items={checkedItems}
        onClose={commandBar.close}
        open={commandBar.isOpen}
      />
    </>
  );
}

export default memo(RunTree);
