/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { SubscriptionsApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { subscriptionUserNormalizer } from '../subscription_user/subscriptionUserNormalize';
import { billingContactNormalizer } from '../billing_contact/billingContactNormalize';

export const subscriptionsNormalizer = (
  model: SubscriptionsApi,
  cache: NormalizerCache,
) => {
  if (!cache.subscriptions) {
    cache.subscriptions = [];
  }
  cache.subscriptions.push(model);

  if (model.subscription_user && model.subscription_user.length) {
    model.subscription_user.forEach((child) => {
      subscriptionUserNormalizer(child, cache);
    });
    model.subscription_user = undefined;
  }
  if (model.billing_contact && model.billing_contact.length) {
    model.billing_contact.forEach((child) => {
      billingContactNormalizer(child, cache);
    });
    model.billing_contact = undefined;
  }
};
