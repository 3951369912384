import React from 'react';
import AntSwitch, { SwitchChangeEventHandler } from 'antd/es/switch';
import 'antd/es/switch/style/index.less';

type Props = {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
  onChange?: SwitchChangeEventHandler;
};

export default function Switch({
  className,
  checked,
  disabled,
  loading,
  checkedChildren,
  unCheckedChildren,
  onChange,
}: Props) {
  return (
    <AntSwitch
      className={className}
      checked={checked}
      disabled={disabled}
      loading={loading}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      onChange={onChange}
    />
  );
}
