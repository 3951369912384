import { State } from '@bitmodern/redux/store';
import { planSuiteTestIncludeSelectors } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeSelector';
import { createSelector } from 'reselect';

export function planSuiteTestIncludesSelector(state: State) {
  return planSuiteTestIncludeSelectors.selectAll(state);
}

export function planSuiteTestIncludeSelector(state: State, id: number) {
  return planSuiteTestIncludeSelectors.selectById(state, id);
}

export function planIncludeSelectorByTest(
  state: State,
  planId: number,
  suiteId: number,
  testId: number,
) {
  return planSuiteTestIncludesSelector(state).find(
    (include) =>
      include.test_id === testId &&
      include.suite_id === suiteId &&
      include.plan_id === planId,
  );
}

export function planIncludeSelectorBySuite(
  state: State,
  planId: number,
  suiteId: number,
) {
  return planSuiteTestIncludesSelector(state).find(
    (include) => include.suite_id === suiteId && include.plan_id === planId,
  );
}

export const planSuiteTestIncludeByPlanSelector = createSelector(
  [
    planSuiteTestIncludesSelector,
    (_, { planId }: { planId?: number }) => planId,
  ],
  (planSuiteTestIncludes, planId) => {
    return planSuiteTestIncludes.filter((p) => p.plan_id === planId);
  },
);

export const planSuiteTestIncludeByProjectSelector = createSelector(
  [
    planSuiteTestIncludesSelector,
    (_, { projectId }: { projectId?: number }) => projectId,
  ],
  (planSuiteTestIncludes, projectId) => {
    return planSuiteTestIncludes.filter((p) => p.project_id === projectId);
  },
);

export const includesByTestSelector = createSelector(
  [
    planSuiteTestIncludesSelector,
    (_, { testId }: { testId?: number }) => testId,
  ],
  (planSuiteTestIncludes, testId) => {
    return planSuiteTestIncludes.filter(
      (include) => include.test_id === testId,
    );
  },
);

export function includesBySuiteTestSelector(
  state: State,
  { testId, suiteId }: { testId?: number; suiteId?: number },
) {
  return planSuiteTestIncludesSelector(state).filter(
    (include) => include.test_id === testId && include.suite_id === suiteId,
  );
}
