import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getResponse,
  showNotificationError,
  ReturnToken,
  HttpError,
} from '@testquality/sdk';
import { userDeleteOneThunk } from 'src/gen/domain/user/userThunk';
import { ThunkArgs } from 'src/gen/actions/ThunkArgs';
import { getClient } from 'src/Client';
import { AppThunk } from '@bitmodern/redux/store';
import { currentUserSelector } from '../authentication/selectors';
import { logoutThunk } from '../authentication/thunks';

export function deleteUserThunk(userId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    await dispatch(userDeleteOneThunk({ id: userId }));

    if (userId === currentUser?.id) {
      await dispatch(logoutThunk());
    }
  };
}

export const userInviteThunk = createAsyncThunk(
  'user/InviteThunk',
  async ({
    handleError = true,
    params,
  }: ThunkArgs<{ invites: string; is_web: boolean }>) => {
    try {
      const { api } = getClient();
      return await api({
        url: '/system/auth/invite_user',
        params,
      });
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const userCompleteInviteThunk = createAsyncThunk(
  'user/userCompleteInviteThunk',
  async ({
    handleError = true,
    params,
  }: ThunkArgs<{
    user: string;
    g_recaptcha_response: string;
  }>): Promise<ReturnToken> => {
    try {
      const { api } = getClient();
      return await getResponse(api, {
        url: '/system/auth/complete_invite_user',
        params,
      });
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

type InviteUsersArgs = {
  onClose?: (...args: any[]) => void;
  onSuccess?: (...args: any[]) => void;
  values:
    | {
        type: 'email';
        emails: string;
        accessRole?: number;
      }
    | {
        type: 'invite';
        invites: Array<{
          email: string;
          accessRole?: number;
        }>;
      };
};

export function inviteUsersThunk({
  onSuccess,
  onClose,
  values,
}: InviteUsersArgs) {
  return async (dispatch) => {
    const invites = JSON.stringify(
      values.type === 'invite'
        ? values.invites.map(({ email, accessRole }) => ({
            email,
            access_role_id: accessRole,
          }))
        : values.emails.split(/[\s,]+/).map((email) => ({
            email,
            access_role_id: values.accessRole,
          })),
    );

    const response = await dispatch(
      userInviteThunk({ params: { invites, is_web: true } }),
    );

    if (onSuccess && !response.error) onSuccess();

    if (onClose) onClose();

    return response;
  };
}
