import { nativeDefectResSelectors } from 'src/gen/domain/native_defect_res/nativeDefectResSelector';
import { State } from '@bitmodern/redux/store';

export function nativeDefectResSelector(state: State) {
  return nativeDefectResSelectors.selectAll(state);
}

export function nativeDefectResByIntegrationProjectSelectors(
  state: State,
  integrationProjectId: number,
) {
  return nativeDefectResSelector(state).filter(
    (nativeDefectRes) =>
      nativeDefectRes.integration_project_id === integrationProjectId,
  );
}
