import { State } from '@bitmodern/redux/store';
import { createSelector } from 'reselect';
import suiteTestAdapter from 'src/gen/domain/suite_test/suiteTestAdapter';
import { SuiteTest } from '@testquality/sdk';

import { planSuiteTestIncludeSelector } from '../planSuitesTestsIncludes/selectors';
import { planSuitesByPlanSelector } from '../planSuites/selectors';
import { rootPlanSelector } from '../plans/selectors';
import { suitesSelector } from '../suites/selectors';

const suiteTestsSelectors = suiteTestAdapter.getSelectors(
  (state: State) => state.gen.suiteTest,
);

export function suiteTestSelector(
  state: State,
  { suiteTestId }: { suiteTestId?: number },
) {
  if (!suiteTestId) return;
  return suiteTestsSelectors.selectById(state, suiteTestId);
}

export function suiteTestsBySuiteSelector(state: State, suiteId: number) {
  return suiteTestsSelectors
    .selectAll(state)
    .filter((suiteTest) => suiteTest.suite_id === suiteId);
}

export function suiteTestsBySuitesSelector(state: State, suiteIds: number[]) {
  return suiteTestsSelectors
    .selectAll(state)
    .filter((suiteTest) => suiteIds.includes(suiteTest.suite_id));
}

export function suiteTestsByTestSelector(state: State, testId: number) {
  return suiteTestsSelectors
    .selectAll(state)
    .filter((suiteTest) => suiteTest.test_id === testId);
}

export const suitesByTest = createSelector(
  [
    suiteTestsSelectors.selectAll,
    suitesSelector,
    (_, { testId }: { testId?: number }) => testId,
  ],
  (suitesTests, suites, testId) =>
    suitesTests
      .filter((suiteTest) => suiteTest.test_id === testId)
      .flatMap((suiteTest) =>
        suites.filter((suite) => suite.id === suiteTest.suite_id),
      ),
);

export function suiteTestsSelector(state: State) {
  return suiteTestsSelectors
    .selectAll(state)
    .sort((a, b) => a.sequence_suite - b.sequence_suite);
}

export function suiteTestsByPlanSelector(state: State, planId: number) {
  const suitesIds = planSuitesByPlanSelector(state, planId).map(
    (planSuite) => planSuite.suite_id,
  );
  return suiteTestsSelectors
    .selectAll(state)
    .filter((suiteTest) => suitesIds.includes(suiteTest.suite_id));
}

export function suiteTestsByIncludesSelector(
  state: State,
  planSuiteTestsIncludeIds: number[],
  projectId: number,
): SuiteTest[] {
  if (!planSuiteTestsIncludeIds.length) return [];
  const rootPlan = rootPlanSelector(state, projectId);
  if (!rootPlan) return [];

  const suiteTests = suiteTestsByPlanSelector(state, rootPlan.id);
  const byIncludes: SuiteTest[] = [];

  planSuiteTestsIncludeIds.forEach((id) => {
    const include = planSuiteTestIncludeSelector(state, id);
    const suiteTest = suiteTests.find(
      (st) =>
        st.test_id === include?.test_id && st.suite_id === include?.suite_id,
    );
    if (suiteTest) byIncludes.push(suiteTest);
  });

  return byIncludes;
}
