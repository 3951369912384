import React, { ComponentProps } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHover, mergeProps } from 'react-aria';
import classnames from 'classnames';
import { Test, PlanSuiteTestInclude, SuiteTest, Plan } from '@testquality/sdk';
import { IconButton, notification } from '@bitmodern/bit-ui';
import {
  AddFolderIcon,
  TestCreateIcon,
  WatchIcon,
  WatchRemoveIcon,
} from '@bitmodern/bit-ui/icons';

import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { watchTestThunk } from '@bitmodern/redux/state/watch/thunks';
import { watchByTestIdSelector } from '@bitmodern/redux/state/watch/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { labelAssignedByTestSelector } from '@bitmodern/redux/state/label_assigned/selectors';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import vars from 'src/export.scss';
import TreeItem from '../TreeItem';
import styles from './TestListed.module.scss';

type Props = ComponentProps<'div'> & {
  checked?: boolean;
  nestingLevel?: number;
  onChangeChecked?: (value: boolean) => void;
  planSuiteTestInclude?: PlanSuiteTestInclude;
  plan?: Plan;
  selected?: boolean;
  suiteTest?: SuiteTest;
  test: Test;
  withActions?: boolean;
  withInclude?: boolean;
  includeRequirement?: boolean;
  requirementTestIncluded?: boolean;
  requirementId?: number;
};

export default function TestListed({
  checked,
  nestingLevel = 0,
  onChangeChecked,
  planSuiteTestInclude,
  plan,
  selected = false,
  style,
  suiteTest,
  test,
  withActions = true,
  withInclude = false,
  includeRequirement = false,
  requirementTestIncluded,
  requirementId,
  ...rest
}: Props) {
  const { showModal } = useModalManager();
  const { hoverProps, isHovered } = useHover({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const testWatch = useAppSelector((state) =>
    watchByTestIdSelector(state, test.id),
  );
  const labels = useAppSelector(labelsSelector);
  const labelsAssigned = useAppSelector((state) =>
    labelAssignedByTestSelector(state, { testId: test.id }),
  );
  const labelsIdsFromAssigned = labelsAssigned.map((la) => la.label_id);

  const onClickAddFolder = () => {
    showModal({
      modalName: 'suiteCreate',
      modalProps: { suiteTest, planId: plan?.id },
      type: 'modal',
    });
  };

  const onClickCreateTest = () => {
    showModal({
      modalName: 'testCreate',
      modalProps: { suiteTest, planId: plan?.id, requirementId },
      type: 'modal',
    });
  };

  const onWatchTest = () => {
    dispatch(watchTestThunk(test.id)).then(() =>
      notification.open({
        type: 'success',
        message: t('notifications.watchAdded.message'),
        description: t('notifications.watchAdded.description'),
      }),
    );
  };

  const onUnwatchTest = () => {
    dispatch(watchDeleteOneThunk({ id: testWatch?.id }))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notifications.watchRemoved.message'),
          description: t('notifications.watchRemoved.description'),
        }),
      );
  };

  const notIncluded = withInclude && !planSuiteTestInclude;
  const notIncludedReq = includeRequirement && !requirementTestIncluded;

  const keyCN = classnames(styles.key, {
    [styles.notIncludedKey]: notIncluded || notIncludedReq,
  });

  const mainData = [
    <span key="key" className={keyCN}>
      TC{test.key}
    </span>,
    <span key="name" className={styles.name}>
      {test.name}
    </span>,
  ];

  const hoverActions = [
    testWatch ? (
      <IconButton
        key="watchTest"
        boxed={false}
        onClick={onUnwatchTest}
        title={t('testListed.unwatchTest')}>
        <WatchRemoveIcon color={vars.textPrimary} size={18} />
      </IconButton>
    ) : (
      <IconButton
        key="unwatchTest"
        boxed={false}
        onClick={onWatchTest}
        title={t('testListed.watchTest')}>
        <WatchIcon color={vars.textPrimary} size={18} />
      </IconButton>
    ),
    <IconButton
      key="addSuite"
      boxed={false}
      onClick={onClickAddFolder}
      title={t('testListed.addSuite')}>
      <AddFolderIcon color={vars.textPrimary} size={18} />
    </IconButton>,
    <IconButton
      key="addTest"
      boxed={false}
      onClick={onClickCreateTest}
      title={t('testListed.addTest')}>
      <TestCreateIcon color={vars.textPrimary} size={18} />
    </IconButton>,
  ];

  const testBadges = labels
    .filter((label) => labelsIdsFromAssigned.includes(label.id))
    .map((label) => (
      <span key={`testLabel${label.id}`} className={styles.badge}>
        {label.label}
      </span>
    ));

  function getRightSection() {
    if (!withActions) return undefined;

    return isHovered ? [...hoverActions, ...testBadges] : testBadges;
  }

  return (
    <TreeItem
      {...mergeProps(rest, hoverProps)}
      checked={checked}
      onChangeChecked={onChangeChecked}
      nestingLevel={nestingLevel}
      notIncluded={notIncluded || notIncludedReq}
      selected={selected}
      mainData={mainData}
      label={test.name}
      rightSection={getRightSection()}
    />
  );
}
