import React, { ComponentProps } from 'react';
import ReactDropzone from 'react-dropzone';
import { useTranslation } from 'src/i18n/hooks';
import { ImageIcon } from '../icons';
import Loading from '../Loading';
import styles from './Dropzone.module.scss';
import vars from '../styling/exports.scss';

type Props = Pick<ComponentProps<typeof ReactDropzone>, 'accept' | 'onDrop'> & {
  className?: string;
  loading?: boolean;
};

export default function Dropzone({
  accept,
  className = '',
  onDrop,
  loading,
}: Props) {
  const { t } = useTranslation();

  return (
    <ReactDropzone accept={accept} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div className={`${styles.dropZone} ${className}`} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            t('importFile.actions.dropHere')
          ) : (
            <>
              <ImageIcon
                className={styles.icon}
                color={vars.textSecondary}
                size={48}
              />
              {t('importFile.actions.dragArea')}
            </>
          )}
          {loading && <Loading className={styles.loading} size={32} />}
        </div>
      )}
    </ReactDropzone>
  );
}
