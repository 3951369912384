import { createSelector } from '@reduxjs/toolkit';
import { defectTypeSelectors } from 'src/gen/domain/defect_type/defectTypeSelector';
import { State } from '@bitmodern/redux/store';

export function defectTypeSelector(state: State) {
  return defectTypeSelectors.selectAll(state);
}

export const defectTypesByIntegrationProjectSelector = (
  integrationProjectId?: number,
) =>
  createSelector(defectTypeSelector, (defectTypes) =>
    defectTypes.filter(
      ({ integration_project_id }) =>
        integration_project_id === integrationProjectId,
    ),
  );
