import { createSelector } from '@reduxjs/toolkit';
import { State } from '@bitmodern/redux/store';
import { filterSelectors } from 'src/gen/domain/filter/filterSelector';
import { currentUserSelector } from '../authentication/selectors';
import { TestsFiltersKeys } from '../filters/reducer';

export function customFiltersSelector(state: State) {
  return filterSelectors.selectAll(state);
}

export function customFilterByIdSelector(
  state: State,
  customFilterId: number | null,
) {
  if (!customFilterId) return undefined;
  return filterSelectors.selectById(state, customFilterId);
}

export const createFiltersByTypeSelector = (type: TestsFiltersKeys) =>
  createSelector(
    customFiltersSelector,
    currentUserSelector,
    (customFilters, currentUser) =>
      customFilters.filter(
        (filter) =>
          filter.related_type === type &&
          (filter.is_shareable || filter.created_by === currentUser?.id),
      ),
  );
