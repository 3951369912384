import React, { ComponentProps, useRef, useState } from 'react';
import { useEditing } from './useEditing';
import InputDate from '../InputDate';
import { useEditingKeyboard } from './useEditingKeyboard';
import { InlineEditButtons } from './InlineEditButtons';
import styles from './InlineEdit.module.scss';

type Props = ComponentProps<typeof InputDate> & {
  onCommit: (value?: Date) => Promise<any>;
};

export function InputDirectInlineEdit({ onCommit, value, ...rest }: Props) {
  const input = useRef<any>(null);
  const [valueInline, setValueInline] = useState(value);
  const inlineEdit = useEditing({
    onCommit: () => {
      if (valueInline === value) return Promise.resolve();
      return onCommit(valueInline);
    },
    onCancel: () => setValueInline(value),
  });

  const { keyboardProps } = useEditingKeyboard({
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });

  const handleCancel = () => {
    inlineEdit.cancel();
    setTimeout(() => {
      if (input.current) input.current.blur();
    }, 0);
  };

  const onChange = (date) => setValueInline(date);

  return (
    <div
      className={styles.inlineBase}
      onClick={inlineEdit.open}
      {...keyboardProps}>
      <InputDate
        {...rest}
        endAdornment={
          <InlineEditButtons
            onCancel={handleCancel}
            onCommit={inlineEdit.commit}
            isEditing={inlineEdit.isEditing}
          />
        }
        onChange={onChange}
        value={valueInline}
        focused={inlineEdit.isEditing}
        ref={input}
      />
    </div>
  );
}

export default InputDirectInlineEdit;
