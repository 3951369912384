import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { isSameDay } from 'date-fns';
import popularEmailDomains from 'src/enums/popularEmailDomains';

export function leadingZeros(number: number, zeros: number): string {
  const digits = number.toString().length;
  const zerosToAdd = zeros - digits;
  if (zerosToAdd > 0) {
    const zs = Array(zerosToAdd)
      .fill(0)
      .reduce((acu, z) => acu + z, '');
    return zs + number;
  }
  return number.toString();
}

/* NOTE: Create type guard in order to avoid typing the array elements as undefined */
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function objDifferences(obj, updatedObj) {
  return Object.keys(updatedObj).reduce((acc, filterKey) => {
    if (typeof updatedObj[filterKey] === 'object') {
      // date entities
      if (updatedObj[filterKey] instanceof Date) {
        if (!isSameDay(updatedObj[filterKey], new Date(obj[filterKey]))) {
          acc[filterKey] = updatedObj[filterKey];
        }
        return acc;
      }

      // obj entities
      const diff = omitBy(updatedObj[filterKey], (_, key) => {
        if (obj[filterKey] && obj[filterKey][key]) return true;
        return false;
      });
      if (!isEmpty(diff)) acc[filterKey] = diff;
      return acc;
    }

    // primitives entities
    if (updatedObj[filterKey] !== obj[filterKey])
      acc[filterKey] = updatedObj[filterKey];
    return acc;
  }, {});
}

export function isPopularDomain(domain: string) {
  return popularEmailDomains.includes(domain);
}
