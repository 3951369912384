import { createSelector } from '@reduxjs/toolkit';

import { State } from '@bitmodern/redux/store';
import { policySelectors } from 'src/gen/domain/policy/policySelector';

export function policiesSelector(state: State) {
  return policySelectors.selectAll(state);
}

export const createPoliciesByRoleIdSelector = (roleId) =>
  createSelector(policiesSelector, (policies) =>
    policies.filter((policy) => {
      return policy.access_role_id === roleId;
    }),
  );
