import { useFormik } from 'formik';
import React, { ComponentProps, useMemo } from 'react';
import Yup from 'src/utils/yup';
import { Button, Dialog, InputPassword } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import styles from './ChangePasswordDialog.module.scss';

const initialValues = {
  existingPassword: '',
  password: '',
  passwordConfirm: '',
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  isOwnPassword: boolean;
  onSubmit: (values: typeof initialValues) => Promise<any>;
};

export default function ChangePasswordDialog({
  isOwnPassword,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...(isOwnPassword && {
          existingPassword: Yup.string()
            .required()
            .label('changePassword.form.existingPassword'),
        }),
        password: Yup.string().required().label('changePassword.form.password'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), ''], 'Passwords must match')
          .required()
          .label('changePassword.form.passwordConfirm'),
      }),
    [isOwnPassword],
  );

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('changePassword.title')}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        {isOwnPassword && (
          <InputPassword
            error={formikError(formik, 'existingPassword')}
            fullWidth
            label={t('changePassword.form.existingPassword')}
            name="existingPassword"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.existingPassword}
          />
        )}
        <InputPassword
          error={formikError(formik, 'password')}
          fullWidth
          label={t('changePassword.form.password')}
          name="password"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          strengthIndicator
          value={formik.values.password}
        />
        <InputPassword
          error={formikError(formik, 'passwordConfirm')}
          fullWidth
          label={t('changePassword.form.passwordConfirm')}
          name="passwordConfirm"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.passwordConfirm}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit"
          disabled={!formik.dirty}>
          {t('changePassword.form.submit')}
        </Button>
      </form>
    </Dialog>
  );
}
