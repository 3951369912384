import { MotionConfig } from 'framer-motion';
import React, { ReactNode } from 'react';
import { OverlayProvider } from 'react-aria';
import './Provider.scss';

type Props = {
  children: ReactNode;
  overlayProviderId?: string;
};

export default function Provider({ children, overlayProviderId }: Props) {
  return (
    <MotionConfig transition={{ duration: 0.15 }}>
      <OverlayProvider id={overlayProviderId}>{children}</OverlayProvider>
    </MotionConfig>
  );
}
