import { State } from '@bitmodern/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import { Defect } from '@testquality/sdk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { runResultsByTestSelector } from '../runResults/selectors';
import { defectHistoryByDefectSelector } from '../defectHistory/selectors';
import { runResultHistoryByDefectSelector } from '../runResultHistory/selectors';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { defectStatusSelectors } from 'src/gen/domain/defect_status/defectStatusSelector';
import { userSelectorById } from '../users/selectors';
import { runSelector } from '../runs/selectors';
import { defectSelectors } from 'src/gen/domain/defect/defectSelector';
import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import { runSelectors } from 'src/gen/domain/run/runSelector';
import {
  DefectUpdateEvent,
  DefectCreateEvent,
  EventType,
  RunResultEvent,
} from '../defectHistory/thunks';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import { objDifferences } from 'src/utils/common';
import { defectRunResultSelectors } from 'src/gen/domain/defect_run_result/defectRunResultSelector';
import { requirementTestSelectors } from 'src/gen/domain/requirement_test/requirementTestSelector';

type DiffType = {
  status_id?: number;
  defect_status_id?: number;
};

export function getDefectById(state, args: { defectId?: number }) {
  return args.defectId
    ? defectSelectors.selectById(state, args.defectId)
    : undefined;
}

const defectsByProjectSelector = createSelector(
  [
    (state: State) => state.gen.defect.entities,
    (state: State, projectId: number) => projectId,
  ],
  (entities, projectId) =>
    (filter(entities, (defect) => defect?.project_id === projectId) ||
      []) as Defect[],
);

export const defectsByRunResultSelector = createSelector(
  [
    (state: State) => state.gen.defect.entities,
    (state: State) => state.gen.defectRunResult.entities,
    (state: State, runResultId: number) => runResultId,
  ],
  (entities, defectRunResult, runResultId) => {
    const defectIds = filter(
      defectRunResult,
      (value) => value?.run_result_id === runResultId,
    ).map((value) => value?.defect_id);

    return (filter(
      entities,
      (defect) =>
        defect !== undefined &&
        (defect.related_id === runResultId || defectIds.includes(defect.id)),
    ) || []) as Defect[];
  },
);

export const defectsByRunSelector = createSelector(
  [
    defectSelectors.selectEntities,
    runResultSelectors.selectEntities,
    defectRunResultSelectors.selectEntities,
    (state: State, runId: number) => runId,
  ],
  (defects, runResults, defectsRunResults, runId) => {
    const runResultIds: number[] = [];
    for (const id in runResults) {
      const runResult = runResults[id];
      if (!runResult) continue;
      if (runResult.run_id === runId) runResultIds.push(runResult.id);
    }

    const defectIds: number[] = [];
    for (const id in defectsRunResults) {
      const defectRunResult = defectsRunResults[id];
      if (!defectRunResult) continue;
      if (
        defectRunResult.defect_id &&
        runResultIds.some((rrId) => rrId === defectRunResult.run_result_id)
      ) {
        defectIds.push(defectRunResult.defect_id);
      }
    }

    const toReturn: Defect[] = [];
    for (const id in defects) {
      const defect = defects[id];
      if (!defect) continue;
      if (defectIds.some((dId) => dId === defect.id)) {
        toReturn.push(defect);
      }
    }

    return toReturn;
  },
);

export const makeDefectsByPlan = () =>
  createSelector(
    [
      defectSelectors.selectEntities,
      runResultSelectors.selectEntities,
      runSelectors.selectEntities,
      defectRunResultSelectors.selectEntities,
      (_, args: { planId?: number }) => args,
    ],
    (defects, runResults, runs, defectsRunResults, { planId }) => {
      // Map <runResultId, planId>
      const runResultPlanMap = new Map<number, number>();
      for (const key in runResults) {
        const runResult = runResults[key];
        if (!runResult) continue;
        const runPlanId = runs[runResult.run_id]?.plan_id;

        if (runPlanId) {
          runResultPlanMap.set(runResult.id, runPlanId);
        }
      }

      const defectIds: number[] = [];
      for (const id in defectsRunResults) {
        const defectRunResult = defectsRunResults[id];
        if (!defectRunResult) continue;
        if (
          defectRunResult.defect_id &&
          defectRunResult.run_result_id &&
          runResultPlanMap.get(defectRunResult.run_result_id) === planId
        ) {
          defectIds.push(defectRunResult?.defect_id);
        }
      }

      const planDefects: Defect[] = [];
      for (const key in defects) {
        const defect = defects[key];
        if (!defect) continue;

        if (defectIds.some((id) => id === defect.id)) planDefects.push(defect);

        const runResultId = defect?.related_id;
        if (!runResultId) continue;

        console.log(runResultPlanMap.get(runResultId), planId);

        if (runResultPlanMap.get(runResultId) === planId) {
          planDefects.push(defect);
        }
      }

      return planDefects;
    },
  );

export const makeDefectsCountByPlan = () =>
  createSelector(makeDefectsByPlan(), (defects) => defects.length);

export function defectsByTestSelector(state, args: { testId?: number }) {
  if (!args.testId) return [];
  const runResults = runResultsByTestSelector(state, args.testId);

  return defectsByRunResultsSelector(
    state,
    runResults.map((rr) => rr.id),
  );
}

export interface DefectsByRangeParam {
  start?: Date | null;
  end?: Date | null;
  milestoneId?: number;
}

export const defectsByRangeSelector = createSelector(
  [
    runSelectors.selectAll,
    runResultSelectors.selectAll,
    defectRunResultSelectors.selectAll,
    defectsByProjectSelector,
    (state: State, projectId: number, rangeParam: DefectsByRangeParam) =>
      rangeParam,
  ],
  (runs, runResults, defectRunResults, defects, rangeParam) => {
    if (rangeParam.milestoneId) {
      const runsIds = runs
        .filter((r) => r.milestone_id === rangeParam.milestoneId)
        .map((r) => r.id);

      const runResultIds = runResults
        .filter((rr) => runsIds.includes(rr.run_id))
        .map((r) => r.id);

      const defectIds = defectRunResults
        .filter(
          (dr) => dr.run_result_id && runResultIds.includes(dr.run_result_id),
        )
        .map((dr) => dr.defect_id);

      return defects.filter((defect) => {
        if (!defect) return false;
        if (!defect?.related_id) return defectIds.includes(defect.id);

        return runResultIds.includes(defect.related_id);
      });
    }
    return defects.filter((defect) => {
      if (!rangeParam.start && !rangeParam.end) return true;

      const date = defect.created_at
        ? new Date(defect.created_at).getTime()
        : 0;

      if (rangeParam.start && rangeParam.end) {
        return (
          date >= rangeParam.start.getTime() && date <= rangeParam.end.getTime()
        );
      }
      if (rangeParam.start) {
        return date >= rangeParam.start.getTime();
      }
      if (rangeParam.end) {
        return date <= rangeParam.end.getTime();
      }
      return true;
    });
  },
);

// FIXME: To deprecate related_id after migration
export const defectsByRunResultsSelector = createSelector(
  [
    defectSelectors.selectEntities,
    defectRunResultSelectors.selectEntities,
    (state: State, ids: number[]) => ids,
  ],
  (defects, defectsRunResults, ids) => {
    const toReturn: Defect[] = [];
    const defectIds: number[] = [];
    for (const id in defectsRunResults) {
      const defectRunResult = defectsRunResults[id];
      if (!defectRunResult) continue;
      if (
        defectRunResult?.defect_id &&
        ids?.some((i) => i === defectRunResult?.run_result_id)
      ) {
        defectIds.push(defectRunResult?.defect_id);
      }
    }

    for (const id in defects) {
      const defect = defects[id];
      if (!defect) continue;
      if (
        defectIds.some((i) => i === defect.id) ||
        (ids?.includes(defect.related_id || 0) &&
          defect.related_type === RelatedType.RUN_RESULT)
      ) {
        toReturn.push(defect);
      }
    }
    return toReturn;
  },
);

export const createAssociatedDefectsSelector = createSelector(
  [
    (state: State) => state.gen.defect.entities,
    (state: State, defectId: number) => defectId,
    (state: State, defectId: number, projectId: number) => projectId,
  ],
  (defects, defectId, projectId) => {
    const currentDefect = defects[defectId];
    if (!currentDefect) return [];
    return (filter(
      defects,
      (d) =>
        d !== undefined &&
        d.external_reference_id === currentDefect.external_reference_id &&
        d.project_id === projectId,
    ) || []) as Defect[];
  },
);

export const selectCommentsByDefectId = createSelector(
  [
    (state: State) => state.gen.defect.entities,
    (state: State, defectId: number) => defectId,
  ],
  (entities, defectId) => {
    const defect = entities[defectId];
    if (!defect) {
      return [];
    }
    // renderedFields.comments and comments should be the same, need to investigate why this is needed.
    const comments = !defect.payload?.renderedFields
      ? defect.payload?.comments
      : defect.payload?.comments.map((comment, i) => ({
          ...comment,
          ...defect.payload?.renderedFields.comments[i],
        }));
    return comments;
  },
);

export function defectEventsByDefectSelector(
  state: State,
  args: { defectId?: number },
) {
  if (!args.defectId) return [];
  const defect = getDefectById(state, args);

  const defectHistory = defectHistoryByDefectSelector(state, { defect });

  const selector = runResultHistoryByDefectSelector();
  const runResultsHistory = selector(state, { defect });

  const defectEvents: DefectCreateEvent[] & DefectUpdateEvent[] = defectHistory
    .map((event, i, array) => {
      let diffs: DiffType = {};
      if (i > 0 && event?.operation === 'update') {
        diffs = objDifferences(event, array[i - 1]);
      }
      return { ...event, diffs };
    })
    .filter(filterEmptyUpdates)
    .reduce<DefectCreateEvent[] & DefectUpdateEvent[]>((acu, event) => {
      if (event.operation === 'create') {
        acu.push({
          id: event._id,
          user: userSelectorById(state, event.updated_by),
          updatedAt: event.updated_at,
          operation: event.operation,
          itemType: 'defectCreate',
        });
      }

      const status = event.defect_status_id
        ? defectStatusSelectors.selectById(state, event.defect_status_id)
        : undefined;

      const statusDiff = event.diffs.defect_status_id
        ? defectStatusSelectors.selectById(state, event.diffs.defect_status_id)
        : undefined;

      if (!status || !statusDiff) return acu;

      acu.push({
        id: event._id,
        user: userSelectorById(state, event.updated_by),
        updatedAt: event.updated_at,
        operation: event.operation,
        itemType: 'defectUpdate',
        status,
        statusDiff,
        diffs: event.diffs,
      });

      return acu;
    }, []);
  const runResultsEvents = runResultsHistory
    .map((event, i, array) => {
      let diffs: DiffType = {};
      if (i > 0 && event.operation === 'update') {
        diffs = objDifferences(event, array[i - 1]);
      }
      return { ...event, diffs };
    })
    .filter(filterEmptyUpdates)
    .reduce<RunResultEvent[]>((acu, event) => {
      const status = statusSelectors.selectById(state, event.status_id);
      const statusDiff = event.diffs.status_id
        ? statusSelectors.selectById(state, event.diffs.status_id)
        : undefined;
      const run = runSelector(state, event.run_id);
      const test = testSelectors.selectById(state, event.test_id);
      if (!status || !statusDiff || !run || !test) return acu;
      acu.push({
        id: event._id,
        diffs: event.diffs,
        itemType: 'runResult',
        updatedAt: event.updated_at,
        operation: event.operation,
        user: userSelectorById(state, event.updated_by),
        run,
        statusDiff,
        status,
        test,
      });
      return acu;
    }, []);
  const events: EventType[] = [...defectEvents, ...runResultsEvents].sort(
    (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
  );
  return events;
}

function filterEmptyUpdates(item) {
  return (
    item.operation === 'create' ||
    (item.operation === 'update' && Object.keys(item.diffs).length === 0) ===
      false
  );
}

export const makeDefectsByRequirement = () =>
  createSelector(
    [
      requirementTestSelectors.selectEntities,
      runResultSelectors.selectEntities,
      defectRunResultSelectors.selectEntities,
      defectSelectors.selectEntities,
      (state: State, args: { requirementId?: number }) => args,
    ],
    (
      requirementsTests,
      runResults,
      defectsRunResults,
      defects,
      { requirementId },
    ) => {
      const testIds: number[] = [];
      for (const key in requirementsTests) {
        const requirementTest = requirementsTests[key];
        if (
          requirementTest &&
          requirementTest.requirement_id === requirementId &&
          requirementTest.test_id
        ) {
          testIds.push(requirementTest.test_id);
        }
      }

      const runResultIds: number[] = [];
      for (const key in runResults) {
        const runResult = runResults[key];
        if (runResult && testIds.some((id) => id === runResult?.test_id)) {
          runResultIds.push(runResult.id);
        }
      }

      const toReturn: Defect[] = [];
      const defectIds: number[] = [];

      for (const key in defectsRunResults) {
        const defectRunResult = defectsRunResults[key];
        if (!defectRunResult) continue;
        if (
          defectRunResult?.defect_id &&
          runResultIds.some((i) => i === defectRunResult?.run_result_id)
        ) {
          defectIds.push(defectRunResult?.defect_id);
        }
      }

      for (const id in defects) {
        const defect = defects[id];
        if (!defect) continue;
        if (
          defectIds.some((i) => i === defect.id) ||
          (runResultIds.includes(defect.related_id || 0) &&
            defect.related_type === RelatedType.RUN_RESULT)
        ) {
          toReturn.push(defect);
        }
      }

      return toReturn;
    },
  );

export const makeDefectsCountByRequirement = () =>
  createSelector(makeDefectsByRequirement(), (defects) => defects.length);
