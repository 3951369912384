import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { Test } from '@testquality/sdk';
import { State } from '@bitmodern/redux/store';
import { filterTest } from '@bitmodern/redux/state/suites/selectors';
import { TestsFiltersKeys } from '../filters/reducer';
import { filterLabelAssignedByTestId } from '../label_assigned/selectors';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import { requirementTestSelectors } from 'src/gen/domain/requirement_test/requirementTestSelector';

export const selectTestsByProject = createSelector(
  [
    testSelectors.selectEntities,
    (state: State, args: { projectId?: number }) => args,
  ],
  (tests, { projectId }) => {
    if (!projectId) return [];
    const toReturn: Test[] = [];

    for (const key in tests) {
      const test = tests[key];
      if (test && test?.project_id === projectId) {
        toReturn.push(test);
      }
    }

    return toReturn;
  },
);
export const testsFilteredSelector = createSelector(
  [
    (state: State) => state.filters,
    (state: State) => state.gen.test.entities,
    (state: State) => state.gen.labelAssigned.entities,
    (state: State, projectId: number) => projectId,
    (state: State, projectId: number, filterType: TestsFiltersKeys) =>
      filterType,
  ],
  (filters, tests, labelAssigned, projectId, filterType) => {
    const stateFilter = filters[filterType];
    return (filter(tests, (test) => {
      if (!test) {
        return false;
      }
      const labels = filterLabelAssignedByTestId(labelAssigned, test.id);
      return (
        test.project_id === projectId &&
        filterTest(stateFilter, labels, { test })
      );
    }) || []) as Test[];
  },
);

export const makeTestsByRequirement = () =>
  createSelector(
    [
      requirementTestSelectors.selectEntities,
      testSelectors.selectEntities,
      (state: State, args: { requirementId?: number }) => args,
    ],
    (requirementsTests, tests, { requirementId }) => {
      const testIds: number[] = [];
      for (const key in requirementsTests) {
        const requirementTest = requirementsTests[key];
        if (
          requirementTest &&
          requirementTest.requirement_id === requirementId &&
          requirementTest.test_id
        ) {
          testIds.push(requirementTest.test_id);
        }
      }

      const toReturn: Test[] = [];
      for (const key in tests) {
        const test = tests[key];
        if (test && testIds.some((id) => id === test?.id)) {
          toReturn.push(test);
        }
      }
      return toReturn;
    },
  );

export const makeTestsCountByRequirement = () =>
  createSelector(makeTestsByRequirement(), (tests) => tests.length);

export const makeTestsPercentage = () =>
  createSelector(
    [
      selectTestsByProject,
      (state: State, args: { testsCount?: number }) => args,
    ],
    (testsByProject, { testsCount }) => {
      if (!testsCount || testsCount === 0 || testsByProject.length === 0)
        return 0;

      return Math.floor((testsCount / testsByProject.length) * 100) || 0;
    },
  );
