import React from 'react';
import { Button, Grid, Separator, Switch } from 'src/packages/bit-ui';
import DescriptiveAction from '../DescriptiveAction';
import { InfoIcon } from 'src/packages/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { JiraInstanceType } from 'src/enums/IntegrationEnums';
import jiraAppLogo from './JiraApp.png';
import vars from 'src/export.scss';
import classnames from 'classnames';
import styles from './JiraAppSetup.module.scss';

export type JiraAppSetupProps = {
  appVersion?: number | null;
  jiraInstance?: string;
};

export function JiraAppSetup({ appVersion, jiraInstance }: JiraAppSetupProps) {
  const { t } = useTranslation();

  const instructionContainerCN = classnames(styles.instructionContainer, {
    [styles.addMarginBottom]:
      appVersion || jiraInstance === JiraInstanceType.Cloud,
  });

  return (
    <div className={styles.jiraAppSetupContainer}>
      {appVersion === null && (
        <Grid.Row gutter={0} className={styles.warningBanner}>
          <div className={styles.bannerIcon}>
            <InfoIcon
              className={styles.roundedWarning}
              color={vars.textPrimary}
              size={56}
            />
          </div>

          <DescriptiveAction
            className={styles.jiraAppAction}
            action={
              <Button color="onWarning" onClick={() => {}} size="large">
                {t('integrationSetup.actions.setupApp')}
              </Button>
            }
            description={t('integrationSetup.confugureJiraAppDescription')}
            title={t('integrationSetup.actions.configureJiraApp')}
          />
        </Grid.Row>
      )}

      <Grid.Row gutter={0} className={styles.rowContainer}>
        <div className={styles.setupContainer}>
          <div className={styles.bannerIcon}>
            <img className={styles.jiraAppLogo} src={jiraAppLogo} />
          </div>

          <div className={styles.jiraAppSetup}>
            <div className={styles.title}>JIRA App</div>
            <div>
              Linking the Jira app to your TestQuality project provides
              significant value for developer testing by streamlining the entire
              testing process within the Jira app. It enables developers to
              track and manage test cases, collaborate with team members, and
              seamlessly integrate testing with their development workflow,
              resulting in improved efficiency and quality of software releases.
            </div>
          </div>
        </div>
        <Separator color="primaryMain" className={styles.separator} />
        <div className={styles.setupContainerAction}>
          <Button color="accent" onClick={() => {}} size="large">
            {t('integrationSetup.actions.setupApp')}
          </Button>
          <Switch checked={appVersion === 2} disabled={false} />
        </div>
      </Grid.Row>
      <Grid.Row gutter={0} className={instructionContainerCN}>
        <div className={styles.jiraSetupInstruction}>
          <div className={styles.title}>How to setup Jira and TestQuality</div>
          <ul>
            <li>Go to the Jira MarketPlace</li>
            <li>Install TestQuality App</li>
            <li>Click on Login in Get Started page</li>
          </ul>
        </div>
      </Grid.Row>
    </div>
  );
}
