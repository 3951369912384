import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@testquality/sdk';

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: { user: {} },
  reducers: {
    currentUserCleared: (state) => {
      state.user = {};
    },
    currentUserSet: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const { currentUserCleared, currentUserSet } = currentUserSlice.actions;

export const currentUserReducer = currentUserSlice.reducer;
