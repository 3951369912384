import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Input } from 'src/packages/bit-ui';
import {
  GithubIcon,
  GoogleIcon,
  JiraIcon,
  KeyIcon,
} from 'src/packages/bit-ui/icons';
import { showNotificationError } from '@testquality/sdk';
import routes from 'src/components/Router/routes';
import { useTranslation } from 'src/i18n/hooks';
import { useSocialLogin } from 'src/modules/authentication/elements';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import { getEnv } from 'src/env';
import vars from 'src/export.scss';
import styles from './SignInStep1.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('signIn.form.email'),
});

type InitialValues = {
  email: string;
};

export type SignInStep1Props = {
  email?: string;
  onSubmit: ({ email }: InitialValues) => void;
};

export function SignInStep1({ email = '', onSubmit }: SignInStep1Props) {
  const { atlassian, github, google, sso } = useSocialLogin({
    onError: showNotificationError,
  });
  const { t } = useTranslation();

  const initialValues = useMemo<InitialValues>(
    () => ({
      email,
    }),
    [email],
  );
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className={styles.content}>
      <div className={styles.title}>{t('signIn.step1.title')}</div>

      <div className={styles.buttons}>
        {getEnv().features.atlassianLogin && (
          <Button
            color="primaryLight"
            fullWidth
            icon={<JiraIcon color={vars.textPrimary} size={20} />}
            loading={atlassian.loading}
            onClick={atlassian.handleClick}>
            {t('signIn.step1.buttons.atlassian')}
          </Button>
        )}
        <Button
          color="primaryLight"
          fullWidth
          loading={github.loading}
          onClick={github.handleClick}
          icon={<GithubIcon color={vars.textPrimary} size={24} />}>
          {t('signIn.step1.buttons.github')}
        </Button>
        <Button
          color="primaryLight"
          fullWidth
          loading={google.loading}
          onClick={google.handleClick}
          icon={<GoogleIcon color={vars.textPrimary} size={22} />}>
          {t('signIn.step1.buttons.google')}
        </Button>
        <Button
          color="primaryLight"
          fullWidth
          icon={<KeyIcon color={vars.textPrimary} size={26} />}
          onClick={sso.handleClick}>
          {t('signIn.step1.buttons.sso')}
        </Button>
      </div>

      <div className={styles.separator}>
        <span className={styles.separatorText}>
          {t('signIn.step1.separator')}
        </span>
      </div>

      <form
        className={styles.form}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Input
          aria-label={t('signIn.form.email')}
          error={formikError(formik, 'email')}
          fullWidth
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('signIn.form.email')}
          value={formik.values.email}
        />
        <Button
          className={styles.formSubmit}
          color="accent"
          fullWidth
          loading={formik.isSubmitting}
          size="large"
          type="submit">
          {t('signIn.form.submit')}
        </Button>
      </form>

      <div className={styles.signUp}>
        {t('signIn.step1.signUp')}{' '}
        <Link className={styles.signUpLink} to={routes.FREETRIAL}>
          {t('signIn.step1.signUpLink')}
        </Link>
      </div>
    </div>
  );
}
