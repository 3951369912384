import React, { ReactElement } from 'react';
import { NodeData, NodeRendererProps } from 'react-sortable-tree';
import { DropPlaceholder } from '@bitmodern/bit-ui';
import { dropPlaceholderSize } from 'src/utils/nestingSize';

type Props = NodeRendererProps & {
  current: number;
  onCheckParentChange?: (value: boolean, node: NodeData) => void;
  renderNodeType?: {
    [key: string]: (nodeProps: any) => ReactElement;
  };
};

function NodeContentRenderer(props: Props) {
  const {
    buttons,
    canDrag,
    canDrop,
    className,
    connectDragPreview,
    connectDragSource,
    current,
    didDrop,
    draggedNode,
    isDragging,
    isSearchFocus,
    isSearchMatch,
    lowerSiblingCounts,
    node,
    onCheckParentChange,
    parentNode,
    path,
    renderNodeType,
    rowDirection,
    scaffoldBlockPxWidth,
    style,
    subtitle,
    title,
    toggleChildrenVisibility,
    treeId,
    treeIndex,
    ...rest
  } = props;

  const level = path.length - 1;

  if (isDragging) {
    return (
      <div
        style={{
          paddingLeft: dropPlaceholderSize(level),
          height: '100%',
          width: '100%',
        }}>
        <DropPlaceholder />
      </div>
    );
  }

  let component = <div>{node.title}</div>;

  const render = renderNodeType ? renderNodeType[node?.type] : null;

  if (render) {
    component = render(props);
  }

  if (!canDrag) return component;

  const dragable = connectDragSource(<div {...rest}>{component}</div>, {
    dropEffect: 'copy',
  });

  return dragable;
}

export default NodeContentRenderer;
