import React, { ComponentProps, useMemo, useCallback } from 'react';
import Yup from 'src/utils/yup';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
  Select,
} from '@bitmodern/bit-ui';
import { SignupOption, showNotificationError } from '@testquality/sdk';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import { useAppSelector } from '@bitmodern/redux/store';
import { githubIntegrationSelector } from '@bitmodern/redux/state/integration/selectors';
import { githubBaseIntegrationSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { routes } from 'src/components/Router';
import { SignUpType, SignUpTypeDisplay } from 'src/enums/SignUpTypeEnum';
import useFetch from 'src/hooks/useFetch';
import { getIntegrationsOrganizations } from '@bitmodern/services/integrationService';
import styles from './SignUpOptionDialog.module.scss';

const urlPattern =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi; // eslint-disable-line no-useless-escape

type Values = Pick<SignupOption, 'name' | 'signup_type'>;

const initialValues: Values = {
  signup_type: SignUpType.SSO,
  name: '',
};

type Props = {
  site: string;
  onSubmit: (values: Values) => Promise<any>;
} & Required<Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>>;

export default function SignUpOptionDialog({
  site,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const githubBaseIntegration = useAppSelector(githubBaseIntegrationSelector);
  const githubIntegration = useAppSelector(githubIntegrationSelector);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        signup_type: Yup.number()
          .oneOf([SignUpType.SSO, SignUpType.GITHUB, SignUpType.GOOGLE])
          .required(),
        name: Yup.string().when('signup_type', (signUpType, schema) => {
          switch (signUpType) {
            case SignUpType.GITHUB:
              return schema.label(t('signUpOptionDialog.form.org')).required();
            case SignUpType.SSO:
              return schema
                .label(t('signUpOptionDialog.form.sso'))
                .matches(urlPattern, t('signUpOptionDialog.urlError'))
                .required();
            default:
              return schema.required();
          }
        }),
      }),
    [t],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const signUpIntegrationTypesOptions = useMemo(
    () => [
      {
        label: t(SignUpTypeDisplay.get(SignUpType.SSO) as any),
        value: SignUpType.SSO,
      },
      {
        label: t(SignUpTypeDisplay.get(SignUpType.GITHUB) as any),
        value: SignUpType.GITHUB,
      },
      {
        label: t(SignUpTypeDisplay.get(SignUpType.GOOGLE) as any),
        value: SignUpType.GOOGLE,
      },
    ],
    [t],
  );

  const fetchGithubOrganizations = useCallback(() => {
    if (!githubIntegration || formik.values.signup_type !== SignUpType.GITHUB) {
      return Promise.resolve([]);
    }
    return getIntegrationsOrganizations(githubIntegration.id).catch((err) => {
      showNotificationError(err);
      throw err;
    });
  }, [githubIntegration, formik.values.signup_type]);

  const githubOrgFetch = useFetch(
    fetchGithubOrganizations,
    `github-orgs-${githubIntegration?.id}`,
    { initialData: [] },
  );

  const handleSignupTypeChange = (value) => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('signup_type', value);

    if (value === SignUpType.GITHUB && githubBaseIntegration) {
      if (!githubIntegration) {
        return history.push({
          pathname: routes.INTEGRATION_SETUP({
            site,
            baseIntegrationId: githubBaseIntegration.id.toString(),
          }),
        });
      }
    }
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const githubOrgOptions = githubOrgFetch.data.map((org) => ({
    label: org.login,
    value: org.login,
  }));

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      size="small"
      title={t('signUpOptionDialog.title')}>
      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <DialogContent className={styles.content}>
          <Select
            label={t('signUpOptionDialog.form.signUpType')}
            name="signup_type"
            onChange={handleSignupTypeChange}
            options={signUpIntegrationTypesOptions}
            value={formik.values?.signup_type}
          />

          {formik.values.signup_type === SignUpType.SSO && (
            <Input
              error={formikError(formik, 'name')}
              fullWidth
              label={t('signUpOptionDialog.form.sso')}
              name="name"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              required
              value={formik.values.name}
            />
          )}

          {formik.values.signup_type === SignUpType.GOOGLE && (
            <Input
              error={formikError(formik, 'name')}
              fullWidth
              label={t('signUpOptionDialog.form.google')}
              name="name"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              required
              value={formik.values.name}
            />
          )}

          {formik.values.signup_type === SignUpType.GITHUB && (
            <Select
              error={formikError(formik, 'name')}
              label={t('signUpOptionDialog.form.org')}
              loading={githubOrgFetch.isLoading}
              name="name"
              onChange={formik.handleChange('name')}
              options={githubOrgOptions}
              placeholder={t('signUpOptionDialog.form.orgPlaceholder')}
              required
              value={formik.values.name}
            />
          )}
        </DialogContent>
        <PanelActions className={styles.actions}>
          <Button loading={formik.isSubmitting} type="submit">
            {t('signUpOptionDialog.form.submit')}
          </Button>
        </PanelActions>
      </form>
    </Dialog>
  );
}
