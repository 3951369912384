/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { runResultStepAdapter } from './runResultStepAdapter';

export const runResultStepSelectors = runResultStepAdapter.getSelectors(
  (state: State) => state.gen.runResultStep,
);
