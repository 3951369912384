/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { pullRequestAdapter } from './pullRequestAdapter';

export const pullRequestSelectors = pullRequestAdapter.getSelectors(
  (state: State) => state.gen.pullRequest,
);
