/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { ProjectApi } from '@testquality/sdk';
import { IntegrationProject } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { integrationNormalizer } from '../integration/integrationNormalize';
import { integrationProjectNormalizer } from '../integration_project/integrationProjectNormalize';
import { defectNormalizer } from '../defect/defectNormalize';
import { requirementNormalizer } from '../requirement/requirementNormalize';
import { planNormalizer } from '../plan/planNormalize';
import { suiteNormalizer } from '../suite/suiteNormalize';
import { testNormalizer } from '../test/testNormalize';
import { milestoneNormalizer } from '../milestone/milestoneNormalize';
import { stepNormalizer } from '../step/stepNormalize';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { planSuiteTestIncludeNormalizer } from '../plan_suite_test_include/planSuiteTestIncludeNormalize';
import { runResultStepNormalizer } from '../run_result_step/runResultStepNormalize';
import { runNormalizer } from '../run/runNormalize';
import { filterNormalizer } from '../filter/filterNormalize';
import { dataSetNormalizer } from '../data_set/dataSetNormalize';
import { runSuiteNormalizer } from '../run_suite/runSuiteNormalize';

export const projectNormalizer = (
  model: ProjectApi,
  cache: NormalizerCache,
) => {
  if (!cache.project) {
    cache.project = [];
  }
  cache.project.push(model);

  if (model.integration && model.integration.length) {
    model.integration.forEach((child) => {
      integrationProjectNormalizer(child.pivot as IntegrationProject, cache);
      child.pivot = undefined;
      integrationNormalizer(child, cache);
    });
    model.integration = undefined;
  }
  if (model.defect && model.defect.length) {
    model.defect.forEach((child) => {
      defectNormalizer(child, cache);
    });
    model.defect = undefined;
  }
  if (model.requirement && model.requirement.length) {
    model.requirement.forEach((child) => {
      requirementNormalizer(child, cache);
    });
    model.requirement = undefined;
  }
  if (model.plan && model.plan.length) {
    model.plan.forEach((child) => {
      planNormalizer(child, cache);
    });
    model.plan = undefined;
  }
  if (model.suite && model.suite.length) {
    model.suite.forEach((child) => {
      suiteNormalizer(child, cache);
    });
    model.suite = undefined;
  }
  if (model.test && model.test.length) {
    model.test.forEach((child) => {
      testNormalizer(child, cache);
    });
    model.test = undefined;
  }
  if (model.milestone && model.milestone.length) {
    model.milestone.forEach((child) => {
      milestoneNormalizer(child, cache);
    });
    model.milestone = undefined;
  }
  if (model.step && model.step.length) {
    model.step.forEach((child) => {
      stepNormalizer(child, cache);
    });
    model.step = undefined;
  }
  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
  if (model.plan_suite_test_include && model.plan_suite_test_include.length) {
    model.plan_suite_test_include.forEach((child) => {
      planSuiteTestIncludeNormalizer(child, cache);
    });
    model.plan_suite_test_include = undefined;
  }
  if (model.run_result_step && model.run_result_step.length) {
    model.run_result_step.forEach((child) => {
      runResultStepNormalizer(child, cache);
    });
    model.run_result_step = undefined;
  }
  if (model.run && model.run.length) {
    model.run.forEach((child) => {
      runNormalizer(child, cache);
    });
    model.run = undefined;
  }
  if (model.filter && model.filter.length) {
    model.filter.forEach((child) => {
      filterNormalizer(child, cache);
    });
    model.filter = undefined;
  }
  if (model.data_set && model.data_set.length) {
    model.data_set.forEach((child) => {
      dataSetNormalizer(child, cache);
    });
    model.data_set = undefined;
  }
  if (model.run_suite && model.run_suite.length) {
    model.run_suite.forEach((child) => {
      runSuiteNormalizer(child, cache);
    });
    model.run_suite = undefined;
  }
};
