import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';
import { ComboBoxProps } from '@react-types/combobox';
import {
  useButton,
  useFilter,
  useComboBox,
  useHover,
  usePress,
} from 'react-aria';
import { Item, useComboBoxState } from 'react-stately';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { getFocusAccentCN } from '../hooks/useFocusAccent';
import Options from '../Select/Options';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';
import Loading from '../Loading';
import { ArrowDownIcon, CancelIcon } from '../icons';
import styles from './ComboBox.module.scss';

type AriaComboBoxProps<T> = ComboBoxProps<T> & {
  allowClear?: boolean;
  className?: string;
  error?: ReactNode;
  loading?: boolean;
  name?: string;
  size?: 'small' | 'medium';
  labelMarginTop?: number;
};

function AriaComboBox(props: AriaComboBoxProps<any>) {
  const {
    allowClear,
    error,
    isDisabled,
    isRequired,
    label,
    loading,
    onSelectionChange,
    selectedKey,
    size = 'medium',
    labelMarginTop,
  } = props;

  const targetRef = useRef<HTMLDivElement>(null);
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...props, defaultFilter: contains });

  const [selectWidth, setSelectWidth] = useState<number | null>(null);
  const { hoverProps, isHovered } = useHover({ isDisabled });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const { buttonProps } = useButton(triggerProps as any, buttonRef);

  const onResize = useCallback(() => {
    if (targetRef.current) {
      setSelectWidth(targetRef.current.offsetWidth);
    }
  }, [targetRef, setSelectWidth]);

  useResizeObserver(targetRef, onResize);

  const clearPress = usePress({
    onPressStart: () => {
      if (onSelectionChange) {
        onSelectionChange('');
      }
    },
  });

  const comboCN = classnames(
    styles.combo,
    styles[size],
    getFocusAccentCN(isHovered, state.isFocused),
  );

  const inputCN = classnames(styles.input, {
    [styles.disabled]: isDisabled,
  });

  const renderEndAdorment = () => {
    if (loading) {
      return (
        <span className={styles.endAdorment}>
          <Loading color={vars.textPrimary} delay={0} size={20} />
        </span>
      );
    }
    if (allowClear && selectedKey) {
      return (
        <span className={styles.endAdorment} {...clearPress.pressProps}>
          <CancelIcon color={vars.textPrimary} size={18} />
        </span>
      );
    }
    return (
      <button
        {...buttonProps}
        className={`${styles.endAdorment} ${styles.trigger}`}
        ref={buttonRef}
        type="button">
        <ArrowDownIcon color={vars.textPrimary} size={22} />
      </button>
    );
  };

  return (
    <div>
      {label && (
        <FieldLabel
          labelMarginTop={labelMarginTop}
          required={isRequired}
          {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <div className={comboCN} ref={targetRef} {...hoverProps}>
        <input {...inputProps} className={inputCN} ref={inputRef} />
        {renderEndAdorment()}
        {state.isOpen && !isDisabled && (
          <Options
            {...listBoxProps}
            overlayRef={popoverRef}
            listRef={listBoxRef}
            targetRef={targetRef}
            state={state}
            width={selectWidth}
          />
        )}
      </div>
      {error && <FieldError>{error}</FieldError>}
    </div>
  );
}

export interface Option {
  label: string;
  value: any;
  [key: string]: any;
}

type Props = Pick<
  AriaComboBoxProps<any>,
  | 'allowClear'
  | 'className'
  | 'disabledKeys'
  | 'error'
  | 'inputValue'
  | 'label'
  | 'loading'
  | 'name'
  | 'onFocus'
  | 'onInputChange'
  | 'placeholder'
  | 'size'
> & {
  disabled?: boolean;
  empty?: string;
  onChange: (value: any) => void;
  options: Option[];
  renderOption?: (option: Option) => ReactNode;
  required?: boolean;
  value: any;
  labelMarginTop?: number;
};

export default function ComboBox({
  disabled,
  disabledKeys,
  empty,
  inputValue,
  onChange,
  onInputChange,
  options,
  renderOption = (item) => item.label,
  required,
  value,
  ...rest
}: Props) {
  const items = useMemo(() => {
    if (!options.length && empty)
      return [
        {
          id: 'empty',
          label: empty,
        },
      ];

    return options.map(({ label, value: id, ...keys }) => ({
      id,
      label,
      ...keys,
    }));
  }, [options, empty]);

  const [input, setInput] = useState(inputValue);

  useEffect(() => {
    setInput(inputValue);
  }, [inputValue]);

  const handleOnChange = (val) => {
    const valLabel = items.find((i) => i.id === val)?.label || '';
    handleInputChange(valLabel);
    onChange(val);
  };

  const handleInputChange = (val: string) => {
    setInput(val);
    if (onInputChange) {
      onInputChange(val);
    }
  };

  return (
    <AriaComboBox
      menuTrigger="focus"
      defaultItems={items}
      disabledKeys={options.length ? disabledKeys : ['empty']}
      isDisabled={disabled}
      isRequired={required}
      onSelectionChange={handleOnChange}
      onInputChange={handleInputChange}
      selectedKey={value}
      inputValue={input}
      {...rest}>
      {(item) => <Item textValue={item.label}>{renderOption(item)}</Item>}
    </AriaComboBox>
  );
}
