import { BatchService } from '@testquality/sdk';
import { AppThunk } from '@bitmodern/redux/store';
import { attachProject } from '@bitmodern/services/projectIntegrationService';
import { integrationProjectUpsertOne } from 'src/gen/domain/integration_project/integrationProjectSlice';
import { nativeDefectResFetchManyThunk } from 'src/gen/domain/native_defect_res/nativeDefectResThunk';
import { nativeDefectStatusFetchManyThunk } from 'src/gen/domain/native_defect_status/nativeDefectStatusThunk';

export function attachProjectThunk(args: {
  integration_id: number;
  project_reference_id: string;
  project_id: number;
  username?: string;
  org?: string;
}): AppThunk {
  return async (dispatch) => {
    const integrationProjects = await attachProject({
      integrationId: args.integration_id,
      projectReferenceId: args.project_reference_id,
      projectId: args.project_id,
      userName: args.username,
      org: args.org,
    });
    if (integrationProjects[0]) {
      const integrationProject = integrationProjects[0];
      dispatch(integrationProjectUpsertOne(integrationProject));
      const batch = new BatchService();
      dispatch(
        nativeDefectResFetchManyThunk({
          batch,
          params: { per_page: -1, _with: 'DefectRes' },
        }),
      );
      dispatch(
        nativeDefectStatusFetchManyThunk({
          batch,
          params: { per_page: -1, _with: 'DefectStatus' },
        }),
      );
      await batch.executeBatch();
    }
  };
}
