import { mergeProps } from '@react-aria/utils';
import React, { useRef } from 'react';
import { ToggleButtonProps } from '@react-types/button';
import { useHover } from '@react-aria/interactions';
import { useToggleButton } from '@react-aria/button';
import { useToggleState } from '@react-stately/toggle';
import classNames from 'classnames';
import { useFocusRing } from 'react-aria';
import styles from './ToggleButton.module.scss';

type Props = ToggleButtonProps & {
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

export default function ToggleButton(props: Props) {
  const { className, isDisabled, children, size = 'medium' } = props;

  const ref = useRef(null);
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { isFocusVisible, focusProps } = useFocusRing();

  const buttonCN = classNames(
    styles.toggle,
    styles[size],
    {
      [styles.disabled]: isDisabled,
      [styles.focused]: isFocusVisible,
      [styles.pressed]: isPressed,
      [styles.hover]: isHovered,
      [styles.selected]: state.isSelected,
    },
    className,
  );

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps)}
      type="button"
      className={buttonCN}
      ref={ref}>
      {children}
    </button>
  );
}
