import {
  BaseIntegration,
  DefectComponent,
  DefectLabel,
  DefectPriority,
  DefectType,
  DefectUser,
  NativeDefectRes,
  NativeDefectStatus,
} from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import { defectComponentsByIntegrationProjectSelector } from 'src/packages/redux/state/defectComponents/selectors';
import { defectPrioritiesByIntegrationProjectSelector } from 'src/packages/redux/state/defectPriorities/selectors';
import { defectUsersByIntegrationProjectSelector } from 'src/packages/redux/state/defectUsers/selectors';
import { defectTypesByIntegrationProjectSelector } from 'src/packages/redux/state/defectTypes/selectors';
import { ownDefectLabelsByIntegrationProjectSelector } from 'src/packages/redux/state/defectLabels/selectors';
import { nativeDefectStatusesByIntegrationProject } from 'src/packages/redux/state/nativeDefectStatuses/selectors';
import { nativeDefectResByIntegrationProjectSelectors } from 'src/packages/redux/state/nativeDefectRes/selectors';
import { baseIntegrationByIdSelector } from 'src/packages/redux/state/baseIntegration/selectors';
import { integrationByIdSelector } from 'src/packages/redux/state/integration/selectors';

export type FormArgs = {
  integrationProjectId: number;
  integrationId?: number;
};

export type DefectOverviewData = {
  defectPriorities: DefectPriority[];
  defectStatuses: NativeDefectStatus[];
  defectResolutions: NativeDefectRes[];
  defectUsers: DefectUser[];
  defectTypes: DefectType[];
  defectLabels: DefectLabel[];
  defectComponents: DefectComponent[];
  baseIntegration: BaseIntegration | undefined;
};

export default function useDefectOverview({
  integrationProjectId,
  integrationId,
}: FormArgs): DefectOverviewData {
  return useGetDefectOverview(integrationProjectId, integrationId);
}

function useGetDefectOverview(integrationProjectId, integrationId) {
  const defectPriorities = useAppSelector(
    defectPrioritiesByIntegrationProjectSelector(integrationProjectId),
  );
  const defectStatuses = useAppSelector((state) =>
    nativeDefectStatusesByIntegrationProject(state, integrationProjectId),
  );
  const defectResolutions = useAppSelector((state) =>
    nativeDefectResByIntegrationProjectSelectors(state, integrationProjectId),
  );
  const defectUsers = useAppSelector(
    defectUsersByIntegrationProjectSelector(integrationProjectId),
  );
  const defectTypes = useAppSelector(
    defectTypesByIntegrationProjectSelector(integrationProjectId),
  );
  const defectLabels = useAppSelector(
    ownDefectLabelsByIntegrationProjectSelector(integrationProjectId),
  );
  const defectComponents = useAppSelector(
    defectComponentsByIntegrationProjectSelector(integrationProjectId),
  );

  const integration = useAppSelector((state) => {
    return integrationByIdSelector(state, {
      id: integrationId,
    });
  });

  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, {
      id: integration?.base_integration_id,
    }),
  );

  return {
    defectPriorities,
    defectStatuses,
    defectResolutions,
    defectUsers,
    defectTypes,
    defectLabels,
    defectComponents,
    baseIntegration,
  };
}
