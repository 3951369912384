import React, { ComponentProps } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useHover } from 'react-aria';
import { TreeItem } from 'src/components/organisms';
import { IconButton } from '@bitmodern/bit-ui';
import {
  AddIcon,
  DeleteIcon,
  DropdownDownIcon,
  DropdownUpIcon,
  EditIcon,
} from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import styles from './ConfigurationItem.module.scss';

type Props = {
  collapsed?: boolean;
  onCreate?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  titleCreate?: string;
  titleDelete?: string;
  titleEdit?: string;
} & Pick<
  ComponentProps<typeof TreeItem>,
  'onClick' | 'mainData' | 'nestingLevel'
>;

export default function ConfigurationItem({
  collapsed,
  mainData,
  nestingLevel,
  onClick,
  onCreate,
  onDelete,
  onEdit,
  titleCreate,
  titleDelete,
  titleEdit,
}: Props) {
  const { hoverProps, isHovered } = useHover({});

  const collapsedIcon = collapsed ? (
    <DropdownUpIcon key="dropdownUp" color={vars.textPrimary} />
  ) : (
    <DropdownDownIcon key="dropdownDown" color={vars.textPrimary} />
  );

  return (
    <TreeItem
      {...hoverProps}
      mainData={mainData}
      nestingLevel={nestingLevel}
      notIncluded={false}
      onClick={onClick}
      rightSection={
        <AnimatePresence>
          <div className={styles.actions}>
            {isHovered && (
              <>
                {onCreate && (
                  <MotionIconButton
                    {...motionProps}
                    key="create"
                    boxed={false}
                    onClick={onCreate}
                    title={titleCreate}
                    size="small">
                    <AddIcon color={vars.textPrimary} size={16} />
                  </MotionIconButton>
                )}
                {onEdit && (
                  <MotionIconButton
                    {...motionProps}
                    key="edit"
                    boxed={false}
                    onClick={onEdit}
                    title={titleEdit}
                    size="small">
                    <EditIcon color={vars.textPrimary} size={16} />
                  </MotionIconButton>
                )}
                {onDelete && (
                  <MotionIconButton
                    {...motionProps}
                    key="delete"
                    boxed={false}
                    onClick={onDelete}
                    title={titleDelete}
                    size="small">
                    <DeleteIcon color={vars.textPrimary} size={16} />
                  </MotionIconButton>
                )}
              </>
            )}
            {typeof collapsed === 'boolean' && collapsedIcon}
          </div>
        </AnimatePresence>
      }
    />
  );
}

const MotionIconButton = motion(IconButton);

const variants: Variants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
};

const motionProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants,
};
