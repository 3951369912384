import React, { ReactElement } from 'react';
import { useRouteMatch } from 'react-router';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import {
  isAuthenticatedSelector,
  tokenSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import { MainLayout } from 'src/components/organisms';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { Loading } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import useFetch from 'src/hooks/useFetch';
import { getEnv } from 'src/env';
import { getInitialFetchThunk } from '@bitmodern/redux/state/initialFetchThunks';
import { ConsoleLogger } from '../../../common/ConsoleLogger';
import styles from './MainView.module.scss';

type Props = {
  children: ReactElement;
};

const log = new ConsoleLogger('MainView');

export default function MainView({ children }: Props) {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const token = useAppSelector(tokenSelector);
  const dispatch = useAppDispatch();
  const getStartedMatch = useRouteMatch(routes.GET_STARTED.path);

  const clientId = token?.client_id;
  const userId = token?.user_id;

  const globalFetch = useFetch(
    ({ dataCached }) => {
      if (!isAuthenticated || !clientId || !userId) return Promise.resolve();
      return dispatch(
        getInitialFetchThunk({ userId, clientId, dataCached }),
      ).catch((error) => log.warn('batch failed', error));
    },
    ['main', clientId, userId],
    {
      ignoreCallback: true,
      ...(getEnv()?.features?.dataCache && { staleWhileRevalidate: true }),
    },
  );

  return (
    <MainLayout>
      <Helmet>
        <body
          className={classnames(styles.body, {
            [styles.getStarted]: getStartedMatch,
          })}
        />
      </Helmet>
      {globalFetch.isLoading && (
        <Loading className={styles.loading} size={48} />
      )}
      {globalFetch.isFetched && children}
    </MainLayout>
  );
}
