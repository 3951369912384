import React from 'react';
import { ConfirmDialog, SettingsPanel } from 'src/components/organisms';
import { ConfigurationTreeType } from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import TreeBase from 'src/components/organisms/TreeBase';
import { Button } from '@bitmodern/bit-ui';
import { AddIcon, SettingsIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import AppFormDialog from './AppFormDialog';
import PlatFormDialog from './PlatFormDialog';
import PlatVersionFormDialog from './PlatVersionFormDialog';
import ConfigurationItem from './ConfigurationItem';
import styles from './ConfigurationsView.module.scss';
import useConfigurationView from './useConfigurationView';
import AppVersionFormDialog from './AppVersionFormDialog';
import EditAppFormDialog from './EditAppFormDialog';

export default function ConfigurationsView() {
  const { t } = useTranslation();
  const {
    appDialog,
    appEditDialog,
    appVersionDialog,
    configurationsTree,
    confirmDialog,
    createAppVersion,
    createPlatVersion,
    data,
    clearData,
    deleteApp,
    deleteAppVersion,
    deletePlatform,
    deletePlatVersion,
    editAppVersion,
    editApp,
    editPlat,
    editPlatVersion,
    platformDialog,
    toogleCollapse,
    platVersionDialog,
  } = useConfigurationView();

  return (
    <SettingsPanel
      actions={
        <Button
          color="primaryLight"
          icon={<AddIcon color={vars.textPrimary} size={16} />}
          onClick={platformDialog.open}
          size="small">
          {t('configurations.addPlatform')}
        </Button>
      }
      fullHeight
      icon={<SettingsIcon color={vars.textPrimary} size={20} />}
      paddingLess
      title={t('configurations.title')}>
      <div className={styles.treeWrap}>
        <TreeBase
          canDrag={false}
          id="configurations"
          renderNodeType={{
            [ConfigurationTreeType.Platform]: (item) => {
              return (
                <ConfigurationItem
                  collapsed={item.node.expanded || false}
                  mainData={<div>{item.node.title}</div>}
                  nestingLevel={item.path.length - 1}
                  onClick={() => toogleCollapse(item)}
                  onCreate={() => createPlatVersion(item.node.data)}
                  onDelete={() =>
                    deletePlatform(item.node.data, item.node.children)
                  }
                  onEdit={() => editPlat(item.node.data)}
                />
              );
            },
            [ConfigurationTreeType.PlatVersion]: (item) => {
              return (
                <ConfigurationItem
                  collapsed={item.node.expanded || false}
                  mainData={<div>{item.node.title}</div>}
                  nestingLevel={item.path.length - 1}
                  onClick={() => toogleCollapse(item)}
                  onCreate={() => createAppVersion(item.node.data)}
                  onDelete={() => deletePlatVersion(item.node.data)}
                  onEdit={() =>
                    editPlatVersion(item?.parentNode?.data, item.node.data)
                  }
                />
              );
            },
            [ConfigurationTreeType.App]: (item) => {
              return (
                <ConfigurationItem
                  collapsed={item.node.expanded || false}
                  mainData={<div>{item.node.title}</div>}
                  nestingLevel={item.path.length - 1}
                  onClick={() => toogleCollapse(item)}
                  onDelete={() => {
                    if (!item?.node?.children?.length) return;
                    deleteApp(
                      // @ts-expect-error
                      item?.node?.children?.map(
                        (el) => el.data.appVersionPlatVersion,
                      ),
                    );
                  }}
                  onEdit={() => editApp(item.node.data.app)}
                />
              );
            },
            [ConfigurationTreeType.AppVersion]: (item) => {
              return (
                <ConfigurationItem
                  mainData={<div>{item.node.title}</div>}
                  nestingLevel={item.path.length - 1}
                  onDelete={() =>
                    deleteAppVersion(item.node.data.appVersionPlatVersion)
                  }
                  onEdit={() =>
                    editAppVersion(
                      item?.parentNode?.data?.app,
                      item.node.data.appVersion,
                    )
                  }
                />
              );
            },
          }}
          treeData={configurationsTree}
        />
      </div>
      <ConfirmDialog
        loading={confirmDialog?.mutation?.isLoading}
        onCancel={confirmDialog.close}
        onConfirm={confirmDialog?.mutation?.mutate}
        open={confirmDialog.isOpen}
        title={confirmDialog?.title}>
        {confirmDialog?.content}
      </ConfirmDialog>
      <AppFormDialog
        {...data}
        isOpen={appDialog.isOpen}
        onClose={() => {
          clearData();
          appDialog.close();
        }}
      />
      <PlatFormDialog
        {...data}
        isOpen={platformDialog.isOpen}
        onClose={() => {
          clearData();
          platformDialog.close();
        }}
      />
      <PlatVersionFormDialog
        {...data}
        isOpen={platVersionDialog.isOpen}
        onClose={() => {
          clearData();
          platVersionDialog.close();
        }}
      />
      <AppVersionFormDialog
        {...data}
        isOpen={appVersionDialog.isOpen}
        onClose={() => {
          clearData();
          appVersionDialog.close();
        }}
      />
      <EditAppFormDialog
        {...data}
        isOpen={appEditDialog.isOpen}
        onClose={() => {
          clearData();
          appEditDialog.close();
        }}
      />
    </SettingsPanel>
  );
}
