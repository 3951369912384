/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { TestQualityApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { testNormalizer } from '../test/testNormalize';

export const testQualityNormalizer = (
  model: TestQualityApi,
  cache: NormalizerCache,
) => {
  if (!cache.testQuality) {
    cache.testQuality = [];
  }
  cache.testQuality.push(model);

  if (model.test && model.test.length) {
    model.test.forEach((child) => {
      testNormalizer(child, cache);
    });
    model.test = undefined;
  }
};
