/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { LabelAssignedApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const labelAssignedNormalizer = (
  model: LabelAssignedApi,
  cache: NormalizerCache,
) => {
  if (!cache.labelAssigned) {
    cache.labelAssigned = [];
  }
  cache.labelAssigned.push(model);
};
