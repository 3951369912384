/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AppInstallProjectApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { pullRequestNormalizer } from '../pull_request/pullRequestNormalize';
import { integrationProjectNormalizer } from '../integration_project/integrationProjectNormalize';

export const appInstallProjectNormalizer = (
  model: AppInstallProjectApi,
  cache: NormalizerCache,
) => {
  if (!cache.appInstallProject) {
    cache.appInstallProject = [];
  }
  cache.appInstallProject.push(model);

  if (model.pull_request && model.pull_request.length) {
    model.pull_request.forEach((child) => {
      pullRequestNormalizer(child, cache);
    });
    model.pull_request = undefined;
  }
  if (model.integration_project && model.integration_project.length) {
    model.integration_project.forEach((child) => {
      integrationProjectNormalizer(child, cache);
    });
    model.integration_project = undefined;
  }
};
