import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  PanelActions,
  Spacer,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import styles from './ConfirmDialog.module.scss';

type Props = {
  children: ReactNode;
  isDismissable?: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  title: ReactNode;
};

export default function ConfirmDialog({
  children,
  isDismissable = true,
  loading,
  onCancel,
  onConfirm,
  open,
  title,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      isDismissable={isDismissable}
      isOpen={open}
      onClose={onCancel}
      size="small"
      title={title}>
      <DialogContent className={styles.content}>{children}</DialogContent>

      <PanelActions>
        <Spacer>
          <Button color="primaryDark" onClick={onCancel}>
            {t('confirmDialog.cancel')}
          </Button>
          <Button loading={loading} onClick={onConfirm}>
            {t('confirmDialog.confirm')}
          </Button>
        </Spacer>
      </PanelActions>
    </Dialog>
  );
}
