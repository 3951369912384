import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import { IconButton, useFocusAccent } from '@bitmodern/bit-ui';
import {
  CancelIcon,
  RemoveCircleIcon,
  TqDissatisfiedIcon,
  TqSatisfiedIcon,
  TqVeryDissatisfiedIcon,
  TqVerySatisfiedIcon,
} from '@bitmodern/bit-ui/icons';
import { TestQuality } from '@testquality/sdk';
import { useAppSelector } from '@bitmodern/redux/store';
import { testQualityTypeSelectorById } from '@bitmodern/redux/state/testQualityType/selectors';
import styles from './TestQualityField.module.scss';

type Props = {
  className?: string;
  onChange: (value: boolean) => void;
  placeholder?: string;
  testQuality: TestQuality | undefined;
  userOverride: boolean;
};

export default function TestQualityField({
  className,
  placeholder = '',
  onChange,
  testQuality,
  userOverride,
}: Props) {
  const { t } = useTranslation();
  const { borderClassName, borderProps, isHovered } = useFocusAccent();

  const testQualityType = useAppSelector((state) =>
    testQualityTypeSelectorById(
      state,
      testQuality ? testQuality.test_quality_type_id : -1,
    ),
  );

  const onClick = () => {
    if (onChange) {
      onChange(!userOverride);
    }
  };

  const inlineCN = classnames(
    className,
    borderClassName,
    styles.testQualityField,
  );

  let faceIcon;
  const getTestQualityTypeIcon = (key: number) => {
    switch (key) {
      case 1:
        faceIcon = <TqVerySatisfiedIcon color={vars.textPrimary} size={20} />; // Excellent
        break;
      case 2:
        faceIcon = <TqDissatisfiedIcon color={vars.textPrimary} size={20} />; // Warning
        break;
      case 3:
        faceIcon = (
          <TqVeryDissatisfiedIcon color={vars.textPrimary} size={20} /> // Bad
        );
        break;
      case 4:
        faceIcon = <TqSatisfiedIcon color={vars.textPrimary} size={20} />; // Good
        break;
      default:
        faceIcon = undefined;
    }
    return faceIcon;
  };

  return (
    <div className={inlineCN} {...borderProps}>
      {testQualityType ? getTestQualityTypeIcon(testQualityType.key) : null}
      <div className={styles.name} placeholder={placeholder}>
        {`${testQualityType?.name.toUpperCase()} ${testQuality?.name} ${
          userOverride ? ' - Fixed' : ''
        }`}
      </div>
      {isHovered ? (
        <IconButton
          onClick={onClick}
          title={
            userOverride
              ? t('testQualityField.add')
              : t('testQualityField.remove')
          }>
          {userOverride ? (
            <CancelIcon color={vars.textPrimary} size={16} />
          ) : (
            <RemoveCircleIcon color={vars.textPrimary} size={16} />
          )}
        </IconButton>
      ) : null}
    </div>
  );
}
