import { Test } from '@testquality/sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import AnalyzeStats from 'src/components/organisms/AnalyzeStats';
import {
  warningTestQualitySelector,
  goodTestQualitySelector,
} from '@bitmodern/redux/state/testQuality/selectors';
import vars from 'src/export.scss';
import styles from './TestQualityMetrics.module.scss';

type Props = {
  tests: Test[];
  className?: string;
};

function TestQualityMetrics({ tests, className = '' }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const goodTestQuality = useAppSelector(goodTestQualitySelector);
  const warningTestQuality = useAppSelector(warningTestQualitySelector);
  const projectTestQualities = tests.map((test) => test.test_quality_id);
  /* NOTE: Get ONLY the IDs of the test qualities used in the project so we can */
  /* filter by them without setting filters we cannot unset from the breakdown list */
  const goodTestQualityIds = goodTestQuality
    .map((tq) => tq.id)
    .filter((id) => projectTestQualities.includes(id));
  const warningTestQualityIds = warningTestQuality
    .map((tq) => tq.id)
    .filter((id) => projectTestQualities.includes(id));

  const { totalGood, totalWarning } = useMemo(() => {
    const goodTests = tests.filter(
      (test) =>
        test.test_quality_id &&
        goodTestQualityIds.includes(test.test_quality_id),
    );
    const warningTests = tests.filter(
      (test) =>
        test.test_quality_id &&
        warningTestQualityIds.includes(test.test_quality_id),
    );

    return {
      totalGood: goodTests.length,
      totalWarning: warningTests.length,
    };
  }, [tests, goodTestQualityIds, warningTestQualityIds]);

  const onClickGoodQuality = () => {
    goodTestQualityIds.forEach((id) => {
      dispatch(
        toggleFilterAction({
          type: 'analyzeTestQuality',
          filter: 'testQuality',
          value: id,
        }),
      );
    });
  };

  const onClickWarningQuality = () => {
    warningTestQualityIds.forEach((id) => {
      dispatch(
        toggleFilterAction({
          type: 'analyzeTestQuality',
          filter: 'testQuality',
          value: id,
        }),
      );
    });
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <AnalyzeStats
        onClick={onClickGoodQuality}
        title={t('analyze.testQuality.metrics.totalGood.title')}
        subtitle={t('analyze.testQuality.metrics.totalGood.subtitle')}
        stat={totalGood}
        statColor={vars.successMain}
      />
      <AnalyzeStats
        onClick={onClickWarningQuality}
        title={t('analyze.testQuality.metrics.totalWarning.title')}
        subtitle={t('analyze.testQuality.metrics.totalWarning.subtitle')}
        stat={totalWarning}
        statColor={vars.warningMain}
      />
    </div>
  );
}

export default React.memo(TestQualityMetrics);
