import React from 'react';
import styles from './ChipProgress.module.scss';

type Props = {
  className?: string;
  idValue?: number | string;
  progress: Array<{
    color: string;
    percent: number;
  }>;
  size?: number;
};

export default function ChipProgress({
  className = '',
  progress,
  idValue,
  size = 48,
}: Props) {
  const statusSize = [...progress]
    .sort((a, b) => a.percent - b.percent)
    .reduce((acu, status, index, arr) => {
      if (index > 0 && status.percent === arr[index - 1].percent) {
        acu[status.color] = acu[arr[index - 1].color];
        return acu;
      }
      if (status.percent <= 0) {
        acu[status.color] = 0;
      } else {
        acu[status.color] = (index + 1) / 2;
      }
      return acu;
    }, {});

  return (
    <div className={`${styles.progress} ${className}`} style={{ width: size }}>
      {progress.map(({ color }, index) => (
        <div
          // eslint-disable-next-line
          key={`${idValue}-${index}`}
          className={styles.item}
          style={{
            backgroundColor: color,
            flexGrow: statusSize[color],
          }}
        />
      ))}
    </div>
  );
}
