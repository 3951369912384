import {
  GherkinExamples,
  GherkinFeature,
  GherkinScenario,
  GherkinStep,
} from '../types';

export default function featureToString(feature: GherkinFeature): string {
  const tags = feature.tags.reduce(
    (acc, tag) => (tag.startsWith('@') ? `${acc}${tag} ` : `${acc}@${tag} `),
    '',
  );
  const title = `${feature.title}\n`;
  const description = feature.description
    ? `\t${feature.description.replace('\n', '\n\t')}\n`
    : '';
  const scenarios = feature.scenarios.reduce(
    (acc, scenario) => `${acc}${scenarioToString(scenario)}\n`,
    '',
  );
  return `${tags}${
    tags.length > 0 ? '\n' : ''
  }${title}${description}\n${scenarios}`;
}

function scenarioToString(scenario: GherkinScenario): string {
  const tags = scenario.tags.reduce(
    (acc, tag) => (tag.startsWith('@') ? `${acc}${tag} ` : `${acc}@${tag} `),
    '',
  );
  const description = `Scenario${scenario.examples ? ' Outline' : ''}: ${
    scenario.description
  }\n`;

  let given = '';
  if (scenario.given) {
    given = scenario.given
      .split('\n')
      .filter((l) => l.trim().length > 0)
      .map((line) => line.replace('Given ', '').replace('And ', '').trim())
      .map((line, index) => (index === 0 ? `\tGiven ${line}` : `\tAnd ${line}`))
      .reduce((acc, line) => `${acc}${line}\n`, '');
  }

  const examples = scenario.examples
    ? `${examplesToString(scenario.examples)}`
    : '';

  return `${tags}${
    tags.length > 0 ? '\n' : ''
  }${description}${given}${stepsToString(scenario.steps)}${examples}`;
}

function stepsToString(steps: GherkinStep[]) {
  return steps.reduce(
    (acc, step: GherkinStep) => `${acc}${stepToString(step)}`,
    '',
  );
}

function stepToString(step: GherkinStep) {
  const when = step.when
    ? `\tWhen ${step.when.replace('\n', '\n\tAnd ').replace('When ', '')}\n`
        .replaceAll('{{', '<')
        .replaceAll('}}', '>')
    : '';

  const then = step.then
    ? `\tThen ${step.then.replace('\n', '\n\tAnd ').replace('Then ', '')}\n`
        .replaceAll('{{', '<')
        .replaceAll('}}', '>')
    : '';
  return `${when}${then}`;
}

function examplesToString(examples: GherkinExamples) {
  const headers = examples.headers.reduce(
    (acc, header) => `${acc}${header} | `,
    '',
  );

  const rows = examples.rows.reduce(
    (acc, row) => `${acc}\t\t| ${row.join(' | ')} |\n`,
    '',
  );

  return `\tExamples:\n\t\t| ${headers}\n${rows}`;
}
