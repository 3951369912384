/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { testQualityTypeAdapter } from './testQualityTypeAdapter';

export const testQualityTypeSelectors = testQualityTypeAdapter.getSelectors(
  (state: State) => state.gen.testQualityType,
);
