import React from 'react';
import { DefectStatus } from '@testquality/sdk';
import DoneIcon from '@bitmodern/bit-ui/icons/DoneIcon';
import vars from 'src/export.scss';
import styles from './DefectStatusItem.module.scss';

type Props = {
  defectStatus?: DefectStatus;
};

export function DefectStatusItem({ defectStatus }: Props) {
  if (!defectStatus) {
    return <></>;
  }
  switch (defectStatus.key) {
    case 2: // in Progress
      return (
        <div className={`${styles.status} ${styles.open}`}>
          <span>{defectStatus.name}</span>
        </div>
      );
    case 3: // Done
      return (
        <div className={styles.status}>
          <DoneIcon
            className={styles.statusIcon}
            color={vars.successMain}
            size={16}
          />
          <span>{defectStatus.name}</span>
        </div>
      );
    default:
      return (
        <div className={styles.status}>
          <span>{defectStatus.name}</span>
        </div>
      );
  }
}
