import { runResultSelectors } from 'src/gen/domain/run_result/runResultSelector';
import { runResultStepSelectors } from 'src/gen/domain/run_result_step/runResultStepSelector';
import { State } from '@bitmodern/redux/store';

export function runResultStepsSelector(state: State) {
  return runResultStepSelectors.selectAll(state);
}

export function runResultStepsByRunResultSelector(
  state: State,
  runResultId?: number,
) {
  return runResultStepsSelector(state).filter(
    (runResult) => runResult.run_result_id === runResultId,
  );
}

export function runResultStepsByRunStatusSelector(
  state: State,
  runId: number,
  statusId: number,
) {
  const runResults = runResultSelectors
    .selectAll(state)
    .filter((runResult) => runResult.run_id === runId);

  if (!runResults.length) return [];

  const runResultsIds = runResults.map((runResult) => runResult.id);

  return runResultStepsSelector(state).filter(
    (rrs) =>
      rrs.status_id === statusId && runResultsIds.includes(rrs.run_result_id),
  );
}

export function runResultStepsByRunResultsSelector(
  state: State,
  runResultIds: number[],
) {
  return runResultStepsSelector(state).filter((runResult) =>
    runResultIds.includes(runResult.run_result_id),
  );
}
