import { State } from '@bitmodern/redux/store';
import { DateRange } from 'src/common/DataRange';
import { dateRangeByTimeframe } from 'src/packages/bit-ui/InputDateRange/InputDateRange';

export function getDateRangeByTimeframe(state: State, args: { type?: string }) {
  if (!args.type) {
    return null;
  }
  const filters = state.filters[args.type];

  const timeframe = filters.timeframe;
  const timeframeRange = dateRangeByTimeframe(timeframe);

  const startDate = timeframeRange
    ? timeframeRange[0]
    : new Date(filters.startDate);

  const endDate = timeframeRange
    ? timeframeRange[1]
    : new Date(filters.endDate);
  if (!startDate || !endDate) return null;

  const range: DateRange = [startDate, endDate];
  return range;
}
