import React from 'react';
import { Tooltip } from '@bitmodern/bit-ui';
import { InfoIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { Run, RunResult } from '@testquality/sdk';

import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { runResultsByRunIdSelector } from '@bitmodern/redux/state/runResults/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import {
  getStatusChartColor,
  getStatusTypeChartColor,
} from 'src/utils/statusHelper';
import { testSelectors } from 'src/gen/domain/test/testSelector';
import StatusIcon from '../StatusIcon';
import styles from './TestStatusChart.module.scss';
import { statusTypesSelector } from 'src/packages/redux/state/statusTypes/selectors';

type Props = {
  className?: string;
  run: Run;
};

export default function TestStatusChart({ className = '', run }: Props) {
  const { openDrawer } = useDrawerManager();
  const runResults = useAppSelector((state) =>
    runResultsByRunIdSelector(state, { runId: run.id }),
  );
  const tests = useAppSelector(testSelectors.selectEntities);
  const statuses = useAppSelector(statusSelectors.selectEntities);

  const statusTypes = useAppSelector(statusTypesSelector);
  const checkStatusColor = (status) => {
    let color = getStatusChartColor(status);
    if (!color.length) {
      const statusType = statusTypes.find(
        (s) => s.id === status.status_type_id,
      );
      color = getStatusTypeChartColor(statusType);
    }
    return color;
  };

  const onClickResult = (runResult: RunResult) => {
    openDrawer({ resultId: runResult.id });
  };

  const getToolTipContent = (result: RunResult) => {
    const test = tests[result.test_id];
    const status = statuses[result.status_id];

    return (
      <div className={styles.restulToolTip}>
        <div className={styles.testKey}>
          <span>{`TC${test?.key}`}</span>
          <InfoIcon
            className={styles.infoIcon}
            color={vars.textPrimary}
            size={18}
          />
        </div>
        <div className={styles.testName}>{test?.name}</div>
        <div className={styles.status}>
          {status && <StatusIcon status={status} size={16} />}
          <div className={styles.statusName}>{status?.name}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.chart} ${className}`}>
      {runResults.map((runResult: RunResult) => {
        const status = statuses[runResult.status_id];
        const backgroundColor = status ? checkStatusColor(status) : '';

        return (
          <Tooltip
            enterDelay={200}
            key={runResult.id}
            tooltip={getToolTipContent(runResult)}>
            <div
              className={styles.runResult}
              onClick={() => onClickResult(runResult)}
              style={{ backgroundColor }}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}
