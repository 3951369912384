import React, { ReactNode } from 'react';
import styles from './RunListBadge.module.scss';
import { Tooltip } from 'src/packages/bit-ui';

type RunListBadgeProps = {
  icon: ReactNode;
  text: string;
};

export default function RunListBadge({ icon, text }: RunListBadgeProps) {
  return (
    <Tooltip tooltip={text}>
      <span>
        <span className={styles.badge}>
          {icon}
          <div className={styles.badgeText}>{text}</div>
        </span>
      </span>
    </Tooltip>
  );
}
