import { createReducer, current } from '@reduxjs/toolkit';
import { InputDateRange } from '@bitmodern/bit-ui';
import { DateRange } from 'src/common/DataRange';
import { applyCustomFilterAction } from '../customFilters/actions';
import {
  clearAllFiltersAction,
  emptyFiltersAction,
  toggleFilterAction,
} from './actions';

export type TestFilters = {
  author?: { [key: number]: number };
  caseType?: { [key: number]: number };
  casePriority?: { [key: number]: number };
  assignee?: { [key: number]: number };
  automated?: boolean;
  label?: { [key: number]: number };
  plan?: { [key: number]: number };
  name?: string;
  startDate?: string;
  endDate?: string;
  testQuality?: { [key: number]: number };
  requirement?: { [key: number]: number };
  timeframe?: string;
  // CAREFUL: add new keys to testsFiltersKeys
};

export const testsFiltersKeys: Array<keyof TestFilters> = [
  'author',
  'caseType',
  'casePriority',
  'assignee',
  'automated',
  'label',
  'plan',
  'name',
  'startDate',
  'endDate',
  'testQuality',
  'requirement',
  'timeframe',
];

export type RunResultFilters = TestFilters & {
  actualResult?: string;
  hasActualResult?: boolean;
  status?: { [key: number]: number };
  // CAREFUL: add new keys to RunResultFiltersKeys
};

export const RunResultFiltersKeys: Array<keyof RunResultFilters> = [
  'actualResult',
  'hasActualResult',
  'status',
];

export type SuiteFilters = {
  suiteName?: string;
  suiteLabel?: { [key: number]: number };
  // CAREFUL: add new keys to suiteFiltersKeys
};

export const suiteFiltersKeys: Array<keyof SuiteFilters> = [
  'suiteName',
  'suiteLabel',
];

export type RequirementFilters = {
  summary?: string;
};

export const requirementsFiltersKeys: Array<keyof RequirementFilters> = [
  'summary',
];

export type TreeFilters = TestFilters &
  RunResultFilters &
  SuiteFilters &
  RequirementFilters;

interface PlansFilters extends TreeFilters {
  exclusions: boolean;
}
interface RequirementsFilters extends TreeFilters {
  exclusions: boolean;
}

export interface State {
  plans: PlansFilters;
  tests: TreeFilters;
  runs: TreeFilters;
  analyze: TreeFilters;
  analyzeTestQuality: TreeFilters;
  overview: TreeFilters;

  plansList: {
    name?: string;
  };

  requirementsList: {
    summary?: string;
  };

  requirements: RequirementsFilters;
}

export type TestsFiltersKeys = keyof Pick<
  State,
  'plans' | 'tests' | 'runs' | 'analyze' | 'analyzeTestQuality' | 'requirements'
>;

export const entities = [
  'author',
  'caseType',
  'casePriority',
  'assignee',
  'label',
  'suiteLabel',
  'plan',
  'status',
  'testQuality',
  'requirement',
];

/* NOTE: Use default values for analyze filter */
const [startDate, endDate] = InputDateRange.defaultTimeranges[1]
  .value as DateRange;

const initialState: State = {
  plans: {
    exclusions: true,
  },
  plansList: {},
  analyze: {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  },
  analyzeTestQuality: {},
  runs: {},
  tests: {},
  requirementsList: {},
  requirements: {
    exclusions: true,
  },
  overview: {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  },
};

export default createReducer(initialState, (builder) => {
  builder.addCase(emptyFiltersAction, (state, { payload }) => {
    const filterInitialState = initialState[payload.type][payload.filter];
    if (filterInitialState) {
      state[payload.type][payload.filter] = filterInitialState;
    } else {
      delete state[payload.type][payload.filter];
    }
  });

  builder.addCase(clearAllFiltersAction, (state, { payload }) => {
    state[payload.type] = initialState[payload.type];
  });

  builder.addCase(
    toggleFilterAction,
    (state, { payload: { type, filter, value } }) => {
      const currentState: State = current(state);
      if (!currentState[type]) {
        currentState[type] = {};
        currentState[type][filter] = '';
      }

      const filterState = currentState[type][filter];

      if (entities.includes(String(filter))) {
        if (!filterState?.[value]) {
          state[type][filter] = {
            ...state[type][filter],
            [value]: value,
          };
        } else {
          delete state[type][filter]?.[value];

          // Remove object if empty
          const filters = currentState[type][filter];
          if (filters && Object.keys(filters).length <= 1) {
            delete state[type][filter];
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!filterState?.[value]) {
          state[type][filter] = value;
        } else {
          delete state[type][filter];
        }
      }
    },
  );

  builder.addCase(applyCustomFilterAction, (state, { payload }) => {
    const { filter: customFilter, type } = payload;
    state[type] = filtersBuilder(customFilter.payload);
  });
});

/* NOTE: Prevent us from injecting a stringified Date into the store */
function filtersBuilder(customFilter): PlansFilters {
  const filters = { ...customFilter };
  if (filters.startDate) filters.startDate = new Date(filters.startDate);
  if (filters.endDate) filters.endDate = new Date(filters.endDate);
  return filters;
}
