import React from 'react';
import { Grid, Loading, Select } from '@bitmodern/bit-ui';
import { IntegrationProject } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { nativeDefectResByIntegrationProjectSelectors } from '@bitmodern/redux/state/nativeDefectRes/selectors';
import { nativeDefectStatusesByIntegrationProject } from '@bitmodern/redux/state/nativeDefectStatuses/selectors';
import { defectStatusesSelector } from '@bitmodern/redux/state/defectStatuses/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { defectResSelector } from '@bitmodern/redux/state/defectRes/selectors';
import { defectResNativeDefectResByNativeRes } from '@bitmodern/redux/state/defectResNativeDefectRes/selectors';
import { defectStatusNativeDefectStatusByNativeStatus } from '@bitmodern/redux/state/defectStatusNativeDefectStatus/selectors';
import { integrationByIdSelector } from '@bitmodern/redux/state/integration/selectors';
import vars from 'src/export.scss';
import styles from './IntegrationMappings.module.scss';
import { defectStatusNativeDefectStatusUpdateOneThunk } from '../../../gen/domain/defect_status_native_defect_status/defectStatusNativeDefectStatusThunk';
import { defectResNativeDefectResUpdateOneThunk } from '../../../gen/domain/defect_res_native_defect_res/defectResNativeDefectResThunk';

type Props = {
  integrationProject: IntegrationProject;
};

export default function IntegrationMappings({ integrationProject }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defectStatuses = useAppSelector(defectStatusesSelector);
  const defectRes = useAppSelector(defectResSelector);
  const nativeDefectRes = useAppSelector((state) =>
    nativeDefectResByIntegrationProjectSelectors(state, integrationProject.id),
  );
  const integration = useAppSelector((state) => {
    return integrationByIdSelector(state, {
      id: integrationProject?.integration_id,
    });
  });

  const nativeDefectStatuses = useAppSelector((state) =>
    nativeDefectStatusesByIntegrationProject(state, integrationProject.id),
  );

  const defectResNativeDefectRes = useAppSelector((state) =>
    defectResNativeDefectResByNativeRes(
      state,
      nativeDefectRes.map((ndr) => ndr.id),
    ),
  );

  const defectStatusNativeDefectStatus = useAppSelector((state) =>
    defectStatusNativeDefectStatusByNativeStatus(
      state,
      nativeDefectStatuses.map((status) => status.id),
    ),
  );

  const onChangeDefectStatus = (nativeStatus) => {
    return (statusId: number) => {
      const defectStatusNative = defectStatusNativeDefectStatus.find(
        (item) => item.native_defect_status_id === nativeStatus.id,
      );
      if (!defectStatusNative) return;
      dispatch(
        defectStatusNativeDefectStatusUpdateOneThunk({
          id: defectStatusNative.id,
          data: { defect_status_id: statusId },
        }),
      );
    };
  };

  const onChangeDefectRes = (resolution) => {
    return (value: number) => {
      const defectResNative = defectResNativeDefectRes.find(
        (item) => item.native_defect_res_id === resolution.id,
      );
      if (!defectResNative) return;
      dispatch(
        defectResNativeDefectResUpdateOneThunk({
          id: defectResNative.id,
          data: { defect_res_id: value },
        }),
      );
    };
  };

  const statusLoading =
    nativeDefectStatuses === undefined ||
    nativeDefectStatuses.length === 0 ||
    defectStatuses === undefined ||
    defectStatusNativeDefectStatus === undefined;
  const resLoading =
    nativeDefectRes === undefined ||
    nativeDefectRes.length === 0 ||
    defectRes === undefined ||
    defectResNativeDefectRes === undefined;

  return (
    <div>
      {statusLoading && (
        <Loading color={vars.textPrimary} delay={0} size={18} />
      )}
      {!statusLoading && (
        <>
          <Grid.Row>
            <Grid.Col span={7} className={styles.title}>
              {t('integrationMappings.status.integration', {
                integration: integration?.name,
              })}
            </Grid.Col>
            <Grid.Col span={5} className={styles.title}>
              {t('integrationMappings.status.tq')}
            </Grid.Col>
          </Grid.Row>
          {nativeDefectStatuses.map((nativeStatus) => (
            <Grid.Row key={nativeStatus.id}>
              <Grid.Col span={7}>
                <div className={styles.mappingName}>{nativeStatus.name}</div>
                <div className={styles.mappingDesc}>
                  {nativeStatus.description}
                </div>
              </Grid.Col>
              <Grid.Col span={5}>
                <Select
                  onChange={onChangeDefectStatus(nativeStatus)}
                  options={defectStatuses.map((defectStatus) => ({
                    label: defectStatus.name,
                    value: defectStatus.id,
                  }))}
                  value={
                    defectStatusNativeDefectStatus.find(
                      (item) =>
                        item.native_defect_status_id === nativeStatus.id,
                    )?.defect_status_id
                  }
                />
              </Grid.Col>
            </Grid.Row>
          ))}
        </>
      )}
      {resLoading && <Loading color={vars.textPrimary} delay={0} size={18} />}
      {!resLoading && (
        <>
          <Grid.Row>
            <Grid.Col span={7} className={styles.title}>
              {t('integrationMappings.resolution.integration', {
                integration: integration?.name,
              })}
            </Grid.Col>
            <Grid.Col span={5} className={styles.title}>
              {t('integrationMappings.resolution.tq')}
            </Grid.Col>
          </Grid.Row>
          {nativeDefectRes.map((resolution) => (
            <Grid.Row key={resolution.id}>
              <Grid.Col span={7}>
                <div className={styles.mappingName}>{resolution.name}</div>
                <div className={styles.mappingDesc}>
                  {resolution.description}
                </div>
              </Grid.Col>
              <Grid.Col span={5}>
                <Select
                  onChange={onChangeDefectRes(resolution)}
                  options={defectRes.map((defectResolution) => ({
                    label: defectResolution.name,
                    value: defectResolution.id,
                  }))}
                  value={
                    defectResNativeDefectRes.find(
                      (item) => item.native_defect_res_id === resolution.id,
                    )?.defect_res_id
                  }
                />
              </Grid.Col>
            </Grid.Row>
          ))}
        </>
      )}
    </div>
  );
}
