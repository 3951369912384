/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DataSetHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { dataSetHistoryAdapter } from './dataSetHistoryAdapter';
import { dataSetHistoryFetchThunk } from './dataSetHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const dataSetHistorySlice = createSlice({
  name: 'dataSetHistory',
  initialState: dataSetHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    dataSetHistoryCleared(state) {
      state.loading = 'idle';
      dataSetHistoryAdapter.removeAll(state);
    },
    dataSetHistoryUpsertMany(
      state,
      action: PayloadAction<DataSetHistory[] | Record<number, DataSetHistory>>,
    ) {
      dataSetHistoryAdapter.upsertMany(state, action.payload);
    },
    dataSetHistoryUpsertOne(state, action: PayloadAction<DataSetHistory>) {
      dataSetHistoryAdapter.upsertOne(state, action.payload);
    },
    dataSetHistoryUpdateOne(
      state,
      action: PayloadAction<Update<DataSetHistory>>,
    ) {
      dataSetHistoryAdapter.updateOne(state, action.payload);
    },
    dataSetHistoryRemoveOne(state, action: PayloadAction<number>) {
      dataSetHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dataSetHistoryFetchThunk.pending, handlePending);
    builder.addCase(dataSetHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      dataSetHistoryAdapter.upsertMany(state, action.payload.dataSetHistory);
    });
    builder.addCase(dataSetHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  dataSetHistoryCleared,
  dataSetHistoryUpsertMany,
  dataSetHistoryUpsertOne,
  dataSetHistoryUpdateOne,
  dataSetHistoryRemoveOne,
} = dataSetHistorySlice.actions;

export const dataSetHistoryReducer = dataSetHistorySlice.reducer;
