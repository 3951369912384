import React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, TableCell, TableRow, Tooltip } from '@bitmodern/bit-ui';
import { Defect } from '@testquality/sdk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useAppSelector } from '@bitmodern/redux/store';
import styles from './DefectItem.module.scss';
import { formatUserName } from '../../../utils/fileHelper';
import { defectStatusSelectors } from '../../../gen/domain/defect_status/defectStatusSelector';
import { DefectStatusItem } from '../DefectStatusItem';

type Props = {
  defect: Defect;
};

export default function DefectItem({ defect }: Props) {
  const { generateDrawerPath } = useDrawerManager();
  const history = useHistory();

  const defectStatus = useAppSelector((state) => {
    if (defect.defect_status_id) {
      return defectStatusSelectors.selectById(state, defect.defect_status_id);
    }
    const defectStatuses = defectStatusSelectors.selectAll(state);
    return defectStatuses.find((df) => df.key === 1);
  });

  const onClickRow = () => {
    history.push(generateDrawerPath({ defectId: defect.id }));
  };

  return (
    <TableRow className={styles.clickableRow} onClick={onClickRow}>
      <TableCell className={styles.key}>{defect.payload?.key}</TableCell>
      <TableCell>{defect.payload?.summary}</TableCell>
      <TableCell>
        {new Date(
          defect.payload?.created_at || defect.created_at,
        ).toLocaleDateString()}
      </TableCell>
      <TableCell>
        {defect.payload?.assignee && (
          <Tooltip tooltip={formatUserName(defect.payload?.assignee?.name)}>
            <span>
              <Avatar
                src={defect.payload?.assignee?.avatar_url}
                name={defect.payload?.assignee?.name}
                size={24}
              />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <DefectStatusItem defectStatus={defectStatus} />
      </TableCell>
    </TableRow>
  );
}
