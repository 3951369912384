import React, { ComponentProps } from 'react';
import { TreeItem } from 'react-sortable-tree';
import { useTranslation } from 'src/i18n/hooks';
import { AppVersionPlatVersion, Milestone, Plan } from '@testquality/sdk';
import { FormikProps } from 'formik';
import {
  Button,
  ComboBox,
  Dialog,
  FieldLabel,
  Grid,
  Input,
  InputDate,
  PanelActions,
  Select,
} from '@bitmodern/bit-ui';
import useModalManager from 'src/hooks/useModalManager';
import { formikError } from 'src/utils/formik';
import { VirtualSchema } from '@bitmodern/redux/state/virtuals/selectors';
import { SuiteListed, TestListed, VirtualInputs } from '..';
import TreeBase from '../TreeBase';
import { SUITE_TYPE, TEST_TYPE } from '../TreeBase/treeTypes';
import AppVersionPlatVersionItem from './AppVersionPlatVersionItem';
import styles from './StartRunForm.module.scss';

export type FormValues = {
  name: string;
  startTime?: Date;
  milestone?: number;
  plan?: number;
  appVersionPlatVersions: number[];
};

type Props = {
  appVersionPlatVersions: AppVersionPlatVersion[];
  checkeds: ComponentProps<typeof TreeBase>['checkeds'];
  formik: FormikProps<FormValues>;
  milestones: Milestone[];
  onCheckItems: (items) => void;
  plans: Plan[];
  tree: TreeItem[];
  virtuals: { [key: string]: VirtualSchema };
};

export default function StartRunForm({
  appVersionPlatVersions,
  checkeds,
  formik,
  milestones,
  onCheckItems,
  plans,
  tree,
  virtuals,
}: Props) {
  const { t } = useTranslation();
  const { hideModal } = useModalManager();

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="xlarge"
      title={t('startRunForm.title')}>
      <form
        className={styles.content}
        id="startRun"
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Grid.Row gutter={[16, 0]}>
          <Grid.Col span={6}>
            <Input
              error={formikError(formik, 'name')}
              fullWidth
              label={t('startRunForm.name')}
              name="name"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              required
              value={formik.values.name}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              allowClear
              className={styles.input}
              error={formikError(formik, 'milestone')}
              label={t('startRunForm.plan')}
              placeholder={t('startRunForm.planPlaceholder')}
              onChange={(value) => formik.setFieldValue('plan', value)}
              onFocus={formik.handleBlur}
              options={plans.map((plan) => ({
                label: plan.name,
                value: plan.id,
              }))}
              value={formik.values.plan}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={6}>
            <InputDate
              error={formikError(formik, 'startTime')}
              fullWidth
              label={t('startRunForm.startTime')}
              name="startTime"
              onChange={(value) => formik.setFieldValue('startTime', value)}
              onFocus={formik.handleBlur}
              value={formik.values.startTime}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ComboBox
              error={formikError(formik, 'milestone')}
              label={t('startRunForm.milestone')}
              placeholder={t('startRunForm.milestonePlaceholder')}
              onChange={(value) => formik.setFieldValue('milestone', value)}
              onFocus={formik.handleBlur}
              options={milestones.map((milestone) => ({
                label: milestone.name,
                value: milestone.id,
              }))}
              value={formik.values.milestone}
            />
          </Grid.Col>
        </Grid.Row>
        {appVersionPlatVersions.length > 0 && (
          <div className={styles.configurations}>
            <FieldLabel>{t('startRunForm.configurations')}</FieldLabel>
            <div className={styles.configurationItems}>
              {appVersionPlatVersions.map((avpv) => (
                <AppVersionPlatVersionItem
                  appVersionPlatVersion={avpv}
                  key={avpv.id}
                  formik={formik}
                />
              ))}
            </div>
          </div>
        )}
        <div className={styles.tree}>
          <TreeBase
            checkeds={checkeds}
            canDrag={false}
            id="startRun"
            getNodeKey={(data) => data.node.nodeKey}
            canNodeHaveChildren={(node) => node.type !== TEST_TYPE}
            onCheckItems={onCheckItems}
            renderNodeType={{
              [TEST_TYPE]: (item) => {
                const { node, onCheckParentChange, path, treeIndex } = item;
                const { data } = node;
                return (
                  <TestListed
                    checked={node.checked}
                    nestingLevel={path.length - 1}
                    onChangeChecked={(value) => {
                      if (onCheckParentChange)
                        onCheckParentChange(value, { node, treeIndex, path });
                    }}
                    suiteTest={data.suiteTest}
                    test={data.test}
                    withActions={false}
                  />
                );
              },

              [SUITE_TYPE]: (item) => {
                const {
                  node,
                  onCheckParentChange,
                  path,
                  toggleChildrenVisibility,
                  treeIndex,
                } = item;
                const { data } = node;
                return (
                  <SuiteListed
                    checked={node.checked}
                    collapsed={node.expanded || false}
                    collapsible={Boolean(node.children?.length)}
                    nestingLevel={path.length - 1}
                    onChangeChecked={(value) => {
                      if (onCheckParentChange)
                        onCheckParentChange(value, { node, treeIndex, path });
                    }}
                    onClick={() => {
                      if (!toggleChildrenVisibility) return;
                      toggleChildrenVisibility({ node, path, treeIndex });
                    }}
                    planSuite={data.planSuite}
                    suite={data.suite}
                    withActions={false}
                  />
                );
              },
            }}
            treeData={tree}
          />
        </div>
        <VirtualInputs formik={formik} virtuals={virtuals} />
      </form>
      <PanelActions>
        <Button
          disabled={!formik.isValid}
          form="startRun"
          loading={formik.isSubmitting}
          type="submit">
          {t('startRunForm.submit')}
        </Button>
      </PanelActions>
    </Dialog>
  );
}
