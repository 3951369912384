/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Watch,
  watchGetMany,
  watchGetOne,
  watchDeleteOne,
  watchUpdateOne,
  watchCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const watchFetchManyThunk = createAsyncThunk(
  'watch/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Watch>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await watchGetMany(inlineArgs);
      return {
        watch: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const watchFetchOneThunk = createAsyncThunk(
  'watch/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Watch>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'watch/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await watchGetOne(arg.id as number, arg);
      return {
        watch: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const watchDeleteOneThunk = createAsyncThunk(
  'watch/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Watch>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'watch/DeleteOneThunk');
      }
      return await watchDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const watchUpdateOneThunk = createAsyncThunk(
  'watch/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Watch>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'watch/UpdateOneThunk');
      }
      const data = await watchUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Watch>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Watch>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const watchCreateOneThunk = createAsyncThunk(
  'watch/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Watch>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'watch/CreateOneThunk',
        );
      }
      return await watchCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
