import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TreeItem } from 'react-sortable-tree';
import { jiraBaseIntegrationSelector } from 'src/packages/redux/state/baseIntegration/selectors';
import { allIntegrationsProjectByProjectIdSelector } from 'src/packages/redux/state/integrationProject/selectors';
import { TEST_TYPE } from 'src/components/organisms/TreeBase/treeTypes';
import useDebouncedState from 'src/hooks/useDebouncedState';
import { integrationSelector } from '@bitmodern/redux/state/integration/selectors';
import { searchDefects } from '@bitmodern/services/DefectService';
import { showNotificationError, IntegrationProject } from '@testquality/sdk';
import { useParamSelector } from 'src/packages/redux/hooks';
import { useAppSelector } from 'src/packages/redux/store';

export type UseStep1Params = {
  projectId: number;
  isDefect: boolean;
  onNext: (
    data: { summary: string; testItems: TreeItem[] },
    integrationProject: any,
  ) => void;
  onLinkIssue: (data: {
    issue;
    testItems: TreeItem[];
    integration_project_id: number;
  }) => Promise<any>;
};

export function useStep1({
  onNext,
  onLinkIssue,
  isDefect,
  projectId,
}: UseStep1Params) {
  const [isLoading, setIsLoading] = useState(false);
  const [externalIssues, setExternalIssues] = useState<any[]>([]);
  const [selectedIssue, setSelectedIssue] = useState<any>();

  const [isLinking, setIsLinking] = useState(false);
  const [jqlActive, setJqlActive] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedState(search, 500);
  const [summary, setSummary] = useState('');
  const [tests, setTests] = useState<TreeItem[]>([]);
  const [integrationProject, setIntegrationProject] =
    useState<IntegrationProject>();

  const integrationProjects = useParamSelector(
    allIntegrationsProjectByProjectIdSelector,
    { projectId },
  );
  const jiraBaseIntegration = useAppSelector(jiraBaseIntegrationSelector);
  const integration = useParamSelector(integrationSelector, {
    integrationId: integrationProject?.integration_id,
  });

  const useJQL = integration?.base_integration_id === jiraBaseIntegration?.id;

  const formIsValid = useMemo(() => {
    if (isDefect) {
      return (!summary && !selectedIssue) || tests.length <= 0;
    }
    return !selectedIssue && !summary;
  }, [isDefect, tests, selectedIssue, summary]);

  const integrationId = integration?.id;

  const searchIssue = useCallback(
    (searchValue, integrationProject?: IntegrationProject) => {
      if (!integrationId || !integrationProject) return;
      const intId = integrationProject?.integration_id
        ? integrationProject.integration_id
        : integrationId;

      setExternalIssues([]);
      setIsLoading(true);
      searchDefects(
        intId,
        projectId,
        proccessJql(searchValue, useJQL, jqlActive),
        integrationProject.project_reference_id,
      )
        .then((resp) => setExternalIssues(resp.data))
        .catch(showNotificationError)
        .finally(() => setIsLoading(false));
    },
    [useJQL, integrationId, projectId, jqlActive],
  );

  useEffect(() => {
    searchIssue(debouncedSearch, integrationProject);
  }, [searchIssue, debouncedSearch, integrationProject]);

  const defaultSetted = useRef(false);
  useEffect(() => {
    if (!defaultSetted.current && integrationProjects?.length) {
      defaultSetted.current = true;
      setIntegrationProject(integrationProjects[0]);
    }
  }, [integrationProjects]);

  const onSelectIssue = (defect) => {
    setSelectedIssue((s) =>
      s && s.number === defect.number ? undefined : defect,
    );
    if (summary) setSummary('');
  };

  const onChangeSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const handleOnChangeSummary = useCallback(
    (event) => {
      setSummary(event.target.value);
      if (selectedIssue) setSelectedIssue(undefined);
    },
    [selectedIssue],
  );

  const handleNextStepDefect = () => {
    const toSend = testsSelected();
    if (toSend.length > 0) {
      if (summary && !selectedIssue) {
        onNext({ summary, testItems: toSend }, integrationProject);
      } else if (selectedIssue && integrationProject) {
        setIsLinking(true);
        onLinkIssue({
          issue: selectedIssue,
          testItems: toSend,
          integration_project_id: integrationProject.id,
        }).finally(() => {
          setIsLinking(false);
        });
      }
    }
  };

  const handleChangeTests = (value: TreeItem[]) => {
    setTests(value);
  };

  const handleNextStepRequirement = () => {
    const toSend = testsSelected();
    if (selectedIssue && integrationProject) {
      setIsLinking(true);
      onLinkIssue({
        issue: selectedIssue,
        testItems: toSend,
        integration_project_id: integrationProject.id,
      }).finally(() => {
        setIsLinking(false);
      });
    } else if (summary && !selectedIssue) {
      onNext({ summary, testItems: toSend }, integrationProject);
    }
  };

  const testsSelected = () => {
    return tests.filter((test) => test.type === TEST_TYPE);
  };

  const toggleJQL = (value: boolean) => {
    setSearch('');
    setJqlActive(value);
  };

  const onChangeIntegrationProject = (value: number) => {
    const ip = integrationProjects?.find((el) => el.id === value);
    setIntegrationProject(ip);
    if (integrationProject?.id !== ip?.id) {
      setSearch('');
    }
  };

  return {
    externalIssues,
    formIsValid,
    handleChangeTests,
    handleNextStepDefect,
    handleNextStepRequirement,
    handleOnChangeSummary,
    integration,
    integrationProject,
    integrationProjects,
    isLinking,
    isLoading,
    jqlActive,
    onChangeIntegrationProject,
    onChangeSearch,
    onSelectIssue,
    search,
    searchIssue,
    selectedIssue,
    summary,
    toggleJQL,
    useJQL,
  };
}

/**
 * TODO: Move this to the backend when angular app no loger exists
 * proposal:
 * Backend fillter text by default and accept jql:sumary~my isuue
 */
function proccessJql(
  query: string,
  allowJQL: boolean,
  JQLActive: boolean,
): string {
  if (!query || !allowJQL || JQLActive) return query;
  if (query.match(/^[a-zA-Z]*-[0-9]*/)) return `issuekey = ${query}`;
  return `summary~"${query}"`;
}
