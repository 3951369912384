import React from 'react';
import { routes } from 'src/components/Router';
import { Integration } from '@testquality/sdk';
import useParams from 'src/hooks/useParams';
import { baseIntegrationByIdSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { integrationsByBaseSelector } from '@bitmodern/redux/state/integration/selectors';
import { integrationUsersByCurrentUserSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { useQuery } from 'src/hooks/useQuery';
import { IntegrationCommon } from '../IntegrationView/IntegrationCommon';

export default function IntegrationSetupViewContainer() {
  const { site, baseIntegrationId } =
    useParams<typeof routes.INTEGRATION_SETUP.params>();
  const params = useQuery(['project_create']);
  const projectCreate = !!params.project_create;
  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, { id: parseInt(baseIntegrationId, 10) }),
  );
  const integrationUser = useAppSelector((state) =>
    integrationUsersByCurrentUserSelector(
      state,
      parseInt(baseIntegrationId, 10),
    ),
  );
  const integrations = useAppSelector((state) =>
    integrationsByBaseSelector(state, parseInt(baseIntegrationId, 10)),
  );

  const integration: Integration | undefined = integrations[0];

  return (
    <IntegrationCommon
      site={site}
      projectCreate={projectCreate}
      baseIntegration={baseIntegration!}
      integration={integration}
      integrationUser={integrationUser}
    />
  );
}
