import { BatchService } from '@testquality/sdk';
import { batch as reduxBatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { accessRoleFetchManyThunk } from 'src/gen/domain/access_role/accessRoleThunk';
import { appFetchManyThunk } from 'src/gen/domain/app/appThunk';
import { attachmentFetchManyThunk } from 'src/gen/domain/attachment/attachmentThunk';
import { casePriorityFetchManyThunk } from 'src/gen/domain/case_priority/casePriorityThunk';
import { caseTypeFetchManyThunk } from 'src/gen/domain/case_type/caseTypeThunk';
import { clientFetchOneThunk } from 'src/gen/domain/client/clientThunk';
import { defectResFetchManyThunk } from 'src/gen/domain/defect_res/defectResThunk';
import { defectStatusFetchManyThunk } from 'src/gen/domain/defect_status/defectStatusThunk';
import { integrationFetchManyThunk } from 'src/gen/domain/integration/integrationThunk';
import { filterFetchManyThunk } from 'src/gen/domain/filter/filterThunk';
import { labelAssignedFetchManyThunk } from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { labelFetchManyThunk } from 'src/gen/domain/label/labelThunk';
import { notificationsFetchManyThunk } from 'src/gen/domain/notifications/notificationsThunk';
import { platFetchManyThunk } from 'src/gen/domain/plat/platThunk';
import { productFetchManyThunk } from 'src/gen/domain/product/productThunk';
import { projectFetchManyThunk } from 'src/gen/domain/project/projectThunk';
import { purposeFetchManyThunk } from 'src/gen/domain/purpose/purposeThunk';
import { statusFetchManyThunk } from 'src/gen/domain/status/statusThunk';
import { statusTypeFetchManyThunk } from 'src/gen/domain/status_type/statusTypeThunk';
import { subscriptionsFetchManyThunk } from 'src/gen/domain/subscriptions/subscriptionsThunk';
import { testQualityFetchManyThunk } from 'src/gen/domain/test_quality/testQualityThunk';
import { testQualityTypeFetchManyThunk } from 'src/gen/domain/test_quality_type/testQualityTypeThunk';
import { userFetchManyThunk } from 'src/gen/domain/user/userThunk';
import { virtualFetchManyThunk } from 'src/gen/domain/virtual/virtualThunk';
import { watchFetchManyThunk } from 'src/gen/domain/watch/watchThunk';
import { baseIntegrationFetchManyThunk } from 'src/gen/domain/base_integration/baseIntegrationThunk';
import { appVersionPlatVersionFetchManyThunk } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionThunk';
import { appVersionPlatVersionPlanFetchManyThunk } from 'src/gen/domain/app_version_plat_version_plan/appVersionPlatVersionPlanThunk';
import { subscriptionUserFetchManyThunk } from 'src/gen/domain/subscription_user/subscriptionUserThunk';
import { integrationTemplateFetchManyThunk } from 'src/gen/domain/integration_template/integrationTemplateThunk';
import { combineBatchedEntitiesCache } from 'src/utils/cache';
import { baseCapabilityFetchManyThunk } from '../../../gen/domain/base_capability/baseCapabilityThunk';
import { baseCapabilityBaseIntegrationFetchManyThunk } from '../../../gen/domain/base_capability_base_integration/baseCapabilityBaseIntegrationThunk';
import { upsertEntitiesAction } from '../RootReducer/batchSetGenReducer';
import { AppThunk } from '../store';

export function getInitialFetchThunk({
  dataCached = [],
  userId,
  clientId,
}: {
  dataCached?: any;
  userId: number;
  clientId: number;
}): AppThunk<Promise<any>> {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      reduxBatch(() => {
        const entities = combineBatchedEntitiesCache(dataCached);
        dispatch(upsertEntitiesAction(entities));

        const batch = new BatchService();
        const thunks = Promise.all([
          dispatch(projectFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(
            notificationsFetchManyThunk({
              params: {
                per_page: -1,
                notifiable_id: userId,
                _sort: 'updated_at',
              },
              batch,
            }),
          ),
          dispatch(
            watchFetchManyThunk({
              params: { per_page: -1, created_by: userId },
              batch,
            }),
          ),
          dispatch(
            attachmentFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(purposeFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(
            casePriorityFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(caseTypeFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(virtualFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(
            accessRoleFetchManyThunk({
              params: { per_page: -1, _with: 'user' },
              batch,
            }),
          ),
          dispatch(clientFetchOneThunk({ batch, id: clientId })),
          dispatch(userFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(labelFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(
            filterFetchManyThunk({
              batch,
              params: { client_id: clientId, per_page: -1 },
            }),
          ),
          dispatch(
            labelAssignedFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(
            testQualityFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(
            testQualityTypeFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(
            defectStatusFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(
            defectResFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(statusFetchManyThunk({ batch, params: { per_page: -1 } })),
          dispatch(
            statusTypeFetchManyThunk({ batch, params: { per_page: -1 } }),
          ),
          dispatch(
            integrationFetchManyThunk({
              params: { per_page: -1, _with: 'user,project,capability' },
              batch,
            }),
          ),
          dispatch(
            productFetchManyThunk({
              batch,
              params: {
                is_active: true,
                per_page: -1,
              },
            }),
          ),
          dispatch(
            subscriptionsFetchManyThunk({
              batch,
              params: { per_page: -1 },
            }),
          ),
          dispatch(
            subscriptionUserFetchManyThunk({
              batch,
              params: { per_page: -1 },
            }),
          ),
          dispatch(
            baseCapabilityFetchManyThunk({ params: { per_page: -1 }, batch }),
          ),
          dispatch(
            baseCapabilityBaseIntegrationFetchManyThunk({
              params: { per_page: -1 },
              batch,
            }),
          ),
          dispatch(
            baseIntegrationFetchManyThunk({ params: { per_page: -1 }, batch }),
          ),
          dispatch(
            appFetchManyThunk({
              batch,
              params: { per_page: -1, _with: 'appVersion' },
            }),
          ),
          dispatch(
            appVersionPlatVersionFetchManyThunk({
              batch,
              params: { per_page: -1 },
            }),
          ),
          dispatch(
            platFetchManyThunk({
              batch,
              params: { per_page: -1, _with: 'platVersion' },
            }),
          ),
          dispatch(
            appVersionPlatVersionPlanFetchManyThunk({
              batch,
              params: { per_page: -1 },
            }),
          ),
          dispatch(
            integrationTemplateFetchManyThunk({
              batch,
              params: {
                per_page: -1,
              },
            }),
          ),
        ]);

        batch.executeBatch();

        thunks
          .then((responses) => responses.map(unwrapResult))
          .then(resolve)
          .catch(reject);
      });
    });
  };
}
