/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { DefectResNativeDefectResApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const defectResNativeDefectResNormalizer = (
  model: DefectResNativeDefectResApi,
  cache: NormalizerCache,
) => {
  if (!cache.defectResNativeDefectRes) {
    cache.defectResNativeDefectRes = [];
  }
  cache.defectResNativeDefectRes.push(model);
};
