/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { StepApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runResultStepNormalizer } from '../run_result_step/runResultStepNormalize';

export const stepNormalizer = (model: StepApi, cache: NormalizerCache) => {
  if (!cache.step) {
    cache.step = [];
  }
  cache.step.push(model);

  if (model.run_result_step && model.run_result_step.length) {
    model.run_result_step.forEach((child) => {
      runResultStepNormalizer(child, cache);
    });
    model.run_result_step = undefined;
  }
};
