/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { policyRowsAdapter } from './policyRowsAdapter';

export const policyRowsSelectors = policyRowsAdapter.getSelectors(
  (state: State) => state.gen.policyRows,
);
