import React, { ComponentProps } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenSelector } from '@bitmodern/redux/state/authentication/selectors';
import routes from './routes';
import AppRoute from './AppRoute';
import useParams from '../../hooks/useParams';

type Props = ComponentProps<typeof AppRoute>;

/**
 * Route that requieres authentication and
 * an active subscription
 */
export default function AuthRoute(props: Props) {
  const token = useSelector(tokenSelector);
  const { site } = useParams<{ site: string }>();

  if (token) {
    if (token.is_expired) {
      const siteName = site || token.client_name;
      return <Redirect to={routes.SUBSCRIPTIONS({ site: siteName })} />;
    }
  } else {
    if (props.location) {
      sessionStorage.setItem('authFrom', JSON.stringify(props.location));
    }

    return <Redirect to={routes.LOGIN({})} />;
  }

  return <AppRoute {...props} />;
}
