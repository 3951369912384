import { State } from '@bitmodern/redux/store';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { commentSelectors } from 'src/gen/domain/comment/commentSelector';

export function testCommentsSelector(state: State, testId: number) {
  return commentSelectors
    .selectAll(state)
    .filter(
      (c) => c.related_type === RelatedType.TEST && c.related_id === testId,
    );
}

export function runResultCommentsSelector(state: State, runResult: number) {
  return commentSelectors
    .selectAll(state)
    .filter(
      (c) =>
        c.related_type === RelatedType.RUN_RESULT && c.related_id === runResult,
    );
}
