import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { defectCreateOneThunk } from 'src/gen/domain/defect/defectThunk';
import {
  showNotificationError,
  BatchService,
  IntegrationProject,
} from '@testquality/sdk';
import { Dialog } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { NoteAddIcon } from '@bitmodern/bit-ui/icons';
import { useQuery } from 'src/hooks/useQuery';
import { defectsByRunResultsSelector } from '@bitmodern/redux/state/defects/selectors';
import useModalManager from 'src/hooks/useModalManager';
import vars from 'src/export.scss';
import {
  runResultByIdSelector,
  runResultsByRunSelector,
} from '@bitmodern/redux/state/runResults/selectors';
import { TreeItem } from 'react-sortable-tree';
import { Step1, Step2 } from '../MultiIssueItem';
import styles from './DefectCreate.module.scss';
import { defectRunResultCreateOneThunk } from 'src/gen/domain/defect_run_result/defectRunResultThunk';
import { defectRunResultsByDefects } from 'src/packages/redux/state/defectsRunResults/selectors';
import { unwrapResult } from '@reduxjs/toolkit';
import { defectSelectors } from 'src/gen/domain/defect/defectSelector';

export default function DefectCreateView() {
  const location = useLocation<{ modalParentLocation? }>();
  const history = useHistory();
  const { resultIds } = useQuery(['resultIds'], location.search);
  const { t } = useTranslation();
  const [step, setStep] = useState('step1');
  const [integrationProject, setIntegrationProject] =
    useState<IntegrationProject>();
  const [initialSummary, setInitialSummary] = useState('');
  const dispatch = useAppDispatch();
  const { site, projectId, runId } =
    useParams<typeof routes.DEFECT_CREATE.params>();

  const { hideModal, setDefaultModalParent } = useModalManager();

  const runResultIds = resultIds
    ? resultIds.split(',').map((id) => parseInt(id, 10))
    : [];
  const runResultId = runResultIds[0];

  const relatedDefects = useAppSelector((state) =>
    defectsByRunResultsSelector(state, runResultIds),
  );
  const allDefects = useAppSelector((state) =>
    defectSelectors.selectAll(state),
  );

  const defectsRunResults = useAppSelector((state) =>
    defectRunResultsByDefects(
      state,
      relatedDefects.map((defect) => defect.id),
    ),
  );

  const tree = useAppSelector((state) =>
    runResultsByRunSelector(state, { runId: parseInt(runId, 10) }),
  );

  const suiteTest = useAppSelector((state) =>
    runResultByIdSelector(state, { ids: runResultIds }),
  );
  const [tests, setTests] = useState<TreeItem[]>([]);

  useEffect(() => {
    setDefaultModalParent({
      pathname: routes.RUN({
        site,
        projectId,
        runId,
      }),
      search: runResultId ? `?resultId=${runResultId}` : undefined,
    });
  }, [setDefaultModalParent, runResultId, projectId, runId, site]);

  const hideModalAndChangeTab = () => {
    if (location.state?.modalParentLocation) {
      const toNavigate = {
        pathname: routes.RUN({
          site,
          projectId,
          runId,
        }),
        search: runResultId
          ? `?resultId=${runResultId}&activeTab=defects`
          : undefined,
      };
      history.replace(toNavigate);
    }
  };

  const onCreateDefect = async ({
    components,
    summary,
    description,
    labels,
    priority,
    assignee,
    type,
    integrationProjectId,
  }) => {
    if (tests.length && integrationProject) {
      const data = {
        payload: {
          components: components.map(({ value, label }) => ({
            id: value,
            name: label,
          })),
          summary,
          description,
          labels: labels.map(({ id: lId, label }) => ({
            id: lId,
            name: label,
          })),
          priority,
          assignee,
          type,
        },
        project_id: parseInt(projectId, 10),
        integration_project_id: integrationProjectId,
      };

      const newDefect = await createDefect(data).then(unwrapResult);
      createDefectRunResults(newDefect.id, tests).catch(showNotificationError);
    }
    return Promise.resolve()
      .then(hideModalAndChangeTab)
      .catch((error) => {
        showNotificationError(error);
      });
  };

  const onLinkDefect = async (data: {
    issue;
    testItems: TreeItem[];
    integration_project_id: number;
  }): Promise<void> => {
    const defect = allDefects.find((def) => def.payload.url === data.issue.url);
    let newDef;

    if (!defect) {
      // Insert issue into DB before trying to link to tests
      newDef = await createDefect({
        payload: {
          summary: data.issue.title,
          description: data.issue.body,
          labels: data.issue.labels,
          priority: {},
          resolution: {},
          assignee: data.issue.assignee,
        },
        defect_status_id: undefined,
        defect_res_id: undefined,
        project_id: parseInt(projectId, 10),
        external_reference_id: data.issue.number.toString(),
        integration_project_id: data.integration_project_id,
      }).then(unwrapResult);
    }

    const defectId = newDef?.id || defect?.id;
    if (data.testItems.length > 0) {
      const toCreate = data.testItems.filter((ti) => {
        return !defectsRunResults.some(
          (rt) =>
            rt.run_result_id === ti.data.runResults[0].id &&
            rt.defect_id === defectId &&
            typeof rt === 'undefined',
        );
      });
      createDefectRunResults(defectId, toCreate).catch(showNotificationError);
    }

    return Promise.resolve();
  };

  const createDefect = async (data) => {
    return dispatch(defectCreateOneThunk({ data }));
  };

  const createDefectRunResults = (defectId, runResults) => {
    const batch = new BatchService();
    runResults.forEach((value) => {
      dispatch(
        defectRunResultCreateOneThunk({
          data: {
            run_result_id: value.data.runResults[0].id,
            defect_id: defectId,
          },
          batch,
        }),
      );
    });
    return batch
      .executeBatch()
      .then(hideModalAndChangeTab)
      .catch(showNotificationError);
  };

  const onNext = ({ summary, testItems }, selectedIp) => {
    setInitialSummary(summary);
    setTests(testItems);
    setIntegrationProject(selectedIp);
    setStep('step2');
  };

  return (
    <Dialog
      animatePresence={false}
      title={
        <div className={styles.head}>
          <NoteAddIcon
            color={vars.textPrimary}
            className={styles.titleIcon}
            size={18}
          />
          {t('defectCreate.title')}
        </div>
      }
      isDismissable={false}
      onClose={hideModal}
      size="xlarge"
      isOpen>
      {step === 'step1' && suiteTest && (
        <Step1
          onNext={onNext}
          onLinkIssue={onLinkDefect}
          tree={tree}
          selectedTests={suiteTest}
          isDefect
        />
      )}
      {step === 'step2' && integrationProject && (
        <Step2
          onCreateDefect={onCreateDefect}
          initialSummary={initialSummary}
          integrationProject={integrationProject}
          issueType={IntegrationSchema.DEFECT}
        />
      )}
    </Dialog>
  );
}
