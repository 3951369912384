import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from '@bitmodern/bit-ui';
import { useAppDispatch } from '@bitmodern/redux/store';
import { inviteUsersThunk } from '@bitmodern/redux/state/users/thunks';
import StaggerWrapper from '../StaggerWrapper';
import { StepContent } from '../StepContent';
import { StepActions } from '../StepActions';
import { StepProps } from '../Step';
import { InvitesStepForm, useInvitesStepForm } from './InvitesStepForm';
import styles from './InvitesStep.module.scss';

import Debug from 'debug';
const debug = Debug('InvitesStep');

export function InvitesStep({ onNextStep, currentStep, stepTotal }: StepProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onSuccess = useCallback(() => {
    debug('onSuccess');
    notification.open({
      type: 'success',
      message: t('getStarted.invitesStep.successNotification'),
    });
    onNextStep();
  }, [onNextStep, t]);

  const onSubmit = useCallback(
    (values) => {
      debug('onSubmit', values);
      return dispatch(
        inviteUsersThunk({
          values,
          onSuccess,
        }),
      );
    },
    [dispatch, onSuccess],
  );

  const formik = useInvitesStepForm({ onSubmit });

  return (
    <>
      <StepContent
        currentStep={currentStep}
        stepTotal={stepTotal}
        title={t('getStarted.invitesStep.title')}>
        <StaggerWrapper>
          <div className={styles.text}>{t('getStarted.invitesStep.text')}</div>
          <InvitesStepForm formik={formik} />
        </StaggerWrapper>
      </StepContent>
      <StepActions
        action={t('getStarted.invitesStep.form.submitButton')}
        loading={formik.isSubmitting}
        onSkip={onNextStep}
        onClickAction={formik.submitForm}
      />
    </>
  );
}
