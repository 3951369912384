import React from 'react';
import styles from './ReporterCell.module.scss';
import { processAttachmentPath } from 'src/utils/fileHelper';
import { Avatar, TableCell } from '@bitmodern/bit-ui';
import { useTranslation } from 'react-i18next';
import {
  DefectCreateEvent,
  DefectUpdateEvent,
  RunResultEvent,
} from 'src/packages/redux/state/defectHistory/thunks';

type DefectEventsItemDefectProps = {
  event: DefectUpdateEvent | DefectCreateEvent | RunResultEvent;
  marginRight?: number;
};

export function ReporterCell({
  event,
  marginRight = 8,
}: DefectEventsItemDefectProps) {
  const { t } = useTranslation();
  const userDisplay = event.user?.given_name
    ? `${event.user.given_name} ${event.user.family_name}`
    : event.user?.email;

  return (
    <TableCell>
      <div className={styles.reporter} style={{ marginRight }}>
        <Avatar
          className={styles.reporterAvatar}
          name={event.user?.given_name}
          size={30}
          src={processAttachmentPath(event.user?.picture)}
        />
        <div>
          <div className={styles.reporterName}>{userDisplay}</div>
          <div>
            {t('defectEvent.date', { date: new Date(event.updatedAt) })}
          </div>
        </div>
      </div>
    </TableCell>
  );
}
