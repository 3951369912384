import {
  defaultGetNodeKey,
  getFlatDataFromTree,
  TreeItem,
} from 'react-sortable-tree';
import { PlanSuite, RunResult, SuiteTest } from '@testquality/sdk';

export function countAttribute(
  treeData: TreeItem[],
  getCount: (treeItem: TreeItem) => number,
) {
  const traverse = (item) => {
    if (!item.children) return getCount(item);
    const childrenCount = item.children.reduce(
      (total, currentNode) => total + traverse(currentNode),
      0,
    );
    return childrenCount + getCount(item);
  };

  return treeData.reduce(
    (total, currentNode) => total + traverse(currentNode),
    0,
  );
}

export function getCheckedNodesCount(checked: { [key: string]: boolean }) {
  return Object.keys(checked).reduce((acu, item) => {
    if (checked[item]) return acu + 1;
    return acu;
  }, 0);
}

export function filterTree(
  array: TreeItem[],
  search: (treeItem: TreeItem) => boolean,
  onlyFilterParents = false,
) {
  const getNodes = (result, treeItem: TreeItem) => {
    if (onlyFilterParents && treeItem.children && search(treeItem)) {
      result.push(treeItem);
      return result;
    }

    if (Array.isArray(treeItem.children)) {
      const children = treeItem.children.reduce(getNodes, []);
      if (children.length) result.push({ ...treeItem, children });
      return result;
    }

    if (search(treeItem)) {
      result.push(treeItem);
    }
    return result;
  };

  return array.reduce(getNodes, []);
}

export function getCheckedList(
  treeData: TreeItem[],
  checkeds: { [key: string]: boolean },
) {
  return getFlatDataFromTree({
    treeData,
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
  })
    .filter(({ node }) => checkeds[node.nodeKey])
    .map((item) => item.node);
}

export function generateNodeKey(data: {
  suiteTest?: SuiteTest;
  planSuite?: PlanSuite;
  runResult?: RunResult;
}) {
  if (data.suiteTest) return generateSuiteTestNodeKey(data.suiteTest.id);
  if (data.planSuite) return `S-${data.planSuite.id}`;
  if (data.runResult) return `R-${data.runResult.id}`;
  return '';
}

export function generateSuiteTestNodeKey(suiteTestId: number) {
  return `T-${suiteTestId}`;
}

export function generateRunTestNodeKey(suiteTestId: number) {
  return `R-${suiteTestId}`;
}

type GetTreeFromArrayParams = {
  flatData: any[];
  getKey: (node) => any;
  getParentKey: (node) => any;
  rootKey: string;
  transform: (item) => any;
};

export function getTreeFromArray({
  flatData,
  getKey,
  getParentKey,
  rootKey = '0',
  transform = (item) => item,
}: GetTreeFromArrayParams): TreeItem[] {
  if (!flatData) return [];

  const childrenToParents = {};
  flatData.forEach((child) => {
    const parentKey = getParentKey(child);
    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child);
    } else {
      childrenToParents[parentKey] = [child];
    }
  });

  if (!(rootKey in childrenToParents)) return [];

  const trav = (parent) => {
    const parentKey = getKey(parent);
    if (parentKey in childrenToParents) {
      return {
        ...transform(parent),
        children: childrenToParents[parentKey].map(trav),
      };
    }

    return transform(parent);
  };

  return childrenToParents[rootKey].map(trav);
}
