/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectPriorityAdapter } from './defectPriorityAdapter';

export const defectPrioritySelectors = defectPriorityAdapter.getSelectors(
  (state: State) => state.gen.defectPriority,
);
