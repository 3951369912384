import React from 'react';
import { Notifications, Run, Project } from '@testquality/sdk';
import { format } from 'date-fns';
import { notification as notificationTrigger } from '@bitmodern/bit-ui';
import { AppThunk } from '@bitmodern/redux/store';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import {
  passStatusSelector,
  statusesSelector,
} from '@bitmodern/redux/state/statuses/selectors';
import {
  currentUserSelector,
  siteSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import { notificationsUpsertOne } from 'src/gen/domain/notifications/notificationsSlice';
import {
  NotificationIcon,
  ErrorIcon,
  WarningIcon,
  DoneIcon,
} from '@bitmodern/bit-ui/icons';
import { notificationsUpdateOneThunk } from 'src/gen/domain/notifications/notificationsThunk';
import {
  getNotificationName,
  NotificationItemType,
  NotificationNameToType,
} from 'src/enums/NotificationEnums';
import { DateFormat } from 'src/enums/DateFormatEnum';
import vars from 'src/export.scss';
import {
  NotificationRunResult,
  WatchEntity,
} from 'src/components/organisms/WatchTray/WatchTray';
import navigation from '@bitmodern/services/navigationService';
import i18n from 'src/i18n';
import { generateDrawerUrlParams } from 'src/hooks/useDrawerManager';
import { routes } from 'src/components/Router';
import { formatUserName } from 'src/utils/fileHelper';
import { StatusIcon } from 'src/components/organisms';

export function notifiableNotificationThunk(
  notification: Notifications,
): AppThunk<any> {
  return async (dispatch) => {
    // hard code the updation and creation date before storing (not present in push)
    notification.updated_at = format(new Date(), DateFormat.RetrievedEntity);
    notification.created_at = format(new Date(), DateFormat.RetrievedEntity);

    const name = getNotificationName(notification.type);
    const description = i18n.t(`notification.description.${name}`, {
      email: (notification.data as any)?.email,
    });
    const message = i18n.t('notifications.newNotification.message');

    function onClick() {
      return dispatch(
        notificationsUpdateOneThunk({
          id: notification.id,
          data: {
            read_at: format(new Date(), DateFormat.UpdateEntity),
          },
        }),
      ).finally(() => notificationTrigger.close(notification.id));
    }
    function getNotificationIcon() {
      const type =
        NotificationNameToType.get(name) || NotificationItemType.WARNING;
      switch (type) {
        case NotificationItemType.DANGER:
          return <ErrorIcon color={vars.warningMain} />;
        case NotificationItemType.INFO:
          return <NotificationIcon color={vars.successMain} />;
        case NotificationItemType.SUCCESS:
          return <DoneIcon color={vars.successMain} />;
        case NotificationItemType.WARNING:
          return <WarningIcon color={vars.warningMain} />;
        default:
          return <NotificationIcon color={vars.successMain} />;
      }
    }

    notificationTrigger.open({
      onClick,
      description,
      message,
      icon: getNotificationIcon(),
      key: notification.id,
    });

    return dispatch(notificationsUpsertOne(notification));
  };
}

export function watchNotificationThunk(
  notification: Notifications,
): AppThunk<any> {
  return async (dispatch, getState) => {
    // hard code the updation and creation date before storing (not present in push)
    notification.updated_at = format(new Date(), DateFormat.RetrievedEntity);
    notification.created_at = format(new Date(), DateFormat.RetrievedEntity);

    const updatedEntity: NotificationRunResult | Run = notification.data as any;
    const isEntityARunResult =
      updatedEntity.metadata_model === WatchEntity.RUN_RESULT;
    const users = usersSelector(getState());
    const currentUser = currentUserSelector(getState());
    const site = siteSelector(getState());
    const updatedBy = users.find(
      (user) => user.id === updatedEntity.updated_by,
    );

    /* NOTE: If the notification was generated by the current user do not proceed */
    if (!currentUser || updatedBy?.id === currentUser.id) return null;

    // resourses
    const projects = projectsSelector(getState());
    const statuses = statusesSelector(getState());
    const passStatus = passStatusSelector(getState());

    const project = projects.find((p) => p.id === updatedEntity.project_id);
    const status = isEntityARunResult
      ? statuses.find(
          (s) => s.id === (updatedEntity as NotificationRunResult).status_id,
        )
      : passStatus;

    if (!status || !updatedBy || !project) return null;

    const pushPath = site ? getPushPath(updatedEntity, project, site) : '';
    const onClick = () => {
      if (pushPath) navigation.push(pushPath);
      dispatch(
        notificationsUpdateOneThunk({
          id: notification.id,
          data: {
            read_at: format(new Date(), DateFormat.UpdateEntity),
          },
        }),
      ).finally(() => notificationTrigger.close(notification.id));
    };

    const runName = !isEntityARunResult && (updatedEntity as Run).name;
    const runResultName =
      isEntityARunResult &&
      `TC-${(updatedEntity as NotificationRunResult).test_key} ${
        (updatedEntity as NotificationRunResult).test_name
      }`;
    const description = runName
      ? i18n.t('notifications.newNotification.descriptionRun', {
          runName,
          project: project.name,
          updatedBy: formatUserName(updatedBy),
        })
      : i18n.t('notifications.newNotification.descriptionRunResult', {
          runResultName,
          project: project.name,
          updatedBy: formatUserName(updatedBy),
        });

    const message = i18n.t('notifications.newNotification.message');

    notificationTrigger.open({
      onClick,
      description,
      message,
      icon: <StatusIcon status={status} />,
      key: notification.id,
    });

    return dispatch(notificationsUpsertOne(notification));
  };
}

function getPushPath(
  entity: NotificationRunResult | Run,
  project: Project,
  site: string,
) {
  let search;
  let runId;

  if (entity.metadata_model === WatchEntity.RUN_RESULT) {
    search = generateDrawerUrlParams({
      resultId: (entity as NotificationRunResult).id,
    });
    runId = (entity as NotificationRunResult).run_id.toString();
  }
  if (entity.metadata_model === WatchEntity.RUN) {
    runId = (entity as Run).id.toString();
  }

  const baseRoute = routes.RUN({
    site,
    projectId: project.id.toString(),
    runId,
  });

  return `${baseRoute}?${search}`;
}
