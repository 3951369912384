import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import {
  BaseIntegrationItem,
  PersonalAccessToken,
  SettingsPanel,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { integrationSelectors } from 'src/gen/domain/integration/integrationSelector';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { useTranslation } from 'src/i18n/hooks';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { baseIntegrationByBaseCapabilityIdSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { integrationUserByUserIdSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { IntegrationsIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { Integration } from '@testquality/sdk';

export default function IntegrationsView() {
  const { t } = useTranslation();
  const history = useHistory();
  const { site } = useParamsInRoute<typeof routes.INTEGRATIONS.params>(
    routes.INTEGRATIONS.path,
  );

  const currentUser = useAppSelector(currentUserSelector);
  const baseIntegrations = useAppSelector((state) =>
    baseIntegrationByBaseCapabilityIdSelector(state, { baseCapabilityId: 5 }),
  );
  const integrations = useAppSelector(integrationSelectors.selectAll);

  const integrationUsers = useAppSelector((state) =>
    integrationUserByUserIdSelector(state, { userId: currentUser?.id }),
  );

  const onAddNewProject = (integration: Integration) => {
    history.push(
      routes.INTEGRATION_PROJECT_CREATE({
        site,
        integrationId: integration.id.toString(),
      }),
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('integrations.head.title')}</title>
      </Helmet>
      <SettingsPanel
        icon={<IntegrationsIcon color={vars.textPrimary} size={24} />}
        title={t('integrations.head.title')}>
        {baseIntegrations.map((baseIntegration) => (
          <BaseIntegrationItem
            key={baseIntegration.id}
            baseIntegration={baseIntegration}
            integrationUsers={integrationUsers}
            onAddNewProject={onAddNewProject}
            integrations={integrations}
          />
        ))}
        <PersonalAccessToken />
      </SettingsPanel>
    </>
  );
}
