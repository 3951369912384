import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useParams from 'src/hooks/useParams';
import { Button, Dropdown } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import useModalManager from 'src/hooks/useModalManager';
import { Project } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import ProjectItem from './ProjectItem';
import styles from './ProjectSelect.module.scss';

type Props<V = number> = {
  projects: Project[];
  value: V;
};

export default function ProjectSelect({ projects, value }: Props) {
  const { t } = useTranslation();
  const { showModal } = useModalManager();
  const { site } = useParams<typeof routes.PROJECT_CREATE.params>();
  const [isVisible, setVisible] = useState(false);

  function onClickCreate() {
    setVisible(false);
    showModal({
      pathname: routes.PROJECT_CREATE({ site }),
      type: 'routeModal',
    });
  }

  const currentProject = projects.find((project) => project.id === value);

  const overlay = (
    <div>
      <div className={styles.projects}>
        {projects.map((project) => (
          <Link
            to={routes.PROJECT({ site, projectId: String(project.id) })}
            key={String(project.id)}>
            <ProjectItem project={project} />
          </Link>
        ))}
      </div>
      <div className={styles.newProject}>
        <Button fullWidth onClick={onClickCreate}>
          {t('mainMenu.projectSelect.newProject')}
        </Button>
      </div>
    </div>
  );

  return (
    <Dropdown
      className={styles.projectSelect}
      classNameOverlay={styles.overlay}
      onChangeVisible={setVisible}
      overlay={overlay}
      popupAlign={{ points: ['tl', 'tl'] }}
      stretch="width"
      visible={isVisible}>
      {currentProject ? (
        <ProjectItem
          className={styles.currentProject}
          hasDropdown
          project={currentProject}
        />
      ) : (
        <div className={styles.placeholder}>
          {t('mainMenu.projectSelect.placeholder')}
        </div>
      )}
    </Dropdown>
  );
}
