import classnames from 'classnames';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useOverlayTriggerState } from 'react-stately';
import { IconButton, TableCell, TableRow } from '@bitmodern/bit-ui';
import {
  AddIcon,
  DeleteIcon,
  DoneIcon,
  EditIcon,
} from '@bitmodern/bit-ui/icons';
import {
  BaseIntegrationIcon,
  DeleteProjectDialog,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import { Project } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { integrationProjectByProjectIdSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { integrationSelectors } from 'src/gen/domain/integration/integrationSelector';
import { githubBaseIntegrationSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { integrationsByBaseSelector } from '@bitmodern/redux/state/integration/selectors';
import styles from './IntegrationProjectItem.module.scss';

type Props = {
  onProjectDeleted: () => Promise<any>;
  project: Project;
};

export default function IntegrationProjectItem({
  onProjectDeleted,
  project,
}: Props) {
  const { t } = useTranslation();
  const { site } = useParams<typeof routes.INTEGRATION_PROJECT_CREATE.params>();
  const history = useHistory();
  const deleteProject = useOverlayTriggerState({});
  const baseIntegration = useAppSelector(githubBaseIntegrationSelector);
  const integrationProject = useAppSelector((state) =>
    integrationProjectByProjectIdSelector(state, { projectId: project.id }),
  );
  const integration = useAppSelector((state) => {
    if (integrationProject?.integration_id) {
      return integrationSelectors.selectById(
        state,
        integrationProject.integration_id,
      );
    }
    if (baseIntegration) {
      return integrationsByBaseSelector(state, baseIntegration.id)[0];
    }
    return undefined;
  });

  const onClickButton = () => {
    if (integration) {
      return history.push(
        routes.PROJECT_INTEGRATION({
          site,
          integrationId: integration.id.toString(),
          projectId: project.id.toString(),
          externalRef: integrationProject?.project_reference_id,
        }),
      );
    }
    if (baseIntegration) {
      return history.push(
        routes.INTEGRATION_SETUP({
          site,
          baseIntegrationId: baseIntegration.id.toString(),
        }),
      );
    }
    return undefined;
  };

  const checkIconCN = classnames(styles.checkIcon, {
    [styles.integrated]: Boolean(integrationProject),
  });

  const projectName =
    integrationProject?.project_reference_name ||
    integrationProject?.project_reference_id;

  return (
    <>
      <TableRow>
        <TableCell className={styles.cell}>
          <span className={checkIconCN}>
            <DoneIcon color={vars.textPrimary} size={16} />
          </span>
          {project.name}
        </TableCell>
        <TableCell className={styles.cell}>
          {projectName && (
            <>
              <BaseIntegrationIcon
                baseIntegration={baseIntegration}
                className={styles.integrationIcon}
                color={vars.textPrimary}
                size={20}
              />
              {projectName}
            </>
          )}
        </TableCell>
        <TableCell className={styles.cell}>
          <div className={styles.container}>
            {integrationProject ? (
              <IconButton
                onClick={onClickButton}
                size="small"
                title={t('integrationProjectItem.edit')}>
                <EditIcon color={vars.textPrimary} size={16} />
              </IconButton>
            ) : (
              <IconButton
                onClick={onClickButton}
                size="small"
                title={t('integrationProjectItem.add')}>
                <AddIcon color={vars.textPrimary} size={16} />
              </IconButton>
            )}
            <IconButton
              onClick={deleteProject.open}
              size="small"
              title={t('integrationProjectItem.deleteProject')}>
              <DeleteIcon color={vars.textPrimary} size={16} />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>

      <DeleteProjectDialog
        onClose={deleteProject.close}
        onProjectDeleted={onProjectDeleted}
        isOpen={deleteProject.isOpen}
        projectId={project.id}
      />
    </>
  );
}
