import { Step } from '@testquality/sdk';

export function stepParser(value: string): Step[] {
  const steps: Step[] = [];
  const results: Step[] = [];
  const re = new RegExp(/(^[#]+.+\n)(.+)/gm);
  let content = value;
  while (re.test(content) !== null) {
    const step = {
      step: '',
      sequence: 1,
      expected_result: '',
    };
    const matches = re.exec(content);
    // console.log(matches);
    let sequence: number = 1;
    if (matches) {
      // console.log(matches[1]);
      // console.log(matches[2]);

      const stepSequence = matches[1]
        .replace(new RegExp(/^[#]{2}/), '')
        .replace(new RegExp(/^[#]{3}/), '')
        .match(new RegExp(/([0-9])/));

      if (stepSequence && stepSequence[1]) {
        sequence = parseInt(stepSequence[1], 10);
      } else {
        sequence += 1;
      }

      const isResult = matches[1].match(new RegExp(/[#]{3}/)) !== null;
      if (isResult) {
        step.expected_result = matches[2] ? matches[2] : '';
        step.sequence = sequence;
        results.push(step as Step);
        content = content.replace(matches[1], '');
        content = content.replace(matches[2], '');
        // eslint-disable-next-line no-continue
        continue;
      }
      step.step = matches[2] ? matches[2] : '';
      step.sequence = sequence;
      steps.push(step as Step);
      // console.log('steps', steps);
      // console.log('results', results);
      // Clean up processed match
      content = content.replace(matches[1], '');
      content = content.replace(matches[2], '');
    } else {
      break;
    }
  }
  return mergeResults(steps, results);
}

function mergeResults(steps: Step[], results: Step[]): Step[] {
  // Look for existing step sequence and assign expected result if any
  return steps.map((step) => {
    const result = results.find((item) => item.sequence === step.sequence);
    if (result) {
      step.expected_result = result.expected_result;
    }
    return step;
  });
}

export function stepsToString(steps: Step[]): string {
  let result: string = '';
  steps.forEach((step) => {
    result += `## Step ${step.sequence}\n${step.step}\n`;
    result += `### Step ${step.sequence} expected result\n${step.expected_result}\n`;
    result += `----\n`;
  });
  return result;
}
