/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { CapabilityIntegrationApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { integrationTemplateNormalizer } from '../integration_template/integrationTemplateNormalize';

export const capabilityIntegrationNormalizer = (
  model: CapabilityIntegrationApi,
  cache: NormalizerCache,
) => {
  if (!cache.capabilityIntegration) {
    cache.capabilityIntegration = [];
  }
  cache.capabilityIntegration.push(model);

  if (model.integration_template && model.integration_template.length) {
    model.integration_template.forEach((child) => {
      integrationTemplateNormalizer(child, cache);
    });
    model.integration_template = undefined;
  }
};
