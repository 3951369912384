/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { clientAdapter } from './clientAdapter';

export const clientSelectors = clientAdapter.getSelectors(
  (state: State) => state.gen.client,
);
