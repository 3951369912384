import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Keyboard.module.scss';

type Props = {
  className?: string;
  keys: ReactNode[];
  size?: 'small' | 'medium';
};

export default function Keyboard({
  className = '',
  keys,
  size = 'medium',
}: Props) {
  const keyCN = classNames(styles.key, styles[size]);

  return (
    <kbd className={`${styles.keyboard} ${className}`}>
      {keys.map((key, i) => (
        <span className={keyCN} key={i}>
          {key}
        </span>
      ))}
    </kbd>
  );
}
