/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Watch } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { watchAdapter } from './watchAdapter';
import {
  watchFetchManyThunk,
  watchFetchOneThunk,
  watchDeleteOneThunk,
  watchUpdateOneThunk,
  watchCreateOneThunk,
} from './watchThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const watchSlice = createSlice({
  name: 'watch',
  initialState: watchAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    watchCleared(state) {
      state.loading = 'idle';
      watchAdapter.removeAll(state);
    },
    watchUpsertMany(
      state,
      action: PayloadAction<Watch[] | Record<number, Watch>>,
    ) {
      watchAdapter.upsertMany(state, action.payload);
    },
    watchUpsertOne(state, action: PayloadAction<Watch>) {
      watchAdapter.upsertOne(state, action.payload);
    },
    watchUpdateOne(state, action: PayloadAction<Update<Watch>>) {
      watchAdapter.updateOne(state, action.payload);
    },
    watchRemoveOne(state, action: PayloadAction<number>) {
      watchAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(watchFetchManyThunk.pending, handlePending);
    builder.addCase(watchFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      watchAdapter.upsertMany(state, action.payload.watch);
    });
    builder.addCase(watchFetchManyThunk.rejected, handleRejected);
    builder.addCase(watchFetchOneThunk.pending, handlePending);
    builder.addCase(watchFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      watchAdapter.upsertOne(state, action.payload.watch[0]);
    });
    builder.addCase(watchFetchOneThunk.rejected, handleRejected);
    builder.addCase(watchDeleteOneThunk.pending, handlePending);
    builder.addCase(watchDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        watchAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(watchDeleteOneThunk.rejected, handleRejected);
    builder.addCase(watchUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, watchAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(watchUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      watchAdapter.updateOne(state, action.payload);
    });
    builder.addCase(watchUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          watchAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(watchCreateOneThunk.pending, handlePending);
    builder.addCase(watchCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      watchAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(watchCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  watchCleared,
  watchUpsertMany,
  watchUpsertOne,
  watchUpdateOne,
  watchRemoveOne,
} = watchSlice.actions;

export const watchReducer = watchSlice.reducer;
