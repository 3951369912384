import React, { ReactNode } from 'react';
import styles from './makeSvgIcon.module.scss';

type IconProps = {
  color?: string;
  size?: number;
  className?: string;
};

export default function makeSvgIcon(
  path: ReactNode,
  displayName: string,
  svgProps = {},
  vbHeight: number = 24,
  vbWidth: number = 24,
) {
  const Icon = (
    { color = '#fff', className = '', size = 24, ...rest }: IconProps,
    ref,
  ) => (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      fill={color}
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid"
      className={`${styles.icon} ${className}`}
      {...svgProps}
      {...rest}>
      {path}
    </svg>
  );

  if (process.env.NODE_ENV !== 'production') {
    Icon.displayName = `${displayName}Icon`;
  }

  return React.memo(React.forwardRef(Icon));
}
