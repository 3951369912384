/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { BillingContactHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { billingContactHistoryAdapter } from './billingContactHistoryAdapter';
import { billingContactHistoryFetchThunk } from './billingContactHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const billingContactHistorySlice = createSlice({
  name: 'billingContactHistory',
  initialState: billingContactHistoryAdapter.getInitialState<TQStateProperties>(
    {
      loading: 'idle',
      error: undefined,
    },
  ),
  reducers: {
    billingContactHistoryCleared(state) {
      state.loading = 'idle';
      billingContactHistoryAdapter.removeAll(state);
    },
    billingContactHistoryUpsertMany(
      state,
      action: PayloadAction<
        BillingContactHistory[] | Record<number, BillingContactHistory>
      >,
    ) {
      billingContactHistoryAdapter.upsertMany(state, action.payload);
    },
    billingContactHistoryUpsertOne(
      state,
      action: PayloadAction<BillingContactHistory>,
    ) {
      billingContactHistoryAdapter.upsertOne(state, action.payload);
    },
    billingContactHistoryUpdateOne(
      state,
      action: PayloadAction<Update<BillingContactHistory>>,
    ) {
      billingContactHistoryAdapter.updateOne(state, action.payload);
    },
    billingContactHistoryRemoveOne(state, action: PayloadAction<number>) {
      billingContactHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(billingContactHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      billingContactHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        billingContactHistoryAdapter.upsertMany(
          state,
          action.payload.billingContactHistory,
        );
      },
    );
    builder.addCase(billingContactHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  billingContactHistoryCleared,
  billingContactHistoryUpsertMany,
  billingContactHistoryUpsertOne,
  billingContactHistoryUpdateOne,
  billingContactHistoryRemoveOne,
} = billingContactHistorySlice.actions;

export const billingContactHistoryReducer = billingContactHistorySlice.reducer;
