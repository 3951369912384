import { RunResult } from '@testquality/sdk';
import {
  getDefectById,
  selectCommentsByDefectId,
} from '@bitmodern/redux/state/defects/selectors';
import {
  makeRunResultsByDefect,
  makeRunResultsByDefectId,
} from '@bitmodern/redux/state/runResults/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { getIntegrationProjectById } from 'src/packages/redux/state/integrationProject/selectors';
import { integrationByIdSelector } from 'src/packages/redux/state/integration/selectors';
import { integrationUserByIntegrationSelector } from 'src/packages/redux/state/integrationUser/selectors';
import { baseIntegrationByIdSelector } from 'src/packages/redux/state/baseIntegration/selectors';
import { usersSelector } from 'src/packages/redux/state/users/selectors';
import { currentUserSelector } from 'src/packages/redux/state/authentication/selectors';
import { useCallback } from 'react';
import useFetch from 'src/hooks/useFetch';
import { getDefectEventsThunk } from 'src/packages/redux/state/defectHistory/thunks';
import { nativeDefectStatusFetchManyThunk } from 'src/gen/domain/native_defect_status/nativeDefectStatusThunk';
import { useMaker, useParamSelector } from 'src/packages/redux/hooks';

export type Args = {
  defectId: number;
};

export type EventsReturn = {
  events: RunResult[];
};

export default function useDefectView({ defectId }: Args) {
  const dispatch = useAppDispatch();

  const {
    baseIntegration,
    defect,
    integration,
    integrationProject,
    integrationUsers,
    associatedRunResults,
    users,
    currentUser,
    comments,
    runResults,
  } = useGetDefectData({ defectId });

  const request = useCallback(() => {
    return dispatch(getDefectEventsThunk(defect, runResults));
  }, [dispatch, defect, runResults]);

  const { isLoading } = useFetch(request, `defectHistory-${defectId}`, {
    fetchOnce: true,
  });

  const requestStatus = useCallback(() => {
    return dispatch(
      nativeDefectStatusFetchManyThunk({
        params: { per_page: -1, _with: 'DefectStatus' },
      }),
    );
  }, [dispatch]);

  const { isLoading: isLoadingStatus } = useFetch(
    requestStatus,
    `defectStatus`,
    {
      fetchOnce: true,
    },
  );

  return {
    baseIntegration,
    defect,
    integration,
    integrationProject,
    integrationUsers,
    associatedRunResults,
    users,
    currentUser,
    comments,
    runResults,
    isLoading,
    isLoadingStatus,
  };
}

function useGetDefectData({ defectId }) {
  const defect = useParamSelector(getDefectById, { defectId });

  const associatedRunResults = useMaker(makeRunResultsByDefect, {
    defect,
  });

  const runResults = useMaker(makeRunResultsByDefectId, {
    defect,
  });

  const integrationProject = useAppSelector((state) =>
    getIntegrationProjectById(state, { id: defect?.integration_project_id }),
  );
  const integration = useAppSelector((state) =>
    integrationByIdSelector(state, { id: integrationProject?.integration_id }),
  );
  const integrationUsers = useAppSelector((state) =>
    integrationUserByIntegrationSelector(state, {
      integrationId: integration?.id,
    }),
  );
  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, {
      id: integration?.base_integration_id,
    }),
  );

  const users = useAppSelector(usersSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const comments = useAppSelector((state) =>
    selectCommentsByDefectId(state, defectId),
  );

  return {
    baseIntegration,
    defect,
    integration,
    integrationProject,
    integrationUsers,
    associatedRunResults,
    users,
    currentUser,
    comments,
    runResults,
  };
}
