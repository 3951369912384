import {
  planSuiteTestIncludeCreateOneThunk,
  planSuiteTestIncludeDeleteOneThunk,
} from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import {
  SuiteTest,
  BatchService,
  PlanSuiteTestInclude,
  Suite,
} from '@testquality/sdk';
import { AppThunk } from '@bitmodern/redux/store';
import { planSuiteTestIncludeByPlanSelector } from '@bitmodern/redux/state/planSuitesTestsIncludes/selectors';
import { planSelector } from '../plans/selectors';
import {
  planIncludeSelectorByTest,
  planIncludeSelectorBySuite,
} from './selectors';

export function includeTestInPlanThunk(
  planId: number,
  suiteTests: SuiteTest[],
): AppThunk<void> {
  return (dispatch, getState) => {
    const plan = planSelector(getState(), planId);
    if (!plan) {
      return Promise.reject(new Error('Plan not found'));
    }

    const planIncludes = planSuiteTestIncludeByPlanSelector(getState(), {
      planId,
    });

    const batch = new BatchService();
    const promises: Array<Promise<any>> = suiteTests.map((suiteTest) => {
      const include = planIncludes.find((i) => {
        return (
          i.suite_id === suiteTest.suite_id &&
          i.test_id === suiteTest.test_id &&
          i.plan_id === planId
        );
      });

      if (include) {
        return Promise.resolve(true);
      }
      return dispatch(
        planSuiteTestIncludeCreateOneThunk({
          data: {
            project_id: plan.project_id,
            plan_id: plan.id,
            suite_id: suiteTest.suite_id,
            test_id: suiteTest.test_id,
          },
          batch,
        }),
      );
    });

    batch.executeBatch();
    return Promise.all(promises);
  };
}

export function includeSuiteInPlanThunk(
  planId: number,
  suites: Suite[],
): AppThunk<void> {
  return (dispatch, getState) => {
    const plan = planSelector(getState(), planId);
    if (!plan) {
      return Promise.reject(new Error('Plan not found'));
    }

    const planIncludes = planSuiteTestIncludeByPlanSelector(getState(), {
      planId,
    });

    const batch = new BatchService();
    const promises: Array<Promise<any>> = suites.map((suite) => {
      const include = planIncludes.find((i) => {
        return i.suite_id === suite.id && i.plan_id === planId;
      });

      if (include) {
        return Promise.resolve(true);
      }
      return dispatch(
        planSuiteTestIncludeCreateOneThunk({
          data: {
            project_id: plan.project_id,
            plan_id: plan.id,
            suite_id: suite.id,
          },
          batch,
        }),
      );
    });

    batch.executeBatch();
    return Promise.all(promises);
  };
}

export function excludeTestFromPlanThunk(
  planId: number,
  suiteTests: SuiteTest[],
): AppThunk<void> {
  return async (dispatch, getState) => {
    const batch = new BatchService();
    const planSuitesTestsIncludes: PlanSuiteTestInclude[] = [];
    suiteTests.forEach((suiteTest) => {
      const pst = planIncludeSelectorByTest(
        getState(),
        planId,
        suiteTest.suite_id,
        suiteTest.test_id,
      );
      if (pst) planSuitesTestsIncludes.push(pst);
    });

    planSuitesTestsIncludes.forEach((pst) => {
      dispatch(planSuiteTestIncludeDeleteOneThunk({ id: pst.id, batch }));
    });
    return batch.executeBatch();
  };
}

export function excludeSuiteFromPlanThunk(
  planId: number,
  suites: Suite[],
): AppThunk<void> {
  return async (dispatch, getState) => {
    const batch = new BatchService();
    const planSuitesTestsIncludes = suites
      .map((suite) => {
        return planIncludeSelectorBySuite(getState(), planId, suite.id);
      })
      .filter((pst) => pst !== undefined) as PlanSuiteTestInclude[];

    const promises = planSuitesTestsIncludes.map((pst) =>
      dispatch(planSuiteTestIncludeDeleteOneThunk({ id: pst.id, batch })),
    );

    batch.executeBatch();
    return Promise.all(promises);
  };
}
