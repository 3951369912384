/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { IntegrationHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { integrationHistoryAdapter } from './integrationHistoryAdapter';
import { integrationHistoryFetchThunk } from './integrationHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const integrationHistorySlice = createSlice({
  name: 'integrationHistory',
  initialState: integrationHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    integrationHistoryCleared(state) {
      state.loading = 'idle';
      integrationHistoryAdapter.removeAll(state);
    },
    integrationHistoryUpsertMany(
      state,
      action: PayloadAction<
        IntegrationHistory[] | Record<number, IntegrationHistory>
      >,
    ) {
      integrationHistoryAdapter.upsertMany(state, action.payload);
    },
    integrationHistoryUpsertOne(
      state,
      action: PayloadAction<IntegrationHistory>,
    ) {
      integrationHistoryAdapter.upsertOne(state, action.payload);
    },
    integrationHistoryUpdateOne(
      state,
      action: PayloadAction<Update<IntegrationHistory>>,
    ) {
      integrationHistoryAdapter.updateOne(state, action.payload);
    },
    integrationHistoryRemoveOne(state, action: PayloadAction<number>) {
      integrationHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(integrationHistoryFetchThunk.pending, handlePending);
    builder.addCase(integrationHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      integrationHistoryAdapter.upsertMany(
        state,
        action.payload.integrationHistory,
      );
    });
    builder.addCase(integrationHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  integrationHistoryCleared,
  integrationHistoryUpsertMany,
  integrationHistoryUpsertOne,
  integrationHistoryUpdateOne,
  integrationHistoryRemoveOne,
} = integrationHistorySlice.actions;

export const integrationHistoryReducer = integrationHistorySlice.reducer;
