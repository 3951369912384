import React, { ComponentProps, useMemo } from 'react';
import { Button, Dialog, DialogContent, PanelActions } from '@bitmodern/bit-ui';
import { AddIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import useModalManager from 'src/hooks/useModalManager';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useFormik } from 'formik';
import { appVersionPlatVersionPlansSelectorByPlan } from '@bitmodern/redux/state/appVersionPlatVersionPlans/selectors';
import { planUpdateOneThunk } from 'src/gen/domain/plan/planThunk';
import { appVersionPlatVersionPlanFetchManyThunk } from 'src/gen/domain/app_version_plat_version_plan/appVersionPlatVersionPlanThunk';
import { appVersionPlatVersionPlanRemoveOne } from 'src/gen/domain/app_version_plat_version_plan/appVersionPlatVersionPlanSlice';
import vars from 'src/export.scss';
import Item from './Item';
import styles from './PlanConfigurationDialog.module.scss';

type Values = ComponentProps<typeof Item>['formik']['values'];

type Props = {
  planId: number;
};

export default function PlanConfigurationDialog({ planId }: Props) {
  const { hideModal } = useModalManager();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const appVersionPlatVersionPlans = useAppSelector((state) =>
    appVersionPlatVersionPlansSelectorByPlan(state, { planId }),
  );

  const initialValues = useMemo<Values>(() => {
    const appVersionPlatVersions = appVersionPlatVersionPlans.map(
      (item) => item.app_version_plat_version_id,
    );

    return { appVersionPlatVersions };
  }, [appVersionPlatVersionPlans]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  async function onSubmit(values: Values) {
    await dispatch(
      planUpdateOneThunk({
        id: planId,
        data: {
          // @ts-expect-error no in type
          app_version_plat_version: values.appVersionPlatVersions
            .filter((item) => item !== undefined)
            .map((item) => ({ id: item })),
        },
      }),
    );

    // remove deleted from store
    appVersionPlatVersionPlans
      .filter(
        (item) =>
          values.appVersionPlatVersions.includes(
            item.app_version_plat_version_id,
          ) === false,
      )
      .forEach((item) => {
        dispatch(appVersionPlatVersionPlanRemoveOne(item.id));
      });

    await dispatch(
      appVersionPlatVersionPlanFetchManyThunk({
        params: { per_page: -1, plan_id: planId },
      }),
    );

    hideModal();
  }

  const onAddItem = () => {
    formik.setFieldValue('appVersionPlatVersions', [
      ...formik.values.appVersionPlatVersions,
      undefined,
    ]);
  };

  return (
    <Dialog
      animatePresence={false}
      onClose={hideModal}
      size="large"
      title={t('planConfiguration.title')}>
      <DialogContent className={styles.content}>
        <form
          id="plan-configuration"
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}>
          {formik.values.appVersionPlatVersions.map((_, i) => (
            <Item formik={formik} index={i} key={i} />
          ))}
          <Button
            className={styles.add}
            color="primaryLight"
            icon={<AddIcon color={vars.textPrimary} size={18} />}
            onClick={onAddItem}>
            {t('planConfiguration.add')}
          </Button>
        </form>
      </DialogContent>
      <PanelActions>
        <Button
          form="plan-configuration"
          loading={formik.isSubmitting}
          type="submit">
          {t('planConfiguration.form.submit')}
        </Button>
      </PanelActions>
    </Dialog>
  );
}
