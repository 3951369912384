/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { CheckRun } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { checkRunAdapter } from './checkRunAdapter';
import {
  checkRunFetchManyThunk,
  checkRunFetchOneThunk,
  checkRunDeleteOneThunk,
  checkRunUpdateOneThunk,
  checkRunCreateOneThunk,
} from './checkRunThunk';
import {
  checkSuiteFetchManyThunk,
  checkSuiteFetchOneThunk,
} from '../check_suite/checkSuiteThunk';
import {
  pullRequestFetchManyThunk,
  pullRequestFetchOneThunk,
} from '../pull_request/pullRequestThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const checkRunSlice = createSlice({
  name: 'checkRun',
  initialState: checkRunAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    checkRunCleared(state) {
      state.loading = 'idle';
      checkRunAdapter.removeAll(state);
    },
    checkRunUpsertMany(
      state,
      action: PayloadAction<CheckRun[] | Record<number, CheckRun>>,
    ) {
      checkRunAdapter.upsertMany(state, action.payload);
    },
    checkRunUpsertOne(state, action: PayloadAction<CheckRun>) {
      checkRunAdapter.upsertOne(state, action.payload);
    },
    checkRunUpdateOne(state, action: PayloadAction<Update<CheckRun>>) {
      checkRunAdapter.updateOne(state, action.payload);
    },
    checkRunRemoveOne(state, action: PayloadAction<number>) {
      checkRunAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkRunFetchManyThunk.pending, handlePending);
    builder.addCase(checkRunFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkRunAdapter.upsertMany(state, action.payload.checkRun);
    });
    builder.addCase(checkRunFetchManyThunk.rejected, handleRejected);
    builder.addCase(checkRunFetchOneThunk.pending, handlePending);
    builder.addCase(checkRunFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkRunAdapter.upsertOne(state, action.payload.checkRun[0]);
    });
    builder.addCase(checkRunFetchOneThunk.rejected, handleRejected);
    builder.addCase(checkRunDeleteOneThunk.pending, handlePending);
    builder.addCase(checkRunDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        checkRunAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(checkRunDeleteOneThunk.rejected, handleRejected);
    builder.addCase(checkRunUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, checkRunAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(checkRunUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkRunAdapter.updateOne(state, action.payload);
    });
    builder.addCase(checkRunUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          checkRunAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(checkRunCreateOneThunk.pending, handlePending);
    builder.addCase(checkRunCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkRunAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(checkRunCreateOneThunk.rejected, handleRejected);
    builder.addCase(checkSuiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(checkSuiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(pullRequestFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(pullRequestFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkRun) {
          checkRunAdapter.upsertMany(state, action.payload.checkRun);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkRun) {
          checkRunAdapter.upsertMany(state, action.payload.checkRun);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.checkRun) {
          checkRunAdapter.upsertMany(state, action.payload.checkRun);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.checkRun) {
        checkRunAdapter.upsertMany(state, action.payload.checkRun);
      }
    });
  },
});

export const {
  checkRunCleared,
  checkRunUpsertMany,
  checkRunUpsertOne,
  checkRunUpdateOne,
  checkRunRemoveOne,
} = checkRunSlice.actions;

export const checkRunReducer = checkRunSlice.reducer;
