import React, { ComponentProps } from 'react';
import { useAppSelector } from '@bitmodern/redux/store';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { Select } from '@bitmodern/bit-ui';
import { Status } from '@testquality/sdk';
import styles from './StatusSelect.module.scss';
import StatusIcon from '../StatusIcon';

type Props = {
  disabled?: boolean;
  onChange?: (status: Status) => void;
  value: any;
} & Pick<ComponentProps<typeof Select>, 'className' | 'size'>;

export default function StatusSelect({
  className = '',
  disabled,
  onChange,
  size,
  value,
}: Props) {
  const statuses = useAppSelector(statusSelectors.selectAll);

  const handleChange = (val) => {
    const current = statuses.find((s) => s.id === val);
    if (onChange && current) {
      onChange(current);
    }
  };

  const renderOption = (option) => (
    <>
      <StatusIcon status={option.status} />
      <span className={styles.label}>{option.label}</span>
    </>
  );

  return (
    <Select
      className={`${styles.select} ${className}`}
      disabled={disabled}
      placeholder="Status"
      onChange={handleChange}
      options={statuses.map((status) => ({
        label: status.name,
        status,
        value: status.id,
      }))}
      renderOption={renderOption}
      size={size}
      value={value}
    />
  );
}
