import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import usePersistedState from 'src/hooks/usePersistedState';
import classnames from 'classnames';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { Dropdown, IconButton, Menu, MenuItem } from '@bitmodern/bit-ui';
import {
  AddVariableIcon,
  AttachFileIcon,
  BoldIcon,
  CodeIcon,
  FormatIcon,
  FormatSizeIcon,
  ItalicIcon,
  LinkIcon,
  ListBulletedIcon,
  ListNumberedIcon,
  PreviewIcon,
  QuoteIcon,
  TableCharIcon,
} from '../icons';
import styles from './Toolbar.module.scss';

// const isMac = /Mac/.test(navigator.platform);
// // const CTRL = isMac ? '⌘' : 'Ctrl';
// // const ALT = isMac ? '⌥' : 'Alt';

// // const shortcuts = {
// //   bold: 'Cmd-B',
// //   italic: 'Cmd-I',
// //   link: 'Cmd-K',
// //   heading: 'Cmd-H',
// //   toggleHeadingBigger: 'Shift-Cmd-H',
// //   cleanBlock: 'Cmd-E',
// //   image: 'Cmd-Alt-I',
// //   quote: "Cmd-'",
// //   'ordered-list': 'Cmd-Alt-L',
// //   'unordered-list': 'Cmd-L',
// //   code: 'Cmd-Alt-C',
// //   preview: 'Cmd-P',
// //   write: 'Cmd-P',
// //   'side-by-side': 'F9',
// //   fullscreen: 'F11',
// // };

/* eslint-disable react/no-unused-prop-types */
type ToolbarIcon = {
  action: string;
  icon: any;
  name: string;
  title: string;
};

type TemplateVariable = {
  title: string;
  templateVariable: string;
  isNested?: boolean;
};
/* eslint-enable react/no-unused-prop-types */

type Props = {
  onClick: (action: string, templateVariable?: string) => void;
  state: any;
  withFormatting?: boolean;
  withCapabilityTemplateEditingFor?: CapabilityEnum | null;
};

export default function Toolbar({
  onClick,
  state,
  withFormatting = true,
  withCapabilityTemplateEditingFor,
}: Props) {
  const [isShowing, setIsShowing] = usePersistedState('toolbar', false);

  const onClickFormating = () => {
    setIsShowing((s) => !s);
  };

  const onClickAttacht = () => {
    onClick('uploadFile');
  };

  const toolbar: ToolbarIcon[] = [
    {
      action: 'toggleHeadingSmaller',
      icon: FormatSizeIcon,
      name: 'heading',
      title: 'Heading',
    },
    {
      action: 'toggleBold',
      icon: BoldIcon,
      name: 'bold',
      title: 'Bold',
    },
    {
      action: 'toggleItalic',
      icon: ItalicIcon,
      name: 'italic',
      title: 'Italic',
    },
    {
      action: 'toggleBlockquote',
      icon: QuoteIcon,
      name: 'quote',
      title: 'Quote',
    },
    {
      action: 'toggleCodeBlock',
      icon: CodeIcon,
      name: 'code',
      title: 'Code',
    },
    {
      action: 'drawLink',
      icon: LinkIcon,
      name: 'link',
      title: 'Create Link',
    },
    {
      action: 'toggleUnorderedList',
      icon: ListBulletedIcon,
      name: 'unordered-list',
      title: 'Generic List',
    },
    {
      action: 'toggleOrderedList',
      icon: ListNumberedIcon,
      name: 'ordered-list',
      title: 'Numbered List',
    },
    {
      action: 'drawTable',
      icon: TableCharIcon,
      name: 'table',
      title: 'Insert Table',
    },
    {
      action: 'togglePreview',
      icon: PreviewIcon,
      name: '',
      title: 'Preview',
    },
  ];

  const defectTemplateVariables: TemplateVariable[] = [
    {
      title: 'Test precondition',
      templateVariable: '{{run_result.test.precondition}}',
    },
    {
      title: 'Run result steps',
      templateVariable: '{{run_result.steps}}',
    },
    {
      title: 'Run result step',
      templateVariable: '{{step.step}}',
      isNested: true,
    },
    {
      title: 'Run result step expected result',
      templateVariable: '{{step.expected_result}}',
      isNested: true,
    },
    {
      title: 'Run result step actual result',
      templateVariable: '{{result}}',
      isNested: true,
    },
    {
      title: 'Status name',
      templateVariable: '{{status.name}}',
    },
    {
      title: 'Status color',
      templateVariable: '{{status.color}}',
    },
  ];
  const requirementTemplateVariables: TemplateVariable[] = [
    {
      title: 'Test precondition',
      templateVariable: '{{test.precondition}}',
    },
    {
      title: 'Test steps',
      templateVariable: '{{test.steps}}',
      isNested: true,
    },
    {
      title: 'Test step',
      templateVariable: '{{step}}',
      isNested: true,
    },
    {
      title: 'Test step expected result',
      templateVariable: '{{expected_result}}',
      isNested: true,
    },
  ];

  const templateVariables = {
    [CapabilityEnum.Defect]: defectTemplateVariables,
    [CapabilityEnum.Requirement]: requirementTemplateVariables,
  };

  const showItems = withFormatting && isShowing;

  return (
    <div className={styles.toolbar}>
      <AnimatePresence initial={false}>
        {toolbar.map(
          ({ action, icon: Icon, name, title }: ToolbarIcon, i, arr) =>
            showItems && (
              <motion.span
                animate="show"
                initial="hidden"
                exit="hidden"
                key={name}
                variants={{
                  hidden: {
                    scale: 0,
                    x: 10,
                    transition: { delay: 0.01 * i },
                  },
                  show: {
                    scale: 1,
                    x: 0,
                    transition: {
                      delay: 0.01 * (arr.length - i - 1),
                    },
                  },
                }}>
                <IconButton
                  boxed={false}
                  className={styles.button}
                  key={action}
                  onClick={() => onClick(action)}
                  title={title}>
                  <Icon
                    color={state[name] ? vars.textPrimary : vars.textSecondary}
                    size={18}
                  />
                </IconButton>
              </motion.span>
            ),
        )}
      </AnimatePresence>
      {!withCapabilityTemplateEditingFor && (
        <IconButton
          boxed={false}
          onClick={onClickAttacht}
          className={styles.button}
          title="Attachment">
          <AttachFileIcon color={vars.textSecondary} size={18} />
        </IconButton>
      )}
      {withFormatting && (
        <IconButton
          boxed={false}
          className={styles.button}
          onClick={onClickFormating}
          title="">
          <FormatIcon
            color={isShowing ? vars.textPrimary : vars.textSecondary}
            size={18}
          />
        </IconButton>
      )}
      {withCapabilityTemplateEditingFor && (
        <Dropdown
          popupAlign={{ points: ['br', 'tl'], offset: [30, -5] }}
          mask={false}
          overlay={
            <Menu>
              {templateVariables[withCapabilityTemplateEditingFor].map(
                ({ title, templateVariable, isNested }) => {
                  const titleCN = classnames(styles.templateVariableTitle, {
                    [styles.templateVariableTitleNested]: isNested,
                  });

                  return (
                    <MenuItem
                      className={styles.templateVariableItem}
                      key={templateVariable}
                      onClick={() => {
                        onClick('drawTemplateVariable', templateVariable);
                      }}
                      size="small">
                      <div className={styles.templateVariableItemContainer}>
                        <span className={titleCN}>{title}</span>
                        <span className={styles.templateVariableCode}>
                          {templateVariable}
                        </span>
                      </div>
                    </MenuItem>
                  );
                },
              )}
            </Menu>
          }>
          <IconButton
            boxed={false}
            className={styles.button}
            title="Add variables">
            <AddVariableIcon color={vars.textSecondary} size={18} />
          </IconButton>
        </Dropdown>
      )}
    </div>
  );
}
