/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Milestone } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { milestoneAdapter } from './milestoneAdapter';
import {
  milestoneFetchManyThunk,
  milestoneFetchOneThunk,
  milestoneDeleteOneThunk,
  milestoneUpdateOneThunk,
  milestoneCreateOneThunk,
} from './milestoneThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const milestoneSlice = createSlice({
  name: 'milestone',
  initialState: milestoneAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    milestoneCleared(state) {
      state.loading = 'idle';
      milestoneAdapter.removeAll(state);
    },
    milestoneUpsertMany(
      state,
      action: PayloadAction<Milestone[] | Record<number, Milestone>>,
    ) {
      milestoneAdapter.upsertMany(state, action.payload);
    },
    milestoneUpsertOne(state, action: PayloadAction<Milestone>) {
      milestoneAdapter.upsertOne(state, action.payload);
    },
    milestoneUpdateOne(state, action: PayloadAction<Update<Milestone>>) {
      milestoneAdapter.updateOne(state, action.payload);
    },
    milestoneRemoveOne(state, action: PayloadAction<number>) {
      milestoneAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(milestoneFetchManyThunk.pending, handlePending);
    builder.addCase(milestoneFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      milestoneAdapter.upsertMany(state, action.payload.milestone);
    });
    builder.addCase(milestoneFetchManyThunk.rejected, handleRejected);
    builder.addCase(milestoneFetchOneThunk.pending, handlePending);
    builder.addCase(milestoneFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      milestoneAdapter.upsertOne(state, action.payload.milestone[0]);
    });
    builder.addCase(milestoneFetchOneThunk.rejected, handleRejected);
    builder.addCase(milestoneDeleteOneThunk.pending, handlePending);
    builder.addCase(milestoneDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        milestoneAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(milestoneDeleteOneThunk.rejected, handleRejected);
    builder.addCase(milestoneUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, milestoneAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(milestoneUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      milestoneAdapter.updateOne(state, action.payload);
    });
    builder.addCase(milestoneUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          milestoneAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(milestoneCreateOneThunk.pending, handlePending);
    builder.addCase(milestoneCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      milestoneAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(milestoneCreateOneThunk.rejected, handleRejected);
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.milestone) {
          milestoneAdapter.upsertMany(state, action.payload.milestone);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.milestone) {
          milestoneAdapter.upsertMany(state, action.payload.milestone);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.milestone) {
          milestoneAdapter.upsertMany(state, action.payload.milestone);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.milestone) {
        milestoneAdapter.upsertMany(state, action.payload.milestone);
      }
    });
  },
});

export const {
  milestoneCleared,
  milestoneUpsertMany,
  milestoneUpsertOne,
  milestoneUpdateOne,
  milestoneRemoveOne,
} = milestoneSlice.actions;

export const milestoneReducer = milestoneSlice.reducer;
