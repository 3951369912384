import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mergeProps } from 'react-aria';
import classnames from 'classname';
import { MarkdownView, useFocusAccent } from '@bitmodern/bit-ui';
import MarkdownEditor from '@bitmodern/bit-ui/EasyMDE/MarkdownEditor';
import ActionButtons from '@bitmodern/bit-ui/EasyMDE/ActionButtons';
import Toolbar from '@bitmodern/bit-ui/EasyMDE/Toolbar';
import { ExpectedResultIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { Step } from '@testquality/sdk';
import { useEditing } from '@bitmodern/bit-ui/InlineEdit/useEditing';
import { useEditingKeyboard } from '@bitmodern/bit-ui/InlineEdit/useEditingKeyboard';
import styles from './StepEditor.module.scss';

type Values = { step?: string; expectedResult?: string };

type Props = Pick<
  ComponentProps<typeof MarkdownEditor>,
  'onFileUpload' | 'parentId' | 'parentType'
> & {
  editable?: boolean;
  highlights?: string[];
  onCommit?: (values: Values) => Promise<any>;
  onChange?: (values: Values) => void;
  step: Step;
};

export default function StepEditor({
  editable = true,
  onChange,
  onCommit,
  onFileUpload,
  parentId,
  parentType,
  step,
  highlights,
}: Props) {
  const withCommit = Boolean(onCommit);
  const onlyEditor = !withCommit && editable;

  const { t } = useTranslation();
  const [values, setValues] = useState({
    step: step.step,
    expectedResult: step.expected_result,
  });
  const [state, setState] = useState({});
  const stepRef = useRef<any>();
  const expectedResultRef = useRef<any>();
  const [currentEditor, setCurrentEditor] = useState<'step' | 'expectedResult'>(
    'step',
  );

  useEffect(() => {
    setValues({
      step: step.step,
      expectedResult: step.expected_result,
    });
  }, [step]);

  const { borderClassName, borderProps } = useFocusAccent();
  const inlineEdit = useEditing({
    onCommit: () => {
      if (onCommit) {
        if (
          step.step !== values.step ||
          step.expected_result !== values.expectedResult
        ) {
          return onCommit(values);
        }
      }
      return Promise.resolve();
    },
    onCancel: () => {},
  });

  const { keyboardProps } = useEditingKeyboard({
    commitOnEnter: false,
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });

  const handleOnCommit = () => {
    inlineEdit.commit();
    return Promise.resolve();
  };

  const onCancel = () => {
    inlineEdit.cancel();
    setValues({
      step: step.step,
      expectedResult: step.expected_result,
    });
  };

  const onClickPreview = () => {
    inlineEdit.open();
  };

  const onFocusStep = useCallback(() => {
    setCurrentEditor('step');
  }, []);

  const onFocusExpectedResult = useCallback(() => {
    setCurrentEditor('expectedResult');
  }, []);

  const onChangeStep = useCallback(
    (value) => {
      setValues((s) => {
        const next = { ...s, step: value };
        if (onChange) onChange(next);
        return next;
      });
    },
    [onChange],
  );

  const onChangeExpectedResult = useCallback(
    (value) => {
      setValues((s) => {
        const next = { ...s, expectedResult: value };
        if (onChange) onChange(next);
        return next;
      });
    },
    [onChange],
  );

  const onClickToolbar = (action: string) => {
    const editor = currentEditor === 'step' ? stepRef : expectedResultRef;
    if (editor.current[action]) {
      editor.current[action]();
    }
  };

  const onClickExpectedResult = () => {
    expectedResultRef.current.focus();
  };

  const stepCN = classnames(styles.step, {
    [borderClassName]: !inlineEdit.isEditing,
  });

  const renderStep = () => {
    const editor = (
      <>
        <div className={styles.editor}>
          <MarkdownEditor
            autofocus
            minHeight="64px"
            onChange={onChangeStep}
            onChangeState={setState}
            onFileUpload={onFileUpload}
            onFocus={onFocusStep}
            parentId={parentId}
            parentType={parentType}
            ref={stepRef}
            value={values.step}
          />
          <div
            className={styles.expetedResultEditor}
            onClick={onClickExpectedResult}>
            <div className={styles.exptedTitle}>{t('step.expectedResult')}</div>
            <MarkdownEditor
              minHeight="48x"
              onChange={onChangeExpectedResult}
              onChangeState={setState}
              onFileUpload={onFileUpload}
              onFocus={onFocusExpectedResult}
              parentId={parentId}
              parentType={parentType}
              ref={expectedResultRef}
              value={values.expectedResult}
            />
          </div>
          <Toolbar onClick={onClickToolbar} state={state} />
        </div>
        {withCommit && (
          <ActionButtons onCancel={onCancel} onCommit={handleOnCommit} />
        )}
      </>
    );

    if (onlyEditor) return editor;

    if (editable) {
      return (
        <div
          className={stepCN}
          onClick={onClickPreview}
          {...mergeProps(borderProps, keyboardProps)}>
          {inlineEdit.isEditing ? (
            editor
          ) : (
            <MarkdownView
              className={styles.stepView}
              markdown={values.step || ''}
              highlights={highlights}
            />
          )}
        </div>
      );
    }

    return (
      <MarkdownView className={styles.stepView} markdown={values.step || ''} />
    );
  };

  return (
    <>
      {renderStep()}
      {!onlyEditor && (
        <div className={styles.step}>
          {!inlineEdit.isEditing && step.expected_result && (
            <div className={styles.expectedResult}>
              <ExpectedResultIcon className={styles.resultIcon} size={24} />
              <div className={styles.expectedResultContent}>
                <div className={styles.resultLabel}>
                  {t('step.expectedResult')}
                </div>
                <MarkdownView
                  className={styles.resultText}
                  markdown={step.expected_result || ''}
                  highlights={highlights}
                  read
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
