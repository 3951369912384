/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CheckRunHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { checkRunHistoryAdapter } from './checkRunHistoryAdapter';
import { checkRunHistoryFetchThunk } from './checkRunHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const checkRunHistorySlice = createSlice({
  name: 'checkRunHistory',
  initialState: checkRunHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    checkRunHistoryCleared(state) {
      state.loading = 'idle';
      checkRunHistoryAdapter.removeAll(state);
    },
    checkRunHistoryUpsertMany(
      state,
      action: PayloadAction<
        CheckRunHistory[] | Record<number, CheckRunHistory>
      >,
    ) {
      checkRunHistoryAdapter.upsertMany(state, action.payload);
    },
    checkRunHistoryUpsertOne(state, action: PayloadAction<CheckRunHistory>) {
      checkRunHistoryAdapter.upsertOne(state, action.payload);
    },
    checkRunHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CheckRunHistory>>,
    ) {
      checkRunHistoryAdapter.updateOne(state, action.payload);
    },
    checkRunHistoryRemoveOne(state, action: PayloadAction<number>) {
      checkRunHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkRunHistoryFetchThunk.pending, handlePending);
    builder.addCase(checkRunHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkRunHistoryAdapter.upsertMany(state, action.payload.checkRunHistory);
    });
    builder.addCase(checkRunHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  checkRunHistoryCleared,
  checkRunHistoryUpsertMany,
  checkRunHistoryUpsertOne,
  checkRunHistoryUpdateOne,
  checkRunHistoryRemoveOne,
} = checkRunHistorySlice.actions;

export const checkRunHistoryReducer = checkRunHistorySlice.reducer;
