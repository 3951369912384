import { PureComponent, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  fallback: ReactNode;
};

export default class ErrorBoundary extends PureComponent<Props> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) return fallback;

    return children;
  }
}
