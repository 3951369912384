/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CheckSuiteHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { checkSuiteHistoryAdapter } from './checkSuiteHistoryAdapter';
import { checkSuiteHistoryFetchThunk } from './checkSuiteHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const checkSuiteHistorySlice = createSlice({
  name: 'checkSuiteHistory',
  initialState: checkSuiteHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    checkSuiteHistoryCleared(state) {
      state.loading = 'idle';
      checkSuiteHistoryAdapter.removeAll(state);
    },
    checkSuiteHistoryUpsertMany(
      state,
      action: PayloadAction<
        CheckSuiteHistory[] | Record<number, CheckSuiteHistory>
      >,
    ) {
      checkSuiteHistoryAdapter.upsertMany(state, action.payload);
    },
    checkSuiteHistoryUpsertOne(
      state,
      action: PayloadAction<CheckSuiteHistory>,
    ) {
      checkSuiteHistoryAdapter.upsertOne(state, action.payload);
    },
    checkSuiteHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CheckSuiteHistory>>,
    ) {
      checkSuiteHistoryAdapter.updateOne(state, action.payload);
    },
    checkSuiteHistoryRemoveOne(state, action: PayloadAction<number>) {
      checkSuiteHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkSuiteHistoryFetchThunk.pending, handlePending);
    builder.addCase(checkSuiteHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      checkSuiteHistoryAdapter.upsertMany(
        state,
        action.payload.checkSuiteHistory,
      );
    });
    builder.addCase(checkSuiteHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  checkSuiteHistoryCleared,
  checkSuiteHistoryUpsertMany,
  checkSuiteHistoryUpsertOne,
  checkSuiteHistoryUpdateOne,
  checkSuiteHistoryRemoveOne,
} = checkSuiteHistorySlice.actions;

export const checkSuiteHistoryReducer = checkSuiteHistorySlice.reducer;
