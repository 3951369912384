import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { CSVFormValuesType, FormValuesType } from '../FormikType';
import {
  planCreateOneThunk,
  planFetchOneThunk,
} from 'src/gen/domain/plan/planThunk';
import { useAppDispatch, useAppSelector } from 'src/packages/redux/store';
import {
  lastCycleSelector,
  rootPlanSelector,
} from 'src/packages/redux/state/plans/selectors';
import { leadingZeros } from 'src/utils/common';
import { postJunitXML } from 'src/packages/services/PlanService';
import { BatchService, Plan, showNotificationError } from '@testquality/sdk';
import { planSuiteTestIncludeFetchManyThunk } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeThunk';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { attachmentFetchManyThunk } from 'src/gen/domain/attachment/attachmentThunk';
import { runFetchOneThunk } from 'src/gen/domain/run/runThunk';
import { useEffect, useState } from 'react';

export type Args = {
  projectId: number;
};

export type FormValues = FormValuesType | CSVFormValuesType;

const initialValues: FormValues = {
  planId: 0,
  milestoneId: 0,
  automatedRunResults: false,
  filesToSend: [],
  delimiter: ',',
  escape: '\\',
  enclosure: '"',
  useUtf8Encode: true,
  mappings: [],
  extraFields: {},
};

const validationSchema = Yup.object().shape({
  filesToSend: Yup.array<File>().required(),
});

export default function useJUnit({ projectId }: Args) {
  const dispatch = useAppDispatch();
  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, projectId),
  );
  const lastCycle = useAppSelector(lastCycleSelector);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fetchData = (rootPlanId: number, runId: number) => {
    const batch = new BatchService();
    dispatch(
      planFetchOneThunk({
        id: rootPlanId,
        params: {
          _with: 'suite,suite.test,suite.test.step',
          per_page: -1,
        },
        batch,
      }),
    );
    dispatch(
      runFetchOneThunk({
        id: runId,
        params: {
          _with: 'runResult.runResultStep',
          per_page: -1,
        },
        batch,
      }),
    );
    dispatch(
      attachmentFetchManyThunk({
        params: {
          per_page: -1,
          related_type: RelatedType.RUN_RESULT,
        },
        batch,
      }),
    );
    dispatch(
      planSuiteTestIncludeFetchManyThunk({
        params: {
          project_id: projectId,
          per_page: -1,
        },
        batch,
      }),
    );
    batch.executeBatch();
  };

  const onSubmit: (values: any) => Promise<any> = (values) => {
    if (!rootPlan) return Promise.reject();
    if (!values.planId) {
      return dispatch(
        planCreateOneThunk({
          data: {
            project_id: projectId,
            name: lastCycle ? `Cycle ${leadingZeros(lastCycle.key, 3)}` : '',
            is_root: false,
          },
        }),
      )
        .then(({ payload }) => {
          return postJunitXML(
            values.filesToSend,
            (payload as Plan).id,
            values.milestoneId,
          );
        })
        .then((resp) => {
          return fetchData(rootPlan.id, resp.run_id);
        });
    }
    return postJunitXML(values.filesToSend, values.planId, values.milestoneId)
      .then((payload) => {
        return fetchData(rootPlan.id, payload.run_id);
      })
      .then(
        () => {
          setUploadProgress(100);
          // hideModal(); ??
        },
        (error) => {
          showNotificationError(error);
          setUploadProgress(0);
          return Promise.reject(error);
        },
      );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [uploadFinished, setUploadFinished] = useState<boolean>(false);

  useEffect(() => {
    setUploadFinished(uploadProgress >= 100);
  }, [uploadProgress]);

  const handleDropFiles = (files) => {
    formik.setFieldValue('filesToSend', files);
  };

  return { formik, uploadFinished, handleDropFiles };
}
