import React, { useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'src/i18n/hooks';
import {
  Dropdown,
  IconButton,
  InputSearch,
  Menu,
  MenuItem,
  PanelHeader,
  Spacer,
} from '@bitmodern/bit-ui';
import { AddIcon, ArrowDownIcon, FilterIcon } from '@bitmodern/bit-ui/icons';
import { Expandable } from 'src/modules/core/elements';
import vars from 'src/export.scss';
import styles from './RequirementsHeader.module.scss';
import { Option } from 'src/packages/bit-ui/Select/Select';

type Props = {
  filter: string;
  filters: Option[];
  initialSearch: string;
  onAddRequirement: () => void;
  onChangeFilter: (filter: Option) => void;
  onChangeSearch: (value: string) => void;
};

export default function RequirementsHeader({
  filter,
  filters,
  initialSearch,
  onAddRequirement,
  onChangeFilter,
  onChangeSearch,
}: Props) {
  const filtersState = useOverlayTriggerState({});
  const { t } = useTranslation();
  const [search, setSearch] = useState(initialSearch);

  const onChangeDebounced = useMemo(() => {
    return debounce(onChangeSearch, 250);
  }, [onChangeSearch]);

  const handleChangeSearch = (value) => {
    setSearch(value);
    onChangeDebounced(value);
  };

  const onClick = (option) => {
    if (option.value !== filter) {
      onChangeFilter(option);
    }
  };

  const overlay = (
    <Menu className={styles.overlay}>
      {filters.map((option) => (
        <MenuItem
          color="primaryDark"
          key={option.value}
          onClick={() => onClick(option)}>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );

  const option = filters.find((o) => o.value === filter);

  const filtersCN = classnames({
    [styles.hasFilters]: Boolean(search) || Boolean(filter),
  });

  return (
    <>
      <PanelHeader
        actions={
          <Spacer>
            <IconButton
              className={filtersCN}
              onClick={filtersState.toggle}
              title={t('requirementsPage.search')}>
              <FilterIcon color={vars.textPrimary} size={20} />
            </IconButton>
            <IconButton
              onClick={onAddRequirement}
              title={t('requirementsPage.create')}>
              <AddIcon color={vars.textPrimary} size={20} />
            </IconButton>
          </Spacer>
        }
        title={t('requirementsPage.title')}
      />
      <Expandable
        className={styles.filters}
        expanded={filtersState.isOpen}
        trigger={0.8}>
        <Dropdown
          className={styles.requirementsFilter}
          classNameOverlay={styles.overlay}
          overlay={overlay}
          stretch="width">
          <div className={styles.selected}>
            <FilterIcon color={vars.accentMain} size={24} />
            <div className={styles.content}>
              <div className={styles.filterStatus}>
                {t('requirements.filter')}
              </div>
              <div className={styles.option}>{option?.label}</div>
            </div>
            <ArrowDownIcon color={vars.textPrimary} />
          </div>
        </Dropdown>

        <InputSearch
          autoFocus
          fullWidth
          onChange={handleChangeSearch}
          showLabel={false}
          value={search}
        />
      </Expandable>
    </>
  );
}
