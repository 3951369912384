import React, { useCallback } from 'react';
import { BatchService } from '@testquality/sdk';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getEnv } from 'src/env';
import { billingContactFetchManyThunk } from 'src/gen/domain/billing_contact/billingContactThunk';
import { invoiceFetchManyThunk } from 'src/gen/domain/invoice/invoiceThunk';
import { useAppDispatch } from '@bitmodern/redux/store';
import useFetch from 'src/hooks/useFetch';
import BillingView from './BillingView';

export default function BillingViewContainer() {
  const dispatch = useAppDispatch();

  const billingFetch = useCallback(() => {
    const batch = new BatchService();
    dispatch(billingContactFetchManyThunk({ batch, params: { per_page: -1 } }));
    dispatch(invoiceFetchManyThunk({ batch, params: { per_page: -1 } }));
    return batch.executeBatch();
  }, [dispatch]);

  const { isLoading } = useFetch(billingFetch, 'billing');

  return (
    // @ts-expect-error
    <Elements stripe={loadStripe(getEnv().stripeKey)}>
      <BillingView loading={isLoading} />
    </Elements>
  );
}
