import { createSelector } from 'reselect';
import { State } from '@bitmodern/redux/store';
import { Label } from '@testquality/sdk';
import { labelSelectors } from 'src/gen/domain/label/labelSelector';
import { labelAssignedByTestSelector } from '../label_assigned/selectors';

export function labelsSelector(state: State) {
  return labelSelectors
    .selectAll(state)
    .slice()
    .sort(({ label: a }, { label: b }) =>
      a.toLowerCase() < b.toLocaleLowerCase() ? -1 : 1,
    );
}

export function labelSelector(state: State, id: number): Label | undefined {
  return labelSelectors.selectById(state, id);
}

export const labelsByTestSelector = createSelector(
  [
    (state: State) => state.gen.label.entities,
    (state: State, testId: number) =>
      labelAssignedByTestSelector(state, { testId }),
  ],
  (label, labelAssigned) =>
    labelAssigned.map((a) => label[a.label_id] as Label),
);
