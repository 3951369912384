/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { SharedPrecondition } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { sharedPreconditionAdapter } from './sharedPreconditionAdapter';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
  sharedPreconditionDeleteOneThunk,
  sharedPreconditionUpdateOneThunk,
  sharedPreconditionCreateOneThunk,
} from './sharedPreconditionThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const sharedPreconditionSlice = createSlice({
  name: 'sharedPrecondition',
  initialState: sharedPreconditionAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    sharedPreconditionCleared(state) {
      state.loading = 'idle';
      sharedPreconditionAdapter.removeAll(state);
    },
    sharedPreconditionUpsertMany(
      state,
      action: PayloadAction<
        SharedPrecondition[] | Record<number, SharedPrecondition>
      >,
    ) {
      sharedPreconditionAdapter.upsertMany(state, action.payload);
    },
    sharedPreconditionUpsertOne(
      state,
      action: PayloadAction<SharedPrecondition>,
    ) {
      sharedPreconditionAdapter.upsertOne(state, action.payload);
    },
    sharedPreconditionUpdateOne(
      state,
      action: PayloadAction<Update<SharedPrecondition>>,
    ) {
      sharedPreconditionAdapter.updateOne(state, action.payload);
    },
    sharedPreconditionRemoveOne(state, action: PayloadAction<number>) {
      sharedPreconditionAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedPreconditionFetchManyThunk.pending, handlePending);
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        sharedPreconditionAdapter.upsertMany(
          state,
          action.payload.sharedPrecondition,
        );
      },
    );
    builder.addCase(sharedPreconditionFetchManyThunk.rejected, handleRejected);
    builder.addCase(sharedPreconditionFetchOneThunk.pending, handlePending);
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        sharedPreconditionAdapter.upsertOne(
          state,
          action.payload.sharedPrecondition[0],
        );
      },
    );
    builder.addCase(sharedPreconditionFetchOneThunk.rejected, handleRejected);
    builder.addCase(sharedPreconditionDeleteOneThunk.pending, handlePending);
    builder.addCase(
      sharedPreconditionDeleteOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        if (action.payload.id) {
          sharedPreconditionAdapter.removeOne(state, action.payload.id);
        }
      },
    );
    builder.addCase(sharedPreconditionDeleteOneThunk.rejected, handleRejected);
    builder.addCase(
      sharedPreconditionUpdateOneThunk.pending,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          performOptimisticUpdate(state, sharedPreconditionAdapter, {
            id: action.meta.arg.id?.toString(),
            changes: action.meta.arg.data,
          });
        } else {
          handlePending(state);
        }
      },
    );
    builder.addCase(
      sharedPreconditionUpdateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        sharedPreconditionAdapter.updateOne(state, action.payload);
      },
    );
    builder.addCase(
      sharedPreconditionUpdateOneThunk.rejected,
      (state, action) => {
        if (action.meta.arg.optimistic) {
          revertOptimisticUpdate(
            state,
            sharedPreconditionAdapter,
            action.meta.arg.id?.toString(),
          );
        } else {
          handleRejected(state, action);
        }
      },
    );
    builder.addCase(sharedPreconditionCreateOneThunk.pending, handlePending);
    builder.addCase(
      sharedPreconditionCreateOneThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        sharedPreconditionAdapter.upsertOne(state, action.payload);
      },
    );
    builder.addCase(sharedPreconditionCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  sharedPreconditionCleared,
  sharedPreconditionUpsertMany,
  sharedPreconditionUpsertOne,
  sharedPreconditionUpdateOne,
  sharedPreconditionRemoveOne,
} = sharedPreconditionSlice.actions;

export const sharedPreconditionReducer = sharedPreconditionSlice.reducer;
