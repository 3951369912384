import { suiteGetMany } from '@testquality/sdk';
import { useFormik } from 'formik';
import React, { ComponentProps, useCallback, useMemo } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import {
  Button,
  Dialog,
  DialogContent,
  PanelActions,
  Select,
} from '@bitmodern/bit-ui';
import useFetch from 'src/hooks/useFetch';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { routes } from 'src/components/Router';
import styles from './CloneDialog.module.scss';

type Values = {
  project?: number;
  suite?: number;
};

type Props = {
  projectId?: number;
  onClone: ({ project, suite }: Values) => Promise<any>;
} & Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'>;

export default function CloneDialog({
  isOpen,
  onClone,
  onClose,
  projectId,
}: Props) {
  const { t } = useTranslation();
  const params = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const projects = useAppSelector(projectsSelector);

  const currentProject = parseInt(params.projectId, 10);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        project: Yup.number().required().label('clone.form.project'),
        suite: Yup.number()
          .label('clone.form.suite')
          .when('project', {
            is: (project) => project !== currentProject,
            then: Yup.number().required(),
          }),
      }),
    [currentProject],
  );

  const formik = useFormik<Values>({
    initialValues: {
      project: projectId || currentProject,
      suite: undefined,
    },
    onSubmit,
    validationSchema,
  });

  const omitSuite = currentProject !== formik.values.project;

  async function onSubmit(values: Values) {
    if (onClone) {
      return onClone(values).finally(onClose);
    }
    return Promise.resolve();
  }

  const fetchSuites = useCallback(() => {
    if (!formik.values.project || !isOpen) return Promise.resolve([]);
    return suiteGetMany({
      params: { project_id: formik.values.project, per_page: -1 },
    }).then((res) => res.data);
  }, [formik.values.project, isOpen]);

  const suitesFetch = useFetch(
    fetchSuites,
    `suitesPlan-${formik.values.project}`,
    { initialData: [] },
  );

  const onChangeProject = (value) => {
    formik.setValues({ project: value, suite: undefined });
  };

  const onChangeSuite = (value) => formik.setFieldValue('suite', value);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={t('clone.title')}>
      <DialogContent className={styles.content}>
        <form
          id="cloneDialog"
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}>
          <Select
            error={formikError(formik, 'project')}
            label={t('clone.form.project')}
            name="project"
            onChange={onChangeProject}
            onFocus={formik.handleBlur}
            options={projects.map((project) => ({
              label: project.name,
              value: project.id,
            }))}
            placeholder={t('clone.form.projectPlaceholder')}
            required
            value={formik.values.project}
          />

          {omitSuite && (
            <Select
              error={formikError(formik, 'suite')}
              label={t('clone.form.suite')}
              loading={suitesFetch.isLoading}
              name="suite"
              onChange={onChangeSuite}
              onFocus={formik.handleBlur}
              options={suitesFetch.data.map((suite) => ({
                label: suite.name,
                value: suite.id,
              }))}
              placeholder={t('clone.form.suitePlaceholder')}
              required
              value={formik.values.suite}
            />
          )}
        </form>
      </DialogContent>
      <PanelActions>
        <Button form="cloneDialog" loading={formik.isSubmitting} type="submit">
          {t('clone.form.submit')}
        </Button>
      </PanelActions>
    </Dialog>
  );
}
