import React from 'react';
import {
  Button,
  Content,
  DialogContent,
  PanelActions,
  PanelHeader,
  Spacer,
  Surface,
} from '@bitmodern/bit-ui';
import { DangerIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import styles from './MigrationDialog.module.scss';

type Props = {
  migrationLoading: boolean;
  onMigrate: () => void;
};

export default function MigrationDialog({
  migrationLoading,
  onMigrate,
}: Props) {
  return (
    <Content fullHeight className={styles.migration}>
      <Surface className={styles.surface}>
        <PanelHeader
          title={
            <div className={styles.title}>
              <DangerIcon
                className={styles.titleIcon}
                color={vars.textPrimary}
              />
              Project Migration
            </div>
          }
        />
        <DialogContent className={styles.content}>
          <p>
            In order to use the new TestQuality UI for this project, given we
            have made improvements to the way Runs now works, a migration must
            be performed.
          </p>
          <p>
            This is one way migration for this project and the project will not
            be useable in the old the UI. As an alternative we can create a demo
            project for you to try. If you are not ready please navigate to
            another project.
          </p>
        </DialogContent>

        <PanelActions>
          <Spacer>
            <Button loading={migrationLoading} onClick={onMigrate}>
              Migrate Project
            </Button>
          </Spacer>
        </PanelActions>
      </Surface>
    </Content>
  );
}
