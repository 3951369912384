import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { routes } from 'src/components/Router';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { projectsSelector } from 'src/packages/redux/state/projects/selectors';
import { useAppSelector } from 'src/packages/redux/store';

export default function ProjectViewContainer() {
  const { site, projectId: projectIdUrl } =
    useParams<typeof routes.PROJECT.params>();
  const projectId = parseInt(projectIdUrl, 10);
  const user = useAppSelector(currentUserSelector);
  const projects = useAppSelector(projectsSelector);
  const history = useHistory();

  const hasCompletedIntro = Boolean(user?.settings?.has_completed_intro);

  useEffect(() => {
    if (projects.length === 0 || !hasCompletedIntro) {
      history.replace(routes.GET_STARTED({ site }));
    } else if (!projectId && projects.length > 0) {
      const lastProject = localStorage.getItem('lastProject');
      if (
        lastProject &&
        projects.find((project) => project.id.toString() === lastProject)
      ) {
        history.replace(routes.PROJECT({ site, projectId: lastProject }));
      } else {
        history.replace(
          routes.PROJECT({ site, projectId: projects[0].id.toString() }),
        );
      }
    }
  }, [projects, hasCompletedIntro, site, projectId, history]);

  return null;
}
