import handlebars from 'handlebars';
import showdown from 'showdown';
import { RunResultStep, Status, Step, Test } from '@testquality/sdk';
import { IntegrationType } from 'src/enums/IntegrationEnums';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import { getRawStatusColor } from './statusHelper';

const showdownConverter = new showdown.Converter();
showdownConverter.setFlavor('github');
showdownConverter.setOption('tables', true);

export function getStatus(statusList: Status[], status_id: number): Status {
  const status = statusList.find((s) => s.id === status_id) as Status;
  if (!status) {
    throw new Error(`Could not find status ${status_id}`);
  }
  return {
    ...status,
    color: getRawStatusColor(status),
  };
}

export function htmlToMd(src?: string) {
  // first check
  if (src && src.startsWith('<p>', 0)) {
    let md = showdownConverter.makeMarkdown(src);
    md = md.replace(/<!--[\s\S]*?-->/g, '');
    return md;
  }
  return src;
}

handlebars.registerHelper(
  'add',
  function handler(originalNr: number, nrToAdd: number = 0) {
    return originalNr + nrToAdd;
  },
);

export const defaultGithubDefectDescriptionTemplate = `{{#if run_result.test.precondition}}
## Precondition
{{run_result.test.precondition}}
{{/if}}

## Steps to Reproduce:
{{#each run_result.steps}}
### Step {{add @index 1}} <span style="color:{{status.color}}"> **[{{status.name}}]** </span>
{{step.step}}

{{#if step.expected_result}}
#### Expected Result
{{step.expected_result}}

{{/if}}
{{#if result}}
#### Actual Result
{{result}}
{{/if}}
{{/each}}`;

export const defaultGithubRequirementDescriptionTemplate = `{{#if test.precondition}}
## Precondition
{{test.precondition}}
{{/if}}
## Steps to Reproduce:
{{#each test.steps}}
### Step {{add @index 1}}
{{step}}

{{#if expected_result}}
#### Expected Result
{{expected_result}}
{{/if}}
{{/each}}`;

export const defaultJIRADefectDescriptionTemplate = `{{#if run_result.test.precondition}}
Precondition
{{run_result.test.precondition}}
{{/if}}

Steps to Reproduce:
{{#each run_result.steps}}
Step {{add @index 1}}[{{status.name}}]
{{step.step}}

{{#if step.expected_result}}
Expected Result
{{step.expected_result}}
{{/if}}
{{#if result}}

Actual Result
{{result}}
{{/if}}
{{/each}}`;

export const defaultJIRARequirementDescriptionTemplate = `{{#if test.precondition}}
Precondition
{{test.precondition}}
{{/if}}
Steps to Reproduce:
{{#each test.steps}}
Step {{add @index 1}}
{{step}}

{{#if expected_result}}
Expected Result
{{expected_result}}
{{/if}}
{{/each}}`;

export function getDefaultIntegrationDescriptionTemplate(
  baseIntegrationType: IntegrationType,
  capability: CapabilityEnum,
): string {
  if (baseIntegrationType === IntegrationType.JIRA) {
    if (capability === CapabilityEnum.Defect)
      return defaultJIRADefectDescriptionTemplate;
    return defaultJIRARequirementDescriptionTemplate;
  }

  if (baseIntegrationType === IntegrationType.GITHUB) {
    if (capability === CapabilityEnum.Defect)
      return defaultGithubDefectDescriptionTemplate;
    return defaultGithubRequirementDescriptionTemplate;
  }

  return '';
}

function getRunResultStep(steps: Step[], runResultStepId: number) {
  const resultStep = steps.find((step) => step.id === runResultStepId);

  return {
    ...resultStep,
    step: htmlToMd(resultStep?.step),
    expected_result: htmlToMd(resultStep?.expected_result),
  } as Step;
}

export function buildIntegrationDescription(
  inputTest: Test | undefined,
  steps: Step[],
  runResultSteps: RunResultStep[],
  statusList: Status[],
  baseIntegrationType: IntegrationType,
  capability: CapabilityEnum,
  inputTemplate: string | undefined = getDefaultIntegrationDescriptionTemplate(
    baseIntegrationType,
    capability,
  ),
) {
  let resultTemplate = '';
  if (inputTest) {
    if (capability === CapabilityEnum.Defect) {
      const run_result = {
        test: { ...inputTest, precondition: htmlToMd(inputTest.precondition) },
        steps: runResultSteps.map((runResultStep) => ({
          ...runResultStep,
          result: htmlToMd(runResultStep?.result),
          status: getStatus(statusList, runResultStep.status_id),
          step: getRunResultStep(steps, runResultStep.step_id),
        })),
      };
      resultTemplate = handlebars.compile(inputTemplate)({ run_result });
    } else {
      const test = {
        ...inputTest,
        precondition: htmlToMd(inputTest.precondition),
        steps: steps.map((step) => ({
          step: htmlToMd(step?.step),
          expected_result: htmlToMd(step?.expected_result),
        })),
      };
      resultTemplate = handlebars.compile(inputTemplate)({ test });
    }
  }
  return resultTemplate;
}
