/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Invoice,
  invoiceGetMany,
  invoiceGetOne,
  invoiceDeleteOne,
  invoiceUpdateOne,
  invoiceCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const invoiceFetchManyThunk = createAsyncThunk(
  'invoice/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Invoice>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await invoiceGetMany(inlineArgs);
      return {
        invoice: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const invoiceFetchOneThunk = createAsyncThunk(
  'invoice/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Invoice>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'invoice/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await invoiceGetOne(arg.id as number, arg);
      return {
        invoice: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const invoiceDeleteOneThunk = createAsyncThunk(
  'invoice/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Invoice>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'invoice/DeleteOneThunk');
      }
      return await invoiceDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const invoiceUpdateOneThunk = createAsyncThunk(
  'invoice/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Invoice>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'invoice/UpdateOneThunk');
      }
      const data = await invoiceUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Invoice>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Invoice>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const invoiceCreateOneThunk = createAsyncThunk(
  'invoice/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Invoice>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'invoice/CreateOneThunk',
        );
      }
      return await invoiceCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
