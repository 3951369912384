import React, { ReactNode, useCallback, useRef } from 'react';
import { RangePicker } from 'rc-picker';
import classnames from 'classnames';
import { format } from 'date-fns';
import moment, { Moment } from 'moment';
import en from 'rc-picker/es/locale/en_US';
import momentGenerateConfig from 'rc-picker/es/generate/moment';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import 'rc-picker/assets/index.css';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import styles from './InputDateRange.module.scss';
import { DoneIcon } from '../icons';

export type Range<D> = [D | null, D | null] | null | undefined;

const today = new Date();
today.setHours(23, 59, 59, 999);

const dateMinusDays = (date: Date, days: number) => {
  date.setDate(today.getDate() - days);
  date.setHours(0, 0, 0);
  return date;
};

const rangeToString = (range: Range<Date>): string => {
  if (!range || !range.length) return '';

  return `${range[0]?.toDateString()} / ${range[1]?.toDateString()}`;
};
type Ranges = Array<{ label: string; value: Range<Date>; timeframe: string }>;

export const defaultTimeranges: Ranges = [
  {
    label: 'Last 7 Days',
    value: [dateMinusDays(new Date(), 7), today],
    timeframe: '7days',
  },
  {
    label: 'Last 30 Days',
    value: [dateMinusDays(new Date(), 30), today],
    timeframe: '30days',
  },
  {
    label: 'Last 3 Months',
    value: [dateMinusDays(new Date(), 90), today],
    timeframe: '3months',
  },
  {
    label: 'Last 6 Months',
    value: [dateMinusDays(new Date(), 180), today],
    timeframe: '6months',
  },
];

export const dateRangeByTimeframe = (timeframe?: string) => {
  return defaultTimeranges.find((dr) => dr.timeframe === timeframe)?.value;
};

type Props = {
  className?: string;
  error?: ReactNode;
  label?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: Range<Date>, timeframe?: string) => void;
  onFocus?: () => void;
  selected?: boolean;
  showLabel?: boolean;
  value?: Range<Date>;
};

export default function InputDateRange({
  className = '',
  error,
  label,
  onChange,
  selected = false,
  showLabel,
  value,
  ...rest
}: Props) {
  const ref = useRef<any>(null);

  const onClickRange = useCallback(
    (range) => {
      if (onChange) {
        onChange(range.value, range.timeframe);
        if (ref.current) {
          ref.current.blur();
        }
      }
    },
    [onChange],
  );

  const handleChange = useCallback(
    (newValue) => {
      if (!onChange) return;

      if (!newValue) {
        onChange(newValue);
      } else {
        let start: Date | null = null;
        if (newValue[0]) {
          start = newValue[0].toDate();
          start?.setHours(0, 0, 0);
        }
        let end: Date | null = null;
        if (newValue[1]) {
          end = newValue[1].toDate();
          end?.setHours(23, 59, 59, 999);
        }
        onChange([start, end]);
      }
    },
    [onChange],
  );
  const toMoment = (date?: Date | null) => (date ? moment(date) : null);

  const dateRange: Range<Moment> = value
    ? [toMoment(value[0]), toMoment(value[1])]
    : null;

  const defaultSelected = defaultTimeranges.find(
    (range) => rangeToString(value) === rangeToString(range.value),
  );

  const dateRangeCN = classnames(styles.dateRange, className, {
    [styles.selected]: selected,
    [styles.hidePicker]: defaultSelected,
  });

  return (
    <>
      {showLabel && <FieldLabel>{label}</FieldLabel>}
      <RangePicker<Moment>
        className={dateRangeCN}
        generateConfig={momentGenerateConfig as any}
        inputReadOnly
        ref={ref}
        format={'MM/DD/YYYY'}
        locale={en}
        onChange={handleChange}
        placeholder={['Start date', 'End date']}
        separator={defaultSelected ? defaultSelected.label : '-'}
        panelRender={(originalPanel) => (
          <div className={styles.panel}>
            <div className={styles.ranges}>
              <div className={styles.rangeTitle}>Dates range</div>
              {defaultTimeranges.map((range, i) => {
                const isSelected =
                  rangeToString(value) === rangeToString(range.value);
                return (
                  <div
                    className={styles.range}
                    key={i} // eslint-disable-line react/no-array-index-key
                    onClick={() => onClickRange(range)}>
                    {range.label}
                    {isSelected && (
                      <DoneIcon
                        className={styles.doneIcon}
                        color={vars.successMain}
                        size={18}
                      />
                    )}
                  </div>
                );
              })}
              <div className={styles.datePreview}>
                <div className={styles.date}>
                  <div className={styles.dateTitle}>From</div>
                  {value?.[0] && <div>{format(value[0], 'MM/dd/yyyy')}</div>}
                </div>
                <div className={styles.date}>
                  <div className={styles.dateTitle}>To</div>
                  {value?.[1] && <div>{format(value[1], 'MM/dd/yyyy')}</div>}
                </div>
              </div>
            </div>

            {originalPanel}
          </div>
        )}
        value={dateRange}
        {...rest}
      />
      {error && <FieldError className={styles.error}>{error}</FieldError>}
    </>
  );
}

InputDateRange.defaultTimeranges = defaultTimeranges;
