export enum StatusEnum {
  Pass = 1,
  Pending = 2,
  Fail = 3,
  Block = 4,
  Retest = 5,
  Skip = 6,
}

export enum StatusTypeEnum {
  Positive = 1,
  Neutral = 2,
  Negative = 3,
}
