import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import React, { ComponentProps, useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  userFetchOneThunk,
  userUpdateOneThunk,
} from 'src/gen/domain/user/userThunk';
import { postFile } from '@bitmodern/services/FileService';
import { AccessRoleUser, showNotificationError, User } from '@testquality/sdk';
import { accessRolesSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import { accessRoleUsersByUserSelector } from '@bitmodern/redux/state/accessRolesUsers/selectors';
import { accessRoleUserRemoveOne } from 'src/gen/domain/access_role_user/accessRoleUserSlice';
import UserForm from './UserForm';
import { processAttachmentPath } from '../../../utils/fileHelper';

type UserFormProps = ComponentProps<typeof UserForm>;
type InitialValues = UserFormProps['formik']['values'];

type Props = {
  user: User;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('userForm.form.email'),
  accessRoles: Yup.array().min(1).label('userForm.form.email.accessRoles'),
});

export default function UserFormContainer({ user }: Props) {
  const dispatch = useAppDispatch();
  const accessRoles = useAppSelector(accessRolesSelector);
  const accessRoleUsers = useAppSelector((state) => {
    return accessRoleUsersByUserSelector(state, user.id);
  });

  const initialValues = useMemo(
    () => ({
      email: user.email || '',
      picture: processAttachmentPath(user.picture),
      family_name: user.family_name || '',
      given_name: user.given_name || '',
      lastViewed: 0,
      searchHistory: 0,
      accessRoles: accessRoleUsers.map((a) => a.access_role_id),
      password: '',
      passwordConfirm: '',
    }),
    [accessRoleUsers, user],
  );

  const onSubmit = useCallback(
    async (values) => {
      const picture = values.picture;
      const access_role: any = values.accessRoles.map((r) => ({ id: r }));

      const requests: Array<Promise<any>> = [];
      if (picture instanceof File) {
        requests.push(
          postFile(picture, user.id, 'User', 'picture').catch(
            showNotificationError,
          ),
        );
      }

      requests.push(
        dispatch(
          userUpdateOneThunk({
            id: user.id,
            data: {
              access_role,
              email: values.email,
              family_name: values.family_name,
              given_name: values.given_name,
            },
          }),
        )
          .then(unwrapResult)
          .then(() => {
            return dispatch(
              userFetchOneThunk({
                id: user.id,
                params: { _with: 'accessRole' },
              }),
            );
          })
          .then(unwrapResult)
          .then((res) => {
            const newAccessRoleUsers =
              (res.accessRoleUser as AccessRoleUser[]) || [];
            accessRoleUsers
              .filter((aru) => {
                return !newAccessRoleUsers.some((a) => a.id === aru.id);
              })
              .forEach((aru) => {
                dispatch(accessRoleUserRemoveOne(aru.id));
              });
          }),
      );

      return Promise.all(requests);
    },
    [accessRoleUsers, dispatch, user],
  );

  const formik = useFormik<InitialValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return <UserForm accessRoles={accessRoles} formik={formik} />;
}
