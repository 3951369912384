import { AppThunk } from '@bitmodern/redux/store';
import {
  showNotificationError,
  HttpError,
  Defect,
  BatchService,
  User,
  DefectStatus,
  Status,
  Run,
  Test,
  RunResultHistory,
  RunResult,
} from '@testquality/sdk';
import { defectHistoryFetchThunk } from 'src/gen/domain/defect/defectHistoryThunk';
import { runResultHistoryFetchThunk } from 'src/gen/domain/run_result/runResultHistoryThunk';

type DiffType = {
  status_id?: number;
  defect_status_id?: number;
};

type BaseEvent<ItemType> = {
  id: string;
  user?: User;
  updatedAt: string;
  operation: string;
  itemType: ItemType;
};

interface EventUpdateType<Status, ItemType> extends BaseEvent<ItemType> {
  diffs: DiffType;
  status: Status;
  statusDiff: Status;
}

export type DefectCreateEvent = BaseEvent<'defectCreate'>;

export type DefectUpdateEvent = EventUpdateType<DefectStatus, 'defectUpdate'>;

export type RunResultEvent = EventUpdateType<Status, 'runResult'> & {
  run?: Run;
  test?: Test;
};

export type EventType = DefectCreateEvent | DefectUpdateEvent | RunResultEvent;

export function getDefectEventsThunk(
  defect?: Defect,
  runResults?: RunResult[],
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      const defectHistory = await dispatch(
        defectHistoryFetchThunk({
          id: defect?.id,
        }),
      );

      const batch = new BatchService();
      runResults?.forEach((rr) => {
        dispatch(
          runResultHistoryFetchThunk({
            params: { id: rr.id },
            batch,
          }),
        );
      });

      let runResultsHistory: RunResultHistory[] = [];
      const { responses } = await batch.executeBatch();

      if (responses[0]) {
        responses[0].forEach((response) => {
          if (response.data.length) {
            runResultsHistory = runResultsHistory.concat(response.data);
          }
        });
      }

      return { defectHistory, runResultsHistory };
    } catch (error) {
      showNotificationError(error as HttpError);
      throw error;
    }
  };
}
