export enum RelatedType {
  TEST = 'App\\Models\\Test',
  RUN_RESULT = 'App\\Models\\RunResult',
  PLAN = 'App\\Models\\Plan',
  SUITE = 'App\\Models\\Suite',
  PROJECT = 'App\\Models\\Project',
  DEFECT = 'App\\Models\\Defect',
  INVOICE = 'App\\Models\\Invoice',
  MILESTONE = 'App\\Models\\Milestone',
}
