/* eslint-disable react/require-default-props */
import React, { ComponentProps, useCallback, useRef } from 'react';
import { AriaTextFieldOptions, useTextField, mergeProps } from 'react-aria';
import Picker from 'rc-picker';
import moment, { Moment } from 'moment';
import en from 'rc-picker/es/locale/en_US';
import momentGenerateConfig from 'rc-picker/es/generate/moment';
import mergeRefs from 'react-merge-refs';
import 'rc-picker/assets/index.css';
import Field, { useFieldProps } from '../Field';
import styles from './InputDate.module.scss';

type FieldProps = Omit<ComponentProps<typeof Field>, 'children' | 'labelProps'>;
type InputProps = Omit<AriaTextFieldOptions<'input'>, 'value'> &
  FieldProps & {
    onChange?: (date: Date) => void;
    value?: Date;
  };

const InputDate = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const { className = '', onChange, value, ...rest } = props;

    const innerRef = useRef<HTMLInputElement>(null);
    const { labelProps, inputProps } = useTextField(rest, innerRef);
    const fieldProps = useFieldProps(props);

    const handleChange = useCallback(
      (momentValue) => {
        if (onChange) {
          onChange(momentValue.toDate());
        }
      },
      [onChange],
    );

    return (
      <Field labelProps={labelProps} {...fieldProps}>
        {(borderProps, fieldRef) => (
          <Picker<Moment>
            {...mergeProps(inputProps, borderProps)}
            className={`${styles.date} ${className}`}
            generateConfig={momentGenerateConfig}
            inputReadOnly
            locale={en}
            onChange={handleChange}
            ref={mergeRefs([innerRef, ref, fieldRef])}
            value={value ? moment(value) : undefined}
          />
        )}
      </Field>
    );
  },
);

export default InputDate;
