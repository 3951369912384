import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Chip,
  MarkdownView,
  Grid,
  IconButton,
  PanelActions,
  PanelHeader,
  Spacer,
  Surface,
  Tab,
  Tabs,
} from '@bitmodern/bit-ui';
import useParams from 'src/hooks/useParams';
import {
  BugIcon,
  CancelIcon,
  ChevronLeftIcon,
  TestIcon,
} from '@bitmodern/bit-ui/icons';
import {
  Activity,
  Attachments,
  CommentCreate,
  CustomProperties,
  Requirements,
  RunResultsAndDefects,
  RunResultStep,
  StatusSelect,
  UserSelect,
} from 'src/components/organisms';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import { routes } from 'src/components/Router';
import useDrawerManager from 'src/hooks/useDrawerManager';
import { useQuery } from 'src/hooks/useQuery';
import useRunResultView from './useRunResultView';
import { RunResults } from './RunResults';
import styles from './RunResultView.module.scss';
import { getUsersMap } from '../../../utils/fileHelper';
import useMutation from '../../../hooks/useMutation';

type Props = {
  runResultId: number;
};

export default function RunResultView({ runResultId }: Props) {
  const { site, projectId } = useParams<typeof routes.PROJECT.path>();
  const queryStrings = useQuery(['activeTab', 'resultId']);
  const { closeDrawer, openDrawer } = useDrawerManager();
  const view = useRunResultView({
    projectId: parseInt(projectId, 10),
    runResultId,
    site,
  });

  const {
    activity,
    casePriority,
    caseType,
    defects,
    dataSet,
    isLoadingRuns,
    nextRunResult,
    onAddDefect,
    onAddRequirement,
    onChangeRunResult,
    onChangeRunResultStatus,
    onChangeStepElapsed,
    onChangeStepResult,
    onChangeStepStatus,
    onComment,
    onDeleteComment,
    onEditComment,
    plan,
    previousRunResult,
    requirements,
    run,
    runResult,
    currentRunResults,
    runResults,
    runResultStatus,
    runResultSteps,
    steps,
    test,
    testLabels,
    users,
  } = view;

  const { t } = useTranslation();
  const content = useRef<HTMLDivElement>(null);
  const [tabs, setTabs] = useState('overview');
  const location = useLocation<{ modalParentLocation?: string }>();
  const history = useHistory();

  const pushActiveTab = (value: string) => {
    history.push({
      pathname: location.pathname,
      search: `resultId=${queryStrings.resultId}&activeTab=${value}`,
    });
  };

  // This useEffects needs to be independent from one another because closing popups or opening sidemenus alter the url,
  // and that will trigger the use effect. This one may trigger but since we don't have the queryString nothing will happen
  // Trigger on tab change.
  useEffect(() => {
    if (queryStrings?.activeTab) {
      setTabs(queryStrings.activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  // Trigger only on page load to set default.
  useEffect(() => {
    pushActiveTab('overview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDefectMutation = useMutation(onAddDefect);

  const runResultIdValue = runResult?.id;
  useEffect(() => {
    if (content.current) {
      content.current.scrollTo({ top: 0 });
    }
  }, [runResultIdValue]);

  useEffect(() => {
    if (!runResult) {
      closeDrawer();
    }
  }, [closeDrawer, runResult]);

  const onChangeAssignedTo = (value) => {
    return onChangeRunResult({
      assigned_to_tester: value === '' ? null : value,
    });
  };

  const onClose = () => {
    closeDrawer();
  };

  const onNext = () => {
    if (nextRunResult) {
      openDrawer({ resultId: nextRunResult.id });
    }
  };

  const onPrevious = () => {
    if (previousRunResult) {
      openDrawer({ resultId: previousRunResult.id });
    }
  };

  const onOpenTest = () => {
    if (runResult) {
      openDrawer({ testId: runResult.test_id });
    }
  };

  const assignedToOptions = getUsersMap(users);

  const stepsToRender = steps;

  const timeSpent = runResultSteps.reduce((acu, r) => {
    // @ts-expect-error
    return acu + parseFloat(r.elapsed || 0);
  }, 0);

  if (!runResult || !test || !run) return null;

  return (
    <Surface className={styles.runResult}>
      <Helmet>
        <title>{test.name}</title>
      </Helmet>
      <PanelHeader
        title={test.name}
        actions={
          <Spacer>
            <IconButton
              onClick={onOpenTest}
              title={t('runResult.actions.openTest')}>
              <TestIcon color={vars.textPrimary} size={18} />
            </IconButton>
            <IconButton
              boxed={false}
              onClick={onClose}
              title={t('runResult.actions.close')}>
              <CancelIcon color={vars.textPrimary} size={22} />
            </IconButton>
          </Spacer>
        }
      />
      <div className={styles.panelContent} ref={content}>
        <Tabs
          tab={tabs}
          onChange={(value: string) => {
            pushActiveTab(value);
            setTabs(value);
          }}
          withBarBackground>
          <Tab id="overview">{t('runResult.tabs.overview')}</Tab>
          <Tab id="defects">{t('runResult.tabs.runsAndDefects')}</Tab>
          <Tab id="requirements">{t('runResult.tabs.requirements')}</Tab>
        </Tabs>

        {tabs === 'overview' && (
          <>
            <div className={styles.section}>
              <RunResults
                current={runResult.id}
                runResults={currentRunResults}
              />
              {test.precondition && (
                <>
                  <div className={styles.label}>
                    {t('runResult.overview.precondition')}
                  </div>
                  <MarkdownView markdown={test.precondition} read />
                </>
              )}

              {stepsToRender.length > 0 && (
                <>
                  <h3 className={styles.title}>
                    {t('runResult.overview.steps')}
                  </h3>
                  <div className={styles.testSteps}>
                    {stepsToRender.map((step) => {
                      const runResultStep = runResultSteps.find(
                        (r) => r.step_id === step.id,
                      );
                      return (
                        <RunResultStep
                          dataSet={dataSet}
                          dataSetRow={runResult.data_set_row}
                          disabled={run.is_complete}
                          onChangeStepElapsed={(elapsed) => {
                            if (runResultStep) {
                              onChangeStepElapsed(elapsed, runResultStep);
                            }
                          }}
                          onChangeStatus={(status) => {
                            if (runResultStep) {
                              onChangeStepStatus(status, runResultStep);
                            }
                          }}
                          onChangeStepResult={(result) => {
                            if (!runResultStep) return Promise.resolve();
                            return onChangeStepResult(result, runResultStep);
                          }}
                          key={step.id}
                          runResultStep={runResultStep}
                          step={step}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              <Grid.Row>
                <Grid.Col span={6}>
                  <UserSelect
                    label={t('runResult.overview.assignedTo')}
                    placeholder="Select"
                    allowClear
                    onChange={onChangeAssignedTo}
                    options={assignedToOptions}
                    value={runResult.assigned_to_tester}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <div className={styles.label}>
                    {t('runResult.overview.timeSpent')}
                  </div>
                  <span>
                    {t('runResult.overview.timeSpentCount', {
                      count: timeSpent,
                    })}
                  </span>
                </Grid.Col>
              </Grid.Row>

              <h3 className={styles.title}>
                {t('runResult.overview.attachments')}
              </h3>
              <Attachments parentType="runResult" parentId={runResult.id} />
            </div>

            <div className={styles.section}>
              <Grid.Row gutter={8}>
                <Grid.Col span={6}>
                  <div className={styles.label}>
                    {t('runResult.overview.caseType')}
                  </div>
                  <span>{caseType?.name}</span>
                </Grid.Col>
                <Grid.Col span={6}>
                  <div className={styles.label}>
                    {t('runResult.overview.casePriority')}
                  </div>
                  <span>{casePriority?.name}</span>
                </Grid.Col>
              </Grid.Row>
              <Checkbox
                className={styles.isAutomated}
                checked={test.is_automated}
                isReadOnly>
                {t('runResult.overview.isAutomated')}
              </Checkbox>

              {!plan.is_root && (
                <>
                  <div className={styles.label}>
                    {t('runResult.overview.plan')}
                  </div>
                  <Link
                    key={plan.id}
                    className={styles.plan}
                    to={routes.PLAN({
                      site,
                      projectId: projectId.toString(),
                      planId: plan.id.toString(),
                    })}>
                    <Chip>{plan.name}</Chip>
                  </Link>
                </>
              )}

              {testLabels.length > 0 && (
                <>
                  <div className={styles.label}>
                    {t('runResult.overview.labels')}
                  </div>
                  {testLabels.map((label) => {
                    if (label) {
                      return <Chip key={label.id}>{label.label}</Chip>;
                    }
                    return <></>;
                  })}
                </>
              )}
              {Object.keys(test.virtual || {}).length > 0 && (
                <h3 className={styles.title}>
                  {t('runResult.overview.customProperties')}
                </h3>
              )}
              <CustomProperties
                onChange={() => {}}
                model={test}
                inputProps={{ isReadOnly: true }}
              />
            </div>
            <div className={styles.section}>
              {activity.length > 0 && (
                <Activity
                  items={activity}
                  onDeleteComment={onDeleteComment}
                  onEditComment={onEditComment}
                />
              )}
              <CommentCreate
                onComment={onComment}
                parentId={runResult.id}
                parentType="RunResult"
              />
            </div>
          </>
        )}
        {tabs === 'defects' && (
          <RunResultsAndDefects
            defects={defects}
            loading={isLoadingRuns}
            runResults={runResults}
          />
        )}
        {tabs === 'requirements' && (
          <Requirements
            onAddRequirement={onAddRequirement}
            requirements={requirements}
          />
        )}
      </div>
      {(tabs === 'overview' || tabs === 'defects') && (
        <PanelActions className={styles.panelActions}>
          <StatusSelect
            disabled={run.is_complete}
            onChange={onChangeRunResultStatus}
            value={runResultStatus?.id}
          />
          <Spacer>
            <Button
              color="primaryDark"
              loading={addDefectMutation.isLoading}
              onClick={addDefectMutation.mutate}
              icon={<BugIcon color={vars.textPrimary} size={20} />}>
              {t('runResult.footer.logDefect')}
            </Button>
            {previousRunResult && (
              <IconButton
                color="primaryDark"
                size="large"
                onClick={onPrevious}
                title={t('runResult.footer.previousTest')}>
                <ChevronLeftIcon color={vars.textPrimary} size={24} />
              </IconButton>
            )}
            {nextRunResult && (
              <Button color="accent" onClick={onNext}>
                {t('runResult.footer.nextTest')}
              </Button>
            )}
          </Spacer>
        </PanelActions>
      )}
    </Surface>
  );
}
