import { createSelector } from 'reselect';
import { invoiceSelectors } from 'src/gen/domain/invoice/invoiceSelector';
import { State } from '@bitmodern/redux/store';

export function invoicesSelector(state: State) {
  return invoiceSelectors.selectAll(state);
}

export const invoicesSortedSelector = createSelector(
  invoicesSelector,
  (invoices) => {
    return [...invoices].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  },
);
