import React from 'react';
import styles from './DefectEventsItemDefectCreate.module.scss';
import { TableCell, TableRow } from '@bitmodern/bit-ui';
import { useTranslation } from 'react-i18next';
import { DefectCreateEvent } from 'src/packages/redux/state/defectHistory/thunks';
import { InfoIcon } from 'src/packages/bit-ui/icons';
import vars from 'src/export.scss';
import { ReporterCell } from '../ReporterCell';

type DefectEventsItemDefectCreateProps = {
  event: DefectCreateEvent;
};

export function DefectEventsItemDefectCreate({
  event,
}: DefectEventsItemDefectCreateProps) {
  const { t } = useTranslation();

  return (
    <TableRow className={styles.row} selected>
      <TableCell className={styles.statusDefect} colSpan={2}>
        <div className={styles.statusCell}>
          <div className={styles.icon}>
            <InfoIcon color={vars.textPrimary} size={36} />
          </div>

          <div className={styles.createdText}>
            {t('defectEvent.defectCreation')}
          </div>
        </div>
      </TableCell>

      <ReporterCell event={event} />
    </TableRow>
  );
}
