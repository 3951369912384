import React, {
  ComponentProps,
  useCallback,
  ReactNode,
  useRef,
  forwardRef,
} from 'react';
import classnames from 'classnames';
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useFocusRing,
} from 'react-aria';
import styles from './IconButton.module.scss';
import Tooltip from '../Tooltip';
import Loading from '../Loading';

type Props = Omit<ComponentProps<'button'>, 'isDisabled'> &
  Exclude<AriaButtonProps, 'onPress'> & {
    boxed?: boolean;
    isLoading?: boolean;
    children: ReactNode;
    color?:
      | 'accent'
      | 'success'
      | 'main'
      | 'primaryDark'
      | 'transparent'
      | 'error';
    onClick?: () => void;
    size?: 'small' | 'medium' | 'large';
    title?: string;
    type?: 'button' | 'submit' | 'reset';
  };

function IconButton(
  {
    boxed = true,
    children,
    className,
    color,
    onClick,
    isLoading = false,
    size = 'medium',
    title,
    ...props
  }: Props,
  ref,
) {
  const { disabled } = props;

  const onPress = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const buttonRef = useRef(ref);
  const { buttonProps } = useButton(
    { ...props, isDisabled: disabled || isLoading, onPress },
    buttonRef,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const butonCN = classnames(styles.iconButton, className, {
    [styles.boxed]: boxed,
    [styles.disabled]: disabled,
    [styles.focused]: isFocusVisible,
    [styles.success]: color === 'success',
    [styles.accent]: color === 'accent',
    [styles.main]: color === 'main',
    [styles.primaryDark]: color === 'primaryDark',
    [styles.error]: color === 'error',
    [styles.transparent]: color === 'transparent',
    [styles.small]: size === 'small' && boxed,
    [styles.medium]: size === 'medium' && boxed,
    [styles.large]: size === 'large' && boxed,
  });

  const button = (
    <button
      {...mergeProps(buttonProps, focusProps, props)}
      className={butonCN}
      type="button"
      ref={ref}>
      {isLoading ? <Loading delay={0} size={22} /> : children}
    </button>
  );

  if (!title) return button;

  return <Tooltip tooltip={title}>{button}</Tooltip>;
}

export default forwardRef(IconButton);
