import React from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory, useParams } from 'react-router';
import { Content, Surface, Tab, Tabs } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import { EmptyTests } from 'src/components/organisms';
import { useTranslation } from 'src/i18n/hooks';
import { rootPlanSelector } from '@bitmodern/redux/state/plans/selectors';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import AnalyzeGrowthView from '../AnalyzeGrowthView';
import AnalyzeRequirementsView from '../AnalyzeRequirementsView';
import AnalyzeReliabilityView from '../AnalyzeReliabilityView';
import AnalyzeTestQualityView from '../AnalyzeTestQualityView';

export enum AnalyzeTabs {
  TestGrowth = 'growth',
  TestQuality = 'quality',
  TestReliability = 'reliability',
  TestRequirements = 'requirements',
}

const tabsMap = {
  [AnalyzeTabs.TestGrowth]: AnalyzeGrowthView,
  [AnalyzeTabs.TestQuality]: AnalyzeTestQualityView,
  [AnalyzeTabs.TestReliability]: AnalyzeReliabilityView,
  [AnalyzeTabs.TestRequirements]: AnalyzeRequirementsView,
  default: AnalyzeGrowthView,
};

export default function AnalyzeView() {
  const { t } = useTranslation();
  const {
    site,
    projectId,
    tab = AnalyzeTabs.TestGrowth,
  } = useParams<typeof routes.ANALYZE.params>();
  const { push } = useHistory();

  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, parseInt(projectId, 10)),
  );
  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: parseInt(projectId, 10) }),
  );

  const handleTabChange = (tabPath: AnalyzeTabs) => {
    push(
      generatePath(routes.ANALYZE.path, {
        site,
        tab: tabPath,
        projectId,
      }),
    );
  };

  if (!rootPlan) return null;

  const testsEmpty = tests.length === 0;

  let empty;
  if (testsEmpty) {
    empty = <EmptyTests filterType="analyze" potentialAmount={tests.length} />;
  }

  const CurrentTab = tabsMap[tab] || tabsMap.default;

  return (
    <Content fullHeight>
      <Helmet>
        <title>{t('analyze.head.title')}</title>
      </Helmet>
      {!empty ? (
        <Surface>
          <Tabs onChange={handleTabChange} tab={tab} withBarBackground>
            <Tab id={AnalyzeTabs.TestGrowth}>
              {t('analyze.tabs.testGrowth')}
            </Tab>
            <Tab id={AnalyzeTabs.TestQuality}>
              {t('analyze.tabs.testQuality')}
            </Tab>
            <Tab id={AnalyzeTabs.TestReliability}>
              {t('analyze.tabs.testReliability')}
            </Tab>
            <Tab id={AnalyzeTabs.TestRequirements}>
              {t('analyze.tabs.testRequirements')}
            </Tab>
          </Tabs>
          <CurrentTab />
        </Surface>
      ) : (
        empty
      )}
    </Content>
  );
}
