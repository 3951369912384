import React from 'react';
import { useHistory } from 'react-router';
import { routes } from 'src/components/Router';
import { useQuery } from 'src/hooks/useQuery';
import { Button, Spacer, Surface } from 'src/packages/bit-ui';
import { JiraIcon, TqLogoIcon } from 'src/packages/bit-ui/icons';
import { tokenSelector } from 'src/packages/redux/state/authentication/selectors';
import { useAppSelector } from 'src/packages/redux/store';
import { useTranslation } from 'src/i18n/hooks';
import styles from './ConnectAppView.module.scss';

const apps = {
  jira: {
    icon: JiraIcon,
    name: 'Jira',
  },
};

export function ConnectAppView() {
  const { app: application = 'jira', callback } = useQuery(['callback', 'app']);
  const app = apps[application];
  const token = useAppSelector(tokenSelector);
  const history = useHistory();
  const { t } = useTranslation();

  function onConnect() {
    if (callback && token) {
      window.location.href = callback + JSON.stringify(token);
    }
  }

  function onCancel() {
    history.push(routes.SITE_EMPTY({}));
  }

  if (!app) return <div>No valid app found</div>;

  return (
    <div className={styles.connect}>
      <Surface className={styles.content}>
        <div className={styles.logos}>
          <TqLogoIcon size={50} />
          <div className={styles.dots}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
          </div>
          <JiraIcon size={45} />
        </div>
        <div className={styles.title}>
          {t('connect.connectTo', { app: app.name })}
        </div>
        <div>
          {t('connect.message')}
          <ul className={styles.permissions}>
            <li>{t('connect.permissions.per1')}</li>
            <li>{t('connect.permissions.per2')}</li>
            <li>{t('connect.permissions.per3')}</li>
            <li>{t('connect.permissions.per4')}</li>
          </ul>
        </div>
        <Spacer className={styles.actions}>
          <Button color="primaryDark" onClick={onCancel}>
            {t('connect.cancel')}
          </Button>
          <Button color="accent" onClick={onConnect}>
            {t('connect.connect')}
          </Button>
        </Spacer>
      </Surface>
    </div>
  );
}
