import React from 'react';
import { appSelectors } from 'src/gen/domain/app/appSelector';
import { appVersionSelectors } from 'src/gen/domain/app_version/appVersionSelector';
import { platSelectors } from 'src/gen/domain/plat/platSelector';
import { platVersionSelectors } from 'src/gen/domain/plat_version/platVersionSelector';
import { FormikProps } from 'formik';
import { Option } from '@bitmodern/bit-ui/Select/Select';
import { formikErrorArray } from 'src/utils/formik';
import { DeleteIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { appVersionPlatVersionSelectors } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionSelector';
import { IconButton, Select } from '@bitmodern/bit-ui';
import {
  formatAppName,
  formatPlatName,
} from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import vars from 'src/export.scss';
import styles from './Item.module.scss';

type Values = {
  appVersionPlatVersions: Array<number | undefined>;
};

type Props = {
  formik: FormikProps<Values>;
  index: number;
};

export default function Item({ formik, index }: Props) {
  const { t } = useTranslation();
  const id = formik.values.appVersionPlatVersions[index];
  const avpv = useAppSelector((state) => {
    if (!id) return undefined;
    return appVersionPlatVersionSelectors.selectById(state, id);
  });
  const platfformEntities = useAppSelector(platSelectors.selectEntities);
  const platfformVersions = useAppSelector(platVersionSelectors.selectAll);
  const appEntities = useAppSelector(appSelectors.selectEntities);
  const appVersionsEntities = useAppSelector(
    appVersionSelectors.selectEntities,
  );
  const appVersionPlatVersions = useAppSelector(
    appVersionPlatVersionSelectors.selectAll,
  );

  const platformOptions = platfformVersions.map((version) => ({
    label: formatPlatName({
      plat: platfformEntities[version.plat_id],
      platVersion: version,
    }),
    value: version.id,
  }));

  const appOptions = appVersionPlatVersions
    .filter((item) => item.plat_version_id === avpv?.plat_version_id)
    .reduce<Option[]>((acu, item) => {
      const version = appVersionsEntities[item.app_version_id];
      if (!version) return acu;
      acu.push({
        label: formatAppName({
          app: appEntities[version.app_id],
          appVersion: version,
        }),
        value: item.app_version_id,
      });
      return acu;
    }, []);

  const onChangeAppVersion = (value) => {
    const nextId = appVersionPlatVersions.find((item) => {
      return (
        item.app_version_id === value &&
        avpv?.plat_version_id === item.plat_version_id
      );
    })?.id;
    formik.setFieldValue(`appVersionPlatVersions[${index}]`, nextId);
  };

  const onChangePlatformVersion = (value) => {
    const nextId = appVersionPlatVersions.find((item) => {
      return item?.plat_version_id === value;
    })?.id;
    formik.setFieldValue(`appVersionPlatVersions[${index}]`, nextId);
  };

  const onDelete = () => {
    const next = formik.values.appVersionPlatVersions.filter((el) => el !== id);
    formik.setFieldValue('appVersionPlatVersions', next);
  };

  return (
    <div className={styles.item}>
      <Select
        error={
          formikErrorArray(formik, 'appVersionPlatVersions', index)
            ?.platformVersionId
        }
        fullWidth
        label={t('planConfiguration.form.platform')}
        name={`appVersionPlatVersions[${index}].platformVersionId`}
        onBlur={formik.handleBlur}
        onChange={onChangePlatformVersion}
        options={platformOptions}
        placeholder={t('planConfiguration.form.platformPlaceholder')}
        required
        value={avpv?.plat_version_id}
      />
      <Select
        error={
          formikErrorArray(formik, 'appVersionPlatVersions', index)
            ?.appVersionId
        }
        fullWidth
        label={t('planConfiguration.form.app')}
        name={`appVersionPlatVersions[${index}].appVersionId`}
        onBlur={formik.handleBlur}
        onChange={onChangeAppVersion}
        options={appOptions}
        placeholder={t('planConfiguration.form.appPlaceholder')}
        required
        value={avpv?.app_version_id}
      />
      <IconButton className={styles.delete} onClick={onDelete}>
        <DeleteIcon color={vars.textPrimary} size={16} />
      </IconButton>
    </div>
  );
}
