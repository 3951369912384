import { getResponse, IntegrationProjectRoute } from '@testquality/sdk';
import { getClient } from 'src/Client';

export const searchDefects = (
  integrationId: number,
  projectid: number,
  search: string,
  externalReference?: string,
): Promise<any> => {
  const params = {
    ...(search !== '' && { q: search }),
    ...(externalReference && externalReference !== '' && { externalReference }),
  };
  return getResponse<any>(getClient().api, {
    method: 'get',
    url: `${IntegrationProjectRoute(integrationId)}/${projectid}/search`,
    params,
  });
};
