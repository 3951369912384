import JSZip from 'jszip';
import unescape from 'lodash/unescape';
import { saveAs } from 'file-saver';
import i18n from 'src/i18n';

class CreateXLSXFile {
  private readonly workbookXML: string =
    '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
    '<workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" ' +
    'xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">\n' +
    // 'xmlns:mx="http://schemas.microsoft.com/office/mac/excel/2008/main" ' +
    // 'xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" ' +
    // 'xmlns:mv="urn:schemas-microsoft-com:mac:vml" ' +
    // 'xmlns:x14="http://schemas.microsoft.com/office/spreadsheetml/2009/9/main" ' +
    // 'xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac" ' +
    // 'xmlns:xm="http://schemas.microsoft.com/office/excel/2006/main"><workbookPr/>' +
    '<sheets>\n' +
    '<sheet name="TestQuality" sheetId="1" r:id="rId1"/>\n' + // state="visible"
    '</sheets>' +
    // '<definedNames/><calcPr/>' +
    '</workbook>';

  private readonly workbookXMLRels: string =
    '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n' +
    '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">\n' +
    '<Relationship Id="rId1" Target="/xl/worksheets/sheet1.xml" ' +
    'Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet"/>\n' +
    '<Relationship Id="rId2" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles" ' +
    'Target="/xl/styles.xml" />' +
    '</Relationships>';

  private readonly rels: string =
    '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
    '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">' +
    '<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" ' +
    'Target="/xl/workbook.xml"/></Relationships>';

  private readonly contentType: string =
    '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
    '<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types"><Default Extension="xml" ' +
    'ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml" />' +
    '<Default Extension="rels" ' +
    'ContentType="application/vnd.openxmlformats-package.relationships+xml" />' +
    '<Override PartName="/xl/worksheets/sheet1.xml" ' +
    'ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml" />' +
    '<Override PartName="/xl/styles.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml" />' +
    '</Types>';

  private readonly style: string = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
    <styleSheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main">
     <fonts count="2">
      <font />
      <font>
       <b />
       <sz val="14"/>
      </font>
     </fonts>
     <fills count="1">
      <fill />
     </fills>
     <borders count="1">
      <border />
     </borders>
     <cellStyleXfs count="1">
      <xf />
     </cellStyleXfs>
     <cellXfs count="2">
      <xf />
      <xf fontId="1" />
      <xf numFmtId="14" />
     </cellXfs>
    </styleSheet>`;

  private readonly filename: string;

  constructor(filename: string) {
    this.filename = filename;
  }

  private worksheet({ header, rows }: { header: string; rows: string }) {
    return `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main"
        xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">
          <sheetData>
            ${header}
            ${rows}
          </sheetData>
        </worksheet>`;
  }

  private JSDateToExcelDate(inDate: Date) {
    const returnDateTime =
      25569.0 +
      (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
        (1000 * 60 * 60 * 24);
    return returnDateTime;
  }

  private generateHeader(json: Object[]): string {
    const header = json && json.length > 0 ? Object.keys(json[0]) : [];
    let headerXLSX: string = '<row>\n';
    let cols: string = '<cols>\n'; // eslint-disable-line

    for (let idx = 0; idx < header.length; idx += 1) {
      const title: string = header[idx];
      headerXLSX += `<c t="inlineStr" s="1">
        <is><t>${title}</t></is>
      </c>`;

      const colNum = idx + 1;
      cols += `<col min="${colNum.toString()}" max="${colNum.toString()}" width="10.0" bestFit="1" customWidth="1" />`;
    }
    cols += '</cols>\n';

    headerXLSX += cols;
    headerXLSX += '</row>\n';
    return headerXLSX;
  }

  private htmlToPlaintext(text) {
    if (!text) return text;
    const removed = text.replace(/<[^>]+>/gm, ''); // remove html
    return unescape(removed)
      .replace(/&/g, '&amp;')
      .replace(/>/g, '&gt;')
      .replace(/</g, '&lt;');
    // .replace(/"/g, '&quot;');
  }

  private generateCell(value: any): string {
    if (value && typeof value.format === 'function') {
      // assume date
      return `<c s="2">
        <v>${this.JSDateToExcelDate(value.toDate())}</v>
      </c>`;
    }
    if (Array.isArray(value)) {
      return `<c t="inlineStr">
      <is><t>${this.htmlToPlaintext(JSON.stringify(value))}</t></is>
    </c>`;
    }

    switch (typeof value) {
      case 'string':
        return `<c t="inlineStr">
          <is><t>${this.htmlToPlaintext(value)}</t></is>
        </c>`;

      case 'number':
        return `<c><v>${value.toString()}</v></c>`;

      case 'boolean':
        return `<c t="inlineStr">
        <is><t>${value ? 'true' : 'false'}</t></is>
      </c>`;

      default:
        return '<c><v></v></c>';
    }
  }

  private generateRows(json: Object[]): string {
    let rows: string = '';
    json.forEach((row) => {
      rows += '<row>\n';
      Object.keys(row).forEach((key) => {
        rows += this.generateCell(row[key]);
      });
      rows += '</row>\n';
    });
    return rows;
  }

  public generateXLSX(json: Object[] | Object): Promise<void> {
    const data: Object[] = Array.isArray(json) ? json : [json];
    const zip = JSZip();
    const xl = zip.folder('xl');
    if (xl) {
      xl.file('workbook.xml', this.workbookXML);
      xl.file('styles.xml', this.style);
      xl.file('_rels/workbook.xml.rels', this.workbookXMLRels);
      zip.file('_rels/.rels', this.rels);
      zip.file('[Content_Types].xml', this.contentType);

      const header = this.generateHeader(data);
      const rows = this.generateRows(data);
      const worksheet = this.worksheet({ header, rows });

      xl.file('worksheets/sheet1.xml', worksheet);
    }

    return zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, `${this.filename}.xlsx`);
    });
  }
}

export function genereateXlsx(
  filename: string,
  data: Object[] | Object,
): Promise<void> {
  return new CreateXLSXFile(filename).generateXLSX(data);
}

export function generateCsv(json: Object[] | Object): string {
  const array: Object[] = Array.isArray(json) ? json : [json];
  const header = Object.keys(array[0]);

  return [header]
    .concat(
      array.map((row) =>
        header.map((fieldName) => {
          let data = row[fieldName];
          data = data === undefined || data === null ? '' : data;
          if (data instanceof Date) {
            data = i18n.t('dates.default', { date: data });
          }
          if (Array.isArray(data)) {
            data = JSON.stringify(data);
          } else {
            data = data.toString();
          }
          let result = data.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            result = `"${result}"`;
          }
          return result;
        }),
      ),
    )
    .join('\r\n');
}
