/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  DefectResNativeDefectRes,
  defectResNativeDefectResDetach,
  defectResNativeDefectResUpdateOne,
  defectResNativeDefectResCreateOne,
  defectResNativeDefectResGetMany,
  defectResNativeDefectResGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const defectResNativeDefectResDetachThunk = createAsyncThunk(
  'defectResNativeDefectRes/DetachThunk',
  async (arg: QueryParams<DefectResNativeDefectRes>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'defectResNativeDefectRes/DetachThunk',
      );
    }
    return defectResNativeDefectResDetach(arg.data, arg).then(() => arg.data);
  },
);

export const defectResNativeDefectResUpdateOneThunk = createAsyncThunk(
  'defectResNativeDefectRes/UpdateOneThunk',
  async ({
    handleError = true,
    ...arg
  }: ThunkArgs<DefectResNativeDefectRes>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'defectResNativeDefectRes/UpdateOneThunk',
        );
      }
      const data = await defectResNativeDefectResUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<DefectResNativeDefectRes>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectResNativeDefectResCreateOneThunk = createAsyncThunk(
  'defectResNativeDefectRes/CreateOneThunk',
  async ({
    handleError = true,
    ...arg
  }: ThunkArgs<DefectResNativeDefectRes>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'defectResNativeDefectRes/CreateOneThunk',
        );
      }
      return await defectResNativeDefectResCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectResNativeDefectResFetchManyThunk = createAsyncThunk(
  'defectResNativeDefectRes/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectResNativeDefectRes>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await defectResNativeDefectResGetMany(inlineArgs);
      return {
        defectResNativeDefectRes: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectResNativeDefectResFetchOneThunk = createAsyncThunk(
  'defectResNativeDefectRes/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectResNativeDefectRes>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'defectResNativeDefectRes/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await defectResNativeDefectResGetOne(arg.id as number, arg);
      return {
        defectResNativeDefectRes: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
