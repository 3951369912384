import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@bitmodern/bit-ui';
import { DefectEventsItemDefect } from './DefectEventsItemDefect';
import useEvents from '../hooks/useEvents';
import { Defect } from '@testquality/sdk';
import { useTranslation } from 'react-i18next';
import { DefectEventsItemRunResult } from './DefectEventsItemRunResult';
import { DefectEventsItemDefectCreate } from './DefectEventsItemDefectCreate';

type Props = {
  defect: Defect;
  integrationProjectId: number;
};

export default function DefectEvents({
  defect,
  integrationProjectId,
}: Props): JSX.Element {
  const { events, defectStatusesNativeDefectStatuses, nativeDefectStatuses } =
    useEvents({
      defect,
      integrationProjectId,
    });
  const { t } = useTranslation();

  return (
    <Table maxHeight={300}>
      <TableBody>
        {events.map((event) => {
          if (event.itemType === 'defectCreate') {
            return (
              <DefectEventsItemDefectCreate key={event.id} event={event} />
            );
          }
          if (event.itemType === 'defectUpdate') {
            return (
              <DefectEventsItemDefect
                key={event.id}
                event={event}
                defectStatusesNativeDefectStatuses={
                  defectStatusesNativeDefectStatuses
                }
                nativeDefectStatuses={nativeDefectStatuses}
              />
            );
          }
          if (event.itemType === 'runResult') {
            return <DefectEventsItemRunResult key={event.id} event={event} />;
          }
          return null;
        })}
        {events.length === 0 && (
          <TableRow key={'no-event'}>
            <TableCell>{t('defectEvent.noEvents')}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
