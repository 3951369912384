import React, { useCallback, useMemo } from 'react';
import { FlatList, Surface } from '@bitmodern/bit-ui';
import { PlanListed } from 'src/components/organisms';
import useModalManager from 'src/hooks/useModalManager';
import { Plan } from '@testquality/sdk';
import { PlanIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import PlansHeader from './PlansHeader';
import EmptyList from '../EmptyList';
import vars from 'src/export.scss';
import styles from './Plans.module.scss';

type Props = {
  currentPlanId: string;
  currentProjetId: string;
  initialFilter?: string;
  onChangeFilter: (filter: string) => void;
  plans: Plan[];
  plansTestCount: { [key: number]: number };
  site: string;
};

function Plans({
  currentProjetId,
  initialFilter = '',
  onChangeFilter,
  plans,
  plansTestCount,
  site = '',
  ...rest
}: Props) {
  const currentPlanId = parseInt(rest.currentPlanId, 10);
  const { showModal } = useModalManager();
  const { t } = useTranslation();

  const context = useMemo(
    () => ({
      currentPlanId,
      site,
      plansTestCount,
    }),
    [currentPlanId, site, plansTestCount],
  );

  const renderItem = useCallback(
    ({ item, context }) => (
      <PlanListed
        plan={item}
        selected={context.currentPlanId === item.id}
        key={item.id}
        site={context.site}
        testsCount={context.plansTestCount[item.id] || 0}
      />
    ),
    [],
  );

  const keyExtractor = useCallback((item) => item.id, []);

  const renderEmpty = useCallback(
    () => (
      <EmptyList
        className={styles.empty}
        icon={<PlanIcon color={vars.textSecondary} size={72} />}
        description={t('requirements.filtersEmpty.description')}
        size="small"
        variant="secondary"
        title={t('requirements.filtersEmpty.title')}
      />
    ),
    [t],
  );

  const onCreate = () => {
    showModal({
      modalName: 'createPlan',
      modalProps: { projectId: currentProjetId },
      type: 'modal',
    });
  };

  return (
    <Surface className={styles.panel}>
      <PlansHeader
        onCreate={onCreate}
        onChangeSearch={onChangeFilter}
        search={initialFilter}
      />
      <div className={styles.panelContent}>
        <FlatList
          className={styles.list}
          context={context}
          data={plans}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          renderEmpty={renderEmpty}
          scrollToElement={currentPlanId}
          gap={8}
          scrollClassName={styles.scroll}
        />
      </div>
    </Surface>
  );
}

export default React.memo(Plans);
