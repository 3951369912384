import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useAppSelector, useAppDispatch } from '@bitmodern/redux/store';
import {
  githubBaseIntegrationSelector,
  jiraBaseIntegrationSelector,
} from '@bitmodern/redux/state/baseIntegration/selectors';
import { authorizeIntegrationThunk } from '@bitmodern/redux/state/integration/thunks';
import { integrationsByBaseSelector } from '@bitmodern/redux/state/integration/selectors';
import { integrationUsersByCurrentUserSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { BaseIntegration, Integration } from '@testquality/sdk';
import { routes } from 'src/components/Router';
import useMutation from 'src/hooks/useMutation';
import { useQuery } from 'src/hooks/useQuery';

import Debug from 'debug';
const debug = Debug('useIntegration');

const GITHUB_SUCCESS = 'github-step-success';
const JIRA_SUCCESS = 'jira-step-success';

export type integrationType = 'github' | 'jira';

export function useIntegration(type: integrationType) {
  const [integrationSelector, integrationSuccess] = useMemo(
    () =>
      type === 'github'
        ? [githubBaseIntegrationSelector, GITHUB_SUCCESS]
        : [jiraBaseIntegrationSelector, JIRA_SUCCESS],
    [type],
  );
  const baseIntegration: BaseIntegration | undefined =
    useAppSelector(integrationSelector);

  const [integration]: Array<Integration | undefined> = useAppSelector(
    (state) => {
      if (!baseIntegration) return [];
      return integrationsByBaseSelector(state, baseIntegration.id);
    },
  );

  const integrationUser = useAppSelector((state) => {
    if (!baseIntegration) return undefined;
    return integrationUsersByCurrentUserSelector(state, baseIntegration.id);
  });

  const dispatch = useAppDispatch();
  const { site } = useParams<typeof routes.GET_STARTED.params>();
  const { href } = window.location;

  const authorizeIntegration = useCallback(() => {
    if (!baseIntegration) return Promise.reject();

    const url = new URL(href);
    url.searchParams.set(GITHUB_SUCCESS, String(true));
    const redirectUrl = url.toString();

    debug('authorizeIntegration', { redirectUrl });

    return dispatch(
      authorizeIntegrationThunk({
        redirectUrl,
        baseIntegration,
        projectCreate: false,
        integration,
        site,
      }),
    );
  }, [baseIntegration, dispatch, href, integration, site]);

  const { isLoading, mutate } = useMutation(authorizeIntegration);

  const { [integrationSuccess]: success } = useQuery([integrationSuccess]);

  return {
    authorize: mutate,
    loading: isLoading,
    success,
    user: integrationUser,
  };
}
