import React, { useMemo, useState } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import debounce from 'lodash/debounce';
import {
  IconButton,
  InputSearch,
  PanelHeader,
  Spacer,
} from '@bitmodern/bit-ui';
import { AddIcon, SearchIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import classnames from 'classnames';
import vars from 'src/export.scss';
import styles from './PlansHeader.module.scss';
import { Expandable } from 'src/modules/core/elements';

type Props = {
  onCreate: () => void;
  onChangeSearch: (search: string) => void;
  search: string;
};

export default function PlansHeader({
  onCreate,
  onChangeSearch,
  search,
}: Props) {
  const { t } = useTranslation();
  const filterState = useOverlayTriggerState({});
  const [searchValue, setSearchValue] = useState(search);

  const onChangeSearchDebounced = useMemo(() => {
    return debounce(onChangeSearch, 250);
  }, [onChangeSearch]);

  const handleChangeSearch = (value) => {
    setSearchValue(value);
    onChangeSearchDebounced(value);
  };

  const searchCN = classnames({
    [styles.hasFilters]: Boolean(search),
  });

  return (
    <>
      <PanelHeader
        actions={
          <Spacer>
            <IconButton
              className={searchCN}
              onClick={filterState.toggle}
              title={t('plans.search')}>
              <SearchIcon color={vars.textPrimary} size={20} />
            </IconButton>
            <IconButton onClick={onCreate} title={t('plans.create')}>
              <AddIcon color={vars.textPrimary} size={20} />
            </IconButton>
          </Spacer>
        }
        title={t('plans.title')}
      />
      <Expandable
        className={styles.filters}
        expanded={filterState.isOpen}
        trigger={0.8}>
        <InputSearch
          autoFocus
          fullWidth
          onChange={handleChangeSearch}
          showLabel={false}
          value={searchValue}
        />
      </Expandable>
    </>
  );
}
