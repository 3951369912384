import React from 'react';
import styles from './DefectEventsItemDefect.module.scss';
import { TableCell, TableRow } from '@bitmodern/bit-ui';
import {
  DefectStatusNativeDefectStatus,
  NativeDefectStatus,
} from '@testquality/sdk';
import classnames from 'classnames';
import { DefectUpdateEvent } from 'src/packages/redux/state/defectHistory/thunks';
import { NativeDefectStatusItem } from 'src/components/organisms/NativeDefectStatusItem';
import { StatusTypeEnum } from 'src/enums/StatusEnum';
import { ReporterCell } from '../ReporterCell';

type DefectEventsItemDefectProps = {
  event: DefectUpdateEvent;
  defectStatusesNativeDefectStatuses: DefectStatusNativeDefectStatus[];
  nativeDefectStatuses: NativeDefectStatus[];
};

export function DefectEventsItemDefect({
  event,
  defectStatusesNativeDefectStatuses,
  nativeDefectStatuses,
}: DefectEventsItemDefectProps) {
  const statusJoinTable = defectStatusesNativeDefectStatuses.find(
    (dn) => dn.defect_status_id === event.diffs.defect_status_id,
  );
  const nativeDefect = nativeDefectStatuses.find(
    (nd) => nd.id === statusJoinTable?.native_defect_status_id,
  );

  const defectRowCN = classnames(styles.defectRow, {
    [styles.statusDefectSolved]:
      event.statusDiff.key === StatusTypeEnum.Negative,
    [styles.statusDefectNotSolved]:
      event.statusDiff.key === StatusTypeEnum.Positive,
  });

  return (
    <TableRow className={defectRowCN} selected>
      <TableCell className={styles.statusDefect} colSpan={2}>
        <div className={styles.statusCell}>
          <NativeDefectStatusItem
            nativeDefectStatus={nativeDefect}
            statusKey={event.statusDiff.key}
          />
        </div>
      </TableCell>

      <ReporterCell event={event} />
    </TableRow>
  );
}
