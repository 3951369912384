/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CapabilityHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { capabilityHistoryAdapter } from './capabilityHistoryAdapter';
import { capabilityHistoryFetchThunk } from './capabilityHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const capabilityHistorySlice = createSlice({
  name: 'capabilityHistory',
  initialState: capabilityHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    capabilityHistoryCleared(state) {
      state.loading = 'idle';
      capabilityHistoryAdapter.removeAll(state);
    },
    capabilityHistoryUpsertMany(
      state,
      action: PayloadAction<
        CapabilityHistory[] | Record<number, CapabilityHistory>
      >,
    ) {
      capabilityHistoryAdapter.upsertMany(state, action.payload);
    },
    capabilityHistoryUpsertOne(
      state,
      action: PayloadAction<CapabilityHistory>,
    ) {
      capabilityHistoryAdapter.upsertOne(state, action.payload);
    },
    capabilityHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CapabilityHistory>>,
    ) {
      capabilityHistoryAdapter.updateOne(state, action.payload);
    },
    capabilityHistoryRemoveOne(state, action: PayloadAction<number>) {
      capabilityHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(capabilityHistoryFetchThunk.pending, handlePending);
    builder.addCase(capabilityHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      capabilityHistoryAdapter.upsertMany(
        state,
        action.payload.capabilityHistory,
      );
    });
    builder.addCase(capabilityHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  capabilityHistoryCleared,
  capabilityHistoryUpsertMany,
  capabilityHistoryUpsertOne,
  capabilityHistoryUpdateOne,
  capabilityHistoryRemoveOne,
} = capabilityHistorySlice.actions;

export const capabilityHistoryReducer = capabilityHistorySlice.reducer;
