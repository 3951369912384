import React from 'react';
import { AuthRoute, routes, SubscribeRoute } from 'src/components/Router';
import RouteNested from 'src/components/Router/RouteNested';
import AccessRolesView from 'src/modules/settings/views/AccessRolesView';
import BillingView from 'src/modules/settings/views/BillingView';
import CompanyView from 'src/modules/settings/views/CompanyView';
import ConfigurationsView from 'src/modules/settings/views/ConfigurationsView';
import CustomizationView from 'src/modules/settings/views/CustomizationView';
import GithubView from 'src/modules/settings/views/GithubView';
import IntegrationSetupView from 'src/modules/settings/views/IntegrationSetupView';
import IntegrationsSectionView from 'src/modules/settings/views/IntgrationsSectionView';
import IntegrationsView from 'src/modules/settings/views/IntegrationsView';
import IntegrationView from 'src/modules/settings/views/IntegrationView';
import LookupDataView from 'src/modules/settings/views/LookupDataView';
import ProfileView from 'src/modules/settings/views/ProfileView';
import ProjectIntegrationEditView from 'src/modules/settings/views/ProjectIntegrationEditView';
import SettingsView from 'src/modules/settings/views/SettingsView';
import SpaceView from 'src/modules/settings/views/SpaceView';
import UserEditView from 'src/modules/settings/views/UserEditView';
import UsersView from 'src/modules/settings/views/UsersView';
import WatchView from 'src/modules/settings/views/WatchView';
import { ProjectIntegrationCreateView } from 'src/modules/settings/views/ProjectIntegrationCreateView';
import ImportAutomationView from 'src/components/views/ImportAutomationView';
import AutomationsView from 'src/components/views/AutomationsView';
import ImportDataView from 'src/components/views/ImportDataView';

export default function SettingsRoutes() {
  return (
    <RouteNested
      path={[
        routes.ACCESS_ROLES.path,
        routes.AUTOMATIONS.path,
        routes.COMPANY.path,
        routes.CONFIGURATIONS.path,
        routes.CUSTOMIZATION.path,
        routes.GITHUB.path,
        routes.IMPORT_AUTOMATION.path,
        routes.IMPORT_DATA.path,
        routes.IMPORT.path,
        routes.INTEGRATION_SETUP.path,
        routes.INTEGRATIONS.path,
        routes.LOOKUP.path,
        routes.PROFILE.path,
        routes.SETTINGS.path,
        routes.SPACE.path,
        routes.SUBSCRIPTIONS.path,
        routes.USER_CREATE.path,
        routes.USERS.path,
        routes.WATCH.path,
      ]}
      component={SettingsView}>
      <SubscribeRoute path={routes.PROFILE.path} component={ProfileView} />
      <RouteNested
        component={IntegrationsSectionView}
        exact
        path={[
          routes.INTEGRATIONS.path,
          routes.INTEGRATION.path,
          routes.INTEGRATION_PROJECT_CREATE.path,
          routes.INTEGRATION_SETUP.path,
          routes.PROJECT_INTEGRATION.path,
        ]}>
        <SubscribeRoute
          path={routes.INTEGRATIONS.path}
          exact
          component={IntegrationsView}
        />
        <SubscribeRoute
          path={routes.INTEGRATION.path}
          exact
          component={IntegrationView}
        />
        <SubscribeRoute
          path={routes.INTEGRATION_PROJECT_CREATE.path}
          exact
          component={ProjectIntegrationCreateView}
        />
        <SubscribeRoute
          path={routes.INTEGRATION_SETUP.path}
          exact
          component={IntegrationSetupView}
        />
        <SubscribeRoute
          path={routes.PROJECT_INTEGRATION.path}
          exact
          component={ProjectIntegrationEditView}
        />
      </RouteNested>

      <SubscribeRoute
        path={routes.AUTOMATIONS.path}
        exact
        component={AutomationsView}
      />
      <SubscribeRoute
        path={routes.IMPORT_AUTOMATION.path}
        exact
        component={ImportAutomationView}
      />
      <SubscribeRoute
        path={routes.IMPORT_DATA.path}
        exact
        component={ImportDataView}
      />

      <SubscribeRoute
        path={routes.COMPANY.path}
        exact
        component={CompanyView}
      />
      <SubscribeRoute
        path={routes.SUBSCRIPTIONS.path}
        exact
        component={BillingView}
      />
      <AuthRoute path={routes.GITHUB.path} exact component={GithubView} />
      <AuthRoute
        path={routes.ACCESS_ROLES.path}
        exact
        component={AccessRolesView}
      />
      <AuthRoute path={routes.WATCH.path} exact component={WatchView} />
      <AuthRoute path={routes.LOOKUP.path} exact component={LookupDataView} />
      <AuthRoute
        path={routes.CUSTOMIZATION.path}
        exact
        component={CustomizationView}
      />
      <AuthRoute
        path={routes.CONFIGURATIONS.path}
        exact
        component={ConfigurationsView}
      />
      <AuthRoute path={routes.SPACE.path} exact component={SpaceView} />
      <SubscribeRoute path={routes.USERS.path} exact component={UsersView} />
      <AuthRoute path={routes.USER.path} exact component={UserEditView} />
    </RouteNested>
  );
}
