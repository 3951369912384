/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { BaseIntegrationHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { baseIntegrationHistoryAdapter } from './baseIntegrationHistoryAdapter';
import { baseIntegrationHistoryFetchThunk } from './baseIntegrationHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const baseIntegrationHistorySlice = createSlice({
  name: 'baseIntegrationHistory',
  initialState:
    baseIntegrationHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    baseIntegrationHistoryCleared(state) {
      state.loading = 'idle';
      baseIntegrationHistoryAdapter.removeAll(state);
    },
    baseIntegrationHistoryUpsertMany(
      state,
      action: PayloadAction<
        BaseIntegrationHistory[] | Record<number, BaseIntegrationHistory>
      >,
    ) {
      baseIntegrationHistoryAdapter.upsertMany(state, action.payload);
    },
    baseIntegrationHistoryUpsertOne(
      state,
      action: PayloadAction<BaseIntegrationHistory>,
    ) {
      baseIntegrationHistoryAdapter.upsertOne(state, action.payload);
    },
    baseIntegrationHistoryUpdateOne(
      state,
      action: PayloadAction<Update<BaseIntegrationHistory>>,
    ) {
      baseIntegrationHistoryAdapter.updateOne(state, action.payload);
    },
    baseIntegrationHistoryRemoveOne(state, action: PayloadAction<number>) {
      baseIntegrationHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(baseIntegrationHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      baseIntegrationHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseIntegrationHistoryAdapter.upsertMany(
          state,
          action.payload.baseIntegrationHistory,
        );
      },
    );
    builder.addCase(baseIntegrationHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  baseIntegrationHistoryCleared,
  baseIntegrationHistoryUpsertMany,
  baseIntegrationHistoryUpsertOne,
  baseIntegrationHistoryUpdateOne,
  baseIntegrationHistoryRemoveOne,
} = baseIntegrationHistorySlice.actions;

export const baseIntegrationHistoryReducer =
  baseIntegrationHistorySlice.reducer;
