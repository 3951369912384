import React from 'react';
import styles from './DefectEventsItemRunResult.module.scss';
import { StatusIcon } from 'src/components/organisms';
import { TableCell, TableRow } from '@bitmodern/bit-ui';
import { RunResultEvent } from 'src/packages/redux/state/defectHistory/thunks';
import { ReporterCell } from '../ReporterCell';

export type DefectEventsItemRunResultProps = {
  event: RunResultEvent;
};

export function DefectEventsItemRunResult({
  event,
}: DefectEventsItemRunResultProps) {
  return (
    <TableRow>
      <TableCell>
        <div>{event.run?.name}</div>
        <div className={styles.testName}>
          <span>TC{event.test?.key}</span>
          {event.test?.name}
        </div>
      </TableCell>

      <TableCell>
        <StatusIcon
          className={styles.statusIcon}
          status={event.statusDiff}
          size={16}
        />
        {event.statusDiff.name}
      </TableCell>

      <ReporterCell event={event} marginRight={4} />
    </TableRow>
  );
}
