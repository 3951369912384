export enum Permission {
  COMPANY = 'company',
  INTEGRATIONS = 'integrations',
  USER_MANAGEMENT = 'userManagement',
  CUSTOMIZATIONS = 'customizations',
  PROJECT = 'project',
  MILESTONE = 'milestone',
  TEST = 'test',
  RUN = 'run',
}

export const permissionTypes: { [key in Permission]: string[] } = {
  [Permission.COMPANY]: ['client'],
  [Permission.INTEGRATIONS]: [
    'integration',
    'integration_user',
    'integration_project',
  ],
  [Permission.USER_MANAGEMENT]: [
    'user',
    'policy',
    'policy_rows',
    'access_role',
  ],
  [Permission.CUSTOMIZATIONS]: [
    'status',
    'purpose',
    'case_type',
    'case_priority',
    'app',
    'app_version',
    'app_version_plat_version',
    'app_version_plat_version_plan',
    'plat',
    'plat_version',
    'status',
    'status_type',
    'test_quality',
    'test_quality_type',
    'virtual',
  ],
  [Permission.PROJECT]: ['project'],
  [Permission.MILESTONE]: ['milestone', 'target'],
  [Permission.TEST]: [
    'label',
    'label_assigned',
    'plan',
    'plan_purpose',
    'suite',
    'plan_suite',
    'test',
    'step',
    'suite_test',
    'requirement',
    'comment',
    'watch',
  ],
  [Permission.RUN]: [
    'run',
    'run_result',
    'run_result_step',
    'defect',
    'defect_component',
    'defect_priority',
    'defect_res',
    'defect_status',
    'native_defect_res',
    'native_defect_status',
    'defect_res_native_defect_res',
    'defect_status_native_defect_status',
  ],
};

export const projectSpecificPermissions = [
  'milestone',
  'plan',
  'suite',
  'test',
  'step',
  'run',
  'run_result',
  'run_result_step',
];
