import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './EmptyList.module.scss';

type Props = {
  button?: ReactNode;
  className?: string;
  description: ReactNode;
  icon?: ReactNode;
  size?: 'small' | 'medium';
  variant?: 'primary' | 'secondary';
  title: ReactNode;
};

export default function EmptyList({
  button,
  className = '',
  description,
  icon,
  size = 'medium',
  variant = 'primary',
  title,
}: Props) {
  const titleCN = classNames(styles.title, styles[size]);
  const emptyCN = classNames(styles.empty, className, styles[variant]);

  return (
    <div className={emptyCN}>
      {icon || ''}
      <div className={titleCN}>{title}</div>
      <div className={styles.description}>{description}</div>
      {button || ''}
    </div>
  );
}
