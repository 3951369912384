/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { ProductApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { subscriptionsNormalizer } from '../subscriptions/subscriptionsNormalize';

export const productNormalizer = (
  model: ProductApi,
  cache: NormalizerCache,
) => {
  if (!cache.product) {
    cache.product = [];
  }
  cache.product.push(model);

  if (model.subscriptions && model.subscriptions.length) {
    model.subscriptions.forEach((child) => {
      subscriptionsNormalizer(child, cache);
    });
    model.subscriptions = undefined;
  }
};
