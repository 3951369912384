/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { runSuiteAdapter } from './runSuiteAdapter';

export const runSuiteSelectors = runSuiteAdapter.getSelectors(
  (state: State) => state.gen.runSuite,
);
