/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Project } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { projectAdapter } from './projectAdapter';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
  projectDeleteOneThunk,
  projectUpdateOneThunk,
  projectCreateOneThunk,
} from './projectThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const projectSlice = createSlice({
  name: 'project',
  initialState: projectAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    projectCleared(state) {
      state.loading = 'idle';
      projectAdapter.removeAll(state);
    },
    projectUpsertMany(
      state,
      action: PayloadAction<Project[] | Record<number, Project>>,
    ) {
      projectAdapter.upsertMany(state, action.payload);
    },
    projectUpsertOne(state, action: PayloadAction<Project>) {
      projectAdapter.upsertOne(state, action.payload);
    },
    projectUpdateOne(state, action: PayloadAction<Update<Project>>) {
      projectAdapter.updateOne(state, action.payload);
    },
    projectRemoveOne(state, action: PayloadAction<number>) {
      projectAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectFetchManyThunk.pending, handlePending);
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      projectAdapter.upsertMany(state, action.payload.project);
    });
    builder.addCase(projectFetchManyThunk.rejected, handleRejected);
    builder.addCase(projectFetchOneThunk.pending, handlePending);
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      projectAdapter.upsertOne(state, action.payload.project[0]);
    });
    builder.addCase(projectFetchOneThunk.rejected, handleRejected);
    builder.addCase(projectDeleteOneThunk.pending, handlePending);
    builder.addCase(projectDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        projectAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(projectDeleteOneThunk.rejected, handleRejected);
    builder.addCase(projectUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, projectAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(projectUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      projectAdapter.updateOne(state, action.payload);
    });
    builder.addCase(projectUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          projectAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(projectCreateOneThunk.pending, handlePending);
    builder.addCase(projectCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      projectAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(projectCreateOneThunk.rejected, handleRejected);
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.project) {
          projectAdapter.upsertMany(state, action.payload.project);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.project) {
          projectAdapter.upsertMany(state, action.payload.project);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.project) {
          projectAdapter.upsertMany(state, action.payload.project);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.project) {
        projectAdapter.upsertMany(state, action.payload.project);
      }
    });
  },
});

export const {
  projectCleared,
  projectUpsertMany,
  projectUpsertOne,
  projectUpdateOne,
  projectRemoveOne,
} = projectSlice.actions;

export const projectReducer = projectSlice.reducer;
