/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  QueryParams,
  PlanSuite,
  planSuiteDetach,
  planSuiteUpdateOne,
  planSuiteCreateOne,
  planSuiteGetMany,
  planSuiteGetOne,
  HttpError,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const planSuiteDetachThunk = createAsyncThunk(
  'planSuite/DetachThunk',
  async (arg: QueryParams<PlanSuite>) => {
    if (!arg.data) {
      throw new ThunkError(
        'Must supply data to delete',
        'planSuite/DetachThunk',
      );
    }
    return planSuiteDetach(arg.data, arg).then(() => arg.data);
  },
);

export const planSuiteUpdateOneThunk = createAsyncThunk(
  'planSuite/UpdateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanSuite>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'planSuite/UpdateOneThunk');
      }
      const data = await planSuiteUpdateOne(arg.id as number, arg.data, arg);
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<PlanSuite>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planSuiteCreateOneThunk = createAsyncThunk(
  'planSuite/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanSuite>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'planSuite/CreateOneThunk',
        );
      }
      return await planSuiteCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planSuiteFetchManyThunk = createAsyncThunk(
  'planSuite/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanSuite>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await planSuiteGetMany(inlineArgs);
      return {
        planSuite: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const planSuiteFetchOneThunk = createAsyncThunk(
  'planSuite/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlanSuite>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'planSuite/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await planSuiteGetOne(arg.id as number, arg);
      return {
        planSuite: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
