/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  PlatVersion,
  platVersionGetMany,
  platVersionGetOne,
  platVersionDeleteOne,
  platVersionUpdateOne,
  platVersionCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { platVersionNormalizer } from './platVersionNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const platVersionFetchManyThunk = createAsyncThunk(
  'platVersion/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PlatVersion>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await platVersionGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, platVersionNormalizer);
      }
      return {
        platVersion: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platVersionFetchOneThunk = createAsyncThunk(
  'platVersion/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PlatVersion>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'platVersion/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await platVersionGetOne(arg.id as number, arg);
      return executeNormalizer([data], platVersionNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platVersionDeleteOneThunk = createAsyncThunk(
  'platVersion/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlatVersion>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'platVersion/DeleteOneThunk');
      }
      return await platVersionDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platVersionUpdateOneThunk = createAsyncThunk(
  'platVersion/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<PlatVersion>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'platVersion/UpdateOneThunk');
      }
      const data = await platVersionUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<PlatVersion>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<PlatVersion>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const platVersionCreateOneThunk = createAsyncThunk(
  'platVersion/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PlatVersion>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'platVersion/CreateOneThunk',
        );
      }
      return await platVersionCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
