import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Context = {
  isLoading: boolean;
  setLoading: (value: boolean, key: string) => void;
};

const GlobalContext = createContext<Context>({ isLoading: false } as Context);

export function GlobalLoadingProvider({ children }: { children: ReactNode }) {
  const [loadingKeys, setLoadingKeys] = useState<string[]>([]);

  const setLoading = useCallback((value: boolean, key: string) => {
    setLoadingKeys((s) => {
      if (value) return [...s, key];
      return s.filter((k) => k !== key);
    });
  }, []);

  const value = useMemo(
    () => ({
      setLoading,
      isLoading: loadingKeys.length > 0,
    }),
    [setLoading, loadingKeys],
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export function useGlobalLoading() {
  const globalLoading = useContext(GlobalContext);
  return globalLoading;
}

export function useGlobalLoadingSync(value: boolean, key: string) {
  const { setLoading } = useGlobalLoading();
  useEffect(() => {
    setLoading(value, key);
  }, [key, setLoading, value]);
}
