/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { DefectStatus } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectStatusAdapter } from './defectStatusAdapter';
import {
  defectStatusFetchManyThunk,
  defectStatusFetchOneThunk,
  defectStatusDeleteOneThunk,
  defectStatusUpdateOneThunk,
  defectStatusCreateOneThunk,
} from './defectStatusThunk';
import {
  nativeDefectStatusFetchManyThunk,
  nativeDefectStatusFetchOneThunk,
} from '../native_defect_status/nativeDefectStatusThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectStatusSlice = createSlice({
  name: 'defectStatus',
  initialState: defectStatusAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectStatusCleared(state) {
      state.loading = 'idle';
      defectStatusAdapter.removeAll(state);
    },
    defectStatusUpsertMany(
      state,
      action: PayloadAction<DefectStatus[] | Record<number, DefectStatus>>,
    ) {
      defectStatusAdapter.upsertMany(state, action.payload);
    },
    defectStatusUpsertOne(state, action: PayloadAction<DefectStatus>) {
      defectStatusAdapter.upsertOne(state, action.payload);
    },
    defectStatusUpdateOne(state, action: PayloadAction<Update<DefectStatus>>) {
      defectStatusAdapter.updateOne(state, action.payload);
    },
    defectStatusRemoveOne(state, action: PayloadAction<number>) {
      defectStatusAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectStatusFetchManyThunk.pending, handlePending);
    builder.addCase(defectStatusFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
    });
    builder.addCase(defectStatusFetchManyThunk.rejected, handleRejected);
    builder.addCase(defectStatusFetchOneThunk.pending, handlePending);
    builder.addCase(defectStatusFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectStatusAdapter.upsertOne(state, action.payload.defectStatus[0]);
    });
    builder.addCase(defectStatusFetchOneThunk.rejected, handleRejected);
    builder.addCase(defectStatusDeleteOneThunk.pending, handlePending);
    builder.addCase(defectStatusDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        defectStatusAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(defectStatusDeleteOneThunk.rejected, handleRejected);
    builder.addCase(defectStatusUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, defectStatusAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(defectStatusUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectStatusAdapter.updateOne(state, action.payload);
    });
    builder.addCase(defectStatusUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          defectStatusAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(defectStatusCreateOneThunk.pending, handlePending);
    builder.addCase(defectStatusCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectStatusAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(defectStatusCreateOneThunk.rejected, handleRejected);
    builder.addCase(
      nativeDefectStatusFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatus) {
          defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
        }
      },
    );
    builder.addCase(
      nativeDefectStatusFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatus) {
          defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatus) {
          defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatus) {
          defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload.defectStatus) {
          defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.defectStatus) {
        defectStatusAdapter.upsertMany(state, action.payload.defectStatus);
      }
    });
  },
});

export const {
  defectStatusCleared,
  defectStatusUpsertMany,
  defectStatusUpsertOne,
  defectStatusUpdateOne,
  defectStatusRemoveOne,
} = defectStatusSlice.actions;

export const defectStatusReducer = defectStatusSlice.reducer;
