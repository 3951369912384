import { createSelector } from 'reselect';
import { subscriptionsSelectors } from 'src/gen/domain/subscriptions/subscriptionsSelector';
import { State } from '@bitmodern/redux/store';
import { Subscriptions } from '@testquality/sdk';
import { currentUserSelector } from '../authentication/selectors';

const OPENSOURCE_PRODUCT_ID = 15;

export function subscriptionsSelector(state: State) {
  return subscriptionsSelectors.selectAll(state);
}

// SUBSCRIPTIONS
export const subscriptionsSortedSelector = createSelector(
  subscriptionsSelector,
  (subscriptions) => {
    return [...subscriptions].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  },
);

export const activeSubscriptionsSelector = createSelector(
  subscriptionsSortedSelector,
  (subscriptions) => {
    const now = new Date();
    return subscriptions.filter((subscription) => {
      if (!subscription.ends_at || !subscription.subscription_expires_at)
        return true;
      if (!subscription.ends_at && subscription.subscription_expires_at) {
        return (
          now.getTime() <
          new Date(subscription.subscription_expires_at).getTime()
        );
      }
      return now.getTime() < new Date(subscription.ends_at).getTime();
    });
  },
);

// COMMERCIAL SUBSCRIPTIONS
export const commercialSubscriptionsSelector = createSelector(
  subscriptionsSortedSelector,
  (subscriptions) => subscriptions.filter(isCommertial),
);

export const activeCommertialSubscriptionsSelector = createSelector(
  activeSubscriptionsSelector,
  (subscriptions) => subscriptions.filter(isCommertial),
);

export const userCommertialSubscriptionSelector = createSelector(
  [activeCommertialSubscriptionsSelector, currentUserSelector],
  (subscriptions, currentUser) => {
    if (!currentUser) return undefined;
    return subscriptions.find(
      (subscription) => subscription.user_id === currentUser.id,
    );
  },
);

// OPEN SOURCE SUBSCRIPTIONS
export const activeOpenSourceSubscriptionSelector = createSelector(
  activeSubscriptionsSelector,
  (subscriptions) => subscriptions.find(isOpenSource),
);

// MARKETPLACE SUBSCRIPTIONS
export const markeplaceSubscriptionsSelector = createSelector(
  subscriptionsSortedSelector,
  (subscriptions) => subscriptions.filter(isMarketplace),
);

export const activeMarketplaceSubscriptionsSelector = createSelector(
  activeSubscriptionsSelector,
  (subscriptions) => subscriptions.filter(isMarketplace),
);

export const userMarketplaceSubscriptionSelector = createSelector(
  [activeMarketplaceSubscriptionsSelector, currentUserSelector],
  (subscriptions, currentUser) => {
    if (!currentUser) return undefined;
    return subscriptions.find(
      (subscription) => subscription.user_id === currentUser.id,
    );
  },
);

function isOpenSource(subscription: Subscriptions): boolean {
  return subscription.product_id === OPENSOURCE_PRODUCT_ID;
}

function isMarketplace(subscription: Subscriptions): boolean {
  return Boolean(subscription.marketplace_id) && !isOpenSource(subscription);
}

function isCommertial(subscription: Subscriptions): boolean {
  return !subscription.name.includes('GitHub') && !subscription.marketplace_id;
}
