/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectHistoryAdapter } from './defectHistoryAdapter';
import { defectHistoryFetchThunk } from './defectHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectHistorySlice = createSlice({
  name: 'defectHistory',
  initialState: defectHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectHistoryCleared(state) {
      state.loading = 'idle';
      defectHistoryAdapter.removeAll(state);
    },
    defectHistoryUpsertMany(
      state,
      action: PayloadAction<DefectHistory[] | Record<number, DefectHistory>>,
    ) {
      defectHistoryAdapter.upsertMany(state, action.payload);
    },
    defectHistoryUpsertOne(state, action: PayloadAction<DefectHistory>) {
      defectHistoryAdapter.upsertOne(state, action.payload);
    },
    defectHistoryUpdateOne(
      state,
      action: PayloadAction<Update<DefectHistory>>,
    ) {
      defectHistoryAdapter.updateOne(state, action.payload);
    },
    defectHistoryRemoveOne(state, action: PayloadAction<number>) {
      defectHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectHistoryFetchThunk.pending, handlePending);
    builder.addCase(defectHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectHistoryAdapter.upsertMany(state, action.payload.defectHistory);
    });
    builder.addCase(defectHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  defectHistoryCleared,
  defectHistoryUpsertMany,
  defectHistoryUpsertOne,
  defectHistoryUpdateOne,
  defectHistoryRemoveOne,
} = defectHistorySlice.actions;

export const defectHistoryReducer = defectHistorySlice.reducer;
