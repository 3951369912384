import { useCallback, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  App,
  AppVersion,
  AppVersionPlatVersion,
  BatchService,
  Plat,
  PlatVersion,
} from '@testquality/sdk';
import { useOverlayTriggerState } from 'react-stately';
import { useTranslation } from 'src/i18n/hooks';
import useMutation from 'src/hooks/useMutation';
import { platVersionDeleteOneThunk } from 'src/gen/domain/plat_version/platVersionThunk';
import { appVersionPlatVersionDeleteOneThunk } from 'src/gen/domain/app_version_plat_version/appVersionPlatVersionThunk';
import { appVersionPlatVersionsTreeSelector } from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import { platDeleteOneThunk } from 'src/gen/domain/plat/platThunk';
import { platVersionRemoveOne } from 'src/gen/domain/plat_version/platVersionSlice';

export default function useConfigurationView() {
  const { t } = useTranslation();
  const appDialog = useOverlayTriggerState({});
  const appVersionDialog = useOverlayTriggerState({});
  const appEditDialog = useOverlayTriggerState({});
  const platformDialog = useOverlayTriggerState({});
  const platVersionDialog = useOverlayTriggerState({});
  const [data, setData] = useState({});
  const dispatch = useAppDispatch();

  const confirmDialog = useConfirmDialog();

  const configurationsTree = useAppSelector(appVersionPlatVersionsTreeSelector);

  const clearData = () => {
    setData({});
  };

  const handleDeletePlatform = (platform, children) => {
    children.forEach((child) => {
      dispatch(platVersionRemoveOne(child.data.id));
    });
    return dispatch(platDeleteOneThunk({ id: platform.id }));
  };

  const handleDeletePlatVersion = (platVersion) => {
    return dispatch(platVersionDeleteOneThunk({ id: platVersion.id }));
  };

  const handleDeleteApp = (appVersionPlatVersions: AppVersionPlatVersion[]) => {
    const batch = new BatchService();
    appVersionPlatVersions.forEach((avpv) => {
      dispatch(
        appVersionPlatVersionDeleteOneThunk({
          batch,
          id: avpv.id,
        }),
      );
    });
    return batch.executeBatch();
  };

  const handleDeleteAppVersion = (
    appVersionPlatVersion: AppVersionPlatVersion,
  ) => {
    return dispatch(
      appVersionPlatVersionDeleteOneThunk({
        id: appVersionPlatVersion.id,
      }),
    );
  };

  const deletePlatform = (platform, children) => {
    confirmDialog.open({
      title: t('configurations.delete.title'),
      content: t('configurations.deletePlat.content'),
      mutation: () => handleDeletePlatform(platform, children),
    });
  };

  const deletePlatVersion = (platVersion) => {
    confirmDialog.open({
      title: t('configurations.delete.title'),
      content: t('configurations.deletePlatVersion.content'),
      mutation: () => handleDeletePlatVersion(platVersion),
    });
  };

  const deleteApp = (appVersionPlatVersions: AppVersionPlatVersion[]) => {
    confirmDialog.open({
      title: t('configurations.delete.title'),
      content: t('configurations.deleteApp.content'),
      mutation: () => handleDeleteApp(appVersionPlatVersions),
    });
  };

  const deleteAppVersion = (appVersionPlatVersion) => {
    confirmDialog.open({
      title: t('configurations.delete.title'),
      content: t('configurations.deleteAppVersion.content'),
      mutation: () => handleDeleteAppVersion(appVersionPlatVersion),
    });
  };

  const createPlatVersion = (plat: Plat) => {
    setData({ plat });
    platVersionDialog.open();
  };

  const createAppVersion = (platVersion: PlatVersion) => {
    setData({ platVersion });
    appDialog.open();
  };

  const editApp = (app: App) => {
    setData({ app });
    appEditDialog.open();
  };

  const editAppVersion = (app: App, appVersion: AppVersion) => {
    setData({ app, appVersion });
    appVersionDialog.open();
  };

  const toogleCollapse = (item) => {
    const { node, path, toggleChildrenVisibility, treeIndex } = item;
    const collapsible = Boolean(node.children?.length);
    if (!toggleChildrenVisibility || !collapsible) return;
    toggleChildrenVisibility({ node, path, treeIndex });
  };

  const editPlat = (plat: Plat) => {
    setData({ plat });
    platformDialog.open();
  };

  const editPlatVersion = (plat: Plat, platVersion: PlatVersion) => {
    setData({ plat, platVersion });
    platVersionDialog.open();
  };

  return {
    appDialog,
    appEditDialog,
    appVersionDialog,
    configurationsTree,
    confirmDialog,
    createAppVersion,
    createPlatVersion,
    data,
    clearData,
    deleteApp,
    deleteAppVersion,
    deletePlatform,
    deletePlatVersion,
    editApp,
    editAppVersion,
    editPlat,
    editPlatVersion,
    platformDialog,
    platVersionDialog,
    toogleCollapse,
  };
}

type ConfirmDialogState = {
  title?: string;
  content?: string;
  mutation?: () => Promise<any>;
};

function useConfirmDialog() {
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogState>({});
  const callback = useRef(confirmDialog.mutation);
  const state = useOverlayTriggerState({});
  const closeDialog = state.close;

  const close = useCallback(() => {
    setConfirmDialog({});
    closeDialog();
  }, [closeDialog]);

  const open = (...next: Parameters<typeof setConfirmDialog>) => {
    setConfirmDialog(...next);
    state.open();
  };

  callback.current = confirmDialog.mutation;
  const handleMutation = useCallback(() => {
    if (callback.current) return callback.current().then(close);
    return Promise.resolve();
  }, [close]);

  const mutation = useMutation(handleMutation);

  return {
    ...confirmDialog,
    close,
    isOpen: state.isOpen,
    open,
    mutation,
  };
}
