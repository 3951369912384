import React, { ReactElement } from 'react';
import { matchPath } from 'react-router';
import { Content, Separator } from '@bitmodern/bit-ui';
import {
  AccessRoleIcon,
  ArrowBackIcon,
  CompanyIcon,
  CustomizeIcon,
  FileIcon,
  IntegrationsIcon,
  LookupIcon,
  SettingsIcon,
  SpaceIcon,
  SubscriptionsIcon,
  UserIcon,
  UsersIcon,
  WatchIcon,
} from '@bitmodern/bit-ui/icons';
import { AsideMenuItem } from 'src/components/organisms';
import { useAppSelector } from '@bitmodern/redux/store';
import { isCurrentUserAdminSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import { routes } from 'src/components/Router';
import useParams from 'src/hooks/useParams';
import usePathname from 'src/hooks/usePathname';
import { useTranslation } from 'src/i18n/hooks';
import styles from './SettingsView.module.scss';

type Sections =
  | 'profile'
  | 'company'
  | 'users'
  | 'accessRoles'
  | 'lookup'
  | 'integrations'
  | 'customization'
  | 'subscriptions'
  | 'space'
  | 'watch'
  | 'configurations'
  | 'import'
  | 'importAutomation';

type Mapping = {
  [k in Sections]: string | string[];
};

const mapping: Mapping = {
  profile: routes.PROFILE.path,
  company: routes.COMPANY.path,
  users: [routes.USERS.path, routes.USER.path],
  accessRoles: routes.ACCESS_ROLES.path,
  lookup: routes.LOOKUP.path,
  integrations: [
    routes.INTEGRATIONS.path,
    routes.INTEGRATION_SETUP.path,
    routes.INTEGRATION.path,
    routes.PROJECT_INTEGRATION.path,
    routes.INTEGRATION_PROJECT_CREATE.path,
  ],
  customization: routes.CUSTOMIZATION.path,
  subscriptions: routes.SUBSCRIPTIONS.path,
  space: routes.SPACE.path,
  watch: routes.WATCH.path,
  configurations: routes.CONFIGURATIONS.path,
  import: routes.IMPORT.path,
  importAutomation: routes.IMPORT.path,
};

type Props = {
  children: ReactElement;
};

export default function SettingsView({ children }: Props) {
  const { site } = useParams<typeof routes.SETTINGS.path>();
  const isUserAdmin = useAppSelector(isCurrentUserAdminSelector);
  const pathname = usePathname();
  const { t } = useTranslation();

  const current =
    Object.entries(mapping).find((entrie) => {
      const values = Array.isArray(entrie[1]) ? entrie[1] : [entrie[1]];
      return values
        .map(
          (path) =>
            matchPath(pathname, {
              path,
              exact: true,
              strict: true,
            }) !== null,
        )
        .includes(true);
    })?.[0] || '';

  return (
    <Content className={styles.content} fullHeight>
      <aside>
        <ul className={styles.menu}>
          <AsideMenuItem
            className={styles.back}
            icon={<ArrowBackIcon size={18} />}
            to={routes.HOME({ site })}>
            {t('settings.menu.back')}
          </AsideMenuItem>
          <Separator color="primaryMain" size="small" />
          <AsideMenuItem
            icon={<UserIcon size={18} />}
            selected={current === 'profile'}
            to={routes.PROFILE({ site })}>
            {t('settings.menu.profile')}
          </AsideMenuItem>
          {isUserAdmin && (
            <AsideMenuItem
              icon={<CompanyIcon size={18} />}
              selected={current === 'company'}
              to={routes.COMPANY({ site })}>
              {t('settings.menu.company')}
            </AsideMenuItem>
          )}
          <AsideMenuItem
            icon={<UsersIcon size={18} />}
            selected={current === 'users'}
            to={routes.USERS({ site })}>
            {t('settings.menu.users')}
          </AsideMenuItem>
          {isUserAdmin && (
            <AsideMenuItem
              icon={<AccessRoleIcon size={18} />}
              selected={current === 'accessRoles'}
              to={routes.ACCESS_ROLES({ site })}>
              {t('settings.menu.accessRoles')}
            </AsideMenuItem>
          )}
          <AsideMenuItem
            icon={<WatchIcon size={18} />}
            selected={current === 'watch'}
            to={routes.WATCH({ site })}>
            {t('settings.menu.watches')}
          </AsideMenuItem>
          <AsideMenuItem
            icon={<IntegrationsIcon size={18} />}
            selected={current === 'integrations'}
            to={routes.INTEGRATIONS({ site })}>
            {t('settings.menu.integrations')}
          </AsideMenuItem>
          {isUserAdmin && (
            <>
              <AsideMenuItem
                icon={<LookupIcon size={18} />}
                selected={current === 'lookup'}
                to={routes.LOOKUP({ site })}>
                {t('settings.menu.lookup')}
              </AsideMenuItem>
              <AsideMenuItem
                icon={<CustomizeIcon size={18} />}
                selected={current === 'customization'}
                to={routes.CUSTOMIZATION({ site })}>
                {t('settings.menu.customization')}
              </AsideMenuItem>
            </>
          )}
          <AsideMenuItem
            icon={<SettingsIcon size={18} />}
            selected={current === 'configurations'}
            to={routes.CONFIGURATIONS({ site })}>
            {t('settings.menu.configurations')}
          </AsideMenuItem>
          <AsideMenuItem
            icon={<SubscriptionsIcon size={18} />}
            selected={current === 'subscriptions'}
            to={routes.SUBSCRIPTIONS({ site })}>
            {t('settings.menu.subscriptions')}
          </AsideMenuItem>
          <AsideMenuItem
            icon={<FileIcon size={18} />}
            selected={current === 'importAutomation'}
            to={routes.AUTOMATIONS({ site })}>
            {t('settings.menu.importAutomation')}
          </AsideMenuItem>
          {isUserAdmin && (
            <>
              <AsideMenuItem
                icon={<SpaceIcon size={18} />}
                selected={current === 'space'}
                to={routes.SPACE({ site })}>
                {t('settings.menu.space')}
              </AsideMenuItem>
            </>
          )}
        </ul>
      </aside>
      <section className={styles.section}>{children}</section>
    </Content>
  );
}
