/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { nativeDefectResAdapter } from './nativeDefectResAdapter';

export const nativeDefectResSelectors = nativeDefectResAdapter.getSelectors(
  (state: State) => state.gen.nativeDefectRes,
);
