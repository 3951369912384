import { FormikProps } from 'formik';
import React from 'react';
import { Avatar, Button, Grid, Input, SelectMultiple } from '@bitmodern/bit-ui';
import { AccessRole, User } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import styles from './UserForm.module.scss';

type Props = {
  accessRoles: AccessRole[];
  formik: FormikProps<
    Pick<User, 'email' | 'family_name' | 'given_name'> & {
      picture?: string | File;
      searchHistory: number;
      lastViewed: number;
      accessRoles: number[];
      password: string;
      passwordConfirm: string;
    }
  >;
};

export default function UserForm({ accessRoles, formik }: Props) {
  const { t } = useTranslation();
  const onChangeAvatar = (event) => {
    formik.setFieldValue('picture', event.currentTarget.files[0]);
  };

  const onChangeAccessRoles = (values) => {
    formik.setFieldValue('accessRoles', values);
  };

  const pictureSource =
    formik.values?.picture instanceof File
      ? URL.createObjectURL(formik.values.picture)
      : formik.values.picture;

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <div className={styles.avatarUpload}>
        <Avatar
          className={styles.avatar}
          name={formik.values.given_name}
          src={pictureSource}
          size={72}
        />
        <div>
          <label className={styles.avatarInput} htmlFor="picture">
            {t('userForm.picture')}
            <input
              className={styles.avatarInput}
              id="picture"
              name="picture"
              onChange={onChangeAvatar}
              onFocus={formik.handleBlur}
              type="file"
            />
          </label>
          <div className={styles.pictureSize}>{t('userForm.pictureSize')}</div>
        </div>
      </div>
      <Grid.Row gutter={[16, 0]}>
        <Grid.Col lg={6} span={12}>
          <Input
            error={formikError(formik, 'given_name')}
            fullWidth
            label={t('userForm.given_name')}
            name="given_name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.given_name}
          />
        </Grid.Col>
        <Grid.Col lg={6} span={12}>
          <Input
            error={formikError(formik, 'family_name')}
            fullWidth
            label={t('userForm.family_name')}
            name="family_name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.family_name}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row gutter={[16, 0]}>
        <Grid.Col lg={6} span={12}>
          <Input
            error={formikError(formik, 'email')}
            fullWidth
            label={t('userForm.email')}
            name="email"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.email}
          />
        </Grid.Col>
        <Grid.Col lg={6} span={12}>
          <SelectMultiple
            error={formikError(formik, 'accessRoles')}
            label={t('userForm.accessRoles')}
            name="accessRoles"
            onChange={onChangeAccessRoles}
            onFocus={formik.handleBlur}
            options={accessRoles.map((role) => ({
              label: role.name,
              value: role.id,
            }))}
            required
            values={formik.values.accessRoles}
          />
        </Grid.Col>
      </Grid.Row>
      <div className={styles.submit}>
        <Button
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
          type="submit">
          {t('userForm.update')}
        </Button>
      </div>
    </form>
  );
}
