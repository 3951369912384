import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
    </g>
  </>,
  'Download',
);
