import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Input } from '@bitmodern/bit-ui';
import { TimerIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import styles from './Duration.module.scss';

type Props = {
  disabled?: boolean;
  className?: string;
  onChange: (duration: number) => void;
  value: number;
};

export default function Duration({
  className = '',
  disabled = false,
  value = 0,
  onChange,
}: Props) {
  const { t } = useTranslation();

  return (
    <Input
      isDisabled={disabled}
      className={`${styles.duration} ${className}`}
      label={t('duration.label')}
      onChange={(event) => onChange(parseInt(event.target.value || '0', 10))}
      showLabel={false}
      startAdornment={<TimerIcon color={vars.textSecondary} size={18} />}
      type="number"
      value={value.toString()}
    />
  );
}
