export enum BooleanEnum {
  True = 'true',
  False = 'false',
}

type BooleanKeys = `booleanEnum.${BooleanEnum}`;

export const BooleanEnumDisplay: ReadonlyMap<BooleanEnum, BooleanKeys> =
  new Map([
    [BooleanEnum.True, 'booleanEnum.true'],
    [BooleanEnum.False, 'booleanEnum.false'],
  ]);

export function booleanEnumToBoolean(value: BooleanEnum): boolean {
  return value === BooleanEnum.True;
}

export function booleanToBooleanEnum(value: boolean): BooleanEnum {
  return value ? BooleanEnum.True : BooleanEnum.False;
}
