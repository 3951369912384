/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { casePriorityAdapter } from './casePriorityAdapter';

export const casePrioritySelectors = casePriorityAdapter.getSelectors(
  (state: State) => state.gen.casePriority,
);
