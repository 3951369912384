import { createSelector } from '@reduxjs/toolkit';
import { State } from '@bitmodern/redux/store';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { requirementSelectors } from 'src/gen/domain/requirement/requirementSelector';
import { requirementTestSelectors } from 'src/gen/domain/requirement_test/requirementTestSelector';
import { runResultsByRunIdSelector } from '../runResults/selectors';
import { statusSelector } from '../statuses/selectors';
import { defectsByTestSelector } from '../defects/selectors';
import { requirementsTestByTestSelector } from '../requirementTest/selectors';
import { planSuiteTestIncludeSelectors } from 'src/gen/domain/plan_suite_test_include/planSuiteTestIncludeSelector';
import { Requirement, Run } from '@testquality/sdk';
import { runSelectors } from 'src/gen/domain/run/runSelector';

export function requirementsSelector(state: State) {
  return requirementSelectors.selectAll(state);
}

export function requirementsByTestIdSelector(state: State, testId: number) {
  const requirementTest = requirementsTestByTestSelector(state, testId);
  return requirementSelectors
    .selectAll(state)
    .filter((requirement) =>
      requirementTest.find((rt) => rt.requirement_id === requirement.id),
    );
}

export const makeRequirementsByProject = () =>
  createSelector(
    [requirementsSelector, (_, args: { projectId?: number }) => args],
    (requirements, { projectId }) => {
      return requirements.filter(
        (requirement) =>
          (requirement.related_type === RelatedType.TEST ||
            requirement.related_type === null) &&
          requirement.project_id === projectId,
      );
    },
  );

// temporary solution until I figure out how to are we handling memo selectors
// with props
export const requirementsByProjectSelector = makeRequirementsByProject();

type RequirementsByProjectWithStatusArgs = {
  projectId?: number;
  search?: string;
  statusFilter?: string;
};

export const makeRequirementsByProjectWithStatus = () =>
  createSelector(
    [
      makeRequirementsByProject(),
      (_, args: RequirementsByProjectWithStatusArgs) => args,
    ],
    (requirements, { search, statusFilter }) => {
      let toReturn = [...requirements].sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
      if (!statusFilter && (!search || search.length <= 0)) return toReturn;

      if (search) {
        toReturn = toReturn.filter((requirement) =>
          requirement.payload?.summary
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()),
        );
      }

      if (statusFilter) {
        toReturn = toReturn.filter(
          (requirement) => requirement.payload.status.name === statusFilter,
        );
      }
      return toReturn;
    },
  );

export const makeRequirementsByPlan = () =>
  createSelector(
    [
      requirementSelectors.selectEntities,
      planSuiteTestIncludeSelectors.selectEntities,
      requirementTestSelectors.selectEntities,
      (_, args: { planId?: number }) => args,
    ],
    (requirements, pstIncludes, requirementTests, { planId }) => {
      const planRequirements: Requirement[] = [];
      if (!planId) return planRequirements;

      // Map <testIds, planIds>
      const testIdsPlanIdsMap = new Map<number, number>();
      for (const id in pstIncludes) {
        const pst = pstIncludes[id];
        if (pst?.test_id && pst?.plan_id === planId) {
          testIdsPlanIdsMap.set(pst.test_id, planId);
        }
      }

      for (const id in requirementTests) {
        const requirementTest = requirementTests[id];
        if (!requirementTest?.test_id || !requirementTest?.requirement_id)
          continue;
        if (!testIdsPlanIdsMap.get(requirementTest.test_id)) continue;
        const requirement = requirements[requirementTest.requirement_id];
        if (requirement) {
          planRequirements.push(requirement);
        }
      }

      return planRequirements;
    },
  );

export function requirementByIdSelector(state: State, requirementId) {
  return requirementSelectors
    .selectAll(state)
    .find((requirement) => requirement.id === requirementId);
}

export function requirementsByRunResultSelector(state: State, runId: number) {
  const runResults = runResultsByRunIdSelector(state, { runId });
  const testIds = runResults.map((r) => r.test_id);
  const reqTests = requirementTestSelectors.selectAll(state);
  const reqTestOnRun = reqTests.filter((requirement) => {
    if (!requirement.test_id) return false;
    return testIds.includes(requirement.test_id);
  });

  const reqWithStatus = reqTestOnRun.reduce((acc, next) => {
    if (next.requirement_id && !acc[next.requirement_id]) {
      acc[next.requirement_id] = {
        requirement: requirementSelectors.selectById(
          state,
          next.requirement_id,
        ),
        testStatus: {
          pass: 0,
          fail: 0,
          skip: 0,
          defects: 0,
        },
        requirementsTests: [],
      };
    }

    const result = runResults.find((rr) => rr.test_id === next.test_id);
    if (result && next.requirement_id) {
      acc[next.requirement_id].requirementsTests.push(result.test_id);
      const status = statusSelector(state, result.status_id);
      switch (status?.key) {
        case 1:
          acc[next.requirement_id].testStatus.pass += 1;
          break;
        case 3:
          acc[next.requirement_id].testStatus.fail += 1;
          break;
        case 6:
          acc[next.requirement_id].testStatus.skip += 1;
          break;
        default:
          break;
      }

      acc[next.requirement_id].testStatus.defects += defectsByTestSelector(
        state,
        { testId: result.test_id },
      ).length;
    }

    return acc;
  }, {});

  const toReturn = Object.keys(reqWithStatus).map((ur) => {
    return {
      requirement: reqWithStatus[ur].requirement,
      testStatus: reqWithStatus[ur].testStatus,
      requirementsTests: reqWithStatus[ur].requirementsTests,
    };
  });

  return toReturn;
}

export function runsByRequirementAnalysis(
  state: State,
  args: { runs?: Run[] },
) {
  if (!args.runs) {
    return [];
  }
  const runs = runSelectors.selectAll(state);

  const toReturn: Run[] = args.runs.reduce<Run[]>((acu, next) => {
    const run = runs?.find((r) => r.id === next.id);
    if (run) {
      const toAdd = { ...run, ...next };
      acu.push(toAdd);
    }

    return acu;
  }, []);

  return toReturn;
}
