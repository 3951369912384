import { Defect } from '@testquality/sdk';
import { RunResultTreeItem } from 'src/packages/redux/state/runResults/selectors';
import { suitesTreeByDefect } from 'src/packages/redux/state/suites/selectors';
import { useMaker } from 'src/packages/redux/hooks';

export type Args = {
  defect: Defect;
  projectId: number;
};

export type ReturnType = {
  tree: RunResultTreeItem[];
};

export default function useTests({ defect, projectId }: Args): ReturnType {
  const { tree } = useGetDefectTests({ defect, projectId });
  return { tree };
}

function useGetDefectTests({ defect, projectId }) {
  const tree = useMaker(suitesTreeByDefect, {
    projectId: parseInt(projectId, 10),
    defect,
  });

  return { tree };
}
