import React, { useState } from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { Button, Clipboard, Dialog, Input } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { createPersonalAccessToken } from '@bitmodern/services/personalAccessToken';
import { useTranslation } from 'src/i18n/hooks';
import styles from './PersonalAccessTokenCreate.module.scss';

type Props = {
  onClose: () => void;
  open: boolean;
  onCreate: (token: any) => void;
};

export default function PersonalAccessTokenCreate({
  onClose,
  onCreate,
  open,
}: Props) {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  const formik = useFormik({
    onSubmit,
    initialValues: { name: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().label('personalTokenCreate.form.name'),
    }),
  });

  const handleClose = () => {
    onClose();
    setToken('');
    formik.resetForm();
  };

  function onSubmit(values) {
    return createPersonalAccessToken(values).then((pat) => {
      setToken(pat.accessToken);
      onCreate(pat.token);
    });
  }

  return (
    <Dialog
      isDismissable={false}
      isOpen={open}
      onClose={handleClose}
      size="medium"
      title={t('personalTokenCreate.title')}>
      {!token && (
        <div className={styles.content}>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Input
              error={formikError(formik, 'name')}
              fullWidth
              label={t('personalTokenCreate.form.name')}
              name="name"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              required
              value={formik.values.name}
            />
            <div className={styles.actions}>
              <Button loading={formik.isSubmitting} type="submit">
                {t('personalTokenCreate.form.submit')}
              </Button>
            </div>
          </form>
        </div>
      )}
      {token && (
        <>
          <div className={styles.content}>
            <div className={styles.once}>
              {t('personalTokenCreate.showedOnce')}
            </div>
            <Clipboard text={token} type="horizontal">
              {token}
            </Clipboard>
          </div>
          <div className={styles.actions}>
            <Button onClick={handleClose} type="submit">
              {t('personalTokenCreate.close')}
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
}
