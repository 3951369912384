import React, { ComponentProps, useMemo } from 'react';
import { App, AppVersion } from '@testquality/sdk';
import { useFormik } from 'formik';
import { useTranslation } from 'src/i18n/hooks';
import { Button, Dialog, Input, Grid } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import {
  appVersionCreateOneThunk,
  appVersionUpdateOneThunk,
} from 'src/gen/domain/app_version/appVersionThunk';
import { useAppDispatch } from '@bitmodern/redux/store';
import styles from './AppVersionFormDialog.module.scss';

const validationSchema = Yup.object().shape({
  major: Yup.string().required().label('platVersionForm.form.major'),
  minor: Yup.string().required().label('platVersionForm.form.minor'),
});

type Props = {
  app?: App;
  appVersion?: AppVersion;
} & Pick<ComponentProps<typeof Dialog>, 'onClose' | 'isOpen'>;

export default function AppVersionFormDialog({
  app,
  appVersion,
  onClose,
  isOpen,
}: Props) {
  const isEdit = Boolean(appVersion);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues = useMemo(() => {
    return {
      major: appVersion?.major || '',
      minor: appVersion?.minor || '',
    };
  }, [appVersion]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  function onSubmit(values) {
    if (!app) return Promise.reject();
    if (appVersion) {
      return dispatch(
        appVersionUpdateOneThunk({
          id: appVersion.id,
          data: {
            is_default: false,
            major: values.major,
            minor: values.minor,
          },
        }),
      ).then(onClose);
    }

    return dispatch(
      appVersionCreateOneThunk({
        data: {
          is_default: false,
          major: values.major,
          minor: values.minor,
          app_id: app.id,
        },
      }),
    ).then(onClose);
  }

  const handleClose = () => {
    formik.resetForm();
    if (onClose) onClose();
  };

  const title = isEdit ? t('appVersionForm.edit') : t('appVersionForm.create');
  const submit = isEdit
    ? t('appVersionForm.form.update')
    : t('appVersionForm.form.create');

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} size="medium" title={title}>
      <form
        className={styles.content}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}>
        <Grid.Row gutter={[16, 0]}>
          <Grid.Col span={12} sm={6}>
            <Input
              autoFocus
              error={formikError(formik, 'major')}
              fullWidth
              label={t('appVersionForm.form.major')}
              name="major"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              placeholder={t('appVersionForm.form.majorPlaceholder')}
              value={formik.values.major}
            />
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <Input
              error={formikError(formik, 'minor')}
              fullWidth
              label={t('appVersionForm.form.minor')}
              name="minor"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              placeholder={t('appVersionForm.form.minorPlaceholder')}
              value={formik.values.minor}
            />
          </Grid.Col>
        </Grid.Row>

        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit">
          {submit}
        </Button>
      </form>
    </Dialog>
  );
}
