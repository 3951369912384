import { createSelector } from '@reduxjs/toolkit';

import { StatusEnum } from 'src/enums/StatusEnum';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { State } from '@bitmodern/redux/store';
import { statusTypeNegativeSelector } from '@bitmodern/redux/state/statusTypes/selectors';

export function statusesSelector(state: State) {
  return statusSelectors.selectAll(state);
}

export const passStatusSelector = createSelector(statusesSelector, (statuses) =>
  statuses.find((s) => s.key === StatusEnum.Pass),
);

export const pendingStatusSelector = createSelector(
  statusesSelector,
  (statuses) => statuses.find((s) => s.key === StatusEnum.Pending),
);

export const failStatusSelector = createSelector(statusesSelector, (statuses) =>
  statuses.find((s) => s.key === StatusEnum.Fail),
);

export const skipStatusSelector = createSelector(statusesSelector, (statuses) =>
  statuses.find((s) => s.key === StatusEnum.Skip),
);

export const retestStatusSelector = createSelector(
  statusesSelector,
  (statuses) => statuses.find((s) => s.key === StatusEnum.Retest),
);

export const negativeStatusesSelector = createSelector(
  statusesSelector,
  statusTypeNegativeSelector,
  (statuses, negativeStatusType) =>
    statuses.filter((s) => s.status_type_id === negativeStatusType?.id),
);

export function statusSelector(state: State, id: number) {
  return statusSelectors.selectById(state, id);
}
