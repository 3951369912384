import React, { useCallback } from 'react';
import {
  BaseIntegration,
  Integration,
  IntegrationUser,
  showNotificationError,
} from '@testquality/sdk';
import { integrationDeleteOneThunk } from 'src/gen/domain/integration/integrationThunk';
import { notification } from '@bitmodern/bit-ui';
import {
  authorizeIntegrationThunk,
  setupIntegrationThunk,
} from '@bitmodern/redux/state/integration/thunks';
import { useAppDispatch } from '@bitmodern/redux/store';
import IntegrationSetupView from '../IntegrationSetupView/IntegrationSetupView';
import { integrationUserDetachThunk } from 'src/gen/domain/integration_user/integrationUserThunk';

type Props = {
  site: string;
  projectCreate: boolean;
  baseIntegration: BaseIntegration;
  integration?: Integration;
  integrationUser?: IntegrationUser;
};

export function IntegrationCommon({
  site,
  projectCreate,
  baseIntegration,
  integration,
  integrationUser,
}: Props) {
  const dispatch = useAppDispatch();

  const setupIntegration = useCallback(
    ({ org, jiraInstance, serverUrl }) =>
      dispatch(
        setupIntegrationThunk({
          baseIntegration,
          integration,
          org,
          jiraInstance,
          serverUrl,
        }),
      ),
    [dispatch, baseIntegration, integration],
  );

  const authorizeIntegration = useCallback(
    () =>
      dispatch(
        authorizeIntegrationThunk({
          baseIntegration,
          integration,
          site,
          projectCreate,
        }),
      ),
    [dispatch, integration, site, baseIntegration, projectCreate],
  );

  const onDisassociate = useCallback(async () => {
    try {
      if (integrationUser) {
        await dispatch(integrationUserDetachThunk(integrationUser));
      }
    } catch (error) {
      showNotificationError(error as any);
    }
  }, [dispatch, integrationUser]);

  const onConfirmDelete = useCallback(() => {
    if (!integration) return Promise.resolve();
    return dispatch(integrationDeleteOneThunk({ id: integration.id })).then(
      () => {
        notification.open({
          type: 'success',
          message: 'Integration removed',
        });
      },
    );
  }, [dispatch, integration]);

  return (
    <IntegrationSetupView
      baseIntegration={baseIntegration}
      integration={integration}
      integrationUser={integrationUser}
      onAuthorize={authorizeIntegration}
      onDisassociate={onDisassociate}
      onConfirmDelete={onConfirmDelete}
      onSubmitJira={setupIntegration}
    />
  );
}
