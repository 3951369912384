import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import {
  Button,
  PanelActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@bitmodern/bit-ui';
import { Requirement } from '@testquality/sdk';
import styles from './Requirements.module.scss';
import EmptyList from '../EmptyList';
import useMutation from '../../../hooks/useMutation';
import { RequirementItem } from '../RequirementItem';

type Props = {
  requirements: Requirement[];
  onAddRequirement?: () => Promise<any>;
};

export default function Requirements({
  requirements,
  onAddRequirement,
}: Props) {
  const { t } = useTranslation();

  const allowAdding = onAddRequirement !== undefined;
  const onAddRequirementFn =
    onAddRequirement !== undefined ? onAddRequirement : () => Promise.resolve();
  const addRequirementMutation = useMutation(onAddRequirementFn);

  const isEmpty = requirements.length === 0;

  return (
    <div className={styles.requirements}>
      {isEmpty && allowAdding ? (
        <EmptyList
          button={
            <Button
              loading={addRequirementMutation.isLoading}
              onClick={addRequirementMutation.mutate}
              color="accent">
              {t('requirements.empty.button')}
            </Button>
          }
          className={styles.empty}
          description={t('requirements.empty.desc')}
          title={t('requirements.empty.title')}
        />
      ) : (
        <>
          <div className={styles.list}>
            <Table>
              <TableHead className={styles.head}>
                <TableCell>{t('requirements.head.key')}</TableCell>
                <TableCell>{t('requirements.head.name')}</TableCell>
                <TableCell>{t('requirements.head.defects')}</TableCell>
                <TableCell>{t('requirements.head.coverage')}</TableCell>
                <TableCell>{t('requirements.head.testsInclusion')}</TableCell>
                <TableCell>{t('requirements.head.created')}</TableCell>
                <TableCell>{t('requirements.head.assigned')}</TableCell>
                <TableCell>{t('requirements.head.status')}</TableCell>
              </TableHead>
              <TableBody>
                {requirements.map((requirement) => (
                  <RequirementItem
                    requirement={requirement}
                    key={requirement.id}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
          {allowAdding && (
            <PanelActions>
              <Button onClick={addRequirementMutation.mutate} color="accent">
                {t('requirements.add')}
              </Button>
            </PanelActions>
          )}
        </>
      )}
    </div>
  );
}
