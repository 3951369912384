import React, { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import Modal from '../Modal';
import Surface from '../Surface';
import styles from './Dialog.module.scss';
import PanelHeader from '../PanelHeader';
import { CancelIcon } from '../icons';
import IconButton from '../IconButton';

type Props = ComponentProps<typeof Modal> & {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  title?: ReactNode;
};

export default function Dialog({
  className = '',
  children,
  onClose,
  size = 'medium',
  title,
  ...rest
}: Props) {
  const handleClose = () => {
    if (onClose) onClose();
  };

  const surfaceCN = classnames(className, styles.surface, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.xlarge]: size === 'xlarge',
  });

  return (
    <Modal onClose={onClose} {...rest}>
      <motion.div
        className={styles.animator}
        animate="visible"
        initial="initial"
        exit="hidden"
        variants={{
          initial: {
            scale: 0.8,
            y: 32,
          },
          visible: {
            scale: 1,
            y: 0,
          },
          hidden: {
            scale: 0.8,
          },
        }}>
        <Surface className={surfaceCN}>
          <PanelHeader
            title={title}
            actions={
              onClose && (
                <IconButton boxed={false} onClick={handleClose}>
                  <CancelIcon color={vars.textPrimary} />
                </IconButton>
              )
            }
          />
          {children}
        </Surface>
      </motion.div>
    </Modal>
  );
}
