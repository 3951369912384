import { State } from '@bitmodern/redux/store';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import attachmentAdapter from 'src/gen/domain/attachment/attachmentAdapter';

const attachmentsSelectors = attachmentAdapter.getSelectors(
  (state: State) => state.gen.attachment,
);

export function attachmentsSelector(state: State) {
  return attachmentsSelectors.selectAll(state);
}

export function attachmentsByRelatedIdSelector(state: State, testId: number) {
  return attachmentsSelectors
    .selectAll(state)
    .filter((attachment) => attachment.related_id === testId);
}

export function attachmentsByTestSelector(state: State, testId: number) {
  return attachmentsSelector(state).filter(
    (attachment) =>
      attachment.related_type === RelatedType.TEST &&
      attachment.related_id === testId,
  );
}

export function attachmentSelectorById(state: State, id: string) {
  return attachmentsSelectors.selectById(state, id);
}

export function attachmentsByInvoiceSelector(state: State) {
  return attachmentsSelector(state).filter(
    (attachment) => attachment.related_type === RelatedType.INVOICE,
  );
}
