/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SubscriptionUserHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { subscriptionUserHistoryAdapter } from './subscriptionUserHistoryAdapter';
import { subscriptionUserHistoryFetchThunk } from './subscriptionUserHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const subscriptionUserHistorySlice = createSlice({
  name: 'subscriptionUserHistory',
  initialState:
    subscriptionUserHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    subscriptionUserHistoryCleared(state) {
      state.loading = 'idle';
      subscriptionUserHistoryAdapter.removeAll(state);
    },
    subscriptionUserHistoryUpsertMany(
      state,
      action: PayloadAction<
        SubscriptionUserHistory[] | Record<number, SubscriptionUserHistory>
      >,
    ) {
      subscriptionUserHistoryAdapter.upsertMany(state, action.payload);
    },
    subscriptionUserHistoryUpsertOne(
      state,
      action: PayloadAction<SubscriptionUserHistory>,
    ) {
      subscriptionUserHistoryAdapter.upsertOne(state, action.payload);
    },
    subscriptionUserHistoryUpdateOne(
      state,
      action: PayloadAction<Update<SubscriptionUserHistory>>,
    ) {
      subscriptionUserHistoryAdapter.updateOne(state, action.payload);
    },
    subscriptionUserHistoryRemoveOne(state, action: PayloadAction<number>) {
      subscriptionUserHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subscriptionUserHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      subscriptionUserHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        subscriptionUserHistoryAdapter.upsertMany(
          state,
          action.payload.subscriptionUserHistory,
        );
      },
    );
    builder.addCase(subscriptionUserHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  subscriptionUserHistoryCleared,
  subscriptionUserHistoryUpsertMany,
  subscriptionUserHistoryUpsertOne,
  subscriptionUserHistoryUpdateOne,
  subscriptionUserHistoryRemoveOne,
} = subscriptionUserHistorySlice.actions;

export const subscriptionUserHistoryReducer =
  subscriptionUserHistorySlice.reducer;
