import React, { useCallback, useEffect, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { FormikHelpers, useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { useOverlayTriggerState } from 'react-stately';
import { Button, Grid, Input } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import { Client } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { currentClientSelector } from '@bitmodern/redux/state/clients/selectors';
import { authenticateAction } from '@bitmodern/redux/state/authentication/actions';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import {
  ConfirmDialog,
  DescriptiveAction,
  SettingsPanel,
} from 'src/components/organisms';
import { clientUpdateOneThunk } from 'src/gen/domain/client/clientThunk';
import useMutation from 'src/hooks/useMutation';
import { validateSiteName } from '@bitmodern/services/SiteVerificationService';
import { CompanyIcon } from '@bitmodern/bit-ui/icons';
import { getClient } from 'src/Client';
import vars from 'src/export.scss';
import styles from './CompanyView.module.scss';
import { deleteClientThunk } from '@bitmodern/redux/state/clients/thunks';
import { signupOptionSelectors } from 'src/gen/domain/signup_option/signupOptionSelector';
import { AutomaticSignUp } from './components/AutomaticSignUp';
import { signupOptionFetchManyThunk } from 'src/gen/domain/signup_option/signupOptionThunk';
import { SSOConfig } from './components/SSOConfig';

type Values = Pick<
  Client,
  'company_email' | 'company_phone' | 'company_url' | 'company_name' | 'name'
>;

const validationSchema = Yup.object().shape({
  company_email: Yup.string()
    .email()
    .nullable()
    .label('client.form.company_email'),
  name: Yup.string().required().slug().label('client.form.name'),
  company_url: Yup.string().url().nullable().label('client.form.company_url'),
});

export default function CompanyView() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const deleteClient = useOverlayTriggerState({});

  const client = useAppSelector(currentClientSelector);
  const signupOptions = useAppSelector(signupOptionSelectors.selectAll);

  useEffect(() => {
    dispatch(signupOptionFetchManyThunk({ params: { per_page: -1 } }));
  }, [dispatch]);

  const initialValues = useMemo<Values>(
    () => ({
      company_email: client?.company_email || '',
      company_phone: client?.company_phone || '',
      company_url: client?.company_url || '',
      company_name: client?.company_name || '',
      name: client?.name || '',
      openid_url: client?.openid_url || '',
      openid_client_id: client?.openid_client_id || '',
    }),
    [client],
  );

  const formik = useFormik<Values>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  const onConfirmDelete = useCallback(() => {
    if (!client) return Promise.resolve();
    return dispatch(deleteClientThunk(client.id));
  }, [client, dispatch]);

  const deleteMutation = useMutation(onConfirmDelete);

  async function onSubmit(
    values: Values,
    { setStatus }: FormikHelpers<Values>,
  ) {
    if (!client) return;

    if (initialValues.name !== values.name) {
      const { nextName } = await validateSiteName(values.name);
      setStatus({ validSiteName: nextName });
      if (nextName) return;
    }

    await dispatch(clientUpdateOneThunk({ id: client.id, data: values }))
      .then(unwrapResult)
      .then(({ changes }) => {
        if (changes.name && changes.name !== initialValues.name) {
          history.replace(routes.COMPANY({ site: changes.name }));
          getClient()
            .getAuth()
            .refresh()
            .then((token) => {
              if (token) {
                dispatch(authenticateAction(token));
              }
            });
        }
      });
  }

  const onSubmitSSO = async (values) => {
    if (!client) return;
    return dispatch(clientUpdateOneThunk({ id: client.id, data: values }));
  };

  const handleNameChange = (name: string) => {
    if (formik.status) formik.setStatus(undefined);
    formik.setFieldValue('name', name);
  };

  const nameError = formik.status?.validSiteName ? (
    <div>
      {t('client.error.invalidName')}
      <span
        className={styles.errorAction}
        onClick={() => handleNameChange(formik.status.validSiteName)}>
        {formik.status.validSiteName}
      </span>
    </div>
  ) : (
    formikError(formik, 'name')
  );

  return (
    <SettingsPanel
      icon={<CompanyIcon color={vars.textPrimary} size={24} />}
      title={t('client.title')}>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid.Row gutter={[16, 0]}>
          <Grid.Col lg={6} span={12}>
            <Input
              error={nameError}
              fullWidth
              label={t('client.form.name')}
              name="name"
              onChange={(e) => handleNameChange(e.target.value)}
              onFocus={formik.handleBlur}
              required
              value={formik.values.name}
            />
          </Grid.Col>
          <Grid.Col lg={6} span={12}>
            <Input
              error={formikError(formik, 'company_email')}
              fullWidth
              label={t('client.form.company_email')}
              name="company_email"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              type="email"
              value={formik.values.company_email}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row gutter={[16, 0]}>
          <Grid.Col lg={6} span={12}>
            <Input
              error={formikError(formik, 'company_phone')}
              fullWidth
              label={t('client.form.company_phone')}
              name="company_phone"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              value={formik.values.company_phone}
            />
          </Grid.Col>
          <Grid.Col lg={6} span={12}>
            <Input
              error={formikError(formik, 'company_name')}
              fullWidth
              label={t('client.form.company_name')}
              name="company_name"
              onChange={formik.handleChange}
              onFocus={formik.handleBlur}
              value={formik.values.company_name}
            />
          </Grid.Col>
        </Grid.Row>
        <Input
          error={formikError(formik, 'company_url')}
          fullWidth
          label={t('client.form.company_url')}
          name="company_url"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          value={formik.values.company_url}
        />
        <div className={styles.submit}>
          <Button loading={formik.isSubmitting} type="submit">
            {t('client.form.submit')}
          </Button>
        </div>
      </form>
      <SSOConfig client={client} onSubmit={onSubmitSSO} />
      <AutomaticSignUp signupOptions={signupOptions} />
      <DescriptiveAction
        action={
          <Button
            color="primaryLight"
            onClick={deleteClient.open}
            size="medium">
            {t('client.deleteClient.action')}
          </Button>
        }
        description={t('client.deleteClient.description')}
        title={t('client.deleteClient.title')}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteClient.close}
        onConfirm={deleteMutation.mutate}
        open={deleteClient.isOpen}
        title={t('client.deleteDialog.title')}>
        {t('client.deleteDialog.content')}
      </ConfirmDialog>
    </SettingsPanel>
  );
}
