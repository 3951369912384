import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import Input from '../Input';
import { useEditing } from './useEditing';
import { useEditingKeyboard } from './useEditingKeyboard';
import { InlineEditButtons } from './InlineEditButtons';
import styles from './InlineEdit.module.scss';

type Props = ComponentProps<typeof Input> & {
  onCommit: (value?: string) => Promise<any>;
};

export function InputDirectInlineEdit({ onCommit, value, id, ...rest }: Props) {
  const [valueInline, setValueInline] = useState(value);

  const inlineEdit = useEditing({
    onCommit: () => {
      if (valueInline === value) return Promise.resolve();
      return onCommit(valueInline);
    },
    onCancel: () => setValueInline(value),
    id,
  });

  const { keyboardProps } = useEditingKeyboard({
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });
  const input = useRef<any>(null);

  useEffect(() => {
    setValueInline(value);
  }, [value]);

  const onClickInline = () => {
    inlineEdit.open();
  };

  const onCancel = () => {
    inlineEdit.cancel();
    setTimeout(() => {
      if (input.current) input.current.blur();
    }, 0);
  };

  const handleCommit = () => {
    inlineEdit.commit();
    setTimeout(() => {
      if (input.current) input.current.blur();
    }, 0);
  };

  const onChange = (event) => setValueInline(event.target.value);

  return (
    <>
      <div
        className={styles.inlineBase}
        {...keyboardProps}
        onClick={onClickInline}>
        <Input
          {...rest}
          endAdornment={
            <InlineEditButtons
              onCancel={onCancel}
              onCommit={handleCommit}
              isEditing={inlineEdit.isEditing && !rest.isReadOnly}
            />
          }
          id={inlineEdit.id}
          onChange={onChange}
          value={valueInline}
          focused={inlineEdit.isEditing}
          ref={input}
        />
      </div>
    </>
  );
}

export default InputDirectInlineEdit;
