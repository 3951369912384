import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'src/i18n/hooks';
import { Button } from '@bitmodern/bit-ui';
import { LaptopTestIcon } from '@bitmodern/bit-ui/icons';
import useModalManager from 'src/hooks/useModalManager';
import useParams from 'src/hooks/useParams';
import { routes } from 'src/components/Router';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { rootPlanSuiteSelector } from '@bitmodern/redux/state/planSuites/selectors';
import { filtersDisplaySelector } from '@bitmodern/redux/state/filters/selectors';
import { customFilterByTypeSelector } from '@bitmodern/redux/state/customFilters/selectors';
import { TestsFiltersKeys } from '@bitmodern/redux/state/filters/reducer';
import { clearAllFiltersAction } from '@bitmodern/redux/state/filters/actions';
import styles from './EmptyTests.module.scss';
import { useParamSelector } from 'src/packages/redux/hooks';

type Props = {
  filterType: TestsFiltersKeys;
  potentialAmount: number;
};

export default function EmptyTests({ filterType, potentialAmount }: Props) {
  const { t } = useTranslation();
  const { showModal } = useModalManager();
  const dispatch = useAppDispatch();
  const { projectId } = useParams<typeof routes.PROJECT.params>();
  const rootPlanSuite = useParamSelector(rootPlanSuiteSelector, {
    projectId: parseInt(projectId, 10),
  });
  const filters = useAppSelector((state) =>
    filtersDisplaySelector(state, filterType),
  );
  const customFilter = useAppSelector((state) =>
    customFilterByTypeSelector(state, filterType),
  );
  const isFilterApplied = !!(customFilter || !isEmpty(filters));

  const onClickCreateTest = () => {
    if (rootPlanSuite) {
      showModal({
        modalName: 'testCreate',
        modalProps: { planSuite: rootPlanSuite },
        type: 'modal',
      });
    }
  };

  const onRemoveFilters = () => {
    dispatch(clearAllFiltersAction({ type: filterType }));
  };

  return isFilterApplied ? (
    <div className={styles.empty}>
      <h2 className={styles.title}>{t('emptyTests.filtersApplied.title')}</h2>
      <div>
        {t('emptyTests.filtersApplied.text', { count: potentialAmount })}
      </div>
      <Button className={styles.button} onClick={onRemoveFilters}>
        {t('emptyTests.filtersApplied.action')}
      </Button>
    </div>
  ) : (
    <div className={styles.empty}>
      <LaptopTestIcon size={150} />
      <h2 className={styles.title}>{t('emptyTests.title')}</h2>
      <div>{t('emptyTests.text')}</div>
      <Button className={styles.button} onClick={onClickCreateTest}>
        {t('emptyTests.action')}
      </Button>
    </div>
  );
}
