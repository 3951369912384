import React, { ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Expandable.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  expanded?: boolean;
  trigger?: number;
};

export default function Expandable({
  children,
  className = '',
  expanded = false,
  trigger,
}: Props) {
  return (
    <div className={styles.expandable}>
      <AnimatePresence>
        {expanded && (
          <motion.div
            animate="expanded"
            initial="collapsed"
            exit="collapsed"
            variants={{
              collapsed: {
                height: 0,
                y: -50,
                ...(trigger && {
                  originX: trigger,
                  scaleX: 0.5,
                }),
              },
              expanded: {
                height: 'auto',
                y: 0,
                ...(trigger && {
                  originX: trigger,
                  scaleX: 1,
                }),
              },
            }}>
            <div className={className}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
