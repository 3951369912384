import React from 'react';
import { useHistory } from 'react-router';
import { Button } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import { getEnv } from 'src/env';
import { useTranslation } from 'src/i18n/hooks';
import { productsSelector } from '@bitmodern/redux/state/products/selectors';
import {
  markeplaceSubscriptionsSelector,
  userMarketplaceSubscriptionSelector,
} from '@bitmodern/redux/state/subscriptions/selectors';
import { cancelSubscriptionThunk } from '@bitmodern/redux/state/subscriptions/thunks';
import { usersSelector } from '@bitmodern/redux/state/users/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { subscriptionUserSelectors } from 'src/gen/domain/subscription_user/subscriptionUserSelector';
import CancelSubscription from '../CancelSubscription';
import SubscriptionsList from '../SubscriptionsList';
import styles from './MarketplaceSubscription.module.scss';

export default function MarketplaceSubscription() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const products = useAppSelector(productsSelector);
  const users = useAppSelector(usersSelector);
  const subscriptions = useAppSelector(markeplaceSubscriptionsSelector);
  const userMarketplaceSubscription = useAppSelector(
    userMarketplaceSubscriptionSelector,
  );
  const subscriptionUsers = useAppSelector(subscriptionUserSelectors.selectAll);

  const onClick = () => {
    window.open(getEnv().githubMarketplace, '_blank', 'noopener');
  };

  const onClickAssing = () => {
    history.push(routes.GITHUB_MARKET({}));
  };

  const cancelSubscription = () => {
    if (!userMarketplaceSubscription) return Promise.resolve();
    return dispatch(cancelSubscriptionThunk(userMarketplaceSubscription.id));
  };

  return (
    <div>
      {subscriptions.length > 0 && (
        <SubscriptionsList
          className={styles.list}
          products={products}
          subscriptions={subscriptions}
          users={users}
          subscriptionUsers={subscriptionUsers}
        />
      )}
      <div>
        <Button className={styles.button} onClick={onClick}>
          {t('marketplaceSubscription.marketplace')}
        </Button>
        {t('marketplaceSubscription.marketplaceDesc')}
      </div>
      <div>
        <Button className={styles.button} onClick={onClickAssing}>
          {t('marketplaceSubscription.assign')}
        </Button>
        {t('marketplaceSubscription.assignDesc')}
      </div>
      {userMarketplaceSubscription && (
        <CancelSubscription
          className={styles.cancelSubscription}
          onCancel={cancelSubscription}
        />
      )}
    </div>
  );
}
