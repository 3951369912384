import { useFormik } from 'formik';
import React, { ComponentProps } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
  Spacer,
} from '@bitmodern/bit-ui';
import { DeleteIcon, WarningIcon } from '@bitmodern/bit-ui/icons';
import vars from 'src/export.scss';
import { projectDeleteOneThunk } from 'src/gen/domain/project/projectThunk';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import styles from './DeleteProjectDialog.module.scss';

type Props = {
  projectId: number;
  onProjectDeleted?: () => void;
} & Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'>;

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('deleteProject.form.name'),
});

export default function DeleteProjectDialog({
  onProjectDeleted,
  isOpen,
  onClose,
  projectId,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const project = useAppSelector((state) => projectSelector(state, projectId));
  const projectName = project?.name;

  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: ({ name }, { setFieldError }) => {
      if (name !== projectName || !name) {
        setFieldError('name', t('deleteProject.form.nameInvalid'));
        return Promise.reject();
      }
      return dispatch(projectDeleteOneThunk({ id: projectId })).then(
        onProjectDeleted,
      );
    },
    validationSchema,
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size="medium"
      title={t('deleteProject.title')}>
      <DialogContent className={styles.content}>
        <form
          id="deleteProject"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}>
          <Alert
            className={styles.alert}
            icon={<WarningIcon color={vars.warningMain} />}
            size="medium"
            title={t('deleteProject.alert.title')}>
            {t('deleteProject.alert.content')}
          </Alert>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('deleteProject.form.nameLabel', { name: projectName })}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.name}
          />
        </form>
      </DialogContent>
      <PanelActions>
        <Spacer>
          <Button color="primaryDark" onClick={onClose}>
            {t('confirmDialog.cancel')}
          </Button>
          <Button
            color="error"
            icon={<DeleteIcon color={vars.onError} size={20} />}
            form="deleteProject"
            loading={formik.isSubmitting}
            type="submit">
            {t('deleteProject.form.submit')}
          </Button>
        </Spacer>
      </PanelActions>
    </Dialog>
  );
}
