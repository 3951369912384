/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { SuiteHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { suiteHistoryAdapter } from './suiteHistoryAdapter';
import { suiteHistoryFetchThunk } from './suiteHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const suiteHistorySlice = createSlice({
  name: 'suiteHistory',
  initialState: suiteHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    suiteHistoryCleared(state) {
      state.loading = 'idle';
      suiteHistoryAdapter.removeAll(state);
    },
    suiteHistoryUpsertMany(
      state,
      action: PayloadAction<SuiteHistory[] | Record<number, SuiteHistory>>,
    ) {
      suiteHistoryAdapter.upsertMany(state, action.payload);
    },
    suiteHistoryUpsertOne(state, action: PayloadAction<SuiteHistory>) {
      suiteHistoryAdapter.upsertOne(state, action.payload);
    },
    suiteHistoryUpdateOne(state, action: PayloadAction<Update<SuiteHistory>>) {
      suiteHistoryAdapter.updateOne(state, action.payload);
    },
    suiteHistoryRemoveOne(state, action: PayloadAction<number>) {
      suiteHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(suiteHistoryFetchThunk.pending, handlePending);
    builder.addCase(suiteHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      suiteHistoryAdapter.upsertMany(state, action.payload.suiteHistory);
    });
    builder.addCase(suiteHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  suiteHistoryCleared,
  suiteHistoryUpsertMany,
  suiteHistoryUpsertOne,
  suiteHistoryUpdateOne,
  suiteHistoryRemoveOne,
} = suiteHistorySlice.actions;

export const suiteHistoryReducer = suiteHistorySlice.reducer;
