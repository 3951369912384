/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { AppInstallApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { appInstallProjectNormalizer } from '../app_install_project/appInstallProjectNormalize';

export const appInstallNormalizer = (
  model: AppInstallApi,
  cache: NormalizerCache,
) => {
  if (!cache.appInstall) {
    cache.appInstall = [];
  }
  cache.appInstall.push(model);

  if (model.app_install_project && model.app_install_project.length) {
    model.app_install_project.forEach((child) => {
      appInstallProjectNormalizer(child, cache);
    });
    model.app_install_project = undefined;
  }
};
