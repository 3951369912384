import React from 'react';
import { Button } from '@bitmodern/bit-ui';
import { LaptopRequirementIcon } from '@bitmodern/bit-ui/icons';
import EmptyList from 'src/components/organisms/EmptyList';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { integrationsByProjectSelector } from 'src/packages/redux/state/integration/selectors';

type Props = {
  projectId: number;
  site: string;
};

export default function EmptyRequirements({ site, projectId }: Props) {
  const { t } = useTranslation();

  const integrationProjects = useAppSelector((state) =>
    integrationsByProjectSelector(state, { projectId }),
  );

  const { openCreateRequirement } = useIntegrationCheck(site, projectId);
  const onAddRequirement = () => {
    return openCreateRequirement(0);
  };

  return (
    <EmptyList
      button={
        <Button onClick={onAddRequirement}>
          {integrationProjects
            ? t('emptyRequirements.button')
            : t('noIntegration.button')}
        </Button>
      }
      description={
        integrationProjects
          ? t('emptyRequirements.description')
          : t('noIntegration.description')
      }
      icon={<LaptopRequirementIcon size={130} />}
      title={
        integrationProjects
          ? t('emptyRequirements.title')
          : t('noIntegration.title')
      }
    />
  );
}
