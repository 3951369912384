import React, { useState, useMemo } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'src/i18n/hooks';
import {
  Button,
  InputNumber,
  Grid,
  Select,
  Spacer,
  Checkbox,
} from '@bitmodern/bit-ui';
import { showNotificationError } from '@testquality/sdk';
import { formikError } from 'src/utils/formik';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import {
  productByBilledSelector,
  productSelector,
} from '@bitmodern/redux/state/products/selectors';
import {
  intervalToEnum,
  SubscriptionIntervalEnum,
} from 'src/enums/SubscriptionIntervalEnum';
import { userCommertialSubscriptionSelector } from '@bitmodern/redux/state/subscriptions/selectors';
import { updateSubscription } from '@bitmodern/services/subscriptionsService';
import { subscriptionsFetchManyThunk } from 'src/gen/domain/subscriptions/subscriptionsThunk';

import styles from './SubscriptionForm.module.scss';

type InitialValues = {
  numberUsers: number;
  interval: SubscriptionIntervalEnum;
  isAutoUpgrade: boolean;
};

const validationSchema = Yup.object().shape({
  numberUsers: Yup.number()
    .required()
    .positive()
    .label('comertialSub.form.seats'),
});

export default function SubscriptionForm() {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const subscription = useAppSelector(userCommertialSubscriptionSelector);
  const subscriptionProduct = useAppSelector((state) => {
    if (!subscription?.product_id) return undefined;
    return productSelector(state, subscription.product_id);
  });

  const initialValues = useMemo<InitialValues>(
    () => ({
      numberUsers: subscription ? subscription.quantity : 1,
      interval: intervalToEnum(subscriptionProduct?.interval || ''),
      isAutoUpgrade: subscription ? subscription.is_auto_upgrade : true,
    }),
    [subscriptionProduct, subscription],
  );

  const formik = useFormik({ onSubmit, initialValues, validationSchema });

  const product = useAppSelector((state) =>
    productByBilledSelector(state, formik.values.interval),
  );

  async function onSubmit({ numberUsers, isAutoUpgrade }) {
    if (!product || !subscription) return;
    await updateSubscription(
      subscription.id,
      product.id,
      numberUsers,
      isAutoUpgrade,
    ).catch(showNotificationError);
    await dispatch(
      subscriptionsFetchManyThunk({ params: { per_page: -1, _with: 'user' } }),
    );
    setShowForm(false);
  }

  const onManage = () => setShowForm(true);

  const onCancel = () => setShowForm(false);

  const onChangeNumberUsers = (n) => formik.setFieldValue('numberUsers', n);

  const onChangeInterval = (n) => formik.setFieldValue('interval', n);

  const onChangeAutoUpgrade = (n) => formik.setFieldValue('isAutoUpgrade', n);

  const expiresAt = subscription?.subscription_expires_at
    ? new Date(subscription.subscription_expires_at)
    : null;

  const endsAt = subscription?.ends_at ? new Date(subscription.ends_at) : null;

  const pricePerUser =
    parseFloat(product?.amount.toString() || '0') / (product?.user_limit || 1);
  const price = pricePerUser * formik.values.numberUsers;

  const monthlyPrice =
    formik.values.interval === SubscriptionIntervalEnum.Yearly
      ? pricePerUser / 12
      : pricePerUser;

  if (!subscription) return null;

  if (!showForm)
    return (
      <div className={styles.element}>
        <div className={styles.elementContent}>
          <div className={styles.title}>{t('billing.managePlan.title')}</div>
          {!endsAt ? (
            <div>
              {t('billing.managePlan.description', { date: expiresAt })}
            </div>
          ) : (
            <div>
              {t('billing.managePlan.cancelled', {
                cancelled: endsAt,
                ends: expiresAt,
              })}
            </div>
          )}
        </div>
        {!endsAt && (
          <Button color="accent" size="medium" onClick={onManage}>
            {t('billing.managePlan.manage')}
          </Button>
        )}
      </div>
    );

  return (
    <form className={styles.form} onSubmit={formik.handleSubmit}>
      <div className={styles.title}>{t('billing.managePlan.title')}</div>
      <div>{t('billing.managePlan.description', { date: expiresAt })}</div>
      <div className={styles.priceSection}>
        <div className={styles.priceTitle}>
          {t('billing.cardForm.priceTitle')}
        </div>
        <div>
          <div className={styles.price}>
            {t('billing.cardForm.price', { value: price })}
          </div>
          <div className={styles.userPerMonth}>
            {t('billing.cardForm.userMonth', { value: monthlyPrice })}
          </div>
        </div>
      </div>
      <Grid.Row>
        <Grid.Col span={4}>
          <InputNumber
            error={formikError(formik, 'numberUsers')}
            fullWidth
            label={t('comertialSub.form.seats')}
            name="numberUsers"
            onChange={onChangeNumberUsers}
            onFocus={formik.handleBlur}
            required
            value={formik.values.numberUsers}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Select
            label={t('comertialSub.form.interval')}
            onChange={onChangeInterval}
            options={[
              { label: 'Monthly', value: SubscriptionIntervalEnum.Monthly },
              { label: 'Yearly', value: SubscriptionIntervalEnum.Yearly },
            ]}
            value={formik.values.interval}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Checkbox
          checked={formik.values.isAutoUpgrade}
          name="isAutoUpgrade"
          className={styles.autoUpgrade}
          onChange={onChangeAutoUpgrade}>
          {t('comertialSub.form.autoUpgrade')}
        </Checkbox>
      </Grid.Row>
      <Spacer className={styles.submit}>
        <Button color="primaryLight" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!stripe} loading={formik.isSubmitting} type="submit">
          Update
        </Button>
      </Spacer>
    </form>
  );
}
