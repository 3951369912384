import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path
      fill="#e01e5a"
      d="M 5.140625 15.117188 C 5.140625 16.496094 4.023438 17.613281 2.644531 17.613281 C 1.265625 17.613281 0.152344 16.496094 0.152344 15.117188 C 0.152344 13.738281 1.265625 12.625 2.644531 12.625 L 5.140625 12.625 Z M 6.386719 15.117188 C 6.386719 13.738281 7.503906 12.625 8.882812 12.625 C 10.261719 12.625 11.375 13.738281 11.375 15.117188 L 11.375 21.355469 C 11.375 22.734375 10.261719 23.847656 8.882812 23.847656 C 7.503906 23.847656 6.386719 22.734375 6.386719 21.355469 Z M 6.386719 15.117188 "
    />
    <path
      fill="#36c5f0"
      d="M 8.882812 5.101562 C 7.503906 5.101562 6.386719 3.988281 6.386719 2.609375 C 6.386719 1.226562 7.503906 0.113281 8.882812 0.113281 C 10.261719 0.113281 11.375 1.226562 11.375 2.609375 L 11.375 5.101562 Z M 8.882812 6.367188 C 10.261719 6.367188 11.375 7.484375 11.375 8.863281 C 11.375 10.242188 10.261719 11.359375 8.882812 11.359375 L 2.625 11.359375 C 1.246094 11.359375 0.132812 10.242188 0.132812 8.863281 C 0.132812 7.484375 1.246094 6.367188 2.625 6.367188 Z M 8.882812 6.367188 "
    />
    <path
      fill="#2eb67d"
      d="M 18.878906 8.863281 C 18.878906 7.484375 19.992188 6.367188 21.375 6.367188 C 22.753906 6.367188 23.867188 7.484375 23.867188 8.863281 C 23.867188 10.242188 22.753906 11.359375 21.375 11.359375 L 18.878906 11.359375 Z M 17.632812 8.863281 C 17.632812 10.242188 16.515625 11.359375 15.136719 11.359375 C 13.757812 11.359375 12.640625 10.242188 12.640625 8.863281 L 12.640625 2.609375 C 12.640625 1.226562 13.757812 0.113281 15.136719 0.113281 C 16.515625 0.113281 17.632812 1.226562 17.632812 2.609375 Z M 17.632812 8.863281 "
    />
    <path
      fill="#ecb22e"
      d="M 15.136719 18.859375 C 16.515625 18.859375 17.632812 19.976562 17.632812 21.355469 C 17.632812 22.734375 16.515625 23.847656 15.136719 23.847656 C 13.757812 23.847656 12.640625 22.734375 12.640625 21.355469 L 12.640625 18.859375 Z M 15.136719 17.613281 C 13.757812 17.613281 12.640625 16.496094 12.640625 15.117188 C 12.640625 13.738281 13.757812 12.625 15.136719 12.625 L 21.390625 12.625 C 22.773438 12.625 23.886719 13.738281 23.886719 15.117188 C 23.886719 16.496094 22.773438 17.613281 21.390625 17.613281 Z M 15.136719 17.613281 "
    />
  </>,
  'SlackLogo',
);
