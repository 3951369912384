/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  TestQualityType,
  testQualityTypeGetMany,
  testQualityTypeGetOne,
  testQualityTypeDeleteOne,
  testQualityTypeUpdateOne,
  testQualityTypeCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { testQualityTypeNormalizer } from './testQualityTypeNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const testQualityTypeFetchManyThunk = createAsyncThunk(
  'testQualityType/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<TestQualityType>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await testQualityTypeGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, testQualityTypeNormalizer);
      }
      return {
        testQualityType: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityTypeFetchOneThunk = createAsyncThunk(
  'testQualityType/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<TestQualityType>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'testQualityType/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await testQualityTypeGetOne(arg.id as number, arg);
      return executeNormalizer([data], testQualityTypeNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityTypeDeleteOneThunk = createAsyncThunk(
  'testQualityType/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<TestQualityType>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'testQualityType/DeleteOneThunk',
        );
      }
      return await testQualityTypeDeleteOne(arg.id as number, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityTypeUpdateOneThunk = createAsyncThunk(
  'testQualityType/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<TestQualityType>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'testQualityType/UpdateOneThunk',
        );
      }
      const data = await testQualityTypeUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<TestQualityType>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<TestQualityType>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const testQualityTypeCreateOneThunk = createAsyncThunk(
  'testQualityType/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<TestQualityType>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'testQualityType/CreateOneThunk',
        );
      }
      return await testQualityTypeCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
