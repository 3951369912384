import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import {
  mergeProps,
  AriaSelectOptions,
  HiddenSelect,
  useSelect,
  useHover,
  usePress,
  useButton,
} from 'react-aria';
import useResizeObserver from '@react-hook/resize-observer';
import { Item, useSelectState } from 'react-stately';
import { getFocusAccentCN } from '../hooks/useFocusAccent';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import { ArrowDownIcon, CancelIcon } from '../icons';
import Options from './Options';
import FieldLabel from '../FieldLabel';
import styles from './Select.module.scss';
import FieldError from '../FieldError';
import Loading from '../Loading';

type AriaSelectProps<T extends object> = AriaSelectOptions<T> & {
  allowClear?: boolean;
  className?: string;
  empty?: ReactNode;
  error?: ReactNode;
  fullWidth?: boolean;
  labelClassName?: string;
  loading?: boolean;
  size?: 'small' | 'medium';
};

function AriaSelect(props: AriaSelectProps<any>) {
  const {
    allowClear,
    className,
    error,
    fullWidth = false,
    isDisabled,
    isRequired,
    label,
    labelClassName,
    loading,
    name,
    onSelectionChange,
    placeholder,
    selectedKey,
    size = 'medium',
  } = props;

  const state = useSelectState(props);
  const ref = useRef<HTMLButtonElement>(null);
  const listRef = useRef(null);
  const overlayRef = useRef(null);
  const [selectWidth, setSelectWidth] = useState<number | null>(null);
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref,
  );

  const onResize = useCallback(() => {
    if (ref.current) {
      setSelectWidth(ref.current.offsetWidth);
    }
  }, [ref, setSelectWidth]);

  useResizeObserver(ref, onResize);

  const { buttonProps } = useButton(triggerProps, ref);
  const { hoverProps, isHovered } = useHover({ isDisabled });

  const clearPress = usePress({
    onPressStart: () => {
      if (onSelectionChange) {
        onSelectionChange('');
      }
    },
  });

  const renderEndAdorment = () => {
    if (loading) {
      return (
        <span className={styles.endAdorment}>
          <Loading color={vars.textPrimary} delay={0} size={20} />
        </span>
      );
    }
    if (allowClear && selectedKey) {
      return (
        <span className={styles.endAdorment} {...clearPress.pressProps}>
          <CancelIcon color={vars.textPrimary} size={18} />
        </span>
      );
    }
    return (
      <ArrowDownIcon
        className={styles.endAdorment}
        color={vars.textPrimary}
        size={22}
      />
    );
  };

  const wrapCN = classnames(className, styles.wrap, {
    [styles.fullwidth]: fullWidth,
  });

  const selectCN = classnames(
    styles.select,
    styles[size],
    getFocusAccentCN(isHovered, state.isFocused),
    {
      [styles.disabled]: isDisabled,
    },
  );

  return (
    <div className={wrapCN}>
      <HiddenSelect
        isDisabled={isDisabled}
        state={state}
        triggerRef={ref}
        label={label}
        name={name}
      />
      {label && (
        <FieldLabel
          className={labelClassName}
          required={isRequired}
          {...labelProps}>
          {label}
        </FieldLabel>
      )}
      <button
        {...mergeProps(buttonProps, hoverProps)}
        className={selectCN}
        disabled={isDisabled}
        name={name}
        ref={ref}
        type="button">
        <span className={styles.value} {...valueProps}>
          {state.selectedItem ? (
            state.selectedItem.rendered
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
        </span>
        {renderEndAdorment()}
      </button>
      {state.isOpen && !isDisabled && (
        <Options
          {...menuProps}
          targetRef={ref}
          listRef={listRef}
          overlayRef={overlayRef}
          state={state}
          width={selectWidth}
        />
      )}
      {error && <FieldError className={styles.error}>{error}</FieldError>}
    </div>
  );
}

export interface Option {
  label: ReactNode;
  value: any;
  [key: string]: any;
}

type Props = Pick<
  AriaSelectProps<any>,
  | 'allowClear'
  | 'className'
  | 'disabledKeys'
  | 'error'
  | 'fullWidth'
  | 'label'
  | 'labelClassName'
  | 'loading'
  | 'onBlur'
  | 'onFocus'
  | 'placeholder'
  | 'name'
  | 'size'
> & {
  disabled?: boolean;
  empty?: ReactNode;
  onChange: (value: any) => void;
  options: Option[];
  renderOption?: (option: Option) => ReactNode;
  required?: boolean;
  value: any;
};

export default function Select({
  disabled,
  disabledKeys,
  empty,
  onChange,
  options,
  renderOption = (item) => item.label,
  required,
  value,
  ...rest
}: Props) {
  const items = useMemo(() => {
    if (!options.length && empty)
      return [
        {
          id: 'empty',
          label: empty,
        },
      ];

    return options.map(({ label, value: id, ...keys }) => ({
      id,
      label,
      ...keys,
    }));
  }, [options, empty]);

  return (
    <AriaSelect
      aria-label={rest?.label || value}
      isDisabled={disabled}
      isRequired={required}
      items={items}
      onSelectionChange={onChange}
      selectedKey={value}
      disabledKeys={options.length ? disabledKeys : ['empty']}
      {...rest}>
      {(item) => (
        <Item textValue={item?.label || ''}>{renderOption(item)}</Item>
      )}
    </AriaSelect>
  );
}
