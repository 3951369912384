/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { StatusTypeApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { statusNormalizer } from '../status/statusNormalize';

export const statusTypeNormalizer = (
  model: StatusTypeApi,
  cache: NormalizerCache,
) => {
  if (!cache.statusType) {
    cache.statusType = [];
  }
  cache.statusType.push(model);

  if (model.status && model.status.length) {
    model.status.forEach((child) => {
      statusNormalizer(child, cache);
    });
    model.status = undefined;
  }
};
