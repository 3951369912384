import { history } from 'src/components/Router';

function push(path: string) {
  history.push(path);
}

function replace(path: string) {
  history.replace(path);
}

function back() {
  history.goBack();
}

export default {
  push,
  replace,
  back,
};
