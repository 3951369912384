import { createSelector } from '@reduxjs/toolkit';

import { State } from '@bitmodern/redux/store';
import { TestQualityTypeEnum } from 'src/enums/TestQualityTypeEnum';
import testQualityTypeAdapter from 'src/gen/domain/test_quality_type/testQualityTypeAdapter';

const testQualityTypeSelectors = testQualityTypeAdapter.getSelectors(
  (state: State) => state.gen.testQualityType,
);

export function testQualityTypeSelectorById(state: State, Id: number) {
  return testQualityTypeSelectors
    .selectAll(state)
    .filter((tq) => tq.id === Id)[0];
}

export const excellentTQTypeSelector = createSelector(
  testQualityTypeSelectors.selectAll,
  (testQualityType) =>
    testQualityType.find((type) => type.key === TestQualityTypeEnum.EXCELLENT),
);

export const goodTQTypeSelector = createSelector(
  testQualityTypeSelectors.selectAll,
  (testQualityType) =>
    testQualityType.find((type) => type.key === TestQualityTypeEnum.GOOD),
);

export const warningTQTypeSelector = createSelector(
  testQualityTypeSelectors.selectAll,
  (testQualityType) =>
    testQualityType.find((type) => type.key === TestQualityTypeEnum.WARNING),
);

export const badTQTypeSelector = createSelector(
  testQualityTypeSelectors.selectAll,
  (testQualityType) =>
    testQualityType.find((type) => type.key === TestQualityTypeEnum.BAD),
);
