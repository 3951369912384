/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { appVersionAdapter } from './appVersionAdapter';

export const appVersionSelectors = appVersionAdapter.getSelectors(
  (state: State) => state.gen.appVersion,
);
