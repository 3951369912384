/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PullRequestHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { pullRequestHistoryAdapter } from './pullRequestHistoryAdapter';
import { pullRequestHistoryFetchThunk } from './pullRequestHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const pullRequestHistorySlice = createSlice({
  name: 'pullRequestHistory',
  initialState: pullRequestHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    pullRequestHistoryCleared(state) {
      state.loading = 'idle';
      pullRequestHistoryAdapter.removeAll(state);
    },
    pullRequestHistoryUpsertMany(
      state,
      action: PayloadAction<
        PullRequestHistory[] | Record<number, PullRequestHistory>
      >,
    ) {
      pullRequestHistoryAdapter.upsertMany(state, action.payload);
    },
    pullRequestHistoryUpsertOne(
      state,
      action: PayloadAction<PullRequestHistory>,
    ) {
      pullRequestHistoryAdapter.upsertOne(state, action.payload);
    },
    pullRequestHistoryUpdateOne(
      state,
      action: PayloadAction<Update<PullRequestHistory>>,
    ) {
      pullRequestHistoryAdapter.updateOne(state, action.payload);
    },
    pullRequestHistoryRemoveOne(state, action: PayloadAction<number>) {
      pullRequestHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pullRequestHistoryFetchThunk.pending, handlePending);
    builder.addCase(pullRequestHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      pullRequestHistoryAdapter.upsertMany(
        state,
        action.payload.pullRequestHistory,
      );
    });
    builder.addCase(pullRequestHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  pullRequestHistoryCleared,
  pullRequestHistoryUpsertMany,
  pullRequestHistoryUpsertOne,
  pullRequestHistoryUpdateOne,
  pullRequestHistoryRemoveOne,
} = pullRequestHistorySlice.actions;

export const pullRequestHistoryReducer = pullRequestHistorySlice.reducer;
