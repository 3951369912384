import { defectStatusNativeDefectStatusSelectors } from 'src/gen/domain/defect_status_native_defect_status/defectStatusNativeDefectStatusSelector';
import { State } from '@bitmodern/redux/store';

export function defectStatusNativeDefectStatusByNativeStatus(
  state: State,
  nativeStatusIds: number[],
) {
  return defectStatusNativeDefectStatusSelectors
    .selectAll(state)
    .filter((item) => nativeStatusIds.includes(item.native_defect_status_id));
}
