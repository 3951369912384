/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { dataSetAdapter } from './dataSetAdapter';

export const dataSetSelectors = dataSetAdapter.getSelectors(
  (state: State) => state.gen.dataSet,
);
