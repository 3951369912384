import React, { useEffect, useState } from 'react';
import { TreeItem } from 'react-sortable-tree';
import TreeBase from 'src/components/organisms/TreeBase';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import { useTranslation } from 'src/i18n/hooks';
import { SuiteListed, TestListed } from 'src/components/organisms';
import {
  generateRunTestNodeKey,
  generateSuiteTestNodeKey,
  getCheckedList,
} from 'src/utils/tree';
import styles from './TestTree.module.scss';

export type TestTreeProps = {
  isDefect: boolean;
  tree: TreeItem[];
  defaultTests: any[];
  onChangeTests: (args) => void;
};

export function TestTree({
  isDefect,
  defaultTests,
  tree,
  onChangeTests,
}: TestTreeProps) {
  const { t } = useTranslation();
  const [checkeds, setCheckeds] = useState({});

  useEffect(() => {
    if (defaultTests) {
      const ids = defaultTests.map((suiteTest) => suiteTest.id);
      const toCheck = ids.reduce((acu, stId) => {
        const nodeKey = isDefect
          ? generateRunTestNodeKey(stId)
          : generateSuiteTestNodeKey(stId);
        acu[nodeKey] = true;
        return acu;
      }, {});
      setCheckeds(toCheck);
      onChangeTests(getCheckedList(tree, toCheck));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTests]);

  const onCheckItems = (data: TreeItem[]) => {
    onChangeTests(data);
  };

  return tree ? (
    <>
      <div className={styles.selectTest}>{t('multiIssue.selectTest')}</div>
      <div className={styles.treeWrap}>
        <TreeBase
          checkeds={checkeds}
          canDrag={false}
          id="multiIssueTree"
          onCheck={setCheckeds}
          getNodeKey={(data) => data.node.nodeKey}
          canNodeHaveChildren={(node) => node.type !== TEST_TYPE}
          onCheckItems={onCheckItems}
          renderNodeType={{
            [TEST_TYPE]: (item) => {
              const { node, onCheckParentChange, path, treeIndex } = item;
              const { data } = node;
              return (
                <TestListed
                  checked={node.checked}
                  nestingLevel={path.length - 1}
                  onChangeChecked={(value) => {
                    if (onCheckParentChange)
                      onCheckParentChange(value, { node, treeIndex, path });
                  }}
                  suiteTest={data.suiteTest}
                  test={data.test}
                  withActions={false}
                />
              );
            },

            [SUITE_TYPE]: (item) => {
              const {
                node,
                onCheckParentChange,
                path,
                toggleChildrenVisibility,
                treeIndex,
              } = item;
              const { data } = node;
              return (
                <SuiteListed
                  checked={node.checked}
                  collapsed={node.expanded || false}
                  collapsible={Boolean(node.children?.length)}
                  nestingLevel={path.length - 1}
                  onChangeChecked={(value) => {
                    if (onCheckParentChange)
                      onCheckParentChange(value, { node, treeIndex, path });
                  }}
                  onClick={() => {
                    if (!toggleChildrenVisibility) return;
                    toggleChildrenVisibility({ node, path, treeIndex });
                  }}
                  planSuite={data.planSuite}
                  suite={data.suite}
                  withActions={false}
                />
              );
            },
          }}
          treeData={tree}
        />
      </div>
    </>
  ) : (
    <>No Tests</>
  );
}
