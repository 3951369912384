/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Notifications,
  notificationsGetMany,
  notificationsGetOne,
  notificationsDeleteOne,
  notificationsUpdateOne,
  notificationsCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const notificationsFetchManyThunk = createAsyncThunk(
  'notifications/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Notifications>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await notificationsGetMany(inlineArgs);
      return {
        notifications: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const notificationsFetchOneThunk = createAsyncThunk(
  'notifications/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Notifications>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'notifications/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await notificationsGetOne(arg.id as string, arg);
      return {
        notifications: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const notificationsDeleteOneThunk = createAsyncThunk(
  'notifications/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Notifications>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'notifications/DeleteOneThunk',
        );
      }
      return await notificationsDeleteOne(arg.id as string, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const notificationsUpdateOneThunk = createAsyncThunk(
  'notifications/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Notifications>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'notifications/UpdateOneThunk',
        );
      }
      const data = await notificationsUpdateOne(
        arg.id as string,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Notifications>;
      }
      return {
        id: arg.id as string,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Notifications>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const notificationsCreateOneThunk = createAsyncThunk(
  'notifications/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Notifications>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'notifications/CreateOneThunk',
        );
      }
      return await notificationsCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
