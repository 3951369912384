import { createSelector } from '@reduxjs/toolkit';

import { State } from '@bitmodern/redux/store';
import { notificationsSelectors } from 'src/gen/domain/notifications/notificationsSelector';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import {
  notifiableNotificationTypes,
  watchNotificationTypes,
} from 'src/gen/actions/notificationTypes';

import { tokenSelector } from '../authentication/selectors';

export function notificationsSelector(state: State) {
  return notificationsSelectors.selectAll(state);
}

export const notifiableNotificationsSelector = createSelector(
  notificationsSelector,
  (notifications) =>
    notifications
      .filter((notification) =>
        notifiableNotificationTypes.includes(notification.type),
      )
      .reverse(),
);

export const watchNotificationsSelector = createSelector(
  notificationsSelector,
  currentUserSelector,
  (notifications, currentUser) =>
    notifications
      .filter(
        (notification) =>
          watchNotificationTypes.includes(notification.type) &&
          notification.created_by !== currentUser?.id,
      )
      .reverse(),
);

export const unreadNotifiableNotificationsSelector = createSelector(
  notifiableNotificationsSelector,
  (notifications) =>
    notifications.filter((notification) => !notification.read_at),
);

export const unreadWatchNotificationsSelector = createSelector(
  watchNotificationsSelector,
  (notifications) =>
    notifications.filter((notification) => !notification.read_at),
);

export const isTrialSelector = createSelector(tokenSelector, (token) => {
  if (token) {
    const { trial_ends_at, subscription_ends_at, subscription_ended_at } =
      token;

    if (trial_ends_at && !subscription_ends_at && !subscription_ended_at) {
      return true;
    }
  }

  return false;
});

export const isVerifiedUserSelector = createSelector(tokenSelector, (token) => {
  if (token?.verification_ends_at) return false;
  return true;
});
