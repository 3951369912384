/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  suiteTestDetachThunk,
  suiteTestFetchManyThunk,
  suiteTestFetchOneThunk,
  suiteTestUpdateOneThunk,
  suiteTestCreateOneThunk,
} from './suiteTestThunk';
import { TQStateProperties } from '../../store/TQState';
import { suiteTestAdapter } from './suiteTestAdapter';
import {
  suiteDeleteOneThunk,
  suiteFetchManyThunk,
  suiteFetchOneThunk,
} from '../suite/suiteThunk';
import {
  testDeleteOneThunk,
  testFetchManyThunk,
  testFetchOneThunk,
} from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import { planFetchManyThunk, planFetchOneThunk } from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import { suiteRemoveOne } from '../suite/suiteSlice';
import { testRemoveOne } from '../test/testSlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const suiteTestSlice = createSlice({
  name: 'suiteTest',
  initialState: suiteTestAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    suiteTestCleared: suiteTestAdapter.removeAll,
    suiteTestUpsertMany: suiteTestAdapter.upsertMany,
    suiteTestUpsertOne: suiteTestAdapter.upsertOne,
    suiteTestUpdateOne: suiteTestAdapter.updateOne,
    suiteTestRemoveOne: suiteTestAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(suiteTestDetachThunk.pending, handlePending);
    builder.addCase(suiteTestDetachThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload && action.payload.id) {
        suiteTestAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(suiteTestDetachThunk.rejected, handleRejected);
    builder.addCase(suiteTestFetchManyThunk.pending, handlePending);
    builder.addCase(suiteTestFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
    });
    builder.addCase(suiteTestFetchManyThunk.rejected, handleRejected);
    builder.addCase(suiteTestFetchOneThunk.pending, handlePending);
    builder.addCase(suiteTestFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      suiteTestAdapter.upsertOne(state, action.payload.suiteTest[0]);
    });
    builder.addCase(suiteTestFetchOneThunk.rejected, handleRejected);
    builder.addCase(suiteTestUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, suiteTestAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(suiteTestUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      suiteTestAdapter.updateOne(state, action.payload);
    });
    builder.addCase(suiteTestUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          suiteTestAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(suiteTestCreateOneThunk.pending, handlePending);
    builder.addCase(suiteTestCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      suiteTestAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(suiteTestCreateOneThunk.rejected, handleRejected);
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.suiteTest) {
          suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.suiteTest) {
        suiteTestAdapter.upsertMany(state, action.payload.suiteTest);
      }
    });

    builder.addCase(suiteDeleteOneThunk.fulfilled, (state, action) => {
      suiteTestAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.suite_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(suiteRemoveOne, (state, action) => {
      suiteTestAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) => value !== undefined && value.suite_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(testDeleteOneThunk.fulfilled, (state, action) => {
      suiteTestAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.test_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(testRemoveOne, (state, action) => {
      suiteTestAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) => value !== undefined && value.test_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  suiteTestCleared,
  suiteTestUpsertMany,
  suiteTestUpsertOne,
  suiteTestUpdateOne,
  suiteTestRemoveOne,
} = suiteTestSlice.actions;

export const suiteTestReducer = suiteTestSlice.reducer;
