import { integrationTemplateSelectors } from 'src/gen/domain/integration_template/integrationTemplateSelector';
import { State } from '@bitmodern/redux/store';

export function integrationTemplatesSelector(state: State) {
  return integrationTemplateSelectors.selectAll(state);
}

export function integrationTemplateByCapabilitySelector(
  state: State,
  capabilityIntegrationId?: number,
) {
  return integrationTemplatesSelector(state).find(
    (integrationTemplate) =>
      integrationTemplate.capability_integration_id === capabilityIntegrationId,
  );
}
