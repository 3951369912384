/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  PolicyRows,
  policyRowsGetMany,
  policyRowsGetOne,
  policyRowsDeleteOne,
  policyRowsUpdateOne,
  policyRowsCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';

export const policyRowsFetchManyThunk = createAsyncThunk(
  'policyRows/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PolicyRows>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await policyRowsGetMany(inlineArgs);
      return {
        policyRows: resourceList.data || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyRowsFetchOneThunk = createAsyncThunk(
  'policyRows/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PolicyRows>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'policyRows/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await policyRowsGetOne(arg.id as number, arg);
      return {
        policyRows: [data],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyRowsDeleteOneThunk = createAsyncThunk(
  'policyRows/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PolicyRows>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'policyRows/DeleteOneThunk');
      }
      return await policyRowsDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyRowsUpdateOneThunk = createAsyncThunk(
  'policyRows/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<PolicyRows>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'policyRows/UpdateOneThunk');
      }
      const data = await policyRowsUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<PolicyRows>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<PolicyRows>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const policyRowsCreateOneThunk = createAsyncThunk(
  'policyRows/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PolicyRows>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'policyRows/CreateOneThunk',
        );
      }
      return await policyRowsCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
