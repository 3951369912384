import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { routes } from 'src/components/Router';
import { useTranslation } from 'src/i18n/hooks';
import { EmailPanel, ReviewPanel } from 'src/modules/authentication/elements';
import { SignUpStep1 } from './SignUpStep1';
import { SignUpStep2 } from './SignUpStep2';
import styles from './SignUpView.module.scss';

export function SignUpView() {
  const { t } = useTranslation();
  const emailMatch = useRouteMatch(routes.FREETRIAL_EMAIL.path);
  const history = useHistory();
  const location = useLocation();
  const search = location.search;

  const onChangeStep = useCallback(
    (step) => {
      const pathname =
        step === 2 ? routes.FREETRIAL_EMAIL({}) : routes.FREETRIAL({});
      history.push({ search, pathname });
    },
    [history, search],
  );

  return (
    <div className={styles.main}>
      <Helmet>
        <title>{t('signUp.head.title')}</title>
      </Helmet>
      <EmailPanel
        onChangeStep={onChangeStep}
        step={emailMatch ? 2 : 1}
        Step1={SignUpStep1}
        Step2={SignUpStep2}
      />
      <ReviewPanel />
    </div>
  );
}
