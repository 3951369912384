import React, { useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { Select, notification, ComboBox } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { useAppSelector } from '@bitmodern/redux/store';
import { useParamSelector } from '@bitmodern/redux/hooks';
import {
  Integration,
  showNotificationError,
  IntegrationProject,
  Project,
} from '@testquality/sdk';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import {
  getIntegrationRepositories,
  getIntegrationsOrganizations,
} from '@bitmodern/services/integrationService';
import { integrationUserByUserIdSelector } from '@bitmodern/redux/state/integrationUser/selectors';
import { integrationProjectsSelector } from '@bitmodern/redux/state/integrationProject/selectors';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import { formikError } from 'src/utils/formik';
import { notificationErrorTimeout } from 'src/constants';
import useFetch from 'src/hooks/useFetch';
import styles from './IntegrationProject.module.scss';
import useDebouncedState from 'src/hooks/useDebouncedState';

export type IntegrationProjectItemProps = {
  integration?: Integration;
  project?: Project;
  required?: boolean;
  formik: FormikProps<{
    repository?: number;
    org?: number;
    project?: number;
  }>;
  setIntegrationProject: (ip: Partial<IntegrationProject>) => void;
};

export default function IntegrationProjectItem({
  integration,
  project,
  required,
  formik,
  setIntegrationProject: onIntegrationProjectChange,
}: IntegrationProjectItemProps) {
  const { t } = useTranslation();
  const [repoInput, setRepoInput] = useState('');
  const query = useDebouncedState(repoInput, 250);
  const projects = useAppSelector(projectsSelector);
  const user = useAppSelector(currentUserSelector);
  const integrationUser = useParamSelector(integrationUserByUserIdSelector, {
    userId: user?.id,
  });
  const integrationProjects = useAppSelector(integrationProjectsSelector);
  const integrationProject = integrationProjects.find(
    (ip) => ip.project_id === project?.id,
  );

  const integrationType = integration && integrationKey(integration);
  const integrationId = integration?.id;
  const { org } = formik.values;
  const { setFieldValue } = formik;

  const lookupOrg = (orgId?: number) => {
    if (!orgId) {
      if (integration && isJira(integrationType)) {
        return { login: integration.org };
      }
      return undefined;
    }
    return orgsFetch.data.find((o) => o.id === orgId);
  };

  const orgsFetch = useFetch(
    async () => {
      if (!integrationId) return [];
      return getIntegrationsOrganizations(integration?.id).catch((error) => {
        showNotificationError(error);
        throw error;
      });
    },
    { key: 'orgs', integrationId },
    { initialData: [], ignoreCallback: true },
  );

  const reposFetch = useFetch<any[]>(
    async () => {
      const organization = orgsFetch.data.find((o) => o.id === org);
      if (shouldFetchRepos(integrationType, integrationId, org)) return [];
      if (!integrationId) return [];
      return getIntegrationRepositories(
        integrationId,
        organization?.login,
        organization?.type === 'User',
        query,
      )
        .then((res) => res.data)
        .catch((error) => {
          showNotificationError(error);
          throw error;
        });
    },
    { integrationId, integrationType, org, query },
    { initialData: [], ignoreCallback: true },
  );

  const disabledRepos = useMemo<number[]>(() => {
    return reposFetch.data.reduce((ids, repo) => {
      const integrationExist = integrationProjects.some((ip) => {
        return (
          ip.project_reference_id === repo.name &&
          ip.project_id === formik.values.project
        );
      });
      if (repo.permissions?.admin === false || integrationExist) {
        ids.push(repo.id);
      }
      return ids;
    }, []);
  }, [reposFetch, formik.values.project, integrationProjects]);

  const reposOptions = useMemo(() => {
    return reposFetch.data.map((repo) => {
      const ipsFound = integrationProjects.filter(
        (ip) => ip.project_reference_id === repo.name,
      );
      const linkedProjects = projects.filter((p) =>
        ipsFound.some((ip) => ip.project_id === p.id),
      );
      return {
        id: repo.id,
        label: repo.full_name,
        value: repo.id,
        repository: repo,
        linkedProjects,
      };
    });
  }, [reposFetch.data, integrationProjects, projects]);

  const onChangeOrg = (value: number) => {
    setFieldValue('org', value);
  };

  const onChangeRepo = (value: number) => {
    setFieldValue('repository', value);
    if (value) {
      const repo = reposFetch.data.find((r) => r.id === value);
      if (!repo) {
        notification.open({
          type: 'error',
          message: 'Repository Error',
          description: 'Could not find repository',
          duration: notificationErrorTimeout,
        });
        return;
      }
      const integrationUserName = integrationUser.find(
        (iu) => iu.integration_id === integrationId,
      )?.username;

      const ip: Partial<IntegrationProject> = {
        org: lookupOrg(org)?.login,
        project_reference_id: repo.name,
        username: integrationUserName,
      };
      if (integrationProject) {
        ip.id = integrationProject.id;
      }
      onIntegrationProjectChange(ip);
    }
  };

  const renderRepositoryOption = (option) => {
    const linkedTo = option.linkedProjects.map((p) => p.name).join(', ');

    return (
      <>
        <span className={styles.repositoryName}>
          {option.repository.full_name}
        </span>
        {option.repository.private && (
          <span className={styles.privateRepo}>
            {t('integrationProject.repo.private')}
          </span>
        )}
        {linkedTo && (
          <span className={styles.mappedTo}>
            {t('integrationProject.repo.mappedTo', {
              projectName: linkedTo,
            })}
          </span>
        )}
        {linkedTo === undefined &&
          option.repository?.permissions?.admin === false && (
            <span className={styles.noPermissions}>
              {t('integrationProject.repo.noPermissions')}
            </span>
          )}
      </>
    );
  };

  const renderOrgOption = (option) => (
    <>
      <span className={styles.repositoryName}>{option.label}</span>
      {option.org && option.org.type && option.org.type === 'User' && (
        <span className={styles.privateRepo}>
          {t('integrationProject.org.user')}
        </span>
      )}
    </>
  );

  const orgOptions = orgsFetch.data.map((orgValue) => ({
    id: orgValue.id,
    label: orgValue.login,
    value: orgValue.login,
    org: orgValue,
  }));

  const repositorySelection = isJira(integrationType)
    ? t('integrationProject.repositorySelection')
    : t('integrationProject.repositorySelectionGitHub');

  return (
    <>
      {integration && isGithub(integrationType) && (
        <Select
          name="org"
          label={t('integrationProject.orgSelection')}
          loading={orgsFetch.isLoading}
          onChange={onChangeOrg}
          renderOption={renderOrgOption}
          options={orgOptions}
          placeholder="Select Organization..."
          value={formik.values.org}
          onFocus={formik.handleBlur}
          required={required}
          error={formikError(formik, 'org')}
        />
      )}
      {integration && (
        <ComboBox
          disabledKeys={disabledRepos}
          error={formikError(formik, 'repository')}
          label={repositorySelection}
          loading={reposFetch.isLoading}
          name="repository"
          onChange={onChangeRepo}
          onFocus={formik.handleBlur}
          options={reposOptions}
          placeholder="Select Repository..."
          renderOption={renderRepositoryOption}
          onInputChange={setRepoInput}
          inputValue={repoInput}
          required={required}
          value={formik.values.repository}
        />
      )}
    </>
  );
}

function shouldFetchRepos(
  integrationType?: IntegrationType,
  integrationId?: number,
  orgId?: number,
): boolean {
  if (!integrationId) return false;
  // only github has organizations. An org must be selected
  if (isGithub(integrationType) || !orgId) return false;
  return true;
}

function isJira(integrationType?: IntegrationType): boolean {
  return integrationType === IntegrationType.JIRA;
}

function isGithub(integrationType?: IntegrationType): boolean {
  return integrationType === IntegrationType.GITHUB;
}
