/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  DefectStatus,
  defectStatusGetMany,
  defectStatusGetOne,
  defectStatusDeleteOne,
  defectStatusUpdateOne,
  defectStatusCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { defectStatusNormalizer } from './defectStatusNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const defectStatusFetchManyThunk = createAsyncThunk(
  'defectStatus/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectStatus>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await defectStatusGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, defectStatusNormalizer);
      }
      return {
        defectStatus: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectStatusFetchOneThunk = createAsyncThunk(
  'defectStatus/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<DefectStatus>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'defectStatus/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await defectStatusGetOne(arg.id as number, arg);
      return executeNormalizer([data], defectStatusNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectStatusDeleteOneThunk = createAsyncThunk(
  'defectStatus/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<DefectStatus>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'defectStatus/DeleteOneThunk',
        );
      }
      return await defectStatusDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectStatusUpdateOneThunk = createAsyncThunk(
  'defectStatus/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<DefectStatus>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'defectStatus/UpdateOneThunk',
        );
      }
      const data = await defectStatusUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<DefectStatus>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<DefectStatus>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectStatusCreateOneThunk = createAsyncThunk(
  'defectStatus/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<DefectStatus>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'defectStatus/CreateOneThunk',
        );
      }
      return await defectStatusCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
