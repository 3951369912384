import React from 'react';
import { GithubIcon, JiraIcon } from '@bitmodern/bit-ui/icons';
import { BaseIntegration } from '@testquality/sdk';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';

type Props = {
  baseIntegration?: BaseIntegration;
  className?: string;
  color: string;
  size: number;
};

export default function BaseIntegrationIcon({
  baseIntegration,
  className,
  color,
  size,
}: Props) {
  const defaultIcon = (
    <span className={className} style={{ width: size, height: size }} />
  );

  if (!baseIntegration) return defaultIcon;
  const integrationType = integrationKey(baseIntegration);

  if (integrationType === IntegrationType.JIRA) {
    return <JiraIcon className={className} color={color} size={size * 0.8} />;
  }

  if (integrationType === IntegrationType.GITHUB) {
    return <GithubIcon className={className} color={color} size={size} />;
  }

  return defaultIcon;
}
