import { Run } from '@testquality/sdk';
import React, { ReactElement } from 'react';
import { useParamSelector } from 'src/packages/redux/hooks';
import { getMilestoneById } from 'src/packages/redux/state/milestones/selectors';
import { getPlanById } from 'src/packages/redux/state/plans/selectors';
import {
  BugIcon,
  DoneIcon,
  FlagIcon,
  PendingIcon,
  PlanIcon,
} from 'src/packages/bit-ui/icons';
import styles from './RunListItem.module.scss';
import classnames from 'classnames';
import vars from 'src/packages/bit-ui/styling/exports.scss';
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup } from 'src/packages/bit-ui';
import { formatUserName, processAttachmentPath } from 'src/utils/fileHelper';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'src/components/Router';
import RunChipProgress from '../RunChipProgress';
import RunListBadge from '../RunListBadge';
import { userSelectors } from 'src/gen/domain/user/userSelector';
import { useAppSelector } from 'src/packages/redux/store';
import useDrawerManager from 'src/hooks/useDrawerManager';

export type RunListItemProps = {
  run: Run;
  openInDrawer?: boolean;
};

export default function RunListItem({ run, openInDrawer }: RunListItemProps) {
  const { t } = useTranslation();
  const { site } = useParams<typeof routes.RUN.params>();
  const { generateDrawerPath } = useDrawerManager();

  const plan = useParamSelector(getPlanById, {
    planId: run.plan_id,
  });
  const milestone = useParamSelector(getMilestoneById, {
    milestoneId: run.milestone_id,
  });
  const users = useAppSelector(userSelectors.selectAll);

  const iconCN = classnames(styles.icon, {
    [styles.done]: run.is_complete,
    [styles.pending]: !run.is_complete,
  });

  const badgeSize = 14;

  function getRunUsers() {
    const avatars: Array<ReactElement<typeof Avatar>> = [];
    if (!run.analysis?.users || !users) return avatars;
    run.analysis?.users?.forEach((id) => {
      const user = users.find((u) => u.id === id);
      if (!user) return;

      avatars.push(
        <Avatar
          className={styles.avatar}
          name={formatUserName(user)}
          size={24}
          src={processAttachmentPath(user.picture)}
          key={user.id}
        />,
      );
    });

    return avatars;
  }

  return (
    <Link
      to={
        openInDrawer
          ? generateDrawerPath({ runId: run.id })
          : routes.RUN({
              site,
              projectId: run.project_id.toString(),
              runId: run.id.toString(),
            })
      }
      className={styles.item}>
      <div className={styles.itemName}>
        <span className={iconCN}>
          {run.is_complete ? (
            <DoneIcon size={16} />
          ) : (
            <PendingIcon color={vars.textDisabled} />
          )}
        </span>
        <div className={styles.name}>{run.name}</div>
      </div>
      <div className={styles.info}>
        {plan && (
          <RunListBadge
            icon={<PlanIcon color={vars.textPrimary} size={badgeSize} />}
            text={plan?.is_root ? t('runItem.planRoot') : plan?.name}
          />
        )}

        {milestone && (
          <RunListBadge
            icon={<FlagIcon size={badgeSize} />}
            text={milestone.name}
          />
        )}

        {run.analysis?.users && run.analysis.users.length > 0 && (
          <div>
            <AvatarGroup max={3}>{getRunUsers()}</AvatarGroup>
          </div>
        )}

        <div
          className={styles.bugs}
          style={{
            color: run.analysis?.defect_count
              ? vars.textPrimary
              : vars.textSecondary,
          }}>
          <BugIcon
            color={
              run.analysis?.defect_count ? vars.textPrimary : vars.textSecondary
            }
            size={18}
          />
          <span>{run.analysis?.defect_count}</span>
        </div>

        <RunChipProgress run={run} />

        <div className={styles.date}>
          {t('runListItem.created', { created: new Date(run.created_at) })}
        </div>
      </div>
    </Link>
  );
}
