import React from 'react';
import { AriaProgressBarBaseProps } from '@react-types/progress';
import { useMeter } from 'react-aria';
import { clamp } from '@react-aria/utils';
import styles from './Meter.module.scss';

type Props = AriaProgressBarBaseProps & {
  className?: string;
};

export default function Meter(props: Props) {
  const {
    className = '',
    label,
    showValueLabel = !!label,
    value = 0,
    minValue = 0,
    maxValue = 100,
  } = props;
  const { meterProps, labelProps } = useMeter(props);

  const valueClamped = clamp(value, minValue, maxValue);
  const percentage = ((valueClamped - minValue) * 100) / (maxValue - minValue);

  return (
    <div {...meterProps} className={`${styles.meter} ${className}`}>
      <div className={styles.label}>
        {label && <span {...labelProps}>{label}</span>}
        {showValueLabel && (
          <span className={styles.valueLabel}>
            {meterProps['aria-valuetext']}
          </span>
        )}
      </div>
      <div className={styles.barTrack}>
        <div
          className={styles.barProgress}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
}
