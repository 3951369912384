import { AppVersionPlatVersion } from '@testquality/sdk';
import { FormikProps } from 'formik';
import React from 'react';
import { Checkbox } from '@bitmodern/bit-ui';
import { configurationFormatedSelector } from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import { useAppSelector } from '@bitmodern/redux/store';

type Props = {
  appVersionPlatVersion: AppVersionPlatVersion;
  formik: FormikProps<
    {
      appVersionPlatVersions: number[];
    } & any
  >;
};

export default function AppVersionPlatVersionItem({
  appVersionPlatVersion,
  formik,
}: Props) {
  const configurationFormated = useAppSelector((state) =>
    configurationFormatedSelector(state, appVersionPlatVersion.id),
  );

  const onChange = (value) => {
    let next = formik.values.appVersionPlatVersions.filter(
      (v) => v !== appVersionPlatVersion.id,
    );
    if (value) {
      next = [...next, appVersionPlatVersion.id];
    }
    formik.setFieldValue('appVersionPlatVersions', next);
  };

  const checked = formik.values.appVersionPlatVersions.includes(
    appVersionPlatVersion.id,
  );

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      value={appVersionPlatVersion.id.toString()}>
      {configurationFormated}
    </Checkbox>
  );
}
