import React from 'react';
import { Helmet } from 'react-helmet';
import { GithubIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { LearnMorePanel } from '../LearnMorePanel';

import styles from './GithubStepInfo.module.scss';

export function GithubStepInfo() {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <Helmet>
        <title>{t('getStarted.githubStep.info.hero')}</title>
      </Helmet>
      <LearnMorePanel
        title={t('getStarted.githubStep.info.title')}
        text={t('getStarted.githubStep.info.text')}
        icon={<GithubIcon size={100} />}
        url={t('getStarted.githubStep.info.url')}
      />
    </div>
  );
}
