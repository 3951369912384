/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  PullRequest,
  pullRequestGetMany,
  pullRequestGetOne,
  pullRequestDeleteOne,
  pullRequestUpdateOne,
  pullRequestCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { pullRequestNormalizer } from './pullRequestNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const pullRequestFetchManyThunk = createAsyncThunk(
  'pullRequest/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PullRequest>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await pullRequestGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, pullRequestNormalizer);
      }
      return {
        pullRequest: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const pullRequestFetchOneThunk = createAsyncThunk(
  'pullRequest/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<PullRequest>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'pullRequest/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await pullRequestGetOne(arg.id as number, arg);
      return executeNormalizer([data], pullRequestNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const pullRequestDeleteOneThunk = createAsyncThunk(
  'pullRequest/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PullRequest>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'pullRequest/DeleteOneThunk');
      }
      return await pullRequestDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const pullRequestUpdateOneThunk = createAsyncThunk(
  'pullRequest/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<PullRequest>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'pullRequest/UpdateOneThunk');
      }
      const data = await pullRequestUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<PullRequest>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<PullRequest>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const pullRequestCreateOneThunk = createAsyncThunk(
  'pullRequest/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<PullRequest>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'pullRequest/CreateOneThunk',
        );
      }
      return await pullRequestCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
