import React from 'react';
import {
  ProjectColors,
  ProjectColorsDisplay,
  ProjectIcons,
  ProjectIconsDisplay,
} from 'src/enums/ProjectEnums';
import styles from './ProjectIcon.module.scss';

type Props = {
  className?: string;
  color?: ProjectColors;
  icon?: ProjectIcons;
  size?: number;
};

export default function ProjectIcon({
  className = '',
  color,
  icon,
  size = 32,
}: Props) {
  const projectColor =
    ProjectColorsDisplay.get(color || ProjectColors.Blue) ||
    ProjectColorsDisplay.get(ProjectColors.Blue);
  const Icon =
    ProjectIconsDisplay.get(icon || ProjectIcons.Test) ||
    ProjectIconsDisplay.get(ProjectIcons.Test);

  return (
    <div
      className={`${styles.projectIcon} ${className}`}
      style={{ backgroundColor: projectColor, width: size, height: size }}>
      <Icon size={20} />
    </div>
  );
}
