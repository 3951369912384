/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectType } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectTypeAdapter } from './defectTypeAdapter';
import { defectTypeFetchManyThunk } from './defectTypeThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectTypeSlice = createSlice({
  name: 'defectType',
  initialState: defectTypeAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectTypeCleared(state) {
      state.loading = 'idle';
      defectTypeAdapter.removeAll(state);
    },
    defectTypeUpsertMany(
      state,
      action: PayloadAction<DefectType[] | Record<number, DefectType>>,
    ) {
      defectTypeAdapter.upsertMany(state, action.payload);
    },
    defectTypeUpsertOne(state, action: PayloadAction<DefectType>) {
      defectTypeAdapter.upsertOne(state, action.payload);
    },
    defectTypeUpdateOne(state, action: PayloadAction<Update<DefectType>>) {
      defectTypeAdapter.updateOne(state, action.payload);
    },
    defectTypeRemoveOne(state, action: PayloadAction<number>) {
      defectTypeAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectTypeFetchManyThunk.pending, handlePending);
    builder.addCase(defectTypeFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectTypeAdapter.upsertMany(state, action.payload.defectType);
    });
    builder.addCase(defectTypeFetchManyThunk.rejected, handleRejected);
  },
});

export const {
  defectTypeCleared,
  defectTypeUpsertMany,
  defectTypeUpsertOne,
  defectTypeUpdateOne,
  defectTypeRemoveOne,
} = defectTypeSlice.actions;

export const defectTypeReducer = defectTypeSlice.reducer;
