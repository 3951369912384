import React, { useMemo } from 'react';
import { IconButton } from '@bitmodern/bit-ui';
import { AddIcon } from '@bitmodern/bit-ui/icons';
import { useTranslation } from 'src/i18n/hooks';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import {
  BaseIntegration,
  IntegrationUser,
  Integration,
} from '@testquality/sdk';
import vars from 'src/export.scss';

import { useAppSelector } from '@bitmodern/redux/store';
import { integrationProjectsCountSelector } from '@bitmodern/redux/state/integrationProject/selectors';

import ExistingIntegrationsView from 'src/components/views/ExistingIntegrationsView';
import BaseIntegrationIcon from '../BaseIntegrationIcon';
import styles from './BaseIntegrationItem.module.scss';

type Props = {
  baseIntegration: BaseIntegration;
  integrations: Integration[];
  integrationUsers: IntegrationUser[];
  onAddNewProject: (integration: Integration) => void;
};

export default function BaseIntegrationItem({
  baseIntegration,
  integrations,
  integrationUsers,
  onAddNewProject,
}: Props) {
  const { t } = useTranslation();

  const integration = integrations.find(
    (item) => item.base_integration_id === baseIntegration?.id,
  );

  const integrationType = integrationKey(baseIntegration);

  const integrationsCount = useAppSelector((state) => {
    return integrationProjectsCountSelector(state, baseIntegration.id);
  });

  const handleOnAddNewProject = () => {
    if (integration) {
      onAddNewProject(integration);
    }
  };

  const getIntegrationDesc = () => {
    return (
      <div>
        {integrationType === IntegrationType.JIRA
          ? t('integrations.jira')
          : t('integrations.gitHub')}
      </div>
    );
  };

  const userHasAccessToken = useMemo(() => {
    return (
      integration &&
      integrationUsers.find(
        (iu) => iu.integration_id === integration.id && iu.access_token,
      )
    );
  }, [integration, integrationUsers]);

  return (
    <>
      <div className={styles.integrationItem}>
        <div className={styles.integration}>
          <BaseIntegrationIcon
            baseIntegration={baseIntegration}
            color={vars.textPrimary}
            size={30}
          />
          <div className={styles.integrationInfo}>
            <div className={styles.integrationName}>{baseIntegration.name}</div>
            {getIntegrationDesc()}
          </div>
        </div>
        <div className={styles.integrationsCount}>
          {t('integrations.integrationsCount', { count: integrationsCount })}
        </div>
        <IconButton
          boxed
          disabled={!userHasAccessToken}
          onClick={handleOnAddNewProject}
          title={t('integrations.actions.addProject')}>
          <AddIcon color={vars.textPrimary} size={18} />
        </IconButton>
      </div>

      <ExistingIntegrationsView
        key={baseIntegration.id}
        baseIntegration={baseIntegration}
        integration={integration}
      />
    </>
  );
}
