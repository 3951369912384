/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { CouponApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { subscriptionsNormalizer } from '../subscriptions/subscriptionsNormalize';

export const couponNormalizer = (model: CouponApi, cache: NormalizerCache) => {
  if (!cache.coupon) {
    cache.coupon = [];
  }
  cache.coupon.push(model);

  if (model.subscriptions && model.subscriptions.length) {
    model.subscriptions.forEach((child) => {
      subscriptionsNormalizer(child, cache);
    });
    model.subscriptions = undefined;
  }
};
