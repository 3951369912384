/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { stepHistoryAdapter } from './stepHistoryAdapter';

export const stepHistorySelectors = stepHistoryAdapter.getSelectors(
  (state: State) => state.gen.stepHistory,
);
