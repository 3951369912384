import React, { ComponentProps, forwardRef } from 'react';
import {
  AriaSearchFieldProps,
  mergeProps,
  useButton,
  useSearchField,
} from 'react-aria';
import { useSearchFieldState } from 'react-stately';
import mergeRefs from 'react-merge-refs';
import { CancelIcon, SearchIcon } from '@bitmodern/bit-ui/icons';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import Field, { useFieldProps } from '../Field';
import styles from './InputSearch.module.scss';

type FieldProps = Omit<ComponentProps<typeof Field>, 'children' | 'labelProps'>;
type InputSearchProps = AriaSearchFieldProps & FieldProps;

const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (props, ref) => {
    const { value = '' } = props;
    const state = useSearchFieldState(props);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const clearRef = React.useRef<HTMLButtonElement>(null);
    const { labelProps, inputProps, clearButtonProps } = useSearchField(
      props,
      state,
      inputRef,
    );

    const { buttonProps } = useButton(clearButtonProps, clearRef);
    const fieldProps = useFieldProps(props);

    const iconColor = !value.length ? vars.textSecondary : vars.textPrimary;

    return (
      <Field
        {...fieldProps}
        startAdornment={<SearchIcon color={iconColor} size={18} />}
        labelProps={labelProps}>
        {(borderProps, fieldRef) => (
          <div className={styles.search}>
            <input
              {...mergeProps(inputProps, borderProps)}
              className={styles.input}
              ref={mergeRefs([inputRef, fieldRef, ref])}
            />
            {state.value !== '' && (
              <button {...buttonProps} className={styles.clear}>
                <CancelIcon color={vars.textPrimary} size={16} />
              </button>
            )}
          </div>
        )}
      </Field>
    );
  },
);
export default InputSearch;
