export enum RequirementFilterGithub {
  All = '',
  Open = 'open',
  Closed = 'closed',
}

export enum RequirementFilterJira {
  All = '',
  Todo = 'To Do',
  InProgress = 'In Progress',
  Done = 'Done',
}

export const RequirementFilterGithubDisplay: ReadonlyMap<
  RequirementFilterGithub,
  string
> = new Map([
  [RequirementFilterGithub.All, 'requirementFilter.all'],
  [RequirementFilterGithub.Open, 'requirementFilter.githubOpen'],
  [RequirementFilterGithub.Closed, 'requirementFilter.githubClosed'],
]);

export const RequirementFilterJiraDisplay: ReadonlyMap<
  RequirementFilterJira,
  string
> = new Map([
  [RequirementFilterJira.All, 'requirementFilter.all'],
  [RequirementFilterJira.Todo, 'requirementFilter.jiraTodo'],
  [RequirementFilterJira.InProgress, 'requirementFilter.jiraInProgress'],
  [RequirementFilterJira.Done, 'requirementFilter.jiraDone'],
]);
