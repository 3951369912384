import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Run, Status } from '@testquality/sdk';
import { runResultsByRunIdSelector } from '@bitmodern/redux/state/runResults/selectors';
import { useAppSelector } from '@bitmodern/redux/store';

import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { getStatusColor, getStatusTypeColor } from 'src/utils/statusHelper';
import vars from 'src/export.scss';
import styles from './RunStatusChart.module.scss';
import StatusIcon from '../StatusIcon';
import { statusTypesSelector } from 'src/packages/redux/state/statusTypes/selectors';

type Data = {
  status: Status;
  id: string;
  label: string;
  value: number;
  color: string;
};

type Props = {
  className?: string;
  run: Run;
};

export default function RunStatusChart({ className, run }: Props) {
  const statuses = useAppSelector(statusSelectors.selectAll);
  const runResults = useAppSelector((state) =>
    runResultsByRunIdSelector(state, { runId: run.id }),
  );

  const formatValue = (value: number) => `${value}%`;

  function getTestsByStatusId(statusId: number) {
    return runResults.filter((r) => r.status_id === statusId).length;
  }

  const statusTypes = useAppSelector(statusTypesSelector);
  const checkStatusColor = (status) => {
    let color = getStatusColor(status);
    if (!color.length) {
      const statusType = statusTypes.find(
        (s) => s.id === status.status_type_id,
      );
      color = getStatusTypeColor(statusType);
    }
    return color;
  };

  const chartData: Data[] = statuses
    .map((s) => {
      const testStatus = runResults.filter((r) => r.status_id === s.id).length;
      const item: Data = {
        status: s,
        id: s.name,
        label: s.name,
        color: checkStatusColor(s),
        value: parseFloat(((testStatus * 100) / runResults.length).toFixed(2)),
      };
      return item;
    })
    .filter((data) => data.value > 0);

  const colors = chartData.map((data) => checkStatusColor(data.status));

  return (
    <div className={`${styles.content} ${className}`}>
      <div className={styles.chart}>
        <ResponsivePie
          data={chartData}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          innerRadius={0.5}
          padAngle={1}
          cornerRadius={4}
          colors={colors}
          borderWidth={0}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={vars.onSuccess}
          enableArcLinkLabels={false}
          valueFormat={formatValue}
          theme={{
            crosshair: {
              line: {
                stroke: 'red',
                strokeWidth: 0,
              },
            },
            background: 'transparent',
            textColor: vars.textSecondary,
            fontSize: 14,
            tooltip: {
              container: { background: vars.primaryDark },
            },
          }}
        />
      </div>
      <div className={styles.legend}>
        {statuses.map((s: Status) => (
          <div className={styles.legendItem} key={s.id}>
            <StatusIcon className={styles.statusIcon} size={20} status={s} />
            <div>
              <div className={styles.legendName}>{s.name}</div>
              <div className={styles.legendTests}>
                {getTestsByStatusId(s.id)} Tests
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
