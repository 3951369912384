import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Debug from 'debug';
const debug = Debug('useDrawerManager');

const COLLAPSED_WIDTH = 600;
const EXPANDED_WIDTH = '100%';

type TestDrawer = { testId: number; folderId?: number };
type DefectDrawer = { defectId: number };
type RequirementDrawer = { requirementId: number };
type RunResult = { resultId: number };
type Run = { runId: number };
type OpenParams =
  | TestDrawer
  | DefectDrawer
  | RequirementDrawer
  | RunResult
  | Run;

type Width = number | string;

type Context = {
  closeDrawer: () => void;
  collapse: () => void;
  expand: () => void;
  generateDrawerPath: (params: OpenParams) => string;
  generateDrawerUrlParams: (params: OpenParams) => string;
  isExpanded: boolean;
  maxWidth: Width;
  minWidth: Width;
  openDrawer: (params: OpenParams) => void;
  setIsOpen: (open: boolean) => void;
  setIsExpanded: (open: boolean) => void;
  setMaxWidth: (width: Width) => void;
  setMinWidth: (width: Width) => void;
  setWidth: (width: Width) => void;
  width: Width;
};

export const DrawerContext = createContext<Context>(undefined!);

type Props = { children: ReactNode };

export function generateDrawerUrlParams(params: OpenParams) {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, param]) => {
    if (param) {
      urlParams.set(key, param.toString());
    }
  });
  return urlParams.toString();
}

export const DrawerProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxWidth, setMaxWidth] = useState<Width>(EXPANDED_WIDTH);
  const [minWidth, setMinWidth] = useState<Width>(COLLAPSED_WIDTH);
  const [width, setWidth] = useState<Width>(maxWidth);

  const history = useHistory();
  const location = useLocation<any>();

  const generateDrawerPath = useCallback(
    (params: OpenParams) => {
      return `${location.pathname}?${generateDrawerUrlParams(params)}`;
    },
    [location],
  );

  const openDrawer = useCallback(
    (params: OpenParams) => {
      setIsOpen(true);
      const urlParams = new URLSearchParams();
      Object.entries(params).forEach(([key, param]) => {
        if (param) {
          urlParams.set(key, param.toString());
        }
      });
      if (isOpen) {
        history.replace({ search: urlParams.toString() });
      } else {
        history.push({ search: urlParams.toString() });
      }
    },
    [history, isOpen],
  );

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
    history.replace(location.pathname);
  }, [history, location.pathname]);

  const expand = useCallback(() => {
    setIsExpanded(true);
    setWidth(maxWidth);
  }, [maxWidth]);

  const collapse = useCallback(() => {
    setIsExpanded(false);
    setWidth(minWidth);
  }, [minWidth]);

  const value = useMemo(
    () => ({
      closeDrawer,
      collapse,
      expand,
      generateDrawerPath,
      generateDrawerUrlParams,
      isExpanded,
      maxWidth,
      minWidth,
      openDrawer,
      setIsOpen,
      setIsExpanded,
      setMaxWidth,
      setMinWidth,
      setWidth,
      width,
    }),
    [
      closeDrawer,
      collapse,
      expand,
      generateDrawerPath,
      isExpanded,
      maxWidth,
      minWidth,
      openDrawer,
      setIsOpen,
      setIsExpanded,
      setMaxWidth,
      setMinWidth,
      setWidth,
      width,
    ],
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export default function useDrawerManager({
  initialWidth,
}: {
  initialWidth?: () => { max?: Width; min?: Width; value?: Width };
} = {}) {
  const drawer = useContext(DrawerContext);

  useEffect(() => {
    if (!initialWidth) {
      return;
    }

    const {
      max = EXPANDED_WIDTH,
      min = COLLAPSED_WIDTH,
      value = max,
    } = initialWidth();
    debug('initialWidth:', { max, min, value });

    drawer.setMaxWidth(max);
    drawer.setMinWidth(min);

    if (typeof value === typeof max && value === max) {
      debug('drawer.expand');
      drawer.expand();
    } else {
      debug('drawer.setWidth: %s', value);
      drawer.setWidth(value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return drawer;
}
