export enum FlakinessEnum {
  UNKNOWN = 1,
  FLAKY = 2,
  NOT_FLAKY = 4,
}

export function parseFlakinessStatus(flakiness: FlakinessEnum) {
  switch (flakiness) {
    case FlakinessEnum.UNKNOWN:
      return 'Uknown';
    case FlakinessEnum.FLAKY:
      return 'Flaky';
    case FlakinessEnum.NOT_FLAKY:
      return 'Not flaky';
    default:
      return '';
  }
}
