import { createSelector } from '@reduxjs/toolkit';
import { State } from '@bitmodern/redux/store';
import { capabilityIntegrationSelectors } from 'src/gen/domain/capability_integration/capabilityIntegrationSelector';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';

export function capabilityIntegrationSelector(state: State) {
  return capabilityIntegrationSelectors.selectAll(state);
}

export const capabilityIntegrationByIntegrationSelector = (
  state: State,
  integrationId?: number,
) =>
  capabilityIntegrationSelectors
    .selectAll(state)
    .filter(
      (capabilityIntegration) =>
        capabilityIntegration.integration_id === integrationId,
    );

export const capabilityIntegrationBySchemaSelector = (
  schema: IntegrationSchema,
  integrationId?: number,
) =>
  createSelector(capabilityIntegrationSelector, (capabilityIntegrations) => {
    return capabilityIntegrations.find(
      (capability) =>
        capability.integration_id === integrationId &&
        capability.integration_schema_class?.includes(schema),
    );
  });
