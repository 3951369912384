/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { appVersionPlatVersionAdapter } from './appVersionPlatVersionAdapter';

export const appVersionPlatVersionSelectors =
  appVersionPlatVersionAdapter.getSelectors(
    (state: State) => state.gen.appVersionPlatVersion,
  );
