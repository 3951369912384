/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { StatusTypeHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { statusTypeHistoryAdapter } from './statusTypeHistoryAdapter';
import { statusTypeHistoryFetchThunk } from './statusTypeHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const statusTypeHistorySlice = createSlice({
  name: 'statusTypeHistory',
  initialState: statusTypeHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    statusTypeHistoryCleared(state) {
      state.loading = 'idle';
      statusTypeHistoryAdapter.removeAll(state);
    },
    statusTypeHistoryUpsertMany(
      state,
      action: PayloadAction<
        StatusTypeHistory[] | Record<number, StatusTypeHistory>
      >,
    ) {
      statusTypeHistoryAdapter.upsertMany(state, action.payload);
    },
    statusTypeHistoryUpsertOne(
      state,
      action: PayloadAction<StatusTypeHistory>,
    ) {
      statusTypeHistoryAdapter.upsertOne(state, action.payload);
    },
    statusTypeHistoryUpdateOne(
      state,
      action: PayloadAction<Update<StatusTypeHistory>>,
    ) {
      statusTypeHistoryAdapter.updateOne(state, action.payload);
    },
    statusTypeHistoryRemoveOne(state, action: PayloadAction<number>) {
      statusTypeHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(statusTypeHistoryFetchThunk.pending, handlePending);
    builder.addCase(statusTypeHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      statusTypeHistoryAdapter.upsertMany(
        state,
        action.payload.statusTypeHistory,
      );
    });
    builder.addCase(statusTypeHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  statusTypeHistoryCleared,
  statusTypeHistoryUpsertMany,
  statusTypeHistoryUpsertOne,
  statusTypeHistoryUpdateOne,
  statusTypeHistoryRemoveOne,
} = statusTypeHistorySlice.actions;

export const statusTypeHistoryReducer = statusTypeHistorySlice.reducer;
