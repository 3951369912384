import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Run } from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { runSelectors } from 'src/gen/domain/run/runSelector';
import { runResultFetchManyThunk } from 'src/gen/domain/run_result/runResultThunk';
import { runResultsByRunSelector } from '@bitmodern/redux/state/runResults/selectors';
import useParams from 'src/hooks/useParams';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { runDeleteOneThunk } from 'src/gen/domain/run/runThunk';
import { useQuery } from 'src/hooks/useQuery';
import { Helmet } from 'react-helmet';
import useFetch from 'src/hooks/useFetch';
import { unwrapResult } from '@reduxjs/toolkit';

export default function RunView() {
  const { site, projectId, runId } = useParams<typeof routes.RUN.params>();
  const history = useHistory();
  const { resultId: runResultId } = useQuery(['resultId']);
  const dispatch = useAppDispatch();
  const runResultsTree = useAppSelector((state) =>
    runResultsByRunSelector(state, { runId: parseInt(runId, 10) }),
  );
  const run = useAppSelector((state) => runSelectors.selectById(state, runId));

  const onDeleteRun = useCallback(() => {
    if (!run) return Promise.resolve();
    return dispatch(runDeleteOneThunk({ id: run.id }))
      .then(unwrapResult)
      .then(() => {
        history.push(routes.RUNS({ site, projectId }));
      });
  }, [dispatch, history, site, projectId, run]);

  const request = useCallback(() => {
    return dispatch(
      runResultFetchManyThunk({
        params: {
          run_id: parseInt(runId, 10),
          project_id: parseInt(projectId, 10),
          _with: 'runResultStep',
          per_page: -1,
        },
      }),
    );
  }, [dispatch, runId, projectId]);

  const { isLoading } = useFetch(request, `runView-${runId}`, {
    fetchOnce: true,
  });

  if (!run) return null;

  return (
    <>
      <Helmet>
        <title>{run.name}</title>
      </Helmet>
      <Run
        current={parseInt(runResultId, 10)}
        loading={isLoading}
        onDeleteRun={onDeleteRun}
        run={run}
        site={site}
        runResultsTree={runResultsTree}
      />
    </>
  );
}
