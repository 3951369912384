/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { labelAdapter } from './labelAdapter';

export const labelSelectors = labelAdapter.getSelectors(
  (state: State) => state.gen.label,
);
