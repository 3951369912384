/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import { Product } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { productAdapter } from './productAdapter';
import {
  productFetchManyThunk,
  productFetchOneThunk,
  productDeleteOneThunk,
  productUpdateOneThunk,
  productCreateOneThunk,
} from './productThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const productSlice = createSlice({
  name: 'product',
  initialState: productAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    productCleared(state) {
      state.loading = 'idle';
      productAdapter.removeAll(state);
    },
    productUpsertMany(
      state,
      action: PayloadAction<Product[] | Record<number, Product>>,
    ) {
      productAdapter.upsertMany(state, action.payload);
    },
    productUpsertOne(state, action: PayloadAction<Product>) {
      productAdapter.upsertOne(state, action.payload);
    },
    productUpdateOne(state, action: PayloadAction<Update<Product>>) {
      productAdapter.updateOne(state, action.payload);
    },
    productRemoveOne(state, action: PayloadAction<number>) {
      productAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(productFetchManyThunk.pending, handlePending);
    builder.addCase(productFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      productAdapter.upsertMany(state, action.payload.product);
    });
    builder.addCase(productFetchManyThunk.rejected, handleRejected);
    builder.addCase(productFetchOneThunk.pending, handlePending);
    builder.addCase(productFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      productAdapter.upsertOne(state, action.payload.product[0]);
    });
    builder.addCase(productFetchOneThunk.rejected, handleRejected);
    builder.addCase(productDeleteOneThunk.pending, handlePending);
    builder.addCase(productDeleteOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload.id) {
        productAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(productDeleteOneThunk.rejected, handleRejected);
    builder.addCase(productUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, productAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(productUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      productAdapter.updateOne(state, action.payload);
    });
    builder.addCase(productUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          productAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(productCreateOneThunk.pending, handlePending);
    builder.addCase(productCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      productAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(productCreateOneThunk.rejected, handleRejected);
  },
});

export const {
  productCleared,
  productUpsertMany,
  productUpsertOne,
  productUpdateOne,
  productRemoveOne,
} = productSlice.actions;

export const productReducer = productSlice.reducer;
