import React, { forwardRef, ReactNode, ComponentProps } from 'react';
import classNames from 'classnames';
import styles from './Table.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  height?: number | string;
  maxHeight?: number | string;
  rounded?: boolean;
  outlined?: boolean;
} & ComponentProps<'table'>;

function Table(
  {
    children,
    className = '',
    height,
    maxHeight,
    rounded = false,
    outlined = false,
    ...props
  }: Props,
  ref,
) {
  const CN = classNames(styles.table, className, {
    [styles.rounded]: rounded,
    [styles.outlined]: outlined,
  });

  const table = (
    <table ref={ref} className={CN} {...props}>
      {children}
    </table>
  );

  if (!height && !maxHeight) return table;

  const wrapCN = classNames(styles.heightWrap, {
    [styles.heightWrapOutlined]: outlined,
  });

  return (
    <div className={wrapCN} style={{ height, maxHeight }}>
      {table}
    </div>
  );
}

export default forwardRef(Table);
