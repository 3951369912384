/* eslint-disable react/self-closing-comp */
import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <g id="Exploration-05" stroke="none" fill="none">
      <g id="Artboard" transform="translate(-5763.000000, -4451.000000)">
        <g id="Group-10" transform="translate(5720.000000, 3922.000000)">
          <g id="Group-4" transform="translate(27.000000, 395.000000)">
            <g
              id="verified-24px-(1)-copy-2"
              transform="translate(15.000000, 133.000000)">
              <g id="Group">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              </g>
              <polygon
                id="Shape"
                fill="#58B880"
                points="23 12 20.56 9.21 20.9 5.52 17.29 4.7 15.4 1.5 12 2.96 8.6 1.5 6.71 4.69 3.1 5.5 3.44 9.2 1 12 3.44 14.79 3.1 18.49 6.71 19.31 8.6 22.5 12 21.03 15.4 22.49 17.29 19.3 20.9 18.48 20.56 14.79"></polygon>
              <path
                d="M9.38,16.01 L7,13.61 C6.61,13.22 6.61,12.59 7,12.2 L7.07,12.13 C7.46,11.74 8.1,11.74 8.49,12.13 L10.1,13.75 L15.25,8.59 C15.64,8.2 16.28,8.2 16.67,8.59 L16.74,8.66 C17.13,9.05 17.13,9.68 16.74,10.07 L10.82,16.01 C10.41,16.4 9.78,16.4 9.38,16.01 Z"
                id="Path"
                fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </>,
  'ExpectedResult',
);
