/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import styles from './FieldLabel.module.scss';

type Props = ComponentProps<'label'> & {
  required?: boolean;
  labelMarginTop?: number;
};

export default function FieldLabel({
  className = '',
  children,
  required = false,
  labelMarginTop = 2,
  ...rest
}: Props) {
  const labelCN = classnames(styles.label, className, {
    [styles.required]: required,
  });

  return (
    <label
      className={labelCN}
      {...rest}
      // spacing on variables.scss
      style={{ marginTop: labelMarginTop * 8 }}>
      {children}
    </label>
  );
}
