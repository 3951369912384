import React, { ComponentProps } from 'react';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { Button, Dialog, Input } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import styles from './IntegrationTagsDialog.module.scss';

const validationSchema = Yup.object().shape({
  requirementTag: Yup.string()
    .required()
    .label('integrationTags.form.requirementTag'),
  defectTag: Yup.string().required().label('integrationTags.form.defectTag'),
});

type IntegrationTagsValues = {
  requirementTag: string;
  defectTag: string;
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen' | 'onClose'> & {
  initialValues: IntegrationTagsValues;
  onSubmit: (values: IntegrationTagsValues) => Promise<any>;
};

export default function IntegrationTagsDialog({
  isOpen,
  onClose,
  initialValues,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title={t('integrationTags.title')}>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}>
        <Input
          error={formikError(formik, 'requirementTag')}
          label={t('integrationTags.form.requirementTag')}
          name="requirementTag"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.requirementTag}
        />
        <Input
          error={formikError(formik, 'defectTag')}
          label={t('integrationTags.form.defectTag')}
          name="defectTag"
          onChange={formik.handleChange}
          onFocus={formik.handleBlur}
          required
          value={formik.values.defectTag}
        />
        <Button
          className={styles.submit}
          loading={formik.isSubmitting}
          type="submit"
          disabled={!formik.dirty}>
          {t('integrationTags.form.submit')}
        </Button>
      </form>
    </Dialog>
  );
}
