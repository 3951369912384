import { State } from '@bitmodern/redux/store';
import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { Integration } from '@testquality/sdk';
import { allIntegrationsProjectByProjectIdSelector } from '../integrationProject/selectors';
import { githubBaseIntegrationSelector } from '../baseIntegration/selectors';
import { integrationSelectors } from 'src/gen/domain/integration/integrationSelector';

/**
 * @deprecated  Use integrationSelector instead
 */
export const integrationByIdSelector = createSelector(
  [
    (state: State) => state.gen.integration.entities,
    (state: State, { id }: { id?: number }) => id,
  ],
  (integrations, id) => {
    if (!id) return undefined;
    return integrations[id];
  },
);

export function integrationSelector(
  state: State,
  { integrationId }: { integrationId?: number },
) {
  if (!integrationId) return undefined;
  return integrationSelectors.selectById(state, integrationId);
}

export function integrationsSelector(state: State) {
  return integrationSelectors.selectAll(state);
}

export const integrationsByProjectSelector = createSelector(
  integrationSelectors.selectEntities,
  allIntegrationsProjectByProjectIdSelector,
  (integrations, integrationProjects): Integration[] => {
    if (!integrationProjects) return [];
    return integrationProjects?.reduce((prev: Integration[], curr) => {
      if (!curr.integration_id) return prev;
      const integration = integrations[curr.integration_id];
      if (integration) prev.push(integration);
      return prev;
    }, []);
  },
);

export const integrationsByBaseSelector = createSelector(
  [
    integrationSelectors.selectEntities,
    (state: State, baseIntegrationId: number) => baseIntegrationId,
  ],
  (integrations, baseIntegrationId) =>
    (filter(
      integrations,
      (integration) => integration?.base_integration_id === baseIntegrationId,
    ) || []) as Integration[],
);

export const githubIntegrationSelector = createSelector(
  [githubBaseIntegrationSelector, integrationsSelector],
  (baseIntegration, integrations) => {
    if (!baseIntegration) return undefined;

    return integrations.find(
      (integration) => baseIntegration.id === integration.base_integration_id,
    );
  },
);
