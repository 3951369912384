/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { suiteAdapter } from './suiteAdapter';

export const suiteSelectors = suiteAdapter.getSelectors(
  (state: State) => state.gen.suite,
);
