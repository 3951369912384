/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef, ReactNode, useRef, useState } from 'react';
import {
  AriaCheckboxProps,
  useCheckbox,
  useFocus,
  useHover,
  mergeProps,
} from 'react-aria';
import { useToggleState } from 'react-stately';
import classnames from 'classnames';
import { getFocusAccentCN } from '@bitmodern/bit-ui';
import vars from '@bitmodern/bit-ui/styling/exports.scss';
import FieldError from '../FieldError';
import styles from './Checkbox.module.scss';
import { DoneIcon } from '../icons';

type CheckboxProps = Exclude<
  AriaCheckboxProps,
  'isSelected' | 'isRequired' | 'isDisabled'
> & {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: ReactNode;
  required?: boolean;
};

const AriaCheckbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  (props: CheckboxProps, ref) => {
    const {
      checked = false,
      children,
      className = '',
      disabled = false,
      error,
      isReadOnly,
      onChange,
      required = false,
    } = props;

    const ariaProps = {
      ...props,
      isDisabled: disabled,
      isRequired: required,
      isSelected: checked,
    };

    const isDisabled = disabled || isReadOnly || !onChange;

    const inputRef = useRef<HTMLInputElement>(null);
    const state = useToggleState(ariaProps);
    const [isFocused, setIsFocused] = useState(false);
    const { inputProps } = useCheckbox(ariaProps, state, inputRef);
    const { hoverProps, isHovered } = useHover({ isDisabled });
    const { focusProps } = useFocus({
      onFocusChange: setIsFocused,
      isDisabled,
    });

    return (
      <>
        <label
          {...mergeProps(hoverProps)}
          className={classnames(styles.root, className, {
            [styles.noInteractive]: isDisabled,
          })}
          ref={ref}>
          <span
            className={classnames(
              styles.checkbox,
              getFocusAccentCN(isHovered, isFocused),
              {
                [styles.checked]: inputProps.checked,
              },
            )}>
            <input
              {...mergeProps(inputProps, focusProps)}
              className={classnames(styles.input, {
                [styles.noInteractiveInput]: isDisabled,
              })}
              ref={inputRef}
            />
            {inputProps.checked && (
              <DoneIcon
                className={styles.icon}
                color={vars.onAccent}
                size={18}
              />
            )}
          </span>
          {children && <span className={styles.label}>{children}</span>}
        </label>
        {error && <FieldError className={styles.error}>{error}</FieldError>}
      </>
    );
  },
);

export default AriaCheckbox;
