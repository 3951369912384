/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { RunApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { runSuiteNormalizer } from '../run_suite/runSuiteNormalize';

export const runNormalizer = (model: RunApi, cache: NormalizerCache) => {
  if (!cache.run) {
    cache.run = [];
  }
  cache.run.push(model);

  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
  if (model.run_suite && model.run_suite.length) {
    model.run_suite.forEach((child) => {
      runSuiteNormalizer(child, cache);
    });
    model.run_suite = undefined;
  }
};
