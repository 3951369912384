/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { LoggerBase } from './LoggerBase';

export class ConsoleLogger extends LoggerBase {
  private static parsePathToScope(filepath: string): string {
    if (filepath) {
      const sp = filepath.split('/');
      const len = sp.length;
      const filename = sp[len - 1];
      return filename;
    }
    return filepath;
  }

  constructor(scope?: string) {
    super();
    this.scope = ConsoleLogger.parsePathToScope(
      scope || LoggerBase.DEFAULT_SCOPE,
    );
  }

  protected log(level: string, message: string, ...meta: any[]): void {
    const msg = `${this.formatScope()} ${message}`;
    if (
      meta &&
      meta.length === 1 &&
      meta[0] &&
      Array.isArray(meta[0]) &&
      meta[0].length === 0
    ) {
      switch (level) {
        case 'info':
          console.info(msg);
          break;
        case 'debug':
          console.debug(msg);
          break;
        case 'warn':
          console.warn(msg);
          break;
        case 'error':
          console.error(msg);
          Sentry.withScope((scope) => {
            scope.setLevel(Sentry.Severity.Error);
            Sentry.captureMessage(msg);
          });
          break;
        default:
          console.info(msg);
          break;
      }
    } else {
      switch (level) {
        case 'info':
          console.info(msg, ...meta);
          break;
        case 'debug':
          console.debug(msg, ...meta);
          break;
        case 'warn':
          console.warn(msg, ...meta);
          break;
        case 'error':
          console.error(msg, ...meta);
          /* NOTE: Only send 400 and 5xx status errors to Sentry */
          if (meta?.[1] === 400 || meta?.[1] >= 500) {
            Sentry.withScope((scope) => {
              scope.setExtra('data', meta);
              scope.setLevel(Sentry.Severity.Error);
              Sentry.captureMessage(msg);
            });
          }
          break;
        default:
          console.info(msg, ...meta);
          break;
      }
    }
  }
}
