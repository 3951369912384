/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Purpose,
  purposeGetMany,
  purposeGetOne,
  purposeDeleteOne,
  purposeUpdateOne,
  purposeCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { purposeNormalizer } from './purposeNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const purposeFetchManyThunk = createAsyncThunk(
  'purpose/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Purpose>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await purposeGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, purposeNormalizer);
      }
      return {
        purpose: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const purposeFetchOneThunk = createAsyncThunk(
  'purpose/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Purpose>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'purpose/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await purposeGetOne(arg.id as number, arg);
      return executeNormalizer([data], purposeNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const purposeDeleteOneThunk = createAsyncThunk(
  'purpose/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Purpose>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'purpose/DeleteOneThunk');
      }
      return await purposeDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const purposeUpdateOneThunk = createAsyncThunk(
  'purpose/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Purpose>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'purpose/UpdateOneThunk');
      }
      const data = await purposeUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Purpose>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Purpose>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const purposeCreateOneThunk = createAsyncThunk(
  'purpose/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Purpose>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'purpose/CreateOneThunk',
        );
      }
      return await purposeCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
