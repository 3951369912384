import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Alert.module.scss';

type Props = {
  children: ReactNode;
  className?: string;
  icon: ReactNode;
  size?: 'medium' | 'large';
  title: ReactNode;
};

export default function Alert({
  children,
  className = '',
  icon,
  size = 'medium',
  title,
}: Props) {
  const alertCN = classnames(styles.alert, className, {
    [styles.large]: size === 'large',
  });

  return (
    <div className={alertCN}>
      <div className={styles.alertIcon}>{icon}</div>
      <div className={styles.info}>
        <div className={styles.alertTitle}>{title}</div>
        {children}
      </div>
    </div>
  );
}
