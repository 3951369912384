/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { defectAdapter } from './defectAdapter';

export const defectSelectors = defectAdapter.getSelectors(
  (state: State) => state.gen.defect,
);
