/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { runResultAdapter } from './runResultAdapter';

export const runResultSelectors = runResultAdapter.getSelectors(
  (state: State) => state.gen.runResult,
);
