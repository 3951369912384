/* eslint-disable react/button-has-type */
import React, { ComponentProps, useCallback, useRef } from 'react';
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useFocusRing,
} from 'react-aria';
import classname from 'classname';
import styles from './BaseButton.module.scss';

type Props = ComponentProps<'button'> &
  Exclude<AriaButtonProps, 'onPress'> & {
    className?: string;
    fullWidth?: boolean;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
  };

export default function BaseButton({
  className = '',
  fullWidth = false,
  type = 'button',
  onClick,
  ...props
}: Props) {
  const onPress = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const ref = useRef(null);
  const { buttonProps } = useButton({ ...props, onPress }, ref);
  const { children, disabled = false } = props;
  const { isFocusVisible, focusProps } = useFocusRing();

  const buttonCN = classname(styles.button, className, {
    [styles.fullWidth]: fullWidth,
    [styles.focused]: isFocusVisible,
    [styles.disabled]: disabled,
  });

  return (
    <button
      {...mergeProps(props, buttonProps, focusProps)}
      className={buttonCN}
      disabled={disabled}
      ref={ref}
      type={type}>
      {children}
    </button>
  );
}
