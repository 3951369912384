import i18n from 'i18next';
import * as Yup from 'yup';

const nameRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

// Type is added in src/typings.d.ts
Yup.addMethod<Yup.StringSchema>(Yup.string, 'slug', function slug(message) {
  return this.test(
    'slug',
    function message({ label = 'slug', path = label }) {
      return i18n.t('yup.string.slug', {
        // If label and path are equal it means label hasn't been translated so we do it here.
        // BTW the aforementioned behavior is what we have always observed to be the case with added methods,
        // though it might change in other Yup versions.
        label: label === path ? i18n.t(path) : label,
      });
    },
    function test(value) {
      return typeof value === 'string' && value.length
        ? nameRegex.test(value) || this.createError({ message })
        : true;
    },
  );
});

export default Yup;
