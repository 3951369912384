/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlanPurposeApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const planPurposeNormalizer = (
  model: PlanPurposeApi,
  cache: NormalizerCache,
) => {
  if (!cache.planPurpose) {
    cache.planPurpose = [];
  }
  cache.planPurpose.push(model);
};
