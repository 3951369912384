import { createSelector } from '@reduxjs/toolkit';

import { State } from '@bitmodern/redux/store';
import {
  excellentTQTypeSelector,
  goodTQTypeSelector,
  warningTQTypeSelector,
  badTQTypeSelector,
} from '@bitmodern/redux/state/testQualityType/selectors';
import testQualityAdapter from 'src/gen/domain/test_quality/testQualityAdapter';

const testQualitySelectors = testQualityAdapter.getSelectors(
  (state: State) => state.gen.testQuality,
);

export function allTestQualitySelector(state: State) {
  return testQualitySelectors.selectAll(state);
}

export function testQualitySelectorById(state: State, Id: number) {
  return testQualitySelectors.selectAll(state).filter((tq) => tq.id === Id)[0];
}

export const warningTestQualitySelector = createSelector(
  testQualitySelectors.selectAll,
  badTQTypeSelector,
  warningTQTypeSelector,
  (testQualities, badTQType, warningTQType) =>
    testQualities.filter(
      (tq) =>
        tq.test_quality_type_id === badTQType?.id ||
        tq.test_quality_type_id === warningTQType?.id,
    ),
);

export const goodTestQualitySelector = createSelector(
  testQualitySelectors.selectAll,
  excellentTQTypeSelector,
  goodTQTypeSelector,
  (testQualities, excellentTQType, goodTQType) =>
    testQualities.filter(
      (tq) =>
        tq.test_quality_type_id === excellentTQType?.id ||
        tq.test_quality_type_id === goodTQType?.id,
    ),
);
