/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { milestoneAdapter } from './milestoneAdapter';

export const milestoneSelectors = milestoneAdapter.getSelectors(
  (state: State) => state.gen.milestone,
);
