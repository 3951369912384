import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@bitmodern/bit-ui';
import { UserIcon } from '@bitmodern/bit-ui/icons';
import {
  ChangePasswordDialog,
  ConfirmDialog,
  DescriptiveAction,
  SettingsPanel,
  UserForm,
} from 'src/components/organisms';
import { routes } from 'src/components/Router';
import { userUpdateOneThunk } from 'src/gen/domain/user/userThunk';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'src/i18n/hooks';
import useMutation from 'src/hooks/useMutation';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { isCurrentUserAdminSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import {
  currentUserSelector,
  tokenSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import { deleteUserThunk } from '@bitmodern/redux/state/users/thunks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useOverlayTriggerState } from 'react-stately';
import vars from 'src/export.scss';

export default function ProfileViewContainer() {
  const user = useAppSelector(currentUserSelector)!;
  const { t } = useTranslation();

  let title = `${user.given_name || ''} ${user.family_name || ''}`.trim();

  if (!user.given_name && !user.family_name) {
    title = t('profile.title');
  }

  const dispatch = useAppDispatch();
  const changePassword = useOverlayTriggerState({});
  const deleteUser = useOverlayTriggerState({});
  const userToken = useAppSelector(tokenSelector);
  const { site } = useParamsInRoute<typeof routes.PROFILE.path>(
    routes.PROFILE.path,
  );

  const isUserAdmin = useAppSelector(isCurrentUserAdminSelector);
  const isOwnUser = user.id === userToken?.user_id;

  const onChangePassword = useCallback(
    async (values) => {
      await dispatch(
        userUpdateOneThunk({
          id: user.id,
          data: {
            password: values.password,
            // @ts-expect-error not in type
            existing_password: values.existingPassword,
          },
        }),
      ).then(unwrapResult);
    },
    [dispatch, user],
  );

  const handleOnDelete = useCallback(() => {
    return dispatch(deleteUserThunk(user.id));
  }, [dispatch, user]);

  const handleOnChangePassword = useCallback(
    (values) => {
      return onChangePassword(values).then(changePassword.close);
    },
    [onChangePassword, changePassword.close],
  );

  const deleteMutation = useMutation(handleOnDelete);

  if (!user) return null;

  return (
    <SettingsPanel
      icon={<UserIcon color={vars.textPrimary} size={24} />}
      title={title}>
      <UserForm user={user} />
      <DescriptiveAction
        action={
          <Link to={routes.GET_STARTED({ site })}>
            <Button color="primaryLight" size="medium">
              {t('profile.intro.action')}
            </Button>
          </Link>
        }
        description={t('profile.intro.description')}
        title={t('profile.intro.title')}
      />
      {(isUserAdmin || isOwnUser) && (
        <>
          <DescriptiveAction
            action={
              <Button
                color="primaryLight"
                onClick={changePassword.open}
                size="medium">
                {t('profile.changePassword.action')}
              </Button>
            }
            description={t('profile.changePassword.description')}
            title={t('profile.changePassword.title')}
          />
          <DescriptiveAction
            action={
              <Button
                color="primaryLight"
                onClick={deleteUser.open}
                size="medium">
                {t('profile.deleteUser.action')}
              </Button>
            }
            description={t('profile.deleteUser.description')}
            title={t('profile.deleteUser.title')}
          />
        </>
      )}
      <ChangePasswordDialog
        isOpen={changePassword.isOpen}
        onClose={changePassword.close}
        isOwnPassword={isOwnUser}
        onSubmit={handleOnChangePassword}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteUser.close}
        onConfirm={deleteMutation.mutate}
        open={deleteUser.isOpen}
        title={t('profile.deleteDialog.title')}>
        {t('profile.deleteDialog.content', { user: user?.email })}
      </ConfirmDialog>
    </SettingsPanel>
  );
}
