/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AppInstallHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appInstallHistoryAdapter } from './appInstallHistoryAdapter';
import { appInstallHistoryFetchThunk } from './appInstallHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appInstallHistorySlice = createSlice({
  name: 'appInstallHistory',
  initialState: appInstallHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appInstallHistoryCleared(state) {
      state.loading = 'idle';
      appInstallHistoryAdapter.removeAll(state);
    },
    appInstallHistoryUpsertMany(
      state,
      action: PayloadAction<
        AppInstallHistory[] | Record<number, AppInstallHistory>
      >,
    ) {
      appInstallHistoryAdapter.upsertMany(state, action.payload);
    },
    appInstallHistoryUpsertOne(
      state,
      action: PayloadAction<AppInstallHistory>,
    ) {
      appInstallHistoryAdapter.upsertOne(state, action.payload);
    },
    appInstallHistoryUpdateOne(
      state,
      action: PayloadAction<Update<AppInstallHistory>>,
    ) {
      appInstallHistoryAdapter.updateOne(state, action.payload);
    },
    appInstallHistoryRemoveOne(state, action: PayloadAction<number>) {
      appInstallHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appInstallHistoryFetchThunk.pending, handlePending);
    builder.addCase(appInstallHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appInstallHistoryAdapter.upsertMany(
        state,
        action.payload.appInstallHistory,
      );
    });
    builder.addCase(appInstallHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  appInstallHistoryCleared,
  appInstallHistoryUpsertMany,
  appInstallHistoryUpsertOne,
  appInstallHistoryUpdateOne,
  appInstallHistoryRemoveOne,
} = appInstallHistorySlice.actions;

export const appInstallHistoryReducer = appInstallHistorySlice.reducer;
