import React, { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';
import { Checkbox } from '@bitmodern/bit-ui';

import nestingSize from 'src/utils/nestingSize';
import styles from './TreeItem.module.scss';

type Props = ComponentProps<'div'> & {
  checked?: boolean;
  label?: string;
  mainData: ReactNode;
  nestingLevel?: number;
  notIncluded: boolean;
  onChangeChecked?: (value: boolean) => void;
  rightSection: ReactNode;
  selected?: boolean;
};

export default function TreeItem({
  checked,
  className = '',
  label = '',
  mainData,
  nestingLevel = 0,
  notIncluded = false,
  onChangeChecked,
  rightSection,
  selected = false,
  style,
  ...rest
}: Props) {
  const treeItemCN = classnames(styles.testListed, className, {
    [styles.selected]: selected,
    [styles.notIncluded]: notIncluded,
  });

  const mainDataCN = classnames(styles.data, {
    [styles.notIncludedData]: notIncluded,
  });

  return (
    <div
      className={treeItemCN}
      {...rest}
      style={{
        ...style,
        paddingLeft: nestingSize(nestingLevel),
      }}>
      {typeof checked !== 'undefined' && (
        <Checkbox
          aria-label={label}
          checked={checked}
          className={styles.checkbox}
          onChange={onChangeChecked}
        />
      )}
      <div className={mainDataCN}>{mainData}</div>

      {rightSection}
    </div>
  );
}
