/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  planSuiteDetachThunk,
  planSuiteFetchManyThunk,
  planSuiteFetchOneThunk,
  planSuiteUpdateOneThunk,
  planSuiteCreateOneThunk,
} from './planSuiteThunk';
import { TQStateProperties } from '../../store/TQState';
import { planSuiteAdapter } from './planSuiteAdapter';
import {
  suiteDeleteOneThunk,
  suiteFetchManyThunk,
  suiteFetchOneThunk,
} from '../suite/suiteThunk';
import { testFetchManyThunk, testFetchOneThunk } from '../test/testThunk';
import {
  dataSetFetchManyThunk,
  dataSetFetchOneThunk,
} from '../data_set/dataSetThunk';
import {
  testQualityFetchManyThunk,
  testQualityFetchOneThunk,
} from '../test_quality/testQualityThunk';
import {
  sharedPreconditionFetchManyThunk,
  sharedPreconditionFetchOneThunk,
} from '../shared_precondition/sharedPreconditionThunk';
import {
  testQualityTypeFetchManyThunk,
  testQualityTypeFetchOneThunk,
} from '../test_quality_type/testQualityTypeThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  caseTypeFetchManyThunk,
  caseTypeFetchOneThunk,
} from '../case_type/caseTypeThunk';
import {
  casePriorityFetchManyThunk,
  casePriorityFetchOneThunk,
} from '../case_priority/casePriorityThunk';
import {
  planDeleteOneThunk,
  planFetchManyThunk,
  planFetchOneThunk,
} from '../plan/planThunk';
import {
  purposeFetchManyThunk,
  purposeFetchOneThunk,
} from '../purpose/purposeThunk';
import {
  appVersionPlatVersionFetchManyThunk,
  appVersionPlatVersionFetchOneThunk,
} from '../app_version_plat_version/appVersionPlatVersionThunk';
import {
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import { userFetchManyThunk, userFetchOneThunk } from '../user/userThunk';
import {
  platVersionFetchManyThunk,
  platVersionFetchOneThunk,
} from '../plat_version/platVersionThunk';
import { platFetchManyThunk, platFetchOneThunk } from '../plat/platThunk';
import {
  appVersionFetchManyThunk,
  appVersionFetchOneThunk,
} from '../app_version/appVersionThunk';
import { appFetchManyThunk, appFetchOneThunk } from '../app/appThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import { planRemoveOne } from '../plan/planSlice';
import { suiteRemoveOne } from '../suite/suiteSlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const planSuiteSlice = createSlice({
  name: 'planSuite',
  initialState: planSuiteAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    planSuiteCleared: planSuiteAdapter.removeAll,
    planSuiteUpsertMany: planSuiteAdapter.upsertMany,
    planSuiteUpsertOne: planSuiteAdapter.upsertOne,
    planSuiteUpdateOne: planSuiteAdapter.updateOne,
    planSuiteRemoveOne: planSuiteAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(planSuiteDetachThunk.pending, handlePending);
    builder.addCase(planSuiteDetachThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload && action.payload.id) {
        planSuiteAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(planSuiteDetachThunk.rejected, handleRejected);
    builder.addCase(planSuiteFetchManyThunk.pending, handlePending);
    builder.addCase(planSuiteFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planSuiteAdapter.upsertMany(state, action.payload.planSuite);
    });
    builder.addCase(planSuiteFetchManyThunk.rejected, handleRejected);
    builder.addCase(planSuiteFetchOneThunk.pending, handlePending);
    builder.addCase(planSuiteFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planSuiteAdapter.upsertOne(state, action.payload.planSuite[0]);
    });
    builder.addCase(planSuiteFetchOneThunk.rejected, handleRejected);
    builder.addCase(planSuiteUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, planSuiteAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(planSuiteUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planSuiteAdapter.updateOne(state, action.payload);
    });
    builder.addCase(planSuiteUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          planSuiteAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(planSuiteCreateOneThunk.pending, handlePending);
    builder.addCase(planSuiteCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planSuiteAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(planSuiteCreateOneThunk.rejected, handleRejected);
    builder.addCase(suiteFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(suiteFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(testFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(testFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(dataSetFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(dataSetFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(testQualityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(testQualityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(
      sharedPreconditionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(
      sharedPreconditionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(
      testQualityTypeFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(testQualityTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(caseTypeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(caseTypeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(casePriorityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(casePriorityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(planFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(planFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(purposeFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(purposeFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(
      appVersionPlatVersionFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(
      appVersionPlatVersionFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(platVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(platVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(platFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(platFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appVersionFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appVersionFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.planSuite) {
          planSuiteAdapter.upsertMany(state, action.payload.planSuite);
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.planSuite) {
        planSuiteAdapter.upsertMany(state, action.payload.planSuite);
      }
    });

    builder.addCase(planDeleteOneThunk.fulfilled, (state, action) => {
      planSuiteAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.plan_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(planRemoveOne, (state, action) => {
      planSuiteAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) => value !== undefined && value.plan_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(suiteDeleteOneThunk.fulfilled, (state, action) => {
      planSuiteAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.suite_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(suiteRemoveOne, (state, action) => {
      planSuiteAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) => value !== undefined && value.suite_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  planSuiteCleared,
  planSuiteUpsertMany,
  planSuiteUpsertOne,
  planSuiteUpdateOne,
  planSuiteRemoveOne,
} = planSuiteSlice.actions;

export const planSuiteReducer = planSuiteSlice.reducer;
