import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Comment } from '@testquality/sdk';
import ActivityComment from './ActivityComment';
import ActivityItem from './ActivityItem';
import debounce from 'lodash/debounce';
import styles from './Activity.module.scss';

type Item = ComponentProps<typeof ActivityItem>['item'];

type CommentItem = Omit<Item, 'operation'> & {
  operation: Item['operation'] | 'comment';
  comment: Comment;
};

type Props = {
  items: Item[] | CommentItem[];
  textFieldsItems?: string[];
} & Pick<
  ComponentProps<typeof ActivityComment>,
  'onDeleteComment' | 'onEditComment'
>;

export default function Activity({
  items,
  onDeleteComment,
  onEditComment,
  textFieldsItems = [],
}: Props) {
  const [width, setWidth] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const observeResize = useMemo(
    () =>
      debounce(() => {
        const measuredWidth = ref.current?.clientWidth ?? null;
        setWidth(measuredWidth);
      }, 500),
    // we want to remesure when items change
    [items.length], // eslint-disable-line react-hooks/exhaustive-deps,
  );

  useEffect(() => {
    let observer;
    if (ref.current) {
      observer = new ResizeObserver(observeResize);

      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [observeResize]);

  if (!items.length) return null;

  return (
    <div ref={ref}>
      <h3 className={styles.title}>Activity</h3>
      <ul className={styles.list}>
        {items.map((item) => {
          if (item.operation === 'comment') {
            return (
              <ActivityComment
                key={`${item.operation}-${item.updatedAt.toISOString()}`}
                comment={item.comment}
                onDeleteComment={onDeleteComment}
                onEditComment={onEditComment}
                user={item.user}
              />
            );
          }
          return (
            <ActivityItem
              key={`${item.operation}-${item.updatedAt.toISOString()}`}
              containerWidth={width}
              item={item}
              textFieldsItems={textFieldsItems}
            />
          );
        })}
      </ul>
    </div>
  );
}
