/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { NativeDefectStatusApi } from '@testquality/sdk';
import { DefectStatusNativeDefectStatus } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { defectStatusNormalizer } from '../defect_status/defectStatusNormalize';
import { defectStatusNativeDefectStatusNormalizer } from '../defect_status_native_defect_status/defectStatusNativeDefectStatusNormalize';

export const nativeDefectStatusNormalizer = (
  model: NativeDefectStatusApi,
  cache: NormalizerCache,
) => {
  if (!cache.nativeDefectStatus) {
    cache.nativeDefectStatus = [];
  }
  cache.nativeDefectStatus.push(model);

  if (model.defect_status && model.defect_status.length) {
    model.defect_status.forEach((child) => {
      defectStatusNativeDefectStatusNormalizer(
        child.pivot as DefectStatusNativeDefectStatus,
        cache,
      );
      child.pivot = undefined;
      defectStatusNormalizer(child, cache);
    });
    model.defect_status = undefined;
  }
};
