import { getResponse, QueryParams, IntegrationRoute } from '@testquality/sdk';
import { getClient } from 'src/Client';

export async function getIntegrationRepositories(
  integrationId: number,
  org?: string,
  isUser?: boolean,
  query?: string,
  setData?: (data: any) => void,
): Promise<any> {
  const params: { [key: string]: unknown } = {};
  if (query) params.query = query;
  if (org) {
    const orgParamName = isUser ? 'username' : 'org';
    params[orgParamName] = org;
  }

  const per_page = 30;
  let resData: any[] = [];
  let totalPages = 1;
  for (let page = 1; page <= totalPages; page += 1) {
    // eslint-disable-next-line no-await-in-loop
    const response = await getClient().api.request({
      method: 'get',
      url: `${IntegrationRoute()}/${integrationId}/repos`,
      params: { ...params, per_page, page },
    });
    const { data } = response;
    totalPages = Math.round(data.total / per_page);
    resData = [...resData, ...data.data];
    if (setData) {
      setData(resData);
    }
  }

  return { data: resData, total: totalPages };
}

export const getHost = () => {
  const currentURL = window.location.href; // returns the absolute URL of a page
  const currentPathname = window.location.pathname;
  const idx = currentURL.indexOf(currentPathname);
  const host = currentURL.substring(0, idx);
  return host;
};

export function authenticateWithRedirect(
  integrationId: number,
  redirectUrl: string,
): Promise<any> {
  const URL = `/system/integration/${integrationId}/authorize?redirect_url=${redirectUrl}`;
  const config: QueryParams<any> = {
    method: 'get',
    url: URL,
  };
  return getResponse<any>(getClient().api, config);
}

export const redirectForAuth = async (
  localIntegrationId: number,
  pathname: string,
  search?: string,
) => {
  const url = `${getHost()}${pathname}${search || ''}`;

  const resp = await authenticateWithRedirect(localIntegrationId, url);

  window.location.href = resp.redirect_url;

  return resp;
};

export function getIntegrationsOrganizations(
  integrationId: number,
): Promise<any> {
  const config: QueryParams<any> = {
    method: 'get',
    url: `${IntegrationRoute()}/${integrationId}/orgs`,
  };
  return getResponse<any>(getClient().api, config);
}
