/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { IntegrationTemplateHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { integrationTemplateHistoryAdapter } from './integrationTemplateHistoryAdapter';
import { integrationTemplateHistoryFetchThunk } from './integrationTemplateHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const integrationTemplateHistorySlice = createSlice({
  name: 'integrationTemplateHistory',
  initialState:
    integrationTemplateHistoryAdapter.getInitialState<TQStateProperties>({
      loading: 'idle',
      error: undefined,
    }),
  reducers: {
    integrationTemplateHistoryCleared(state) {
      state.loading = 'idle';
      integrationTemplateHistoryAdapter.removeAll(state);
    },
    integrationTemplateHistoryUpsertMany(
      state,
      action: PayloadAction<
        | IntegrationTemplateHistory[]
        | Record<number, IntegrationTemplateHistory>
      >,
    ) {
      integrationTemplateHistoryAdapter.upsertMany(state, action.payload);
    },
    integrationTemplateHistoryUpsertOne(
      state,
      action: PayloadAction<IntegrationTemplateHistory>,
    ) {
      integrationTemplateHistoryAdapter.upsertOne(state, action.payload);
    },
    integrationTemplateHistoryUpdateOne(
      state,
      action: PayloadAction<Update<IntegrationTemplateHistory>>,
    ) {
      integrationTemplateHistoryAdapter.updateOne(state, action.payload);
    },
    integrationTemplateHistoryRemoveOne(state, action: PayloadAction<number>) {
      integrationTemplateHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      integrationTemplateHistoryFetchThunk.pending,
      handlePending,
    );
    builder.addCase(
      integrationTemplateHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        integrationTemplateHistoryAdapter.upsertMany(
          state,
          action.payload.integrationTemplateHistory,
        );
      },
    );
    builder.addCase(
      integrationTemplateHistoryFetchThunk.rejected,
      handleRejected,
    );
  },
});

export const {
  integrationTemplateHistoryCleared,
  integrationTemplateHistoryUpsertMany,
  integrationTemplateHistoryUpsertOne,
  integrationTemplateHistoryUpdateOne,
  integrationTemplateHistoryRemoveOne,
} = integrationTemplateHistorySlice.actions;

export const integrationTemplateHistoryReducer =
  integrationTemplateHistorySlice.reducer;
