import React from 'react';
import { Button } from '@bitmodern/bit-ui';
import { UploadIcon } from '@bitmodern/bit-ui/icons';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { useTranslation } from 'src/i18n/hooks';
import styles from './JUnit.module.scss';
import ImportTable from '../ImportTable/ImportTable';
import useJUnit from './useJUnit';
import { Link } from 'react-router-dom';
import useModalManager from 'src/hooks/useModalManager';
import ExtraFields from '../ExtraFields';

export default function JUnit() {
  const { t } = useTranslation();
  const { site, projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const { hideModal } = useModalManager();

  const { formik, uploadFinished, handleDropFiles } = useJUnit({
    projectId: parseInt(projectId, 10),
  });

  const extraInfo = {
    project: !projectId,
    plan: true,
    suite: true,
    projectId: parseInt(projectId, 10) ?? null,
  };

  const onChangeFiles = (files: File[]) => {
    handleDropFiles(files);
  };

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <div className={styles.content}>
        <div className={styles.container}>
          <UploadIcon color={vars.textPrimary} size={24} />
          <div className={styles.title}>
            <span>Automation XML</span>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.description}>
            <p>{t('importDataForm.help.JUnitXML')}</p>
          </div>
        </div>
        <div className={styles.dropzone}>
          <ImportTable
            files={formik.values.filesToSend || []}
            filesTypeAccepted={['image/jpeg', 'image/png', '.xml']}
            onChangeFiles={onChangeFiles}
          />
        </div>
        <div className={styles.options}>
          <ExtraFields formik={formik} extraFields={extraInfo} />
          {uploadFinished && (
            <div className={styles.container}>
              <Link
                className={styles.link}
                to={routes.RUNS({ site, projectId })}>
                {t('importDataForm.importResults')}
              </Link>
            </div>
          )}
        </div>
        <div className={styles.submit}>
          {uploadFinished ? (
            <Button color="primaryDark" name="cancel" onClick={hideModal}>
              {t('importDataForm.close')}
            </Button>
          ) : (
            <Button
              loading={formik.isSubmitting}
              type="submit"
              disabled={formik.values.filesToSend.length === 0}>
              {t('importDataForm.submit')}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
