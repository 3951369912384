import { createSelector } from 'reselect';
import { planPurposeSelectors } from 'src/gen/domain/plan_purpose/planPurposeSelector';

export const planPurposesByPlanSelector = createSelector(
  [
    planPurposeSelectors.selectAll,
    (_, { planId }: { planId?: number }) => planId,
  ],
  (planPurpose, planId) => {
    return planPurpose.filter((pp) => pp.plan_id === planId);
  },
);
