/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Subscriptions,
  subscriptionsGetMany,
  subscriptionsGetOne,
  subscriptionsDeleteOne,
  subscriptionsUpdateOne,
  subscriptionsCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { subscriptionsNormalizer } from './subscriptionsNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const subscriptionsFetchManyThunk = createAsyncThunk(
  'subscriptions/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Subscriptions>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await subscriptionsGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, subscriptionsNormalizer);
      }
      return {
        subscriptions: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const subscriptionsFetchOneThunk = createAsyncThunk(
  'subscriptions/FetchOneThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<Subscriptions>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'subscriptions/FetchOneThunk',
        );
      }
      arg.cancelToken = source.token;
      const data = await subscriptionsGetOne(arg.id as number, arg);
      return executeNormalizer([data], subscriptionsNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const subscriptionsDeleteOneThunk = createAsyncThunk(
  'subscriptions/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Subscriptions>) => {
    try {
      if (!arg.id) {
        throw new ThunkError(
          'Must supply an id',
          'subscriptions/DeleteOneThunk',
        );
      }
      return await subscriptionsDeleteOne(arg.id as number, arg).then(
        () => arg,
      );
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const subscriptionsUpdateOneThunk = createAsyncThunk(
  'subscriptions/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Subscriptions>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError(
          'Must supply an id',
          'subscriptions/UpdateOneThunk',
        );
      }
      const data = await subscriptionsUpdateOne(
        arg.id as number,
        arg.data,
        arg,
      );
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Subscriptions>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Subscriptions>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const subscriptionsCreateOneThunk = createAsyncThunk(
  'subscriptions/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Subscriptions>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'subscriptions/CreateOneThunk',
        );
      }
      return await subscriptionsCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
