import React, { ComponentProps } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '@bitmodern/redux/state/authentication/selectors';
import AppRoute from './AppRoute';
import routes from './routes';

type Props = ComponentProps<typeof AppRoute>;

/**
 * Route that requieres authentication but not require
 * an active subscription
 */
export function SubscribeRoute(props: Props) {
  const isLoggedIn = useSelector(isAuthenticatedSelector);

  if (!isLoggedIn) {
    if (props.location) {
      sessionStorage.setItem('authFrom', JSON.stringify(props.location));
    }
    return <Redirect to={routes.LOGIN({})} />;
  }

  return <AppRoute {...props} />;
}
