/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { statusAdapter } from './statusAdapter';

export const statusSelectors = statusAdapter.getSelectors(
  (state: State) => state.gen.status,
);
