/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { AppVersionHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { appVersionHistoryAdapter } from './appVersionHistoryAdapter';
import { appVersionHistoryFetchThunk } from './appVersionHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const appVersionHistorySlice = createSlice({
  name: 'appVersionHistory',
  initialState: appVersionHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    appVersionHistoryCleared(state) {
      state.loading = 'idle';
      appVersionHistoryAdapter.removeAll(state);
    },
    appVersionHistoryUpsertMany(
      state,
      action: PayloadAction<
        AppVersionHistory[] | Record<number, AppVersionHistory>
      >,
    ) {
      appVersionHistoryAdapter.upsertMany(state, action.payload);
    },
    appVersionHistoryUpsertOne(
      state,
      action: PayloadAction<AppVersionHistory>,
    ) {
      appVersionHistoryAdapter.upsertOne(state, action.payload);
    },
    appVersionHistoryUpdateOne(
      state,
      action: PayloadAction<Update<AppVersionHistory>>,
    ) {
      appVersionHistoryAdapter.updateOne(state, action.payload);
    },
    appVersionHistoryRemoveOne(state, action: PayloadAction<number>) {
      appVersionHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appVersionHistoryFetchThunk.pending, handlePending);
    builder.addCase(appVersionHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      appVersionHistoryAdapter.upsertMany(
        state,
        action.payload.appVersionHistory,
      );
    });
    builder.addCase(appVersionHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  appVersionHistoryCleared,
  appVersionHistoryUpsertMany,
  appVersionHistoryUpsertOne,
  appVersionHistoryUpdateOne,
  appVersionHistoryRemoveOne,
} = appVersionHistorySlice.actions;

export const appVersionHistoryReducer = appVersionHistorySlice.reducer;
