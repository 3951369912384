import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Button, Input, PanelHeader, Surface } from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { formikError } from 'src/utils/formik';
import Yup from 'src/utils/yup';
import { TestQualityLogoIcon } from 'src/packages/bit-ui/icons';
import { getClient } from 'src/Client';
import { showNotificationError } from '@testquality/sdk';
import { useQuery } from 'src/hooks/useQuery';
import { useTheme } from 'src/hooks/useTheme';
import routes from 'src/components/Router/routes';
import styles from './LoginSSO.module.scss';

type Values = {
  email: string;
};

const initialValues: Values = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required().label('loginSso.form.email'),
});

export function LoginSSO() {
  const [theme] = useTheme();
  const { t } = useTranslation();
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  const { callback } = useQuery(['callback']);

  const generateLoginTokenUrl = () => {
    const webUrl = window.location.origin;
    const loginTokenPath = routes.LOGIN_TOKEN({});

    return `${webUrl}${loginTokenPath}/?token=`;
  };

  function onSubmit({ email }: Values, helpers: FormikHelpers<Values>) {
    // we only stop loading on error to keep it while doing href redirection
    const callbackUrl = callback || generateLoginTokenUrl();

    helpers.setSubmitting(true);
    getClient()
      .getAuth()
      .loginSSO(email, callbackUrl)
      .then(({ redirect_url }) => {
        window.location.href = redirect_url;
      })
      .catch((error) => {
        helpers.setSubmitting(false);
        showNotificationError(error);
      });
  }

  return (
    <div className={styles.sso}>
      <TestQualityLogoIcon className={styles.logo} size={200} theme={theme} />
      <Surface className={styles.surface}>
        <PanelHeader title={t('loginSso.title')} />
        <form
          className={styles.content}
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}>
          <Input
            error={formikError(formik, 'email')}
            fullWidth
            label={t('loginSso.form.email')}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <Button
            className={styles.submit}
            color="primaryLight"
            fullWidth
            loading={formik.isSubmitting}
            type="submit"
            size="large">
            {t('loginSso.form.submit')}
          </Button>
        </form>
      </Surface>
    </div>
  );
}
