/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { PlanHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { planHistoryAdapter } from './planHistoryAdapter';
import { planHistoryFetchThunk } from './planHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const planHistorySlice = createSlice({
  name: 'planHistory',
  initialState: planHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    planHistoryCleared(state) {
      state.loading = 'idle';
      planHistoryAdapter.removeAll(state);
    },
    planHistoryUpsertMany(
      state,
      action: PayloadAction<PlanHistory[] | Record<number, PlanHistory>>,
    ) {
      planHistoryAdapter.upsertMany(state, action.payload);
    },
    planHistoryUpsertOne(state, action: PayloadAction<PlanHistory>) {
      planHistoryAdapter.upsertOne(state, action.payload);
    },
    planHistoryUpdateOne(state, action: PayloadAction<Update<PlanHistory>>) {
      planHistoryAdapter.updateOne(state, action.payload);
    },
    planHistoryRemoveOne(state, action: PayloadAction<number>) {
      planHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(planHistoryFetchThunk.pending, handlePending);
    builder.addCase(planHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      planHistoryAdapter.upsertMany(state, action.payload.planHistory);
    });
    builder.addCase(planHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  planHistoryCleared,
  planHistoryUpsertMany,
  planHistoryUpsertOne,
  planHistoryUpdateOne,
  planHistoryRemoveOne,
} = planHistorySlice.actions;

export const planHistoryReducer = planHistorySlice.reducer;
