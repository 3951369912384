/* eslint-disable react/prop-types */
import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import useModalManager from 'src/hooks/useModalManager';
import { walk } from 'react-sortable-tree';
import { Button, PanelActions, PanelHeader } from '@bitmodern/bit-ui';
import { RunResultTreeItem } from '@bitmodern/redux/state/runResults/selectors';
import { useTranslation } from 'src/i18n/hooks';
import styles from './DefectsTree.module.scss';
import {
  SUITE_TYPE,
  TEST_TYPE,
} from 'src/components/organisms/TreeBase/treeTypes';
import TreeBase from 'src/components/organisms/TreeBase';
import { SuiteListed, TestListed } from 'src/components/organisms';

type Props = {
  id: string;
  tree: RunResultTreeItem[];
};

function DefectsTree({ id, tree }: Props) {
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [defaultChecks, setDefaultChecks] = useState<{}>({});
  const { showModal } = useModalManager();

  const onCheckItems = useCallback((items) => {
    const ids = items
      .filter((item) => item.type === TEST_TYPE)
      .flatMap((item) => item.data.suiteTest)
      .map((suiteTest) => suiteTest.id);

    setCheckedItems(ids);
  }, []);

  // This useEffect will trigger one time when the list of Tests is open and will mark every test by default
  useEffect(() => {
    const idsCheckeds: number[] = [];
    const nextCheckeds = {};

    walk({
      treeData: tree,
      getNodeKey: (item) => item.node.nodeKey,
      callback: (item) => {
        if (item?.node?.data?.suiteTest?.id) {
          idsCheckeds.push(item.node.data.suiteTest.id);
          nextCheckeds[item.node.nodeKey] = true;
        }
      },
      ignoreCollapsed: false,
    });

    setDefaultChecks(nextCheckeds);
    setCheckedItems(idsCheckeds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <PanelHeader
        title={t('defects.defectTree.title')}
        actions={
          <PanelActions>
            <Button
              color="primaryLight"
              onClick={() => {
                showModal({
                  modalName: 'startRun',
                  modalProps: {
                    planId: null,
                    suiteTestsIds: checkedItems,
                  },
                  type: 'modal',
                });
              }}>
              {t('defect.rerun')}
            </Button>
          </PanelActions>
        }
      />

      <div className={styles.wrapper}>
        <TreeBase
          canDrag={false}
          checkeds={defaultChecks}
          id={id}
          onCheckItems={onCheckItems}
          renderNodeType={{
            [TEST_TYPE]: (item) => {
              const { node, onCheckParentChange, path, treeIndex } = item;
              const { data } = node;
              return (
                <TestListed
                  checked={node.checked}
                  nestingLevel={path.length - 1}
                  onChangeChecked={(value) => {
                    if (onCheckParentChange)
                      onCheckParentChange(value, { node, treeIndex, path });
                  }}
                  planSuiteTestInclude={data.include}
                  suiteTest={data.suiteTest}
                  test={data.test}
                  withActions={false}
                />
              );
            },
            [SUITE_TYPE]: (item) => {
              const {
                node,
                style,
                treeIndex,
                path,
                onCheckParentChange,
                toggleChildrenVisibility,
              } = item;
              const { data } = node;
              return (
                <SuiteListed
                  checked={node.checked}
                  collapsed={node.expanded || false}
                  collapsible={Boolean(node.children?.length)}
                  nestingLevel={path.length - 1}
                  onChangeChecked={(value) => {
                    if (onCheckParentChange)
                      onCheckParentChange(value, { node, treeIndex, path });
                  }}
                  onClick={() => {
                    if (!toggleChildrenVisibility) return;
                    toggleChildrenVisibility({ node, path, treeIndex });
                  }}
                  planSuite={data.planSuite}
                  style={style as CSSProperties}
                  suite={data.suite}
                  withActions={false}
                />
              );
            },
          }}
          treeData={tree}
        />
      </div>
    </>
  );
}

export default memo(DefectsTree);
