import { generatePath } from 'react-router';
import { AnalyzeTabs } from '../views/AnalyzeView/AnalyzeView';

function createRoute<Params extends {}>(path) {
  const route = (params: Params) => generatePath(path, params);
  route.toString = () => path;
  route.path = path;
  route.params = {} as Params;

  return route;
}

// We assume /site is present in the url to determine if previous page is a site or not
// Check GuestRoute for more details
const BASE = createRoute<{ site: string }>('/site/:site');
const SITE_EMPTY = createRoute('/site');

const LOGIN = createRoute('/site/login');
const LOGIN_EMAIL = createRoute(`${LOGIN}/email`);
const LOGIN_SSO = createRoute(`${LOGIN}/sso`);
const LOGIN_TOKEN = createRoute(`${LOGIN}/token`);
const FREETRIAL = createRoute('/site/freeTrial');
const FREETRIAL_EMAIL = createRoute(`${FREETRIAL}/email`);
const COMPLETE_INVITE_USER = createRoute('/site/complete_invite_user');
const CONNECT_APP = createRoute('/connect-app');
const RECOVERY = createRoute('/site/recovery');
const RECOVERY_CHANGE = createRoute<{ token: string }>(
  '/site/password-reset/:token',
);

const HOME = BASE;

const EMAIL_VERIFICATION = createRoute<typeof SITE_EMPTY.params>(
  `${SITE_EMPTY}/email-verification`,
);

const INTEGRATIONS = createRoute<typeof BASE.params>(`${BASE}/integrations`);
const INTEGRATION_SETUP = createRoute<
  typeof BASE.params & { baseIntegrationId: string }
>(`${BASE}/integrations/setup/:baseIntegrationId`);

const INTEGRATION = createRoute<typeof BASE.params & { integrationId: string }>(
  `${BASE}/integrations/:integrationId`,
);

const INTEGRATION_PROJECT_CREATE = createRoute<typeof INTEGRATION.params>(
  `${INTEGRATION}/create`,
);
const PROJECT_INTEGRATION = createRoute<
  typeof INTEGRATION.params & { projectId: string; externalRef?: string }
>(`${INTEGRATION}/project/:projectId/external/:externalRef?`);

const PROJECT_CREATE = createRoute<typeof BASE.params>(
  `${BASE}/project_create`,
);
const PROJECT_EMPTY = createRoute<typeof BASE.params>(`${BASE}/project`);
const PROJECT = createRoute<typeof BASE.params & { projectId: string }>(
  `${BASE}/project/:projectId`,
);
const OVERVIEW = PROJECT;
const RUNS = createRoute<typeof PROJECT.params>(`${PROJECT}/runs`);
const RUN = createRoute<typeof RUNS.params & { runId: string }>(
  `${RUNS}/:runId`,
);
const RUN_OVERVIEW = RUN;

const ANALYZE = createRoute<typeof PROJECT.params & { tab?: AnalyzeTabs }>(
  `${PROJECT}/analyze/:tab?`,
);
const PLANS = createRoute<typeof PROJECT.params>(`${PROJECT}/cycles`);
const PLAN = createRoute<typeof PROJECT.params & { planId: string }>(
  `${PROJECT}/cycles/:planId`,
);

const REQUIREMENTS = createRoute<typeof PROJECT.params>(`${PROJECT}/stories`);
const REQUIREMENT = createRoute<
  typeof PROJECT.params & { requirementId: string }
>(`${PROJECT}/stories/:requirementId`);

const IMPORT = createRoute<typeof PROJECT.params>(`${PROJECT}/import`);
const IMPORT_SLUG = createRoute<typeof PROJECT.params & { slug?: string }>(
  `${PROJECT}/import/:slug?`,
);
const AUTOMATIONS = createRoute<typeof BASE.params>(`${BASE}/import`);
const IMPORT_AUTOMATION = createRoute<typeof BASE.params & { slug?: string }>(
  `${BASE}/import-automation/:slug?`,
);
const IMPORT_DATA = createRoute<typeof BASE.params & { slug?: string }>(
  `${BASE}/import/:slug?`,
);

const PLAN_OVERVIEW = PLAN;
const PLAN_CREATE = createRoute<typeof PLANS.params>(`${PLANS}/create`);
const PROJECT_TESTS = createRoute<typeof PROJECT.params>(`${PROJECT}/tests`);

const DEFECT_CREATE = createRoute<typeof RUN.params & { runId: string }>(
  `${RUN}/defect/create`,
);
const REQUIREMENT_CREATE = createRoute<
  typeof PROJECT.params & { testId: string }
>(`${PROJECT}/test/:testId/requirement/create`);
const GET_STARTED = createRoute<typeof BASE.params>(`${BASE}/get-started`);

const USER = createRoute<typeof BASE.params & { userId: string }>(
  `${BASE}/users/:userId`,
);
const USERS = createRoute<typeof BASE.params>(`${BASE}/users`);
const USER_CREATE = createRoute<typeof BASE.params>(`${USERS}/create`);

const SETTINGS = createRoute<typeof BASE.params>(`${BASE}/settings`);
const PROFILE = createRoute<typeof BASE.params>(`${BASE}/profile`);
const COMPANY = createRoute<typeof BASE.params>(`${BASE}/company`);

const ACCESS_ROLES = createRoute<typeof BASE.params>(`${BASE}/roles`);
const WATCH = createRoute<typeof BASE.params>(`${BASE}/watch`);
const LOOKUP = createRoute<typeof BASE.params>(`${BASE}/lookup`);
const SPACE = createRoute<typeof BASE.params>(`${BASE}/space`);
const CUSTOMIZATION = createRoute<typeof BASE.params>(`${BASE}/customization`);
const CONFIGURATIONS = createRoute<typeof BASE.params>(
  `${BASE}/configurations`,
);

const SUBSCRIPTIONS = createRoute<typeof BASE.params>(`${BASE}/subscriptions`);
const GITHUB = createRoute<typeof BASE.params>(`${BASE}/github`);
const GITHUB_INSTALL = createRoute<typeof BASE.params>('/github/install');
const GITHUB_MARKET = createRoute('/github/market');

export default {
  ACCESS_ROLES,
  ANALYZE,
  AUTOMATIONS,
  COMPANY,
  COMPLETE_INVITE_USER,
  CONFIGURATIONS,
  CONNECT_APP,
  CUSTOMIZATION,
  DEFECT_CREATE,
  EMAIL_VERIFICATION,
  FREETRIAL_EMAIL,
  FREETRIAL,
  GET_STARTED,
  GITHUB_INSTALL,
  GITHUB_MARKET,
  GITHUB,
  HOME,
  IMPORT_AUTOMATION,
  IMPORT,
  IMPORT_SLUG,
  IMPORT_DATA,
  INTEGRATION_PROJECT_CREATE,
  INTEGRATION_SETUP,
  INTEGRATION,
  INTEGRATIONS,
  LOGIN_EMAIL,
  LOGIN_SSO,
  LOGIN_TOKEN,
  LOGIN,
  LOOKUP,
  OVERVIEW,
  PLAN_CREATE,
  PLAN_OVERVIEW,
  PLAN,
  PLANS,
  PROFILE,
  PROJECT_CREATE,
  PROJECT_EMPTY,
  PROJECT_INTEGRATION,
  PROJECT_TESTS,
  PROJECT,
  RECOVERY_CHANGE,
  RECOVERY,
  REQUIREMENT_CREATE,
  REQUIREMENT,
  REQUIREMENTS,
  RUN_OVERVIEW,
  RUN,
  RUNS,
  SETTINGS,
  SITE_EMPTY,
  SPACE,
  SUBSCRIPTIONS,
  USER_CREATE,
  USER,
  USERS,
  WATCH,
};
