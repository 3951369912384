import React, { ComponentProps } from 'react';
import { LocationDescriptor } from 'history';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import {
  isAuthenticatedSelector,
  tokenSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import AppRoute from './AppRoute';
import routes from './routes';

type Props = ComponentProps<typeof AppRoute>;

/**
 * Route only accessible for guest users.
 * Logged in users have no access to these routes
 */
export default function GuestRoute(props: Props) {
  const isLoggedIn = useSelector(isAuthenticatedSelector);
  const appToken = useSelector(tokenSelector);
  const site = appToken?.client_name || '';

  if (isLoggedIn && site) {
    let from: Location | undefined;
    try {
      from = JSON.parse(sessionStorage.getItem('authFrom') || '');
      sessionStorage.removeItem('authFrom');
    } catch (error) {}

    let to: LocationDescriptor = from || { pathname: routes.HOME({ site }) };

    if (siteChanged(from?.pathname, site)) {
      to = { pathname: routes.HOME({ site }) };
    }
    return <Redirect to={to} />;
  }

  return <AppRoute {...props} />;
}

function siteChanged(from = '', site) {
  const match = matchPath<typeof routes.HOME.params>(from, {
    path: routes.HOME.path,
    exact: false,
  });

  // no site in the path, so no change
  if (!site || !match?.params?.site) return false;

  return site !== match?.params?.site;
}
