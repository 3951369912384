/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { baseCapabilityBaseIntegrationAdapter } from './baseCapabilityBaseIntegrationAdapter';

export const baseCapabilityBaseIntegrationSelectors =
  baseCapabilityBaseIntegrationAdapter.getSelectors(
    (state: State) => state.gen.baseCapabilityBaseIntegration,
  );
