import React, { ComponentType, useCallback, useState } from 'react';
import { TestQualityLogoIcon } from 'src/packages/bit-ui/icons';
import { useQuery } from 'src/hooks/useQuery';
import { useTheme } from 'src/hooks/useTheme';
import styles from './EmailPanel.module.scss';

import Debug from 'debug';
const debug = Debug('EmailPanel');

type AuthStep = 1 | 2;

export type EmailPanelProps = {
  onChangeStep: (step: AuthStep) => void;
  step: AuthStep;
  Step1: ComponentType<{
    email: string;
    onSubmit: ({ email }: { email: string }) => void;
  }>;
  Step2?: ComponentType<{ email: string }>;
};

export function EmailPanel({
  onChangeStep,
  step,
  Step1,
  Step2,
}: EmailPanelProps) {
  const { email: queryEmail } = useQuery(['email']);
  const [email, setEmail] = useState(queryEmail);
  const [theme] = useTheme();

  const onSubmit = useCallback(
    async ({ email }: { email: string }) => {
      debug('onSubmit', { email });
      setEmail(email);
      onChangeStep(2);
    },
    [onChangeStep],
  );

  return (
    <div className={styles.main}>
      <TestQualityLogoIcon className={styles.logo} size={200} theme={theme} />
      {step === 1 && <Step1 email={email} onSubmit={onSubmit} />}
      {step === 2 && Step2 && <Step2 email={email} />}
    </div>
  );
}
