import { createSelector } from '@reduxjs/toolkit';
import { defectUserSelectors } from 'src/gen/domain/defect_user/defectUserSelector';
import { State } from '@bitmodern/redux/store';

export function defectUsersSelector(state: State) {
  return defectUserSelectors.selectAll(state);
}

export const defectUsersByIntegrationProjectSelector = (
  integrationProjectId?: number,
) =>
  createSelector(defectUsersSelector, (defectUsers) =>
    defectUsers.filter(
      ({ integration_project_id }) =>
        integration_project_id === integrationProjectId,
    ),
  );
