import React, { useMemo } from 'react';
import {
  PanelHeader,
  Surface,
  Table,
  TableBody,
  TableCell,
  TableEmpty,
  TableHead,
} from '@bitmodern/bit-ui';
import { BugIcon } from '@bitmodern/bit-ui/icons';
import { Defect } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import vars from 'src/export.scss';
import DefectItem from '../DefectItem';
import styles from './Defects.module.scss';

type Props = {
  className?: string;
  defects: Defect[];
};

export default function Defects({ className, defects }: Props) {
  const { t } = useTranslation();

  /* NOTE: We create a defect entity for each run result that has */
  /* an issue associated with it. The issue could be the same. */
  const uniqueDefects = useMemo(() => {
    const defectExtKeys: string[] = [];
    return defects.filter((defect) => {
      if (defectExtKeys.includes(defect.external_reference_id)) {
        return false;
      }

      defectExtKeys.push(defect.external_reference_id);
      return true;
    });
  }, [defects]);

  return (
    <Surface className={className}>
      <PanelHeader
        title={
          <div className={styles.title}>
            <BugIcon color={vars.textPrimary} size={20} />
            <span>{t('defects.title')}</span>
          </div>
        }
      />
      <Table maxHeight={400}>
        <TableHead className={styles.head}>
          <TableCell>{t('defects.head.key')}</TableCell>
          <TableCell>{t('defects.head.summary')}</TableCell>
          <TableCell>{t('defects.head.created')}</TableCell>
          <TableCell>{t('defects.head.assigned')}</TableCell>
          <TableCell>{t('defects.head.status')}</TableCell>
        </TableHead>
        {uniqueDefects.length ? (
          <TableBody>
            {uniqueDefects.map((defect) => (
              <DefectItem defect={defect} key={defect.id} />
            ))}
          </TableBody>
        ) : (
          <TableEmpty
            colSpan={7}
            description={t('defects.empty.description')}
            icon={<BugIcon color={vars.textSecondary} size={64} />}
            title={t('defects.empty.title')}
          />
        )}
      </Table>
    </Surface>
  );
}
