import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/i18n/hooks';
import { Surface } from '@bitmodern/bit-ui';
import classnames from 'classnames';
import { Plan } from '@testquality/sdk';
import {
  BookmarkIcon,
  BugIcon,
  SettingsIcon,
  TestIcon,
} from '@bitmodern/bit-ui/icons';
import { makeRequirementsByPlan } from '@bitmodern/redux/state/requirements/selectors';
import { makeAppVersionPlatVersionPlansByPlan } from '@bitmodern/redux/state/appVersionPlatVersionPlans/selectors';
import { makeDefectsCountByPlan } from '@bitmodern/redux/state/defects/selectors';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';
import { useMaker } from 'src/packages/redux/hooks';
import styles from './PlanListed.module.scss';

type Props = {
  plan: Plan;
  selected?: boolean;
  site: string;
  testsCount: number;
};

function PlanListed({ plan, selected = false, site, testsCount }: Props) {
  const { t } = useTranslation();

  const planId = plan.id;
  const defectsCount = useMaker(makeDefectsCountByPlan, { planId });
  const requirements = useMaker(makeRequirementsByPlan, { planId });
  const avpvps = useMaker(makeAppVersionPlatVersionPlansByPlan, { planId });

  const reqsCount = requirements.length;
  const configurationsCount = avpvps.length;

  const planListedCN = classnames(styles.planListed, {
    [styles.selected]: selected,
  });

  return (
    <Link
      className={styles.link}
      to={routes.PLAN({
        site,
        projectId: plan.project_id.toString(),
        planId: String(plan.id),
      })}>
      <Surface className={planListedCN}>
        <div className={styles.name}>{plan.name}</div>
        <div className={styles.info}>
          <div className={`${styles.infoItem} ${styles.tests}`}>
            <TestIcon
              className={styles.icon}
              color={vars.textSecondary}
              size={16}
            />
            <span>{t('plans.item.tests', { count: testsCount })}</span>
          </div>
          {configurationsCount > 0 && (
            <div className={styles.infoItem}>
              <SettingsIcon
                className={styles.icon}
                color={vars.textSecondary}
                size={16}
              />
              {configurationsCount}
            </div>
          )}
          <div className={styles.infoItem}>
            <BookmarkIcon
              className={styles.icon}
              color={vars.textSecondary}
              size={16}
            />
            {reqsCount}
          </div>
          <div className={styles.infoItem}>
            <BugIcon
              className={styles.icon}
              color={vars.textSecondary}
              size={18}
            />
            {defectsCount}
          </div>
        </div>
      </Surface>
    </Link>
  );
}

export default memo(PlanListed);
