import { StatusEnum, StatusTypeEnum } from 'src/enums/StatusEnum';
import vars from 'src/export.scss';
import { Status, StatusType } from '@testquality/sdk';

export function getStatusColor(status: Status): string {
  switch (status.key) {
    case StatusEnum.Pass:
      return vars.successMain;
    case StatusEnum.Pending:
      return vars.warningMain;
    case StatusEnum.Fail:
      return vars.errorMain;
    case StatusEnum.Block:
      return vars.errorMain;
    case StatusEnum.Retest:
      return vars.warningMain;
    case StatusEnum.Skip:
      return vars.skipMain;
    default:
      return '';
  }
}

export function getStatusChartColor(status: Status): string {
  switch (status.key) {
    case StatusEnum.Pass:
      return vars.chartPass;
    case StatusEnum.Pending:
      return vars.chartPending;
    case StatusEnum.Fail:
      return vars.chartFail;
    case StatusEnum.Block:
      return vars.chartBlock;
    case StatusEnum.Retest:
      return vars.chartRetest;
    case StatusEnum.Skip:
      return vars.chartSkip;
    default:
      return '';
  }
}

export function getStatusTypeChartColor(status?: StatusType): string {
  switch (status?.key) {
    case StatusTypeEnum.Positive:
      return vars.chartPass;
    case StatusTypeEnum.Neutral:
      return vars.chartPending;
    case StatusTypeEnum.Negative:
      return vars.chartFail;
    default:
      return '';
  }
}

export function getRawStatusColor(status: Status): string {
  switch (status.key) {
    case StatusEnum.Pass:
      return '#58b880';
    case StatusEnum.Pending:
      return '#ff9000';
    case StatusEnum.Fail:
      return '#ff5538';
    case StatusEnum.Block:
      return '#ff5538';
    case StatusEnum.Retest:
      return '#FCF0BA';
    case StatusEnum.Skip:
      return '#ff9000';
    default:
      return '#ff5538';
  }
}

export function getStatusTypeColor(statusType?: StatusType) {
  switch (statusType?.key) {
    case StatusTypeEnum.Positive:
      return vars.successMain;
    case StatusTypeEnum.Neutral:
      return vars.warningMain;
    case StatusTypeEnum.Negative:
      return vars.errorMain;
    default:
      return '';
  }
}
