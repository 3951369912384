/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectPriority } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectPriorityAdapter } from './defectPriorityAdapter';
import { defectPriorityFetchManyThunk } from './defectPriorityThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectPrioritySlice = createSlice({
  name: 'defectPriority',
  initialState: defectPriorityAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectPriorityCleared(state) {
      state.loading = 'idle';
      defectPriorityAdapter.removeAll(state);
    },
    defectPriorityUpsertMany(
      state,
      action: PayloadAction<DefectPriority[] | Record<number, DefectPriority>>,
    ) {
      defectPriorityAdapter.upsertMany(state, action.payload);
    },
    defectPriorityUpsertOne(state, action: PayloadAction<DefectPriority>) {
      defectPriorityAdapter.upsertOne(state, action.payload);
    },
    defectPriorityUpdateOne(
      state,
      action: PayloadAction<Update<DefectPriority>>,
    ) {
      defectPriorityAdapter.updateOne(state, action.payload);
    },
    defectPriorityRemoveOne(state, action: PayloadAction<number>) {
      defectPriorityAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectPriorityFetchManyThunk.pending, handlePending);
    builder.addCase(defectPriorityFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      defectPriorityAdapter.upsertMany(state, action.payload.defectPriority);
    });
    builder.addCase(defectPriorityFetchManyThunk.rejected, handleRejected);
  },
});

export const {
  defectPriorityCleared,
  defectPriorityUpsertMany,
  defectPriorityUpsertOne,
  defectPriorityUpdateOne,
  defectPriorityRemoveOne,
} = defectPrioritySlice.actions;

export const defectPriorityReducer = defectPrioritySlice.reducer;
