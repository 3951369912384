import React, { ReactNode, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useHistory, useParams } from 'react-router';
import { Content } from '@bitmodern/bit-ui';
import { getSize, setSize } from '@bitmodern/services/ResizingService';
import { routes } from 'src/components/Router';
import { useAppSelector } from '@bitmodern/redux/store';
import { requirementsByProjectSelector } from '@bitmodern/redux/state/requirements/selectors';
import RequirementsContainer from 'src/components/organisms/RequirementsContainer';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import { EmptyTests } from 'src/components/organisms';
import EmptyRequirements from './EmptyRequirements';
import styles from './RequirementsView.module.scss';

const STORAGE_KEY = 'requirementsWidth';

type Props = {
  children: ReactNode;
};

export default function RequirementsView({ children }: Props) {
  const history = useHistory();
  const { site, requirementId, ...params } =
    useParams<typeof routes.REQUIREMENT.params>();
  const projectId = parseInt(params.projectId, 10);

  const [initialWidth] = useState(() => getSize(STORAGE_KEY) || 350);

  const requirements = useAppSelector((state) =>
    requirementsByProjectSelector(state, { projectId }),
  );
  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId }),
  );

  useEffect(() => {
    if (!requirementId && requirements.length > 0) {
      history.replace(
        routes.REQUIREMENT({
          site,
          requirementId: requirements[0].id.toString(),
          projectId: projectId.toString(),
        }),
      );
    }
  }, [history, requirementId, requirements, projectId, site]);

  const onResizeStop = (event, direaction, elementRef) => {
    setSize(STORAGE_KEY, elementRef.offsetWidth);
  };

  const requirementsEmpty = requirements.length === 0;
  const testsEmpty = tests.length === 0;

  let empty;

  if (testsEmpty) {
    empty = (
      <EmptyTests filterType="requirements" potentialAmount={tests.length} />
    );
  } else if (requirementsEmpty) {
    empty = <EmptyRequirements site={site} projectId={projectId} />;
  }

  return (
    <Content fullHeight>
      {!empty ? (
        <div className={styles.plans}>
          <Resizable
            className={styles.aside}
            defaultSize={{
              height: '100%',
              width: initialWidth,
            }}
            enable={{ right: true }}
            maxWidth={450}
            minWidth={350}
            onResizeStop={onResizeStop}>
            <RequirementsContainer />
          </Resizable>
          <div className={styles.content}>{children}</div>
        </div>
      ) : (
        empty
      )}
    </Content>
  );
}
