import { useId } from 'react-aria';
import { useCallback, useEffect, useState } from 'react';

interface Edit {
  onCommit?: () => Promise<any>;
  onCancel?: () => void;
  id: string;
}

class InlineEditManager {
  private edit?: Edit;
  private subscribers: Array<(editId?: Edit['id']) => void> = [];

  setEdit = (updater: (edit?: Edit) => Edit | undefined) => {
    this.edit = updater(this.edit);
    this.notifySubscribers();
  };

  notifySubscribers = () => {
    const editId = this.edit?.id;
    this.subscribers.forEach((subscriber) => subscriber(editId));
  };

  subscribe = (callback: (editId: string | undefined) => void) => {
    this.subscribers.push(callback);
    return () => {
      this.subscribers = this.subscribers.filter((c) => callback !== c);
    };
  };

  clear = () => {
    this.setEdit(() => undefined);
  };

  open = (edit: Edit) => {
    if (this.edit?.onCommit && this.edit?.id !== edit.id) {
      this.edit.onCommit();
    }
    this.setEdit(() => edit);
  };

  cancel = () => {
    if (this.edit?.onCancel) {
      this.edit.onCancel();
    }
    this.clear();
  };

  commit = async () => {
    try {
      if (this.edit?.onCommit) {
        await this.edit.onCommit();
      }
    } finally {
      this.clear();
    }
  };

  getId() {
    return this.edit?.id;
  }

  setOnCommit = (onCommit: Edit['onCommit']) => {
    if (this.edit) {
      this.edit.onCommit = onCommit;
    }
  };

  setOnCancel = (onCancel: Edit['onCancel']) => {
    if (this.edit) {
      this.edit.onCancel = onCancel;
    }
  };
}

export const inlineEditManager = new InlineEditManager();

export function useEditing(params: Partial<Edit>) {
  const { onCommit, onCancel } = params;

  const id = useId(params.id);
  const [currentEditId, setCurrentId] = useState(() =>
    inlineEditManager.getId(),
  );

  useEffect(() => {
    const cancelSubscription = inlineEditManager.subscribe(setCurrentId);
    return cancelSubscription;
  }, []);

  useEffect(() => {
    if (inlineEditManager.getId() === id) {
      inlineEditManager.setOnCommit(onCommit);
      inlineEditManager.setOnCancel(onCancel);
    }
  }, [id, onCancel, onCommit]);

  const open = useCallback(() => {
    inlineEditManager.open({ id, onCommit, onCancel });
  }, [id, onCommit, onCancel]);

  return {
    isEditing: id === currentEditId,
    cancel: inlineEditManager.cancel,
    commit: inlineEditManager.commit,
    clear: inlineEditManager.clear,
    open,
    id,
  };
}
