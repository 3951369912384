import React, { useCallback, useMemo, useState } from 'react';
import { Attachment, BatchService, IntegrationProject } from '@testquality/sdk';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { useLocation, useParams } from 'react-router';
import useFetch from 'src/hooks/useFetch';
import { useQuery } from 'src/hooks/useQuery';
import { routes } from 'src/components/Router';
import { runResultStepsByRunResultSelector } from '@bitmodern/redux/state/RunResultSteps/selectors';
import { stepsByTestSelector } from '@bitmodern/redux/state/steps/selectors';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { defectComponentFetchManyThunk } from 'src/gen/domain/defect_component/defectComponentThunk';
import { defectLabelFetchManyThunk } from 'src/gen/domain/defect_label/defectLabelThunk';
import { defectPriorityFetchManyThunk } from 'src/gen/domain/defect_priority/defectPriorityThunk';
import { defectUserFetchManyThunk } from 'src/gen/domain/defect_user/defectUserThunk';
import { defectTypeFetchManyThunk } from 'src/gen/domain/defect_type/defectTypeThunk';
import { integrationTemplateByCapabilitySelector } from '@bitmodern/redux/state/integrationTemplate/selectors';
import { testByRunResultSelector } from '@bitmodern/redux/state/runResults/selectors';
import { capabilitySelectors } from 'src/gen/domain/capability/capabilitySelector';
import { baseIntegrationByIdSelector } from '@bitmodern/redux/state/baseIntegration/selectors';
import { IntegrationSchema } from 'src/enums/IntegrationSchemaEnum';
import { CapabilityEnum } from 'src/enums/CapabilityEnum';
import { integrationByIdSelector } from '@bitmodern/redux/state/integration/selectors';
import { capabilityIntegrationBySchemaSelector } from '@bitmodern/redux/state/capabilityIntegration/selectors';
import { defectComponentsByIntegrationProjectSelector } from '@bitmodern/redux/state/defectComponents/selectors';
import { defectUsersByIntegrationProjectSelector } from '@bitmodern/redux/state/defectUsers/selectors';
import { defectTypesByIntegrationProjectSelector } from '@bitmodern/redux/state/defectTypes/selectors';
import { ownDefectLabelsByIntegrationProjectSelector } from '@bitmodern/redux/state/defectLabels/selectors';
import { defectPrioritiesByIntegrationProjectSelector } from '@bitmodern/redux/state/defectPriorities/selectors';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { buildIntegrationDescription } from 'src/utils/integrationTemplateHelper';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import { testSelectors } from '../../../gen/domain/test/testSelector';
import Step2 from './Step2';

type InitialValues = {
  components: Array<{ [key: string]: any }>;
  assignee: { [key: string]: any };
  attachments: Attachment[];
  description: string;
  labels: Array<{ [key: string]: any }>;
  priority: { [key: string]: any };
  type: { [key: string]: any };
  summary: string;
  integrationProjectId: number;
};

const validationSchema = Yup.object().shape({
  summary: Yup.string().required().label('defectCreate.form.summary'),
});

type Props = {
  initialSummary?: string;
  issueType: IntegrationSchema;
  integrationProject: IntegrationProject;
  onCreateDefect: (values: any) => Promise<any>;
};

export default function Step2Container({
  initialSummary,
  issueType,
  integrationProject,
  onCreateDefect,
}: Props) {
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const { testId } = useParams<typeof routes.REQUIREMENT_CREATE.params>();
  const dispatch = useAppDispatch();
  const location = useLocation<{ modalParentLocation?: string }>();
  const { resultIds } = useQuery(['resultIds'], location.search);
  const [isLinking, setIsLinking] = useState(false);

  const request = useCallback(() => {
    const batch = new BatchService();
    [
      defectComponentFetchManyThunk,
      defectLabelFetchManyThunk,
      defectPriorityFetchManyThunk,
      defectUserFetchManyThunk,
      defectTypeFetchManyThunk,
    ].forEach((fetchManyThunk) => {
      dispatch(
        fetchManyThunk({
          batch,
          params: {
            integration_project_id: integrationProject.id,
            per_page: -1,
          },
        }),
      );
    });

    return batch.executeBatch();
  }, [dispatch, integrationProject]);

  const { isLoading } = useFetch(request, 'step-2');

  const integration = useAppSelector((state) => {
    return integrationByIdSelector(state, {
      id: integrationProject.integration_id,
    });
  });

  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, {
      id: integration?.base_integration_id,
    }),
  );

  const baseIntegrationType = integrationKey(baseIntegration!);

  const isDefect = issueType === IntegrationSchema.DEFECT;

  const runResultIds = resultIds
    ? resultIds.split(',').map((id) => parseInt(id, 10))
    : [];
  const runResultId = runResultIds[0];

  const testByRunResult = useAppSelector((state) => {
    return testByRunResultSelector(state, runResultId);
  });
  const testById = useAppSelector((state) =>
    testSelectors.selectById(state, parseInt(testId, 10)),
  );
  const test = isDefect ? testByRunResult : testById;

  const testSteps = useAppSelector((state) => {
    return stepsByTestSelector(state, { testId: test ? test.id : undefined });
  });

  const runResultSteps = useAppSelector((state) => {
    return runResultStepsByRunResultSelector(state, runResultId);
  });

  const statuses = useAppSelector(statusSelectors.selectAll);

  const isJira =
    !!integration && integrationKey(integration) === IntegrationType.JIRA;

  const capabilityIntegrationSelector = useMemo(
    () => capabilityIntegrationBySchemaSelector(issueType, integration?.id),
    [integration, issueType],
  );

  const capabilityIntegration = useAppSelector(capabilityIntegrationSelector);

  const integrationTemplate = useAppSelector((state) =>
    integrationTemplateByCapabilitySelector(state, capabilityIntegration?.id),
  );

  const capability = useAppSelector((state) =>
    capabilitySelectors.selectById(
      state,
      capabilityIntegration?.capability_id!,
    ),
  );

  const defectComponents = useAppSelector(
    defectComponentsByIntegrationProjectSelector(integrationProject.id),
  );
  const defectPriorities = useAppSelector(
    defectPrioritiesByIntegrationProjectSelector(integrationProject.id),
  );
  const defectUsers = useAppSelector(
    defectUsersByIntegrationProjectSelector(integrationProject.id),
  );
  const defectTypes = useAppSelector(
    defectTypesByIntegrationProjectSelector(integrationProject.id),
  );
  const defectLabels = useAppSelector(
    ownDefectLabelsByIntegrationProjectSelector(integrationProject.id),
  );

  const defaultType =
    defectTypes?.find(
      (el) => el?.is_subtask === false && el?.name !== 'Epic',
    ) || {};

  const initialValues: InitialValues = {
    components: [],
    summary: initialSummary || test?.name || '',
    description: buildIntegrationDescription(
      test,
      testSteps,
      runResultSteps,
      statuses,
      baseIntegrationType,
      capability?.name as CapabilityEnum,
      integrationTemplate?.template,
    ),
    labels: [],
    priority: {},
    type: defaultType,
    assignee: {},
    attachments: [],
    integrationProjectId: integrationProject.id,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setIsLinking(true);
      onCreateDefect(values).finally(() => setIsLinking(false));
    },
    validationSchema,
  });

  return (
    <Step2
      defectComponents={defectComponents}
      defectLabels={defectLabels}
      defectPriorities={defectPriorities}
      defectUsers={defectUsers}
      defectTypes={defectTypes}
      formik={formik}
      isJira={isJira}
      integration={integration}
      isLoading={isLoading}
      isLinking={isLinking}
      projectId={parseInt(projectId, 10)}
    />
  );
}
