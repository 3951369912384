export enum SubscriptionIntervalEnum {
  Monthly = 'month',
  Yearly = 'year',
}

export function intervalToEnum(interval: string): SubscriptionIntervalEnum {
  switch (interval) {
    case 'year':
      return SubscriptionIntervalEnum.Yearly;
    case 'month':
      return SubscriptionIntervalEnum.Monthly;
    default:
      return SubscriptionIntervalEnum.Monthly;
  }
}
