import React, { memo, useRef } from 'react';
import {
  FocusScope,
  mergeProps,
  useOverlay,
  usePress,
  usePreventScroll,
} from 'react-aria';
import { motion } from 'framer-motion';
import styles from './ImageFullscreen.module.scss';

type Props = Pick<MotionImageProps, 'layoutId' | 'src'> & {
  onClose: () => void;
};

export default function ImageFullscreen({ layoutId, onClose, src }: Props) {
  const fullscreenPress = usePress({}); // used to stop propagation
  const { pressProps } = usePress({ onPress: onClose });
  usePreventScroll();
  const overlayRef = useRef(null);

  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen: true,
      isDismissable: true,
      onClose,
    },
    overlayRef,
  );

  return (
    <FocusScope autoFocus contain restoreFocus>
      <div className={styles.fullscreen} {...fullscreenPress.pressProps}>
        <motion.div
          {...(underlayProps as any)}
          className={styles.underlay}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
        <div
          {...(mergeProps(overlayProps, pressProps) as any)}
          className={styles.overlay}
          ref={overlayRef}>
          <MotionImage src={src} layoutId={layoutId} />
        </div>
      </div>
    </FocusScope>
  );
}

type MotionImageProps = {
  layoutId: string;
  src: string;
};

// For some reason on rerenders while image is pressed cause the parent
// image to apear. This is why this memoized component was created
const MotionImage = memo(({ layoutId, src, ...rest }: MotionImageProps) => {
  return <motion.img {...rest} src={src} layoutId={layoutId} tabIndex={1} />;
});
