/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { TestHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { testHistoryAdapter } from './testHistoryAdapter';
import { testHistoryFetchThunk } from './testHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const testHistorySlice = createSlice({
  name: 'testHistory',
  initialState: testHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    testHistoryCleared(state) {
      state.loading = 'idle';
      testHistoryAdapter.removeAll(state);
    },
    testHistoryUpsertMany(
      state,
      action: PayloadAction<TestHistory[] | Record<number, TestHistory>>,
    ) {
      testHistoryAdapter.upsertMany(state, action.payload);
    },
    testHistoryUpsertOne(state, action: PayloadAction<TestHistory>) {
      testHistoryAdapter.upsertOne(state, action.payload);
    },
    testHistoryUpdateOne(state, action: PayloadAction<Update<TestHistory>>) {
      testHistoryAdapter.updateOne(state, action.payload);
    },
    testHistoryRemoveOne(state, action: PayloadAction<number>) {
      testHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testHistoryFetchThunk.pending, handlePending);
    builder.addCase(testHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testHistoryAdapter.upsertMany(state, action.payload.testHistory);
    });
    builder.addCase(testHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  testHistoryCleared,
  testHistoryUpsertMany,
  testHistoryUpsertOne,
  testHistoryUpdateOne,
  testHistoryRemoveOne,
} = testHistorySlice.actions;

export const testHistoryReducer = testHistorySlice.reducer;
