import { unwrapResult } from '@reduxjs/toolkit';
import { Notifications as NotificationsType } from '@testquality/sdk';
import classname from 'classname';
import { differenceInCalendarDays, format } from 'date-fns';
import React, { useCallback } from 'react';
import {
  notification as notificationHandler,
  Separator,
} from '@bitmodern/bit-ui';
import { NotificationIcon } from '@bitmodern/bit-ui/icons';
import { NotificationItem } from 'src/components/organisms';
import {
  getNotificationName,
  NotificationItemName,
  NotificationItemType,
  NotificationNameToType,
} from 'src/enums/NotificationEnums';
import { DateFormat } from 'src/enums/DateFormatEnum';
import vars from 'src/export.scss';
import {
  notificationsDeleteOneThunk,
  notificationsUpdateOneThunk,
} from 'src/gen/domain/notifications/notificationsThunk';
import useMutation from 'src/hooks/useMutation';
import { useTranslation } from 'src/i18n/hooks';
import { tokenSelector } from '@bitmodern/redux/state/authentication/selectors';
import { resendEmailVerificationThunk } from '@bitmodern/redux/state/authentication/thunks';
import {
  isTrialSelector,
  isVerifiedUserSelector,
  notifiableNotificationsSelector,
} from '@bitmodern/redux/state/notifications/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import styles from './Notifications.module.scss';

type Props = {
  onClickSubscribe: () => void;
  notificationsAmount: number;
};

function Notifications({ onClickSubscribe, notificationsAmount }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notifiableNotifications = useAppSelector(
    notifiableNotificationsSelector,
  );
  const token = useAppSelector(tokenSelector);
  const isTrial = useAppSelector(isTrialSelector);
  const isVerifiedUser = useAppSelector(isVerifiedUserSelector);

  const resendVerification = useCallback(() => {
    return dispatch(resendEmailVerificationThunk({}))
      .then(unwrapResult)
      .then(() =>
        notificationHandler.open({
          type: 'success',
          message: t('notification.actions.resend.success.message'),
        }),
      )
      .catch(() =>
        notificationHandler.open({
          type: 'error',
          message: t('notification.actions.resend.failed.message'),
        }),
      );
  }, [dispatch, t]);

  const resendVerificationMutation = useMutation(resendVerification);

  if (!notificationsAmount) {
    return (
      <div className={classname(styles.empty, styles.panelContent)}>
        <NotificationIcon color={vars.textSecondary} size={66} />
        <h5 className={styles.emptyHeader}>{t('notification.empty.header')}</h5>
        <p className={styles.emptyDescription}>
          {t('notification.empty.description')}
        </p>
      </div>
    );
  }

  function renderStaticNotifications() {
    const staticNotifications: JSX.Element[] = [];

    if (!isVerifiedUser && token) {
      const translations = {
        date: null,
        description: t(
          `notification.description.${NotificationItemName.StaticVerify}`,
          { date: new Date(token.verification_ends_at) },
        ),
      };
      const action = {
        onClick: () => {
          resendVerificationMutation.mutate();
        },
        isLoading: resendVerificationMutation.isLoading,
        label: 'resend.label',
      } as const;

      staticNotifications.push(
        <NotificationItem
          key={NotificationItemName.StaticVerify}
          type={NotificationItemType.WARNING}
          name={NotificationItemName.StaticVerify}
          translations={translations}
          wasRead={false}
          action={action}
        />,
      );
    }

    if (isTrial && token) {
      const trialEndsIn = differenceInCalendarDays(
        new Date(token.trial_ends_at),
        new Date(),
      );
      const translations = {
        date: null,
        description: t(
          trialEndsIn === 0
            ? `notification.description.${NotificationItemName.StaticTrial}.today`
            : `notification.description.${NotificationItemName.StaticTrial}.later`,
          { trialEndsIn },
        ),
      };
      const action = {
        onClick: onClickSubscribe,
        label: 'subscribe',
      } as const;
      const type =
        trialEndsIn > 0
          ? NotificationItemType.INFO
          : NotificationItemType.DANGER;

      staticNotifications.push(
        <NotificationItem
          key={NotificationItemName.StaticTrial}
          type={type}
          name={NotificationItemName.StaticTrial}
          translations={translations}
          wasRead={false}
          action={action}
        />,
      );
    }

    if (staticNotifications.length) {
      staticNotifications.push(<Separator key="separator" size="minimal" />);
    }

    return staticNotifications;
  }

  function renderDynamicNotification(
    notification: NotificationsType,
    index: number,
  ) {
    const name = getNotificationName(notification.type);
    const type =
      NotificationNameToType.get(name) || NotificationItemType.WARNING;
    const wasRead = Boolean(notification.read_at);
    const translations = {
      date: t('notification.created', {
        created: new Date(notification.created_at),
      }),
      description: t(`notification.description.${name}`, {
        email: (notification.data as any)[0]?.email,
      }),
    };
    function onDelete() {
      return dispatch(
        notificationsDeleteOneThunk({
          id: notification.id,
        }),
      );
    }
    function onClick() {
      return dispatch(
        notificationsUpdateOneThunk({
          id: notification.id,
          data: {
            read_at: format(new Date(), DateFormat.UpdateEntity),
          },
        }),
      );
    }

    return (
      <NotificationItem
        key={name + index}
        type={type}
        name={name}
        translations={translations}
        wasRead={wasRead}
        onDelete={onDelete}
        onClick={wasRead ? undefined : onClick}
      />
    );
  }

  return (
    <ol className={styles.panelContent}>
      {renderStaticNotifications()}
      {notifiableNotifications.map(renderDynamicNotification)}
    </ol>
  );
}

export default Notifications;
