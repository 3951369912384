import React from 'react';
import {
  Button,
  ComboBox,
  DialogContent,
  IconButton,
  Input,
  InputSearch,
  Loading,
  PanelActions,
  ToggleButton,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { SearchIcon } from '@bitmodern/bit-ui/icons';
import DefectListed from 'src/components/organisms/DefectListed';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { routes } from 'src/components/Router';
import vars from 'src/export.scss';

import { TestTree } from '../TestTree/TestTree';
import { useStep1, UseStep1Params } from './useStep1';
import styles from './Step1.module.scss';
import { Defect, Requirement } from '@testquality/sdk';

export type Step1Props = {
  tree;
  selectedTests: any[];
  isDefect?: boolean;
  includedIssues?: Requirement[] | Defect[];
} & Pick<UseStep1Params, 'onNext' | 'onLinkIssue'>;

export function Step1({
  onNext,
  onLinkIssue,
  tree,
  selectedTests,
  isDefect = false,
  includedIssues = [],
}: Step1Props) {
  const { projectId } = useParamsInRoute<typeof routes.PROJECT.params>(
    routes.PROJECT.path,
  );
  const {
    externalIssues,
    formIsValid,
    handleChangeTests,
    handleNextStepDefect,
    handleNextStepRequirement,
    handleOnChangeSummary,
    integration,
    integrationProject,
    integrationProjects,
    isLinking,
    isLoading,
    jqlActive,
    onChangeIntegrationProject,
    onChangeSearch,
    onSelectIssue,
    search,
    searchIssue,
    selectedIssue,
    summary,
    toggleJQL,
    useJQL,
  } = useStep1({
    onNext,
    onLinkIssue,
    isDefect,
    projectId: parseInt(projectId, 10),
  });
  const { t } = useTranslation();

  const onSubmitSearch = (value) => searchIssue(value, integrationProject);

  const onSearchClick = () => searchIssue(search, integrationProject);

  const integrationProjectOptions =
    integrationProjects?.map((ip) => ({
      label: ip.project_reference_id,
      value: ip.id,
    })) || [];

  const issueType = isDefect ? 'Defect' : 'Story';

  return (
    <>
      <DialogContent className={styles.dialogContent}>
        <div className={styles.requirementsSide}>
          {integrationProjectOptions.length > 1 && (
            <ComboBox
              label={t('integrations.head.title')}
              name="Integrations"
              onChange={onChangeIntegrationProject}
              options={integrationProjectOptions}
              placeholder="Select Integration..."
              required
              value={integrationProject?.id}
            />
          )}
          <div className={styles.search}>
            <div className={styles.inputSearch}>
              <InputSearch
                label={t('multiIssue.actions.searchDefect', {
                  integrationName: integration?.name,
                  issueType,
                })}
                onSubmit={onSubmitSearch}
                onChange={onChangeSearch}
                placeholder={t('multiIssue.searchPlaceholder')}
                value={search}
                isDisabled={!integrationProject}
                fullWidth
              />
            </div>
            {useJQL && (
              <div className={styles.jql}>
                {jqlActive && (
                  <IconButton onClick={onSearchClick}>
                    <SearchIcon color={vars.textPrimary} size={16} />
                  </IconButton>
                )}
                <ToggleButton onChange={toggleJQL} isSelected={jqlActive}>
                  JQL
                </ToggleButton>
              </div>
            )}
          </div>
          <div className={styles.searchResult}>
            {isLoading && <Loading className={styles.loading} size={32} />}
            {externalIssues.map((defect: any) => {
              const included = includedIssues.some(
                (el) =>
                  el.external_reference_id === defect.number.toString() &&
                  el.integration_project_id === integrationProject?.id,
              );

              return (
                <DefectListed
                  data={defect}
                  key={defect.id}
                  onClick={() => {
                    if (!isDefect && included) return;
                    onSelectIssue(defect);
                  }}
                  selected={defect.number === selectedIssue?.number}
                  isDefect={isDefect}
                  included={included}
                />
              );
            })}
          </div>
          <Input
            label={t('multiIssue.actions.createNew', { issueType })}
            fullWidth
            placeholder={t('multiIssue.issueTitlePlaceholder', { issueType })}
            onChange={handleOnChangeSummary}
            value={summary}
          />
        </div>

        <div className={styles.treeSide}>
          <TestTree
            isDefect={isDefect}
            defaultTests={selectedTests}
            tree={tree}
            onChangeTests={handleChangeTests}
          />
        </div>
      </DialogContent>
      <PanelActions>
        <Button
          disabled={formIsValid}
          onClick={isDefect ? handleNextStepDefect : handleNextStepRequirement}
          loading={isLinking}>
          {selectedIssue
            ? t('multiIssue.actions.linkDefect')
            : t('multiIssue.actions.next')}
        </Button>
      </PanelActions>
    </>
  );
}
