import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'src/i18n/hooks';
import LaptopErrorIcon from '@bitmodern/bit-ui/icons/LaptopErrorIcon';
import { Button } from '@bitmodern/bit-ui';
import styles from './ErrorView.module.scss';

export function ErrorView() {
  const { t } = useTranslation();

  const toReload = () => {
    window.location.reload();
  };

  const toDashboard = () => {
    window.location.pathname = '/';
  };

  return (
    <div className={styles.login}>
      <Helmet>
        <title>{t('error.head.title')}</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.laptop}>
            <LaptopErrorIcon className={styles.laptopIcon} size={150} />
          </div>
          <h1 className={styles.title}>{t('error.title')}</h1>
          <div className={styles.error}>{t('error.error')}</div>
          <div className={styles.recommendation}>
            {t('error.recommendation')}
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.dashboard}
              color="primaryDark"
              onClick={toDashboard}>
              <div className={styles.buttonText}>{t('error.goto')}</div>
            </Button>
            <Button className={styles.reload} onClick={toReload}>
              <div className={styles.buttonText}>{t('error.reload')}</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
