import React, { ComponentProps } from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Dialog } from '@bitmodern/bit-ui';
import MilestoneForm from '../MilestoneForm';
import styles from './MilestoneFormDialog.module.scss';

type AdditionalInfoKeys = 'analyze.testGrowth.filter.milestone.missingDates';

type Props = Pick<
  ComponentProps<typeof MilestoneForm>,
  'initialValues' | 'onSubmit'
> & {
  onClose: () => void;
  open: boolean;
  additionalInfo?: AdditionalInfoKeys;
};

export default function MilestoneFormDialog({
  initialValues,
  onClose,
  onSubmit,
  open,
  additionalInfo,
}: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      isDismissable={false}
      isOpen={open}
      onClose={onClose}
      size="small"
      title={t('milestoneFormDialog.title')}>
      {additionalInfo && <p className={styles.warning}>{t(additionalInfo)}</p>}
      <MilestoneForm initialValues={initialValues} onSubmit={onSubmit} />
    </Dialog>
  );
}
