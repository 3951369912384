import React from 'react';
import { Button } from '@bitmodern/bit-ui';
import { LaptopTQIcon } from '@bitmodern/bit-ui/icons';
import EmptyList from 'src/components/organisms/EmptyList';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';

type Props = {
  projectId: number;
};

export default function EmptyPlans({ projectId }: Props) {
  const { t } = useTranslation();
  const { showModal } = useModalManager();

  const onClick = () => {
    showModal({
      modalName: 'createPlan',
      modalProps: { projectId },
      type: 'modal',
    });
  };

  return (
    <EmptyList
      button={<Button onClick={onClick}>{t('emptyPlans.button')}</Button>}
      description={t('emptyPlans.description')}
      icon={<LaptopTQIcon size={130} />}
      title={t('emptyPlans.title')}
    />
  );
}
