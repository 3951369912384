import { RelatedType } from './RelatedTypeEnum';

export enum WatchType {
  PROJECT = 'Project',
  TEST = 'Test',
  PLAN = 'Plan',
  SUITE = 'Suite',
}

export const WatchTypeNewNaming: ReadonlyMap<WatchType, string> = new Map([
  [WatchType.PROJECT, 'Project'],
  [WatchType.TEST, 'Test'],
  [WatchType.PLAN, 'Cycle'],
  [WatchType.SUITE, 'Folder'],
]);

export function getWatchType(entityType: string): WatchType {
  switch (entityType) {
    case RelatedType.PROJECT:
      return WatchType.PROJECT;
    case RelatedType.PLAN:
      return WatchType.PLAN;
    case RelatedType.SUITE:
      return WatchType.SUITE;
    default:
      return WatchType.TEST;
  }
}
