/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { StatusApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runResultNormalizer } from '../run_result/runResultNormalize';
import { runResultStepNormalizer } from '../run_result_step/runResultStepNormalize';

export const statusNormalizer = (model: StatusApi, cache: NormalizerCache) => {
  if (!cache.status) {
    cache.status = [];
  }
  cache.status.push(model);

  if (model.run_result && model.run_result.length) {
    model.run_result.forEach((child) => {
      runResultNormalizer(child, cache);
    });
    model.run_result = undefined;
  }
  if (model.run_result_step && model.run_result_step.length) {
    model.run_result_step.forEach((child) => {
      runResultStepNormalizer(child, cache);
    });
    model.run_result_step = undefined;
  }
};
