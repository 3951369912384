import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <path
    fill="currentColor"
    d="M20.4895,0 C20.4895,5.16 24.725,9.3525 29.842,9.3525 L33.669,9.3525 L33.669,13.0075 C33.669,18.1675 37.84,22.3385 43,22.36 L43,1.806 C43,0.808573742 42.1914263,0 41.194,0 L20.4895,0 L20.4895,0 Z M10.2555,10.32 C10.277,15.4585 14.448,19.6295 19.5865,19.651 L23.4565,19.651 L23.4565,23.349 C23.478,28.4875 27.649,32.6585 32.7875,32.68 L32.7875,12.1045 C32.766,11.1155 31.992,10.3415 31.003,10.32 L10.2555,10.32 L10.2555,10.32 Z M0,20.64 C0,25.8 4.1925,29.971 9.3525,29.971 L13.1795,29.971 L13.1795,33.669 C13.201,38.8075 17.372,43 22.5105,43 L22.5105,22.4245 C22.5105,21.4270737 21.7019263,20.6185 20.7045,20.6185 L0,20.64 Z"
    id="Shape"
  />,
  'Jira',
  {},
  43,
  43,
);
