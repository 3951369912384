/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { DefectComponent } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { defectComponentAdapter } from './defectComponentAdapter';
import { defectComponentFetchManyThunk } from './defectComponentThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const defectComponentSlice = createSlice({
  name: 'defectComponent',
  initialState: defectComponentAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    defectComponentCleared(state) {
      state.loading = 'idle';
      defectComponentAdapter.removeAll(state);
    },
    defectComponentUpsertMany(
      state,
      action: PayloadAction<
        DefectComponent[] | Record<number, DefectComponent>
      >,
    ) {
      defectComponentAdapter.upsertMany(state, action.payload);
    },
    defectComponentUpsertOne(state, action: PayloadAction<DefectComponent>) {
      defectComponentAdapter.upsertOne(state, action.payload);
    },
    defectComponentUpdateOne(
      state,
      action: PayloadAction<Update<DefectComponent>>,
    ) {
      defectComponentAdapter.updateOne(state, action.payload);
    },
    defectComponentRemoveOne(state, action: PayloadAction<number>) {
      defectComponentAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(defectComponentFetchManyThunk.pending, handlePending);
    builder.addCase(
      defectComponentFetchManyThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        defectComponentAdapter.upsertMany(
          state,
          action.payload.defectComponent,
        );
      },
    );
    builder.addCase(defectComponentFetchManyThunk.rejected, handleRejected);
  },
});

export const {
  defectComponentCleared,
  defectComponentUpsertMany,
  defectComponentUpsertOne,
  defectComponentUpdateOne,
  defectComponentRemoveOne,
} = defectComponentSlice.actions;

export const defectComponentReducer = defectComponentSlice.reducer;
