import React, { ComponentProps, useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHover, mergeProps } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import {
  AddFolderIcon,
  DeleteIcon,
  DropdownDownIcon,
  DropdownUpIcon,
  EditIcon,
  FolderIcon,
  TestCreateIcon,
  WatchIcon,
  WatchRemoveIcon,
} from '@bitmodern/bit-ui/icons';
import { IconButton, notification } from '@bitmodern/bit-ui';
import { Plan, PlanSuite, Suite } from '@testquality/sdk';
import { ConfirmDialog } from 'src/components/organisms';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { watchDeleteOneThunk } from 'src/gen/domain/watch/watchThunk';
import { watchBySuiteIdSelector } from '@bitmodern/redux/state/watch/selectors';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { labelAssignedBySuiteSelector } from '@bitmodern/redux/state/label_assigned/selectors';
import { suiteDeleteOneThunk } from 'src/gen/domain/suite/suiteThunk';
import { watchSuiteThunk } from '@bitmodern/redux/state/watch/thunks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import useMutation from 'src/hooks/useMutation';
import vars from 'src/export.scss';
import TreeItem from '../TreeItem';
import styles from './SuiteListed.module.scss';
import { suiteTestsBySuiteSelector } from 'src/packages/redux/state/suiteTests/selectors';

type Props = ComponentProps<'div'> & {
  checked?: boolean;
  collapsed: boolean;
  collapsible: boolean;
  nestingLevel?: number;
  notIncluded?: boolean;
  onChangeChecked?: (value: boolean) => void;
  plan?: Plan;
  planSuite: PlanSuite;
  suite: Suite;
  withActions?: boolean;
  requirementId?: number;
};

export default function SuiteListed({
  checked,
  collapsed,
  collapsible,
  nestingLevel = 0,
  notIncluded = false,
  onChangeChecked,
  plan,
  planSuite,
  style,
  suite,
  withActions = true,
  requirementId,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const { hoverProps, isHovered } = useHover({});
  const dispatch = useAppDispatch();

  const labels = useAppSelector(labelsSelector);
  const labelsAssigned = useAppSelector((state) =>
    labelAssignedBySuiteSelector(state, { suiteId: suite.id }),
  );
  const labelsIdsFromAssigned = labelsAssigned.map((la) => la.label_id);
  const suiteWatch = useAppSelector((state) =>
    watchBySuiteIdSelector(state, suite.id),
  );
  const { showModal } = useModalManager();
  const deleteDialog = useOverlayTriggerState({});

  const onDeleteSuite = useCallback(() => {
    return dispatch(suiteDeleteOneThunk({ id: planSuite.suite_id }));
  }, [dispatch, planSuite.suite_id]);

  const deleteMutation = useMutation(onDeleteSuite);

  const onClickAddFolder = () => {
    showModal({
      modalName: 'suiteCreate',
      modalProps: { planSuite, planId: plan?.id },
      type: 'modal',
    });
  };

  const suites = useAppSelector((state) =>
    suiteTestsBySuiteSelector(state, suite.id),
  );
  const lastSuiteTest = suites[suites.length - 1];

  const onClickCreateTest = () => {
    showModal({
      modalName: 'testCreate',
      modalProps: {
        planSuite,
        suiteTest: lastSuiteTest,
        planId: plan?.id,
        requirementId,
      },
      type: 'modal',
    });
  };

  const onClickEdit = () => {
    showModal({
      modalName: 'suiteEdit',
      modalProps: { suite },
      type: 'modal',
    });
  };

  const onWatchSuite = () => {
    dispatch(watchSuiteThunk(suite.id)).then(() =>
      notification.open({
        type: 'success',
        message: t('notifications.watchAdded.message'),
        description: t('notifications.watchAdded.description'),
      }),
    );
  };

  const onUnwatchSuite = () => {
    dispatch(watchDeleteOneThunk({ id: suiteWatch?.id }))
      .then(unwrapResult)
      .then(() =>
        notification.open({
          type: 'success',
          message: t('notifications.watchRemoved.message'),
          description: t('notifications.watchRemoved.description'),
        }),
      );
  };

  const collapsedIcon = collapsed ? (
    <DropdownUpIcon key="dropdownUp" color={vars.textPrimary} />
  ) : (
    <DropdownDownIcon key="dropdownDown" color={vars.textPrimary} />
  );

  const mainData = [
    <FolderIcon key="icon" color={vars.textPrimary} size={20} />,
    <span key="name" className={styles.name}>
      {suite.name}
    </span>,
  ];

  const hoverActions = [
    suiteWatch ? (
      <IconButton
        key="removeWatch"
        boxed={false}
        onClick={onUnwatchSuite}
        title={t('suiteListed.unwatchSuite')}>
        <WatchRemoveIcon color={vars.textPrimary} size={18} />
      </IconButton>
    ) : (
      <IconButton
        key="addWatch"
        boxed={false}
        onClick={onWatchSuite}
        title={t('suiteListed.watchSuite')}>
        <WatchIcon color={vars.textPrimary} size={18} />
      </IconButton>
    ),
    <IconButton
      key="editSuite"
      boxed={false}
      onClick={onClickEdit}
      title={t('suiteListed.editSuite')}>
      <EditIcon color={vars.textPrimary} size={18} />
    </IconButton>,
    <IconButton
      key="deleteSuite"
      boxed={false}
      onClick={deleteDialog.open}
      title={t('suiteListed.deleteSuite')}>
      <DeleteIcon color={vars.textPrimary} size={18} />
    </IconButton>,
    <IconButton
      key="addSuite"
      boxed={false}
      onClick={onClickAddFolder}
      title={t('suiteListed.addSuite')}>
      <AddFolderIcon color={vars.textPrimary} size={18} />
    </IconButton>,
    <IconButton
      key="addTest"
      boxed={false}
      onClick={onClickCreateTest}
      title={t('suiteListed.addTest')}>
      <TestCreateIcon color={vars.textPrimary} size={18} />
    </IconButton>,
  ];

  const suitesBadges = labels
    .filter((label) => labelsIdsFromAssigned.includes(label.id))
    .map((label) => (
      <span key={`suiteLabel${label.id}`} className={styles.badge}>
        {label.label}
      </span>
    ));

  function getRightSection() {
    const section = [...suitesBadges, collapsedIcon];
    if (withActions && isHovered) {
      section.unshift(...hoverActions);
    }

    return section;
  }

  return (
    <>
      <TreeItem
        {...mergeProps(rest, hoverProps)}
        checked={checked}
        mainData={mainData}
        nestingLevel={nestingLevel}
        notIncluded={notIncluded}
        onChangeChecked={onChangeChecked}
        rightSection={getRightSection()}
        selected={false}
      />
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('suiteListed.deleteSuiteDialog.title')}>
        {t('suiteListed.deleteSuiteDialog.content')}
      </ConfirmDialog>
    </>
  );
}
