import React from 'react';
import { FormikProps } from 'formik';
import { Checkbox, Input, InputDate } from '@bitmodern/bit-ui';
import { formikError } from 'src/utils/formik';
import { VirtualSchema } from '@bitmodern/redux/state/virtuals/selectors';

import { VirtualSchemaType } from 'src/enums/VirtualSchemaTypeEmum';
import styles from './VirtualInputs.module.scss';

type Props = {
  formik: FormikProps<any>;
  virtuals: { [key: string]: VirtualSchema };
};

export default function VirtualInputs({ formik, virtuals }: Props) {
  const renderVirtualInput = ([key, virtual]) => {
    switch (virtual.type) {
      case VirtualSchemaType.Date:
        return (
          <InputDate
            error={formikError(formik, key)}
            fullWidth
            key={key}
            label={key}
            name={key}
            onChange={(value) => formik.setFieldValue(key, value)}
            onFocus={() => formik.setFieldTouched(key, true)}
            required
            value={formik.values[key]}
          />
        );
      case VirtualSchemaType.Number:
        return (
          <Input
            error={formikError(formik, key)}
            fullWidth
            key={key}
            label={key}
            name={key}
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            type="number"
            value={formik.values[key]}
          />
        );

      case VirtualSchemaType.Boolean:
        return (
          <Checkbox
            checked={formik.values[key]}
            className={styles.checkbox}
            error={formikError(formik, key)}
            key={key}
            name={key}
            onChange={(value) => {
              formik.setFieldTouched(key, true);
              formik.setFieldValue(key, value);
            }}>
            {key}
          </Checkbox>
        );

      default:
        return (
          <Input
            error={formikError(formik, key)}
            fullWidth
            key={key}
            label={key}
            name={key}
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values[key]}
          />
        );
    }
  };

  return <>{Object.entries(virtuals).map(renderVirtualInput)}</>;
}
