import { State } from '@bitmodern/redux/store';
import { testHistorySelectors } from 'src/gen/domain/test/testHistorySelector';

export function testHistoryByTestSelector(state: State, testId: number) {
  return testHistorySelectors.selectAll(state).filter((th) => th.id === testId);
}

export function testHistoryByProjectSelector(state: State, projectId: number) {
  return testHistorySelectors
    .selectAll(state)
    .filter((th) => th.project_id === projectId);
}
