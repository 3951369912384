import React, { ComponentProps, useCallback, useMemo } from 'react';
import {
  booleanEnumToBoolean,
  booleanToBooleanEnum,
} from 'src/enums/BooleanEnum';
import {
  virtualCreateOneThunk,
  virtualUpdateOneThunk,
} from 'src/gen/domain/virtual/virtualThunk';
import {
  virtualSelector,
  virtualsSelector,
} from '@bitmodern/redux/state/virtuals/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import CustomFieldDialog from './CustomFieldDialog';

type CustomFieldDialogProps = ComponentProps<typeof CustomFieldDialog>;
type OnSubmit = CustomFieldDialogProps['onSubmit'];

type Props = Omit<CustomFieldDialogProps, 'initialValues' | 'onSubmit'> & {
  fieldName: string;
  virtualId?: number;
};

export default function CustomFieldDialogContainer({
  fieldName,
  onClose,
  virtualId,
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const virtuals = useAppSelector(virtualsSelector);
  const virtual = useAppSelector((state) => {
    if (!virtualId) return undefined;
    return virtualSelector(state, virtualId);
  });

  const initialValues = useMemo(() => {
    const field = virtual?.virtual_schema?.columns?.[fieldName];
    if (!field) return undefined;
    if (!virtual?.table_name) return undefined;

    return {
      objectType: virtual?.table_name as any,
      name: fieldName,
      description: field.description,
      mandatory: booleanToBooleanEnum(field.is_mandatory),
      type: field.type,
    };
  }, [fieldName, virtual]);

  const onSubmit = useCallback<OnSubmit>(
    (values) => {
      const formVirtual = virtuals.find(
        (v) => v.table_name === values.objectType,
      );

      if (!formVirtual) {
        return dispatch(
          virtualCreateOneThunk({
            data: {
              table_name: values.objectType,
              virtual_schema: {
                columns: {
                  [values.name]: {
                    description: values.description,
                    is_mandatory: booleanEnumToBoolean(values.mandatory),
                    type: values.type,
                  },
                },
              },
            },
          }),
        );
      }

      let columns = formVirtual.virtual_schema?.columns || {};
      // remove old name
      if (values.name !== fieldName) {
        let removedValue = null; // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
        ({ [fieldName]: removedValue, ...columns } = columns); // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
      }

      return dispatch(
        virtualUpdateOneThunk({
          id: formVirtual.id,
          data: {
            virtual_schema: {
              columns: {
                ...columns,
                [values.name]: {
                  description: values.description,
                  is_mandatory: booleanEnumToBoolean(values.mandatory),
                  type: values.type,
                },
              },
            },
          },
        }),
      ).finally(onClose);
    },
    [dispatch, fieldName, onClose, virtuals],
  );

  return (
    <CustomFieldDialog
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
      {...props}
    />
  );
}
