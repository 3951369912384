import {
  Product,
  Subscriptions,
  SubscriptionUser,
  User,
} from '@testquality/sdk';
import React from 'react';
import classname from 'classname';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import styles from './SubscriptionsList.module.scss';
import { formatUserName } from 'src/utils/fileHelper';

type Props = {
  className?: string;
  products: Product[];
  subscriptions: Subscriptions[];
  users: User[];
  subscriptionUsers: SubscriptionUser[];
};

export default function SubscriptionsList({
  className = '',
  products,
  subscriptions,
  users,
  subscriptionUsers,
}: Props) {
  const { t } = useTranslation();
  const hasEnded = (subscription: Subscriptions) => {
    if (subscription.ends_at) {
      return (
        new Date().getTime() > new Date(subscription.ends_at).getTime() &&
        subscription.subscription_expires_at &&
        new Date().getTime() >
          new Date(subscription.subscription_expires_at).getTime()
      );
    }
    return false;
  };
  const getEndsAt = (subscription: Subscriptions) => {
    if (subscription.ends_at && subscription.subscription_expires_at) {
      if (
        new Date(subscription.ends_at).getTime() >
        new Date(subscription.subscription_expires_at).getTime()
      ) {
        return subscription.ends_at;
      }
      return subscription.subscription_expires_at;
    }
    return undefined;
  };
  const inUse = (subscription: Subscriptions) => {
    const subs = subscriptionUsers.filter(
      (s) => s.subscriptions_id === subscription.id,
    );
    return subs.length;
  };

  return (
    <Table className={className} outlined rounded>
      <TableHead>
        <TableCell>{t(`subscriptonList.tableHead.plan`)}</TableCell>
        <TableCell>{t(`subscriptonList.tableHead.seats`)}</TableCell>
        <TableCell>{t(`subscriptonList.tableHead.inUse`)}</TableCell>
        <TableCell>{t(`subscriptonList.tableHead.total`)}</TableCell>
        <TableCell>{t(`subscriptonList.tableHead.owner`)}</TableCell>
        <TableCell>{t(`subscriptonList.tableHead.ends`)}</TableCell>
      </TableHead>
      <TableBody>
        {subscriptions.map((subscription) => {
          const product = products.find(
            (p) => p.id === subscription.product_id,
          );
          const user = users.find((u) => u.id === subscription.user_id);
          const price = (product?.amount || 0) * subscription.quantity;
          const amount = product?.amount.toString() || '0';
          const ended = hasEnded(subscription);
          const endsAt = getEndsAt(subscription);
          const inUseValue = inUse(subscription);

          return (
            <TableRow key={subscription.id}>
              <TableCell className={styles.cell}>
                <Tooltip
                  tooltip={
                    ended
                      ? t('subscriptonList.expired')
                      : t('subscriptonList.active')
                  }>
                  <span
                    className={classname(styles.indicator, {
                      [styles.success]: !ended,
                    })}
                  />
                </Tooltip>
                {subscription.name}
              </TableCell>
              <TableCell className={styles.cell}>
                {subscription.quantity} x{' '}
                {t('price', { value: parseInt(amount, 10) })}
              </TableCell>
              <TableCell className={styles.cell}>{inUseValue}</TableCell>
              <TableCell className={styles.cell}>
                {t('price', { value: price })}
              </TableCell>
              <TableCell className={styles.cell}>
                <Avatar
                  name={formatUserName(user)}
                  size={20}
                  src={user?.picture}
                />
                <span className={styles.username}>{formatUserName(user)}</span>
              </TableCell>
              <TableCell className={styles.cell}>
                {endsAt && t('dates.default', { date: new Date(endsAt) })}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
