/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createSlice, SerializedError } from '@reduxjs/toolkit';
import {
  performOptimisticUpdate,
  revertOptimisticUpdate,
} from 'redux-toolkit-optimistic';
import values from 'lodash/values';
import {
  accessRoleUserDetachThunk,
  accessRoleUserFetchManyThunk,
  accessRoleUserFetchOneThunk,
  accessRoleUserUpdateOneThunk,
  accessRoleUserCreateOneThunk,
} from './accessRoleUserThunk';
import { TQStateProperties } from '../../store/TQState';
import { accessRoleUserAdapter } from './accessRoleUserAdapter';
import {
  userDeleteOneThunk,
  userFetchManyThunk,
  userFetchOneThunk,
} from '../user/userThunk';
import {
  accessRoleDeleteOneThunk,
  accessRoleFetchManyThunk,
  accessRoleFetchOneThunk,
} from '../access_role/accessRoleThunk';
import {
  integrationFetchManyThunk,
  integrationFetchOneThunk,
} from '../integration/integrationThunk';
import {
  capabilityFetchManyThunk,
  capabilityFetchOneThunk,
} from '../capability/capabilityThunk';
import {
  appInstallProjectFetchManyThunk,
  appInstallProjectFetchOneThunk,
} from '../app_install_project/appInstallProjectThunk';
import {
  appInstallFetchManyThunk,
  appInstallFetchOneThunk,
} from '../app_install/appInstallThunk';
import {
  appConfigFetchManyThunk,
  appConfigFetchOneThunk,
} from '../app_config/appConfigThunk';
import {
  projectFetchManyThunk,
  projectFetchOneThunk,
} from '../project/projectThunk';
import {
  baseIntegrationFetchManyThunk,
  baseIntegrationFetchOneThunk,
} from '../base_integration/baseIntegrationThunk';
import {
  baseCapabilityFetchManyThunk,
  baseCapabilityFetchOneThunk,
} from '../base_capability/baseCapabilityThunk';
import { accessRoleRemoveOne } from '../access_role/accessRoleSlice';
import { userRemoveOne } from '../user/userSlice';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const accessRoleUserSlice = createSlice({
  name: 'accessRoleUser',
  initialState: accessRoleUserAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    accessRoleUserCleared: accessRoleUserAdapter.removeAll,
    accessRoleUserUpsertMany: accessRoleUserAdapter.upsertMany,
    accessRoleUserUpsertOne: accessRoleUserAdapter.upsertOne,
    accessRoleUserUpdateOne: accessRoleUserAdapter.updateOne,
    accessRoleUserRemoveOne: accessRoleUserAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(accessRoleUserDetachThunk.pending, handlePending);
    builder.addCase(accessRoleUserDetachThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload && action.payload.id) {
        accessRoleUserAdapter.removeOne(state, action.payload.id);
      }
    });
    builder.addCase(accessRoleUserDetachThunk.rejected, handleRejected);
    builder.addCase(accessRoleUserFetchManyThunk.pending, handlePending);
    builder.addCase(accessRoleUserFetchManyThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
    });
    builder.addCase(accessRoleUserFetchManyThunk.rejected, handleRejected);
    builder.addCase(accessRoleUserFetchOneThunk.pending, handlePending);
    builder.addCase(accessRoleUserFetchOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      accessRoleUserAdapter.upsertOne(state, action.payload.accessRoleUser[0]);
    });
    builder.addCase(accessRoleUserFetchOneThunk.rejected, handleRejected);
    builder.addCase(accessRoleUserUpdateOneThunk.pending, (state, action) => {
      if (action.meta.arg.optimistic) {
        performOptimisticUpdate(state, accessRoleUserAdapter, {
          id: action.meta.arg.id?.toString(),
          changes: action.meta.arg.data,
        });
      } else {
        handlePending(state);
      }
    });
    builder.addCase(accessRoleUserUpdateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      accessRoleUserAdapter.updateOne(state, action.payload);
    });
    builder.addCase(accessRoleUserUpdateOneThunk.rejected, (state, action) => {
      if (action.meta.arg.optimistic) {
        revertOptimisticUpdate(
          state,
          accessRoleUserAdapter,
          action.meta.arg.id?.toString(),
        );
      } else {
        handleRejected(state, action);
      }
    });
    builder.addCase(accessRoleUserCreateOneThunk.pending, handlePending);
    builder.addCase(accessRoleUserCreateOneThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      accessRoleUserAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(accessRoleUserCreateOneThunk.rejected, handleRejected);
    builder.addCase(userFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(userFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(accessRoleFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(accessRoleFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(integrationFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(integrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(capabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(capabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(
      appInstallProjectFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.accessRoleUser) {
          accessRoleUserAdapter.upsertMany(
            state,
            action.payload.accessRoleUser,
          );
        }
      },
    );
    builder.addCase(
      appInstallProjectFetchOneThunk.fulfilled,
      (state, action) => {
        if (action.payload.accessRoleUser) {
          accessRoleUserAdapter.upsertMany(
            state,
            action.payload.accessRoleUser,
          );
        }
      },
    );
    builder.addCase(appInstallFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(appInstallFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(appConfigFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(appConfigFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(projectFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(projectFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(
      baseIntegrationFetchManyThunk.fulfilled,
      (state, action) => {
        if (action.payload && action.payload.accessRoleUser) {
          accessRoleUserAdapter.upsertMany(
            state,
            action.payload.accessRoleUser,
          );
        }
      },
    );
    builder.addCase(baseIntegrationFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(baseCapabilityFetchManyThunk.fulfilled, (state, action) => {
      if (action.payload && action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });
    builder.addCase(baseCapabilityFetchOneThunk.fulfilled, (state, action) => {
      if (action.payload.accessRoleUser) {
        accessRoleUserAdapter.upsertMany(state, action.payload.accessRoleUser);
      }
    });

    builder.addCase(accessRoleDeleteOneThunk.fulfilled, (state, action) => {
      accessRoleUserAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.access_role_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(accessRoleRemoveOne, (state, action) => {
      accessRoleUserAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.access_role_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(userDeleteOneThunk.fulfilled, (state, action) => {
      accessRoleUserAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) =>
              value !== undefined && value.user_id === action.payload.id,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
    builder.addCase(userRemoveOne, (state, action) => {
      accessRoleUserAdapter.removeMany(
        state,
        values(state.entities)
          .filter(
            (value) => value !== undefined && value.user_id === action.payload,
          )
          .map<number | undefined>((m) => (m ? m.id : undefined))
          .filter((f) => f !== undefined) as number[],
      );
    });
  },
});

export const {
  accessRoleUserCleared,
  accessRoleUserUpsertMany,
  accessRoleUserUpsertOne,
  accessRoleUserUpdateOne,
  accessRoleUserRemoveOne,
} = accessRoleUserSlice.actions;

export const accessRoleUserReducer = accessRoleUserSlice.reducer;
