/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { StepHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { stepHistoryAdapter } from './stepHistoryAdapter';
import { stepHistoryFetchThunk } from './stepHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const stepHistorySlice = createSlice({
  name: 'stepHistory',
  initialState: stepHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    stepHistoryCleared(state) {
      state.loading = 'idle';
      stepHistoryAdapter.removeAll(state);
    },
    stepHistoryUpsertMany(
      state,
      action: PayloadAction<StepHistory[] | Record<number, StepHistory>>,
    ) {
      stepHistoryAdapter.upsertMany(state, action.payload);
    },
    stepHistoryUpsertOne(state, action: PayloadAction<StepHistory>) {
      stepHistoryAdapter.upsertOne(state, action.payload);
    },
    stepHistoryUpdateOne(state, action: PayloadAction<Update<StepHistory>>) {
      stepHistoryAdapter.updateOne(state, action.payload);
    },
    stepHistoryRemoveOne(state, action: PayloadAction<number>) {
      stepHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(stepHistoryFetchThunk.pending, handlePending);
    builder.addCase(stepHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      stepHistoryAdapter.upsertMany(state, action.payload.stepHistory);
    });
    builder.addCase(stepHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  stepHistoryCleared,
  stepHistoryUpsertMany,
  stepHistoryUpsertOne,
  stepHistoryUpdateOne,
  stepHistoryRemoveOne,
} = stepHistorySlice.actions;

export const stepHistoryReducer = stepHistorySlice.reducer;
