import {
  dataSetCreateOneThunk,
  dataSetUpdateOneThunk,
} from 'src/gen/domain/data_set/dataSetThunk';
import { AppThunk } from '@bitmodern/redux/store';
import { DataSet } from './selectors';
import { unwrapResult } from '@reduxjs/toolkit';
import { testUpdateOneThunk } from 'src/gen/domain/test/testThunk';

export function upsertDataSetThunk({
  dataSet,
  testId,
}: {
  dataSet: Partial<DataSet>;
  testId: number;
}): AppThunk<Promise<void>> {
  return async (dispatch) => {
    if (dataSet.id) {
      dispatch(
        dataSetUpdateOneThunk({
          data: dataSet,
          id: dataSet.id,
          optimistic: true,
        }),
      );
      return;
    }

    await dispatch(dataSetCreateOneThunk({ data: dataSet }))
      .then(unwrapResult)
      .then((res) => {
        return dispatch(
          testUpdateOneThunk({
            id: testId,
            data: {
              data_set_id: res.id,
            },
          }),
        );
      });
  };
}
