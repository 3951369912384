/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { NativeDefectResApi } from '@testquality/sdk';
import { DefectResNativeDefectRes } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { defectResNormalizer } from '../defect_res/defectResNormalize';
import { defectResNativeDefectResNormalizer } from '../defect_res_native_defect_res/defectResNativeDefectResNormalize';

export const nativeDefectResNormalizer = (
  model: NativeDefectResApi,
  cache: NormalizerCache,
) => {
  if (!cache.nativeDefectRes) {
    cache.nativeDefectRes = [];
  }
  cache.nativeDefectRes.push(model);

  if (model.defect_res && model.defect_res.length) {
    model.defect_res.forEach((child) => {
      defectResNativeDefectResNormalizer(
        child.pivot as DefectResNativeDefectRes,
        cache,
      );
      child.pivot = undefined;
      defectResNormalizer(child, cache);
    });
    model.defect_res = undefined;
  }
};
