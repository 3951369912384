/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { VirtualApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const virtualNormalizer = (
  model: VirtualApi,
  cache: NormalizerCache,
) => {
  if (!cache.virtual) {
    cache.virtual = [];
  }
  cache.virtual.push(model);
};
