import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'src/i18n/hooks';
import { Checkbox, ComboBox, Grid, Tooltip } from '@bitmodern/bit-ui';
import { routes } from 'src/components/Router';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { Run as RunModel, RunApi, Milestone } from '@testquality/sdk';
import {
  planByIdSelector,
  rootPlanSelector,
} from '@bitmodern/redux/state/plans/selectors';
import { runUpdateOneThunk } from 'src/gen/domain/run/runThunk';
import { milestonesByProjectSelector } from '@bitmodern/redux/state/milestones/selectors';
import { virtualsByTableNameSelector } from '@bitmodern/redux/state/virtuals/selectors';
import RunStatusChart from 'src/components/organisms/RunStatusChart';
import TestStatusChart from 'src/components/organisms/TestStatusChart';
import CustomProperties from 'src/components/organisms/CustomProperties/CustomProperties';
import {
  EndTimeIcon,
  InfoIcon,
  PlanIcon,
  StartTimeIcon,
} from '@bitmodern/bit-ui/icons';
import { DateFormat } from 'src/enums/DateFormatEnum';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import { requirementsByRunResultSelector } from '@bitmodern/redux/state/requirements/selectors';
import { RunsConfigurations, RunsRequirements } from 'src/components/organisms';
import { appVersionPlatVersionsByPlanSelector } from '@bitmodern/redux/state/appVersionPlatVersions/selectors';
import vars from 'src/export.scss';
import styles from './RunOverviewView.module.scss';

type Props = {
  run: RunModel;
};

export default function RunOverviewView({ run }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { site } = useParams<typeof routes.RUN_OVERVIEW.params>();
  const plan = useAppSelector((state) => planByIdSelector(state, run.plan_id));

  const milestones = useAppSelector((state) =>
    milestonesByProjectSelector(state, run.project_id),
  );
  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, run.project_id),
  )!;

  const runRequirements = useAppSelector((state) =>
    requirementsByRunResultSelector(state, run.id),
  );
  const virtuals = useAppSelector((state) =>
    virtualsByTableNameSelector(state, 'run'),
  );

  const milestoneOptions = milestones.map((milestone: Milestone) => ({
    label: milestone.name,
    value: milestone.id,
  }));

  const appVersionPlatVersions = useAppSelector((state) =>
    appVersionPlatVersionsByPlanSelector(state, run.plan_id),
  );

  const onChangeMilestone = (value) => {
    updateRun({ milestone_id: value === '' ? null : value });
  };

  const onChangeIsPermanent = (checked) => {
    updateRun({ is_permanent: checked });
  };

  const onChangeCustomProperties = (customProperties: {
    [key: string]: Date | string | number | boolean;
  }) => {
    updateRun({ virtual: { ...customProperties } });
  };

  const updateRun = (value: Partial<RunModel> | Partial<RunApi>) => {
    return dispatch(
      runUpdateOneThunk({ data: value, id: run.id, optimistic: true }),
    );
  };

  return (
    <div className={styles.content}>
      <Grid.Row>
        <Grid.Col span={7}>
          <div className={styles.title}>{t('runs.overview.chartTitle')}</div>
          <RunStatusChart className={styles.chart} run={run} />
        </Grid.Col>

        <Grid.Col span={5}>
          <div className={styles.title}>
            {t('runs.overview.testStatusChartTitle')}
          </div>
          <TestStatusChart className={styles.testChart} run={run} />
        </Grid.Col>
      </Grid.Row>
      <div className={styles.fields}>
        <div>
          <ComboBox
            labelMarginTop={0}
            allowClear
            empty={t('runs.overview.milestone.empty')}
            label={t('runs.overview.milestone.label')}
            onChange={onChangeMilestone}
            options={milestoneOptions}
            placeholder={t('runs.overview.milestone.placeholder')}
            value={run.milestone_id}
          />
        </div>
        {plan && (
          <div>
            <div className={styles.label}>{t('runs.overview.plan')}</div>
            <Link
              className={styles.plan}
              to={routes.PLAN({
                site,
                projectId: run.project_id.toString(),
                planId: plan.id.toString(),
              })}>
              <PlanIcon
                className={styles.planIcon}
                color={vars.textPrimary}
                size={20}
              />
              {plan.name}
            </Link>
          </div>
        )}
        <div>
          <div className={styles.label}>
            {t('runs.overview.header.startTime')}
          </div>
          <div className={styles.time}>
            <StartTimeIcon
              className={styles.icon}
              color={vars.textPrimary}
              size={20}
            />
            <span>
              {run.start_time &&
                format(new Date(run.start_time), DateFormat.DayWithTime)}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.label}>
            {t('runs.overview.header.endTime')}
          </div>
          <div className={styles.time}>
            <EndTimeIcon
              className={styles.icon}
              color={vars.textPrimary}
              size={20}
            />
            {run.end_time ? (
              <span>
                {format(new Date(run.end_time), DateFormat.DayWithTime)}
              </span>
            ) : (
              t('runs.overview.inProgress')
            )}
          </div>
        </div>
        {/* IsPermanent only for cycles */}
        {run.plan_id !== rootPlan.id && (
          <div>
            <Checkbox
              className={styles.isPermanent}
              onChange={onChangeIsPermanent}
              checked={run.is_permanent}>
              {t('runs.overview.isPermanent')}
            </Checkbox>
            <Tooltip
              tooltip={
                <div className={styles.infoTooltip}>
                  {t('runs.overview.isPermanentTooltip')}
                </div>
              }>
              <InfoIcon
                className={styles.infoIcon}
                color={vars.textPrimary}
                size={16}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {!isEmpty(virtuals) && (
        <CustomProperties model={run} onChange={onChangeCustomProperties} />
      )}

      {runRequirements.length > 0 && (
        <>
          <div className={styles.title}>{t('runs.overview.requirements')}</div>
          <RunsRequirements runRequirements={runRequirements} />
        </>
      )}
      {appVersionPlatVersions.length > 0 && (
        <>
          <div className={styles.title}>
            {t('runs.overview.configurations')}
          </div>
          <RunsConfigurations
            appVersionPlatVersions={appVersionPlatVersions}
            runId={run.id}
          />
        </>
      )}
    </div>
  );
}
