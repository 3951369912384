import { virtualSelectors } from 'src/gen/domain/virtual/virtualSelector';
import { State } from '@bitmodern/redux/store';
import omitBy from 'lodash/omitBy';
import { VirtualSchemaType } from 'src/enums/VirtualSchemaTypeEmum';

export interface VirtualSchema {
  description: string;
  is_mandatory: boolean;
  type: VirtualSchemaType;
}

export interface Schemas {
  [key: string]: VirtualSchema;
}

export function virtualsSelector(state: State) {
  return virtualSelectors.selectAll(state);
}

export function virtualSelector(state: State, virtualId: number) {
  return virtualSelectors.selectById(state, virtualId);
}

export function virtualsByTableNameSelector(
  state: State,
  tableName: string,
): Schemas {
  const virtuals = virtualsSelector(state).filter(
    (virtual) => virtual.table_name === tableName.toLowerCase(),
  );
  return virtuals[0]?.virtual_schema?.columns || {};
}

export function requiredVirtualsByTableNameSelector(
  state: State,
  tableName: string,
): Schemas {
  const schemas = virtualsByTableNameSelector(state, tableName);
  return omitBy(schemas, (item) => !item.is_mandatory);
}
