import React, { ComponentProps, ReactNode } from 'react';
import { Dialog } from '@bitmodern/bit-ui';
import { InviteUsersForm } from 'src/components/organisms';

export type InviteUsersFormValues = {
  emails: string;
  accessRole?: number;
};

type Props = Pick<ComponentProps<typeof Dialog>, 'isOpen'> & {
  onClose: () => void;
  onSubmit: (initialValues) => void;
  title: ReactNode;
};

export default function InviteUsersDialog({
  isOpen,
  onClose,
  onSubmit,
  title,
}: Props) {
  return (
    <Dialog
      isDismissable={false}
      isOpen={isOpen}
      onClose={onClose}
      title={title}>
      <InviteUsersForm onSubmit={onSubmit} />
    </Dialog>
  );
}
