/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { CheckSuiteApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { checkRunNormalizer } from '../check_run/checkRunNormalize';

export const checkSuiteNormalizer = (
  model: CheckSuiteApi,
  cache: NormalizerCache,
) => {
  if (!cache.checkSuite) {
    cache.checkSuite = [];
  }
  cache.checkSuite.push(model);

  if (model.check_run && model.check_run.length) {
    model.check_run.forEach((child) => {
      checkRunNormalizer(child, cache);
    });
    model.check_run = undefined;
  }
};
