/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { CouponHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { couponHistoryAdapter } from './couponHistoryAdapter';
import { couponHistoryFetchThunk } from './couponHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const couponHistorySlice = createSlice({
  name: 'couponHistory',
  initialState: couponHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    couponHistoryCleared(state) {
      state.loading = 'idle';
      couponHistoryAdapter.removeAll(state);
    },
    couponHistoryUpsertMany(
      state,
      action: PayloadAction<CouponHistory[] | Record<number, CouponHistory>>,
    ) {
      couponHistoryAdapter.upsertMany(state, action.payload);
    },
    couponHistoryUpsertOne(state, action: PayloadAction<CouponHistory>) {
      couponHistoryAdapter.upsertOne(state, action.payload);
    },
    couponHistoryUpdateOne(
      state,
      action: PayloadAction<Update<CouponHistory>>,
    ) {
      couponHistoryAdapter.updateOne(state, action.payload);
    },
    couponHistoryRemoveOne(state, action: PayloadAction<number>) {
      couponHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(couponHistoryFetchThunk.pending, handlePending);
    builder.addCase(couponHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      couponHistoryAdapter.upsertMany(state, action.payload.couponHistory);
    });
    builder.addCase(couponHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  couponHistoryCleared,
  couponHistoryUpsertMany,
  couponHistoryUpsertOne,
  couponHistoryUpdateOne,
  couponHistoryRemoveOne,
} = couponHistorySlice.actions;

export const couponHistoryReducer = couponHistorySlice.reducer;
