import React, { memo, useCallback, useMemo } from 'react';
import { FlatList, Surface } from '@bitmodern/bit-ui';
import { BookmarkIcon } from '@bitmodern/bit-ui/icons';
import { Requirement as RequirementType } from '@testquality/sdk';
import { Option } from '@bitmodern/bit-ui/Select/Select';
import {
  RequirementFilterGithub,
  RequirementFilterJira,
} from 'src/enums/RequirementFilterEnum';
import { useTranslation } from 'src/i18n/hooks';
import RequirementListed from '../RequirementListed';
import RequirementsHeader from './components/RequirementsHeader';
import EmptyList from '../EmptyList';
import vars from 'src/export.scss';
import styles from './RequirementsContainer.module.scss';

type Props = {
  currentRequirementId: number;
  filter: RequirementFilterGithub | RequirementFilterJira;
  filters: Option[];
  initialSearch?: string;
  onAddRequirement: () => Promise<any>;
  onChangeFilter: (option: Option) => void;
  onChangeSearch: (search: string) => void;
  requirements: RequirementType[];
  site: string;
};

function Requirements({
  currentRequirementId,
  initialSearch = '',
  onChangeSearch,
  filters,
  filter,
  onChangeFilter,
  requirements,
  site = '',
  onAddRequirement,
}: Props) {
  const { t } = useTranslation();
  const renderItem = useCallback(
    ({ item, context }) => (
      <RequirementListed
        key={item.id}
        requirement={item}
        selected={context.currentRequirementId === item.id}
        site={context.site}
      />
    ),
    [],
  );

  const keyExtractor = useCallback((item) => item.id, []);

  const context = useMemo(
    () => ({
      currentRequirementId,
      site,
    }),
    [currentRequirementId, site],
  );

  const renderEmpty = useCallback(
    () => (
      <EmptyList
        className={styles.empty}
        icon={<BookmarkIcon color={vars.textSecondary} size={72} />}
        description={t('requirements.filtersEmpty.description')}
        size="small"
        variant="secondary"
        title={t('requirements.filtersEmpty.title')}
      />
    ),
    [t],
  );

  return (
    <Surface className={styles.panel}>
      <RequirementsHeader
        filter={filter}
        filters={filters}
        initialSearch={initialSearch}
        onAddRequirement={onAddRequirement}
        onChangeFilter={onChangeFilter}
        onChangeSearch={onChangeSearch}
      />

      <div className={styles.panelContent}>
        <FlatList
          context={context}
          className={styles.list}
          data={requirements}
          gap={8}
          keyExtractor={keyExtractor}
          renderEmpty={renderEmpty}
          renderItem={renderItem}
          scrollToElement={currentRequirementId}
          scrollClassName={styles.scroll}
        />
      </div>
    </Surface>
  );
}

export default memo(Requirements);
