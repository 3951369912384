/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  User,
  UserApi,
  userGetMany,
  userGetOne,
  userDeleteOne,
  userUpdateOne,
  userCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { userNormalizer } from './userNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const userFetchManyThunk = createAsyncThunk(
  'user/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<User>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await userGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, userNormalizer);
      }
      return {
        user: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const userFetchOneThunk = createAsyncThunk(
  'user/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<User>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'user/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await userGetOne(arg.id as number, arg);
      return executeNormalizer([data], userNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const userDeleteOneThunk = createAsyncThunk(
  'user/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<User>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'user/DeleteOneThunk');
      }
      return await userDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const userUpdateOneThunk = createAsyncThunk(
  'user/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<UserApi>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'user/UpdateOneThunk');
      }
      const data = await userUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<User>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<User>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const userCreateOneThunk = createAsyncThunk(
  'user/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<UserApi>, { dispatch }) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'user/CreateOneThunk',
        );
      }
      const value = await userCreateOne(arg.data, arg);
      dispatch(
        userFetchOneThunk({ id: value.id, params: { _with: 'access_role' } }),
      );
      return value;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
