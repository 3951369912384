import React from 'react';
import { mergeProps, useFocus, useHover } from 'react-aria';
import { useTranslation } from 'src/i18n/hooks';
import { FilterIcon } from '@bitmodern/bit-ui/icons';
import { Button } from '@bitmodern/bit-ui';
import styles from './SaveFilterMenuItem.module.scss';

type Props = {
  onSave: () => void;
};

export default function SaveFilterMenuItem({ onSave }: Props) {
  const { t } = useTranslation();
  const { hoverProps } = useHover({});
  const { focusProps } = useFocus({});

  return (
    <li className={styles.menuItem} {...mergeProps(hoverProps, focusProps)}>
      <div className={styles.labelContainer}>
        <FilterIcon size={18} className={styles.icon} />
        <span>{t('commandBarFilter.customFilter.save.message')}</span>
      </div>
      <Button size="small" color="primaryDark" onClick={onSave}>
        {t('commandBarFilter.customFilter.save.save')}
      </Button>
    </li>
  );
}
