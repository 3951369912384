import { useFormik } from 'formik';
import React from 'react';
import Yup from 'src/utils/yup';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
  SelectMultiple,
} from '@bitmodern/bit-ui';
import { BatchService, Label, Suite } from '@testquality/sdk';
import { suiteUpdateOneThunk } from 'src/gen/domain/suite/suiteThunk';
import {
  labelAssignedCreateOneThunk,
  labelAssignedDeleteOneThunk,
} from 'src/gen/domain/label_assigned/labelAssignedThunk';
import { labelCreateOneThunk } from 'src/gen/domain/label/labelThunk';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { labelsSelector } from '@bitmodern/redux/state/label/selectors';
import { labelAssignedBySuiteSelector } from '@bitmodern/redux/state/label_assigned/selectors';
import { RelatedType } from 'src/enums/RelatedTypeEnum';
import { formikError } from 'src/utils/formik';
import styles from './SuiteEditDialog.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('suiteEdit.form.name'),
});

type Props = {
  suite: Suite;
};

export default function SuiteEditDialog({ suite }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hideModal } = useModalManager();
  const labels = useAppSelector(labelsSelector);
  const labelsAssigned = useAppSelector((state) =>
    labelAssignedBySuiteSelector(state, { suiteId: suite.id }),
  );
  const labelIds = labels.map((label) => label.id);
  const labelsIdsFromAssigned = labelsAssigned.map((la) => la.label_id);
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: suite.name,
      labels: labelsIdsFromAssigned,
    },
    onSubmit,
  });

  const labelOptions = labels.map((label) => ({
    value: label.id,
    label: label.label,
  }));

  const onChangeLabel = (values) => {
    formik.setFieldValue('labels', values);
  };

  async function onSubmit({ name, labels: submittedLabels }) {
    const batch = new BatchService();

    if (name !== suite.name) {
      dispatch(
        suiteUpdateOneThunk({
          id: suite.id,
          data: { name },
          batch,
        }),
      );
    }

    /* NOTE: Creeate new labels (if any) */
    const labelsBatch = new BatchService();
    const existingLabels = submittedLabels.filter((value) => {
      if (labelIds.includes(value)) return true;

      dispatch(
        labelCreateOneThunk({
          data: { label: value },
          batch: labelsBatch,
        }),
      );
      return false;
    });

    const newLabels: Label[] = await labelsBatch
      .executeBatch()
      .then(({ responses }) =>
        responses.length ? responses[0].map((labelRes) => labelRes.data) : [],
      );

    const newLabelsIds = [...existingLabels, ...newLabels.map((l) => l.id)];

    /* NOTE: Assign new labels to suite */
    newLabelsIds.forEach((labelId) => {
      dispatch(
        labelAssignedCreateOneThunk({
          data: {
            related_id: suite.id,
            related_type: RelatedType.SUITE,
            label_id: labelId,
          },
          batch,
        }),
      );
    });

    /* NOTE: Delete removed labels */
    labelsAssigned.forEach((labelAssigned) => {
      if (!submittedLabels.includes(labelAssigned.label_id)) {
        dispatch(labelAssignedDeleteOneThunk({ id: labelAssigned.id, batch }));
      }
    });

    return batch.executeBatch().then(hideModal);
  }

  return (
    <Dialog
      animatePresence={false}
      isDismissable={false}
      isOpen
      onClose={hideModal}
      size="small"
      title={t('suiteEdit.title')}>
      <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <DialogContent className={styles.content}>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('suiteEdit.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            value={formik.values.name}
          />
          <SelectMultiple
            label={t('suiteCreate.form.labels')}
            onChange={onChangeLabel}
            options={labelOptions}
            placeholder={t('suiteCreate.form.labelsPlaceholder')}
            tags
            values={formik.values.labels}
          />
        </DialogContent>
        <PanelActions>
          <Button
            loading={formik.isSubmitting}
            type="submit"
            disabled={!formik.dirty}>
            {t('suiteEdit.form.submit')}
          </Button>
        </PanelActions>
      </form>
    </Dialog>
  );
}
