import { useId } from 'react-aria';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Input,
  PanelActions,
} from '@bitmodern/bit-ui';
import { runUpdateOneThunk } from 'src/gen/domain/run/runThunk';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { runSelector } from '@bitmodern/redux/state/runs/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import styles from './RunEditDialog.module.scss';

type Props = {
  runId: number;
};

export default function RunEditDialog({ runId }: Props) {
  const { t } = useTranslation();
  const { hideModal } = useModalManager();
  const dispatch = useAppDispatch();
  const run = useAppSelector((state) => runSelector(state, runId));
  const id = useId();

  const initialValues = useMemo(() => {
    return {
      name: run?.name || '',
    };
  }, [run]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  function onSubmit({ name }) {
    return dispatch(
      runUpdateOneThunk({
        id: run?.id,
        data: { name },
      }),
    ).finally(hideModal);
  }

  return (
    <Dialog
      animatePresence={false}
      onClose={hideModal}
      size="small"
      title={t('runEdit.title')}>
      <DialogContent className={styles.content}>
        <form
          id={id}
          onReset={formik.handleSubmit}
          onSubmit={formik.handleSubmit}>
          <Input
            error={formikError(formik, 'name')}
            fullWidth
            label={t('runEdit.form.name')}
            name="name"
            onChange={formik.handleChange}
            onFocus={formik.handleBlur}
            required
            value={formik.values.name}
          />
        </form>
      </DialogContent>
      <PanelActions>
        <Button form={id} loading={formik.isSubmitting} type="submit">
          {t('runEdit.form.submit')}
        </Button>
      </PanelActions>
    </Dialog>
  );
}
