import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { useEditing } from './useEditing';
import { useEditingKeyboard } from './useEditingKeyboard';
import { InlineEditButtons } from './InlineEditButtons';
import styles from './InlineEdit.module.scss';
import InputNumber from '../InputNumber';

type Props = ComponentProps<typeof InputNumber> & {
  onCommit: (value?: number) => Promise<any>;
};

export function InputDirectInlineEdit({ onCommit, value, id, ...rest }: Props) {
  const [valueInline, setValueInline] = useState(value);

  const inlineEdit = useEditing({
    onCommit: () => {
      if (valueInline === value) return Promise.resolve();
      return onCommit(valueInline);
    },
    onCancel: () => setValueInline(value),
    id,
  });

  const { keyboardProps } = useEditingKeyboard({
    isEditing: inlineEdit.isEditing,
    onCancel: inlineEdit.cancel,
    onCommit: inlineEdit.commit,
    onOpen: inlineEdit.open,
  });
  const input = useRef<any>(null);

  useEffect(() => {
    setValueInline(value);
  }, [value]);

  const onClickInline = () => {
    inlineEdit.open();
  };

  const onCancel = () => {
    inlineEdit.cancel();
    setTimeout(() => {
      if (input.current) input.current.blur();
    }, 0);
  };

  const handleCommit = () => {
    inlineEdit.commit();
    setTimeout(() => {
      if (input.current) input.current.blur();
    }, 0);
  };

  const onChange = (data: number) => setValueInline(data);
  return (
    <div
      className={styles.inlineBase}
      {...keyboardProps}
      onClick={onClickInline}>
      <InputNumber
        {...rest}
        endAdornment={
          <InlineEditButtons
            onCancel={onCancel}
            onCommit={handleCommit}
            isEditing={inlineEdit.isEditing && !rest.isReadOnly}
          />
        }
        id={inlineEdit.id}
        onChange={onChange}
        value={valueInline}
        focused={inlineEdit.isEditing}
        ref={input}
      />
    </div>
  );
}

export default InputDirectInlineEdit;
