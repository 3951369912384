import React, { ComponentProps } from 'react';
import { Avatar } from '@bitmodern/bit-ui';
import { User } from '@testquality/sdk';
import { useTranslation } from 'src/i18n/hooks';
import { formatUserName, processAttachmentPath } from 'src/utils/fileHelper';
import { Trans } from 'react-i18next';
import ActivityTextFieldDiff from './ActivityTextFieldDiff';
import styles from './ActivityItem.module.scss';

type Item = {
  user?: User;
  diffs: {
    [key: string]: ComponentProps<typeof ActivityTextFieldDiff>['diff'];
  };
  updatedAt: Date;
  operation: 'create' | 'update' | 'add' | 'remove' | 'stepCreate';
  sequence?: number;
};

type Fields =
  | 'name'
  | 'case_type_id'
  | 'case_priority_id'
  | 'estimate'
  | 'precondition'
  | 'assigned_to_tester'
  | 'is_automated'
  | 'status_id'
  | 'step'
  | 'expected_result';

type Props = {
  item: Item;
  textFieldsItems?: string[];
  containerWidth: number | null;
};

const operationsKeyComponentsMap = {
  'activity.operation.set': [<strong key="str-1" />],
  'activity.operation.update': [<strong key="str-1" />, <strong key="str-2" />],
};

export default function ActivityItem({
  item,
  textFieldsItems = [],
  containerWidth,
}: Props) {
  const { t } = useTranslation();
  const username = formatUserName(item.user);

  const renderOperation = (field: Fields) => {
    const diff = item.diffs?.[field];
    const operationKey = getOperationI18nKey(item, field, textFieldsItems);

    return (
      <div className={styles.change} key={field}>
        <Trans
          i18nKey={operationKey}
          values={{
            username,
            item: t(`activity.field.${field}`, {
              sequence: item?.sequence,
            }),
            from: diff?.from,
            to: diff?.to,
          }}
          components={operationsKeyComponentsMap[operationKey] || []}
        />
        <span className={styles.ago}>
          {t('activity.ago', { date: item.updatedAt })}
        </span>

        <ActivityTextFieldDiff
          operation={item.operation}
          textFieldsItems={textFieldsItems}
          field={field}
          containerWidth={containerWidth}
          diff={diff}
        />
      </div>
    );
  };

  return (
    <li className={styles.item}>
      {item.user && (
        <Avatar
          className={styles.avatar}
          name={item.user.given_name}
          size={16}
          src={processAttachmentPath(item.user.picture)}
        />
      )}
      <div className={styles.wrapper}>
        {(Object.keys(item.diffs) as Fields[]).map(renderOperation)}
      </div>
    </li>
  );
}

type Operations =
  | 'add'
  | 'create'
  | 'stepCreate'
  | 'remove'
  | 'updateTextField'
  | 'set'
  | 'clear'
  | 'update';

type OperationKey = `activity.operation.${Operations}`;

function getOperationI18nKey(
  item: Item,
  field: string,
  textFieldsItems: string[],
): OperationKey {
  let key: OperationKey = 'activity.operation.add';
  const diff = item.diffs?.[field];

  if (item.operation === 'create') key = 'activity.operation.create';

  if (item.operation === 'stepCreate') key = 'activity.operation.stepCreate';

  if (item.operation === 'remove') key = 'activity.operation.remove';

  if (item.operation === 'update') {
    if (textFieldsItems.includes(field)) {
      if (diff.from && diff.to) {
        return 'activity.operation.updateTextField';
      }
      if (!diff.to) return 'activity.operation.remove';
      if (!diff.from) {
        return key;
      }
    }

    if (diff.to && !diff.from) key = 'activity.operation.set';
    else if (!diff.to && diff.from) key = 'activity.operation.clear';
    else key = 'activity.operation.update';
  }
  return key;
}
