import React from 'react';
import { useTranslation } from 'src/i18n/hooks';
import { Dialog } from '@bitmodern/bit-ui';
import { ProjectColors, ProjectIcons } from 'src/enums/ProjectEnums';
import { projectSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { ProjectForm } from '..';
import { InitialValues } from '../ProjectForm/ProjectFormContainer';

type Props = {
  projectId: number;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: InitialValues) => void;
};

export default function ProjectEditDialog({
  projectId,
  onClose,
  open,
  onSubmit,
}: Props) {
  const { t } = useTranslation();
  const project = useAppSelector((state) => projectSelector(state, projectId));

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      size="small"
      title={t('projectEdit.title')}>
      <ProjectForm
        initialValues={{
          color: (project?.color || ProjectColors.Blue) as ProjectColors,
          description: project?.description || '',
          icon: (project?.picture || ProjectIcons.Test) as ProjectIcons,
          name: project?.name || '',
          integrationProject: undefined,
        }}
        onSubmit={onSubmit}
        project={project}
      />
    </Dialog>
  );
}
