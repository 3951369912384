import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <path
      d="M14,8 L14,3 L19,8 L14,8 Z M15.414,2 L6,2 C4.897,2 4,2.898 4,4 L4,20 C4,21.103 4.897,22 6,22 L18,22 C19.103,22 20,21.103 20,20 L20,6.586 L15.414,2 Z"
      fill="none"
    />
    <path d="M14,8 L14,3 L19,8 L14,8 Z M15.414,2 L6,2 C4.897,2 4,2.898 4,4 L4,20 C4,21.103 4.897,22 6,22 L18,22 C19.103,22 20,21.103 20,20 L20,6.586 L15.414,2 Z" />
  </>,
  'Attachment',
);
