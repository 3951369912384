import { User, Comment } from '@testquality/sdk';
import { State } from '@bitmodern/redux/store';
import {
  runResultCommentsSelector,
  testCommentsSelector,
} from '@bitmodern/redux/state/comments/selectors';
import { userSelectorById } from '@bitmodern/redux/state/users/selectors';
import { testHistoryByTestSelector } from '@bitmodern/redux/state/testHistory/selectors';
import { stepsTestHistorySelector } from '@bitmodern/redux/state/StepHistory/selectors';
import { statusSelectors } from 'src/gen/domain/status/statusSelector';
import { formatUserName } from 'src/utils/fileHelper';
import { casePrioritySelector } from '../casePriorities/selectors';
import { caseTypeSelector } from '../caseTypes/selectors';
import { runResultHistoryByRunResultIdSelector } from '../runResultHistory/selectors';

const testDiffs = [
  'name',
  'case_type_id',
  'case_priority_id',
  'estimate',
  'precondition',
  'assigned_to_tester',
  'is_automated',
  'description',
] as const;

const stepDiffs = ['step', 'expected_result', 'sequence'] as const;

const runResultDiffs = ['assigned_to_tester', 'status_id'] as const;

type DiffsKeys =
  | (typeof testDiffs)[number]
  | (typeof stepDiffs)[number]
  | (typeof runResultDiffs)[number];

type ActivityDiff = {
  [k in DiffsKeys]: {
    from: string;
    to?: string;
  };
};

type Activity = {
  user?: User;
  diffs: Activity['operation'] extends 'comment' ? never : ActivityDiff;
  updatedAt: Date;
  operation: 'create' | 'comment' | 'update' | 'add' | 'remove' | 'stepCreate';
  sequence?: number;
  comment: Activity['operation'] extends 'comment' ? Comment : never;
};

export function testActivitySelector(state: State, testId: number): Activity[] {
  const comments = testCommentsSelector(state, testId);
  const testHistory = testHistoryByTestSelector(state, testId);
  const stepsTestHistory = stepsTestHistorySelector(state, testId);

  const hydrateDiff = (diff, value) => {
    if (diff === 'case_type_id') {
      return caseTypeSelector(state, value)?.name;
    }
    if (diff === 'case_priority_id') {
      return casePrioritySelector(state, value)?.name;
    }
    if (diff === 'assigned_to_tester') {
      const user = userSelectorById(state, value);
      return formatUserName(user);
    }
    return value;
  };

  const testActivity = [...testHistory]
    .sort(sortUpdated)
    .map((item, i, array) => {
      let diffs = {};
      if (i > 0 && item.operation === 'update') {
        const prevItem = array[i - 1];
        diffs = objDiffs(prevItem, item, testDiffs, (key, value) => ({
          from: hydrateDiff(key, prevItem[key]),
          to: hydrateDiff(key, value),
        }));
      }

      if (item.operation === 'create') {
        diffs = { test: null };
      }

      return {
        user: userSelectorById(state, item.updated_by),
        diffs,
        updatedAt: new Date(item.updated_at),
        operation: item.operation,
      } as Activity;
    })
    .filter(filterEmptyUpdates);

  const stepsActivity = [...stepsTestHistory]
    .sort(sortUpdated)
    .map((item, i, array) => {
      let diffs = {};
      let { operation }: { operation: string } = item;

      if (i > 0 && item.operation === 'update') {
        const prevItem = findPrevious(array, i, (el) => el.id === item.id);
        diffs = objDiffs(prevItem || {}, item, stepDiffs, (key, value) => ({
          from: prevItem?.[key],
          to: value,
        }));
      }

      if (item.operation === 'delete') {
        diffs = { step: null };
        operation = 'remove';
      }

      if (item.operation === 'create') {
        diffs = { step: null };
        operation = 'stepCreate';
      }

      return {
        diffs,
        operation,
        sequence: item.sequence,
        updatedAt: new Date(item.updated_at),
        user: userSelectorById(state, item.updated_by),
      } as Activity;
    })
    .filter(filterEmptyUpdates);

  const commentsActivity = comments.map(
    (c) =>
      ({
        comment: c,
        operation: 'comment',
        updatedAt: new Date(c.created_at),
        user: userSelectorById(state, c.updated_by),
      } as Activity),
  );

  const finalActivity = [
    ...testActivity,
    ...stepsActivity,
    ...commentsActivity,
  ].sort(
    (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
  );
  return finalActivity;
}

export function runResultActivitySelector(state: State, runResultId: number) {
  const runResultHistory = runResultHistoryByRunResultIdSelector(state, {
    runResultId,
  });
  const comments = runResultCommentsSelector(state, runResultId);

  const hydrateRunResult = (diff, value) => {
    if (diff === 'status_id') {
      return statusSelectors.selectById(state, value)?.name;
    }
    if (diff === 'assigned_to_tester') {
      const user = userSelectorById(state, value);
      return formatUserName(user);
    }
    return value;
  };

  const activity: Activity[] = [...runResultHistory]
    .sort(sortUpdated)
    .map((item, i, array) => {
      let diffs = {};
      if (i > 0 && item.operation === 'update') {
        const prevItem = array[i - 1];
        diffs = objDiffs(prevItem, item, runResultDiffs, (key, value) => ({
          from: hydrateRunResult(key, prevItem[key]),
          to: hydrateRunResult(key, value),
        }));
      }
      return {
        user: userSelectorById(state, item.updated_by),
        diffs,
        updatedAt: new Date(item.updated_at),
        operation: item.operation,
      } as Activity;
    })
    .filter(filterEmptyUpdates);

  const commentsActivity = comments.map(
    (c) =>
      ({
        comment: c,
        operation: 'comment',
        updatedAt: new Date(c.created_at),
        user: userSelectorById(state, c.updated_by),
      } as Activity),
  );

  const finalActivity = [...activity, ...commentsActivity].sort(
    (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
  );

  return finalActivity;
}

function sortUpdated(a, b) {
  return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
}

function filterEmptyUpdates(item) {
  return (
    (item.operation === 'update' && Object.keys(item.diffs).length === 0) ===
    false
  );
}

function bothNull(a, b) {
  return (a === null || a === undefined) && (b === null || b === undefined);
}

/**
 * Get and object with the values different from previous state
 */
function objDiffs<A extends {}, B extends {}>(
  a: A,
  b: B,
  keys: readonly string[],
  transformValue: (key, value) => any = (key, value) => value,
): { [key: string]: any } {
  return keys.reduce((diffs, key) => {
    if (a[key] !== b[key] && !bothNull(a[key], b[key])) {
      diffs[key] = transformValue(key, b[key]);
    }
    return diffs;
  }, {});
}

/**
 * Return the previous element of array to the left of toIndex that satisfies predicate
 */
function findPrevious<A extends readonly any[]>(
  array: A,
  toIndex: number,
  predicate: (item: A[number]) => boolean,
): A[number] | undefined {
  for (let index = toIndex - 1; index >= 0; index -= 1) {
    if (predicate(array[index])) return array[index];
  }
  return undefined;
}
