import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <path d="M106.3 5.6c-1.8.9-18.6 17.3-37.4 36.5L34.8 77l37.2-.2 37.2-.3 2.3-6.2c2.6-6.7 8-15.7 12.8-21.3 4.4-5 13.5-12.2 18.5-14.4 2.3-1 4.2-2.2 4.2-2.6 0-.3-5.5-6.1-12.3-12.8-15.3-15.1-20.4-17.5-28.4-13.6zM164.7 58.4c-13 4.7-20.5 16.2-22.8 35.1-1.1 9.2-.1 22.5 2.5 31 5.7 18.9 23.7 28.1 45 23.1 5.5-1.3 6.5-2.1 17.8-13.3 6.6-6.5 12.6-13 13.4-14.5 1.7-3.2 1.8-10.1.3-13.1-2.1-4.2-48.3-49.7-50.4-49.7-1.1.1-3.7.7-5.8 1.4zM6 107.6c-1.5 4-1.2 9.1.9 13.2 1.1 2 10.3 12 20.5 22.2L46 161.5V105H7l-1 2.6zM79 149.8v44.9l12.8 12.5c10.3 10.1 13.7 12.8 17 13.8 8.1 2.1 9.6 1.1 32.9-22.2 11.7-11.7 21.3-21.6 21.3-22 0-.5-1.5-.8-3.3-.8-8.8 0-23.4-6-31.9-13.2-10.5-8.7-18.7-25.8-20.9-43.2-.6-4.5-1.3-9.6-1.5-11.4l-.5-3.2H79v44.8z" />,
  'TestComponent',
  {},
  300,
  300,
);
