import React, { ReactElement, useEffect, useState } from 'react';
import { Resizable } from 're-resizable';
import { useHistory } from 'react-router';
import { Content } from '@bitmodern/bit-ui';
import { EmptyTests, Runs } from 'src/components/organisms';
import { useAppSelector } from '@bitmodern/redux/store';
import useParams from 'src/hooks/useParams';
import { useTranslation } from 'src/i18n/hooks';
import { routes } from 'src/components/Router';
import { selectTestsByProject } from '@bitmodern/redux/state/tests/selectors';
import {
  selectRunsByFilter,
  selectRunsByProjectId,
} from '@bitmodern/redux/state/runs/selectors';
import { RunFilter, RunFilterDisplay } from 'src/enums/RunFilterEnum';
import { getSize, setSize } from '@bitmodern/services/ResizingService';
import EmptyRuns from './EmptyRuns';
import styles from './RunsView.module.scss';
import { useParamSelector } from 'src/packages/redux/hooks';

const STORAGE_KEY = 'runsWidth';

type Props = {
  children: ReactElement;
};

export default function RunsView({ children }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [filter, setFilter] = useState(RunFilter.All);
  const [search, setSearch] = useState('');
  const { site, projectId, runId } = useParams<typeof routes.RUN.params>();
  const projectIdNum = parseInt(projectId, 10);

  const runs = useAppSelector((state) =>
    selectRunsByFilter(state, {
      projectId: projectIdNum,
      runFilter: filter,
      search,
    }),
  );
  const projectRuns = useParamSelector(selectRunsByProjectId, {
    projectId: projectIdNum,
  });
  const tests = useAppSelector((state) =>
    selectTestsByProject(state, { projectId: projectIdNum }),
  );

  useEffect(() => {
    if (runs && runs.length > 0 && !runId && runs[0]?.id) {
      history.replace(
        routes.RUN({ site, projectId, runId: runs[0].id.toString() }),
      );
    }
  }, [history, site, projectId, runs, runId]);

  const [initialWidth] = useState(() => getSize(STORAGE_KEY) || 300);

  const onResizeStop = (event, direaction, elementRef) => {
    setSize(STORAGE_KEY, elementRef.offsetWidth);
  };

  const onChangeFilter = (option) => {
    setFilter(option.value);
  };

  const onChangeSearch = (value) => {
    setSearch(value);
  };

  const filters = Array.from(RunFilterDisplay).map(([value, label]) => ({
    label: t(label),
    value,
  }));

  const testsEmpty = tests.length === 0;
  const runsEmpty = projectRuns.length === 0;

  let empty;
  if (testsEmpty) {
    empty = <EmptyTests filterType="runs" potentialAmount={tests.length} />;
  } else if (runsEmpty) {
    empty = <EmptyRuns />;
  }

  return (
    <Content fullHeight>
      {!empty ? (
        <div className={styles.runs}>
          <Resizable
            className={styles.aside}
            defaultSize={{
              height: '100%',
              width: initialWidth,
            }}
            enable={{ right: true }}
            maxWidth={450}
            minWidth={325}
            onResizeStop={onResizeStop}>
            <Runs
              filters={filters}
              filter={filter}
              search={search}
              onChangeFilter={onChangeFilter}
              onChangeSearch={onChangeSearch}
              runs={runs}
            />
          </Resizable>
          <div className={styles.content}>{children}</div>
        </div>
      ) : (
        empty
      )}
    </Content>
  );
}
