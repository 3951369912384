import { State } from '@bitmodern/redux/store';
import { casePrioritySelectors } from 'src/gen/domain/case_priority/casePrioritySelector';

export function casePrioritiesSelector(state: State) {
  return casePrioritySelectors.selectAll(state);
}

export function casePrioritySelector(state: State, id: number) {
  return casePrioritySelectors.selectById(state, id);
}
