/* eslint-disable react/self-closing-comp */
import React from 'react';
import makeSvgIcon from './makeSvgIcon';

export default makeSvgIcon(
  <>
    <title>Group 2</title>
    <g
      id="Exploration-04"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="Group-2" transform="translate(0.000000, 0.000000)">
        <g id="Group-25">
          <path
            d="M138.025393,105.943862 L19.2169886,105.943862 C15.4961916,105.943862 12.480274,102.922854 12.480274,99.1964881 L12.480274,23.9061963 C12.480274,20.1795119 15.4961916,17.1588223 19.2169886,17.1588223 L138.025393,17.1588223 C141.745872,17.1588223 144.76179,20.1795119 144.76179,23.9061963 L144.76179,99.1964881 C144.76179,102.922854 141.745872,105.943862 138.025393,105.943862"
            id="Fill-1"
            fill="#384070"></path>
          <path
            d="M134.57927,98.8501114 L22.6633021,98.8501114 C21.140089,98.8501114 19.9054377,97.6135066 19.9054377,96.0878833 L19.9054377,27.7009337 C19.9054377,26.1756286 21.140089,24.9387056 22.6633021,24.9387056 L134.57927,24.9387056 C136.102166,24.9387056 137.337135,26.1756286 137.337135,27.7009337 L137.337135,96.0878833 C137.337135,97.6135066 136.102166,98.8501114 134.57927,98.8501114"
            id="Fill-3"
            fill="#5376BA"></path>
          <path
            d="M115.708365,89.9976446 L41.926753,89.9976446 C39.323657,89.9976446 37.2137859,87.884435 37.2137859,85.2775385 L37.2137859,4.72029708 C37.2137859,2.11340053 39.323657,0.000190981432 41.926753,0.000190981432 L115.708365,0.000190981432 C118.311144,0.000190981432 120.421015,2.11340053 120.421015,4.72029708 L120.421015,85.2775385 C120.421015,87.884435 118.311144,89.9976446 115.708365,89.9976446"
            id="Fill-5"
            fill="#FEFEFE"></path>
          <path
            d="M155.941056,115.054027 L1.30138912,115.054027 C0.582844356,115.054027 0,114.470578 0,113.750578 L0,107.317369 C0,106.597687 0.582844356,106.014239 1.30138912,106.014239 L155.941056,106.014239 C156.659919,106.014239 157.242445,106.597687 157.242445,107.317369 L157.242445,113.750578 C157.242445,114.470578 156.659919,115.054027 155.941056,115.054027"
            id="Fill-7"
            fill="#F5F9FD"></path>
          <path
            d="M151.676504,119.942642 L5.56562334,119.942642 C2.49186619,119.942642 0,117.446833 0,114.367894 L0,113.064764 L157.242445,113.064764 L157.242445,114.367894 C157.242445,117.446833 154.750579,119.942642 151.676504,119.942642"
            id="Fill-9"
            fill="#B7C8E6"></path>
          <path
            d="M101.56225,115.591767 L55.6799413,115.591767 C54.9613965,115.591767 54.3785522,115.008318 54.3785522,114.288318 L54.3785522,111.748584 C54.3785522,111.028584 54.9613965,110.445135 55.6799413,110.445135 L101.56225,110.445135 C102.281112,110.445135 102.863639,111.028584 102.863639,111.748584 L102.863639,114.288318 C102.863639,115.008318 102.281112,115.591767 101.56225,115.591767"
            id="Fill-11"
            fill="#B7C8E6"></path>
          <path
            d="M112.158259,11.1667196 C110.907717,11.1727321 110.105909,10.4002122 110.100161,9.18397878 C110.094786,7.95183024 110.887378,7.17421751 112.142369,7.18023023 C113.369393,7.18599469 114.139739,7.93114058 114.158171,9.12954907 C114.177239,10.3616976 113.393228,11.1606366 112.158259,11.1667196"
            id="Fill-13"
            fill="#5376BA"></path>
          <path
            d="M98.6058561,11.165443 C97.3314799,11.1562122 96.6253293,10.410748 96.6049902,9.05414324 C96.589418,7.99833422 97.5218418,7.16661008 98.7040561,7.18093369 C99.8862704,7.1958939 100.662019,7.99101326 100.658219,9.18496552 C100.654392,10.407565 99.859893,11.1743554 98.6058561,11.165443"
            id="Fill-15"
            fill="#5376BA"></path>
          <path
            d="M105.380516,11.167166 C104.039084,11.1690716 103.391726,10.5203714 103.390454,9.17236074 C103.389184,7.86 104.081034,7.17405836 105.399902,7.1804244 C106.600231,7.18615385 107.362632,7.94976127 107.366463,9.14976127 C107.370577,10.4535279 106.670147,11.165252 105.380516,11.167166"
            id="Fill-17"
            fill="#5376BA"></path>
          <path
            d="M112.158259,11.1667196 C110.907717,11.1727321 110.105909,10.4002122 110.100161,9.18397878 C110.094786,7.95183024 110.887378,7.17421751 112.142369,7.18023023 C113.369393,7.18599469 114.139739,7.93114058 114.158171,9.12954907 C114.177239,10.3616976 113.393228,11.1606366 112.158259,11.1667196"
            id="Fill-19"
            fill="#5376BA"></path>
          <path
            d="M98.6058561,11.165443 C97.3314799,11.1562122 96.6253293,10.410748 96.6049902,9.05414324 C96.589418,7.99833422 97.5218418,7.16661008 98.7040561,7.18093369 C99.8862704,7.1958939 100.662019,7.99101326 100.658219,9.18496552 C100.654392,10.407565 99.859893,11.1743554 98.6058561,11.165443"
            id="Fill-21"
            fill="#5376BA"></path>
          <path
            d="M105.380516,11.167166 C104.039084,11.1690716 103.391726,10.5203714 103.390454,9.17236074 C103.389184,7.86 104.081034,7.17405836 105.399902,7.1804244 C106.600231,7.18615385 107.362632,7.94976127 107.366463,9.14976127 C107.370577,10.4535279 106.670147,11.165252 105.380516,11.167166"
            id="Fill-23"
            fill="#5376BA"></path>
        </g>
        <g id="Group-14" transform="translate(56.000000, 25.000000)">
          <rect
            id="Rectangle"
            fill="#58B880"
            x="0"
            y="0"
            width="48"
            height="48"
            rx="8"></rect>
          <g
            id="bookmark-solid"
            transform="translate(12.000000, 8.000000)"
            fill="#FFFFFF"
            fillRule="nonzero">
            <path
              d="M3,0 L21,0 C22.65625,0 24,1.343125 24,3 L24,30.48125 C24,31.31875 23.31875,32 22.48125,32 C22.16875,32 21.8625,31.90625 21.60625,31.725 L12,25 L2.3925,31.725 C2.136875,31.90625 1.8325,32 1.520625,32 C0.680625,32 0,31.31875 0,30.48125 L0,3 C0,1.343125 1.343125,0 3,0 Z"
              id="Path"></path>
          </g>
        </g>
      </g>
    </g>
  </>,
  'LaptopRequirement',
  {},
  123,
  158,
);
