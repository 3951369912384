/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { planSuiteTestIncludeAdapter } from './planSuiteTestIncludeAdapter';

export const planSuiteTestIncludeSelectors =
  planSuiteTestIncludeAdapter.getSelectors(
    (state: State) => state.gen.planSuiteTestInclude,
  );
