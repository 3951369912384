import React, { ReactNode } from 'react';
import { ConsoleLogger } from 'src/common/ConsoleLogger';
import { ErrorView } from 'src/components/views/ErrorView';

type Props = {
  children?: ReactNode;
};

export default class AppErrorBoundary extends React.Component<Props> {
  state = { error: null, errorInfo: null };
  log = new ConsoleLogger('ErrorBoundary');

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    this.log.error('App Error Boundary trigger', error);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error || errorInfo) {
      return <ErrorView />;
    }

    return children;
  }
}
