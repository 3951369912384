import React, { ReactNode } from 'react';
import styles from './Spacer.module.scss';

type Props = {
  children: ReactNode[] | ReactNode;
  className?: string;
};

export default function Spacer({ children, className = '' }: Props) {
  return <div className={`${styles.spacer} ${className}`}>{children}</div>;
}
