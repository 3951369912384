import React, { ReactNode } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

type Props = Pick<
  RouteProps,
  'component' | 'exact' | 'path' | 'strict' | 'location'
> & {
  as?: any;
  children: ReactNode;
  withSwitch?: boolean;
};

export default function RouteNested({
  as,
  children,
  component,
  exact,
  location,
  path,
  strict,
  withSwitch = true,
}: Props) {
  const Component = component;
  const RouteComponent = as || Route;

  return (
    <RouteComponent
      exact={exact}
      location={location}
      path={path}
      strict={strict}
      render={(p) => (
        // @ts-expect-error
        <Component {...p}>
          {withSwitch ? (
            <Switch location={location}>{children}</Switch>
          ) : (
            children
          )}
        </Component>
      )}
    />
  );
}
