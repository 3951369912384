import { State } from '@bitmodern/redux/store';
import { RunResultHistory } from '@testquality/sdk';
import { createSelector } from 'reselect';
import { runResultHistorySelectors } from 'src/gen/domain/run_result/runResultHistorySelector';
import { makeRunResultsByDefect } from '../runResults/selectors';

export function runResultHistorySelector(state: State) {
  return runResultHistorySelectors.selectAll(state);
}

export function runResultHistoryByRunResultIdSelector(
  state: State,
  args: { runResultId?: number },
) {
  return runResultHistorySelectors
    .selectAll(state)
    .filter((rh) => rh.id === args.runResultId);
}

export const runResultHistoryByDefectSelector = () =>
  createSelector(
    [makeRunResultsByDefect(), runResultHistorySelector],
    (runResults, runResultHistory) => {
      let history: RunResultHistory[] = [];
      if (!runResults.length || !runResultHistory.length) return history;
      runResults.forEach((rr) => {
        const toAdd = runResultHistory.filter((rh) => rh.id === rr.id);
        history = history.concat(toAdd);
      });
      return history;
    },
  );
