import React, { useState } from 'react';
import classNames from 'classnames';
import { DeleteIcon, DomainIcon, GithubIcon } from '@bitmodern/bit-ui/icons';
import { IconButton, TableCell, TableRow } from '@bitmodern/bit-ui';
import { SignupOption } from '@testquality/sdk';
import vars from 'src/export.scss';
import { useTranslation } from 'src/i18n/hooks';
import { SignUpType, SignUpTypeDisplay } from 'src/enums/SignUpTypeEnum';
import styles from './SignupOptionItem.module.scss';

export type SignupOptionItemProps = {
  onDelete: (signupOption: SignupOption) => Promise<any>;
  signupOption: SignupOption;
};

export function SignupOptionItem({
  onDelete,
  signupOption,
}: SignupOptionItemProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnDelete = () => {
    setIsLoading(true);
    onDelete(signupOption).finally(() => setIsLoading(false));
  };

  const type = t(SignUpTypeDisplay.get(signupOption.signup_type) as any);

  const icon =
    {
      [SignUpType.GITHUB]: <GithubIcon color={vars.textPrimary} size={20} />,
      [SignUpType.SSO]: <DomainIcon color={vars.textPrimary} size={20} />,
      [SignUpType.GOOGLE]: <DomainIcon color={vars.textPrimary} size={20} />,
      [SignUpType.DOMAIN]: <DomainIcon color={vars.textPrimary} size={20} />,
    }[signupOption.signup_type] || null;

  return (
    <TableRow key={signupOption.id}>
      <TableCell className={styles.cell}>{signupOption.name}</TableCell>
      <TableCell className={styles.cell}>
        <span className={styles.icon}>{icon}</span>
        {type}
      </TableCell>
      <TableCell className={classNames(styles.cell, styles.delete)}>
        <IconButton onClick={handleOnDelete} isLoading={isLoading} size="small">
          <DeleteIcon color={vars.textPrimary} size={16} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
