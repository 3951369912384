import React, { ComponentProps } from 'react';
import classnames from 'classnames';

import styles from './NotificationBubble.module.scss';

type Props = ComponentProps<'span'> & {
  size?: 'small' | 'medium';
  position?: 'right' | 'left';
};

function NotificationBubble({
  children,
  size = 'medium',
  position = 'right',
}: Props) {
  const badgeCN = classnames(styles.badge, styles[size], styles[position]);

  return <span className={badgeCN}>{children}</span>;
}

export default NotificationBubble;
