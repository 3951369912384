import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useFocus, useHover, mergeProps } from 'react-aria';
import classnames from 'classnames';
import { Checkbox, Loading } from '@bitmodern/bit-ui';
import styles from './CommandItem.module.scss';

type Props = {
  checked?: boolean;
  children: ReactNode;
  icon?: ReactElement;
  onHover: () => void;
  onClick: () => void;
  onChangeChecked?: (checked: boolean) => void;
  selected: boolean;
  extraActions?: ReactElement;
  isLoading?: boolean;
};

export function CommandItem({
  checked,
  children,
  icon,
  onChangeChecked,
  onClick,
  onHover,
  selected,
  extraActions,
  isLoading,
}: Props) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { hoverProps } = useHover({ onHoverChange: onHover });
  const { focusProps } = useFocus({ onFocus: onHover });

  const handleClick = () => onClick();

  const menuItemCN = classnames(styles.menuItem, {
    [styles.focused]: selected,
    [styles.disabled]: isLoading,
  });

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [selected]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={menuItemCN}
      onClick={handleClick}
      ref={ref}
      {...mergeProps(hoverProps, focusProps)}>
      <div className={styles.mainContainer}>
        {typeof checked === 'boolean' && (
          <Checkbox
            className={styles.checkbox}
            checked={checked}
            onChange={onChangeChecked}
          />
        )}
        {icon &&
          React.cloneElement(icon, {
            className: styles.icon,
          })}
        {children}
      </div>
      {isLoading && selected && <Loading />}
      {!isLoading && extraActions}
    </li>
  );
}
