/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { BaseCapabilityHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { baseCapabilityHistoryAdapter } from './baseCapabilityHistoryAdapter';
import { baseCapabilityHistoryFetchThunk } from './baseCapabilityHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const baseCapabilityHistorySlice = createSlice({
  name: 'baseCapabilityHistory',
  initialState: baseCapabilityHistoryAdapter.getInitialState<TQStateProperties>(
    {
      loading: 'idle',
      error: undefined,
    },
  ),
  reducers: {
    baseCapabilityHistoryCleared(state) {
      state.loading = 'idle';
      baseCapabilityHistoryAdapter.removeAll(state);
    },
    baseCapabilityHistoryUpsertMany(
      state,
      action: PayloadAction<
        BaseCapabilityHistory[] | Record<number, BaseCapabilityHistory>
      >,
    ) {
      baseCapabilityHistoryAdapter.upsertMany(state, action.payload);
    },
    baseCapabilityHistoryUpsertOne(
      state,
      action: PayloadAction<BaseCapabilityHistory>,
    ) {
      baseCapabilityHistoryAdapter.upsertOne(state, action.payload);
    },
    baseCapabilityHistoryUpdateOne(
      state,
      action: PayloadAction<Update<BaseCapabilityHistory>>,
    ) {
      baseCapabilityHistoryAdapter.updateOne(state, action.payload);
    },
    baseCapabilityHistoryRemoveOne(state, action: PayloadAction<number>) {
      baseCapabilityHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(baseCapabilityHistoryFetchThunk.pending, handlePending);
    builder.addCase(
      baseCapabilityHistoryFetchThunk.fulfilled,
      (state, action) => {
        state.loading = 'idle';
        baseCapabilityHistoryAdapter.upsertMany(
          state,
          action.payload.baseCapabilityHistory,
        );
      },
    );
    builder.addCase(baseCapabilityHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  baseCapabilityHistoryCleared,
  baseCapabilityHistoryUpsertMany,
  baseCapabilityHistoryUpsertOne,
  baseCapabilityHistoryUpdateOne,
  baseCapabilityHistoryRemoveOne,
} = baseCapabilityHistorySlice.actions;

export const baseCapabilityHistoryReducer = baseCapabilityHistorySlice.reducer;
