import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { format } from 'date-fns';

import { useTranslation } from 'src/i18n/hooks';
import { ReliabilityTimeSeries } from '@bitmodern/services/ReliabilityService';
import vars from 'src/export.scss';
import styles from './FlakinessChart.module.scss';

type Props = {
  flakyTimeSeries: ReliabilityTimeSeries[];
};

function FlakinessChart({ flakyTimeSeries }: Props) {
  const { t } = useTranslation();
  const barsData = chartFlakyData(flakyTimeSeries);

  if (!barsData.length)
    return (
      <div className={styles.empty}>
        <p>{t('analyze.testReliability.chart.empty')}</p>
      </div>
    );

  return (
    <div style={{ height: 300 }}>
      <ResponsiveBar
        data={barsData}
        keys={['flakies']}
        indexBy="date"
        margin={{ top: 24, right: 40, bottom: 24, left: 40 }}
        padding={0.5}
        enableGridY={false}
        colors={[vars.warningMain]}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        theme={{
          background: 'transparent',
          textColor: vars.textSecondary,
          fontSize: 12,
          tooltip: {
            container: { background: vars.primaryDark },
          },
        }}
      />
    </div>
  );
}

type Bar = { date: string; flakies: number };

function chartFlakyData(flakyTimeSeries: ReliabilityTimeSeries[]): Bar[] {
  return flakyTimeSeries.map((series) => {
    const date = format(new Date(series.updated_at), 'MM-dd-yyyy');
    const flakies = series.total;

    return { date, flakies };
  });
}

export default React.memo(FlakinessChart);
