import React, { ComponentProps } from 'react';
import CommandBar from '../CommandBar';

type ComandBarProps = ComponentProps<typeof CommandBar>;
type Commands = ComandBarProps['commands'];

type Props = Pick<ComandBarProps, 'onClose' | 'open'> & {
  handleCSVExport: () => void;
  handleXLSExport: () => void;
  handleGherkinExport: () => void;
};

export default function CommandBarRunExport({
  handleCSVExport,
  handleXLSExport,
  handleGherkinExport,
  onClose,
  open,
}: Props) {
  const commands: Commands = [
    {
      command: 'csv',
      key: 'csvExport',
      handler: handleCSVExport,
      icon: <span />,
      label: 'CSV',
    },
    {
      command: 'excel',
      key: 'xlsExport',
      handler: handleXLSExport,
      icon: <span />,
      label: 'Excel',
    },
    {
      command: 'gherkin',
      key: 'gherkinExport',
      handler: handleGherkinExport,
      icon: <span />,
      label: 'Gherkin',
    },
  ];

  return (
    <CommandBar commands={commands} id="export" open={open} onClose={onClose} />
  );
}
