/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { PlanSuiteApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';
import { runSuiteNormalizer } from '../run_suite/runSuiteNormalize';

export const planSuiteNormalizer = (
  model: PlanSuiteApi,
  cache: NormalizerCache,
) => {
  if (!cache.planSuite) {
    cache.planSuite = [];
  }
  cache.planSuite.push(model);

  if (model.run_suite && model.run_suite.length) {
    model.run_suite.forEach((child) => {
      runSuiteNormalizer(child, cache);
    });
    model.run_suite = undefined;
  }
};
