import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { getEnv } from 'src/env';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { useAppSelector } from '@bitmodern/redux/store';
import { TrackerEvents } from './events';

export default function Tracker() {
  const user = useAppSelector(currentUserSelector);
  const userId = user?.id;
  const trialEndsAt = user?.trial_ends_at;

  const trialIsActive = useMemo(() => {
    if (!trialEndsAt) return false;
    return new Date(trialEndsAt).getTime() > new Date().getTime();
  }, [trialEndsAt]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: getEnv().tagManager,
    });
  }, []);

  useEffect(() => {
    const hotjarKey = getEnv().hotjar;
    if (hotjarKey && trialIsActive && userId) {
      hotjar.initialize(getEnv().hotjar, 6);
      hotjar.identify(userId.toString(), { trialIsActive });
    }
  }, [userId, trialEndsAt, trialIsActive]);

  return null;
}

export function trackEvent(name: TrackerEvents, data?: any) {
  if (!window?.dataLayer) return;
  window.dataLayer.push({
    event: name,
    ...(data && { data }),
  });
}

export function canTrack() {
  return Boolean(window?.dataLayer);
}
