/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { InvoiceHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { invoiceHistoryAdapter } from './invoiceHistoryAdapter';
import { invoiceHistoryFetchThunk } from './invoiceHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const invoiceHistorySlice = createSlice({
  name: 'invoiceHistory',
  initialState: invoiceHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    invoiceHistoryCleared(state) {
      state.loading = 'idle';
      invoiceHistoryAdapter.removeAll(state);
    },
    invoiceHistoryUpsertMany(
      state,
      action: PayloadAction<InvoiceHistory[] | Record<number, InvoiceHistory>>,
    ) {
      invoiceHistoryAdapter.upsertMany(state, action.payload);
    },
    invoiceHistoryUpsertOne(state, action: PayloadAction<InvoiceHistory>) {
      invoiceHistoryAdapter.upsertOne(state, action.payload);
    },
    invoiceHistoryUpdateOne(
      state,
      action: PayloadAction<Update<InvoiceHistory>>,
    ) {
      invoiceHistoryAdapter.updateOne(state, action.payload);
    },
    invoiceHistoryRemoveOne(state, action: PayloadAction<number>) {
      invoiceHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(invoiceHistoryFetchThunk.pending, handlePending);
    builder.addCase(invoiceHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      invoiceHistoryAdapter.upsertMany(state, action.payload.invoiceHistory);
    });
    builder.addCase(invoiceHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  invoiceHistoryCleared,
  invoiceHistoryUpsertMany,
  invoiceHistoryUpsertOne,
  invoiceHistoryUpdateOne,
  invoiceHistoryRemoveOne,
} = invoiceHistorySlice.actions;

export const invoiceHistoryReducer = invoiceHistorySlice.reducer;
