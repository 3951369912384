import { useOverlayTriggerState } from 'react-stately';
import {
  Integration,
  IntegrationProject,
  IntegrationUser,
  Requirement,
  SuiteTest,
  Test,
  DefectComponent,
  DefectLabel,
  DefectPriority,
  NativeDefectStatus,
  NativeDefectRes,
  DefectUser,
  DefectType,
  RequirementRunAnalysis,
} from '@testquality/sdk';
import React, { useCallback, useMemo, useState } from 'react';
import { walk } from 'react-sortable-tree';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  PanelHeader,
  Select,
  SelectMultiple,
  Spacer,
  Surface,
  Switch,
  Tab,
  Tabs,
  Tooltip,
} from '@bitmodern/bit-ui';
import {
  CancelIcon,
  DeleteIcon,
  EditIcon,
  GithubIcon,
  JiraIcon,
  PlayIcon,
  AddFolderIcon,
  TestCreateIcon,
} from '@bitmodern/bit-ui/icons';
import EasyMEDInlineEdit from '@bitmodern/bit-ui/InlineEdit/EasyMEDInlineEdit';
import InputDirectInlineEdit from '@bitmodern/bit-ui/InlineEdit/InputDirectInlineEdit';
import {
  BaseIntegrationIcon,
  Comments,
  ConfirmDialog,
  EmptyTests,
  Filters,
  RunAndDefectsList,
  SuitesTree,
  UserSelect,
} from 'src/components/organisms';
import { TEST_TYPE } from 'src/components/organisms/TreeBase/treeTypes';
import vars from 'src/export.scss';
import { requirementUpdateOneThunk } from 'src/gen/domain/requirement/requirementThunk';
import useDrawerManager from 'src/hooks/useDrawerManager';
import useModalManager from 'src/hooks/useModalManager';
import useMutation from 'src/hooks/useMutation';
import { useQuery } from 'src/hooks/useQuery';
import { useTranslation } from 'src/i18n/hooks';
import { integrationKey, IntegrationType } from 'src/enums/IntegrationEnums';
import { currentUserSelector } from '@bitmodern/redux/state/authentication/selectors';
import { defectsByRunResultsSelector } from '@bitmodern/redux/state/defects/selectors';
import { toggleFilterAction } from '@bitmodern/redux/state/filters/actions';
import { filtersByTypeSelector } from '@bitmodern/redux/state/filters/selectors';
import { rootPlanSelector } from '@bitmodern/redux/state/plans/selectors';
import { rootPlanSuiteSelector } from '@bitmodern/redux/state/planSuites/selectors';
import { requirementsTestByRequirementSelector } from '@bitmodern/redux/state/requirementTest/selectors';
import { filteredSuitesTreeByRequirements } from '@bitmodern/redux/state/suites/selectors';
import {
  userSelectorById,
  usersSelector,
} from '@bitmodern/redux/state/users/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { toM } from 'src/utils/J2M';
import { useIntegrationCheck } from 'src/hooks/useIntegrationCheck';
import useLocation from 'src/hooks/useLocation';
import { formatUserName, processAttachmentPath } from 'src/utils/fileHelper';
import styles from './RequirementView.module.scss';
import { baseIntegrationByIdSelector } from 'src/packages/redux/state/baseIntegration/selectors';
import OpenInNew from 'src/packages/bit-ui/icons/OpenInNew';
import { useParamSelector } from 'src/packages/redux/hooks';
import { runsByRequirementAnalysis } from 'src/packages/redux/state/requirements/selectors';

enum RequirementTabs {
  Overview,
  Tests,
  Defects,
}

type Payload = {
  assignee?: { name: string | undefined; id?: number };
  comments?: any[];
  description?: string;
  labels?: Array<{ name: string }>;
  priority?: { name: string };
  resolution?: { name: string };
  status?: { name: string };
  summary?: string;
  components?: Array<{ id: string; name: string }>;
};

type Props = {
  className?: string;
  drawer?: boolean;
  onClose?: () => void;
  onDelete: () => Promise<any>;
  requirement: Requirement;
  integration?: Integration;
  isLoading: boolean;
  integrationProject?: IntegrationProject;
  integrationUsers?: IntegrationUser[];
  defectComponents: DefectComponent[];
  defectPriorities: DefectPriority[];
  defectLabels: DefectLabel[];
  defectStatuses: NativeDefectStatus[];
  defectResolutions: NativeDefectRes[];
  defectUsers: DefectUser[];
  defectTypes: DefectType[];
  projectId: string;
  site: string;
  tests: Test[];
  withRunRequirement?: boolean;
  runsAnalysis: RequirementRunAnalysis;
};

export default function RequirementView({
  className = '',
  onClose,
  onDelete,
  requirement,
  integration,
  integrationProject,
  integrationUsers,
  isLoading,
  defectComponents,
  defectLabels,
  defectStatuses,
  defectResolutions,
  defectPriorities,
  defectUsers,
  defectTypes,
  projectId,
  site,
  tests,
  withRunRequirement,
  runsAnalysis,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const users = useAppSelector(usersSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const reporter = useAppSelector((state) =>
    userSelectorById(state, requirement.created_by),
  );
  const reporterName = formatUserName(reporter) || '-';
  const [tab, setTab] = useState(RequirementTabs.Overview);
  const [editingTitle, setEditingTitle] = useState(false);
  const { openDrawer } = useDrawerManager();
  const { showModal } = useModalManager();
  const requirementsFilter = useAppSelector((state) =>
    filtersByTypeSelector(state, 'requirements'),
  );

  const requirementTestsTest = useAppSelector((state) =>
    requirementsTestByRequirementSelector(state, requirement.id),
  );
  const requirementTestsTestId = requirementTestsTest.map((rt) => rt.test_id);

  const baseIntegration = useAppSelector((state) =>
    baseIntegrationByIdSelector(state, {
      id: integration?.base_integration_id,
    }),
  );

  const defects = useAppSelector((state) =>
    defectsByRunResultsSelector(state, runsAnalysis?.run_results_ids),
  );

  // Use runs in store overwritting analysis by the request analysis
  const runs = useParamSelector(runsByRequirementAnalysis, {
    runs: runsAnalysis?.runs,
  });

  const rootPlan = useAppSelector((state) =>
    rootPlanSelector(state, parseInt(projectId, 10)),
  );
  const rootPlanSuite = useParamSelector(rootPlanSuiteSelector, {
    projectId: parseInt(projectId, 10),
  });

  const tree = useAppSelector((state) =>
    filteredSuitesTreeByRequirements(state, {
      projectId: parseInt(projectId, 10),
      requirementId: requirement.id,
      filterType: 'requirements',
    }),
  );

  const { folderId } = useQuery(['folderId']);

  const onClickTest = useCallback(
    (test: Test, suiteTest: SuiteTest) => {
      openDrawer({
        folderId: suiteTest.id,
        testId: test.id,
      });
    },
    [openDrawer],
  );

  const deleteDialog = useOverlayTriggerState({});

  const location = useLocation();
  const { checkIntegrationRedirect } = useIntegrationCheck(
    site,
    parseInt(projectId, 10),
  );

  const onConfirmDelete = useCallback(() => {
    return onDelete().finally(deleteDialog.close);
  }, [deleteDialog, onDelete]);

  const deleteMutation = useMutation(onConfirmDelete);

  const isJira =
    integration && integrationKey(integration) === IntegrationType.JIRA;

  const getPayload = () => {
    return JSON.parse(JSON.stringify(requirement.payload)) as Payload;
  };

  const onChangePriority = (value) => {
    const payload = getPayload();
    updateRequirement({
      payload: {
        ...payload,
        priority: {
          id: value,
          name: defectPriorities.find(({ id }) => id === value)?.name,
        },
      },
    });
  };

  const onChangeDefectStatus = (value) => {
    const payload = getPayload();
    const status = defectStatuses.find(({ id }) => id === value);

    updateRequirement({
      payload: {
        ...payload,
        status,
      },
    });
  };

  const onChangeDefectResolution = (value) => {
    const payload = getPayload();
    const resolution = defectResolutions.find(({ id }) => id === value);

    updateRequirement({
      payload: {
        ...payload,
        resolution,
      },
    });
  };

  const onChangeAssignedTo = (value) => {
    const payload = getPayload();

    updateRequirement({
      payload: {
        ...payload,
        assignee: defectUsers.find(({ id }) => id === value),
      },
    });
  };

  const onChangeType = (value) => {
    const payload = getPayload();

    updateRequirement({
      payload: {
        ...payload,
        type: defectTypes.find(({ id }) => id === value),
      },
    });
  };

  const onChangeLabels = (values: string[]) => {
    const payload = getPayload();

    updateRequirement({
      payload: {
        ...payload,
        labels: values.map((labelName) => ({
          name: labelName,
          id: defectLabels.find(({ name }) => name === labelName)?.id,
        })),
      },
    });
  };

  const onChangeComponent = (values) => {
    const payload = getPayload();
    updateRequirement({
      payload: {
        ...payload,
        components: values.map((id) => ({
          id,
          name: defectComponents.find(({ id: dId }) => dId === id)?.name,
        })),
      },
    });
  };

  const onCommitDescription = (value) => {
    const payload = getPayload();
    payload.description = value; // isJira ? toJ(value) : value;
    return updateRequirement({ payload });
  };

  const onComment = (value) => {
    // const desc = isJira ? toJ(value) : value;
    const payload = getPayload();
    const integrationUser = integrationUsers?.find(
      (iu) => iu.user_id === currentUser?.id,
    );
    const userName = integrationUser?.username;
    let newComment;
    if (!isJira) {
      newComment = {
        body: value,
        created_at: `${new Date().toISOString().slice(0, -5)}'Z'`,
        user: userName,
      };
    } else {
      newComment = {
        body: value,
        user: userName,
      };
    }
    if (payload?.comments) {
      payload.comments.push(newComment);
    }
    updateRequirement({ payload });
  };

  const onChangeSummary = (value) => {
    const payload = getPayload();
    payload.summary = value;
    setEditingTitle(false);
    return updateRequirement({ payload });
  };

  const onClickCreateTest = () => {
    showModal({
      modalName: 'testCreate',
      modalProps: {
        planSuite: rootPlanSuite,
        planId: rootPlan?.id,
        requirementId: requirement?.id,
      },
      type: 'modal',
    });
  };

  const onClickCreateFolder = () => {
    showModal({
      modalName: 'suiteCreate',
      modalProps: { planSuite: rootPlanSuite, planId: rootPlan?.id },
      type: 'modal',
    });
  };

  const priorityOptions = defectPriorities.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const statusOptions = defectStatuses.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const resolutionOptions = defectResolutions.map((value) => ({
    label: value.name,
    value: value.id,
  }));

  const labelsOptions = defectLabels.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const componentOptions = defectComponents.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const componentsValues = useMemo(() => {
    if (isLoading) return [];

    return requirement.payload?.components?.map(({ id }) => id).sort();
  }, [requirement, isLoading]);

  const assignedToId = integrationUsers?.find(
    (iu) => iu.username === requirement.payload?.assignee?.name,
  )?.user_id;

  const typeValue = requirement.payload?.type?.id;

  const statusValue = defectStatuses.find(
    ({ name }) => name === requirement.payload?.status?.name,
  )?.id;

  const resolutionValue = defectResolutions.find(
    ({ name }) => name === requirement.payload?.resolution?.name,
  )?.id;

  const priorityValue = useMemo(() => {
    return defectPriorities.find(
      ({ name }) => name === requirement.payload?.priority?.name,
    )?.id;
  }, [defectPriorities, requirement]);

  // TODO: When setting a priority on an issue within GH integration,
  // labels come as an object instead of an array. Let's  see if we can fix this.
  const labelsValues = useMemo(() => {
    const labels = requirement.payload?.labels || [];
    const normalizedLabels = Array.isArray(labels)
      ? labels
      : Object.values(labels);

    return normalizedLabels.map(({ name }) => name).sort();
  }, [requirement]);

  const userOptions = defectUsers.map(({ id, name, email, avatar_url }) => ({
    label: name || email,
    value: id,
    picture: avatar_url,
  }));

  const typeOptions = defectTypes.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const updateRequirement = (value: Partial<Requirement>): Promise<void> => {
    return new Promise((resolve, reject) => {
      return checkIntegrationRedirect(
        location.pathname,
        location.search,
        location.state,
      ).then(() => {
        return dispatch(
          requirementUpdateOneThunk({
            data: value,
            id: requirement.id,
            optimistic: true,
          }),
        ).then(() => resolve(), reject);
      }, reject);
    });
  };

  const handleOpenDefectIntegration = () => {
    window.open(
      `${integrationProject?.app_url}${requirement.external_reference_id}`,
      '_blank',
    );
  };

  const onClickToggleHide = () => {
    dispatch(
      toggleFilterAction({
        type: 'requirements',
        filter: 'exclusions',
        value: !requirementsFilter?.exclusions,
      }),
    );
  };

  const onStartRun = () => {
    const includedSuitesIds: number[] = [];
    walk({
      treeData: tree,
      getNodeKey: (item) => item.node.nodeKey,
      callback: (item) => {
        if (
          item.node.type === TEST_TYPE &&
          requirementTestsTestId.includes(item.node.data.test.id)
        ) {
          includedSuitesIds.push(item.node.data.suiteTest.id);
        }
      },
      ignoreCollapsed: false,
    });

    showModal({
      modalName: 'startRun',
      modalProps: { suiteTestsIds: includedSuitesIds },
      type: 'modal',
    });
  };

  return (
    <Surface className={`${styles.requirement} ${className}`}>
      <PanelHeader
        title={
          !editingTitle ? (
            requirement.payload?.summary
          ) : (
            <InputDirectInlineEdit
              className={styles.summary}
              showLabel={false}
              onCommit={onChangeSummary}
              value={requirement.payload?.summary}
            />
          )
        }
        actions={
          <Spacer>
            <>
              {tab === RequirementTabs.Tests && (
                <>
                  <Filters type="requirements" />
                  <Tooltip
                    tooltip={
                      requirementsFilter?.exclusions
                        ? t('requirement.actions.hideTests')
                        : t('requirement.actions.showTests')
                    }>
                    <span className={styles.actionSwitch}>
                      <Switch
                        checked={!requirementsFilter?.exclusions}
                        onChange={onClickToggleHide}
                      />
                    </span>
                  </Tooltip>
                  <IconButton
                    onClick={onClickCreateFolder}
                    title={t('tests.actions.createFolder')}>
                    <AddFolderIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                  <IconButton
                    onClick={onClickCreateTest}
                    title={t('tests.actions.createTest')}>
                    <TestCreateIcon color={vars.textPrimary} size={18} />
                  </IconButton>
                </>
              )}
              <IconButton
                title={t('defect.actions.openIntegration', {
                  integrationName: integration?.name || '',
                })}
                onClick={handleOpenDefectIntegration}>
                {isJira ? (
                  <JiraIcon color={vars.textPrimary} size={18} />
                ) : (
                  <GithubIcon color={vars.textPrimary} size={18} />
                )}
              </IconButton>
              <IconButton
                onClick={() => setEditingTitle(!editingTitle)}
                title={t('requirement.actions.edit')}>
                <EditIcon color={vars.textPrimary} size={18} />
              </IconButton>
              <IconButton
                onClick={deleteDialog.open}
                title={t('requirement.actions.delete')}>
                <DeleteIcon color={vars.textPrimary} size={18} />
              </IconButton>
              {withRunRequirement && (
                <Button
                  icon={<PlayIcon color={vars.onAccent} />}
                  onClick={onStartRun}
                  size="small">
                  {t('requirement.runRequirement')}
                </Button>
              )}
              {onClose && (
                <IconButton
                  boxed={false}
                  onClick={onClose}
                  title={t('requirement.actions.close')}>
                  <CancelIcon color={vars.textPrimary} size={22} />
                </IconButton>
              )}
            </>
          </Spacer>
        }
      />
      <Tabs tab={tab} onChange={setTab} withBarBackground>
        <Tab id={RequirementTabs.Overview}>
          {t('requirement.tabs.overview')}
        </Tab>
        <Tab id={RequirementTabs.Tests}>{t('requirement.tabs.tests')}</Tab>
        <Tab id={RequirementTabs.Defects}>
          {t('requirement.tabs.runsAndDefects')}
        </Tab>
      </Tabs>
      <div className={styles.panelContent}>
        {tab === RequirementTabs.Overview && (
          <div className={styles.overview}>
            <div className={styles.section}>
              {integrationProject && (
                <Grid.Row>
                  <Grid.Col span={12}>
                    <div
                      className={styles.integrationDisplay}
                      onClick={handleOpenDefectIntegration}>
                      <div className={styles.integration}>
                        <BaseIntegrationIcon
                          baseIntegration={baseIntegration}
                          color={vars.textPrimary}
                          size={18}
                        />
                        <div className={styles.integrationText}>
                          {t('requirement.integrationText', {
                            org: integrationProject?.org,
                            name: integrationProject?.project_reference_id,
                          })}
                        </div>
                      </div>
                      <OpenInNew
                        size={18}
                        className={styles.integrationLinkIcon}
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              )}
              <h3 className={styles.title}>{t('requirement.description')}</h3>
              <EasyMEDInlineEdit
                id="requirement-description"
                onCommit={onCommitDescription}
                parentId={requirement.related_id!}
                parentType="Test"
                preview={
                  isJira
                    ? toM(requirement.payload?.description || '')
                    : requirement.payload?.description
                }
                value={requirement.payload?.description}
                withFormatting
              />
              <Grid.Row>
                <Grid.Col span={7}>
                  <Select
                    label={t('requirement.priority')}
                    onChange={onChangePriority}
                    options={priorityOptions}
                    placeholder={t('requirement.priorityPlaceholder')}
                    value={priorityValue}
                  />
                  {isJira && (
                    <>
                      <SelectMultiple
                        label={t('requirement.component')}
                        onChange={onChangeComponent}
                        options={componentOptions}
                        placeholder={t('requirement.componentPlaceholder')}
                        loading={isLoading}
                        values={componentsValues}
                      />
                      <Select
                        label={t('requirement.type')}
                        onChange={onChangeType}
                        options={typeOptions}
                        loading={isLoading}
                        placeholder={t('requirement.typePlaceholder')}
                        value={typeValue}
                      />
                    </>
                  )}
                  <SelectMultiple
                    label={t('requirement.label')}
                    onChange={onChangeLabels}
                    options={labelsOptions}
                    placeholder={t('requirement.labelsPlaceholder')}
                    tags
                    values={labelsValues}
                  />
                </Grid.Col>
                <Grid.Col span={5}>
                  <Select
                    label={t('requirement.status')}
                    onChange={onChangeDefectStatus}
                    options={statusOptions}
                    placeholder={t('requirement.statusPlaceholder')}
                    value={statusValue}
                  />
                  <Select
                    label={t('requirement.resolution')}
                    onChange={onChangeDefectResolution}
                    options={resolutionOptions}
                    placeholder={t('requirement.resolutionPlaceholder')}
                    value={resolutionValue}
                  />
                  <UserSelect
                    label={t('requirement.assignee')}
                    onChange={onChangeAssignedTo}
                    options={userOptions}
                    placeholder={t('requirement.assigneePlaceholder')}
                    value={assignedToId}
                  />
                  <div className={styles.reporter}>
                    <h4 className={styles.title}>
                      {t('requirement.reporter')}
                    </h4>
                    <div className={styles.reporterData}>
                      <Avatar
                        className={styles.avatar}
                        size={24}
                        src={processAttachmentPath(reporter?.picture)}
                      />
                      {reporterName}
                    </div>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </div>
            <div className={styles.section}>
              <h3 className={`${styles.title} ${styles.commentsTitle}`}>
                {t('requirement.activity')}
              </h3>
              <Comments
                comments={requirement.payload?.comments}
                isJira={isJira}
                integrationUsers={integrationUsers}
                users={users}
                parentId={requirement.id}
                parentType="Requirement"
                onComment={onComment}
              />
            </div>
          </div>
        )}
        {tab === RequirementTabs.Tests && (
          <div className={styles.tests}>
            {tree.length === 0 || !rootPlan ? (
              <EmptyTests
                filterType="requirements"
                potentialAmount={tests.length}
              />
            ) : (
              <SuitesTree
                canDrag={false}
                current={parseInt(folderId, 10)}
                id={rootPlan.id.toString()}
                onClickTest={onClickTest}
                plan={rootPlan}
                suitesTree={tree}
                withInclude={false}
                includeRequirement
                requirementTests={requirementTestsTest}
                requirement={requirement}
              />
            )}
          </div>
        )}
        {tab === RequirementTabs.Defects && (
          <RunAndDefectsList
            runs={runs}
            defects={defects}
            loading={isLoading}
          />
        )}
      </div>
      <ConfirmDialog
        loading={deleteMutation.isLoading}
        onCancel={deleteDialog.close}
        onConfirm={deleteMutation.mutate}
        open={deleteDialog.isOpen}
        title={t('requirement.deleteDialog.title')}>
        {t('requirement.deleteDialog.content')}
      </ConfirmDialog>
    </Surface>
  );
}
