/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import {
  createSlice,
  PayloadAction,
  SerializedError,
  Update,
} from '@reduxjs/toolkit';
import { TestQualityHistory } from '@testquality/sdk';
import { TQStateProperties } from '../../store/TQState';
import { testQualityHistoryAdapter } from './testQualityHistoryAdapter';
import { testQualityHistoryFetchThunk } from './testQualityHistoryThunk';

const handleRejected = (
  state: TQStateProperties,
  action: { error: SerializedError },
) => {
  state.loading = 'idle';
  state.error = action.error;
};

const handlePending = (state: TQStateProperties) => {
  if (state.loading === 'idle') {
    state.loading = 'pending';
  }
};

const testQualityHistorySlice = createSlice({
  name: 'testQualityHistory',
  initialState: testQualityHistoryAdapter.getInitialState<TQStateProperties>({
    loading: 'idle',
    error: undefined,
  }),
  reducers: {
    testQualityHistoryCleared(state) {
      state.loading = 'idle';
      testQualityHistoryAdapter.removeAll(state);
    },
    testQualityHistoryUpsertMany(
      state,
      action: PayloadAction<
        TestQualityHistory[] | Record<number, TestQualityHistory>
      >,
    ) {
      testQualityHistoryAdapter.upsertMany(state, action.payload);
    },
    testQualityHistoryUpsertOne(
      state,
      action: PayloadAction<TestQualityHistory>,
    ) {
      testQualityHistoryAdapter.upsertOne(state, action.payload);
    },
    testQualityHistoryUpdateOne(
      state,
      action: PayloadAction<Update<TestQualityHistory>>,
    ) {
      testQualityHistoryAdapter.updateOne(state, action.payload);
    },
    testQualityHistoryRemoveOne(state, action: PayloadAction<number>) {
      testQualityHistoryAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(testQualityHistoryFetchThunk.pending, handlePending);
    builder.addCase(testQualityHistoryFetchThunk.fulfilled, (state, action) => {
      state.loading = 'idle';
      testQualityHistoryAdapter.upsertMany(
        state,
        action.payload.testQualityHistory,
      );
    });
    builder.addCase(testQualityHistoryFetchThunk.rejected, handleRejected);
  },
});

export const {
  testQualityHistoryCleared,
  testQualityHistoryUpsertMany,
  testQualityHistoryUpsertOne,
  testQualityHistoryUpdateOne,
  testQualityHistoryRemoveOne,
} = testQualityHistorySlice.actions;

export const testQualityHistoryReducer = testQualityHistorySlice.reducer;
