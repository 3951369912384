import React from 'react';
import { SignupOption } from '@testquality/sdk';
import { useOverlayTriggerState } from 'react-stately';
import { Button, Table, TableBody } from '@bitmodern/bit-ui';
import { SignUpOptionDialog } from 'src/components/organisms';
import { useAppDispatch } from 'src/packages/redux/store';
import {
  signupOptionCreateOneThunk,
  signupOptionDeleteOneThunk,
} from 'src/gen/domain/signup_option/signupOptionThunk';
import useParams from 'src/hooks/useParams';
import { routes } from 'src/components/Router';
import { SignupOptionItem } from '../SignupOptionItem';
import styles from './AutomaticSignUp.module.scss';

export type AutomaticSignUpProps = {
  signupOptions: SignupOption[];
};

export function AutomaticSignUp({ signupOptions }: AutomaticSignUpProps) {
  const dispatch = useAppDispatch();
  const addSignUpTypeDialog = useOverlayTriggerState({});
  const { site } = useParams<typeof routes.COMPANY.params>();

  const onDeleteOption = (signupOption: SignupOption) => {
    return dispatch(signupOptionDeleteOneThunk({ id: signupOption.id }));
  };

  const onAdd = (data) => {
    return dispatch(
      signupOptionCreateOneThunk({
        data: {
          name: data.name,
          signup_type: data.signup_type,
        },
      }),
    ).then(addSignUpTypeDialog.close);
  };

  return (
    <div className={styles.signup}>
      <div className={styles.head}>
        <div className={styles.title}>Automatic Sign up</div>
        <Button
          onClick={addSignUpTypeDialog.open}
          color="primaryLight"
          size="small">
          Add Sign up
        </Button>
      </div>
      <Table rounded outlined>
        <TableBody>
          {signupOptions.map((signupOption) => (
            <SignupOptionItem
              key={signupOption.id}
              onDelete={onDeleteOption}
              signupOption={signupOption}
            />
          ))}
        </TableBody>
      </Table>

      <SignUpOptionDialog
        site={site}
        isOpen={addSignUpTypeDialog.isOpen}
        onClose={addSignUpTypeDialog.close}
        onSubmit={onAdd}
      />
    </div>
  );
}
