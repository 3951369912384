/**
 * Copyright (C) 2022 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  HttpError,
  showNotificationError,
  LookupIntegrationParams,
  defectLabelGetMany,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';

export const defectLabelFetchManyThunk = createAsyncThunk(
  'defectLabel/FetchManyThunk',
  async (
    { handleError = true, ...arg }: ThunkArgs<LookupIntegrationParams>,
    { signal },
  ) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const response = await defectLabelGetMany(inlineArgs);
      return {
        defectLabel: response || [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
