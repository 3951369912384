import React, { ComponentProps } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import Yup from 'src/utils/yup';
import {
  Button,
  Checkbox,
  Grid,
  Input,
  InputCardStripe,
  InputNumber,
  Select,
  Spacer,
} from '@bitmodern/bit-ui';
import { useTranslation } from 'src/i18n/hooks';
import { productByBilledSelector } from '@bitmodern/redux/state/products/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { formikError } from 'src/utils/formik';
import { subscribeThunk } from '@bitmodern/redux/state/subscriptions/thunks';
import { themeColorSelector } from '@bitmodern/redux/state/theme/selectors';
import { SubscriptionIntervalEnum } from 'src/enums/SubscriptionIntervalEnum';
import styles from './CommercialSubscription.module.scss';

type InitialValues = {
  card: Parameters<ComponentProps<typeof InputCardStripe>['onChange']>[0];
  cardholderName: string;
  seats: number;
  billed: SubscriptionIntervalEnum;
  isAutoUpgrade: boolean;
};

const initialValues: InitialValues = {
  card: null,
  cardholderName: '',
  seats: 3,
  billed: SubscriptionIntervalEnum.Monthly,
  isAutoUpgrade: true,
};

const validationSchema = Yup.object().shape({
  card: Yup.mixed().required().label('comertialSub.form.card'),
  cardholderName: Yup.string()
    .required()
    .label('comertialSub.form.cardholderName'),
  seats: Yup.number().required().positive().label('comertialSub.form.seats'),
});

type Props = {
  className?: string;
};

export default function CommercialSubscription({ className }: Props) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const formik = useFormik({ onSubmit, initialValues, validationSchema });

  const themeColor = useAppSelector(themeColorSelector);

  const product = useAppSelector((state) =>
    productByBilledSelector(state, formik.values.billed),
  );

  async function onSubmit({ card, seats, isAutoUpgrade }) {
    if (!stripe || !card || !product) return;
    const { token } = await stripe.createToken(card, {});
    await dispatch(subscribeThunk(product.id, seats, isAutoUpgrade, token));
  }

  const onChangeCard = (card) => formik.setFieldValue('card', card);

  const onChangeSeats = (n) => formik.setFieldValue('seats', n);

  const onChangeBilled = (n) => formik.setFieldValue('billed', n);

  const onChangeAutoUpgrade = (n) => formik.setFieldValue('isAutoUpgrade', n);

  const pricePerUser =
    parseFloat(product?.amount.toString() || '0') / (product?.user_limit || 1);
  const price = pricePerUser * formik.values.seats;

  const monthlyPrice =
    formik.values.billed === SubscriptionIntervalEnum.Yearly
      ? pricePerUser / 12
      : pricePerUser;

  return (
    <form className={className} onSubmit={formik.handleSubmit}>
      <div className={styles.priceSection}>
        <div className={styles.priceTitle}>
          {t('billing.cardForm.priceTitle')}
        </div>
        <div>
          <div className={styles.price}>
            {t('billing.cardForm.price', { value: price })}
          </div>
          <div className={styles.userPerMonth}>
            {t('billing.cardForm.userMonth', { value: monthlyPrice })}
          </div>
        </div>
      </div>
      <Grid.Row>
        <Grid.Col span={4}>
          <InputNumber
            error={formikError(formik, 'seats')}
            fullWidth
            label={t('comertialSub.form.seats')}
            name="seats"
            onChange={onChangeSeats}
            onFocus={formik.handleBlur}
            required
            value={formik.values.seats}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Select
            label={t('comertialSub.form.interval')}
            onChange={onChangeBilled}
            options={[
              { label: 'Monthly', value: SubscriptionIntervalEnum.Monthly },
              { label: 'Yearly', value: SubscriptionIntervalEnum.Yearly },
            ]}
            value={formik.values.billed}
          />
        </Grid.Col>
      </Grid.Row>

      <Input
        error={formikError(formik, 'cardholderName')}
        fullWidth
        label={t('comertialSub.form.cardholderName')}
        name="cardholderName"
        onChange={formik.handleChange}
        onFocus={formik.handleBlur}
        required
        value={formik.values.cardholderName}
      />
      <InputCardStripe
        label={t('comertialSub.form.card')}
        onChange={onChangeCard}
        required
        theme={themeColor}
      />
      <Checkbox
        checked={formik.values.isAutoUpgrade}
        name="isAutoUpgrade"
        className={styles.autoUpgrade}
        onChange={onChangeAutoUpgrade}>
        {t('comertialSub.form.autoUpgrade')}
      </Checkbox>
      <Spacer className={styles.submit}>
        <Button disabled={!stripe} loading={formik.isSubmitting} type="submit">
          {t('comertialSub.form.submit')}
        </Button>
      </Spacer>
    </form>
  );
}
