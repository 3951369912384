import Debug from 'debug';
import {
  AccessRole,
  BatchService,
  Policy,
  PolicyRows,
  restrictProject,
  showNotificationError,
  unrestrictProject,
} from '@testquality/sdk';
import {
  RoleFormDetails,
  PermissionBreakdown,
  allProjectsOption,
} from 'src/components/organisms/RoleForm/RoleForm';
import { Permission, permissionTypes } from 'src/enums/PermissionsEnum';
import { policyUpdateOneThunk } from 'src/gen/domain/policy/policyThunk';
import { AppThunk } from '@bitmodern/redux/store';
import { policyRowsByPolilicyIds } from '../policyRows/selectors';
import { createPoliciesByRoleIdSelector } from './selectors';
import { accessRoleFetchOneThunk } from 'src/gen/domain/access_role/accessRoleThunk';
import { policyRowsRemoveOne } from 'src/gen/domain/policy_rows/policyRowsSlice';

const debug = Debug('upsertAccessRolePolicies');

const PROJECT_POLICY_ROW = 'project_id';

export function upsertAccessRolePoliciesThunk(
  formValues: RoleFormDetails,
  accessRole: AccessRole,
  policies?: Policy[],
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const rolePolicies = createPoliciesByRoleIdSelector(accessRole?.id)(
      getState(),
    );
    const associatedPolicies = policies || rolePolicies;
    const policyRows = policyRowsByPolilicyIds(getState(), {
      policiesIds: associatedPolicies.map((p) => p.id),
    });
    const currentAllProjects = associatedPolicies.every((p) => p.all_rows);

    const isAllProjects =
      formValues.projects.length === 1 &&
      formValues.projects.includes(allProjectsOption.value);

    const allProjectsChanged = currentAllProjects !== isAllProjects;

    const pids = policyRows.reduce<number[]>((ids, pr) => {
      if (pr.column_name === PROJECT_POLICY_ROW) ids.push(pr.column_value);
      return ids;
    }, []);
    const currentProjects = Array.from(new Set(pids));

    const policiesUpdates: { [id: number]: Partial<Policy> } = {};

    // Update policies
    associatedPolicies.forEach((policy) => {
      const permission = getPermission(policy);
      const permissionValues = formValues[permission || ''];
      if (!permissionValues) return;

      if (isPolicyChange(policy, permissionValues)) {
        addPolicyUpdate(policiesUpdates, policy.id, {
          ...setView(permissionValues.view),
          ...setEdit(permissionValues.edit),
        });
      }
    });
    const batch = new BatchService();

    // Update projectRestrictions
    if (isAllProjects && allProjectsChanged) {
      // remove all project restrictions
      await dispatch(unrestrictProjectsThunk(accessRole.id, [], policyRows));
    } else {
      const nextProjects = formValues.projects;
      const newProjects = nextProjects.filter(
        (p) => !currentProjects.includes(p),
      );
      const projectsRemoved = currentProjects.filter(
        (p) => !nextProjects.includes(p),
      );
      debug('newProjects', newProjects);
      debug('projectsRemoved', projectsRemoved);

      if (newProjects.length) {
        restrictProject(
          { access_role_id: accessRole.id, project_id: newProjects },
          { batch },
        ).catch(showNotificationError);
      }
      if (projectsRemoved.length) {
        await dispatch(
          unrestrictProjectsThunk(accessRole.id, projectsRemoved, policyRows),
        );
      }
    }

    Object.entries(policiesUpdates).forEach(([id, data]) => {
      dispatch(policyUpdateOneThunk({ id, data, batch }));
    });

    await batch.executeBatch();

    // get all new Policy policyRows
    await dispatch(
      accessRoleFetchOneThunk({
        id: accessRole.id,
        params: { _with: 'policy,policy.policyRows' },
      }),
    );
  };
}

function unrestrictProjectsThunk(
  accessRoleId: number,
  projectsId: number[],
  policyRows: PolicyRows[],
) {
  return (dispatch) => {
    return unrestrictProject({
      access_role_id: accessRoleId,
      project_id: projectsId,
    })
      .then(() => {
        const rows =
          projectsId.length === 0
            ? policyRows
            : policyRows.filter((row) => projectsId.includes(row.column_value));
        rows.forEach((row) => dispatch(policyRowsRemoveOne(row.id)));
      })
      .catch(showNotificationError);
  };
}

type PolicyUpdates = { [id: number]: Partial<Policy> };

function addPolicyUpdate(
  policiesUpdates: PolicyUpdates,
  id: number,
  fields: Partial<Policy>,
) {
  if (policiesUpdates[id]) {
    policiesUpdates[id] = { ...policiesUpdates[id], ...fields };
  } else {
    policiesUpdates[id] = fields;
  }
}

function getPermission(policy: Policy): Permission | undefined {
  const permissionType = Object.entries(permissionTypes).find(
    ([_, policyNames]) => {
      return policyNames.includes(policy.name);
    },
  );

  return permissionType?.[0] as Permission | undefined;
}

const setView = (value: boolean) => ({
  can_view: value,
  can_list: value,
});

const setEdit = (value: boolean) => ({
  can_close: value,
  can_create: value,
  can_delete: value,
  can_edit: value,
  can_execute: value,
});

/* NOTE: Strict checks, every permission should be checked */
const canView = (policy: Policy) => {
  if (policy.can_view && policy.can_list) return true;
  return false;
};

const canEdit = (policy: Policy) => {
  if (
    policy.can_close &&
    policy.can_create &&
    policy.can_delete &&
    policy.can_edit &&
    policy.can_execute
  ) {
    return true;
  }
  return false;
};

function isPolicyChange(
  associatedPolicy: Policy,
  newValues: PermissionBreakdown,
) {
  if (newValues.view !== canView(associatedPolicy)) return true;
  if (newValues.edit !== canEdit(associatedPolicy)) return true;
  return false;
}
