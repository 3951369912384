import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, Input, Keyboard } from '@bitmodern/bit-ui';
import { keyMap } from 'src/enums/HotkeysEnum';
import useModalManager from 'src/hooks/useModalManager';
import { useTranslation } from 'src/i18n/hooks';
import { renderKey } from 'src/utils/hotkeys';
import styles from './HotkeysDialog.module.scss';

export default function HotkeysDialog() {
  const [search, setSearch] = useState('');
  const { hideModal } = useModalManager();
  const { t } = useTranslation();

  const hotkeys = useMemo(() => {
    return (Object.entries(keyMap) as Array<[keyof typeof keyMap, string[]]>)
      .map(([hotkey, keys]) => ({
        keys,
        hotkey,
        display: t(`hotkeys.keys.${hotkey}`),
      }))
      .filter(({ display }) =>
        display.toLowerCase().includes(search.toLowerCase()),
      );
  }, [t, search]);

  const onChangeSearch = (event) => setSearch(event.target.value);

  return (
    <Dialog
      animatePresence={false}
      onClose={hideModal}
      title={t('hotkeys.title')}>
      <DialogContent className={styles.content}>
        <Input
          className={styles.search}
          fullWidth
          onChange={onChangeSearch}
          placeholder={t('hotkeys.searchPlaceholder')}
          value={search}
        />
        {hotkeys.map(({ hotkey, keys, display }) => (
          <div className={styles.hotkey} key={hotkey}>
            <div className={styles.name}>{display}</div>
            <Keyboard
              className={styles.key}
              keys={keys.map((key) => renderKey(key))}
            />
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
}
