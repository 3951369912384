import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './ErrorDisplay.module.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

export default function ErrorDisplay({ children, className }: Props) {
  const CN = classnames(styles.error, className);

  return <div className={CN}>{children}</div>;
}
