/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { State } from '@bitmodern/redux/store';
import { checkRunAdapter } from './checkRunAdapter';

export const checkRunSelectors = checkRunAdapter.getSelectors(
  (state: State) => state.gen.checkRun,
);
