/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  showNotificationError,
  HttpError,
  Defect,
  defectGetMany,
  defectGetOne,
  defectDeleteOne,
  defectUpdateOne,
  defectCreateOne,
} from '@testquality/sdk';
import { ThunkArgs } from '../../actions/ThunkArgs';
import { ThunkError } from '../../actions/ThunkError';
import { defectNormalizer } from './defectNormalize';
import { executeNormalizer } from '../../actions/executeNormalizer';

export const defectFetchManyThunk = createAsyncThunk(
  'defect/FetchManyThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Defect>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const inlineArgs = arg || {};
      inlineArgs.cancelToken = source.token;
      const resourceList = await defectGetMany(inlineArgs);
      if (resourceList.data && resourceList.data.length) {
        return executeNormalizer(resourceList.data, defectNormalizer);
      }
      return {
        defect: [],
      };
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectFetchOneThunk = createAsyncThunk(
  'defect/FetchOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Defect>, { signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'defect/FetchOneThunk');
      }
      arg.cancelToken = source.token;
      const data = await defectGetOne(arg.id as number, arg);
      return executeNormalizer([data], defectNormalizer);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectDeleteOneThunk = createAsyncThunk(
  'defect/DeleteOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Defect>) => {
    try {
      if (!arg.id) {
        throw new ThunkError('Must supply an id', 'defect/DeleteOneThunk');
      }
      return await defectDeleteOne(arg.id as number, arg).then(() => arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectUpdateOneThunk = createAsyncThunk(
  'defect/UpdateOneThunk',
  async ({
    handleError = true,
    refreshData = false,
    ...arg
  }: ThunkArgs<Defect>) => {
    try {
      if (!arg.id || !arg.data) {
        throw new ThunkError('Must supply an id', 'defect/UpdateOneThunk');
      }
      const data = await defectUpdateOne(arg.id as number, arg.data, arg);
      if (refreshData) {
        return {
          id: data.id,
          changes: data,
        } as Update<Defect>;
      }
      return {
        id: arg.id as number,
        changes: {
          updated_by: data.updated_by,
          updated_at: data.updated_at,
          epoch: data.epoch,
          ...arg.data,
        },
      } as Update<Defect>;
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);

export const defectCreateOneThunk = createAsyncThunk(
  'defect/CreateOneThunk',
  async ({ handleError = true, ...arg }: ThunkArgs<Defect>) => {
    try {
      if (!arg.data) {
        throw new ThunkError(
          'Must supply data to insert',
          'defect/CreateOneThunk',
        );
      }
      return await defectCreateOne(arg.data, arg);
    } catch (error) {
      if (handleError) showNotificationError(error as HttpError);
      throw error;
    }
  },
);
