/**
 * Copyright (C) 2021 BitModern, Inc - All Rights Reserved
 */

/* eslint-disable import/no-cycle, import/no-duplicates */
import { DefectStatusNativeDefectStatusApi } from '@testquality/sdk';
import { NormalizerCache } from '../../actions/NormalizeCache';

export const defectStatusNativeDefectStatusNormalizer = (
  model: DefectStatusNativeDefectStatusApi,
  cache: NormalizerCache,
) => {
  if (!cache.defectStatusNativeDefectStatus) {
    cache.defectStatusNativeDefectStatus = [];
  }
  cache.defectStatusNativeDefectStatus.push(model);
};
