import { DataSet, Step, Test } from '@testquality/sdk';
import { DASET_ID_KEY } from '../../dataSets/selectors';
import { GherkinScenario } from '../types';

export default function createScenario(
  test: Test,
  steps: Step[],
  testLabels: string[],
  dataSet?: DataSet,
): GherkinScenario {
  const examples = createExamplesFromDataSet(dataSet);

  return {
    given: test.precondition,
    tags: testLabels ?? [],
    description: createScenarioDescription(test.name ?? ''),
    examples: examples ?? null,
    steps: steps.map((item) => ({
      when: item.step,
      then: item.expected_result,
    })),
  };
}

function createExamplesFromDataSet(dataSet?: DataSet) {
  if (!dataSet) {
    return null;
  }
  const dataSetHeaders = Object.keys(dataSet.schema).filter(
    (h) => h !== DASET_ID_KEY,
  );
  const dataSetRows = dataSet.data.map((row) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tq_id, ...rest } = row;
    return Object.values(rest);
  });
  return {
    headers: dataSetHeaders,
    rows: dataSetRows,
  };
}

function createScenarioDescription(testName: string) {
  return testName
    .replace('Scenario Outline: ', '')
    .replace('Scenario Outline', '')
    .replace('Scenario: ', '')
    .replace('Scenario', '');
}
