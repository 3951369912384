import { State } from '@bitmodern/redux/store';
import { accessRoleUserSelectors } from 'src/gen/domain/access_role_user/accessRoleUserSelector';

export function accessRoleUsersSelector(state: State) {
  return accessRoleUserSelectors.selectAll(state);
}

export function accessRoleUsersByUserSelector(state: State, userId: number) {
  return accessRoleUsersSelector(state).filter(
    (accessRoleUser) => accessRoleUser.user_id === userId,
  );
}
