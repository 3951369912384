import { State } from '@bitmodern/redux/store';
import caseTypeAdapter from 'src/gen/domain/case_type/caseTypeAdapter';

const caseTypeSelectors = caseTypeAdapter.getSelectors(
  (state: State) => state.gen.caseType,
);

export function caseTypesSelector(state: State) {
  return caseTypeSelectors.selectAll(state);
}

export function caseTypeSelector(state: State, id: number) {
  return caseTypeSelectors.selectById(state, id);
}
