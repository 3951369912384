export enum VirtualSchemaType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Date = 'Date',
}

type VirtualSchemaTypeKeys =
  `virtualSchemaType.${Uncapitalize<VirtualSchemaType>}`;

export const VirtualSchemaTypeDisplay: ReadonlyMap<
  VirtualSchemaType,
  VirtualSchemaTypeKeys
> = new Map([
  [VirtualSchemaType.String, 'virtualSchemaType.string'],
  [VirtualSchemaType.Number, 'virtualSchemaType.number'],
  [VirtualSchemaType.Boolean, 'virtualSchemaType.boolean'],
  [VirtualSchemaType.Date, 'virtualSchemaType.date'],
]);
