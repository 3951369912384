import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loading, notification } from '@bitmodern/bit-ui';
import MainMenu from 'src/components/organisms/MainMenu';
import ProjectSelect from 'src/components/organisms/ProjectSelect';
import { routes } from 'src/components/Router';
import useFetch from 'src/hooks/useFetch';
import { projectsSelector } from '@bitmodern/redux/state/projects/selectors';
import { useAppDispatch, useAppSelector } from '@bitmodern/redux/store';
import { planCreateOneThunk } from 'src/gen/domain/plan/planThunk';
import MigrationDialog from 'src/components/organisms/MigrationDialog';
import useMutation from 'src/hooks/useMutation';
import { notificationErrorTimeout } from 'src/constants';
import { getEnv } from 'src/env';
import { getProjectDataThunk } from '@bitmodern/redux/state/projects/thunks';
import styles from './ProjectView.module.scss';

type Props = {
  children: ReactElement;
};

export default function ProjectView({ children }: Props) {
  const { site, projectId: projectIdUrl } =
    useParams<typeof routes.PROJECT.params>();
  const projectId = parseInt(projectIdUrl, 10);
  const [showMigration, setShowMigration] = useState(false);
  const history = useHistory();
  const projects = useAppSelector(projectsSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (projectIdUrl) {
      localStorage.setItem('lastProject', projectIdUrl);
    }
  }, [projectIdUrl]);

  const projectFetch = useFetch(
    ({ dataCached = [] }) => {
      setShowMigration(false);
      if (!projectId) return Promise.reject();

      return dispatch(
        getProjectDataThunk({
          projectId,
          onMigrateProject: () => setShowMigration(true),
          dataCached,
        }),
      ).catch((error) => {
        if (error.name === 'ProjectNotFound') {
          notification.open({
            message: 'Project does not exist',
            type: 'error',
            duration: notificationErrorTimeout,
          });
          history.push(routes.PROJECT_EMPTY({ site }));
        }
      });
    },
    [site, 'project', projectId],
    {
      ignoreCallback: true,
      ...(getEnv()?.features?.dataCache && { staleWhileRevalidate: true }),
    },
  );

  const onMigrate = useCallback(() => {
    return dispatch(
      planCreateOneThunk({
        data: {
          project_id: projectId,
          name: 'All tests',
          is_root: true,
        },
      }),
    ).then(() => {
      setShowMigration(false);
      window.location.reload();
    });
  }, [dispatch, projectId]);

  const migrateMutation = useMutation(onMigrate);

  const content = showMigration ? (
    <MigrationDialog
      migrationLoading={migrateMutation.isLoading}
      onMigrate={migrateMutation.mutate}
    />
  ) : (
    children
  );

  return (
    <>
      <div className={styles.subHeader}>
        <ProjectSelect projects={projects} value={projectId} />
        <MainMenu />
      </div>
      {projectFetch.isLoading && (
        <Loading className={styles.loading} size={48} />
      )}
      {projectFetch.isFetched && content}
    </>
  );
}
