import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';
import {
  currentUserSelector,
  isAuthenticatedSelector,
  tokenSelector,
} from '@bitmodern/redux/state/authentication/selectors';
import { isCurrentUserAdminSelector } from '@bitmodern/redux/state/accessRoles/selectors';
import { themeColorSelector } from '@bitmodern/redux/state/theme/selectors';
import { useTranslation } from 'src/i18n/hooks';
import {
  Avatar,
  Dropdown,
  IconButton,
  Menu,
  MenuItem,
} from '@bitmodern/bit-ui';
import { logoutThunk } from '@bitmodern/redux/state/authentication/thunks';
import {
  IntegrationsIcon,
  LogoutIcon,
  SubscriptionsIcon,
  UserIcon,
  CustomizeIcon,
  UsersIcon,
  HotkeysIcon,
  TqLogoIcon,
  DropdownDownIcon,
  FileIcon,
} from '@bitmodern/bit-ui/icons';
import useModalManager from 'src/hooks/useModalManager';
import { routes } from 'src/components/Router';
import useParamsInRoute from 'src/hooks/useParamsInRoute';
import { HotkeysEnum } from 'src/enums/HotkeysEnum';
import { getKeyMaps } from 'src/utils/hotkeys';
import { useAppDispatch, useAppSelector } from 'src/packages/redux/store';
import { formatUserName, processAttachmentPath } from 'src/utils/fileHelper';
import { HeaderActions } from './HeaderAcions';
import { useCommandBar } from 'src/hooks/useCommandBar';
import GlobalCommandBar from '../GlobalCommandBar/GlobalCommandBar';
import vars from 'src/export.scss';
import styles from './MainLayout.module.scss';

type Props = {
  children: ReactNode;
};

export default function MainLayout({ children }: Props) {
  const { site: urlSite } = useParamsInRoute<typeof routes.HOME.params>(
    routes.HOME.path,
  );
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const user = useAppSelector(currentUserSelector);
  const isUserAdmin = useAppSelector(isCurrentUserAdminSelector);
  const { showModal } = useModalManager();
  const token = useAppSelector(tokenSelector);

  const themeColor = useAppSelector(themeColorSelector);
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { show } = useCommandBar();

  const site = urlSite || token?.client_name || '';

  function onLogout() {
    dispatch(logoutThunk());
  }

  const onClickIntegrations = () => {
    history.push(routes.INTEGRATIONS({ site }));
  };

  const onClickUserManagement = () => {
    history.push(routes.USERS({ site }));
  };

  const onClickCustomization = () => {
    history.push(routes.CUSTOMIZATION({ site }));
  };

  const onClickSubscriptions = () => {
    history.push(routes.SUBSCRIPTIONS({ site }));
  };

  const clickProfile = () => {
    history.push(routes.PROFILE({ site }));
  };

  const onHotkeyList = (event) => {
    event.preventDefault();
    showModal({
      modalName: 'hotkeys',
      type: 'modal',
    });
  };

  const onClickImport = () => {
    history.push(routes.AUTOMATIONS({ site }));
  };

  const onShowCommandBar = (event) => {
    show();
  };

  return (
    <GlobalHotKeys
      allowChanges
      handlers={{
        [HotkeysEnum.HotkeyList]: onHotkeyList,
        [HotkeysEnum.CommandBar]: onShowCommandBar,
      }}
      keyMap={getKeyMaps(HotkeysEnum.HotkeyList, HotkeysEnum.CommandBar)}>
      <header className={styles.header}>
        <Link
          className={styles.logoContainer}
          to={site ? routes.HOME({ site }) : '#'}>
          <IconButton boxed={false} title={t('app.header.logo')}>
            <TqLogoIcon
              size={20}
              color={themeColor === 'dark' ? undefined : vars.tqLogoCheckLight}
            />
          </IconButton>
        </Link>
        <HeaderActions />
        {isAuthenticated && (
          <Dropdown
            className={styles.user}
            popupAlign={{ points: ['tr', 'br'], offset: [0, 8] }}
            overlay={
              <Menu>
                <MenuItem
                  onClick={clickProfile}
                  startAdornment={
                    <UserIcon color={vars.textPrimary} size={20} />
                  }>
                  {t('app.header.menu.profile')}
                </MenuItem>
                <MenuItem
                  onClick={onClickIntegrations}
                  startAdornment={
                    <IntegrationsIcon color={vars.textPrimary} size={18} />
                  }>
                  {t('app.header.menu.integrations')}
                </MenuItem>
                <MenuItem
                  onClick={onClickSubscriptions}
                  startAdornment={
                    <SubscriptionsIcon color={vars.textPrimary} size={18} />
                  }>
                  {t('app.header.menu.subscriptions')}
                </MenuItem>
                <MenuItem
                  onClick={onClickUserManagement}
                  startAdornment={
                    <UsersIcon color={vars.textPrimary} size={18} />
                  }>
                  {t('app.header.menu.userManagement')}
                </MenuItem>
                {isUserAdmin && (
                  <MenuItem
                    onClick={onClickCustomization}
                    startAdornment={
                      <CustomizeIcon color={vars.textPrimary} size={18} />
                    }>
                    {t('app.header.menu.customization')}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={onClickImport}
                  startAdornment={
                    <FileIcon color={vars.textPrimary} size={18} />
                  }>
                  {t('app.header.menu.import')}
                </MenuItem>
                <MenuItem
                  onClick={onHotkeyList}
                  startAdornment={
                    <HotkeysIcon color={vars.textPrimary} size={18} />
                  }>
                  {t('app.header.menu.hotkeys')}
                </MenuItem>
                <MenuItem
                  onClick={onLogout}
                  startAdornment={
                    <LogoutIcon color={vars.textPrimary} size={20} />
                  }>
                  {t('app.header.menu.logout')}
                </MenuItem>
              </Menu>
            }>
            <div id="active-user" className={styles.settings}>
              <Avatar
                className={styles.avatar}
                name={user?.given_name}
                size={24}
                src={processAttachmentPath(user?.picture)}
              />
              <span className={styles.username}>{formatUserName(user)}</span>
              <DropdownDownIcon color={vars.textPrimary} />
            </div>
          </Dropdown>
        )}
      </header>
      <GlobalCommandBar />
      {children}
    </GlobalHotKeys>
  );
}
