import { createReducer } from '@reduxjs/toolkit';
import { ReturnToken } from '@testquality/sdk';
import { authenticateAction } from './actions';

export interface State {
  token?: ReturnToken;
}

const initialState: State = {
  token: undefined,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(authenticateAction, (state, action) => ({
    ...state,
    token: action.payload,
  }));
});
